import { Card, Divider, Tooltip } from "@blueprintjs/core";
import React, { CSSProperties, FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
  showDetailsStrategyDialog,
  showStrategyResourceComponentsDialog,
} from "../../../../store/strategies/actions";
import { PlanStrategy, Strategy } from "../../../../types";
import ButtonWithTooltip from "../../../common/buttons/ButtonWithTooltip";
import ResourcesList from "../../resources/ResourcesList";
import { isPlanStrategy } from "../../utils";
import StrategyActions from "../StrategyActions";
import StrategyStatus from "./StrategyStatus";

type OwnProps<TStrategy = Strategy | PlanStrategy> = {
  style?: CSSProperties;
  goalTitle?: string;
  interactive?: boolean;
  strategy: TStrategy;
  isActive?: boolean;
  onClick?: (strategy: TStrategy) => void;
  rightContent?: React.ReactNode;
  showControlButtons?: boolean;
  onDeleteSuccess?: () => void;
  onUpdateStatusSuccess?: () => void;
  onUpdateLogsSuccess?: () => void;
};

type Props = OwnProps;

const StrategyItem: FunctionComponent<Props> = (props) => {
  const {
    style,
    interactive,
    strategy,
    isActive,
    onClick,
    rightContent,
    showControlButtons = !onClick,
    onDeleteSuccess,
    onUpdateStatusSuccess,
    onUpdateLogsSuccess,
    goalTitle,
    children,
  } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const isPlanStrategyType = useMemo(() => {
    return isPlanStrategy(strategy);
  }, [strategy]);

  const strategyName = useMemo(() => {
    return isPlanStrategyType
      ? (strategy as PlanStrategy)?.strategy?.name
      : (strategy as Strategy).name;
  }, [isPlanStrategyType, strategy]);

  const strategyLookFors = useMemo(() => {
    return isPlanStrategyType
      ? (strategy as PlanStrategy)?.strategy?.look_for_items
      : (strategy as Strategy).look_for_items;
  }, [isPlanStrategyType, strategy]);

  const strategyResources = useMemo(() => {
    return isPlanStrategyType
      ? (strategy as PlanStrategy)?.strategy?.resources
      : (strategy as Strategy).resources;
  }, [isPlanStrategyType, strategy]);

  const handleViewResourcesClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      showStrategyResourceComponentsDialog({
        strategyName: strategyName,
        contentName: intl.formatMessage({
          id: "app.titles.resources",
        }),
        content: !!strategyResources?.length ? (
          <ResourcesList resources={strategyResources} />
        ) : undefined,
      })
    );
  };

  const handleViewLookForsClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      showStrategyResourceComponentsDialog({
        strategyName: strategyName,
        contentName: intl.formatMessage({
          id: "app.titles.look-fors",
        }),
        content: !!strategyLookFors?.length ? (
          <ul className="list-inside list-disc">
            {strategyLookFors?.map((lookFor) => (
              <li key={lookFor.id}>{lookFor.text}</li>
            ))}
          </ul>
        ) : undefined,
      })
    );
  };

  const handleViewDetailsClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(showDetailsStrategyDialog(strategy));
  };

  return (
    <Card
      style={style}
      className={`p-4 ${isActive ? "bg-blue-300" : ""} text-xl`}
      interactive={interactive}
      onClick={() => {
        onClick && onClick(strategy);
      }}
    >
      <div>
        <div className="flex justify-between items-center">
          <div className="flex-1 min-w-0">
            <div className="flex flex-wrap items-start gap-2 justify-between">
              <div
                className="font-bold flex-grow text-xl"
                style={{ flexGrow: 999 }}
              >
                {strategyName}
              </div>

              <div className="flex flex-wrap flex-grow gap-2 items-center justify-between">
                {isPlanStrategyType && (
                  <div className="flex flex-wrap items-center">
                    <StrategyStatus planStrategy={strategy as PlanStrategy} />
                  </div>
                )}
                {showControlButtons && (
                  <StrategyActions
                    item={strategy}
                    goalTitle={goalTitle}
                    showPlanStrategyActions={isPlanStrategyType}
                    onDeleteSuccess={onDeleteSuccess}
                    onUpdateStatusSuccess={onUpdateStatusSuccess}
                    onUpdateLogsSuccess={onUpdateLogsSuccess}
                  />
                )}
              </div>
            </div>

            <div className="mt-2 flex flex-1 gap-2 items-center">
              {showControlButtons && (
                <ButtonWithTooltip
                  icon="info-sign"
                  title={intl.formatMessage({
                    id: "app.strategies.view-details",
                  })}
                  onClick={handleViewDetailsClick}
                />
              )}
              <Tooltip
                content={intl.formatMessage({
                  id: "app.tooltips.for-information-on-all-core-components",
                })}
              >
                <a className="italic" onClick={handleViewLookForsClick}>
                  {intl.formatMessage(
                    {
                      id: "app.titles.look-fors.count",
                    },
                    { count: strategyLookFors?.length }
                  )}
                </a>
              </Tooltip>
            </div>

            {!!strategyResources.length && (
              <div className="flex flex-col gap-1">
                <Divider />

                <ResourcesList resources={strategyResources} />
              </div>
            )}
          </div>
          {rightContent}
        </div>
      </div>
      {children && (
        <>
          <Divider />
          {children}
        </>
      )}
    </Card>
  );
};

export default StrategyItem;
