import React from "react";
import { useIntl } from "react-intl";
import { HTMLTable } from "@blueprintjs/core";

type OwnProps = {};

type Props = OwnProps;

enum TeamCompositionEnum {
  KNOWLEDGE = "knowledge",
  TECHNICAL_SKILLS = "technical_skills",
  INTERPERSONAL_SKILLS = "interpersonal_skills",
  DIVERSE_PERSPECTIVES = "diverse_perspectives",
  Motivation = "motivation",
}
const TeamCompositionTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  return (
    <div>
      <p>
        {intl.formatMessage(
          { id: "app.team-composition.content" },
          { br: <br /> }
        )}
      </p>
      <HTMLTable className="w-full border-2 border-gray-400" bordered interactive>
        <tbody>
          {Object.values(TeamCompositionEnum).map((tc, index) => (
            <tr key={index}>
              <td>
                {intl.formatMessage({ id: `app.team-composition.${tc}` })}
              </td>
              <td>
                {intl.formatMessage(
                  { id: `app.team-composition.${tc}.content` },
                  { i: (chunks) => <i>{chunks}</i> }
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      <i className="text-sm">{intl.formatMessage({ id: "app.team-composition.footer" })}</i>
    </div>
  );
};

export default TeamCompositionTab;
