import React, { FunctionComponent } from "react";
import InviteUsersDialog from "./InviteUsersDialog";
import { useSelector } from "react-redux";

type OwnProps = { forAllSystem?: boolean };

type Props = OwnProps;

const InviteUsersDialogContainer: FunctionComponent<Props> = (props) => {
  const loading = useSelector((s) => s.users.loading.inviteUsers);
  const error = useSelector((s) => s.users.errors.inviteUsers);

  const failedInvites = useSelector((s) => s.users.failedInvites);

  const amountOfSucceedInvites = useSelector(
    (s) => s.users.amountOfSucceedInvites
  );

  return (
    <InviteUsersDialog
      successInvitesNum={amountOfSucceedInvites}
      failedInvites={failedInvites}
      loading={loading}
      error={error}
      forAllSystem={props.forAllSystem}
    />
  );
};

export default InviteUsersDialogContainer;
