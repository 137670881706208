import React, { useMemo } from "react";
import {
  District,
  Survey,
  SurveyDeployment,
  UserNotesType,
} from "../../../../types";
import { useIntl } from "react-intl";
import { Button, Divider } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../../App";
import SurveyAdministrationTable from "./SurveyAdministrationTable";

type OwnProps = {
  activeSurveyDeployment: SurveyDeployment<District, Survey<number>>;
};

type Props = OwnProps;

const SurveyAdministrationWidgetBody: React.FC<Props> = ({
  activeSurveyDeployment,
}: Props) => {
  const intl = useIntl();

  const history = useHistory();

  const handleViewDetailsClick = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanData, {
        workspace: "data",
        tabId: UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
      } as any)
    );
  };

  const isSurveyHasNoResponses = useMemo(() => {
    return !Object.keys(activeSurveyDeployment?.responses ?? {}).length;
  }, [activeSurveyDeployment]);

  return (
    <div>
      <div className="flex items-center">
        <div className="flex-1">
          <p className="text-xl">{activeSurveyDeployment?.survey?.name}</p>
          <p className="text-xl">
            {intl.formatMessage({
              id: "app.survey-report.response-rates.response-rates",
            })}
          </p>
        </div>
        <Button
          text={intl.formatMessage({ id: "app.strategies.view-details" })}
          title={intl.formatMessage({ id: "app.strategies.view-details" })}
          onClick={handleViewDetailsClick}
          large
        />
      </div>
      <Divider />
      {isSurveyHasNoResponses ? (
        <div>
          <p className="font-bold text-center text-lg">
            {intl.formatMessage({
              id: "app.non-ideal-state.no-responses.title",
            })}
          </p>
          <p className="text-center">
            {intl.formatMessage({
              id: "app.non-ideal-state.no-responses.description",
            })}
          </p>
        </div>
      ) : (
        <>
          <SurveyAdministrationTable
            openTimePeriods={activeSurveyDeployment.school_open_periods}
            responses={activeSurveyDeployment.responses}
          />
        </>
      )}
    </div>
  );
};

export default SurveyAdministrationWidgetBody;
