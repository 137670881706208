import { Epic } from "redux-observable";
import { catchError, filter, map, switchMap, mergeMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getDistrictsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getDistricts.request)),
    switchMap((action) =>
      from(api.districts.getDistricts()).pipe(
        map(actions.getDistricts.success),
        catchError((error) => of(actions.getDistricts.failure(error)))
      )
    )
  );
};

export const createDistrictEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addDistrict.request)),
    switchMap((action) =>
      from(api.districts.addDistrict(action.payload.district)).pipe(
        mergeMap((district) =>
          from(
            [actions.addDistrict.success(district)],
            action.payload.onSuccess && action.payload.onSuccess(district)
          )
        ),
        catchError((error) => of(actions.addDistrict.failure(error)))
      )
    )
  );
};

export const updateDistrictEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateDistrict.request)),
    switchMap((action) =>
      from(api.districts.updateDistrict(action.payload)).pipe(
        map(actions.updateDistrict.success),
        catchError((error) => of(actions.updateDistrict.failure(error)))
      )
    )
  );
};

export const getDistrictByIdEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getDistrictById.request)),
    switchMap((action) =>
      from(api.districts.getDistrictById(action.payload)).pipe(
        map(actions.getDistrictById.success),
        catchError((error) => of(actions.getDistrictById.failure(error)))
      )
    )
  );
};
