import {
  AddPlanStrategyDialogType,
  AddStrategyToGoalDialogType,
  PlanStrategy,
  Strategy,
} from "../../types";
import * as actions from "./actions";
import { createReducer, RootAction } from "typesafe-actions";
import React from "react";

type ActionName =
  | "getStrategies"
  | "addStrategy"
  | "updateStrategy"
  | "deleteStrategy";

export type StrategiesState = {
  strategies: Strategy[];
  dialogs: {
    strategyUpsertDialog: {
      show?: boolean;
      selectedStrategy?: Strategy;
      onConfirm?: Function;
    };
    addPlanStrategyDialog: AddPlanStrategyDialogType;
    updateImplementationStatusDialog: {
      show?: boolean;
      selectedStrategy?: PlanStrategy;
      onConfirm?: Function;
      implementation?: number;
    };
    updatePlanStrategyDetailsDialog: {
      show?: boolean;
      goalTitle?: string;
      planStrategy?: PlanStrategy;
      onConfirm?: Function;
    };
    strategyResourcesComponentsDialog: {
      show?: boolean;
      strategyName?: string;
      contentName?: string;
      content?: React.ReactNode;
      onConfirm?: Function;
    };
    detailsStrategyDialog: {
      show?: boolean;
      strategy?: Strategy | PlanStrategy;
    };
    addStrategyToGoalDialog: AddStrategyToGoalDialogType;
  };
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: StrategiesState = {
  strategies: [],
  loading: {},
  errors: {},
  dialogs: {
    strategyUpsertDialog: {},
    addPlanStrategyDialog: {},
    updateImplementationStatusDialog: {},
    updatePlanStrategyDetailsDialog: {},
    strategyResourcesComponentsDialog: {},
    detailsStrategyDialog: {},
    addStrategyToGoalDialog: {},
  },
};

export const strategiesReducer = createReducer<StrategiesState, RootAction>(
  initialState
)
  // update implementation status dialog
  .handleAction(
    actions.showImplementationStatusDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        updateImplementationStatusDialog: {
          show: true,
          selectedStrategy: action.payload.planStrategy,
          onConfirm: action.payload.onUpdateSuccess,
          implementation: action.payload.implementation,
        },
      },
    })
  )
  .handleAction(
    actions.hideImplementationStatusDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        updateImplementationStatusDialog: {},
      },
    })
  )
  // update plan strategy details dialog
  .handleAction(
    actions.showUpdatePlanStrategyDetailsDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        updatePlanStrategyDetailsDialog: { show: true, ...action.payload },
      },
    })
  )
  .handleAction(
    actions.hideUpdatePlanStrategyDetailsDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        updatePlanStrategyDetailsDialog: {},
      },
    })
  )

  // details strategy dialog
  .handleAction(
    actions.showDetailsStrategyDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        detailsStrategyDialog: {
          show: true,
          strategy: action.payload,
        },
      },
    })
  )
  .handleAction(
    actions.hideDetailsStrategyDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        detailsStrategyDialog: {},
      },
    })
  )
  // strategy resource components dialog
  .handleAction(
    actions.showStrategyResourceComponentsDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        strategyResourcesComponentsDialog: {
          show: true,
          strategyName: action.payload.strategyName,
          contentName: action.payload.contentName,
          content: action.payload.content,
        },
      },
    })
  )
  .handleAction(
    actions.hideStrategyResourceComponentsDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        strategyResourcesComponentsDialog: {},
      },
    })
  )
  // get strategies
  .handleAction(
    actions.getStrategies.request,
    (state, action): StrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        getStrategies: true,
      },
      errors: {
        ...state.errors,
        getStrategies: undefined,
      },
    })
  )
  .handleAction(
    actions.getStrategies.success,
    (state, action): StrategiesState => ({
      ...state,
      strategies: action.payload,
      loading: {
        ...state.loading,
        getStrategies: false,
      },
    })
  )
  .handleAction(
    actions.getStrategies.failure,
    (state, action): StrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        getStrategies: action.payload,
      },
      loading: {
        ...state.loading,
        getStrategies: false,
      },
    })
  )
  // upsert strategy dialog
  .handleAction(
    actions.showUpsertStrategyDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        strategyUpsertDialog: {
          show: true,
          selectedStrategy: action.payload ? action.payload : undefined,
        },
      },
    })
  )
  .handleAction(
    actions.hideUpsertStrategyDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        strategyUpsertDialog: {},
      },
    })
  )
  // show strategy library dialog
  .handleAction(
    actions.showAddPlanStrategyDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        addPlanStrategyDialog: action.payload
          ? { ...action.payload, show: true }
          : {
              show: true,
            },
      },
    })
  )
  .handleAction(
    actions.hideAddPlanStrategyDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        addPlanStrategyDialog: {
          ...state.dialogs.addPlanStrategyDialog,
          show: false,
        },
      },
    })
  )
  .handleAction(
    actions.reopenAddPlanStrategyDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        addPlanStrategyDialog: {
          ...state.dialogs.addPlanStrategyDialog,
          show: true,
        },
      },
    })
  )
  // add strategy to goal dialog
  .handleAction(
    actions.showAddStrategyToGoalDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        addPlanStrategyDialog: {
          ...state.dialogs.addPlanStrategyDialog,
          show: false,
        },
        addStrategyToGoalDialog: {
          ...action.payload,
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideAddStrategyToGoalDialog,
    (state, action): StrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        addStrategyToGoalDialog: {},
      },
    })
  )
  // add strategy
  .handleAction(
    actions.addStrategy.request,
    (state, action): StrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        addStrategy: true,
      },
      errors: {
        ...state.errors,
        addStrategy: undefined,
      },
    })
  )
  .handleAction(
    actions.addStrategy.success,
    (state, action): StrategiesState => ({
      ...state,
      strategies: [...state.strategies, action.payload],
      loading: {
        ...state.loading,
        addStrategy: false,
      },
    })
  )
  .handleAction(
    actions.addStrategy.failure,
    (state, action): StrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        addStrategy: action.payload,
      },
      loading: {
        ...state.loading,
        addStrategy: false,
      },
    })
  )
  // update strategy
  .handleAction(
    actions.updateStrategy.request,
    (state, action): StrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        updateStrategy: true,
      },
      errors: {
        ...state.errors,
        updateStrategy: undefined,
      },
    })
  )
  .handleAction(
    actions.updateStrategy.success,
    (state, action): StrategiesState => ({
      ...state,
      strategies: state.strategies.map((s) =>
        s.id === action.payload.id ? action.payload : s
      ),
      loading: {
        ...state.loading,
        updateStrategy: false,
      },
    })
  )
  .handleAction(
    actions.updateStrategy.failure,
    (state, action): StrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        updateStrategy: action.payload,
      },
      loading: {
        ...state.loading,
        updateStrategy: false,
      },
    })
  )
  // delete strategy
  .handleAction(
    actions.deleteStrategy.request,
    (state, action): StrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteStrategy: true,
      },
      errors: {
        ...state.errors,
        deleteStrategy: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteStrategy.success,
    (state, action): StrategiesState => ({
      ...state,
      strategies: state.strategies.filter((s) => s.id !== action.payload),
      loading: {
        ...state.loading,
        deleteStrategy: false,
      },
    })
  )
  .handleAction(
    actions.deleteStrategy.failure,
    (state, action): StrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteStrategy: action.payload,
      },
      loading: {
        ...state.loading,
        deleteStrategy: false,
      },
    })
  );
