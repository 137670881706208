import { createAction, createAsyncAction } from "typesafe-actions";
import {
  CompletedSurvey,
  QuestionTag,
  Survey,
  SurveyPermissionsError,
  SurveyQuestion,
  SurveyQuestionAnswer,
  SurveyQuestionExcludeBaseModelType,
} from "../../types";
import {
  AddQuestionTagRequest,
  AddSurveyRequest,
  CompletedSurveyRequest,
  GetQuestionTagsRequest,
  UpdateSurveyQuestionsRequest,
  UpdateSurveyRequest,
} from "../../api/surveys/types";
import { Locale } from "../UIState";

export const getSurvey = createAsyncAction(
  "@surveys/GET_SURVEY_REQUEST",
  "@surveys/GET_SURVEY_SUCCESS",
  "@surveys/GET_SURVEY_FAILURE"
)<number, Survey, Error>();

export const addSurvey = createAsyncAction(
  "@surveys/ADD_SURVEY_REQUEST",
  "@surveys/ADD_SURVEY_SUCCESS",
  "@surveys/ADD_SURVEY_FAILURE"
)<{ survey: AddSurveyRequest; onSuccess?: Function }, Survey, Error>();

export const updateSurvey = createAsyncAction(
  "@surveys/UPDATE_SURVEY_REQUEST",
  "@surveys/UPDATE_SURVEY_SUCCESS",
  "@surveys/UPDATE_SURVEY_FAILURE"
)<
  {
    surveyId: number;
    updatedSurvey: UpdateSurveyRequest;
    onSuccess?: Function;
  },
  Survey,
  Error
>();

export const deleteSurvey = createAsyncAction(
  "@surveys/DELETE_SURVEY_REQUEST",
  "@surveys/DELETE_SURVEY_SUCCESS",
  "@surveys/DELETE_SURVEY_FAILURE"
)<number, number, Error>();

export const getSurveys = createAsyncAction(
  "@surveys/GET_SURVEYS_REQUEST",
  "@surveys/GET_SURVEYS_SUCCESS",
  "@surveys/GET_SURVEYS_FAILURE"
)<void, Survey[], Error>();

export const setSurveyQuestions = createAction("@surveys/SET_SURVEY_QUESTIONS")<
  SurveyQuestionExcludeBaseModelType[]
>();

export const getSurveyQuestions = createAsyncAction(
  "@surveys/GET_SURVEY_QUESTIONS_REQUEST",
  "@surveys/GET_SURVEY_QUESTIONS_SUCCESS",
  "@surveys/GET_SURVEY_QUESTIONS_FAILURE"
)<number, SurveyQuestion<number, number, QuestionTag>[], Error>();

export const getSurveyQuestionsByDomain = createAsyncAction(
  "@surveys/GET_SURVEY_QUESTIONS_BY_DOMAIN_REQUEST",
  "@surveys/GET_SURVEY_QUESTIONS_BY_DOMAIN_SUCCESS",
  "@surveys/GET_SURVEY_QUESTIONS_BY_DOMAIN_FAILURE"
)<number, SurveyQuestion[], Error>();

export const getSurveyQuestionsByHash = createAsyncAction(
  "@surveys/GET_SURVEY_QUESTIONS_BY_HASH_REQUEST",
  "@surveys/GET_SURVEY_QUESTIONS_BY_HASH_SUCCESS",
  "@surveys/GET_SURVEY_QUESTIONS_BY_HASH_FAILURE"
)<
  {
    demographicHash: string;
    schoolHash: string;
    sessionHash: string;
    locale: Locale;
    password?: string;
  },
  { payload: CompletedSurvey; locale: Locale },
  SurveyPermissionsError | string
>();

export const showSurveyUpsertDialog = createAction(
  "@surveys/SHOW_SURVEY_UPSERT_DIALOG"
)<void | Survey>();
export const hideSurveyUpsertDialog = createAction(
  "@surveys/HIDE_SURVEY_UPSERT_DIALOG"
)<void>();

export const editSurveyQuestionsInSelectedSurvey = createAsyncAction(
  "@surveys/EDIT_SURVEY_QUESTION_IN_SELECTED_SURVEY_REQUEST",
  "@surveys/EDIT_SURVEY_QUESTION_IN_SELECTED_SURVEY_SUCCESS",
  "@surveys/EDIT_SURVEY_QUESTION_IN_SELECTED_SURVEY_FAILURE"
)<{ request: UpdateSurveyQuestionsRequest; surveyId: number }, void, Error>();

export const saveCompletedSurvey = createAsyncAction(
  "@surveys/SAVE_COMPLETED_SURVEY_REQUEST",
  "@surveys/SAVE_COMPLETED_SURVEY_SUCCESS",
  "@surveys/SAVE_COMPLETED_SURVEY_FAILURE"
)<
  {
    hash: string;
    completedSurvey: CompletedSurveyRequest;
    school: string;
    sessionHash: string;
  },
  void,
  Error
>();

export const saveSurveyQuestionAnswer = createAsyncAction(
  "@surveys/SAVE_SURVEY_QUESTION_ANSWER_REQUEST",
  "@surveys/SAVE_SURVEY_QUESTION_ANSWER_SUCCESS",
  "@surveys/SAVE_SURVEY_QUESTION_ANSWER_FAILURE"
)<
  {
    hash: string;
    school: string;
    sessionHash: string;
    request: SurveyQuestionAnswer;
    previousObject: SurveyQuestionAnswer;
  },
  SurveyQuestionAnswer,
  {
    error: Error;
    previousObject: SurveyQuestionAnswer;
  }
>();

export const saveTestingSurveyQuestionAnswer = createAction(
  "@surveys/SAVE_TESTING_SURVEY_QUESTION_ANSWER"
)<SurveyQuestionAnswer>();

export const resetSurveyCompletion = createAction(
  "@surveys/RESET_SURVEY_COMPLETION"
)<void>();

export const getQuestionTags = createAsyncAction(
  "@surveys/GET_QUESTION_TAGS_REQUEST",
  "@surveys/GET_QUESTION_TAGS_SUCCESS",
  "@surveys/GET_QUESTION_TAGS_FAILURE"
)<GetQuestionTagsRequest, QuestionTag[], Error>();

export const getQuestionTagById = createAsyncAction(
  "@surveys/GET_QUESTION_TAG_BY_ID_REQUEST",
  "@surveys/GET_QUESTION_TAG_BY_ID_SUCCESS",
  "@surveys/GET_QUESTION_TAG_BY_ID_FAILURE"
)<number | string, QuestionTag, Error>();

export const addQuestionTag = createAsyncAction(
  "@surveys/ADD_QUESTION_TAG_REQUEST",
  "@surveys/ADD_QUESTION_TAG_SUCCESS",
  "@surveys/ADD_QUESTION_TAG_FAILURE"
)<{ body: AddQuestionTagRequest; onSuccess?: Function }, QuestionTag, Error>();

export const updateQuestionTag = createAsyncAction(
  "@surveys/UPDATE_QUESTION_TAG_REQUEST",
  "@surveys/UPDATE_QUESTION_TAG_SUCCESS",
  "@surveys/UPDATE_QUESTION_TAG_FAILURE"
)<Pick<QuestionTag, "id" | "tag">, QuestionTag, Error>();

export const deleteQuestionTag = createAsyncAction(
  "@surveys/DELETE_QUESTION_TAG_REQUEST",
  "@surveys/DELETE_QUESTION_TAG_SUCCESS",
  "@surveys/DELETE_QUESTION_TAG_FAILURE"
)<number, number, Error>();

export const setSelectedSurvey = createAction("@surveys/SET_SELECTED_SURVEY")<
  Survey | undefined
>();
