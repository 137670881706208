import React, { FunctionComponent, useMemo } from "react";
import { Dataset } from "../../../../types";
import { useInsightTitle } from "../../../surveys/tab/insights/useInsightTitle";
import _ from "lodash";
import { useIntl } from "react-intl";

type OwnProps = {
  dataset: Dataset;
  hideDemographic?: boolean;
};

type Props = OwnProps;

const DatasetTitle: FunctionComponent<Props> = (props) => {
  const { dataset, hideDemographic = true } = props;

  const intl = useIntl();
  const { generateInsightTitle } = useInsightTitle({} as any);

  const title = useMemo(() => {
    const title = _.chain(dataset)
      .pick(
        "direct_instruction",
        "gender",
        "grade",
        "race",
        "remote_learning",
        "staff_role",
        "reduced_lunch",
        "has_iep",
        "english_at_home",
        hideDemographic ? "" : "demographic"
      )
      .toPairs()
      .filter(([key, value]) => value != null)
      .map(([key, value]) => generateInsightTitle(key, value as string))
      .join("; ")
      .value();

    if (title) {
      return title;
    }

    return dataset.demographic
      ? intl.formatMessage({
          id: `app.filters.respondent-type.${dataset.demographic}`,
        })
      : undefined;
  }, [dataset]);

  return <>{title}</>;
};

export default DatasetTitle;
