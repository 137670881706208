import { AttachedResource, Strategy } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import { AddStrategyRequest, UpdateStrategyRequest } from "./types";
import { addResources, addResource } from "../resources";
import _ from "lodash";

export const getStrategies = (planId?: number | void): Promise<Strategy[]> => {
  return Axios.get(`${API_ROOT}/strategies/?plan=${planId}`);
};

export const addStrategy = async (
  strategy: AddStrategyRequest,
  planId: number
): Promise<Strategy> => {
  let resourceIds: number[] = [];

  if (strategy.resources?.length && !strategy.resources.some(_.isNumber)) {
    await addResources(
      strategy.resources as Omit<
        AttachedResource,
        "id" | "created_at" | "updated_at"
      >[],
      planId
    ).then((success) => {
      resourceIds = success
        ?.filter((response) => response.status === "fulfilled")
        ?.map(
          (resource) =>
            (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
        );
    });
  } else {
    resourceIds = ((strategy.resources as unknown) as number[]) ?? [];
  }

  let pdfResource;
  if (strategy.pdf_resource && strategy.pdf_resource.id < 0) {
    pdfResource = await addResource(strategy.pdf_resource);
  }

  return Axios.post(`${API_ROOT}/strategies/`, {
    ...strategy,
    resources: resourceIds,
    pdf_resource: pdfResource?.id || strategy.pdf_resource?.id,
  });
};

export const updateStrategy = async (
  id: number,
  strategy: AddStrategyRequest
): Promise<Strategy> => {
  let resourceIds: number[] = [];

  if (strategy?.resources?.length && !strategy?.resources?.some(_.isNumber)) {
    await addResources(
      strategy.resources as Omit<
        AttachedResource,
        "id" | "created_at" | "updated_at"
      >[]
    ).then((success) => {
      resourceIds = success
        ?.filter((response) => response.status === "fulfilled")
        ?.map(
          (resource) =>
            (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
        );
    });
  } else {
    resourceIds = ((strategy.resources as unknown) as number[]) ?? [];
  }

  let pdfResource;
  if (strategy.pdf_resource && strategy.pdf_resource.id < 0) {
    pdfResource = await addResource(strategy.pdf_resource);
  }

  return Axios.put(`${API_ROOT}/strategies/${id}/`, {
    ...strategy,
    resources: resourceIds,
    pdf_resource: pdfResource?.id || strategy.pdf_resource?.id,
  });
};

export const deleteStrategy = (strategyId: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/strategies/${strategyId}`);
};
