import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BookmarkType,
  Domain,
  DomainComparison,
  DomainDemographicTriageStatuses,
  DomainPriorityDecision,
  DomainPriorityTriageStatus,
} from "../../../../types";
import { useIntl } from "react-intl";
import {
  AnchorButton,
  Button,
  Callout,
  HTMLTable,
  IAnchorButtonProps,
  Intent,
} from "@blueprintjs/core";
import BookmarksInfoTable from "../bookmarks-info-table/BookmarksInfoTable";
import useDomainLevelItemBookmarkInfo from "../../../../helpers/hooks/bookmarks/useDomainLevelItemBookmarkInfo";
import { POTENTIAL_PRIORITY_BTN_COLOR } from "../item-level-info-and-bookmarks-dialog/useBookmarksLevelInfo";
import {
  UpdateDomainPrioritiesDecisionRequest,
  UpdateDomainPrioritiesDecisionResponse,
} from "../../../../api/needs/types";
import { updateDomainPrioritiesDecision } from "../../../../store/needs/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import useDomainLevelBookmarkCounter from "../../../../helpers/hooks/bookmarks/useDomainLevelBookmarkCounter";
import BookmarksIcons from "../explore-strengths-and-needs-tab/BookmarksIcons";
type OwnProps = {
  domain: Domain;
  dismissed?: boolean;
  prioritized?: boolean;
  hasPriorities?: boolean;
  domainComparison: DomainComparison;
  domainDemographicTriageStatuses?: DomainDemographicTriageStatuses;
  isPriorityConfirmed?: boolean;
};

type Props = OwnProps;

const DEFAULT_FOCUSING_QUESTIONS_MAX_HEIGHT = 160;

const DomainComparisonCollapseContent: React.FC<Props> = (props) => {
  const {
    domain,
    prioritized,
    hasPriorities,
    domainComparison,
    domainDemographicTriageStatuses,
    dismissed,
    isPriorityConfirmed,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const [domainId, setDomainId] = useState(domain?.id);

  const [loading, setLoading] = useState<boolean>(false);
  const surveyDeploymentId = useSelector((s) => s.needs.surveyDeploymentId);
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const [collapseMaxHeight, setCollapseMaxHeight] = useState<
    number | undefined
  >(undefined); //DEFAULT_FOCUSING_QUESTIONS_MAX_HEIGHT);

  const { items: domainLevelItems } = useDomainLevelItemBookmarkInfo({
    domainComparison: domainComparison,
    domainDemographicTriageStatuses: domainDemographicTriageStatuses,
  });

  const { marksCountByDemographic } = useDomainLevelBookmarkCounter({
    domainId: domain.id,
  });

  const handleWarningAlertShow = (
    req: UpdateDomainPrioritiesDecisionRequest
  ) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          handleRequestSent({
            ...req,
            force: true,
            onComplete: () => {
              setLoading(false);
              dispatch(hideConfirmDialog());
            },
          });
        },
        show: true,
        intent: "primary",
        text: intl.formatMessage({
          id: "app.needs.do-not-prioritize.warning-message",
        }),
        cancelButtonText: intl.formatMessage({ id: "app.titles.no" }),
        confirmButtonText: intl.formatMessage({ id: "app.titles.yes" }),
      })
    );
  };

  const onComplete = (
    res?: UpdateDomainPrioritiesDecisionResponse,
    req?: UpdateDomainPrioritiesDecisionRequest
  ) => {
    setLoading(false);
    if (res?.has_developed_priority && req) {
      handleWarningAlertShow(req);
    }
  };

  const makeRequest = useCallback(
    (
      decision: DomainPriorityDecision | null,
      force?: boolean
    ): UpdateDomainPrioritiesDecisionRequest | null => {
      if (surveyDeploymentId == null || activePlanId == null) {
        return null;
      }

      return {
        plan_id: activePlanId,
        survey_deployment: surveyDeploymentId,
        domain: domainId || 0,
        decision: decision,
        force: force,
        onComplete: onComplete,
      };
    },
    [domainId, activePlanId, surveyDeploymentId, onComplete]
  );

  const handleRequestSent = (
    request: UpdateDomainPrioritiesDecisionRequest
  ) => {
    setLoading(true);
    dispatch(updateDomainPrioritiesDecision.request(request));
  };

  const handleDomainPriorityDecisionChange = (
    decision: DomainPriorityDecision | null,
    checked: boolean
  ) => {
    const requestBody = makeRequest(checked ? null : decision);
    if (requestBody) {
      handleRequestSent(requestBody);
    }
  };

  const handleDismissed = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleDomainPriorityDecisionChange(
      DomainPriorityDecision.Dismissed,
      dismissed || false
    );
  };

  const handlePrioritize = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleDomainPriorityDecisionChange(
      DomainPriorityDecision.Prioritized,
      prioritized || false
    );
  };

  const dismissedButtonProps: IAnchorButtonProps = useMemo(() => {
    let text: string;
    let intent: Intent | undefined;
    let large: boolean = false;
    if (dismissed) {
      text = intl.formatMessage({ id: "app.titles.do-not-prioritize" });
      intent = "primary";
      // large = true;
    } else {
      text = intl.formatMessage({ id: "app.titles.do-not-prioritize" });
    }

    return {
      intent: intent,
      text: text,
      title: text,
      large: large,
    };
  }, [dismissed]);

  const respondent = useMemo(()=>{
    return Object.entries(domainComparison.respondents)
    .map((item, index)=>{return {...item[1],name:item[0]}})
    .sort((a:any, b:any) => b.mean_score - a.mean_score)
  },[domainComparison.respondents])

  return (
    <div className="flex gap-4">
      <div className="min-w-0">
        {
          <HTMLTable className={" common-gray-table bg-white w-3/4 m-auto"} striped bordered>
            <thead>
              <tr>
                <th className="item-level-header max-width-dig-deeper">{intl.formatMessage({
                  id: "app.titles.respondent.group",
                })}</th>
                <th>{intl.formatMessage({
                  id: "app.titles.domain-mean", //"app.titles.domain-overall-mean",
                })}</th>
                <th>

                  {intl.formatMessage({ id: "app.titles.bookmarks" })}</th>
              </tr>
            </thead>
            <tbody>
              {!!respondent && respondent.map((item, index) => {
                  const markCount = marksCountByDemographic[item.name]
                  return item.mean_score && (
                      <tr key={'domain_comp_key'+index}>
                        <td className={item.triage_status === DomainPriorityTriageStatus.PotentialPriority?'priority-row-dig-deeper text-left-align':'text-left-align'}>{intl.formatMessage({ id: `app.filters.respondent-type.${item.name}` })}</td>
                        <td>{item.mean_score?.toFixed(2)}</td>
                        <td>
                          <BookmarksIcons negative={markCount?markCount[BookmarkType.Negative]:0} positive={markCount?markCount[BookmarkType.Positive]:0} />
                        </td>
                      </tr>
                  );
              })
              }
            </tbody>
          </HTMLTable>
        }
        {/* <BookmarksInfoTable dismissed={dismissed} items={domainLevelItems} /> */}
      </div>
      <div className="flex flex-col gap-2 flex-1 min-w-0">
        <i className="font-bold">
          {intl.formatMessage({ id: "app.titles.focusing-questions" })}:
        </i>
        {domainComparison.domain.focusing_questions ? (
          <>
            <div
              style={
                collapseMaxHeight
                  ? { maxHeight: collapseMaxHeight, overflow: "hidden" }
                  : undefined
              }
            >
              <div
                className={"jodit-dangerous-content"}
                dangerouslySetInnerHTML={{
                  __html: domainComparison.domain.focusing_questions,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <Callout
              intent="warning"
              title={intl.formatMessage({
                id: "app.needs.no-focusing-questions",
              })}
            />
          </>
        )}
        <div className="flex justify-end mt-4">
          <div className="flex justify-between">
            <AnchorButton
              className="mr-1"
              {...dismissedButtonProps}
              disabled={!hasPriorities || prioritized || isPriorityConfirmed}
              loading={loading}
              onClick={handleDismissed}
              text={intl.formatMessage({
                id: "app.titles.do-not-prioritize",
              })}
            />
            <AnchorButton
              className="ml-1"
              loading={loading}
              disabled={!hasPriorities || dismissed || isPriorityConfirmed}
              style={
                prioritized
                  ? {
                    background: POTENTIAL_PRIORITY_BTN_COLOR,
                    color: "white",
                  }
                  : undefined
              }
              text={intl.formatMessage({
                id: "app.titles.prioritize-domain",
              })}
              title={intl.formatMessage({
                id: "app.titles.prioritize-domain",
              })}
              onClick={handlePrioritize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainComparisonCollapseContent;
