import * as React from "react";
import { ChangeEvent, InputHTMLAttributes, useState } from "react";
import { IInputGroupProps, InputGroup } from "@blueprintjs/core";

type Props = IInputGroupProps & InputHTMLAttributes<HTMLInputElement>;

const useFormInputField = (
  props?: Props
): [
  string | undefined,
  JSX.Element,
  React.Dispatch<React.SetStateAction<string | undefined>>
] => {
  const [formFieldValue, setFormFieldValue] = useState<string | undefined>(
    undefined
  );

  const inputGroup = (
    <InputGroup
      value={formFieldValue ?? ""}
      // intent={"primary"}
      {...props}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        setFormFieldValue(event.target?.value)
      }
    />
  );

  return [formFieldValue, inputGroup, setFormFieldValue];
};

export default useFormInputField;
