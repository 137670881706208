import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import Pusher from "pusher-js";
import "./App.css";
import { getCurrentUserRole, getIsAuthenticated } from "./store/auth/selectors";
import AppNavbar from "./components/app-navigation/AppNavbar";
import AppSideBarMenu from "./components/app-navigation/AppSideBarMenu";
import LoginForm from "./components/LoginForm";
import RegistrationForm from "./components/accept-invitation/RegistrationForm";
import withBodyContainer from "./components/body-container/WithBodyContainer";
import ResetPasswordPage from "./components/pages/ResetPasswordPage";
import AdminTables from "./components/pages/AdminTables";
import EditSurveyQuestions from "./components/pages/EditSurveyQuestions";
import ConfirmDialog from "./components/common/ConfirmDialog";
import { UserRole, userRoles } from "./types";
import { getAuthenticatedUser } from "./store/auth/actions";
import Survey from "./components/pages/Survey";
import SurveyReport from "./components/pages/SurveyReport";
import Districts, { DistrictTabs } from "./components/pages/Districts";
import DistrictDetails from "./components/pages/DistrictDetails";
import { Navbar } from "@blueprintjs/core";
import { generatePath, matchPath, useLocation } from "react-router";
import { useIntl } from "react-intl";
import AdminSettingsPage from "./components/pages/AdminSettingsPage";
import RestorePasswordPage from "./components/pages/RestorePasswordPage";
import PlanPage from "./components/pages/PlanPage";
//import njStrategicPlan from "./components/pages/nj_strategic_plan/njstrategicplan";
import useRolesPermissions from "./helpers/hooks/useRolesPermissions";
import EditProfileDialog from "./components/profile/profile-dialogs/EditProfileDialog";
import ChangePasswordDialog from "./components/profile/profile-dialogs/ChangePasswordDialog";
import NoInternetConnection from "./NoInternetConnection";


import { PUSHER_KEY, PUSHER_CLUSTER } from "./api/config";
import { downloadCSVReportByTaskId } from "./store/survey-reports/actions";

// Disabled for now, but we will enable the next deploy
//
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
  // process.env.NODE_ENV === 'production'? production : staging 
  dsn: process.env.NODE_ENV === 'production' ? "https://d1bf5a814ba5fb0aa9d731a471a66b3a@o4506988158713856.ingest.us.sentry.io/4506988168609792" : "https://f65b7d49ca35eab764e1672327235546@o4506988344311808.ingest.us.sentry.io/4506988350013440",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0 : 1.0,
});



let mixpanel = require("mixpanel-browser");

mixpanel.init("f6f0fa2414413664adfd217869687105", { debug: true });

// mixpanel.track("Page View");

export enum AuthRoutes {
  AppWelcomePage = "/:workspace(welcome)",
  SurveyReport = "/districts/:districtId?/schools/:schoolId?/surveys/:surveyDeploymentId?/domains/:domainId?/report/:tabId/:tagId(\\d+)?",
  YourPlan = "/plan/:workspace(dashboard|team|data|needs|goals|resources|learning|my-district|welcome|deleted-data|sci-writing|published-notes)?/:tabId?/:tagId(\\d+)?",
  YourPlanTeam = "/plan/:workspace(team)/:tabId?/:activity?",
  YourPlanResources = "/plan/:workspace(resources)/:tabId?/:activity?",
  YourPlanReports = "/plan/schools/:schoolId?/surveys/:surveyDeploymentId?/domains/:domainId?/:workspace(report)/:tabId/:tagId(\\d+)?",
  PlanSurveyQuestions = "/plan/surveys/:id/:workspace(survey-questions)",
  YourPlanDistrict = "/plan/:workspace(my-district)/:tabId?",
  YourPlanData = "/plan/:workspace(data)/:tabId?",
  YourPlanDomainData = "/plan/:workspace(data)/:tabId?/domains/:domainId?/",
  YourPlanNeeds = "/plan/:workspace(needs)/:tabId?",
  YourPlanGoals = "/plan/:workspace(goals)/:tabId?",
  YourPlanLearning = "/plan/:workspace(learning)/:tabId?",
  YourPlanDeletedData = "/plan/:workspace(deleted-data)",
}

export enum NonAuthRoutes {
  Login = "/login",
  ForgotPassword = "/restore-password",
  RestorePassword = "/restore-password/:code",
  Registration = "/registration",
  ConfirmInvitation = "/invite/accept/:code",
}

export enum AuthAndNonAuthRoutes {
  SurveysQuestions = "/surveys/questions/:hash/:school/(test)?",
}

export enum AdminRoutes {
  AdminTables = "/admin/tables/:tab",
  EditSurveyQuestions = "/admin/surveys/:id/edit",
  Districts = "/admin/districts-info/:adminTab",
  DistrictDetails = "/admin/districts/:districtId/details/:tabId",
  LocalStorageSettings = "/admin/settings",
}

const INTERVAL = 300000;

export const TEST_URL_ENDING = "test";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const currentUser = useSelector((s) => s.auth.authenticatedUser);
  const { surveyReportTaskId, surveyReportName } = useSelector((s) => s.surveyReports);

  const isSuperadmins = useRolesPermissions(userRoles.admins as UserRole[]);

  const loading = useSelector((s) => s.auth.loading.getAuthenticatedUser);

  const showSideBarMenu = useMemo(() => {
    if (!!currentUser) {
      return isSuperadmins;
    }
    return isSuperadmins && !loading;
  }, [currentUser, isSuperadmins, loading]);

  const intl = useIntl();

  const location = useLocation();

  const isTesting = useMemo(() => {
    return location.pathname.endsWith(TEST_URL_ENDING);
  }, [location]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    dispatch(getAuthenticatedUser.request());
    const interval = setInterval(() => {
      dispatch(getAuthenticatedUser.request());
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [isAuthenticated]);

  const isWelcomePage = useMemo(() => {
    return matchPath(location.pathname, AuthRoutes.AppWelcomePage);
  }, [location]);

  const isSurveyPage = useMemo(() => {
    return matchPath(location.pathname, AuthAndNonAuthRoutes.SurveysQuestions);
  }, [location]);


  useEffect(() => {
    if (surveyReportTaskId) {
      const PusherChannel = "private-report-download";
      const pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
        channelAuthorization: {
          endpoint: "/pusher/auth",
          transport: "ajax",
          params: {},
          headers: {},
        },
        userAuthentication: {
          endpoint: "/pusher/auth",
          transport: "ajax",
          headers: {
            "X-CSRF-Token": "some_csrf_token",
          },
        },
      });
      pusher.connection.bind("error", function (err: any) {
        if (err?.error?.data?.code === 4004) {
          console.log("Over limit!");
        }
      });
      const channel = pusher.subscribe(
        PusherChannel
      );
      channel.bind(
        "deployment_report_task_" + surveyReportTaskId,
        function (data: any) {
          dispatch(downloadCSVReportByTaskId.request({...data, name:surveyReportName}));
        }
      );
      return () => {
        pusher.disconnect();
      };
    }
  }, [surveyReportTaskId])

  return useMemo(
    () => (
      <>
        {isTesting && (
          <Navbar fixedToTop className="bg-red-600 z-50 shadow-none">
            <div className="flex justify-center text-3xl font-bold text-white">
              {intl.formatMessage({ id: "app.titles.test-mode" })}
            </div>
          </Navbar>
        )}
        {((isSuperadmins && !isWelcomePage) || isSurveyPage) && (
          <AppNavbar isTesting={isTesting} />
        )}
        <div className="flex flex-grow">
          {isAuthenticated && showSideBarMenu && !isWelcomePage && (
            <AppSideBarMenu />
          )}
          <Switch>
            <Route
              path={AuthAndNonAuthRoutes.SurveysQuestions}
              component={withBodyContainer(Survey)}
            />
            <UnauthenticatedOnlyRoute
              isSuperadmin={isSuperadmins}
              path={[
                NonAuthRoutes.Registration,
                NonAuthRoutes.ConfirmInvitation,
              ]}
              component={withBodyContainer(RegistrationForm)}
              exact
            />
            <UnauthenticatedOnlyRoute
              isSuperadmin={isSuperadmins}
              path={NonAuthRoutes.Login}
              component={withBodyContainer(LoginForm)}
            />
            <UnauthenticatedOnlyRoute
              isSuperadmin={isSuperadmins}
              path={NonAuthRoutes.ForgotPassword}
              component={withBodyContainer(ResetPasswordPage)}
              exact
            />
            <UnauthenticatedOnlyRoute
              isSuperadmin={isSuperadmins}
              path={NonAuthRoutes.RestorePassword}
              component={withBodyContainer(RestorePasswordPage)}
            />
            <AuthenticatedRoute
              path={AdminRoutes.Districts}
              component={withBodyContainer(Districts)}
              requiredRoles={userRoles.admins}
              exact
            />
            <AuthenticatedRoute
              path={AdminRoutes.DistrictDetails}
              component={withBodyContainer(DistrictDetails)}
              requiredRoles={userRoles.admins}
              exact
            />
            <AuthenticatedRoute
              path={AdminRoutes.AdminTables}
              component={withBodyContainer(AdminTables)}
              requiredRoles={userRoles.admins}
              exact
            />
            <AuthenticatedRoute
              path={AdminRoutes.EditSurveyQuestions}
              component={withBodyContainer(EditSurveyQuestions)}
              requiredRoles={userRoles.admins}
            />

            <AuthenticatedRoute
              path={[
                AuthRoutes.YourPlan,
                AuthRoutes.YourPlanTeam,
                AuthRoutes.YourPlanResources,
                AuthRoutes.YourPlanReports,
                AuthRoutes.PlanSurveyQuestions,
                AuthRoutes.YourPlanDistrict,
                AuthRoutes.YourPlanData,
                AuthRoutes.YourPlanDomainData,
                AuthRoutes.YourPlanNeeds,
                AuthRoutes.YourPlanGoals,
                AuthRoutes.YourPlanLearning,
                AuthRoutes.AppWelcomePage,
                AuthRoutes.YourPlanDeletedData,
              ]}
              component={PlanPage}
              requiredRoles={userRoles.all}
              exact
            />
            <AuthenticatedRoute
              path={AdminRoutes.LocalStorageSettings}
              component={withBodyContainer(AdminSettingsPage)}
            />
            <AuthenticatedRoute
              path={AuthRoutes.SurveyReport}
              component={withBodyContainer(SurveyReport)}
            />
            <Redirect
              to={
                !isSuperadmins
                  ? generatePath(AuthRoutes.YourPlan, {
                    workspace: "dashboard" as any,
                  } as any)
                  : generatePath(AdminRoutes.Districts, {
                    adminTab: DistrictTabs.DISTRICTS,
                  })
              }
            />
          </Switch>
        </div>
        <ConfirmDialog isOpen={false} />
        <EditProfileDialog />
        <ChangePasswordDialog />
      </>
    ),
    [
      isAuthenticated,
      isSuperadmins,
      showSideBarMenu,
      isTesting,
      isWelcomePage,
      isSurveyPage,
    ]
  );
};

export default Sentry.withProfiler(App);
// export default App;

function UnauthenticatedOnlyRoute({
  isSuperadmin,
  ...routeProps
}: { redirectTo?: string; isSuperadmin?: boolean } & RouteProps) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const authenticatedUserRole = useSelector(getCurrentUserRole);

  if (isAuthenticated && !!authenticatedUserRole) {
    if (routeProps.location?.state) {
      return <Redirect to={routeProps.location.state as string} />;
    }
    return (
      <Redirect
        to={
          !isSuperadmin
            ? generatePath(AuthRoutes.YourPlan, {
              workspace: "dashboard" as any,
            } as any)
            : generatePath(AdminRoutes.Districts, {
              adminTab: DistrictTabs.DISTRICTS,
            })
        }
      />
    );
  } else {
    return <Route {...routeProps} />;
  }
}

function AuthenticatedRoute({
  redirectTo = NonAuthRoutes.Login,
  requiredRoles = userRoles.all,
  ...routeProps
}: { redirectTo?: string; requiredRoles?: string[] } & RouteProps) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const authenticatedUserRole = useSelector(getCurrentUserRole);
  const userHasRequiredRole = requiredRoles.includes(
    String(authenticatedUserRole ?? UserRole.Principal)
  );

  if (isAuthenticated && userHasRequiredRole) {
    return (<>
      <NoInternetConnection>
        <Route {...routeProps} />
      </NoInternetConnection>
    </>)
  } else {
    return (
      <Redirect
        to={{ pathname: redirectTo, state: routeProps.location?.pathname }}
      />
    );
  }
}
