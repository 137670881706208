import { createReducer, RootAction } from "typesafe-actions";
import {
  CompletedSurvey,
  QuestionTag,
  Survey,
  SurveyPermissionsError,
  SurveyQuestion,
  SurveyQuestionAnswer,
  SurveyQuestionExcludeBaseModelType,
} from "../../types";
import * as actions from "./actions";
import _ from "lodash";
import { Locale } from "../UIState";

type ActionName =
  | "addSurvey"
  | "getSurveyQuestions"
  | "getSurvey"
  | "getSurveys"
  | "editSurveyQuestionsInSelectedSurvey"
  | "deleteSurvey"
  | "updateSurvey"
  | "getSurveyQuestionsByHash"
  | "getSurveyQuestionsByDomain"
  | "saveCompletedSurvey"
  | "saveSurveyQuestionAnswer"
  | "getQuestionTags"
  | "getQuestionTagById"
  | "addQuestionTag"
  | "updateQuestionTag"
  | "deleteQuestionTag";

export type SurveysState = {
  showSurveyUpsertDialog?: boolean;

  surveys: Survey[];
  selectedSurvey?: Survey;

  surveyQuestions: SurveyQuestionExcludeBaseModelType[];

  selectedSurveyQuestions?: SurveyQuestion[];

  questionTags: QuestionTag[];
  selectedQuestionTag?: QuestionTag;

  completedSurvey?: CompletedSurvey;

  surveyAnswers: SurveyQuestionAnswer[];

  surveyPermissionsError?: SurveyPermissionsError;
  surveyExpiredError?: string;

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: SurveysState = {
  surveys: [],
  surveyQuestions: [],
  questionTags: [],
  surveyAnswers: [],
  loading: {},
  errors: {},
};

export const surveysReducer = createReducer<SurveysState, RootAction>(
  initialState
)
  //get survey questions
  .handleAction(
    actions.getSurveyQuestions.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyQuestions: true,
      },
      errors: {
        ...state.errors,
        getSurveyQuestions: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyQuestions.success,
    (state, action): SurveysState => ({
      ...state,
      surveyQuestions: action.payload.map((item) => ({
        ...item,
        question_tags: item.question_tags?.map((tag: QuestionTag) => tag?.id),
      })),
      loading: {
        ...state.loading,
        getSurveyQuestions: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyQuestions.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyQuestions: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyQuestions: false,
      },
    })
  )
  // setSurveyQuestions
  .handleAction(
    actions.setSurveyQuestions,
    (state, action): SurveysState => ({
      ...state,
      surveyQuestions: action.payload,
    })
  )
  //get survey questions by Domain
  .handleAction(
    actions.getSurveyQuestionsByDomain.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyQuestionsByDomain: true,
      },
      errors: {
        ...state.errors,
        getSurveyQuestionsByDomain: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyQuestionsByDomain.success,
    (state, action): SurveysState => ({
      ...state,
      surveyQuestions: action.payload,
      loading: {
        ...state.loading,
        getSurveyQuestionsByDomain: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyQuestionsByDomain.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyQuestionsByDomain: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyQuestionsByDomain: false,
      },
    })
  )
  //get survey questions by hash
  .handleAction(
    actions.getSurveyQuestionsByHash.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyQuestionsByHash: true,
      },
      errors: {
        ...state.errors,
        getSurveyQuestionsByHash: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyQuestionsByHash.success,
    (state, action): SurveysState => {
      return {
        ...state,
        surveyPermissionsError: undefined,
        surveyExpiredError: undefined,
        completedSurvey: action.payload.payload,
        surveyAnswers: action.payload?.payload?.survey_questions?.map((sq) => {
          const maxIndex: number | null = _.chain(
            sq?.domain?.answers?.[action.payload.locale] ??
              sq?.domain?.answers?.[Locale.English]
          )
            .keys()
            .max()
            .toNumber()
            .value();
          return {
            question_id: sq.id,
            answer_index: action.payload?.payload?.survey_questions_completed?.find(
              (s) => s.question_id === sq.id
            )?.answer_index,
            max_answer_index: maxIndex == null ? 0 : maxIndex,
          };
        }),
        loading: {
          ...state.loading,
          getSurveyQuestionsByHash: false,
        },
      };
    }
  )
  .handleAction(
    actions.getSurveyQuestionsByHash.failure,
    (state, action): SurveysState => {
      const isPermissionError = !!(action.payload as SurveyPermissionsError)
        ?.demographic;
      return {
        ...state,
        surveyPermissionsError: isPermissionError
          ? (action.payload as SurveyPermissionsError)
          : undefined,
        surveyExpiredError: isPermissionError
          ? undefined
          : (action.payload as string),
        loading: {
          ...state.loading,
          getSurveyQuestionsByHash: false,
        },
      };
    }
  )
  //add survey
  .handleAction(
    actions.addSurvey.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        addSurvey: true,
      },
      errors: {
        ...state.errors,
        addSurvey: undefined,
      },
    })
  )
  .handleAction(
    actions.addSurvey.success,
    (state, action): SurveysState => ({
      ...state,
      surveys: [...state.surveys, action.payload],
      loading: {
        ...state.loading,
        addSurvey: false,
      },
    })
  )
  .handleAction(
    actions.addSurvey.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        addSurvey: action.payload,
      },
      loading: {
        ...state.loading,
        addSurvey: false,
      },
    })
  )
  //get surveys
  .handleAction(
    actions.getSurveys.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveys: true,
      },
      errors: {
        ...state.errors,
        getSurveys: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveys.success,
    (state, action): SurveysState => ({
      ...state,
      surveys: action.payload,
      loading: {
        ...state.loading,
        getSurveys: false,
      },
    })
  )
  .handleAction(
    actions.getSurveys.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveys: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveys: false,
      },
    })
  )
  //get surveys
  .handleAction(
    actions.getSurvey.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurvey: true,
      },
      errors: {
        ...state.errors,
        getSurvey: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurvey.success,
    (state, action): SurveysState => ({
      ...state,
      selectedSurvey: action.payload,
      loading: {
        ...state.loading,
        getSurvey: false,
      },
    })
  )
  .handleAction(
    actions.getSurvey.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurvey: action.payload,
      },
      loading: {
        ...state.loading,
        getSurvey: false,
      },
    })
  )
  // survey creation dialog
  .handleAction(
    actions.showSurveyUpsertDialog,
    (state, action): SurveysState => ({
      ...state,
      showSurveyUpsertDialog: true,
      selectedSurvey: action.payload || undefined,
    })
  )
  .handleAction(
    actions.hideSurveyUpsertDialog,
    (state, action): SurveysState => ({
      ...state,
      showSurveyUpsertDialog: false,
      // selectedSurvey: undefined,
    })
  )

  //edit survey questions in selected survey
  .handleAction(
    actions.editSurveyQuestionsInSelectedSurvey.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        editSurveyQuestionsInSelectedSurvey: true,
      },
      errors: {
        ...state.errors,
        editSurveyQuestionsInSelectedSurvey: undefined,
      },
    })
  )
  .handleAction(
    actions.editSurveyQuestionsInSelectedSurvey.success,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        editSurveyQuestionsInSelectedSurvey: false,
      },
    })
  )
  .handleAction(
    actions.editSurveyQuestionsInSelectedSurvey.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        editSurveyQuestionsInSelectedSurvey: action.payload,
      },
      loading: {
        ...state.loading,
        editSurveyQuestionsInSelectedSurvey: false,
      },
    })
  )
  //delete survey
  .handleAction(
    actions.deleteSurvey.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteSurvey: true,
      },
      errors: {
        ...state.errors,
        deleteSurvey: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteSurvey.success,
    (state, action): SurveysState => ({
      ...state,
      surveys: state.surveys.filter((survey) => survey.id !== action.payload),
      loading: {
        ...state.loading,
        deleteSurvey: false,
      },
    })
  )
  .handleAction(
    actions.deleteSurvey.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteSurvey: action.payload,
      },
      loading: {
        ...state.loading,
        deleteSurvey: false,
      },
    })
  )
  //update survey
  .handleAction(
    actions.updateSurvey.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        updateSurvey: true,
      },
      errors: {
        ...state.errors,
        updateSurvey: undefined,
      },
    })
  )
  .handleAction(
    actions.updateSurvey.success,
    (state, action): SurveysState => ({
      ...state,
      surveys: state.surveys.map((survey) =>
        survey.id === action.payload.id ? action.payload : survey
      ),
      loading: {
        ...state.loading,
        updateSurvey: false,
      },
    })
  )
  .handleAction(
    actions.updateSurvey.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        updateSurvey: action.payload,
      },
      loading: {
        ...state.loading,
        updateSurvey: false,
      },
    })
  )
  //save completed survey
  .handleAction(
    actions.saveCompletedSurvey.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        saveCompletedSurvey: true,
      },
      errors: {
        ...state.errors,
        saveCompletedSurvey: undefined,
      },
    })
  )
  .handleAction(
    actions.saveCompletedSurvey.success,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        saveCompletedSurvey: false,
      },
    })
  )
  .handleAction(
    actions.saveCompletedSurvey.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        saveCompletedSurvey: action.payload,
      },
      loading: {
        ...state.loading,
        saveCompletedSurvey: false,
      },
    })
  )
  // save survey question answer
  .handleAction(
    actions.saveSurveyQuestionAnswer.request,
    (state, action): SurveysState => ({
      ...state,
      surveyAnswers: state.surveyAnswers.map((sa) => {
        if (sa.question_id === action.payload.request?.question_id) {
          return action.payload.request;
        }
        return sa;
      }),
      loading: {
        ...state.loading,
        saveSurveyQuestionAnswer: true,
      },
      errors: {
        ...state.errors,
        saveSurveyQuestionAnswer: undefined,
      },
    })
  )
  .handleAction(
    actions.saveSurveyQuestionAnswer.success,
    (state, action): SurveysState => ({
      ...state,
      surveyAnswers: state.surveyAnswers.map((sa) => {
        if (sa.question_id === action.payload?.question_id) {
          return action.payload;
        }
        return sa;
      }),
      loading: {
        ...state.loading,
        saveSurveyQuestionAnswer: false,
      },
    })
  )
  .handleAction(
    actions.saveSurveyQuestionAnswer.failure,
    (state, action): SurveysState => ({
      ...state,
      surveyAnswers: state.surveyAnswers.map((sa) => {
        if (sa.question_id === action.payload.previousObject?.question_id) {
          return action.payload.previousObject;
        }
        return sa;
      }),
      errors: {
        ...state.errors,
        saveSurveyQuestionAnswer: action.payload.error,
      },
      loading: {
        ...state.loading,
        saveSurveyQuestionAnswer: false,
      },
    })
  )
  .handleAction(actions.resetSurveyCompletion, (state, action) => ({
    ...state,
    surveyAnswers: [],
    completedSurvey: undefined,
  }))
  .handleAction(actions.saveTestingSurveyQuestionAnswer, (state, action) => ({
    ...state,
    surveyAnswers: state.surveyAnswers.map((sa) => {
      if (sa.question_id === action.payload?.question_id) {
        return action.payload;
      }
      return sa;
    }),
  }))
  // get question tags
  .handleAction(
    actions.getQuestionTags.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        getQuestionTags: true,
      },
      errors: {
        ...state.errors,
        getQuestionTags: undefined,
      },
    })
  )
  .handleAction(
    actions.getQuestionTags.success,
    (state, action): SurveysState => ({
      ...state,
      questionTags: action.payload,
      loading: {
        ...state.loading,
        getQuestionTags: false,
      },
    })
  )
  .handleAction(
    actions.getQuestionTags.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        getQuestionTags: action.payload,
      },
      loading: {
        ...state.loading,
        getQuestionTags: false,
      },
    })
  )
  // get question tag by Id
  .handleAction(
    actions.getQuestionTagById.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        getQuestionTagById: true,
      },
      errors: {
        ...state.errors,
        getQuestionTagById: undefined,
      },
    })
  )
  .handleAction(
    actions.getQuestionTagById.success,
    (state, action): SurveysState => ({
      ...state,
      selectedQuestionTag: action.payload,
      loading: {
        ...state.loading,
        getQuestionTagById: false,
      },
    })
  )
  .handleAction(
    actions.getQuestionTagById.failure,
    (state, action): SurveysState => ({
      ...state,
      selectedQuestionTag: undefined,
      errors: {
        ...state.errors,
        getQuestionTagById: action.payload,
      },
      loading: {
        ...state.loading,
        getQuestionTagById: false,
      },
    })
  )
  // add question tag
  .handleAction(
    actions.addQuestionTag.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        addQuestionTag: true,
      },
      errors: {
        ...state.errors,
        addQuestionTag: undefined,
      },
    })
  )
  .handleAction(
    actions.addQuestionTag.success,
    (state, action): SurveysState => ({
      ...state,
      questionTags: [action.payload, ...state.questionTags],
      loading: {
        ...state.loading,
        addQuestionTag: false,
      },
    })
  )
  .handleAction(
    actions.addQuestionTag.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        addQuestionTag: action.payload,
      },
      loading: {
        ...state.loading,
        addQuestionTag: false,
      },
    })
  )
  // update question tag
  .handleAction(
    actions.updateQuestionTag.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        updateQuestionTag: true,
      },
      errors: {
        ...state.errors,
        updateQuestionTag: undefined,
      },
    })
  )
  .handleAction(
    actions.updateQuestionTag.success,
    (state, action): SurveysState => ({
      ...state,
      questionTags: state.questionTags.map((qt) =>
        qt.id === action.payload.id ? action.payload : qt
      ),
      loading: {
        ...state.loading,
        updateQuestionTag: false,
      },
    })
  )
  .handleAction(
    actions.updateQuestionTag.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        updateQuestionTag: action.payload,
      },
      loading: {
        ...state.loading,
        updateQuestionTag: false,
      },
    })
  )
  // delete question tag
  .handleAction(
    actions.deleteQuestionTag.request,
    (state, action): SurveysState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteQuestionTag: true,
      },
      errors: {
        ...state.errors,
        deleteQuestionTag: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteQuestionTag.success,
    (state, action): SurveysState => ({
      ...state,
      questionTags: state.questionTags.filter((qt) => qt.id !== action.payload),
      loading: {
        ...state.loading,
        deleteQuestionTag: false,
      },
    })
  )
  .handleAction(
    actions.deleteQuestionTag.failure,
    (state, action): SurveysState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteQuestionTag: action.payload,
      },
      loading: {
        ...state.loading,
        deleteQuestionTag: false,
      },
    })
  )
  .handleAction(
    actions.setSelectedSurvey,
    (state, action): SurveysState => ({
      ...state,
      selectedSurvey: action.payload,
    })
  );
