import React, { FunctionComponent } from "react";
import ItemMark from "../table/survey-report-table-row/ItemMark";
import useDatasetMark from "./useDatasetMark";
import { SurveyDemographic } from "../../../types";

type OwnProps = {
  domainId: number;
  demographic?: SurveyDemographic;
  className?: string;
  meanScore?: number;
};

type Props = OwnProps;

const ReportDomainFlagIcon: FunctionComponent<Props> = (props) => {
  const { domainId, demographic, meanScore, className } = props;

  const {
    flagged,
    onClick,
    onDiscard,
    bookmarkType,
    supportsBookmarks,
  } = useDatasetMark(
    {
      domainId: domainId,
      is_grouped: false,
      demographic: demographic,
    },
    meanScore
  );

  if (!supportsBookmarks) {
    return null;
  }

  return (
    <ItemMark
      className={className}
      flagged={flagged}
      bookmarkType={bookmarkType}
      onClick={onClick}
      onDiscard={onDiscard}
    />
  );
};

export default ReportDomainFlagIcon;
