import { createAsyncAction } from "typesafe-actions";
import { NotificationSettings, Notification } from "../../types";
import {
  MarkAsReadNotifications,
  UpdateNotificationSettingsRequest,
} from "../../api/notifications/types";

export const getNotificationSettings = createAsyncAction(
  "@notifications/GET_NOTIFICATION_SETTINGS_REQUEST",
  "@notifications/GET_NOTIFICATION_SETTINGS_SUCCESS",
  "@notifications/GET_NOTIFICATION_SETTINGS_FAILURE"
)<void, NotificationSettings[], Error>();

export const updateNotificationSettings = createAsyncAction(
  "@notifications/UPDATE_NOTIFICATION_SETTINGS_REQUEST",
  "@notifications/UPDATE_NOTIFICATION_SETTINGS_SUCCESS",
  "@notifications/UPDATE_NOTIFICATION_SETTINGS_FAILURE"
)<UpdateNotificationSettingsRequest, NotificationSettings, Error>();

export const getNotifications = createAsyncAction(
  "@notifications/GET_NOTIFICATIONS_REQUEST",
  "@notifications/GET_NOTIFICATIONS_SUCCESS",
  "@notifications/GET_NOTIFICATIONS_FAILURE"
)<void, Notification[], Error>();

export const markNotificationsAsRead = createAsyncAction(
  "@notifications/MARK_NOTIFICATIONS_AS_READ_REQUEST",
  "@notifications/MARK_NOTIFICATIONS_AS_READ_SUCCESS",
  "@notifications/MARK_NOTIFICATIONS_AS_READ_FAILURE"
)<MarkAsReadNotifications, Notification[], Error>();
