import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { getPlanStatus } from "../../../store/plans/actions";
import {
  LockType,
  PlanStep,
  PlanWorkspaces,
  UserNotesType,
  WorkspaceAvailabilityDictionary,
} from "../../../types";

import establishTeamSvg from "../../../static/icons/svg/establish_team.svg";
import spaceShepSvg from "../../../static/icons/svg/space_shep.svg";
import collectDataSvg from "../../../static/icons/svg/collect_data.svg";
import analyzeDataSvg from "../../../static/icons/svg/analyze_data.svg";
import setGoalsSvg from "../../../static/icons/svg/set_goals.svg";
import trackGoalsSvg from "../../../static/icons/svg/track_goals.svg";
import trackStrategySvg from "../../../static/icons/svg/track_strategy.svg";
import alignStrategiesSvg from "../../../static/icons/svg/align_strategies.svg";
import DashboardWidgetWrapper from "./DashboardWidgetWrapper";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../App";

type OwnProps = {
  wsAvailableDict: WorkspaceAvailabilityDictionary;
};

type Props = OwnProps;

export type DashboardWidgetPart = {
  name?: string;
  steps: DashboardWidgetStep[];
  callbacks?: {
    onLeftIconClick: Function;
    onRightIconClick: Function;
  };
};

export type DashboardWidgetStep = {
  src: string;
  text?: string;
  active?: boolean;
  completed?: boolean;
  onClick?: Function;
  iconSize?: number;
  lockType?: LockType;
};

const SHOW_DOTS_MIN_WIDTH = 960;
const WRAP_PLANNING_BREAKPOINT = 640;

const DashboardWidget: FunctionComponent<Props> = ({ wsAvailableDict }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showLeftPart, setShowLeftPart] = useState<boolean>(true);
  const [showRightPart, setShowRightPart] = useState<boolean>(true);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const planStatus = useSelector((s) => s.plans.planStatus);
  useEffect(() => {
    if (activePlanId) {
      dispatch(getPlanStatus.request(activePlanId));
    }
  }, [activePlanId]);

  const loading = useSelector((s) => s.plans.loading.getPlanStatus);
  const error = useSelector((s) => s.plans.errors.getPlanStatus);
  useLoading({ loading, error });

  const planningPartCompleted = useMemo(() => {
    const planningStatuses = _.chain(planStatus)
      .pick([
        PlanStep.EstablishTeam,
        PlanStep.CollectData,
        PlanStep.AnalyzeData,
        PlanStep.SetGoals,
        PlanStep.AlignStrategies,
      ])
      .values()
      .value();

    return !!planningStatuses.length && planningStatuses.every((a) => a);
  }, [planStatus]);

  useEffect(() => {
    setShowLeftPart(true);
    setShowRightPart(true);

    // if (planningPartCompleted) {
    //   setShowLeftPart(false);
    //   setShowRightPart(true);
    // } else {
    //   setShowLeftPart(true);
    //   setShowRightPart(false);
    // }
    // }, [planningPartCompleted]);
  }, []);

  const itemsGroups: DashboardWidgetPart[] = useMemo(() => {
    return [
      {
        name: intl.formatMessage({
          id: "app.titles.planning",
        }),
        steps: [
          {
            src: establishTeamSvg,
            text: intl.formatMessage({ id: "app.plan.develop-team" }),
            active: true,
            completed: planStatus?.establish_team,
            onClick: () => history.push(`/plan/${PlanWorkspaces.Team}`),
          },
          {
            src: collectDataSvg,
            text: intl.formatMessage({ id: "app.collect-data.title" }),
            active: planStatus?.establish_team,
            completed: planStatus?.collect_data,
            lockType: wsAvailableDict[PlanWorkspaces.Data]?.available
              ? undefined
              : wsAvailableDict[PlanWorkspaces.Data]?.lockType,
            onClick: () => history.push(`/plan/${PlanWorkspaces.Data}`),
          },
          {
            src: analyzeDataSvg,
            text: intl.formatMessage({ id: "app.titles.analyze-data" }),
            active: planStatus?.collect_data,
            completed: planStatus?.analyze_data,
            lockType: wsAvailableDict[PlanWorkspaces.Needs]?.available
              ? undefined
              : wsAvailableDict[PlanWorkspaces.Needs]?.lockType,
            onClick: () => history.push(`/plan/${PlanWorkspaces.Needs}`),
          },
          {
            src: setGoalsSvg,
            text: intl.formatMessage({ id: "app.titles.set-goals" }),
            active: planStatus?.analyze_data,
            completed: planStatus?.set_goals,
            lockType: wsAvailableDict[PlanWorkspaces.Goals]?.available
              ? undefined
              : wsAvailableDict[PlanWorkspaces.Goals]?.lockType,
            onClick: () => history.push(`/plan/${PlanWorkspaces.Goals}`),
          },
          {
            src: alignStrategiesSvg,
            text: intl.formatMessage({ id: "app.titles.align-strategies" }),
            active: planStatus?.set_goals,
            completed: planStatus?.align_strategies,
            lockType: wsAvailableDict[PlanWorkspaces.Goals]?.available
              ? undefined
              : wsAvailableDict[PlanWorkspaces.Goals]?.lockType,
            onClick: () =>
              history.push(
                generatePath(AuthRoutes.YourPlanResources, {
                  workspace: "resources",
                  tabId: UserNotesType.SNA_RESOURCES,
                } as any)
              ),
          },
        ],
      },
      {
        steps: [
          {
            src: spaceShepSvg,
            active: planningPartCompleted,
            iconSize: 96,
          },
        ],
        callbacks: {
          onLeftIconClick: () => setShowLeftPart((flag) => !flag),
          onRightIconClick: () => setShowRightPart((flag) => !flag),
        },
      },
      {
        name: intl.formatMessage({
          id: "app.titles.progress-monitoring",
        }),
        steps: [
          {
            src: establishTeamSvg,
            text: intl.formatMessage({ id: "app.titles.maintain-team" }),
            completed: planStatus?.maintain_team,
            onClick: () => history.push(`/plan/${PlanWorkspaces.Team}`),
          },
          {
            src: trackGoalsSvg,
            text: intl.formatMessage({ id: "app.collect-data.title" }),
            completed: planStatus?.monitoring_collect_data,
            onClick: () => history.push(`/plan/${PlanWorkspaces.Data}`),
            lockType: wsAvailableDict[PlanWorkspaces.Data]?.available
              ? undefined
              : wsAvailableDict[PlanWorkspaces.Data]?.lockType,
          },
          {
            src: trackStrategySvg,
            text: intl.formatMessage({ id: "app.titles.track-strategies" }),
            completed: planStatus?.track_strategies,
            onClick: () => history.push(`/plan/${PlanWorkspaces.Resources}`),
            lockType: wsAvailableDict[PlanWorkspaces.Goals]?.available
              ? undefined
              : wsAvailableDict[PlanWorkspaces.Goals]?.lockType,
          },
        ],
      },
    ].filter((group, index) => {
      switch (index) {
        case 0:
          return showLeftPart;
        case 2:
          return showRightPart;
        default:
          return true;
      }
    });
  }, [
    showLeftPart,
    showRightPart,
    planStatus,
    planningPartCompleted,
    wsAvailableDict,
  ]);

  const [showDots, setShowDots] = useState<boolean>(true);
  const [wrapPlanningIcon, setWrapPlanningIcon] = useState<boolean>(false);

  const widgetRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(([widget]) => {
      setShowDots(widget.target.clientWidth > SHOW_DOTS_MIN_WIDTH);
      setWrapPlanningIcon(widget.target.clientWidth < WRAP_PLANNING_BREAKPOINT);
    });
    if (widgetRef.current) {
      resizeObserver.observe(widgetRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [widgetRef.current]);

  if (loading) {
    return <Spinner intent="primary" className="mb-4" />;
  }

  return (
    <div className="plan-setup-widget" data-tour="plan-setup-widget">
      <div className="flex-1 min-w-0 p-2">
        <p className="text-center text-2xl mb-2 text-blue-600 font-bold">
          {intl.formatMessage({ id: "app.plan.dashboard-widget.title" })}
        </p>

        <div className="plan-setup-widget__steps" ref={widgetRef}>
          <DashboardWidgetWrapper
            items={itemsGroups}
            showDots={showDots}
            wrapPlanningIcon={wrapPlanningIcon}
            showLeftPart={showLeftPart}
            showRightPart={showRightPart}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardWidget;
