/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo, useEffect, useState } from 'react'
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import './CoreComponents.scss';
import { Strategy } from '../../../../types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { isDistrictPlan } from '../../../../store/plans/selectors';
import { getDistrictById } from '../../../../store/districts/actions';

type OwnProps = {
    strategy: Strategy;
    isLibrary?: boolean;
    school?: string;
};

type Props = OwnProps;

const CoreComponent: FunctionComponent<Props> = ({ strategy, isLibrary,school }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const activePlanId = useSelector((s) => s.plans.activePlan?.id);
    const activePlanSchool = useSelector((s) => s.plans.activePlan?.school?.name);
    const isActiveDistrictPlan = useSelector(isDistrictPlan);
    const strategyName = useMemo(() => strategy.name, [strategy]);

    const filterStrategyLookFors = (strategy: Strategy, isLibrary: any, activePlanId: any, isActiveDistrictPlan: any) => {
        return strategy.look_for_items.filter((item) => {
            if (isLibrary) {
                return item.original || item.plan_id === activePlanId || (isActiveDistrictPlan && !!strategy.school) || (!item.school_item || (!item.original && item.plan_id === activePlanId));
            } else {
                return item.plan_id === activePlanId || item.original || (!item.school_item || (!item.original && item.plan_id === activePlanId));

            }
        });
    };

    const strategyLookFors = useMemo(() => {
        return filterStrategyLookFors(strategy, isLibrary, activePlanId, isActiveDistrictPlan);
    }, [activePlanId, isActiveDistrictPlan, isLibrary, strategy]);

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const districtName = useSelector((s) => s.districts.selectedDistrict?.name);

    const districtId = strategy.district;

    useEffect(() => {
        if (districtId) {
            dispatch(getDistrictById.request(Number(districtId)));
        }
    }, [dispatch, districtId]);

    return (
        <>
            <a onClick={openDialog}>
                {intl.formatMessage({ id: "app.titles.look-fors-with-count" }, { total: strategyLookFors.length })}
            </a>

            <Dialog
                className="corecomponent-modal"
                open={isDialogOpen}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="corecomponent-modal-heading">
                    <h3>{intl.formatMessage({ id: "app.titles.look-fors" })}</h3>
                    <button onClick={closeDialog}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>

                <div className="corecomponent-modal__content">
                    <h4>{strategyName}</h4>
                    <ul>
                    {strategyLookFors.map(({ id, text,original,school_item }) => (
                           <div className="strategies-content__list-head p-0 m-0 bg-white border-white">
                            <li key={id}>{text}
                                    {!original ?
                                        <h3 className='d-inline'>
                                            {
                                                strategy.district && !school_item ?
                                                    <span className="custom-strategy-district">{intl.formatMessage({ id: "app.custom-strategy" }, { school: districtName })}</span> :
                           strategy.school?
                           <span>
                            <b> {intl.formatMessage({ id: "app.custom-strategy" }, { school: school })}</b>
                           </span>
                           :
                           <span>
                           <b> {intl.formatMessage({ id: "app.custom-strategy" }, { school: activePlanSchool })}</b>
                          </span>
                         }
                         </h3>:<></>}
                         </li>
                         </div>
                        ))}
                    </ul>
                </div>
            </Dialog>
        </>
    )
}

export default CoreComponent
