import React from "react";
import { useIntl } from "react-intl";
import { Button, Classes, Dialog, Icon } from "@blueprintjs/core";
import { SurveyDemographic } from "../../../../types";
import SurveyReportTableWrapper from "../SurveyReportTableWrapper";
import { hideBookmarkDialog } from "../../../../store/survey-reports/actions";
import { useSelector, useDispatch } from "react-redux";

type OwnProps = {
  schoolId: number | undefined;
  surveyDeploymentId: number | undefined;
  domainId: number | undefined;
  tabId: string | undefined;
  demographic: SurveyDemographic;
  isReportInsights?: boolean;
};

type Props = OwnProps;

const ManageBookmarkDialog: React.FC<Props> = (props: Props) => {
  const {
    schoolId,
    surveyDeploymentId,
    domainId,
    tabId,
    demographic,
    isReportInsights,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const showBookmarkDialog = useSelector(
    (s) => s.surveyReports.showBookmarkDialog
  );
  const handleBookmarkDialogClose = () => {
    dispatch(hideBookmarkDialog());
  };

  return (
    <Dialog
      isOpen={showBookmarkDialog}
      onClose={handleBookmarkDialogClose}
      icon={<Icon icon={"bookmark"} iconSize={24} />}
      title={intl.formatMessage({
        id: "app.surveys.reports.review-data.bookmark.dialog.title",
      })}
      style={{ width: "90rem" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <SurveyReportTableWrapper
          demographic={demographic}
          isReportInsights={isReportInsights}
          surveyDeploymentId={surveyDeploymentId}
          domainId={domainId}
          schoolId={schoolId}
          tabId={tabId}
          manageBookmarkDialog={true}
        />
      </div>
    </Dialog>
  );
};

export default ManageBookmarkDialog;
