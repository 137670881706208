import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const GroupingStepContent: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <>
      {intl.formatMessage({
        id: "app.survey-report.guide.filter-grouping",
      })}
    </>
  );
};

export default GroupingStepContent;
