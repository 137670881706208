import { DatasetMark } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import {
  DiscardDatasetMarkRequest,
  FlagDatasetMarkRequest,
  GetDatasetMarkRequest,
  UpdateDatasetMarkRequest,
  UpsertDatasetWithDatasetMarkRequest,
} from "./types";
import { generateQueryString } from "../../helpers/stringUtils";

export const getDatasetMarks = ({
  schoolId,
  ...request
}: GetDatasetMarkRequest): Promise<DatasetMark[]> => {
  let url = `${API_ROOT}/schools/${schoolId}/datasetmarks/`;

  if (request) {
    const queryStr = generateQueryString(request);

    if (queryStr.length && request.isFiltered) {
      url += "filters/?" + queryStr;
    }
    else if (queryStr.length && !request.isFiltered) {
      url += "?" + queryStr;
    }
  }

  return Axios.get(url);
};

export const flagDatasetMark = ({
  schoolId,
  datasetId,
  bookmarkType,
  dataPoint,
}: FlagDatasetMarkRequest): Promise<DatasetMark> => {
  return Axios.post(`${API_ROOT}/schools/${schoolId}/datasetmarks/`, {
    dataset: datasetId,
    bookmark_type: bookmarkType,
    data_point: dataPoint,
  });
};

export const updateDatasetMark = ({
  schoolId,
  datasetMarkId,
  bookmarkType,
}: Omit<UpdateDatasetMarkRequest, "onComplete">): Promise<DatasetMark> => {
  return Axios.patch(
    `${API_ROOT}/schools/${schoolId}/datasetmarks/${datasetMarkId}/`,
    { bookmark_type: bookmarkType }
  );
};

export const upsertDatasetWithDatasetMark = ({
  surveyId,
  planId,
  body,
}: UpsertDatasetWithDatasetMarkRequest): Promise<DatasetMark> => {
  return Axios.post(
    `${API_ROOT}/surveys/${surveyId}/plans/${planId}/datasets/`,
    body
  );
};

export const discardDatasetMark = ({
  schoolId,
  datasetMarkId,
}: Omit<DiscardDatasetMarkRequest, "onComplete">): Promise<void> => {
  return Axios.delete(
    `${API_ROOT}/schools/${schoolId}/datasetmarks/${datasetMarkId}/`
  );
};
