import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import ActionAuditLogTable from "./ActionAuditLogTable";
import { useDispatch, useSelector } from "react-redux";
import { getUserActionAudit } from "../../../store/users/actions";
import { UserActionAudit } from "../../../types";
type OwnProps = {};

type Props = OwnProps;

const ActionAuditLog: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const auditActionData = useSelector((s) => s.users.auditActionData);
  const loading = useSelector((s) => s.users.loading.getUserActionAudit);

  const [customPageSize, setCustomPageSize] = useState<number>(10);
  const [customPageIndex, setCustomPageIndex] = useState<number>(0);

  const customSetPageSize = (page: number) => {
    setCustomPageSize(page);
  };

  const customGoToPage = (index: number) => {
    setCustomPageIndex(index);
  };

  const customPageCount = useMemo(() => {
    if (auditActionData?.totalcount) {
      if (
        Math.ceil(auditActionData?.totalcount / customPageSize) <
        1 + customPageIndex
      ) {
        setCustomPageIndex(0);
      }
      return Math.ceil(auditActionData?.totalcount / customPageSize);
    }
    return 0;
  }, [customPageSize, auditActionData]);

  useEffect(() => {
    dispatch(
      getUserActionAudit.request({
        page_no: 1 + customPageIndex,
        page_size: customPageSize,
      })
    );
  }, [customPageIndex, customPageSize]);

  const auditActionLogData = useMemo(() => {
    if (auditActionData?.list) {
      const result: UserActionAudit[] = auditActionData?.list?.map(
        (et: any) => {
          return {
            id: et.id,
            created_at: et.created_at,
            district: et.district.name,
            action_type: et.action_type,
            user: et.user,
            targets: et.targets,
            school:et.school,
            role: et.role,
            updated_at: et.updated_at,
            school_in_which_action_done:et.school_in_which_action_done
          };
        }
      );
      return result;
    } else return [];
  }, [auditActionData]);

  if (loading) {
    return <Spinner intent="primary" className="mt-4" />;
  }

  if (!auditActionLogData.length) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({
          id: "app.non-ideal-state.action-audit-log",
        })}
      />
    );
  }

  return (
    <ActionAuditLogTable
      actionAudit={auditActionLogData}
      customSetPageSize={customSetPageSize}
      customPageCount={customPageCount}
      customPageSize={customPageSize}
      customPageIndex={customPageIndex}
      customGoToPage={customGoToPage}
    />
  );
};

export default ActionAuditLog;
