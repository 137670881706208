import React, { FunctionComponent } from "react";
import { Button, Card } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
} from "../../../../../types";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  hideUpsertResourceDialog,
  showUpsertResourceDialog,
} from "../../../../../store/resources/actions";
import UpsertResourceDialog from "../../../resources/modify-resource-dialog/UpsertResourceDialog";
import AddResourcesButtons from "../../../resources/AddResourcesButtons";
import ResourcesList from "../../../resources/ResourcesList";

type OwnProps = {
  strategyResources: AttachedResource[];
  setStrategyResources: (resources: AttachedResource[]) => void;
  attachedToType: AttachedToType;
  forUserNotes?: boolean;
  hideUrl?: boolean;
  onHideTabClick?: () => void;
};

type Props = OwnProps;

const ResourcesTab: FunctionComponent<Props> = (props) => {
  const {
    strategyResources,
    setStrategyResources,
    attachedToType,
    forUserNotes = true,
    hideUrl,
    onHideTabClick,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleUpsertResourceDialogShow = (
    resource: Partial<AttachedResource> & Pick<AttachedResource, "type">
  ) => {
    if (forUserNotes && resource.type !== AttachedResourceType.Link) {
      const pResource = {
        ...resource,
        attached_to_type: attachedToType,
      } as Partial<AttachedResource> &
        Pick<
          AttachedResource,
          "type" | "title" | "description" | "attached_to_type"
        >;
      handleUpsertResourceInfoDialogSave(pResource);
    } else {
      dispatch(
        showUpsertResourceDialog({
          resource: {
            ...resource,
            attached_to_type: attachedToType,
          },
          onSave: handleUpsertResourceInfoDialogSave,
          onClose: handleUpsertResourceInfoDialogHide,
        })
      );
    }
  };

  const handleUpsertResourceInfoDialogHide = (
    event: React.SyntheticEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    dispatch(hideUpsertResourceDialog());
  };

  const handleResourceEdit = (resource: AttachedResource) => () => {
    handleUpsertResourceDialogShow(resource);
  };

  const handleResourceRemove = (deletedResourceId: number) => () => {
    setStrategyResources(
      strategyResources.filter((r) => r.id !== deletedResourceId)
    );
  };

  const handleUpsertResourceInfoDialogSave = (
    pResource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => {
    let resource: AttachedResource;
    if (pResource.id) {
      setStrategyResources(
        strategyResources.map((res) =>
          res.id == pResource.id ? { ...res, ...pResource } : res
        )
      );
    } else {
      if (pResource.type === AttachedResourceType.Link) {
        resource = {
          ...pResource,
          id: -1 * +_.uniqueId(),
          created_at: "",
          updated_at: "",
        };
      } else {
        resource = {
          ...pResource,
          id: -1 * +_.uniqueId(),
          created_at: "",
          updated_at: "",
        };
      }

      setStrategyResources([...strategyResources, resource]);
    }
    dispatch(hideUpsertResourceDialog());
  };

  return (
    <Card className="flex flex-col items-center relative">
      {onHideTabClick ? (
        <div className="absolute top-0 right-0">
          <Button icon="cross" minimal onClick={onHideTabClick} />
        </div>
      ) : null}
      <div className="font-bold text-xl mb-2">
        {intl.formatMessage({ id: "app.resources.add-resources.title" })}
      </div>
      <div className="text-center my-2">
        {intl.formatMessage({ id: "app.resources.add-resources.tip" })}
      </div>

      {!!strategyResources.length && (
        <>
          <hr className="my-2 w-full" />

          <ResourcesList
            resources={strategyResources}
            onResourceEdit={handleResourceEdit}
            onResourceRemove={handleResourceRemove}
          />
        </>
      )}

      <hr className="my-2 w-full" />

      <AddResourcesButtons
        onClick={handleUpsertResourceDialogShow}
        attachedToType={attachedToType}
        hideUrl={hideUrl}
      />

      <UpsertResourceDialog />
    </Card>
  );
};

export default ResourcesTab;
