import { PlanStatus } from "./../../types/index";
import Axios from "axios";
import { API_ROOT } from "../config";
import { Plan } from "../../types";

export const getPlans = (): Promise<Plan[]> => {
  return Axios.get(`${API_ROOT}/plans/`);
};
export const addPlan = (): Promise<Plan> => {
  return Axios.post(`${API_ROOT}/plans/`);
};
export const getPlanStatus = (planId: number): Promise<PlanStatus> => {
  return Axios.get(`${API_ROOT}/plans/${planId}/status/`);
};
