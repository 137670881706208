import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Stepper from "./Stepper";
import { Button, Icon, Spinner } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import {
  saveCompletedSurvey,
  saveSurveyQuestionAnswer,
  saveTestingSurveyQuestionAnswer,
} from "../../../store/surveys/actions";
import { useIntl } from "react-intl";
import _ from "lodash";
import { CompletedDomain, SurveyQuestion, Timer } from "../../../types";
import { useParams } from "react-router";
import RespondentInfoForm from "./RespondentInfoForm";
import { CompletedSurveyRequest } from "../../../api/surveys/types";
import SurveyQuestionCard from "../cards/SurveyQuestionCard";
import {
  hideConfirmDialog,
  hideLanguageSelector,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import moment from "moment";
import { useWindowUnload } from "../../../helpers/hooks/useWindowUnload";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { Locale, LocaleEnglishValues } from "../../../store/UIState";
import {
  getLocalizedInstruction,
  getLocalizedStatement,
} from "../../utils/utils";
import {
  getNumberFromSessionStorage,
  getStringFromSessionStorage,
  SessionStorageKeys,
} from "../../../constants/session-storage";
import { AppToaster } from "../../../helpers/toaster";
import SurveyTitle from "../../utils/SurveyTitle";
import SurveyQuestionCardHeader from "./SurveyQuestionCardHeader";

type OwnProps = {
  isTesting: boolean;
  surveyTimer: Timer[];
  onSurveyFinished: (isCompletedSurvey: boolean) => void;
  onUpdateTimer: (timer: Timer) => void;
  demographic?: string;
  showLanguageSelector?: boolean;
};

type Props = OwnProps;

type RouterParams = {
  hash: string;
  school: string;
};

export type SurveyFormRefs = {
  separators: { sqId: number; ref: any }[];
  leadIns: { sqId: number; ref: any }[];
};

const SurveyForm: FunctionComponent<Props> = (props) => {
  const {
    isTesting,
    onSurveyFinished,
    demographic,
    onUpdateTimer,
    surveyTimer,
    showLanguageSelector,
  } = props;

  const intl = useIntl();

  const locale = useSelector((s) => s.UIState.surveyLocale);

  const { hash, school } = useParams<RouterParams>();

  const dispatch = useDispatch();

  const handleCompletedSurveySubmitSuccess = () => {
    onSurveyFinished(true);
  };

  const handleCompletedSurveySubmitError = () => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.surveys-completions.survey-not-saved",
      }),
      icon: "error",
      intent: "danger",
      timeout: 10000,
    });
  };

  const completedSurvey = useSelector((s) => s.surveys.completedSurvey);

  const loading = useSelector((s) => s.surveys.loading.saveCompletedSurvey);
  const error = useSelector((s) => s.surveys.errors.saveCompletedSurvey);
  useLoading({
    loading: loading,
    error: error,
    onSuccess: handleCompletedSurveySubmitSuccess,
    onError: handleCompletedSurveySubmitError,
    notShowDefaultToaster: true,
  });

  const answers = useSelector((s) => s.surveys.surveyAnswers);

  const showDemographicData = useMemo(() => {
    return (
      completedSurvey?.collected_demographic_data?.demographic_data?.length !==
      0
    );
  }, [completedSurvey]);

  const [
    surveyCompletion,
    setSurveyCompletion,
  ] = useState<CompletedSurveyRequest>({
    grade: undefined,
    language: undefined,
    gender: [],
    race: [],
    direct_instruction: undefined,
    remote_learning: undefined,
    answers: [],
    survey_time: [],
  });
  useEffect(() => {
    setSurveyCompletion({
      ...surveyCompletion,
      answers: answers,
    });
  }, [answers]);

  const surveyQuestionsPages = useMemo(() => {
    if (completedSurvey?.survey_questions?.length) {
      return _.mapValues(
        _.groupBy(completedSurvey?.survey_questions, (sq) => sq.page),
        (v) => _.sortBy(v, "order")
      );
    }
    return {};
  }, [completedSurvey]);

  const [selectedPageNotAnsweredQuestions, setNotAnsweredQuestions] = useState<
    number[]
  >([]);

  const [selectedPage, setSelectedPage] = useState<number>(
    getNumberFromSessionStorage(SessionStorageKeys.SurveyCurrentPage)
  );

  const direction = useMemo(() => {
    return `${intl.formatMessage({
      id: "app.language.direction",
    })}`;
  }, []);

  useEffect(() => {
    if (selectedPage === undefined) {
      const keys = Object.keys(surveyQuestionsPages);
      setSelectedPage(Number(keys[0] ?? 0));
    }
  }, [surveyQuestionsPages]);

  const timer = useMemo<Timer>(
    () => ({
      page: selectedPage + 1,
      time_start: moment().format(),
    }),
    [selectedPage]
  );

  const setEndDateToTimer = () => {
    onUpdateTimer({ ...timer, time_end: moment().format() });
  };
  useWindowUnload({ onBeforeUnload: setEndDateToTimer });

  const totalPages: number = useMemo(() => {
    const keys = Object.keys(surveyQuestionsPages);
    return (showDemographicData ? keys.length : keys.length - 1) ?? 0;
  }, [surveyQuestionsPages, showDemographicData]);

  const handleFormSubmit = () => {
    setEndDateToTimer();
    if (isTesting) {
      handleCompletedSurveySubmitSuccess();
    } else {
      dispatch(
        saveCompletedSurvey.request({
          hash: hash,
          completedSurvey: {
            ...surveyCompletion,
            survey_time: [
              ...surveyTimer,
              { ...timer, time_end: moment().format() },
            ],
            language: LocaleEnglishValues[locale],
          },
          school: school,
          sessionHash:
            getStringFromSessionStorage(SessionStorageKeys.SurveySessionHash) ??
            "",
        })
      );
    }
  };

  const isAnswerActive = useCallback(
    (
      surveyQuestion: SurveyQuestion<number, CompletedDomain>,
      index: number
    ) => {
      return (
        answers.find((a) => a.question_id === surveyQuestion.id)
          ?.answer_index === index
      );
    },
    [answers]
  );

  const handleCompletedSurveyChange = (
    updatedCompletedSurvey: CompletedSurveyRequest
  ) => {
    setSurveyCompletion(updatedCompletedSurvey);
  };

  const handlePageChangeConfirm = (page: number) => {
    setEndDateToTimer();
    sessionStorage.setItem(
      SessionStorageKeys.SurveyCurrentPage,
      JSON.stringify(page)
    );
    setSelectedPage(page);

    if (!("scrollBehavior" in document.documentElement.style)) {
      window.scrollTo(0, 0);
    } else {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const handlePageChangeCancel = (notAnsweredQuestionIds: number[]) => {
    dispatch(hideConfirmDialog());
    setNotAnsweredQuestions(notAnsweredQuestionIds);
  };

  const handlePageChange = (page: number) => {
    const notAnsweredQuestionIds = getCurrentPageNotAnsweredQuestionIds();
    if (notAnsweredQuestionIds?.length > 0) {
      dispatch(
        showConfirmDialog({
          show: true,
          icon: "info-sign",
          intent: "warning",
          cancelButtonText: intl.formatMessage({
            id: "app.confirmation-dialogs.answer-questions",
          }),
          confirmButtonText: intl.formatMessage({
            id: "app.confirmation-dialogs.continue-without-answering",
          }),
          text: intl.formatMessage({
            id: "app.confirmation-dialogs.change-not-answered-page",
          }),
          onConfirm: () => {
            dispatch(hideConfirmDialog());
            handlePageChangeConfirm(page);
          },
          onCancel: () => handlePageChangeCancel(notAnsweredQuestionIds),
        })
      );
    } else {
      handlePageChangeConfirm(page);
    }
  };

  const getCurrentPageNotAnsweredQuestionIds = useCallback(() => {
    const selectedPageQuestionIds: number[] = _.map(
      surveyQuestionsPages[selectedPage],
      "id"
    );
    return _.map(
      _.filter(answers, (sq) => {
        return selectedPageQuestionIds.some(
          (item) => item === sq.question_id && sq.answer_index === undefined
        );
      }),
      "question_id"
    );
  }, [selectedPage, surveyQuestionsPages, answers]);

  const handleQuestionAnswerClick = (
    index: number,
    surveyQuestion: SurveyQuestion<number, CompletedDomain>
  ) => {
    if (selectedPageNotAnsweredQuestions.includes(surveyQuestion.id)) {
      setNotAnsweredQuestions(
        selectedPageNotAnsweredQuestions.filter(
          (item) => item !== surveyQuestion.id
        )
      );
    }
    const selectedAnswer = answers.find(
      (a) => a.question_id === surveyQuestion.id
    );
    if (!!selectedAnswer) {
      const updatedAnswer = {
        ...selectedAnswer,
        answer_index:
          selectedAnswer.answer_index === index
            ? selectedAnswer.answer_index === undefined
              ? index
              : undefined
            : index,
      };
      if (isTesting) {
        dispatch(saveTestingSurveyQuestionAnswer(updatedAnswer));
      } else {
        dispatch(
          saveSurveyQuestionAnswer.request({
            hash: hash,
            school: school,
            sessionHash:
              getStringFromSessionStorage(
                SessionStorageKeys.SurveySessionHash
              ) ?? "",
            request: updatedAnswer,
            previousObject: selectedAnswer,
          })
        );
      }
    }
  };

  const getAnswerOptions = useCallback(
    (
      sq: SurveyQuestion<number, CompletedDomain>
    ): { key: number; value: string }[] => {
      const englishAnswers = sq?.domain?.answers[Locale.English];
      let answers: { key: number; value: string }[] = [];
      if (!!sq?.domain?.answers) {
        const validAnswers = sq?.domain?.answers[locale] ?? englishAnswers;

        answers = Object.keys(validAnswers)
          .map((v) => parseInt(v))
          .filter((index) => {
            if (!sq.is_not_applicable_answer) {
              return index >= 0;
            }
            return true;
          })
          .map((index) => {
            const item = validAnswers[index];
            if (item === "") {
              return {
                key: index,
                value: Object.values(englishAnswers)[index],
              };
            }
            return {
              key: index,
              value: item,
            };
          });
      }

      return answers;
    },
    [surveyQuestionsPages, selectedPage]
  );

  const isDemographicDataPage = useMemo(() => {
    return totalPages === selectedPage && showDemographicData;
  }, [totalPages, selectedPage, showDemographicData]);

  useEffect(() => {
    if (
      isDemographicDataPage ||
      selectedPage === totalPages ||
      !showLanguageSelector
    ) {
      dispatch(hideLanguageSelector(true));
    } else {
      dispatch(hideLanguageSelector(undefined));
    }
  }, [isDemographicDataPage, selectedPage, totalPages, showLanguageSelector]);

  const [stickyVisible, setStickyVisible] = useState<boolean>(true);

  const [refs, setRefs] = useState<SurveyFormRefs>();
  useEffect(() => {
    let refs: SurveyFormRefs = { leadIns: [], separators: [] };
    surveyQuestionsPages[selectedPage]?.forEach((sq, index, array) => {
      if (
        (index === 0 && sq.lead_in) ||
        (index !== 0 && array[index - 1].lead_in !== sq.lead_in && sq.lead_in)
      ) {
        refs.leadIns.push({ sqId: sq.id, ref: React.createRef() });
      }
      if (index !== 0 && !sq.lead_in && array[index - 1].lead_in) {
        refs.separators.push({ sqId: sq.id, ref: React.createRef() });
      }
    });
    setRefs(refs);
  }, [selectedPage, surveyQuestionsPages]);

  let separatorsY;
  let leadInsY;

  const handleScroll = useCallback(() => {
    separatorsY = refs?.separators?.map(
      (sr) => sr.ref.current?.getBoundingClientRect()?.y
    );
    leadInsY = refs?.leadIns?.map(
      (lr) => lr.ref.current?.getBoundingClientRect()?.y
    );
    if (separatorsY !== undefined && leadInsY !== undefined) {
      for (let i = separatorsY.length - 1; i >= 0; i--) {
        if (separatorsY[i] > 0 && leadInsY[i] > 0) {
          continue;
        }
        if (separatorsY[i] < 0 && leadInsY[i] < 0) {
          setStickyVisible(false);
          return;
        }
        if (separatorsY[i] > 0 && leadInsY[i] < 0) {
          setStickyVisible(true);
          return;
        }
      }
    }
  }, [stickyVisible, refs]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      {loading ? (
        <Spinner intent="primary" />
      ) : (
        <>
          <div className="flex justify-center">
            <div className="question-container-max-width w-full">
              <SurveyTitle
                className="welcome-page-title text-blue-600 text-center"
                schoolName={completedSurvey?.school?.name}
                demographic={demographic}
              />

              {!!totalPages && (
                <Stepper
                  totalSteps={totalPages}
                  currentStep={selectedPage}
                  onClick={handlePageChange}
                />
              )}

              <div className={`text-2xl text-blue-600 font-medium my-8 mx-4`}>
                {getLocalizedInstruction(
                  locale,
                  isDemographicDataPage ? "demographic_data" : selectedPage,
                  completedSurvey
                )}
              </div>

              <div
                className={`text-xl sm:text-2xl text-blue-600 font-bold my-4 mx-4`}
              >
                {getLocalizedStatement(
                  locale,
                  isDemographicDataPage ? "demographic_data" : selectedPage,
                  completedSurvey
                )}
              </div>

              <div className="space-y-8">
                {surveyQuestionsPages[selectedPage]?.map((sq, index) => (
                  <div key={index}>
                    <SurveyQuestionCardHeader
                      surveyQuestion={sq}
                      locale={locale}
                      refs={refs}
                      stickyVisible={stickyVisible}
                    />
                    <SurveyQuestionCard
                      key={index}
                      direction={direction}
                      isNotAnswered={selectedPageNotAnsweredQuestions.includes(
                        sq.id
                      )}
                      surveyQuestion={sq}
                      answerOptions={getAnswerOptions(sq)}
                      isAnswerActive={isAnswerActive}
                      onQuestionAnswerClick={handleQuestionAnswerClick}
                    />
                  </div>
                ))}
              </div>

              {isDemographicDataPage && (
                <div className="mx-4">
                  <RespondentInfoForm
                    collectedDemographicData={
                      completedSurvey?.collected_demographic_data
                        ?.demographic_data
                    }
                    completedSurvey={surveyCompletion}
                    onCompletedSurveyChange={handleCompletedSurveyChange}
                    availableGrades={completedSurvey?.school?.grades}
                    demographic={completedSurvey?.demographic}
                  />
                </div>
              )}
              <div className="flex justify-between mt-4">
                <Button
                  intent="primary"
                  icon={
                    <Icon
                      icon={direction === "rtl" ? "arrow-right" : "arrow-left"}
                      iconSize={26}
                    />
                  }
                  className="survey-arrows-button-size"
                  disabled={selectedPage === 0}
                  onClick={() => handlePageChange(selectedPage - 1)}
                />
                <Button
                  intent="primary"
                  className="survey-arrows-button-size"
                  icon={
                    <Icon
                      icon={direction === "rtl" ? "arrow-left" : "arrow-right"}
                      iconSize={26}
                    />
                  }
                  onClick={() => {
                    selectedPage === totalPages
                      ? handleFormSubmit()
                      : handlePageChange(selectedPage + 1);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SurveyForm;
