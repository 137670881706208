import React, { useMemo } from "react";
import { Activity, ActivityTypeEnum } from "../../types";
import { useDispatch } from "react-redux";
import { Button } from "@blueprintjs/core";
import {
  deleteActivity,
  showActivityVideoDialog,
} from "../../store/activities/actions";
import { useIntl } from "react-intl";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../store/UIState/actions";
import DownloadButton from "../common/buttons/DownloadButton";

type OwnProps = {
  activity: Activity;
  hideRemoveButton?: boolean;
};

type Props = OwnProps;

const ActivityItemActions: React.FC<Props> = ({
  activity,
  hideRemoveButton,
}: Props) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const handleActivityRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteActivity.request(activity.id));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirm-dialogs.delete-activity",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const buttons = useMemo(() => {
    switch (activity.activity_type) {
      case ActivityTypeEnum.SCI_WRITING: {
        return (
          <>
            <DownloadButton
              attachmentUrl={activity.docx_resource?.attachment_url}
              extension={".docx"}
            />
            <DownloadButton
              attachmentUrl={activity.pdf_resource?.attachment_url}
              extension={".pdf"}
            />
            <DownloadButton
              attachmentUrl={activity.ppt_resource?.attachment_url}
              extension={".ppt"}
            />
            <Button
              intent="primary"
              text="View Activity Content"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  showActivityVideoDialog({
                    activity: activity,
                    viewable_field: "html_content",
                  })
                );
              }}
            />
            <Button
              intent="primary"
              text="View Activity Notes Placeholder"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  showActivityVideoDialog({
                    activity: activity,
                    viewable_field: "notes_placeholder_html",
                  })
                );
              }}
            />
          </>
        );
      }
      case ActivityTypeEnum.EXTERNAL_RESOURCE: {
        return (
          <>
          <DownloadButton
            attachmentUrl={activity.pdf_resource?.attachment_url}
            externalUrl={activity.external_url}
            extension={".pdf"} />
            
          <DownloadButton
              attachmentUrl={activity.ppt_resource?.attachment_url}
              externalUrl={activity.external_url}
              extension={".ppt"} />
          </>
        );
      }
      case ActivityTypeEnum.KALTURA_VIDEO: {
        return (
          <Button
            intent="primary"
            text="Watch Video"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                showActivityVideoDialog({
                  activity: activity,
                  viewable_field: "embed_code",
                })
              );
            }}
          />
        );
      }
      default:
        return null;
    }
  }, [activity]);

  return (
    <div className="flex flex-wrap gap-2">
      {buttons}
      {!hideRemoveButton && (
        <Button
          title={intl.formatMessage({ id: "app.titles.delete" })}
          icon="trash"
          intent="danger"
          onClick={handleActivityRemove}
        />
      )}
    </div>
  );
};

export default ActivityItemActions;
