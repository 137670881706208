import React, { useMemo } from "react";
import { Activity } from "../../types";
import ActivityItem from "./ActivityItem";

type OwnProps = {
  activities: Activity[];
  selectedActivities?: number[];
  onActivityClick: (activity: Activity) => void;
};

type Props = OwnProps;

const ActivitiesList: React.FC<Props> = ({
  activities,
  selectedActivities,
  onActivityClick,
}: Props) => {
  const hideRemoveButton = useMemo(() => {
    return selectedActivities !== undefined
  }, [selectedActivities]);
  return (
    <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-2">
      {activities.map((a, i) => (
        <ActivityItem
          key={i}
          activity={a}
          onActivityClick={onActivityClick}
          isActive={
            selectedActivities && selectedActivities.some((sa) => sa === a.id)
          }
          hideRemoveButton={hideRemoveButton}
        />
      ))}
    </div>
  );
};

export default ActivitiesList;
