import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getResourcesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getResources.request)),
    switchMap((action) =>
      from(api.resources.getResources(action.payload)).pipe(
        map(actions.getResources.success),
        catchError((error) => of(actions.getResources.failure(error)))
      )
    )
  );
};

export const addResourceEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addResource.request)),
    switchMap((action) =>
      from(
        api.resources.addResource(
          action.payload.resource,
          action.payload.planId
        )
      ).pipe(
        mergeMap((resource) =>
          from(
            [actions.addResource.success(resource)],
            action.payload.onSuccess && action.payload.onSuccess(resource)
          )
        ),
        catchError((error) => of(actions.addResource.failure(error)))
      )
    )
  );
};

export const updateResourceEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateResource.request)),
    switchMap((action) =>
      from(api.resources.updateResource(action.payload.resource, action.payload.planId)).pipe(
        mergeMap((resource) =>
          from(
            [actions.updateResource.success(resource)],
            action.payload.onSuccess && action.payload.onSuccess(resource)
          )
        ),
        catchError((error) => of(actions.updateResource.failure(error)))
      )
    )
  );
};

export const deleteResourceEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteResource.request)),
    switchMap((action) =>
      from(
        api.resources.deleteResource(
          action.payload.resourceId,
          action.payload.planId
        )
      ).pipe(
        mergeMap(() =>
          from(
            [actions.deleteResource.success(action.payload.resourceId)],
            action.payload.onSuccess &&
              action.payload.onSuccess(action.payload.resourceId)
          )
        ),
        catchError((error) => of(actions.deleteResource.failure(error)))
      )
    )
  );
};
