import React, { FunctionComponent, useMemo } from "react";
import { Popover } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { MAX_DISCREPANCY } from "../../../../types";

type OwnProps = {
  isDomain?: boolean;
  disabled?: boolean;
  show?: boolean;
};

type Props = OwnProps;

const DiscrepancyPopover: FunctionComponent<Props> = (props) => {
  const { isDomain, disabled, show, children } = props;

  const intl = useIntl();

  const message = useMemo(() => {
    return intl.formatMessage(
      {
        id: isDomain
          ? "app.survey-report.table.discrepancy.domain.popover-content"
          : "app.survey-report.table.discrepancy.question.popover-content",
      },
      { discrepancy: MAX_DISCREPANCY }
    );
  }, [isDomain]);

  return (
    <Popover
      isOpen={show}
      popoverClassName={"w-25"}
      disabled={disabled}
      content={<div className="p-2">{message}</div>}
      modifiers={{
        flip: { enabled: true },
        preventOverflow: { enabled: true, boundariesElement: "viewport" },
      }}
    >
      {children}
    </Popover>
  );
};

export default DiscrepancyPopover;
