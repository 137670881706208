import React, { useCallback, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import CreatableSelect from "react-select/creatable";
import { getFullName } from "../../../helpers/message-format-utils";
import { UserModel } from "../../../types";
import UserSelectorItem from "./UserSelectorItem";
import PseudoValidator from "../../common/selectors/PseudoValidator";

type Props<IsMulti extends boolean> = {
  users: UserModel[];
  userId?: IsMulti extends true ? number[] : number;
  onUserChange: (values?: IsMulti extends true ? number[] : UserModel) => void;
  placeholder: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  selectedUsers?: number[];
  onUserCreate?: (name: string) => void;
  required?: boolean;
};

const UserSelector = <IsMulti extends boolean = false>(
  props: Props<IsMulti>
) => {
  const {
    users,
    userId,
    onUserChange,
    onUserCreate,
    placeholder,
    disabled,
    loading,
    className,
    selectedUsers,
    required,
  } = props;
  const intl = useIntl();

  const handleUserChange = (value?: any) => {
    onUserChange((isArray ? value?.map((v: any) => v) : value) as any);
  };

  const displayNoOptions = useCallback(() => {
    return intl.formatMessage({ id: "app.titles.no-options" });
  }, []);

  const isArray = useMemo(() => {
    return Array.isArray(userId);
  }, [userId]);

  const displayedUsers: UserModel[] = useMemo(() => {
    if (selectedUsers?.length) {
      return (
        users?.filter(
          (u) => !selectedUsers?.some((userId) => userId === u?.id)
        ) || []
      );
    }
    return users;
  }, [users, selectedUsers]);

  const selectedValue: UserModel | UserModel[] | undefined = useMemo(() => {
    return isArray
      ? (userId as number[])?.map(
          (sId) =>
            users.find((option) => {
              return option.id === sId;
            })!
        )
      : users.find((option) => {
          return option.id === userId;
        });
  }, [users, userId, isArray]);

  const renderCreateLabel = (inputValue: string) => {
    return intl.formatMessage(
      { id: "app.invites.invite-name" },
      { name: inputValue }
    );
  };

  const renderOptionLabel = (user: any) => {
    if (user.__isNew__) {
      return user.label;
    }
    return <UserSelectorItem user={user} />;
  };

  const handleUserCreate = (name: string) => {
    onUserCreate && onUserCreate(name);
  };

  const getOptionValue = (user: any) => {
    return user.id;
  };

  const getOptionLabel = (user: any) => {
    if (user.__isNew__) {
      return user.label;
    }

    return getFullName(user);
  };

  const selectRef = useRef<any>();

  return (
    <div style={{ position: "relative" }}>
      {required && (
        <PseudoValidator
          targetRef={selectRef}
          value={isArray ? (userId as number[])[0] : userId}
        />
      )}
      <CreatableSelect
        ref={selectRef}
        isClearable
        isMulti={isArray}
        isDisabled={disabled}
        isLoading={loading}
        value={selectedValue}
        options={displayedUsers}
        className={className ?? "selector-min-width"}
        placeholder={placeholder}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        formatOptionLabel={renderOptionLabel}
        formatCreateLabel={renderCreateLabel}
        onChange={handleUserChange}
        onCreateOption={handleUserCreate}
        noOptionsMessage={displayNoOptions}
      />
    </div>
  );
};

export default UserSelector;
