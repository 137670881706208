import React, { FunctionComponent, useMemo } from "react";
import { AutoSizer, Column, Table } from "react-virtualized";
import { useIntl } from "react-intl";
import "react-virtualized/styles.css";
import MeasurementBasedInput from "../measurement-based-input/MeasurementBasedInput";
import { MeasurementType, PossibleValue } from "../../../../types";
import DateInputGroup from "../measurement-based-input/DateInputGroup"; // only needs to be imported once

type OwnProps = {
  dataPoints: { value: string | number; date?: Date }[];
  setDataPoints: React.Dispatch<
    React.SetStateAction<
      { value: string | number; date?: Date; originalIndex: number }[]
    >
  >;
  possibleValues: Pick<PossibleValue, "id" | "order" | "name">[];
  measurementType: MeasurementType;
  scrollToIndex?: number;
};

type Props = OwnProps;

const PreviewTable: FunctionComponent<Props> = (props) => {
  const {
    dataPoints,
    setDataPoints,
    measurementType,
    possibleValues,
    scrollToIndex,
  } = props;

  const intl = useIntl();

  const rowHeight = useMemo(() => {
    return measurementType === MeasurementType.Ratio ? 80 : 35;
  }, [measurementType]);

  return (
    <div style={{ height: 400 }}>
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={30}
            rowHeight={rowHeight}
            rowCount={dataPoints.length}
            rowGetter={({ index }) => dataPoints[index]}
            onRowClick={(e) => e.event.preventDefault()}
            scrollToIndex={scrollToIndex}
          >
            <Column
              cellRenderer={(tableCell) => {
                return (
                  <MeasurementBasedInput
                    labelless
                    value={tableCell.rowData.value}
                    setValue={(value) => {
                      setDataPoints((dataPoints) =>
                        dataPoints.map((dp, dpIndex) =>
                          dpIndex === tableCell.rowIndex
                            ? { ...dp, value: value }
                            : dp
                        )
                      );
                    }}
                    measurementType={measurementType}
                    possibleValues={possibleValues}
                    requiredValue={false}
                  />
                );
              }}
              label={intl.formatMessage({ id: "app.titles.value" })}
              dataKey="value"
              width={100}
              flexGrow={1}
            />
            <Column
              width={100}
              flexGrow={1}
              label={intl.formatMessage({ id: "app.titles.date" })}
              dataKey="date"
              cellRenderer={(tableCell) => {
                return (
                  <DateInputGroup
                    labelless
                    minDateOffset={10}
                    date={tableCell.rowData.date}
                    onDateChange={(date) => {
                      setDataPoints((dataPoints) =>
                        dataPoints.map((dp, dpIndex) =>
                          dpIndex === tableCell.rowIndex
                            ? { ...dp, date: date }
                            : dp
                        )
                      );
                    }}
                  />
                );
              }}
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

export default PreviewTable;
