import { NonIdealState, Spinner } from "@blueprintjs/core";
import moment from "moment";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { CellProps } from "react-table";
import { CountAudit, UserActionAudit } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getUserActionAudit } from "../../../store/users/actions";

import EntityTable from "../../common/EntityTable"
import DownloadDistrictSummary from "./DownloadDistrictSummary";
import DownloadSchoolSummary from "./DownloadSchoolSummary";

type OwnProps = {
  countAudits: CountAudit[];
};

type Props = OwnProps;

const CountAuditLogTable: FunctionComponent<Props> = (props) => {
  const {
    countAudits,
  } = props;
  

  const dispatch = useDispatch();

  const intl = useIntl();
  const [searchString, setSearchString] = useState<string>("");


  
  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({
          id: "app.district.summary.count.total-number-of-districts",
        }),
        width: "10%",
        accessor: "date",
        Cell: ({ row: { original } }: CellProps<CountAudit>) => {
          return (
            <p className="flex whitespace-no-wrap">
              {original?.count_district}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.district.summary.count.total-number-of-schools",
        }),
        width: "5%",
        accessor: "time",
        Cell: ({ row: { original } }: CellProps<CountAudit>) => {
          return (
            <p className="flex whitespace-no-wrap">
              {original?.count_school}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.district.summary.count.total-number-of-all-users" }),
        width: "10%",
        accessor: "district_school_role",
        Cell: ({ row: { original } }: CellProps<CountAudit>) => {
          return (
            <p className="flex capitalize">
              {original.count_users}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.district.summary.count.total-number-of-active-users",
        }),
        width: "20%",
        wrap: true,
        accessor: "action_type",
        Cell: ({ row: { original } }: CellProps<CountAudit>) => {
          return (
            <p className="flex whitespace-no-wrap capitalize">
              {original?.count_active_users}
            </p>
          );
        },
      },
    ];
  }, []);

  const noUsers = useMemo(
    () => (
      <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({
          id: "app.non-ideal-state.action-audit-log",
        })}
      />
    ),
    []
  );

  // if (loading) {
  //   return <Spinner intent="primary" className="mt-4" />;
  // }

  return (
    <EntityTable
      data={countAudits as CountAudit[]}
      columns={columns}
      searchString={searchString}
      setSearchString={setSearchString}
      loading={false}
      noDataComp={noUsers}
      customPagination={true}
    >
      <div className="flex items-center ml-2">
        <DownloadDistrictSummary />
        <DownloadSchoolSummary />
      </div>
    </EntityTable>
  );
};

export default CountAuditLogTable;
