import React, { FunctionComponent, useMemo } from "react";
import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { showInviteUsersDialog } from "../../../store/users/actions";
import moment from "moment/moment";
import { UserInfo, UserRole } from "../../../types";
import _ from "lodash";
import { getCurrentUserRole } from "../../../store/auth/selectors";

type OwnProps = {
  users: UserInfo[];
  keys?: (keyof UserInfo)[];
  forAllSystem?: boolean;
};

type Props = OwnProps;

const UserTableHeaderActions: FunctionComponent<Props> = (props) => {
  const { users, keys = [], forAllSystem } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const currentUserRole = useSelector(getCurrentUserRole);

  const isSuperadmin = useMemo(() => {
    if (currentUserRole) {
      return [UserRole.Superadmin, UserRole.NJ_SCI_Leadership].includes(
        currentUserRole
      );
    }
    return false;
  }, [currentUserRole]);

  const handleDialogOpen = () => {
    dispatch(showInviteUsersDialog({}));
  };

  const keysReMapper: {
    [key in keyof UserInfo]?: string;
  } = useMemo(() => {
    return {
      full_name: intl.formatMessage({ id: "app.common-table.columns.name" }),
      email: intl.formatMessage({ id: "app.users-table.columns.email" }),
      role_name: intl.formatMessage({ id: "app.titles.role" }),
      district_name: intl.formatMessage({
        id: "app.forms.create-district.name",
      }),
      is_single_school_district: intl.formatMessage({
        id: "app.titles.is-single-school-district",
      }),
      schools: intl.formatMessage({ id: "app.titles.schools" }),
      is_dcc: intl.formatMessage({ id: "app.titles.is-dcc" }),
      invite_status_name: intl.formatMessage({
        id: "app.titles.invite-status",
      }),
    };
  }, []);

  const handleCsvDownload = () => {
    const header = keys.map((key) => `"${keysReMapper[key] ?? key}"`).join(",");
    const dataRows = users.map((u) =>
      _.chain(u)
        .pick(keys)
        .values()
        .map((val: string) => {
          if (val && val.toString().indexOf(",") >= 0) {
            return `"${val.toString().replaceAll('"', '\\"')}"`;
          } else {
            return val;
          }
        })
        .join(",")
    );

    const csvStr = [header, ...dataRows].join("\n");
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute(
      "download",
      `NJ SCI User Export ${moment().format("MM/DD/YYYY")}`
    );
    link.href = csvUrl;
    document.body.append(link);
    link.click();
    link.remove();
  };

  return (
    <Popover
      position={"bottom-left"}
      className="flex-grow justify-start"
      content={
        <Menu>
          {isSuperadmin && (
            <MenuItem
              text={intl.formatMessage({
                id: forAllSystem
                  ? "app.titles.invite-admins"
                  : "app.users-table.invite-users",
              })}
              icon="plus"
              className="px-3 capitalize"
              onClick={handleDialogOpen}
            />
          )}
          <MenuItem
            text={intl.formatMessage({
              id: "app.titles.download-as-CSV",
            })}
            icon="import"
            className="px-3 capitalize"
            onClick={handleCsvDownload}
          />
        </Menu>
      }
      minimal
    >
      <Button
        large
        minimal
        title={intl.formatMessage({ id: "app.titles.users" })}
        icon={"properties"}
        text={intl.formatMessage({ id: "app.titles.users" })}
        intent="primary"
        className="font-bold px-4"
      />
    </Popover>
  );
};

export default UserTableHeaderActions;
