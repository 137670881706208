import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTeamMembersByPlan } from "../../../store/team-members/actions";
import { useLoading } from "../useLoading";

export const useGetTeamMembersLoading = () => {
  const dispatch = useDispatch();
  const activePlan = useSelector((s) => s.plans.activePlan);

  useEffect(() => {
    if (activePlan?.id) {
      dispatch(
        getTeamMembersByPlan.request({
          planId: activePlan.id,
          include_district_user: true,
        })
      );
    }
  }, [activePlan?.id]);

  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  const error = useSelector((s) => s.teamMembers.errors.getTeamMembersByPlan);
  useLoading({ loading, error });

  return {
    loading,
  };
};
export default useGetTeamMembersLoading;
