import { useMemo } from "react";
import { Plan, DistrictComparisonRouteParams } from "../../types";
import { matchPath, useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import { AuthRoutes } from "../../App";

export const useDistrictComparisonParams = () => {
  const activePlan = useSelector((s) => s.plans.activePlan);

  const routeParams = useParams<DistrictComparisonRouteParams>();

  const location = useLocation();

  const isPlanPage = useMemo(
    () => !!matchPath(location.pathname, AuthRoutes.YourPlanReports),
    [location.pathname]
  );

  const plan: Plan | undefined = useMemo(() => {
    return isPlanPage ? activePlan : undefined;
  }, [isPlanPage, activePlan]);

  const selectedSchoolId: number | undefined = useMemo(() => {
    return plan?.school.id
      ? +plan.school.id
      : routeParams?.schoolId
        ? +routeParams.schoolId
        : undefined;
  }, [plan?.school.id, routeParams?.schoolId]);

  const reportPagePath = useMemo(() => {
    return AuthRoutes.YourPlanDomainData
  }, [plan]);

  return {
    reportPagePath: reportPagePath,
    tabId: routeParams.tabId,
    schoolId: selectedSchoolId,
    surveyDeploymentId: routeParams.surveyDeploymentId
      ? +routeParams.surveyDeploymentId
      : undefined,
    domainId: routeParams.domainId ? +routeParams.domainId : undefined,
    tagId: routeParams.tagId,

    routeParams: routeParams,
  };
};

export default useDistrictComparisonParams;