import { HTMLTable } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { DemographicWrapper, OpenTimePeriod, School } from "../../../../types";
import { getAvailableDemographics } from "../../../../helpers/survey-deployment-utils";
import useSchoolPlanDemographics from "../../../../helpers/hooks/useSchoolPlanDemographics";
import { useSelector } from "react-redux";

type OwnProps = {
  openTimePeriods?: OpenTimePeriod<School>[];
  responses: DemographicWrapper<number>;
};

type Props = OwnProps;

const SurveyAdministrationTable: React.FC<Props> = (props) => {
  const { responses, openTimePeriods } = props;

  const intl = useIntl();

  const activePlanSchoolGrades = useSelector(
    (s) => s.plans.activePlan?.school?.grades
  );
  const {
    demographics: availableDemographicsInPlan,
  } = useSchoolPlanDemographics({
    grades: activePlanSchoolGrades,
  });

  const demographics = useMemo(() => {
    return getAvailableDemographics({
      availableDemographicsInPlan: availableDemographicsInPlan,
      schoolOpenPeriods: openTimePeriods,
      responses: responses,
    });
  }, [availableDemographicsInPlan, openTimePeriods, responses]);

  return (
    <HTMLTable className="dashboard-table">
      <thead>
        <tr>
          {demographics.map((d, i) => (
            <th key={i}>
              {intl.formatMessage({
                id: `app.surveys.survey-demographic.${d}`,
              })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {demographics.map((d) => (
            <td key={d}>{responses[d] ?? 0}</td>
          ))}
        </tr>
      </tbody>
    </HTMLTable>
  );
};

export default SurveyAdministrationTable;
