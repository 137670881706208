import {
  Button,
  IButtonProps,
  IconName,
  Intent,
  MaybeElement,
  Tooltip,
} from "@blueprintjs/core";
import React, { FunctionComponent } from "react";

type OwnProps = IButtonProps & {
  intent?: Intent;
  icon?: IconName | MaybeElement;
  title?: string;
  text?: string;
  tooltipText?: string;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

type Props = OwnProps;

const ButtonWithTooltip: FunctionComponent<Props> = (props) => {
  const {
    icon,
    intent,
    text,
    title = text,
    tooltipText = title,
    onClick,

    ...otherButtonProps
  } = props;

  return (
    <Tooltip content={tooltipText}>
      <Button
        {...otherButtonProps}
        icon={icon}
        text={text}
        title={title}
        intent={intent}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default ButtonWithTooltip;
