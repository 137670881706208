import React from "react";
import { UserModel } from "../../../types";
import { Tag } from "@blueprintjs/core";
import { useIntl } from "react-intl";

type OwnProps = {
  user: UserModel;
};

type Props = OwnProps;

const UserSelectorItem: React.FC<Props> = ({ user }: Props) => {
  const intl = useIntl();

  return (
    <div className="flex gap-2 justify-between">
      <div>
        {user.first_name}&nbsp;{user.last_name}
      </div>
      <div>
        <Tag intent="warning" minimal>
          {intl.formatMessage(
            { id: "app.roles" },
            { role: user.profile?.role }
          )}
        </Tag>
      </div>
    </div>
  );
};

export default UserSelectorItem;
