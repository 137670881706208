import React, { FunctionComponent, useMemo, useRef } from "react";
import Select from "react-select";
import { FormGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { smallSelectorStyles } from "../../../common/selectors";
import { PossibleValue } from "../../../../types";
import { ValueType } from "react-select/src/types";
import PseudoValidator from "../../../common/selectors/PseudoValidator";

type OwnProps = {
  value: string | number;
  onChange: (value: string | number) => any;
  possibleValues?: Pick<PossibleValue, "id" | "order" | "name">[];
  labelless?: boolean;
  required: boolean;
};

type Props = OwnProps;

const LabelDataInput: FunctionComponent<Props> = (props) => {
  const {
    value,
    onChange,
    possibleValues = [],
    labelless,
    children,
    required,
  } = props;

  const intl = useIntl();

  const selectedValue = useMemo(() => {
    return value != null && value.toString().length
      ? possibleValues.find((pv) => pv.order === +value)
      : null;
  }, [possibleValues, value]);

  const handleValueChange = (
    value: ValueType<Pick<PossibleValue, "id" | "order" | "name">, false>
  ) => {
    onChange(value?.order ?? "");
  };

  const options = useMemo(() => {
    return possibleValues.sort((a, b) => a.order - b.order);
  }, [possibleValues]);

  const selectRef = useRef<any>();

  return (
    <div className="flex space-x-4" style={{ minWidth: "10rem" }}>
      <FormGroup
        label={
          labelless ? undefined : intl.formatMessage({ id: "app.titles.value" })
        }
        className="flex-1 mb-0 relative"
      >
        {required && (
          <PseudoValidator targetRef={selectRef} value={selectedValue?.order} />
        )}
        <Select
          ref={selectRef}
          options={options}
          value={selectedValue ?? null}
          onChange={handleValueChange}
          getOptionValue={(pv) => pv.order.toString()}
          getOptionLabel={(pv) =>
            pv.name || intl.formatMessage({ id: "app.titles.not-applicable" })
          }
          styles={smallSelectorStyles}
          menuPortalTarget={document.body}
        />
      </FormGroup>
      {children}
    </div>
  );
};

export default LabelDataInput;
