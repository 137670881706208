import { createAction, createAsyncAction } from "typesafe-actions";
import { AuthenticatedUserModel } from "../../types";
import {
  UpdatePasswordRequest,
  UpdateProfileRequest,
} from "../../api/profile/types";

export const updatePassword = createAsyncAction(
  "@profile/UPDATE_PASSWORD_REQUEST",
  "@profile/UPDATE_PASSWORD_SUCCESS",
  "@profile/UPDATE_PASSWORD_FAILURE"
)<UpdatePasswordRequest, void, Error>();

export const updateProfile = createAsyncAction(
  "@profile/UPDATE_PROFILE_REQUEST",
  "@profile/UPDATE_PROFILE_SUCCESS",
  "@profile/UPDATE_PROFILE_FAILURE"
)<UpdateProfileRequest, AuthenticatedUserModel, Error>();

export const showChangePasswordDialog = createAction(
  "@profile/SHOW_CHANGE_PASSWORD_DIALOG"
)<void>();

export const hideChangePasswordDialog = createAction(
  "@profile/HIDE_CHANGE_PASSWORD_DIALOG"
)<void>();

export const showEditProfileDialog = createAction(
  "@profile/SHOW_EDIT_PROFILE_DIALOG"
)<void>();

export const hideEditProfileDialog = createAction(
  "@profile/HIDE_EDIT_PROFILE_DIALOG"
)<void>();
