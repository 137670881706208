/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useIntl } from "react-intl";
import { Icon, NonIdealState } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import './EditCorecomponents.scss';
import {
    LookForItem,
    LookForItemStatus,
    LookForItemStatusEnum,
    PlanStrategy,
    RecurringStatusEnum,
} from '../../../../../types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CoreComponentItem from './core-component-item/CoreComponentItem';
import { updatePlanStrategy } from '../../../../../store/plan-strategies/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppToaster } from '../../../../../helpers/toaster';
import { updateStrategy } from '../../../../../store/strategies/actions';
import TextField from '@mui/material/TextField';
import { isDistrictPlan } from '../../../../../store/plans/selectors';

type OwnProps = {
    planstrategy: PlanStrategy;
    activePlan?: number;
    handleClose: () => void;
};
type Props = OwnProps;

type InputFieldType = {
    item: {
        text: string;
        original: boolean;
        plan_id?: number;
    };
    recurring_status: RecurringStatusEnum;
    status: LookForItemStatusEnum;
}

const EditCorecomponents: React.FC<Props> = (props) => {
    const { planstrategy, activePlan, handleClose } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    // STATES
    const [isEditCoreComponentOpen, setIsEditCoreComponentOpen] = useState(false);
    const [lookForStatuses, setLookForStatuses] = useState<
        Omit<
            LookForItemStatus<Omit<LookForItem, "id" | "created_at" | "updated_at">>,
            "id" | "created_at" | "updated_at"
        >[]
        >([]);
    const [isFormError, setIsFormError] = useState<boolean>(false);
    const [isUnSaved, setIsUnSaved] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const isActiveDistrictPlan = useSelector(isDistrictPlan);
    const [characterLimitExceeded, setCharacterLimitExceeded] = useState<boolean>(false); // State for character limit exceeded

    const handleCloseAlert = () => {
        setOpen(false);
    };

    const editCoreComponentClickOpen = () => {
        setIsEditCoreComponentOpen(true);

        // sorted core components original core component on top snd custom in bottom
        planstrategy.strategy.look_for_items.sort((a, b) => (a.original === b.original) ? 0 : a.original ? -1 : 1);

        setLookForStatuses(
            planstrategy.strategy.look_for_items?.map((lookFor) => {
                const lookForStatus = planstrategy.look_for_statuses?.find(
                    (lookForStatus) => lookForStatus.item === lookFor.id
                );
                if (lookForStatus) {
                    return {
                        ...lookForStatus,
                        status: LookForItemStatusEnum.NotPresent,
                        item: lookFor,
                    };
                }
                return {
                    recurring_status: RecurringStatusEnum.NotRecurring,
                    status: LookForItemStatusEnum.NotPresent,
                    item: lookFor,
                };
            })
        );
    };

    const editCoreComponentClose = () => {
        if (isUnSaved) {
            setOpen(true)
        }
        else {
            setIsEditCoreComponentOpen(false);
            handleClose();
        }
    };

    // Remove Core Component Item
    const handleLookForRemove = (index: number) => () => {
        setLookForStatuses((lookForStatuses) =>
            lookForStatuses.filter((lookForStatus, i) => i !== index)
        );
        setIsUnSaved(true);
    };

    const handleLookForChange = <T extends string | RecurringStatusEnum>(
        index: number
    ) => (key: keyof LookForItem | keyof LookForItemStatus, value: T) => {
        setLookForStatuses((lookForStatuses) =>
            lookForStatuses.map((lookForStatus, i) =>
                i === index
                    ? key === "text"
                        ? {
                            ...lookForStatus,
                            item: { ...lookForStatus.item, [key]: value, plan_id: activePlan },
                        }
                        : { ...lookForStatus, [key]: value, plan_id: activePlan }
                    : { ...lookForStatus }
            )
        )
        setIsUnSaved(true);
        };

    // Add Core Component Item
    const handleLookForAdd = () => {
        setLookForStatuses((lookForStatuses) => [
            ...lookForStatuses,
            {
                item: { text: "", original: false, school_item: isActiveDistrictPlan ? false : true, plan_id: activePlan },
                recurring_status: RecurringStatusEnum.NotRecurring,
                status: LookForItemStatusEnum.NotPresent,
            },
        ]);
        setIsUnSaved(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSuccess = () => {
        AppToaster.show({
            icon: "tick",
            intent: "success",
            message: intl.formatMessage(
                { id: "app.plan-strategies.update-core-components" },
                { name: planstrategy?.strategy?.name }
            ),
        });
        handleClose();
    };

    useEffect(() => {
        const updatedLookForItems = lookForStatuses.map((lookForStatus) => lookForStatus.item);
        const emptyItem = updatedLookForItems.filter((item) => {
            if (!item.text.length) {
                return item;
            }
        });
        emptyItem.length ? setIsFormError(true) : setIsFormError(false);
        const characterLimitExceeded = updatedLookForItems.some(item => item.text.length > 5000);
        setCharacterLimitExceeded(characterLimitExceeded);
    }, [lookForStatuses]);

    const isLookForItemsChanged = useCallback(() => {
        if (!planstrategy) return false;

        const lookForItems = planstrategy.strategy?.look_for_items;
        const updatedLookForItems = lookForStatuses.map((lookForStatus) => lookForStatus.item);

        return (
            lookForItems?.length !== updatedLookForItems?.length ||
            lookForItems.some((lookFor, i) => lookFor.text !== updatedLookForItems[i]?.text)
        );
    }, [planstrategy, lookForStatuses]);

    const onUpdateSuccess = useCallback((strategy) => {
        if (planstrategy && activePlan) {
            const updatedLookForStatuses = lookForStatuses.map((lookForStatus, index) => ({
                ...lookForStatus,
                item: strategy.look_for_items?.[index]?.id,
                plan_id: strategy.look_for_items?.[index].plan_id
            }));

            dispatch(
                updatePlanStrategy.request({
                    planStrategy: {
                        planId: activePlan,
                        strategy: planstrategy.strategy.id,
                        planStrategyId: planstrategy.id,
                        look_for_statuses: updatedLookForStatuses,
                    },
                    onSuccess: onSuccess()
                })
            );
        }
    }, [planstrategy, activePlan, lookForStatuses, dispatch, onSuccess]);

    
    const handleOnSubmit = () => {
        if (isLookForItemsChanged()) {
            // Check for character limit exceeded
            if (characterLimitExceeded) {
                AppToaster.show({
                    icon: "warning-sign",
                    intent: "danger",
                    message: intl.formatMessage({ id: "app.core-components.character-limit-exceeded" }),
                });
            } else {
                dispatch(
                    updateStrategy.request({
                        updatedStrategy: {
                            id: planstrategy.strategy.id,
                            name: planstrategy.strategy.name,
                            domains: planstrategy.strategy.domains.map((d) => d.id),
                            description: planstrategy.strategy.description,
                            supporting_evidence: planstrategy.strategy.supporting_evidence,
                            look_for_items: lookForStatuses.map((lookForStatus) => lookForStatus.item),
                            resources: planstrategy.strategy.resources
                        },
                        onSuccess: onUpdateSuccess,
                    })
                );
            }
        } else if (activePlan) {
            dispatch(
                updatePlanStrategy.request({
                    planStrategy: {
                        planId: activePlan,
                        strategy: planstrategy.strategy.id,
                        planStrategyId: planstrategy.id,
                        look_for_statuses: lookForStatuses.map((lookForStatus, index) => ({
                            ...lookForStatus,
                            item: planstrategy.strategy.look_for_items?.[index]?.id,
                        })),
                    },
                    onSuccess: onSuccess
                })
            );
        }

        setIsUnSaved(false);
    };

    return (
        <div>
            <a onClick={editCoreComponentClickOpen}><Icon icon="edit" iconSize={14} /> {intl.formatMessage({ id: "app.strategy.edit-core-components" })}</a>
            <Dialog
                className="edit-core-modal"
                open={isEditCoreComponentOpen}
                onClose={editCoreComponentClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal-title">
                    <h3>{intl.formatMessage({ id: "app.strategy.edit-core-components" })}</h3>
                    <button onClick={editCoreComponentClose}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>

                <div className="edit-core-modal-content">
                    <div className="picker-info">
                        <h4>{planstrategy?.strategy?.name}</h4>
                    </div>
                    <div className="accordionbar">
                        <div className="accordionbar__content">
                            {
                                !!lookForStatuses.length ? (
                                    lookForStatuses.map((lookForStatus, index) => (
                                        (lookForStatus.item.plan_id === activePlan || lookForStatus.item.original || (!lookForStatus.item.school_item || (!lookForStatus.item.original && lookForStatus.item.plan_id === activePlan))) && (
                                        <CoreComponentItem
                                            key={index}
                                            lookForStatus={lookForStatus}
                                            onLookForStatusFieldUpdate={handleLookForChange(index)}
                                            onLookForRemove={handleLookForRemove(index)}
                                        />
                                        )
                                    ))
                                ) : (
                                    <NonIdealState
                                        icon="add-to-artifact"
                                        title={intl.formatMessage({ id: "app.strategies.look-fors.empty" })}
                                        description={intl.formatMessage({
                                            id: "app.non-ideal-state.let's-get-started",
                                        })}
                                        />
                                    )
                            }
                        </div>
                    </div>

                    <div className="action">
                        <div className="action__left">
                            <button className="nj-btn" onClick={handleLookForAdd}><Icon icon="plus" iconSize={16} /> Core component</button>
                        </div>
                        <div className="action__right">
                            <button onClick={handleOnSubmit} className="nj-btn" disabled={!lookForStatuses.length || isFormError}>{intl.formatMessage({ id: "app.titles.save" })}</button>
                            {characterLimitExceeded && (
                                <span className="character-limit-message">{intl.formatMessage({ id: "app.core.component.limit.character" })}</span>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog >

            <Dialog
                className="medium-popup"
                open={open}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="cancel">
                    <button onClick={handleCloseAlert}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" className="alertIcon"  viewBox="0 0 1024 1024" version="1.1">
                <path d="M956.416 802.816L581.632 159.744c-17.408-30.72-41.984-47.104-69.632-47.104-26.624 0-52.224 16.384-69.632 47.104L66.56 802.816c-17.408 29.696-19.456 59.392-6.144 82.944 13.312 23.552 39.936 35.84 74.752 35.84h753.664c34.816 0 61.44-13.312 74.752-35.84 13.312-23.552 10.24-53.248-7.168-82.944z m-28.672 62.464c-6.144 10.24-19.456 15.36-38.912 15.36H135.168c-19.456 0-33.792-6.144-38.912-15.36-6.144-10.24-4.096-25.6 6.144-41.984l376.832-643.072c10.24-17.408 22.528-26.624 33.792-26.624s24.576 10.24 33.792 26.624L921.6 823.296c9.216 16.384 12.288 31.744 6.144 41.984zM542.72 716.8c0 17.408-13.312 30.72-30.72 30.72s-30.72-13.312-30.72-30.72 13.312-30.72 30.72-30.72 30.72 13.312 30.72 30.72z m0-296.96v194.56c0 17.408-13.312 30.72-30.72 30.72s-30.72-13.312-30.72-30.72V419.84c0-17.408 13.312-30.72 30.72-30.72s30.72 13.312 30.72 30.72z"/></svg>
                <h3>
                    {intl.formatMessage({ id: "app.title.popup.button-close.hrading" })}
                </h3>
                <div>
                    {intl.formatMessage(
                        { id: "app.title.popup.button-close.text" },
                        { br: <br /> }
                    )}
                </div>
                <div className="button-group">
                    <button onClick={() => {
                        setIsEditCoreComponentOpen(false);
                        handleCloseAlert();
                        handleClose();
                    }} className="stroke-btn">
                        {intl.formatMessage({ id: "app.title.popup.button-close.leave-without-saving" })}
                    </button>
                    <button
                        onClick={() => setOpen(false)}
                        className="nj-btn"
                    >
                        {intl.formatMessage({
                            id: "app.titles.stay",
                        })}
                    </button>
                </div>
            </Dialog>
        </div >
    );
}

export default EditCorecomponents;