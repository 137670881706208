import { createReducer, RootAction } from "typesafe-actions";
import {
  hideChangePasswordDialog,
  hideEditProfileDialog,
  showChangePasswordDialog,
  showEditProfileDialog,
} from "./actions";
import actions from "../actions";
import { LocalStorageKeys } from "../../constants";

type ActionName = "updatePassword" | "updateProfile";

export type ProfileState = {
  showEditProfileDialog?: boolean;
  showChangePasswordDialog?: boolean;

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: ProfileState = {
  showEditProfileDialog: false,
  showChangePasswordDialog: false,

  loading: {},
  errors: {},
};

export const profileReducer = createReducer<ProfileState, RootAction>(
  initialState
)
  .handleAction(showEditProfileDialog, (state, action) => ({
    ...state,
    showEditProfileDialog: true,
  }))
  .handleAction(hideEditProfileDialog, (state, action) => ({
    ...state,
    showEditProfileDialog: false,
  }))
  .handleAction(showChangePasswordDialog, (state, action) => ({
    ...state,
    showChangePasswordDialog: true,
  }))
  .handleAction(hideChangePasswordDialog, (state, action) => ({
    ...state,
    showChangePasswordDialog: false,
  }))
  //update profile
  .handleAction(
    actions.updateProfile.request,
    (state, action): ProfileState => ({
      ...state,
      loading: {
        ...state.loading,
        updateProfile: true,
      },
      errors: {
        ...state.errors,
        updateProfile: undefined,
      },
    })
  )
  .handleAction(
    actions.updateProfile.success,
    (state, action): ProfileState => {
      localStorage.setItem(
        LocalStorageKeys.CurrentUser,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        loading: {
          ...state.loading,
          updateProfile: false,
        },
      };
    }
  )
  .handleAction(
    actions.updateProfile.failure,
    (state, action): ProfileState => ({
      ...state,
      errors: {
        ...state.errors,
        updateProfile: action.payload,
      },
      loading: {
        ...state.loading,
        updateProfile: false,
      },
    })
  )
  //update password
  .handleAction(
    actions.updatePassword.request,
    (state, action): ProfileState => ({
      ...state,
      loading: {
        ...state.loading,
        updatePassword: true,
      },
      errors: {
        ...state.errors,
        updatePassword: undefined,
      },
    })
  )
  .handleAction(actions.updatePassword.success, (state, action) => ({
    ...state,
    loading: {
      ...state.loading,
      updatePassword: false,
    },
  }))
  .handleAction(actions.updatePassword.failure, (state, action) => ({
    ...state,
    errors: {
      ...state.errors,
      updatePassword: action.payload,
    },
    loading: {
      ...state.loading,
      updatePassword: false,
    },
  }));
