import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { hideWorkspaceTour } from "../../../store/tours/actions";
import TooltipComponent from "../TooltipComponent";
import { isDistrictPlan } from "../../../store/plans/selectors";

type OwnProps = {};

type Props = OwnProps;

const DashboardTour: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const show = useSelector((s) => s.tours.showWorkspaceTour);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const dispatch = useDispatch();

  const steps = useMemo((): Step[] => {
    return [
      {
        target: "#root",
        content: intl.formatMessage({
          id: "app.tours.dashboard-tour",
        }),
        placement: "center",
        title: intl.formatMessage({
          id: "app.tours.dashboard-tour.title",
        }),
        disableBeacon: true,
      },
      {
        target: `[data-tour=${
          isDistrictActivePlan
            ? "recent-sci-writing-widget"
            : "leadership-team-widget"
        }]`,
        content: intl.formatMessage({
          id: "app.tours.recent-sci-writing-widget",
        }),
        disableBeacon: true,
      },
      {
        target: `[data-tour=plan-setup-widget]`,
        content: intl.formatMessage({
          id: "app.tours.dashboard-tour.roadmap-widget",
        }),
        disableBeacon: true,
      },
      {
        target: '[data-tour="plan-selector"]',
        content: intl.formatMessage({
          id: "app.tours.plan-selector",
        }),
        disableBeacon: true,
      },
      {
        target: '[data-tour="team-plan-workspaces"]',
        content: intl.formatMessage({
          id: "app.tours.team-plan-workspaces",
        }),
        disableBeacon: true,
      },
      {
        target: '[data-tour="workspace-header-tour"]',
        content: intl.formatMessage({
          id: "app.titles.dashboard-tour.workspace-header",
        }),
        disableBeacon: true,
      },
    ];
  }, [isDistrictActivePlan]);

  const handleJoyrideAction = (data: CallBackProps) => {
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      dispatch(hideWorkspaceTour());
    }
  };

  return (
    <ReactJoyride
      run={!!show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
    />
  );
};

export default DashboardTour;
