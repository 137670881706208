import React, { FunctionComponent } from "react";
import SchoolsTable from "./SchoolsTable";
import SchoolsUpsertDialog from "../../schools-selector-panel/SchoolsUpsertDialog";
import AvailableSchoolsCallout from "./AvailableSchoolsCallout";

type OwnProps = { isTeamPlanPage?: boolean; isSingleSchoolDistrict?: boolean };

type Props = OwnProps;

const DistrictSchools: FunctionComponent<Props> = ({
  isTeamPlanPage,
  isSingleSchoolDistrict,
}: Props) => {
  return (
    <>
      {/* <AvailableSchoolsCallout /> */}
      <SchoolsTable
        isSingleSchoolDistrict={isSingleSchoolDistrict}
        isTeamPlanPage={isTeamPlanPage}
      />
      <SchoolsUpsertDialog
        isSingleSchoolDistrict={isSingleSchoolDistrict}
        isTeamPlanPage={isTeamPlanPage}
      />
    </>
  );
};

export default DistrictSchools;
