import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import { hideManageUsersDialog } from "../../../../store/team-members/actions";
import TeamMembersTable from "../team-members-table/TeamMembersTable";

type OwnProps = {};

type Props = OwnProps;

const ManageUsersDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { show } = useSelector((s) => s.teamMembers.dialogs.manageUsersDialog);

  const handleClose = () => {
    dispatch(hideManageUsersDialog());
  };

  const members = useSelector((s) => s.teamMembers.teamMembers);

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      icon="people"
      title={intl.formatMessage({
        id: "app.team-plan.setup.team.manage-users",
      })}
      style={{ width: "60rem" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <TeamMembersTable members={members} />
      </div>
      <div className={`mt-4 flex justify-center`}>
        <Button className="w-1/4" onClick={handleClose} intent="primary">
          {intl.formatMessage({ id: "app.titles.close" })}
        </Button>
      </div>
    </Dialog>
  );
};

export default ManageUsersDialog;
