import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { DomainAnswerSet, SurveyDemographic } from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { CellProps } from "react-table";
import { Button, Menu, MenuItem, Popover, Tag } from "@blueprintjs/core";
import EntityTable from "../../../common/EntityTable";
import {
  deleteDomainAnswerSet,
  getDomainAnswerSets,
  showDomainAnswerSetUpsertDialog,
} from "../../../../store/domain-answer-set/actions";
import { Locale, LocaleDisplayedValues } from "../../../../store/UIState";
import _ from "lodash";
import { getTranslationLocales } from "../index";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import NoDomainAnswerSets from "./NoDomainAnswerSets";

type OwnProps = {};

type Props = OwnProps;

const DomainAnswerSetsTable: FunctionComponent<Props> = (props) => {
  const domainAnswerSets: DomainAnswerSet[] = useSelector(
    (s) => s.domainsAnswerSet.domainAnswerSets
  );

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDomainAnswerSets.request());
  }, []);

  const showConfirmDeleteDialog = (domainId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteDomainAnswerSet.request(domainId));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete--answer-set",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const columns = useMemo(() => {
    return [
      ...Object.values(SurveyDemographic).map((demographic) => ({
        Header: intl.formatMessage({
          id: `app.filters.respondent-type.${demographic}`,
        }),
        accessor: demographic,
        width: "25%",
        Cell: ({ row: { original } }: CellProps<DomainAnswerSet>) => {
          const options = _.values(original?.[demographic]?.[Locale.English]);

          if (!options.length) {
            return (
              <i>{intl.formatMessage({ id: "app.titles.not-applicable" })}</i>
            );
          }

          return (
            <div className="flex flex-wrap gap-2">
              {options.map((option, index) => (
                <Tag key={index} intent="primary" minimal multiline>
                  {option}
                </Tag>
              ))}
            </div>
          );
        },
      })),
      {
        Header: intl.formatMessage({
          id: "app.titles.translations",
        }),
        accessor: "translations",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<DomainAnswerSet>) => {
          const options = getTranslationLocales(original);

          if (!options.length) {
            return (
              <i>{intl.formatMessage({ id: "app.titles.not-applicable" })}</i>
            );
          }
          return (
            <div className="-m-1">
              {options.map((option) => (
                <Tag
                  key={option.locale}
                  className="m-1"
                  intent={option.filled ? "success" : "warning"}
                  minimal
                >
                  {LocaleDisplayedValues[option.locale as Locale]}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        id: "actions",
        width: "100%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<DomainAnswerSet>) => {
          return (
            <div className="flex -ml-1">
              <Button
                className="whitespace-no-wrap m-1"
                title={intl.formatMessage({
                  id: "app.titles.edit",
                })}
                icon="edit"
                intent="primary"
                onClick={() => {
                  dispatch(
                    showDomainAnswerSetUpsertDialog({
                      domainAnswerSet: original,
                      editing: true,
                    })
                  );
                }}
              />
              <Button
                title={intl.formatMessage({
                  id: "app.surveys-table.columns.delete",
                })}
                icon="trash"
                className="whitespace-no-wrap m-1"
                intent="danger"
                onClick={() => {
                  showConfirmDeleteDialog(original.id);
                }}
              />
            </div>
          );
        },
      },
    ];
  }, []);

  const [searchString, setSearchString] = useState<string>("");

  const loading = useSelector(
    (s) => s.domainsAnswerSet.loading.getDomainAnswerSets
  );

  return (
    <>
      <EntityTable
        data={domainAnswerSets}
        columns={columns}
        searchString={searchString}
        setSearchString={setSearchString}
        loading={loading}
        noDataComp={<NoDomainAnswerSets />}
      >
        <Popover
          position={"bottom-left"}
          className="flex-grow justify-start"
          content={
            <Menu>
              <MenuItem
                text={intl.formatMessage({
                  id: "app.answer-sets.create-new",
                })}
                icon="plus"
                className="px-3 capitalize"
                onClick={() =>
                  dispatch(
                    showDomainAnswerSetUpsertDialog({
                      domainAnswerSet: undefined,
                    })
                  )
                }
              />
            </Menu>
          }
          minimal
        >
          <Button
            large
            minimal
            title={intl.formatMessage({ id: "app.titles.answer-sets" })}
            icon={"properties"}
            text={intl.formatMessage({ id: "app.titles.answer-sets" })}
            intent="primary"
            className="font-bold px-4"
          />
        </Popover>
      </EntityTable>
    </>
  );
};

export default DomainAnswerSetsTable;
