import { createReducer, RootAction } from "typesafe-actions";
import { PlanStrategy } from "../../types";
import actions from "../actions";

type ActionName =
  | "addStrategyToPlan"
  | "updatePlanStrategy"
  | "deletePlanStrategy"
  | "updatePlanStrategyImplementationStatus"
  | "getPlanStrategies"
  | "deletePlanStrategyImplementation"
  | "updatePlanStrategyImplementation";

export type PlanStrategiesState = {
  planStrategies: PlanStrategy[];

  dialogs: {
    viewImplementationLogsDialog: {
      show?: boolean;
      planStrategy?: PlanStrategy;
      onConfirm?: Function;
    };
  };

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: PlanStrategiesState = {
  planStrategies: [],

  dialogs: {
    viewImplementationLogsDialog: {},
  },

  loading: {},
  errors: {},
};

export const planStrategiesReducer = createReducer<
  PlanStrategiesState,
  RootAction
>(initialState)
  // view implementation logs dialog
  .handleAction(
    actions.showViewImplementationLogsDialog,
    (state, action): PlanStrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        viewImplementationLogsDialog: {
          show: true,
          planStrategy: action.payload.planStrategy,
          onConfirm: action.payload.onConfirm,
        },
      },
    })
  )
  .handleAction(
    actions.hideViewImplementationLogsDialog,
    (state, action): PlanStrategiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        viewImplementationLogsDialog: {},
      },
    })
  )
  //add strategy to plan
  .handleAction(
    actions.addStrategyToPlan.request,
    (state, action): PlanStrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        addStrategyToPlan: true,
      },
      errors: {
        ...state.errors,
        addStrategyToPlan: undefined,
      },
    })
  )
  .handleAction(
    actions.addStrategyToPlan.success,
    (state, action): PlanStrategiesState => ({
      ...state,
      planStrategies: [...state.planStrategies, action.payload],
      loading: {
        ...state.loading,
        addStrategyToPlan: false,
      },
    })
  )
  .handleAction(
    actions.addStrategyToPlan.failure,
    (state, action): PlanStrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        addStrategyToPlan: action.payload,
      },
      loading: {
        ...state.loading,
        addStrategyToPlan: false,
      },
    })
  )
  // update plan strategy
  .handleAction(
    actions.updatePlanStrategy.request,
    (state, action): PlanStrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        updatePlanStrategy: true,
      },
      errors: {
        ...state.errors,
        updatePlanStrategy: undefined,
      },
    })
  )
  .handleAction(
    actions.updatePlanStrategy.success,
    (state, action): PlanStrategiesState => ({
      ...state,
      planStrategies: state.planStrategies.map((ps) =>
        ps.id === action.payload.id ? action.payload : ps
      ),
      loading: {
        ...state.loading,
        updatePlanStrategy: false,
      },
    })
  )
  .handleAction(
    actions.updatePlanStrategy.failure,
    (state, action): PlanStrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        updatePlanStrategy: action.payload,
      },
      loading: {
        ...state.loading,
        updatePlanStrategy: false,
      },
    })
  )
  //delete plan strategy
  .handleAction(
    actions.deletePlanStrategy.request,
    (state, action): PlanStrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        deletePlanStrategy: true,
      },
      errors: {
        ...state.errors,
        deletePlanStrategy: undefined,
      },
    })
  )
  .handleAction(
    actions.deletePlanStrategy.success,
    (state, action): PlanStrategiesState => ({
      ...state,
      planStrategies: state.planStrategies?.filter(
        (ps) => ps.id !== action.payload
      ),
      loading: {
        ...state.loading,
        deletePlanStrategy: false,
      },
    })
  )
  .handleAction(
    actions.deletePlanStrategy.failure,
    (state, action): PlanStrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        deletePlanStrategy: action.payload,
      },
      loading: {
        ...state.loading,
        deletePlanStrategy: false,
      },
    })
  )
  //update plan strategy implementation status
  .handleAction(
    actions.updatePlanStrategyImplementationStatus.request,
    (state, action): PlanStrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        updatePlanStrategyImplementationStatus: true,
      },
      errors: {
        ...state.errors,
        updatePlanStrategyImplementationStatus: undefined,
      },
    })
  )
  .handleAction(
    actions.updatePlanStrategyImplementationStatus.success,
    (state, action): PlanStrategiesState => {
      const updatedState = {
        ...state,
        planStrategies: state.planStrategies.map((ps) =>
          ps.id === action.payload.plan_strategy
            ? {
                ...ps,
                implementations: [...ps.implementations, action.payload],
              }
            : ps
        ),
        loading: {
          ...state.loading,
          updatePlanStrategyImplementationStatus: false,
        },
      };
      if (updatedState.dialogs.viewImplementationLogsDialog?.show) {
        const planStrategy =
          updatedState.dialogs.viewImplementationLogsDialog?.planStrategy;
        if (planStrategy) {
          return {
            ...updatedState,
            dialogs: {
              ...updatedState.dialogs,
              viewImplementationLogsDialog: {
                ...updatedState.dialogs.viewImplementationLogsDialog,
                planStrategy: {
                  ...planStrategy,
                  implementations: [
                    ...planStrategy.implementations,
                    action.payload,
                  ],
                },
              },
            },
          };
        }
      }
      return updatedState;
    }
  )
  .handleAction(
    actions.updatePlanStrategyImplementationStatus.failure,
    (state, action): PlanStrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        updatePlanStrategyImplementationStatus: action.payload,
      },
      loading: {
        ...state.loading,
        updatePlanStrategyImplementationStatus: false,
      },
    })
  )
  //get plan strategies
  .handleAction(
    actions.getPlanStrategies.request,
    (state, action): PlanStrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        getPlanStrategies: true,
      },
      errors: {
        ...state.errors,
        getPlanStrategies: undefined,
      },
    })
  )
  .handleAction(
    actions.getPlanStrategies.success,
    (state, action): PlanStrategiesState => ({
      ...state,
      planStrategies: action.payload,
      loading: {
        ...state.loading,
        getPlanStrategies: false,
      },
    })
  )
  .handleAction(
    actions.getPlanStrategies.failure,
    (state, action): PlanStrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        getPlanStrategies: action.payload,
      },
      loading: {
        ...state.loading,
        getPlanStrategies: false,
      },
    })
  )
  //delete plan strategy implementation
  .handleAction(
    actions.deletePlanStrategyImplementation.request,
    (state, action): PlanStrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        deletePlanStrategyImplementation: true,
      },
      errors: {
        ...state.errors,
        deletePlanStrategyImplementation: undefined,
      },
    })
  )
  .handleAction(
    actions.deletePlanStrategyImplementation.success,
    (state, action): PlanStrategiesState => {
      if (state.dialogs.viewImplementationLogsDialog?.show) {
        const planStrategy =
          state.dialogs.viewImplementationLogsDialog?.planStrategy;
        if (planStrategy) {
          return {
            ...state,
            dialogs: {
              ...state.dialogs,
              viewImplementationLogsDialog: {
                ...state.dialogs.viewImplementationLogsDialog,
                planStrategy: {
                  ...planStrategy,
                  implementations: planStrategy.implementations?.filter(
                    (impl) => impl.id !== action.payload
                  ),
                },
              },
            },
            loading: {
              ...state.loading,
              deletePlanStrategyImplementation: false,
            },
          };
        }
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          deletePlanStrategyImplementation: false,
        },
      };
    }
  )
  .handleAction(
    actions.deletePlanStrategyImplementation.failure,
    (state, action): PlanStrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        deletePlanStrategyImplementation: action.payload,
      },
      loading: {
        ...state.loading,
        deletePlanStrategyImplementation: false,
      },
    })
  )
  //update plan strategy implementation
  .handleAction(
    actions.updatePlanStrategyImplementation.request,
    (state, action): PlanStrategiesState => ({
      ...state,
      loading: {
        ...state.loading,
        updatePlanStrategyImplementation: true,
      },
      errors: {
        ...state.errors,
        updatePlanStrategyImplementation: undefined,
      },
    })
  )
  .handleAction(
    actions.updatePlanStrategyImplementation.success,
    (state, action): PlanStrategiesState => {
      if (state.dialogs.viewImplementationLogsDialog?.show) {
        const planStrategy =
          state.dialogs.viewImplementationLogsDialog?.planStrategy;
        if (planStrategy) {
          return {
            ...state,
            dialogs: {
              ...state.dialogs,
              viewImplementationLogsDialog: {
                ...state.dialogs.viewImplementationLogsDialog,
                planStrategy: {
                  ...planStrategy,
                  implementations: planStrategy.implementations?.map((impl) =>
                    impl.id === action.payload.implementation
                      ? action.payload.response
                      : impl
                  ),
                },
              },
            },
            loading: {
              ...state.loading,
              updatePlanStrategyImplementation: false,
            },
          };
        }
      }
      return {
        ...state,
        loading: {
          ...state.loading,
          updatePlanStrategyImplementation: false,
        },
      };
    }
  )
  .handleAction(
    actions.updatePlanStrategyImplementation.failure,
    (state, action): PlanStrategiesState => ({
      ...state,
      errors: {
        ...state.errors,
        updatePlanStrategyImplementation: action.payload,
      },
      loading: {
        ...state.loading,
        updatePlanStrategyImplementation: false,
      },
    })
  );
