import { AnchorButton, Card, IconName, Tooltip } from "@blueprintjs/core";
import React, {
    FunctionComponent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import logo from "../../../../static/images/nj-sci-logo.png";
import { showNotesDialog } from "../../../../store/notes/actions";
import MeetingDialog from "../../../../components/team-plan/meetings/dialogs/MeetingDialog";
import StrategyDetailsDialog from "../../../../components/team-plan/strategies/add-plan-strategy-dialog/StrategyDetailsDialog";
import UpdateImplementationStatusDialog from "../../../../components/team-plan/strategies/update-implementation-status-dialog/UpdateImplementationStatusDialog";
import UpdatePlanStrategyDetailsDialog from "../../../../components/team-plan/strategies/update-plan-strategy-details-dialog/UpdatePlanStrategyDetailsDialog";
import ViewImplementationLogsDialog from "../../../../components/team-plan/strategies/view-implementation-logs-dialog/ViewImplementationLogsDialog";
import MenuFilter from "../menu-filter-bar/MenuFilter";
import UserSpan from "../UserSpan";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import useWorkspacesLock from "../../../../helpers/hooks/useWorkspacesLock";
import { getPlans } from "../../../../store/plans/actions";
import { PlanWorkspaceButton, PlanWorkspaces, UserRole } from "../../../../types";
import { getTeamMembersByPlan } from "../../../../store/team-members/actions";
import { resetNewGoalState } from "../../../../store/goals/actions";
import { changeSurveyDeploymentIdOnNeedsPage } from "../../../../store/needs/actions";
import { initDistrictSetupState } from "../../../../store/tours/actions";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import svgIcons from "../../svg-icons/svg-icons.json";

import './MenuList.scss';
import { AuthRoutes } from "../../../../App";

type OwnProps = {};

type Props = OwnProps;

enum CategoriesSection {
  General = "general",
  Explore = "explore",
  Manage = "manage"
}

const MenuList: FunctionComponent<Props> = (props) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const location = useLocation();

    const isActiveDistrictPlan = useSelector(isDistrictPlan);

    const plan =useSelector((s)=>s.plans?.activePlan);

    const me = useSelector((s) => s.auth.authenticatedUser);

    const {setupCompleted}=useSelector((s)=>s.tours)

  const { role, isDCC,isSuperintendent } = useUserRole();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const activePlan = useSelector((s) => s.plans.activePlan);
    useEffect(() => {
        dispatch(getPlans.request());
    }, []);

    useEffect(() => {
        if (activePlan?.id && me?.id) {
            dispatch(initDistrictSetupState({ plan: activePlan, me: me }));
        }
    }, [activePlan, me]);

  const { workspace: currentWorkspaceKey, tabId } = useParams<{
        workspace?: string;
        setupStep?: string;
    tabId?: string;
  }>();

  const workspaces: (PlanWorkspaceButton | null)[] = useMemo(() => {
    return [
      {
        title: intl.formatMessage({ id: "app.titles.dashboard" }),
        icon: "grid-view",
        // dataTour: "dashboard-workspace-button",
        workspace_keys: [PlanWorkspaces.Dashboard],
        hidden: false,
        category: CategoriesSection.General
      },
      null,
      {
        title: intl.formatMessage({
          id: `app.titles.${isActiveDistrictPlan ? "district-" : "school-"}team`,
        }),
        icon: "people",
        workspace_keys: [PlanWorkspaces.Team],
        disabled: !activePlan,
        hidden: false,
        category: CategoriesSection.General
      },
      {
        title: intl.formatMessage({
          id: "app.titles.calendar",
        }),
        icon: "calendar",
        workspace_keys: [PlanWorkspaces.Calendar],
        dataTour:"setup-calendar-button",
        disabled: false,
        hidden: false,
        category: CategoriesSection.General
      },
      {
        title: intl.formatMessage({
          id: `app.titles.data-collection-and-reports${isActiveDistrictPlan ? "-district" : ""}`,
        }),
        icon: "horizontal-bar-chart",
        workspace_keys: [
          PlanWorkspaces.Data,
          PlanWorkspaces.Report,
          PlanWorkspaces.SurveyQuestions,
        ],
        disabled: !activePlan,
        className: "tracking-tighter",
        hidden: false,
        category: CategoriesSection.General
      },
      {
        title: intl.formatMessage({ id: "app.titles.needs" }),
        workspace_keys: [PlanWorkspaces.Needs],
        disabled: !activePlan,
        hidden: isActiveDistrictPlan,
        category: CategoriesSection.General,
        svgIcon: <svg
          width='19'
          height='18'
          viewBox='0 0 19 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          dangerouslySetInnerHTML={{
            __html: svgIcons.need
          }} ></svg>
      },
      {
        title: intl.formatMessage({ id: "app.titles.strategic-plan" }),
        icon: "chart",
        workspace_keys: [PlanWorkspaces.Goals],
        disabled: !activePlan,
        hidden: isActiveDistrictPlan,
        category: CategoriesSection.General
      },
      {
        title: intl.formatMessage({ id: "app.titles.learning" }),
        icon: "learning",
        workspace_keys: [PlanWorkspaces.Learning],
        disabled: !activePlan,
        hidden: false,
        category: CategoriesSection.Explore
      },
      {
        title: intl.formatMessage({ id: "app.titles.resources" }),
        icon: "folder-close",
        workspace_keys: [PlanWorkspaces.Resources],
        disabled: !activePlan,
        hidden: false,
        category: CategoriesSection.Explore
      },
      {
        title: intl.formatMessage({
          id: "app.titles.manage-district-label",
        }),
        dataTour: "manage-school-users",
        workspace_keys: [PlanWorkspaces.MyDistrict],
        disabled: false,
        hidden: false,
        category: CategoriesSection.Manage,
        svgIcon: <svg
          width='19'
          height='18'
          viewBox='0 0 19 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          dangerouslySetInnerHTML={{
            __html: svgIcons.manage_school
          }} ></svg>
      },
      {
        title: intl.formatMessage({
          id: "app.titles.manage-deleted-data-label",
        }),
        icon: "trash",
        workspace_keys: [PlanWorkspaces.ManageDeletedData],
        disabled: !activePlan,
        hidden: !isActiveDistrictPlan || role !== UserRole.Superintendent,
        category: CategoriesSection.Manage
      }
    ];
  }, [intl, isActiveDistrictPlan, activePlan, role, isDCC, dispatch]);

  const displayedWorkspaces: (PlanWorkspaceButton | null)[] = useMemo(() => {
    return workspaces.filter((w) => !w?.hidden);
  }, [workspaces]);

  const { workspacesWithLocks } = useWorkspacesLock({
    workspaces: displayedWorkspaces,
  });

  const groupedWorkspacesWithLocks: any = workspacesWithLocks.reduce((acc: any, item) => {
    if (item !== null && item.category !== undefined) {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
    }
    return acc;
  }, {});

    const loading = useSelector((s) => s.plans.loading.getPlans);
    const error = useSelector((s) => s.plans.errors.getPlans);
    useLoading({ loading, error });

    const ref = useRef<any>();
    useEffect(() => {
        if (ref.current) {
            ref.current.style.overflow = undefined;
            ref.current.style.overflowX = "hidden";
            ref.current.style.overflowY = "auto";
        }
    }, [location]);

  const handleNavigate = (workspace?: PlanWorkspaceButton | null) => {
    if (activePlan?.id) {
      dispatch(getTeamMembersByPlan.request({ planId: activePlan.id }));
    }
    if (workspace && workspace.workspace_keys[0] === PlanWorkspaces.Calendar) {
      workspace.onClick
        ? workspace.onClick()
        : history.push(`/plan/team/${isActiveDistrictPlan ? 'dclt_sci_' : 'sclt_sci_'}${workspace.workspace_keys[0]}`, {
          fromWorkSpace: currentWorkspaceKey
        });
    }
    else if (workspace) {
        workspace.onClick
          ? workspace.onClick()
          : history.push(`/plan/${workspace.workspace_keys[0]}`, {
            fromWorkSpace: currentWorkspaceKey
          });
      }
    };

    useEffect(() => {
        handleNavigate();
        dispatch(changeSurveyDeploymentIdOnNeedsPage(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan]);

    
  // useEffect(() => {
  
  //   if(!isDCC){
  //     history.push(
  //     generatePath(AuthRoutes.YourPlan, {
  //       workspace: "dashboard",
  //     } as any)
  //   );}
    
  // }, [isDCC]);

    return (
        <>
        {plan && <div className="site-navigation" >
          <div className="logo">
            <img src={logo} alt="NJ SCI Platform" />
          </div>
        
          <div data-tour="plan-selector">
            <MenuFilter activePlan={activePlan} />
          </div> 

            <div className="menu-list">
            {groupedWorkspacesWithLocks[CategoriesSection.General].map((workspace: PlanWorkspaceButton | null | undefined, index: React.Key | null | undefined) => {
                if (!workspace) {
                  return false;
                }

                const selected =
                  currentWorkspaceKey &&
                  workspace.workspace_keys.includes(
                    currentWorkspaceKey as PlanWorkspaces
                  );

                return (
                  <Tooltip
                    key={index}
                    disabled={!workspace.popoverMessage}
                    interactionKind="hover"
                    content={workspace.popoverMessage}
                    boundary={"viewport"}
                  >
                    <AnchorButton
                      className={workspace.className}
                      disabled={setupCompleted? workspace.disabled : false}
                      data-tour={workspace.dataTour}
                      large
                      alignText="left"
                      icon={setupCompleted && workspace.disabled ? "lock" : workspace.icon}
                      // icon={workspace.icon}
                      intent={
                        tabId === 'sclt_sci_calendar' || tabId === 'dclt_sci_calendar'
                          ? workspace.workspace_keys[0] === PlanWorkspaces.Calendar
                            ? "primary"
                            : undefined
                          : selected
                            ? "primary"
                            : undefined
                      }
                      onClick={() => handleNavigate(workspace)}

                    >                      
                      {workspace.svgIcon} {workspace.title}
                    </AnchorButton>
                  </Tooltip>
                );
              })}
           
         
         <div className="menu-accordion">
              <Accordion expanded={expanded === CategoriesSection.Explore} onChange={handleChange(CategoriesSection.Explore)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="explore-panel-content"
                  id="explore-panel-header"
                >
                  {intl.formatMessage({
                    id: "app.title.explore",
                  })}
                </AccordionSummary>

                <AccordionDetails>
                  {groupedWorkspacesWithLocks[CategoriesSection.Explore].map((workspace: PlanWorkspaceButton | null | undefined, index: React.Key | null | undefined) => {
                    if (!workspace) {
                      return false;
                    }

                    const selected =
                      currentWorkspaceKey &&
                      workspace.workspace_keys.includes(
                        currentWorkspaceKey as PlanWorkspaces
                      );

                    return (
                      <Tooltip
                        key={index}
                        disabled={!workspace.popoverMessage}
                        interactionKind="hover"
                        content={workspace.popoverMessage}
                        boundary={"viewport"}
                      >
                        <AnchorButton
                          className={workspace.className}
                          disabled={setupCompleted? workspace.disabled : false}
                          data-tour={workspace.dataTour}
                          large
                          alignText="left"
                          icon={setupCompleted && workspace.disabled ? "lock" : workspace.icon}
                          // icon={workspace.icon}

                          intent={selected ? "primary" : undefined}
                          onClick={() => handleNavigate(workspace)}
                        >
                          {workspace.svgIcon} {workspace.title}
                        </AnchorButton>
                      </Tooltip>
                    );
                  })}
                </AccordionDetails>
              </Accordion>

              {
                (isActiveDistrictPlan && (role === UserRole.Superintendent || isDCC) ) && (
                  setupCompleted ?
                  <Accordion expanded={expanded === CategoriesSection.Manage } onChange={handleChange(CategoriesSection.Manage)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="manage-panel-content"
                      id="manage-panel-header"
                    >
                      {intl.formatMessage({
                        id: "app.title.manage",
                      })}
                    </AccordionSummary>

                    <AccordionDetails>
                      {groupedWorkspacesWithLocks[CategoriesSection.Manage].map((workspace: PlanWorkspaceButton | null | undefined, index: React.Key | null | undefined) => {
                        if (!workspace) {
                          return false;
                        }

                        const selected =
                          currentWorkspaceKey &&
                          workspace.workspace_keys.includes(
                            currentWorkspaceKey as PlanWorkspaces
                          );

                        return (
                          <Tooltip
                            key={index}
                            disabled={!workspace.popoverMessage}
                            interactionKind="hover"
                            content={workspace.popoverMessage}
                            boundary={"viewport"}
                          >
                            <AnchorButton
                              className={workspace.className}
                              disabled={setupCompleted? workspace.disabled : false}
                              data-tour={workspace.dataTour}
                              large
                              alignText="left"
                              icon={setupCompleted && workspace.disabled ? "lock" : workspace.icon}
                              // icon={workspace.icon}
                              intent={selected ? "primary" : undefined}
                              onClick={() => handleNavigate(workspace)}
                            >
                              {workspace.svgIcon} {workspace.title}
                            </AnchorButton>
                          </Tooltip>
                        );
                      })}
                    </AccordionDetails>
                    </Accordion> :
                  <Accordion expanded={expanded === CategoriesSection.Manage ||  isSuperintendent===true || isDCC===true } onChange={handleChange(CategoriesSection.Manage)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="manage-panel-content"
                    id="manage-panel-header"
                  >
                    {intl.formatMessage({
                      id: "app.title.manage",
                    })}
                  </AccordionSummary>

                  <AccordionDetails>
                    {groupedWorkspacesWithLocks[CategoriesSection.Manage].map((workspace: PlanWorkspaceButton | null | undefined, index: React.Key | null | undefined) => {
                      if (!workspace) {
                        return false;
                      }

                      const selected =
                        currentWorkspaceKey &&
                        workspace.workspace_keys.includes(
                          currentWorkspaceKey as PlanWorkspaces
                        );

                      return (
                        <Tooltip
                          key={index}
                          disabled={!workspace.popoverMessage}
                          interactionKind="hover"
                          content={workspace.popoverMessage}
                          boundary={"viewport"}
                        >
                          <AnchorButton
                            className={workspace.className}
                            disabled={setupCompleted? workspace.disabled : false}
                            data-tour={workspace.dataTour}
                            large
                            alignText="left"
                            icon={setupCompleted && workspace.disabled ? "lock" : workspace.icon}
                            // icon={workspace.icon}
                            intent={selected ? "primary" : undefined}
                            onClick={() => handleNavigate(workspace)}
                          >
                            {workspace.svgIcon} {workspace.title}
                          </AnchorButton>
                        </Tooltip>
                      );
                    })}
                  </AccordionDetails>
                </Accordion> 
                )
              }

         </div>
          </div>
        </div>}

            <MeetingDialog />
            <UpdateImplementationStatusDialog />
            <UpdatePlanStrategyDetailsDialog />
            <StrategyDetailsDialog />
            <ViewImplementationLogsDialog />
        </>
    );
};

export default MenuList
