import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getStrategiesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getStrategies.request)),
    switchMap((action) =>
      from(api.strategies.getStrategies(action?.payload?.planId || undefined)).pipe(
        map(actions.getStrategies.success),
        catchError((error) => of(actions.getStrategies.failure(error)))
      )
    )
  );
};

export const addStrategyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addStrategy.request)),
    switchMap((action) =>
      from(
        api.strategies.addStrategy(
          action.payload.strategy,
          action.payload.planId
        )
      ).pipe(
        mergeMap((strategy) =>
          from(
            [actions.addStrategy.success(strategy)],
            action.payload.onSuccess && action.payload.onSuccess(strategy)
          )
        ),
        catchError((error) => of(actions.addStrategy.failure(error)))
      )
    )
  );
};

export const updateStrategyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateStrategy.request)),
    switchMap((action) =>
      from(api.strategies.updateStrategy(action.payload.updatedStrategy.id, action.payload.updatedStrategy)).pipe(
        mergeMap((strategy) =>
          from(
            [actions.updateStrategy.success(strategy)],
            action.payload.onSuccess && action.payload.onSuccess(strategy)
          )
        ),
        catchError((error) => of(actions.updateStrategy.failure(error)))
      )
    )
  );
};

export const deleteStrategyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteStrategy.request)),
    switchMap((action) =>
      from(api.strategies.deleteStrategy(action.payload.strategyId)).pipe(
        mergeMap(() =>
          from(
            [actions.deleteStrategy.success(action.payload.strategyId)],
            action.payload.onSuccess && action.payload.onSuccess()
          )
        ),
        catchError((error) => of(actions.deleteStrategy.failure(error)))
      )
    )
  );
};
