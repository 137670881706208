import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import {
  DomainPriorityTriageStatus,
  SurveyDemographic,
} from "../../../../types";
import {
  addDomainPriority,
  updateDomainPriority,
} from "../../../../store/needs/actions";

type Props = {
  domainId: number;
  surveyDeploymentId?: number;
  demographic: SurveyDemographic;
};

export const useDomainPriority = (props: Props) => {
  const { domainId, surveyDeploymentId, demographic } = props;

  const dispatch = useDispatch();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const domainPriorities = useSelector((s) => s.needs.domainPriorities);

  const relatedDomainPriority = useMemo(() => {
    return domainPriorities.find(
      (dM) => dM.domain.id === domainId && dM.demographic === demographic
    );
  }, [domainPriorities, domainId, demographic]);

  const { archived, prioritized } = useMemo(() => {
    return {
      archived:
        relatedDomainPriority?.triage_status ===
        DomainPriorityTriageStatus.Archived,
      prioritized:
        relatedDomainPriority?.triage_status ===
        DomainPriorityTriageStatus.PotentialPriority,
    };
  }, [relatedDomainPriority?.triage_status]);

  const [loading, setLoading] = useState<boolean>(false);

  const onClick = ({
    checked,
    triageStatus,
  }: {
    checked: boolean;
    triageStatus: DomainPriorityTriageStatus;
  }) => {
    if (activePlanId && surveyDeploymentId) {
      setLoading(true);
      if (relatedDomainPriority) {
        dispatch(
          updateDomainPriority.request({
            id: relatedDomainPriority.id,
            plan: activePlanId,
            triage_status: checked ? null : triageStatus,
            onComplete: () => {
              setLoading(false);
            },
          })
        );
      } else {
        dispatch(
          addDomainPriority.request({
            plan: activePlanId,
            survey_deployment: surveyDeploymentId,
            domain: domainId,
            demographic: demographic,
            triage_status: triageStatus,
            decision: null,
            onComplete: () => {
              setLoading(false);
            },
          })
        );
      }
    }
  };

  const onArchiveClick = () => {
    onClick({
      checked: archived,
      triageStatus: DomainPriorityTriageStatus.Archived,
    });
  };
  const onPotentialPriorityClick = () => {
    onClick({
      checked: prioritized,
      triageStatus: DomainPriorityTriageStatus.PotentialPriority,
    });
  };

  return {
    loading: loading,
    archived: archived,
    prioritized: prioritized,
    relatedDomainPriority: relatedDomainPriority,
    onArchiveClick: onArchiveClick,
    onPotentialPriorityClick: onPotentialPriorityClick,
  };
};

export default useDomainPriority;
