import React, { FunctionComponent, useMemo } from "react";
import { TeamRole } from "../../../types";
import Select from "react-select";
import { useIntl } from "react-intl";
import useUserRole from "../../../helpers/hooks/useUserRole";
import { smallSelectorStyles } from "../../common/selectors";

type OwnProps = {
  role?: TeamRole;
  onChange: (role: TeamRole) => void;
  disabled?: boolean;
  isLoading?: boolean;
  minimal?: boolean;
};

type Props = OwnProps;

const TeamRoleSelector: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { role, onChange, disabled, isLoading, minimal = true } = props;

  const { teamRole, isDCC, isSuperintendent, isPrincipal } = useUserRole();

  const roles = useMemo(() => {
    const teamRoles = Object.values(TeamRole);

    return teamRoles
      .slice(
        isDCC ||
          isSuperintendent ||
          isPrincipal ||
          teamRole === TeamRole.TeamChair
          ? 0
          : teamRole && role
          ? teamRoles.indexOf(role)
          : 0,
        teamRoles.length
      )
      .map((role) => ({
        label: intl.formatMessage(
          { id: `app.team-plan.team.roles.${role}` },
          { role: role }
        ),
        value: role,
      }))
      .filter((data) => data.value !== TeamRole.TeamChampion);
  }, [isDCC, teamRole]);

  const selectedValue = useMemo(() => {
    return role ? roles.find((r) => r.value == role) : undefined;
  }, [role, roles]);

  return (
    <Select
      id="enabled"
      isLoading={isLoading}
      disabled={disabled}
      styles={minimal ? smallSelectorStyles : undefined}
      options={roles}
      value={selectedValue}
      onChange={(value) => onChange(value?.value as any)}
      menuPortalTarget={document.querySelector("body") as any}
    />
  );
};

export default TeamRoleSelector;
