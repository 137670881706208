import React, { FunctionComponent, useMemo } from "react";
import { Button, Tooltip } from "@blueprintjs/core";
import { toggleDomainLockById } from "../../../../store/needs/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

type OwnProps = {
  locked: boolean;
  domainId: number;
};

type Props = OwnProps;

const DomainEyeIcon: FunctionComponent<Props> = (props) => {
  const { locked, domainId } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const tooltipContent = useMemo(() => {
    return intl.formatMessage({
      id: locked
        ? "app.needs.tabs.explore-strengths-and-needs.eye-popover.domain.unhighlight"
        : "app.needs.tabs.explore-strengths-and-needs.eye-popover.domain.highlight",
    });
  }, [locked]);

  return (
    <Tooltip interactionKind="hover" content={tooltipContent}>
      <Button
        icon={"eye-open"}
        intent={locked ? "success" : undefined}
        className={`eye-icon-button ${locked ? undefined : "hidden"}`}
        minimal
        onClick={(e) => {
          e.stopPropagation();
          dispatch(toggleDomainLockById(domainId));
        }}
      />
    </Tooltip>
  );
};

export default DomainEyeIcon;
