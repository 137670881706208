import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivities,
  showActivityDialog,
} from "../../store/activities/actions";
import { useLoading } from "../../helpers/hooks/useLoading";
import { Button, InputGroup, NonIdealState, Spinner } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import ActivitiesList from "./ActivitiesList";
import ActivityDialog from "./dialogs/upsert-activity-dialog/ActivityDialog";
import ViewActivityContentDialog from "../team-plan/members/activity-video-dialog/ViewActivityContentDialog";
import { Activity, ActivityScopeEnum } from "../../types";

type OwnProps = {
  displayNavigationPanel?: boolean;
  selectedActivities?: number[];
  type?: ActivityScopeEnum;
  onActivityClick: (activity: Activity) => void;
};

type Props = OwnProps;

const ActivitiesTab: React.FC<Props> = ({
  displayNavigationPanel = true,
  selectedActivities,
  type,
  onActivityClick,
}: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const loading = useSelector(
    (s) =>
      s.activities.loading.getActivities || s.activities.loading.deleteActivity
  );
  const error = useSelector((s) => s.activities.errors.getActivities);
  useLoading({ loading, error });

  const activities = useSelector((s) => s.activities.activities);
  useEffect(() => {
    dispatch(getActivities.request({ type: type }));
  }, [type]);

  const [searchString, setSearchString] = useState("");

  const displayedActivities = useMemo(() => {
    return activities?.filter((a) =>
      a.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    );
  }, [activities, searchString]);

  const handleAddActivityClick = () => {
    dispatch(showActivityDialog({}));
  };

  if (loading) {
    return <Spinner intent="primary" className="mt-4" />;
  }

  return (
    <div>
      {displayNavigationPanel && (
        <div className="flex justify-between">
          <Button
            intent="primary"
            large
            text={intl.formatMessage({
              id: "app.activities.add-activity",
            })}
            title={intl.formatMessage({
              id: "app.activities.add-activity",
            })}
            onClick={handleAddActivityClick}
          />
          <InputGroup
            type="search"
            large
            placeholder="Search Activities..."
            value={searchString}
            onChange={(e) => setSearchString(e.target?.value)}
          />
        </div>
      )}
      {displayedActivities?.length ? (
        <ActivitiesList
          activities={displayedActivities}
          selectedActivities={selectedActivities}
          onActivityClick={onActivityClick}
        />
      ) : (
        <NonIdealState
          className="mt-4"
          title={intl.formatMessage({
            id: searchString
              ? "app.tables.no-matching-data"
              : "app.non-ideal-state.activities.title",
          })}
          description={intl.formatMessage({
            id: searchString
              ? "app.tables.button-text.cleat-search-string"
              : "app.non-ideal-state.activities.description",
          })}
          icon="zoom-out"
          action={
            <Button
              text={intl.formatMessage({
                id: searchString
                  ? "app.tables.button-text.cleat-search-string"
                  : "app.activities.add-activity",
              })}
              title={intl.formatMessage({
                id: searchString
                  ? "app.tables.button-text.cleat-search-string"
                  : "app.activities.add-activity",
              })}
              onClick={() =>
                searchString ? setSearchString("") : handleAddActivityClick()
              }
              intent="primary"
            />
          }
        />
      )}

      <ActivityDialog />
      <ViewActivityContentDialog isResourceTab={true} />
    </div>
  );
};

export default ActivitiesTab;
