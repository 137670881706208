import React, { FunctionComponent, useMemo } from "react";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { SurveyDemographic } from "../../../types";
import { useIntl } from "react-intl";

type OwnProps = {
  selectedDemographic: SurveyDemographic;
  setSelectedDemographic: React.Dispatch<
    React.SetStateAction<SurveyDemographic>
  >;
};

type Props = OwnProps;

const DemographicButtonGroup: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { selectedDemographic, setSelectedDemographic } = props;

  const translatedSurveyDemographic = useMemo(() => {
    return Object.values(SurveyDemographic).map((item) => ({
      value: item,
      title: intl.formatMessage({ id: `app.filters.respondent-type.${item}` }),
    }));
  }, []);

  return (
    <ButtonGroup fill large>
      {translatedSurveyDemographic.map((item) => (
        <Button
          key={item.value}
          text={item.title}
          className="text-center flex-basis-0"
          intent={selectedDemographic === item.value ? "primary" : undefined}
          onClick={() => setSelectedDemographic(item.value)}
        />
      ))}
    </ButtonGroup>
  );
};

export default DemographicButtonGroup;
