import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { EditableText, HTMLTable, Spinner } from "@blueprintjs/core";
import {
  OpenTimePeriod,
  School,
  SurveyDemographic,
  SurveyDeploymentPassword,
} from "../../../types";
import { useIntl } from "react-intl";
import _ from "lodash";
import NoSchools from "../../pages/NoSchools";
import useSchoolPlanDemographics from "../../../helpers/hooks/useSchoolPlanDemographics";
import { getAvailableDemographics } from "../../../helpers/survey-deployment-utils";

type OwnProps = {
  districtIds: number[];
  isGlobal: boolean;
  passwords: Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[];
  setPasswords: React.Dispatch<
    React.SetStateAction<
      Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[]
    >
  >;
  isTeamPlanDialog?: boolean;
  schools: School[];
  openTimePeriods: OpenTimePeriod[];
};

type Props = OwnProps;

const DEFAULT_PASSWORDS: { [key in SurveyDemographic]: string } = {
  [SurveyDemographic.ElementaryStudents]: "student35",
  [SurveyDemographic.Students]: `student612`,
  [SurveyDemographic.SchoolStaff]: "staff",
  [SurveyDemographic.Parents]: "parent",
};

const SurveyDeploymentPasswords: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const {
    passwords,
    setPasswords,
    schools,
    isTeamPlanDialog,
    openTimePeriods,
  } = props;

  const schoolsDictionary: {
    [school: number]: School;
  } = useMemo(() => {
    return _.chain(schools)
      .keyBy((s) => s.id!)
      .value();
  }, [schools]);

  const activePlanSchool = useSelector((s) => s.plans.activePlan?.school);
  const { demographics } = useSchoolPlanDemographics({
    grades: isTeamPlanDialog ? activePlanSchool?.grades : undefined,
  });

  const availableDemographics = useMemo(
    () =>
      getAvailableDemographics({
        availableDemographicsInPlan: demographics,
        schoolOpenPeriods: openTimePeriods,
      }),
    [demographics, openTimePeriods]
  );

  useEffect(() => {
    const rows: Omit<
      SurveyDeploymentPassword,
      "created_at" | "updated_at"
    >[] = schools.flatMap((school) =>
      availableDemographics.map((demographic) => {
        const existingPassword = passwords.find(
          (pass) =>
            pass.school === school.id && pass.demographic === demographic
        );

        if (existingPassword) {
          return {
            id: existingPassword.id,
            school: school.id,
            demographic: existingPassword.demographic,
            password: existingPassword.password,
          };
        }

        const defaultPassword = DEFAULT_PASSWORDS[demographic];

        return {
          id: +_.uniqueId() * -1,
          school: school.id,
          demographic: demographic,
          password: defaultPassword,
        };
      })
    );
    setPasswords(rows);
  }, [schools, availableDemographics]);

  const loading = useSelector(
    (s) =>
      s.schools.loading.getSchoolsByDistricts || s.schools.loading.getSchools
  );

  const handleConfirmSurveyPasswordChange = (surveyPasswordId: number) => (
    value: string
  ) => {
    setPasswords((passwords) =>
      passwords.map((p) =>
        p.id === surveyPasswordId ? { ...p, password: value } : p
      )
    );
  };

  return (
    <div>
      <div className="mb-2">
        {intl.formatMessage({ id: "app.surveys.passwords.info" })}
      </div>

      <div className="mb-4">
        <HTMLTable
          className="w-full table-fixed mt-3 bg-white border border-gray-400"
          striped
          bordered
          condensed
        >
          <thead>
            <tr>
              <th>
                {intl.formatMessage({
                  id: "app.titles.school",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "app.titles.stakeholder-group",
                })}
              </th>
              <th>
                {intl.formatMessage({
                  id: "app.titles.password",
                })}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <Spinner intent="primary" />
                </td>
              </tr>
            ) : schools.length ? (
              passwords.map((pass) => (
                <tr key={`school_${pass.id}`}>
                  <td>{schoolsDictionary[pass.school]?.name}</td>
                  <td>
                    {intl.formatMessage({
                      id: `app.filters.respondent-type.${pass.demographic}`,
                    })}
                  </td>
                  <td>
                    <EditableText
                      className="w-full"
                      defaultValue={pass.password}
                      onConfirm={handleConfirmSurveyPasswordChange(pass.id)}
                      maxLength={25}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>
                  <NoSchools hasAction={false} />
                </td>
              </tr>
            )}
          </tbody>
        </HTMLTable>
      </div>
    </div>
  );
};

export default SurveyDeploymentPasswords;
