import React, { FunctionComponent } from "react";
import { Button, Card, NonIdealState } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { addPlan } from "../../store/plans/actions";
import { useLoading } from "../../helpers/hooks/useLoading";

type OwnProps = {};

type Props = OwnProps;

const TeamPlanWelcome: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const user = useSelector((s) => s.auth.authenticatedUser);

  const loading = useSelector((s) => s.plans.loading.addPlan);
  const error = useSelector((s) => s.plans.errors.addPlan);
  useLoading({ loading, error });

  const handleMyPlanStart = () => {
    dispatch(addPlan.request());
  };
  const handleNotReadyClick = () => {
    // todo
  };

  return (
    <div>
      <div className="text-3xl font-bold mb-2">
        {intl.formatMessage(
          { id: "app.titles.welcome-message" },
          { name: user?.first_name }
        )}
      </div>
      <Card>
        <NonIdealState
          icon="error"
          // title={intl.formatMessage({ id: "app.team-plan.intro.title" })}
          description={
            <div>
              {intl.formatMessage(
                {
                  id: "app.team-plan.intro.description",
                },
                {
                  br: <br />,
                }
              )}
            </div>
          }
          action={
            <div className="flex flex-col -m-1 items-center">
              {/* <Button
                className="m-1"
                intent="primary"
                large
                onClick={handleMyPlanStart}
                loading={loading}
              >
                {intl.formatMessage({ id: "app.team-plan.start-my-plan" })}
              </Button>
              <Button className="m-1" onClick={handleNotReadyClick} disabled>
                {intl.formatMessage({ id: "app.team-plan.not-ready" })}
              </Button> */}
            </div>
          }
        />
      </Card>
    </div>
  );
};

export default TeamPlanWelcome;
