import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@blueprintjs/core";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { getQuestionTags } from "../../../../store/surveys/actions";
import SurveyReportNonIdealState from "../../table/non-ideal-states/SurveyReportNonIdealState";
import ClusterTagsGrid from "./ClusterTagsGrid";
import SurveyComparisonTable from "../../table/SurveyComparisonTable";
import { SurveyReportRouteParams } from "../../../../types";
import { getSurveyComparison } from "../../../../store/survey-reports/actions";
import useComparison from "../useComparison";

type OwnProps = {
  schoolId: number | undefined;
  domainId: number | undefined;
  surveyDeploymentId: number | undefined;
  tagId: string | undefined;
  routeParams: SurveyReportRouteParams;
  reportPagePath: string;
};

type Props = OwnProps;

const ClusterTagsTab: React.FC<Props> = (props) => {
  const {
    schoolId,
    domainId,
    tagId,
    surveyDeploymentId,
    routeParams,
    reportPagePath,
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  const questionTags = useSelector((s) => s.surveys.questionTags);
  const loading = useSelector((s) => s.surveys.loading.getQuestionTags);
  const error = useSelector((s) => s.surveys.errors.getQuestionTags);

  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);

  const { showNonIdealState, demographics } = useComparison({
    surveyComparison: surveyComparison,
  });

  useLoading({ loading: loading, error: error });

  useEffect(() => {
    if (surveyDeploymentId) {
      dispatch(
        getQuestionTags.request({ survey_deployment_id: surveyDeploymentId })
      );
    }
  }, [surveyDeploymentId]);

  useEffect(() => {
    if (surveyDeploymentId && schoolId && tagId) {
      dispatch(
        getSurveyComparison.request({
          survey_deployment_id: [+surveyDeploymentId],
          school_id: [+schoolId],
          domain_id: domainId ? +domainId : undefined,
          tag_id: tagId ? +tagId : undefined,
        })
      );
    }
  }, [tagId]);

  return (
    <>
      <div className={"text-xl mb-2"}>
        {intl.formatMessage({
          id: "app.surveys.reports.additional-focus-area.info",
        })}
      </div>

      {tagId ? (
        <SurveyComparisonTable
          surveyDeploymentId={surveyDeploymentId}
          domainId={domainId}
          tagId={tagId}
          demographics={demographics}
          surveyComparison={surveyComparison}
          showNonIdealState={showNonIdealState}
        />
      ) : (
        <>
          <div className="w-full bg-gray-600 rounded-t-md border-2 border-gray-700">
            <p className="text-white text-center text-2xl px-4 pt-4 pb-0 font-bold">
              {intl.formatMessage({
                id: "app.survey-report.cluster-tags.header-title",
              })}
            </p>
            <p className="text-white text-center text-lg pb-4">
              {intl.formatMessage({
                id: "app.survey-report.cluster-tags.header-subtitle",
              })}
            </p>
          </div>

          <div className={"w-full bg-gray-200 p-4 border-2 border-gray-400"}>
            {loading ? (
              <Spinner intent={"primary"} />
            ) : !questionTags.length || !surveyDeploymentId ? (
              <SurveyReportNonIdealState />
            ) : (
              <ClusterTagsGrid
                questionTags={questionTags}
                routeParams={routeParams}
                reportPagePath={reportPagePath}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ClusterTagsTab;
