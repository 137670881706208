import React, { FunctionComponent, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { useIntl } from "react-intl";
import { deleteGoal } from "../../../../../store/goals/actions";
import Tooltip from '@mui/material/Tooltip';
import { Avatar } from "@mui/material";

type OwnProps = {
  subscribersData: any;
};

type Props = OwnProps;
const GoalTopBar: FunctionComponent<Props> = ({ subscribersData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const { goal } = useSelector((s) => s.goals.newGoalState);
  const { activePlan } = useSelector((s) => s.plans);
  const selectedDistrict = useSelector((s) => s.plans.activePlan?.district);


  const handleDeleteGoal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(deleteGoal.request({ id: goal.id, planId: activePlan?.id }));
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteGoal.request({ id: goal.id, planId: activePlan?.id }));
    handleClose();
  };
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const { users } = subscribersData;

  return (
    <div className="planing-goal__bar">
      <h3>
        {(goal?.survey_deployment?.survey as any)?.name}{" "}
        {goal?.survey_deployment?.deployment_name}
      </h3>

      <div className="gola-list goal_list">
        {users &&
          Object.keys(users).map((key) => {
            const user = users[key];
            return (
              <span key={'icon_user_' + key} className="light-orange">
                {!user.user_profile_image ? (
                  <>
                    <Avatar title={user.firstName + " " + user.lastName} {...stringAvatar(user.firstName[0].toUpperCase() + " " + user.lastName[0].toUpperCase())} />
                  </>
                ) : (
                  <>
                    <Avatar title={user.firstName + " " + user.lastName} alt={user.firstName[0].toUpperCase() + " " + user.lastName[0].toUpperCase()} src={user.user_profile_image} />
                  </>
                )}
              </span>

            );
          })}
        {/* <span className="light-blue">LD</span>
                    <span className="light-orange">VT</span>          */}

        <ul>
          {/* {!!selectedDistrict?.is_dummy_district && (
                        <li>
                            <button>
                                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.2946 0.77951H15.4175C14.9198 0.77951 14.5052 1.19414 14.5052 1.69181C14.5052 2.18948 14.9198 2.60411 15.4175 2.60411H17.1177L11.8929 7.82887L7.72554 3.6615C7.3731 3.30905 6.79259 3.30905 6.44012 3.6615L0.779959 9.32166C0.427514 9.67411 0.427514 10.2546 0.779959 10.6071C0.966525 10.7936 1.19459 10.8767 1.42265 10.8767C1.65072 10.8767 1.87878 10.7938 2.06534 10.6071L7.10351 5.56891L11.2709 9.73629C11.6233 10.0887 12.2038 10.0887 12.5563 9.73629L18.4238 3.86879V5.56899C18.4238 6.06662 18.8384 6.48129 19.3361 6.48129C19.8337 6.48129 20.2484 6.06666 20.2484 5.56899L20.2485 1.69188C20.207 1.19425 19.7924 0.779585 19.2947 0.779585L19.2946 0.77951Z" fill="#458CCB" />
                                    <path d="M3.51668 13.3646H1.21529C0.904356 13.3646 0.634766 13.6133 0.634766 13.9451V17.1173C0.634766 17.4282 0.88352 17.6978 1.21529 17.6978H3.51668C3.82762 17.6978 4.09721 17.449 4.09721 17.1173V13.9451C4.09721 13.634 3.84845 13.3646 3.51668 13.3646Z" fill="#458CCB" />
                                    <path d="M8.80398 10.6485H6.4819C6.17096 10.6485 5.90137 10.8972 5.90137 11.229V17.1172C5.90137 17.4282 6.15012 17.6978 6.4819 17.6978H8.78328C9.09422 17.6978 9.36381 17.449 9.36381 17.1172V11.229C9.38449 10.9179 9.11491 10.6483 8.80398 10.6483V10.6485Z" fill="#458CCB" />
                                    <path d="M19.336 8.84473H17.0346C16.7237 8.84473 16.4541 9.09348 16.4541 9.42526V17.1173C16.4541 17.4282 16.7029 17.6978 17.0346 17.6978H19.336C19.647 17.6978 19.9165 17.449 19.9165 17.1173V9.42526C19.9165 9.11432 19.6678 8.84473 19.336 8.84473Z" fill="#458CCB" />
                                    <path d="M14.0694 12.473H11.768C11.4571 12.473 11.1875 12.7217 11.1875 13.0535V17.1172C11.1875 17.4282 11.4363 17.6978 11.768 17.6978H14.0694C14.3804 17.6978 14.6499 17.449 14.6499 17.1172L14.6501 13.0535C14.6501 12.7218 14.3805 12.473 14.0694 12.473Z" fill="#458CCB" />
                                </svg>
                            </button>
                        </li>
                    )} */}

          {/* <li>
                        <button>
                            <Icon icon="many-to-one" iconSize={16} />
                        </button>
                    </li> */}
          <li>
          <Tooltip title="Delete Goal" arrow>
            <button onClick={handleClickOpen}>
              <Icon icon="trash" iconSize={16} />
            </button>
            </Tooltip>
            <Dialog
              className="medium-popup"
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="cancel">
                <button onClick={handleClose}>
                  <Icon icon="cross" iconSize={18} />
                </button>
              </div>
              <Icon icon="trash" iconSize={30} />
              <h3>
                {intl.formatMessage({ id: "app.goals.goal.dialog.title" })}
              </h3>
              <div>
                {intl.formatMessage(
                  { id: "app.goals.goal.dialog.text" },
                  { b: (chunks) => <b>{chunks}</b>, br: <br /> }
                )}
              </div>
              <div className="button-group">
                <button onClick={handleClose} className="stroke-btn">
                  {intl.formatMessage({ id: "app.titles.cancel" })}
                </button>
                <button
                  onClick={() => handleConfirmDelete()}
                  className="nj-btn"
                >
                  {intl.formatMessage({
                    id: "app.goals.goal.dialog.delete-btn-text",
                  })}
                </button>
              </div>
            </Dialog>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GoalTopBar;
