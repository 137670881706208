import React, { FunctionComponent, useCallback } from "react";
import { DashboardWidgetPart } from "./DashboardWidget";
import DashboardWidgetStage from "./DashboardWidgetStage";

type OwnProps = {
  items: DashboardWidgetPart[];
  showDots: boolean;
  wrapPlanningIcon: boolean;
  showLeftPart?: boolean;
  showRightPart: boolean;
};

type Props = OwnProps;

const DashboardWidgetWrapper: FunctionComponent<Props> = (props) => {
  const {
    items,
    showLeftPart,
    showRightPart,
    showDots,
    wrapPlanningIcon,
  } = props;

  const renderGroup = useCallback(
    (
      group: DashboardWidgetPart,
      index: number,
      array: DashboardWidgetPart[]
    ) => {
      return (
        <DashboardWidgetStage
          key={index}
          group={group}
          showDots={showDots}
          showLeftPart={showLeftPart}
          showRightPart={showRightPart}
          isFirstGroup={!!index}
          isLastGroup={index !== array.length - 1}
        />
      );
    },
    [showDots, showLeftPart, showRightPart]
  );

  if (showLeftPart) {
    return (
      <>
        <div
          className={`flex ${wrapPlanningIcon ? "flex-wrap" : ""}`}
          style={{
            flexGrow: items
              .slice(0, 2)
              .reduce((pV, cV) => pV + cV.steps.length, 0),
          }}
        >
          {items.slice(0, 2).map(renderGroup)}
        </div>

        {items.slice(2).map(renderGroup)}
      </>
    );
  }

  return <>{items.map(renderGroup)}</>;
};

export default DashboardWidgetWrapper;
