import Axios from "axios";
import { LoggedStrategyImplementation, PlanStrategy } from "../../types";
import { API_ROOT } from "../config";
import {
  CreatePlanStrategyRequest,
  UpdatePlanStrategyImplementationStatusRequest,
  UpdatePlanStrategyRequest,
} from "./types";

export const addStrategyToPlan = ({
  plan_id,
  ...rest
}: CreatePlanStrategyRequest): Promise<PlanStrategy> => {
  return Axios.post(`${API_ROOT}/plans/${plan_id}/strategies/`, rest);
};

export const updatePlanStrategy = ({
  planId,
  planStrategyId,
  ...body
}: UpdatePlanStrategyRequest): Promise<PlanStrategy> => {
  return Axios.put(
    `${API_ROOT}/plans/${planId}/strategies/${planStrategyId}/`,
    body
  );
};

export const deletePlanStrategy = (
  planStrategyId: number,
  planId: number
): Promise<void> => {
  return Axios.delete(
    `${API_ROOT}/plans/${planId}/strategies/${planStrategyId}`
  );
};

export const updatePlanStrategyImplementationStatus = ({
  plan_strategy_id,
  ...rest
}: UpdatePlanStrategyImplementationStatusRequest): Promise<LoggedStrategyImplementation> => {
  return Axios.post(
    `${API_ROOT}/plan_strategies/${plan_strategy_id}/implementation/`,
    rest
  );
};

export const getPlanStrategies = (planId: number): Promise<PlanStrategy[]> => {
  return Axios.get(`${API_ROOT}/plans/${planId}/strategies/`);
};

export const getPlanStrategyById = (
  planId: number,
  planStrategyId: number
): Promise<PlanStrategy> => {
  return Axios.get(`${API_ROOT}/plans/${planId}/strategies/${planStrategyId}/`);
};

export const deletePlanStrategyImplementation = (
  plan_strategy_id: number,
  implementation: number
): Promise<void> => {
  return Axios.delete(
    `${API_ROOT}/plan_strategies/${plan_strategy_id}/implementation/${implementation}/`
  );
};

export const updatePlanStrategyImplementation = (
  implementation: number,
  { plan_strategy_id, ...body }: UpdatePlanStrategyImplementationStatusRequest
): Promise<LoggedStrategyImplementation> => {
  return Axios.put(
    `${API_ROOT}/plan_strategies/${plan_strategy_id}/implementation/${implementation}/`,
    body
  );
};
