import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getSurveyQuestionsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyQuestions.request)),
    switchMap((action) =>
      from(api.surveys.getSurveyQuestions(action.payload)).pipe(
        map(actions.getSurveyQuestions.success),
        catchError((error) => of(actions.getSurveyQuestions.failure(error)))
      )
    )
  );
};

export const getSurveyQuestionsByDomainEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyQuestionsByDomain.request)),
    switchMap((action) =>
      from(api.surveys.getSurveyQuestionsByDomain(action.payload)).pipe(
        map(actions.getSurveyQuestionsByDomain.success),
        catchError((error) =>
          of(actions.getSurveyQuestionsByDomain.failure(error))
        )
      )
    )
  );
};

export const getSurveyQuestionsByHashEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyQuestionsByHash.request)),
    switchMap((action) =>
      from(
        api.surveys.getSurveyQuestionsByHash(
          action.payload.demographicHash,
          action.payload.schoolHash,
          action.payload.sessionHash,
          action.payload?.password
        )
      ).pipe(
        map((payload) =>
          actions.getSurveyQuestionsByHash.success({
            payload: payload,
            locale: action.payload.locale,
          })
        ),
        catchError((error) => {
          return of(
            actions.getSurveyQuestionsByHash.failure(error?.response?.data)
          );
        })
      )
    )
  );
};

export const addSurveyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addSurvey.request)),
    switchMap((action) =>
      from(api.surveys.addSurvey(action.payload.survey)).pipe(
        mergeMap((survey) =>
          from(
            [actions.addSurvey.success(survey)],
            action.payload.onSuccess && action.payload.onSuccess(survey)
          )
        ),
        catchError((error) => of(actions.addSurvey.failure(error)))
      )
    )
  );
};

export const addCompletedSurveyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.saveCompletedSurvey.request)),
    switchMap((action) =>
      from(
        api.surveys.saveCompletedSurvey(
          action.payload.hash,
          action.payload.completedSurvey,
          action.payload.school,
          action.payload.sessionHash
        )
      ).pipe(
        map(actions.saveCompletedSurvey.success),
        catchError((error) => of(actions.saveCompletedSurvey.failure(error)))
      )
    )
  );
};

export const updateSurveyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateSurvey.request)),
    switchMap((action) =>
      from(
        api.surveys.updateSurvey(
          action.payload.surveyId,
          action.payload.updatedSurvey
        )
      ).pipe(
        mergeMap((survey) =>
          from(
            [actions.updateSurvey.success(survey)],
            action.payload.onSuccess && action.payload.onSuccess(survey)
          )
        ),
        catchError((error) => of(actions.updateSurvey.failure(error)))
      )
    )
  );
};

export const getSurveys: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveys.request)),
    switchMap((action) =>
      from(api.surveys.getSurveys()).pipe(
        map(actions.getSurveys.success),
        catchError((error) => of(actions.getSurveys.failure(error)))
      )
    )
  );
};

export const getSurvey: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurvey.request)),
    switchMap((action) =>
      from(api.surveys.getSurvey(action.payload)).pipe(
        map(actions.getSurvey.success),
        catchError((error) => of(actions.getSurvey.failure(error)))
      )
    )
  );
};

export const editSurveyQuestionsInSelectedSurveyEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.editSurveyQuestionsInSelectedSurvey.request)),
    switchMap((action) =>
      from(
        api.surveys.editSurveyQuestionsInSelectedSurvey(
          action.payload.surveyId,
          action.payload.request
        )
      ).pipe(
        map(actions.editSurveyQuestionsInSelectedSurvey.success),
        catchError((error) =>
          of(actions.editSurveyQuestionsInSelectedSurvey.failure(error))
        )
      )
    )
  );
};

export const deleteSurveyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteSurvey.request)),
    switchMap((action) =>
      from(api.surveys.deleteSurvey(action.payload)).pipe(
        map(() => actions.deleteSurvey.success(action.payload)),
        catchError((error) => of(actions.deleteSurvey.failure(error)))
      )
    )
  );
};

export const saveSurveyQuestionAnswerEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.saveSurveyQuestionAnswer.request)),
    switchMap((action) =>
      from(
        api.surveys.saveSurveyQuestionAnswer(
          action.payload.hash,
          action.payload.school,
          action.payload.sessionHash,
          action.payload.request
        )
      ).pipe(
        map(() =>
          actions.saveSurveyQuestionAnswer.success(action.payload.request)
        ),
        catchError((error) =>
          of(
            actions.saveSurveyQuestionAnswer.failure({
              error: error,
              previousObject: action.payload.previousObject,
            })
          )
        )
      )
    )
  );
};

export const getQuestionTagsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getQuestionTags.request)),
    switchMap((action) =>
      from(api.surveys.getQuestionTags(action.payload)).pipe(
        map(actions.getQuestionTags.success),
        catchError((error) => of(actions.getQuestionTags.failure(error)))
      )
    )
  );
};

export const getQuestionTagByIdEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getQuestionTagById.request)),
    switchMap((action) =>
      from(api.surveys.getQuestionTagById(action.payload)).pipe(
        map(actions.getQuestionTagById.success),
        catchError((error) => of(actions.getQuestionTagById.failure(error)))
      )
    )
  );
};

export const addQuestionTagEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addQuestionTag.request)),
    switchMap((action) =>
      from(api.surveys.addQuestionTag(action.payload.body)).pipe(
        mergeMap((questionTag) =>
          from(
            [actions.addQuestionTag.success(questionTag)],
            action.payload.onSuccess && action.payload.onSuccess(questionTag)
          )
        ),
        catchError((error) => of(actions.addQuestionTag.failure(error)))
      )
    )
  );
};

export const updateQuestionTagEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateQuestionTag.request)),
    switchMap((action) =>
      from(api.surveys.updateQuestionTag(action.payload)).pipe(
        map(actions.updateQuestionTag.success),
        catchError((error) => of(actions.updateQuestionTag.failure(error)))
      )
    )
  );
};

export const deleteQuestionTagEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteQuestionTag.request)),
    switchMap((action) =>
      from(api.surveys.deleteQuestionTag(action.payload)).pipe(
        map(() => actions.deleteQuestionTag.success(action.payload)),
        catchError((error) => of(actions.deleteQuestionTag.failure(error)))
      )
    )
  );
};
