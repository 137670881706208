import Axios from "axios";
import { API_ROOT } from "../config";
import {
  ActivityScopeEnum,
  AttachedResource,
  MeetingTemplate,
} from "../../types";
import { MeetingTemplateRequest } from "./types";
import { addResources } from "../resources";

export const getMeetingTemplates = (
  type?: ActivityScopeEnum,
  showHidden?: boolean
): Promise<MeetingTemplate[]> => {
  return Axios.get(
    `${API_ROOT}/meeting-templates/${type ? `?type=${type}` : ""}${
      showHidden ? `${type ? "&" : "?"}show_hidden=${showHidden}` : ""
    }`
  );
};

export const addMeetingTemplate = async (
  body: MeetingTemplateRequest
): Promise<MeetingTemplate> => {
  let resourceIds: number[] = body?.resources
    ?.filter((r) => r?.id > 0)
    ?.map((r) => r.id);
  await addResources(
    body?.resources?.filter((r) => r?.id < 0 || !r.id) ?? []
  ).then((success) => {
    resourceIds = [
      ...resourceIds,
      ...success
        ?.filter((response) => response.status === "fulfilled")
        ?.map(
          (resource) =>
            (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
        ),
    ];
  });
  return Axios.post(`${API_ROOT}/meeting-templates/`, {
    ...body,
    resources: resourceIds,
  });
};

export const updateMeetingTemplate = async (
  id: number,
  body: MeetingTemplateRequest
): Promise<MeetingTemplate> => {
  let resourceIds: number[] = body?.resources
    ?.filter((r) => r?.id > 0)
    ?.map((r) => r.id);
  await addResources(
    body?.resources?.filter((r) => r?.id < 0 || !r.id) ?? []
  ).then((success) => {
    resourceIds = [
      ...resourceIds,
      ...success
        ?.filter((response) => response.status === "fulfilled")
        ?.map(
          (resource) =>
            (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
        ),
    ];
  });
  return Axios.put(`${API_ROOT}/meeting-templates/${id}/`, {
    ...body,
    resources: resourceIds,
  });
};

export const deleteMeetingTemplate = (id: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/meeting-templates/${id}`);
};
