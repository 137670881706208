import React, { useState } from "react";
import { DomainResponses, SurveyDemographic } from "../../../../types";
import { useDispatch } from "react-redux";
import { showDomainComparisonDialog } from "../../../../store/survey-reports/actions";
import { Button } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import DomainQuestionComparisonTableRowDemographic from "./DomainQuestionComparisonTableRowDemographic";
import { COMPARISON_COLORS } from "../../tab/SurveyComparisonTab";

type OwnProps = {
  domainId?: number;
  item: DomainResponses;
  demographics: SurveyDemographic[];
  domainColor?: string;
};

type Props = OwnProps;

const DomainQuestionComparisonTableRow: React.FC<Props> = (props: Props) => {
  const {
    item,
    demographics,
    domainId,
    domainColor,
  } = props;

  // Don't use a default value in the props because that only works if `domainColor` is undefined,
  // and sometimes the server sends back `null` which causes the app to crash.
  const safeDomainColor = domainColor || COMPARISON_COLORS[(domainId ?? 0) % COMPARISON_COLORS.length];

  const intl = useIntl();

  const dispatch = useDispatch();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleDistributionItemClick = () => {
    dispatch(
      showDomainComparisonDialog({
        responses: item,
        demographics: demographics,
      })
    );
  };

  return (
    <tr>
      <td className={"bg-gray-200 p-2"}>
        <div className="flex justify-center">
          <p
            className="text-xl text-center flex-grow font-bold cursor-default"
            style={{ maxWidth: "20rem" }}
            onMouseEnter={() => setPopoverOpen(true)}
            onMouseLeave={() => setPopoverOpen(false)}
          >
            {item.common_text?.replaceAll(/\s/g, " ")}
          </p>
        </div>
      </td>

      {demographics?.map((demographic, index) => (
        <DomainQuestionComparisonTableRowDemographic
          key={index}
          domainId={domainId}
          item={item}
          demographic={demographic}
          domainColor={safeDomainColor}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
        />
      ))}
      <td>
        <div className="flex justify-center px-2">
          <Button
            intent="primary"
            className="text-center"
            text={intl.formatMessage({ id: "app.titles.show-distributions" })}
            title={intl.formatMessage({ id: "app.titles.show-distributions" })}
            large
            onClick={handleDistributionItemClick}
          />
        </div>
      </td>
    </tr>
  );
};

export default DomainQuestionComparisonTableRow;
