import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getDatasetsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getDatasets.request)),
    switchMap((action) =>
      from(api.datasets.getDatasets(action.payload)).pipe(
        map(actions.getDatasets.success),
        catchError((error) => of(actions.getDatasets.failure(error)))
      )
    )
  );
};

export const createCustomSetEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.createCustomDataset.request)),
    switchMap((action) =>
      from(api.datasets.createCustomDataset(action.payload)).pipe(
        map(actions.createCustomDataset.success),
        catchError((error) => of(actions.createCustomDataset.failure(error)))
      )
    )
  );
};

export const deleteCustomDatasetEpic: Epic<RootAction, RootAction, RootState> =
  (action$, state$) => {
    return action$.pipe(
      filter(isActionOf(actions.deleteCustomDataset.request)),
      switchMap((action) =>
        from(api.datasets.deleteCustomDataset(action.payload)).pipe(
          mergeMap(() =>
            from(
              [actions.deleteCustomDataset.success(action.payload.datasetId)],
              action.payload.onSuccess && action.payload.onSuccess()
            )
          ),
          catchError((error) => of(actions.deleteCustomDataset.failure(error)))
        )
      )
    );
  };

export const addDataPointToCustomDataSetEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.addDataPointToCustomDataset.request)),
    switchMap((action) =>
      from(api.datasets.addDataPointToCustomSet(action.payload)).pipe(
        map((dp) =>
          actions.addDataPointToCustomDataset.success({
            dataPoint: dp,
            datasetId: action.payload.datasetId,
            planId: action.payload.planId,
          })
        ),
        catchError((error) =>
          of(actions.addDataPointToCustomDataset.failure(error))
        )
      )
    )
  );
};

export const deleteDataPointFromCustomDataset: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteDataPointFromCustomDataset.request)),
    switchMap((action) =>
      from(
        api.datasets.deleteDataPointFromCustomDataset(
          action.payload.planId,
          action.payload.dataPointId
        )
      ).pipe(
        mergeMap(() =>
          from(
            [
              actions.deleteDataPointFromCustomDataset.success({
                datasetId: action.payload.datasetId,
                dataPointId: action.payload.dataPointId,
              }),
            ],
            action.payload.onSuccess &&
              action.payload.onSuccess(action.payload.dataPointId)
          )
        ),
        catchError((error) =>
          of(actions.deleteDataPointFromCustomDataset.failure(error))
        )
      )
    )
  );
};

export const updateDataPointInCustomDataset: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updateDataPointInCustomDataset.request)),
    switchMap((action) =>
      from(
        api.datasets.updateDataPointInCustomDataset(action.payload.body)
      ).pipe(
        mergeMap((dataPoint) =>
          from(
            [
              actions.updateDataPointInCustomDataset.success({
                datasetId: action.payload.datasetId,
                dataPoint: dataPoint,
              }),
            ],
            action.payload.onSuccess && action.payload.onSuccess(dataPoint)
          )
        ),
        catchError((error) =>
          of(actions.updateDataPointInCustomDataset.failure(error))
        )
      )
    )
  );
};
