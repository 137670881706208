import React, { FunctionComponent, useMemo } from "react";
import { Button, ButtonGroup, Card, Tag } from "@blueprintjs/core";
import {
  LookForItem,
  LookForItemStatus,
  LookForItemStatusEnum,
} from "../../../../types";
import { useIntl } from "react-intl";
import moment from "moment";

type OwnProps = {
  lookFor: LookForItem;
  readonly?: boolean;
  lookForItemStatus?: LookForItemStatus;
  status?: LookForItemStatusEnum;
  onItemClick?: (itemId: number, status: LookForItemStatusEnum) => void;
};

type Props = OwnProps;

const CoreComponentItem: FunctionComponent<Props> = (props) => {
  const { lookFor, readonly, status, lookForItemStatus, onItemClick } = props;

  const intl = useIntl();

  const handleItemClick = (
    itemId: number,
    status: LookForItemStatusEnum
  ) => () => {
    onItemClick && onItemClick(itemId, status);
  };

  const dateRange = useMemo(() => {
    if (
      lookForItemStatus &&
      lookForItemStatus.target_start_date &&
      lookForItemStatus.target_end_date
    ) {
      return (
        <div className="flex flex-wrap gap-2">
          <div className="flex gap-1">
            <div>{intl.formatMessage({ id: "app.date-range.from" })}:</div>
            <Tag>
              {moment(lookForItemStatus.target_start_date).format("YYYY-MM-DD")}
            </Tag>
          </div>
          <div className="flex gap-1">
            <div>{intl.formatMessage({ id: "app.date-range.to" })}:</div>
            <Tag>
              {moment(lookForItemStatus.target_end_date).format("YYYY-MM-DD")}
            </Tag>
          </div>
        </div>
      );
    }
  }, [lookForItemStatus]);

  return (
    <Card key={lookFor.id} className="p-2">
      <div className="flex items-stretch">
        <div className="w-1/3 mr-2">
          <p>{lookFor.text}</p>
          {lookForItemStatus && (
            <div className="mt-2 flex flex-wrap items-center gap-2">
              {lookForItemStatus.recurring_status && (
                <Tag intent="primary">
                  {intl.formatMessage({
                    id: `app.recurring-status.${lookForItemStatus.recurring_status}`,
                  })}
                </Tag>
              )}
              {dateRange && <div>{dateRange}</div>}
            </div>
          )}
        </div>
        <div className="w-2/3">
          <ButtonGroup large fill className="whitespace-no-wrap">
            <Button
              disabled={readonly}
              intent={
                status === LookForItemStatusEnum.NotPresent
                  ? "primary"
                  : undefined
              }
              text={intl.formatMessage(
                { id: "app.strategies.look-fors.status" },
                { status: LookForItemStatusEnum.NotPresent }
              )}
              title={intl.formatMessage(
                { id: "app.strategies.look-fors.status" },
                { status: LookForItemStatusEnum.NotPresent }
              )}
              onClick={handleItemClick(
                lookFor.id,
                LookForItemStatusEnum.NotPresent
              )}
            />
            <Button
              disabled={readonly}
              intent={
                status === LookForItemStatusEnum.InProgress
                  ? "primary"
                  : undefined
              }
              text={intl.formatMessage(
                { id: "app.strategies.look-fors.status" },
                { status: LookForItemStatusEnum.InProgress }
              )}
              title={intl.formatMessage(
                { id: "app.strategies.look-fors.status" },
                { status: LookForItemStatusEnum.InProgress }
              )}
              onClick={handleItemClick(
                lookFor.id,
                LookForItemStatusEnum.InProgress
              )}
            />
            <Button
              disabled={readonly}
              intent={
                status === LookForItemStatusEnum.Present ? "primary" : undefined
              }
              text={intl.formatMessage(
                { id: "app.strategies.look-fors.status" },
                { status: LookForItemStatusEnum.Present }
              )}
              title={intl.formatMessage(
                { id: "app.strategies.look-fors.status" },
                { status: LookForItemStatusEnum.Present }
              )}
              onClick={handleItemClick(
                lookFor.id,
                LookForItemStatusEnum.Present
              )}
            />
          </ButtonGroup>
        </div>
      </div>
    </Card>
  );
};

export default CoreComponentItem;
