import { createAction, createAsyncAction } from "typesafe-actions";
import {
  AddMeetingRequest,
  UpdateMeetingRequest,
} from "../../api/meetings/types";
import { Meeting, UserNotes } from "../../types";

export const showMeetingDialog = createAction("@meetings/SHOW_MEETING_DIALOG")<{
  meeting: Meeting | undefined;
  viewOnly?: boolean;
  showMeetingTemplates?: boolean;
  fromAdminView?: boolean;
}>();

export const hideMeetingDialog = createAction(
  "@meetings/HIDE_MEETING_DIALOG"
)<void>();

export const updateNotesInMeeting = createAction(
  "@meetings/UPDATE_NOTES_IN_MEETING"
)<UserNotes & { meeting_id?: number | null | undefined }>();
export const addNotesToMeeting = createAction("@meetings/ADD_NOTES_TO_MEETING")<
  UserNotes & { meeting_id?: number | null | undefined }
>();
export const removeNotesFromMeeting = createAction(
  "@meetings/REMOVE_NOTES_FROM_MEETING"
)<number>();

export const getMeetings = createAsyncAction(
  "@meetings/GET_MEETINGS_REQUEST",
  "@meetings/GET_MEETINGS_SUCCESS",
  "@meetings/GET_MEETINGS_FAILURE"
)<number | undefined, Meeting[], Error>();

export const addMeeting = createAsyncAction(
  "@meetings/ADD_MEETING_REQUEST",
  "@meetings/ADD_MEETING_SUCCESS",
  "@meetings/ADD_MEETING_FAILURE"
)<AddMeetingRequest, Meeting, Error>();

export const updateMeeting = createAsyncAction(
  "@meetings/UPDATE_MEETING_REQUEST",
  "@meetings/UPDATE_MEETING_SUCCESS",
  "@meetings/UPDATE_MEETING_FAILURE"
)<UpdateMeetingRequest, Meeting, Error>();

export const deleteMeeting = createAsyncAction(
  "@meetings/DELETE_MEETING_REQUEST",
  "@meetings/DELETE_MEETING_SUCCESS",
  "@meetings/DELETE_MEETING_FAILURE"
)<any, number, Error>();

export const getMeetingById = createAsyncAction(
  "@meetings/GET_MEETING_BY_ID_REQUEST",
  "@meetings/GET_MEETING_BY_ID_SUCCESS",
  "@meetings/GET_MEETING_BY_ID_FAILURE"
)<{ id: number; planId?: number }, Meeting<string, false>, Error>();
