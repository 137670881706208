import React, { FunctionComponent, useMemo } from "react";
import moment from "moment";
import { Tag } from "@blueprintjs/core";
import { Meeting } from "../../../../types";
import useUpcomingActivityTag from "../../../../helpers/hooks/useUpcomingActivityTag";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";

type OwnProps = {
  meeting: Meeting;
};

type Props = OwnProps;

const UpcomingActivityTag: FunctionComponent<Props> = (props) => {
  const { meeting } = props;

  const { getTagButton, getTagBackgroundColor } = useUpcomingActivityTag();
  const tagColor = getTagBackgroundColor(meeting);
  const textColor = pickTextColorBasedOnBgColor(tagColor);
  const rightButton = getTagButton(meeting);

  const dateTitlePart = useMemo(() => {
    const dateMoment = moment(meeting.date);
    const endDateMoment = moment(meeting.end_date);
    const formatDate = meeting.all_day_event ? "MM/DD" : "MM/DD h:mmA";

    let res: string = dateMoment.format(formatDate);

    if (!dateMoment.isSame(endDateMoment)) {
      const endDateFormat = dateMoment.diff(endDateMoment, "days")
        ? "MM/DD h:mmA"
        : "h:mmA";
      res += ` - ${endDateMoment.format(endDateFormat)}`;
    }

    return res;
  }, [meeting.date, meeting.end_date, meeting.all_day_event]);

  const title = useMemo(() => {
    return meeting.short_title || meeting.title;
  }, [meeting.short_title, meeting.title]);

  return (
    <Tag
      round
      style={{ backgroundColor: tagColor }}
      className="upcoming-activity-tag"
      rightIcon={<div className="p-2">{rightButton}</div>}
    >
      <div className={"flex gap-2 justify-between leading-normal"}>
        <p
          title={title}
          className="font-bold text-black text-base truncate"
          style={{ maxWidth: "17rem", color: textColor }}
        >
          {title}
        </p>
        <p className="text-base" style={{ color: textColor }}>
          {dateTitlePart}
        </p>
      </div>
    </Tag>
  );
};

export default UpcomingActivityTag;
