import * as auth from "./auth/actions";
import * as surveys from "./surveys/actions";
import * as domains from "./domains/actions";
import * as commonText from "./common-text/actions";
import * as domainAnswerSet from "./domain-answer-set/actions";
import * as districts from "./districts/actions";
import * as users from "./users/actions";
import * as schools from "./schools/actions";
import * as goals from "./goals/actions";
import * as profile from "./profile/actions";
import * as notifications from "./notifications/actions";
import * as surveysDeployment from "./surveys-deployment/actions";
import * as surveyReports from "./survey-reports/actions";
import * as strategies from "./strategies/actions";
import * as meetings from "./meetings/actions";
import * as resources from "./resources/actions";
import * as datasets from "./data-sets/actions";
import * as plans from "./plans/actions";
import * as teamMembers from "./team-members/actions";
import * as datasetMarks from "./dataset-marks/actions";
import * as planStrategies from "./plan-strategies/actions";
import * as needs from "./needs/actions";
import * as notes from "./notes/actions";
import * as activities from "./activities/actions";
import * as meetingTemplates from "./meeting-templates/actions";
import * as tours from "./tours/actions";

export default {
  ...domains,
  ...commonText,
  ...domainAnswerSet,
  ...districts,
  ...users,
  ...schools,
  ...surveys,
  ...goals,
  ...profile,
  ...auth,
  ...notifications,
  ...surveysDeployment,
  ...surveyReports,
  ...strategies,
  ...meetings,
  ...resources,
  ...datasets,
  ...plans,
  ...teamMembers,
  ...datasetMarks,
  ...planStrategies,
  ...needs,
  ...notes,
  ...activities,
  ...meetingTemplates,
  ...tours,
};
