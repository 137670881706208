import React, { useState } from "react";
import { Button } from "@blueprintjs/core";
import { BreadcrumbPropsType } from "../../../types";

type OwnProps = {
  breadcrumbProps: BreadcrumbPropsType;
  onBreadcrumbItemClick: () => void;
  isActive?: boolean;
};

type Props = OwnProps;

const BreadcrumbSurveyReportItem: React.FC<Props> = (props: Props) => {
  const {
    isSelectable,
    itemName,
    isVisible,
    editableComponent,
  } = props.breadcrumbProps;

  const [showEditableComponent, setEditableComponent] = useState(false);

  const handleShowEditableComponentButtonClick = () => {
    setEditableComponent(true);
  };

  return (
    <>
      {isSelectable && isVisible ? (
        <>
          {showEditableComponent ? (
            <>{editableComponent}</>
          ) : (
            <Button
              icon={"add"}
              minimal
              onClick={handleShowEditableComponentButtonClick}
            />
          )}
        </>
      ) : (
        <p
          className={`text-base whitespace-nowrap ${
            props.isActive ? "font-medium" : "text-gray-600"
          } sm:text-lg cursor-pointer`}
          onClick={props.onBreadcrumbItemClick}
        >
          {itemName}
        </p>
      )}
    </>
  );
};

export default BreadcrumbSurveyReportItem;
