import React from "react";
import { District, Survey, SurveyDeployment } from "../../../../types";
import { Divider } from "@blueprintjs/core";
import SurveyAdministrationWidgetBody from "./SurveyAdministrationWidgetBody";

type OwnProps = {
  activeSurveysDeployment?: SurveyDeployment<District, Survey<number>>[];
};

type Props = OwnProps;

const ActiveSurveysDeploymentList: React.FC<Props> = ({
  activeSurveysDeployment,
}: Props) => {
  return (
    <>
      <div className="flex-1 mb-4">
        <div>
          {activeSurveysDeployment?.map((sd, index) => (
            <React.Fragment key={sd.id}>
              {!!index && <Divider />}
              <SurveyAdministrationWidgetBody activeSurveyDeployment={sd} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default ActiveSurveysDeploymentList;
