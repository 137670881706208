import _ from "lodash";
import React, { FunctionComponent, useMemo } from "react";
import { PlanStrategy } from "../../../../../../types";
import './StrategyStatus.scss';

type OwnProps = {
  planStrategy: PlanStrategy;
  isPauseImplementation: boolean;
};

type Props = OwnProps;

const StrategyStatus: FunctionComponent<Props> = (props) => {
  const { planStrategy, isPauseImplementation } = props;

  const implementedPercent = useMemo(() => {
    return Math.round(
      (_.chain(planStrategy.implementations)
        .orderBy((impl) => new Date(impl.date), ["desc"])
        .first()
        .value()?.score ?? 0) * 100
    );
  }, [planStrategy.implementations]);

  return isPauseImplementation ?
    <div className="progress-bar"></div>
    :
    implementedPercent == null ? (
    <div className="progress-bar">
      0% <span>
        <span style={{
          'width': '0%', 'backgroundColor': '#D9D9D9'
        }}></span>
      </span>
    </div>
  ) : (
    <div className="progress-bar">
      {implementedPercent}% <span>
        <span style={{
          'width': implementedPercent, 'backgroundColor': implementedPercent === 0 ? '#D9D9D9' : implementedPercent < 100 ? '#EFB256' : '#81B74C'
        }}></span>
      </span>
    </div>
  );
};

export default StrategyStatus;
