import React, { FunctionComponent, useMemo } from "react";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import { DistrictSetupTaskKey } from "../../../../../types";
import { useIntl } from "react-intl";
import WelcomeIntro from "../../../../welcome-page-content-by-role/WelcomeIntro";
import WelcomeStepOne from "../../../../welcome-page-content-by-role/WelcomeStepOne";
import WelcomeStepThree from "../../../../welcome-page-content-by-role/WelcomeStepThree";
import FamiliarizeWithSccpTour from "../../../members/tours/FamiliarizeWithSccpTour";
import { useDispatch, useSelector } from "react-redux";
import {
  markStepCompleted,
  selectDistrictSetupTaskKey,
} from "../../../../../store/tours/actions";
import useNextTourCallback from "../../../../../helpers/hooks/team-members/useNextTourCallback";
import CommunicateWithPrincipalButton from "./CommunicateWithPrincipalButton";

type OwnProps = {};

type Props = OwnProps;

const DistrictSetupInfoDialog: FunctionComponent<Props> = (props) => {
  const districtSetupTaskKey = useSelector(
    (s) => s.tours.selectedDistrictSetupTaskKey
  );

  const show = useMemo(() => {
    return !!districtSetupTaskKey;
  }, [districtSetupTaskKey]);

  const onNext = useNextTourCallback(districtSetupTaskKey);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(selectDistrictSetupTaskKey(undefined));
  };

  const intl = useIntl();

  const { title, className, style } = useMemo(() => {
    switch (districtSetupTaskKey) {
      case DistrictSetupTaskKey.CommunicateWithPrincipals:
        return {
          className: "wrap-title",
          title: intl.formatMessage({
            id:
              "app.district-setup.tasks.register_schools_and_invite_principals.dialog-title",
          }),
        };

      case DistrictSetupTaskKey.FamiliarizeWithSccp:
        return {
          title: intl.formatMessage({
            id: `app.district-setup.tasks.${districtSetupTaskKey}`,
          }),
          style: { width: "43rem" },
        };

      default:
        return {
          title: districtSetupTaskKey
            ? intl.formatMessage({
                id: `app.district-setup.tasks.${districtSetupTaskKey}`,
              })
            : null,
        };
    }
  }, [districtSetupTaskKey]);

  const content = useMemo(() => {
    switch (districtSetupTaskKey) {
      case DistrictSetupTaskKey.Review: {
        return <WelcomeIntro />;
      }
      case DistrictSetupTaskKey.FamiliarizeWithSccp: {
        return (
          <WelcomeStepOne>
            <Button
              text={intl.formatMessage({ id: "app.titles.close" })}
              intent="primary"
              onClick={() => {
                dispatch(
                  markStepCompleted({
                    key: DistrictSetupTaskKey.FamiliarizeWithSccp,
                  })
                );
                onNext && onNext();
              }}
            />
            <FamiliarizeWithSccpTour />
          </WelcomeStepOne>
        );
      }

      case DistrictSetupTaskKey.CommunicateWithPrincipals: {
        return (
          <WelcomeStepThree>
            <CommunicateWithPrincipalButton
              onClose={handleClose}
              onComplete={() => {
                onNext && onNext();
              }}
            />
          </WelcomeStepThree>
        );
      }

      default:
        return null;
    }
  }, [districtSetupTaskKey]);

  return (
    <Dialog
      title={title}
      isOpen={show}
      onClose={handleClose}
      isCloseButtonShown={(title === intl.formatMessage({
        id: "app.district-setup.tasks.review"
      })) ? true : false}
      className={className}
      portalClassName="district-setup-container"
      style={{ ...style, width: style?.width ?? "80%" }}
    >
      <div className={Classes.DIALOG_BODY}>{content}</div>
    </Dialog>
  );
};

export default DistrictSetupInfoDialog;
