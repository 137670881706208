import {
  CollectedDemographicData,
  CollectedDemographicDataEnum,
  SurveyDemographic,
} from "../../../types";

export const getUpdatedSurveyDeploymentCollectedData = (
  collectedData: Omit<
    CollectedDemographicData,
    "id" | "created_at" | "updated_at"
  >[],
  selectedDemographic: SurveyDemographic,
  selectorType: CollectedDemographicDataEnum,
  isShow: boolean
): Omit<CollectedDemographicData, "id" | "created_at" | "updated_at">[] => {
  const collectedDemographicData = collectedData.find(
    (item) => item.demographic === selectedDemographic
  );
  if (!!collectedDemographicData) {
    if (!isShow) {
      collectedDemographicData.demographic_data = collectedDemographicData.demographic_data.filter(
        (item) => item !== selectorType
      );
    } else {
      collectedDemographicData.demographic_data = [
        ...collectedDemographicData.demographic_data,
        selectorType,
      ];
    }
    collectedData = [
      ...collectedData.filter(
        (item) => item.demographic !== selectedDemographic
      ),
      collectedDemographicData,
    ];
  } else {
    const newCollectedData: Omit<
      CollectedDemographicData,
      "id" | "created_at" | "updated_at"
    > = {
      demographic: selectedDemographic,
      demographic_data: [selectorType],
    };
    collectedData = [...collectedData, newCollectedData];
  }
  return collectedData;
};
