import React, { FunctionComponent, useMemo } from "react";
import ComparisonDistributionItem from "./ComparisonDistributionItem";
import ItemMark from "../survey-report-table-row/ItemMark";
import { DomainComparison, SurveyDemographic } from "../../../../types";
import useDatasetMark from "../../cards/useDatasetMark";

type OwnProps = {
  item: DomainComparison;
  demographic: SurveyDemographic;
  color?: string;
};

type Props = OwnProps;

const DomainComparisonTableRowDemographic: FunctionComponent<Props> = (
  props
) => {
  const { item, demographic, color } = props;

  const {
    flagged,
    bookmarkType,
    onClick,
    onDiscard,
    supportsBookmarks,
  } = useDatasetMark(
    {
      domainId: item.domain.id,
      demographic: demographic,
      is_grouped: false,
    },
    item?.respondents?.[demographic]?.mean_score
  );

  return (
    <td>
      <div className="middle-and-end-columns-container">
        {!!item?.respondents?.[demographic] && (
          <>
            <ComparisonDistributionItem
              notEnoughData={item?.respondents?.[demographic]?.not_enough_data} // check if the respondenets not enough and admin toggle to hide sensetive data is on
              meanScore={item?.respondents?.[demographic]?.mean_score}
              responsesCount={
                item?.respondents?.[demographic]?.total_respondents
              }
              domainColor={color}
            />
            {supportsBookmarks && (
              <ItemMark
                className="mr-auto"
                flagged={flagged}
                bookmarkType={bookmarkType}
                onClick={onClick}
                onDiscard={onDiscard}
              />
            )}
          </>
        )}
      </div>
    </td>
  );
};

export default DomainComparisonTableRowDemographic;
