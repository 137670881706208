import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { authReducer, AuthState } from "./auth";
import { UIState, UIStateReducer } from "./UIState";
import { surveysReducer, SurveysState } from "./surveys";
import { schoolsReducer, SchoolsState } from "./schools";
import { goalsReducer, GoalsState } from "./goals";
import { profileReducer, ProfileState } from "./profile";
import {
  notificationSettingsReducer,
  NotificationSettingsState,
} from "./notifications";
import {
  surveysDeploymentReducer,
  SurveysDeploymentState,
} from "./surveys-deployment";
import { surveyReportsReducer, SurveyReportsState } from "./survey-reports";
import { districtsReducer, DistrictsState } from "./districts";
import { usersReducer, UsersState } from "./users";
import { domainsReducer, DomainsState } from "./domains";
import {
  domainsAnswerSetReducer,
  DomainsAnswerSetState,
} from "./domain-answer-set";
import { commonTextsReducer, CommonTextsState } from "./common-text";
import { strategiesReducer, StrategiesState } from "./strategies";
import { resourcesReducer, ResourcesState } from "./resources";
import { meetingsReducer, MeetingsState } from "./meetings";
import { datasetsReducer, DatasetsState } from "./data-sets";
import { plansReducer, PlansState } from "./plans";
import { teamMembersReducer, TeamMembersState } from "./team-members";
import { needsReducer, NeedsState } from "./needs";
import { datasetMarksReducer, DatasetMarksState } from "./dataset-marks";
import { planStrategiesReducer, PlanStrategiesState } from "./plan-strategies";
import { notesReducer, NotesState } from "./notes";
import { activitiesReducer, ActivitiesState } from "./activities";
import {
  meetingTemplatesReducer,
  MeetingTemplatesState,
} from "./meeting-templates";
import { toursReducer, ToursState } from "./tours";

export type ApplicationState = {
  districts: DistrictsState;
  domains: DomainsState;
  commonTexts: CommonTextsState;
  domainsAnswerSet: DomainsAnswerSetState;
  users: UsersState;
  schools: SchoolsState;
  surveys: SurveysState;
  surveyReports: SurveyReportsState;
  surveysDeployment: SurveysDeploymentState;
  goals: GoalsState;
  strategies: StrategiesState;
  resources: ResourcesState;
  planStrategies: PlanStrategiesState;
  UIState: UIState;

  router: RouterState;
  auth: AuthState;
  profile: ProfileState;
  notifications: NotificationSettingsState;
  meetings: MeetingsState;
  datasets: DatasetsState;
  plans: PlansState;
  teamMembers: TeamMembersState;
  needs: NeedsState;
  datasetMarks: DatasetMarksState;
  notes: NotesState;
  activities: ActivitiesState;
  meetingTemplates: MeetingTemplatesState;
  tours: ToursState;
};

const createRootReducer = (history: History) =>
  combineReducers<ApplicationState>({
    districts: districtsReducer,
    domains: domainsReducer,
    commonTexts: commonTextsReducer,
    domainsAnswerSet: domainsAnswerSetReducer,
    users: usersReducer,
    schools: schoolsReducer,
    surveys: surveysReducer,
    surveyReports: surveyReportsReducer,
    surveysDeployment: surveysDeploymentReducer,
    goals: goalsReducer,
    strategies: strategiesReducer,
    resources: resourcesReducer,

    router: connectRouter(history),
    UIState: UIStateReducer,
    auth: authReducer,
    profile: profileReducer,
    notifications: notificationSettingsReducer,
    meetings: meetingsReducer,
    datasets: datasetsReducer,
    plans: plansReducer,
    teamMembers: teamMembersReducer,
    needs: needsReducer,
    datasetMarks: datasetMarksReducer,
    planStrategies: planStrategiesReducer,
    notes: notesReducer,
    activities: activitiesReducer,
    meetingTemplates: meetingTemplatesReducer,
    tours: toursReducer,
  });

export default createRootReducer;
