import React, { FunctionComponent, useMemo } from "react";
import { Tag } from "@blueprintjs/core";
import _ from "lodash";
import { useIntl } from "react-intl";

type OwnProps = {
  score?: number;
};

type Props = OwnProps;

const MeanScoreItemTag: FunctionComponent<Props> = (props) => {
  const { score } = props;
  const intl = useIntl();

  const value = useMemo(() => {
    return score != null
      ? _.round(score, 2)
      : intl.formatMessage({ id: "app.titles.not-applicable" });
  }, [score]);

  return (
    <Tag multiline round large className="mean-score-tag">
      {value}
    </Tag>
  );
};

export default MeanScoreItemTag;
