import React from "react";
import { Card, Tab, Tabs } from "@blueprintjs/core";
import AdminSurveysTable from "../surveys/table/AdminSurveysTable";
import { useIntl } from "react-intl";
import SurveysDeploymentTable from "../survey-deployment/table/surveys-deployment-table/SurveysDeploymentTable";
import SurveyDeploymentDialog from "../survey-deployment/dialogs/SurveyDeploymentDialog";
import DomainsTable from "../domains/table/DomainsTable";
import DomainAnswerSetUpsertDialog from "../domains/domain-answer-set/DomainAnswerSetUpsertDialog";
import SurveyUpsertDialog from "../surveys/dialogs/SurveyUpsertDialog";
import DomainAnswerSetsTable from "../domains/domain-answer-set/table/DomainAnswerSetsTable";
import { generatePath, useParams } from "react-router";
import { AdminRoutes } from "../../App";
import { useHistory } from "react-router-dom";
import CommonTextTable from "../common-text/table/CommonTextTable";
import ActivitiesTab from "../activities/ActivitiesTab";
import AdminMeetingTemplatesTable from "../meeting-templates/AdminMeetingTemplatesTable";
import { showActivityDialog } from "../../store/activities/actions";
import { Activity } from "../../types";
import { useDispatch } from "react-redux";
import TeamPlanCalendar from "../team-plan/calendar/TeamPlanCalendar";
import MeetingDialog from "../team-plan/meetings/dialogs/MeetingDialog";
import AdminWorkspaceStrategies from "../team-plan/strategies/AdminWorkspaceStrategies";

export enum AdminTabs {
  SURVEYS = "surveys",
  SURVEY_DEPLOYMENT = "survey-deployment",
  DOMAINS = "domains",
  ANSWERS_SETS = "answer-sets",
  COMMON_TEXT = "common-text",
  ACTIVITIES = "activities",
  CALENDAR = "calendar",
  MEETING_TEMPLATES = "meeting-templates",
  STRATEGY_LIBRARY = "strategy-library",
}

type RouteParams = {
  tab: AdminTabs;
};

type OwnProps = {};

type Props = OwnProps;

const AdminTables: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { tab } = useParams<RouteParams>();

  const history = useHistory();
  const dispatch = useDispatch();

  const handleTabChange = (newTab: AdminTabs) => {
    history.push(
      generatePath(AdminRoutes.AdminTables, {
        tab: newTab,
      })
    );
  };

  const handleActivityClick = (activity: Activity) => {
    dispatch(showActivityDialog({ activity: activity }));
  };

  return (
    <Card>
      <Tabs
        id="admin-tables"
        onChange={handleTabChange}
        selectedTabId={tab}
        renderActiveTabPanelOnly
        className="tabs-wrap"
      >
        <Tab
          id={AdminTabs.SURVEYS}
          title={intl.formatMessage({ id: "app.titles.surveys" })}
          panel={<AdminSurveysTable />}
        />
        <Tab
          id={AdminTabs.SURVEY_DEPLOYMENT}
          title={intl.formatMessage({ id: "app.titles.survey-deployment" })}
          panel={<SurveysDeploymentTable />}
        />
        <Tab
          id={AdminTabs.DOMAINS}
          title={intl.formatMessage({ id: "app.titles.domains" })}
          panel={<DomainsTable />}
        />
        <Tab
          id={AdminTabs.ANSWERS_SETS}
          title={intl.formatMessage({ id: "app.titles.answer-sets" })}
          panel={<DomainAnswerSetsTable />}
        />
        <Tab
          id={AdminTabs.COMMON_TEXT}
          title={intl.formatMessage({ id: "app.titles.common-text" })}
          panel={<CommonTextTable />}
        />
        <Tab
          id={AdminTabs.MEETING_TEMPLATES}
          title={intl.formatMessage({ id: "app.titles.meeting-templates" })}
          panel={<AdminMeetingTemplatesTable />}
        />
        <Tab
          id={AdminTabs.ACTIVITIES}
          title={intl.formatMessage({ id: "app.titles.activities" })}
          panel={<ActivitiesTab onActivityClick={handleActivityClick} />}
        />
        <Tab
          id={AdminTabs.CALENDAR}
          title={intl.formatMessage({ id: "app.titles.calendar" })}
          panel={
            <>
              <TeamPlanCalendar isAdminView={true} calendarMaxWidth={"100%"} />
              <MeetingDialog />
            </>
          }
        />
        <Tab
          id={AdminTabs.STRATEGY_LIBRARY}
          title={intl.formatMessage({ id: "app.strategies.strategy-library" })}
          panel={
            <AdminWorkspaceStrategies /> 
          }
        />
      </Tabs>

      <DomainAnswerSetUpsertDialog />
      <SurveyUpsertDialog />
      <SurveyDeploymentDialog />
    </Card>
  );
};

export default AdminTables;
