import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Dialog, Icon } from "@blueprintjs/core";
import CommonTextUpsertDialogContent from "./CommonTextUpsertDialogContent";
import { useDispatch, useSelector } from "react-redux";
import { hideCommonTextUpsertDialog } from "../../../store/common-text/actions";

type OwnProps = {};

type Props = OwnProps;

const CommonTextUpsertDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const show = useSelector((s) => s.commonTexts.showCommonTextUpsertDialog);
  const selectedCommonText = useSelector(
    (s) => s.commonTexts.selectedCommonText
  );

  const handleClose = () => {
    dispatch(hideCommonTextUpsertDialog());
  };

  return (
    <Dialog
      isOpen={show}
      className="w-1/2"
      style={{ minWidth: "500px" }}
      onClose={handleClose}
      icon={<Icon icon="highlight" iconSize={24} />}
      title={intl.formatMessage({
        id: selectedCommonText
          ? "app.common-text.dialogs.edit-common-text.title"
          : "app.common-text.create-new",
      })}
    >
      {show && (
        <CommonTextUpsertDialogContent
          onClose={handleClose}
          selectedCommonText={selectedCommonText}
        />
      )}
    </Dialog>
  );
};

export default CommonTextUpsertDialog;
