/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Tab, Tabs } from "@blueprintjs/core";
import { Strategy } from "../../../../../../types";
import './StrategyDetails.scss';

type OwnProps = {
  selectedStrategy: Strategy;
};

const maxLengthOverview = 750;
const maxLengthSupportiveEvd = 750;
const MIN_STRATEGY_DOMAINS_PER_LOAD = 6;

type Props = OwnProps;

const StrategyDetails: FunctionComponent<Props> = ({ selectedStrategy }) => {
  const [showOverviewMore, setShowOverviewMore] = useState<boolean>(false);
  const [showSupportiveEvdMore, setShowSupportiveEvdMore] = useState<boolean>(
    false
  );
  const [linkedDomains, setLinkedDomains] = useState<number>(
    MIN_STRATEGY_DOMAINS_PER_LOAD
  );
const intl=useIntl();

  useEffect(() => {
    setShowOverviewMore(false);
    setShowSupportiveEvdMore(false);
  }, [selectedStrategy]);

  // Overview More Details Text Logic
  const description = selectedStrategy?.description ?? "";
  const isLongDescription = description.length > maxLengthOverview;

    const truncatedDescription = isLongDescription && !showOverviewMore
      ? `${description.slice(0, maxLengthOverview)}...`
      : description;

  // SupportingEvd More Details Text Logic
  const supportiveEvd = selectedStrategy?.supporting_evidence ?? "";
  const isLongSupportiveEvd = supportiveEvd.length > maxLengthSupportiveEvd;

    const truncatedSupportiveEvd = isLongSupportiveEvd && !showSupportiveEvdMore
      ? `${supportiveEvd.slice(0, maxLengthSupportiveEvd)}...`
      : supportiveEvd;

  return (
    <div className="nj-sm-6 left-bar">
      <div className="tablist">
        <Tabs
          animate={true}
          key={"vertical"}
          vertical={false}
        >
          <Tab id="overview" title="Overview" panel={
            <div className="tablist__content">
              <h3>{selectedStrategy.name}</h3>
              <p>
                <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                {isLongDescription && !showOverviewMore && (
                  <span>
                    <a onClick={() => setShowOverviewMore(true)}>
                      {intl.formatMessage({ id: "app.titles.more-details" })}

                    </a>
                  </span>
                )}
              </p>
              {selectedStrategy && selectedStrategy.domains.length > 0 && (
                <div className="linked-domain">
                  <h4>{intl.formatMessage({id:"app.strategies.linked-domains"})}</h4>
                  <ul>
                    {selectedStrategy &&
                      selectedStrategy.domains.slice(0, linkedDomains).map((domain: any) => {
                        return (
                          <li
                            key={domain.id}
                            style={{
                              borderLeft: `5px solid ${domain.color_hex}`,
                            }}
                          >
                            {domain.name}
                          </li>
                        );
                      })}
                  </ul>
                  {linkedDomains < selectedStrategy.domains.length && (
                 <p>   
                  <div>
                      <span>
                      <a onClick={() => setLinkedDomains(selectedStrategy.domains.length)}>
                        {intl.formatMessage({ id: "app.titles.more-details" })}
                      </a>
                      </span>
                    </div>
                      </p>
                  )}
                </div>
              )}
            </div>
            }
          />
          <Tab
            id="supportive-evidences"
            title="Supportive Evidence/References"
            panel={
              <div className="tablist__content">
                <p>
                  <div dangerouslySetInnerHTML={{ __html: truncatedSupportiveEvd }} className="view-detail-cnt" />
                  {isLongSupportiveEvd && !showSupportiveEvdMore && (
                    <span>
                      <a onClick={() => setShowSupportiveEvdMore(true)}>
                      {intl.formatMessage({id:"app.titles.more-details"})}

                      </a>
                    </span>
                  )}
                </p>
              </div>
            }
          />
        </Tabs>
      </div>
    </div>
    )
}

export default StrategyDetails;
