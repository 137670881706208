import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Classes, Dialog, Icon } from "@blueprintjs/core";
import DomainForm from "./DomainForm";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../helpers/toaster";
import {
  addDomain,
  hideDomainUpsertDialog,
  updateDomain,
} from "../../../store/domains/actions";
import { AddDomainRequest } from "../../../api/domains/types";

type OwnProps = {};

type Props = OwnProps;

const DomainUpsertDialog: FunctionComponent<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const show = useSelector((s) => s.domains.showDomainUpsertDialog);
  const handleClose = () => {
    dispatch(hideDomainUpsertDialog());
  };

  const domainName = useSelector<string | undefined>(
    (s) => s.domains.domainName
  );

  const formId = useMemo(() => {
    return "domain-creation-form";
  }, []);

  const selectedDomain = useSelector((s) => s.domains.selectedDomain);

  const loading = useSelector((s) =>
    selectedDomain
      ? s.domains.loading.updateDomain
      : s.domains.loading.addDomain
  );
  const error = useSelector((s) =>
    selectedDomain ? s.domains.errors.updateDomain : s.domains.errors.addDomain
  );
  const onSuccess = useCallback(() => {
    dispatch(hideDomainUpsertDialog());
    AppToaster.show({
      message: intl.formatMessage({
        id: selectedDomain
          ? "app.toaster.domains.updated"
          : "app.toaster.domains.added",
      }),
      icon: "tick",
      intent: "success",
    });
  }, []);

  useLoading({
    loading: loading,
    error: error,
    onSuccess: onSuccess,
  });

  const handleFormSubmit = (params: AddDomainRequest) => {
    if (selectedDomain) {
      dispatch(
        updateDomain.request({
          id: selectedDomain.id,
          ...params,
        })
      );
    } else {
      dispatch(addDomain.request(params));
    }
  };

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon="inbox" iconSize={24} />}
      title={intl.formatMessage({
        id: selectedDomain
          ? "app.domains.dialogs.edit-domain.title"
          : "app.domains.dialogs.domain-creation.title",
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        <DomainForm
          selectedDomain={selectedDomain}
          query={domainName}
          onFormSubmit={handleFormSubmit}
          formId={formId}
        />
      </div>
      <div className={`${Classes.DIALOG_FOOTER} mt-4`}>
        <div className="flex justify-between">
          <Button
            title={intl.formatMessage({ id: "app.titles.cancel" })}
            text={intl.formatMessage({ id: "app.titles.cancel" })}
          />
          <Button
            intent={"primary"}
            type={"submit"}
            form={formId}
            title={intl.formatMessage({ id: "app.titles.save" })}
            text={intl.formatMessage({ id: "app.titles.save" })}
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DomainUpsertDialog;
