import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";
import { DomainComparison, DomainQuestionsComparison, SurveyReportTaskIdName } from "../../types";
import { API_ROOT } from "../../api/config";

export const getSurveyReportEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyReport.request)),
    switchMap((action) =>
      from(api.surveyReports.getSurveyReports(action.payload)).pipe(
        map(actions.getSurveyReport.success),
        catchError((error) => of(actions.getSurveyReport.failure(error)))
      )
    )
  );
};

export const fillBreadcrumbEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.fillBreadcrumb.request)),
    mergeMap((action) => {
      let inputActions: RootAction[] = [];
      if (action.payload?.fetchDistricts) {
        inputActions.push(actions.getDistricts.request());
      }
      if (action.payload?.districtId) {
        inputActions.push(
          actions.getSchoolsByDistrict.request(action.payload.districtId)
        );
      }
      if (action.payload?.schoolId) {
        inputActions.push(
          actions.getSurveyDeploymentsBySchool.request(action.payload.schoolId)
        );
      }
      inputActions.push(actions.fillBreadcrumb.success());
      return from(inputActions);
    })
  );
};

export const getSurveyComparisonEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyComparison.request)),
    switchMap((action) =>
      from(api.surveyReports.getSurveyComparison(action.payload)).pipe(
        map(actions.getSurveyComparison.success),
        catchError((error) => of(actions.getSurveyComparison.failure(error)))
      )
    )
  );
};


export const getComparisonDomainItemsData: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getComparisonDomainItemsData.request)),
    switchMap((action) =>
      from(api.surveyReports.getComparisonDomainItemsData(action.payload)).pipe(
        map(actions.getComparisonDomainItemsData.success),
        catchError((error) => of(actions.getComparisonDomainItemsData.failure(error)))
      )
    )
  );
};






export const getRespondentGraphDomainData: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getRespondentGraphDomainData.request)),
    switchMap((action) =>
      from(api.surveyReports.getRespondentGraphDomainData(action.payload)).pipe(
        map(actions.getRespondentGraphDomainData.success),
        catchError((error) => of(actions.getRespondentGraphDomainData.failure(error)))
      )
    )
  );
};


export const getRespondentGraphItemData: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getRespondentGraphItemData.request)),
    switchMap((action) =>
      from(api.surveyReports.getRespondentGraphItemData(action.payload)).pipe(
        map(actions.getRespondentGraphItemData.success),
        catchError((error) => of(actions.getRespondentGraphItemData.failure(error)))
      )
    )
  );
};

export const getDistrictSurveyComparisonEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getDistrictSurveyComparison.request)),
    switchMap((action) => {
      type ComparisonResponse = DomainQuestionsComparison | DomainComparison[];
      let promiseArray = action.payload.surveyComparisonReq.map(
        api.surveyReports.getSurveyComparison
      );
      if (action.payload.isComprisonReport)
        promiseArray.push(
          api.surveyReports.getSurveyComparison(
            action.payload.surveyComparisonReq
          )
        );
      const promise = Promise.all(promiseArray);

      promise.then((res: ComparisonResponse[]) => {
        return res;
      });
      return from(promise);
    }),
    map(actions.getDistrictSurveyComparison.success),
    catchError((error) =>
      of(actions.getDistrictSurveyComparison.failure(error))
    )
  );
};

export const downloadStaticReportEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.downloadStaticReport.request)),
    switchMap((action) =>
      from(
        api.surveyReports.downloadStaticReport(
          action.payload.surveyDeploymentId,
          action.payload.schoolId,
          action.payload.demographic,
          action.payload.hide_sensitive_reporting_data
        )
      ).pipe(
        map((file) => {
          const blob = new Blob([file], { type: "application/pdf" });
          const pdfUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("download", action.payload.surveyName);
          link.href = pdfUrl;
          document.body.append(link);
          link.click();
          link.remove();
          return actions.downloadStaticReport.success(file);
        }),
        catchError((error) => of(actions.downloadStaticReport.failure(error)))
      )
    )
  );
};

export const downloadCSVReportEpic: Epic<RootAction, RootAction, RootAction> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.downloadCSVReport.request)),
    switchMap((action) =>
      from(
        api.surveyReports.downloadCSVReport(
          action.payload.surveyDeploymentId,
          action.payload.schoolId,
          action.payload.demographic
        )
      ).pipe(
        map((result: SurveyReportTaskIdName| Blob) => {
          const id = (result as SurveyReportTaskIdName)?.task_id;
          if (!id) {
            const blob = new Blob([(result as Blob)], { type: "text/csv" });
            const csvUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("download", action.payload.surveyName + ".csv");
            link.href = csvUrl;
            document.body.append(link);
            link.click();
            link.remove();
          }
          return actions.downloadCSVReport.success({ ...result, name: action.payload.surveyName });
        }),
        catchError((error) => of(actions.downloadCSVReport.failure(error)))
      )
    )
  );
};


export const downloadCSVReportEpicByTaskId: Epic<RootAction, RootAction, RootAction> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.downloadCSVReportByTaskId.request)),
    switchMap((action) =>
      from(
        api.surveyReports.downloadCSVReportByTaskId(
          action.payload.task_id
        )
      ).pipe(
        map((result) => {
          const blob = new Blob([(result as Blob)], { type: "text/csv" });
          const csvUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("download", action.payload?.name ?? 'report' + ".csv");
          link.href = csvUrl;
          document.body.append(link);
          link.click();
          link.remove();
          return actions.downloadCSVReport.success(result);
        }),
        catchError((error) => of(actions.downloadCSVReportByTaskId.failure(error)))
      )
    )
  );
};


export const getSurveyReportResponseRatesEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyReportResponseRates.request)),
    switchMap((action) =>
      from(
        api.surveyReports.getSurveyReportResponseRates(
          action.payload.surveyDeploymentId,
          action.payload.schoolId,
          action.payload?.domainId
        )
      ).pipe(
        map(actions.getSurveyReportResponseRates.success),
        catchError((error) =>
          of(actions.getSurveyReportResponseRates.failure(error))
        )
      )
    )
  );
};

export const getSurveyInsightsEpic: Epic<RootAction, RootAction, RootAction> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyInsights.request)),
    switchMap((action) =>
      from(
        api.surveyReports.getInsights(
          action.payload.surveyDeploymentId,
          action.payload.schoolId
        )
      ).pipe(
        map(actions.getSurveyInsights.success),
        catchError((error) => of(actions.getSurveyInsights.failure(error)))
      )
    )
  );
};

export const shareFeedbackEpic: Epic<RootAction, RootAction, RootAction> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.shareFeedback.request)),
    switchMap((action) =>
      from(api.surveyReports.shareFeedback(action.payload.message,action.payload.feedback_choices)).pipe(
        map(actions.shareFeedback.success),
        catchError((error) => of(actions.shareFeedback.failure(error)))
      )
    )
  );
};

export const getShareFeedbackOptionEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getShareFeedbackOption.request)),
    switchMap((action) =>
      from(api.surveyReports.getShareFeedbackOption()).pipe(
        map(actions.getShareFeedbackOption.success),
        catchError((error) => of(actions.getShareFeedbackOption.failure(error)))
      )
    )
  );
};

export const getDomainsAndItemsByIdsEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getDomainsAndItemsByIds.request)),
    switchMap((action) =>
      from(api.domains.getDomainsAndItemsByIds(action.payload)).pipe(
        map(actions.getDomainsAndItemsByIds.success),
        catchError((error) =>
          of(actions.getDomainsAndItemsByIds.failure(error))
        )
      )
    )
  );
};

export const actionAuditselectSurveyDeploymentEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.actionAuditselectSurveyDeployment.request)),
    switchMap((action) =>
      from(
        api.surveyReports.actionAuditselectSurveyDeployment(action.payload)
      ).pipe(
        map(actions.actionAuditselectSurveyDeployment.success),
        catchError((error) =>
          of(actions.actionAuditselectSurveyDeployment.failure(error))
        )
      )
    )
  );
};
