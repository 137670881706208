import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { MeetingTemplate } from "../../types";

type ActionName =
  | "getMeetingTemplates"
  | "addMeetingTemplate"
  | "updateMeetingTemplate"
  | "deleteMeetingTemplate";

export type MeetingTemplatesState = {
  meetingTemplates: MeetingTemplate[];

  dialogs: {
    meetingTemplateDialog: {
      show?: boolean;
      meetingTemplate?: MeetingTemplate;
    };
  };

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: MeetingTemplatesState = {
  meetingTemplates: [],
  dialogs: { meetingTemplateDialog: {} },
  loading: {},
  errors: {},
};

export const meetingTemplatesReducer = createReducer<
  MeetingTemplatesState,
  RootAction
>(initialState)
  // show meeting templates dialog
  .handleAction(
    actions.showMeetingTemplateDialog,
    (state, action): MeetingTemplatesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        meetingTemplateDialog: {
          show: true,
          meetingTemplate: action.payload.meetingTemplate,
        },
      },
    })
  )
  // hide meeting templates dialog
  .handleAction(
    actions.hideMeetingTemplateDialog,
    (state, action): MeetingTemplatesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        meetingTemplateDialog: {},
      },
    })
  )
  // get meeting templates
  .handleAction(
    actions.getMeetingTemplates.request,
    (state, action): MeetingTemplatesState => ({
      ...state,
      loading: {
        ...state.loading,
        getMeetingTemplates: true,
      },
      errors: {
        ...state.errors,
        getMeetingTemplates: undefined,
      },
    })
  )
  .handleAction(
    actions.getMeetingTemplates.success,
    (state, action): MeetingTemplatesState => ({
      ...state,
      meetingTemplates: action.payload,
      loading: {
        ...state.loading,
        getMeetingTemplates: false,
      },
    })
  )
  .handleAction(
    actions.getMeetingTemplates.failure,
    (state, action): MeetingTemplatesState => ({
      ...state,
      errors: {
        ...state.errors,
        getMeetingTemplates: action.payload,
      },
      loading: {
        ...state.loading,
        getMeetingTemplates: false,
      },
    })
  )
  // add meeting template
  .handleAction(
    actions.addMeetingTemplate.request,
    (state, action): MeetingTemplatesState => ({
      ...state,
      loading: {
        ...state.loading,
        addMeetingTemplate: true,
      },
      errors: {
        ...state.errors,
        addMeetingTemplate: undefined,
      },
    })
  )
  .handleAction(
    actions.addMeetingTemplate.success,
    (state, action): MeetingTemplatesState => ({
      ...state,
      meetingTemplates: [action.payload, ...state.meetingTemplates],
      loading: {
        ...state.loading,
        addMeetingTemplate: false,
      },
    })
  )
  .handleAction(
    actions.addMeetingTemplate.failure,
    (state, action): MeetingTemplatesState => ({
      ...state,
      errors: {
        ...state.errors,
        addMeetingTemplate: action.payload,
      },
      loading: {
        ...state.loading,
        addMeetingTemplate: false,
      },
    })
  )
  // update meeting template
  .handleAction(
    actions.updateMeetingTemplate.request,
    (state, action): MeetingTemplatesState => ({
      ...state,
      loading: {
        ...state.loading,
        updateMeetingTemplate: true,
      },
      errors: {
        ...state.errors,
        updateMeetingTemplate: undefined,
      },
    })
  )
  .handleAction(
    actions.updateMeetingTemplate.success,
    (state, action): MeetingTemplatesState => ({
      ...state,
      meetingTemplates: state.meetingTemplates.map((a) =>
        a.id === action.payload.id ? action.payload : a
      ),
      loading: {
        ...state.loading,
        updateMeetingTemplate: false,
      },
    })
  )
  .handleAction(
    actions.updateMeetingTemplate.failure,
    (state, action): MeetingTemplatesState => ({
      ...state,
      errors: {
        ...state.errors,
        updateMeetingTemplate: action.payload,
      },
      loading: {
        ...state.loading,
        updateMeetingTemplate: false,
      },
    })
  )
  // delete meeting template
  .handleAction(
    actions.deleteMeetingTemplate.request,
    (state, action): MeetingTemplatesState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteMeetingTemplate: true,
      },
      errors: {
        ...state.errors,
        deleteMeetingTemplate: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteMeetingTemplate.success,
    (state, action): MeetingTemplatesState => ({
      ...state,
      meetingTemplates: state.meetingTemplates.filter(
        (a) => a.id !== action.payload
      ),
      loading: {
        ...state.loading,
        deleteMeetingTemplate: false,
      },
    })
  )
  .handleAction(
    actions.deleteMeetingTemplate.failure,
    (state, action): MeetingTemplatesState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteMeetingTemplate: action.payload,
      },
      loading: {
        ...state.loading,
        deleteMeetingTemplate: false,
      },
    })
  );
