import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Card, HTMLTable, Switch } from "@blueprintjs/core";
import { getBooleanFromLocalStorage, LocalStorageKeys } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { toggleSensitiveData } from "../../store/survey-reports/actions";

type OwnProps = {};

type Props = OwnProps;

type AdminSettingItemType = {
  translatedName: string;
  value: boolean | undefined;
};

const AdminSettingsPage: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // not using hideSensitiveData for sensitive data
  const hideSensitiveData = useSelector(
    (s) => s.surveyReports.hideSensitiveData
  );

  const localFlagToHideSensitiveData: boolean = useMemo(() => {
    const result = getBooleanFromLocalStorage(
      LocalStorageKeys.SensitiveReportingDataHidden
    );
    return result;
  }, []);

  const [settings, setSettings] = useState<AdminSettingItemType[]>([
    {
      translatedName: intl.formatMessage({
        id: "app.admin.settings.hide-reporting-data",
      }),
      value: localFlagToHideSensitiveData, //hideSensitiveData
    },
  ]);

  const handleSettingChange = (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const checked = e.currentTarget?.checked;
    localStorage.setItem(
      LocalStorageKeys.SensitiveReportingDataHidden,
      JSON.stringify(checked)
    );
    dispatch(
      toggleSensitiveData({
        sensitiveDataToggle: checked,
      })
    );
    setSettings(
      settings.map((item, i) =>
        i === index ? { ...item, value: checked } : item
      )
    );
  };

  return (
    <Card className="w-full">
      <HTMLTable className="w-full" striped={false} interactive>
        <thead>
          <tr>
            <th className="text-2xl">
              {intl.formatMessage({
                id: "app.admin.settings-table.system-setting",
              })}
            </th>
            <th className="text-2xl">
              {intl.formatMessage({
                id: "app.admin.settings-table.value",
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {settings.map((setting, index) => (
            <tr key={index}>
              <td className="text-xl">{setting.translatedName}</td>
              <td>
                <Switch
                  checked={setting.value}
                  className="mb-0"
                  onChange={(e) => handleSettingChange(e, index)}
                  large
                  innerLabelChecked={intl.formatMessage({
                    id: "app.titles.on",
                  })}
                  innerLabel={intl.formatMessage({ id: "app.titles.off" })}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Card>
  );
};

export default AdminSettingsPage;
