// useIdleTimer.ts
import { useState, useEffect, useCallback } from 'react';

type IdleCallback = () => void;

interface UseIdleTimerOptions {
    timeout: number;
    onIdle: IdleCallback;
}

const useIdleTimer = ({ timeout, onIdle }: UseIdleTimerOptions) => {
    const [isIdle, setIsIdle] = useState<boolean>(false);
    let idleTimeout: NodeJS.Timeout;
    const handleUserActivity = useCallback(() => {
        if (isIdle) {
            setIsIdle(false);
            console.log('>> log 1');
        }
        clearTimeout(idleTimeout);
        idleTimeout = setTimeout(() => {
        console.log('>> log 2 : executing timeout');
            setIsIdle(true);
            onIdle();
        }, timeout);
        console.log('>> log 3 : ', new Date());
    }, [isIdle, onIdle, timeout]);

    useEffect(() => {

        // Attach event listeners
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);

        // Initialize idle timer
        handleUserActivity();

        // Cleanup on unmount
        return () => {
            clearTimeout(idleTimeout);
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            console.log('>> log 4', timeout)
        };
    }, []);

    // handleUserActivity

    return isIdle;
};

export default useIdleTimer;
