import { useCallback, useMemo } from "react";
import { BookmarkType, DatasetMark } from "../../../types";
import { useSelector } from "react-redux";
import _ from "lodash";

type Props = {
  domainId: number;
};

export const useDomainLevelBookmarkCounter = (props: Props) => {
  const { domainId } = props;

  const surveyDeploymentId = useSelector((s) => s.needs.surveyDeploymentId);

  const datasetMarks: DatasetMark[] = useSelector(
    (s) => s.datasetMarks.datasetMarks
  );

  const filterDomainLevelDatasetMarks = useCallback(
    ({ dataset }: DatasetMark) => {
      return (
        dataset.domain?.id === domainId &&
        !dataset.is_grouped &&
        !dataset.question &&
        !!dataset.data_points?.some(
          (dP) => dP.survey_deployment_id === surveyDeploymentId
        )
      );
    },
    [domainId, surveyDeploymentId]
  );

  const marksCountByDemographic = useMemo(() => {
    return _.chain(datasetMarks)
      .filter(filterDomainLevelDatasetMarks)
      .groupBy((dM) => dM.dataset.demographic)
      .mapValues((values) =>
        values.reduce<
          {
            [key in BookmarkType]: number;
          }
        >(
          (pV, cV) => {
            return { ...pV, [cV.bookmark_type]: pV[cV.bookmark_type] + 1 };
          },
          {
            [BookmarkType.Positive]: 0,
            [BookmarkType.Negative]: 0,
          }
        )
      )
      .value();
  }, [filterDomainLevelDatasetMarks, datasetMarks]);

  return { marksCountByDemographic };
};

export default useDomainLevelBookmarkCounter;
