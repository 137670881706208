import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS } from "react-joyride";
import CustomButtonsTooltipComponent from "./CustomButtonsTooltipComponent";
import { DistrictSetupTaskKey, UserRole } from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { markStepCompleted, updateMakingProgressTourTimeWatched } from "../../../../store/tours/actions";
import useUserPlanAssociation from "../../../../helpers/hooks/useUserPlanAssociation";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import useNextTourCallback from "../../../../helpers/hooks/team-members/useNextTourCallback";
import TooltipComponent from "../../../tour/TooltipComponent";

type OwnProps = {};

type Props = OwnProps;

const ManageUsersTour: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const { isDCC, isSuperintendent, teamRole, isTeamMember, role, } = useUserRole();

  const {
    isSchoolPlanPrincipal,
    planSchoolId,
    isSingleSchoolDistrict,
  } = useUserPlanAssociation();

  const onNext = useNextTourCallback(
    planSchoolId
      ? DistrictSetupTaskKey.InviteSchoolStaff
      : DistrictSetupTaskKey.InviteOtherMembers
  );

  const onNextPrincipal = useNextTourCallback(
    planSchoolId
      ? DistrictSetupTaskKey.InviteSchoolStaffPrincipal
      : DistrictSetupTaskKey.InviteOtherMembers
  );

  const onNextTour = useNextTourCallback(
    DistrictSetupTaskKey.LearnAboutTeamDevelopment
  );
  
  
  const isSchoolLevelPresentation = useMemo(() => {
    return (
      (isTeamMember &&
        (role === UserRole.Staff || role === UserRole.VicePrincipal)) ||
      (isSchoolPlanPrincipal && role !== UserRole.Superintendent)
    );
  }, [role, isTeamMember, isSchoolPlanPrincipal]);

  const steps = useMemo(() => {
    if (isDCC || isSchoolPlanPrincipal){
    return [
      {
        target: '[data-tour="team-development-presentation"]',
        content: intl.formatMessage(
          {
            id: `app.tours.team-development-presentation${isSchoolLevelPresentation ? ".team_member" : ""
              }`,
          },
          {
            i: (chunks) => <i>{chunks}</i>,
          }
        ),
        disableBeacon: true,
      },
      {
        target: '[data-tour="manage-users-tour"]',
        content: intl.formatMessage({
          id: planSchoolId
            ? "app.tours.invite_school_staff"
            : "app.tours.manage_users_tour",
        }),
        disableBeacon: true,
      },
    ];
  }
  else{
    return [
      {
        target: '[data-tour="team-development-presentation"]',
        content: intl.formatMessage(
          {
            id: `app.tours.team-development-presentation${isSchoolLevelPresentation ? ".team_member" : ""
              }`,
          },
          {
            i: (chunks) => <i>{chunks}</i>,
          }
        ),
        disableBeacon: true,
      },
    ];
  }
  }, [planSchoolId]);

  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    if (isDCC || isSuperintendent || isSchoolPlanPrincipal) {
      const start = setupCompleted
        ? false
        : planSchoolId
          ? isSchoolPlanPrincipal &&
          !tours[DistrictSetupTaskKey.InviteSchoolStaff]?.[planSchoolId]
            ?.completed &&
          !tours[DistrictSetupTaskKey.InviteSchoolStaff]?.[planSchoolId]
            ?.softAddStaff &&
          !tours[DistrictSetupTaskKey.LearnAboutTeamDevelopment]
          : isSingleSchoolDistrict
            ? false
            : (isDCC || isSuperintendent) &&
            !tours[DistrictSetupTaskKey.InviteOtherMembers] &&
            !tours.softAddDistrictStaff &&
            !!tours[DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals];

      setShow(start);
    }
    else{
      const start = setupCompleted
        ? false : true
      setShow(start);
    }
  }, [
    planSchoolId,
    isDCC,
    isSuperintendent,
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
    setupCompleted,
  ]);

  const handleTourClose = () => {
    setShow(false);
    if (planSchoolId) {
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.InviteSchoolStaff,
          schoolId: planSchoolId,
          softAdd: true,
        })
      );
    } else {
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.InviteOtherMembers,
          softAdd: true,
        })
      );
    }
  };

  const handleStepSkip = useCallback(() => {
    setShow(false);
    if (planSchoolId) {
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.InviteSchoolStaff,
          schoolId: planSchoolId,
        })
      );
      onNext && onNext();
    } else {
      dispatch(
        markStepCompleted({ key: DistrictSetupTaskKey.InviteOtherMembers })
      );
      onNext && onNext();
    }
  }, [planSchoolId]);

  const renderTooltipComponent = useCallback(
    (tooltipProps) => {
      return (
        <>
        <CustomButtonsTooltipComponent
          {...tooltipProps}
          onDismissClick={handleTourClose}
          onContinueClick={handleStepSkip}
        />
        </>
      );
    },
    [handleTourClose, handleStepSkip]
  );

  const handleJoyrideAction = (data: CallBackProps) => {
    console.log(data,'data')
    if(data.step.target === '[data-tour="team-development-presentation"]' && data.action === 'prev' && isDCC){
      setShow(false);
    }
    if(data.step.target === '[data-tour="team-development-presentation"]' && data.action === 'prev'){
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
        })
      );
      setShow(false);
    }
    if (([STATUS.RUNNING, STATUS.SKIPPED] as string[]).includes(data.status) && data.step.target === '[data-tour="manage-users-tour"]' && (isDCC || isSchoolPlanPrincipal)) {
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
        })
      );
      dispatch(updateMakingProgressTourTimeWatched(new Date()));
    
    }
    
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status) && data.step.target === '[data-tour="team-development-presentation"]') {
      dispatch(
        markStepCompleted({ key: DistrictSetupTaskKey.InviteOtherMembers })
      );
      onNextTour && onNextTour();      
    }   

    if(data.step.target === '[data-tour="manage-users-tour"]' && data.action === 'prev'){
      
      if (planSchoolId) {
        dispatch(
          markStepCompleted({
            key: DistrictSetupTaskKey.InviteSchoolStaff,
            schoolId: planSchoolId,
            softAdd: true,
          })
        );
        onNext && onNext();
      } else {
        dispatch(
          markStepCompleted({
            key: DistrictSetupTaskKey.InviteOtherMembers,
            softAdd: true,
          })
        );
        onNext && onNext();
      }
      setShow(false);
    }
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status) && data.step.target === '[data-tour="manage-users-tour"]') {
      setShow(false);
        if (planSchoolId) {
          dispatch(
            markStepCompleted({
              key: DistrictSetupTaskKey.InviteSchoolStaff,
              schoolId: planSchoolId,
              softAdd: true,
            })
          );
          onNextPrincipal && onNextPrincipal();
        } else {
          dispatch(
            markStepCompleted({ key: DistrictSetupTaskKey.InviteOtherMembers })
          );
          onNextTour && onNextTour();
        }
    }
  };

  return (
    <ReactJoyride
      run={show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      callback={handleJoyrideAction}
      continuous
      disableOverlayClose
    />
  );
};

export default ManageUsersTour;
