import { Button, Tab, Tabs, Tooltip } from "@blueprintjs/core";
import React, { FunctionComponent, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router";
import { AuthRoutes } from "../../../App";
import {
  showAddUserToPlanDialog,
  showInviteUsersDialog,
} from "../../../store/users/actions";
import {
  Activity,
  ActivityTagEnum,
  ActivityTypeEnum,
  DistrictSetupTaskKey,
  TeamRole,
  UserNotesType,
  UserRole
} from "../../../types";
import TeamPlanCalendar from "../calendar/TeamPlanCalendar";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import AddUserToPlanDialog from "./add-user-to-plan-dialog/AddUserToPlanDialog";
import TeamHomeTab from "./tabs/team-home-tab/TeamHomeTab";
// import ActivityResourcesTab from "./tabs/resources-tab/ActiveResourcesTabs";
import useUserRole from "../../../helpers/hooks/useUserRole";
import {
  markStepCompleted,
  selectDistrictSetupTaskKey,
} from "../../../store/tours/actions";
import useUserPlanAssociation from "../../../helpers/hooks/useUserPlanAssociation";
import LeadershipTeamWorkspaceTour from "../../tour/team-plan/LeadershipTeamWorkspaceTour";
import { Card } from "@mui/material";
import { ACTIVITY_LINK_CLASSNAME, useAttachActivities } from "../../../helpers/hooks/useAttachActivities";
import { showActivityVideoDialog } from "../../../store/activities/actions";
import './teamingPage.scss';
import ActivityResourcesTab from "./tabs/resources-tab/ActiveResourcesTabs";

const GUIDELINES_URL = "https://platform.njschoolclimate.org/files/activity/Mg==/download/pdf/Guidance+on+School+Climate+Leadership+Team+Development.pdf";

type OwnProps = {};

type Props = OwnProps;

const TeamingPage: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const { planSchoolId } = useUserPlanAssociation();

  const isDistrictActivePlan = useMemo(() => {
    return !planSchoolId;
  }, [planSchoolId]);

  

  const members = useSelector((s) => s.teamMembers.teamMembers);

  const handleShowInviteUsersDialog = () => {
    dispatch(
      showInviteUsersDialog({
        onSuccess: (members || []).some((m) => m.user?.profile?.is_dcc)
          ? undefined
          : () => {
              history.push(
                generatePath(AuthRoutes.YourPlan, {
                  workspace: "dashboard",
                } as any)
              );
              dispatch(
                markStepCompleted({
                  key: DistrictSetupTaskKey.DesignateDistrictClimateCoordinator,
                })
              );
              dispatch(
                selectDistrictSetupTaskKey(
                  DistrictSetupTaskKey.FamiliarizeWithSccp
                )
              );
            },
      })
    );
  };

  const handleShowAddUsersToPlanDialog = () => {
    dispatch(showAddUserToPlanDialog());
  };

  const { tabId } = useParams<{ tabId?: string }>();
  console.log(UserNotesType.SCLT_SCI_CALENDAR === tabId,'UserNotesType.SCLT_SCI_CALENDAR')

  const selectedTabId: UserNotesType = useMemo(() => {
    return tabId &&
      [
        UserNotesType.SCLT_TEAM_HOME,
        UserNotesType.SCLT_SCI_CALENDAR,
        UserNotesType.SCLT_RESOURCES,
        UserNotesType.DCLT_TEAM_HOME,
        UserNotesType.DCLT_SCI_CALENDAR,
        UserNotesType.DCLT_RESOURCES,
      ].includes(tabId as UserNotesType)
      ? (tabId as UserNotesType)
      : isDistrictActivePlan
      ? UserNotesType.DCLT_TEAM_HOME
      : UserNotesType.SCLT_TEAM_HOME;
  }, [tabId, isDistrictActivePlan]);

  const activePlan = useSelector((s) => s.plans.activePlan);
  const { hasDistrictPlanAccess, teamRole ,role ,isDCC} = useUserRole();




  const showGuidanceButton = useMemo(() => {
    return !!activePlan?.school?.id;
  }, [activePlan?.school?.id]);

  const showManageUsersSection = useMemo(() => {
    return (
      (selectedTabId === UserNotesType.SCLT_TEAM_HOME ||
        selectedTabId === UserNotesType.DCLT_TEAM_HOME) &&
      (hasDistrictPlanAccess ||
        (teamRole &&
          !isDistrictActivePlan &&
          (teamRole === TeamRole.TeamChampion ||
            teamRole === TeamRole.TeamChair)))
    );
  }, [selectedTabId, hasDistrictPlanAccess, teamRole, isDistrictActivePlan]);

  const inviteUserButtonTitle = useMemo(() => {
    return isDistrictActivePlan
      ? "app.team-plan.setup.team.invite-new-member-district"
      : "app.team-plan.setup.team.invite-new-member-school";
  }, [isDistrictActivePlan]);

  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      switch (activity.activity_type) {
        case ActivityTypeEnum.KALTURA_VIDEO:
          dispatch(
            showActivityVideoDialog({
              activity: activity,
              viewable_field: "embed_code",
            })
          );
          break;

        default:
          break;
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `app.titles.${isDistrictActivePlan ? "district-" : ""}teaming`,
          })}
        </title>
        
      </Helmet>
      
      <div className="teampage_container">
        <WorkspaceHeader
          title={intl.formatMessage({
            id: `app.titles.${isDistrictActivePlan ? "district-" : ""}teaming`,
          })}
          userNotesType={selectedTabId}
          isDistrictSchoolTeamHomeTab={ isDistrictActivePlan
            ? UserNotesType.DCLT_TEAM_HOME ===selectedTabId
            : UserNotesType.SCLT_TEAM_HOME===selectedTabId}
        />
        
        {(UserNotesType.SCLT_SCI_CALENDAR === tabId || UserNotesType.DCLT_SCI_CALENDAR === tabId) ?
        <TeamPlanCalendar /> :(
        <div className="teampage_container__tab">
          <Tabs
            renderActiveTabPanelOnly
            selectedTabId={selectedTabId}
            onChange={(tabId) => {
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                  tabId: tabId,
                } as any)
              );
            }}
          >
            <Tab
              id={
                isDistrictActivePlan
                  ? UserNotesType.DCLT_TEAM_HOME
                  : UserNotesType.SCLT_TEAM_HOME
              }
              title={intl.formatMessage({ id: "app.titles.team-home" })}
              panel={<TeamHomeTab />}
            />

            {/* <Tab
              data-tour={"sci-calendar-tab"}
              id={
                isDistrictActivePlan
                  ? UserNotesType.DCLT_SCI_CALENDAR
                  : UserNotesType.SCLT_SCI_CALENDAR
              }
              title={
                <Tooltip
                  interactionKind="hover"
                  popoverClassName="w-64"
                  content={intl.formatMessage({ id: "app.calendar.info" })}
                >
                  {intl.formatMessage({ id: "app.titles.sci-calendar" })}
                </Tooltip>
              }
              panel={<TeamPlanCalendar />}
            /> */}
            <Tab
              data-tour={"resources-tab"}
              id={
                isDistrictActivePlan
                  ? UserNotesType.DCLT_RESOURCES
                  : UserNotesType.SCLT_RESOURCES
              }
              title={intl.formatMessage({ id: "app.titles.resources" })}
              panel={
                <ActivityResourcesTab
                  activityTag = {ActivityTagEnum.TEAM}
                  userNotesType = {
                    isDistrictActivePlan
                      ? UserNotesType.DCLT_RESOURCES
                      : UserNotesType.SCLT_RESOURCES
                  }
                />
              }
            />
            <Tabs.Expander />
           {(role===UserRole.Superintendent && !isDCC) && 
           <div
            style={{position:"relative",right:"160px",top:"120px",width:"250px",height:"30px"}} 
            data-tour="manage-users-tour">
            </div>}
          </Tabs>
        </div>
        )
        }
      </div>
      <AddUserToPlanDialog />
      <LeadershipTeamWorkspaceTour />
    </>
  );
};

export default TeamingPage;
