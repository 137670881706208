import React from "react";
import { useIntl } from "react-intl";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";

type OwnProps = {
  show: boolean;
  onClose: () => void;
};

type Props = OwnProps;

const CalendarInfoDialog: React.FC<Props> = ({ show, onClose }: Props) => {
  const intl = useIntl();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  return (
    <Dialog
      isOpen={show}
      onClose={onClose}
      title={intl.formatMessage({ id: "app.titles.nj-calendar" })}
      style={{ width: "50rem" }}
    >
      <div className={`${Classes.DIALOG_BODY} terms-and-conditions-body`}>
        {intl.formatMessage(
          {
            id: `app.calendar-info.${
              isDistrictActivePlan ? "district" : "school"
            }-plan`,
          },
          {
            br: <br />,
          }
        )}
      </div>

      <div className="flex justify-center mt-4">
        <Button
          large
          intent="primary"
          text={intl.formatMessage({ id: "app.titles.close" })}
          title={intl.formatMessage({ id: "app.titles.close" })}
          onClick={onClose}
        />
      </div>
    </Dialog>
  );
};

export default CalendarInfoDialog;
