import React, { FunctionComponent, useMemo } from "react";
import { FormGroup, NumericInput } from "@blueprintjs/core";
import { useIntl } from "react-intl";

type OwnProps = {
  value: string | number;
  onChange: (value: string) => any;
  labelless?: boolean;
  required: boolean;
};

type Props = OwnProps;

const RatioDataPointInput: FunctionComponent<Props> = (props) => {
  const { value, onChange, labelless, children, required } = props;

  const intl = useIntl();

  const parts = useMemo(() => {
    const parts = (value || "").toString().split(":");
    return parts.length === 2 ? parts : ["", ""];
  }, [value]);

  const handleNumeratorChange = (numerator: number) => {
    onChange(`${numerator}:${parts[1]}`);
  };
  const handleDenominatorChange = (denominator: number) => {
    onChange(`${parts[0]}:${denominator}`);
  };

  return (
    <div className="flex items-start justify-between">
      <FormGroup
        label={
          labelless
            ? undefined
            : intl.formatMessage({
                id: "app.titles.value",
              })
        }
        className="mb-0"
      >
        <NumericInput
          required={required}
          asyncControl
          placeholder={intl.formatMessage({
            id: "app.forms.numerator.placeholder",
          })}
          value={parts[0]}
          onValueChange={handleNumeratorChange}
        />
        <hr className="my-1 border-gray-500" />
        <NumericInput
          required={required}
          asyncControl
          placeholder={intl.formatMessage({
            id: "app.forms.denominator.placeholder",
          })}
          value={parts[1]}
          onValueChange={handleDenominatorChange}
        />
      </FormGroup>
      {children}
    </div>
  );
};

export default RatioDataPointInput;
