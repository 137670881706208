/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  LookForItem,
  LookForItemStatus,
  RecurringStatusEnum,
} from "../../../../../../types";
import { Collapse, Icon } from "@blueprintjs/core";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
type OwnProps = {
  lookForStatus: Omit<
    LookForItemStatus<Omit<LookForItem, "id" | "created_at" | "updated_at">>,
    "id" | "created_at" | "updated_at"
  >;
  onLookForStatusFieldUpdate: <T extends string | RecurringStatusEnum>(
    key: keyof LookForItem | keyof LookForItemStatus,
    value: T
  ) => void;
  onLookForRemove: () => void;
};

type Props = OwnProps;

const CoreComponentItem: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { lookForStatus, onLookForStatusFieldUpdate, onLookForRemove } = props;

  const [isCollapseDetailsOpen, setCollapseDetailsOpen] = useState<boolean>(false);
  const [isEditText, setIsEditText] = useState<boolean>(lookForStatus.item.text ? false : true);

  const [isError, setIsError] = useState<boolean | undefined>(undefined);

  const recurringStatusesOptions = useMemo(() => {
    return Object.values(RecurringStatusEnum).map((r, i) => ({
      label: r,
      value: i,
      translate: intl.formatMessage({ id: `app.recurring-status.${r}` }),
    }));
  }, [intl]);

  const handleIsEditTextClick = () => {
    setIsEditText(!isEditText);
  };

  const handleLookForTextChange = (
    e: React.FocusEvent<HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    onLookForStatusFieldUpdate("text", value);

    !value.length ? setIsError(true) : setIsError(false);
  };

  const handleDetailsTextChange = (
    e: React.FocusEvent<HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    onLookForStatusFieldUpdate("details", value);
  };

  const handleDetailsClick = () => {
    setCollapseDetailsOpen(!isCollapseDetailsOpen);
  };

  return (
    <>
      {
        lookForStatus?.item?.original || !lookForStatus?.item?.school_item ?
          (
          <div className="accordionbar__content-row">
            <div className="nj-row">
              <div className="nj-sm-9">
                <div className="edit-core-lock">
                  <Icon icon="lock" iconSize={14} />
                    {lookForStatus.item.text}
                  <a onClick={handleDetailsClick}>{intl.formatMessage({
                    id: `app.titles.${isCollapseDetailsOpen ? "hide" : lookForStatus?.details ? "show" : "add"
                      }-details`,
                  })}</a>
                  <Collapse isOpen={isCollapseDetailsOpen} className="">
                    <div className="inputbar">
                        <TextField
                          size="small"
                          id="outlined-multiline-static"
                          multiline
                          error={isError}
                          rows={2}
                          defaultValue=""
                          value={lookForStatus?.details}
                          onChange={handleDetailsTextChange}
                        />
                    </div>
                  </Collapse>
                </div>
              </div>

              <div className="nj-sm-3">
                <div className="not-recurting">
                  <FormControl size="small">
                    <InputLabel id="demo-select-small-label">{intl.formatMessage({
                      id: "app.titles.look-fors.component-recurrence",
                    })}</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      className="dropdowlist"
                      label={intl.formatMessage({
                        id: "app.titles.look-fors.component-recurrence",
                      })}
                      value={lookForStatus?.recurring_status}
                      onChange={(value) =>
                        onLookForStatusFieldUpdate(
                          "recurring_status",
                          value.target.value as string
                        )
                      }
                    >
                      {
                        recurringStatusesOptions.map(item =>
                          <MenuItem key={item.label} value={item.label} className="status-dropdown">
                            {item.translate}
                          </MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        )
          :
          (
            <div className="accordionbar__content-row custom-core-lock custom-core-lock-row ">
              <div className="nj-row">
                <div className="nj-sm-9">
                  <div className="edit-core-lock">
                    <div className="inputbar titleBar">
                      {
                        isEditText ?
                          <>
                          <TextField
                            size="small"
                            id="outlined-multiline-static"
                            multiline
                            error={isError || !lookForStatus?.item?.text.length}
                            rows={1}
                              value={lookForStatus?.item?.text}
                            onChange={handleLookForTextChange}
                            placeholder={intl.formatMessage({
                              id: "app.strategy.looksforstatus.placeholder",
                            })}
                            />
                          </>
                          :
                          <div className="inputbar-content">
                            {lookForStatus?.item?.text}
                            <button className="inputbar-content__editbutton" onClick={handleIsEditTextClick}>
                              <Icon icon="edit" iconSize={16} />
                            </button>

                          </div>
                      }
                      <a onClick={handleDetailsClick}>
                        {intl.formatMessage({
                          id: `app.titles.${isCollapseDetailsOpen ? "hide" : lookForStatus.details ? "show" : "add"
                            }-details`
                        })}
                      </a>
                    </div>
                  </div>

                  <Collapse isOpen={isCollapseDetailsOpen} className="">
                    <div className="inputbar">
                      <TextField
                        size="small"
                        id="outlined-multiline-static"
                        label={intl.formatMessage({
                          id: `app.titles.${lookForStatus?.details ? "edit" : "add"
                            }-details`,
                        })}
                        multiline
                        rows={2}
                        defaultValue=""
                        value={lookForStatus?.details}
                        onChange={handleDetailsTextChange}
                      />
                    </div>
                  </Collapse>
                </div>

                <div className="nj-sm-3">
                  <div className="not-recurting">
                    <FormControl size="small">
                      <InputLabel id="demo-select-small-label">{intl.formatMessage({
                        id: "app.titles.look-fors.component-recurrence",
                      })}</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        className="dropdowlist"
                        label={intl.formatMessage({
                          id: "app.titles.look-fors.component-recurrence",
                        })}
                        value={lookForStatus?.recurring_status}
                        onChange={(value) =>
                          onLookForStatusFieldUpdate(
                            "recurring_status",
                            value.target.value as string
                          )
                        }
                      >
                        {
                          recurringStatusesOptions.map(item =>
                            <MenuItem key={item.label} value={item.label}>
                              {item.translate}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="delete-custom">
                  <Tooltip title={intl.formatMessage({
                    id: "app.app.strategy.delete-core-component",
                  })} arrow>
                    <button onClick={onLookForRemove}><Icon icon="trash" iconSize={16} /></button>
                  </Tooltip>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default CoreComponentItem;
