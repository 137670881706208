import { InputGroup, Tag } from "@blueprintjs/core";
import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { StaffRole, SuperintendentRole, UserRole } from "../../../types";
import RoleSelector from "./RoleSelector";

type OwnProps = {
  role?: UserRole;
  schoolRole?: StaffRole | SuperintendentRole | string;
  onRoleChange: ([role, staffRole]: [UserRole, string | undefined]) => void;
  onSchoolRoleNameChange: (value: string) => void;
  isLoading?: boolean;
  hasEditingAccess?: boolean;
  isSmallSelector?: boolean;
  isAdminLevelSelector?: boolean;
  menuPortalTargetSelector?: string;
};

type Props = OwnProps;

const SchoolRoleCell: FunctionComponent<Props> = (props) => {
  const {
    role,
    schoolRole,
    isLoading,
    onRoleChange,
    onSchoolRoleNameChange,
    hasEditingAccess = true,
    isSmallSelector = true,
    isAdminLevelSelector,
    menuPortalTargetSelector,
  } = props;

  const intl = useIntl();

  const handleRoleChange = ([role, staffRole]: [
    UserRole,
    string | undefined
  ]) => {
    onRoleChange([role, staffRole]);
  };

  const handleCustomStaffNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    onSchoolRoleNameChange(value);
  };

  const definedSchoolRoles = useMemo(() => {
    return [...Object.values(StaffRole), ...Object.values(SuperintendentRole)];
  }, []);

  const showCustomStaffNameInput = useMemo(() => {
    return (
      schoolRole != null &&
      (schoolRole === StaffRole.Other ||
        !definedSchoolRoles.includes(schoolRole as any))
    );
  }, [schoolRole]);

  return (
    <div className="space-y-2">
      {hasEditingAccess ? (
        <>
          <RoleSelector
            isLoading={isLoading}
            role={role}
            staffRole={schoolRole as any}
            onChange={handleRoleChange}
            isSmallSelector={isSmallSelector}
            isAdminLevelSelector={isAdminLevelSelector}
            menuPortalTargetSelector={menuPortalTargetSelector}
          />

          {showCustomStaffNameInput && (
            <InputGroup
              id="custom_staff_role_name"
              name="custom_staff_role_name"
              required
              fill
              defaultValue={schoolRole ?? ""}
              onBlur={handleCustomStaffNameChange}
              placeholder={intl.formatMessage({
                id: "app.forms.role.placeholder",
              })}
            />
          )}
        </>
      ) : (
        <div className="flex">
            {schoolRole
              ? schoolRole
              : intl.formatMessage(
                  { id: "app.roles" },
                  { role: role ?? UserRole.Staff }
                )} 
        </div>
      )}
    </div>
  );
};

export default SchoolRoleCell;
