import React, { FunctionComponent, useEffect, useMemo } from "react";
import SurveyDeploymentSelector from "../../common/selectors/SurveyDeploymentSelector";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyDeploymentsBySchool } from "../../../store/surveys-deployment/actions";
import _ from "lodash";
import moment from "moment";
import { SelectorOptionType, SurveyDeploymentStateEnum } from "../../../types";

type OwnProps = {
  selectedSurveyDeployment: SelectorOptionType | undefined;
  setSelectedSurveyDeployment: (sd: SelectorOptionType | undefined) => void;
  surveyDeploymentId: number | undefined;
  setSurveyDeploymentId: (id: number | undefined) => void;
};

type Props = OwnProps;

const SurveyDeploymentSelectorPanel: FunctionComponent<Props> = (props) => {
  const {
    surveyDeploymentId,
    setSurveyDeploymentId,
    selectedSurveyDeployment,
    setSelectedSurveyDeployment,
  } = props;

  const dispatch = useDispatch();

  const activePlanSchoolId = useSelector((s) => s.plans.activePlan?.school?.id);

  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  const loading = useSelector(
    (s) => s.surveysDeployment.loading.getSurveyDeploymentsBySchool
  );

  const displayedData = useMemo(() => {
    return surveyDeployments?.filter(
      (sd) =>
        (sd.state ? sd.state === SurveyDeploymentStateEnum.READY : true) &&
        sd.survey.supports_bookmarks
    );
  }, [surveyDeployments]);

  useEffect(() => {
    if (activePlanSchoolId) {
      dispatch(getSurveyDeploymentsBySchool.request(activePlanSchoolId));
    }
  }, [activePlanSchoolId]);

  useEffect(() => {
    if (
      surveyDeploymentId === undefined ||
      (displayedData.length
        ? // for the case if the last viewed Survey Deployment is missing
          !displayedData.some((sD) => sD.id === surveyDeploymentId)
        : true)
    ) {
      const today = moment();
      const resentSurveyDeployment = _.chain(displayedData)
        .reduce((pV, cV) => {
          if (!pV) {
            return cV;
          }

          const msDiff = today.diff(moment(pV.end_date));
          return msDiff > 0 && msDiff < today.diff(moment(cV.end_date))
            ? pV
            : cV;
        })
        .value();

      setSurveyDeploymentId(resentSurveyDeployment?.id);
    }
  }, [displayedData]);

  return (
    <div className="sm:w-1/2 md:w-1/3">
      <SurveyDeploymentSelector
        surveyDeploymentId={surveyDeploymentId}
        surveyDeployments={displayedData}
        onSurveyDeploymentChange={(s) => {
          setSurveyDeploymentId(s);
        }}
        selectedSurveyDeployment={selectedSurveyDeployment}
        setSelectedSurveyDeployment={setSelectedSurveyDeployment}
        isClearable={false}
        isLoading={loading}
      />
    </div>
  );
};

export default SurveyDeploymentSelectorPanel;
