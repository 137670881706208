import { Epic } from "redux-observable";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const updateProfileEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateProfile.request)),
    switchMap((action) =>
      from(api.profile.updateProfile(action.payload)).pipe(
        mergeMap((user) =>
          from([
            actions.updateProfile.success(user),
            actions.setAuthenticatedUser(user),
          ])
        ),
        catchError((error) => of(actions.updateProfile.failure(error)))
      )
    )
  );
};

export const updatePasswordEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updatePassword.request)),
    switchMap((action) =>
      from(api.profile.changePassword(action.payload)).pipe(
        map(actions.updatePassword.success),
        catchError((error) => of(actions.updatePassword.failure(error)))
      )
    )
  );
};
