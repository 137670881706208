import React, { FunctionComponent, useMemo, useState } from "react";
import EntityTable from "../../../common/EntityTable";
import { MeetingTemplate, MeetingType } from "../../../../types";
import { useIntl } from "react-intl";
import { Button, Icon, Popover, PopoverPosition } from "@blueprintjs/core";
import { CellProps } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import moment from "moment";
import { wrapBracketContent } from "../../../../helpers/stringUtils";

type OwnProps = {
  data: MeetingTemplate[];
  onRowClick: (a: MeetingTemplate) => void;
};

type Props = OwnProps;

const MeetingTemplatesTable: FunctionComponent<Props> = (props) => {
  const { data = [], onRowClick } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const meetings = useSelector((s) => s.meetings.meetings);

  const [searchString, setSearchString] = useState<string>("");

  const sortedData = useMemo(() => {
    return _.orderBy(data, "order_index", "asc") || [];
  }, [data]);

  const filteredData = useMemo(() => {
    const lowerCasedSearchString = searchString.toLowerCase();

    if (searchString) {
      return sortedData.filter(
        (mt) =>
          mt.activities.some((a) =>
            a.name.toLowerCase().includes(lowerCasedSearchString)
          ) ||
          mt.meeting_type_other?.includes(lowerCasedSearchString) ||
          mt.general_notes?.includes(lowerCasedSearchString) ||
          mt.timing_guidance?.includes(lowerCasedSearchString)
      );
    }

    return sortedData;
  }, [sortedData, searchString]);

  const codesAlreadyInUse = useMemo(() => {
    return _.chain(meetings)
      .map((m) => m.meeting_code)
      .uniq()
      .filter((code) => code != null)
      .value();
  }, [meetings]);

  const timingGuidanceContent = (timing_guidance?: string) => {
    if (timing_guidance) {
      const htmlContent = wrapBracketContent(timing_guidance, true)
        .wrappedContent;
      return <span dangerouslySetInnerHTML={{ __html: htmlContent ?? "" }} />;
    }
    return timing_guidance;
  };

  const columns = useMemo(() => {
    return [
      {
        id: "short_title",
        width: "20%",
        sticky: "left",
        Header: () => (
          <div className="flex items-center space-x-2">
            <div>{intl.formatMessage({ id: "app.titles.short-title" })}</div>
          </div>
        ),
        Cell: ({ row: { original, index } }: CellProps<MeetingTemplate>) => {
          const checked =
            !!original.meeting_code &&
            codesAlreadyInUse.includes(original.meeting_code);

          return (
            <div className="flex gap-2 items-center">
              <Icon
                iconSize={20}
                icon={"star"}
                className={"-ml-2"}
                color={checked ? "#FFC940" : "lightgray"}
              />
              <div>
                {original.order_index ?? index + 1}. {original.short_title}
              </div>
            </div>
          );
        },
      },
      {
        id: "activity",
        width: "35%",
        sticky: "left",
        Header: () => (
          <div className="flex items-center space-x-2">
            <div>{intl.formatMessage({ id: "app.meetings.dialog.title" })}</div>
            <Popover
              popoverClassName="w-64"
              interactionKind={"hover"}
              content={
                <div className="p-2">
                  {intl.formatMessage({
                    id: "app.meeting-templates.title.description",
                  })}
                </div>
              }
            >
              <Icon className="cursor-pointer" icon="info-sign" />
            </Popover>
          </div>
        ),
        Cell: ({ row: { original } }: CellProps<MeetingTemplate>) => {
          return (
            <div className="flex gap-2 items-center">
              <div>{original.title}</div>
            </div>
          );
        },
      },
      {
        id: "meeting_type",
        width: "20%",
        sticky: "left",
        disableSortBy: true,
        Header: () => (
          <div className="flex items-center space-x-2">
            <div>{intl.formatMessage({ id: "app.titles.type" })}</div>
            <Popover
              popoverClassName="w-64"
              interactionKind={"hover"}
              content={
                <div className="p-2">
                  {intl.formatMessage({
                    id: "app.meeting-templates.type.description",
                  })}
                </div>
              }
              position={PopoverPosition.RIGHT}
            >
              <Icon className="cursor-pointer" icon="info-sign" />
            </Popover>
          </div>
        ),
        accessor: (meetingTemplate: MeetingTemplate, index: number) => {
          return meetingTemplate.meeting_type
            ? meetingTemplate.meeting_type === MeetingType.Other
              ? meetingTemplate.meeting_type_other
              : intl.formatMessage(
                { id: "app.enums.meeting-type" },
                { type: meetingTemplate.meeting_type }
              )
            : intl.formatMessage({ id: "app.titles.not-applicable" });
        },
      },
      {
        id: "timing_guidance",
        width: "30%",
        sticky: "left",
        Header: intl.formatMessage({ id: "app.titles.timing-guidance" }),
        disableSortBy: true,
        accessor: (meetingTemplate: MeetingTemplate, index: number) => {
          return (
            timingGuidanceContent(meetingTemplate.timing_guidance) ??
            intl.formatMessage({ id: "app.titles.not-applicable" })
          );
        },
      },
      {
        id: "actions",
        width: "10%",
        sticky: "left",
        Header: intl.formatMessage({ id: "app.titles.actions" }),
        Cell: ({ row: { original, index } }: CellProps<MeetingTemplate>) => {
          if (!original.can_be_added) {
            return null;
          }

          const checked =
            !!original.meeting_code &&
            codesAlreadyInUse.includes(original.meeting_code);

          return (
            <Button
              intent="primary"
              className="whitespace-no-wrap"
              text={intl.formatMessage(
                { id: "app.calendar.add-action" },
                { exist: checked }
              )}
            />
          );
        },
      },
    ];
  }, [codesAlreadyInUse]);

  const eventExistConfirmAlert = (entityData: MeetingTemplate) => {
    let meetingDate = _.orderBy(meetings, ["date"], ["desc"]).find(
      (data) => data.meeting_code === entityData.meeting_code
    )?.date;

    if (
      entityData.meeting_type === MeetingType.TeamMeeting ||
      entityData.meeting_type === MeetingType.DistrictWideLeadershipMeeting
    ) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            dispatch(hideConfirmDialog());
            entityData?.can_be_added && onRowClick(entityData);
          },
          onCancel: () => dispatch(hideConfirmDialog()),
          show: true,
          intent: "warning",
          text: intl.formatMessage(
            { id: "app.calendar.action-button.alert" },
            {
              autofill_date_of_event: moment(meetingDate).format("MM/DD/YYYY"),
              i: (chunks) => <i>{chunks}</i>,
            }
          ),
          confirmButtonText: intl.formatMessage({
            id: "app.calendar.action-button.alert.schedule-confirm",
          }),
          cancelButtonText: intl.formatMessage({ id: "app.titles.cancel" }),
          icon: "info-sign",
        })
      );
    } else {
      entityData?.can_be_added && onRowClick(entityData);
    }
  };

  const handleRowClick = (entity: MeetingTemplate) => {
    const checked =
      !!entity.meeting_code && codesAlreadyInUse.includes(entity.meeting_code);
    if (checked) {
      eventExistConfirmAlert(entity);
    } else {
      entity?.can_be_added && onRowClick(entity);
    }
  };

  const getTooltipMessage = (entity: MeetingTemplate) => {
    return entity?.can_be_added
      ? undefined
      : intl.formatMessage({ id: "app.titles.meeting-template-can-not-added" });
  };

  return (
    <EntityTable
      searchString={searchString}
      setSearchString={setSearchString}
      showTopContainer={false}
      data={filteredData}
      columns={columns}
      onRowClick={handleRowClick}
      noDataComp={<div />}
      maxHeight={"30rem"}
      getTooltipMessage={getTooltipMessage}
    >
      <div />
    </EntityTable>
  );
};

export default MeetingTemplatesTable;
