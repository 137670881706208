import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";

type OwnProps = {
  forNegativeDomains?: boolean;
};

type Props = OwnProps;

const OverallMeanStepContent: FunctionComponent<Props> = (props) => {
  const { forNegativeDomains } = props;

  const intl = useIntl();

  const content = useMemo(() => {
    if (forNegativeDomains) {
      return intl.formatMessage({
        id: "app.survey-report.guide.overall-mean.negative-domain",
      });
    } else {
      return intl.formatMessage(
        {
          id: "app.survey-report.guide.overall-mean-domain",
        },
        {
          i: (content) => <i>{content}</i>,
        }
      );
    }
  }, [forNegativeDomains]);

  return <div>{content}</div>;
};

export default OverallMeanStepContent;
