import React, { FunctionComponent } from "react";
import sctpLogo from "../../static/images/sctp-logo1.png";
import rsunjLogo from "../../static/images/RSUNJ_S_RED_BLACK1.png";
import njDoe from "../../static/images/nj-doe-logo1.png";

type OwnProps = {};

type Props = OwnProps;

const FooterLogos: FunctionComponent<Props> = (props) => {
  return (
    <div className={"footer-logos"}>
      <img src={sctpLogo} alt="School Climate Transformation Project" />
      <img src={rsunjLogo} alt="Rutgers The State University of New Jersey" />
      <img src={njDoe} alt="New Jersey Department of Education" />
    </div>
  );
};

export default FooterLogos;
