/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useIntl } from "react-intl";
import { PlanStrategy } from '../../../../../../types';
import { Icon } from "@blueprintjs/core";
import Popover from "@mui/material/Popover";
import { useDispatch, useSelector } from 'react-redux';
import DeleteStrategyPlan from '../../delete-strategy-plan/DeleteStrategyPlan';
import EditParameters from '../../edit-parameters/EditParameters';
import EditCorecomponents from '../../edit-core-components/EditCorecomponents';
import UpdateImplementation from '../../update-implementation/UpdateImplementation';
import ViewLogsModal from '../../view-logs/ViewLogsModal';
import './StrategyActions.scss';
import { generatePath, useHistory } from 'react-router';
import { AuthRoutes } from '../../../../../../App';
import StrategyStatus from '../strategy-status/StrategyStatus';
import { updatePlanStrategy } from '../../../../../../store/plan-strategies/actions';
import { AppToaster } from '../../../../../../helpers/toaster';

type OwnProps = {
    item: PlanStrategy;
};
type Props = OwnProps;

const StrategyActions: React.FC<Props> = ({ item }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    // STATES
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isPauseImplementation, setIsPauseImplementation] = useState<boolean | undefined>(item.is_pause_implimentation);

    // ACTIONS
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // Get data from srore by redux
    const activePlanId = useSelector((s) => s.plans.activePlan?.id);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleEditCustomStrategy = (planId: number) => {
        history.push(
            generatePath(AuthRoutes.YourPlan, {
                workspace: "goals" as any,
                tabId: "custom-strategy",
                tagId: planId
            } as any)
        );
    }

    const onUpdateSuccess = () => {
        AppToaster.show({
            intent: "success",
            icon: "tick",
            message: intl.formatMessage(
                { id: `app.strategies.updated-strategy` },
                { name: item.strategy.name }
            ),
        });
    };

    const handlePauseImplementation = () => {
        if (item && activePlanId) {
            dispatch(
                updatePlanStrategy.request({
                    planStrategy: {
                        planId: activePlanId,
                        planStrategyId: item.id,
                        strategy: item.strategy.id,
                        is_pause_implimentation: !isPauseImplementation
                    },
                    onSuccess: onUpdateSuccess
                })
            );
        }
        setIsPauseImplementation(!isPauseImplementation);
        handleClose();
    }

    return (
        <>
            <li>
                <StrategyStatus planStrategy={item} isPauseImplementation={isPauseImplementation!} />
            </li>

            <li className="">
                {intl.formatMessage({ id: `app.titles.${isPauseImplementation ? "paused-" : ""}implementation` })}
                <button aria-describedby={id} onClick={handleClick}>
                    <Icon icon="more" iconSize={14} />
                </button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <ul className="dropdown-list strategy-actions">
                        {
                            item.strategy.school && (
                                <li>
                                    <a onClick={() => handleEditCustomStrategy(item.id)}><Icon icon="edit" iconSize={14} />{intl.formatMessage({ id: "app.titles.edit-details" })}</a>
                                </li>
                            )
                        }
                        <li>
                            <EditParameters planstrategy={item} activePlan={activePlanId} handleClose={handleClose} />
                        </li>
                        <li>
                            <EditCorecomponents planstrategy={item} activePlan={activePlanId} handleClose={handleClose} />
                        </li>
                        <li>
                            <UpdateImplementation planstrategy={item} activePlan={activePlanId} handleClose={handleClose} isPauseImplementation={isPauseImplementation!} />
                        </li>

                        <li><ViewLogsModal planstrategy={item} activePlan={activePlanId} handleClose={handleClose} /></li>

                        <li><a onClick={handlePauseImplementation}><Icon icon={isPauseImplementation ? "play" : "pause"} iconSize={14} />{intl.formatMessage({ id: `app.strategy.${isPauseImplementation ? "resume" : "pause"}-implementation-title` })}</a></li>

                        <li><DeleteStrategyPlan planstrategy={item} activePlan={activePlanId} handleClose={handleClose} /></li>
                    </ul>
                </Popover>
            </li>
        </>
    )
}

export default StrategyActions
