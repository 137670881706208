import React, { FunctionComponent, useState, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@blueprintjs/core";
import NotesList from "./NotesList";
import NotePreview from "./NotePreview";
import PublishedNotesFilter from "./PublishedNotesFilter";
import { useDispatch, useSelector } from "react-redux";
import "./NotesPage.scss";
import moment from "moment";
// import useNotesDialogInNewTabLocation from '../../../../../../../helpers/hooks/useNotesDialogInNewTabLocation';
// import NotesDialogContent from '../../../../../../team-plan/notes/notes-dialog/NotesDialogContent';
// import { getActivities } from '../../../../../../../store/activities/actions';
// import { ActivityScopeEnum,
//   ActivityTagEnum,
//   UserNotes, } from '../../../../../../../types';

// import { showConfirmDialog,hideConfirmDialog } from '../../../../../../../store/UIState/actions';

import { Button, Spinner } from "@blueprintjs/core";
import useNotesDialogInNewTabLocation from "../../../../helpers/hooks/useNotesDialogInNewTabLocation";
import {
  ActivityScopeEnum,
  ActivityTagEnum,
  UserNotes,
} from "../../../../types";
import {
  getActivePlanId,
  isDistrictPlan,
} from "../../../../store/plans/selectors";
import { getNotes, hideNotesDialog } from "../../../../store/notes/actions";
import { getPlans } from "../../../../store/plans/actions";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import { getActivities } from "../../../../store/activities/actions";
import { PUSHER_CLUSTER, PUSHER_KEY } from "../../../../api/config";
import Pusher from "pusher-js";
// import { getActivePlanId, isDistrictPlan } from '../../../../../../../store/plans/selectors';
// import { getNotes, hideNotesDialog } from '../../../../../../../store/notes/actions';
// import { getPlans } from '../../../../../../../store/plans/actions';
type OwnProps = {
  pusher?: any;
};

type Props = OwnProps;

const PublishedNotes: FunctionComponent<Props> = (props) => {
  // CONSTANTS
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isSideMenuClosed, setIsSideMenuClosed] = useState<boolean>(false);

  const { show } = useSelector((s) => s.notes.dialogs.notesDialog);

  const { onNewTabNoteDialogActionSuccess } = useNotesDialogInNewTabLocation();
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const {
    selectedNote: preselectedNote,
    selectedActivity,
    checkExisting,
  } = useSelector((s) => s.notes.dialogs.notesDialog);

  const [isLeftDialogSideHidden, setLeftDialogSideHidden] = useState<boolean>(
    false
  );

  const notes = useSelector((s) => s.notes.notes);
  // console.log("notes", notes);
  const loading = useSelector((s) => s.notes.loading.getNotes);
  const note = useSelector((s) => s.notes.dialogs.notesDialog.selectedNote);
  const [isExistNotes, setIsExistNotes] = useState(true);
  const [isNewNote, setIsNewNote] = useState(true);
  const [existNotesData, setExistNotesData] = useState<UserNotes>();

  const [filterbyKeyword, setFilterByKeyword] = useState<any>("");
  const [filterbyTopic, setFilterByTopic] = useState<any>("");
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const activePlan = useSelector(getActivePlanId);
  useEffect(() => {
    if (activePlan) {
      dispatch(getNotes.request({ planId: activePlan }));
    }
  }, [activePlan]);

  useEffect(() => {
    dispatch(getPlans.request());
  }, []);

  useEffect(() => {
    if (notes.length === 0) setIsExistNotes(false);
  }, [notes]);

  const handleDialogClose = () => {
    if (hasChanges) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            dispatch(hideConfirmDialog());
            onNewTabNoteDialogActionSuccess &&
              onNewTabNoteDialogActionSuccess();
            dispatch(hideNotesDialog());
            setIsExistNotes(true);
            setExistNotesData(undefined);
          },
          show: true,
          intent: "warning",
          text: intl.formatMessage({
            id: "app.confirmation-dialogs.invite-users-close-confirm",
          }),
          icon: "info-sign",
          confirmButtonText: intl.formatMessage({ id: "app.titles.yes" }),
          cancelButtonText: intl.formatMessage({ id: "app.titles.no" }),
        })
      );
    } else {
      onNewTabNoteDialogActionSuccess && onNewTabNoteDialogActionSuccess();
      dispatch(hideNotesDialog());
      setIsExistNotes(true);
      setExistNotesData(undefined);
    }
    setExistNotesData(undefined);
  };

  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const activites = useSelector((state) => state.activities.activities);

  useEffect(() => {
    if (!activites) {
      dispatch(
        getActivities.request({
          type: isDistrictActivePlan
            ? ActivityScopeEnum.DISTRICT
            : ActivityScopeEnum.SCHOOL,
          tag: ActivityTagEnum.TEAM,
        })
      );
    }
  }, []);

  // Add pusher
  const pusher = useMemo(() => {
   return new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER, // PUSHER_CLUSTER
      channelAuthorization: {
        endpoint: "/pusher/auth-presence",
        transport: "ajax",
        params: { editNote: false },
        headers: {},
      },
      userAuthentication: {
        endpoint: "/pusher/auth-presence",
        transport: "ajax",
        headers: {
          "X-CSRF-Token": "some_csrf_token",
        },
      },
    });
  }, [PUSHER_KEY, PUSHER_CLUSTER]);

  useEffect(()=>{
    return ()=>{
      pusher?.disconnect()
    }
  },[])

  const dialogTitle = useMemo(() => {
    return (
      <div className="flex space-x-2">
        <p>
          {intl.formatMessage({
            id: "app.titles.sci-writing",
          })}
        </p>
        <div>
          <Button
            text={intl.formatMessage({
              id: `app.titles.${
                isLeftDialogSideHidden ? "show" : "hide"
              }-left-side`,
            })}
            title={intl.formatMessage({
              id: `app.titles.${
                isLeftDialogSideHidden ? "show" : "hide"
              }-left-side`,
            })}
            onClick={() => setLeftDialogSideHidden(!isLeftDialogSideHidden)}
            intent="primary"
          />
        </div>
      </div>
    );
  }, [isLeftDialogSideHidden]);

  const handleOpenExistingNote = () => {
    setIsNewNote(false);
    setExistNotesData(notes[0]);
    setIsExistNotes(false);
  };

  const handleOpenNewNote = () => {
    setIsExistNotes(false);
  };

  return (
    <div>
      <div className="publishNotes-topbar">
        <h2>
          {intl.formatMessage({
            id: "app.note-sci-start-writing-published-notes",
          })}{" "}
        </h2>
        <PublishedNotesFilter
          filterByKeyword={filterbyKeyword}
          setFilterByKeyword={setFilterByKeyword}
          filterByTopic={filterbyTopic}
          setFilterByTopic={setFilterByTopic}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          isFilterApplied={isFilterApplied}
          setIsFilterApplied={setIsFilterApplied}
        />
      </div>
      <div className={`planning ${isSideMenuClosed ? "collapse-toggle" : ""}`}>
        <NotesList
          pusher={pusher}
          isSideMenuClosed={isSideMenuClosed}
          setIsSideMenuClosed={setIsSideMenuClosed}
          filterByKeyword={filterbyKeyword}
          filterByTopic={filterbyTopic}
          selectedDateRange={selectedDateRange}
          isFilterApplied={isFilterApplied}
        />
        <div className="planning__midd">
          <div className="sciMid-content mt-2">
            {note && <NotePreview note={note} pusher={pusher} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishedNotes;
