import React from "react";
import { Icon } from "@blueprintjs/core";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./InfoBar.scss";
import { useIntl } from "react-intl";

function InfoBar() {
  const [infoexpanded, infosetExpanded] = React.useState<string | false>(false);
  const [infoDocument, setInfoDocument] = React.useState<string | false>(false);

  const intl = useIntl();

  const infohandleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    infosetExpanded(isExpanded ? panel : false);
  };

  const [infobaropen, infoBarOpen] = React.useState(false);

  const infoBarClickOpen = () => {
    infoBarOpen(true);
  };

  const infoBarClose = () => {
    infoBarOpen(false);
    infosetExpanded(false);
    setInfoDocument(false);
  };

  return (
    <>
      <button onClick={infoBarClickOpen}>
        <span className="info-icon">i</span> Info
      </button>
      <Dialog
        open={infobaropen}
        onClose={infoBarClose}
        className="sidebar-modal small-modal"
      >
        <div className="sidepanel">
          <div className="sidepanel__header">
            <h3>
              {intl.formatMessage({
                id: "app.titles.information.resources.strategic-plan",
              })}
            </h3>
            <button onClick={infoBarClose}>
              <Icon icon="cross" iconSize={12} />
            </button>
          </div>
          <div className="sidepanel__content">
            <div className="info-list">
              <Accordion
                expanded={infoexpanded === "panel1"}
                onChange={infohandleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  {intl.formatMessage({ id: "app.titles.goals" })}
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals",
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)",
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Setting-Indicator-Targets",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 

                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Setting-Indicator-Targets",
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Describing-Desired-Outcomes",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Describing-Desired-Outcomes",
                        })}
                      </a>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={infoexpanded === "panel2"}
                onChange={infohandleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content3"
                  id="panel1bh-header3"
                >
                  {intl.formatMessage({ id: "app.titles.strategies" })}
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Defining-Research-based-Strategies",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Defining-Research-based-Strategies",
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Aligning-Research-based-Strategies-with-Goals",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Aligning-Research-based-Strategies-with-Goals",
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Customizing-and-Creating-New-Strategies",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Customizing-and-Creating-New-Strategies",
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Monitoring-Strategies-in-the-NJ-SCI-Platform",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Monitoring-Strategies-in-the-NJ-SCI-Platform",
                        })}
                      </a>
                    </li>

                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.goals.Interpreting-Strategy-Implementation-Scores",
                            })
                          )
                        }
                      >
                         <span>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span> 
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.goals.Interpreting-Strategy-Implementation-Scores",
                        })}
                      </a>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={infoexpanded === "panel3"}
                onChange={infohandleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content2"
                  id="panel1bh-header2"
                >
                  {intl.formatMessage({ id: "app.titles.progress-monitoring" })}
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.progress-monitring.Getting-Ready-to-Monitor-Progress-on-School-Climate-Outcomes",
                            })
                          )
                        }
                      >
                        <span>
                          
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span>
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.progress-monitring.Getting-Ready-to-Monitor-Progress-on-School-Climate-Outcomes",
                        })}
                      </a>
                    </li>
                    <li>
                      <a
                        // href="#"
                        onClick={() =>
                          setInfoDocument(
                            intl.formatMessage({
                              id:
                                "app.strategic-plan.infobar.progress-monitring.Understanding-Progress-Toward-Achievement-of-School-Climate-Outcomes",
                            })
                          )
                        }
                      >
                        <span>

                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="13px"
                          height="17px"
                          viewBox="0 0 13 17"
                          enable-background="new 0 0 13 17"
                        >
                          <path
                            fill="#619CD1"
                            d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                          />
                          <path
                            fill="#619CD1"
                            d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                          />
                        </svg>
                        </span>
                        {intl.formatMessage({
                          id:
                            "app.strategic-plan.infobar.progress-monitring.Understanding-Progress-Toward-Achievement-of-School-Climate-Outcomes",
                        })}
                      </a>
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>

        {infoDocument ===
          intl.formatMessage({
            id: "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content">
              {intl.formatMessage(
                {
                  id:
                    "app.strategic-plan.infobar.goals.SMART-School-Climate-Goals-content",
                },
                {
                  p: (chunks) => <p>{chunks}</p>,
                  b: (chunks) => <b>{chunks}</b>,
                  i: (chunks) => <i>{chunks}</i>,
                  br: <br />,
                }
              )}
            </div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content">
              {intl.formatMessage(
                {
                  id:
                    "app.strategic-plan.infobar.goals.Selecting-Domain(s)-to-Include-in-Goal(s)-content",
                },
                {
                  p: (chunks) => <p>{chunks}</p>,
                }
              )}
            </div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id: "app.strategic-plan.infobar.goals.Setting-Indicator-Targets",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Setting-Indicator-Targets",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div
              className="sidepanel__content"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Setting-Indicator-Targets-content",
                }),
              }}
            ></div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id: "app.strategic-plan.infobar.goals.Describing-Desired-Outcomes",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Describing-Desired-Outcomes",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content" dangerouslySetInnerHTML={{__html:intl.formatMessage(
                {
                  id:
                    "app.strategic-plan.infobar.goals.Describing-Desired-Outcomes-content",
                }
              )}}>
            </div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.goals.Defining-Research-based-Strategies",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Defining-Research-based-Strategies",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content">
              {intl.formatMessage(
                {
                  id:
                    "app.strategic-plan.infobar.goals.Defining-Research-based-Strategies-content",
                },
                {
                  p: (chunks) => <p>{chunks}</p>,
                }
              )}
            </div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.goals.Aligning-Research-based-Strategies-with-Goals",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Aligning-Research-based-Strategies-with-Goals",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div
              className="sidepanel__content"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Aligning-Research-based-Strategies-with-Goals-content",
                }),
              }}
            ></div>
          </div>
        )}
        {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.goals.Customizing-and-Creating-New-Strategies",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Customizing-and-Creating-New-Strategies",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div
              className="sidepanel__content"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Customizing-and-Creating-New-Strategies-content",
                }),
              }}
            ></div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.goals.Monitoring-Strategies-in-the-NJ-SCI-Platform",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Monitoring-Strategies-in-the-NJ-SCI-Platform",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div
              className="sidepanel__content"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Monitoring-Strategies-in-the-NJ-SCI-Platform-content",
                }),
              }}
            ></div>
          </div>
        )}
        {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.goals.Interpreting-Strategy-Implementation-Scores",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.goals.Interpreting-Strategy-Implementation-Scores",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content">
              {intl.formatMessage(
                {
                  id:
                    "app.strategic-plan.infobar.goals.Interpreting-Strategy-Implementation-Scores-content",
                },
                {
                  p: (chunks) => <p>{chunks}</p>,
                }
              )}
            </div>
          </div>
        )}
                {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.progress-monitring.Getting-Ready-to-Monitor-Progress-on-School-Climate-Outcomes",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.progress-monitring.Getting-Ready-to-Monitor-Progress-on-School-Climate-Outcomes",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div
              className="sidepanel__content"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.progress-monitring.Getting-Ready-to-Monitor-Progress-on-School-Climate-Outcomes-content",
                }),
              }}
            ></div>
          </div>
        )}
        {infoDocument ===
          intl.formatMessage({
            id:
              "app.strategic-plan.infobar.progress-monitring.Understanding-Progress-Toward-Achievement-of-School-Climate-Outcomes",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.progress-monitring.Understanding-Progress-Toward-Achievement-of-School-Climate-Outcomes",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div
              className="sidepanel__content"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id:
                    "app.strategic-plan.infobar.progress-monitring.Understanding-Progress-Toward-Achievement-of-School-Climate-Outcomes-content",
                }),
              }}
            ></div>
          </div>
        )}
      </Dialog>
    </>
  );
}

export default InfoBar;
