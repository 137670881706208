import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import LearningModuleTour from "./LearningModuleTour";
import { useSelector } from "react-redux";
import {
  getCurrentUserDistrict,
  getCurrentUserRole,
} from "../../store/auth/selectors";
import { UserRole } from "../../types";

type OwnProps = {};

type Props = OwnProps;

const WelcomeStepThree: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <>
      <div className="flex flex-col gap-4 items-center">
        <div className="p-2 border border-gray-400 shadow-inner rounded italic">
          {intl.formatMessage(
            {
              id: "app.welcome-page.step-three.email-template",
            },
            {
              a: (chunks) => (
                <a
                  href="https://njschoolclimate.org"
                  target="_blank"
                  className="text-blue-400 hover:text-blue-500"
                >
                  {chunks}
                </a>
              ),
              b: (chunks) => <span className="font-bold">{chunks}</span>,
              br: <br />,
              communicatePrincipalMenu: (
                <ul className="list-disc pl-10">
                  {intl.formatMessage(
                    {
                      id:
                        "app.welcome-page.step-three.communicate-principal-menu",
                    },
                    {
                      li: (chunks) => (
                        <>
                          <li>{chunks}</li>
                        </>
                      ),
                    }
                  )}
                </ul>
              ),
            }
          )}
        </div>
        {props.children}
      </div>

      <LearningModuleTour />
    </>
  );
};

export default WelcomeStepThree;
