import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isDomainQuestionsComparison } from "../../../surveys/table/utils";
import * as _ from "lodash";
import {
  DomainDemographicTriageStatuses,
  DomainPriorityTriageStatus,
  SurveyDemographic,
} from "../../../../types";
import { Callout, Spinner } from "@blueprintjs/core";
import useComparison from "../../../surveys/tab/useComparison";
import DomainComparisonCollapse from "./DomainComparisonCollapse";
import { useLoadingWithDependencies } from "../../../../helpers/hooks/loading/useLoadingWithDependencies";
import { useUpdateDomainPrioritiesDecisionLoading } from "../../../../helpers/hooks/loading/ useUpdateDomainPrioritiesDecisionLoading";
import { useIntl } from "react-intl";

type OwnProps = {
  isCnfBtnDisabledDisabled?: boolean;
  updateCnfBtnDisabledStatus?: any;
  isPriorityConfirmed?: boolean;
  setIsLoading?: any;
};

type Props = OwnProps;

const DigDeeperTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const {
    updateCnfBtnDisabledStatus,
    isPriorityConfirmed,
    setIsLoading,
  } = props;

  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);

  const loadingGetSurveyComparison = useSelector(
    (s) => s.surveyReports.loading.getSurveyComparison
  );

  const domainPriorities = useSelector((s) => s.needs.domainPriorities);

  const loadingGetDomainPriorities = useSelector(
    (s) => s.needs.loading.getDomainPriorities
  );

  const loadingGetDatasetMarks = useSelector(
    (s) => s.datasetMarks.loading.getDatasetMarks
  );

  const { showNonIdealState, demographics } = useComparison({
    surveyComparison: surveyComparison,
  });

  const triageStatusesDictionary: {
    [domainId: string]: DomainDemographicTriageStatuses;
  } = useMemo(() => {
    return _.chain(domainPriorities)
      .groupBy((dP) => dP.domain.id)
      .mapValues<DomainDemographicTriageStatuses>((dPriorities) => {
        const triageStatusByDemographic: {
          [key in SurveyDemographic]?: DomainPriorityTriageStatus | null;
        } = _.chain(demographics)
          .map((demographic) => [
            demographic,
            dPriorities.find((dP) => dP.demographic === demographic)
              ?.triage_status,
          ])
          .fromPairs()
          .value();

        const archived = _.chain(triageStatusByDemographic)
          .values()
          .filter((status) => status != null)
          .every((status) => status === DomainPriorityTriageStatus.Archived)
          .value();

        return {
          ...triageStatusByDemographic,
          archived: archived,
          domainPriorities: dPriorities,
        };
      })
      .value();
  }, [domainPriorities, demographics]);

  const sortedByDomainDataWithoutArchived = useMemo(() => {
    if (!isDomainQuestionsComparison(surveyComparison)) {
      return _.chain(surveyComparison)
        .filter(
          (comparison) =>
            !(triageStatusesDictionary[comparison.domain.id]?.archived || triageStatusesDictionary[comparison.domain.id]?.archived === undefined)
        )
        .orderBy((comparison) => comparison?.domain?.name ?? "", ["asc"])
        .value();
    }
    return [];
  }, [surveyComparison, triageStatusesDictionary]);

  useEffect(() => {
    const results = sortedByDomainDataWithoutArchived.map(
      (e) =>
        !!triageStatusesDictionary[e.domain.id]?.domainPriorities[0]?.decision
    );
    updateCnfBtnDisabledStatus(results.includes(false));
  }, [sortedByDomainDataWithoutArchived, triageStatusesDictionary, updateCnfBtnDisabledStatus]);

  const { loading } = useLoadingWithDependencies({
    loadingDeps: [
      loadingGetSurveyComparison,
      loadingGetDomainPriorities,
      loadingGetDatasetMarks,
    ],
  });

  useUpdateDomainPrioritiesDecisionLoading();

  useEffect(() => {
    setIsLoading(!!loading);
  }, [loading]);

  const sortedDomainWithTriageStatus = useMemo(() => {
    if(!sortedByDomainDataWithoutArchived) return [];
    return sortedByDomainDataWithoutArchived.map(et => {
      let obj: any = { ...et };
      Object.entries(et.respondents).map((dg: any) => {
        const found = domainPriorities.find(dp => {
          return dp.domain.id === et.domain.id && dp.demographic === dg[0]
        })
        obj.respondents[`${dg[0]}`] = { ...obj.respondents[`${dg[0]}`], triage_status: found?.triage_status }
      })
      return { ...obj }
    });
  }, [sortedByDomainDataWithoutArchived])

  if (loading) {
    return <Spinner intent="primary" />;
  }

  if (showNonIdealState) {
    return (
      <Callout
        title={intl.formatMessage({ id: "app.titles.no-data" })}
        intent="warning"
      />
    );
  }

  return (
    <div className="space-y-2">
      {isPriorityConfirmed && (
        <div className="mb-3">
          <Callout intent="warning">
            {intl.formatMessage({
              id: "app.prioritized-domain-warning-text",
            })}
          </Callout>
        </div>
      )}

      {sortedDomainWithTriageStatus.map((comparison) => (
        <DomainComparisonCollapse
          key={comparison.domain.id}
          comparison={comparison}
          isPriorityConfirmed={isPriorityConfirmed}
          domainDemographicTriageStatuses={
            triageStatusesDictionary[comparison.domain.id]
          }
        />
      ))}
    </div>
  );
};

export default DigDeeperTab;
