import { Button, Menu, MenuItem, Popover, Spinner, Tag } from "@blueprintjs/core";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { DOMAIN_DEFAULT_COLOR } from "../../../constants/constants";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../helpers/toaster";
import { getDomains } from "../../../store/domains/actions";
import { deleteStrategy, getStrategies, showUpsertStrategyDialog } from "../../../store/strategies/actions";
import { hideConfirmDialog, showConfirmDialog } from "../../../store/UIState/actions";
import { AuthenticatedUserModel, Strategy } from "../../../types";
import { pickTextColorBasedOnBgColor } from "../../charts/utils";
import EntityTable from "../../common/EntityTable";
import { filterStrategiesByDomainAndSearchString } from "../utils";
import AddPlanStrategyDialog from "./add-plan-strategy-dialog/AddPlanStrategyDialog";
import DetailsStrategyDialog from "./details-strategy-dialog/DetailsStrategyDialog";
import UpsertStrategyDialog from "./upsert-strategy-dialog/UpsertStrategyDialog";

type OwnProps = {};

type Props = OwnProps;

const AdminWorkspaceStrategies: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const selectedDomain = useSelector((s) => s.domains.selectedDomain);
  const [searchingString, setSearchingString] = useState("");
  const [displayedStrategies, setDisplayedStrategies] = useState<Strategy[]>(
    []
  );

  const handleAddStrategyClick = () => {
    dispatch(showUpsertStrategyDialog());
  };

  const handleEditStrategyClick = (item: Strategy<AuthenticatedUserModel>) => {
    dispatch(showUpsertStrategyDialog(item));
  };

  useEffect(() => {
    dispatch(getStrategies.request());
    dispatch(getDomains.request());
  }, [dispatch]);

  const strategyDeleteLoading = useSelector(
    (s) => s.strategies.loading.deleteStrategy
  );
  const strategyDeleteError = useSelector(
    (s) => s.strategies.errors.deleteStrategy
  );
  useLoading({ loading: strategyDeleteLoading, error: strategyDeleteError });

  const strategiesAll = useSelector((s) => s.strategies.strategies);
  const loading = useSelector((s) => s.strategies.loading.getStrategies);

  const strategies = useMemo(() => {
    return strategiesAll.filter(stratrgy => stratrgy.is_global);
  }, [strategiesAll]);

  useEffect(() => {
    setDisplayedStrategies(strategies);
  }, [strategies]);

  useEffect(() => {
    setDisplayedStrategies(
      filterStrategiesByDomainAndSearchString(
        strategies,
        searchingString,
        selectedDomain ? [selectedDomain] : undefined
      )
    );
  }, [searchingString, selectedDomain, strategies]);

  const showNonIdealState = useMemo(() => {
    return !displayedStrategies.length;
  }, [displayedStrategies]);

  (loading || strategyDeleteLoading) && <Spinner intent="primary" className="my-2" />

  const columns = [
    {
      Header: intl.formatMessage({ id: "app.common-table.columns.name" }),
      accessor: "name",
      width: "100%",
      Cell: ({ row: { original } }: CellProps<Strategy>) => {
        return <p>{original.name}</p>;
      }
    },
    {
      Header: intl.formatMessage({ id: "app.strategy.aligned-domains" }),
      accessor: "domains",
      minWidth: "50rem",
      disableSortBy: true,
      width: "100%",
      Cell: ({ row: { original } }: CellProps<Strategy>) => {
        return (
          <div className="flex flex-wrap -m-1">
            {original.domains.map((domain) => (
              <Tag key={domain.id} className="ml-2" minimal intent="primary">
                {domain.name}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      id: "actions",
      width: "100%",
      Header: intl.formatMessage({ id: "app.titles.actions" }),
      Cell: ({ row: { original } }: CellProps<Strategy>) => {
        return (
          <div className="flex -ml-1 justify-center">
            <>
              <Button
                title={intl.formatMessage({
                  id: "app.strategies.edit-strategy",
                })}
                icon="edit"
                className="whitespace-no-wrap m-1"
                intent="primary"
                onClick={() => {
                  //dispatch(showSurveyUpsertDialog(original));
                  //showUpsertStrategyDialog(original);
                  handleEditStrategyClick(original)
                }}
              />
              <Button
                title={intl.formatMessage({
                  id: "app.titles.delete",
                })}
                icon="trash"
                className="whitespace-no-wrap m-1"
                intent="danger"
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  showConfirmDeleteDialog(original)
                }
              />
            </>
          </div>
        );
      },
    },
  ];

  const showConfirmDeleteDialog = (item: Strategy<AuthenticatedUserModel>) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(
            deleteStrategy.request({
              strategyId: item.id,
              onSuccess: () => {
                AppToaster.show({
                  icon: "tick",
                  intent: "success",
                  message: intl.formatMessage(
                    { id: "app.strategies.deleted-successfully" },
                    { name: (item as Strategy)?.name }
                  ),
                });
              },
            })
          );
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        icon: "trash",
        text: intl.formatMessage(
          {
            id: "app.confirmation-dialogs.delete-strategy",
          },
          {
            name: (item as Strategy).name,
          }
        ),
        confirmButtonText: intl.formatMessage({ id: "app.confirmation-dialogs.delete-strategy-yes" }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.cancel",
        }),
        onCancel: () => {
          dispatch(hideConfirmDialog());
        },
      })
    );
  };

  const popoverContent = () => {
    return (
      <Menu>
        <MenuItem
          text={intl.formatMessage({ id: "app.strategies.create-strategy" })}
          icon="plus"
          className="px-3 capitalize"
          onClick={handleAddStrategyClick}
        />
      </Menu>
    );
  };

  return (
    <>
      <div>
        <p className="text-xl mb-4">
          {intl.formatMessage({ id: "app.strategies.description" })}
        </p>
        <div className="flex items-center justify-between flex-wrap gap-2 mb-4">
          <Button
            icon="add"
            intent="primary"
            text={intl.formatMessage({
              id: "app.strategies.create-strategy",
            })}
            title={intl.formatMessage({
              id: "app.strategies.create-strategy",
            })}
            onClick={handleAddStrategyClick}
          />
        </div>

        <EntityTable
          data={displayedStrategies}
          columns={columns}
          searchString={searchingString}
          setSearchString={setSearchingString}
          loading={loading}
          noDataComp={showNonIdealState}
        >
          <Popover
            position={"bottom-left"}
            className={`flex-grow justify-start`}
            content={popoverContent()}
            minimal
          >
            <Button
              large
              minimal
              title={intl.formatMessage({ id: "app.titles.strategies" })}
              icon={"properties"}
              text={intl.formatMessage({ id: "app.titles.strategies" })}
              className="font-bold px-4"
            />
          </Popover>
        </EntityTable>
      </div>

      <UpsertStrategyDialog />
      <AddPlanStrategyDialog />
      <DetailsStrategyDialog />
    </>
  )
};

export default AdminWorkspaceStrategies;
