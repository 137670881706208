import { createAsyncAction } from "typesafe-actions";
import { DatasetMark } from "../../types";
import {
  DiscardDatasetMarkRequest,
  FlagDatasetMarkRequest,
  GetDatasetMarkRequest,
  UpdateDatasetMarkRequest,
  UpsertDatasetWithDatasetMarkRequest,
} from "../../api/dataset-marks/types";

export const getDatasetMarks = createAsyncAction(
  "@dataset-marks/GET_DATASET_MARKS_REQUEST",
  "@dataset-marks/GET_DATASET_MARKS_SUCCESS",
  "@dataset-marks/GET_DATASET_MARKS_FAILURE"
)<GetDatasetMarkRequest, DatasetMark[], Error>();

export const updateDatasetMark = createAsyncAction(
  "@dataset-marks/UPDATE_DATASET_MARK_REQUEST",
  "@dataset-marks/UPDATE_DATASET_MARK_SUCCESS",
  "@dataset-marks/UPDATE_DATASET_MARK_FAILURE"
)<UpdateDatasetMarkRequest, DatasetMark, Error>();

export const flagDatasetMark = createAsyncAction(
  "@dataset-marks/FLAG_DATASET_MARK_REQUEST",
  "@dataset-marks/FLAG_DATASET_MARK_MARK_SUCCESS",
  "@dataset-marks/FLAG_DATASET_MARK_MARK_FAILURE"
)<FlagDatasetMarkRequest, DatasetMark, Error>();

export const discardDatasetMark = createAsyncAction(
  "@dataset-marks/DISCARD_DATASET_MARK_REQUEST",
  "@dataset-marks/DISCARD_DATASET_MARK_SUCCESS",
  "@dataset-marks/DISCARD_DATASET_MARK_FAILURE"
)<DiscardDatasetMarkRequest, number, Error>();

export const upsertDatasetWithDatasetMark = createAsyncAction(
  "@dataset-marks/UPSERT_DATASET_WITH_DATASET_MARK_REQUEST",
  "@dataset-marks/UPSERT_DATASET_WITH_DATASET_MARK_SUCCESS",
  "@dataset-marks/UPSERT_DATASET_WITH_DATASET_MARK_FAILURE"
)<UpsertDatasetWithDatasetMarkRequest, DatasetMark, Error>();
