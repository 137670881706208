import React, { FunctionComponent } from "react";
import SurveyReport from "./SurveyReport";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import WorkspaceHeader from "../team-plan/workspace-header/WorkspaceHeader";
import { UserNotesType } from "../../types";
import { generatePath } from "react-router";
import { useHistory } from "react-router-dom";
import { AuthRoutes } from "../../App";
import ReturnButton from "../surveys/cards/ReturnButton";

type OwnProps = {};

type Props = OwnProps;

const PlanReports: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const activePlan = useSelector((s) => s.plans.activePlan);

  if (!activePlan) {
    return null;
  }

  const handleReturnToWorkspace = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanData, {
        workspace: "data",
        tabId: UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
      } as any)
    );
  };

  return (
    <>
      <WorkspaceHeader
        title={intl.formatMessage({
          id: "app.titles.data-collection-and-reports",
        })}
        nextElement={
          <ReturnButton
            text={intl.formatMessage({ id: "app.titles.return-to-workspace" })}
            onClick={handleReturnToWorkspace}
          />
        }
        userNotesType={UserNotesType.DC_REPORTING_REPORTS}
      />
      <hr className="mt-2 mb-4" />
      <SurveyReport />
    </>
  );
};

export default PlanReports;
