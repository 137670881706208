import React, { useMemo } from "react";
import { Menu, MenuItem, Popover, Tag } from "@blueprintjs/core";
import { Locale } from "../../../store/UIState";
import { useIntl } from "react-intl";

type OwnProps = {
  items: Locale[];
  onLanguageChange: (updatedLocale: Locale) => void;
  selectedLocale: Locale;
};

type Props = OwnProps;

const TranslationSelector: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { items, onLanguageChange, selectedLocale } = props;

  const localeDisplayedValues = useMemo(() => {
    return items.map((item) => {
      return {
        key: item,
        value: intl.formatMessage(
          { id: "app.languages" },
          { abbreviation: Object.values(Locale).indexOf(item) }
        ),
      };
    });
  }, [items]);

  return (
    <Popover
      content={
        <Menu className="min-w-0">
          {localeDisplayedValues.map((locale, index) => (
            <MenuItem
              key={locale.key}
              text={locale.value}
              active={locale.key === selectedLocale}
              onClick={() => onLanguageChange(locale.key)}
            />
          ))}
        </Menu>
      }
    >
      <Tag
        rightIcon="translate"
        className="uppercase font-bold"
        interactive
        intent="primary"
        minimal
      >
        {
          localeDisplayedValues?.find((item) => item.key === selectedLocale)
            ?.value
        }
      </Tag>
    </Popover>
  );
};

export default TranslationSelector;
