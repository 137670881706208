import * as React from "react";
import { Icon } from "@blueprintjs/core";
import Dialog from "@mui/material/Dialog";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { deleteNote } from "../../../../store/notes/actions";

type OwnProps = {
  note?: any;
};

type Props = OwnProps;
export default function DeleteNotePopup(props: Props) {
  const [open, setOpen] = React.useState(false);

  const { note } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const handsleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoteDelete = () => {
    dispatch(deleteNote.request({ id: note?.id, planId: note?.plan }));
    handleClose();
  };

  return (
    <React.Fragment>
      <button
        aria-label="Delete"
        className="deleteBtn"
        onClick={handsleClickOpen}
      >
        <Icon icon="trash" iconSize={16} />
      </button>
      <Dialog
        className="delete-strategyPlan-popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="modalBody text-center">
          <div className="iconDiv">
            <Icon icon="trash" iconSize={30} />
          </div>
          
          <h3>{intl.formatMessage({ id: "app.publish.note.delete" })}</h3>
          <p>
          {intl.formatMessage({ id: "app.publish.delete.message" })}
            <br />
            {intl.formatMessage({ id: "app.publish.delete.confirm" })}
          </p>
        </div>
        <div className="button-group">
          <button className="stroke-btn" onClick={handleClose}>
            {" "}
            {intl.formatMessage({ id: "app.titles.cancel" })}{" "}
          </button>
          <button className="nj-btn" onClick={handleNoteDelete}>
            {" "}
            {intl.formatMessage({ id: "app.titles.delete" })}{" "}
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
