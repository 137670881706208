import { AttachedResource, UpsertResourceDialogConfig } from "../../types";
import * as actions from "./actions";
import { createReducer, RootAction } from "typesafe-actions";

type ActionName =
  | "getResources"
  | "addResource"
  | "updateResource"
  | "deleteResource";

export type ResourcesState = {
  resources: AttachedResource[];
  dialogs: {
    upsertResourceDialog: UpsertResourceDialogConfig;
  };
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: ResourcesState = {
  resources: [],
  dialogs: {
    upsertResourceDialog: {},
  },
  loading: {},
  errors: {},
};

export const resourcesReducer = createReducer<ResourcesState, RootAction>(
  initialState
)
  // upsert resource dialog
  .handleAction(
    actions.showUpsertResourceDialog,
    (state, action): ResourcesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        upsertResourceDialog: {
          ...action.payload,
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideUpsertResourceDialog,
    (state, action): ResourcesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        upsertResourceDialog: {},
      },
    })
  )
  // get resources
  .handleAction(
    actions.getResources.request,
    (state, action): ResourcesState => ({
      ...state,
      loading: {
        ...state.loading,
        getResources: true,
      },
      errors: {
        ...state.errors,
        getResources: undefined,
      },
    })
  )
  .handleAction(
    actions.getResources.success,
    (state, action): ResourcesState => ({
      ...state,
      resources: action.payload,
      loading: {
        ...state.loading,
        getResources: false,
      },
    })
  )
  .handleAction(
    actions.getResources.failure,
    (state, action): ResourcesState => ({
      ...state,
      errors: {
        ...state.errors,
        getResources: action.payload,
      },
      loading: {
        ...state.loading,
        getResources: false,
      },
    })
  )
  // add resource
  .handleAction(
    actions.addResource.request,
    (state, action): ResourcesState => ({
      ...state,
      loading: {
        ...state.loading,
        addResource: true,
      },
      errors: {
        ...state.errors,
        addResource: undefined,
      },
    })
  )
  .handleAction(
    actions.addResource.success,
    (state, action): ResourcesState => ({
      ...state,
      resources: [action.payload, ...state.resources],
      loading: {
        ...state.loading,
        addResource: false,
      },
    })
  )
  .handleAction(
    actions.addResource.failure,
    (state, action): ResourcesState => ({
      ...state,
      errors: {
        ...state.errors,
        addResource: action.payload,
      },
      loading: {
        ...state.loading,
        addResource: false,
      },
    })
  )
  // update resource
  .handleAction(
    actions.updateResource.request,
    (state, action): ResourcesState => ({
      ...state,
      loading: {
        ...state.loading,
        updateResource: true,
      },
      errors: {
        ...state.errors,
        updateResource: undefined,
      },
    })
  )
  .handleAction(
    actions.updateResource.success,
    (state, action): ResourcesState => ({
      ...state,
      resources: state.resources.map((res) =>
        res.id === action.payload.id ? action.payload : res
      ),
      loading: {
        ...state.loading,
        updateResource: false,
      },
    })
  )
  .handleAction(
    actions.updateResource.failure,
    (state, action): ResourcesState => ({
      ...state,
      errors: {
        ...state.errors,
        updateResource: action.payload,
      },
      loading: {
        ...state.loading,
        updateResource: false,
      },
    })
  )
  // delete resource
  .handleAction(
    actions.deleteResource.request,
    (state, action): ResourcesState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteResource: true,
      },
      errors: {
        ...state.errors,
        deleteResource: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteResource.success,
    (state, action): ResourcesState => ({
      ...state,
      resources: state.resources.filter((res) => res.id !== action.payload),
      loading: {
        ...state.loading,
        deleteResource: false,
      },
    })
  )
  .handleAction(
    actions.deleteResource.failure,
    (state, action): ResourcesState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteResource: action.payload,
      },
      loading: {
        ...state.loading,
        deleteResource: false,
      },
    })
  );
