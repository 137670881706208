import React, { FunctionComponent } from "react";
import { NonIdealState } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import SetupNewDistrictBtn from "./SetupNewDistrictBtn";

type OwnProps = {};

type Props = OwnProps;

const NoDistricts: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <NonIdealState
      icon="office"
      title={intl.formatMessage({ id: "app.forms.no-districts.title" })}
      description={intl.formatMessage({
        id: "app.forms.no-districts.description",
      })}
      action={<SetupNewDistrictBtn />}
    />
  );
};

export default NoDistricts;
