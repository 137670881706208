import React, { useMemo } from "react";
import {
  Activity,
  ActivityTypeEnum,
  Domain,
  DomainQuestionsComparison,
  DomainStatistics,
  SurveyDemographic,
  SurveyReportRouteParams,
  SurveyReportTabEnum,
} from "../../../types";
import { selectIsAdmin } from "../../../store/auth/selectors";
import { useIntl } from "react-intl";
import { Button, Card } from "@blueprintjs/core";
import { generatePath, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import { Locale } from "../../../store/UIState";
import TagTabHeader from "./TagTabHeader";
import { getUrlParams } from "../../../helpers/url-utils";
import { GROUP_BY_PARAM } from "../table/survey-report-table-head/SurveyReportTableHead";
import ReportDomainFlagIcon from "./ReportDomainFlagIcon";
import NegativeDomainIcon from "./NegativeDomainIcon";
import ReturnButton from "./ReturnButton";
import CollapseRedactedDataSwitch from "./CollapseRedactedDataSwitch";
import { isDistrictPlan } from "../../../store/plans/selectors";
import { useAttachActivities } from "../../../helpers/hooks/useAttachActivities";
import { useDispatch, useSelector } from "react-redux";
import { showActivityVideoDialog } from "../../../store/activities/actions";
import DownloadReportButton from "../breadcrumb/DownloadReportButton";
import DownloadReportCSVButton from "../breadcrumb/DownloadReportCSVButton";

type OwnProps = {
  surveyReport: DomainStatistics;
  surveyComparison: DomainQuestionsComparison;
  demographic?: SurveyDemographic;
  selectedDomain?: Domain;

  reportPagePath: string;
  domainId: number | undefined;
  tabId: string | undefined;
  tagId: string | undefined;
  routeParams: SurveyReportRouteParams;
  surveyDeploymentId?: number;
  schoolId?: number;
};

type Props = OwnProps;

const ReportHeaderCard: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    surveyReport,
    surveyComparison,
    demographic,
    selectedDomain,
    reportPagePath,
    domainId,
    tabId,
    tagId,
    routeParams,
    surveyDeploymentId,
    schoolId,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const isAdmin = useSelector(selectIsAdmin);
  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const groupingKeys = useMemo(() => {
    return getUrlParams(GROUP_BY_PARAM, location.search);
  }, [location]);

  const surveyDeployment = useSelector(
    (s) => s.surveyReports.selectedSurveyDeployment
  );

  const isGroupedData = useMemo(() => {
    return !!groupingKeys?.length;
  }, [groupingKeys]);

  const handleReturnToMainReportClick = () => {
    const { tagId, ...params } = routeParams;
    history.push(
      generatePath(reportPagePath, {
        ...params,
        domainId: undefined,
      })
    );
  };
  const { onActivityCheck } = useAttachActivities({
    onClick: (activity: Activity) => {
      if (activity.activity_type === ActivityTypeEnum.KALTURA_VIDEO) {
        dispatch(
          showActivityVideoDialog({
            activity: activity,
            viewable_field: "embed_code",
          })
        );
      }
    },
  });

  const selectedDemographic = useMemo(() => {
    if (
      Object.values(SurveyDemographic).includes(
        (tabId as unknown) as SurveyDemographic
      )
    ) {
      return (tabId as unknown) as SurveyDemographic;
    }
  }, [tabId]);

  const domainMaxAnswerIndex = useMemo(() => {
    return Number(
      _.max(
        Object.keys(
          (demographic &&
            selectedDomain?.domain_answer?.[demographic]?.[Locale.English]) ??
          {}
        )
      ) ?? 0
    );
  }, [selectedDomain, demographic]);

  const meanScore = useMemo(() => {
    if (tabId === SurveyReportTabEnum.Comparison) {
      return surveyComparison?.mean_score ?? 0;
    } else if (!!demographic && !!surveyReport?.mean_ratings) {
      return (
        (surveyReport?.mean_ratings?.[demographic]?.mean_rating ?? 0) *
        domainMaxAnswerIndex
      );
    }
  }, [
    tabId,
    demographic,
    surveyReport,
    surveyComparison,
    domainMaxAnswerIndex,
  ]);
  const actionButtons = (
    <div className="flex flex-grow justify-end">
      <Button
        title={intl.formatMessage({
          id: "app.survey-report.beard-crumbs.survey-report-tutorial",
        })}
        text={intl.formatMessage({
          id: "app.survey-report.beard-crumbs.survey-report-tutorial",
        })}
        className="light-blue mr-3"
        intent="primary"
        onClick={() =>
          onActivityCheck(
            intl.formatMessage({
              id: "app.survey-report.beard-crumbs.survey-report-features",
            })
          )
        }
      />

      {surveyDeploymentId && schoolId && selectedDemographic && (
        <>
          <DownloadReportButton
            surveyDeploymentId={surveyDeploymentId}
            schoolId={schoolId}
            selectedDemographic={selectedDemographic}
            surveyName={surveyDeployment?.survey?.name ?? ""}
            disable={surveyReport.not_enough_data}
          />
          {isAdmin && (
            <DownloadReportCSVButton
              surveyDeploymentId={surveyDeploymentId}
              schoolId={schoolId}
              selectedDemographic={selectedDemographic}
              surveyName={surveyDeployment?.survey?.name ?? ""}
            />
          )}
        </>
      )}
    </div>
  );
  const buttonText = useMemo(() => {
    if (tabId === SurveyReportTabEnum.ClusterTags) {
      return intl.formatMessage({
        id: "app.survey-report.cluster-tags.back-to-list",
      });
    }
    if (tabId === SurveyReportTabEnum.ResponseRates) {
      return "";
    }

    return intl.formatMessage(
      {
        id: "app.survey-report.selected-domain.return-button.text",
      },
      {
        page:
          tabId === SurveyReportTabEnum.Comparison
            ? intl.formatMessage({ id: "app.titles.comparison" })
            : demographic
              ? intl.formatMessage({
                id: `app.filters.respondent-type.${demographic}`,
              })
              : "",
      }
    );
  }, [demographic, tabId]);

  if (tagId) {
    return (
      <TagTabHeader
        tagId={tagId}
        onClick={handleReturnToMainReportClick}
        buttonText={buttonText}
      />
    );
  }

  if (domainId) {
    return (
      <>
        {tabId !== SurveyReportTabEnum.ResponseRates && (
          <div className="flex justify-between mt-4">
            <ReturnButton
              text={buttonText}
              onClick={handleReturnToMainReportClick}
            />
            {isGroupedData && <CollapseRedactedDataSwitch />}
            <div>{actionButtons}</div>
          </div>
        )}
        <div className="flex items-center justify-between py-4">
          <div className="flex gap-2 items-center">
            <strong className="text-3xl">{selectedDomain?.name}</strong>

            {selectedDomain?.domain_answer.is_negative && (
              <NegativeDomainIcon />
            )}

            <ReportDomainFlagIcon
              domainId={domainId}
              demographic={demographic}
              meanScore={meanScore}
            />

            {tabId !== SurveyReportTabEnum.Comparison &&
              tabId !== SurveyReportTabEnum.ResponseRates && (
                !surveyReport.not_enough_data ?
                  <Card className="p-2">
                    <span className="flex justify-center text-2xl font-bold">
                      {intl.formatMessage({
                        id: "app.survey-report.table.second-head.mean-score",
                      })}
                      &#58;&nbsp;
                      {meanScore?.toFixed(2)}
                    </span>
                  </Card>
                  :
                  <Card className="p-2">
                    <span className="flex justify-center text-2xl font-bold">
                      {intl.formatMessage({
                        id: "app.survey-report.table.second-head.mean-score",
                      })}
                      &#58;&nbsp;{intl.formatMessage({
                        id: "app.non-ideal-state.survey-reports.not-enough-respondents",
                      })}
                    </span>
                  </Card>
              )
            }
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="flex justify-between items-center pt-4 pb-1">
      <div className="text-3xl">
        <strong>
          {intl.formatMessage({ id: "app.titles.survey-report" })}
        </strong>
      </div>
      {isGroupedData && <CollapseRedactedDataSwitch />}
      {actionButtons}
    </div>
  );
};

export default ReportHeaderCard;
