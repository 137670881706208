import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { AnchorButton, Button, Classes, Dialog, Tab, Tabs } from "@blueprintjs/core";
import TeamCompositionTab from "./TeamCompositionTab";

const GUIDANCE_PDF_URL = "https://staging.njschoolclimate.org/files/activity/Mg==/download/pdf/Guidance+on+School+Climate+Leadership+Team+Development.pdf";

type OwnProps = {
  show?: boolean;
  onClose: () => void;
};

type Props = OwnProps;

const TeamDevelopmentGuidanceDialog: React.FC<Props> = ({ show, onClose }) => {
  const intl = useIntl();

  const teamPurposeContent = useMemo(() => {
    return intl.formatMessage(
      { id: "app.team-purposes.content" },
      { i: (chunks) => <i>{chunks}</i>, br: <br /> }
    );
  }, [intl]);

  const teamTasksResponsibilitiesContent = useMemo(() => {
    return intl.formatMessage(
      { id: "app.team-tasks-responsibilities.content" },
      {
        menu: (
          <ul className="list-disc pl-10">
            {intl.formatMessage(
              {
                id: "app.team-tasks-responsibilities.menu",
              },
              {
                li: (chunks) => (
                  <>
                    <br />
                    <li>{chunks}</li>
                  </>
                ),
              }
            )}
            <br />
          </ul>
        ),
        i: (chunks) => <i>{chunks}</i>,
        br: <br />,
      }
    );
  }, [intl]);

  return (
    <Dialog
      isOpen={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: "app.dialogs.guidance-team-development",
      })}
      style={{ minWidth: "45rem" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="flex justify-end">
          <AnchorButton
            text={intl.formatMessage({
              id: "app.guidance-team-development-dialog.download-pdf",
            })}
            title={intl.formatMessage({
              id: "app.guidance-team-development-dialog.download-pdf",
            })}
            intent="primary"
            href={GUIDANCE_PDF_URL}
            target="_blank"
          />
        </div>
        <Tabs className="my-4">
          <Tab
            id="team-purpose"
            title={intl.formatMessage({
              id: "app.guidance-team-development-dialog.team-purpose",
            })}
            panel={<p>{teamPurposeContent}</p>}
          />
          <Tab
            id="team-composition"
            title={intl.formatMessage({
              id: "app.guidance-team-development-dialog.team-composition",
            })}
            panel={<TeamCompositionTab />}
          />
          <Tab
            id="team-tasks-responsibilities"
            title={intl.formatMessage({
              id:
                "app.guidance-team-development-dialog.team-tasks-responsibilities",
            })}
            panel={<>{teamTasksResponsibilitiesContent}</>}
          />
        </Tabs>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-center">
          <Button className="w-1/4" onClick={onClose} intent="primary">
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TeamDevelopmentGuidanceDialog;
