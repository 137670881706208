import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getStrategies,
  hideUpdatePlanStrategyDetailsDialog,
  updateStrategy,
} from "../../../../store/strategies/actions";
import { AppToaster } from "../../../../helpers/toaster";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import {
  LookForItem,
  LookForItemStatus,
  LookForItemStatusEnum,
  PlanStrategy,
  RecurringStatusEnum,
  Strategy,
} from "../../../../types";
import {
  Button,
  Classes,
  Dialog,
  Icon,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import StrategyParameters from "../add-plan-strategy-dialog/StrategyParameters";
import { updatePlanStrategy } from "../../../../store/plan-strategies/actions";
import LookForsStatusesTab from "../upsert-strategy-dialog/tabs/LookForsStatusesTab";
import { useStrategyConfrimDelete } from "../../../../helpers/hooks/plan-strategies/useStrategyConfrimDelete";
import { getGoalsByPlan } from "../../../../store/goals/actions";

type OwnProps = {};

type Props = OwnProps;

const UpdatePlanStrategyDetailsDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { show, planStrategy, goalTitle, onConfirm } = useSelector(
    (s) => s.strategies.dialogs.updatePlanStrategyDetailsDialog
  );

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const [selectedTab, setSelectedTab] = useState<TabId>("parameters");

  const [lookForStatuses, setLookForStatuses] = useState<
    Omit<
      LookForItemStatus<Omit<LookForItem, "id" | "created_at" | "updated_at">>,
      "id" | "created_at" | "updated_at"
    >[]
  >([]);

  const handleClose = () => {
    dispatch(hideUpdatePlanStrategyDetailsDialog());
  };

  const handleOpen = () => {
    setSelectedTab("parameters");
    if (planStrategy) {
      setLookForStatuses(
        planStrategy.strategy.look_for_items?.map((lookFor) => {
          const lookForStatus = planStrategy.look_for_statuses?.find(
            (lookForStatus) => lookForStatus.item === lookFor.id
          );
          if (lookForStatus) {
            return {
              ...lookForStatus,
              item: lookFor,
            };
          }
          return {
            recurring_status: RecurringStatusEnum.NotRecurring,
            status: LookForItemStatusEnum.NotPresent,
            item: lookFor,
          };
        })
      );
    }
  };

  const updateStrategyLoading = useSelector(
    (s) => s.strategies.loading.updateStrategy
  );
  const updateStrategyError = useSelector(
    (s) => s.strategies.errors.updateStrategy
  );
  useLoading({ loading: updateStrategyLoading, error: updateStrategyError });

  const loading = useSelector(
    (s) => s.planStrategies.loading.updatePlanStrategy
  );
  const error = useSelector((s) => s.planStrategies.errors.updatePlanStrategy);
  const onSuccess = () => {
    if (show) {
      AppToaster.show({
        icon: "tick",
        intent: "success",
        message: intl.formatMessage(
          { id: "app.plan-strategies.update-parameters" },
          { name: planStrategy?.strategy?.name }
        ),
      });
    }
    onConfirm && onConfirm();
    handleClose();
  };
  useLoading({ loading: loading, error: error, onSuccess: onSuccess });

  const title = useMemo(() => {
    let res = intl.formatMessage({ id: "app.strategies.update-parameters" });

    if (planStrategy?.strategy?.name) {
      res += ": " + planStrategy.strategy.name;
    }

    return res;
  }, [planStrategy?.strategy?.name]);

  const isLookForItemsChanged = useCallback(() => {
    if (planStrategy) {
      let lookForItems = planStrategy.strategy?.look_for_items;
      let updatedLookForItems = lookForStatuses.map(
        (lookForStatus) => lookForStatus.item
      );
      return (
        lookForItems?.length !== updatedLookForItems?.length ||
        lookForItems.some(
          (lookFor, i) => lookFor.text !== updatedLookForItems[i]?.text
        )
      );
    }
    return false;
  }, [planStrategy, lookForStatuses]);

  const onUpdateSuccess =
    (
      params: Pick<
        PlanStrategy,
        | "status"
        | "target_groups"
        | "grades"
        | "launch_date"
        | "locations"
        | "timeframe"
        | "general_approach"
      >
    ) =>
    (strategy: Strategy) => {
      if (planStrategy && activePlanId) {
        dispatch(
          updatePlanStrategy.request({
            planStrategy: {
              planId: activePlanId,
              strategy: planStrategy.strategy.id,
              planStrategyId: planStrategy.id,
              look_for_statuses: lookForStatuses.map(
                (lookForStatus, index) => ({
                  ...lookForStatus,
                  item: strategy.look_for_items?.[index]?.id,
                })
              ),
              ...params,
            },
          })
        );
      }
    };

  const handleFormSubmit = (
    params: Pick<
      PlanStrategy,
      | "status"
      | "target_groups"
      | "grades"
      | "launch_date"
      | "locations"
      | "timeframe"
      | "general_approach"
    > & {
      leaders: number[];
    }
  ) => {
    if (activePlanId && planStrategy) {
      if (isLookForItemsChanged()) {
        dispatch(
          updateStrategy.request({
            updatedStrategy: {
              id: planStrategy.strategy.id,
              name: planStrategy.strategy.name,
              domains: planStrategy.strategy.domains.map((d) => d.id),
              description: planStrategy.strategy.description,
              supporting_evidence: planStrategy.strategy.supporting_evidence,
              look_for_items: lookForStatuses.map(
                (lookForStatus) => lookForStatus.item
              ),
              resources: planStrategy.strategy.resources.map((r) => r.id),
              school: planStrategy.strategy.school,
              is_global: false
            },
            onSuccess: (strategy: Strategy) => {
              onUpdateSuccess(params)(strategy);
            },
          })
        );
      } else {
        dispatch(
          updatePlanStrategy.request({
            planStrategy: {
              planId: activePlanId,
              strategy: planStrategy.strategy.id,
              planStrategyId: planStrategy.id,
              look_for_statuses: lookForStatuses.map(
                (lookForStatus, index) => ({
                  ...lookForStatus,
                  item: planStrategy.strategy.look_for_items?.[index]?.id,
                })
              ),
              ...params,
            },
          })
        );
      }
    }
  };

  const formId = useMemo(() => "strategy-parameters-form", []);

  const goalAndStrategyName = useMemo(() => {
    if (goalTitle && planStrategy?.strategy.name) {
      return `${goalTitle}: ${planStrategy.strategy.name}`;
    }

    return null;
  }, [goalTitle, planStrategy?.strategy.name]);

  const { confirmDelete } = useStrategyConfrimDelete({
    item: planStrategy?.strategy!,
    onDeleteSuccess: () => {
      handleClose();
      if (activePlanId) {
        // todo optimize?
        dispatch(getStrategies.request());
        dispatch(getGoalsByPlan.request(activePlanId));
      }
    },
  });
  const handlePlanStrategyRemoveFromPlan = () => {
    confirmDelete();
  };

  return (
    <Dialog
      isOpen={show}
      onOpening={handleOpen}
      onClose={handleClose}
      style={{
        minWidth: "31.25rem", //500px
        width: selectedTab === "core-components" ? "43.75rem" : undefined, //700px
      }}
      icon={<Icon icon="briefcase" iconSize={24} />}
      title={title}
    >
      <div className={Classes.DIALOG_BODY}>
        {goalAndStrategyName && (
          <div className="font-bold">{goalAndStrategyName}</div>
        )}

        <Tabs
          id="update-strategy-details"
          onChange={setSelectedTab}
          selectedTabId={selectedTab}
        >
          <Tab
            id="parameters"
            title={intl.formatMessage({ id: "app.titles.parameters" })}
            panel={
              <StrategyParameters
                strategy={planStrategy}
                formId={formId}
                onSubmit={handleFormSubmit}
              />
            }
          />
          <Tab
            id="core-components"
            title={intl.formatMessage({ id: "app.titles.look-fors" })}
            panel={
              <LookForsStatusesTab
                lookForStatuses={lookForStatuses}
                setLookForStatuses={setLookForStatuses}
              />
            }
          />
        </Tabs>

        <Button
          intent="danger"
          icon="trash"
          text={intl.formatMessage({
            id: "app.plans-strategies.remove-fromm-plan",
          })}
          title={intl.formatMessage({
            id: "app.plans-strategies.remove-fromm-plan",
          })}
          onClick={handlePlanStrategyRemoveFromPlan}
          className="mt-2"
        />
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between mt-4">
          <Button className="w-1/4" onClick={handleClose} type={"reset"}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            type="submit"
            form={formId}
            className="w-1/4"
            intent="primary"
            loading={loading || updateStrategyLoading}
          >
            {intl.formatMessage({
              id: "app.titles.save",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdatePlanStrategyDetailsDialog;
