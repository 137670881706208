import { UpdatePasswordRequest, UpdateProfileRequest } from "./types";
import Axios from "axios";
import { API_ROOT } from "../config";
import { AuthenticatedUserModel } from "../../types";

export const changePassword = (
  request: UpdatePasswordRequest
): Promise<void> => {
  return Axios.put(`${API_ROOT}/change_password/`, request);
};

export const updateProfile = (
  request: UpdateProfileRequest
): Promise<AuthenticatedUserModel> => {
  return Axios.patch(`${API_ROOT}/users/profile/`, request);
};
