import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMembersByPlan } from "../../../store/team-members/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { Callout, HTMLTable, Spinner } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { isDistrictPlan } from "../../../store/plans/selectors";
import { InviteStatus } from "../../../types";

type OwnProps = {};

type Props = OwnProps;

const TeamTable: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  // useEffect(() => {
  //   if (activePlan?.id) {
  //     dispatch(getTeamMembersByPlan.request({ planId: activePlan.id }));
  //   }
  // }, [activePlan?.id]);

  const members = useSelector((s) => s.teamMembers.teamMembers);
  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  const error = useSelector((s) => s.teamMembers.errors.getTeamMembersByPlan);
  useLoading({ error, loading });

  if (loading && !members.length) {
    return <Spinner intent="primary" />;
  }

  if (!members.length) {
    return (
      <Callout intent="warning">
        {intl.formatMessage({ id: "app.team-plan.team.no-other-members" })}
      </Callout>
    );
  }

  return (
    <HTMLTable className="w-full">
      <div className={members.length >10?"dashboard-role-table":""}>
        <thead>
          <tr>
            <th>{intl.formatMessage({ id: "app.titles.name" })}</th>
            <th>
              {intl.formatMessage({
                id: `app.titles.${
                  isDistrictActivePlan ? "district-school-" : ""
                }role`,
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => {
            const teamRole = intl.formatMessage({
              id: `app.team-plan.team.roles.${member.team_role}`,
            });
            const userRole = intl.formatMessage(
              { id: "app.roles" },
              { role: member.user?.profile?.role }
            );
            return (
              <>
                <tr key={member.user.id}>
                  <td>
                    {`${member.user.first_name} ${member.user.last_name}`}
                    {member.user.is_active === false && member.invited_status === InviteStatus.Accepted ? (
                      <i>
                        {" "}
                        {intl.formatMessage({ id: "app.titles.deactivated" })}
                      </i>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="italic">
                    {member.user?.profile?.custom_role_name ?? userRole}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </div>
    </HTMLTable>
  );
};

export default TeamTable;
