import { AttachedResource } from "../../types";
import Axios, { AxiosRequestConfig } from "axios";
import { API_ROOT } from "../config";
import { AddResourceRequest, UpdateResourceRequest } from "./types";
import _ from "lodash";

export const getResources = (planId: number): Promise<AttachedResource[]> => {
  return Axios.get(`${API_ROOT}/plans/${planId}/resources/`);
};

export const addResources = (
  req: AddResourceRequest[],
  planId?: number
): Promise<PromiseSettledResult<AttachedResource>[]> => {
  return Promise.allSettled(req.map((r) => addResource(r, planId)) ?? []);
};

export const addResource = (
  req: AddResourceRequest,
  planId?: number
): Promise<AttachedResource> => {
  const formData = new FormData();
  _.chain(req)
    .entries()
    .forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        formData.append(key, value!);
      }
    })
    .value();

  if (req.attachment) {
    formData.append("mime_type", req.attachment.type);
  }

  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return Axios.post(
    `${API_ROOT}/${planId ? `plans/${planId}/` : ""}resources/`,
    formData,
    config
  );
};

export const updateResource = (
  { id, ...req }: UpdateResourceRequest,
  planId: number
): Promise<AttachedResource> => {
  const formData = new FormData();
  _.chain(req)
    .entries()
    .forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        formData.append(key, value!);
      }
    })
    .value();

  if (req.attachment) {
    formData.append("mime_type", req.attachment.type);
  }

  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return Axios.put(
    `${API_ROOT}/plans/${planId}/resources/${id}/`,
    formData,
    config
  );
};

export const deleteResource = (
  resourceId: number,
  planId: number
): Promise<void> => {
  return Axios.delete(`${API_ROOT}/plans/${planId}/resources/${resourceId}`);
};
