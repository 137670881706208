import { useMemo, useState } from "react";
import { MeasurementType } from "../../types";
import { useIntl } from "react-intl";

export const useMeasurementOptions = () => {
  const intl = useIntl();

  const [measurementType, setMeasurementType] = useState<MeasurementType>(
    MeasurementType.Number
  );

  const measurementTypes = useMemo(() => {
    return Object.values(MeasurementType).map((type) => ({
      label: intl.formatMessage(
        { id: "app.data-sets.measurement-type" },
        { type: type }
      ),
      value: type,
    }));
  }, []);

  const selectedMeasurementOption = useMemo(() => {
    return (
      measurementType &&
      measurementTypes.find((nt) => nt.value === measurementType)
    );
  }, [measurementTypes, measurementType]);

  return {
    measurementType,
    measurementTypes,
    setMeasurementType,
    selectedMeasurementOption,
  };
};
