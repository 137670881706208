import {
  Notification,
  NotificationSettings,
  NotificationType,
  UserRole,
} from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import {
  MarkAsReadNotifications,
  UpdateNotificationSettingsRequest,
} from "./types";

export const getNotificationSettings = (): Promise<NotificationSettings[]> => {
  const notificationSettings: NotificationSettings[] = [
    {
      id: Math.random().toString(),
      label: "All other notifications",
      preferences: {
        in_app: true,
        email: false,
      },
    },
    {
      id: Math.random().toString(),
      label: "When you receive a chat message",
      preferences: {
        in_app: false,
        email: true,
      },
    },
    {
      id: Math.random().toString(),
      label: "When someone logs an intervation in a group you follow",
      preferences: {
        in_app: true,
        email: true,
      },
    },
  ];
  return Promise.resolve(notificationSettings); //todo remove
  return Axios.get(`${API_ROOT}/settings/notifications`);
};

export const updateNotificationSettings = (
  request: UpdateNotificationSettingsRequest
): Promise<NotificationSettings> => {
  const notificationSettings: NotificationSettings = {
    id: request.id,
    label: "All other notifications",
    preferences: {
      in_app: request.preferences.in_app,
      email: request.preferences.email,
    },
  };
  return Promise.resolve(notificationSettings); //todo remove
  return Axios.put(`${API_ROOT}/settings/notifications`, request);
};

const notifications: Notification[] = [
  //todo remove
  {
    id: Math.floor(Math.random() * 999) + 1,
    is_active: true,
    read: false,
    body: "You should contact your teacher in 3 days",
    notification_type: NotificationType.MESSAGE,
  },
  {
    id: Math.floor(Math.random() * 999) + 1,
    is_active: true,
    read: false,
    body: "Other message",
    notification_type: NotificationType.MESSAGE,
  },
  {
    id: Math.floor(Math.random() * 999) + 1,
    is_active: true,
    read: false,
    body: "Third message",
    notification_type: NotificationType.MESSAGE,
  },
  {
    id: Math.floor(Math.random() * 999) + 1,
    is_active: true,
    read: false,
    body: "Sent you a message",
    email_sent_at: "2020-11-13T02:00:00+05:30",
    from_user: {
      id: Math.random(),
      first_name: "Franklin",
      last_name: "Matt",
      email: "frank_m@email.com",
      profile: {
        id: Math.random(),
        created_at: "2020-11-13T02:00:00+05:30",
        updated_at: "2020-11-13T02:00:00+05:30",
        role: UserRole.Superadmin,
        district: null,
        schools: [],
        user: 2,
        is_dcc: false,
        is_setup_completed: false,
      },
      is_superuser: "true",
      is_staff: true,
    },
    notification_type: NotificationType.MESSAGE,
  },
];

export const markNotificationsAsRead = (
  request: MarkAsReadNotifications
): Promise<Notification[]> => {
  return Promise.resolve(
    request.ids.map((id) => ({
      ...notifications.find((n) => n.id === id)!,
      read: true,
    }))
  ); // todo remove
  return Axios.put(`${API_ROOT}/notifications/${request.ids}`);
};

export const getNotifications = (): Promise<Notification[]> => {
  return Promise.resolve(notifications); //todo remove
  return Axios.get(`${API_ROOT}/notifications`);
};
