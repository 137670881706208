import { NotificationSettings, Notification } from "../../types";
import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import _ from "lodash";

type ActionName =
  | "getNotificationSettings"
  | "updateNotificationSettings"
  | "getNotifications"
  | "markNotificationsAsRead";

export type NotificationSettingsState = {
  notificationSettings: NotificationSettings[];
  notifications: Notification[];
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: NotificationSettingsState = {
  notificationSettings: [],
  notifications: [],
  loading: {},
  errors: {},
};

export const notificationSettingsReducer = createReducer<
  NotificationSettingsState,
  RootAction
>(initialState)
  //get notification settings
  .handleAction(
    actions.getNotificationSettings.request,
    (state, action): NotificationSettingsState => ({
      ...state,
      loading: {
        ...state.loading,
        getNotificationSettings: true,
      },
      errors: {
        ...state.errors,
        getNotificationSettings: undefined,
      },
    })
  )
  .handleAction(
    actions.getNotificationSettings.success,
    (state, action): NotificationSettingsState => ({
      ...state,
      notificationSettings: action.payload,
      loading: {
        ...state.loading,
        getNotificationSettings: false,
      },
    })
  )
  .handleAction(
    actions.getNotificationSettings.failure,
    (state, action): NotificationSettingsState => ({
      ...state,
      errors: {
        ...state.errors,
        getNotificationSettings: action.payload,
      },
      loading: {
        ...state.loading,
        getNotificationSettings: false,
      },
    })
  )
  //update notification
  .handleAction(
    actions.updateNotificationSettings.request,
    (state, action): NotificationSettingsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateNotificationSettings: true,
      },
      errors: {
        ...state.errors,
        updateNotificationSettings: undefined,
      },
    })
  )
  .handleAction(
    actions.updateNotificationSettings.success,
    (state, action): NotificationSettingsState => ({
      ...state,
      notificationSettings: state.notificationSettings.map((notification) => {
        return notification.id === action.payload.id
          ? {
              ...notification,
              preferences: {
                in_app: action.payload.preferences.in_app,
                email: action.payload.preferences.email,
              },
            }
          : notification;
      }),
      loading: {
        ...state.loading,
        updateNotificationSettings: false,
      },
    })
  )
  .handleAction(
    actions.updateNotificationSettings.failure,
    (state, action): NotificationSettingsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateNotificationSettings: action.payload,
      },
      loading: {
        ...state.loading,
        updateNotificationSettings: false,
      },
    })
  )
  //get notifications
  .handleAction(
    actions.getNotifications.request,
    (state, action): NotificationSettingsState => ({
      ...state,
      loading: {
        ...state.loading,
        getNotifications: true,
      },
      errors: {
        ...state.errors,
        getNotifications: undefined,
      },
    })
  )
  .handleAction(
    actions.getNotifications.success,
    (state, action): NotificationSettingsState => ({
      ...state,
      notifications: action.payload,
      loading: {
        ...state.loading,
        getNotifications: false,
      },
    })
  )
  .handleAction(
    actions.getNotifications.failure,
    (state, action): NotificationSettingsState => ({
      ...state,
      errors: {
        ...state.errors,
        getNotifications: action.payload,
      },
      loading: {
        ...state.loading,
        getNotifications: false,
      },
    })
  )
  //mark notification as read
  .handleAction(
    actions.markNotificationsAsRead.request,
    (state, action): NotificationSettingsState => ({
      ...state,
      loading: {
        ...state.loading,
        markNotificationsAsRead: true,
      },
      errors: {
        ...state.errors,
        markNotificationsAsRead: undefined,
      },
    })
  )
  .handleAction(
    actions.markNotificationsAsRead.success,
    (state, action): NotificationSettingsState => ({
      ...state,
      notifications: state.notifications.map(
        (n) => action.payload.find((nn) => nn.id === n.id) ?? n
      ),
      loading: {
        ...state.loading,
        markNotificationsAsRead: false,
      },
    })
  )
  .handleAction(
    actions.markNotificationsAsRead.failure,
    (state, action): NotificationSettingsState => ({
      ...state,
      errors: {
        ...state.errors,
        markNotificationsAsRead: action.payload,
      },
      loading: {
        ...state.loading,
        markNotificationsAsRead: false,
      },
    })
  );
