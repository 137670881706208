import React from "react";
import { Classes, Dialog } from "@blueprintjs/core";
import SCIChangeProcess from "../../../../static/images/School_Climate_Change_Process_Graphic.png";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { hideLearnAboutSccpAndDcltdDialog } from "../../../../store/team-members/actions";

type OwnProps = {};

type Props = OwnProps;

const FullScreenImageDialog: React.FC<Props> = ({ }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { show } = useSelector(
    (s) => s.teamMembers.dialogs.learnAboutSccpAndDcltdDialog
  );

  const handleClose = () => {
    dispatch(hideLearnAboutSccpAndDcltdDialog());
  };

  return (
    <Dialog
      isOpen={show}
      className="bg-white"
      onClose={handleClose}
      style={{ width: "50rem" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="flex justify-center">
          <img src={SCIChangeProcess} alt="School Climate Improvement Change Process" />
        </div>
      </div>
    </Dialog>
  );
};

export default FullScreenImageDialog;
