import React, { FunctionComponent, useMemo, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import SchoolTeamResourcesInfoDialog from "./SchoolTeamResourcesInfoDialog";
import { ActivityTagEnum, UserNotesType } from "../../../../../types";

type OwnProps = {
  userNotesType?: UserNotesType;
  activityTag?: ActivityTagEnum;
};

type Props = OwnProps;

const SchoolTeamResourcesInfo: FunctionComponent<Props> = (props) => {
  const { userNotesType, activityTag } = props;

  const intl = useIntl();

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleDialogOpen = () => {
    setShowDialog(true);
  };

  const title = useMemo(() => {
    switch (userNotesType) {
      case UserNotesType.SCLT_RESOURCES:
        return intl.formatMessage(
          { id: "app.team-plan.leadership-team.resources.info" },
          {
            i: (chunks) => <i>{chunks}</i>,
            icon: (
              <Icon
                icon={"info-sign"}
                className="align-middle cursor-pointer"
                onClick={handleDialogOpen}
              />
            ),
          }
        );

      case UserNotesType.DC_REPORTING_RESOURCES:
        return intl.formatMessage({
          id: "app.team-plan.data-collection-and-reports.resources.info",
        });

      case UserNotesType.SNA_RESOURCES:
        return intl.formatMessage({
          id: "app.team-plan.strengths-and-needs-analysis.resources.info",
        });

      case UserNotesType.STRATEGIC_PLAN_WORKSPACE:
        return intl.formatMessage({
          id: "app.team-plan.strategic-planning.resources.info",
        });

      case UserNotesType.SCHOOL_RESOURCES_WORKSPACE:
      case UserNotesType.DISTRICT_RESOURCES_WORKSPACE:
        return intl.formatMessage({
          id: "app.team-plan.resources.info",
        });

      case UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE:
      case UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE: {
        return intl.formatMessage({
          id:
            activityTag === ActivityTagEnum.LEARNING_NETWORK_EVENTS
              ? "app.learning-modules.network-events"
              : "app.learning-modules.self-directed-learning-modules",
        });
      }

      default:
        return null;
    }
  }, [userNotesType]);

  return (
    <>
      <div className={"text-xl"}>{title}</div>
      <SchoolTeamResourcesInfoDialog
        show={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};

export default SchoolTeamResourcesInfo;
