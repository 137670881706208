import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { Domain } from "../../types";

type ActionName =
  | "getDomains"
  | "getDomainsById"
  | "addDomain"
  | "updateDomain"
  | "getDomainById"
  | "deleteDomain"
  | "getExistDomainItems"

export type DomainsState = {
  domains: Domain[];
  selectedDomain?: Domain;
  domainsExistStatus?:any;
  domainItemsExistStatus?:any;
  showDomainUpsertDialog?: boolean;
  domainName?: string;
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: DomainsState = {
  domains: [],
  domainsExistStatus:[],
  domainItemsExistStatus:[],
  loading: {},
  errors: {},
};

export const domainsReducer = createReducer<DomainsState, RootAction>(
  initialState
)
  .handleAction(
    actions.setSelectedDomain,
    (state, action): DomainsState => ({
      ...state,
      selectedDomain: action.payload,
    })
  )


 // get domains By ID
 .handleAction(
  actions.getDomainsById.request,
  (state, action): DomainsState => ({
    ...state,
    loading: {
      ...state.loading,
      getDomainsById: true,
    },
    errors: {
      ...state.errors,
      getDomainsById: undefined,
    },
  })
)
.handleAction(
  actions.getDomainsById.success,
  (state, action): DomainsState => ({
    ...state,
    domainsExistStatus: action.payload,
    loading: {
      ...state.loading,
      getDomainsById: false,
    },
  })
)
.handleAction(
  actions.getDomainsById.failure,
  (state, action): DomainsState => ({
    ...state,
    errors: {
      ...state.errors,
      getDomainsById: action.payload,
    },
    loading: {
      ...state.loading,
      getDomainsById: false,
    },
  })
)


 // get Exist domains Items By Deployment ID
 .handleAction(
  actions.getExistDomainItems.request,
  (state, action): DomainsState => ({
    ...state,
    loading: {
      ...state.loading,
      getExistDomainItems: true,
    },
    errors: {
      ...state.errors,
      getExistDomainItems: undefined,
    },
  })
)
.handleAction(
  actions.getExistDomainItems.success,
  (state, action): DomainsState => ({
    ...state,
    domainItemsExistStatus: action.payload,
    loading: {
      ...state.loading,
      getExistDomainItems: false,
    },
  })
)
// .handleAction(
//   actions.getExistDomainItems.failure,
//   (state, action): DomainsState => ({
//     ...state,
//     errors: {
//       ...state.errors,
//       getExistDomainItems: action.payload,
//     },
//     loading: {
//       ...state.loading,
//       getExistDomainItems: false,
//     },
//   })
// )



  // get domains
  .handleAction(
    actions.getDomains.request,
    (state, action): DomainsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDomains: true,
      },
      errors: {
        ...state.errors,
        getDomains: undefined,
      },
    })
  )
  .handleAction(
    actions.getDomains.success,
    (state, action): DomainsState => ({
      ...state,
      domains: action.payload,
      loading: {
        ...state.loading,
        getDomains: false,
      },
    })
  )
  .handleAction(
    actions.getDomains.failure,
    (state, action): DomainsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDomains: action.payload,
      },
      loading: {
        ...state.loading,
        getDomains: false,
      },
    })
  )


  // add domain
  .handleAction(
    actions.addDomain.request,
    (state, action): DomainsState => ({
      ...state,
      loading: {
        ...state.loading,
        addDomain: true,
      },
      errors: {
        ...state.errors,
        addDomain: undefined,
      },
    })
  )
  .handleAction(
    actions.addDomain.success,
    (state, action): DomainsState => ({
      ...state,
      domains: [...state.domains, action.payload],
      loading: {
        ...state.loading,
        addDomain: false,
      },
    })
  )
  .handleAction(
    actions.addDomain.failure,
    (state, action): DomainsState => ({
      ...state,
      errors: {
        ...state.errors,
        addDomain: action.payload,
      },
      loading: {
        ...state.loading,
        addDomain: false,
      },
    })
  )

  // update domain
  .handleAction(
    actions.updateDomain.request,
    (state, action): DomainsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateDomain: true,
      },
      errors: {
        ...state.errors,
        updateDomain: undefined,
      },
    })
  )
  .handleAction(
    actions.updateDomain.success,
    (state, action): DomainsState => ({
      ...state,
      domains: state.domains.map((domain) =>
        domain.id === action.payload.id ? action.payload : domain
      ),
      loading: {
        ...state.loading,
        updateDomain: false,
      },
    })
  )
  .handleAction(
    actions.updateDomain.failure,
    (state, action): DomainsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateDomain: action.payload,
      },
      loading: {
        ...state.loading,
        updateDomain: false,
      },
    })
  )
  // delete Domain
  .handleAction(
    actions.deleteDomain.request,
    (state, action): DomainsState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteDomain: true,
      },
      errors: {
        ...state.errors,
        deleteDomain: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteDomain.success,
    (state, action): DomainsState => ({
      ...state,
      domains: state.domains.filter((domain) => domain.id !== action.payload),
      loading: {
        ...state.loading,
        deleteDomain: false,
      },
    })
  )
  .handleAction(
    actions.deleteDomain.failure,
    (state, action): DomainsState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteDomain: action.payload,
      },
      loading: {
        ...state.loading,
        deleteDomain: false,
      },
    })
  )
  // domain upsert dialog
  .handleAction(
    actions.showDomainUpsertDialog,
    (state, action): DomainsState => ({
      ...state,
      showDomainUpsertDialog: true,
      selectedDomain: action.payload.domain,
      domainName: action.payload.domainName,
    })
  )
  .handleAction(
    actions.hideDomainUpsertDialog,
    (state, action): DomainsState => ({
      ...state,
      showDomainUpsertDialog: false,
      domainName: undefined,
      selectedDomain: undefined,
    })
  )
  //get domain by id
  .handleAction(
    actions.getDomainById.request,
    (state, action): DomainsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDomainById: true,
      },
      errors: {
        ...state.errors,
        getDomainById: undefined,
      },
    })
  )
  .handleAction(
    actions.getDomainById.success,
    (state, action): DomainsState => ({
      ...state,
      selectedDomain: action.payload,
      loading: {
        ...state.loading,
        getDomainById: false,
      },
    })
  )
  .handleAction(
    actions.getDomainById.failure,
    (state, action): DomainsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDomainById: action.payload,
      },
      loading: {
        ...state.loading,
        getDomainById: false,
      },
    })
  );
