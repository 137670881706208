import React, { useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
  NumericInput,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  hideActivityDialog,
  saveActivity,
  updateActivity,
} from "../../../../store/activities/actions";
import Select from "react-select";
import {
  ActivityScopeEnum,
  ActivityTagEnum,
  ActivityTypeEnum,
  AttachedResource,
} from "../../../../types";
import ActivityContentByType from "./ActivityContentByType";
import { ActivityRequest } from "../../../../api/activities/types";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../../helpers/toaster";
import { smallSelectorStyles } from "../../../common/selectors";

type OwnProps = {};

type Props = OwnProps;

const ActivityDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { show, activity } = useSelector(
    (s) => s.activities.dialogs.activityDialog
  );

  const [name, setName] = useState("");

  const handleActivityNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };

  const [scope, setScope] = useState<ActivityScopeEnum>(ActivityScopeEnum.ALL);

  const handleScopeChange = (scope?: ActivityScopeEnum) => {
    scope && setScope(scope);
  };

  const [type, setType] = useState<ActivityTypeEnum>(
    ActivityTypeEnum.SCI_WRITING
  );

  const handleTypeChange = (type?: ActivityTypeEnum) => {
    type && setType(type);
  };

  const [tag, setTag] = useState<ActivityTagEnum>(ActivityTagEnum.TEAM);

  const handleTagChange = (tag?: ActivityTagEnum) => {
    tag && setTag(tag);
  };

  const [orderIndex, setOrderIndex] = useState<number>();

  const handleOrderIndexChange = (value: number) => {
    setOrderIndex(value);
  };

  const [embedCode, setEmbedCode] = useState("");
  const [externalUrl, setExternalUrl] = useState("");

  const [pdfFile, setPdfFile] = useState<AttachedResource | undefined>();
  const [docxFile, setDocxFile] = useState<AttachedResource | undefined>();
  const [pptFile, setPptFile] = useState<AttachedResource | undefined>();

  const handleResourceLinkChange = (
    link: string,
    field: "external_url" | "embed_code"
  ) => {
    field === "external_url" ? setExternalUrl(link) : setEmbedCode(link);
  };

  const [htmlContent, setHtmlContent] = useState("");
  const [notesHtml, setNotesHtml] = useState("");

  const handleRichTextFieldChange = (
    html: string,
    field: "html_content" | "notes_placeholder_html"
  ) => {
    field === "html_content" ? setHtmlContent(html) : setNotesHtml(html);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body: ActivityRequest = {
      name: name,
      type: scope,
      activity_type: type,
      tag: tag,
      embed_code: embedCode,
      external_url: externalUrl,
      html_content: htmlContent,
      notes_placeholder_html: notesHtml,
      docx_resource: docxFile,
      pdf_resource: pdfFile,
      ppt_resource: pptFile,
      order_index: orderIndex,
    };
    if (!activity) {
      dispatch(saveActivity.request(body));
    } else {
      dispatch(updateActivity.request({ id: activity.id, body: body }));
    }
  };

  const handleClose = () => {
    dispatch(hideActivityDialog());
  };

  const handleOpen = () => {
    if (activity) {
      setName(activity.name ?? "");
      setScope(activity.type ?? ActivityScopeEnum.ALL);
      setType(activity.activity_type ?? ActivityTypeEnum.SCI_WRITING);
      setTag(activity.tag ?? ActivityTagEnum.TEAM);
      setOrderIndex(activity.order_index);
      setEmbedCode(activity.embed_code ?? "");
      setExternalUrl(activity.external_url ?? "");
      setHtmlContent(activity.html_content ?? "");
      setNotesHtml(activity.notes_placeholder_html ?? "");
      setPdfFile(activity?.pdf_resource);
      setDocxFile(activity?.docx_resource);
      setPptFile(activity?.ppt_resource);
    } else {
      setName("");
      setScope(ActivityScopeEnum.ALL);
      setType(ActivityTypeEnum.SCI_WRITING);
      setTag(ActivityTagEnum.TEAM);
      setOrderIndex(undefined);
      setEmbedCode("");
      setExternalUrl("");
      setHtmlContent("");
      setNotesHtml("");
      setPdfFile(undefined);
      setDocxFile(undefined);
      setPptFile(undefined);
    }
  };

  const loading = useSelector((s) => s.activities.loading.saveActivity);
  const updateLoading = useSelector((s) => s.activities.loading.updateActivity);
  const error = useSelector((s) => s.activities.errors.saveActivity);
  const updateError = useSelector((s) => s.activities.errors.updateActivity);

  const onSuccess = () => {
    AppToaster.show({
      message: intl.formatMessage(
        {
          id: `app.titles.${activity ? "update-" : ""}toaster-message`,
        },
        { name: name }
      ),
      icon: "tick",
      intent: "success",
    });
    handleClose();
  };

  useLoading({ loading, error, onSuccess: onSuccess });
  useLoading({
    loading: updateLoading,
    error: updateError,
    onSuccess: onSuccess,
  });

  const handleResourceChange = (
    type: "pdf" | "docx" | "ppt",
    resource?: AttachedResource
  ) => {
    if (type === "pdf") {
      setPdfFile(resource);
    } 
    else if(type === "ppt"){
      setPptFile(resource);
    }
    else {
      setDocxFile(resource);
    }
  };

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      onOpening={handleOpen}
      enforceFocus={false}
      icon={<Icon icon={activity ? "edit" : "new-object"} iconSize={24} />}
      title={intl.formatMessage(
        { id: "app.dialogs.activity-dialog.title" },
        { isUpdateDialog: !!activity }
      )}
      style={{ minWidth: "500px", width: "90%" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <form id="activity-form" onSubmit={handleFormSubmit}>
          <FormGroup
            label={intl.formatMessage({ id: "app.activity-form.name" })}
          >
            <InputGroup
              placeholder={`${intl.formatMessage({
                id: "app.activity-form.name",
              })}...`}
              value={name}
              onChange={handleActivityNameChange}
              required
            />
          </FormGroup>
          <div className="flex flex-wrap">
            <FormGroup
              label={intl.formatMessage({
                id: "app.activity-form.tag",
              })}
            >
              <Select
                value={{
                  label: intl.formatMessage(
                    { id: "app.titles.plan-workspaces" },
                    { workspace: tag }
                  ),
                  value: tag,
                }}
                options={Object.values(ActivityTagEnum).map((tag) => ({
                  label: intl.formatMessage(
                    { id: "app.titles.plan-workspaces" },
                    { workspace: tag }
                  ),
                  value: tag,
                }))}
                onChange={(v) => handleTagChange(v?.value)}
                className="selector-min-width mr-4"
                styles={smallSelectorStyles}
              />
            </FormGroup>
            <FormGroup
              label={intl.formatMessage({ id: "app.activity-form.scope" })}
            >
              <Select
                value={{
                  label: intl.formatMessage(
                    { id: "app.titles.activity-scope" },
                    { scope: scope }
                  ),
                  value: scope,
                }}
                options={Object.values(ActivityScopeEnum).map((scope) => ({
                  label: intl.formatMessage(
                    { id: "app.titles.activity-scope" },
                    { scope: scope }
                  ),
                  value: scope,
                }))}
                onChange={(v) => handleScopeChange(v?.value)}
                className="selector-min-width mr-4"
                styles={smallSelectorStyles}
              />
            </FormGroup>
            <FormGroup
              label={intl.formatMessage({
                id: "app.activity-form.activity-type",
              })}
            >
              <Select
                value={{
                  label: intl.formatMessage(
                    { id: "app.titles.activity-type" },
                    { type: type }
                  ),
                  value: type,
                }}
                options={Object.values(ActivityTypeEnum).map((type) => ({
                  label: intl.formatMessage(
                    { id: "app.titles.activity-type" },
                    { type: type }
                  ),
                  value: type,
                }))}
                onChange={(v) => handleTypeChange(v?.value)}
                className="selector-min-width mr-4"
                styles={smallSelectorStyles}
              />
            </FormGroup>
            <FormGroup
              label={intl.formatMessage({ id: "app.titles.order-index" })}
            >
              <NumericInput
                className="w-60"
                placeholder={`${intl.formatMessage({
                  id: "app.titles.order-index",
                })}...`}
                value={orderIndex}
                onValueChange={handleOrderIndexChange}
              />
            </FormGroup>
          </div>
          <ActivityContentByType
            embedCode={embedCode}
            externalUrl={externalUrl}
            htmlContent={htmlContent}
            notesPlaceholderHTML={notesHtml}
            pdfFile={pdfFile}
            docxFile={docxFile}
            pptFile={pptFile}
            activityType={type}
            onResourceLinkChange={handleResourceLinkChange}
            onRichTextFieldChange={handleRichTextFieldChange}
            onFileChange={handleResourceChange}
          />
        </form>
      </div>
      <div className={`${Classes.DIALOG_FOOTER} flex justify-between mt-4`}>
        <Button className="w-1/4" onClick={handleClose} type={"reset"}>
          {intl.formatMessage({ id: "app.titles.close" })}
        </Button>
        <Button
          form="activity-form"
          className="w-1/4"
          intent="primary"
          type={"submit"}
          loading={loading || updateLoading}
        >
          {intl.formatMessage({
            id: "app.titles.save",
          })}
        </Button>
      </div>
    </Dialog>
  );
};

export default ActivityDialog;
