import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { School } from "../../types";

type ActionName =
  | "getSchools"
  | "addSchool"
  | "updateSchool"
  | "deleteSchool"
  | "getSchoolsByDistrict"
  | "getSchoolsByDistricts";

export type SchoolsState = {
  schools: School[];
  selectedSchool?: School;

  schoolsUpsertDialog: {
    show?: boolean;
    selectedSchool?: School;
  };

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: SchoolsState = {
  schools: [],
  schoolsUpsertDialog: {},
  selectedSchool: undefined,
  loading: {},
  errors: {},
};

export const schoolsReducer = createReducer<SchoolsState, RootAction>(
  initialState
)
  //get schools
  .handleAction(
    actions.getSchools.request,
    (state, action): SchoolsState => ({
      ...state,
      loading: {
        ...state.loading,
        getSchools: true,
      },
      errors: {
        ...state.errors,
        getSchools: undefined,
      },
    })
  )
  .handleAction(
    actions.getSchools.success,
    (state, action): SchoolsState => ({
      ...state,
      schools: action.payload,
      loading: {
        ...state.loading,
        getSchools: false,
      },
    })
  )
  .handleAction(
    actions.getSchools.failure,
    (state, action): SchoolsState => ({
      ...state,
      errors: {
        ...state.errors,
        getSchools: action.payload,
      },
      loading: {
        ...state.loading,
        getSchools: false,
      },
    })
  )
  //get schools by district
  .handleAction(
    actions.getSchoolsByDistrict.request,
    (state, action): SchoolsState => ({
      ...state,
      loading: {
        ...state.loading,
        getSchoolsByDistrict: true,
      },
      errors: {
        ...state.errors,
        getSchoolsByDistrict: undefined,
      },
    })
  )
  .handleAction(
    actions.getSchoolsByDistrict.success,
    (state, action): SchoolsState => ({
      ...state,
      schools: action.payload,
      loading: {
        ...state.loading,
        getSchoolsByDistrict: false,
      },
    })
  )
  .handleAction(
    actions.getSchoolsByDistrict.failure,
    (state, action): SchoolsState => ({
      ...state,
      errors: {
        ...state.errors,
        getSchoolsByDistrict: action.payload,
      },
      loading: {
        ...state.loading,
        getSchoolsByDistrict: false,
      },
    })
  )
  //get schools by districts
  .handleAction(
    actions.getSchoolsByDistricts.request,
    (state, action): SchoolsState => ({
      ...state,
      loading: {
        ...state.loading,
        getSchoolsByDistricts: true,
      },
      errors: {
        ...state.errors,
        getSchoolsByDistricts: undefined,
      },
    })
  )
  .handleAction(
    actions.getSchoolsByDistricts.success,
    (state, action): SchoolsState => ({
      ...state,
      schools: action.payload,
      loading: {
        ...state.loading,
        getSchoolsByDistricts: false,
      },
    })
  )
  .handleAction(
    actions.getSchoolsByDistricts.failure,
    (state, action): SchoolsState => ({
      ...state,
      errors: {
        ...state.errors,
        getSchoolsByDistricts: action.payload,
      },
      loading: {
        ...state.loading,
        getSchoolsByDistricts: false,
      },
    })
  )
  //add school
  .handleAction(
    actions.addSchool.request,
    (state, action): SchoolsState => ({
      ...state,
      loading: {
        ...state.loading,
        addSchool: true,
      },
      errors: {
        ...state.errors,
        addSchool: undefined,
      },
    })
  )
  .handleAction(
    actions.addSchool.success,
    (state, action): SchoolsState => ({
      ...state,
      schools: [action.payload, ...(state.schools as School[])],
      loading: {
        ...state.loading,
        addSchool: false,
      },
    })
  )
  .handleAction(
    actions.addSchool.failure,
    (state, action): SchoolsState => ({
      ...state,
      errors: {
        ...state.errors,
        addSchool: action.payload,
      },
      loading: {
        ...state.loading,
        addSchool: false,
      },
    })
  )
  // update school
  .handleAction(
    actions.updateSchool.request,
    (state, action): SchoolsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateSchool: true,
      },
      errors: {
        ...state.errors,
        updateSchool: undefined,
      },
    })
  )
  .handleAction(
    actions.updateSchool.success,
    (state, action): SchoolsState => ({
      ...state,
      schools: (state.schools as School[]).map((school) =>
        school.id === action.payload.id ? action.payload : school
      ),
      selectedSchool:
        state.selectedSchool && state.selectedSchool.id === action.payload.id
          ? action.payload
          : state.selectedSchool,
      loading: {
        ...state.loading,
        updateSchool: false,
      },
    })
  )
  .handleAction(
    actions.updateSchool.failure,
    (state, action): SchoolsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateSchool: action.payload,
      },
      loading: {
        ...state.loading,
        updateSchool: false,
      },
    })
  )
  // delete school
  .handleAction(
    actions.deleteSchool.request,
    (state, action): SchoolsState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteSchool: true,
      },
      errors: {
        ...state.errors,
        deleteSchool: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteSchool.success,
    (state, action): SchoolsState => ({
      ...state,
      schools: (state.schools as School[]).filter(
        (school) => school.id !== action.payload
      ),
      selectedSchool:
        state.selectedSchool && state.selectedSchool.id === action.payload
          ? undefined
          : state.selectedSchool,
      loading: {
        ...state.loading,
        deleteSchool: false,
      },
    })
  )
  .handleAction(
    actions.deleteSchool.failure,
    (state, action): SchoolsState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteSchool: action.payload,
      },
      loading: {
        ...state.loading,
        deleteSchool: false,
      },
    })
  )
  // set selected school
  .handleAction(
    actions.setSelectedSchool,
    (state, action): SchoolsState => ({
      ...state,
      selectedSchool: action.payload,
    })
  )
  // upsert school dialog
  .handleAction(
    actions.showUpsertSchoolDialog,
    (state, action): SchoolsState => ({
      ...state,
      schoolsUpsertDialog: {
        show: true,
        selectedSchool: action.payload,
      },
    })
  )
  .handleAction(
    actions.hideUpsertSchoolDialog,
    (state, action): SchoolsState => ({
      ...state,
      schoolsUpsertDialog: {},
    })
  );
