import { useDispatch, useSelector } from "react-redux";
import { getActivityByName } from "../../store/activities/actions";
import { useLoading } from "./useLoading";
import { Activity } from "../../types";
import { AppToaster } from "../toaster";
import React from "react";
import { useIntl } from "react-intl";

export const ACTIVITY_LINK_CLASSNAME = "activity-link";
export const ACTIVITY_BLUE_LINK_CLASSNAME = "activity-blue-link";

export const useAttachActivities = ({
  onClick,
  onError,
}: {
  onClick?: (activity: Activity, calenderAction?: Boolean) => any;
  onError?: (activityName: string) => any;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectedActivity = useSelector(
    (s) => s.notes.dialogs?.notesDialog?.selectedActivity
  );

  const loading = useSelector((s) => s.activities.loading.getActivityByName);
  const error = useSelector((s) => s.activities.errors.getActivityByName);
  useLoading({ loading, error });

  const showError = (activityName: string) => {
    AppToaster.show({
      intent: "warning",
      message: (
        <p className="font-bold">
          {intl.formatMessage(
            { id: "app.activities.not-found" },
            { activity: activityName }
          )}
        </p>
      ),
    });
    onError && onError(activityName);
  };

  const onActivityCheck = (activityName: string, checkExisting?: Boolean) => {
    dispatch(
      getActivityByName.request({
        name: activityName,
        onComplete: (activity?: Activity) => {
          if (activity && onClick) {
            onClick(activity, checkExisting);
          } else {
            showError(activityName);
          }
        },
      })
    );
  };



  const onActivityClick = (name?: string) => (e: any) => {
    if (
      e.target.className?.includes(ACTIVITY_LINK_CLASSNAME) ||
      e.target.parentElement?.className.includes(ACTIVITY_LINK_CLASSNAME)
    ) {
       const activityName = name ?? e.target.innerText;
      selectedActivity?.name !== activityName && onActivityCheck(activityName);
    }
  };

  return {
    onActivityClick,
    onActivityCheck,
  };
};
