import React from "react";
import { Domain } from "../../../../types";
import { Card } from "@blueprintjs/core";
import { generatePath, useHistory } from "react-router";
import useReportParams from "../../../../helpers/hooks/useReportParams";
import NegativeDomainIcon from "../../cards/NegativeDomainIcon";
import useHighlightedText from "../../../../helpers/hooks/useHighlightedText";

type OwnProps = {
  domain: Domain;
  manageBookmarkDialog?: boolean;
};

type Props = OwnProps;

const ReportTableDomainItem: React.FC<Props> = (props: Props) => {
  const { domain, manageBookmarkDialog } = props;

  const history = useHistory();

  const { reportPagePath, routeParams } = useReportParams();

  const handleDomainClick = () => {
    const { tagId, ...params } = routeParams;
    history.push(
      generatePath(reportPagePath, {
        ...params,
        domainId: domain.id,
        // only for plan
        workspace: "report",
      } as any)
    );
  };

  const { highlightedText } = useHighlightedText({
    text: domain?.name,
  });

  return (
    <Card
      className={
        !manageBookmarkDialog
          ? "bg-white flex-grow text-xl text-center border rounded p-4 cursor-pointer relative"
          : "bg-white flex-grow text-xl text-center border rounded p-4 cursor-default relative"
      }
      style={{ maxWidth: "25rem" }}
      onClick={!manageBookmarkDialog ? handleDomainClick : () => {}}
      data-tour={"domain-tour"}
      interactive={!manageBookmarkDialog}
    >
      {domain?.domain_answer.is_negative && (
        <NegativeDomainIcon
          forCard
          targetClassName={"float-right -mt-4 -mr-3"}
        />
      )}
      <strong dangerouslySetInnerHTML={{ __html: highlightedText }} />
    </Card>
  );
};

export default ReportTableDomainItem;
