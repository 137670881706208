import React, { FunctionComponent } from "react";
import { Tooltip } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { LockType } from "../../../types";

type OwnProps = {
  lockType?: LockType;
  disabled?: boolean;
};

type Props = OwnProps;

const LockPopover: FunctionComponent<Props> = (props) => {
  const { lockType, disabled = !lockType } = props;

  const intl = useIntl();

  return (
    <Tooltip
      interactionKind="hover"
      popoverClassName="w-64"
      content={intl.formatMessage({
        id:
          lockType === "district"
            ? "app.district-setup.workspace-blocked"
            : "app.district-setup.not-ready-for-step",
      })}
      disabled={disabled}
    >
      {props.children}
    </Tooltip>
  );
};

export default LockPopover;
