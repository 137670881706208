import React, { FunctionComponent } from "react";
import { Dialog, Icon } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { hideCreateDatasetFromFileDialog } from "../../../../store/data-sets/actions";
import CreateDatasetFromFileDialogContent from "./CreateDatasetFromFileDialogContent";

type OwnProps = {};

type Props = OwnProps;

const CreateDatasetFromFileDialog: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { show, parseResult } = useSelector(
    (s) => s.datasets.dialogs.createDatasetFromFileDialog
  );

  const handleClose = () => {
    dispatch(hideCreateDatasetFromFileDialog());
  };

  return (
    <Dialog
      enforceFocus={false}
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon={"database"} iconSize={24} />}
      title={intl.formatMessage({
        id: "app.datasets.create-custom-dataset",
      })}
    >
      {parseResult && (
        <CreateDatasetFromFileDialogContent
          parseResult={parseResult}
          onClose={handleClose}
        />
      )}
    </Dialog>
  );
};

export default CreateDatasetFromFileDialog;
