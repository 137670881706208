import React, { useMemo } from "react";
import {
  LookForItem,
  LookForItemStatus,
  LookForItemStatusEnum,
  RecurringStatusEnum,
} from "../../../../../types";
import { useIntl } from "react-intl";
import { Button, Card, NonIdealState } from "@blueprintjs/core";
import LookForElement from "./LookForElement";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../../../store/plans/selectors";

type OwnProps = {
  lookForStatuses: Omit<
    LookForItemStatus<Omit<LookForItem, "id" | "created_at" | "updated_at">>,
    "id" | "created_at" | "updated_at"
  >[];
  setLookForStatuses: React.Dispatch<
    React.SetStateAction<
      Omit<
        LookForItemStatus<
          Omit<LookForItem, "id" | "created_at" | "updated_at">
        >,
        "id" | "created_at" | "updated_at"
      >[]
    >
  >;
};

type Props = OwnProps;

const LookForsStatusesTab: React.FC<Props> = (props: Props) => {
  const { lookForStatuses, setLookForStatuses } = props;
  const isActiveDistrictPlan = useSelector(isDistrictPlan);

  const intl = useIntl();

  const handleLookForAdd = () => {
    setLookForStatuses((lookForStatuses) => [
      ...lookForStatuses,
      {
        item: { text: "", original: false,school_item: isActiveDistrictPlan ? false : true, },
        status: LookForItemStatusEnum.NotPresent,
        recurring_status: RecurringStatusEnum.NotRecurring,
      },
    ]);
  };

  const handleLookForRemove = (index: number) => () => {
    setLookForStatuses((lookForStatuses) =>
      lookForStatuses.filter((lookForStatus, i) => i !== index)
    );
  };

  const handleLookForChange = <T extends string | RecurringStatusEnum>(
    index: number
  ) => (key: keyof LookForItem | keyof LookForItemStatus, value: T) => {
    setLookForStatuses((lookForStatus) =>
      lookForStatus.map((lookForStatus, i) =>
        i === index
          ? key === "text"
            ? {
                ...lookForStatus,
                item: { ...lookForStatus.item, [key]: value },
              }
            : { ...lookForStatus, [key]: value }
          : { ...lookForStatus }
      )
    );
  };

  const newItemButton = useMemo(
    () => (
      <Button
        icon="add"
        className="mb-4"
        onClick={handleLookForAdd}
        intent="primary"
      >
        {intl.formatMessage({ id: "app.strategies.look-fors.add-new-item" })}
      </Button>
    ),
    [handleLookForAdd]
  );

  return (
    <div>
      {lookForStatuses.length ? (
        <>
          {newItemButton}
          <form className="space-y-2">
            {lookForStatuses.map((lookForStatus, index) => (
              <Card
                key={index}
                className="p-2 flex items-center justify-between"
              >
                <LookForElement
                  lookForStatus={lookForStatus}
                  onLookForStatusFieldUpdate={handleLookForChange(index)}
                  onLookForRemove={handleLookForRemove(index)}
                />
              </Card>
            ))}
          </form>
        </>
      ) : (
        <NonIdealState
          icon="add-to-artifact"
          title={intl.formatMessage({ id: "app.strategies.look-fors.empty" })}
          description={intl.formatMessage({
            id: "app.non-ideal-state.let's-get-started",
          })}
          action={newItemButton}
        />
      )}
    </div>
  );
};

export default LookForsStatusesTab;
