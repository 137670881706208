import { Button, ButtonGroup } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { SurveyDemographic } from "../../../../types";

type OwnProps = {
  handleDemographicsFilterChange: (demographic: SurveyDemographic) => void;
  surveyDepsDemographics: SurveyDemographic[][];
  filteredDemographics: SurveyDemographic[];
};

type Props = OwnProps;

const DistrictComaprisonHeaderFilter: React.FC<Props> = (props) => {
  const MINIMUM_FILTER_APPLIED = 1;
  const intl = useIntl();
  const {
    surveyDepsDemographics,
    handleDemographicsFilterChange,
    filteredDemographics,
  } = props;

  const uniqueDemographics = useMemo(() => {
    let uniqueDemographics: SurveyDemographic[] = [];
    surveyDepsDemographics.forEach((item: SurveyDemographic[]) =>
      item.forEach((d: SurveyDemographic) => {
        if (uniqueDemographics.indexOf(d) === -1) {
          uniqueDemographics.push(d);
        }
      })
    );
    return uniqueDemographics;
  }, [surveyDepsDemographics]);

  const disableFilter = (element: SurveyDemographic) => {
    if (filteredDemographics.indexOf(element) === -1) {
      return (
        uniqueDemographics.length - filteredDemographics.length <=
        MINIMUM_FILTER_APPLIED
      );
    }
    return false;
  };
  return (
    <ButtonGroup large className="flex-wrap">
      {uniqueDemographics.map((element: SurveyDemographic, i: number) => (
        <Button
          key={i}
          rightIcon={
            !filteredDemographics.includes(element) ? "tick" : undefined
          }
          disabled={disableFilter(element)}
          text={intl.formatMessage({
            id: `app.filters.respondent-type.distric-comparison.${element}`,
          })}
          title={intl.formatMessage({
            id: `app.filters.respondent-type.distric-comparison.${element}`,
          })}
          onClick={() => handleDemographicsFilterChange(element)}
        />
      ))}
    </ButtonGroup>
  );
};

export default DistrictComaprisonHeaderFilter;
