import React, {
  CSSProperties,
  FunctionComponent,
  useMemo,
  useState,
} from "react";
import { Button, Card, Divider } from "@blueprintjs/core";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";
import _ from "lodash";
import BookmarkNumIcon from "./BookmarkNumIcon";
import { BookmarkType, Domain, SurveyDemographic } from "../../../../types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  changeHoveredDomainId,
  showItemLevelInfoAndBookmarksDialog,
} from "../../../../store/needs/actions";
import useDomainPriority from "./useDomainPriority";
import NegativeDomainIcon from "../../../surveys/cards/NegativeDomainIcon";
import { POTENTIAL_PRIORITY_COLOR } from "../item-level-info-and-bookmarks-dialog/useBookmarksLevelInfo";
import useDomainLevelBookmarkCounter from "../../../../helpers/hooks/bookmarks/useDomainLevelBookmarkCounter";
import { DEFAULT_PRECISION } from "../../../../constants/constants";
import DomainEyeIcon from "./DomainEyeIcon";

type OwnProps = {
  /**
   * domain.id is used to match an existing DomainPriority when updating or
   * creating a new one, as well as for highlighting functionality.
   * domain.color_hex is used for the background color of the pill
   * that contains the meanScore
   */
  domain: Domain;
  /**
   * Used to match an existing DomainPriority when updating or creating a new one
   */
  demographic: SurveyDemographic;
  meanScore?: number;
  /**
   * Used to match an existing DomainPriority when updating or creating a new one
   */
  surveyDeploymentId?: number;
  /**
   * Has the highest priority when calculating opacity in highlighting functionality
   */
  hiddenOnDemographicLevel?: boolean;
  showRedBorder?: boolean;
  isPriorityConfirmed?: boolean;
};

type Props = OwnProps;

const CARD_HOVER_EFFECT_TIMEOUT = 500;

/**
 * When hovering over the title of the card displays a button that disables
 * opacity change of the card when highlighting other cards with other
 * domain.id except hiddenOnDemographicLevel=true
 */
const DomainComparisonCard: FunctionComponent<Props> = (props) => {
  const {
    domain,
    demographic,
    meanScore,
    surveyDeploymentId,
    hiddenOnDemographicLevel,
    showRedBorder,
    isPriorityConfirmed,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const scoreTitle = useMemo(() => {
    return meanScore != null
      ? _.round(meanScore, DEFAULT_PRECISION).toFixed(2)
      : intl.formatMessage({
          id: "app.titles.not-applicable",
        });
  }, [meanScore]);

  const colorBasedStyles = useMemo((): CSSProperties => {
    return {
      background: domain.color_hex,
      color: domain.color_hex
        ? pickTextColorBasedOnBgColor(domain.color_hex)
        : undefined,
    };
  }, [domain.color_hex]);

  const { marksCountByDemographic } = useDomainLevelBookmarkCounter({
    domainId: domain.id,
  });

  const marksCount = useMemo(() => marksCountByDemographic[demographic] ?? {}, [
    marksCountByDemographic,
    demographic,
  ]);

  const {
    loading: loading,
    archived: archived,
    prioritized: prioritized,
    onArchiveClick,
    onPotentialPriorityClick,
  } = useDomainPriority({
    domainId: domain.id,
    surveyDeploymentId: surveyDeploymentId,
    demographic: demographic,
  });

  const { hoveredDomainId, lockedDomainsIds } = useSelector(
    (s) => s.needs.hovered
  );

  const [delayHandler, setDelayHandler] = useState<any>(null);

  const handleCardHeaderEnter = () => {
    setDelayHandler(
      setTimeout(() => {
        dispatch(changeHoveredDomainId(domain.id));
      }, CARD_HOVER_EFFECT_TIMEOUT)
    );
  };

  const handleCardHeaderLeave = () => {
    clearTimeout(delayHandler);
    dispatch(changeHoveredDomainId(undefined));
  };

  const { locked, hasAnotherLocks } = useMemo(() => {
    return {
      locked: lockedDomainsIds[domain.id],
      hasAnotherLocks: _.chain(lockedDomainsIds)
        .omit(domain.id)
        .values()
        .some((v) => v)
        .value(),
    };
  }, [lockedDomainsIds, domain.id]);

  const { cardClassname, headerClassname } = useMemo(() => {
    let cardClassname = "domain-comparison-card ";
    let headerClassname = "domain-comparison-card__header";
    if (archived) {
      cardClassname += " card-disabled"; // " grayscale-filter";
      headerClassname += " card-header-disabled cursor-pointer";
    } else {
      headerClassname += " cursor-pointer";
    }

    if (hiddenOnDemographicLevel) {
      cardClassname += " opacity-25";
    } else {
      if (!locked) {
        if (hoveredDomainId) {
          if (hoveredDomainId !== domain.id) {
            cardClassname += " opacity-25";
          }
        } else {
          if (hasAnotherLocks) {
            cardClassname += " opacity-25";
          }
        }
      }
    }
    if (showRedBorder && !prioritized && !archived)
      cardClassname += " border-danger-needs ";
    return { cardClassname, headerClassname };
  }, [
    archived,
    hoveredDomainId,
    domain.id,
    demographic,
    locked,
    hasAnotherLocks,
    hiddenOnDemographicLevel,
    showRedBorder,
  ]);

  const handleCardClick = () => {
    // if (!archived) {
    dispatch(
      showItemLevelInfoAndBookmarksDialog({
        domain: domain,
        demographic: demographic,
        meanScore: meanScore,
        marksCount: marksCount,
      })
    );
    // }
  };

  return (
    <Card className={cardClassname}>
      <div
        onClick={handleCardClick}
        onMouseEnter={handleCardHeaderEnter}
        onMouseLeave={handleCardHeaderLeave}
        className={headerClassname}
        style={{
          background: "#eaeaea",
        }}
      >
        <div className="flex items-center gap-2">
          <DomainEyeIcon locked={locked} domainId={domain.id} />
          {domain?.domain_answer.is_negative && <NegativeDomainIcon forCard />}
          <div>{domain.name}</div>
        </div>
        <div
          className={
            archived
              ? "text-xl text-center rounded px-2 grayscale-filter"
              : "text-xl text-center rounded px-2"
          }
          style={{
            ...colorBasedStyles,
            minWidth: "3.5rem",
          }}
        >
          {scoreTitle}
        </div>
      </div>
      <div className="flex items-center px-4 gap-2 py-1">
        <div className={archived ? "flex grayscale-filter" : "flex"}>
          <BookmarkNumIcon
            count={marksCount[BookmarkType.Positive]}
            bookmarkType={BookmarkType.Positive}
          />
          <BookmarkNumIcon
            count={marksCount[BookmarkType.Negative]}
            bookmarkType={BookmarkType.Negative}
          />
        </div>
        <Divider className="self-stretch my-0" />

        <div className="domain-priority-actions">
          <Button
            intent={archived ? "primary" : undefined}
            disabled={isPriorityConfirmed}
            className={archived ? "disabled-btn" : ""}
            loading={loading}
            text={intl.formatMessage({
              id: archived ? "app.titles.unarchive" : "app.titles.archive",
            })}
            onClick={onArchiveClick}
          />
          <Button
            className="potential-priority-btn"
            disabled={archived || isPriorityConfirmed}
            loading={loading}
            style={
              prioritized && !archived
                ? {
                    fontWeight: "bold",
                    background: POTENTIAL_PRIORITY_COLOR,
                  }
                : undefined
            }
            text={intl.formatMessage({
              id: "app.titles.potential-priority",
            })}
            onClick={onPotentialPriorityClick}
          />
        </div>
      </div>
    </Card>
  );
};

export default DomainComparisonCard;
