import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { DatasetMark } from "../../types";

type ActionName =
  | "getDatasetMarks"
  | "flagDatasetMark"
  | "updateDatasetMark"
  | "discardDatasetMark"
  | "upsertDatasetWithDatasetMark";

export type DatasetMarksState = {
  datasetMarks: DatasetMark[];

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: DatasetMarksState = {
  datasetMarks: [],
  loading: {},
  errors: {},
};

export const datasetMarksReducer = createReducer<DatasetMarksState, RootAction>(
  initialState
)
  // get dataset marks
  .handleAction(
    actions.getDatasetMarks.request,
    (state, action): DatasetMarksState => ({
      ...state,
      loading: {
        ...state.loading,
        getDatasetMarks: true,
      },
      errors: {
        ...state.errors,
        getDatasetMarks: undefined,
      },
    })
  )
  .handleAction(
    actions.getDatasetMarks.success,
    (state, action): DatasetMarksState => {
      return {
      ...state,
      datasetMarks: action.payload,
      loading: {
        ...state.loading,
        getDatasetMarks: false,
      }
    }}
  )
  .handleAction(
    actions.getDatasetMarks.failure,
    (state, action): DatasetMarksState => ({
      ...state,
      errors: {
        ...state.errors,
        getDatasetMarks: action.payload,
      },
      loading: {
        ...state.loading,
        getDatasetMarks: false,
      },
    })
  )
  // flag dataset mark
  .handleAction(
    actions.flagDatasetMark.request,
    (state, action): DatasetMarksState => ({
      ...state,
      loading: {
        ...state.loading,
        flagDatasetMark: true,
      },
      errors: {
        ...state.errors,
        flagDatasetMark: undefined,
      },
    })
  )
  .handleAction(
    actions.flagDatasetMark.success,
    (state, action): DatasetMarksState => ({
      ...state,
      datasetMarks: [...state.datasetMarks, action.payload],
      loading: {
        ...state.loading,
        flagDatasetMark: false,
      },
    })
  )
  .handleAction(
    actions.flagDatasetMark.failure,
    (state, action): DatasetMarksState => ({
      ...state,
      errors: {
        ...state.errors,
        flagDatasetMark: action.payload,
      },
      loading: {
        ...state.loading,
        flagDatasetMark: false,
      },
    })
  )
  // update dataset mark
  .handleAction(
    actions.updateDatasetMark.request,
    (state, action): DatasetMarksState => ({
      ...state,
      loading: {
        ...state.loading,
        updateDatasetMark: true,
      },
      errors: {
        ...state.errors,
        updateDatasetMark: undefined,
      },
    })
  )
  .handleAction(
    actions.updateDatasetMark.success,
    (state, action): DatasetMarksState => ({
      ...state,
      datasetMarks: state.datasetMarks.map((dm) =>
        dm.id === action.payload.id ? action.payload : dm
      ),
      loading: {
        ...state.loading,
        updateDatasetMark: false,
      },
    })
  )
  .handleAction(
    actions.updateDatasetMark.failure,
    (state, action): DatasetMarksState => ({
      ...state,
      errors: {
        ...state.errors,
        updateDatasetMark: action.payload,
      },
      loading: {
        ...state.loading,
        updateDatasetMark: false,
      },
    })
  )
  // discard dataset mark
  .handleAction(
    actions.discardDatasetMark.request,
    (state, action): DatasetMarksState => ({
      ...state,
      loading: {
        ...state.loading,
        discardDatasetMark: true,
      },
      errors: {
        ...state.errors,
        discardDatasetMark: undefined,
      },
    })
  )
  .handleAction(
    actions.discardDatasetMark.success,
    (state, action): DatasetMarksState => ({
      ...state,
      datasetMarks: state.datasetMarks.filter(
        (dMark) => dMark.id !== action.payload
      ),
      loading: {
        ...state.loading,
        discardDatasetMark: false,
      },
    })
  )
  .handleAction(
    actions.discardDatasetMark.failure,
    (state, action): DatasetMarksState => ({
      ...state,
      errors: {
        ...state.errors,
        discardDatasetMark: action.payload,
      },
      loading: {
        ...state.loading,
        discardDatasetMark: false,
      },
    })
  )
  // upsert dataset with dataset mark
  .handleAction(
    actions.upsertDatasetWithDatasetMark.request,
    (state, action): DatasetMarksState => ({
      ...state,
      loading: {
        ...state.loading,
        upsertDatasetWithDatasetMark: true,
      },
      errors: {
        ...state.errors,
        upsertDatasetWithDatasetMark: undefined,
      },
    })
  )
  .handleAction(
    actions.upsertDatasetWithDatasetMark.success,
    (state, action): DatasetMarksState => {
      const isUpdate = state.datasetMarks.some(
        (dM) => dM.id === action.payload.id
      );
      const datasetMarks = isUpdate
        ? state.datasetMarks.map((dM) =>
            dM.id === action.payload.id ? action.payload : dM
          )
        : [...state.datasetMarks, action.payload];

      return {
        ...state,
        datasetMarks: datasetMarks,
        loading: {
          ...state.loading,
          upsertDatasetWithDatasetMark: false,
        },
      };
    }
  )
  .handleAction(
    actions.upsertDatasetWithDatasetMark.failure,
    (state, action): DatasetMarksState => ({
      ...state,
      errors: {
        ...state.errors,
        upsertDatasetWithDatasetMark: action.payload,
      },
      loading: {
        ...state.loading,
        upsertDatasetWithDatasetMark: false,
      },
    })
  );

export default datasetMarksReducer;
