import React, { useMemo } from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";

type OwnProps = {
  notEnoughData?: boolean;
  meanScore?: number;
  responsesCount?: number;
  questionText?: string;
  hasCustomElement?: boolean;
  popoverOpen?: boolean;
  setPopoverOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  domainColor?: string;
  isDeleted?: boolean;
};

type Props = OwnProps;

const ComparisonDistributionItem: React.FC<Props> = ({
  notEnoughData,
  meanScore,
  responsesCount,
  questionText,
  hasCustomElement,
  popoverOpen,
  setPopoverOpen,
  domainColor = "#48AFF0",
  isDeleted,
}: Props) => {
  const intl = useIntl();

  const { bgColor, textColor } = useMemo(() => {
    return {
      bgColor: domainColor,
      textColor: pickTextColorBasedOnBgColor(domainColor),
    };
  }, [domainColor]);

  const content = useMemo(
    () => (
      <div
        className="flex items-center justify-center rounded-md px-2"
        style={{
          width: "8rem",
          height: "5rem",
          backgroundColor: bgColor,
        }}
        onMouseEnter={() => !!setPopoverOpen && setPopoverOpen(true)}
        onMouseLeave={() => !!setPopoverOpen && setPopoverOpen(false)}
      >
        <p
          className="font-bold text-2xl"
          style={{
            color: textColor,
          }}
        >
          {meanScore?.toFixed(2)}
        </p>
      </div>
    ),
    [meanScore, popoverOpen, notEnoughData]
  );

  const withTooltip = useMemo(
    () => (
      <Tooltip
        content={
          <>
            {questionText}
            {isDeleted && (
              <>
                {" "}
                <span className="blue-sq">&#9830;</span>
              </>
            )}
          </>
        }
        position={Position.BOTTOM}
        className="flex justify-center"
        popoverClassName="min-w-12 w-12 text-center"
        isOpen={popoverOpen ?? false}
      >
        {content}
      </Tooltip>
    ),
    [content, popoverOpen]
  );

  if (notEnoughData) {
    return (
      <span className="text-center">
        {intl.formatMessage({
          id: "app.non-ideal-state.survey-reports.not-enough-respondents",
        })}
      </span>
    );
  }

  return (
    <div className="flex flex-col justify-center" style={{ maxWidth: "10rem" }}>
      {!!hasCustomElement ? (
        <>{withTooltip}</>
      ) : (
        <div className="flex justify-center">{content}</div>
      )}
      <p className={"flex justify-center"}>
        {responsesCount}&nbsp;
        {intl.formatMessage({
          id: "app.survey-report.comparison.responses",
        })}
      </p>
    </div>
  );
};

export default ComparisonDistributionItem;
