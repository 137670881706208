import { createAction } from "typesafe-actions";
import {
  AuthenticatedUserModel,
  DistrictSetupTaskKey,
  Plan,
} from "../../types";

export const markStepCompleted = createAction(
  "@district-setup/MARK_STEP_COMPLETED"
)<{
  key: DistrictSetupTaskKey;
  schoolId?: number;
  softAdd?: boolean;
}>();

export const markStepsCompleted = createAction(
  "@district-setup/MARK_STEPS_COMPLETED"
)<{
  districtId?: number;
  keys: DistrictSetupTaskKey[];
}>();

export const initDistrictSetupState = createAction(
  "@district-setup/INIT_DISTRICT_SETUP_STATE"
)<{ plan: Plan; me: AuthenticatedUserModel }>();

export const hideDistrictSetupWidget = createAction(
  "@district-setup/HIDE_DISTRICT_SETUP_WIDGET"
)<void>();

export const selectDistrictSetupTaskKey = createAction(
  "@tours/SELECT_DISTRICT_SETUP_TASK_KEY"
)<DistrictSetupTaskKey | undefined>();

export const updateMakingProgressTourTimeWatched = createAction(
  "@tours/UPDATE_MAKING_PROGRESS_TOUR_TIME_WATCHED"
)<Date>();

export const completeSetup = createAction("@tours/COMPLETE_SETUP")<void>();

export const showWorkspaceTour = createAction(
  "@tours/SHOW_WORKSPACE_TOUR"
)<void>();

export const hideWorkspaceTour = createAction(
  "@tours/HIDE_WORKSPACE_TOUR"
)<void>();

export const hideTourThisWorkspaceTour = createAction(
  "@actions/HIDE_TOUR_THIS_WORKSPACE_TOUR"
)<void>();
