import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Select, { ActionMeta, ValueType } from "react-select";
import { SelectorOptionType } from "../../../types";
import { getDistricts } from "../../../store/districts/actions";

type OwnProps = {
  disabled?: boolean;
  districtIds?: number[];
  onDistrictChange: (selectedDistricts: number[]) => any;
};

type Props = OwnProps;

const MultiDistrictSelector: React.FC<Props> = (props: Props) => {
  const { districtIds, onDistrictChange, disabled } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const loading = useSelector((s) => s.districts.loading.getDistricts);
  const districts = useSelector((s) => s.districts.districts);
  const districtsOptions: SelectorOptionType[] = useMemo(
    () => districts.map((s) => ({ value: s.id, label: s.name })),
    [districts]
  );

  useEffect(() => {
    dispatch(getDistricts.request());
  }, []);

  const handleDistrictChange = (
    value: ValueType<SelectorOptionType, boolean>,
    actionMeta: ActionMeta<SelectorOptionType>
  ) => {
    onDistrictChange(
      (value as SelectorOptionType[])?.map((item) => item.value) ?? []
    );
  };

  const displayNoOptions = useCallback(() => {
    return intl.formatMessage({ id: "app.titles.no-options" });
  }, []);

  return (
    <Select
      value={districtsOptions.filter((item) =>
        districtIds?.includes(item.value)
      )}
      isDisabled={disabled}
      isLoading={loading}
      options={districtsOptions}
      placeholder={intl.formatMessage({
        id: "app.forms.survey-deployment-form.districts-selector.placeholder",
      })}
      noOptionsMessage={displayNoOptions}
      isMulti
      onChange={handleDistrictChange}
    />
  );
};

export default MultiDistrictSelector;
