import React, { useMemo } from "react";
import { Position, Tooltip } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";
import { Popover } from "@blueprintjs/core";

type OwnProps = {
  notEnoughData?: boolean;
  meanScore?: number;
  responsesCount?: number;
  color?: string;
  popoverDisable?: boolean;
  domainTitle?: string;
};

type Props = OwnProps;

const ComparisonDistributionItem: React.FC<Props> = ({
  notEnoughData,
  meanScore,
  responsesCount,
  color,
  popoverDisable,
  domainTitle,
}: Props) => {
  const intl = useIntl();

  const content = useMemo(
    () => (
      <Popover
        interactionKind="hover"
        disabled={!meanScore || popoverDisable}
        content={
          <div className="p-3 text-center">
            {domainTitle && <h4>{domainTitle}</h4>}
            <b>
              {responsesCount && <span>{responsesCount}</span>}
              {intl.formatMessage({
                id:
                  "app.district-comparison-table.respondents-domain-number.popover-containt",
              })}
            </b>
          </div>
        }
      >
        <div
          className="flex items-center justify-center rounded-md px-2"
          style={{
            width: "5rem",
            height: "3rem",
            backgroundColor: meanScore ? color : "",
          }}
        >
          <p
            className="font-bold text-xl"
            style={{
              color: pickTextColorBasedOnBgColor(
                color ?? "#48AFF0",
                "#ffffff",
                "#000000"
              ),
            }}
          >
            {meanScore?.toFixed(2)}
          </p>
        </div>
      </Popover>
    ),
    [meanScore, notEnoughData]
  );

  if (notEnoughData) {
    return (
      <span className="text-center">
        {intl.formatMessage({
          id: "app.non-ideal-state.survey-reports.not-enough-respondents",
        })}
      </span>
    );
  }

  return (
    <div className="flex flex-col justify-center" style={{ maxWidth: "10rem" }}>
      <div className="flex justify-center">{content}</div>
    </div>
  );
};

export default ComparisonDistributionItem;
