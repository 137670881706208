import { Callout, Spinner } from "@blueprintjs/core";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { getGoalsByPlan } from "../../../../store/goals/actions";
import { getPlanStrategies } from "../../../../store/plan-strategies/actions";
import GoalItemCard from "../../goals/goal-item/GoalItemCard";
import StrategyItem from "../../strategies/add-plan-strategy-dialog/StrategyItem";

type OwnProps = {};

type Props = OwnProps;

const StrategicPlanWidgetBody: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const goals = useSelector((s) => s.goals.goals);
  const goalsLoading = useSelector((s) => s.goals.loading.getGoals);
  const goalsError = useSelector((s) => s.goals.errors.getGoals);
  useLoading({ loading: goalsLoading, error: goalsError });

  const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
  const strategiesLoading = useSelector(
    (s) => s.planStrategies.loading.getPlanStrategies
  );
  const strategiesError = useSelector(
    (s) => s.planStrategies.errors.getPlanStrategies
  );
  useLoading({ loading: strategiesLoading, error: strategiesError });

  const getPlanStrategiesRequest = () => {
    if (activePlanId) {
      dispatch(getPlanStrategies.request(activePlanId));
      dispatch(getGoalsByPlan.request(activePlanId));
    }
  };

  const displayedPlanStrategies = useMemo(() => {
    if (planStrategies?.length) {
      if (goals?.length) {
        const usedStrategies = goals.flatMap((goal) =>
          goal.plan_strategies.map((ps) => ps.id)
        );
        return planStrategies.filter(
          (ps) => !usedStrategies.some((id) => id === ps.id)
        );
      }
      return planStrategies;
    }
    return [];
  }, [planStrategies, goals]);

  useEffect(() => {
    getPlanStrategiesRequest();
  }, [activePlanId]);

  if (goalsLoading || strategiesLoading) {
    return (
      <div className="flex-1">
        <Spinner intent={"primary"} />
      </div>
    );
  }

  if (!goals?.length && !displayedPlanStrategies.length) {
    return (
      <Callout>
        {intl.formatMessage({ id: "app.strategic-plan.no-data" })}
      </Callout>
    );
  }

  return (
    <div className="flex-1">
      {goals?.map((g, i) => (
        <GoalItemCard key={i} goal={g} dashboardWidget={true} className={"mb-4"} />
      ))}
      {displayedPlanStrategies.map((ps) => (
        <div className="mb-4" key={ps.id}>
          <StrategyItem
            strategy={ps}
            onUpdateLogsSuccess={getPlanStrategiesRequest}
          />
        </div>
      ))}
    </div>
  );
};

export default StrategicPlanWidgetBody;
