import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { QuestionTag, SelectorOptionType } from "../../../types";
import CreatableSelect from "react-select/creatable";
import { addQuestionTag } from "../../../store/surveys/actions";
import { ActionMeta, ValueType } from "react-select";

type OwnProps = {
  surveyId: number;
  selectedTags: number[];
  onTagsChange: (tagsId: number[]) => void;
};

type Props = OwnProps;

const CreatableTagsSelector: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { surveyId, selectedTags, onTagsChange } = props;

  const dispatch = useDispatch();

  const tags = useSelector((s) => s.surveys.questionTags);
  const options: SelectorOptionType[] = useMemo(() => {
    return tags.map((item) => ({
      label: item.tag,
      value: item.id,
    }));
  }, [tags]);

  const loading = useSelector((s) => s.surveys.loading.getQuestionTags);

  const saveLoading = useSelector((s) => s.surveys.loading.addQuestionTag);

  const onQuestionTagCreate = (tag: QuestionTag) => {
    onTagsChange([...selectedTags, tag?.id]);
  };

  const renderCreateLabel = (inputValue: string) => {
    return intl.formatMessage(
      { id: "app.surveys.selectors.create-new-domain" },
      { domainName: inputValue }
    );
  };

  const handleQuestionTagCreate = (tag: string) => {
    dispatch(
      addQuestionTag.request({
        body: { tag: tag, survey: surveyId },
        onSuccess: onQuestionTagCreate,
      })
    );
  };

  const isValidNewOption = (
    inputValue: string,
    selectValue: any,
    selectOptions: any
  ) => {
    return !(
      inputValue.trim().length === 0 ||
      selectOptions.find((option: QuestionTag) => option.tag === inputValue)
    );
  };

  const displayNoOptions = useCallback(() => {
    return intl.formatMessage({ id: "app.titles.no-options" });
  }, []);

  const handleTagsChange = (
    value: ValueType<SelectorOptionType, boolean>,
    actionMeta: ActionMeta<SelectorOptionType>
  ) => {
    onTagsChange(
      (value as SelectorOptionType[])?.map((item) => item.value) ?? []
    );
  };

  return (
    <CreatableSelect
      value={options?.filter((item) => selectedTags?.includes(item.value))}
      isMulti
      isClearable
      options={options}
      onChange={handleTagsChange}
      placeholder={intl.formatMessage({ id: "app.filters.tags.placeholder" })}
      noOptionsMessage={displayNoOptions}
      isLoading={loading || saveLoading}
      isValidNewOption={isValidNewOption}
      formatCreateLabel={renderCreateLabel}
      onCreateOption={handleQuestionTagCreate}
    />
  );
};

export default CreatableTagsSelector;
