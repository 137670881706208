import React, { FunctionComponent } from "react";

type OwnProps = {
  labels: string[];
};

type Props = OwnProps;

const GroupingLabelsPopoverContent: FunctionComponent<Props> = (props) => {
  const { labels } = props;

  return (
    <div className="flex flex-col p-4">
      {labels?.map((label, index) => (
        <span className="text-xl" key={index}>
          {label}
        </span>
      ))}
    </div>
  );
};

export default GroupingLabelsPopoverContent;
