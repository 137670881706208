import React from "react";
import { useIntl } from "react-intl";
import { FormGroup, TextArea } from "@blueprintjs/core";
import PageEditor from "../../survey-deployment/forms/PageEditor";
import { PageTextKeys, SurveyPrompt } from "../../../types";
import { Locale } from "../../../store/UIState";

type OwnProps = {
  selectedPage: PageTextKeys;
  selectedLanguage: Locale;
  prompt?: SurveyPrompt;
  onTextAreaUpdate?: (
    e: React.FocusEvent<HTMLTextAreaElement>,
    key: "statement" | "instruction"
  ) => void;
  onUpdatePrompt?: (key: "instruction", value: string) => void;
  readOnly?: boolean;
};

type Props = OwnProps;

const SurveyPromptComponent: React.FC<Props> = (props: Props) => {
  const {
    selectedPage,
    selectedLanguage,
    prompt,
    onTextAreaUpdate,
    onUpdatePrompt,
    readOnly,
  } = props;

  const intl = useIntl();

  return (
    <div className="w-full">
      {selectedPage !== "intro" && selectedPage !== "post" ? (
        <>
          <FormGroup
            label={intl.formatMessage({
              id: "app.survey-deployment-dialog.prompts-tab.statement",
            })}
          >
            <TextArea
              key={`${selectedPage}_${selectedLanguage}`}
              defaultValue={
                prompt?.[selectedPage]?.[selectedLanguage]?.statement
              }
              className="italic min-height-textarea"
              placeholder={intl.formatMessage({
                id:
                  "app.survey-deployment-dialog.prompts-tab.statement.placeholder",
              })}
              growVertically
              fill
              disabled={readOnly}
              onBlur={(e) =>
                onTextAreaUpdate && onTextAreaUpdate(e, "statement")
              }
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.survey-deployment-dialog.prompts-tab.instructions",
            })}
          >
            <TextArea
              key={`${selectedPage}_${selectedLanguage}`}
              defaultValue={
                prompt?.[selectedPage]?.[selectedLanguage]?.instruction
              }
              className="italic min-height-textarea"
              placeholder={intl.formatMessage({
                id:
                  "app.survey-deployment-dialog.prompts-tab.instructions.placeholder",
              })}
              growVertically
              fill
              onBlur={(e) =>
                onTextAreaUpdate && onTextAreaUpdate(e, "instruction")
              }
              disabled={readOnly}
            />
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup
            label={intl.formatMessage({
              id: "app.survey-deployment-dialog.prompts-tab.instructions",
            })}
          >
            {readOnly ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    prompt?.[selectedPage]?.[selectedLanguage]?.instruction ??
                    "",
                }}
              />
            ) : (
              <PageEditor
                key={`${selectedPage}_${selectedLanguage}`}
                content={
                  prompt?.[selectedPage]?.[selectedLanguage]?.instruction
                }
                onBlur={(value) =>
                  onUpdatePrompt && onUpdatePrompt("instruction", value)
                }
              />
            )}
          </FormGroup>
        </>
      )}
    </div>
  );
};

export default SurveyPromptComponent;
