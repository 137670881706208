import React, { FunctionComponent, useMemo } from "react";
import {
  Button,
  Card,
  Icon,
  NonIdealState,
  TextArea,
  Tooltip,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { LookForItem } from "../../../../../types";

type OwnProps = {
  lookFors: (Omit<LookForItem, "id" | "created_at" | "updated_at"> & {
    readonly?: boolean;
  })[];
  setLookFors: React.Dispatch<
    React.SetStateAction<
      (Omit<LookForItem, "id" | "created_at" | "updated_at"> & {
        readonly?: boolean;
      })[]
    >
  >;
};

type Props = OwnProps;

const LookForsTab: FunctionComponent<Props> = (props) => {
  const { lookFors, setLookFors } = props;

  const intl = useIntl();

  const handleLookForAdd = () => {
    setLookFors((lookFors) => [...lookFors, { text: "", original: true, school_item:false }]);
  };

  const handleLookForRemove = (index: number) => () => {
    setLookFors((lookFors) => lookFors.filter((lookFor, i) => i !== index));
  };

  const handleLookForChange = (index: number) => (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setLookFors((lookFors) =>
      lookFors.map((lookFor, i) =>
        i === index ? { ...lookFor, text: value } : { ...lookFor }
      )
    );
  };

  const newItemButton = useMemo(
    () => (
      <Button
        icon="add"
        className="mb-4"
        onClick={handleLookForAdd}
        intent="primary"
      >
        {intl.formatMessage({ id: "app.strategies.look-fors.add-new-item" })}
      </Button>
    ),
    [handleLookForAdd]
  );

  return (
    <div>
      {lookFors.length ? (
        <>
          {newItemButton}
          <form className="space-y-2">
            {lookFors.map((lookFor, index) => {
              return lookFor.original &&
                (
                  <Card
                    key={index}
                    className="p-2 flex items-center justify-between"
                  >
                    <div className="flex-1">
                      {lookFor.readonly ? (
                        <div className="flex items-center">
                          <Tooltip
                            className="mr-2"
                            content={intl.formatMessage({
                              id: "app.strategies.look-fors.standard-no-actions",
                            })}
                          >
                            <Icon icon={"lock"} intent="warning" />
                          </Tooltip>

                        <div>{lookFor?.text}</div>
                      </div>
                    ) : (
                      <TextArea
                        placeholder={intl.formatMessage({
                          id: "app.strategies.look-fors.placeholder",
                        })}
                        fill
                        value={lookFor?.text}
                        onChange={handleLookForChange(index)}
                      />
                    )}
                  </div>

                  {!lookFor.readonly && (
                    <Button
                      className="ml-2"
                      intent="danger"
                      small
                      icon="cross"
                      minimal
                      onClick={handleLookForRemove(index)}
                    />
                  )}
                </Card>
                )
            }
            )}
          </form>
        </>
      ) : (
        <NonIdealState
          icon="add-to-artifact"
          title={intl.formatMessage({ id: "app.strategies.look-fors.empty" })}
          description={intl.formatMessage({
            id: "app.non-ideal-state.let's-get-started",
          })}
          action={newItemButton}
        />
      )}
    </div>
  );
};

export default LookForsTab;
