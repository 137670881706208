import {
  Button,
  Checkbox,
  InputGroup,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
} from "@blueprintjs/core";
import { Add } from '@mui/icons-material';

import React, { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { CellProps } from "react-table";
import { Link } from "@mui/material";
import { InviteUserRequest } from "../../../api/users/types";
import {
  getSchools,
  getSchoolsByDistrict,
} from "../../../store/schools/actions";
import { TeamRole, UserRole, userRoles } from "../../../types";
import EntityTable from "../../common/EntityTable";
import DistrictSelector from "../../survey-deployment/selectors/DistrictSelector";
import SchoolRoleCell from "./SchoolRoleCell";
import SchoolSelectorHOC from "./SchoolSelectorHOC";
import useUserRole from "../../../helpers/hooks/useUserRole";

type OwnProps = {
  forDistrictPlan?: boolean;
  forPlan?: boolean;
  defaultNewItem: InviteUserRequest;
  formRef: React.RefObject<HTMLFormElement>;
  data: InviteUserRequest[];
  setData: React.Dispatch<React.SetStateAction<InviteUserRequest[]>>;
  forAllSystem?: boolean;
  setWarning: React.Dispatch<React.SetStateAction<string | undefined>>;
  disableSort?: boolean;
};

type Props = OwnProps;

type RouteParams = {
  districtId?: string;
};

const InviteUsersTable: React.FC<Props> = (props: Props) => {
  const {
    data,
    setData,
    formRef,
    forAllSystem,
    defaultNewItem,
    forPlan,
    forDistrictPlan,
    setWarning,
    disableSort,
  } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const { isDCC, isSuperintendent, isAdmin } = useUserRole();

  const isSuperintendentOrAdmin = useMemo(() => {
    return isDCC || isSuperintendent || isAdmin;
  }, [isDCC, isSuperintendent, isAdmin]);

  const routeParams = useParams<RouteParams>();

  const loading = useSelector((s) => s.schools.loading.getSchoolsByDistrict);
  useEffect(() => {
    if (routeParams?.districtId) {
      dispatch(getSchoolsByDistrict.request(Number(routeParams.districtId)));
    } else {
      dispatch(getSchools.request());
    }
  }, [routeParams?.districtId]);

  const handleInviteChange = (localId: string) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = e.target;
    setData((data) =>
      data.map((d) => (d.local_id === localId ? { ...d, [name]: value } : d))
    );
  };

  const setDistrictByRole = (
    role: UserRole,
    districtId: number | undefined
  ): number | undefined => {
    if (userRoles.admins.includes(role)) {
      return undefined;
    }
    return districtId;
  };

  const setSchoolsByRole = (
    role: UserRole,
    schools: number[] | undefined
  ): number[] | undefined => {
    if (
      (userRoles.admins.includes(role) ||
        userRoles.districtOrSchoolLeadership.includes(role)) &&
      forDistrictPlan
    ) {
      return undefined;
    }
    return schools;
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: intl.formatMessage({ id: "app.users-table.columns.email" }),
        accessor: "email",
        width: "15%",
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          return (
            <InputGroup
              id="email"
              type="email"
              name="email"
              required
              fill
              value={original.email}
              onChange={handleInviteChange(original.local_id)}
              placeholder={intl.formatMessage({
                id: "app.forms.email.placeholder",
              })}
            />
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.invites-table.cells.first-name",
        }),
        accessor: "first_name",
        width: "15%",
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          return (
            <InputGroup
              id="first_name"
              name="first_name"
              required
              fill
              value={original.first_name}
              onChange={handleInviteChange(original.local_id)}
              placeholder={intl.formatMessage({
                id: "app.forms.first-name.placeholder",
              })}
            />
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.invites-table.cells.last-name",
        }),
        accessor: "last_name",
        width: "15%",
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          return (
            <InputGroup
              id="last_name"
              name="last_name"
              required
              fill
              value={original.last_name}
              onChange={handleInviteChange(original.local_id)}
              placeholder={intl.formatMessage({
                id: "app.forms.last-name.placeholder",
              })}
            />
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.district-school-role" }),
        width: "15%",
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          return (
            <SchoolRoleCell
              role={original.role}
              schoolRole={original.custom_role_name}
              onRoleChange={([role, staffRole]) => {
                setData((data) => {
                  let teamRole: TeamRole;
                  let isDCC: boolean;

                  switch (role) {
                    case UserRole.Principal: {
                      forDistrictPlan &&
                        isSuperintendentOrAdmin &&
                        setWarning(
                          intl.formatMessage({
                            id: "app.warnings.principals-cannot-be-dcc",
                          })
                        );
                      teamRole = forDistrictPlan
                        ? TeamRole.TeamMember
                        : TeamRole.TeamChampion;
                      isDCC = false;
                      break;
                    }

                    default: {
                      teamRole = TeamRole.TeamMember;
                      setWarning(undefined);
                      break;
                    }
                  }

                  return data.map((d) =>
                    d.local_id === original.local_id
                      ? {
                          ...d,
                          is_dcc: isDCC ?? d.is_dcc,
                          role: role,
                          team_role: teamRole,
                          district: setDistrictByRole(role, d.district),
                          schools: setSchoolsByRole(role, d.schools),
                          custom_role_name: staffRole,
                        }
                      : d
                  );
                });
              }}
              onSchoolRoleNameChange={(value) => {
                setData((data) => {
                  return data.map((d) =>
                    d.local_id === original.local_id
                      ? {
                          ...d,
                          custom_role_name: value,
                        }
                      : d
                  );
                });
              }}
              isAdminLevelSelector={forAllSystem}
              menuPortalTargetSelector={"body"}
            />
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.district" }),
        width: "20%",
        hidden: true,
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          return (
            <DistrictSelector
              districtId={original?.district}
              isClearable
              onDistrictChange={(districtId) => {
                setData((data) => {
                  return data.map((d) =>
                    d.local_id === original.local_id
                      ? {
                          ...d,
                          district: districtId,
                          schools: [],
                        }
                      : d
                  );
                });
              }}
            />
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.schools" }),
        width: "20%",
        hidden: forPlan || forAllSystem,
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          return (
            <SchoolSelectorHOC
              disabled={forPlan}
              loading={loading}
              districtId={original?.district}
              schoolsIds={original?.schools ?? []}
              onChange={(schoolsIds) => {
                setData((data) =>
                  data.map((d) =>
                    d.local_id === original.local_id
                      ? {
                          ...d,
                          schools: schoolsIds,
                        }
                      : d
                  )
                );
              }}
            />
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.titles.district-climate-coordinator",
        }),
        width: "10%",
        accessor: "is_dcc",
        hidden: !isSuperintendentOrAdmin || !forDistrictPlan,
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          const disabled =
            !original.role || original.role === UserRole.Principal;

          return (
            <Checkbox
              className="flex justify-center items-center mb-0"
              large
              checked={original.is_dcc}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setData((data) =>
                  data.map((d) =>
                    d.local_id === original.local_id
                      ? { ...d, is_dcc: e.target.checked }
                      : d
                  )
                )
              }
            />
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        width: "10%",
        Cell: ({ row: { original } }: CellProps<InviteUserRequest>) => {
          return (
            <div className="flex -ml-1">
              <Button
                disabled={data.length <= 1}
                title={intl.formatMessage({
                  id: "app.surveys-table.columns.delete",
                })}
                icon="trash"
                className="whitespace-no-wrap m-1"
                intent="danger"
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  setData((data) =>
                    data.filter((d) => d.local_id !== original.local_id)
                  )
                }
              />
              {!!original.fail_reason && (
                <Popover
                  content={
                    <span className="p-4">
                      {intl.formatMessage({
                        id: `app.invitation.fail-reason.${original.fail_reason}`,
                      })}
                    </span>
                  }
                  interactionKind={PopoverInteractionKind.HOVER}
                  position={PopoverPosition.TOP}
                  targetClassName="whitespace-no-wrap m-1"
                >
                  <Button icon="help" intent="warning" />
                </Popover>
              )}
            </div>
          );
        },
      },
    ];
    return cols.filter((c) => !c.hidden);
  }, [
    data.length,
    forAllSystem,
    loading,
    forPlan,
    isSuperintendentOrAdmin,
    forDistrictPlan,
  ]);

  const handleAddUserClick = useCallback(() => {
    if (formRef.current?.reportValidity()) {
      setData((data) => [...data, defaultNewItem]);
    }
  }, [defaultNewItem]);

  const addUserButton = useMemo(
    () => (
      <p  onClick={handleAddUserClick} style={{color:"#195A94"}} className="mt-5">
        <Add />
        <b>  {intl.formatMessage({
          id: "app.invites-table.button.add-another-user",
        })}
        </b>
      </p>
      // <Button
      //   large
      //   icon="plus"
      //   intent="primary"

      //   text={intl.formatMessage({
      //     id: "app.invites-table.button.add-another-user",
      //   })}
      //   onClick={handleAddUserClick}
      // />
    ),
    [handleAddUserClick]
  );

  const noUsers = useMemo(
    () => (
      <NonIdealState
        icon="user"
        title={intl.formatMessage({
          id: "app.non-ideal-state.users-table.title",
        })}
        description={intl.formatMessage({
          id: "app.non-ideal-state.users-table.description",
        })}
        action={addUserButton}
      />
    ),
    [addUserButton]
  );

  return (
    <>
      <EntityTable
        data={data}
        columns={columns}
        noDataComp={noUsers}
        disableSort={disableSort}
      >
        {/* {addUserButton} */}
      </EntityTable>
      <div className={'new-user-action-link'} style={{textAlign:"right"}}>
      {addUserButton}
      </div>
    </>
  );
};

export default InviteUsersTable;
