import React, { FunctionComponent, useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import { useIntl } from "react-intl";
import { smallSelectorStyles } from "../../common/selectors";

type OwnProps = {
  value?: string;
  onChange: (value?: string) => void;
  items: string[];
};

type Props = OwnProps;

const SegmentationCodeSelector: FunctionComponent<Props> = (props) => {
  const { items, value, onChange } = props;

  const intl = useIntl();

  const options = useMemo(() => {
    return items.map((item) => ({
      label: item,
      value: item,
    }));
  }, [items]);

  const handleValueChange = (value: any) => {
    onChange(value?.value);
  };
  const handleOptionCreate = (newValue: string) => {
    onChange(newValue);
  };

  const selectedOption = useMemo(() => {
    return value ? options.find((opt) => opt.value === value) : undefined;
  }, [options, value]);

  return (
    <CreatableSelect
      className={"w-56"}
      styles={smallSelectorStyles}
      value={selectedOption}
      isClearable
      options={options}
      onChange={handleValueChange}
      onCreateOption={handleOptionCreate}
      placeholder={
        intl.formatMessage({
          id: "app.surveys.survey-question.segmentation-code",
        }) + "..."
      }
    />
  );
};

export default SegmentationCodeSelector;
