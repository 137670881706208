import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Callout, Classes, Tab, TabId, Tabs } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import GeneralInfoTab from "./tabs/GeneralInfoTab";
import DomainSelectionTab from "./tabs/DomainSelectionTab";
import LookForsTab from "./tabs/LookForsTab";
import ResourcesTab from "./tabs/ResourcesTab";
import { addStrategy, updateStrategy } from "../../../../store/strategies/actions";
import {
  AttachedResource,
  AttachedToType,
  LookForItem,
  Strategy,
} from "../../../../types";
import { AppToaster } from "../../../../helpers/toaster";
import { useLoading } from "../../../../helpers/hooks/useLoading";

type OwnProps = {
  onClose: Function;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
  hasChanges: boolean;
  selectedStrategy?: Strategy;
};

type Props = OwnProps;

const UpsertStrategyDialogContent: FunctionComponent<Props> = (props) => {
  const { onClose, setHasChanges, hasChanges, selectedStrategy } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    if (!hasChanges) {
      setSelectedDomainsIds([]);
      setTabId("general-info");
      setStrategyName("");
      setDescription("");
      setSupportingEvidence("");
      setLookForItems([]);
      setStrategyResources([]);
      setWarning(undefined);
      setPdfFile(undefined);
    }
  };

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const [selectedDomainsIds, setSelectedDomainsIds] = useState<number[]>([]);

  const [warning, setWarning] = useState<string | undefined>();

  const [tabId, setTabId] = useState<TabId>("general-info");
  const handleTabChange = (newTabId: TabId) => {
    setTabId(newTabId);
  };

  const [strategyName, setStrategyName] = useState("");
  const [description, setDescription] = useState("");
  const [supportingEvidence, setSupportingEvidence] = useState("");
  const [lookForItems, setLookForItems] = useState<
    Omit<LookForItem, "id" | "created_at" | "updated_at">[]
  >([]);
  const [strategyResources, setStrategyResources] = useState<
    AttachedResource[]
    >([]);

  useEffect(() => {
    if (selectedStrategy) {
      setStrategyName(selectedStrategy.name);
      setDescription(selectedStrategy.description);
      setSupportingEvidence(selectedStrategy.supporting_evidence);
      setLookForItems(selectedStrategy.look_for_items);
      setStrategyResources(selectedStrategy.resources);
      setSelectedDomainsIds(selectedStrategy.domains.map(a => a.id));
      setPdfFile(selectedStrategy.pdf_resource);
    }
    else {
      setSelectedDomainsIds([]);
      setTabId("general-info");
      setStrategyName("");
      setDescription("");
      setSupportingEvidence("");
      setLookForItems([]);
      setStrategyResources([]);
      setWarning(undefined);
      setPdfFile(undefined);
    }
  }, [selectedStrategy]);


  const loading = useSelector((s) => s.strategies.loading.addStrategy);
  const updateLoading = useSelector((s) => s.strategies.loading.updateStrategy);
  const error = useSelector((s) => s.strategies.errors.addStrategy);
  const updateError = useSelector((s) => s.strategies.errors.updateStrategy);

  const onSuccess = () => {
    AppToaster.show({
      intent: "success",
      icon: "tick",
      message: intl.formatMessage(
        { id: selectedStrategy ? "app.strategies.updated-strategy" : "app.strategies.added-strategy" },
        { name: strategyName }
      ),
    });
    handleClose();
  };
  useLoading({ loading: loading || updateLoading, error: error || updateError, onSuccess: onSuccess });

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (description === "") {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.strategies.validation.description-required",
        }),
      };
      return validationOption;
    }
    if (supportingEvidence === "") {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.strategies.validation.supporting-evidence-required",
        }),
      };
      return validationOption;
    }
    if (!selectedDomainsIds?.length) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.strategies.validation.domains-required",
        }),
      };
      return validationOption;
    }
    if (!lookForItems?.length) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.strategies.validation.look-for-items-required",
        }),
      };
      return validationOption;
    }

    return validationOption;
  };

  const handleSaveStrategyClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, message } = validate();
    if (isValid) {

      const requestBody = {
        name: strategyName,
        domains: selectedDomainsIds,
        description: description,
        supporting_evidence: supportingEvidence,
        look_for_items: lookForItems,
        resources: strategyResources,
        pdf_resource: pdfFile,
      };

      if (selectedStrategy) {
        dispatch(
          updateStrategy.request({
            updatedStrategy: { ...requestBody, id: selectedStrategy.id }
          })
        )
      }
      else {
        dispatch(
          addStrategy.request({
            strategy: {
              ...requestBody
            },
            planId: activePlanId!,
          })
        );
      }
      setHasChanges(false);
      setWarning(undefined);
    } else {
      setWarning(message);
    }
  };

  const handleStrategyResourceUpdate = (resources: AttachedResource[]) => {
    setStrategyResources(resources);
  };

  const removeHTMLTags = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  useEffect(() => {
    if (!selectedStrategy && (strategyName || !!selectedDomainsIds.length || removeHTMLTags(description) || removeHTMLTags(supportingEvidence) || !!lookForItems.length || !!strategyResources.length)) {
      setHasChanges(true);
    }
    else {
      setHasChanges(false);
    }
  }, [description, lookForItems, selectedDomainsIds, selectedStrategy, setHasChanges, strategyName, strategyResources, supportingEvidence]);

  const [pdfFile, setPdfFile] = useState<AttachedResource | undefined>();

  const handleResourceChange = (
    type: "pdf" | "docx" | "ppt",
    resource?: AttachedResource
  ) => {
    if (type === "pdf") {
      setPdfFile(resource);
    }
  };

  return (
    <form id="upsert-strategy-form" onSubmit={handleSaveStrategyClick}>
      <div className={Classes.DIALOG_BODY}>
        <Tabs selectedTabId={tabId} onChange={handleTabChange}>
          <Tab
            id={"general-info"}
            panel={
              <GeneralInfoTab
                strategyName={strategyName}
                setStrategyName={setStrategyName}
                description={description}
                setDescription={setDescription}
                supportingEvidence={supportingEvidence}
                setSupportingEvidence={setSupportingEvidence}
                pdfFile={pdfFile}
                onFileChange={handleResourceChange}
              />
            }
          >
            {intl.formatMessage({ id: "app.titles.general-info" })}
          </Tab>
          <Tab
            id="domains"
            panel={
              <DomainSelectionTab
                selectedDomainsIds={selectedDomainsIds}
                setSelectedDomainsIds={setSelectedDomainsIds}
              />
            }
          >
            {intl.formatMessage({ id: "app.titles.domains" })}
          </Tab>
          <Tab
            id="look-fors"
            panel={
              <LookForsTab
                lookFors={lookForItems}
                setLookFors={setLookForItems}
              />
            }
          >
            {intl.formatMessage({ id: "app.titles.look-fors" })}
          </Tab>
          <Tab
            id="resources"
            panel={
              <ResourcesTab
                strategyResources={strategyResources}
                setStrategyResources={handleStrategyResourceUpdate}
                attachedToType={AttachedToType.STRATEGY}
                forUserNotes={false}
              />
            }
          >
            {intl.formatMessage({ id: "app.titles.resources" })}
          </Tab>
        </Tabs>
        {warning && (
          <div className="my-3 px-3">
            <Callout intent="warning">{warning}</Callout>
          </div>
        )}
      </div>
      <div className={`${Classes.DIALOG_FOOTER} mt-4`}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={handleClose}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            className="w-1/4"
            intent="primary"
            type="submit"
            loading={loading}
          >
            {intl.formatMessage({ id: "app.titles.save" })}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UpsertStrategyDialogContent;
