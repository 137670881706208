import React from "react";
import { Icon, Popover } from "@blueprintjs/core";
import { Domain } from "../../../../types";
import { COMPARISON_COLORS } from "../../tab/SurveyComparisonTab";
import DomainTitleRankingComparisonItem from "./DomainTitleRankingComparisonItem";

type OwnProps = { domains?: Domain[],means?:number[],not_enough_datas?:boolean[] };

type Props = OwnProps;

const ICON_SIZE = 40;

const RedactedDomainsPopoverItem: React.FC<Props> = ({ domains,means,not_enough_datas }: Props) => {
  return (
    <Popover
      interactionKind="hover"
      popoverClassName="bg-white"
      className="flex justify-center"
      content={
        <div className="mt-2">
          {domains?.map((d, i) => (
            <div className="mb-2">
              <DomainTitleRankingComparisonItem
                key={i}
                domain={d}
                color={
                  d?.color_hex
                    ? d.color_hex
                    : COMPARISON_COLORS[(d?.id ?? 0) % COMPARISON_COLORS.length]
                }
                mean= {means?means[i]:0}
                not_enough_data={not_enough_datas?not_enough_datas[i]:false}
              />
            </div>
          ))}
        </div>
      }
    >
      <Icon icon="more" iconSize={ICON_SIZE} color="gray" />
    </Popover>
  );
};

export default RedactedDomainsPopoverItem;
