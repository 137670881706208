import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Classes, Dialog, Icon } from "@blueprintjs/core";
import { hideDetailsStrategyDialog } from "../../../../store/strategies/actions";
import { isPlanStrategy } from "../../utils";
import { PlanStrategy, Strategy } from "../../../../types";
import StrategyDetails from "./StrategyDetails";

type OwnProps = {};

type Props = OwnProps;

const DetailsStrategyDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { show, strategy } = useSelector(
    (s) => s.strategies.dialogs.detailsStrategyDialog
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideDetailsStrategyDialog());
  };

  const isPlanStrategyType = useMemo(() => {
    if (!!strategy) {
      return isPlanStrategy(strategy);
    }
  }, [strategy]);

  return (
    <Dialog
      className="w-2/3"
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon="briefcase" iconSize={24} />}
      title={
        isPlanStrategyType
          ? (strategy as PlanStrategy)?.strategy?.name
          : (strategy as Strategy)?.name
      }
    >
      <div className={Classes.DIALOG_BODY}>
        {!!strategy && <StrategyDetails strategy={strategy} />}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-center mt-4">
          <Button
            text={intl.formatMessage({ id: "app.titles.close" })}
            title={intl.formatMessage({ id: "app.titles.close" })}
            onClick={handleClose}
            intent="primary"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DetailsStrategyDialog;
