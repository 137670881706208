import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Icon, Spinner } from "@blueprintjs/core";
import PictureReader from "./picture-reader/PictureReader";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../helpers/hooks/useLoading";
import { uploadProfileImage,removeProfileImage } from "../../store/auth/actions";


type OwnProps = {
  src?: string;
  size?: number;
};

type Props = OwnProps;

const UserAvatar: FunctionComponent<Props> = (props) => {
  const { src, size = 48 } = props;

  const [imageSrc, setImageSrc] = useState<string | undefined>(src);
  const user = useSelector((s) => s.auth.authenticatedUser);

  const dispatch = useDispatch();
  const fileReaderRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (file: File) => {
    dispatch(uploadProfileImage.request(file));
  };

  useEffect(() => {
    setImageSrc(user?.profile.image_url);
  }, [user?.profile.image_url]);

  const handleRemovePicture = () => {

    dispatch(removeProfileImage.request({email:user?.email}));
    // console.log(user,"<user")
  }

  const loading = useSelector((s) => s.auth.loading.uploadProfileImage);
  const error = useSelector((s) => s.auth.errors.uploadProfileImage);
  useLoading({ loading, error });

  return (

    <div className="Edit-customProfilePicture">
      <div className="circle">
        {
          imageSrc ?
            <img src={imageSrc} alt={""} />
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25" fill="none">
              <path d="M3.0066 4.42477L7.18715 4.42477L8.31037 1.98613C8.72482 1.09714 9.61981 0.52051 10.6049 0.520509L19.5063 0.520509C20.4854 0.520509 21.3864 1.09714 21.7948 1.98613L22.924 4.42477L27.1046 4.42477C28.4981 4.42477 29.6514 5.55397 29.6514 6.94755L29.6514 22.0239C29.6514 23.4174 28.4981 24.5467 27.1046 24.5467L3.00649 24.5467C1.61298 24.5467 0.459721 23.4114 0.459721 22.0239L0.459719 6.94755C0.459719 5.55403 1.61297 4.42477 3.00649 4.42477L3.0066 4.42477ZM1.9014 22.024C1.9014 22.6186 2.41196 23.1052 3.0066 23.1052L27.1047 23.1052C27.6993 23.1052 28.2099 22.6187 28.2099 22.024L28.2099 6.94766C28.2099 6.35302 27.6993 5.86645 27.1047 5.86645L22.4616 5.86645C22.1793 5.86645 21.9271 5.70428 21.8069 5.446L20.4855 2.5929C20.3113 2.21449 19.9269 1.96223 19.5064 1.96223L10.5989 1.96223C10.1785 1.96223 9.79406 2.2085 9.61986 2.5929L8.29845 5.446C8.17832 5.69828 7.92605 5.86645 7.64373 5.86645L3.00067 5.86645C2.40603 5.86645 1.89547 6.34698 1.89547 6.94766L1.89547 22.024L1.9014 22.024Z" fill="black" />
              <path d="M15.056 6.31721C19.2786 6.31721 22.7202 9.75292 22.7202 13.9814C22.7202 18.204 19.2845 21.6456 15.056 21.6456C10.8334 21.6456 7.3918 18.2099 7.3918 13.9814C7.3918 9.75292 10.8336 6.31721 15.056 6.31721ZM15.056 20.1986C18.4857 20.1986 21.2786 17.4055 21.2786 13.9759C21.2786 10.5463 18.4856 7.75933 15.056 7.75933C11.6264 7.75933 8.83337 10.5464 8.83337 13.9759C8.83337 17.4055 11.6264 20.1986 15.056 20.1986Z" fill="black" />
            </svg>
        }
      </div>
          <div className="p-image">
            <label htmlFor="pic-upload" className="image-editIcon">
              <Icon icon="edit" iconSize={16} onClick={() => {
              fileReaderRef.current?.click();
              }} />
            </label>
          <PictureReader
            fileReaderRef={fileReaderRef}
            onFileSelect={handleImageUpload}
          />
      </div>
      {
        !!imageSrc?.length &&
        <button className="deleteProfile_Pic" onClick={handleRemovePicture}>Remove Profile Picture</button>
      }
    </div>
  );
};

export default UserAvatar;
