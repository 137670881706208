import React, { useState, useEffect, memo } from "react";
import { AppToaster } from "./helpers/toaster";

const NoInternetConnection = (props: any): any => {
  const [isOnline, setOnline] = useState(true);
  const [recentlyDisconnected, setRecentlyDisconnected] = useState(false);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);
  window.addEventListener("online", () => {
    setOnline(true);
  });
  window.addEventListener("offline", () => {
    setOnline(false);
  });
  if (isOnline) {
    if (recentlyDisconnected)
      AppToaster.show({
        message: "Network connection restored",
        intent: "success",
      });
    return props.children;
  } else {
    if (!recentlyDisconnected) setRecentlyDisconnected(true);
    AppToaster.show({
      message: "Network connection lost",
      intent: "danger",
    });
    return <>{props.children}</>;
  }
};

export default memo(NoInternetConnection);
