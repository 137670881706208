import { createAction, createAsyncAction } from "typesafe-actions";
import {
  GetDomainsAndItemsByIdsRequest,
  GetDomainsAndItemsByIdsResponse,
} from "../../api/domains/types";
import {
  BookmarkDetail,
  DomainComparison,
  DomainQuestionsComparison,
  DomainResponses,
  DomainStatistics,
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  Insight,
  InsightSubgroupInfoDialogType,
  ResponseRates,
  SchoolNameDeplyments,
  ShareFeedbackModel,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
  SurveyReportTaskIdName,
  SurveyStatistics,
} from "../../types";
import {
  GetSurveyComparisonRequest,
  GetSurveyReportRequest,
} from "../../api/survey-reports/types";

export const showBookmarkDialog = createAction(
  "@survey-reports/SHOW_BOOKMARK_DIALOG"
)<{
  bookmarkDetail: BookmarkDetail;
}>();
export const hideBookmarkDialog = createAction(
  "@survey-reports/HIDE_BOOKMARK_DIALOG"
)<void>();

export const showDomainComparisonDialog = createAction(
  "@survey-reports/SHOW_DOMAIN_COMPARISON_DIALOG"
)<{
  responses: DomainResponses;
  demographics: SurveyDemographic[];
}>();
export const hideDomainComparisonDialog = createAction(
  "@survey-reports/HIDE_DOMAIN_COMPARISON_DIALOG"
)<void>();

export const toggleSensitiveData = createAction (
  "@survey-reports/TOGGLE_SENSITIVE_DATA"
)<{
  sensitiveDataToggle: boolean;
}>();

export const getSurveyReport = createAsyncAction(
  "@survey-reports/GET_SURVEY_REPORT_REQUEST",
  "@survey-reports/GET_SURVEY_REPORT_SUCCESS",
  "@survey-reports/GET_SURVEY_REPORT_FAILURE"
)<
  GetSurveyReportRequest,
  | SurveyStatistics[]
  | GroupedSurveyStatistics[]
  | DomainStatistics
  | DomainStatistics<GroupedAnswerStatistics[]>,
  Error
>();

export const fillBreadcrumb = createAsyncAction(
  "@survey-reports/FILL_BREADCRUMB_REQUEST",
  "@survey-reports/FILL_BREADCRUMB_SUCCESS",
  "@survey-reports/FILL_BREADCRUMB_FAILURE"
)<
  {
    districtId?: number;
    schoolId?: number;
    fetchDistricts?: boolean;
  },
  void,
  Error
>();

export const getDistrictSurveyComparison = createAsyncAction(
  "@survey-reports/GET_DISTRICT_SURVEY_COMPARISON_REQUEST",
  "@survey-reports/GET_DISTRICT_SURVEY_COMPARISON_SUCCESS",
  "@survey-reports/GET_DISTRICT_SURVEY_COMPARISON_FAILURE"
)<
  {
    surveyComparisonReq: GetSurveyComparisonRequest[];
    isComprisonReport: Boolean;
  },
  (DomainQuestionsComparison | DomainComparison[])[],
  Error
>();

export const getSurveyComparison = createAsyncAction(
  "@survey-reports/GET_SURVEY_COMPARISON_REQUEST",
  "@survey-reports/GET_SURVEY_COMPARISON_SUCCESS",
  "@survey-reports/GET_SURVEY_COMPARISON_FAILURE"
)<
  GetSurveyComparisonRequest,
  DomainQuestionsComparison | DomainComparison[],
  Error
>();

export const getComparisonDomainItemsData = createAsyncAction(
  "@survey-reports/GET_SURVEY_COMPARISON_ITEM_REQUEST",
  "@survey-reports/GET_SURVEY_COMPARISON_ITEM_SUCCESS",
  "@survey-reports/GET_SURVEY_COMPARISON_ITEM_FAILURE"
)<any,any,Error>();

export const getRespondentGraphDomainData = createAsyncAction(
  "@survey-reports/GET_SURVEY_RESPONDENT_GRAPH_REQUEST",
  "@survey-reports/GET_SURVEY_RESPONDENT_GRAPH_SUCCESS",
  "@survey-reports/GET_SURVEY_RESPONDENT_GRAPH_FAILURE"
)<GetSurveyComparisonRequest,any,Error>();


export const getRespondentGraphItemData = createAsyncAction(
  "@survey-reports/GET_SURVEY_RESPONDENT_GRAPH_ITEM_REQUEST",
  "@survey-reports/GET_SURVEY_RESPONDENT_GRAPH_ITEM_SUCCESS",
  "@survey-reports/GET_SURVEY_RESPONDENT_GRAPH_ITEM_FAILURE"
)<GetSurveyComparisonRequest,any,Error>();


export const clearRespondentGraphDomainsItemsArray = createAction(
  "@survey-reports/CLEAR_RESPONDENT_GRAPH_DOMAINS_ITEM"
)<void[]>();

export const getSurveyInsights = createAsyncAction(
  "@survey-reports/GET_SURVEY_INSIGHTS_REQUEST",
  "@survey-reports/GET_SURVEY_INSIGHTS_SUCCESS",
  "@survey-reports/GET_SURVEY_INSIGHTS_FAILURE"
)<{ surveyDeploymentId: number; schoolId: number }, Insight[], Error>();

export const resetState = createAction("@survey-reports/RESET_STATE")<void>();

export const downloadStaticReport = createAsyncAction(
  "@survey-reports/DOWNLOAD_STATIC_REPORT_REQUEST",
  "@survey-reports/DOWNLOAD_STATIC_REPORT_SUCCESS",
  "@survey-reports/DOWNLOAD_STATIC_REPORT_FAILURE"
)<
  {
    surveyDeploymentId: number;
    schoolId: number;
    demographic: SurveyDemographic;
    surveyName: string;
    hide_sensitive_reporting_data:boolean;    
  },
  Blob,
  Error
>();

export const downloadCSVReport = createAsyncAction(
  "@survey-reports/DOWNLOAD_CSV_REPORT_REQUEST",
  "@survey-reports/DOWNLOAD_CSV_REPORT_SUCCESS",
  "@survey-reports/DOWNLOAD_CSV_REPORT_FAILURE"
)<
  {
    surveyDeploymentId: number;
    schoolId: number;
    demographic: SurveyDemographic;
    surveyName: string;
  },
  Blob | SurveyReportTaskIdName,
  Error
>();

export const downloadCSVReportByTaskId = createAsyncAction(
  "@survey-reports/DOWNLOAD_CSV_REPORT_BY_TASKID_REQUEST",
  "@survey-reports/DOWNLOAD_CSV_REPORT_BY_TASKID_SUCCESS",
  "@survey-reports/DOWNLOAD_CSV_REPORT_BY_TASKID_FAILURE",
)<
  SurveyReportTaskIdName,
  Blob,
  Error
>();

export const getSurveyReportResponseRates = createAsyncAction(
  "@survey-reports/GET_SURVEY_REPORT_RESPONSE_RATES_REQUEST",
  "@survey-reports/GET_SURVEY_REPORT_RESPONSE_RATES_SUCCESS",
  "@survey-reports/GET_SURVEY_REPORT_RESPONSE_RATES_FAILURE"
)<
  {
    surveyDeploymentId: number;
    schoolId: number;
    domainId?: number;
  },
  ResponseRates,
  Error
>();

export const changeRedactedDataCollapsed = createAction(
  "@survey-reports/CHANGE_REDACTED_DATA_COLLAPSED"
)<boolean>();

export const changeInReportInsights = createAction(
  "@survey-reports/CHANGE_IN_REPORT_INSIGHTS"
)<boolean>();

export const shareFeedback = createAsyncAction(
  "@survey-reports/SHARE_FEEDBACK_REQUEST",
  "@survey-reports/SHARE_FEEDBACK_SUCCESS",
  "@survey-reports/SHARE_FEEDBACK_FAILURE"
)<any, void, Error>();


export const getShareFeedbackOption = createAsyncAction(
  "@survey-reports/GET_SHARE_FEEDBACK_OPTION_REQUEST",
  "@survey-reports/GET_SHARE_FEEDBACK_OPTION_SUCCESS",
  "@survey-reports/GET_SHARE_FEEDBACK_OPTION_FAILURE"
)<void, any, Error>();

export const actionAuditselectSurveyDeployment = createAsyncAction(
  "@survey-reports/ACTION_AUDIT_SELECTION_SURVEY_DEPLOYMENT_REQUEST",
  "@survey-reports/ACTION_AUDIT_SELECTION_SURVEY_DEPLOYMENT_SUCCESS",
  "@survey-reports/ACTION_AUDIT_SELECTION_SURVEY_DEPLOYMENT_FAILURE"
)<SchoolNameDeplyments, void, Error>();

export const changeReportsSearchBarString = createAction(
  "@survey-reports/CHANGE_REPORTS_SEARCH_BAR_STRING"
)<string | undefined>();

export const changeSelectedSurveyDeployment = createAction(
  "@survey-reports/CHANGE_SELECTED_SURVEY_DEPLOYMENT"
)<SurveyDeployment<number, Survey<number>> | undefined>();

export const showInsightSubgroupInfoDialog = createAction(
  "@survey-reports/SHOW_INSIGHT_SUBGROUP_INFO_DIALOG"
)<InsightSubgroupInfoDialogType>();
export const hideInsightSubgroupInfoDialog = createAction(
  "@survey-reports/HIDE_INSIGHT_SUBGROUP_INFO_DIALOG"
)<void>();

export const getDomainsAndItemsByIds = createAsyncAction(
  "@survey-reports/GET_DOMAINS_AND_ITEMS_BY_IDS_REQUEST",
  "@survey-reports/GET_DOMAINS_AND_ITEMS_BY_IDS_SUCCESS",
  "@survey-reports/GET_DOMAINS_AND_ITEMS_BY_IDS_FAILURE"
)<GetDomainsAndItemsByIdsRequest, GetDomainsAndItemsByIdsResponse, Error>();
