import React, { useState } from "react";
import { useIntl } from "react-intl";
import { FormGroup, InputGroup } from "@blueprintjs/core";

const useNewUser = () => {
  const intl = useIntl();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstName(value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLastName(value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  }

  const newUserComponent = (
    <>
      <FormGroup
        label={intl.formatMessage({
          id: "app.invites-table.cells.first-name",
        })}
      >
        <InputGroup
          required
          fill
          value={firstName}
          onChange={handleFirstNameChange}
          placeholder={intl.formatMessage({
            id: "app.forms.first-name.placeholder",
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({
          id: "app.invites-table.cells.last-name",
        })}
      >
        <InputGroup
          required
          fill
          value={lastName}
          onChange={handleLastNameChange}
          placeholder={intl.formatMessage({
            id: "app.forms.last-name.placeholder",
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({
          id: "app.users-table.columns.email",
        })}
      >
        <InputGroup
          type="email"
          required
          fill
          value={email}
          onChange={handleEmailChange}
          placeholder={intl.formatMessage({
            id: "app.forms.email.placeholder",
          })}
        />
      </FormGroup>
    </>
  );

  return {
    newUserComponent,
    firstName,
    lastName,
    email,
    resetForm,
  };
};

export default useNewUser;
