import React from "react";
import { useIntl } from "react-intl";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import SurveysDeploymentTable from "../../survey-deployment/table/surveys-deployment-table/SurveysDeploymentTable";

type OwnProps = {};

type Props = OwnProps;

const ManageDeletedData: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  return (
    <>
    <div className="teampage_container">
      <WorkspaceHeader
        title={intl.formatMessage({ id: "app.titles.manage-deleted-data" })}
      />
    </div>
      <hr className="mt-2 mb-4" />
      <div className="mb-4">
        {intl.formatMessage(
          {
            id:
              "app.survey-deployments.manage-deleted-data-workspace.description",
          },
          { b: (chunks) => <b>{chunks}</b>, br: <br/>}
        )}
      </div>
      <div className="text-2xl font-bold mb-4">
        {intl.formatMessage({ id: "app.titles.survey-deployment" })}:
      </div>
      <SurveysDeploymentTable
        darkHeader
        isTeamPlanTable
        isManagedDeletedDataWorkspace
      />
    
    </>
  );
};

export default ManageDeletedData;
