import React, { FunctionComponent, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Icon } from "@blueprintjs/core";
import { hideUpsertStrategyDialog } from "../../../../store/strategies/actions";
import UpsertStrategyDialogContent from "./UpsertStrategyDialogContent";
import { useIntl } from "react-intl";
import { hideConfirmDialog, showConfirmDialog } from "../../../../store/UIState/actions";

type OwnProps = {};

type Props = OwnProps;

const UpsertStrategyDialog: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { show, selectedStrategy } = useSelector(
    (s) => s.strategies.dialogs.strategyUpsertDialog
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const handleOpen = () => { };

  const handleDialogClose = () => {
    if (hasChanges) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            dispatch(hideUpsertStrategyDialog());
            dispatch(hideConfirmDialog());
          },
          show: true,
          intent: "warning",
          text: intl.formatMessage({
            id: "app.confirmation-dialogs.invite-users-close-confirm",
          }),
          icon: "info-sign",
          confirmButtonText: intl.formatMessage({ id: "app.titles.yes" }),
          cancelButtonText: intl.formatMessage({ id: "app.titles.no" }),
        })
      );
    }
    else {
      dispatch(hideUpsertStrategyDialog());
      dispatch(hideConfirmDialog());
    }
  };

  const dialogTitle = useMemo(() => {
    return intl.formatMessage({
      id: selectedStrategy
        ? "app.strategies.edit-strategy"
        : "app.strategies.create-new-strategy",
    });
  }, [intl, selectedStrategy]);

  return (
    <Dialog
      className={"w-2/3"}
      canOutsideClickClose={false}
      isOpen={show}
      onOpening={handleOpen}
      onClose={handleDialogClose}
      icon={<Icon icon="briefcase" iconSize={24} />}
      title={dialogTitle}
    >
      <UpsertStrategyDialogContent onClose={handleDialogClose} setHasChanges={setHasChanges} hasChanges={hasChanges} selectedStrategy={selectedStrategy} />
    </Dialog>
  );
};

export default UpsertStrategyDialog;
