import {
  CompletedSurvey,
  QuestionTag,
  Survey,
  SurveyPagesByDemographic,
  SurveyQuestion,
  SurveyQuestionAnswer,
} from "../../types";
import { API_ROOT } from "../config";
import Axios from "axios";
import {
  AddQuestionTagRequest,
  AddSurveyRequest,
  CompletedSurveyRequest,
  GetQuestionTagsRequest,
  UpdateSurveyQuestionsRequest,
  UpdateSurveyRequest,
} from "./types";
import { generateQueryString } from "../../helpers/stringUtils";

export const getSurveyQuestions = (
  surveyId: number
): Promise<SurveyQuestion<number, number, QuestionTag>[]> => {
  return Axios.get(`${API_ROOT}/surveys/${surveyId}/questions/`);
};

export const getSurveyQuestionsByDomain = (
  domainId: number
): Promise<SurveyQuestion[]> => {
  return Axios.get(`${API_ROOT}/surveys/domain/${domainId}/questions/`);
};

export const getSurveyQuestionsByHash = (
  hash: string,
  school: string,
  sessionHash: string,
  password?: string
): Promise<CompletedSurvey> => {
  return Axios.get(
    `${API_ROOT}/surveys/questions?key=${hash}&school=${school}${
      sessionHash === "" ? "" : `&session=${sessionHash}`
    }${!!password ? `&survey-password=${password}` : ""}`
  );
};

export const addSurvey = (request: AddSurveyRequest): Promise<Survey> => {
  return Axios.post(`${API_ROOT}/surveys/`, request);
};

export const updateSurvey = (
  surveyId: number,
  request: UpdateSurveyRequest
): Promise<Survey> => {
  return Axios.put(`${API_ROOT}/surveys/${surveyId}/`, request);
};

export const getSurveys = (): Promise<Survey[]> => {
  return Axios.get(`${API_ROOT}/surveys/`);
};

export const getSurvey = (surveyId: number): Promise<Survey> => {
  return Axios.get(`${API_ROOT}/surveys/${surveyId}/`);
};

export const editSurveyQuestionsInSelectedSurvey = (
  surveyId: number,
  request: UpdateSurveyQuestionsRequest
): Promise<void> => {
  return Axios.put(`${API_ROOT}/surveys/${surveyId}/questions/`, request);
};

export const deleteSurvey = (surveyId: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/surveys/${surveyId}`);
};

export const saveCompletedSurvey = (
  hash: string,
  request: CompletedSurveyRequest,
  school: string,
  sessionHash: string
): Promise<void> => {
  return Axios.post(
    `${API_ROOT}/surveys/completed?key=${hash}&school=${school}&session=${sessionHash}`,
    request
  );
};

export const saveSurveyQuestionAnswer = (
  hash: string,
  school: string,
  sessionHash: string,
  request: SurveyQuestionAnswer
): Promise<void> => {
  return Axios.put(
    `${API_ROOT}/question/completed?session=${sessionHash}&key=${hash}&school=${school}`,
    request
  );
};

export const getSurveyPagesGroupedByDemographic = (
  surveyId: number
): Promise<SurveyPagesByDemographic> => {
  return Axios.get(`${API_ROOT}/surveys/${surveyId}/demographic-pages/`);
};

export const getQuestionTags = (
  request: GetQuestionTagsRequest
): Promise<QuestionTag[]> => {
  let url = `${API_ROOT}/surveys/questions/tags/`;

  if (request) {
    const queryStr = generateQueryString(request);
    if (queryStr.length) {
      url += "?" + queryStr;
    }
  }

  return Axios.get(url);
};

export const getQuestionTagById = (
  id: number | string
): Promise<QuestionTag> => {
  return Axios.get(`${API_ROOT}/surveys/questions/tags/${id}`);
};

export const addQuestionTag = (
  request: AddQuestionTagRequest
): Promise<QuestionTag> => {
  return Axios.post(`${API_ROOT}/surveys/questions/tags/`, request);
};

export const updateQuestionTag = ({
  id,
  tag,
}: Pick<QuestionTag, "id" | "tag">): Promise<QuestionTag> => {
  return Axios.put(`${API_ROOT}/surveys/questions/tags/${id}`, { tag: tag });
};

export const deleteQuestionTag = (id: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/surveys/questions/tags/${id}`);
};
