import * as React from "react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import DomainSelector from "../selectors/DomainSelector";
import {
  Button,
  Card,
  InputGroup,
  Tag,
  TextArea,
  Tooltip,
} from "@blueprintjs/core";
import { SurveyQuestionExcludeBaseModelType } from "../../../types";
import { useDispatch } from "react-redux";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { Locale, LocaleDisplayedValues } from "../../../store/UIState";
import _ from "lodash";
import NaOptionSwitch from "./NaOptionSwitch";
import CreatableTagsSelector from "../selectors/CreatableTagsSelector";
import SegmentationCodeSelector from "./SegmentationCodeSelector";

type OwnProps = {
  surveyId: number;
  item: SurveyQuestionExcludeBaseModelType;
  surveyAvailableTranslations: Locale[];
  onRemoveQuestionClick: (page: number, order: number) => void;
  onQuestionFieldUpdate: (
    page: number,
    order: number,
    field: string,
    value: string | number | number[] | boolean | undefined
  ) => void;
  onQuestionLeadInUpdate: (
    page: number,
    order: number,
    leadIn?: string
  ) => void;
  segmentationCodes?: string[];
};

type Props = OwnProps;

const SurveyQuestionForm: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    surveyId,
    item,
    surveyAvailableTranslations,
    onRemoveQuestionClick,
    onQuestionFieldUpdate,
    onQuestionLeadInUpdate,
    segmentationCodes = [],
  } = props;

  const handleQuestionTextFieldFocus = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    onQuestionFieldUpdate(item.page, item.order, name, value);
  };

  const handleQuestionLeadInFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onQuestionLeadInUpdate(item.page, item.order, value);
  };

  const handleDeleteSurveyQuestionClick = (e: React.MouseEvent<HTMLElement>) =>
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onSurveyQuestionDeleteConfirmed();
        },
        show: true,
        intent: "danger",
        icon: "trash",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-survey-question",
        }),
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
        cancelButtonText: intl.formatMessage({ id: "app.titles.cancel" }),
      })
    );

  const onSurveyQuestionDeleteConfirmed = () => {
    dispatch(hideConfirmDialog());
    onRemoveQuestionClick(item.page, item.order);
  };

  const translations = useMemo(() => {
    return item.translations ? _.entries(item.translations) : [];
  }, [item.translations]);

  const handleNaOptionUsageChange = (value: boolean) => {
    onQuestionFieldUpdate(
      item.page,
      item.order,
      "is_not_applicable_answer",
      value
    );
  };

  const handleTagsChange = (tagsId: number[]) => {
    onQuestionFieldUpdate(item.page, item.order, "question_tags", tagsId);
  };

  const handleDomainChange = (domainId: number) => {
    onQuestionFieldUpdate(item.page, item.order, "domain", domainId);
  };

  return (
    <Card elevation={1} className="mt-2 p-2 bg-gray-200">
      <div
        className="flex justify-between items-center"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DomainSelector
          surveyQuestion={item}
          surveyAvailableTranslations={surveyAvailableTranslations}
          onDomainChange={handleDomainChange}
        />
        <NaOptionSwitch
          domainId={item.domain}
          checked={item.is_not_applicable_answer}
          onChange={handleNaOptionUsageChange}
        />
        <Button
          title={intl.formatMessage({
            id: "app.surveys-table.columns.delete",
          })}
          icon="delete"
          intent="danger"
          onClick={handleDeleteSurveyQuestionClick}
        />
      </div>
      <div className="py-2 w-1/3">
        <CreatableTagsSelector
          surveyId={surveyId}
          selectedTags={(item.question_tags as unknown) as number[]}
          onTagsChange={handleTagsChange}
        />
      </div>
      <div className="border border-gray-500 p-2">
        <div className="flex justify-between mb-2">
          <span>
            {intl.formatMessage({ id: "app.surveys.survey-question" })}{" "}
            {item.order + 1}
            {":"}
          </span>
          {!!translations.length && (
            <div className="cursor-default -m-1">
              {translations.map(([locale, value]) => (
                <Tag
                  intent={value ? "success" : "warning"}
                  minimal
                  className="m-1"
                >
                  <Tooltip
                    key={locale}
                    content={value?.text}
                    popoverClassName={value?.text ? undefined : "italic"}
                  >
                    {LocaleDisplayedValues[locale as Locale] ||
                      (locale as string)}
                  </Tooltip>
                </Tag>
              ))}
            </div>
          )}
        </div>
        <div className="flex gap-2 mb-2">
          <InputGroup
            className="w-1/4 min-w-12"
            placeholder={intl.formatMessage({
              id:
                "app.survey-deployment-dialog.prompts-tab.lead-in.placeholder",
            })}
            name="lead_in"
            defaultValue={item.lead_in}
            key={`${item.page}_${item.lead_in}`}
            onBlur={handleQuestionLeadInFocus}
          />
          <InputGroup
            name={"short_code"}
            maxLength={32}
            defaultValue={item.short_code}
            title={intl.formatMessage({
              id: "app.surveys.survey-question.short-code",
            })}
            placeholder={
              intl.formatMessage({
                id: "app.surveys.survey-question.short-code",
              }) + "..."
            }
            onBlur={handleQuestionTextFieldFocus}
          />
          <InputGroup
            name={"variable_name"}
            maxLength={128}
            defaultValue={item.variable_name}
            title={intl.formatMessage({
              id: "app.surveys.survey-question.variable-name",
            })}
            placeholder={
              intl.formatMessage({
                id: "app.surveys.survey-question.variable-name",
              }) + "..."
            }
            onBlur={handleQuestionTextFieldFocus}
          />
          <SegmentationCodeSelector
            items={segmentationCodes}
            value={item.segmentation_code}
            onChange={(value) =>
              onQuestionFieldUpdate(
                item.page,
                item.order,
                "segmentation_code",
                value
              )
            }
          />
        </div>
        <TextArea
          className="italic min-height-textarea"
          placeholder={intl.formatMessage({
            id: "app.surveys.survey-question.placeholder",
          })}
          growVertically
          name="text"
          fill
          defaultValue={item.text}
          onBlur={handleQuestionTextFieldFocus}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
    </Card>
  );
};

export default SurveyQuestionForm;
