import React from "react";
import { useIntl } from "react-intl";
import DashboardCard from "../DashboardCard";
import { District, Survey, SurveyDeployment } from "../../../../types";
import ActiveSurveysDeploymentList from "./ActiveSurveysDeploymentList";
import { Button, NonIdealState } from "@blueprintjs/core";

type OwnProps = {
  activeSurveysDeployment?: SurveyDeployment<District, Survey<number>>[];
  onMoveToCollectDataPage: () => void;
  isDataWorkspaceLocked: boolean;
};

type Props = OwnProps;

const SurveyAdministrationWidget: React.FC<Props> = ({
  activeSurveysDeployment,
  onMoveToCollectDataPage,
  isDataWorkspaceLocked,
}: Props) => {
  const intl = useIntl();

  return (
    <DashboardCard
      title={intl.formatMessage({ id: "app.dashboard.survey-administration" })}
      body={
        !!activeSurveysDeployment?.length ? (
          <ActiveSurveysDeploymentList
            activeSurveysDeployment={activeSurveysDeployment}
          />
        ) : (
          <NonIdealState
            title={intl.formatMessage({
              id: "app.non-ideal-state.no-active-data-collection",
            })}
            action={
              <Button
                text={intl.formatMessage({ id: "app.collect-data.title" })}
                title={intl.formatMessage({ id: "app.collect-data.title" })}
                onClick={onMoveToCollectDataPage}
                intent="primary"
                disabled={isDataWorkspaceLocked}
              />
            }
          />
        )
      }
      centeredBody
    />
  );
};

export default SurveyAdministrationWidget;
