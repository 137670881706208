/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { PlanStrategy, SortByEnum } from '../../../../../types';
import NoStrategy from './no-strategy/NoStrategies';
import { Spinner } from '@blueprintjs/core';
import StrategyItem from './strategy-item/StrategyItem';
import './StrategiesList.scss';
import StrategiesListFilter from '../strategies-list-filter/StrategiesListFilter';
import StrategicDisclaimer from '../../strategic-disclaimer/StrategicDisclaimer';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getSchools } from '../../../../../store/schools/actions';
import { useDispatch, useSelector } from 'react-redux';

type OwnProps = {};

type Props = OwnProps;
const MIN_STRATEGY_PER_LOAD = 5;

const StrategiesList: FunctionComponent<Props> = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // STATE
  const [displayedPlanStrategies, setDisplayedPlanStrategies] = useState<
    PlanStrategy[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const [displayedItems, setDisplayedItems] = useState<number>(MIN_STRATEGY_PER_LOAD);
  const [sortBy, setSortBy] = useState<boolean>(true);

  const schools = useSelector((s) => s.schools.schools);

  const filteredPlanStrategy = (data: React.SetStateAction<PlanStrategy[]>) => {
    setDisplayedPlanStrategies(data);
  };

  const data = useMemo(() => {
    let sorted = [];
    // return filteredGoals
    sorted = displayedPlanStrategies.sort(function (a, b) {
      let c: any = new Date(a.created_at);
      let d: any = new Date(b.created_at);
      return c - d;
    });

    if (sortBy)
      sorted = sorted.reverse();
    return sorted;
  }, [displayedPlanStrategies, sortBy]);

  const loading = (isLoader: boolean | undefined) => {
    setIsLoading(isLoader);
  }

  const [sortOption, setSortOption] = useState<string>('');
  const [selectOpen, setSelectOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<typeof sortOption>) => {
    setSortOption(event.target.value);
    const sortBy = event.target.value;

    if (sortBy === SortByEnum.NEWFIRST)
      setSortBy(true);
    else if (sortBy === SortByEnum.OLDESTFIRST)
      setSortBy(false);
  };

  const handleSelectClose = () => {
    setSelectOpen(false);
  };

  const handleSelectOpen = () => {
    setSelectOpen(true);
  };

  useEffect(() => {
    dispatch(getSchools.request());
  }, [dispatch]);

  return (
    <>
      <div className="StrategiesListFilter">
        <StrategiesListFilter filteredPlanStrategy={filteredPlanStrategy} isLoader={loading} />
      </div>

      <div className="strategies-content">
        <div className="strategies-content__head">
          <label>{intl.formatMessage({ id: "app.strategies.count-label" }, { total: displayedPlanStrategies?.length })}</label>

          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-controlled-open-select-label">{intl.formatMessage({ id: "app.title.sortby" })}</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              className='dropdown-list sort-strategy-dropdown'
              open={selectOpen}
              onClose={handleSelectClose}
              onOpen={handleSelectOpen}
              value={sortOption}
              label={intl.formatMessage({ id: "app.title.sortby" })}
              onChange={handleChange}
            >
              <MenuItem value={SortByEnum.NEWFIRST}>{intl.formatMessage({ id: "app.strategies.sortby.lable-newest" })}</MenuItem>
              <MenuItem value={SortByEnum.OLDESTFIRST}>{intl.formatMessage({ id: "app.strategies.sortby.lable-oldest" })}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="strategies-content__list"  style={{scrollbarWidth:"thin"}}>
          <ul>
          {
            data.length ? data.slice(0, displayedItems).map((ps) => (
              <StrategyItem key={`plan_strategy_${ps.id}`} strategy={ps} schools={schools} />
            ))
              : (
                (isLoading || isLoading === undefined) ?
                  <Spinner intent="primary" className="my-2" /> :
                  <NoStrategy />
              )
          }
          </ul>
          <div>
            <div className="strategies-action">
              <div className="load-more">
                {
                  displayedItems < data.length && (
                    <div className="load-more">
                      <a className="stroke-btn" onClick={() => setDisplayedItems(displayedItems + MIN_STRATEGY_PER_LOAD)}>
                        {intl.formatMessage({ id: "app.title.load-more" })}
                      </a>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StrategiesList;