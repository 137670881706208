import React, { FunctionComponent } from "react";
import { Dialog, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { hideDomainAnswerSetUpsertDialog } from "../../../store/domain-answer-set/actions";
import DomainAnswerSetForm from "./DomainAnswerSetForm";

type OwnProps = {};

type Props = OwnProps;

const DomainAnswerSetUpsertDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectedDomainAnswerSet = useSelector(
    (s) => s.domainsAnswerSet.selectedDomainAnswerSet
  );
  const show = useSelector(
    (s) => s.domainsAnswerSet.dialogs.domainAnswerSetUpsertDialog?.show
  );
  const onSuccess = useSelector(
    (s) => s.domainsAnswerSet.dialogs.domainAnswerSetUpsertDialog?.onSuccess
  );
  const editing = useSelector(
    (s) => s.domainsAnswerSet.dialogs.domainAnswerSetUpsertDialog?.editing
  );
  const handleClose = () => {
    dispatch(hideDomainAnswerSetUpsertDialog());
  };

  return (
    <Dialog
      style={{ minWidth: "600px" }}
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon="clipboard" iconSize={24} />}
      title={intl.formatMessage({
        id: editing
          ? "app.domains.dialogs.edit-answer-set.title"
          : "app.domains.dialogs.create-answer-set.title",
      })}
      portalClassName="answer-set-portal"
    >
      <DomainAnswerSetForm
        onSuccess={onSuccess}
        editingValuesOnly={editing}
        selectedDomainAnswerSet={selectedDomainAnswerSet}
      />
    </Dialog>
  );
};

export default DomainAnswerSetUpsertDialog;
