import { createAction, createAsyncAction } from "typesafe-actions";
import {
  District,
  SummaryStatistics,
  Survey,
  SurveyDeployment,
  SurveyDeploymentStatistics,
  SurveyPagesByDemographic,
  SurveyDeploymentDistrictComparison
} from "../../types";
import {
  AddSurveyDeploymentRequest,
  DownloadSampleLetterRequestType,
  UpdateSurveyDeploymentRequest,
} from "../../api/surveys-deployment/types";

export const setSelectedSurveyDeployment = createAction(
  "surveys-deployment/SET_SELECTED_SURVEY_DEPLOYMENT"
)<SurveyDeployment<District, Survey<number>> | undefined>();

export const setSelectedDistrictComarprisonSurvey = createAction(
  "surveys-deployment/SET_SELECTED_DISTRICT_COMPARISON_SURVEY"
)<SurveyDeploymentDistrictComparison[]>();

export const showSurveyDeploymentDialog = createAction(
  "surveys-deployment/SHOW_SURVEY_DEPLOYMENT_DIALOG"
)<void>();

export const hideSurveyDeploymentDialog = createAction(
  "surveys-deployment/HIDE_SURVEY_DEPLOYMENT_DIALOG"
)<void>();

export const getSurveysDeployment = createAsyncAction(
  "surveys-deployment/GET_SURVEYS_DEPLOYMENT_REQUEST",
  "surveys-deployment/GET_SURVEYS_DEPLOYMENT_SUCCESS",
  "surveys-deployment/GET_SURVEYS_DEPLOYMENT_FAILURE"
)<
  { planId?: number; isDeleted?: boolean },
  SurveyDeployment<District, Survey<number>>[],
  Error
>();

export const addSurveyDeployment = createAsyncAction(
  "surveys-deployment/ADD_SURVEY_DEPLOYMENT_REQUEST",
  "surveys-deployment/ADD_SURVEY_DEPLOYMENT_SUCCESS",
  "surveys-deployment/ADD_SURVEY_DEPLOYMENT_FAILURE"
)<
  AddSurveyDeploymentRequest,
  SurveyDeployment<District, Survey<number>>,
  Error
>();

export const updateSurveyDeployment = createAsyncAction(
  "surveys-deployment/UPDATE_SURVEY_DEPLOYMENT_REQUEST",
  "surveys-deployment/UPDATE_SURVEY_DEPLOYMENT_SUCCESS",
  "surveys-deployment/UPDATE_SURVEY_DEPLOYMENT_FAILURE"
)<
  {
    surveyDeploymentId: number;
    updatedSurveyDeployment: UpdateSurveyDeploymentRequest;
  },
  SurveyDeployment<District, Survey<number>>,
  Error
>();

export const deleteSurveyDeployment = createAsyncAction(
  "surveys-deployment/DELETE_SURVEY_DEPLOYMENT_REQUEST",
  "surveys-deployment/DELETE_SURVEY_DEPLOYMENT_SUCCESS",
  "surveys-deployment/DELETE_SURVEY_DEPLOYMENT_FAILURE"
)<number, number, Error>();

export const getSurveysDeploymentStatistics = createAsyncAction(
  "@surveys-deployment/GET_SURVEYS_DEPLOYMENT_STATISTICS_REQUEST",
  "@surveys-deployment/GET_SURVEYS_DEPLOYMENT_STATISTICS_SUCCESS",
  "@surveys-deployment/GET_SURVEYS_DEPLOYMENT_STATISTICS_FAILURE"
)<string | undefined, SurveyDeploymentStatistics[], Error>();

export const getSummaryStatistics = createAsyncAction(
  "@surveys-deployment/GET_SUMMARY_STATISTICS_REQUEST",
  "@surveys-deployment/GET_SUMMARY_STATISTICS_SUCCESS",
  "@surveys-deployment/GET_SUMMARY_STATISTICS_FAILURE"
)<
  { school?: string; surveyDeploymentId?: string },
  SummaryStatistics[],
  Error
>();

export const getSurveyDeploymentsBySchool = createAsyncAction(
  "@surveys-deployment/GET_SURVEY_DEPLOYMENTS_BY_SCHOOL_REQUEST",
  "@surveys-deployment/GET_SURVEY_DEPLOYMENTS_BY_SCHOOL_SUCCESS",
  "@surveys-deployment/GET_SURVEY_DEPLOYMENTS_BY_SCHOOL_FAILURE"
)<number, SurveyDeployment<number, Survey<number>>[], Error>();

export const getSurveyPagesGroupedByDemographic = createAsyncAction(
  "@surveys-deployment/GET_SURVEY_PAGES_GROUPED_BY_DEMOGRAPHIC_REQUEST",
  "@surveys-deployment/GET_SURVEY_PAGES_GROUPED_BY_DEMOGRAPHIC_SUCCESS",
  "@surveys-deployment/GET_SURVEY_PAGES_GROUPED_BY_DEMOGRAPHIC_FAILURE"
)<number, SurveyPagesByDemographic, Error>();

export const downloadSampleLetters = createAsyncAction(
  "@surveys-deployment/DOWNLOAD_SAMPLE_LETTERS_REQUEST",
  "@surveys-deployment/DOWNLOAD_SAMPLE_LETTERS_SUCCESS",
  "@surveys-deployment/DOWNLOAD_SAMPLE_LETTERS_FAILURE"
)<
  {
    surveyDeploymentId: number;
    planId: number;
    body: DownloadSampleLetterRequestType;
  },
  Blob,
  Error
>();

export const closeSurveyDeployment = createAsyncAction(
  "@surveys-deployment/CLOSE_SURVEY_DEPLOYMENT_REQUEST",
  "@surveys-deployment/CLOSE_SURVEY_DEPLOYMENT_SUCCESS",
  "@surveys-deployment/CLOSE_SURVEY_DEPLOYMENT_FAILURE"
)<
  { surveyDeploymentId: number; planId?: number },
  SurveyDeployment<District, Survey<number>>,
  Error
>();

export const deleteSurveyDeploymentSoftly = createAsyncAction(
  "surveys-deployment/DELETE_SURVEY_DEPLOYMENT_SOFTLY_REQUEST",
  "surveys-deployment/DELETE_SURVEY_DEPLOYMENT_SOFTLY_SUCCESS",
  "surveys-deployment/DELETE_SURVEY_DEPLOYMENT_SOFTLY_FAILURE"
)<number, number, Error>();
