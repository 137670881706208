import { createReducer, RootAction } from "typesafe-actions";
import { Meeting } from "../../types";
import actions from "../actions";

type ActionName =
  | "getMeetings"
  | "addMeeting"
  | "getMeetingById"
  | "updateMeeting"
  | "deleteMeeting";

export type MeetingsState = {
  meetings: Meeting[];
  meeting?: Meeting<string, false>;
  dialogs: {
    showMeetingDialog: {
      show?: boolean;
      meeting?: Meeting;
      viewOnly?: boolean;
      showMeetingTemplates?: boolean;
      fromAdminView?: boolean;
    };
  };
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: MeetingsState = {
  meetings: [],
  dialogs: { showMeetingDialog: {} },
  loading: {},
  errors: {},
};

export const meetingsReducer = createReducer<MeetingsState, RootAction>(
  initialState
)
  .handleAction(
    actions.showMeetingDialog,
    (state, action): MeetingsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        showMeetingDialog: {
          show: true,
          meeting: action.payload.meeting,
          viewOnly: action.payload.viewOnly,
          showMeetingTemplates: action.payload.showMeetingTemplates,
          fromAdminView: action.payload.fromAdminView,
        },
      },
    })
  )
  .handleAction(
    actions.hideMeetingDialog,
    (state, action): MeetingsState => ({
      ...state,
      meeting: undefined,
      dialogs: {
        ...state.dialogs,
        showMeetingDialog: {},
      },
    })
  )
  // get meetings
  .handleAction(
    actions.getMeetings.request,
    (state, action): MeetingsState => ({
      ...state,
      loading: {
        ...state.loading,
        getMeetings: true,
      },
      errors: {
        ...state.errors,
        getMeetings: undefined,
      },
    })
  )
  .handleAction(
    actions.getMeetings.success,
    (state, action): MeetingsState => ({
      ...state,
      meetings: action.payload,
      loading: {
        ...state.loading,
        getMeetings: false,
      },
    })
  )
  .handleAction(
    actions.getMeetings.failure,
    (state, action): MeetingsState => ({
      ...state,
      errors: {
        ...state.errors,
        getMeetings: action.payload,
      },
      loading: {
        ...state.loading,
        getMeetings: false,
      },
    })
  )
  // add meeting
  .handleAction(
    actions.addMeeting.request,
    (state, action): MeetingsState => ({
      ...state,
      loading: {
        ...state.loading,
        addMeeting: true,
      },
      errors: {
        ...state.errors,
        addMeeting: undefined,
      },
    })
  )
  .handleAction(
    actions.addMeeting.success,
    (state, action): MeetingsState => ({
      ...state,
      meetings: [...state.meetings, action.payload],
      loading: {
        ...state.loading,
        addMeeting: false,
      },
    })
  )
  .handleAction(
    actions.addMeeting.failure,
    (state, action): MeetingsState => ({
      ...state,
      errors: {
        ...state.errors,
        addMeeting: action.payload,
      },
      loading: {
        ...state.loading,
        addMeeting: false,
      },
    })
  )
  // update meeting
  .handleAction(
    actions.updateMeeting.request,
    (state, action): MeetingsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateMeeting: true,
      },
      errors: {
        ...state.errors,
        updateMeeting: undefined,
      },
    })
  )
  .handleAction(
    actions.updateMeeting.success,
    (state, action): MeetingsState => ({
      ...state,
      meetings: state.meetings.map((m) =>
        m.id === action.payload.id ? action.payload : m
      ),
      loading: {
        ...state.loading,
        updateMeeting: false,
      },
    })
  )
  .handleAction(
    actions.updateMeeting.failure,
    (state, action): MeetingsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateMeeting: action.payload,
      },
      loading: {
        ...state.loading,
        updateMeeting: false,
      },
    })
  )
  .handleAction(
    actions.updateNotesInMeeting,
    (state, action): MeetingsState => {
      const { meeting_id, ...notes } = action.payload;
      return {
        ...state,
        meeting:
          meeting_id && state.meeting?.id === meeting_id
            ? {
                ...state.meeting,
                user_notes: state.meeting.user_notes.map((uNotes) =>
                  uNotes.id === notes.id ? notes : uNotes
                ),
              }
            : state.meeting,
      };
    }
  )
  .handleAction(
    actions.addNotesToMeeting,
    (state, action): MeetingsState => {
      const { meeting_id, ...notes } = action.payload;
      return {
        ...state,
        meeting:
          meeting_id && state.meeting?.id === meeting_id
            ? {
                ...state.meeting,
                user_notes: [...state.meeting.user_notes, notes],
              }
            : state.meeting,
      };
    }
  )
  .handleAction(
    actions.removeNotesFromMeeting,
    (state, action): MeetingsState => {
      if (state.meeting) {
        return {
          ...state,
          meeting: {
            ...state.meeting,
            user_notes: state.meeting.user_notes.filter(
              (uN) => uN.id !== action.payload
            ),
          },
        };
      }
      return state;
    }
  )
  // delete meeting
  .handleAction(
    actions.deleteMeeting.request,
    (state, action): MeetingsState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteMeeting: true,
      },
      errors: {
        ...state.errors,
        deleteMeeting: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteMeeting.success,
    (state, action): MeetingsState => ({
      ...state,
      meetings: state.meetings.filter((m) => m.id !== action.payload),
      loading: {
        ...state.loading,
        deleteMeeting: false,
      },
    })
  )
  .handleAction(
    actions.deleteMeeting.failure,
    (state, action): MeetingsState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteMeeting: action.payload,
      },
      loading: {
        ...state.loading,
        deleteMeeting: false,
      },
    })
  )
  // get meeting by id
  .handleAction(
    actions.getMeetingById.request,
    (state, action): MeetingsState => ({
      ...state,
      loading: {
        ...state.loading,
        getMeetingById: true,
      },
      errors: {
        ...state.errors,
        getMeetingById: undefined,
      },
    })
  )
  .handleAction(
    actions.getMeetingById.success,
    (state, action): MeetingsState => ({
      ...state,
      meeting: action.payload,
      loading: {
        ...state.loading,
        getMeetingById: false,
      },
    })
  )
  .handleAction(
    actions.getMeetingById.failure,
    (state, action): MeetingsState => ({
      ...state,
      errors: {
        ...state.errors,
        getMeetingById: action.payload,
      },
      loading: {
        ...state.loading,
        getMeetingById: false,
      },
    })
  );
