import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";
import { addNotesToMeeting, removeNotesFromMeeting } from "../meetings/actions";

export const getNotesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getNotes.request)),
    switchMap((action) =>
      from(api.notes.getNotes(action.payload)).pipe(
        map(actions.getNotes.success),
        catchError((error) => of(actions.getNotes.failure(error)))
      )
    )
  );
};

export const addNotesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addNotes.request)),
    switchMap((action) => {
      const { onSuccess, ...body } = action.payload;
      
      return from(api.notes.addNotes(body)).pipe(
        mergeMap((notes) =>
          from(
            [
              actions.addNotes.success(notes),
              actions.addNotesToMeeting({
                ...notes,
                meeting_id: body.meeting_id,
              }),
            ],
            onSuccess && onSuccess(notes)
          )
        ),

        catchError((error) => of(actions.addNotes.failure(error)))
      );
    })
  );
};

export const updateNotesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateNotes.request)),
    switchMap((action) => {
      const { onSuccess, ...body } = action.payload.body;
      return from(api.notes.updateNotes(body, action.payload.id)).pipe(
        mergeMap((notes) =>
          from(
            [
              actions.updateNotes.success(notes),
              actions.updateNotesInMeeting({
                ...notes,
                meeting_id: body.meeting_id,
              }),
            ],
            onSuccess && onSuccess(notes)
          )
        ),

        catchError((error) => of(actions.updateNotes.failure(error)))
      );
    })
  );
};

export const deleteNoteEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteNote.request)),
    switchMap((action) =>
      from(api.notes.deleteNote(action.payload.id, action.payload.planId)).pipe(
        mergeMap((notes) =>
          from([
            actions.deleteNote.success(action.payload.id),
            actions.removeNotesFromMeeting(action.payload.id),
          ])
        ),
        catchError((error) => of(actions.deleteNote.failure(error)))
      )
    )
  );
};


export const triggerPusherEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.triggerPusherEvent.request)),
    switchMap((action) =>
      from(api.goals.triggerPusherEvent(action.payload.plan_id, { event: action.payload.event, channel_name: action.payload.channel_name, data: action.payload.data }))
        .pipe(
          map(actions.triggerPusherEvent.success),
          catchError((error) => of(actions.triggerPusherEvent.failure(error)))
        )
    )
  );
};