import { DistrictSetupTaskKey, UserNotesType, UserRole } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../App";
import { useCallback, useMemo } from "react";
import useUserRole from "../useUserRole";
import {
  completeSetup,
  markStepCompleted,
  markStepsCompleted,
  selectDistrictSetupTaskKey,
} from "../../../store/tours/actions";
import useUserPlanAssociation from "../useUserPlanAssociation";
import usePlanSwitcher from "../usePlanSwitcher";

const useNextTourCallback = (currentSetupTaskKey?: DistrictSetupTaskKey) => {
  const dispatch = useDispatch();

  const { isDCC, isSuperintendent, isTeamMember, role } = useUserRole();

  const districtSetupState = useSelector((s) => s.tours.districtSetupState);

  const isLastTourWatched = useMemo(() => {
    return districtSetupState?.lastTourWatched && !districtSetupState?.hidden;
  }, [districtSetupState]);

  const {
    planSchoolId,
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
  } = useUserPlanAssociation();
  const { switchPlanIfNeeded } = usePlanSwitcher();

  const markCalendarCompleted = useCallback(() => {
    if (planSchoolId) {
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.SetupSchoolCalendar,
          schoolId: planSchoolId,
        })
      );
    } else {
      dispatch(
        markStepCompleted({ key: DistrictSetupTaskKey.SetupDistrictCalendar })
      );
    }
  }, [planSchoolId]);

  const history = useHistory();

  const changeLocationWithTaskKeyReset = useCallback(
    (path: string) => {
      return () => {
        dispatch(selectDistrictSetupTaskKey(undefined));
        history.push(path);
      };
    },
    [history]
  );

  if (!currentSetupTaskKey) {
    return undefined;
  }

  switch (currentSetupTaskKey as DistrictSetupTaskKey) {
    case DistrictSetupTaskKey.CommunicateWithPrincipals: {
      return () => {
        switchPlanIfNeeded(
          () => {
            dispatch(
              markStepCompleted({
                key: DistrictSetupTaskKey.CommunicateWithPrincipals,
              })
            );
            history.push(
              generatePath(AuthRoutes.YourPlanDistrict, {
                workspace: "my-district",
              } as any)
            );
            dispatch(selectDistrictSetupTaskKey(undefined));
          },
          true,
          false
        );
      };
    }

    case DistrictSetupTaskKey.FamiliarizeWithSccp: {
      if (isSuperintendent || isDCC) {
        if (isSingleSchoolDistrict) {
          return changeLocationWithTaskKeyReset(
            generatePath(AuthRoutes.YourPlanDistrict, {
              workspace: "my-district",
            } as any)
          );
        } else {
          return () => {
            dispatch(
              selectDistrictSetupTaskKey(
                DistrictSetupTaskKey.CommunicateWithPrincipals
              )
            );
          };
        }
      }

      if (isTeamMember || role === UserRole.Principal) {
        return changeLocationWithTaskKeyReset(
          generatePath(AuthRoutes.YourPlanTeam, {
            workspace: "team",
          } as any)
        );
      }

      return () => dispatch(selectDistrictSetupTaskKey(undefined));
    }

    case DistrictSetupTaskKey.DesignateDistrictClimateCoordinator: {
      return () => {
        dispatch(
          markStepsCompleted({
            keys: [DistrictSetupTaskKey.DesignateDistrictClimateCoordinator],
          })
        );
        dispatch(
          selectDistrictSetupTaskKey(DistrictSetupTaskKey.FamiliarizeWithSccp)
        );
        history.push(
          generatePath(AuthRoutes.YourPlan, {
            workspace: "dashboard",
          } as any)
        );
      };
    }

    case DistrictSetupTaskKey.LearnAboutTeamDevelopment: {
      if (isSchoolPlanPrincipal) {
        if (districtSetupState[DistrictSetupTaskKey.InviteSchoolStaff]) {
          return () => {
            dispatch(
              markStepCompleted({
                key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
              })
            );
            switchPlanIfNeeded(
              () => {
                history.push(
                  generatePath(AuthRoutes.YourPlanTeam, {
                    workspace: "team",
                  } as any)
                );
              },
              false,
              true
            );
          };
        }
        return () => {
          dispatch(
            markStepCompleted({
              key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
            })
          );
          switchPlanIfNeeded(
            () => {
              dispatch(selectDistrictSetupTaskKey(undefined));
            },
            false,
            true
          );
        };
      }

      if (isSuperintendent || isDCC) {
        return () => {
          dispatch(
            markStepCompleted({
              key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
            })
          );
          switchPlanIfNeeded(
            () => {
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                  tabId: !isSingleSchoolDistrict
                    ? UserNotesType.DCLT_SCI_CALENDAR
                    : UserNotesType.SCLT_SCI_CALENDAR,
                } as any)
              );
            },
            !isSingleSchoolDistrict,
            false
          );
        };
      }

      return () => {
        isTeamMember && !isLastTourWatched && dispatch(completeSetup());
        dispatch(
          markStepCompleted({
            key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
          })
        );
        history.push(
          generatePath(AuthRoutes.YourPlanTeam, {
            workspace: "team",
            tabId: planSchoolId
                      ? UserNotesType.SCLT_SCI_CALENDAR
                      : UserNotesType.DCLT_SCI_CALENDAR,
          } as any)
        );
      };
    }

    case DistrictSetupTaskKey.SetupSchoolCalendar: {
      if (
        planSchoolId &&
        !districtSetupState[DistrictSetupTaskKey.SetupSchoolCalendar]?.[
          planSchoolId
        ] &&
        !isLastTourWatched &&
        (isSchoolPlanPrincipal ||
          (isDCC && isSingleSchoolDistrict) ||
          isSuperintendent)
      ) {
        return () => {
          markCalendarCompleted();
          dispatch(completeSetup());
        };
      }
      break;
    }

    case DistrictSetupTaskKey.SetupDistrictCalendar: {
      if (
        !isSingleSchoolDistrict &&
        isSuperintendent &&
        isSchoolPlanPrincipal
      ) {
        return () => {
          switchPlanIfNeeded(
            () => {
              markCalendarCompleted();
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                  tabId: UserNotesType.SCLT_SCI_CALENDAR,
                } as any)
              );
            },
            false,
            true
          );
        };
      }

      if (
        !districtSetupState[DistrictSetupTaskKey.SetupDistrictCalendar] &&
        !isLastTourWatched &&
        (isDCC || (isSuperintendent && !isSingleSchoolDistrict))
      ) {
        return () => {
          markCalendarCompleted();
          dispatch(completeSetup());
        };
      }
      break;
    }

    case DistrictSetupTaskKey.InviteOtherMembers: {
      return () => {
        dispatch(
          markStepCompleted({ key: DistrictSetupTaskKey.InviteOtherMembers })
        );
        switchPlanIfNeeded(
          () => {
            history.push(
              generatePath(AuthRoutes.YourPlanTeam, {
                workspace: "team",
              } as any)
            );
          },
          true,
          true
        );
      };
    }

    case DistrictSetupTaskKey.InviteSchoolStaff: {
      const switchToDistrictPlan =
        isSuperintendent && isSchoolPlanPrincipal && !isSingleSchoolDistrict;
      return () => {
        dispatch(
          markStepCompleted({
            key: DistrictSetupTaskKey.InviteSchoolStaff,
            schoolId: planSchoolId,
          })
        );
        switchPlanIfNeeded(
          () => {
            history.push(
              generatePath(AuthRoutes.YourPlanTeam, {
                workspace: "team",
              } as any)
            );
          },
          switchToDistrictPlan,
          true
        );
      };
    }

    case DistrictSetupTaskKey.InviteSchoolStaffPrincipal: {
      const switchToDistrictPlan =
        isSuperintendent && isSchoolPlanPrincipal && !isSingleSchoolDistrict;
      return () => {
        dispatch(
          markStepCompleted({
            key: DistrictSetupTaskKey.InviteSchoolStaff,
            schoolId: planSchoolId,
          })
        );
        switchPlanIfNeeded(
          () => {
            history.push(
              generatePath(AuthRoutes.YourPlanTeam, {
                workspace: "team",
                tabId: switchToDistrictPlan
                  ? UserNotesType.DCLT_SCI_CALENDAR
                  : UserNotesType.SCLT_SCI_CALENDAR,
              } as any)
            );
          },
          switchToDistrictPlan,
          true
        );
      };
    }

    default:
      return undefined;
  }
};

export default useNextTourCallback;
