import React, { CSSProperties, FunctionComponent, useMemo } from "react";
import { HTMLTable } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import BookmarksIcons from "../explore-strengths-and-needs-tab/BookmarksIcons";

type OwnProps = {
  items: BookmarksInfoTableItem[];
  isQuestionLevel?: boolean;
  dismissed?: boolean;
};

type Props = OwnProps;

export type BookmarksInfoTableItem = {
  title: JSX.Element | string;
  bookmarksPanel: JSX.Element;
  styles?: CSSProperties;
  item?: string;
  score?: string | number;
  deleted_in_njsci_v2?: boolean;
  itemTitle?: string;
};

const BookmarksInfoSubGroupTable: FunctionComponent<Props> = (props) => {
  const { isQuestionLevel, items, dismissed } = props;
  const intl = useIntl();

  const { nameHeader, meanHeader } = useMemo(() => {
    return {
      nameHeader: intl.formatMessage({
        id: "app.titles.sub-group",
      }),
      meanHeader: intl.formatMessage({
        id: isQuestionLevel ? "app.titles.mean" : "app.titles.domain-mean", //"app.titles.domain-overall-mean",
      }),
    };
  }, [isQuestionLevel]);

  let isQuestionLevelTable = isQuestionLevel ? "" : " ";

  const { demographic } = useSelector(
    (s) => s.needs.dialogs.itemLevelInfoAndBookmarksDialog
  );

  const isVisibleSubGroupTable = items.length > 0 && items.some(checkItem);

  function checkItem(item: any) {
    if (item.itemTitle !== intl.formatMessage({ id: `app.filters.respondent-type.${demographic}` }) && item.deleted_in_njsci_v2 !== true) {
      return true
    }
  }

  if (isVisibleSubGroupTable && !isQuestionLevel) {
    return (
      <div className="summary-modal-table-container">
      <div className="sub-table">
      <table>
        <thead>
          <tr>
            <th style={{ 'width': '25%'}}>{nameHeader}</th>
            <th style={{ 'width': '60%'}}>{meanHeader}</th>
            <th style={{ 'width': '15%'}}>{intl.formatMessage({ id: "app.titles.bookmarks" })}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            if (item.itemTitle !== intl.formatMessage({ id: `app.filters.respondent-type.${demographic}` }))
              return (
                !item.deleted_in_njsci_v2 && (
                  <tr key={index} style={item.styles}>
                    <td className="text-left-align">{item.title}</td>
                    <td>
                      {item.score
                        ? parseFloat(item.score.toString()).toFixed(2)
                        : item.score}
                    </td>
                    <td>{item.bookmarksPanel}</td>
                  </tr>
                )
              );
          })
          }
        </tbody>
      </table>
      </div>
      </div>
    );
  } else if (isQuestionLevel && isVisibleSubGroupTable) {
    return (
      <div className="summary-modal-table-container">
      <div className="sub-table">
      <table>
        <thead>
          <tr>
            <th style={{ 'width': '25%'}}>{nameHeader}</th>
            <th style={{ 'width': '45%'}}>{intl.formatMessage({ id: "app.titles.item" })}</th>
            <th style={{ 'width': '15%'}}>{meanHeader}</th>
            <th style={{ 'width': '15%'}}>{intl.formatMessage({ id: "app.titles.bookmarks" })}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            if (item.itemTitle !== intl.formatMessage({ id: `app.filters.respondent-type.${demographic}` }))
              return (
                !item.deleted_in_njsci_v2 && (
                  <tr key={index} style={item.styles}>
                    <td className="text-left-align">{item.title}</td>
                    <td className="text-left-align">{item.item}</td>
                    <td>
                      {item.score
                        ? parseFloat(item.score.toString()).toFixed(2)
                        : item.score}
                    </td>
                    <td>{item.bookmarksPanel}</td>
                  </tr>
                )
              );
          })
          }
        </tbody>
      </table>
      </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default BookmarksInfoSubGroupTable;
