import React, { FunctionComponent } from "react";
import { Tag } from "@blueprintjs/core";
import { ActivityTypeEnum } from "../../../../../types";
import { useIntl } from "react-intl";

type OwnProps = {
  activityType: ActivityTypeEnum;
};

type Props = OwnProps;

const ActivityTypeTag: FunctionComponent<Props> = (props) => {
  const { activityType } = props;

  const intl = useIntl();

  return (
    <Tag round className={`activity-type-tag ${activityType}`}>
      {intl.formatMessage(
        { id: "app.titles.activity-type" },
        { type: activityType }
      )}
    </Tag>
  );
};

export default ActivityTypeTag;
