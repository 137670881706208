import { createAction, createAsyncAction } from "typesafe-actions";
import {
  AddPlanStrategyDialogType,
  AddStrategyToGoalDialogType,
  PlanStrategy,
  Strategy,
} from "../../types";
import {
  AddStrategyRequest,
  UpdateStrategyRequest,
} from "../../api/strategies/types";
import React from "react";

export const showImplementationStatusDialog = createAction(
  "@strategies/SHOW_IMPLEMENTATION_STATUS_DIALOG"
)<{
  planStrategy: PlanStrategy;
  implementation?: number;
  onUpdateSuccess?: Function;
}>();
export const hideImplementationStatusDialog = createAction(
  "@strategies/HIDE_IMPLEMENTATION_STATUS_DIALOG"
)<void>();

export const showUpdatePlanStrategyDetailsDialog = createAction(
  "@strategies/showUpdatePlanStrategyDetailsDialog"
)<{ planStrategy: PlanStrategy; goalTitle?: string; onConfirm?: Function }>();
export const hideUpdatePlanStrategyDetailsDialog = createAction(
  "@strategies/hideUpdatePlanStrategyDetailsDialog"
)<void>();

export const showStrategyResourceComponentsDialog = createAction(
  "@strategies/SHOW_STRATEGY_RESOURCE_COMPONENTS_DIALOG"
)<{ strategyName: string; contentName: string; content: React.ReactNode }>();
export const hideStrategyResourceComponentsDialog = createAction(
  "@strategies/HIDE_STRATEGY_RESOURCE_COMPONENTS_DIALOG"
)<void>();

export const showDetailsStrategyDialog = createAction(
  "@strategies/SHOW_DETAILS_STRATEGY_DIALOG"
)<Strategy | PlanStrategy>();
export const hideDetailsStrategyDialog = createAction(
  "@strategies/HIDE_DETAILS_STRATEGY_DIALOG"
)<void>();

export const showUpsertStrategyDialog = createAction(
  "@strategies/SHOW_DOMAIN_STRATEGY_DIALOG"
)<Strategy | void>();
export const hideUpsertStrategyDialog = createAction(
  "@strategies/HIDE_DOMAIN_STRATEGY_DIALOG"
)<void>();

export const showAddPlanStrategyDialog = createAction(
  "@strategies/SHOW_ADD_PLAN_STRATEGY_DIALOG"
)<AddPlanStrategyDialogType | void>();

export const reopenAddPlanStrategyDialog = createAction(
  "@strategies/REOPEN_ADD_PLAN_STRATEGY_DIALOG"
)<void>();

export const hideAddPlanStrategyDialog = createAction(
  "@strategies/HIDE_ADD_PLAN_STRATEGY_DIALOG"
)<void>();

export const showAddStrategyToGoalDialog = createAction(
  "@strategies/SHOW_ADD_STRATEGY_TO_GOAL_DIALOG"
)<AddStrategyToGoalDialogType>();
export const hideAddStrategyToGoalDialog = createAction(
  "@strategies/HIDE_ADD_STRATEGY_TO_GOAL_DIALOG"
)<void>();

export const getStrategies = createAsyncAction(
  "@strategies/GET_STRATEGIES_REQUEST",
  "@strategies/GET_STRATEGIES_SUCCESS",
  "@strategies/GET_STRATEGIES_FAILURE"
)<{ planId?: number } | void, Strategy[], Error>();

export const addStrategy = createAsyncAction(
  "@strategies/ADD_STRATEGY_REQUEST",
  "@strategies/ADD_STRATEGY_SUCCESS",
  "@strategies/ADD_STRATEGY_FAILURE"
)<
  { strategy: AddStrategyRequest; planId: number; onSuccess?: Function },
  Strategy,
  Error
>();

export const updateStrategy = createAsyncAction(
  "@surveys/UPDATE_STRATEGY_REQUEST",
  "@surveys/UPDATE_STRATEGY_SUCCESS",
  "@surveys/UPDATE_STRATEGY_FAILURE"
)<
  {
    updatedStrategy: UpdateStrategyRequest;
    onSuccess?: Function;
  },
  Strategy,
  Error
>();

export const deleteStrategy = createAsyncAction(
  "@surveys/DELETE_STRATEGY_REQUEST",
  "@surveys/DELETE_STRATEGY_SUCCESS",
  "@surveys/DELETE_STRATEGY_FAILURE"
)<{ strategyId: number; onSuccess?: Function }, number, Error>();
