import { RootState } from "typesafe-actions";

export function getActivePlanId(state: RootState): number | undefined {
  return state.plans.activePlan?.id;
}

export function isDistrictPlan(state: RootState): boolean | undefined | any {
  if (state?.plans?.activePlan)
    return !!state.plans.activePlan && !state.plans.activePlan?.school?.id
  else return undefined;
}

export function getActivePlanDistrictId(state: RootState): number | undefined {
  return state.plans?.activePlan?.district?.id;
}
