import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";

type OwnProps = {
  selectedRespondentGroup?: any;
  selectedMultiDomains?: any;
  selectedMultiSurveyItem?: any;
  AdminLabelPoints?: any;
  RespondentGraphDomainsData?: any;
  RespondentGraphItemsData?: any;
  selectedGoal?: any;
  selectedDateRange?: any;
};
type Props = OwnProps;

const SurveyRespondentGroupGraph: React.FC<Props> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<any>({});

  const [refreshTable,setRefreshTable]=useState<boolean>(false);
  const RespondentGraphDomainsDataLoading =useSelector((s)=>s.surveyReports.loading.getRespondentGraphDomainData)
  const RespondentGraphItemsDataLoading =useSelector((s)=>s.surveyReports.loading.getRespondentGraphItemData)
  
  const {
    selectedRespondentGroup,
    selectedMultiDomains,
    selectedMultiSurveyItem,
    AdminLabelPoints,
    RespondentGraphDomainsData,
    RespondentGraphItemsData,
    selectedGoal,
    selectedDateRange,
  } = props;

  const getDomainMean = (d: any,demographic:any) => {
    const Data: any = [];
    selectedDateRange.map((sdr: any) =>
      RespondentGraphDomainsData.map((rg: any) => {
        return rg?.domain?.name === d &&
          rg?.respondents[`${demographic}`] &&
          sdr === moment(rg?.survey_deployment[0]?.end_date).format("MM/DD/YY")
          ? Data.push({
              deployment_name: rg?.survey_deployment[0]?.deployment_name,
              MeanScore: Number(rg?.respondents[
                demographic
              ].mean_score).toFixed(2)==="0.00"? intl.formatMessage({id:"app.non-ideal-state.survey-reports.not-enough-respondents"}):Number(rg?.respondents[
                demographic
              ].mean_score).toFixed(2),
            })
          : "";
      })
    );

    return Data;
  };

  const getItemMean = (text: string, domain: any,demographic:any) => {
    const Data: any = [];

    selectedDateRange.map((sdr: any) =>
      RespondentGraphItemsData?.map((fsc: any) =>
      fsc?.domain?.name === domain &&
      fsc?.responses?.forEach((response: any) => {
          if (
            fsc?.domain?.name === domain &&
            response.respondents[`${demographic}`] &&
            response.respondents[
              `${demographic}`
            ]?.question_text?.includes(text) &&
            sdr ===
              moment(response?.survey_deployment[0]?.end_date).format(
                "MM/DD/YY"
              )
          ) {
            return Data.push({
              ItemMeanScore: Number(response?.respondents?.[
                `${demographic}`
              ]?.mean_score)?.toFixed(2)==="0.00"?intl.formatMessage({id:"app.non-ideal-state.survey-reports.not-enough-respondents"}):Number(response?.respondents?.[
                `${demographic}`
              ]?.mean_score)?.toFixed(2),
              deployment_name: response?.survey_deployment[0]?.deployment_name,
            });
          } else {
            return false;
          }
        })
      )
    );

    return Data;
  };


const selectedDomainsName:any=useMemo(()=> {
     const DomainNamesArray:any=[];

     selectedMultiSurveyItem.map((si:any)=>{
      const selectedItemDomainName:string=selectedGoal.targets.find((target:any)=>target?.item_priority?.item?.text===si.text)
      
      if(DomainNamesArray?.includes(selectedItemDomainName)){
        
      }else{
        DomainNamesArray?.push(selectedItemDomainName)
      }
    })
    
    return DomainNamesArray 

},[selectedMultiSurveyItem])


  const TableData = useMemo(() => {
    let domainItemData: any = [];

    if (selectedMultiDomains.length && selectedMultiSurveyItem.length <=0) {
      selectedMultiDomains?.map((domain: any) => {
        const ItemsData: any = [];
        selectedMultiSurveyItem?.map((surveyItem: any) => {
          const ItemMean=domain.domain === surveyItem.domain && domain.demographic ===surveyItem.demographic ?getItemMean(surveyItem.text, domain.domain,domain.demographic):[]

          ItemMean.length>0 && ItemsData.push({
            ItemText: surveyItem.text,
            leadIn: surveyItem.leadIn,
            ItemMeanScore: ItemMean,
            TargetScore: selectedGoal.targets.map((target: any) =>

              target?.item_priority?.item?.text.includes(surveyItem.text)
                ? target.target_value
                : ""
            ),
          });
        });
        
        domainItemData.push({
          DomainRespondentGroup: intl.formatMessage({
            id: `app.filters.respondent-type.${domain.demographic}`,
          }),
          DomainMeanScore: getDomainMean(domain.domain,domain.demographic),
          DomainName: domain.domain,
          ItemData: ItemsData,
          type: "domain_level",
          DomainTargetScore: selectedMultiDomains.length
            ? selectedGoal.targets.map((target: any) =>
                target?.domain_priority?.demographic ===
                domain.demographic &&
                target?.domain_priority?.domain?.name === domain.domain
                  ? target.target_value
                  : ""
              )
            : "",
        });
      });
    }
    else if(selectedMultiDomains.length >= 0 && selectedMultiSurveyItem.length >= 0) {
     
      selectedDomainsName?.map((domain: any) => {

        const ItemsData: any = [];
        selectedMultiSurveyItem?.map((surveyItem: any) => {
          const ItemMean= getItemMean(surveyItem.text, domain?.item_priority?.domain?.name,domain.demographic)

          ItemMean.length>0 && ItemsData.push({
            ItemText: surveyItem.text,
            leadIn: surveyItem.leadIn,
            ItemMeanScore: ItemMean,
            TargetScore: selectedGoal.targets.map((target: any) =>
              target?.item_priority?.item?.text.includes(surveyItem.text)
                ? target.target_value
                : ""
            ),
          });
        });

        domainItemData.push({
          DomainRespondentGroup: intl.formatMessage({
            id: `app.filters.respondent-type.${domain.demographic}`,
          }),
          DomainMeanScore: getDomainMean(domain?.item_priority?.domain?.name,domain.demographic),
          DomainName: domain?.item_priority?.domain?.name,
          ItemData: ItemsData,
          type: "domain_level",
          DomainTargetScore: selectedDomainsName
            ? selectedGoal.targets.map((target: any) =>
              target?.domain_priority?.demographic ===
              domain.demographic && target?.domain_priority?.domain?.name === domain?.item_priority?.domain?.name
                  ? target.target_value
                  : ""
              )
            : "",
        });
      });
      selectedMultiDomains?.map((domain: any) => {
        const ItemsData: any = [];
        selectedMultiSurveyItem?.map((surveyItem: any) => {
          const ItemMean=domain.domain === surveyItem.domain && domain.demographic ===surveyItem.demographic ?getItemMean(surveyItem.text, domain.domain,domain.demographic):[]

          ItemMean.length>0 && ItemsData.push({
            ItemText: surveyItem.text,
            leadIn: surveyItem.leadIn,
            ItemMeanScore: ItemMean,
            TargetScore: selectedGoal.targets.map((target: any) =>

              target?.item_priority?.item?.text.includes(surveyItem.text)
                ? target.target_value
                : ""
            ),
          });
        });

        domainItemData.push({
          DomainRespondentGroup: intl.formatMessage({
            id: `app.filters.respondent-type.${domain.demographic}`,
          }),
          DomainMeanScore: getDomainMean(domain.domain,domain.demographic),
          DomainName: domain.domain,
          ItemData: ItemsData,
          type: "domain_level",
          DomainTargetScore: selectedMultiDomains.length
            ? selectedGoal.targets.map((target: any) =>
                target?.domain_priority?.demographic ===
                  domain.demographic &&
                target?.domain_priority?.domain?.name === domain?.item_priority?.domain?.name
                  ? target.target_value
                  : ""
              )
            : "",
        });
      });


      selectedMultiDomains?.map((domain: any) => {
        const ItemsData: any = [];
        selectedMultiSurveyItem?.map((surveyItem: any) => {
          const ItemMean=domain.domain === surveyItem.domain && domain.demographic ===surveyItem.demographic ?getItemMean(surveyItem.text, domain.domain,domain.demographic):[]

          ItemMean.length>0 && ItemsData.push({
            ItemText: surveyItem.text,
            leadIn: surveyItem.leadIn,
            ItemMeanScore: ItemMean,
            TargetScore: selectedGoal.targets.map((target: any) =>

              target?.item_priority?.item?.text.includes(surveyItem.text)
                ? target.target_value
                : ""
            ),
          });
        });
        
        domainItemData.push({
          DomainRespondentGroup: intl.formatMessage({
            id: `app.filters.respondent-type.${domain.demographic}`,
          }),
          DomainMeanScore: getDomainMean(domain.domain,domain.demographic),
          DomainName: domain.domain,
          ItemData: ItemsData,
          type: "domain_level",
          DomainTargetScore: selectedMultiDomains.length
            ? selectedGoal.targets.map((target: any) =>
                target?.domain_priority?.demographic ===
                  domain.demographic &&
                target?.domain_priority?.domain?.name === domain.domain
                  ? target.target_value
                  : ""
              )
            : "",
        });
      });
    }

    
    return domainItemData;
  }, [
    selectedMultiDomains.length,
    selectedMultiSurveyItem.length,
    selectedRespondentGroup,
    selectedDateRange,
    refreshTable
  ]);


  const groupedData:any = [];

  TableData.forEach((item:any) => {
    const domainName = item.DomainName;

    if (!groupedData[domainName]) {
      groupedData[domainName] = []; // Initialize an empty array for each unique domain name
    }

    // Push the item to the corresponding domain name array
    groupedData[domainName]=({...groupedData[domainName],[item?.DomainRespondentGroup]:item});;
  });


  useEffect(()=>{
    if(RespondentGraphDomainsDataLoading === false && RespondentGraphItemsDataLoading === false){
      setRefreshTable(!refreshTable)
    }
      },[RespondentGraphDomainsDataLoading,RespondentGraphItemsDataLoading])

  return (
    <>
      <div className="monitoring_surveyTbl" style={{ maxHeight: "50rem", overflowY: "scroll" }}>
        {Object.keys(groupedData).map((td: any, index: number) => {
          return (
            <>
              <b className="tblDomain_Name">{td}</b>
              <div className="blueHeader_tbl mb-5" style={{ maxWidth: "100%", overflowX: "scroll" }}>

                <Table>
                {/* table Blue header Row */}
                {Object.keys(groupedData[td])?.length>0 && 
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "210px" }}>
                        {intl.formatMessage({
                          id: "app.titles.respondent.group",
                        })}
                      </TableCell>
                      {Object.keys(groupedData[td]).map((key:any,index:number)=>
                      {return index===0 ?(groupedData[td][key]).DomainMeanScore.map((dm: any) => (
                        <TableCell>
                          {intl.formatMessage({
                            id: "app.titles.domain-mean",
                          }) +
                            " " +
                            dm.deployment_name?.slice(0, 10) +
                            dm.deployment_name?.slice(10, 16) +
                            " " +
                            dm.deployment_name?.slice(26)}
                        </TableCell>
                      ))
                      :""
                        }

                      )}
                      <TableCell>
                        {intl.formatMessage({ id: "app.titles.target-score" })}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                   }
                    
                   {/* table Collapsable Respondent Rows */}
                   {Object.keys(groupedData[td]).map((key:any,index:number)=>
                  {
                    const domainItemData:any=[]
                    domainItemData.push(groupedData[td][key])    

                    return domainItemData.map((domain:any,index:number)=>
                 {
                  const domainCollapsebarName=domain.DomainRespondentGroup+domain.DomainName;
                  return <> <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell>
                    {domain.ItemData.length?<IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen({...open,["Domain"+domainCollapsebarName]:!open["Domain"+domainCollapsebarName]})}
                      >
                        {(open["Domain"+domainCollapsebarName]) ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>:""}
                      <strong>{domain.DomainRespondentGroup}</strong>
                    </TableCell>
                    {domain.DomainMeanScore.map((dm: any) => (
                      <TableCell>{dm.MeanScore}</TableCell>
                    ))}
                    <TableCell>
                      {domain?.DomainTargetScore?.find((ds: any) => ds !== "")
                        ? Number(
                            domain?.DomainTargetScore?.find((ds: any) => ds !== "")
                          )?.toFixed(2)
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={domain?.DomainMeanScore?.length+2}
                    >
                      <Collapse in={(open["Domain"+domainCollapsebarName])} timeout="auto" unmountOnExit>
                        <Box sx={{ marginTop:0 }}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              {domain?.ItemData?.length?<TableRow>
                                <TableCell
                                  style={{
                                    backgroundColor: "#EAECF0",
                                    width: "200px",
                                    color: "#000",
                                    fontWeight: "bolder"
                                  }}
                                >
                                  {intl.formatMessage({
                                    id: "app.titles.survey-item",
                                  })}
                                </TableCell>
                                {domain?.ItemData[0]?.ItemMeanScore.length > 0 &&
                                  domain?.ItemData[0]?.ItemMeanScore.map(
                                    (ims: any) => {
                                      return (
                                        <TableCell
                                          style={{
                                            backgroundColor: "#EAECF0",
                                            color: "#000",
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id:
                                              "app.survey-report.dialogs.item",
                                          }) +
                                            " " +
                                            intl.formatMessage({
                                              id:
                                                "app.survey-report.dialogs.mean",
                                            }) +
                                            " "}
                                          {" " +
                                            ims.deployment_name?.slice(0, 10) +
                                            ims.deployment_name?.slice(10, 16) +
                                            " " +
                                            ims.deployment_name?.slice(26)}
                                        </TableCell>
                                      );
                                    }
                                  )}

                                <TableCell
                                  style={{
                                    backgroundColor: "#EAECF0",
                                    color: "#000",
                                  }}
                                >
                                  {intl.formatMessage({
                                    id: "app.titles.target-score",
                                  })}
                                </TableCell>
                              </TableRow>:""}
                            </TableHead>
                            <TableBody>
                              {domain?.ItemData.map((itd: any) => (
                                <TableRow>
                                  <TableCell style={{ width: "200px" }}>
                                    {itd?.ItemMeanScore.length
                                      ? (itd?.leadIn ?? "" )+" " + itd?.ItemText
                                      : ""}
                                  </TableCell>
                                  {itd?.ItemMeanScore.map((ims: any) => (
                                    <TableCell>{ims.ItemMeanScore}</TableCell>
                                  ))}
                                  <TableCell>
                                    {itd?.TargetScore?.find(
                                      (ds: any) => ds !== ""
                                    )
                                      ? Number(
                                          itd?.TargetScore?.find(
                                            (ds: any) => ds !== ""
                                          )
                                        )?.toFixed(2)
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                    </TableRow></>}
                  )}
        )}
                </Table>
              </div>
          </>
        );
      })}
      </div>
    </>
  );
};

export default SurveyRespondentGroupGraph;
