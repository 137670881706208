import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { hideActivityVideoDialog } from "../../../../store/activities/actions";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import useNextTourCallback from "../../../../helpers/hooks/team-members/useNextTourCallback";
import { DistrictSetupTaskKey } from "../../../../types";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import useUserPlanAssociation from "../../../../helpers/hooks/useUserPlanAssociation";
import useUserRole from "../../../../helpers/hooks/useUserRole";

type OwnProps = {
  isResourceTab?: boolean;
};

type Props = OwnProps;

const ViewActivityContentDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const { isResourceTab } = props;
  const dispatch = useDispatch();

  const { show, activity, viewable_field } = useSelector(
    (s) => s.activities.dialogs.activityVideoDialog
  );
  const {setupCompleted,districtSetupState: tours,} = useSelector((s) => s.tours);

  const { isDCC, isSuperintendent, teamRole, isTeamMember, role, } = useUserRole();

  const {
    isSchoolPlanPrincipal,
    planSchoolId,
    isSingleSchoolDistrict,
  } = useUserPlanAssociation();
  
  const handleClose = () => {
    dispatch(hideActivityVideoDialog());
  };

  const onNextTour = useNextTourCallback(
    DistrictSetupTaskKey.LearnAboutTeamDevelopment,
  );
  const onNext = useNextTourCallback(
    planSchoolId
      ? DistrictSetupTaskKey.InviteSchoolStaff
      : DistrictSetupTaskKey.InviteOtherMembers
  );

  const handleDone = () => {
    handleClose();
    if(isSuperintendent){
    !setupCompleted && localStorage.setItem("tourSteps","leadershipTeamDevelopment")
    }

    if(isSchoolPlanPrincipal || isDCC){
    !setupCompleted && localStorage.setItem("tourSteps","inviteTeamMembersTour")
    onNextTour && onNextTour();
    onNext && onNext();
    }
    else{
      onNextTour && onNextTour();
    }
  };

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      isCloseButtonShown={false}
      title={activity?.name}
      style={{ width: "50rem" }}
      portalClassName="activity-content-portal"
    >
      <div className={`${Classes.DIALOG_BODY} flex justify-center`}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              activity?.[
              (viewable_field as
                | "embed_code"
                | "notes_placeholder_html"
                | "html_content") ?? "embed_code"
              ] ?? "",
          }}
        />
      </div>
      <div className={`${Classes.DIALOG_BODY} flex justify-center`}>
        <p className="modal-sponsorship-statement">
          <i>{intl.formatMessage(
            {
              id: "app.video-modal.sponsorship-statement",
            },
            {
              b: (chunks) => <b>{chunks}</b>,
            }
          )}
          </i>
        </p>
      </div>
        <div className={`${Classes.DIALOG_FOOTER} mt-4 flex justify-center`}>
          <Button
            text={intl.formatMessage({ id: "app.titles.close" })}
            title={intl.formatMessage({ id: "app.titles.close" })}
            intent="primary"
          onClick={isResourceTab ? handleClose : handleDone}
          />
      </div>
    </Dialog>
  );
};

export default ViewActivityContentDialog;
