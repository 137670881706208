/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useIntl } from "react-intl";
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Tooltip from '@mui/material/Tooltip';

import './UpdateImplementation.scss';
import { LookForItem, LookForItemStatus, LookForItemStatusEnum, PlanStrategy, RecurringStatusEnum } from '../../../../../types';
import { useMemo, useState } from 'react';
import _ from 'lodash';
import UpdateImplementationItem from './update-implementation-item/UpdateImplementationItem';
import moment from 'moment';
import { UpdatePlanStrategyImplementationStatusRequest } from '../../../../../api/plan-strategies/types';
import { updatePlanStrategy, updatePlanStrategyImplementation, updatePlanStrategyImplementationStatus } from '../../../../../store/plan-strategies/actions';
import { useDispatch} from 'react-redux';

type OwnProps = {
    planstrategy: PlanStrategy;
    activePlan?: number;
    handleClose: () => void;
    implementationId?: number;
    isPauseImplementation: boolean;
};
type Props = OwnProps;

const UpdateImplementation: React.FC<Props> = (props) => {
    const { planstrategy, activePlan, handleClose, implementationId, isPauseImplementation } = props;
    const dispatch = useDispatch();
    const intl = useIntl();

    const [updateImplementationOpen, setUpdateImplementationOpen] = useState<boolean>(false);
    const [lookForStatuses, setLookForStatuses] = useState<any[]>([]);
    const [date, setDate] = useState<Date>();
    const [error, setError] = useState<boolean>();


    const handleClickOpen = () => {
        setUpdateImplementationOpen(true);

        if (implementationId) {
            const selectedImplemetation = planstrategy.implementations.find(imp => imp.id === implementationId);

            if (selectedImplemetation) {
                setLookForStatuses(
                    planstrategy.strategy.look_for_items?.map((lookFor) => {
                        const lookForStatus = planstrategy.look_for_statuses?.find(
                            (lookForStatus) => lookForStatus.item === lookFor.id
                        );

                        if (lookForStatus) {
                            return {
                                ...lookForStatus,
                                status: selectedImplemetation.statuses.find((status) => status.item === lookFor.id)?.status || LookForItemStatusEnum.NotPresent,
                                item: lookFor,
                            };
                        }
                        return {
                            recurring_status: RecurringStatusEnum.NotRecurring,
                            status: LookForItemStatusEnum.NotPresent,
                            item: lookFor,
                        };
                    })
                );
            }
        }
        else {
            setLookForStatuses(
                planstrategy.strategy.look_for_items?.map((lookFor) => {
                    const lookForStatus = planstrategy.look_for_statuses?.find(
                        (lookForStatus) => lookForStatus.item === lookFor.id
                    );

                    const updatedStatus = statuses.find((status) => status.item === lookFor.id)?.status;

                    if (lookForStatus) {
                        return {
                            ...lookForStatus,
                            status: statuses.find((status) => status.item === lookFor.id)?.status || LookForItemStatusEnum.NotPresent,
                            item: lookFor,
                        };
                    }
                    return {
                        recurring_status: RecurringStatusEnum.NotRecurring,
                        status: updatedStatus || LookForItemStatusEnum.NotPresent,
                        item: lookFor,
                    };
                })
            );
        }
    };

    const implementationModalClose = () => {
        setUpdateImplementationOpen(false);
        handleClose();
        setLookForStatuses([]);
    };

    // on success when existing update implementation
    const updateImplementationOnSuccess = () => {
        activePlan &&
            dispatch(
                updatePlanStrategy.request({
                    planStrategy: {
                        planId: activePlan,
                        strategy: planstrategy.strategy.id,
                        planStrategyId: planstrategy.id
                    }
                })
            );
        implementationModalClose();
    };

    // on success when update implementation
    const onSuccess = () => {
        handleClose();
    }
    // On Submit Action
    const handleOnSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        const updatedLookForStatuses = lookForStatuses.map((lookForStatus, index) => (

            {
            ...lookForStatus,
                item: planstrategy.strategy.look_for_items?.[index]?.id,
                original: planstrategy.strategy.look_for_items?.[index].original,
                plan_id: planstrategy.strategy.look_for_items?.[index].plan_id
            }
        ));

        if (planstrategy && activePlan) {
            const dt = date?new Date(date):new Date()

            let day = dt?.getDate();
            let month = dt?.getMonth() + 1;
            let year = dt?.getFullYear();

            const body: UpdatePlanStrategyImplementationStatusRequest = {
                plan_strategy_id: planstrategy.id,
                date:  `${year}-${month}-${day}T09:30:00.000Z`,
                statuses: updatedLookForStatuses.filter(lookForStatus => (
                    lookForStatus.plan_id === activePlan ||
                    lookForStatus.original ||
                    (!lookForStatus.school_item || (!lookForStatus.original && lookForStatus.plan_id === activePlan))
                )),
            };

            if (implementationId) {
                dispatch(
                    updatePlanStrategyImplementation.request({
                        body: body,
                        implementation: implementationId,
                    })
                );
                updateImplementationOnSuccess();
            } else {
                dispatch(updatePlanStrategyImplementationStatus.request(body));
                onSuccess();
            }
        }
    }

    const statuses = useMemo(() => {
        return (_.chain(planstrategy.implementations)
            .orderBy((impl) => new Date(impl.date), ["desc"])
            .first().value()?.statuses ?? []);
    }, [planstrategy.implementations]);

    const handleLookForChange = <T extends string | RecurringStatusEnum>(
        index: number
    ) => (key: keyof LookForItem | keyof LookForItemStatus, value: T, itemId: number) => {
        setLookForStatuses((lookForStatus) =>
            lookForStatus.map((lookForStatus, i) =>
                i === index
                    ? key === lookForStatus.item.id
                        ? {
                            ...lookForStatus,
                            item: { ...lookForStatus.item, [key]: value },
                        }
                        : { ...lookForStatus, [key]: value }
                    : { ...lookForStatus }
            )
        );
    };
    

    const handleDateChange = (newValue:any) => {
        newValue && setDate(newValue)
        setError(false)
         };

    const handleDateError = () => {
        setError(true);
      };


      React.useEffect(()=>{
      
        if(!date){
        setError(true);
        }

      },[date])

      
    const shouldDisableDate = (date:any) => {
        
        const disabledDates = planstrategy.implementations.map((imp:any)=>(imp.date))

        const adjustedDates = disabledDates.map((dateStr) => {
            const dateObj = new Date(dateStr);
            const adjustedDate =moment( new Date(dateObj)).tz( Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD");
            return adjustedDate;
          });

       
        const formattedDate = moment(new Date(date.$d)).tz( Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD") ; // Format date as 'YYYY-MM-DD'
  
        return adjustedDates.includes(formattedDate);
    };
      

    return (
        <>
            {
                implementationId ?
                    (
                        <Tooltip title="Edit" arrow>
                            <button onClick={handleClickOpen} aria-label="Edit"> <Icon icon="edit" iconSize={14} />
                            </button>
                        </Tooltip>
                    )
                    :
                    (
                        <a onClick={handleClickOpen} className={isPauseImplementation ? "disabled-link" : ""} >
                            <Icon icon="refresh" iconSize={14} />
                            {intl.formatMessage({ id: "app.strategy.update-implementation-title" })}
                        </a>
                    )
            }

            <Dialog
                className="update-implementation-modal"
                open={updateImplementationOpen}
                onClose={implementationModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal-title">
                    <h3>{intl.formatMessage({ id: "app.strategy.update-implementation-title" })}</h3>
                    <button onClick={implementationModalClose}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>

                <div className="update-implementation-modal-content">
                    <h4>{planstrategy?.strategy?.name}</h4>
                    <div className="picker-info">
                        <div className="picker-info__left">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        slotProps={{ textField: { size: 'small',color:error?"error":"primary" } }} 
                                        label={intl.formatMessage({ id: "app.titles.select-date" },
                                        {span:(chunks)=><span className='text-red-700'>{chunks}</span>})  
                                            }
                                        value={date}
                                        onChange={(newValue) => handleDateChange(newValue)}
                                        onError={handleDateError}
                                        // disablePast
                                        shouldDisableDate={shouldDisableDate}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div className="picker-info__right">
{/* 
                            <Tooltip title={intl.formatMessage({ id: "app.strategy.update-implementation-tooltip" })}>
                                <button>
                                    <span>i</span>
                                    Info</button>
                            </Tooltip> */}
                        </div>
                    </div>
                    <div className="accordionbar">
                        <div className="accordionbar__content">
                            {
                                !!lookForStatuses.length ?
                                    lookForStatuses.map((lookForStatus, index) => {
                                        return (lookForStatus.item.plan_id === activePlan || lookForStatus.item.original || (!lookForStatus.item.school_item || (!lookForStatus.item.original && lookForStatus.item.plan_id === activePlan))) &&
                                            <UpdateImplementationItem
                                                key={index}
                                                lookForStatus={lookForStatus} onLookForStatusFieldUpdate={handleLookForChange(index)}
                                            />
                                    })
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    <div className="action">
                        <button onClick={implementationModalClose} className="stroke-btn">{intl.formatMessage({ id: "app.titles.cancel" })}</button>

                        <button onClick={handleOnSubmit} className="nj-btn" disabled={error}>{intl.formatMessage({ id: "app.titles.save" })}</button>

                    </div>
                </div>

            </Dialog>
        </>
    );
}

export default UpdateImplementation;