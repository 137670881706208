import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getDatasetMarksEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getDatasetMarks.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.datasetMarks.getDatasetMarks(request)).pipe(
        mergeMap((datasetMarks) =>
          from(
            [actions.getDatasetMarks.success(datasetMarks)],
            onComplete && onComplete(datasetMarks)
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.getDatasetMarks.failure(error));
        })
      )
    )
  );
};

export const flagDatasetMarkEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.flagDatasetMark.request)),
    switchMap((action) =>
      from(api.datasetMarks.flagDatasetMark(action.payload)).pipe(
        map(actions.flagDatasetMark.success),
        catchError((error) => of(actions.flagDatasetMark.failure(error)))
      )
    )
  );
};

export const updateDatasetMarkEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateDatasetMark.request)),
    switchMap((action) =>
      from(api.datasetMarks.updateDatasetMark(action.payload)).pipe(
        map(actions.updateDatasetMark.success),
        catchError((error) => of(actions.updateDatasetMark.failure(error)))
      )
    )
  );
};

export const upsertDatasetWithDatasetMarkEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.upsertDatasetWithDatasetMark.request)),
    switchMap((action) =>
      from(api.datasetMarks.upsertDatasetWithDatasetMark(action.payload)).pipe(
        mergeMap((datasetMark) =>
          from([
            actions.upsertDatasetWithDatasetMark.success(datasetMark),
            actions.upsertDataset(datasetMark.dataset),
          ])
        ),
        catchError((error) =>
          of(actions.upsertDatasetWithDatasetMark.failure(error))
        )
      )
    )
  );
};

export const discardDatasetMarkEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.discardDatasetMark.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.datasetMarks.discardDatasetMark(request)).pipe(
        mergeMap(() =>
          from(
            [actions.discardDatasetMark.success(request.datasetMarkId)],
            onComplete && onComplete()
          )
        ),
        catchError((error) => {
          return of(actions.discardDatasetMark.failure(error));
        })
      )
    )
  );
};
