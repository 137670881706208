import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useStrategyConfrimDelete } from "../../../helpers/hooks/plan-strategies/useStrategyConfrimDelete";
import useUserRole from "../../../helpers/hooks/useUserRole";
import { showViewImplementationLogsDialog } from "../../../store/plan-strategies/actions";
import {
  showAddPlanStrategyDialog,
  showImplementationStatusDialog,
  showUpdatePlanStrategyDetailsDialog
} from "../../../store/strategies/actions";
import { PlanStrategy, Strategy } from "../../../types";
import ButtonWithTooltip from "../../common/buttons/ButtonWithTooltip";
import { isPlanStrategy } from "../utils";

type OwnProps = {
  goalTitle?: string;
  showPlanStrategyActions?: boolean;
  item: PlanStrategy | Strategy;
  onDeleteSuccess?: () => void;
  onUpdateStatusSuccess?: () => void;
  onUpdateLogsSuccess?: () => void;
};

type Props = OwnProps;

const StrategyActions: React.FC<Props> = (props) => {
  const {
    showPlanStrategyActions,
    item,
    onDeleteSuccess,
    onUpdateStatusSuccess,
    onUpdateLogsSuccess,
    goalTitle,
  } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const selectedDomain = useSelector((s) => s.domains.selectedDomain);

  const isPlanStrategyType = useMemo(() => {
    return isPlanStrategy(item);
  }, [item]);

  const handleUpdateImplementationStatusClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      showImplementationStatusDialog({
        planStrategy: item as PlanStrategy,
        onUpdateSuccess: onUpdateStatusSuccess,
      })
    );
  };

  const handleUpdatePlanStrategyDetailsDialogShow = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      showUpdatePlanStrategyDetailsDialog({
        planStrategy: item as PlanStrategy,
        goalTitle: goalTitle,
        onConfirm: onUpdateStatusSuccess,
      })
    );
  };

  const { confirmDelete } = useStrategyConfrimDelete({ item, onDeleteSuccess });

  const handleDeleteStrategyClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    confirmDelete();
  };

  const handleAddStrategyClick = (item: PlanStrategy | Strategy) => {
    dispatch(
      showAddPlanStrategyDialog({
        strategy: item as Strategy,
        domains: selectedDomain ? [selectedDomain] : undefined,
      })
    );
  };

  const { isAdmin } = useUserRole();

  const showDeleteButton = useMemo(() => {
    if (isPlanStrategyType) {
      return isAdmin;
    }

    return (item as Strategy).is_global && isAdmin;
  }, [isPlanStrategyType, isAdmin, item]);

  const handleViewImplementationLogsClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      showViewImplementationLogsDialog({
        planStrategy: item as PlanStrategy,
        onConfirm: onUpdateLogsSuccess,
      })
    );
  };

  return (
    <div className="flex gap-1 items-center">
      {showPlanStrategyActions && (
        <>
          <ButtonWithTooltip
            icon="cog"
            intent="primary"
            title={intl.formatMessage({
              id: "app.strategies.update-parameters",
            })}
            onClick={handleUpdatePlanStrategyDetailsDialogShow}
          />

          <ButtonWithTooltip
            icon="add"
            intent="primary"
            title={intl.formatMessage({
              id: "app.strategies.update-implementation-status",
            })}
            onClick={handleUpdateImplementationStatusClick}
          />

          <ButtonWithTooltip
            icon="eye-open"
            intent="primary"
            title={intl.formatMessage({
              id: "app.strategies.view-implementation-logs",
            })}
            onClick={handleViewImplementationLogsClick}
          />
        </>
      )}
      {!isPlanStrategyType && (
        <ButtonWithTooltip
          icon="add"
          intent="primary"
          title={intl.formatMessage({
            id: "app.strategies.add-to-my-school",
          })}
          onClick={() => handleAddStrategyClick(item)}
        />
      )}
      {showDeleteButton && (
        <ButtonWithTooltip
          intent="danger"
          icon="trash"
          title={intl.formatMessage({ id: "app.titles.delete" })}
          onClick={handleDeleteStrategyClick}
        />
      )}
    </div>
  );
};

export default StrategyActions;
