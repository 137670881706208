import { Tag } from "@blueprintjs/core";
import _ from "lodash";
import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { PlanStrategy } from "../../../../types";

type OwnProps = {
  planStrategy: PlanStrategy;
};

type Props = OwnProps;

const StrategyStatus: FunctionComponent<Props> = (props) => {
  const { planStrategy } = props;

  const intl = useIntl();

  const implementedPercent = useMemo(() => {
    return Math.round(
      (_.chain(planStrategy.implementations)
        .orderBy((impl) => new Date(impl.date), ["desc"])
        .first()
        .value()?.score ?? 0) * 100
    );
  }, [planStrategy.implementations]);

  return implementedPercent == null ? (
    <div>
      <Tag round large intent="warning">
        {intl.formatMessage({ id: "app.titles.invalid-data" })}
      </Tag>
    </div>
  ) : (
    <div>
      <Tag round large intent="success" style={{ minWidth: "8.125rem" }}>
        {implementedPercent}&#37;&nbsp;
        {intl.formatMessage({ id: "app.titles.implemented" })}
      </Tag>
    </div>
  );
};

export default StrategyStatus;
