import React, { FunctionComponent } from "react";
import { Dialog, Icon } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { hideCreateCustomDataSetDialog } from "../../../../store/data-sets/actions";
import CreateCustomDataSetDialogContent from "./CreateCustomDataSetDialogContent";

type OwnProps = {};

type Props = OwnProps;

const CreateCustomDataSetDialog: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { show } = useSelector(
    (s) => s.datasets.dialogs.createCustomDataSetDialog
  );

  const handleClose = () => {
    dispatch(hideCreateCustomDataSetDialog());
  };

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon={"database"} iconSize={24} />}
      title={intl.formatMessage({
        id: "app.datasets.create-custom-dataset",
      })}
    >
      <CreateCustomDataSetDialogContent onClose={handleClose} />
    </Dialog>
  );
};

export default CreateCustomDataSetDialog;
