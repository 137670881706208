import React, { SetStateAction, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { MeetingTemplateRequest } from "../../../../api/meeting-templates/types";
import {
  Checkbox,
  FormGroup,
  InputGroup,
  NumericInput,
} from "@blueprintjs/core";
import Select from "react-select";
import { ActivityScopeEnum, MeetingType } from "../../../../types";
import { smallSelectorStyles } from "../../../common/selectors";
import PageEditor from "../../../survey-deployment/forms/PageEditor";

type OwnProps = {
  meetingTemplateRequest: MeetingTemplateRequest;
  setMeetingTemplateRequest: React.Dispatch<
    SetStateAction<MeetingTemplateRequest>
  >;
};

type Props = OwnProps;

const GeneralInfoTab: React.FC<Props> = ({
  meetingTemplateRequest,
  setMeetingTemplateRequest,
}: Props) => {
  const intl = useIntl();

  const handleStringFieldChange = (field: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setMeetingTemplateRequest({ ...meetingTemplateRequest, [field]: value });
  };

  const handleHTMLFieldChange = useCallback(
    (field: string) => (value: string) => {
      setMeetingTemplateRequest((mt) => ({
        ...mt,
        [field]: value,
      }));
    },
    [meetingTemplateRequest]
  );

  const handleBooleanFieldChange = (field: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    setMeetingTemplateRequest({ ...meetingTemplateRequest, [field]: checked });
  };

  const handleScopeChange = (scope?: ActivityScopeEnum) => {
    if (scope) {
      setMeetingTemplateRequest({
        ...meetingTemplateRequest,
        type: scope,
        activities: [],
      });
    }
  };

  const handleOrderIndexChange = (value?: number) => {
    setMeetingTemplateRequest({
      ...meetingTemplateRequest,
      order_index: value,
    });
  };

  const handleMeetingTypeChange = (type?: MeetingType) => {
    if (type) {
      setMeetingTemplateRequest({
        ...meetingTemplateRequest,
        meeting_type: type,
        meeting_type_other:
          type !== MeetingType.Other
            ? undefined
            : meetingTemplateRequest.meeting_type_other,
      });
    }
  };

  const generalNotesEditor = useMemo(
    () => (
      <PageEditor
        content={meetingTemplateRequest.general_notes}
        onBlur={handleHTMLFieldChange("general_notes")}
      />
    ),
    [meetingTemplateRequest.general_notes]
  );

  const preWorkEditor = useMemo(
    () => (
      <PageEditor
        content={meetingTemplateRequest.pre_work}
        onBlur={handleHTMLFieldChange("pre_work")}
      />
    ),
    [meetingTemplateRequest.pre_work]
  );

  const agendaEditor = useMemo(
    () => (
      <PageEditor
        content={meetingTemplateRequest.agenda}
        onBlur={handleHTMLFieldChange("agenda")}
      />
    ),
    [meetingTemplateRequest.agenda]
  );

  const timingGuidanceEditor = useMemo(
    () => (
      <PageEditor
        content={meetingTemplateRequest.timing_guidance}
        onBlur={handleHTMLFieldChange("timing_guidance")}
      />
    ),
    [meetingTemplateRequest.timing_guidance]
  );

  return (
    <>
      <FormGroup label={intl.formatMessage({ id: "app.meetings.dialog.title" })}>
        <InputGroup
          placeholder={`${intl.formatMessage({ id: "app.meetings.dialog.title" })}...`}
          value={meetingTemplateRequest?.title}
          onChange={handleStringFieldChange("title")}
          required
        />
      </FormGroup>
      <FormGroup label={intl.formatMessage({ id: "app.titles.short-title" })}>
        <InputGroup
          className="w-full lg:w-1/2"
          placeholder={`${intl.formatMessage({
            id: "app.titles.short-title",
          })}...`}
          value={meetingTemplateRequest?.short_title}
          onChange={handleStringFieldChange("short_title")}
          required
        />
      </FormGroup>
      <div className="flex flex-wrap">
        <FormGroup
          label={intl.formatMessage({ id: "app.titles.meeting-code" })}
        >
          <InputGroup
            className="w-60 mr-4"
            placeholder={`${intl.formatMessage({
              id: "app.titles.meeting-code",
            })}...`}
            value={meetingTemplateRequest?.meeting_code}
            onChange={handleStringFieldChange("meeting_code")}
            required
          />
        </FormGroup>
        <FormGroup label={intl.formatMessage({ id: "app.titles.order-index" })}>
          <NumericInput
            className="w-60 mr-4"
            placeholder={`${intl.formatMessage({
              id: "app.titles.order-index",
            })}...`}
            value={meetingTemplateRequest?.order_index}
            onValueChange={handleOrderIndexChange}
          />
        </FormGroup>
        <FormGroup label={intl.formatMessage({ id: "app.titles.scope" })}>
          <Select
            value={{
              label: intl.formatMessage(
                { id: "app.titles.activity-scope" },
                { scope: meetingTemplateRequest.type }
              ),
              value: meetingTemplateRequest.type,
            }}
            options={Object.values(ActivityScopeEnum).map((scope) => ({
              label: intl.formatMessage(
                { id: "app.titles.activity-scope" },
                { scope: scope }
              ),
              value: scope,
            }))}
            onChange={(v) => handleScopeChange(v?.value)}
            className="selector-min-width mr-4"
            styles={smallSelectorStyles}
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({ id: "app.titles.meeting-type" })}
        >
          <Select
            value={{
              label: intl.formatMessage(
                { id: "app.enums.meeting-type" },
                { type: meetingTemplateRequest.meeting_type }
              ),
              value: meetingTemplateRequest.meeting_type,
            }}
            options={Object.values(MeetingType).map((type) => ({
              label: intl.formatMessage(
                { id: "app.enums.meeting-type" },
                { type: type }
              ),
              value: type,
            }))}
            onChange={(v) => handleMeetingTypeChange(v?.value)}
            className="selector-min-width mr-4"
            styles={smallSelectorStyles}
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({ id: "app.titles.meeting-type-other" })}
        >
          <InputGroup
            className="w-60 mr-4"
            placeholder={`${intl.formatMessage({
              id: "app.titles.meeting-type-other",
            })}...`}
            value={meetingTemplateRequest?.meeting_type_other}
            onChange={handleStringFieldChange("meeting_type_other")}
            disabled={meetingTemplateRequest.meeting_type !== MeetingType.Other}
          />
        </FormGroup>
        <div className="flex items-center">
          <Checkbox
            checked={meetingTemplateRequest.can_be_added}
            label={intl.formatMessage({
              id: "app.titles.can-be-added",
            })}
            className="w-60 mr-4"
            onChange={handleBooleanFieldChange("can_be_added")}
          />
        </div>
        <div className="flex items-center">
          <Checkbox
            checked={meetingTemplateRequest.is_hidden}
            label={intl.formatMessage({
              id: "app.titles.is-hidden",
            })}
            className="w-60 mr-4"
            onChange={handleBooleanFieldChange("is_hidden")}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 lg:grid-cols-2">
        <FormGroup
          label={intl.formatMessage({
            id: "app.meetings.dialog.general-notes",
          })}
        >
          {generalNotesEditor}
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({
            id: "app.meetings.dialog.pre-work",
          })}
        >
          {preWorkEditor}
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({
            id: "app.meetings.dialog.agenda",
          })}
        >
          {agendaEditor}
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.timing-guidance",
          })}
        >
          {timingGuidanceEditor}
        </FormGroup>
      </div>
    </>
  );
};

export default GeneralInfoTab;
