import React, { FunctionComponent } from "react";
import { Button } from "@blueprintjs/core";
import { showUpsertStrategyDialog } from "../../../store/strategies/actions";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

type OwnProps = {
  onClick?: Function;
};

type Props = OwnProps;

const NewStrategyButton: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const handleStrategyUpsertDialogOpen = () => {
    props.onClick && props.onClick();
    dispatch(showUpsertStrategyDialog());
  };

  return (
    <Button
      text={intl.formatMessage({
        id: "app.strategies.new-strategy-button",
      })}
      title={intl.formatMessage({
        id: "app.strategies.new-strategy-button",
      })}
      intent="primary"
      onClick={handleStrategyUpsertDialogOpen}
      icon={"add"}
    />
  );
};

export default NewStrategyButton;
