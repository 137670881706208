/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useIntl } from "react-intl";
import { PlanStrategy } from '../../../../../../types';
import { Icon } from "@blueprintjs/core";
import Popover from "@mui/material/Popover";
import { useSelector } from 'react-redux';
import DeleteStrategyPlan from '../../delete-strategy-plan/DeleteStrategyPlan';
import './StrategyActions.scss';
import { generatePath, useHistory } from 'react-router';
import { AuthRoutes } from '../../../../../../App';

type OwnProps = {
	item: PlanStrategy;
};
type Props = OwnProps;

const StrategyDistrictActions: React.FC<Props> = ({ item }) => {
	const intl = useIntl();
	const history = useHistory();

	// STATES
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	// ACTIONS
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	// Get data from srore by redux
	const activePlanId = useSelector((s) => s.plans.activePlan?.id);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleEditCustomStrategy = (planId: number) => {
		history.push(
			generatePath(AuthRoutes.YourPlan, {
				workspace: "goals" as any,
				tabId: "custom-strategy",
				tagId: planId
			} as any)
		);
	}

	return (
		<>
			<li className="">
				<button aria-describedby={id} onClick={handleClick}>
					<Icon icon="more" iconSize={14} />
				</button>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<ul className="dropdown-list strategy-actions">
						<li>
							<a onClick={() => handleEditCustomStrategy(item.id)}><Icon icon="edit" iconSize={14} />{intl.formatMessage({ id: "app.titles.edit-details" })}</a>
						</li>
						<li><DeleteStrategyPlan planstrategy={item} activePlan={activePlanId} handleClose={handleClose} /></li>
					</ul>
				</Popover>
			</li>
		</>
	)
}

export default StrategyDistrictActions;
