import React from "react";
import { useIntl } from "react-intl";
import { Domain } from "../../../../types";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";

type OwnProps = {
  domain: Domain;
  color?: string;
  mean?: number;
  not_enough_data?: boolean;
};

type Props = OwnProps;

const DomainTitleRankingComparisonItem: React.FC<Props> = ({
  domain,
  color,
  mean,
  not_enough_data
}: Props) => {
  const intl = useIntl();
  return (
    <div
      className="items-center justify-center rounded-md px-2 py-4 mx-2"
      style={{
        backgroundColor: color ?? "#48AFF0",
      }}
    >
      <p
        className="font-bold text-center text-l"
        style={{
          color: pickTextColorBasedOnBgColor(
            color ?? "#48AFF0",
            "#ffffff",
            "#000000"
          ),
        }}
      >
        {domain?.name}
      </p>
      <p
        className="text-center text-l"
        style={{
          color: pickTextColorBasedOnBgColor(
            color ?? "#48AFF0",
            "#ffffff",
            "#000000"
          ),
        }}
      >
        {!not_enough_data?"Mean: "+mean?.toFixed(2):
        intl.formatMessage({
          id: "app.non-ideal-state.survey-reports.not-enough-respondents",
        })}
      </p>
    </div>
  );
};

export default DomainTitleRankingComparisonItem;
