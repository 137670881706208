import * as auth from "./auth";
import * as schools from "./schools";
import * as surveys from "./surveys";
import * as profile from "./profile";
import * as notifications from "./notifications";
import * as surveysDeployment from "./surveys-deployment";
import * as surveyReports from "./survey-reports";
import * as districts from "./districts";
import * as domains from "./domains";
import * as commonText from "./common-text";
import * as domainAnswerSet from "./domain-answer-set";
import * as users from "./users";
import * as strategies from "./strategies";
import * as resources from "./resources";
import * as datasets from "./data-sets";
import * as plans from "./plans";
import * as teamMembers from "./team-members";
import * as datasetMarks from "./dataset-marks";
import * as planStrategies from "./plan-strategies";
import * as needs from "./needs";
import * as goals from "./goals";
import * as notes from "./notes";
import * as meetings from "./meetings";
import * as activities from "./activities";
import * as meetingTemplates from "./meeting-templates";

export default {
  auth,
  schools,
  surveys,
  profile,
  notifications,
  surveysDeployment,
  surveyReports,
  districts,
  commonText,
  domains,
  domainAnswerSet,
  users,
  strategies,
  resources,
  datasets,
  plans,
  teamMembers,
  datasetMarks,
  planStrategies,
  needs,
  goals,
  notes,
  meetings,
  activities,
  meetingTemplates,
};
