import React, { FunctionComponent, useMemo } from "react";
import { Icon } from '@blueprintjs/core';
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import _ from "lodash";
import { BookmarkType } from "../../../../types";
import { useBookmarksLevelInfo } from "./useBookmarksLevelInfo";
import BookmarksIcons from "../explore-strengths-and-needs-tab/BookmarksIcons";
import { Callout } from "@blueprintjs/core";
import BookmarksInfoWrapperTable from "../bookmarks-info-table/BookmarksInfoWrapperTable";

type OwnProps = {};

type Props = OwnProps;

const ItemLevelInfoAndBookmarksDialogContent: FunctionComponent<Props> = (
  props
) => {
  const intl = useIntl();

  const { demographic, domain, meanScore, marksCount } = useSelector(
    (s) => s.needs.dialogs.itemLevelInfoAndBookmarksDialog
  );

  const scoreTitle = useMemo(() => {
    return meanScore != null
      ? _.round(meanScore, 2).toFixed(2)
      : intl.formatMessage({
        id: "app.titles.not-applicable",
      });
  }, [meanScore]);

  const { domainLevel, itemLevel } = useBookmarksLevelInfo({
    demographic: demographic ? [demographic] : undefined,
    domainId: domain?.id,
  });

  const isDomainData = domainLevel.length > 0 && domainLevel.some(checkItem);
  const isDomainItemsData = itemLevel.length > 0 && itemLevel.some(checkItem);

  function checkItem(item: any) {
    if (item.itemTitle !== intl.formatMessage({ id: `app.filters.respondent-type.${demographic}` }) && item.deleted_in_njsci_v2 !== true) {
      return true
    }
  }

  const noData = useMemo(() => {
    return !isDomainData && !isDomainItemsData
  }, [isDomainData, isDomainItemsData]);

  return (
    
    <div >
    
      {/* <div className="flex justify-between gap-2"> */}
      <div className="summary-modal-content">
        {/* <div className="flex gap-2"> */}
        <h4>{domain?.name}</h4>
        {/* <BookmarksIcons
              negative={marksCount?.[BookmarkType.Negative]}
              positive={marksCount?.[BookmarkType.Positive]}
            /> */}
        {/* </div> */}
        <p>
          {intl.formatMessage({
            id: `app.filters.respondent-type.${demographic}`,
          })}
        </p>
        {noData ? (
        <>
            <BookmarksInfoWrapperTable items={domainLevel} />
        
          <Callout intent="warning">
            {intl.formatMessage(
              { id: "app.needs.no-bookmarks-added" },
              { target: "" }
            )}
          </Callout>
        </>

      ) : (
        <>
          <BookmarksInfoWrapperTable items={domainLevel} />
          {!!itemLevel.length && (
            <BookmarksInfoWrapperTable items={itemLevel} isQuestionLevel />
          )}
        </>
      )}
      </div>
      {/* <div className="text-3xl font-bold">{scoreTitle}</div> */}
      {/* </div> */}

      
    </div>
  );
};

export default ItemLevelInfoAndBookmarksDialogContent;
