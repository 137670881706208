import { Locale } from "../../store/UIState";
import { CompletedSurvey, PageTextKeys } from "../../types";

export function getLocalizedInstruction(
  locale: Locale,
  selectedPage: PageTextKeys,
  completedSurvey?: CompletedSurvey
): string | undefined {
  if (completedSurvey !== undefined) {
    if (completedSurvey?.page_text !== null && !!completedSurvey?.page_text) {
      if (completedSurvey?.page_text[selectedPage] !== undefined) {
        if (completedSurvey?.page_text[selectedPage][locale] !== undefined) {
          return (
            completedSurvey?.page_text[selectedPage][locale].instruction ??
            completedSurvey?.page_text[selectedPage][Locale.English]
              ?.instruction
          );
        } else {
          return completedSurvey?.page_text[selectedPage][Locale.English]
            ?.instruction;
        }
      }
    }
  }
}

export function getLocalizedStatement(
  locale: Locale,
  selectedPage: PageTextKeys,
  completedSurvey?: CompletedSurvey
): string | undefined {
  if (completedSurvey !== undefined) {
    if (completedSurvey?.page_text !== null && !!completedSurvey?.page_text) {
      if (completedSurvey?.page_text[selectedPage] !== undefined) {
        if (completedSurvey?.page_text[selectedPage][locale] !== undefined) {
          return (
            completedSurvey?.page_text[selectedPage][locale].statement ??
            completedSurvey?.page_text[selectedPage][Locale.English]?.statement
          );
        } else {
          return completedSurvey?.page_text[selectedPage][Locale.English]
            ?.statement;
        }
      }
    }
  }
}
