import React from "react";
import { Activity } from "../../types";
import { useIntl } from "react-intl";
import { Card, Tag } from "@blueprintjs/core";
import ActivityItemActions from "./ActivityItemActions";

type OwnProps = {
  activity: Activity;
  isActive?: boolean;
  onActivityClick: (activity: Activity) => void;
  hideRemoveButton?: boolean;
};

type Props = OwnProps;

const ActivityItem: React.FC<Props> = ({
  activity,
  isActive,
  onActivityClick,
  hideRemoveButton,
}: Props) => {
  const intl = useIntl();

  const handleActivityClick = () => {
    onActivityClick(activity);
  };

  return (
    <Card
      elevation={2}
      className={`${isActive ? "bg-blue-300" : ""} space-y-2 cursor-pointer`}
      onClick={handleActivityClick}
    >
      <div className="text-xl font-bold">{activity.name}</div>
      <div className="flex items-center">
        <p className="font-semibold">
          {intl.formatMessage({ id: "app.activity-form.scope" })}:
        </p>
        <Tag className="ml-2" intent="success" minimal>
          {intl.formatMessage(
            { id: "app.titles.activity-scope" },
            { scope: activity?.type }
          )}
        </Tag>
      </div>
      <div className="flex items-center">
        <p className="font-semibold">
          {intl.formatMessage({ id: "app.activity-form.activity-type" })}:
        </p>
        <Tag className="ml-2" intent="success" minimal>
          {intl.formatMessage(
            { id: "app.titles.activity-type" },
            { type: activity?.activity_type }
          )}
        </Tag>
      </div>
      <div className="flex items-center">
        <p className="font-semibold">
          {intl.formatMessage({ id: "app.activity-form.tag" })}:
        </p>
        <Tag className="ml-2" intent="success" minimal>
          {intl.formatMessage(
            { id: "app.titles.plan-workspaces" },
            { workspace: activity?.tag }
          )}
        </Tag>
      </div>
      <div className="flex items-center">
        <p className="font-semibold">
          {intl.formatMessage({ id: "app.titles.order-index" })}:
        </p>
        <Tag className="ml-2" intent="success" minimal>
          {activity?.order_index}
        </Tag>
      </div>
      <ActivityItemActions
        activity={activity}
        hideRemoveButton={hideRemoveButton}
      />
    </Card>
  );
};

export default ActivityItem;
