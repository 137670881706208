import React, { ChangeEvent, useEffect, useState } from "react";

import { Button, Card, FormGroup } from "@blueprintjs/core";
import useEyeButton from "../../helpers/hooks/useEyeButton";
import useFormInputField from "../../helpers/hooks/useFormInputField";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../store/auth/actions";
import { showErrorAlert, useLoading } from "../../helpers/hooks/useLoading";
import { useParams } from "react-router";
import {
  changeSelectedUserInvite,
  getInviteByCode,
} from "../../store/users/actions";
import { AxiosError } from "axios";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import logo from "../../static/images/nj-sci-logo.png";
import { useHistory } from "react-router-dom";
import { AppToaster } from "../../helpers/toaster";
import { NonAuthRoutes } from "../../App";
import InvitationErrorCallout from "./InvitationErrorCallout";


import './registrationForm.scss'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type OwnProps = {};

type Props = OwnProps;

const EMAIL_ALREADY_EXISTS = "Email already exists";

const pattern = /^(?=.*[0-9])(?=.*[A-Za-z])([A-Za-z\d\w\W]{8,})$/;

const RegistrationForm: React.FC<Props> = (props: Props) => {
  
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const [lockButton] = useEyeButton();
  const [confirmLockButton,] = useEyeButton();
  const [isPasswordsEqual, setPasswordsEqual] = useState(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState(true);
  const [isValidForm, setIsValidForm] = useState(true);
  const [isEmailPasswordEqual, setIsEmailPasswordEqual] = useState(true);
  const [isCheckedValid, setIsCheckedValid] = useState(false);
  const [isCheckedFirst, setIsCheckedFirst] = useState(false);
  const [firstName,setFirstName] = useState<string>("");
  const [lastName,setLastName] = useState<string>("");
  const [email,setEmail] = useState<string>();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setconfirmPassword] = useState<string>("");

  const { code } = useParams<{ code?: string }>();
  const userInvite = useSelector((s) => s.users.userInvite);
  const [errorResponseCode, setErrorResponseCode] = useState<
    403 | 404 | 400 | 200|undefined
  >(undefined);

  useEffect(() => {
    if (userInvite) {
      setFirstName(userInvite.first_name);
      setLastName(userInvite.last_name);
      setEmail(userInvite.email);
    }
  }, [userInvite]);

  useEffect(() => {
    if (code) {
      dispatch(getInviteByCode.request(code));
      return () => {
        dispatch(changeSelectedUserInvite(undefined));
      };
    }
  }, [code]);
  const loadingGetInviteByCode = useSelector(
    (s) => s.users.loading.getInviteByCode
  );
  const errorGetInviteByCode = useSelector(
    (s) => s.users.errors.getInviteByCode
  );
  useLoading({
    loading: loadingGetInviteByCode,
    error: errorGetInviteByCode,
    notShowDefaultToaster: true,
    onError: () => {
      const status = (errorGetInviteByCode as AxiosError).response?.status;
      if (status === 404 || status === 403 || status === 400) {
        setErrorResponseCode(status as any);
      } else {
        showErrorAlert(errorGetInviteByCode);
      }
    },
    onSuccess:()=>{
      setErrorResponseCode(200)
    }
  });

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);

  };

  const handlePasswordConfirmChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setconfirmPassword(value);

  };

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFirstName(value);

  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLastName(value);

  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isPasswordsEqual && !!code) {
      setShowTermsAndConditionsDialog(true);
    }
    if(isCheckedValid && isPasswordsEqual && !!code){
      dispatch(
        registerUser.request({
          user: {
            first_name: firstName as string,
            last_name: lastName as string,
            email: email as string,
            password: password as string,
            password_confirmation: confirmPassword as string,
          },
          inviteCode: code!,
        })
      );
    }
  };

  useEffect(() => {
    setPasswordsEqual(password === confirmPassword);
  }, [password, confirmPassword]);
  
  useEffect(() => {
    password &&
    setIsPasswordsValid(pattern.test(password));
  }, [password]);

  useEffect(() => {
    setIsEmailPasswordEqual(password === email);
  }, [password, email]);

  useEffect(() => {
    isPasswordsValid && isPasswordsEqual && isCheckedValid &&
     !isEmailPasswordEqual && password!=="" && confirmPassword!=="" && firstName!=="" && lastName!==""? 
    setIsValidForm(true):setIsValidForm(false);
  },[isPasswordsValid,isPasswordsEqual,isEmailPasswordEqual,isCheckedValid,firstName,lastName,password,confirmPassword]);

  const loading = useSelector(
    (s) =>
      s.auth.loading.registerUser ||
      s.auth.loading.login ||
      s.auth.loading.getAuthenticatedUser
  );
  const error: any = useSelector((s) => s.auth.errors.registerUser);
  const onError = (error: any) => {
    if (error) {
      if (
        error.response?.data?.non_field_errors?.[0] === EMAIL_ALREADY_EXISTS
      ) {
        history.push(NonAuthRoutes.Login);
        AppToaster.show({
          icon: "tick",
          intent: "success",
          message: intl.formatMessage(
            { id: "app.errors.registration.email-already-registered" },
            { email: email }
          ),
        });
      } else {
        showErrorAlert(error);
      }
    }
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();}

  useLoading({ loading, error, notShowDefaultToaster: true, onError: onError });

  const errorMessage: JSX.Element = (
    <div className="pb-3 errorMsg">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.not-equal-to-confirm-password",
        })}
      </strong>
    </div>
  );


  const emptyFirstNameMessage: JSX.Element = (
    <div className="pb-3 errorMsg">
      <strong className="text-red-700">
        {firstName===""&&"First Name cannot be empty"}
      </strong>
    </div>
  );
  const emptyLastNameMessage: JSX.Element = (
    <div className="pb-3 errorMsg">
      <strong className="text-red-700">
        {lastName===""&&"Last Name cannot be empty"}
      </strong>
    </div>
  );
 

  const errorPasswordValid: JSX.Element = (
    <div className="pb-3 errorMsg">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.invalid-password",
        })}
      </strong>
    </div>
  );

  const errorEmailPasswordEqual: JSX.Element = (
    <div className="pb-3 errorMsg">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.email-password-not-equal",
        })}
      </strong>
    </div>
  );

  const errorIsCheckedValid: JSX.Element = (
    <div className="pb-3 errorMsg">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.is.checked.valid",
        })}
      </strong>
    </div>
  );

  const [
    showTermsAndConditionsDialog,
    setShowTermsAndConditionsDialog,
  ] = useState<boolean>(false);
 
const RegistrationForm=()=>{

  return(  
     <div className="registration-wrapper">
      <div className="registration-wrapper__header">
      <div className="container">
      <div className="nj-md-12 cstmColPad">
      <img src={logo} style={{ width: "140px" }} alt="NJ SCI Platform" />

      </div>
      </div>

      </div>
      <div className="registration-wrapper__content">
          <div className="container">
            <div className="nj-row">
                <div className="nj-md-6 cstmColPad">
                <div className="form-header">
                  <h1>Register</h1>
                  <p className="form-header__subTxt">
                  {intl.formatMessage({ id: "app.forms.registration-form.title" },
                  { strong: (chunks) => <strong>{chunks}</strong> })}
                  </p>
                  </div>
                  <form className="registration-wrapper__form" onSubmit={handleFormSubmit}>
                      <div className="nj-row">
                        <div className="nj-md-6 pr">
                            <div className="formControl">
                            <TextField fullWidth 
                              label="First Name"
                              id="outlined-size-small"
                              defaultValue=""
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={firstName}
                              onChange={handleFirstNameChange}
                              required={true}
                              />
                            </div>
                        </div>
                        <div className="nj-md-6 pl">
                            <div className="formControl">
                            <TextField fullWidth 
                              label="Last Name"
                              id="outlined-size-small"
                              defaultValue=""
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              value={lastName}
                              onChange={handleLastNameChange}
                              required={true}
                              />
                            </div>
                        </div>
                        <div className="nj-md-12">
                            <div className="formControl">
                            <TextField fullWidth 
                              label="Email"
                              id="outlined-size-small"
                              defaultValue=""
                              size="small"
                              type={"email"}
                              InputLabelProps={{ shrink: true }}
                              value={email}
                              disabled={true}
                              />
                            </div>
                        </div>
                        <div className="nj-md-12">
                            <div className="formControl">
                            <FormControl fullWidth variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                              <OutlinedInput
                              id="outlined-adornment-password"
                              onChange={handlePasswordChange}
                              type={showPassword ? 'text' : 'password'}
                              endAdornment={
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? 
                                    <VisibilityOff />
                                    : 
                                    <Visibility />
                                    }
                                 </IconButton>
                              </InputAdornment>
                              }
                              label="Password"
                              />
                           </FormControl>
                            </div>
                        </div>
                        <div className="nj-md-12">
                            <div className="formControl">
                            <FormControl fullWidth variant="outlined" size="small">
                              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                              <OutlinedInput
                              id="outlined-adornment-password"
                              type={showConfirmPassword ? 'text' : 'password'}
                              onChange={handlePasswordConfirmChange}
                              endAdornment={
                              <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showConfirmPassword ? 
                                    <VisibilityOff />
                                    : 
                                    <Visibility />
                                    }
                                 </IconButton>
                              </InputAdornment>
                              }
                              label="Confirm Password"
                              />
                           </FormControl>
                            </div>
                        </div>
                        {!isCheckedValid && isCheckedFirst && errorIsCheckedValid}
                        {isEmailPasswordEqual && errorEmailPasswordEqual}
                        {!isEmailPasswordEqual && !isPasswordsValid && errorPasswordValid}
                        {!isEmailPasswordEqual && isPasswordsValid && !isPasswordsEqual && errorMessage}
                        {emptyFirstNameMessage}
                        {emptyLastNameMessage}
                        

                        <div className="nj-md-12">
                        <div className="action">                        
                        <Button disabled={!isValidForm} type={"submit"} loading={loading} className="nj-btn change-password-btn">{intl.formatMessage({
                  id: "app.forms.registration-form.register-button-text",
                })}</Button>
                        </div>
                        </div>

                      </div>
                  </form>
                </div>
                <TermsAndConditionsDialog
                  role={userInvite?.role}
                  show={showTermsAndConditionsDialog}
                  onClose={() => {
                    setShowTermsAndConditionsDialog(false);
                  }}
                  onConfirm={() => {
                    setShowTermsAndConditionsDialog(false);
                    setIsCheckedValid(true);
                    setIsCheckedFirst(true);
                  }}
                  onNotConfirm={() => {
                    setShowTermsAndConditionsDialog(false);
                    setIsCheckedValid(false);
                  }}
                />
                
            </div>
          </div>
      </div>
    </div>)


}
return (
    <>
    <Helmet>
      <title>
        {intl.formatMessage({ id: "app.titles.accepting-invite" })}
      </title>
    </Helmet>
   {
     (errorResponseCode === 404 || errorResponseCode === 403 || errorResponseCode === 400)?
      (<InvitationErrorCallout errorCode={errorResponseCode} /> ) 
   : 
 
     errorResponseCode===200? RegistrationForm():""
}
    </>
  );
};

export default RegistrationForm;
