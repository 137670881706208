import { useParams } from "react-router";
import { SurveyReportRouteParams } from "../../types";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useSelectedSchoolId = () => {
  const { schoolId } = useParams<SurveyReportRouteParams>();
  const plan = useSelector((s) => s.plans.activePlan);

  const selectedSchoolId: number | undefined = useMemo(() => {
    return plan?.school.id ? +plan.school.id : schoolId ? +schoolId : undefined;
  }, [plan?.school.id, schoolId]);

  return { schoolId: selectedSchoolId };
};

export default useSelectedSchoolId;
