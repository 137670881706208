import {
  DemographicWrapper,
  EditedSurveyQuestionsByPage,
  QuestionTranslations,
  SurveyDemographic,
  SurveyPrompt,
  SurveyQuestionExcludeBaseModelType,
} from "../../../types";
import _ from "lodash";

export const addNewQuestion = (
  questionsPages: EditedSurveyQuestionsByPage,
  defaultDomain: number,
  page: number,
  demographic: SurveyDemographic,
  surveyId: number
): EditedSurveyQuestionsByPage => {
  const pages = Object.keys(questionsPages).map((p) => +p);
  let previousQuestion;
  if (page !== 0) {
    previousQuestion = !!questionsPages[page]?.length
      ? questionsPages[page][questionsPages[page].length - 1]
      : questionsPages[page - 1][questionsPages[page - 1].length - 1];
  } else if (!!questionsPages[page]?.length) {
    previousQuestion = questionsPages[page][questionsPages[page].length - 1];
  }
  const newQuestion: SurveyQuestionExcludeBaseModelType = {
    id: _.uniqueId(`${page.toString()}_`),
    survey: !!previousQuestion ? previousQuestion.survey : surveyId,
    domain: !!previousQuestion ? previousQuestion.domain : defaultDomain,
    demographic: !!previousQuestion
      ? previousQuestion.demographic
      : demographic,
    is_not_applicable_answer: false,
    text: "",
    page: page,
    order: !!previousQuestion ? previousQuestion.order + 1 : 0,
    translations: undefined,
    question_tags: [],
  };
  if (page !== _.max(pages)) {
    let updatedQuestions = {
      ...questionsPages,
      [page]: [...(questionsPages[page] ?? []), newQuestion],
    };
    for (let i = page + 1; i < pages.length; i++) {
      updatedQuestions = {
        ...updatedQuestions,
        [i]: updatedQuestions[i].map((sq) => ({
          ...sq,
          order: sq.order + 1,
        })),
      };
    }
    return updatedQuestions;
  }
  return {
    ...questionsPages,
    [page]: [...(questionsPages[page] ?? []), newQuestion],
  };
};

export const removeQuestion = (
  questionsPages: EditedSurveyQuestionsByPage,
  page: number,
  order: number
): EditedSurveyQuestionsByPage => {
  const pages = Object.keys(questionsPages).map((p) => +p);
  let updatedQuestions = {
    ...questionsPages,
    [page]: questionsPages[page].filter((sq) => sq.order !== order),
  };
  for (let i = page; i < pages.length; i++) {
    updatedQuestions = {
      ...updatedQuestions,
      [i]: updatedQuestions[i].map((sq) => ({
        ...sq,
        order: sq.order > order ? sq.order - 1 : sq.order,
      })),
    };
  }
  return updatedQuestions;
};

export const removePage = (
  questionsPages: EditedSurveyQuestionsByPage,
  page: number
): EditedSurveyQuestionsByPage => {
  const deletedPageLength = questionsPages[page]?.length;
  return _.fromPairs(
    _.map(
      _.filter(_.toPairs(questionsPages), (o) => Number(o[0]) !== page),
      (o) => [
        Number(o[0]) > page ? Number(o[0]) - 1 : o[0],
        _.map(o[1], (sq) =>
          sq.page > page
            ? {
                ...sq,
                page: sq.page - 1,
                order: sq.order - deletedPageLength,
              }
            : { ...sq }
        ),
      ]
    )
  );
};

export const reorderQuestions = (
  questionsPages: EditedSurveyQuestionsByPage,
  sourceIndex: number,
  destinationIndex: number,
  page: number
): EditedSurveyQuestionsByPage => {
  return {
    ...questionsPages,
    [page]: _.sortBy(
      _.map(questionsPages[page], (o) => {
        if (o.order === sourceIndex) {
          return { ...o, order: destinationIndex };
        }
        if (o.order > sourceIndex) {
          if (o.order > destinationIndex) {
            return o;
          } else {
            return { ...o, order: o.order - 1 };
          }
        } else {
          if (o.order < destinationIndex) {
            return o;
          } else {
            return { ...o, order: o.order + 1 };
          }
        }
      }),
      "order"
    ),
  };
};

export const addPage = (
  questionsPages: EditedSurveyQuestionsByPage,
  page: number
): EditedSurveyQuestionsByPage => {
  return {
    ...questionsPages,
    [page]: [],
  };
};

export const updateTranslations = (
  questionsPages: EditedSurveyQuestionsByPage,
  translates: QuestionTranslations
): EditedSurveyQuestionsByPage => {
  return _.fromPairs(
    _.map(_.toPairs(questionsPages), (o) => [
      o[0],
      _.map(o[1], (sq) =>
        !!translates[sq.id]
          ? {
              ...sq,
              translations: translates[sq.id],
            }
          : { ...sq }
      ),
    ])
  );
};

export const setLeadIn = (
  questionsByPage: SurveyQuestionExcludeBaseModelType[],
  order: number,
  leadIn?: string
): SurveyQuestionExcludeBaseModelType | undefined => {
  let editedQuestion = questionsByPage.find((q) => q.order === order);

  if (editedQuestion) {
    editedQuestion.lead_in = !leadIn ? undefined : leadIn;
    updateQuestionLeadInTranslations(questionsByPage, editedQuestion);
  }

  return editedQuestion;
};

export const updateQuestionLeadInTranslations = (
  questionsByPage: SurveyQuestionExcludeBaseModelType[],
  updatedQuestion: SurveyQuestionExcludeBaseModelType
) => {
  const leadInTranslations = questionsByPage.find(
    (q) =>
      q.lead_in === updatedQuestion.lead_in && q.order !== updatedQuestion.order
  )?.translations;

  if (leadInTranslations) {
    const hasLeadInTranslation = _.toPairs(leadInTranslations).some(
      ([, translate]) => translate.lead_in
    );

    if (hasLeadInTranslation) {
      _.toPairs(leadInTranslations).forEach(([language, translation]) => {
        updatedQuestion.translations = {
          ...(updatedQuestion.translations || {}),
          [language]: {
            ...(updatedQuestion.translations?.[language] || {}),
            lead_in: translation.lead_in,
          },
        };
      });
    }
  }
};

export const updateRemovedQuestionsPageTextTranslations = (
  pageText: DemographicWrapper<SurveyPrompt>,
  removedPage: number,
  demographic: SurveyDemographic
): DemographicWrapper<SurveyPrompt> => {
  return {
    ...pageText,
    [demographic]: _.fromPairs(
      Object.entries(pageText?.[demographic] ?? {})
        .filter((entry) => Number(entry[0]) !== removedPage)
        ?.map((entry) =>
          isNaN(Number(entry[0]))
            ? entry
            : [+entry[0] > removedPage ? +entry[0] - 1 : entry[0], entry[1]]
        )
    ),
  };
};
