import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS } from "react-joyride";
import TooltipComponent from "../../../tour/TooltipComponent";
import { DistrictSetupTaskKey, UserRole } from "../../../../types";
import { useSelector } from "react-redux";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import useUserPlanAssociation from "../../../../helpers/hooks/useUserPlanAssociation";
import { isDistrictPlan } from "../../../../store/plans/selectors";

type OwnProps = {};

type Props = OwnProps;

const DistrictClimateTeamDevelopmentTour: FunctionComponent<Props> = (
  props
) => {
  const intl = useIntl();

  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const { isTeamMember, role, isDCC } = useUserRole();

  const {
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
    planSchoolId,
  } = useUserPlanAssociation();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const isSchoolLevelPresentation = useMemo(() => {
    return (
      (isTeamMember &&
        (role === UserRole.Staff || role === UserRole.VicePrincipal)) ||
      (isSchoolPlanPrincipal && role !== UserRole.Superintendent)
    );
  }, [role, isTeamMember, isSchoolPlanPrincipal]);
  const steps = useMemo(() => {
    return [
      {
        target: '[data-tour="manage-users-tour"]',
        content: intl.formatMessage({
          id: planSchoolId
            ? "app.tours.invite_school_staff"
            : "app.tours.manage_users_tour",
        }),
        disableBeacon: true,
      },
    ];
  }, [isSchoolLevelPresentation]);

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (setupCompleted) {
      setShow(false);
    } else {
      if (
        (isSchoolLevelPresentation && !isDistrictActivePlan) ||
        (!isSchoolLevelPresentation && isDistrictActivePlan)
      ) {
        if (
          isSchoolPlanPrincipal &&
          role === UserRole.Superintendent &&
          !isSingleSchoolDistrict
        ) {
          setShow(
            !tours[DistrictSetupTaskKey.LearnAboutTeamDevelopment] &&
            !!tours[DistrictSetupTaskKey.InviteOtherMembers]
          );
        } else if ((isTeamMember && !isDCC) || isSchoolPlanPrincipal) {
          setShow(
            !tours[DistrictSetupTaskKey.LearnAboutTeamDevelopment] &&
            !!tours[DistrictSetupTaskKey.FamiliarizeWithSccp]
          );
        } else {
          setShow(
            isSingleSchoolDistrict
              ? !tours[DistrictSetupTaskKey.LearnAboutTeamDevelopment] &&
              !!tours[DistrictSetupTaskKey.RegisterYourSchool] &&
              !!tours[
              DistrictSetupTaskKey.DesignateDistrictClimateCoordinator
              ]
              : !tours[DistrictSetupTaskKey.LearnAboutTeamDevelopment] &&
              !!tours[DistrictSetupTaskKey.InviteOtherMembers] &&
              !!tours[
              DistrictSetupTaskKey.DesignateDistrictClimateCoordinator
              ]
          );
        }
      }
    }
  }, [
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
    isTeamMember,
    setupCompleted,
    isSchoolLevelPresentation,
  ]);

  const handleJoyrideAction = (data: CallBackProps) => {
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      setShow(false);
    }
  };

  return (
    <ReactJoyride
      run={show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
    />
  );
};

export default DistrictClimateTeamDevelopmentTour;
