import React, { useCallback, useMemo } from "react";
import {
  AnswerStatistics,
  ResponsesDistribution,
  SurveyDemographic,
  SurveyQuestionStatistics,
} from "../../../../types";
import HorizontalBarChart from "../../../charts/HorizontalBarChart";
import { useSelector } from "react-redux";
import { Locale } from "../../../../store/UIState";
import { Popover } from "@blueprintjs/core";
import SurveyAnonymizeNonIdealState from "../non-ideal-states/SurveyAnonymizeNonIdealState";
import ItemMark from "./ItemMark";
import useDatasetMark from "../../cards/useDatasetMark";
import QuestionShortCode from "./QuestionShortCode";
import useHighlightedText from "../../../../helpers/hooks/useHighlightedText";
import TotalCountStatisticPopoverContent from "./TotalCountStatisticPopoverContent";

type OwnProps = {
  index?: number;
  domainId?: number;
  maxAnswerIndex: number;
  inReportInsights?: boolean;
  surveyQuestionStatistics: SurveyQuestionStatistics<AnswerStatistics>;
  demographic: SurveyDemographic;
  showTotalRespondentsColumn: boolean;
  isHighlighted?: boolean;
  manageBookmarkDialog?: boolean;
  isDisaggregation?: boolean;
};

type Props = OwnProps;

const DomainStatisticsTableRow: React.FC<Props> = (props) => {
  const {
    index,
    domainId,
    maxAnswerIndex,
    surveyQuestionStatistics,
    demographic,
    showTotalRespondentsColumn,
    isHighlighted,
    inReportInsights,
    manageBookmarkDialog,
    isDisaggregation,
  } = props;

  const selectedDomain = useSelector((s) => s.domains.selectedDomain);

  const responsesDistribution: ResponsesDistribution[] = useMemo(() => {
    const domainAnswers = selectedDomain?.domain_answer?.[demographic];
    if (!!domainAnswers) {
      return Object.entries(domainAnswers?.[Locale.English] ?? {})
        ?.filter((entry) => Number(entry[0]) !== -1)
        ?.map((item) => ({
          label: item[1],
          percent: Math.round(
            (surveyQuestionStatistics?.[demographic]?.distributions?.find(
              (d) => d.answer_index === Number(item[0])
            )?.mean_rating ?? 0) * 100
          ),
          responses:
            surveyQuestionStatistics?.[demographic]?.distributions?.find(
              (d) => d.answer_index === Number(item[0])
            )?.count ?? 0,
        }));
    }
    return [];
  }, [selectedDomain, surveyQuestionStatistics]);

  const showSeparator = useCallback(
    (index) => {
      return responsesDistribution?.length / 2 === index + 1;
    },
    [responsesDistribution]
  );

  const popoverTarget = useMemo(
    () => (
      <div className="flex justify-between items-end my-2 cursor-pointer">
        {responsesDistribution?.map((item, index) => (
          <div className="flex flex-row" key={index}>
            <div className="px-2 flex flex-col text-center">
              {item.label.split(" ").map((label, i) => (
                <span key={i} className="font-bold">
                  {label}
                </span>
              ))}
              <span className="font-bold">{item.percent}%</span>
              <span>({item.responses})</span>
            </div>
            {showSeparator(index) && <div className="vertical-line" />}
          </div>
        ))}
      </div>
    ),
    [responsesDistribution, showSeparator]
  );

  const itemText = useMemo(() => {
    if (surveyQuestionStatistics.lead_in) {
      return `${surveyQuestionStatistics.lead_in} ${surveyQuestionStatistics.text}`;
    }

    return surveyQuestionStatistics.text;
  }, [surveyQuestionStatistics.lead_in, surveyQuestionStatistics.text]);

  const mean = useMemo(() => {
    const mValue =
      (surveyQuestionStatistics?.[demographic]?.mean_rating ?? 0) *
      maxAnswerIndex;
    if (mValue === 1.0) return 1.04;
    else return mValue;
  }, [surveyQuestionStatistics, demographic, maxAnswerIndex]);

  const {
    flagged,
    bookmarkType,
    onClick,
    onDiscard,
    supportsBookmarks,
  } = useDatasetMark(
    {
      domainId: domainId!,
      questionId: surveyQuestionStatistics.question_id,
      demographic: demographic,
      is_grouped: false,
    },
    mean
  );

  const { highlightedText } = useHighlightedText({
    text: itemText,
  });

  const { leftPartClassName, rightPartClassName } = useMemo(() => {
    return {
      leftPartClassName: isHighlighted ? "bg-orange-300" : "bg-gray-200",
      rightPartClassName: isHighlighted ? "bg-orange-300" : "bg-gray-100",
    };
  }, [isHighlighted]);

  const keyHorizontalBarChart = useMemo(() => {
    return inReportInsights ? 0 : 1;
  }, [inReportInsights]);

  return (
    <tr>
      <td
        colSpan={supportsBookmarks ? 1 : 2}
        className={`font-bold text-xl ${leftPartClassName}`}
      >
        {surveyQuestionStatistics.short_code && (
          <>
            <QuestionShortCode
              inverse={isHighlighted}
              shortCode={surveyQuestionStatistics.short_code}
              deletedIcon={surveyQuestionStatistics?.deleted_in_njsci_v2}
            />
          </>
        )}

        <div dangerouslySetInnerHTML={{ __html: highlightedText }} />
      </td>

      {supportsBookmarks && (
        <td className={`${leftPartClassName} p-2`}>
          <ItemMark
            flagged={flagged}
            bookmarkType={bookmarkType}
            onClick={onClick}
            onDiscard={onDiscard}
          />
        </td>
      )}

      {surveyQuestionStatistics?.[demographic]?.not_enough_data ? (
        <td colSpan={3} className={`${leftPartClassName}`}>
          <SurveyAnonymizeNonIdealState />
        </td>
      ) : (
        <>
          <td
            key={keyHorizontalBarChart}
            className={rightPartClassName}
            style={{ maxWidth: 200 }}
          >
            <div className="flex w-full items-center">
              <HorizontalBarChart
                index={index}
                maxAnswerIndex={maxAnswerIndex}
                dataSet={{
                  data: [mean],
                  labels: [],
                }}
                isDisaggregation={isDisaggregation}
                inReportInsights={inReportInsights}
                domainId={selectedDomain?.id}
                color={selectedDomain?.color_hex}
              />
            </div>
          </td>
          <td className={rightPartClassName}>
            <Popover
              content={
                <TotalCountStatisticPopoverContent
                  responsesDistribution={responsesDistribution}
                />
              }
              target={popoverTarget}
            />
          </td>
          {showTotalRespondentsColumn && (
            <td className={rightPartClassName}>
              <div className="flex justify-center text-2xl font-semibold">
                {surveyQuestionStatistics?.[demographic]?.distributions?.reduce(
                  (c, p) => c + (p?.count || 0),
                  0
                )}
              </div>
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default DomainStatisticsTableRow;
