import { useMemo } from "react";
import { useSelector } from "react-redux";
import { UserRole } from "../../types";

export const useUserPlanAssociation = () => {
  const activePlan = useSelector((s) => s.plans.activePlan);
  const currentUser = useSelector((s) => s.auth.authenticatedUser);
  const plans = useSelector((s) => s.plans.plans);

  return useMemo(() => {
    const planSchoolId = activePlan?.school?.id;

    // if planSchoolId=undefined - district plan, should check on all plans,
    // otherwise schoolPlan and checking only activePlan
    return {
      planDistrictId: activePlan?.district.id,
      planSchoolId: planSchoolId,
      isSchoolPlanPrincipal:
        currentUser?.profile?.role === UserRole.Principal && planSchoolId
          ? true
          : planSchoolId
          ? !!(
              currentUser?.id &&
              planSchoolId &&
              currentUser.id == activePlan!.school!.principal!.id
            )
          : !!(
              plans?.length &&
              plans.some(
                (p) =>
                  p?.school?.principal?.id &&
                  p.school.principal.id === currentUser?.id
              )
            ),
      isSingleSchoolDistrict: !!activePlan?.district.is_single_school_district,
    };
  }, [activePlan?.district]);
};

export default useUserPlanAssociation;
