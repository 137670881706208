import { Button, Menu, MenuItem, Navbar, Popover } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { AdminRoutes, AuthRoutes } from "../../App";
import useWindowSize, { WindowSize } from "../../helpers/hooks/useWindowSize";
import LocaleSelector from "./LocaleSelector";
import LogOutMenuItem from "./LogOutMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { getIsAuthenticated, selectIsAdmin } from "../../store/auth/selectors";
import { generatePath } from "react-router";
import { showEditProfileDialog } from "../../store/profile/actions";

const NAVBAR_HEIGHT = 50;
const TEST_MODE_BORDER_WIDTH = 14;

type Props = {
  isTesting: boolean;
};

export const AppNavbar: React.FC<Props> = (props) => {
  const { isTesting } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(getIsAuthenticated);

  const isAdminAuthenticated = useSelector(selectIsAdmin);

  const isLanguageSelectorHidden = useSelector(
    (s) => s.UIState.hideLanguageSelector
  );

  const history = useHistory();

  const windowSize = useWindowSize();

  const handleProfile = () => {
    dispatch(showEditProfileDialog());
  };

  const handleSettingsClick = () =>
    history.push(AdminRoutes.LocalStorageSettings);

  const isSmallScreen = useMemo(() => {
    return windowSize === WindowSize.SM;
  }, [windowSize]);

  return (
    <Navbar
      style={{
        marginTop: isTesting ? NAVBAR_HEIGHT - TEST_MODE_BORDER_WIDTH : 0,
      }}
    >
      <Navbar.Group align="left">
        <Navbar.Heading className="font-bold">
          {intl.formatMessage({ id: "app.title" })}
        </Navbar.Heading>
        {!isLanguageSelectorHidden && <LocaleSelector />}
      </Navbar.Group>
      {isAuthenticated && (
        <Navbar.Group align="right">
          {isSmallScreen ? (
            <Popover
              content={
                <Menu>
                  <MenuItem
                    text={intl.formatMessage({ id: "app.menu.profile" })}
                    icon="user"
                    onClick={handleProfile}
                  />
                  {isAdminAuthenticated && (
                    <MenuItem
                      text={intl.formatMessage({ id: "app.menu.settings" })}
                      icon="cog"
                      onClick={handleSettingsClick}
                    />
                  )}
                  <Menu.Divider />
                  <LogOutMenuItem />
                </Menu>
              }
            >
              <Button minimal icon="menu" />
            </Popover>
          ) : (
            <>
              <Link
                to={generatePath(AuthRoutes.YourPlan, {
                  workspace: "dashboard" as any,
                } as any)}
              >
                <Button
                  minimal
                  icon="home"
                  text={intl.formatMessage({ id: "app.titles.dashboard" })}
                  className="font-bold"
                />
              </Link>
              <Navbar.Divider />
              <Popover
                content={
                  <Menu>
                    <MenuItem
                      text={intl.formatMessage({ id: "app.menu.profile" })}
                      icon="user"
                      onClick={handleProfile}
                    />
                    <Menu.Divider />
                    <LogOutMenuItem />
                  </Menu>
                }
              >
                <Button minimal icon="user" />
              </Popover>

              {isAdminAuthenticated && (
                <Popover
                  content={
                    <Menu>
                      <MenuItem
                        text={intl.formatMessage({
                          id: "app.menu.settings",
                        })}
                        onClick={handleSettingsClick}
                      />
                    </Menu>
                  }
                >
                  <Button minimal icon="cog" />
                </Popover>
              )}
            </>
          )}
        </Navbar.Group>
      )}
    </Navbar>
  );
};

export default AppNavbar;
