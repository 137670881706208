import React, { FunctionComponent } from "react";
import GenericCollapse from "../../../../common/GenericCollapse";
import { Domain, SurveyQuestion } from "../../../../../types";
import useDatasetMark from "../../../cards/useDatasetMark";
import ItemMark from "../../../table/survey-report-table-row/ItemMark";
import { useSelector } from "react-redux";
import DomainGroupItem from "./DomainGroupItem";
import MeanScoreItemTag from "./MeanScoreItemTag";

type OwnProps = {
  domain: Domain;
  items: SurveyQuestion[];
  domainMeanScore?: number;
  itemsMeansDictionary: { [id: number]: number };
};

type Props = OwnProps;

const DomainGroupCollapse: FunctionComponent<Props> = (props) => {
  const { domain, items, domainMeanScore, itemsMeansDictionary } = props;

  const { groupBy, demographic } = useSelector(
    (s) => s.surveyReports.dialogs.insightSubgroupInfoDialog
  );

  const datasetMarkParams = {
    domainId: domain.id,
    is_grouped: true,
    demographic: demographic,
    ...(groupBy ? { [groupBy.key]: groupBy.value } : {}),
  };

  const {
    flagged,
    onClick,
    onDiscard,
    bookmarkType,
    supportsBookmarks,
  } = useDatasetMark(datasetMarkParams, domainMeanScore);

  return (
    <GenericCollapse
      title={
        <div className="flex items-center gap-2 pl-2">
          {supportsBookmarks && (
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <ItemMark
                flagged={flagged}
                bookmarkType={bookmarkType}
                onClick={onClick}
                onDiscard={onDiscard}
              />
            </span>
          )}
          <MeanScoreItemTag score={domainMeanScore} />

          {domain.name}
        </div>
      }
      showCollapseWithEmptyContent={false}
      defaultIsOpen
    >
      {items.length ? (
        <div className="space-y-2">
          {items.map((question) => (
            <DomainGroupItem
              key={question.id}
              question={question}
              itemMeanScore={itemsMeansDictionary[question.id]}
              datasetMarkParams={datasetMarkParams}
            />
          ))}
        </div>
      ) : null}
    </GenericCollapse>
  );
};

export default DomainGroupCollapse;
