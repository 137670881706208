import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { District, School, SurveyDemographic } from "../../../types";
import {
  AnchorButton,
  Button,
  ControlGroup,
  FormGroup,
  InputGroup,
  NonIdealState,
} from "@blueprintjs/core";
import SchoolSelector from "../../common/selectors/SchoolSelector";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { generatePath } from "react-router";
import { AuthAndNonAuthRoutes } from "../../../App";
import { getSchoolsByDistricts } from "../../../store/schools/actions";
import { AppToaster } from "../../../helpers/toaster";
import _ from "lodash";
import useUserRole from "../../../helpers/hooks/useUserRole";
import { colors } from "react-select/src/theme";

type OwnProps = {
  forTeamPlanView?: boolean;
  districts?: District[];
  availableDemographics?: SurveyDemographic[];
  surveyHashMap: {
    [key in SurveyDemographic]?: string;
  };
};

type Props = OwnProps;

const SurveyLinkForm: React.FC<Props> = (props) => {
  const {
    districts,
    availableDemographics,
    surveyHashMap,
    forTeamPlanView,
  } = props;

  const intl = useIntl();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const { isAdmin } = useUserRole();

  const [selectedSchool, setSelectedSchool] = useState<School | undefined>();

  useEffect(() => {
    if (isAdmin && districts?.length && !forTeamPlanView) {
      dispatch(
        getSchoolsByDistricts.request({
          district_ids: districts.map((item) => item.id),
        })
      );
    } else if (activePlan) {
      setSelectedSchool(activePlan.school);
    }
  }, [isAdmin, activePlan, districts]);

  const dispatch = useDispatch();

  const schoolsByDistricts = useSelector((s) => s.schools.schools as School[]);
  const schoolsLoading = useSelector(
    (s) => s.schools.loading.getSchoolsByDistricts
  );

  const url = useMemo(() => {
    return window.location.protocol + "//" + window.location.host;
  }, []);

  const generateSurveyQuestionsLink = useCallback(
    (demographicHash?: string, school?: string) => {
      return (
        url +
        "/app" +
        generatePath(AuthAndNonAuthRoutes.SurveysQuestions, {
          hash: demographicHash!,
          school: school!,
        })
      );
    },
    [url]
  );

  const copyLinkToClipboard = useCallback(
    (
      inputRef: React.RefObject<HTMLInputElement>,
      translatedDemographic: string
    ) => {
      inputRef.current?.select();
      document.execCommand("copy");
      inputRef.current?.setSelectionRange(0, 0);
      inputRef.current?.blur();

      AppToaster.show({
        message: intl.formatMessage(
          {
            id: "app.toaster.surveys-deployment.copied-successfully",
          },
          { demographic: translatedDemographic }
        ),
        icon: "tick",
        intent: "success",
        timeout: 5000,
      });
    },
    []
  );

  const copyButton = useCallback(
    (onClick: () => void) => (
      <Button intent="success" text="Copy" onClick={() => onClick()} large />
    ),
    []
  );

  const viewInTestModeButton = useCallback(
    (url?: string) => (
      <AnchorButton
        intent="primary"
        text={intl.formatMessage({
          id: "app.survey-deployment-table.survey-link-form.view-in-test-mode",
        })}
        title={intl.formatMessage({
          id: "app.survey-deployment-table.survey-link-form.view-in-test-mode",
        })}
        href={url + "/test"}
        target="_blank"
      />
    ),
    []
  );

  const handleSchoolChange = (schoolId?: number) => {
    setSelectedSchool(
      schoolsByDistricts.find((school) => school.id === schoolId)
    );
  };

  const [demographicRefs, setDemographicRefs] = useState<
    {
      [key in SurveyDemographic]?: RefObject<HTMLInputElement>;
    }
  >({});

  useEffect(() => {
    setDemographicRefs(
      _.chain(availableDemographics)
        .map((d) => [d, React.createRef()])
        .fromPairs()
        .value()
    );
  }, [availableDemographics]);

  return (
    <div className="p-4">
    <p className="text-red-901 text-center font-bold">{intl.formatMessage({ id: "app.forms.get-survey-link.survey-waring-message" })}</p>
    <p className="text-red-901 flex justify-center">{intl.formatMessage({ id: "app.forms.get-survey-link.survey-waring-submessage" })}</p> 
   <div className={"p-4"}>
    <form>
        {!forTeamPlanView && (
          <FormGroup>
            <SchoolSelector
              schools={schoolsByDistricts}
              onSchoolChange={handleSchoolChange}
              loading={schoolsLoading}
            />
          </FormGroup>
        )}

        {selectedSchool ? (
          <>
            {availableDemographics?.length ? (
              availableDemographics.map((demographic) => {
                const demographicName = intl.formatMessage({
                  id: `app.filters.respondent-type.${demographic}`,
                });
                const label = `${demographicName} ${intl.formatMessage({
                  id: "app.forms.get-survey-link.survey-link",
                })}`;
                return (
                  <FormGroup label={label}>
                    <ControlGroup fill>
                      <InputGroup
                        inputRef={demographicRefs?.[demographic]}
                        large
                        value={generateSurveyQuestionsLink(
                          surveyHashMap?.[demographic],
                          selectedSchool.school_hash
                        )}
                        id= {demographic}
                        readOnly
                      />
                      {copyButton(() =>
                        copyLinkToClipboard(
                          demographicRefs?.[demographic]!,
                          demographicName
                        )
                      )}
                      {viewInTestModeButton(
                        demographicRefs?.[demographic]?.current?.value
                      )}
                    </ControlGroup>
                  </FormGroup>
                );
              })
            ) : (
              <NonIdealState
                icon={"search"}
                title={intl.formatMessage({
                  id: "app.forms.get-survey-link.non-ideal-state.title",
                })}
                description={intl.formatMessage({
                  id:
                    "app.forms.get-survey-link.non-ideal-state.no-time-periods",
                })}
              />
            )}
          </>
        ) : (
          <NonIdealState
            icon={"search"}
            title={intl.formatMessage({
              id: "app.forms.get-survey-link.non-ideal-state.title",
            })}
            description={intl.formatMessage({
              id: "app.forms.get-survey-link.non-ideal-state.description",
            })}
          />
        )}
      </form>
    </div>
    </div> 
  );
};

export default SurveyLinkForm;
