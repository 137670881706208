import { createAction, createAsyncAction } from "typesafe-actions";
import { Activity, Meeting, UserNotes, UserNotesType } from "../../types";
import {
  AddNotesRequest,
  GetNotesRequest,
  GetNotesResponse,
} from "../../api/notes/types";

export const showNotesDialog = createAction("@notes/SHOW_NOTES_DIALOG")<{
  type?: UserNotesType;
  meeting?: Meeting<string, false>;
  selectedNote?: UserNotes;
  selectedActivity?: Activity;
  checkExisting?:Boolean;
  agenda?: string;
}>();
export const hideNotesDialog = createAction("@notes/HIDE_NOTES_DIALOG")<void>();

export const updateDialogNote = createAction(
  "@notes/UPDATE_DIALOG_NOTE"
)<AddNotesRequest>();

export const changeActivityNotesDialog = createAction(
  "@notes/CHANGE_ACTIVITY_NOTES_DIALOG"
)<{ activity: Activity; userNotesType?: UserNotesType; notes?: UserNotes }>();

export const getNotes = createAsyncAction(
  "@notes/GET_NOTES_REQUEST",
  "@notes/GET_NOTES_SUCCESS",
  "@notes/GET_NOTES_FAILURE"
)<GetNotesRequest, GetNotesResponse, Error>();

export const addNotes = createAsyncAction(
  "@notes/ADD_NOTES_REQUEST",
  "@notes/ADD_NOTES_SUCCESS",
  "@notes/ADD_NOTES_FAILURE"
)<AddNotesRequest, UserNotes, Error>();

export const updateNotes = createAsyncAction(
  "@notes/UPDATE_NOTES_REQUEST",
  "@notes/UPDATE_NOTES_SUCCESS",
  "@notes/UPDATE_NOTES_FAILURE"
)<{ body: AddNotesRequest; id: number }, UserNotes, Error>();

export const deleteNote = createAsyncAction(
  "@notes/DELETE_NOTE_REQUEST",
  "@notes/DELETE_NOTE_SUCCESS",
  "@notes/DELETE_NOTE_FAILURE"
)<{ id: number; planId: number }, number, Error>();


export const saveNoteToRedux = createAction('SAVE_NOTE_TO_REDUX', (note: UserNotes) => ({
  payload: { note } ,
}))();

export const saveNoteToRedux1 = createAction("SAVE_NOTE_TO_REDUX")<{
 
  selectedNote?: UserNotes;
 
}>();


export const pusherUpdateNoteEvent = createAction(
  "@notes/PUSHER_UPDATE_NOTE_EVENT"
)<any>();

export const pusherNewNoteEvent = createAction(
  "@notes/PUSHER_NEW_NOTE_EVENT"
)<any>();


export const pusherDeleteNoteEvent = createAction(
  "@notes/PUSHER_DELETE_NOTE_EVENT"
)<any>();

export const setNavigationWarning = createAction(
  "@notes/NAVIGATION_WARNING"
)<any>();