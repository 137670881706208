import { Button, Classes, Dialog, Icon } from "@blueprintjs/core";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { hideViewImplementationLogsDialog } from "../../../../store/plan-strategies/actions";
import ImplementationLogsTable from "./ImplementationLogsTable";

type OwnProps = {};

type Props = OwnProps;

const ViewImplementationLogsDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { show, planStrategy, onConfirm } = useSelector(
    (s) => s.planStrategies.dialogs.viewImplementationLogsDialog
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideViewImplementationLogsDialog());
    onConfirm && onConfirm();
  };

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      style={{
        minWidth: "31.25rem", //500px
      }}
      icon={<Icon icon="list" iconSize={24} />}
      title={intl.formatMessage(
        { id: "app.strategies.dialogs.view-implementation-logs" },
        { strategyName: planStrategy?.strategy?.name }
      )}
    >
      <div className={Classes.DIALOG_BODY}>
        {planStrategy ? (
          <ImplementationLogsTable planStrategy={planStrategy} />
        ) : null}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-center mt-4">
          <Button className="w-1/4" onClick={handleClose} type={"reset"}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ViewImplementationLogsDialog;
