import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Plan } from "../../../../types";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import { Button, Card } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../../../constants";

type OwnProps = {
  activePlan: Plan;
};

type Props = OwnProps;

const RecommendationsToPrincipal: FunctionComponent<Props> = (props) => {
  const { activePlan } = props;

  const intl = useIntl();

  const { isPrincipal, user } = useUserRole();

  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    const watched = getBooleanFromLocalStorage(
      LocalStorageKeys.SuperintendentAsPrincipalInfo
    );
    setShow(!watched);
  }, []);

  const hasPrincipalAccess = useMemo(() => {
    return (
      (user?.id && activePlan.school?.principal?.id === user.id) || isPrincipal
    );
  }, [isPrincipal, user?.id, activePlan.school?.principal?.id]);

  const handleDone = () => {
    localStorage.setItem(
      LocalStorageKeys.SuperintendentAsPrincipalInfo,
      String(true)
    );
    setShow(false);
  };

  return show && hasPrincipalAccess ? (
    <Card className="p-2 mb-4 text-base">
      {intl.formatMessage(
        {
          id: "app.next-steps.superintendent-as-principal.info",
        },
        {
          br: <br />,
          b: (chunks) => <b>{chunks}</b>,
        }
      )}
      <div className="flex items-center justify-center mt-2">
        <Button
          intent="primary"
          text={intl.formatMessage({ id: "app.titles.got-it" })}
          title={intl.formatMessage({ id: "app.titles.got-it" })}
          onClick={handleDone}
        />
      </div>
    </Card>
  ) : null;
};

export default RecommendationsToPrincipal;
