import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Callout,
  Classes,
  EditableText,
  FormGroup,
  Icon,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import LocaleSelector from "../LocaleSelector";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useIntl } from "react-intl";
import {
  DOMAIN_ANSWER_MIN_VALUE,
  DomainAnswerSet,
  NOT_APPLICABLE_KEY,
  SurveyDemographic,
} from "../../../types";
import useLanguages from "../../../helpers/hooks/useLanguages";
import { Locale, LocaleDisplayedValues } from "../../../store/UIState";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addDomainAnswerSet,
  hideDomainAnswerSetUpsertDialog,
  updateDomainAnswerSet,
} from "../../../store/domain-answer-set/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";

type OwnProps = {
  editingValuesOnly?: boolean;
  onSuccess?: Function;
  selectedDomainAnswerSet?: DomainAnswerSet;
};

type Props = OwnProps;

const NOT_APPLICABLE_DEFAULT_VALUE_ENGLISH = "Does not apply to me";

type LocalAnswerSet = {
  [key in SurveyDemographic]: {
    [langKey in Locale]: {
      [value: number]: {
        text: string;
        local_id: string;
      };
    };
  };
};

const MINIMUM_NUMBER_OF_ANSWER_CHOICES = 2;

const DomainAnswerSetForm: FunctionComponent<Props> = (props) => {
  const { selectedDomainAnswerSet, onSuccess, editingValuesOnly } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const demographics: {
    demographic: SurveyDemographic;
    text: string;
  }[] = useMemo(() => {
    return Object.values(SurveyDemographic).map((demographic) => {
      return {
        demographic: demographic,
        text: intl.formatMessage({
          id: `app.filters.respondent-type.${demographic}`,
        }),
      };
    });
  }, []);

  const defaultState: LocalAnswerSet = useMemo(() => {
    return _.chain(SurveyDemographic)
      .values()
      .map((demographic) => [
        demographic,
        {
          [Locale.English]: {},
        },
      ])
      .fromPairs()
      .value() as LocalAnswerSet;
  }, []);

  const [selectedDemographic, setSelectedDemographic] =
    useState<SurveyDemographic>(SurveyDemographic.ElementaryStudents);

  const [isNegative, setIsNegative] = useState<boolean>(false);

  const reverseOrder = () => {
    setAnswerSet(
      (answerSet) =>
        _.chain(answerSet)
          .toPairs()
          .map(([demographicKey, localesAnswers]) => [
            demographicKey,
            _.chain(localesAnswers)
              .toPairs()
              .map(([localeKey, answerSet]) => [
                localeKey,
                _.chain(answerSet)
                  .toPairs()
                  .map(([answerIndex, obj], index, array) => [
                    +answerIndex === NOT_APPLICABLE_KEY
                      ? answerIndex
                      : array.length - index - 1,
                    obj,
                  ])
                  .fromPairs()
                  .value(),
              ])
              .fromPairs()
              .value(),
          ])
          .fromPairs()
          .value() as any
    );
  };

  const handleIsNegativeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    reverseOrder();
    setIsNegative(checked);
  };

  const handleNotApplicableOptionUsageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;

    setAnswerSet(
      (answerSet) =>
        _.chain(answerSet)
          .toPairs()
          .map(([demographicKey, localesAnswers]) => [
            demographicKey,
            _.chain(usedLocales)
              .map((locale) => {
                const locales = localesAnswers[locale] ?? {};
                const options = checked
                  ? _.keys(locales).length
                    ? {
                        ...locales,
                        [NOT_APPLICABLE_KEY]: {
                          text:
                            locale === Locale.English
                              ? NOT_APPLICABLE_DEFAULT_VALUE_ENGLISH
                              : "",
                          local_id: _.uniqueId(),
                        },
                      }
                    : {}
                  : _.omit(localesAnswers[locale] ?? {}, [NOT_APPLICABLE_KEY]);
                return [locale, options];
              })
              .fromPairs()
              .value(),
          ])
          .fromPairs()
          .value() as any
    );
  };

  const { selectedLocale, setSelectedLocale } = useLanguages();

  const [answerSet, setAnswerSet] = useState<LocalAnswerSet>(defaultState);

  useEffect(() => {
    if (selectedDomainAnswerSet) {
      setIsNegative(selectedDomainAnswerSet.is_negative);
      setAnswerSet(
        _.chain(selectedDomainAnswerSet)
          .pick(Object.values(SurveyDemographic))
          .toPairs()
          .map(([demographicKey, localesAnswers]) => [
            demographicKey,
            _.chain(localesAnswers)
              .toPairs()
              .map(([localeKey, answerSet]) => [
                localeKey,
                _.chain(answerSet)
                  .toPairs()
                  .map(([answerIndex, text]) => [
                    answerIndex,
                    {
                      local_id: _.uniqueId(),
                      text: text,
                    },
                  ])
                  .fromPairs()
                  .value(),
              ])
              .fromPairs()
              .value(),
          ])
          .fromPairs()
          .value() as any
      );
    } else {
      setIsNegative(false);
      setAnswerSet(defaultState);
    }
    setSelectedLocale(Locale.English);
    setSelectedDemographic(SurveyDemographic.ElementaryStudents);
    setWarning(undefined);
    setShowWarningAlert(false);
  }, [selectedDomainAnswerSet]);

  const usedLocales = useMemo(() => {
    return (
      (_.chain(answerSet)
        .values()
        .flatMap((v) => _.keys(v))
        .uniq()
        .value() as Locale[]) ?? []
    );
  }, [answerSet]);

  const hasNotApplicableValue = useMemo(() => {
    return _.chain(answerSet)
      .values()
      .some((dLoc) => dLoc.en && NOT_APPLICABLE_KEY in dLoc.en)
      .value();
  }, [answerSet]);

  const onDragAnswerOptionEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (destination && !(destination.index === source.index)) {
      setAnswerSet((answerSet) => {
        return {
          ...answerSet,
          [selectedDemographic]: {
            ...answerSet[selectedDemographic],

            ..._.chain(usedLocales)
              .map((key) => {
                const options = answerSet[selectedDemographic][key] ?? {};
                const values = _.chain(options)
                  .toPairs()
                  .map<[number, any]>(([key, value]) => {
                    if (+key === source.index) {
                      return [destination.index, value];
                    }
                    if (+key > source.index) {
                      if (+key > destination.index) {
                        return [+key, value];
                      } else {
                        return [+key - 1, value];
                      }
                    } else {
                      if (+key < destination.index) {
                        return [+key, value];
                      } else {
                        return [+key + 1, value];
                      }
                    }
                  })
                  .fromPairs()
                  .value();
                return [key, values];
              })
              .fromPairs()
              .value(),
          },
        };
      });
    }
  };

  const handleOptionAdd = () => {
    setAnswerSet((answerSet) => {
      return {
        ...answerSet,
        [selectedDemographic]: {
          ...answerSet[selectedDemographic],
          ..._.chain(usedLocales)
            .map((key) => {
              let options = answerSet[selectedDemographic][key] ?? {};
              const optionsNumber = Object.keys(options).length;

              if (!optionsNumber && hasNotApplicableValue) {
                options = {
                  ...options,
                  [NOT_APPLICABLE_KEY]: {
                    text:
                      key === Locale.English
                        ? NOT_APPLICABLE_DEFAULT_VALUE_ENGLISH
                        : "",
                    local_id: _.uniqueId(),
                  },
                };
              }

              const value = hasNotApplicableValue
                ? optionsNumber || DOMAIN_ANSWER_MIN_VALUE
                : optionsNumber + 1;

              return [
                key,
                {
                  ...options,
                  [value]: {
                    text: "",
                    local_id: _.uniqueId(),
                  },
                },
              ];
            })
            .fromPairs()
            .value(),
        },
      };
    });
  };

  const handleRemove =
    (removedKey: string) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      setAnswerSet((answerSet) => {
        return {
          ...answerSet,
          [selectedDemographic]: {
            ...answerSet[selectedDemographic],
            ..._.chain(usedLocales)
              .map((key) => {
                const options = answerSet[selectedDemographic][key] ?? {};

                let removedKeys: string[] = [removedKey];

                if (
                  hasNotApplicableValue &&
                  Object.keys(options).length === 2
                ) {
                  // remove item itself and n/a option
                  removedKeys = [...removedKeys, NOT_APPLICABLE_KEY.toString()];
                }

                return [
                  key,
                  _.chain(options)
                    .omit(removedKeys)
                    .toPairs()
                    .map(([key, value], index) => [
                      +key == NOT_APPLICABLE_KEY
                        ? NOT_APPLICABLE_KEY
                        : index + 1,
                      value,
                    ])
                    .fromPairs()
                    .value(),
                ];
              })
              .fromPairs()
              .value(),
          },
        };
      });
    };

  const answerChoices = useMemo(
    () => _.entries(answerSet[selectedDemographic][selectedLocale]),
    [answerSet, selectedDemographic, selectedLocale]
  );

  const handleChange = (key: string) => (value: string) => {
    setAnswerSet((answerSet) => {
      const options = answerSet[selectedDemographic][selectedLocale] ?? {};

      return {
        ...answerSet,
        [selectedDemographic]: {
          ...answerSet[selectedDemographic],
          [selectedLocale]: {
            ...options,
            [key]: { ...options[key as any], text: value },
          },
        },
      };
    });
  };

  const handleDomainAnswersSave = () => {
    const request: any = _.chain(answerSet)
      .toPairs()
      .map(([demographicKey, localesAnswers]) => [
        demographicKey,
        _.chain(localesAnswers)
          .toPairs()
          .map(([localeKey, answerSet]) => [
            localeKey,
            _.chain(answerSet)
              .toPairs()
              .map(([answerIndex, answerOptions]) => [
                answerIndex,
                answerOptions.text,
              ])
              .fromPairs()
              .value(),
          ])
          .filter(([locale, obj]) => !!Object.keys(obj).length)
          .fromPairs()
          .value(),
      ])
      .filter(([demographic, obj]) => !!Object.keys(obj).length)
      .fromPairs()
      .value();

    if (editingValuesOnly && selectedDomainAnswerSet) {
      dispatch(
        updateDomainAnswerSet.request({
          domainAnswerSet: {
            ...request,
            id: selectedDomainAnswerSet.id,
            is_negative: isNegative,
          },
          onSuccess: onSuccess,
        })
      );
    } else {
      dispatch(
        addDomainAnswerSet.request({
          domainAnswerSet: { ...request, is_negative: isNegative },
          onSuccess: onSuccess,
        })
      );
    }
  };

  const handleAddNewLocale = (locale: Locale) => {
    setAnswerSet(
      (answerSet) =>
        _.chain(answerSet)
          .toPairs()
          .map(([demographicKey, localesAnswers]) => [
            demographicKey,
            {
              ...localesAnswers,
              [locale]: _.chain(localesAnswers[Locale.English])
                .toPairs()
                .map(([key, value]) => [
                  key,
                  { text: "", local_id: _.uniqueId() },
                ])
                .fromPairs()
                .value(),
            },
          ])
          .fromPairs()
          .value() as any
    );
    setSelectedLocale(locale);
  };

  const handleCancel = () => {
    dispatch(hideDomainAnswerSetUpsertDialog());
  };

  const validateAnswerSet = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (noOneAnswerChoice) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.translations.validation.no-answer-options",
        }),
      };
      return validationOption;
    }

    _.chain(answerSet)
      .keys()
      .reverse()
      .forEach((demographic) => {
        const enOptionsCount = _.keys(
          answerSet[demographic as SurveyDemographic].en
        ).length;
        if (
          enOptionsCount &&
          enOptionsCount < MINIMUM_NUMBER_OF_ANSWER_CHOICES
        ) {
          setSelectedLocale(Locale.English);
          setSelectedDemographic(demographic as SurveyDemographic);
          validationOption = {
            isValid: false,
            message: intl.formatMessage(
              {
                id: "app.translations.validation.minimum-number-of-answer-choices",
              },
              {
                demographic: intl.formatMessage({
                  id: `app.filters.respondent-type.${demographic}`,
                }),
                minimum: MINIMUM_NUMBER_OF_ANSWER_CHOICES,
              }
            ),
          };
          return;
        }
      })
      .forEach((demographic) => {
        if (!validationOption.isValid) {
          return;
        }

        const englishWithEmptyOption = _.chain(
          answerSet[demographic as SurveyDemographic].en
        )
          .values()
          .find((v) => !v.text.trim().length)
          .value();

        if (englishWithEmptyOption) {
          refs[englishWithEmptyOption.local_id]?.current?.inputElement?.focus();
          setSelectedLocale(Locale.English);
          setSelectedDemographic(demographic as SurveyDemographic);
          validationOption = {
            isValid: false,
            message: intl.formatMessage(
              {
                id: "app.translations.validation.english-answer-choices-blank",
              },
              {
                demographic: intl.formatMessage({
                  id: `app.filters.respondent-type.${demographic}`,
                }),
              }
            ),
          };
          return;
        }
      })
      .forEach((demographic) => {
        if (!validationOption.isValid) {
          return;
        }

        const answerPair = _.chain(answerSet[demographic as SurveyDemographic])
          .toPairs()
          .find(([key, value]) => {
            if (key === Locale.English) {
              return false;
            }
            return _.values(value).some((obj) => !obj.text.trim().length);
          })
          .value();

        if (answerPair) {
          setShowWarningAlert(true);
          setSelectedLocale(answerPair[0] as Locale);
          setSelectedDemographic(demographic as SurveyDemographic);
          validationOption = {
            isValid: false,
            message: intl.formatMessage(
              {
                id: "app.translations.validation.other-language-answer-choices-blank",
              },
              {
                demographic: intl.formatMessage({
                  id: `app.filters.respondent-type.${demographic}`,
                }),
                language: LocaleDisplayedValues[answerPair[0] as Locale],
              }
            ),
          };
          return;
        }
      })
      .value();

    return validationOption;
  };

  const [warning, setWarning] = useState<string | undefined>();
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);

  const handleValidate = () => {
    const { isValid, message } = validateAnswerSet();
    if (isValid) {
      setWarning(undefined);
      handleDomainAnswersSave();
    } else {
      setWarning(message);
    }
  };

  const [refs, setRefs] = useState<any>({});

  useEffect(() => {
    setRefs(() =>
      _.chain(answerChoices)
        .reduce((pV, [value, obj]) => {
          return {
            ...pV,
            [obj.local_id]: React.createRef(),
          };
        }, {})
        .value()
    );
  }, [answerChoices]);

  const handleSuccess = () => {
    dispatch(hideDomainAnswerSetUpsertDialog());
  };

  const loading = useSelector((s) =>
    editingValuesOnly
      ? s.domainsAnswerSet.loading.updateDomainAnswerSet
      : s.domainsAnswerSet.loading.addDomainAnswerSet
  );
  const error = useSelector((s) =>
    editingValuesOnly
      ? s.domainsAnswerSet.errors.updateDomainAnswerSet
      : s.domainsAnswerSet.errors.addDomainAnswerSet
  );
  useLoading({ loading, error, onSuccess: handleSuccess });

  const noOneAnswerChoice = useMemo(() => {
    return _.chain(answerSet)
      .values()
      .every((l) => !_.keys(l.en).length)
      .value();
  }, [answerSet]);

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Switch
          disabled={editingValuesOnly}
          checked={isNegative}
          label={intl.formatMessage({ id: "app.domains.answers.is-negative" })}
          onChange={handleIsNegativeChange}
        />
        <Tooltip
          disabled={!noOneAnswerChoice || editingValuesOnly}
          content={intl.formatMessage({
            id: "app.domains.answers.not-applicable-option.disabled.info",
          })}
          interactionKind={"hover"}
        >
          <Switch
            disabled={editingValuesOnly || noOneAnswerChoice}
            checked={hasNotApplicableValue}
            label={intl.formatMessage({
              id: "app.domains.answers.add-not-applicable-option",
            })}
            onChange={handleNotApplicableOptionUsageChange}
          />
        </Tooltip>
        <FormGroup
          label={
            <div className="answer-choices">
              {intl.formatMessage({
                id: "app.forms.domain-form.answer-choices",
              })}
              <LocaleSelector
                position={"left"}
                usedLocales={usedLocales}
                onSelectedLocaleChange={(locale) => {
                  setSelectedLocale(locale);
                }}
                onAddTranslation={handleAddNewLocale}
                selectedLocale={selectedLocale}
              />
            </div>
          }
        >
          <ButtonGroup fill large>
            {demographics.map((item, index) => (
              <Button
                key={item.demographic}
                className="text-center flex-basis-0"
                intent={
                  selectedDemographic === item.demographic
                    ? "primary"
                    : undefined
                }
                text={item.text}
                onClick={() => setSelectedDemographic(item.demographic)}
              />
            ))}
          </ButtonGroup>

          <div className="mt-3">
            {answerChoices.length ? (
              <DragDropContext onDragEnd={onDragAnswerOptionEnd}>
                <Droppable
                  droppableId="list"
                  isDropDisabled={editingValuesOnly}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {answerChoices.map(([value, obj], index) => {
                        const key = `${selectedDemographic}_${selectedLocale}_${obj.local_id}`;
                        const isNotApplicableOption =
                          +value === NOT_APPLICABLE_KEY;
                        return (
                          <Draggable
                            isDragDisabled={
                              isNotApplicableOption || editingValuesOnly
                            }
                            key={key}
                            draggableId={obj.local_id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="flex items-center border rounded bg-white p-2">
                                  <span className="flex items-center font-bold mr-2 whitespace-no-wrap">
                                    <Icon icon={"drag-handle-vertical"} />
                                    {intl.formatMessage(
                                      {
                                        id: "app.titles.choice",
                                      },
                                      {
                                        number: isNotApplicableOption
                                          ? intl.formatMessage({
                                              id: "app.titles.not-applicable",
                                            })
                                          : index + 1,
                                      }
                                    )}
                                  </span>
                                  <EditableText
                                    ref={refs[obj.local_id]}
                                    multiline
                                    alwaysRenderInput
                                    className="flex-1 h-6"
                                    defaultValue={obj.text}
                                    onChange={handleChange(value)}
                                  />
                                  {!isNotApplicableOption &&
                                    !editingValuesOnly && (
                                      <Icon
                                        intent="danger"
                                        onClick={handleRemove(value)}
                                        className="cursor-pointer ml-2"
                                        icon="cross"
                                      />
                                    )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Callout intent="warning">
                {intl.formatMessage({
                  id: "app.domains.answers.no-option",
                })}
              </Callout>
            )}

            {!editingValuesOnly && (
              <div className="flex justify-between">
                <Button
                  className="mt-3"
                  intent="primary"
                  onClick={handleOptionAdd}
                >
                  {intl.formatMessage({
                    id: "app.titles.add-option",
                  })}
                </Button>
              </div>
            )}
          </div>
        </FormGroup>

        {warning && (
          <Callout className="my-3" intent="warning">
            {warning}
          </Callout>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button
            onClick={handleCancel}
            title={intl.formatMessage({ id: "app.titles.cancel" })}
            text={intl.formatMessage({ id: "app.titles.cancel" })}
          />
          <Button
            onClick={handleValidate}
            intent={"primary"}
            title={intl.formatMessage({ id: "app.titles.save" })}
            text={intl.formatMessage({ id: "app.titles.save" })}
            loading={loading}
          />
        </div>
      </div>
      <Alert
        intent={"warning"}
        isOpen={showWarningAlert}
        cancelButtonText={intl.formatMessage({ id: "app.titles.cancel" })}
        confirmButtonText={intl.formatMessage({
          id: "app.titles.save-and-continue-later",
        })}
        onCancel={() => setShowWarningAlert(false)}
        onClose={() => setShowWarningAlert(false)}
        onConfirm={() => handleDomainAnswersSave()}
      >
        {warning}
      </Alert>
    </>
  );
};

export default DomainAnswerSetForm;
