import React, { FunctionComponent, useMemo } from "react";
import ComparisonDistributionItem from "./ComparisonDistributionItem";
import { DomainResponses, SurveyDemographic } from "../../../../types";
import useDatasetMark from "../../cards/useDatasetMark";
import ItemMark from "../survey-report-table-row/ItemMark";
import { LocalStorageKeys, getBooleanFromLocalStorage } from "../../../../constants";

type OwnProps = {
  item: DomainResponses;
  domainId?: number;
  demographic: SurveyDemographic;
  domainColor?: string;
  popoverOpen?: boolean;
  setPopoverOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = OwnProps;

const DomainQuestionComparisonTableRowDemographic: FunctionComponent<Props> = (
  props
) => {
  const {
    item,
    demographic,
    domainColor,
    domainId,
    popoverOpen,
    setPopoverOpen,
  } = props;

  const {
    flagged,
    bookmarkType,
    onClick,
    onDiscard,
    supportsBookmarks,
  } = useDatasetMark(
    {
      domainId: domainId ?? item.domain?.id,
      questionId: item?.respondents?.[demographic]?.question_id,
      demographic: demographic,
      is_grouped: false,
    },
    item.respondents[demographic]?.mean_score
  );
  const TOTAL_RESPONDENTS_THRESHOLD = 10;

  const not_enough_data=Number(item.respondents[demographic]?.count)<TOTAL_RESPONDENTS_THRESHOLD

  const localFlagToHideSensitiveData: boolean = getBooleanFromLocalStorage(
    LocalStorageKeys.SensitiveReportingDataHidden
  );
  return (
    <td>
      <div className="middle-and-end-columns-container">
        {!!item.respondents[demographic] && (
          <>
            <ComparisonDistributionItem
              notEnoughData={localFlagToHideSensitiveData && not_enough_data}
              meanScore={item.respondents[demographic]?.mean_score}
              responsesCount={item.respondents[demographic]?.count}
              questionText={item.respondents[demographic]?.question_text}
              hasCustomElement
              popoverOpen={popoverOpen}
              isDeleted={item.respondents[demographic]?.is_deleted}
              setPopoverOpen={setPopoverOpen}
              domainColor={domainColor}
            />
            {supportsBookmarks && (
              <ItemMark
                className="mr-auto"
                flagged={flagged}
                bookmarkType={bookmarkType}
                onClick={onClick}
                onDiscard={onDiscard}
              />
            )}
          </>
        )}
      </div>
    </td>
  );
};

export default DomainQuestionComparisonTableRowDemographic;
