import React, { FunctionComponent, useMemo } from "react";
import GenericCollapse from "../../../common/GenericCollapse";
import DomainComparisonCollapseTitle from "./DomainComparisonCollapseTitle";
import DomainComparisonCollapseContent from "./DomainComparisonCollapseContent";
import {
  DomainComparison,
  DomainDemographicTriageStatuses,
  DomainPriorityDecision,
} from "../../../../types";

type OwnProps = {
  comparison: DomainComparison;
  domainDemographicTriageStatuses?: DomainDemographicTriageStatuses;
  isPriorityConfirmed?: boolean;
};

type Props = OwnProps;

const GENERIC_COLLAPSE_COLOR = "#eaeaea";

const DomainComparisonCollapse: FunctionComponent<Props> = (props) => {
  const {
    comparison,
    domainDemographicTriageStatuses,
    isPriorityConfirmed,
  } = props;

  const { dismissed, prioritized, hasPriorities } = useMemo(() => {
    let defaultValues = {
      dismissed: false,
      prioritized: false,
      hasPriorities: !!domainDemographicTriageStatuses?.domainPriorities
        ?.length,
    };

    const decision =
      domainDemographicTriageStatuses?.domainPriorities[0]?.decision;

    switch (decision) {
      case DomainPriorityDecision.Prioritized: {
        return {
          ...defaultValues,
          prioritized: true,
        };
      }
      case DomainPriorityDecision.Dismissed: {
        return {
          ...defaultValues,
          dismissed: true,
        };
      }
      default: {
        return defaultValues;
      }
    }
  }, [domainDemographicTriageStatuses?.domainPriorities]);

  return (
    <GenericCollapse
      className={
        dismissed ? "grayscale-filter grayscale-filter-bright" : undefined
      }
      title={
        <DomainComparisonCollapseTitle
          domain={comparison.domain}
          dismissed={dismissed}
          prioritized={prioritized}
          hasPriorities={hasPriorities}
          color={comparison.domain.color_hex}
        />
      }
      color={GENERIC_COLLAPSE_COLOR}
      chevronIntent={"primary"}
    >
      <DomainComparisonCollapseContent
        domain={comparison.domain}
        dismissed={dismissed}
        prioritized={prioritized}
        hasPriorities={hasPriorities}
        domainComparison={comparison}
        isPriorityConfirmed={isPriorityConfirmed}
        domainDemographicTriageStatuses={domainDemographicTriageStatuses}
      />
    </GenericCollapse>
  );
};

export default DomainComparisonCollapse;
