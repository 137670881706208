import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import TooltipComponent from "../../tour/TooltipComponent";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router";
import { AuthRoutes } from "../../../App";
import { markStepCompleted, markStepsCompleted, updateMakingProgressTourTimeWatched } from "../../../store/tours/actions";
import { DistrictSetupTaskKey } from "../../../types";

type OwnProps = {
  isSingleSchoolDistrict?: boolean;
};

type Props = OwnProps;

const SetupSchoolsTour: FunctionComponent<Props> = (props) => {
  const { isSingleSchoolDistrict } = props;

  const { setupCompleted } = useSelector((s) => s.tours);

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    if (setupCompleted !== undefined) {
      setShow(false);
    }
  }, [isSingleSchoolDistrict, setupCompleted]);

  const steps = useMemo((): Step[] => {
    return [
      {
        target: '[data-tour="add-school-button"]',
        content: intl.formatMessage({
          id: "app.tours.district-setup.schools-setup",
        }),
        disableBeacon: true,
      },
    ];
  }, [isSingleSchoolDistrict]);

  const handleJoyrideAction = (data: CallBackProps) => {
    if(data.action === 'prev'){
      dispatch(markStepCompleted({
        key: DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
        }))
      setShow(false);
    }
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      dispatch(markStepCompleted({
        key: DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
        }))
      history.push(
        generatePath(AuthRoutes.YourPlanTeam, {
          workspace: "team",
        } as any),
      );
      // setShow(false);
      dispatch(updateMakingProgressTourTimeWatched(new Date()));
    }
  };

  return (
    <ReactJoyride
      run={show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
      locale={{
        next: { title: intl.formatMessage({ id: "app.titles.next" }) },
      }}
    />
  );
};

export default SetupSchoolsTour;
