import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import SurveysDeploymentTable from "../../survey-deployment/table/surveys-deployment-table/SurveysDeploymentTable";
import AdminSurveysTable from "../../surveys/table/AdminSurveysTable";
import useUserRole from "../../../helpers/hooks/useUserRole";
import { isDistrictPlan } from "../../../store/plans/selectors";
import {
  ACTIVITY_LINK_CLASSNAME,
  useAttachActivities,
} from "../../../helpers/hooks/useAttachActivities";
import { Activity, ActivityTypeEnum, TeamRole } from "../../../types";
import { showNotesDialog } from "../../../store/notes/actions";
import { showActivityVideoDialog } from "../../../store/activities/actions";
import { SURVEYS_DEPLOYMENT_TABLE, SURVEYS_TABLE } from "../../../constants/constants";

type OwnProps = {};

type Props = OwnProps;

const SurveyDataTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { hasDataCoordinatorAccess,teamRole } = useUserRole();
  const dispatch = useDispatch();
  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      switch (activity.activity_type) {
        case ActivityTypeEnum.SCI_WRITING:
          dispatch(
            showNotesDialog({
              selectedActivity: activity,
            })
          );
          break;
        case ActivityTypeEnum.KALTURA_VIDEO:
          dispatch(
            showActivityVideoDialog({
              activity: activity,
              viewable_field: "embed_code",
            })
          );
          break;
        default:
          break;
      }
    },
  });


  const isTeamMember = useMemo(()=>{
    return teamRole ? teamRole === TeamRole.TeamMember : false;
  },[teamRole]);

  return (

    <>
    <div className="teamContent">
      <div className="mb-4">
        {isDistrictActivePlan ? (
          <div className="text-xl mb-3">
            {intl.formatMessage({ id: "app.surveys-deployment.instructions" })}
          </div>
        ) : (
          <div className="text-xl mb-3">
            {intl.formatMessage(
              {
                id: "app.surveys-deployment.school-instructions",
              },
              {
                activity: (chunks) => (
                  <a
                    className={ACTIVITY_LINK_CLASSNAME+" text-link"}
                    onClick={onActivityClick("Planning for NJ SCI Survey Administration")}
                  >
                    <u>{chunks}</u>
                  </a>
                ),
                activity2: (chunks) => (
                  <a
                    className={ACTIVITY_LINK_CLASSNAME+" text-link"}
                    onClick={onActivityClick("Screencast Tutorial of NJ SCI Survey Deployment")}
                  >
                    <u>{chunks}</u>
                  </a>
                ),
              }
            )}
          </div>
        )}
        {hasDataCoordinatorAccess && !isDistrictActivePlan && !isTeamMember ? (
          <>
            <div className="text-2xl font-bold mb-4">
              {intl.formatMessage({ id: "app.titles.available-surveys" })}:
            </div>
             <AdminSurveysTable darkHeader isTeamPlanTable id={SURVEYS_TABLE}/>
          </>
        ) : null}
      </div>
      <SurveysDeploymentTable darkHeader isTeamPlanTable id={SURVEYS_DEPLOYMENT_TABLE}/>
      </div>
    </>
  );
};

export default SurveyDataTab;
