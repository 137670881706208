import { Epic } from "redux-observable";
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getTeamMembersByPlanEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getTeamMembersByPlan.request)),
    switchMap((action) =>
      from(api.teamMembers.getTeamMembersByPlan(action.payload)).pipe(
        map(actions.getTeamMembersByPlan.success),
        catchError((error) => of(actions.getTeamMembersByPlan.failure(error)))
      )
    )
  );
};

export const updateTeamMemberEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateTeamMember.request)),
    switchMap((action) =>
      from(api.teamMembers.updateTeamMember(action.payload.body)).pipe(
        mergeMap((teamMember) =>
          from(
            [actions.updateTeamMember.success(teamMember)],
            action.payload.onSuccess && action.payload.onSuccess(teamMember)
          )
        ),
        catchError((error) => of(actions.updateTeamMember.failure(error)))
      )
    )
  );
};

export const inviteTeamMembersEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.inviteTeamMembers.request)),
    switchMap((action) =>
      from(api.teamMembers.inviteTeamMembers(action.payload.body)).pipe(
        mergeMap((response) =>
          from(
            [actions.inviteTeamMembers.success(response)],
            action.payload.onSuccess && action.payload.onSuccess()
          )
        ),
        catchError((error) => of(actions.inviteTeamMembers.failure(error)))
      )
    )
  );
};

export const addTeamMembersEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addTeamMembers.request)),
    switchMap((action) =>
      from(
        api.teamMembers.addTeamMembers(
          action.payload.planId,
          action.payload.members
        )
      ).pipe(
        map(actions.addTeamMembers.success),
        catchError((error) => of(actions.addTeamMembers.failure(error)))
      )
    )
  );
};

export const deleteTeamMemberFromPlanEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteTeamMemberFromPlan.request)),
    delay(2000),
    switchMap((action) =>
      from(
        api.teamMembers.deleteTeamMemberFromPlan(
          action.payload.planId,
          action.payload.userId
        )
      ).pipe(
        mergeMap((response) =>
          from(
            [actions.deleteTeamMemberFromPlan.success(action.payload.userId)],
            action.payload.onSuccess && action.payload.onSuccess()
          )
        ),
        catchError((error) =>
          of(actions.deleteTeamMemberFromPlan.failure(error))
        )
      )
    )
  );
};
