import React, { FunctionComponent } from "react";
import { Switch } from "@blueprintjs/core";
import { changeRedactedDataCollapsed } from "../../../store/survey-reports/actions";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

type OwnProps = {
  show?: boolean;
};

type Props = OwnProps;

const CollapseRedactedDataSwitch: FunctionComponent<Props> = (props) => {
  const { show = false } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const isRedactedDataCollapsed = useSelector(
    (s) => s.surveyReports.isRedactedDataCollapsed
  );

  const handleRedactedDataChange = (e: React.FormEvent<HTMLInputElement>) => {
    const switchChecked = e.currentTarget?.checked;
    dispatch(changeRedactedDataCollapsed(switchChecked));
  };

  if (!show) {
    return null;
  }

  return (
    <div className="flex items-center">
      <div className="text-2xl">
        {intl.formatMessage({ id: "app.titles.collapse-redacted-data" })}
      </div>
      <Switch
        checked={isRedactedDataCollapsed}
        className="mb-0"
        alignIndicator="right"
        onChange={handleRedactedDataChange}
        large
        innerLabelChecked={intl.formatMessage({
          id: "app.titles.on",
        })}
        innerLabel={intl.formatMessage({ id: "app.titles.off" })}
      />
    </div>
  );
};

export default CollapseRedactedDataSwitch;
