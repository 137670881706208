import { createReducer, RootAction } from "typesafe-actions";
import {
  Plan,
  PlanStatus,
  UserRole,
  userRoles,
  UserViewedPlan,
} from "../../types";
import actions from "../actions";
import { getObjectFromLocalStorage, LocalStorageKeys } from "../../constants";

type ActionName =
  | "getPlans"
  | "addPlan"
  | "updatePlan"
  | "addStrategyToPlan"
  | "getPlanStatus";

export type PlansState = {
  plans: Plan[];
  activePlan?: Plan;
  planStatus?: PlanStatus;
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: PlansState = {
  plans: [],
  loading: {},
  errors: {},
};

export const plansReducer = createReducer<PlansState, RootAction>(initialState)
  // get plans
  .handleAction(
    actions.getPlans.request,
    (state, action): PlansState => ({
      ...state,
      loading: {
        ...state.loading,
        getPlans: true,
      },
      errors: {
        ...state.errors,
        getPlans: undefined,
      },
    })
  )
  .handleAction(actions.getPlans.success, (state, action): PlansState => {
    const userViewedPlan: UserViewedPlan = getObjectFromLocalStorage(
      LocalStorageKeys.ViewedPlan
    );
    const currentUser = getObjectFromLocalStorage(LocalStorageKeys.CurrentUser);
    const currentUserRole = currentUser?.profile?.role;
    const viewedPlan = action.payload.find(
      (p) => p.id === userViewedPlan?.planId
    );
    let activePlan;
    //if other user authenticated
    if (userViewedPlan?.userId !== currentUser?.id && viewedPlan?.is_active) {
      if (!viewedPlan.school?.id) {
        // if current user - principal, then should set school plan as default
        if (
          currentUserRole === UserRole.Principal ||
          currentUserRole === UserRole.Staff ||
          currentUserRole === UserRole.VicePrincipal
        ) {
          activePlan = action.payload.find((p) => p.is_active && p.school?.id);
        } else {
          //otherwise set district plan as default
          activePlan = viewedPlan;
        }
      } else {
        //if selected plan - school plan
        if (
          currentUserRole !== UserRole.Principal &&
          currentUserRole !== UserRole.Staff &&
          currentUserRole !== UserRole.VicePrincipal
        ) {
          //then should set district plan as default
          activePlan = action.payload.find((p) => p.is_active && !p.school?.id);
        } else {
          activePlan = viewedPlan;
        }
      }
    } else {
      //if current user authenticated again
      if (viewedPlan?.is_active) {
        activePlan = viewedPlan;
      } else {
        activePlan = action.payload.find((p) => {
          if (
            currentUserRole === UserRole.Principal ||
            currentUserRole === UserRole.Staff ||
            currentUserRole === UserRole.VicePrincipal
          ) {
            return p.is_active && p.school?.id;
          }
          return p.is_active && !p.school?.id;
        });
      }
    }
    if (!activePlan && action.payload.length) {
      if (userRoles.admins.includes(currentUserRole)) {
        activePlan = action.payload[0];
      } else {
        // todo check for other roles
        activePlan = action.payload.find((p) => p.is_active);
      }
    }

    if (activePlan) {
      localStorage.setItem(
        LocalStorageKeys.ViewedPlan,
        JSON.stringify({ planId: activePlan.id, userId: currentUser?.id })
      );
    }
    return {
      ...state,
      plans: action.payload,
      activePlan: activePlan,
      loading: {
        ...state.loading,
        getPlans: false,
      },
    };
  })
  .handleAction(
    actions.getPlans.failure,
    (state, action): PlansState => ({
      ...state,
      errors: {
        ...state.errors,
        getPlans: action.payload,
      },
      loading: {
        ...state.loading,
        getPlans: false,
      },
    })
  )
  // add plan
  .handleAction(
    actions.addPlan.request,
    (state, action): PlansState => ({
      ...state,
      loading: {
        ...state.loading,
        addPlan: true,
      },
      errors: {
        ...state.errors,
        addPlan: undefined,
      },
    })
  )
  .handleAction(
    actions.addPlan.success,
    (state, action): PlansState => ({
      ...state,
      plans: [action.payload, ...state.plans],
      activePlan: action.payload,
      loading: {
        ...state.loading,
        addPlan: false,
      },
    })
  )
  .handleAction(
    actions.addPlan.failure,
    (state, action): PlansState => ({
      ...state,
      errors: {
        ...state.errors,
        addPlan: action.payload,
      },
      loading: {
        ...state.loading,
        addPlan: false,
      },
    })
  )
  .handleAction(actions.changeActivePlan, (state, action): PlansState => {
    const currentUser = getObjectFromLocalStorage(LocalStorageKeys.CurrentUser);
    localStorage.setItem(
      LocalStorageKeys.ViewedPlan,
      JSON.stringify({ userId: currentUser.id, planId: action.payload.id })
    );
    return {
      ...state,
      activePlan: action.payload,
    };
  })
  // get plan status
  .handleAction(
    actions.getPlanStatus.request,
    (state, action): PlansState => ({
      ...state,
      loading: {
        ...state.loading,
        getPlanStatus: true,
      },
      errors: {
        ...state.errors,
        getPlanStatus: undefined,
      },
    })
  )
  .handleAction(actions.getPlanStatus.success, (state, action): PlansState => {
    return {
      ...state,
      planStatus: action.payload,
      loading: {
        ...state.loading,
        getPlanStatus: false,
      },
    };
  })
  .handleAction(
    actions.getPlanStatus.failure,
    (state, action): PlansState => ({
      ...state,
      errors: {
        ...state.errors,
        getPlanStatus: action.payload,
      },
      loading: {
        ...state.loading,
        getPlanStatus: false,
      },
    })
  );
