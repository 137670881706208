import {
  DomainPriority,
  PrioritizedNeed,
  Priority,
  PriorityAreaCheck,
} from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import {
  AddDomainPrioritiesRequest,
  DeletePriorityRequest,
  DeprioritizeDatasetRequest,
  GetDomainPrioritiesRequest,
  GetPrioritiesRequest,
  PrioritizeDatasetRequest,
  UpdateDomainPrioritiesDecisionRequest,
  UpdateDomainPrioritiesDecisionResponse,
  UpdateDomainPrioritiesRequest,
  UpsertPriorityRequest,
} from "./types";
import { makeRequestPath } from "../../helpers/stringUtils";

export const getPrioritizedNeeds = (
  planId: number
): Promise<PrioritizedNeed[]> => {
  return Axios.get(`${API_ROOT}/plans/${planId}/prioritized-needs/`);
};

// is_priority_area_exists
export const checkPrioritizedArea = (
  planId: number
): Promise<PriorityAreaCheck> => {
  return Axios.get(
    `${API_ROOT}/plans/${planId}/priorities/is_priority_area_exists/`
  );
};

export const getDomainPriorities = ({
  plan_id,
  ...request
}: GetDomainPrioritiesRequest): Promise<DomainPriority[]> => {
  const url = `${API_ROOT}/plans/${plan_id}/domain_priorities/`;
  return Axios.get(makeRequestPath(url, request));
};

export const addDomainPriority = ({
  plan,
  ...body
}: AddDomainPrioritiesRequest): Promise<DomainPriority> => {
  return Axios.post(`${API_ROOT}/plans/${plan}/domain_priorities/`, body);
};

export const updateDomainPriority = ({
  id,
  plan,
  ...body
}: UpdateDomainPrioritiesRequest): Promise<DomainPriority> => {
  return Axios.patch(
    `${API_ROOT}/plans/${plan}/domain_priorities/${id}/`,
    body
  );
};

export const getPriorities = ({
  plan_id,
  ...request
}: GetPrioritiesRequest): Promise<Priority[]> => {
  const url = `${API_ROOT}/plans/${plan_id}/priorities/`;
  return Axios.get(makeRequestPath(url, request));
};

export const addPriority = ({
  plan_id,
  ...body
}: Omit<UpsertPriorityRequest, "id" | "onComplete">): Promise<Priority> => {
  const url = `${API_ROOT}/plans/${plan_id}/priorities/`;
  return Axios.post(url, body);
};

export const updatePriority = ({
  id,
  plan_id,
  ...body
}: Omit<UpsertPriorityRequest, "onComplete">): Promise<Priority> => {
  const url = `${API_ROOT}/plans/${plan_id}/priorities/${id}/`;
  return Axios.patch(url, body);
};

export const confirmPriority =(
  schoolId?:number,
  surveyDeploymentId?: number
): Promise<any> => {
  return Axios.post(`${API_ROOT}/schools/${schoolId}/survey_deployments`,{survey_deployment_id:surveyDeploymentId});  
};


export const deletePriority = ({
  id,
  plan_id,
}: DeletePriorityRequest): Promise<void> => {
  return Axios.delete(`${API_ROOT}/plans/${plan_id}/priorities/${id}/`);
};

export const updateDomainPrioritiesDecision = ({
  plan_id,
  ...body
}: Omit<
  UpdateDomainPrioritiesDecisionRequest,
  "onComplete"
>): Promise<UpdateDomainPrioritiesDecisionResponse> => {
  const url = `${API_ROOT}/plans/${plan_id}/domain_priorities/decision/`;
  return Axios.post(url, body);
};

export const prioritizeDataset = ({
  planId,
  ...body
}: Omit<PrioritizeDatasetRequest, "onComplete">): Promise<
  PrioritizedNeed[]
> => {
  return Axios.post(`${API_ROOT}/plans/${planId}/prioritized-needs/`, body);
};

export const deprioritizeDataset = ({
  planId,
  ...body
}: Omit<DeprioritizeDatasetRequest, "onComplete">): Promise<void> => {
  return Axios.post(
    `${API_ROOT}/plans/${planId}/prioritized-needs/delete/`,
    body
  );
};
