import { useMemo } from "react";
import { getUrlParams } from "../../url-utils";
import { GROUP_BY_PARAM } from "../../../components/surveys/table/survey-report-table-head/SurveyReportTableHead";
import { useLocation } from "react-router";
import {
  DomainStatistics,
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  SurveyStatistics,
} from "../../../types";

const useSurveyReportType = (
  schoolId: number | undefined,
  surveyDeploymentId: number | undefined,
  domainId: number | undefined,
  surveyReport:
    | SurveyStatistics[]
    | GroupedSurveyStatistics[]
    | DomainStatistics
    | DomainStatistics<GroupedAnswerStatistics[]>
) => {
  const location = useLocation();

  const groupingKeys = useMemo(() => {
    return getUrlParams(GROUP_BY_PARAM, location.search);
  }, [location]);

  const isGroupedData = useMemo(() => {
    return !!groupingKeys?.length;
  }, [groupingKeys]);

  const displayedDataIsArray = useMemo(() => {
    return Array.isArray(surveyReport);
  }, [surveyReport]);

  const {
    isSurveyStatisticsArray,
    isGroupedStatisticsArray,
    isDomainStatistics,
    isGroupedDomainStatistics,
  } = useMemo(() => {
    let res = {
      isSurveyStatisticsArray: false,
      isGroupedStatisticsArray: false,
      isGroupedDomainStatistics: false,
      isDomainStatistics: false,
    };

    if (!displayedDataIsArray) {
      if (isGroupedData) {
        res = { ...res, isGroupedDomainStatistics: true };
      } else {
        res = { ...res, isDomainStatistics: true };
      }
    } else {
      if (isGroupedData) {
        res = { ...res, isGroupedStatisticsArray: true };
      } else {
        res = { ...res, isSurveyStatisticsArray: true };
      }
    }

    return res;
  }, [isGroupedData, displayedDataIsArray]);

  return {
    isSurveyStatisticsArray,
    isGroupedStatisticsArray,
    isDomainStatistics,
    isGroupedDomainStatistics,
    groupingKeys,
    isGroupedData,
  };
};

export default useSurveyReportType;
