import { useIntl } from "react-intl";
import { CollectedDemographicDataEnum } from "../../../../types";

type Props = {};

export const useInsightTitle = (props: Props) => {
  const intl = useIntl();

  const generateInsightTitle = (key: string, value: string) => {
    if (value !== null && value !== undefined) {
      switch (key) {
        case CollectedDemographicDataEnum.grade: {
          return intl.formatMessage(
            { id: "app.insights.sub-group.titles.grades" },
            { grade: value }
          );
        }
        case CollectedDemographicDataEnum.race: {
          return intl.formatMessage(
            { id: "app.choices.race" },
            { race: value }
          );
        }
        case CollectedDemographicDataEnum.gender: {
          return intl.formatMessage(
            { id: "app.enums.gender" },
            { gender: value }
          );
        }
        case CollectedDemographicDataEnum.has_iep:
          return `${intl.formatMessage({
            id: "app.survey-report.table.group-by-head.has_iep",
          })}: ${intl.formatMessage(
            { id: "app.choices.simple-answers" },
            { value: value }
          )}`;

        case CollectedDemographicDataEnum.reduced_lunch:
          return `${intl.formatMessage({
            id: "app.survey-report.table.group-by-head.reduced_lunch",
          })}: ${intl.formatMessage(
            { id: "app.choices.simple-answers" },
            { value: value }
          )}`;

        case CollectedDemographicDataEnum.english_at_home: {
          return `${intl.formatMessage({
            id: "app.insights.sub-group.titles.english-main-language-at-home",
          })}: ${intl.formatMessage(
            { id: "app.choices.simple-answers" },
            { value: value }
          )}`;
        }
        case CollectedDemographicDataEnum.direct_instruction: {
          return `${intl.formatMessage({
            id: "app.survey-report.table.group-by-head.direct_instruction",
          })}: ${intl.formatMessage(
            { id: "app.filters.provide-instruction" },
            { directInstruction: value }
          )}`;
        }
        case CollectedDemographicDataEnum.remote_learning: {
          return intl.formatMessage(
            { id: "app.insights.sub-group.titles.remote-learning" },
            { value: value }
          );
        }
        case CollectedDemographicDataEnum.staff_role: {
          return intl.formatMessage(
            { id: "app.choices.staff_role.certified" },
            { value: value }
          );
        }
        case "demographic": {
          return intl.formatMessage({
            id: `app.filters.respondent-type.${value}`,
          });
        }

        default:
          return value;
      }
    } else {
      return intl.formatMessage({
        id: "app.titles.not-applicable",
      });
    }
  };

  return {
    generateInsightTitle,
  };
};
