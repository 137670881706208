import React, { FunctionComponent } from "react";
import DashboardWidgetStepItem from "./DashboardWidgetStepItem";
import { DashboardWidgetPart } from "./DashboardWidget";

type OwnProps = {
  group: DashboardWidgetPart;
  showDots?: boolean;
  showLeftPart?: boolean;
  showRightPart?: boolean;
  isFirstGroup?: boolean;
  isLastGroup?: boolean;
};

type Props = OwnProps;

const DashboardWidgetStage: FunctionComponent<Props> = (props) => {
  const {
    group,
    showDots,
    showLeftPart,
    showRightPart,
    isFirstGroup,
    isLastGroup,
  } = props;

  return (
    <div
      className={"plan-setup-widget__group"}
      style={{ flexGrow: group.steps.length }}
    >
      <div
        className="flex-grow px-2"
        style={{
          marginBottom: group.name ? undefined : 0,
        }}
      >
        {group.steps.map((item, index) => {
          const showNextDots =
            showDots &&
            ((isLastGroup && group.steps.length === 1) ||
              index !== group.steps.length - 1);

          const showPrevDots =
            showDots && !index && isFirstGroup && group.steps.length <= 1;

          return (
            <DashboardWidgetStepItem
              key={index}
              item={item}
              showNextDots={showNextDots}
              showPrevDots={showPrevDots}
              callbacks={group.callbacks}
              showLeftPart={showLeftPart}
              showRightPart={showRightPart}
            />
          );
        })}
      </div>
      <div>
        {group.name && (
          <>
            <div className="bracket" />
            <div>{group.name}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardWidgetStage;
