import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
} from "../../../../types";
import { Button, FileInput, FormGroup } from "@blueprintjs/core";
import _ from "lodash";

type OwnProps = {
  pdfFile?: AttachedResource;
  docxFile?: AttachedResource;
  pptFiles?: AttachedResource;
  onFileChange: (type: "pdf" | "docx" | "ppt" , resource?: AttachedResource) => void;
  isExternalResourceActivityType?: boolean;
  attachedType?: AttachedToType;
};

type Props = OwnProps;

const ActivityFilesFormGroup: React.FC<Props> = ({
  pdfFile,
  docxFile,
  pptFiles,
  onFileChange,
  isExternalResourceActivityType,
  attachedType
}: Props) => {
  const intl = useIntl();

  const handleFileChange = (type: "pdf" | "docx" | "ppt") => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = e.target.files;
    if (fileList) {
      const files: File[] = Array.from(fileList);
      const attachedResources: AttachedResource[] = files.map((file) => ({
        id: -1 * +_.uniqueId(),
        created_at: "",
        updated_at: "",
        title: file.name,
        attachment: file,
        type: AttachedResourceType.Upload,
        attached_to_type: attachedType ? attachedType : AttachedToType.ACTIVITY,
        description: "",
      }));
      onFileChange(type, attachedResources[0]);
    }
    e.target.value = "";
  };

  return (
    <>
      <FormGroup
        label={intl.formatMessage({
          id: isExternalResourceActivityType
            ? "app.titles.pdf-attachment"
            : "app.titles.pdf-resource",
        })}
      >
        <div className="flex items-center">
          <FileInput
            text={pdfFile?.title}
            large
            onInputChange={handleFileChange("pdf")}
            inputProps={{ accept: "application/pdf" }}
          />
          <Button
            icon="cross"
            intent="danger"
            className="ml-2"
            minimal
            disabled={!pdfFile}
            onClick={() => onFileChange("pdf")}
          />
        </div>
      </FormGroup>
      {!isExternalResourceActivityType && (
        <FormGroup
          label={intl.formatMessage({ id: "app.titles.docx-resource" })}
        >
          <div className="flex items-center">
            <FileInput
              text={docxFile?.title}
              large
              onInputChange={handleFileChange("docx")}
              inputProps={{ accept: ".doc,.docx" }}
            />
            <Button
              icon="cross"
              intent="danger"
              className="ml-2"
              minimal
              disabled={!docxFile}
              onClick={() => onFileChange("docx")}
            />
          </div>
        </FormGroup>
      )}
        <FormGroup
          label={intl.formatMessage({ id: "app.titles.ppt-resource" })}
        >
          <div className="flex items-center">
            <FileInput
              text={pptFiles?.title}
              large
              onInputChange={handleFileChange("ppt")}
              inputProps={{ accept: ".ppt,.pptx" }}
            />
            <Button
              icon="cross"
              intent="danger"
              className="ml-2"
              minimal
              disabled={!pptFiles}
              onClick={() => onFileChange("ppt")}
            />
          </div>
        </FormGroup>
     
    </>
  );
};

export default ActivityFilesFormGroup;
