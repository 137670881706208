import React, { useEffect, useMemo, useState } from "react";

import "./NotesPage.scss";
import NotesInfoBar from "./NotesInfoBar";
import { NotesSidebar } from "./NotesSidebar";
import PublishedNotes from "./PublishedNotes";
import CreateEditNoteLayout from "./CreateEditNoteLayout";
import { generatePath, useHistory, useParams } from "react-router";
import { useIntl } from "react-intl";
import { PlanWorkspaces } from "../../../../types";
import { PUSHER_CLUSTER, PUSHER_KEY } from "../../../../api/config";
import Pusher from "pusher-js/types/src/core/pusher";
import { useDispatch } from "react-redux";
import { setNavigationWarning } from "../../../../store/notes/actions";
import { useSelector } from "react-redux";
import { AuthRoutes } from "../../../../App";

export const NotesPage = () => {
  // intl
  const intl = useIntl();

  // dispatch
  const dispatch = useDispatch();
  const history = useHistory();


  // workspace
  const { workspace, tabId } = useParams<{
    workspace?: string;
    tabId?: string;
  }>();

  // state
  const [activePage, setActivePage] = useState<string>(
    PlanWorkspaces.PublishedNotes
  ); // create-notes published-notes
  const navigationWarning = useSelector(
    (s) => s.notes.dialogs.navigationWarning
  );

  // useEffect
  useEffect(() => {
    setActivePage(workspace as string);
  }, [workspace]);

  const handleNavigation = () => {
    if (navigationWarning?.haveUnsavedChanges) {
      dispatch(
        setNavigationWarning({
          show: true,
          navigateTo: PlanWorkspaces.Dashboard,
        })
      );
    } else {
      history.push(
        generatePath(AuthRoutes.YourPlan, {
          workspace: PlanWorkspaces.Dashboard,
        } as any)
      );
    }
  };

  return (
    <div>
      <div className="SciWriting-wrapper">
        <div className="SciWriting-topBar">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={handleNavigation}>
                  {intl.formatMessage({
                    id: "app.titles.dashboard",
                  })}
                </a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>
                <a href="#">
                  {intl.formatMessage({
                    id: "app.titles.sci-writing",
                  })}
                </a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>
                {activePage == PlanWorkspaces.SCIWriting
                  ? intl.formatMessage({
                      id: "app.note-sci-start-writing-create-edit-note",
                    })
                  : intl.formatMessage({
                      id: "app.note-sci-start-writing-published-notes",
                    })}
              </li>
            </ul>
          </div>

          <div className="sci-Toolbar">
            <h1>
              {intl.formatMessage({
                id: "app.titles.sci-writing",
              })}
            </h1>
            <ul>
              <li>
                <NotesInfoBar
                  open={false}
                  setOpen={(val: boolean) => console.log(val)}
                />
              </li>
            </ul>
          </div>
        </div>
        {/* Sidebar */}
        <NotesSidebar activePage={activePage} setActivePage={setActivePage} />
        {/* Content -> Create new note / notes list */}
        {/* <PublishedNotes/> */}
        {activePage == PlanWorkspaces.SCIWriting && <CreateEditNoteLayout />}
        {activePage == PlanWorkspaces.PublishedNotes && <PublishedNotes />}
      </div>
    </div>
  );
};
