import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Button, Card, Icon, Tag, Tooltip } from "@blueprintjs/core";
import moment from "moment";
import {
  SuperintendentRole,
  UserNotes,
  UserNotesViewableBy,
  UserRole,
} from "../../../../types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserId } from "../../../../store/auth/selectors";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import { deleteNote } from "../../../../store/notes/actions";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { getFullName } from "../../../../helpers/message-format-utils";
import Dialog from "@mui/material/Dialog";
type OwnProps = {
  note: UserNotes;
  onClick?: (note?: UserNotes) => void;
  isActive?: boolean;
  interactive?: boolean;
  activityName?: string;
};

type Props = OwnProps;

const MAX_HEIGHT = 120;

const NoteCard: FunctionComponent<Props> = (props) => {
  const { note, onClick, isActive, interactive = true, activityName } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const userId = useSelector(getCurrentUserId);

  const {
    hasDataCoordinatorAccess,
    role,
    isDCC,
    hasDistrictPlanAccess,
    user,
  } = useUserRole();

  const [collapseHeight, setCollapseHeight] = useState<number | undefined>(
    MAX_HEIGHT
  );
  const [showCollapseButton, setShowCollapseButton] = useState<boolean>(false);

  const isActiveDistrictPlan = useSelector(isDistrictPlan);

  const handleNoteClick = () => {
    !!onClick && onClick(isActive ? undefined : note);
  };

  const loading = useSelector((s) => s.notes.loading.deleteNote);
  const error = useSelector((s) => s.notes.errors.deleteNote);

  useLoading({ loading, error });

  const notesType = useMemo(() => {
    return intl.formatMessage(
      {
        id: `app.notes.types`,
      },
      { noteType: note.note_type }
    );
  }, [note.note_type]);

  const className = useMemo(() => {
    let name = "p-2 overflow-hidden";

    if (isActive) {
      name += ` bg-blue-500`;
    }

    if (interactive) {
      name += ` cursor-pointer`;
    }

    return name;
  }, [isActive, interactive]);

  const districtPlan = useSelector(isDistrictPlan);

  const showDeleteButton = useMemo(() => {
    const isMyNote = note.user && note.user.id === userId;
    return (
      isMyNote ||
      hasDataCoordinatorAccess ||
      (isActiveDistrictPlan
        ? false
        : isDCC || role === UserRole.DistrictStaff) ||
      (role === UserRole.DistrictStaff &&
        (user?.profile?.custom_role_name ===
          SuperintendentRole.AssistantSuperintendent ||
          user?.profile?.custom_role_name ===
            SuperintendentRole.DistrictLevelSupervisorOrDirectorOrCoordinator ||
          user?.profile?.custom_role_name === null))
    );
  }, [
    note,
    userId,
    hasDataCoordinatorAccess,
    role,
    isDCC,
    isActiveDistrictPlan,
  ]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleRemoveNoteClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };
  const handleDomainDeleteClose = () => {
    setDeleteDialogOpen(false);
  };
  const handleDomainDelete = () => {
    doDeleteNote();
  };
  const doDeleteNote = () => {
    dispatch(deleteNote.request({ id: note.id, planId: note.plan }));
    setDeleteDialogOpen(false);
  };

  // const handleRemoveNoteClick = (
  //   e: React.MouseEvent<HTMLElement, MouseEvent>
  // ) => {

  //   e.stopPropagation();

  //   dispatch(
  //     showConfirmDialog({
  //       onConfirm: () => {
  //         dispatch(deleteNote.request({ id: note.id, planId: note.plan }));
  //         dispatch(hideConfirmDialog());
  //       },
  //       show: true,
  //       intent: "danger",
  //       text: intl.formatMessage(
  //         {
  //           id: "app.confirmation-dialogs.delete-note",
  //         },
  //         { type: notesType }
  //       ),
  //       icon: "trash",
  //       confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
  //     })
  //   );
  // };

  const handleCollapseButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setCollapseHeight((height) => (height ? undefined : MAX_HEIGHT));
  };

  const getNoteTitle = useCallback(() => {
    return activityName || note.activity?.name || notesType;
  }, [notesType, activityName, note.activity]);

  return (
    <>
      <Card className={className} onClick={handleNoteClick}>
        <div className="flex justify-between text-sm flex-wrap gap-1">
          {/* Removing save as draft functionality */}
          {/* <Tag intent={note.is_draft ? "warning" : "primary"}> */}
          <Tag intent={"primary"}>
            {/* {note.is_draft
            ? intl.formatMessage({ id: "app.title.draft" }) + " "
            : ""} */}
            {getNoteTitle()}
          </Tag>
          <div className="flex flex-grow justify-end items-center gap-2">
            {note.user.is_active === false ? (
              <i>{intl.formatMessage({ id: "app.titles.deactivated" })}</i>
            ) : (
              " "
            )}
            {note.user && (
              <Tag large round>
                {getFullName(note.user)}
              </Tag>
            )}

            {note.viewable_by && (
              <Tooltip
                content={`${intl.formatMessage({
                  id: "app.user-notes.shared_with",
                })} ${intl.formatMessage({
                  id: `app.user-notes.viewable-by.${note.viewable_by}`,
                })}`}
              >
                <Icon
                  icon={
                    note.viewable_by === UserNotesViewableBy.JustMe
                      ? "person"
                      : "people"
                  }
                />
              </Tooltip>
            )}

            <div>{moment(note.created_at).format("M/D/yy hh:mm A")}</div>
            {showDeleteButton && (
              <div>
                <Button
                  intent="danger"
                  icon="trash"
                  title={intl.formatMessage({ id: "app.titles.delete" })}
                  onClick={handleRemoveNoteClick}
                  loading={loading}
                />
              </div>
            )}
          </div>
        </div>

        <hr className="my-1" />

        <div style={{ maxHeight: collapseHeight }}>
          <div
            className={note.text_activity ? "activityHTMLContainer" : undefined}
            style={collapseHeight ? { overflow: "hidden" } : undefined}
            ref={(el) => {
              if (el?.clientHeight && collapseHeight) {
                if (el.clientHeight > collapseHeight) {
                  setShowCollapseButton(true);
                }
              }
            }}
            dangerouslySetInnerHTML={{
              __html: note?.text && note?.text?.trim() ? note.text : note?.text_activity ?? "",
            }}
          />
        </div>

        {showCollapseButton && (
          <div
            className="text-center bg-gradient-to-b relative from-white to-gray-400 -m-2 rounded-b font-bold cursor-pointer"
            onClick={handleCollapseButtonClick}
          >
            {intl.formatMessage({
              id: collapseHeight ? "app.titles.expand" : "app.titles.shrink",
            })}
          </div>
        )}
      </Card>

      {deleteDialogOpen && (
        <Dialog
          className="medium-popup"
          open={deleteDialogOpen}
          onClose={handleDomainDeleteClose}
          style={{ zIndex: 9999 }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="cancel"></div>
          <Icon icon="trash" iconSize={30} />
          <h3>
            {intl.formatMessage({
              id: "app.confirmation.delete-dialogs.title",
            })}
          </h3>
          <p></p>
          <p>
            <ul>
              {intl.formatMessage(
                { id: "app.confirmation-dialogs.delete-note.new" },

                { li: (chunks) => <li>{chunks}</li> }
              )}
            </ul>
          </p>
          <div className="button-group">
            <button onClick={handleDomainDeleteClose} className="stroke-btn">
              {intl.formatMessage({ id: "app.titles.cancel" })}
            </button>
            <button onClick={handleDomainDelete} className="nj-btn">
              {intl.formatMessage({ id: "app.titles.delete" })}
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default NoteCard;
