import React, { FunctionComponent, useMemo } from "react";
import {
  CollectedDemographicDataEnum,
  Insight,
  InsightDemographic,
  SurveyDemographic,
} from "../../../../types";
import _ from "lodash";
import { useIntl } from "react-intl";
import InsightDemographicItem from "./InsightDemographicItem";
import { useInsightTitle } from "./useInsightTitle";
import { sortDemographicData } from "../../../../helpers/demographic-data";
import { HTMLTable } from "@blueprintjs/core";

type OwnProps = {
  insight: Insight;
  availableDemographicsInSchool: SurveyDemographic[];
};

type Props = OwnProps;

const InsightContent: FunctionComponent<Props> = (props) => {
  const { insight, availableDemographicsInSchool } = props;

  const intl = useIntl();

  const { generateInsightTitle } = useInsightTitle({} as any);

  const filteredData: [
    SurveyDemographic,
    InsightDemographic[],
    number,
    number
  ][] = useMemo(() => {
    return _.chain(insight.demographics)
      .toPairs()
      .reduce<[SurveyDemographic, InsightDemographic[], number, number][]>(
        (pV, [demographic, data = []]) => {
          const sortByDemographic = sortDemographicData(demographic);

          const nonEmptyData = _.chain(data)
            .reduce<InsightDemographic[]>((pV, cV) => {
              const nonEmptyResults = _.chain(cV.results)
                .filter((res) => res.title !== undefined && res.title !== null)
                .map((res) => ({
                  ...res,
                  subgroupName: generateInsightTitle(cV.title, res.title),
                }))
                .orderBy(
                  [
                    (res) => res.domains?.length,
                    (res) => res.items?.length,
                    (res) => res.subgroupName,
                  ],
                  ["desc", "desc", "asc"]
                )
                .value();
              return [...pV, { ...cV, results: nonEmptyResults }];
            }, [])
            .filter((insightDemographic) => !!insightDemographic.results.length)
            .sortBy((d) => sortByDemographic(d.title))
            .value();
          if (nonEmptyData.length) {
            const { domainsNumber, itemsNumber } = {
              domainsNumber: nonEmptyData[0].results[0].domain_total ?? 0,
              itemsNumber: nonEmptyData[0].results[0].item_total ?? 0,
            };
            return [
              ...pV,
              [
                demographic as SurveyDemographic,
                nonEmptyData,
                domainsNumber,
                itemsNumber,
              ],
            ];
          }
          return pV;
        },
        []
      )
      .sort((a, b) => {
        return (
          availableDemographicsInSchool.indexOf(a[0]) -
          availableDemographicsInSchool.indexOf(b[0])
        );
      })
      .value();
  }, [insight.demographics]);

  return (
    <HTMLTable className="insight-content-table">
      <thead>
        <tr>
          {filteredData.map(([demographic, , domainsNumber, itemsNumber]) => (
            <th key={demographic}>
              <div>
                {intl.formatMessage({
                  id: `app.filters.respondent-type.${demographic}`,
                })}
              </div>
              <div>
                {intl.formatMessage(
                  {
                    id: "app.insights.sub-group.domain-and-items-count",
                  },
                  {
                    itemsNumber: itemsNumber,
                    domainsNumber: domainsNumber,
                  }
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {filteredData.map(([demographic, data = []]) => (
            <td key={demographic}>
              <div>
                {data.map(
                  (insightDem: InsightDemographic, index) =>
                    insightDem.title !==
                      CollectedDemographicDataEnum.language && (
                      <React.Fragment key={`${demographic}_${index}`}>
                        {!!index && <hr />}
                        {insightDem.results.map((insightDemRes, index) => (
                          <InsightDemographicItem
                            key={index}
                            groupBy={insightDem.title}
                            insightSubgroupName={
                              (insightDemRes as any).subgroupName
                            }
                            demographic={demographic as SurveyDemographic}
                            insightDemographicResult={insightDemRes}
                          />
                        ))}
                        {insightDem.note && (
                          <div className="italic">{insightDem.note}</div>
                        )}
                      </React.Fragment>
                    )
                )}
              </div>
            </td>
          ))}
        </tr>
      </tbody>
    </HTMLTable>
  );
};

export default InsightContent;
