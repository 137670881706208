import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  SelectorOptionType,
  Survey,
  SurveyDeployment,
  SurveyReportTabEnum,
  UserNotesType,
} from "../../../types";
import Select, { StylesConfig } from "react-select";
//import { generateSurveyDeploymentAdmin } from "../../../helpers/message-format-utils";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { TabId } from "@blueprintjs/core/lib/esm/components/tabs/tab";
import { useParams } from "react-router";

type OwnProps = {
  surveyDeployments: SurveyDeployment<number, Survey<number>>[];
  surveyDeploymentId?: number;
  onSurveyDeploymentChange: (surveyDeploymentId?: number) => void;
  minimal?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  selectedSurveyDeployment?: SelectorOptionType | undefined;
  setSelectedSurveyDeployment?: (sd: SelectorOptionType | undefined) => void;
};

type Props = OwnProps;

const SurveyDeploymentSelector: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    surveyDeploymentId,
    onSurveyDeploymentChange,
    surveyDeployments,
    minimal,
    isClearable,
    isLoading,
    setSelectedSurveyDeployment,
  } = props;

  const loading = useSelector(
    (s) => s.surveysDeployment.loading.getSurveyDeploymentsBySchool
  );

  let surveyDeploymentsOptions: SelectorOptionType[] = useMemo(() => {
    //generateSurveyDeploymentAdmin("", "", loading);
    return _.uniq(_.map(surveyDeployments, "survey.name"))
      .map((surveyName: any) => {
        let filterSurvey = _.filter(surveyDeployments, function (o) {
          return o.survey.name === surveyName;
        });
        return _.orderBy(filterSurvey, "end_date", "desc").map((s) => ({
          value: s.id,
          end_date: s.end_date,
          name: s.survey.name,
          label:
            s.survey.name +
            " " +
            s.deployment_name +
            (s.is_deleted ? " -del" : ""),
        }));
      })
      .flat(1);
  }, [surveyDeployments, loading]);

  const handleSurveyDeploymentChange = (item?: any) => {
    if (setSelectedSurveyDeployment) setSelectedSurveyDeployment(item);
    onSurveyDeploymentChange(item?.value);
  };

  useEffect(() => {
    if (setSelectedSurveyDeployment) {
      const defaultOption = surveyDeploymentsOptions.find((option) => {
        return option.value === surveyDeploymentId;
      });
      setSelectedSurveyDeployment(defaultOption);
    }
  }, [
    setSelectedSurveyDeployment,
    surveyDeploymentsOptions,
    surveyDeploymentId,
  ]);

  const customStyles: StylesConfig<any, any> = {
    control: (provided, state) => ({
      ...provided,
      background: "#fafafa",
    }),

    menu: (base, state) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const customMinimalStyles: StylesConfig<any, any> = {
    ...customStyles,
    control: (provided, state) => ({
      ...provided,
      background: "#fafafa",
      minHeight: "30px",
      height: "30px",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "2px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  const displayNoOptions = useCallback(() => {
    return intl.formatMessage({ id: "app.titles.no-options" });
  }, []);

  const { tabId } = useParams<{ tabId?: string }>();

  const surveyTabIds: string[] = [
    SurveyReportTabEnum.ResponseRates,
    SurveyReportTabEnum.ElementaryStudents,
    SurveyReportTabEnum.Students,
    SurveyReportTabEnum.Parents,
    SurveyReportTabEnum.SchoolStaff,
    SurveyReportTabEnum.Comparison,
    SurveyReportTabEnum.Insights,
    SurveyReportTabEnum.ClusterTags,
  ];

  if ((UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS || UserNotesType.SNA_DIGGING_DEEPER || UserNotesType.SNA_PRIORITIZE_NEEDS)
    && !(tabId && surveyTabIds.includes(tabId))) {
    surveyDeploymentsOptions = surveyDeploymentsOptions.filter(k => k.value === surveyDeploymentId);
  }

  return (
    <Select
      isDisabled={
        UserNotesType.SNA_DIGGING_DEEPER === tabId ||
        UserNotesType.SNA_PRIORITIZE_NEEDS === tabId
      }
      isClearable={isClearable}
      isLoading={isLoading}
      value={surveyDeploymentsOptions.find((option) => {
        return option.value === surveyDeploymentId;
      })}
      styles={minimal ? customMinimalStyles : customStyles}
      className={minimal ? "min-w-12" : "selector-min-width"}
      placeholder={intl.formatMessage({
        id: "app.survey-report.breadcrumb.select-survey-deployment-placeholder",
      })}
      options={surveyDeploymentsOptions}
      noOptionsMessage={displayNoOptions}
      onChange={(item) => handleSurveyDeploymentChange(item)}
    />
  );
};

export default SurveyDeploymentSelector;
