import React, { useMemo } from "react";
import { Card, Icon, Tooltip } from "@blueprintjs/core";
import { Domain } from "../../types";

type OwnProps = {
  isActive?: boolean;
  domain?: Domain;
  onDomainClick?: () => void;
};

type Props = OwnProps;

const DomainListItem: React.FC<Props> = (props) => {
  const { domain, onDomainClick, children, isActive } = props;

  const tooltipContent = useMemo(
    () => (
      <div className="tooltip-container">
        <strong>{domain?.name}</strong>
        <p className="pt-4">{domain?.description}</p>
      </div>
    ),
    []
  );

  return (
    <Card
      className={`flex items-center text-xl text-center p-4 cursor-pointer relative m-4 ${
        isActive ? "bg-blue-600 text-white" : ""
      }`}
      style={{ flexBasis: "28%" }}
      onClick={onDomainClick}
      interactive
    >
      {children ?? (
        <div className="w-full">
          <strong>{domain?.name}</strong>
          <Tooltip
            content={tooltipContent}
            // position={Position.BOTTOM}
            className="pr-1 absolute top-0 right-0"
          >
            <Icon icon="info-sign" color={isActive ? "white" : "gray"} />
          </Tooltip>
        </div>
      )}
    </Card>
  );
};

export default DomainListItem;
