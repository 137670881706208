import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserDistrict,
  selectIsAdmin,
} from "../../../store/auth/selectors";
import BreadcrumbSurveyReportItem from "./BreadcrumbSurveyReportItem";
import { useIntl } from "react-intl";
import {
  BreadcrumbPropsType,
  District,
  School,
  SurveyReportTabEnum,
} from "../../../types";
import SchoolSelector from "../../common/selectors/SchoolSelector";
import { Icon } from "@blueprintjs/core";
import SurveyDeploymentSelector from "../../common/selectors/SurveyDeploymentSelector";
import DistrictSelector from "../../survey-deployment/selectors/DistrictSelector";
import {
  changeSelectedSurveyDeployment,
  fillBreadcrumb,
} from "../../../store/survey-reports/actions";

type OwnProps = {
  reportPagePath: string;
  tabId: SurveyReportTabEnum;
  districtId?: number;
  schoolId?: number;
  surveyDeploymentId?: number;
  domainName?: string;
};

type Props = OwnProps;

const BREADCRUMB_DOMAIN_INDEX = 4;
const BREADCRUMB_SURVEY_DEPLOYMENT_INDEX = 3;
const BREADCRUMB_SCHOOL_INDEX = 2;
const BREADCRUMB_DISTRICT_INDEX = 1;
const BREADCRUMB_DISTRICTS_INDEX = 0;

const BreadcrumbsSurveyReport: FunctionComponent<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    reportPagePath,
    tabId,
    districtId,
    schoolId,
    surveyDeploymentId,
    domainName,
  } = props;

  const history = useHistory();

  const dispatch = useDispatch();

  const [district, setDistrict] = useState<District | undefined>();
  const [school, setSchool] = useState<School | undefined>();

  const surveyDeployment = useSelector(
    (s) => s.surveyReports.selectedSurveyDeployment
  );

  const isAdmin = useSelector(selectIsAdmin);

  const currentUserDistrict = useSelector(getCurrentUserDistrict);

  const districts = useSelector((s) => s.districts.districts);

  const loading = useSelector((s) => s.schools.loading.getSchoolsByDistrict);
  const schools = useSelector((s) => s.schools.schools);

  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  useEffect(() => {
    const isFollowLink =
      district === undefined &&
      school === undefined &&
      surveyDeployment === undefined;
    if (isFollowLink) {
      dispatch(
        fillBreadcrumb.request({
          districtId: districtId,
          schoolId: schoolId,
          fetchDistricts: isAdmin,
        })
      );
    } else {
      dispatch(
        fillBreadcrumb.request({
          districtId:
            districtId !== undefined && schoolId === undefined
              ? districtId
              : undefined,
          schoolId:
            districtId !== undefined &&
            schoolId !== undefined &&
            surveyDeploymentId === undefined
              ? schoolId
              : undefined,
          fetchDistricts: false,
        })
      );
    }
  }, [districtId, schoolId, surveyDeploymentId]);

  useEffect(() => {
    if (
      !isAdmin &&
      currentUserDistrict !== null &&
      currentUserDistrict !== undefined
    ) {
      setDistrict(currentUserDistrict);
    } else {
      setDistrict(districts.find((item) => item?.id === districtId));
    }
  }, [isAdmin, currentUserDistrict, districts, districtId]);

  useEffect(() => {
    setSchool(schools.find((item) => item?.id === schoolId));
  }, [schools, schoolId]);

  useEffect(() => {
    const sD = surveyDeployments.find(
      (item) => item?.id === surveyDeploymentId
    );
    dispatch(changeSelectedSurveyDeployment(sD));
  }, [surveyDeployments, surveyDeploymentId]);

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbPropsType[]>();

  useEffect(() => {
    setBreadcrumbs([
      {
        id: BREADCRUMB_DISTRICTS_INDEX,
        isVisible: isAdmin,
        itemName: intl.formatMessage({
          id: "app.survey-report.breadcrumb.districts",
        }),
      },
      {
        id: BREADCRUMB_DISTRICT_INDEX,
        isVisible: true,
        isSelectable: isAdmin && district?.id === undefined,
        itemName: district?.name,
        editableComponent: (
          <DistrictSelector
            className={"selector-min-width"}
            onDistrictChange={handleDistrictChange}
          />
        ),
      },
      {
        id: BREADCRUMB_SCHOOL_INDEX,
        isVisible: district?.id !== undefined,
        itemName: school?.name,
        isSelectable: school?.id === undefined,
        editableComponent: (
          <SchoolSelector
            schools={schools}
            onSchoolChange={handleSchoolChange}
            loading={loading}
          />
        ),
      },
      {
        id: BREADCRUMB_SURVEY_DEPLOYMENT_INDEX,
        isVisible: school?.id !== undefined,
        isSelectable: surveyDeployment?.id === undefined,
        itemName:
          surveyDeployment?.survey?.name +
          " " +
          surveyDeployment?.deployment_name,
        editableComponent: (
          <SurveyDeploymentSelector
            surveyDeployments={surveyDeployments}
            onSurveyDeploymentChange={handleSurveyDeploymentChange}
          />
        ),
      },
      {
        id: BREADCRUMB_DOMAIN_INDEX,
        isVisible: domainName !== undefined,
        itemName: domainName,
      },
    ]);
  }, [
    district,
    school,
    surveyDeployment,
    isAdmin,
    schools,
    surveyDeployments,
    domainName,
  ]);

  const handleDistrictChange = (districtId?: number) => {
    history.push(
      generatePath(reportPagePath, {
        districtId: districtId,
        tabId: tabId,
        // only for plan
        workspace: "report",
      })
    );
  };

  const handleSchoolChange = (selectedSchoolId?: number) => {
    history.push(
      generatePath(reportPagePath, {
        districtId: districtId,
        schoolId: selectedSchoolId,
        tabId: tabId,
        // only for plan
        workspace: "report",
      })
    );
  };

  const handleSurveyDeploymentChange = (selectedSurveyDeployment?: number) => {
    history.push(
      generatePath(reportPagePath, {
        districtId: districtId,
        schoolId: schoolId,
        surveyDeploymentId: selectedSurveyDeployment,
        tabId: tabId,
        // only for plan
        workspace: "report",
      } as any)
    );
  };

  const handleBreadcrumbItemClick = (breadcrumbId: number) => {
    history.push(
      generatePath(reportPagePath, {
        districtId: breadcrumbId < 1 ? undefined : districtId,
        schoolId: breadcrumbId < 2 ? undefined : schoolId,
        surveyDeploymentId: breadcrumbId < 3 ? undefined : surveyDeploymentId,
        domainId: undefined,
        tabId: tabId,
        // only for plan
        workspace: "report",
      } as any)
    );
  };

  return (
    <>
      <div className="bg-white shadow-md border rounded border-gray-400 p-4 w-full flex items-center">
        <div className="flex flex-wrap">
          {breadcrumbs
            ?.filter((item) => item.isVisible)
            ?.map((item, index) => {
              return (
                <div key={index} className="flex items-center">
                  <>
                    {index !== 0 && <Icon icon="chevron-right" color="gray" />}
                    <BreadcrumbSurveyReportItem
                      breadcrumbProps={item}
                      onBreadcrumbItemClick={() => {
                        handleBreadcrumbItemClick(item.id);
                      }}
                      isActive={
                        index ===
                          (isAdmin
                            ? BREADCRUMB_DOMAIN_INDEX
                            : BREADCRUMB_DOMAIN_INDEX - 1) ||
                        index ===
                          (isAdmin
                            ? BREADCRUMB_SURVEY_DEPLOYMENT_INDEX
                            : BREADCRUMB_SURVEY_DEPLOYMENT_INDEX - 1)
                      }
                    />
                  </>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default BreadcrumbsSurveyReport;
