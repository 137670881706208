import { CommonText } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";

export const getCommonTexts = (): Promise<CommonText[]> => {
  return Axios.get(`${API_ROOT}/common-text/`);
};

export const addCommonText = (
  request: Pick<CommonText<number>, "common_text" | "questions">
): Promise<CommonText> => {
  return Axios.post(`${API_ROOT}/common-text/`, request);
};

export const updateCommonText = ({
  id,
  ...rest
}: Pick<CommonText<number>, "id" | "common_text" | "questions">): Promise<
  CommonText
> => {
  return Axios.put(`${API_ROOT}/common-text/${id}/`, rest);
};

export const deleteCommonText = (commonTextId: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/common-text/${commonTextId}/`);
};
