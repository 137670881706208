import _ from "lodash";
import { Locale } from "../../../store/UIState";
import { DomainAnswerSet, SurveyDemographic } from "../../../types";

export const getTranslationLocales = (domainAnswerSet: DomainAnswerSet) => {
  return _.chain(domainAnswerSet)
    .pick(Object.values(SurveyDemographic))
    .toPairs()
    .flatMap(([demographicKey, localesAnswers]) =>
      _.chain(localesAnswers)
        .toPairs()
        .filter(([locale, values]) => locale !== Locale.English)
        .map(([locale, values]) => ({
          locale: locale,
          filled: _.chain(values)
            .values()
            .every((text) => !!text.length)
            .value(),
        }))
        .value()
    )
    .groupBy((t) => t.locale)
    .toPairs()
    .map(([locale, values]) => ({
      locale: locale,
      filled: values.reduce<boolean>((pV, cV) => pV && cV.filled, true),
    }))
    .value();
};
