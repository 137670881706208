import React, { useCallback, useEffect, useMemo } from "react";
import { AnswerSet, SurveyDemographic } from "../../../types";
import * as _ from "lodash";
import { Card, Divider, Icon } from "@blueprintjs/core";
import { pickTextColorBasedOnBgColor } from "../../charts/utils";
import { useIntl } from "react-intl";

type OwnProps = {
  colors: string[];
  demographics?: SurveyDemographic[];
  getDomainDemographicAnswers: (
    demographic: SurveyDemographic
  ) => AnswerSet | undefined;
};

type Props = OwnProps;

type AnswersByDemographic<isArray extends boolean> = {
  demographic: isArray extends true ? SurveyDemographic[] : SurveyDemographic;
  answerSet?: AnswerSet;
};

const DomainAnswerSetContainer: React.FC<Props> = (props: Props) => {
  const { colors, demographics = [], getDomainDemographicAnswers } = props;
  const ICON_SIZE = 40;
  const intl = useIntl();

  const answersByDemographic = useMemo<AnswersByDemographic<false>[]>(() => {
    return demographics?.map((d) => ({
      demographic: d,
      answerSet: getDomainDemographicAnswers(d),
    }));
  }, [demographics, getDomainDemographicAnswers]);

  const answersCombinedByDemographic = useMemo<
    AnswersByDemographic<true>[]
  >(() => {
    let answers: AnswersByDemographic<true>[] = [];
    answersByDemographic.forEach((answerByDemographic) => {
      const existedAnswerIndex = answers.findIndex((answer) =>
        _.isEqual(answer.answerSet, answerByDemographic.answerSet)
      );
      existedAnswerIndex !== -1
        ? (answers[existedAnswerIndex] = {
            ...answers[existedAnswerIndex],
            demographic: [
              ...answers[existedAnswerIndex].demographic,
              answerByDemographic.demographic,
            ],
          })
        : answers.push({
            ...answerByDemographic,
            demographic: [answerByDemographic.demographic],
          });
    });
    return answers;
  }, [answersByDemographic]);

  const getAnswerCardsContainer = useCallback((answerSet: AnswerSet) => {
    return (
      <>
        <div className="text-center font-bold text-xl mt-10">Key:</div>
        <div className="flex justify-center mt-6 mb-10">
          {Object.values(answerSet)?.map((answerText, index) => {
            const colorToUse = colors[index % colors.length];
            return (
              <div className="flex items-center">
                <span>
                  <Icon
                    color={colorToUse}
                    icon={"symbol-square"}
                    iconSize={ICON_SIZE}
                  />
                </span>
                <span className="p-1 pr-4">{answerText}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  }, []);
  return (
    <div className="my-2">
      {answersCombinedByDemographic.length === 1 ? (
        <>
          {answersCombinedByDemographic[0].answerSet &&
            getAnswerCardsContainer(answersCombinedByDemographic[0].answerSet)}
        </>
      ) : (
        answersCombinedByDemographic.map((setsByDemographic, index) => (
          <div key={index}>
            <div className="flex justify-center my-2">
              {setsByDemographic.demographic.map((d, i) => (
                <>
                  <span className="font-semibold text-2xl mr-2" key={i}>
                    {intl.formatMessage({
                      id: `app.filters.respondent-type.${d}`,
                    })}
                    {setsByDemographic?.demographic?.length - 1 !== i && ", "}
                  </span>
                </>
              ))}
            </div>
            {setsByDemographic.answerSet &&
              getAnswerCardsContainer(setsByDemographic.answerSet)}
            <Divider />
          </div>
        ))
      )}
    </div>
  );
};

export default DomainAnswerSetContainer;
