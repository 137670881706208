import { MeetingType } from "../../types";
import { Context } from "chartjs-plugin-datalabels";

export function getLightenDarkenColor(col: string, amt: number): string {
  let usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function getRandomArbitrary(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export const pickTextColorBasedOnBgColor = (
  bgColor: string,
  lightColor: string = "#ffffff",
  darkColor: string = "#000000"
) => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });

  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
};

export const getBackgroundColorByMeetingType = (type?: MeetingType) => {
  switch (type) {
    case MeetingType.NJSCILearningNetworkEvent:
      return "#FFE599";

    case MeetingType.TASK:
      return "#C5E0B3";

    case MeetingType.TeamMeeting:
    case MeetingType.DistrictWideLeadershipMeeting:
      return "#8EAADB";

    default:
      return "#AD99FF";
  }
};

export const formatChartDataLabel = (nonIdealStateMessage?: string) => (
  value: number,
  ctx: Context
) => {
  if (!!ctx.chart?.data?.labels) {
    const label = ctx.chart.data.labels[ctx.dataIndex] as string;
    return label == nonIdealStateMessage ? nonIdealStateMessage : "";
  }
};
