import {
  SciWritingFilterEnum,
  sciWritingFilterUserNotesMapper,
  UserNotesType,
} from "../../../../types";

export const getUserNotesTypeBySciWritingFilter = (
  value: SciWritingFilterEnum,
  isDistrictPlan: boolean
): UserNotesType[] => {
  return isDistrictPlan
    ? sciWritingFilterUserNotesMapper.districtLevel?.[value as "district_climate_leadership_team"]
    : sciWritingFilterUserNotesMapper.schoolLevel?.[value as "school_climate_leadership_team"];
};

export const getSciWritingFilterByUserNotesType = (
  value: UserNotesType,
  isDistrictPlan: boolean
): SciWritingFilterEnum | undefined => {
  let sciFilter;
  Object.entries(
    isDistrictPlan
      ? sciWritingFilterUserNotesMapper.districtLevel
      : sciWritingFilterUserNotesMapper.schoolLevel
  ).forEach(([filter, userNotesType]) => {
    if (userNotesType.some((t) => t === value)) {
      sciFilter = filter;
    }
  });
  return sciFilter;
};
