import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../useLoading";
import { useEffect } from "react";
import { getDatasetMarks } from "../../../store/dataset-marks/actions";
import { BookmarkType } from "../../../types";

export const useGetDatasetMarksLoading = (props: {
  schoolId?: number;
  bookmarkType?: BookmarkType;
  supportsBookmarks?: boolean;
  surveyDeploymentId?: number;
}) => {
  const { schoolId, bookmarkType, supportsBookmarks = true, surveyDeploymentId } = props;
  const surveyDeployment = useSelector(
    (s) => s.surveyReports.selectedSurveyDeployment
  );
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (schoolId && supportsBookmarks)
      dispatch(
        getDatasetMarks.request({
          schoolId: schoolId,
          bookmark_type: bookmarkType,
          survey_deployment_id: surveyDeploymentId? surveyDeploymentId :surveyDeployment?.id,
          isFiltered: false
        })
      );
  }, [schoolId, bookmarkType, supportsBookmarks, dispatch, surveyDeploymentId]);

  const loadingGetDatasetMarks = useSelector(
    (s) => s.datasetMarks.loading.getDatasetMarks
  );
  const errorGetDatasetMarks = useSelector(
    (s) => s.datasetMarks.errors.getDatasetMarks
  );
  useLoading({ loading: loadingGetDatasetMarks, error: errorGetDatasetMarks });

  return {
    loading: loadingGetDatasetMarks,
  };
};

export default useGetDatasetMarksLoading;