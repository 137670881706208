import React, { FunctionComponent } from "react";
import { ChartDataSet, ResponsesDistribution } from "../../../../types";
import * as _ from "lodash";
import overbrace from "../../../../static/images/overbrace.png";
import BarChart from "../../../charts/BarChart";

type OwnProps = {
  responsesDistribution: ResponsesDistribution[];
};

type Props = OwnProps;

const TotalCountStatisticPopoverContent: FunctionComponent<Props> = (props) => {
  const { responsesDistribution } = props;

  const firstSet = responsesDistribution.slice(
    0,
    Math.floor(responsesDistribution.length / 2)
  );
  const lastSet = responsesDistribution.slice(
    Math.floor(responsesDistribution.length / 2)
  );

  const barDataSetFirst: ChartDataSet = {
    data: firstSet.map((item) => item.percent),
    labels: firstSet.map((item) => item.label.split(" ")),
  };
  const barDataSetLast: ChartDataSet = {
    data: lastSet.map((item) => item.percent),
    labels: lastSet.map((item) => item.label.split(" ")),
  };

  const firstHalfDisplay = _.sum(firstSet.map((item) => item.percent));
  const secondHalfDisplay = _.sum(lastSet.map((item) => item.percent));

  return (
    <div className="px-2 pt-4 pb-2" style={{ maxWidth: 520 }}>
      <div
        className="flex justify-around text-lg"
        style={{ marginBottom: -10 }}
      >
        <span>{firstHalfDisplay}%</span>
        <span>{secondHalfDisplay}%</span>
      </div>

      <div
        className="flex justify-around align-middle pt-2"
        style={{ marginBottom: -8 }}
      >
        <img src={overbrace} alt="" />
        <img src={overbrace} alt="" />
      </div>

      <div className="flex justify-around">
        <BarChart
          dataSet={barDataSetFirst}
          barPercentage={0.4}
          width={260}
          // labelDivider={BAR_CHART_LABEL_DIVIDER}
          colors={["#8A2A0E", "#F5A877"]}
        />
        <div className="p-1 w-px h-24 self-center bg-gray-600" />
        <BarChart
          dataSet={barDataSetLast}
          barPercentage={0.4}
          width={260}
          // labelDivider={BAR_CHART_LABEL_DIVIDER}
          colors={["#1DD171", "#385624"]}
        />
      </div>
      <div className="px-4 py-2">
        Percentages for categories are combined above the bars to show overall
        disagreement and overall agreement with this item.
      </div>
    </div>
  );
};

export default TotalCountStatisticPopoverContent;
