import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { getUrlParam, removeUrlSearchParam } from "../url-utils";
import { NOTE } from "../../constants/constants";

const useNotesDialogInNewTabLocation = () => {
  const location = useLocation();

  const history = useHistory();

  const searchNoteId = useMemo(() => {
    return +getUrlParam(NOTE, location.search)!;
  }, [location.search]);

  const onNewTabNoteDialogActionSuccess = useCallback(() => {
    if (searchNoteId) {
      return history.push({
        pathname: location.pathname,
        search: removeUrlSearchParam(NOTE, location.search),
      });
    }
  }, [searchNoteId]);

  return {
    searchParamsNoteId: searchNoteId,
    onNewTabNoteDialogActionSuccess,
  };
};

export default useNotesDialogInNewTabLocation;
