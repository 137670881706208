import { createAction, createAsyncAction } from "typesafe-actions";
import { Domain } from "../../types";
import { AddDomainRequest, UpdateDomainRequest } from "../../api/domains/types";

export const setSelectedDomain = createAction("@domains/SET_SELECTED_DOMAIN")<
  Domain | undefined
>();

export const getDomains = createAsyncAction(
  "@domains/GET_DOMAINS_REQUEST",
  "@domains/GET_DOMAINS_SUCCESS",
  "@domains/GET_DOMAINS_FAILURE"
)<void, Domain[], Error>();

export const getDomainsById = createAsyncAction(
  "@domains/GET_DOMAINS_STATUS_BY_IDS_REQUEST",
  "@domains/GET_DOMAINS_STATUS_BY_IDS_SUCCESS",
  "@domains/GET_DOMAINS_STATUS_BY_IDS_FAILURE"
)<{domainsIds:number[],deployment_id:number,planId:number|undefined}, Domain[], Error>();


export const getExistDomainItems = createAsyncAction(
  "@domains/GET_Exist_DOMAINS_ITEMS_STATUS_BY_IDS_REQUEST",
  "@domains/GET_Exist_DOMAINS_ITEMS_STATUS_BY_IDS_SUCCESS",
  "@domains/GET_Exist_DOMAINS_ITEMS_STATUS_BY_IDS_FAILURE",

)<{deployment_id:number}, Domain[], Error>();

export const addDomain = createAsyncAction(
  "@domains/ADD_DOMAIN_REQUEST",
  "@domains/ADD_DOMAIN_SUCCESS",
  "@domains/ADD_DOMAIN_FAILURE"
)<AddDomainRequest, Domain, Error>();

export const updateDomain = createAsyncAction(
  "@domains/UPDATE_DOMAIN_REQUEST",
  "@domains/UPDATE_DOMAIN_SUCCESS",
  "@domains/UPDATE_DOMAIN_FAILURE"
)<UpdateDomainRequest, Domain, Error>();

export const deleteDomain = createAsyncAction(
  "@domains/DELETE_DOMAIN_REQUEST",
  "@domains/DELETE_DOMAIN_SUCCESS",
  "@domains/DELETE_DOMAIN_FAILURE"
)<number, number, Error>();

export const showDomainUpsertDialog = createAction(
  "@domains/SHOW_DOMAIN_UPSERT_DIALOG"
)<{ domainName?: string; domain?: Domain }>();

export const hideDomainUpsertDialog = createAction(
  "@domains/HIDE_DOMAIN_UPSERT_DIALOG"
)<void>();

export const getDomainById = createAsyncAction(
  "@domains/GET_DOMAIN_BY_ID_REQUEST",
  "@domains/GET_DOMAIN_BY_ID_SUCCESS",
  "@domains/GET_DOMAIN_BY_ID_FAILURE"
)<number, Domain, Error>();
