import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as _ from "lodash";
import {
  DomainPriorityTriageStatus,
  SurveyDemographic,
  UserNotesType,
} from "../../../types";

const DEFAULT_AVAILABLE_TABS = [
  UserNotesType.SNA_EXPLORE_STRENGTHS_NEEDS,
  UserNotesType.SNA_RESOURCES,
];

const useAvailableTabs = (): UserNotesType[] => {
  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);

  const domainPriorities = useSelector((s) => s.needs.domainPriorities);

  const [availableTabs, setAvailableTabs] = useState<UserNotesType[]>(
    DEFAULT_AVAILABLE_TABS
  );

  useEffect(() => {
    const groupedDomainPriorities = _.groupBy(
      domainPriorities,
      (dP) => dP.domain.id
    );
    // const isGroupedPrioritiesLengthEqualToComparison =
    //   Object.keys(groupedDomainPriorities).length ===
    //   (_.isArray(surveyComparison) ? surveyComparison.length : false);

    const isGroupedPrioritiesLengthEqualToComparison =
      Object.keys(groupedDomainPriorities).length

    if (
      isGroupedPrioritiesLengthEqualToComparison &&
      _.isArray(surveyComparison)
    ) {
      let isTriageStatusValid = true;
      let isDecisionsValid = true;
      surveyComparison.forEach((sc) => {
        const availableDemographics = Object.values(SurveyDemographic).filter(
          (d) => !!sc.respondents[d] && !sc.respondents[d]?.not_enough_data
        );
        const prioritiesByDomain = groupedDomainPriorities?.[sc.domain.id];
        if (prioritiesByDomain?.length === availableDemographics.length) {
          availableDemographics.forEach((d) => {
            const demographicPriority = prioritiesByDomain.find(
              (p) => p.demographic === d
            );
            if (!demographicPriority?.triage_status) {
              isTriageStatusValid = false;
            }
            if (
              demographicPriority?.triage_status !==
              DomainPriorityTriageStatus.Archived &&
              !demographicPriority?.decision
            ) {
              isDecisionsValid = false;
            }
          });
        }
        else {
          availableDemographics.forEach((dm) => {
            const demographicPriority = domainPriorities.find(
              (dM) => dM.domain.id === sc.domain.id && dM.demographic === dm
            );
            let archived = demographicPriority?.triage_status ===
              DomainPriorityTriageStatus.Archived;
            let prioritized = demographicPriority?.triage_status ===
              DomainPriorityTriageStatus.PotentialPriority;
            if (!archived && !prioritized && demographicPriority) {
              isTriageStatusValid = false
              isDecisionsValid = false
            }
          })
        }
      });

      if (isTriageStatusValid && !isDecisionsValid) {
        setAvailableTabs([
          ...DEFAULT_AVAILABLE_TABS,
          UserNotesType.SNA_DIGGING_DEEPER,
        ]);
      } else if (isTriageStatusValid && isDecisionsValid) {
        setAvailableTabs([
          ...DEFAULT_AVAILABLE_TABS,
          UserNotesType.SNA_DIGGING_DEEPER,
          UserNotesType.SNA_PRIORITIZE_NEEDS,
        ]);
      } else {
        setAvailableTabs(DEFAULT_AVAILABLE_TABS);
      }
    } else {
      setAvailableTabs(DEFAULT_AVAILABLE_TABS);
    }
  }, [surveyComparison, domainPriorities]);

  return availableTabs;
};

export default useAvailableTabs;
