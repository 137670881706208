import React, { FunctionComponent } from "react";
import { Button } from "@blueprintjs/core";
import { useIntl } from "react-intl";

type OwnProps = {
  onClose: () => void;
  onComplete: () => any;
};

type Props = OwnProps;

const CommunicateWithPrincipalButton: FunctionComponent<Props> = (props) => {
  const { onComplete } = props;

  const intl = useIntl();

  const handleClick = () => {
    onComplete();
  };

  return (
    <Button
      large
      text={intl.formatMessage({
        id:
          "app.district-setup.tasks.communicate_with_principals.button-complete",
      })}
      intent="primary"
      onClick={handleClick}
    />
  );
};

export default CommunicateWithPrincipalButton;
