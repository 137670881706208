import * as React from "react";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import {
  Button,
  ButtonGroup,
  Callout,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import SurveyQuestionForm from "../../forms/SurveyQuestionForm";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  EditedSurveyQuestionsByPage,
  QuestionTranslations,
  SurveyDemographic,
  SurveyQuestionExcludeBaseModelType,
} from "../../../../types";
import { Locale, LocaleDisplayedValues } from "../../../../store/UIState";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionTags } from "../../../../store/surveys/actions";
import _ from "lodash";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import useWindowSize, {
  WindowSize,
} from "../../../../helpers/hooks/useWindowSize";
import DomainUpsertDialog from "../../../domains/domain-upsert-dialog/DomainUpsertDialog";
import { getDomains } from "../../../../store/domains/actions";
import SurveyQuestionsTranslationsDialog from "../dialogs/SurveyQuestionsTranslationsDialog";
import DomainAnswerSetUpsertDialog from "../../../domains/domain-answer-set/DomainAnswerSetUpsertDialog";
import { AppToaster } from "../../../../helpers/toaster";
import {
  addNewQuestion,
  addPage,
  removePage,
  removeQuestion,
  reorderQuestions,
  setLeadIn,
} from "../utils";

type OwnProps = {
  surveyId: number;
  demographic: SurveyDemographic;
  questionsPages?: EditedSurveyQuestionsByPage;
  questionsLoading?: boolean;
  onQuestionPagesChange: (
    updatedQuestionsPages: EditedSurveyQuestionsByPage,
    currentPage?: number
  ) => void;
  onQuestionFieldUpdate: (
    page: number,
    order: number,
    field: string,
    value: string | number | number[] | boolean | undefined
  ) => void;
  onQuestionUpdate: (question: SurveyQuestionExcludeBaseModelType) => void;
  onTranslationUpdate: (translates: QuestionTranslations) => void;
  showDialog?: boolean;
  onClose?: () => void;
};

type Props = OwnProps;

export const INITIAL_PAGE = 0;

const EditSurveyQuestionsTab: React.FunctionComponent<Props> = (
  props: PropsWithChildren<Props>
) => {
  const {
    surveyId,
    demographic,
    children,
    questionsPages,
    questionsLoading,
    onQuestionPagesChange,
    onQuestionFieldUpdate,
    onQuestionUpdate,
    onTranslationUpdate,
    showDialog,
    onClose,
  } = props;
  const dispatch = useDispatch();

  const intl = useIntl();

  const windowSize = useWindowSize();

  const panelTitle = useMemo(() => {
    return (
      intl.formatMessage({
        id: `app.surveys.survey-demographic.${demographic}`,
      }) +
      " " +
      intl.formatMessage({ id: "app.surveys.survey-questions" })
    );
  }, [demographic]);

  const isSmallScreen = useMemo(() => {
    return windowSize === WindowSize.SM;
  }, [windowSize]);

  const loadingSurvey = useSelector((s) => s.surveys.loading.getSurvey);
  const errorSurvey = useSelector((s) => s.surveys.errors.getSurvey);
  useLoading({ loading: loadingSurvey, error: errorSurvey });

  useEffect(() => {
    if (surveyId) {
      dispatch(
        getQuestionTags.request({
          survey_id: surveyId,
        })
      );
    }
  }, [surveyId]);

  const saveLoading = useSelector((s) => s.surveys.loading.addQuestionTag);
  const saveError = useSelector((s) => s.surveys.errors.addQuestionTag);
  const onSaveTagSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.surveys.tag-added",
      }),
      icon: "tick",
      intent: "success",
    });
  }, []);
  useLoading({
    loading: saveLoading,
    error: saveError,
    onSuccess: onSaveTagSuccess,
  });

  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);

  const domains = useSelector((s) => s.domains.domains);
  useEffect(() => {
    dispatch(getDomains.request());
  }, []);

  const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);

  useEffect(() => {
    setCurrentPage(INITIAL_PAGE);
  }, [demographic]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const showConfirmPageDeleteDialog = () => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onDeletePageConfirmed();
        },
        show: true,
        intent: "danger",
        icon: "trash",
        text: intl.formatMessage(
          {
            id: "app.confirmation-dialogs.delete-survey-page",
          },
          { deletedPage: currentPage + 1 }
        ),
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
        cancelButtonText: intl.formatMessage({ id: "app.titles.cancel" }),
      })
    );
  };

  const onDeletePageConfirmed = () => {
    setCurrentPage(
      currentPage === INITIAL_PAGE ? INITIAL_PAGE : currentPage - 1
    );
    dispatch(hideConfirmDialog());
    onQuestionPagesChange(
      removePage(questionsPages ?? {}, currentPage),
      currentPage
    );
  };

  const handleAddQuestionClick = () => {
    onQuestionPagesChange(
      addNewQuestion(
        questionsPages ?? {},
        domains[0]?.id,
        currentPage,
        demographic,
        surveyId
      )
    );
  };

  const handleRemoveQuestionClick = (page: number, order: number) => {
    onQuestionPagesChange(removeQuestion(questionsPages ?? {}, page, order));
  };

  const handleAddPageClick = () => {
    onQuestionPagesChange(addPage(questionsPages ?? {}, currentPage + 1));
    setCurrentPage(currentPage + 1);
  };

  const onDragSurveyQuestionEnd = (result: DropResult) => {
    const { destination, source } = result;
    //dropped outside the droppable area
    if (!destination) {
      return;
    }

    onQuestionPagesChange(
      reorderQuestions(
        questionsPages ?? {},
        source.index,
        destination.index,
        currentPage
      )
    );
  };

  const onClick = (event: any) => {
    // click was used as a part of the drag
    if (event.defaultPrevented) {
      return;
    }
    //event.currentTarget.focus();
  };

  const onMouseDown = (event: any) => {
    if (event.defaultPrevented) {
      event.currentTarget.click();
      return;
    }
    //event.currentTarget.focus();
  };

  const surveyQuestionsByDemographic = useMemo(() => {
    return _.flatMap(_.values(questionsPages ?? {}));
  }, [questionsPages]);

  const translationStats = useMemo(() => {
    return _.chain(surveyQuestionsByDemographic)
      .flatMap((sq) => _.keys(sq.translations))
      .groupBy()
      .value();
  }, [surveyQuestionsByDemographic]);

  const availableTranslations = useMemo(() => {
    return (
      selectedSurvey?.available_languages.filter(
        (locale) => locale !== Locale.English
      ) ?? []
    );
  }, [selectedSurvey?.available_languages]);

  const handleQuestionLeadInUpdate = (
    page: number,
    order: number,
    leadIn?: string
  ) => {
    const updatedLeadInQuestion = setLeadIn(
      questionsPages?.[page] || [],
      order,
      leadIn
    );
    if (updatedLeadInQuestion) {
      onQuestionUpdate(updatedLeadInQuestion);
    }
  };

  const segmentationCodeByDomainDictionary = useMemo(() => {
    return _.chain(questionsPages)
      .values()
      .flatMap((arr) => arr)
      .groupBy((q) => q.domain)
      .mapValues(
        (values) =>
          _.chain(values)
            .map((v) => v.segmentation_code)
            .uniq()
            .filter((item) => !!item)
            .value() as string[]
      )
      .value();
  }, [questionsPages]);

  return (
    <div className="flex-grow">
      {questionsLoading || loadingSurvey ? (
        <Spinner intent="primary" className="pb-2" />
      ) : (
        <>
          <div className="flex justify-center items-center mb-4">
            {isSmallScreen ? children : <div />}
            <p className="font-bold text-xl">{panelTitle}</p>
          </div>
          {!!availableTranslations.length && (
            <Callout intent="primary" className="mb-2">
              <p>
                {availableTranslations.map((key, index, arr) => (
                  <span key={index}>
                    {intl.formatMessage(
                      {
                        id: "app.translations.survey.questions.statistic",
                      },
                      {
                        language: (
                          <strong>
                            {LocaleDisplayedValues[key as Locale]}
                          </strong>
                        ),
                        current: translationStats[key as Locale]?.length ?? 0,
                        total: surveyQuestionsByDemographic.length,
                      }
                    )}
                    {index + 1 !== arr.length && "; "}
                  </span>
                ))}
              </p>
            </Callout>
          )}
          {!!Object.keys(questionsPages ?? {})?.length && (
            <div className="flex flex-wrap justify-between">
              <div className="flex">
                <p className="font-bold text-xl mr-4">
                  {intl.formatMessage({
                    id: "app.surveys.survey-questions.page",
                  })}
                </p>
                <ButtonGroup className="flex flex-wrap">
                  {Object.keys(questionsPages ?? {})?.map(
                    (item, index, array) => (
                      <Button
                        icon={currentPage === Number(item) ? "tick" : "blank"}
                        key={index}
                        rightIcon={
                          <Button
                            icon="trash"
                            minimal
                            intent="danger"
                            disabled={
                              array.length === 1 || Number(item) !== currentPage
                            }
                            onClick={(
                              e: React.MouseEvent<HTMLElement, MouseEvent>
                            ) => {
                              e.stopPropagation();
                              showConfirmPageDeleteDialog();
                            }}
                          />
                        }
                        onClick={() => handlePageChange(+item)}
                      >
                        {Number(item) + 1}
                      </Button>
                    )
                  )}
                  <Button
                    icon="add"
                    intent="success"
                    onClick={handleAddPageClick}
                    disabled={(questionsPages || [])[currentPage]?.length === 0}
                  />
                </ButtonGroup>
              </div>
              <div>
                <Button
                  large
                  className="button-min-width"
                  text={intl.formatMessage({
                    id: "app.surveys.survey-questions.add-question",
                  })}
                  title={intl.formatMessage({
                    id: "app.surveys.survey-questions.add-question",
                  })}
                  onClick={handleAddQuestionClick}
                />
              </div>
            </div>
          )}

          {!!questionsPages && questionsPages[currentPage]?.length > 0 ? (
            <DragDropContext onDragEnd={onDragSurveyQuestionEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {questionsPages[currentPage]?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            key={item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onMouseDown={onMouseDown}
                            onClick={onClick}
                          >
                            <SurveyQuestionForm
                              surveyId={surveyId}
                              item={item}
                              surveyAvailableTranslations={
                                availableTranslations
                              }
                              onRemoveQuestionClick={handleRemoveQuestionClick}
                              onQuestionFieldUpdate={onQuestionFieldUpdate}
                              onQuestionLeadInUpdate={
                                handleQuestionLeadInUpdate
                              }
                              segmentationCodes={
                                segmentationCodeByDomainDictionary[item.domain]
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <NonIdealState
              className="mt-2 py-8 bg-gray-100"
              title={intl.formatMessage({
                id: "app.survey-questions.no-created-survey-questions-yet",
              })}
              description={intl.formatMessage({
                id: "app.survey-questions.try-to-add-new-question",
              })}
              action={
                <Button
                  intent="primary"
                  text={intl.formatMessage({
                    id: "app.surveys.survey-questions.add-question",
                  })}
                  title={intl.formatMessage({
                    id: "app.surveys.survey-questions.add-question",
                  })}
                  onClick={handleAddQuestionClick}
                />
              }
            />
          )}

          {(questionsPages || [])[currentPage]?.length > 0 && (
            <div className="flex justify-end mt-2">
              <Button
                className="button-min-width"
                large
                text={intl.formatMessage({
                  id: "app.surveys.survey-questions.add-question",
                })}
                title={intl.formatMessage({
                  id: "app.surveys.survey-questions.add-question",
                })}
                onClick={handleAddQuestionClick}
              />
            </div>
          )}
        </>
      )}
      <DomainUpsertDialog />
      <DomainAnswerSetUpsertDialog />
      <SurveyQuestionsTranslationsDialog
        availableLanguages={
          selectedSurvey?.available_languages ?? [Locale.English]
        }
        panelTitle={panelTitle}
        surveyQuestionsForDemographic={surveyQuestionsByDemographic}
        show={showDialog}
        onClose={onClose}
        onTranslationUpdate={onTranslationUpdate}
      />
    </div>
  );
};

export default EditSurveyQuestionsTab;
