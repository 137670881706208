import {
  DemographicWrapper,
  OpenTimePeriod,
  School,
  SurveyDemographic,
} from "../types";
import _ from "lodash";

export const getAvailableDemographics = <TSchool extends School | number>({
  schoolOpenPeriods = [],
  availableDemographicsInPlan,
  responses,
}: {
  availableDemographicsInPlan: SurveyDemographic[];
  schoolOpenPeriods?: OpenTimePeriod<TSchool>[];
  responses?: DemographicWrapper<number>;
}): SurveyDemographic[] => {
  const availableDemographicsInSurvey: SurveyDemographic[] = _.chain(
    schoolOpenPeriods
  )
    .filter((op) => !op.disabled)
    .map((op) => op.demographic)
    .concat(_.keys(responses) as SurveyDemographic[])
    .uniq()
    .filter((d) => d != null)
    .value() as SurveyDemographic[];

  return _.intersection(
    availableDemographicsInSurvey,
    availableDemographicsInPlan
  );
};
