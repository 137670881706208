import React, { FunctionComponent, useMemo } from "react";
import { Callout } from "@blueprintjs/core";
import { SurveyQuestion } from "../../../../../types";
import useDatasetMark, { DatasetParams } from "../../../cards/useDatasetMark";
import ItemMark from "../../../table/survey-report-table-row/ItemMark";
import MeanScoreItemTag from "./MeanScoreItemTag";

type OwnProps = {
  question: SurveyQuestion;
  datasetMarkParams: DatasetParams;
  itemMeanScore?: number;
};

type Props = OwnProps;

const DomainGroupItem: FunctionComponent<Props> = (props) => {
  const { question, datasetMarkParams, itemMeanScore } = props;

  const title = useMemo(() => {
    return `${question.order + 1}. ${
      question.lead_in ? `${question.lead_in} ` : ""
    }${question.text}`;
  }, [question]);

  const {
    flagged,
    onClick,
    onDiscard,
    bookmarkType,
    supportsBookmarks,
  } = useDatasetMark(
    {
      ...datasetMarkParams,
      questionId: question.id,
    },
    itemMeanScore
  );

  return (
    <Callout
      icon={null}
      intent={"primary"}
      className="p-2 flex items-center gap-2 border border-1 border-gray-400"
    >
      {supportsBookmarks && (
        <ItemMark
          flagged={flagged}
          bookmarkType={bookmarkType}
          onClick={onClick}
          onDiscard={onDiscard}
        />
      )}

      <MeanScoreItemTag score={itemMeanScore} />

      {title}
    </Callout>
  );
};

export default DomainGroupItem;
