/* eslint-disable jsx-a11y/img-redundant-alt */
import { Icon, Tabs, Tab } from "@blueprintjs/core";
import React, { FunctionComponent, ReactNode, useState } from "react";

import "./top-bar.scss";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { showFeedbackDialog } from "../../../store/UIState/actions";
import { showNotesDialog } from "../../../store/notes/actions";
import { Props } from "react-select";
import { PlanWorkspaces, UserNotesType } from "../../../types";
import Popover from "@mui/material/Popover";
import EditProfile from "./edit-profile/EditProfile";
import ChangePassword from "./change-password/ChangePassword";
import { logout } from "../../../store/auth/actions";
import { Link, useHistory } from "react-router-dom";
import { AuthRoutes } from "../../../App";
import { generatePath } from "react-router";
import logo from "../../../static/images/nj-sci-logo.png";

type OwnProps = {
  title: string;
  onInfoIconClick?: Function;
  userNotesType?: UserNotesType;
  nextElement?: ReactNode;
};

const TopBar: FunctionComponent<Props> = (props) => {
  // CONSTANT
  const intl = useIntl();
  const dispatch = useDispatch();
  const { userNotesType } = props;
  const user = useSelector((s) => s.auth.authenticatedUser);

  // STATES
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // ACTIONS
  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLButtonElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // FUCNCTIONS
  const handleNotesDialogOpen = () => {
    dispatch(showNotesDialog({ type: userNotesType }));
  };

  const handleShareFeedbackClick = () => {
    dispatch(showFeedbackDialog());
  };

  const handleLogOut = () => {
    dispatch(logout.request());
  };

  return (
    <>
      <div className="header-bar">
        <table style={{ width: "100%" }}>
          <tr>
            <td className="left-align">
              {(window.location.pathname.includes(`/plan/${PlanWorkspaces.SCIWriting}`) || window.location.pathname.includes(`/plan/${PlanWorkspaces.PublishedNotes}`)) && (
                <img
                  src={logo}
                  style={{
                    background: "rgba(255, 255, 255, 1.0)",
                    height: "80px",
                    width: "130px",
                  }}
                  className="rounded-md"
                  alt="NJ SCI Platform"
                />
              )}
            </td>
            <td className="right-align">
              <ul>
                <li>
                  {/* <a href="#" onClick={handleNotesDialogOpen}>{intl.formatMessage({ id: "app.titles.sci-writing" })} </a>   */}
                  <Link
                    to={generatePath(AuthRoutes.YourPlan, {
                      workspace: "sci-writing" as any,
                    } as any)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 20 22"
                      fill="none"
                    >
                      <path
                        d="M3.63672 6.47778H9.32705V7.80907H3.63672V6.47778Z"
                        fill="white"
                      />
                      <path
                        d="M3.59375 16.699H6.90051V18.0303H3.59375V16.699Z"
                        fill="white"
                      />
                      <path
                        d="M3.59375 13.3062H7.7595V14.616H3.59375V13.3062Z"
                        fill="white"
                      />
                      <path
                        d="M3.61523 10.0637H8.36075V11.3092H3.61523V10.0637Z"
                        fill="white"
                      />
                      <path
                        d="M10.1862 20.4782H1.57552V1.88261H11.9899V5.68336H15.7906V8.92573H17.0147L17.0145 4.30905L13.3642 0.658691H0.351562V21.6807H10.1862L10.1862 20.4782ZM12.9775 1.7323L15.9193 4.67405H12.9775V1.7323Z"
                        fill="white"
                      />
                      <path
                        d="M9.0918 19.1189L11.805 18.5198L9.69079 16.4058L9.0918 19.1189Z"
                        fill="white"
                      />
                      <path
                        d="M18.8253 11.9193C19.155 11.5903 19.3368 11.1525 19.337 10.6867C19.3373 10.2209 19.156 9.78287 18.8265 9.4535L18.7568 9.38367C18.4278 9.05455 17.9901 8.87329 17.5246 8.87329H17.5237C17.0579 8.87354 16.6202 9.05528 16.291 9.38504L9.97949 15.7096L12.5006 18.2307L18.8253 11.9193Z"
                        fill="white"
                      />
                    </svg>
                    {intl.formatMessage({ id: "app.titles.sci-writing" })}
                    &nbsp;
                  </Link>
                </li>
                <li>
                  <a href="#" onClick={handleShareFeedbackClick}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.1719 0.668213C17.1167 0.671027 17.0624 0.682988 17.0112 0.703744L1.18989 7.1934C1.06875 7.24371 0.972598 7.33986 0.922408 7.461C0.872218 7.58214 0.872218 7.71828 0.922408 7.8394C0.972598 7.96053 1.06875 8.05681 1.18989 8.107L7.58529 10.7775L10.2553 17.1729H10.2554C10.3055 17.294 10.4017 17.3903 10.523 17.4406C10.6441 17.4908 10.7803 17.4908 10.9015 17.4406C11.0226 17.3903 11.1189 17.294 11.1691 17.1729L17.6588 1.35158C17.723 1.19479 17.7025 1.01596 17.6043 0.877714C17.5062 0.739576 17.3441 0.661245 17.1749 0.670272L17.1719 0.668213ZM15.1068 2.55429L7.84503 9.81635L2.67024 7.65575L15.1068 2.55429ZM15.8063 3.25342L10.7047 15.69L8.54413 10.5152L15.8063 3.25342Z"
                        fill="#458CCB"
                      />
                    </svg>

                    {intl.formatMessage({
                      id: "app.titles.share-feedback-inline",
                    })}
                  </a>
                </li>
                <li>
                  <button onMouseOver={handleClick}>
                    {user?.profile?.image_url ? (
                      <span>
                        <img
                          src={user?.profile?.image_url}
                          alt="profile picture"
                          className="rounded-circle border border-gray-500"
                        />
                      </span>
                    ) : (
                      <span>
                        {user?.first_name?.charAt(0)?.toLocaleUpperCase()}{" "}
                        {user?.last_name?.charAt(0)?.toLocaleUpperCase()}
                      </span>
                    )}
                  </button>
                  <Popover
                    className="profileList"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div className="dropdown-list strategy-actions">
                      <div className="profileName">{`${user?.first_name} ${user?.last_name}`}</div>
                      <ul>
                        <li>
                          <EditProfile handleClose={handleClose} />
                        </li>
                        <li>
                          <ChangePassword handleClose={handleClose} />
                        </li>
                        <li>
                          <a onClick={handleLogOut}>Logout</a>
                        </li>
                      </ul>
                    </div>
                  </Popover>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default TopBar;
