import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Button } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { showUpsertSchoolDialog } from "../../store/schools/actions";

type Props = {};

const SetupNewSchoolBtn: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleShowUpsertSchoolDialog = useCallback(() => {
    dispatch(showUpsertSchoolDialog(undefined));
  }, []);

  const buttonText = useMemo(() => {
    return intl.formatMessage({
      id: "app.titles.add-schools",
    });
  }, []);

  return (
    <Button
      large
      data-tour="add-school-button"
      className="capitalize ml-2"
      text={buttonText}
      title={buttonText}
      intent="primary"
      onClick={handleShowUpsertSchoolDialog}
    />
  );
};

export default SetupNewSchoolBtn;
