import React from "react";
import { Icon } from "@blueprintjs/core";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { useIntl } from "react-intl";
// import { AuthRoutes } from "../../../../../../../App";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { AuthRoutes } from "../../../../App";

type OwnProps = {
  open: boolean;
  setOpen: any;
};

type Props = OwnProps;

const NotesInfoBar: React.FC<Props> = (props) => {
  const { open, setOpen } = props;

  const [infoexpanded, infosetExpanded] = React.useState<string | false>(false);
  const [infoDocument, setInfoDocument] = React.useState<string | false>(false);

  const intl = useIntl();

  const infohandleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    infosetExpanded(isExpanded ? panel : false);
  };

  const [infobaropen, infoBarOpen] = React.useState(false);

  const infoBarClickOpen = () => {
    infoBarOpen(true);
  };

  const infoBarClose = () => {
    infoBarOpen(false);
    infosetExpanded(false);
    setInfoDocument(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span style={{ display: "flex" }}>
        {" "}
        {/* <a className="infoIcon" onClick={handleOpen}>
          <HomeOutlinedIcon />
          &nbsp;
          {intl.formatMessage({ id: "app.return-to-dashboard" })}
          &nbsp;&nbsp;&nbsp;
        </a> */}
        <button className="infoIcon" onClick={infoBarClickOpen}>
          <span className="info-icon">i</span> Info
        </button>
      </span>

      <Dialog
        open={infobaropen}
        onClose={infoBarClose}
        className="sidebar-modal small-modal"
      >
        <div className="sidepanel">
          <div className="sidepanel__header">
            <h3>
              {intl.formatMessage({
                id: "app.titles.information.resources.strategic-plan",
              })}
            </h3>
            <button onClick={infoBarClose}>
              <Icon icon="cross" iconSize={12} />
            </button>
          </div>
          <div className="sidepanel__content">
            <div className="info-list">
              <ul>
                <li>
                  <a
                    // href="#"
                    onClick={() =>
                      setInfoDocument(
                        intl.formatMessage({
                          id: "app.sci-writing.infobar.what-is-sci-writing",
                        })
                      )
                    }
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({
                      id: "app.sci-writing.infobar.what-is-sci-writing",
                    })}
                  </a>
                </li>
                <li>
                  <a
                    // href="#"
                    onClick={() =>
                      setInfoDocument(
                        intl.formatMessage({
                          id:
                            "app.sci-writing.infobar.how-do-we-take-notes-during-team-meetings",
                        })
                      )
                    }
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({
                      id:
                        "app.sci-writing.infobar.how-do-we-take-notes-during-team-meetings",
                    })}
                  </a>
                </li>
                <li>
                  <a
                    // href="#"
                    onClick={() =>
                      setInfoDocument(
                        intl.formatMessage({
                          id:
                            "app.sci-writing.infobar.note-not-linked-to-team-meeting-event-agenda",
                        })
                      )
                    }
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>

                    {intl.formatMessage(
                      {
                        id:
                          "app.sci-writing.infobar.note-not-linked-to-team-meeting-event-agenda",
                      },
                      { br: <br /> }
                    )}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {infoDocument ===
          intl.formatMessage({
            id: "app.sci-writing.infobar.what-is-sci-writing",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id: "app.sci-writing.infobar.what-is-sci-writing",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content text-lg leading-8">
              {intl.formatMessage({
                id: "app.sci-writing.infobar.what-is-sci-writing-content",
              })}
            </div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id:
              "app.sci-writing.infobar.how-do-we-take-notes-during-team-meetings",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage({
                  id:
                    "app.sci-writing.infobar.how-do-we-take-notes-during-team-meetings",
                })}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content text-lg leading-8">
              {intl.formatMessage(
                {
                  id:
                    "app.sci-writing.infobar.how-do-we-take-notes-during-team-meetings-content",
                },
                {
                  i: (chunks) => <i>{chunks}</i>,
                }
              )}
            </div>
          </div>
        )}

        {infoDocument ===
          intl.formatMessage({
            id:
              "app.sci-writing.infobar.note-not-linked-to-team-meeting-event-agenda",
          }) && (
          <div className="sidepanel info-panel-detail">
            <div className="sidepanel__header">
              <h3>
                <button onClick={() => setInfoDocument(false)}>
                  <Icon icon="arrow-left" iconSize={12} />
                </button>{" "}
                {intl.formatMessage(
                  {
                    id:
                      "app.sci-writing.infobar.note-not-linked-to-team-meeting-event-agenda",
                  },
                  { br: "" }
                )}
              </h3>
              <button onClick={infoBarClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content text-lg leading-8">
              {intl.formatMessage(
                {
                  id:
                    "app.sci-writing.infobar.note-not-linked-to-team-meeting-event-agenda-content",
                },
                {
                  i: (chunks) => <i>{chunks}</i>,
                }
              )}
            </div>
          </div>
        )}
      </Dialog>

      <Dialog
        className="delete-strategyPlan-popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="modalBody text-center">
          <div className="iconDiv">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="32"
              viewBox="0 0 34 32"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0255 2.2964C10.7067 8.49065 4.08162 20.8578 0.907995 26.7809C0.347745 27.8271 0.37812 29.0905 0.986745 30.1087C1.5965 31.1268 2.69675 31.75 3.8825 31.75C10.376 31.75 23.624 31.75 30.1175 31.75C31.3032 31.75 32.4035 31.1268 33.0132 30.1087C33.6219 29.0905 33.6522 27.8271 33.092 26.7809L19.9745 2.2964C19.3872 1.19953 18.2442 0.514404 17 0.514404C15.7557 0.514404 14.6127 1.19953 14.0255 2.2964ZM16.0089 3.3584C16.2046 2.99278 16.5849 2.7644 17 2.7644C17.4151 2.7644 17.7954 2.99278 17.9911 3.3584L31.1086 27.844C31.2954 28.1928 31.2852 28.6135 31.0827 28.9533C30.8791 29.2919 30.5124 29.5 30.1175 29.5H3.8825C3.48762 29.5 3.12087 29.2919 2.91725 28.9533C2.71475 28.6135 2.70462 28.1928 2.89137 27.844L16.0089 3.3584ZM17 23.875C17.9315 23.875 18.6875 24.631 18.6875 25.5625C18.6875 26.494 17.9315 27.25 17 27.25C16.0685 27.25 15.3125 26.494 15.3125 25.5625C15.3125 24.631 16.0685 23.875 17 23.875ZM15.875 9.25003V20.5C15.875 21.121 16.379 21.625 17 21.625C17.621 21.625 18.125 21.121 18.125 20.5V9.25003C18.125 8.62903 17.621 8.12503 17 8.12503C16.379 8.12503 15.875 8.62903 15.875 9.25003Z"
                fill="black"
              />
            </svg>
          </div>
          <h3>
            {intl.formatMessage({ id: "app.title.popup.button-close.hrading" })}
          </h3>
          <p>
            {" "}
            {intl.formatMessage(
              { id: "app.title.popup.sci-writing-button-close.text" },
              { br: <br /> }
            )}
          </p>
        </div>
        <div className="button-group">
          <button onClick={handleClose} className="stroke-btn">
            {" "}
            {intl.formatMessage({ id: "app.title.stay-on-this-page" })}
          </button>
          <Link
            to={generatePath(AuthRoutes.YourPlan, {
              workspace: "dashboard" as any,
            } as any).toString()}
          >
            <button className="nj-btn">
              {" "}
              {intl.formatMessage({
                id: "app.title.popup.button-close.leave-without-saving",
              })}{" "}
            </button>
          </Link>
        </div>
      </Dialog>
    </>
  );
};

export default NotesInfoBar;
