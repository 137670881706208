/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { PlanStrategy, School } from '../../../../../../types';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import StrategyActions from "../strategy-actions/StrategyActions";
import CoreComponent from "../../../core-components/CoreComponents";
import StrategyDetails from "../../../strategic-view-details/StrategicDetails";
import StrategicResources from "../../../strategic-resources/StrategicResources";
import './StrategyItem.scss';
import { useDispatch, useSelector } from "react-redux";
import { getDistrictById } from "../../../../../../store/districts/actions";
import { isDistrictPlan } from "../../../../../../store/plans/selectors";

type OwnProps = {
  strategy: PlanStrategy;
  inGoalView?: boolean;
  schools?: School[];
};

type Props = OwnProps;

const StrategyItem: FunctionComponent<Props> = (props) => {
  const { strategy, inGoalView, schools } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const schoolName = useMemo(() => {
    return schools?.find(s => s.id === strategy.strategy.school)?.name
  }, [schools, strategy.strategy.school]);

  const districtName = useSelector((s) => s.districts.selectedDistrict?.name);
  const districtId = strategy.strategy.district;

  useEffect(() => {
    if (districtId) {
      dispatch(getDistrictById.request(Number(districtId)));
    }
  }, [dispatch, districtId]);
  const headingStyle = {
    width: '70%',
  };//width added
  return (
    <>
      {!inGoalView ?  
          <li>
            <div className="strategies-content__list2-head">
            <h3 style={headingStyle}>
              {strategy.strategy.name}
              {strategy.strategy.school &&
                <span>{intl.formatMessage({ id: "app.custom-strategy" }, { school: schoolName })}</span>}
              {strategy.strategy.district &&
                <span className="custom-strategy-district">{intl.formatMessage({ id: "app.custom-strategy" }, { school: districtName })}</span>}
            </h3>
            <ul>
                <StrategyActions item={strategy} />
              </ul>
            </div>

            <div className="strategies-content__list-content">
              <div className="strategies-content__list-content--link">
                <ul>
                  <li><CoreComponent strategy={strategy.strategy}  school={schoolName}/></li>
                  <li><StrategyDetails strategy={strategy.strategy} /></li>
                  <li><StrategicResources strategy={strategy.strategy} /> </li>
                </ul>
              </div>
              {
                (strategy.strategy.domains.length > 0) && (
                  <div className="strategies-content__list-content--domain">
                    <label>
                      {intl.formatMessage({
                        id: "app.strategies.linked-domains",
                      })}
                    </label>
                    <ul>
                      {
                        strategy.strategy.domains.map((domain: any) => {
                          return <li key={domain.id} style={{ borderLeft: `5px solid ${domain.color_hex}` }}>{domain.name}</li>;
                        })
                      }
                    </ul>
                  </div>
                )
              }
            </div>
          </li>
        
        : <>
          <div className="strategies-goal_above-line">
            <div className="strategies-content__list2-head">
              <div className="accordionbar__headaing">
                <h3>
                  {strategy.strategy.name}
                  {strategy.strategy.school &&
                    <span>{intl.formatMessage({ id: "app.custom-strategy" }, { school: schoolName })}</span>}
                  {strategy.strategy.district &&
                    <span className="custom-strategy-district">{intl.formatMessage({ id: "app.custom-strategy" }, { school: districtName })}</span>}
                </h3>
              </div>
            </div>
          </div>
        </>}
        
    </>

  )
};

export default StrategyItem;
