import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch} from "react-redux";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import TooltipComponent from "../../tour/TooltipComponent";
import { DistrictSetupTaskKey } from "../../../types";
import useUserRole from "../../../helpers/hooks/useUserRole";
import useUserPlanAssociation from "../../../helpers/hooks/useUserPlanAssociation";
import { markStepCompleted, updateMakingProgressTourTimeWatched } from "../../../store/tours/actions";
import { isDistrictPlan } from "../../../store/plans/selectors";

type OwnProps = {};

type Props = OwnProps;

const SetUpCalendarTour: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isActiveDistrictPlan = useSelector(isDistrictPlan);
  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const { isSuperintendent, isDCC } = useUserRole();

  const {
    planDistrictId,
    planSchoolId,
    isSchoolPlanPrincipal,
    isSingleSchoolDistrict,
  } = useUserPlanAssociation();

  useEffect(() => {
    if (setupCompleted == null || setupCompleted) {
      setShow(false);
    } else {
      const interval = setTimeout(
        //todo remove when react tour will calculate correctly position
        () =>
          setShow(
           
            planSchoolId
              ? !tours[DistrictSetupTaskKey.SetupSchoolCalendar]?.[
                planSchoolId
              ]
              :  (planDistrictId && !(isDCC || isSuperintendent)) ? !tours[DistrictSetupTaskKey.SetupDistrictCalendar]: 
                  !tours[DistrictSetupTaskKey.SetupDistrictCalendar] &&
                  (isDCC || isSuperintendent) &&
                  !!tours[DistrictSetupTaskKey.InviteOtherMembers]
          ),
        400
      );
      return () => clearInterval(interval);
    }
  }, [
    planSchoolId,
    isSchoolPlanPrincipal,
    isDCC,
    isSuperintendent,
    tours,
    setupCompleted,
    isSingleSchoolDistrict,
  ]);

  const steps = useMemo((): Step[] => {
    return [
      {
        target: '[data-tour="setup-calendar-button"]',
        content: intl.formatMessage({
          id: "app.tours.setup_calendar",
        }),
        disableBeacon: true,
      },
      {
        target: '[data-tour="setup-calenda-step-2-button"]',
        content: intl.formatMessage({
          id: "app.tours.setup_calendar_step_2",
        }),
        disableBeacon: true,
      },
    ];
  }, []);

  const handleJoyrideAction = (data: CallBackProps) => {
    if(data.step.target === '[data-tour="setup-calenda-step-2-button"]' && data.action === 'prev'){
      setShow(false);
      isActiveDistrictPlan ?dispatch(markStepCompleted({key: DistrictSetupTaskKey.SetupDistrictCalendar,})):dispatch(markStepCompleted({
        key: DistrictSetupTaskKey.SetupSchoolCalendar,
        schoolId: planSchoolId,
      }))
    }
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      setShow(false); 
      isActiveDistrictPlan ?dispatch(markStepCompleted({key: DistrictSetupTaskKey.SetupDistrictCalendar,})):dispatch(markStepCompleted({
        key: DistrictSetupTaskKey.SetupSchoolCalendar,
        schoolId: planSchoolId,
      })) 
    }
  };

  return (
    <ReactJoyride
      run={show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
      // locale={{
      //   next: { title: intl.formatMessage({ id: "app.titles.next" }) },
      // }}
    />
  );
};

export default SetUpCalendarTour;
