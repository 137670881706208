import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  Domain,
  DomainComparison,
  DomainQuestionsResponses,
  SurveyDemographic,
} from "../../../types";
import SurveyReportNonIdealState from "./non-ideal-states/SurveyReportNonIdealState";
import * as _ from "lodash";
import DomainTitleRankingComparisonItem from "./survey-comparison-table-row/DomainTitleRankingComparisonItem";
import { COMPARISON_COLORS } from "../tab/SurveyComparisonTab";
import { fillDisplayedDataDemographicColumn } from "./utils";
import RedactedDomainsPopoverItem from "./survey-comparison-table-row/RedactedDomainsPopoverItem";

type OwnProps = {
  demographics: SurveyDemographic[];
  surveyComparison: DomainComparison[];
  showNonIdealState?: boolean;
};

type Props = OwnProps;

export type DisplayedRankingViewType = {
  domain?: Domain;
  isRedacted: boolean;
  domains?: Domain[];
  demographic?: string;
  mean_score?: number;
  not_enough_data?: boolean;
  mean_scores?: number[];
  not_enough_datas?: boolean[];
};

const DomainTitleRankingViewComparisonTable: React.FC<Props> = (props) => {
  const { demographics, surveyComparison, showNonIdealState } = props;

  const intl = useIntl();

  const respondentsComparisonByDemographic = useMemo(() => {
    return _.chain(surveyComparison)
      .filter((sc) => {
        return !sc.domain.domain_answer?.is_negative;
      })
      .flatMap((o) =>
        _.chain(o.respondents)
          .entries()
          .filter(
            ([demographic, responses]: [
              string,
              DomainQuestionsResponses | undefined
            ]) => !!responses
          )
          .map(
            ([demographic, responses]: [
              SurveyDemographic,
              DomainQuestionsResponses
            ]) => ({
              domain: o.domain,
              mean_score: responses?.mean_score ?? 0,
              demographic: demographic,
              not_enough_data: responses?.not_enough_data,
            })
          )
          .value()
      )
      .groupBy("demographic")
      .toPairs()
      .map(([demographic, response]) => [
        demographic,
        _.orderBy(response, "mean_score", "desc"),
      ])
      .fromPairs()
      .value();
  }, [surveyComparison]);

  const numberOfColumns = useMemo(() => {
    return demographics?.length;
  }, [demographics]);

  const displayedData: Array<
    Array<DisplayedRankingViewType | undefined>
  > = useMemo(() => {
    let resultArray: Array<Array<DisplayedRankingViewType | undefined>> = [];
    for (let i = 0; i < demographics?.length; i++) {
      resultArray.push([]);
    }
    demographics?.forEach((d, index) => {
      resultArray = fillDisplayedDataDemographicColumn(
        resultArray,
        index,
        respondentsComparisonByDemographic[d]
      );
    });
    return resultArray;
  }, [respondentsComparisonByDemographic, demographics]);

  const rowsArray = useMemo(() => {
    return _.range(_.max(displayedData?.map((i) => i?.length)) ?? 0);
  }, [displayedData]);

  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      className={"survey-comparison-table"}
    >
      <thead>
        <tr>
          <th colSpan={numberOfColumns}>
            {intl.formatMessage({
              id: "app.survey-comparison.domain-title-ranking-view",
            })}
          </th>
        </tr>
        <tr>
          {demographics?.map((item, index) => (
            <th key={index} className="text-white font-light text-xl">
              {intl.formatMessage({
                id: `app.filters.respondent-type.${item}`,
              })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {showNonIdealState || !rowsArray?.length ? (
          <tr>
            <td colSpan={numberOfColumns}>
              <SurveyReportNonIdealState />
            </td>
          </tr>
        ) : (
          <>
            {rowsArray.map((rowIndex) => (
              <tr key={rowIndex}>
                {demographics?.map((d, demographicIndex) => (
                  <td key={demographicIndex}>
                    {displayedData[demographicIndex]?.[rowIndex]?.isRedacted ? (
                      <RedactedDomainsPopoverItem
                        domains={
                          displayedData[demographicIndex]?.[rowIndex]?.domains
                        }
                        means={
                          displayedData[demographicIndex]?.[rowIndex]
                            ?.mean_scores
                        }
                        not_enough_datas={
                          displayedData[demographicIndex]?.[rowIndex]
                            ?.not_enough_datas
                        }
                      />
                    ) : !displayedData[demographicIndex]?.[rowIndex]?.domain ? (
                      <></>
                    ) : (
                      <DomainTitleRankingComparisonItem
                        domain={
                          displayedData[demographicIndex]?.[rowIndex]?.domain!
                        }
                        color={
                          displayedData[demographicIndex]?.[rowIndex]?.domain
                            ?.color_hex
                            ? displayedData[demographicIndex]?.[rowIndex]
                                ?.domain?.color_hex
                            : COMPARISON_COLORS[
                                (displayedData[demographicIndex]?.[rowIndex]
                                  ?.domain?.id ?? 0) % COMPARISON_COLORS.length
                              ]
                        }
                        mean={
                          displayedData[demographicIndex]?.[rowIndex]
                            ?.mean_score
                        }
                        not_enough_data={
                          displayedData[demographicIndex]?.[rowIndex]
                            ?.not_enough_data
                        }
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};

export default DomainTitleRankingViewComparisonTable;
