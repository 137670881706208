import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  LookForItem,
  LookForItemStatus,
  RecurringStatusEnum,
} from "../../../../../types";
import { Button, Collapse, Icon, TextArea, Tooltip } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from "moment";
import Select from "react-select";
import { smallSelectorStyles } from "../../../../common/selectors";

type OwnProps = {
  lookForStatus: Omit<
    LookForItemStatus<Omit<LookForItem, "id" | "created_at" | "updated_at">>,
    "id" | "created_at" | "updated_at"
  >;
  onLookForStatusFieldUpdate: <T extends string | RecurringStatusEnum>(
    key: keyof LookForItem | keyof LookForItemStatus,
    value: T
  ) => void;
  onLookForRemove: () => void;
};

type Props = OwnProps;

const LookForElement: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { lookForStatus, onLookForStatusFieldUpdate, onLookForRemove } = props;

  const [isCollapseDetailsOpen, setCollapseDetailsOpen] = useState<boolean>(
    !!lookForStatus.details
  );

  const recurringStatusesOptions = useMemo(() => {
    return Object.values(RecurringStatusEnum).map((r, i) => ({
      label: r,
      value: i,
      translate: intl.formatMessage({ id: `app.recurring-status.${r}` }),
    }));
  }, []);

  const handleLookForTextChange = (
    e: React.FocusEvent<HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    onLookForStatusFieldUpdate("text", value);
  };

  const handleDetailsTextChange = (
    e: React.FocusEvent<HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    onLookForStatusFieldUpdate("details", value);
  };

  const handleStartDateChange = (
    newDate: Date | null,
    isUserInput: boolean
  ) => {
    onLookForStatusFieldUpdate(
      "target_start_date",
      moment(newDate)?.toISOString()
    );
  };

  const handleEndDateChange = (newDate: Date | null, isUserInput: boolean) => {
    onLookForStatusFieldUpdate(
      "target_end_date",
      moment(newDate)?.toISOString()
    );
  };

  const handleDetailsClick = () => {
    setCollapseDetailsOpen(!isCollapseDetailsOpen);
  };

  return (
    <>
      <div className="flex-1">
        <div className="flex">
          <div className="flex flex-col flex-grow">
            {lookForStatus?.item?.original ? (
              <div className="flex flex-grow items-center">
                <Tooltip
                  className="mr-2"
                  content={intl.formatMessage({
                    id: "app.strategies.look-fors.standard-no-actions",
                  })}
                >
                  <Icon icon={"lock"} intent="warning" />
                </Tooltip>

                <div>{lookForStatus?.item?.text}</div>
              </div>
            ) : (
              <TextArea
                placeholder={intl.formatMessage({
                  id: "app.strategies.look-fors.placeholder",
                })}
                fill
                value={lookForStatus?.item?.text}
                onChange={handleLookForTextChange}
              />
            )}
            <Collapse isOpen={isCollapseDetailsOpen} className="mt-2">
              <TextArea
                placeholder={
                  intl.formatMessage({
                    id: "app.core-components.details",
                  }) + "..."
                }
                fill
                value={lookForStatus?.details}
                onChange={handleDetailsTextChange}
              />
            </Collapse>
          </div>
          <div className="ml-2">
            <div className="mb-2">
              <DateInput
                formatDate={(date) => date.toLocaleDateString(intl.locale)}
                parseDate={(str) => new Date(str)}
                placeholder={intl.formatMessage({
                  id: "app.titles.start-date",
                })}
                value={
                  lookForStatus?.target_start_date
                    ? moment(lookForStatus?.target_start_date)?.toDate()
                    : null
                }
                onChange={handleStartDateChange}
              />
            </div>
            <div className="mb-2">
              <DateInput
                formatDate={(date) => date.toLocaleDateString(intl.locale)}
                parseDate={(str) => new Date(str)}
                placeholder={intl.formatMessage({
                  id: "app.titles.end-date",
                })}
                value={
                  lookForStatus?.target_end_date
                    ? moment(lookForStatus?.target_end_date)?.toDate()
                    : null
                }
                onChange={handleEndDateChange}
              />
              <div className="mb-2 mt-2">
                <Select
                  value={recurringStatusesOptions.find(
                    (r) => lookForStatus?.recurring_status === r.label
                  )}
                  onChange={(value) =>
                    onLookForStatusFieldUpdate(
                      "recurring_status",
                      value?.label as string
                    )
                  }
                  options={recurringStatusesOptions}
                  getOptionLabel={(d) => d.translate}
                  getOptionValue={(d) => d.value.toString()}
                  styles={smallSelectorStyles}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <Button
                intent="primary"
                text={intl.formatMessage({
                  id: `app.titles.${
                    lookForStatus?.details ? "edit" : "add"
                  }-details`,
                })}
                title={intl.formatMessage({
                  id: `app.titles.${
                    lookForStatus?.details ? "edit" : "add"
                  }-details`,
                })}
                onClick={handleDetailsClick}
              />
            </div>
          </div>
        </div>
      </div>

      {!lookForStatus?.item?.original && (
        <Button
          className="ml-2"
          intent="danger"
          small
          icon="cross"
          minimal
          onClick={onLookForRemove}
        />
      )}
    </>
  );
};

export default LookForElement;
