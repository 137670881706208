import React, {
    FunctionComponent,
    useCallback,
    useMemo,
    useState,useEffect,useRef
  } from "react";
  import { Button, Card, Icon, Tag, Tooltip } from "@blueprintjs/core";
  import moment from "moment";
  import { SuperintendentRole, UserNotes, UserNotesViewableBy, UserRole } from "../../../../types";
  import { useIntl } from "react-intl";
  import { useDispatch, useSelector } from "react-redux";
  import { getCurrentUserId } from "../../../../store/auth/selectors";
  import useUserRole from "../../../../helpers/hooks/useUserRole";
  import { deleteNote, saveNoteToRedux, saveNoteToRedux1 } from "../../../../store/notes/actions";
  import {
    hideConfirmDialog,
    showConfirmDialog,
  } from "../../../../store/UIState/actions";
  import { useLoading } from "../../../../helpers/hooks/useLoading";
  import { isDistrictPlan } from "../../../../store/plans/selectors";
  import { getFullName } from "../../../../helpers/message-format-utils";
  
  import SciAddedNotes from "../../../pages/strategic-plan/strategies/edit-core-components/sci-writing/PublishedNotes/SciAddedNotes";
   
  type OwnProps = {
    note: UserNotes;
    onClick?: (note?: UserNotes) => void;
    isActive?: boolean;
    interactive?: boolean;
    activityName?: string;
  };
  
  type Props = OwnProps;
  
  const MAX_HEIGHT = 120;
  
  const NotesListItem: FunctionComponent<Props> = (props) => {
    const { note, onClick, isActive, interactive = true, activityName } = props;
  
    const intl = useIntl();
  
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(true);
    const userId = useSelector(getCurrentUserId);
  
    const { hasDataCoordinatorAccess, role, isDCC,hasDistrictPlanAccess,user } = useUserRole();
  
    const [collapseHeight, setCollapseHeight] = useState<number | undefined>(
      MAX_HEIGHT
    );
    const [showCollapseButton, setShowCollapseButton] = useState<boolean>(false);
  
    const isActiveDistrictPlan = useSelector(isDistrictPlan);
    
    const handleNoteClick = () => {
    setSelected(!selected);
      !!onClick && onClick(isActive ? undefined : note);
      const note1: UserNotes = note;
      
      dispatch(saveNoteToRedux1({ selectedNote: note1 }))
     
    };
    useEffect(() => {
      // Trigger the initial click for the first card when the component mounts
      handleNoteClick();
    }, []);
  
    const loading = useSelector((s) => s.notes.loading.deleteNote);
    const error = useSelector((s) => s.notes.errors.deleteNote);
    const savedNote = useSelector((s) => s.notes.dialogs.notesDialog.selectedNote);
  
    useLoading({ loading, error });
  
    const notesType = useMemo(() => {
      return intl.formatMessage(
        {
          id: `app.notes.types`,
        },
        { noteType: note.note_type }
      );
    }, [note.note_type]);
  
    const className = useMemo(() => {
      let name = "p-2 overflow-hidden";
  
      if (isActive) {
        name += ` bg-blue-100`;
      }
  
      if (interactive) {
        name += ` cursor-pointer`;
      }
  
      return name;
    }, [isActive, interactive]);
  
  
    const districtPlan = useSelector(isDistrictPlan);
  
    const showDeleteButton = useMemo(() => {
      const isMyNote = note.user && note.user.id === userId;
      return (
        isMyNote ||
        hasDataCoordinatorAccess ||
        (isActiveDistrictPlan
          ? false
          : isDCC || role === UserRole.DistrictStaff) ||
        (role === UserRole.DistrictStaff && 
          (user?.profile?.custom_role_name ===
              SuperintendentRole.AssistantSuperintendent ||
              user?.profile?.custom_role_name ===
                SuperintendentRole.DistrictLevelSupervisorOrDirectorOrCoordinator ||
              user?.profile?.custom_role_name === null)
          )
      );
    }, [
      note,
      userId,
      hasDataCoordinatorAccess,
      role,
      isDCC,
      isActiveDistrictPlan,
    ]);
  
    const handleRemoveNoteClick = (
      e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      e.stopPropagation();
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            dispatch(deleteNote.request({ id: note.id, planId: note.plan }));
            dispatch(hideConfirmDialog());
          },
          show: true,
          intent: "danger",
          text: intl.formatMessage(
            {
              id: "app.confirmation-dialogs.delete-note",
            },
            { type: notesType }
          ),
          icon: "trash",
          confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
        })
      );
    };
  
    const handleCollapseButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setCollapseHeight((height) => (height ? undefined : MAX_HEIGHT));
    };
  
    const getNoteTitle = useCallback(() => {
      return activityName || note.activity?.name || notesType;
    }, [notesType, activityName, note.activity]);
  
  return (
    <Card className={selected ? `${className} selected` : className} onClick={handleNoteClick}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxHeight: collapseHeight }}>
        <div style={{ flex: 1 }}>
          {/* Title */}
          <div style={{ fontWeight: 'bold', textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>{note.note_title}
            {/* Render text without calendar icon */}
          </div>
          {/* Last Edited By and Timestamp */}
          <div>
            <span>
              <p>{intl.formatMessage({ id: "app.titles.lastEdited-by" })}</p>
              {(note.user.is_active === false ? <i>{intl.formatMessage({ id: "app.titles.deactivated" })}</i> : ' ')}
              {note.user && (
                <span>
                  {getFullName(note.user)}
                </span>
              )}
            </span>
            {" | "}
            <span>
              {moment(note.updated_at).format("MM/DD/yy hh:mm A")}
            </span>
          </div>
        </div>
        {/* Calendar icon */}
        {note.is_calender_note && (
          <Icon icon="calendar" iconSize={20} />
        )}
      </div>
    </Card>
  );
};

  export default NotesListItem;
  