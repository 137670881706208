/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo, useState } from 'react'
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PlanStrategy } from '../../../../../types';
import { AppToaster } from '../../../../../helpers/toaster';
import { deletePlanStrategy } from '../../../../../store/plan-strategies/actions';
import './DeleteStrategyPlan.scss'
import { deleteStrategy } from '../../../../../store/strategies/actions';

type OwnProps = {
    planstrategy: PlanStrategy;
    activePlan?: number;
    handleClose: () => void;
};

type Props = OwnProps;

const DeleteStrategyPlan: FunctionComponent<Props> = ({ planstrategy, activePlan, handleClose }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();

    const strategyName = useMemo(() => planstrategy.strategy.name, [planstrategy]);

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        handleClose();
    };

    const onDeleteSuccess = () => {
        if (planstrategy.strategy.id) {
            dispatch(
                deleteStrategy.request({
                    strategyId: planstrategy.strategy.id,
                    onSuccess: () => {
                        handleClose();
                    },
                })
            );
        }
    }

    const handleDeleteStrategyPlan = () => {
        if (activePlan && !!planstrategy) {
            dispatch(
                deletePlanStrategy.request({
                    planStrategyId: planstrategy.id,
                    planId: activePlan,
                    onSuccess: () => {
                        AppToaster.show({
                            icon: "tick",
                            intent: "success",
                            message: intl.formatMessage(
                                { id: "app.plan-strategies.deleted-successfully" },
                                { name: (planstrategy as PlanStrategy)?.strategy?.name }
                            ),
                        });
                        (!planstrategy.strategy.is_global && planstrategy.strategy.school) && onDeleteSuccess() || (!planstrategy.strategy.is_global && planstrategy.strategy.district && window.location.pathname.includes("plan/resources")) &&
                            onDeleteSuccess();
                    }
                })
            );
        }

        setIsDialogOpen(false);
    }

    return (
        <>
            <a onClick={openDialog}> <Icon icon="trash" iconSize={14} /> Delete from Plan</a>

            <Dialog
                className="delete-strategyPlan-popup"
                open={isDialogOpen}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="cancel">
                    <button onClick={closeDialog}><Icon icon="cross" iconSize={18} /></button>
                </div>
                <Icon icon="trash" iconSize={30} />

                <h3>
                    {intl.formatMessage(
                        { id: "app.strategy.delete-dialogs.title" }
                    )}
                </h3>
                <p>
                    {intl.formatMessage(
                        { id: `app.confirmation-dialogs.delete-${(!planstrategy.strategy.is_global && planstrategy.strategy.school) ? 'custom-' : ''}strategy` },
                        {
                            br: <br />,
                            name: strategyName,
                        }
                    )}
                </p>
                <div className="button-group">
                    <button onClick={closeDialog} className="stroke-btn">{intl.formatMessage(
                        { id: "app.titles.cancel" }
                    )}</button>
                    <button onClick={handleDeleteStrategyPlan} className="nj-btn">{intl.formatMessage(
                        { id: "app.titles.delete" }
                    )}</button>
                </div>
            </Dialog>
        </>
    )
}

export default DeleteStrategyPlan
