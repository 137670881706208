import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  getCurrentUserIsDCC,
  getCurrentUserRole,
  getCurrentUserTeamRole,
} from "../../store/auth/selectors";
import { TeamRole, UserRole } from "../../types";
import { isDistrictPlan } from "../../store/plans/selectors";

type OwnProps = {
  // isSingleSchoolDistrict?: boolean;
  // forDistrictStaff?: boolean;
  // forTeamMember?: boolean;
  // forTeamChampion?: boolean;
  // isDCC?: boolean;
};

type Props = OwnProps;

const WelcomeIntro: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const userRole = useSelector(getCurrentUserRole);

  const teamRole = useSelector(getCurrentUserTeamRole);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const isDCC = useSelector(getCurrentUserIsDCC);

  const isSingleSchoolDistrict = useMemo(() => {
    return activePlan?.district?.is_single_school_district;
  }, [activePlan?.district?.is_single_school_district]);

  const {
    introMessageId,
    opportunitiesMessageId,
  }: {
    introMessageId: string;
    opportunitiesMessageId: string;
  } = useMemo(() => {
    if (userRole === UserRole.Superintendent) {
      return {
        introMessageId: isSingleSchoolDistrict
          ? "app.welcome-page.superintendent-single-school-welcome.introduction"
          : "app.welcome-page.superintendent-not-single-school-welcome.introduction",
        opportunitiesMessageId:
          "app.welcome-page.superintendent.introduction-menu",
      };
    }

    if (isDCC) {
      return {
        introMessageId: isSingleSchoolDistrict
          ? "app.welcome-page.DCC-single-school-welcome.introduction"
          : "app.welcome-page.DCC-not-single-school-welcome.introduction",
        opportunitiesMessageId: isSingleSchoolDistrict
          ? "app.welcome-page.DCC.introduction-menu.single-school"
          : "app.welcome-page.DCC.introduction-menu.not-single-school",
      };
    }

    if (
      userRole === UserRole.DistrictStaff ||
      (teamRole === TeamRole.TeamMember && isDistrictActivePlan)
    ) {
      return {
        introMessageId: "app.welcome-page.district-staff.introduction",
        opportunitiesMessageId:
          "app.welcome-page.district-staff.introduction-menu",
      };
    }

    if (teamRole === TeamRole.TeamChampion) {
      return {
        introMessageId: "app.welcome-page.team-champion.introduction",
        opportunitiesMessageId:
          "app.welcome-page.team-champion.introduction-menu",
      };
    }

    // for team member
    return {
      introMessageId: "app.welcome-page.team-member.introduction",
      opportunitiesMessageId: "app.welcome-page.team-member.introduction-menu",
    };
  }, [teamRole, userRole, isDCC, isSingleSchoolDistrict, isDistrictActivePlan]);
  return (
    <p>
      {intl.formatMessage(
        {
          id: introMessageId,
        },
        {
          introductionMenu: (
            <ul className="list-disc pl-10">
              <br />
              {intl.formatMessage(
                {
                  id: opportunitiesMessageId,
                },
                {
                  li: (chunks) => <li>{chunks}</li>,
                }
              )}
              <br />
            </ul>
          ),
          br: <br />,
          b: (chunks) => <b>{chunks}</b>,
        }
      )}
    </p>
  );
};

export default WelcomeIntro;
