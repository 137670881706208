import React, { useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Callout, Classes, Dialog, Icon } from "@blueprintjs/core";
import {
  hideInviteUsersDialog,
  inviteUsers,
} from "../../../../store/users/actions";
import {
  InviteFailReasonEnum,
  InviteStatus,
  Plan,
  TeamRole,
  UserInvite,
  UserRole,
  userRoles,
} from "../../../../types";
import InviteUsersTable from "../../district-users/InviteUsersTable";
import _ from "lodash";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import { useParams } from "react-router";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { InviteUserRequest } from "../../../../api/users/types";
import { AppToaster } from "../../../../helpers/toaster";
import { inviteTeamMembers } from "../../../../store/team-members/actions";
import { TeamMemberInvitation } from "../../../../api/team-members/types";
import { getCurrentUserDistrictId } from "../../../../store/auth/selectors";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import "./InviteUsersDialog.scss";

type OwnProps = {
  successInvitesHasDCC?: boolean;
  successInvitesNum?: number;
  failedInvites: UserInvite[];
  loading?: boolean;
  error?: Error;

  activePlan?: Plan;
  forAllSystem?: boolean;
};

type Props = OwnProps;

const InviteUsersDialog: React.FC<Props> = (props: Props) => {
  const {
    successInvitesHasDCC,
    successInvitesNum,
    failedInvites,
    loading,
    error,
    forAllSystem,

    activePlan,
  } = props;
  const intl = useIntl();

  const dispatch = useDispatch();

  const districtPlan = useSelector(isDistrictPlan);

  const authenticatedUserDistrict = useSelector(getCurrentUserDistrictId);

  const routeParams = useParams<{
    districtId?: string;
  }>();
  const {setupCompleted,districtSetupState: tours,} = useSelector((s) => s.tours);

  const show = useSelector((s) => s.users.showInviteUsersDialog);

  const isDCCPreselected = useSelector((s) => s.users.isDCCPreselected);

  const onSuccess = useSelector((s) => s.users.onSuccess);

  const [invitedUsers, setInvitedUsers] = useState<InviteUserRequest[]>([]);

  const [warning, setWarning] = useState<string | undefined>();

  const [dccWarning, setDCCWarning] = useState<string | undefined>();

  const defaultNewItem: InviteUserRequest = useMemo(() => {
    let value: InviteUserRequest = {
      local_id: _.uniqueId("local_id_"),
      email: "",
      first_name: "",
      last_name: "",
      schools: [],
      is_dcc: invitedUsers.length ? false : !!isDCCPreselected,
      is_setup_completed: forAllSystem ? true : undefined,
    };

    if (activePlan) {
      value = {
        ...value,
        schools: [activePlan.school.id],
        district: activePlan.district.id,
        team_role: TeamRole.TeamMember,
      };
    }

    return value;
  }, [activePlan, invitedUsers, isDCCPreselected, forAllSystem]);

  const handleFormShow = () => {
    setInvitedUsers([defaultNewItem]);
  };

  const showToaster = useCallback((message: string, icon: any, intent: any) => {
    AppToaster.show({
      message: message,
      icon: icon,
      intent: intent,
    });
  }, []);

  const onCloseConfirmed = () => {
    setInvitedUsers([]);
    setWarning(undefined);
    setDCCWarning(undefined);
    dispatch(hideInviteUsersDialog());
  };

  const handleClose = () => {
    if (
      invitedUsers.some(
        (i) => i.role || i.email || i.first_name || i.last_name || i.is_dcc
      )
    ) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            onCloseConfirmed();
            dispatch(hideConfirmDialog());
          },
          show: true,
          intent: "warning",
          text: intl.formatMessage({
            id: "app.confirmation-dialogs.invite-users-close-confirm",
          }),
          icon: "info-sign",
          confirmButtonText: intl.formatMessage({ id: "app.titles.yes" }),
          cancelButtonText: intl.formatMessage({ id: "app.titles.no" }),
        })
      );
    } else {
      onCloseConfirmed();
    }
  };

  const { role: activeUserRole } = useUserRole();

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (invitedUsers?.some((invite) => !invite.role)) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.invitation.validation.role-required",
        }),
      };
      return validationOption;
    }

    for (const invite of invitedUsers) {
      if (
        invitedUsers?.some(
          (i) => i?.local_id !== invite?.local_id && i?.email === invite?.email
        )
      ) {
        validationOption = {
          isValid: false,
          message: intl.formatMessage({
            id: "app.invitation.validation.emails-similar",
          }),
        };
        return validationOption;
      }
      if (forAllSystem && !userRoles.admins.some((r) => r === invite.role)) {
        if (invite.district === undefined) {
          validationOption = {
            isValid: false,
            message: intl.formatMessage({
              id: "app.invitation.validation.district-required",
            }),
          };
          return validationOption;
        }
      }

      if (
        userRoles.admins.some((r) => r === invite.role) &&
        (invite.district !== undefined || !!invite.schools?.length)
      ) {
        validationOption = {
          isValid: false,
          message: intl.formatMessage({
            id: "app.invitation.validation.admins-error",
          }),
        };
        return validationOption;
      }

      if (
        userRoles.districtAdmins.some((r) => r === invite.role) &&
        ((forAllSystem ? invite.district === undefined : false) ||
          !!invite.schools?.length)
      ) {
        validationOption = {
          isValid: false,
          message: intl.formatMessage({
            id: "app.invitation.validation.district-admins-error",
          }),
        };
        return validationOption;
      }

      switch (invite.role) {
        case UserRole.Principal: {
          if (invite?.schools?.length !== 1 && !districtPlan) {
            validationOption = {
              isValid: false,
              message: intl.formatMessage({
                id: "app.invitation.validation.only-one-school-required",
              }),
            };
          }
          break;
        }
        case UserRole.Staff:
        case UserRole.VicePrincipal: {
          if (!invite?.schools?.length && !districtPlan) {
            validationOption = {
              isValid: false,
              message: intl.formatMessage({
                id: "app.invitation.validation.school-required",
              }),
            };
          }
          break;
        }

        case UserRole.NJ_SCI_Leadership:
        case UserRole.DistrictAdmin: {
          if (!userRoles.admins.includes(activeUserRole as any)) {
            validationOption = {
              isValid: false,
              message: intl.formatMessage({
                id: "app.invitation.validation.permission-required",
              }),
            };
          }
          break;
        }

        default:
          break;
      }

      if (!validationOption.isValid) {
        break;
      }
    }

    return validationOption;
  };

  const currentUser = useSelector((s) => s.auth.authenticatedUser);
  const currentUserEmailDomain = currentUser?.email?.split("@").pop();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let isOtherDomain: boolean = false;
    isOtherDomain = invitedUsers.some((user) => {
      let userEmailDomain = user?.email?.split("@").pop();
      if (
        userEmailDomain !== undefined &&
        currentUserEmailDomain !== undefined &&
        userEmailDomain !== currentUserEmailDomain
      ) {
        return true;
      }
    });

    if (isOtherDomain) {
      // call if email domain are different from login user email
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            handleFormSubmitPost();
            dispatch(hideConfirmDialog());
          },
          show: true,
          intent: "warning",
          text: intl.formatMessage(
            {
              id: "app.users-table.send-invites-content",
            },
            {
              br: <br />,
            }
          ),
          icon: "info-sign",
          confirmButtonText: intl.formatMessage({
            id: "app.users-table.send-invites-yes",
          }),
          onCancel: () => {
            dispatch(hideConfirmDialog());
          },
          cancelButtonText: intl.formatMessage({
            id: "app.users-table.send-invites-no",
          }),
        })
      );
    } else {
      handleFormSubmitPost();
    }
  };

  const handleFormSubmitPost = () => {
    const { isValid, message } = validate();
    if (isValid) {
      if (activePlan) {
        dispatch(
          inviteTeamMembers.request({
            body: {
              planId: activePlan.id,
              users: invitedUsers.map<TeamMemberInvitation>(
                ({ local_id, role, ...rest }) => ({
                  ...rest,
                  profile_role: role!,
                })
              ),
            },
          })
        );
       !setupCompleted && localStorage.setItem("tourSteps","inviteTeamMembers")
      } else {
        dispatch(
          inviteUsers.request({
            invites: routeParams?.districtId
              ? invitedUsers.map(({ local_id, role, ...rest }) => ({
                  ...rest,
                  role: role,
                  district: !userRoles.admins.some((r) => r === role)
                    ? _.toNumber(routeParams.districtId)
                    : undefined,
                }))
              : invitedUsers.map(({ local_id, ...rest }) => ({
                  ...rest,
                  district: !userRoles.admins.some((r) => r === rest.role)
                    ? rest.district ?? authenticatedUserDistrict
                    : undefined,
                })),
          })
        );
      }
      setWarning(undefined);
    } else {
      setWarning(message);
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  useLoading({
    loading,
    error,
    onSuccess: () => {
      if (!!successInvitesNum) {
        showToaster(
          intl.formatMessage(
            { id: "app.invitation.invites-send" },
            { number: successInvitesNum }
          ),
          "tick",
          "success"
        );
      }
      if (failedInvites.length) {
        showToaster(
          intl.formatMessage(
            { id: "app.invitation.invites.failed" },
            { number: failedInvites.length }
          ),
          "warning-sign",
          "warning"
        );
      }
      if (!!failedInvites?.length) {
        setInvitedUsers(
          failedInvites.map((invite) => ({
            local_id: _.uniqueId("local_id_"),
            email: invite.email,
            first_name: invite.first_name,
            last_name: invite.last_name,
            role: invite.role,
            schools: invite.schools,
            district: invite.district,
            fail_reason:
              invite.status === InviteStatus.Sent ||
              invite.status === InviteStatus.Accepted
                ? InviteFailReasonEnum.EMAIL_EXIST
                : InviteFailReasonEnum.SEND_MAIL_ERROR,
            is_dcc: invite.is_dcc,
          }))
        );
        setWarning(intl.formatMessage({ id: "app.invitation.failed-message" }));
      } else {
        successInvitesHasDCC && onSuccess && onSuccess();
        onCloseConfirmed();
      }
    },
  });

  return (
    <>
    <Dialog
      className={"enviteUserModal"}
      isOpen={show}
      onOpening={handleFormShow}
      onClose={handleClose}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      // icon={<Icon icon="user" iconSize={24} />}
      title={intl.formatMessage({
        id: forAllSystem
          ? "app.titles.invite-admins"
          : districtPlan
          ? "app.title.plan.add-new-user-district-team"
          : "app.title.plan.add-new-user-school-team",
      })}
    >
      {
        <p className="ml-6 mr-6 mt-5">
          {intl.formatMessage(
            {
              id: districtPlan
                ? "app.invite-instructions-district"
                : "app.invite-instructions-school",
            },
            {
              br: <br />,
              b: (chunks) => <b>{chunks}</b>,
              i: (chunks) => <i>{chunks}</i>,
            }
          )}
        </p>
      }
      <form
        ref={formRef}
        id="invites-form"
        onSubmit={handleFormSubmit}
        className={`mb-5 bg-white p-5`}
      >
        {dccWarning && (
          <div className="my-3 px-3">
            <Callout intent="warning">{dccWarning}</Callout>
          </div>
        )}
      
        <InviteUsersTable
          forDistrictPlan={districtPlan}
          forPlan={!!activePlan}
          defaultNewItem={defaultNewItem}
          forAllSystem={forAllSystem}
          formRef={formRef}
          data={invitedUsers}
          setData={setInvitedUsers}
          setWarning={setDCCWarning}
          disableSort={true}
        />
        {warning && (
          <div className="my-3 px-3">
            <Callout intent="warning">{warning}</Callout>
          </div>
        )}
      </form>

          {districtPlan&& <hr style={{width:"800px",marginLeft:"5%"}}/> }

      {
       districtPlan && <p className="ml-6 mr-6 mt-5">
          {intl.formatMessage(
            {
              id: "app.invite-instructions-district-note"
            },
            {
              br: <br />,
              b: (chunks) => <b>{chunks}</b>,
              i: (chunks) => <i>{chunks}</i>,
            }
          )}
        </p>
      }
        
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-end mt-4 memberTbl_head memberTbl_button">
          {/* <Button
            className="w-1/4"
            onClick={handleClose}
            type={"reset"}
            disabled={loading}
          >
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button> */}
          {/* <Button
            loading={loading}
            form={"invites-form"}
            className="w-1/4"
            intent="primary"
            type="submit"
          >
            {intl.formatMessage({
              id: "app.users-table.send-invites",
            })}
          </Button> */}
          <button
          className="nj-btn"
          form={"invites-form"}
          type="submit"
          >
          {intl.formatMessage({
              id: "app.users-table.send-invites",
            })}
          </button>
        </div>
      </div>
    </Dialog>
    </>
  );
};

export default InviteUsersDialog;
