import _ from "lodash";
import React, { FunctionComponent, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import useUserRole from "../../../helpers/hooks/useUserRole";
import {
  StaffRole,
  SuperintendentRole,
  TeamRole,
UserRole,
} from "../../../types";
import { smallSelectorStyles } from "../../common/selectors";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";



type OwnProps = {
  role?: UserRole;
  staffRole?: StaffRole; // if role === staff
  onChange: (role: [UserRole, string | undefined]) => void;
  isLoading?: boolean;
  isSmallSelector: boolean;
  isAdminLevelSelector?: boolean;
  menuPortalTargetSelector?: string;
};

type Props = OwnProps;

const RoleSelector: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const {
    role,
    staffRole,
    isLoading,
    onChange,
    isSmallSelector = true,
    isAdminLevelSelector,
    menuPortalTargetSelector,
  } = props;

  const selectedUser = useSelector((s) => s.users.selectedUser);

  const { role: activeUserRole, teamRole, isDCC } = useUserRole();

  // const [toRole, setToRole] = useUserRole();
  
  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const allRoles = useMemo(() => {
    return Object.values(UserRole);
  }, []);

  const roles: {
    label: string;
    value: [UserRole, string | undefined];
  }[] = useMemo(() => {
    if (isAdminLevelSelector) {
      return [
        UserRole.Superadmin,
        UserRole.NJ_SCI_Leadership,
        UserRole.NJ_SCI_TaProvider,
      ].map((role) => ({
        label: intl.formatMessage({ id: "app.roles" }, { role: role }),
        value: [role] as any,
      }));
    }
    const fromRole =
      isDCC ||
      activeUserRole === UserRole.NJ_SCI_TaProvider ||
      !isAdminLevelSelector
        ? UserRole.Superintendent
        : (activeUserRole as UserRole);
    let rolesArray = _.chain(allRoles)
      .slice(
        // cuts the array of roles from the role of the current user to next-to-last role, excluding the last role (staff)
        activeUserRole ? allRoles.indexOf(fromRole) : 0,
        -1
      )
      .map((role) => {
        return {
          label: intl.formatMessage({ id: "app.roles" }, { role: role }),
          value: [role] as any,
        };
      })
      .concat(
        Object.values(StaffRole).map((staffRole) => {
          return {
            label: staffRole,
            value: [UserRole.Staff, staffRole] as any,
          };
        })
      )
      .value();
    if (
      (activeUserRole &&
        [
          UserRole.Superadmin,
          UserRole.NJ_SCI_Leadership,
          UserRole.NJ_SCI_TaProvider,
          UserRole.Superintendent,
          UserRole.DistrictStaff,
        ].includes(activeUserRole)) ||
      isDCC
    ) {
      const hideSuperintendentRole =
        isDCC && activeUserRole !== UserRole.Superintendent;
      rolesArray.splice(
        rolesArray.findIndex((r) => r.value[0] === UserRole.Superintendent) +
          (hideSuperintendentRole ? 0 : 1),
        hideSuperintendentRole ? 1 : 0, //if DCC remove Superintendent role
        ...Object.values(SuperintendentRole).map((superRole) => ({
          label: superRole,
          value: [UserRole.DistrictStaff, superRole] as any,
        }))
      );
    }
    if (isDistrictActivePlan && !selectedUser) {
      // do not allow inviting users less than Principals for District Teams,
      const principalRoleIndex = rolesArray.findIndex(
        (r) => r.value[0] === UserRole.Principal
      );
      if (principalRoleIndex != -1) {
        // isSmallSelector is an existing variable - we have used it to determine which popup modal is opened
        // accordingly we are removing the principal option from array if the modal is invite modal we can check this by - if(isSmallSelector)
        if (isSmallSelector) rolesArray.splice(principalRoleIndex);
        else rolesArray.splice(principalRoleIndex + 1);
      }
    }

    if (
      !isDistrictActivePlan &&
      (teamRole === TeamRole.TeamChair ||
        teamRole === TeamRole.TeamChampion || 
        activeUserRole === UserRole.Principal ||
        activeUserRole === UserRole.Superintendent ||
        isDCC)
    ) {
      rolesArray = rolesArray.filter(
        (roleData) =>
          ![UserRole.Superintendent, UserRole.DistrictStaff, UserRole.Principal].includes(
            roleData.value[0]
          )
      );
    }
    return _.uniqBy(rolesArray, (opt) => opt.value);
  }, [activeUserRole, teamRole, allRoles, isDistrictActivePlan]);

  const selectedValue = useMemo(() => {
    return role
      ? roles.find((r) => {
          if (staffRole != null) {
            return r.value.includes(staffRole);
          } else {
            return r.value.includes(role) && !r.value[1];
          }
        }) || (staffRole != null ? roles[roles.length - 1] : undefined)
      : undefined;
  }, [role, staffRole, roles]);

  // const handleToRole =(event: SelectChangeEvent) => {
  //   onChange(event.target.value);
  //   };

  console.log(roles,'roles')
  return (
    <Select
      id="role-selector"
      isLoading={isLoading}
      styles={isSmallSelector ? smallSelectorStyles : undefined}
      options={roles}
      value={selectedValue}
      placeholder={role?intl.formatMessage({ id: "app.roles" }, { role: role }):undefined}
      onChange={(value) => {
        onChange(value?.value);
      }}
      
      {...(isSmallSelector && { menuPlacement: "auto" })}
      menuPortalTarget={
        menuPortalTargetSelector
          ? (document.querySelector(menuPortalTargetSelector) as any)
          : undefined
      } //to not cut the menu when using select inside the table with overflow:hidden
    />

    // <FormControl fullWidth size="small">
    //       <InputLabel id="demo-select-small-label">{role?intl.formatMessage({ id: "app.roles" }, { role: role }):undefined}</InputLabel>
    //       <Select
    //         labelId="demo-select-small-label"
    //         id="demo-select-small"
    //         value={selectedValue}
    //         label={role?intl.formatMessage({ id: "app.roles" }, { role: role }):undefined}
    //         onChange={(value) => {
    //               onChange(value as any);
    //             }}
    //       >
    //         {
    //         roles.map((role) => {
    //           return <MenuItem value={role as any}>{role.label}</MenuItem>
    //           })
    //         }
    //       </Select>
    //     </FormControl>
  );
};

export default RoleSelector;
