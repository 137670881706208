import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getMeetingsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getMeetings.request)),
    switchMap((action) =>
      from(api.meetings.getMeetings(action.payload)).pipe(
        map(actions.getMeetings.success),
        catchError((error) => of(actions.getMeetings.failure(error)))
      )
    )
  );
};

export const getMeetingByIdEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getMeetingById.request)),
    switchMap((action) =>
      from(
        api.meetings.getMeetingById(action.payload.id, action.payload.planId)
      ).pipe(
        map(actions.getMeetingById.success),
        catchError((error) => of(actions.getMeetingById.failure(error)))
      )
    )
  );
};

export const addMeetingEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addMeeting.request)),
    switchMap((action) =>
      from(api.meetings.addMeeting(action.payload)).pipe(
        map(actions.addMeeting.success),
        catchError((error) => of(actions.addMeeting.failure(error)))
      )
    )
  );
};

export const updateMeetingEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateMeeting.request)),
    switchMap((action) =>
      from(api.meetings.updateMeeting(action.payload)).pipe(
        map(actions.updateMeeting.success),
        catchError((error) => of(actions.updateMeeting.failure(error)))
      )
    )
  );
};

export const deleteMeetingEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteMeeting.request)),
    switchMap((action) => {
      const [meetingId, onSuccess]: [
        number,
        Function | undefined
      ] = action.payload;
      return from(api.meetings.deleteMeeting(meetingId)).pipe(
        mergeMap(() =>
          from(
            [actions.deleteMeeting.success(meetingId)],
            onSuccess && onSuccess()
          )
        ),
        catchError((error) => of(actions.deleteMeeting.failure(error)))
      );
    })
  );
};
