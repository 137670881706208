import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Button, NonIdealState } from "@blueprintjs/core";
import { showCommonTextUpsertDialog } from "../../../store/common-text/actions";
import { useDispatch } from "react-redux";

type OwnProps = {};

type Props = OwnProps;

const NoCommonTexts: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    dispatch(showCommonTextUpsertDialog());
  };
  return (
    <NonIdealState
      icon="highlight"
      title={intl.formatMessage({ id: "app.common-text.empty" })}
      description={intl.formatMessage({
        id: "app.common-text.empty.tip",
      })}
      action={
        <Button
          large
          intent={"primary"}
          text={intl.formatMessage({
            id: "app.common-text.create-new",
          })}
          className="px-3 capitalize"
          onClick={handleDialogOpen}
        />
      }
    />
  );
};

export default NoCommonTexts;
