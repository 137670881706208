import { District } from "../../types";
import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";

type ActionName =
  | "getDistricts"
  | "addDistrict"
  | "updateDistrict"
  | "getDistrictById";

export type DistrictsState = {
  districts: District[];
  selectedDistrict: District | undefined;
  showDistrictCreationDialog?: boolean;
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: DistrictsState = {
  districts: [],
  selectedDistrict: undefined,
  loading: {},
  errors: {},
};

export const districtsReducer = createReducer<DistrictsState, RootAction>(
  initialState
)
  //get districts
  .handleAction(
    actions.getDistricts.request,
    (state, action): DistrictsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDistricts: true,
      },
      errors: {
        ...state.errors,
        getDistricts: undefined,
      },
    })
  )
  .handleAction(
    actions.getDistricts.success,
    (state, action): DistrictsState => ({
      ...state,
      districts: action.payload,
      loading: {
        ...state.loading,
        getDistricts: false,
      },
    })
  )
  .handleAction(
    actions.getDistricts.failure,
    (state, action): DistrictsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDistricts: action.payload,
      },
      loading: {
        ...state.loading,
        getDistricts: false,
      },
    })
  )
  //add district
  .handleAction(
    actions.addDistrict.request,
    (state, action): DistrictsState => ({
      ...state,
      loading: {
        ...state.loading,
        addDistrict: true,
      },
      errors: {
        ...state.errors,
        addDistrict: undefined,
      },
    })
  )
  .handleAction(
    actions.addDistrict.success,
    (state, action): DistrictsState => ({
      ...state,
      districts: [...state.districts, action.payload],
      loading: {
        ...state.loading,
        addDistrict: false,
      },
    })
  )
  .handleAction(
    actions.addDistrict.failure,
    (state, action): DistrictsState => ({
      ...state,
      errors: {
        ...state.errors,
        addDistrict: action.payload,
      },
      loading: {
        ...state.loading,
        addDistrict: false,
      },
    })
  )
  // update district
  .handleAction(
    actions.updateDistrict.request,
    (state, action): DistrictsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateDistrict: true,
      },
      errors: {
        ...state.errors,
        updateDistrict: undefined,
      },
    })
  )
  .handleAction(
    actions.updateDistrict.success,
    (state, action): DistrictsState => ({
      ...state,
      districts: state.districts.map((d) =>
        d.id === action.payload.id ? action.payload : d
      ),
      loading: {
        ...state.loading,
        updateDistrict: false,
      },
    })
  )
  .handleAction(
    actions.updateDistrict.failure,
    (state, action): DistrictsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateDistrict: action.payload,
      },
      loading: {
        ...state.loading,
        updateDistrict: false,
      },
    })
  )
  // district creation dialog
  .handleAction(
    actions.showDistrictDialog,
    (state, action): DistrictsState => ({
      ...state,
      selectedDistrict: action.payload,
      showDistrictCreationDialog: true,
    })
  )
  .handleAction(
    actions.hideDistrictDialog,
    (state, action): DistrictsState => ({
      ...state,
      selectedDistrict: undefined,
      showDistrictCreationDialog: false,
    })
  )
  //get district by id
  .handleAction(
    actions.getDistrictById.request,
    (state, action): DistrictsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDistrictById: true,
      },
      errors: {
        ...state.errors,
        getDistrictById: undefined,
      },
    })
  )
  .handleAction(
    actions.getDistrictById.success,
    (state, action): DistrictsState => ({
      ...state,
      selectedDistrict: action.payload,
      loading: {
        ...state.loading,
        getDistrictById: false,
      },
    })
  )
  .handleAction(
    actions.getDistrictById.failure,
    (state, action): DistrictsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDistrictById: action.payload,
      },
      loading: {
        ...state.loading,
        getDistrictById: false,
      },
    })
  );
