import React, { FunctionComponent, useMemo } from "react";
import { Button, ButtonGroup, Icon, IconName } from "@blueprintjs/core";
import { AdminRoutes, AuthRoutes, NonAuthRoutes } from "../../App";
import { generatePath, matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setMenuCollapsed } from "../../store/UIState/actions";
import { SurveyReportTabEnum, UserRole, userRoles } from "../../types";
import {
  getCurrentUserDistrictId,
  getCurrentUserRole,
  selectIsAdmin,
} from "../../store/auth/selectors";
import { AdminTabs } from "../pages/AdminTables";
import { DistrictTabs } from "../pages/Districts";
import Sticky from "react-sticky-el";

type OwnProps = {};

type Props = OwnProps;

const ICON_SIZE = 26;

type MenuItem = {
  icon: IconName;
  title: string;
  route: AuthRoutes | AdminRoutes | NonAuthRoutes | string;
  baseRoutes: Array<AuthRoutes | AdminRoutes | NonAuthRoutes | string>;
  allowedRoles: string[];
};

const AppSideBarMenu: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const collapsed = useSelector((s) => s.UIState.menuCollapsed);

  const currentUserRole = useSelector(getCurrentUserRole);

  const currentUserDistrictId = useSelector(getCurrentUserDistrictId);

  const onMenuCollapse = () => {
    dispatch(setMenuCollapsed(!collapsed));
  };

  const isAdmin = useSelector(selectIsAdmin);

  const location = useLocation();
  const intl = useIntl();
  const items: MenuItem[] = useMemo(
    () => [
      {
        icon: "layout-circle",
        title: intl.formatMessage({ id: "app.titles.your-plan" }),
        route: generatePath(AuthRoutes.YourPlan, {
          workspace: "dashboard" as any,
        } as any),
        baseRoutes: [AuthRoutes.YourPlan],
        allowedRoles: userRoles.all,
      },
      {
        icon: "office",
        title: intl.formatMessage({ id: "app.titles.districts" }),
        route: generatePath(AdminRoutes.Districts, {
          adminTab: DistrictTabs.DISTRICTS,
        }),
        baseRoutes: [AdminRoutes.Districts, AdminRoutes.DistrictDetails],
        allowedRoles: userRoles.admins,
      },
      {
        icon: "list-detail-view",
        title: intl.formatMessage({ id: "app.titles.surveys" }),
        route: generatePath(AdminRoutes.AdminTables, {
          tab: AdminTabs.SURVEYS,
        }),
        baseRoutes: [AdminRoutes.AdminTables, AdminRoutes.EditSurveyQuestions],
        allowedRoles: [UserRole.Superadmin, UserRole.NJ_SCI_Leadership],
      },
      {
        icon: "properties",
        title: intl.formatMessage({ id: "app.titles.survey-report" }),
        route: generatePath(AuthRoutes.SurveyReport, {
          districtId: isAdmin ? undefined : currentUserDistrictId,
          tabId: SurveyReportTabEnum.ResponseRates,
        }),
        baseRoutes: [AuthRoutes.SurveyReport],
        allowedRoles: userRoles.all,
      },
    ],
    [currentUserDistrictId, isAdmin]
  );

  const itemsByRole = useMemo(() => {
    return items.filter((item) =>
      item.allowedRoles.includes(String(currentUserRole ?? UserRole.Principal))
    );
  }, [currentUserRole]);

  return (
    <div
      style={{
        minWidth: collapsed ? "13.3125rem" : "5rem",
      }}
      key={`sidebar_${collapsed}`}
    >
      <Sticky
        stickyStyle={{ zIndex: 10, height: "100%" }}
        stickyClassName="bg-gray-200"
      >
        <div className="flex flex-col">
          <button
            className="bg-gray-700 flex items-center justify-center px-4 py-3 w-full hover:bg-gray-500"
            onClick={onMenuCollapse}
          >
            <Icon
              iconSize={ICON_SIZE}
              icon={collapsed ? "chevron-left" : "chevron-right"}
              className="bg-white text-gray-700 rounded-sm"
            />
            {collapsed && (
              <div className="text-white font-bold ml-2">
                {intl.formatMessage({ id: "app.menu.collapse-menu" })}
              </div>
            )}
          </button>

          <ButtonGroup
            vertical
            alignText="left"
            className="c-side-bar-btn-group"
          >
            {itemsByRole.map((item, index) => (
              <Link key={`sidemenu_item_${index}`} to={item.route}>
                <Button
                  active={item.baseRoutes.some((route) =>
                    matchPath(location.pathname, route)
                  )}
                  title={item.title}
                  icon={<Icon icon={item.icon} iconSize={ICON_SIZE} />}
                  text={collapsed ? item.title : undefined}
                />
              </Link>
            ))}
          </ButtonGroup>
        </div>
      </Sticky>
    </div>
  );
};

export default AppSideBarMenu;
