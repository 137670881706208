import React, { FunctionComponent, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import messages_en from "../../translations/en.json";
import messages_es from "../../translations/es.json";
import messages_pt from "../../translations/pt.json";
import messages_ht from "../../translations/ht.json";
import messages_ar from "../../translations/ar.json";
import messages_af from "../../translations/af.json";
import messages_sq from "../../translations/sq.json";
import messages_am from "../../translations/am.json";
import messages_hy from "../../translations/hy.json";
import messages_bn from "../../translations/bn.json";
import messages_prs from "../../translations/prs.json";
import messages_et from "../../translations/et.json";
import messages_ka from "../../translations/ka.json";
import messages_de from "../../translations/de.json";
import messages_el from "../../translations/el.json";
import messages_gu from "../../translations/gu.json";
import messages_he from "../../translations/he.json";
import messages_hi from "../../translations/hi.json";
import messages_ja from "../../translations/ja.json";
import messages_quc from "../../translations/quc.json";
import messages_ko from "../../translations/ko.json";
import messages_ml from "../../translations/ml.json";
import messages_pl from "../../translations/pl.json";
import messages_ru from "../../translations/ru.json";
import messages_zh from "../../translations/zh.json";
import messages_tl from "../../translations/tl.json";
import messages_ta from "../../translations/ta.json";
import messages_te from "../../translations/te.json";
import messages_zh_CH from "../../translations/zh_CH.json";
import messages_tr from "../../translations/tr.json";
import messages_uk from "../../translations/uk.json";
import messages_ur from "../../translations/ur.json";
import messages_vi from "../../translations/vi.json";

import { matchPath, useLocation } from "react-router";
import { AuthAndNonAuthRoutes } from "../../App";

type Props = {};

const LocalizationProvider: FunctionComponent<Props> = (props) => {
  const locale = useSelector((s) => s.UIState.locale);
  const surveyLocale = useSelector((s) => s.UIState.surveyLocale);

  const location = useLocation();

  const isSurveyQuestionsPage = useMemo(
    () => !!matchPath(location.pathname, AuthAndNonAuthRoutes.SurveysQuestions),
    [location]
  );

  const messages: any = useMemo(
    () => ({
      en: messages_en,
      es: messages_es,
      pt: messages_pt,
      ht: messages_ht,
      ar: messages_ar,
      af: messages_af,
      sq: messages_sq,
      am: messages_am,
      hy: messages_hy,
      bn: messages_bn,
      prs: messages_prs,
      et: messages_et,
      ka: messages_ka,
      de: messages_de,
      el: messages_el,
      gu: messages_gu,
      he: messages_he,
      hi: messages_hi,
      ja: messages_ja,
      quc: messages_quc,
      ko: messages_ko,
      ml: messages_ml,
      pl: messages_pl,
      ru: messages_ru,
      zh: messages_zh,
      tl: messages_tl,
      ta: messages_ta,
      te: messages_te,
      'zh-CH': messages_zh_CH,
      tr: messages_tr,
      uk: messages_uk,
      ur: messages_ur,
      vi: messages_vi,
    }),
    []
  );

  return (
    <IntlProvider
      locale={isSurveyQuestionsPage ? surveyLocale : locale}
      key={`${isSurveyQuestionsPage ? surveyLocale : locale}_locale`}
      messages={messages[isSurveyQuestionsPage ? surveyLocale : locale]}
    >
      {props.children}
    </IntlProvider>
  );
};

export default LocalizationProvider;
