import { LoginRequest } from "./request.types";

const API_ROOT = process.env.API_BASE_URL;

console.log(`API Root: ${API_ROOT}`);

export default {
  login: async (request: LoginRequest) => {

    const response = await fetch(`${API_ROOT}/auth/login/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    });
    if (response.status === 400) {
      throw new Error("Invalid username or password");
    } else if (response.status === 200) {
      return response.json();
    } else {
      throw new Error("Server error");
    }
  },
};
