import React, { FunctionComponent, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import useFormInputField from "../../helpers/hooks/useFormInputField";
import { Button, Card, FormGroup } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { NonAuthRoutes } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../store/auth/actions";
import { useLoading } from "../../helpers/hooks/useLoading";
import { AppToaster } from "../../helpers/toaster";

type Props = {};

const ResetPasswordPage: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    history.push(NonAuthRoutes.Login);
  }, []);

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(resetPassword.request(email as string));
  };

  const [email, emailInputField] = useFormInputField({
    placeholder: intl.formatMessage({ id: "app.forms.email.placeholder" }),
    required: true,
    type: "email",
    id: "email",
  });

  const loading = useSelector((s) => s.auth.loading.resetPassword);
  const error = useSelector((s) => s.auth.errors.resetPassword);
  const onSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({ id: "app.forms.forgot-password.success" }),
      icon: "tick",
      intent: "success",
      timeout: 10000,
    });
  }, []);
  useLoading({ loading, error, onSuccess });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "app.titles.forgot-password" })}
        </title>
      </Helmet>
      <div className="flex justify-center">
        <Card>
          <div className="text-2xl mb-2 font-bold">
            {intl.formatMessage({ id: "app.titles.forgot-password" })}
          </div>
          <p className="mb-2">
            {intl.formatMessage({ id: "app.forms.forgot-password.info" })}
          </p>
          <form id="reset-password-form" onSubmit={handleReset}>
            <FormGroup
              label={intl.formatMessage({
                id: "app.forms.email",
              })}
            >
              {emailInputField}
            </FormGroup>
          </form>
          <div className="flex justify-between mt-4">
            <Button
              disabled={loading}
              className="capitalize"
              onClick={handleCancel}
            >
              {intl.formatMessage({
                id: "app.titles.cancel",
              })}
            </Button>
            <Button
              loading={loading}
              intent="primary"
              className="capitalize"
              type="submit"
              form="reset-password-form"
            >
              {intl.formatMessage({
                id: "app.forms.forgot-password.reset-my-password",
              })}
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ResetPasswordPage;
