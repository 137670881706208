import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Card, Tab, Tabs } from "@blueprintjs/core";
import DistrictDialog from "../districts/dialogs/DistrictDialog";
import DistrictUsers from "../districts/district-users/DistrictUsers";
import DistrictsList from "./DistrictsList";
import { generatePath, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { AdminRoutes } from "../../App";
import DistrictTermsAgreement from "../districts/district-terms-agreement/DistrictTermsAgreement";
import LoginHistory from "../districts/login-history/LoginHistory";
import ActionAuditLog from "../districts/action-audit-log/ActionAuditLog";
import GoalActivityAll from "./strategic-plan/GoalPlanning/GoalActivity/GoalActivityAll";
import CountAuditLog from "../districts/district-summary-couts/CountAuditLog";

export enum DistrictTabs {
  DISTRICTS = "districts",
  USERS = "users",
  LOGIN_HISTORY = "login-history",
  GOAlS_LOG = "goal-log",
  USER_TERMS_AGREEMENT = "user-terms-agreement",
  ACTION_AUDIT_LOG = "action-audit-log",
  DISTRICT_SUMMARY_COUNTS = "counts-audit-log",
}

type OwnProps = {};

type RouteParams = {
  adminTab: DistrictTabs;
};

type Props = OwnProps;

const Districts: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const params = useParams<RouteParams>();

  const history = useHistory();

  const handleTabChange = (newTab: DistrictTabs) => {
    history.push(
      generatePath(AdminRoutes.Districts, {
        adminTab: newTab,
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.districts" })}</title>
      </Helmet>

      <Card>
        <Tabs
          renderActiveTabPanelOnly
          selectedTabId={params.adminTab}
          onChange={handleTabChange}
        >
          <Tab id={DistrictTabs.DISTRICTS} panel={<DistrictsList />}>
            {intl.formatMessage({ id: "app.titles.districts" })}
          </Tab>
          <Tab
            id={DistrictTabs.USERS}
            panel={<DistrictUsers forAllSystem isDistrictSelectorAvailable />}
          >
            {intl.formatMessage({ id: "app.titles.users" })}
          </Tab>
          <Tab
            id={DistrictTabs.USER_TERMS_AGREEMENT}
            panel={<DistrictTermsAgreement />}
          >
            {intl.formatMessage({ id: "app.titles.user-terms-agreement" })}
          </Tab>
          <Tab id={DistrictTabs.GOAlS_LOG} panel={<GoalActivityAll />}>
            {intl.formatMessage({ id: "app.titles.goal-log" })}
          </Tab>
          <Tab id={DistrictTabs.ACTION_AUDIT_LOG} panel={<ActionAuditLog />}>
            {intl.formatMessage({ id: "app.titles.action-audit-log" })}
          </Tab>
          <Tab id={DistrictTabs.DISTRICT_SUMMARY_COUNTS} panel={<CountAuditLog />}>
            {intl.formatMessage({ id: "app.titles.action-district-summary-counts" })}
          </Tab>
        </Tabs>
      </Card>

      <DistrictDialog />
    </>
  );
};

export default Districts;
