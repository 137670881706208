import React, { FunctionComponent, memo, useEffect, useMemo, useState } from 'react'
import _ from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Popover from '@mui/material/Popover';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import './DomainSelector.scss';
import { DomainDemographicTriageStatuses, DomainPriorityDecision, DomainPriorityTriageStatus, GoalIndicatorTargetDirection, SurveyDemographic } from '../../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { SurveyReportTabEnum } from '../../../../../types/custom';
import { deleteGoalIndicator, removeDomainFromGoal, updateGoal } from '../../../../../store/goals/actions';
import { INDICATOR_DOMAIN_LEVEL } from '../../../../../constants/constants';
import { getCurrentUserId } from "./../../../../../store/auth/selectors";
import { Label } from '@blueprintjs/core';

type OwnProps = {
  anchorEl: HTMLButtonElement | null;
  handleClick?: any;
  handleChange?: any;
  setAnchorEl?: any;
  expanded: string | false;
  domains?: any;
  prioritizeDomain?: any;
  nonPrioritizeDomain?: any;
  domainsExistStatus?: any;
  sortedDomainWithTriageStatus?: any;
};

type Props = OwnProps;
const MAXIMUMDEMOGRAPHIC = 4;
const MINIMUMDEMOGRAPHIC = 1;

const DomainSelector: React.FunctionComponent<Props> = (props) => {
  // CONSTANTS 
  const dispatch = useDispatch();
  const { anchorEl, handleChange, setAnchorEl, expanded, prioritizeDomain, nonPrioritizeDomain, sortedDomainWithTriageStatus } = props;
  const open = Boolean(anchorEl);

  // SELECTORS 
  const { goal } = useSelector((s) => s.goals.newGoalState);
  const { domainPriorities } = useSelector((s) => s.needs);
  const currentUserId = useSelector(getCurrentUserId);
  const { domainsExistStatus } = useSelector((s) => s.domains);
  const { loading } = useSelector((s) => s.goals);

  // FUNCTIONS
  const saveGoal = (goal: any) => {
    dispatch(
      updateGoal.request({
        ...goal,
        goalId: goal.id,
        user: currentUserId,
        planId: goal.plan_id,
        targets: goal.targets,
        survey_deployment: {
          ...goal.survey_deployment,
          survey: goal?.survey_deployment?.survey?.id,
        },
        is_completed: !!goal.desired_outcome && !!goal.statement && !goal.targets.find((et: any) => (!et.target_date || !et.target_value)) ? true : false
      })
    );
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isDomainAreadyUsed = (domain: any, respondentGroup: any = false) => {
    const found = domainsExistStatus
      ? domainsExistStatus.find((edt: any) => edt.domain_id === domain.domain.id)
      : false;
    if (!found) return false;
    if (!respondentGroup) return found;
    if (found.demographic.includes(respondentGroup)) return true;
    else return false;
  };
  const handleDomainSelection = (domain: any, demographic: any = false) => {
    // find provided domain 
    const isDomainExist = goal?.selected_domains?.find((et: any) => (et.id === domain.id));
    // find target for the demographic provided 
    const isDemographicExist = goal?.targets?.find(
      (t: any) => t?.demographic === demographic && t?.domain_priority?.domain?.id === domain.id
    )
    if (!!isDomainExist && (demographic ? (!!demographic && !!isDemographicExist) : true)) {
      if (!demographic) {
        dispatch(removeDomainFromGoal(domain))
      }
      else {
        const totalIndicatorsDomain = goal?.targets?.filter(
          (t: any) => t?.domain_priority?.domain?.id === domain.id
        )
        if (totalIndicatorsDomain.length === MINIMUMDEMOGRAPHIC)
          dispatch(removeDomainFromGoal(domain))
        handleRemoveGoalItem(domain, demographic)
      }
    }
    else {
      const selectedDomains = [...goal.selected_domains, domain];
      // fetch all available respondent groups
      const filterDomainWithDemographic = domainPriorities.filter(
        (dp) => dp.domain.id === domain.id
      );
      const DomainsMeanScore = sortedDomainWithTriageStatus?.find(
        (sdt: any) => sdt.domain.id === domain.id
      );
      const indicatorTargets: any[] = [];
      filterDomainWithDemographic.forEach((fd) => {
        const DomainsMeanScore = sortedDomainWithTriageStatus.find(
          (sdt: any) => sdt.domain.id === fd.domain.id
        );
        const isAlreadyInUse = isDomainAreadyUsed({ domain: fd.domain }, fd.demographic);
        const indicator = {
          // id: `${fd.domain.id}_${fd.demographic}`,
          item: {},
          domain: fd.domain,
          default_score: DomainsMeanScore?.respondents[
            fd.demographic
          ]?.mean_score?.toFixed(2),
          demographic: fd.demographic,
          title: domain.name,
          type: INDICATOR_DOMAIN_LEVEL,
          domain_priority: fd.id,
          domain_priority_id: fd.id,
          achieve_by: null,
          is_domain: true,
          target_score: (
            DomainsMeanScore?.respondents[fd.demographic]?.mean_score
          )?.toFixed(2),
          target_date: null,
          target_value: null,
          // (
          //   DomainsMeanScore?.respondents[fd.demographic]?.mean_score
          // )?.toFixed(2),
          direction_of_change: GoalIndicatorTargetDirection.Maintain,
          direction: GoalIndicatorTargetDirection.Maintain,
          hasError: { achieve_by: true, target_score: true },
          color_hex: domain.color_hex,
          subgroups: "[]",
          is_confirmed: false,
        };
        const exists = indicatorTargets.find(
          (ind) =>
            ind.demographic === indicator.demographic &&
            ind.domain.id === indicator.domain.id &&
            ind.type === indicator.type
        );
        if (!isAlreadyInUse && !exists) indicatorTargets.push({ ...indicator });
      });
      let updatedGoal = {
        ...goal,
        selected_domains: [...selectedDomains],
        targets: [...indicatorTargets] // automatically add to targets array
      }
      if (demographic) {
        updatedGoal['targets'] = indicatorTargets.filter(et => et.demographic === demographic);
      }
      saveGoal(updatedGoal);
    }
  };
  const handleToggleDomainRespondent = (domain: any, respondentGroup: any) => {
    handleDomainSelection(domain.domain, respondentGroup)
  }
  const handleRemoveGoalItem = (domain: any, respondentGroup: any) => {
    const indicator = goal?.targets.find((edt: any) => edt?.domain_priority?.domain?.id === domain.id && edt?.demographic === respondentGroup);
    if (indicator)
      dispatch(deleteGoalIndicator.request({ id: indicator.id }));
  }
  const checkRespontdentInAnotherGoal = (et: any) => {
    const found = domainsExistStatus ? domainsExistStatus.find(
      (edt: any) => edt.domain_id === et.domain.id
    ) : false;

    const domainWithDemographic = domainPriorities.filter(
      (dp: any) => dp.domain.id === et.domain.id
    );

    const rawData: any = []
    domainWithDemographic.forEach((dwd: any) => {
      const ifExists = rawData.find((rd: any) => (rd.demographic === dwd.demographic))
      if (!ifExists)
        rawData.push(dwd);
    })
    const MaxResopondentCount = rawData.length;
    const disabled = (found && found.demographic.length === MaxResopondentCount)
    const someRespondentAlreadyInUse = found && found.demographic.length >= MINIMUMDEMOGRAPHIC && found.demographic.length < MaxResopondentCount;
    const alreadyUsedDemographics = found?.demographic ?? [];
    return [disabled, someRespondentAlreadyInUse, alreadyUsedDemographics, rawData.map((et: any) => et.demographic)]
  }
  const checkInCurrentGoal = (domain: any, demographic: any = false) => {
    const isDomainExist = goal?.selected_domains?.find((et: any) => (et.id === domain?.domain?.id));
    if (!demographic) {
      return isDomainExist ? true : false;
    }

    let isTargetExist = goal?.targets?.find((t: any) => (t.demographic === demographic
      && t.domain_priority?.domain?.id === domain?.domain?.id))
    return isTargetExist ? true : false;
  }

  // USE MEMO
  const id = useMemo(() => {
    return open ? 'simple-popover' : undefined;
  }, [open])

  return (
    <Popover id={id} open={open} anchorEl={anchorEl ?? null} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} >
      <div className="popover-list">
        <h4>Prioritized Domains</h4>
        {
          prioritizeDomain?.map((domain: any) => {
            // these variables calculate result on the basis of other goals
            const [disabled, someRespondentAlreadyInUse, alreadyUsedDemographics, allAvailableRespondents] = checkRespontdentInAnotherGoal(domain);

            const isElementaryStudentsSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.ElementaryStudents) || alreadyUsedDemographics.includes(SurveyReportTabEnum.ElementaryStudents)
            const isStudentsSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.Students) || alreadyUsedDemographics.includes(SurveyReportTabEnum.Students)
            const isParentsSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.Parents) || alreadyUsedDemographics.includes(SurveyReportTabEnum.Parents)
            const isSchoolStaffSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.SchoolStaff) || alreadyUsedDemographics.includes(SurveyReportTabEnum.SchoolStaff)

            return <Accordion expanded={expanded === domain.domain.code} onChange={handleChange(domain.domain.code)} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Label>{domain.domain.name}</Label>
                {/* <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onChange={() => { handleDomainSelection(domain.domain) }} control={<Checkbox checked={!isInCurrentGoal ? false : (disabled || alreadyUsedDemographics?.length)} disabled={(!checkInCurrentGoal(domain)) && disabled} />} label={domain.domain.name} /> */}
              </AccordionSummary>
              <AccordionDetails>
                {loading?.updateGoal && <div id="overlay">
                  <div className="loader"></div>
                </div>}
                <FormGroup>
                  {
                    allAvailableRespondents.includes(SurveyReportTabEnum.ElementaryStudents) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isElementaryStudentsSelected} disabled={isElementaryStudentsSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.ElementaryStudents) }} />} label="Students Grades 3-5" />
                  }
                  {
                    allAvailableRespondents.includes(SurveyReportTabEnum.Students) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isStudentsSelected} disabled={isStudentsSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.Students) }} />} label="Students Grades 6-12" />
                  }
                  {
                    allAvailableRespondents.includes(SurveyReportTabEnum.Parents) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isParentsSelected} disabled={isParentsSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.Parents) }} />} label="Parents/Caregivers" />
                  }
                  {
                    allAvailableRespondents.includes(SurveyReportTabEnum.SchoolStaff) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isSchoolStaffSelected} disabled={isSchoolStaffSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.SchoolStaff) }} />} label="Staff" />
                  }
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          })
        }

        <h4>Other Domains</h4>
        {
          nonPrioritizeDomain?.map((domain: any) => {
            const [disabled, someRespondentAlreadyInUse, alreadyUsedDemographics, allAvailableRespondents] = checkRespontdentInAnotherGoal(domain);
            const isElementaryStudentsSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.ElementaryStudents) || alreadyUsedDemographics.includes(SurveyReportTabEnum.ElementaryStudents)
            const isStudentsSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.Students) || alreadyUsedDemographics.includes(SurveyReportTabEnum.Students)
            const isParentsSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.Parents) || alreadyUsedDemographics.includes(SurveyReportTabEnum.Parents)
            const isSchoolStaffSelected = checkInCurrentGoal(domain, SurveyReportTabEnum.SchoolStaff) || alreadyUsedDemographics.includes(SurveyReportTabEnum.SchoolStaff)

            return <Accordion expanded={expanded === domain.domain.code} onChange={handleChange(domain.domain.code)} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Label>{domain.domain.name}</Label>

                {/* <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onChange={() => { handleDomainSelection(domain.domain) }} control={<Checkbox checked={!isInCurrentGoal ? false : (disabled || alreadyUsedDemographics?.length)} disabled={(!checkInCurrentGoal(domain)) && disabled} />} label={domain.domain.name} /> */}
              </AccordionSummary>
              <AccordionDetails>
                {loading?.updateGoal && <div id="overlay">
                  <div className="loader"></div>
                </div>}
                <FormGroup>
                {
                    allAvailableRespondents.includes(SurveyReportTabEnum.ElementaryStudents) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isElementaryStudentsSelected} disabled={isElementaryStudentsSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.ElementaryStudents) }} />} label="Students Grades 3-5" />
                  }
                  {
                    allAvailableRespondents.includes(SurveyReportTabEnum.Students) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isStudentsSelected} disabled={isStudentsSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.Students) }} />} label="Students Grades 6-12" />
                  }
                  {
                    allAvailableRespondents.includes(SurveyReportTabEnum.Parents) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isParentsSelected} disabled={isParentsSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.Parents) }} />} label="Parents/Caregivers" />
                  }
                  {
                    allAvailableRespondents.includes(SurveyReportTabEnum.SchoolStaff) &&
                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} control={<Checkbox checked={isSchoolStaffSelected} disabled={isSchoolStaffSelected} onClick={() => { handleToggleDomainRespondent(domain, SurveyReportTabEnum.SchoolStaff) }} />} label="Staff" />
                  }
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          })
        }
      </div>
    </Popover>
  )
}

export default memo(DomainSelector);
