import React, { FunctionComponent } from "react";
import { Classes, Dialog, Icon, NonIdealState } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { hideStrategyResourceComponentsDialog } from "../../../../store/strategies/actions";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const StrategyDetailsDialog: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const { show, strategyName, contentName, content } = useSelector(
    (s) => s.strategies.dialogs.strategyResourcesComponentsDialog
  );

  const handleClose = () => {
    dispatch(hideStrategyResourceComponentsDialog());
  };

  return (
    <Dialog
      icon={<Icon icon="briefcase" iconSize={24} />}
      isOpen={show}
      onClose={handleClose}
      portalClassName="strategy-details-portal"
      title={`${strategyName}: ${contentName}`}
    >
      <div className={Classes.DIALOG_BODY}>
        {!!content ? (
          <>
            {typeof content === "string" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            ) : (
              content
            )}
          </>
        ) : (
          <NonIdealState
            icon="zoom-out"
            title={intl.formatMessage({ id: "app.titles.no-data" })}
            description={intl.formatMessage({
              id:
                "app.strategies.strategy-details-dialog.update-selected-strategy",
            })}
          />
        )}
      </div>
    </Dialog>
  );
};

export default StrategyDetailsDialog;
