import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
} from "@blueprintjs/core";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../../helpers/toaster";
import { getShareFeedbackOption, shareFeedback } from "../../../../store/survey-reports/actions";
import { hideFeedbackDialog } from "../../../../store/UIState/actions";

//new design
import { Icon } from '@blueprintjs/core';
import Dialog  from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import './send-feedback.scss';
type OwnProps = {};

type Props = OwnProps;

const ShareFeedbackDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const show = useSelector((s) => s.UIState.showFeedbackDialog);

  const dispatch = useDispatch();

  const loading = useSelector((s) => s.surveyReports.loading.shareFeedback);
  const error = useSelector((s) => s.surveyReports.errors.shareFeedback);
  useLoading({
    loading: loading,
    error: error,
    onSuccess: () => {
      handleClose();
      AppToaster.show({
        message: intl.formatMessage({
          id: "app.toaster.survey-report.feedback-sent",
        }),
        intent: "success",
        icon: "tick",
      });
    },
  });

  const options = useSelector((s) => s.surveyReports.getFeedbackOptions);

  const feedbackoptions: any[] = useMemo(() => {
    return options.map((s: { id: any; name: any; }) => ({ value: s.id, label: s.name }));
  }, [options]);

  useEffect(() => {
    dispatch(getShareFeedbackOption.request());
  }, [dispatch]);

  const [message, setMessage] = useState<string>("");

  const [feedback_choices, setFeedbackOption] = useState<string>("");



  useEffect(() => {
    setFeedbackOption(feedbackoptions[0]?.label)
  }, [options])
  
  const handleMessageBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setMessage(e.target?.value);
    
  };

  const handleFeedbackOption = (e:any) => {
    setFeedbackOption(e.target?.value);
    
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(feedbackoptions[0].label === feedback_choices){
      let feedback_choices=feedbackoptions[0].value
      dispatch(shareFeedback.request({message,feedback_choices}));
    }else{
      let feedback_choices=feedbackoptions[1].value
      dispatch(shareFeedback.request({message,feedback_choices}));
    }
    
  };

  const handleClose = () => {
    setMessage("");
    dispatch(hideFeedbackDialog());
  };
  return (
    <>   
    {/* <button onClick={show}><Icon icon="geolocation" iconSize={16} /></button> */}
      <Dialog
        open={show?true:false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="send-feedback"
      >
        <div className="send-feedback__header">
        {intl.formatMessage(
        { id: "app.titles.share-feedback" },
        { br: ""}
         )}
          <button  onClick={handleClose} >
            <Icon icon="cross" iconSize={12} />
          </button>
        </div>

        <div className="send-feedback__content">
        <form onSubmit={handleFormSubmit} id={"share-feedback"}>
        <FormControl sx={{ minWidth: 320 }}  size="small" className="leave-comment">
        <InputLabel id="demo-select-small">Send Feedback</InputLabel>
            <Select
            labelId="demo-select-small"
            id="demo-select-small"
            label="Send feedback"
            onChange={handleFeedbackOption}
            value={feedback_choices}
            defaultValue={feedbackoptions[0]}
          >  
         {options.map((s: { id: any; name: any; }) => ( <MenuItem key={s.id} value={s.name}>{s.name}</MenuItem> ))}
          </Select>
          </FormControl>
          <TextField
          id="outlined-multiline-static"
          multiline
          rows={4}
          defaultValue={message}
          onBlur={handleMessageBlur}
          required
        />
    </form>
        <div className="button-group">
        <Button
            loading={loading}
            className="stroke-btn"
            intent="primary"
            type="submit"
            form="share-feedback"
          >
            {intl.formatMessage({
              id: "app.titles.submit",
            })}
          </Button>
            </div>
        </div>       
      </Dialog>
    </>
  );
};

export default ShareFeedbackDialog;
