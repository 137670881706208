import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Card, Classes, Spinner, Tab, Tabs } from "@blueprintjs/core";
import { generatePath, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DistrictSchools from "../districts/district-school/DistrictSchools";
import { useIntl } from "react-intl";
import DistrictUsers from "../districts/district-users/DistrictUsers";
import { getDistrictById } from "../../store/districts/actions";
import { DistrictTabs } from "./Districts";
import { useHistory } from "react-router-dom";
import { AdminRoutes, AuthRoutes } from "../../App";
import { Helmet } from "react-helmet";
import SetupNewSchoolBtn from "../schools-selector-panel/SetupNewSchoolBtn";

export enum DistrictDetailsTabs {
  SCHOOLS = "schools",
  USERS = "users",
}

type OwnProps = {
  isTeamPlanPage?: boolean;
};

type RouteParams = {
  districtId?: string;
  tabId: DistrictTabs;
};

type Props = OwnProps;

const DistrictDetails: FunctionComponent<Props> = ({
  isTeamPlanPage,
}: Props) => {
  const { districtId, tabId } = useParams<RouteParams>();
  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const dispatch = useDispatch();
  const intl = useIntl();

  const history = useHistory();
  const [isSchoolTab, setIsSchoolTab] = useState(true); // default true because first tab is school that will be visible on load

  const handleTabChange = (newTab: DistrictDetailsTabs) => {
    setIsSchoolTab(newTab === DistrictDetailsTabs.SCHOOLS);
    if (districtId) {
      history.push(
        generatePath(AdminRoutes.DistrictDetails, {
          districtId: districtId,
          tabId: newTab,
        })
      );
      return;
    }
    history.push(
      generatePath(AuthRoutes.YourPlanDistrict, {
        workspace: "my-district",
        tabId: newTab,
      } as any)
    );
  };

  useEffect(() => {
    if (!setupCompleted) {
      return () => {
        if (history.action === 'POP') {
            history.go(1);
        }
    };
    };
  }, [setupCompleted]);

  const selectedDistrict = useSelector((s) =>
    isTeamPlanPage ? s.plans.activePlan?.district : s.districts.selectedDistrict
  );
  const loading = useSelector((s) => s.districts.loading.getDistrictById);

  useEffect(() => {
    if (districtId) {
      dispatch(getDistrictById.request(Number(districtId)));
    }
  }, [districtId]);

  const hasSchools = useSelector((s) => !!s.schools.schools.length);

  const planName = useMemo(() => {
    if (isTeamPlanPage) {
      return (
        <span className={selectedDistrict?.name ? undefined : Classes.SKELETON}>
          {selectedDistrict?.name ?? "PLAN NAME"}
        </span>
      );
    }
    return selectedDistrict?.name;
  }, [selectedDistrict?.name, isTeamPlanPage]);

  return (
    <>
      {isTeamPlanPage && (
        <Helmet>
          <title>
            {intl.formatMessage({ id: "app.titles.manage-district" })}
          </title>
        </Helmet>
      )}
      <Card>
        {loading ? (
          <Spinner intent="primary" className="py-4" />
        ) : (
          <div>
            <div className="text-2xl text-blue-600 font-medium my-2 text-center">
              {planName}
            </div>
            <Tabs
              selectedTabId={tabId}
              onChange={handleTabChange}
              renderActiveTabPanelOnly
            >
              <Tab
                id={DistrictDetailsTabs.SCHOOLS}
                panel={
                  <DistrictSchools
                    isTeamPlanPage={isTeamPlanPage}
                    isSingleSchoolDistrict={
                      selectedDistrict?.is_single_school_district
                    }
                  />
                }
              >
                {intl.formatMessage({ id: "app.titles.schools" })}
              </Tab>
              <Tab
                id={DistrictDetailsTabs.USERS}
                panel={<DistrictUsers isTeamPlanPage={isTeamPlanPage} />}
              >
                {intl.formatMessage({ id: "app.titles.users" })}
              </Tab>
              <Tabs.Expander />
              {tabId as any !== DistrictDetailsTabs.USERS && hasSchools && isSchoolTab && <SetupNewSchoolBtn />}
            </Tabs>
          </div>
        )}
      </Card>
    </>
  );
};

export default DistrictDetails;
