import React, { FunctionComponent } from "react";
import doneIcon from "../../../static/icons/png/Done.png";
import { Icon } from "@blueprintjs/core";
import { DashboardWidgetStep } from "./DashboardWidget";
import LockPopover from "./LockPopover";

type OwnProps = {
  item: DashboardWidgetStep;
  showNextDots?: boolean;
  showPrevDots?: boolean;
  callbacks?: {
    onLeftIconClick: Function;
    onRightIconClick: Function;
  };
  showLeftPart?: boolean;
  showRightPart?: boolean;
};

type Props = OwnProps;

const DashboardWidgetStepItem: FunctionComponent<Props> = (props) => {
  const {
    item,
    showNextDots,
    showPrevDots,
    showLeftPart,
    showRightPart,
    callbacks,
  } = props;

  return (
    <React.Fragment>
      {callbacks ? (
        <Icon
          icon={showLeftPart ? "chevron-right" : "chevron-left"}
          iconSize={24}
          className="plan-setup-widget__steps__item__divider cursor-pointer"
          onClick={() => callbacks.onLeftIconClick()}
        />
      ) : (
        showPrevDots && (
          <Icon
            icon={"more"}
            iconSize={24}
            className="plan-setup-widget__steps__item__divider"
          />
        )
      )}
      <LockPopover lockType={item.lockType}>
        <div
          className={`plan-setup-widget__steps__item ${
            item.active && !item.lockType ? "active" : ""
          } ${item.onClick && !item.lockType ? " " : "pointerless"}`}
          onClick={() => {
            if (!item.lockType) {
              item.onClick && item.onClick();
            }
          }}
        >
          <div className="relative">
            <img width={item.iconSize ?? 64} src={item.src} alt="" />
            {item.completed && (
              <img
                width={32}
                src={doneIcon}
                className="absolute"
                style={{ top: 0, right: "-0.5rem" }}
                alt="Completed Badge"
              />
            )}
          </div>
          {!!item.text && (
            <div className="plan-setup-widget__steps__item__title mt-2">
              {item.text}
            </div>
          )}
        </div>
      </LockPopover>
      {callbacks ? (
        <Icon
          icon={showRightPart ? "chevron-left" : "chevron-right"}
          iconSize={24}
          className="plan-setup-widget__steps__item__divider cursor-pointer"
          onClick={() => callbacks.onRightIconClick()}
        />
      ) : (
        showNextDots && (
          <Icon
            icon={"more"}
            iconSize={24}
            className="plan-setup-widget__steps__item__divider"
          />
        )
      )}
    </React.Fragment>
  );
};

export default DashboardWidgetStepItem;
