import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DomainStatistics,
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  SurveyDemographic,
  SurveyQuestionStatistics,
  SurveyStatistics,
} from "../../../types";
import { useDispatch, useSelector } from "react-redux";

import SurveyReportTable from "./SurveyReportTable";
import useSurveyReportType from "../../../helpers/hooks/survey-reports/useSurveyReportType";
import * as _ from "lodash";
import { isArray } from "lodash";
import { useIntl } from "react-intl";
import {
  Button,
  Icon,
  Popover,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import ReviewDataDialog from "./dialogs/ReviewDataDialog";
import { Switch } from "@blueprintjs/core";
import { getUrlParams } from "../../../helpers/url-utils";
import { GROUP_BY_PARAM } from "./survey-report-table-head/SurveyReportTableHead";
import { useLocation } from "react-router";
import { changeInReportInsights } from "../../../store/survey-reports/actions";

type OwnProps = {
  schoolId: number | undefined;
  surveyDeploymentId: number | undefined;
  domainId: number | undefined;
  tabId: string | undefined;
  demographic: SurveyDemographic;
  manageBookmarkDialog?: boolean;
  isReportInsights?: boolean;
};

type Props = OwnProps;

const SurveyReportTableWrapper: React.FC<Props> = (props: Props) => {
  const {
    schoolId,
    surveyDeploymentId,
    domainId,
    isReportInsights,
    tabId,
    demographic,
    manageBookmarkDialog,
  } = props;
  const selectedBookmarkDetail = useSelector(
    (s) => s.surveyReports.bookmarkDialogDetails
  );
  const surveyReport = useSelector((s) => s.surveyReports.surveyReport);
  const surveysDeployment = useSelector(
    (s) => s.surveysDeployment.surveysDeployment
  );
  const surveysDeploymentBySchool = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  const sd = useMemo(() => {
    return (
      surveysDeployment.find((et) => et.id == surveyDeploymentId) ||
      surveysDeploymentBySchool.find((et) => et.id == surveyDeploymentId)
    );
  }, [surveyDeploymentId, surveysDeploymentBySchool]);

  const location = useLocation();
  const dispatch = useDispatch();

  const {
    isDomainStatistics,
    isGroupedDomainStatistics,
    isSurveyStatisticsArray,
    isGroupedStatisticsArray,
  } = useSurveyReportType(schoolId, surveyDeploymentId, domainId, surveyReport);
  const {
    groupedSurveyReport,
    dictionary = {},
  }: {
    groupedSurveyReport: any;
    dictionary?: { [key: number]: boolean };
  } = useMemo(() => {
    if (isDomainStatistics || isGroupedDomainStatistics) {
      const groupedReport = _.groupBy(
        (surveyReport as
          | DomainStatistics
          | DomainStatistics<GroupedAnswerStatistics[]>)?.survey_questions,
        "segmentation_code"
      );
      return {
        groupedSurveyReport: _.chain(groupedReport)
          .entries()
          .map((entry) => ({
            ...surveyReport,
            survey_questions: entry[1],
            segmentation_code_grouped_by_frontend: entry[0],
          }))
          .filter((entry: any) => {
            if (selectedBookmarkDetail) {
              for (let i = 0; i < entry.survey_questions.length; i++) {
                if (
                  entry.survey_questions[i].question_id ===
                  selectedBookmarkDetail.questionId
                ) {
                  return true;
                }
              }
              return false;
            } else return true;
          })
          .orderBy((entry: any) => {
            const minQuestionOrder = _.minBy(
              entry.survey_questions,
              (sq: SurveyQuestionStatistics<any>) => sq.order
            )?.order;
            return minQuestionOrder;
          }, "asc")
          .value(),
      };
    } else if (isSurveyStatisticsArray || isGroupedStatisticsArray) {
      const orderedChain = _.chain(
        surveyReport as SurveyStatistics[] | GroupedSurveyStatistics[]
      )
        .filter((sr: SurveyStatistics | GroupedSurveyStatistics) =>
          selectedBookmarkDetail
            ? sr.domain?.id === selectedBookmarkDetail.domainIndex
            : true
        )
        .groupBy(
          (sr: SurveyStatistics | GroupedSurveyStatistics) =>
            sr.domain?.domain_answer?.is_negative
        )
        .entries()
        .orderBy(([isNegative]) => isNegative);

      return {
        dictionary: orderedChain
          .reduce((pV, [isNegative], index) => {
            return { ...pV, [index]: isNegative == "true" };
          }, {})
          .value(),
        groupedSurveyReport: orderedChain.map(([, items]) => items).value(),
      };
    }

    return { groupedSurveyReport: surveyReport };
  }, [surveyReport]);

  const intl = useIntl();

  const [inReportInsights, setInReportInsights] = useState<boolean>(
    isReportInsights ? true : false
  );
  const [isInfoDialogOpen, setOpen] = useState<boolean>(false);

  const handleReviewDataClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleShowFlag = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setInReportInsights(checked);
    dispatch(changeInReportInsights(checked));
  };

  const isDisaggregation = useMemo(() => {
    return getUrlParams(GROUP_BY_PARAM, location.search).length <= 1;
  }, [location.search]);

  const isVersion1 = useMemo(() => {
    return sd?.njsci_version === 1;
  }, [sd?.njsci_version]);

  return (
    <>
      <div className="text-xl mb-2">
        {intl.formatMessage(
          {
            id: manageBookmarkDialog
              ? "app.surveys.reports.review-data.bookmark.info"
              : "app.surveys.reports.review-data.info",
          },
          {
            icon: (
              <Button
                icon={
                  <Icon
                    icon={"info-sign"}
                    iconSize={20}
                    onClick={handleReviewDataClick}
                  />
                }
                minimal
              />
            ),
          }
        )}
      </div>

      {
        <div>
          <div
            className={isVersion1 ? "flex justify-between" : "flex justify-end"}
          >
            {isVersion1 && (
              <span>
                <span className="blue-sq">&#9830;</span>
                <i>
                  {" "}
                  ={" "}
                  {intl.formatMessage({
                    id: "app.collect-data.icon-info",
                  })}
                </i>
              </span>
            )}
            {isDisaggregation && (
              <Popover
                popoverClassName="max-w-80"
                interactionKind={PopoverInteractionKind.HOVER}
                content={
                  <div className="p-3">
                    {intl.formatMessage({
                      id: "app.survey-report-insights.switch-popover",
                    })}
                  </div>
                }
              >
                <Switch
                  checked={inReportInsights}
                  onChange={handleShowFlag}
                  labelElement={
                    <p className="font-bold whitespace-no-wrap text-xl mr-8">
                      {intl.formatMessage({
                        id: "app.survey-report-insights.flag-view",
                      })}
                    </p>
                  }
                  innerLabelChecked={intl.formatMessage({
                    id: "app.titles.on",
                  })}
                  innerLabel={intl.formatMessage({ id: "app.titles.off" })}
                  alignIndicator="right"
                />
              </Popover>
            )}
          </div>
        </div>
      }
      {isArray(groupedSurveyReport) && groupedSurveyReport.length ? (
        <>
          {(groupedSurveyReport as (
            | DomainStatistics
            | DomainStatistics<GroupedAnswerStatistics[]>
            | SurveyStatistics[]
            | GroupedSurveyStatistics[]
          )[])?.map((ds, i, array) => (
            <div key={i} className="mb-4">
              <SurveyReportTable
                demographic={demographic}
                isDisaggregation={isDisaggregation}
                surveyDeploymentId={surveyDeploymentId}
                domainId={domainId}
                schoolId={schoolId}
                surveyReport={ds}
                tabId={tabId}
                segmentationCode={
                  array?.length === 1
                    ? undefined
                    : (ds as any)?.segmentation_code_grouped_by_frontend
                }
                showTableHeaderContent={!manageBookmarkDialog ? !i : false}
                forNegativeDomains={dictionary[i]}
                manageBookmarkDialog={!!manageBookmarkDialog}
                inReportInsights={inReportInsights}
              />
            </div>
          ))}
        </>
      ) : (
        //uses to display non ideal state if server returned []
        <SurveyReportTable
          schoolId={schoolId}
          surveyDeploymentId={surveyDeploymentId}
          domainId={domainId}
          tabId={tabId}
          isDisaggregation={isDisaggregation}
          inReportInsights={inReportInsights}
          surveyReport={surveyReport}
          demographic={demographic}
          manageBookmarkDialog={!!manageBookmarkDialog}
        />
      )}

      <ReviewDataDialog show={isInfoDialogOpen} setShow={setOpen} />
    </>
  );
};

export default SurveyReportTableWrapper;
