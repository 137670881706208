import React, { FunctionComponent } from "react";

type OwnProps = {
  shortCode?: string;
  inverse?: boolean;
  deletedIcon?: boolean;
};

type Props = OwnProps;

const QuestionShortCode: FunctionComponent<Props> = (props) => {
  const { shortCode, inverse, deletedIcon } = props;
  return (
    <div className={"-mt-2 -ml-4 flex"}>
      <div
        className={`font-normal px-1 ${
          inverse ? "bg-gray-400" : "bg-orange-300"
        }`}
      >
        {shortCode}
      </div>
      {deletedIcon && <span className="blue-sq ml-2">&#9830;</span>}
    </div>
  );
};

export default QuestionShortCode;
