import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { UserTermsAgreement } from "../../../types";
import { Button } from "@blueprintjs/core";
import moment from "moment/moment";

type OwnProps = {
  usersAgreement: UserTermsAgreement[];
};

type Props = OwnProps;

const TERMS_COLUMNS =
  '["Date Accepted","Name","Email","District Name","School Role"]';

const DownloadTermsAgreementCSV: React.FC<Props> = ({
  usersAgreement,
}: Props) => {
  const intl = useIntl();

  const handleCSVDownloadClick = useCallback(() => {
    const csvStr = [
      TERMS_COLUMNS,
      ...usersAgreement.map((ua) => {
        const formattedObject = {
          date: ua.date,
          name: `${ua.first_name} ${ua.last_name} ${ua.is_active?"":"(Deactivated)"}`,
          email: ua.email,
          district_name: ua.district_name,
          school_role:
            ua.custom_role_name ??
            intl.formatMessage({ id: "app.roles" }, { role: ua.school_role }),
        };
        return JSON.stringify(Object.values(formattedObject));
      }),
    ]
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute(
      "download",
      `Terms Agreement${moment().format("MM/DD/YYYY")}`
    );
    link.href = csvUrl;
    document.body.append(link);
    link.click();
    link.remove();
  }, [usersAgreement]);

  return (
    <Button
      text={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
      title={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
      intent="primary"
      onClick={handleCSVDownloadClick}
    />
  );
};

export default DownloadTermsAgreementCSV;
