import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Icon } from '@blueprintjs/core';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { Strategy } from '../../../../../types';
import { getDomains } from '../../../../../store/domains/actions';
import './StrategiesListFilter.scss'
import { getSurveys } from '../../../../../store/surveys/actions';
import { getStrategies } from '../../../../../store/strategies/actions';
import { filterResourceStrategiesByDomainAndSearchString } from '../../utils';
import { getActivePlanDistrictId, isDistrictPlan } from '../../../../../store/plans/selectors';
import { getSchoolsByDistrict } from '../../../../../store/schools/actions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type OwnProps = {
    filteredStrategy: (data: Strategy[]) => void;
    isLoader: (isLoader: boolean | undefined) => void;
};

type Props = OwnProps;
const NJ_SCI_SUBSTRING = "NJ SCI";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 250,
        },
    },
};

const StrategiesListResourceFilter: FunctionComponent<Props> = ({ filteredStrategy, isLoader }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    // STATE
    const [displayedStrategies, setDisplayedStrategies] = useState<Strategy[]>(
        []
      );
    const [selectedDomain, setSelectedDomain] = useState<string[]>([]);
    const [searchString, setSearchString] = useState<string>("");
    const [isCustomStrategy, setIsCustomStrategy] = useState<boolean>(false);


    // Get from redux store
    const domains = useSelector((s) => s.domains.domains);
    const isLoading = useSelector((s) => s.planStrategies.loading.getPlanStrategies);
    const surveys = useSelector((s) => s.surveys.surveys);
    const strategiesAll = useSelector((s) => s.strategies.strategies);
    const activePlanSchoolId = useSelector((s) => s.plans.activePlan?.school?.id);
    const isDistrictActivePlan = useSelector(isDistrictPlan);
    const activePlanDistrictId = useSelector(getActivePlanDistrictId);
    const schools = useSelector((s) => s.schools.schools);

    const schoolIds = useMemo(() => {
        return schools.filter(s => s.id).map(s => s.id);
    }, [schools]);

    useEffect(() => {
        activePlanDistrictId &&
            dispatch(getSchoolsByDistrict.request(activePlanDistrictId));

    }, [activePlanDistrictId, dispatch]);

    const strategies = useMemo(() => {
        if (isDistrictActivePlan) {
            return strategiesAll.filter(strategy => strategy.is_global || (strategy.district === activePlanDistrictId));
        }
        else {
            return strategiesAll.filter(strategy => strategy.is_global || (strategy.school === activePlanSchoolId) || (strategy.district === activePlanDistrictId));
        }
    }, [activePlanDistrictId, activePlanSchoolId, isDistrictActivePlan, strategiesAll]);

    const njSurveyId = useMemo(() => {
        const njsurvey = surveys.find(survey => survey.name.includes(NJ_SCI_SUBSTRING))
        return njsurvey?.id;
    }, [surveys]);

    useEffect(() => {
        dispatch(getStrategies.request());
        dispatch(getDomains.request());
        dispatch(getSurveys.request());
      }, [dispatch]);

    useEffect(() => {
            setDisplayedStrategies(
                filterResourceStrategiesByDomainAndSearchString(
                    strategies,
                    searchString,
                    selectedDomain,
                    isCustomStrategy
                )
            );
    }, [dispatch, isCustomStrategy, searchString, selectedDomain, strategies]);

    const handleDomainChange = (event: SelectChangeEvent<typeof selectedDomain>) => {
        const {
            target: { value },
        } = event;
        setSelectedDomain(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target?.value);
    };

    useEffect(() => {
        filteredStrategy(displayedStrategies);
        isLoader(isLoading);
    }, [displayedStrategies, filteredStrategy, isLoader, isLoading]);

    return (
        <>
            <div className="filterbar">
                <span className="filter-icon">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.10791 0.369736L6.60393 7.87166V15.3736L10.316 17.249V7.8713L16.812 0.369385H8.46013L0.10791 0.369736Z" fill="#8B8B8B" />
                    </svg>

                </span>
                <FormControl sx={{ m: 1, width: 500 }} size="small">
                    <InputLabel id="domain-multiple-checkbox-label">{intl.formatMessage({ id: "app.title.select-domain" })}</InputLabel>
                    <Select
                        labelId="domain-multiple-checkbox-label"
                        id="domain-multiple-checkbox"
                        multiple
                        value={selectedDomain}
                        onChange={handleDomainChange}
                        input={<OutlinedInput label={intl.formatMessage({ id: "app.title.select-domain" })} />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        <div className="filterbar__list">
                            <ul>
                                {
                                    selectedDomain.map((domain, index) => {
                                        return <li key={index}>
                                            <span>{domain}</span>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        {domains.map((domain) => (
                            domain.survey === njSurveyId &&
                            (<MenuItem key={domain.id} value={domain.name} className="checkbox-list">
                                <Checkbox checked={selectedDomain.indexOf(domain.name) > -1} />
                                <ListItemText primary={domain.name} />
                            </MenuItem>)
                        ))}

                        {selectedDomain.length &&
                            <div className="clear-btn">
                                <button onClick={() => setSelectedDomain([])}><Icon icon="cross" iconSize={16} />
                                    {intl.formatMessage({ id: "app.strategy.domain-filter.clear-all" })}
                                </button>
                            </div>
                        }
                    </Select>
                </FormControl>
                <TextField
                    className="filter-input"
                    label={intl.formatMessage({ id: "app.filter-keyword-strategy.placeholder" })}
                    id="outlined-size-small"
                    size="small"
                    value={searchString}
                    onChange={handleSearchStringChange}
                />
                <div className='custom-strategy-checkbox'>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={() => { setIsCustomStrategy(!isCustomStrategy) }} />} label={intl.formatMessage({ id: "app.title.custom-strategy-checkbox-label" })} />
                    </FormGroup>
                </div>
            </div >
        </>
    )
}

export default StrategiesListResourceFilter;