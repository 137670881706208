import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Icon } from '@blueprintjs/core';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { PlanStrategy } from '../../../../../types';
import { getPlanStrategies } from '../../../../../store/plan-strategies/actions';
import { getDomains } from '../../../../../store/domains/actions';
import './StrategiesListFilter.scss'
import { filterStrategiesByDomainAndSearchString } from '../../utils';
import { getSurveys } from '../../../../../store/surveys/actions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


type OwnProps = {
    filteredPlanStrategy: (data: PlanStrategy[]) => void;
    isLoader: (isLoader: boolean | undefined) => void;
};

type Props = OwnProps;
const NJ_SCI_SUBSTRING = "NJ SCI";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 250,
        },
    },
};

const StrategiesListFilter: FunctionComponent<Props> = ({ filteredPlanStrategy, isLoader }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    // STATE
    const [displayedPlanStrategies, setDisplayedPlanStrategies] = useState<
        PlanStrategy[]
    >([]);
    const [selectedDomain, setSelectedDomain] = useState<string[]>([]);
    const [searchString, setSearchString] = useState<string>("");
    const [isCustomStrategy, setIsCustomStrategy] = useState<boolean>(false);

    // Get from redux store
    const domains = useSelector((s) => s.domains.domains);
    const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
    const activePlanId = useSelector((s) => s.plans.activePlan?.id);
    const isLoading = useSelector((s) => s.planStrategies.loading.getPlanStrategies);
    const surveys = useSelector((s) => s.surveys.surveys);

    const njSurveyId = useMemo(() => {
        const njsurvey = surveys.find(survey => survey.name.includes(NJ_SCI_SUBSTRING))
        return njsurvey?.id;
    }, [surveys]);

    useEffect(() => {
        if (activePlanId) {
            dispatch(getDomains.request());
            dispatch(getSurveys.request());
            dispatch(getPlanStrategies.request(activePlanId));
        }
    }, [activePlanId, dispatch]);

    useEffect(() => {
        if (activePlanId) {
            setDisplayedPlanStrategies(
                filterStrategiesByDomainAndSearchString(
                    planStrategies,
                    searchString,
                    selectedDomain,
                    isCustomStrategy
                )
            );
        }
    }, [activePlanId, isCustomStrategy, planStrategies, searchString, selectedDomain]);

    const handleDomainChange = (event: SelectChangeEvent<typeof selectedDomain>) => {
        const {
            target: { value },
        } = event;
        setSelectedDomain(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target?.value);
    };

    useEffect(() => {
        filteredPlanStrategy(displayedPlanStrategies);
        isLoader(isLoading);
    }, [displayedPlanStrategies, filteredPlanStrategy, isLoader, isLoading]);

    return (
        <>
            <div className="filterbar">
                <span className="filter-icon">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.10791 0.369736L6.60393 7.87166V15.3736L10.316 17.249V7.8713L16.812 0.369385H8.46013L0.10791 0.369736Z" fill="#8B8B8B" />
                    </svg>

                </span>
                <FormControl sx={{ m: 1, width: 500 }} size="small">
                    <InputLabel id="domain-multiple-checkbox-label">{intl.formatMessage({ id: "app.title.select-domain" })}</InputLabel>
                    <Select
                        labelId="domain-multiple-checkbox-label"
                        id="domain-multiple-checkbox"
                        multiple
                        value={selectedDomain}
                        onChange={handleDomainChange}
                        input={<OutlinedInput label={intl.formatMessage({ id: "app.title.select-domain" })} />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        <div className="filterbar__list">
                            <ul>
                                {
                                    selectedDomain.map((domain, index) => {
                                        return <li key={index}>
                                            <span>{domain}</span>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        {domains.map((domain) => (
                            domain.survey === njSurveyId &&
                            (<MenuItem key={domain.id} value={domain.name} className="checkbox-list">
                                <Checkbox checked={selectedDomain.indexOf(domain.name) > -1} />
                                <ListItemText primary={domain.name} />
                            </MenuItem>)
                        ))}

                        {selectedDomain.length &&
                            <div className="clear-btn">
                                <button onClick={() => setSelectedDomain([])}><Icon icon="cross" iconSize={16} />
                                    {intl.formatMessage({ id: "app.strategy.domain-filter.clear-all" })}
                                </button>
                            </div>
                        }
                    </Select>
                </FormControl>
                <TextField
                    className="filter-input"
                    label={intl.formatMessage({ id: "app.filter-keyword-strategy.placeholder" })}
                    id="outlined-size-small"
                    size="small"
                    value={searchString}
                    onChange={handleSearchStringChange}
                />
                <div className='custom-strategy-checkbox'>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={() => { setIsCustomStrategy(!isCustomStrategy) }} />} label={intl.formatMessage({ id: "app.title.custom-strategy-checkbox-label" })} />
                    </FormGroup>
                </div>
            </div >
        </>
    )
}

export default StrategiesListFilter;