import React, { FunctionComponent } from "react";
import InviteUsersDialog from "./InviteUsersDialog";
import { useSelector } from "react-redux";
import { Plan } from "../../../../types";

type OwnProps = { activePlan?: Plan; forAllSystem?: boolean };

type Props = OwnProps;

const InviteUsersDialogContainer: FunctionComponent<Props> = (props) => {
  const { activePlan } = props;

  const loading = useSelector((s) => s.teamMembers.loading.inviteTeamMembers);
  const error = useSelector((s) => s.teamMembers.errors.inviteTeamMembers);

  const failedInvites = useSelector((s) => s.teamMembers.failedInvites);

  const amountOfSucceedInvites = useSelector(
    (s) => s.teamMembers.successInvites.length
  );

  const successInvitesHasDCC = useSelector((s) =>
    s.teamMembers.successInvites.some((i) => i.is_dcc)
  );

  return (
    <InviteUsersDialog
      successInvitesHasDCC={successInvitesHasDCC}
      activePlan={activePlan}
      successInvitesNum={amountOfSucceedInvites}
      failedInvites={failedInvites}
      loading={loading}
      error={error}
      forAllSystem={props.forAllSystem}
    />
  );
};

export default InviteUsersDialogContainer;
