import { createAction, createAsyncAction } from "typesafe-actions";
import {
  CreatePlanStrategyRequest,
  UpdatePlanStrategyImplementationStatusRequest,
  UpdatePlanStrategyRequest,
} from "../../api/plan-strategies/types";
import { LoggedStrategyImplementation, PlanStrategy } from "../../types";

export const showViewImplementationLogsDialog = createAction(
  "@plan-strategies/SHOW_VIEW_IMPLEMENTATION_LOGS_DIALOG"
)<{ planStrategy: PlanStrategy; onConfirm?: Function }>();
export const hideViewImplementationLogsDialog = createAction(
  "@plan-strategies/HIDE_VIEW_IMPLEMENTATION_LOGS_DIALOG"
)<void>();

export const addStrategyToPlan = createAsyncAction(
  "@plan-strategies/ADD_STRATEGY_TO_PLAN_REQUEST",
  "@plan-strategies/ADD_STRATEGY_TO_PLAN_SUCCESS",
  "@plan-strategies/ADD_STRATEGY_TO_PLAN_FAILURE"
)<
  { planStrategy: CreatePlanStrategyRequest; onSuccess?: any },
  PlanStrategy,
  Error
>();

export const updatePlanStrategy = createAsyncAction(
  "@plan-strategies/UPDATE_STRATEGY_TO_PLAN_REQUEST",
  "@plan-strategies/UPDATE_STRATEGY_TO_PLAN_SUCCESS",
  "@plan-strategies/UPDATE_STRATEGY_TO_PLAN_FAILURE"
)<
  { planStrategy: UpdatePlanStrategyRequest; onSuccess?: any },
  PlanStrategy,
  Error
>();

export const deletePlanStrategy = createAsyncAction(
  "@plan-strategies/DELETE_PLAN_STRATEGY_REQUEST",
  "@plan-strategies/DELETE_PLAN_STRATEGY_SUCCESS",
  "@plan-strategies/DELETE_PLAN_STRATEGY_FAILURE"
)<
  { planStrategyId: number; planId: number; onSuccess?: Function },
  number,
  Error
>();

export const updatePlanStrategyImplementationStatus = createAsyncAction(
  "@plan-strategies/UPDATE_PLAN_STRATEGY_IMPLEMENTATION_STATUS_REQUEST",
  "@plan-strategies/UPDATE_PLAN_STRATEGY_IMPLEMENTATION_STATUS_SUCCESS",
  "@plan-strategies/UPDATE_PLAN_STRATEGY_IMPLEMENTATION_STATUS_FAILURE"
)<
  UpdatePlanStrategyImplementationStatusRequest,
  LoggedStrategyImplementation,
  Error
>();

export const getPlanStrategies = createAsyncAction(
  "@plan-strategies/GET_PLAN_STRATEGIES_REQUEST",
  "@plan-strategies/GET_PLAN_STRATEGIES_SUCCESS",
  "@plan-strategies/GET_PLAN_STRATEGIES_FAILURE"
)<number, PlanStrategy[], Error>();

export const getPlanStrategyById = createAsyncAction(
  "@plan-strategies/GET_PLAN_STRATEGY_BY_ID_REQUEST",
  "@plan-strategies/GET_PLAN_STRATEGY_BY_ID_SUCCESS",
  "@plan-strategies/GET_PLAN_STRATEGY_BY_ID_FAILURE"
)<{ planId: number; planStrategyId: number }, PlanStrategy, Error>();

export const deletePlanStrategyImplementation = createAsyncAction(
  "@plan-strategies/DELETE_PLAN_STRATEGY_IMPLEMENTATION_REQUEST",
  "@plan-strategies/DELETE_PLAN_STRATEGY_IMPLEMENTATION_SUCCESS",
  "@plan-strategies/DELETE_PLAN_STRATEGY_IMPLEMENTATION_FAILURE"
)<
  { plan_strategy_id: number; implementation: number; onSuccess?: Function },
  number,
  Error
>();

export const updatePlanStrategyImplementation = createAsyncAction(
  "@plan-strategies/UPDATE_PLAN_STRATEGY_IMPLEMENTATION_REQUEST",
  "@plan-strategies/UPDATE_PLAN_STRATEGY_IMPLEMENTATION_SUCCESS",
  "@plan-strategies/UPDATE_PLAN_STRATEGY_IMPLEMENTATION_FAILURE"
)<
  {
    implementation: number;
    body: UpdatePlanStrategyImplementationStatusRequest;
    onSuccess?: Function;
  },
  { implementation: number; response: LoggedStrategyImplementation },
  Error
>();
