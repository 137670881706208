import React, { FunctionComponent, RefObject } from "react";

type OwnProps = {
  targetRef?: React.MutableRefObject<any> | RefObject<any>;
  value?: any;
};

type Props = OwnProps;

const PseudoValidator: FunctionComponent<Props> = (props) => {
  const { value, targetRef } = props;

  return (
    <input
      tabIndex={-1}
      autoComplete="off"
      style={{
        opacity: 0,
        width: "100%",
        // height: 0,
        position: "absolute",
        bottom: 0,
      }}
      value={value ?? ""}
      onChange={() => {}}
      onFocus={() => targetRef?.current?.focus()}
      required
    />
  );
};

export default PseudoValidator;
