import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Checkbox,
  FormGroup,
  InputGroup,
  Switch,
  Callout,
} from "@blueprintjs/core";
import DistrictSelector from "../../survey-deployment/selectors/DistrictSelector";
import {
  TeamRole,
  TeamRolesPlan,
  UserInvite,
  UserModel,
  UserProfileExcludeBaseModel,
  UserRole,
} from "../../../types";
import SchoolRoleCell from "../district-users/SchoolRoleCell";
import useRolesPermissions from "../../../helpers/hooks/useRolesPermissions";
import { useDispatch, useSelector } from "react-redux";
import { editInvitedUser, editUser } from "../../../store/users/actions";
import { AppToaster } from "../../../helpers/toaster";
import {
  updateTeamMember,
  updateUserFieldInTeamMember,
} from "../../../store/team-members/actions";
import {
  getActivePlanId,
  isDistrictPlan,
} from "../../../store/plans/selectors";
import { TextField } from "@mui/material";

type OwnProps = {
  isDistrictSelectorAvailable?: boolean;
  setWarning: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsDisabledButton: React.Dispatch<React.SetStateAction<boolean>>;
  form: string;
  removedTeamMemberPlanId?: number;
  teamMemberEditing?: boolean;
};

type Props = OwnProps;

type UserModelType = UserModel<number, UserProfileExcludeBaseModel<number>>;

const UserEditingForm: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    form,
    isDistrictSelectorAvailable,
    setWarning,
    setIsDisabledButton,
    removedTeamMemberPlanId,
    teamMemberEditing = false,
  } = props;

  const dispatch = useDispatch();

  const [shouldUpdateTeamRole, setShouldUpdateTeamRole] = useState(false);
  const [demoteFromTC, setDemoteFromTC] = useState(false);
  const [userWarningMsg, setUserWarningMsg] = useState<string>("");
  const [isVisibleWarning, setIsVisibleWarning] = useState<boolean>(false);
  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const activePlan = useSelector(getActivePlanId);

  const isCurrentUserSuperintendent = useRolesPermissions([
    UserRole.Superintendent,
  ]);

  const { schools } = useSelector((s) => s.schools);

  const selectedUser = useSelector<UserModel | UserInvite | undefined>(
    (s) =>
      s.users.selectedUser || s.teamMembers.dialogs.updateTeamMemberDialog.user
  );

  const isUserInvite = useMemo(() => {
    return !!(selectedUser as UserInvite)?.status;
  }, [selectedUser]);

  const [user, setUser] = useState<UserInvite | UserModelType>();
  useEffect(() => {
    if (!!selectedUser && !isUserInvite) {
      setUser({
        ...selectedUser,
        profile: {
          ...((selectedUser as UserModel)?.profile || {}),
          district: (selectedUser as UserModel)?.profile?.district?.id,
        },
      } as UserModelType);
    } else {
      setUser(selectedUser as UserInvite);
    }
  }, [selectedUser]);

  const role = useMemo(() => {
    return isUserInvite
      ? (user as UserInvite)?.role
      : (user as UserModelType)?.profile?.role;
  }, [user, isUserInvite]);

  const isRoleCheck = (role?: UserRole) => {
    if (role) {
      return [
        UserRole.Staff,
        UserRole.Principal,
        UserRole.VicePrincipal,
      ].includes(role);
    }
    return false;
  };

  const selectedDistrict = useMemo(() => {
    return isUserInvite
      ? (user as UserInvite)?.district
      : (user as UserModelType)?.profile?.district;
  }, [user, isUserInvite]);

  const handleUserChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    // if (!!user) {
    setUser({ ...(user as UserInvite | UserModelType), [name]: value });
    // }
  };

  const handleIsActiveChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!!user) {
      setUser({
        ...user,
        is_active: e.currentTarget?.checked,
      });
    }
    setIsVisibleWarning(true);
  };

  const showWarning = () => {
    setWarning(
      isRoleCheck(role) && isCurrentUserSuperintendent
        ? intl.formatMessage({
            id: "app.warnings.principals-school-level-staff-cannot-be-dcc",
          })
        : undefined
    );
  };

  const isSchoolPrincipleCheck = useMemo(() => {
    if (
      role === UserRole.Principal ||
      role === UserRole.Superintendent
      // ||
      // role === UserRole.DistrictStaff ||
      // role === UserRole.VicePrincipal
    ) {
      return false;
    } else {
      const roleExist = schools.find(
        (et) => et?.principal?.id === (user as UserModelType)?.id
      );
      if (roleExist) {
        return true;
      }
      return false;
    }
  }, [role]);

  const isSchoolPrincipleActiveCheck = useMemo(() => {
    const isPrincipalActive = (user as UserModelType)?.is_active;
    const roleExist = schools.find(
      (et) => et?.principal?.id === (user as UserModelType)?.id
    );
    if (isPrincipalActive == false && roleExist) {
      return true;
    } else {
      return false;
    }
  }, [user]);

  useEffect(() => {
    if (isSchoolPrincipleActiveCheck || isSchoolPrincipleCheck) {
      setIsDisabledButton(true);
    } else {
      setIsDisabledButton(false);
    }
  }, [isSchoolPrincipleCheck, isSchoolPrincipleActiveCheck]);

  const errorSchoolPrincipleCheck:JSX.Element=(
    <>
      {intl.formatMessage({
        id: "app.warnings.principals-school-level-staff-cannot-be-dcc",
      })}
    </>
  );

  const errorSchoolPrincipleActiveCheck: JSX.Element = (
    <div className="pb-3">
      {/* <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.principal.active.role_change",}
        )}
      </strong> */}
    </div>
  );

  useEffect(() => {
    showWarning();
  }, [role]);

  useEffect(() => {
    (user as UserModelType)?.is_active
      ? setUserWarningMsg(
          intl.formatMessage({
            id: "app.titles.account-reactivate-warning",
          })
        )
      : setUserWarningMsg(
          intl.formatMessage({
            id: "app.titles.account-deactivate-warning",
          })
        );
  }, [intl, user]);

  const handleRolesUpdate = (role: UserRole, staffRole?: string) => {
    if (!!user) {
      const isPrincipal = role === UserRole.Principal;
      const isSuperintendent = role === UserRole.Superintendent;
      const wasPrincipalOrSuperintendent = !isUserInvite
        ? (selectedUser as UserModelType)?.profile?.role ===
            UserRole.Principal ||
          (selectedUser as UserModelType)?.profile?.role ===
            UserRole.Superintendent
        : (user as UserInvite).role === UserRole.Superintendent ||
          (user as UserInvite).role === UserRole.Principal;

      if ((isSuperintendent && isDistrictActivePlan) || isPrincipal) {
        setShouldUpdateTeamRole(true);
      } else if (
        wasPrincipalOrSuperintendent &&
        !((isSuperintendent && isDistrictActivePlan) || isPrincipal)
      ) {
        setDemoteFromTC(true);
      }

      showWarning();
      if (isUserInvite) {
        setUser({
          ...user,
          role: role,
          custom_role_name: staffRole,
          is_dcc: isPrincipal ? false : (user as UserInvite)?.is_dcc,
        });
      } else {
        setUser({
          ...user,
          profile: {
            ...(user as UserModelType)?.profile,
            role: role,
            custom_role_name: staffRole,
            is_dcc: isPrincipal
              ? false
              : (user as UserModelType)?.profile?.is_dcc,
          },
        });
      }
    }
  };

  const onSuccess = (user?: UserModel) => {
    if (user && removedTeamMemberPlanId && shouldUpdateTeamRole) {
      dispatch(updateUserFieldInTeamMember(user));
    }
    if (user && shouldUpdateTeamRole) {
      // need to make team champion in all of the plans
      if (user && user.team_roles)
        user?.team_roles?.forEach((et: TeamRolesPlan) => {
          dispatch(
            updateTeamMember.request({
              body: {
                planId: et.plan!,
                user: {
                  id: user?.id,
                  team_role: TeamRole.TeamChampion,
                  custom_role_name: user?.profile?.custom_role_name,
                },
              },
            })
          );
        });

      // Previously roles were only changing for the active plan
      // dispatch(
      //   updateTeamMember.request({
      //     body: {
      //       planId: activePlan!,
      //       user: {
      //         id: user.id,
      //         team_role: TeamRole.TeamChampion,
      //         custom_role_name: user?.profile?.custom_role_name,
      //       },
      //     },
      //   })
      // );
    } else if (user && demoteFromTC) {
      if (user && user.team_roles)
        user?.team_roles?.forEach((et: TeamRolesPlan) => {
          dispatch(
            updateTeamMember.request({
              body: {
                planId: et.plan!,
                user: {
                  id: user?.id,
                  team_role: TeamRole.TeamMember,
                  custom_role_name: user?.profile?.custom_role_name,
                },
              },
            })
          );
        });

      // Previously roles were only changing for the active plan
      // const existingPlanFound =
      //   user && user?.team_roles
      //     ? user?.team_roles?.find(
      //         (et: TeamRolesPlan) => et.plan === activePlan
      //       )
      //     : undefined;
      // dispatch(
      //   updateTeamMember.request({
      //     body: {
      //       planId: activePlan!,
      //       user: {
      //         id: user?.id,
      //         team_role: TeamRole.TeamMember,// existingPlanFound?.team_role || TeamRole.TeamMember,
      //         custom_role_name: user?.profile?.custom_role_name,
      //       },
      //     },
      //   })
      // );
    } else if (user) {
      const existingPlanFound =
        user && user?.team_roles
          ? user?.team_roles?.find(
              (et: TeamRolesPlan) => et.plan === activePlan
            )
          : undefined;
      dispatch(
        updateTeamMember.request({
          body: {
            planId: activePlan!,
            user: {
              id: user?.id,
              team_role: existingPlanFound?.team_role || TeamRole.TeamMember,
              custom_role_name: user?.profile?.custom_role_name,
            },
          },
        })
      );
    }
    AppToaster.show({
      icon: "tick",
      intent: "success",
      message: intl.formatMessage(
        { id: "app.toaster.edit-user" },
        {
          user: user
            ? user?.first_name + " " + user?.last_name
            : selectedUser
            ? selectedUser?.first_name + " " + selectedUser?.last_name
            : "",
        }
      ),
    });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, message } = validate();
    if (isValid) {
      if (isUserInvite) {
        dispatch(
          editInvitedUser.request({
            user: user as UserInvite,
            onSuccess: onSuccess,
          })
        );
      } else {
        dispatch(
          editUser.request({
            user: user as UserModelType,
            onSuccess: onSuccess,
          })
        );
      }
      setWarning(undefined);
    } else {
      setWarning(message);
    }
  };

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    const role =
      (user as UserInvite).role || (user as UserModelType)?.profile?.role;
    const schoolsLength =
      (user as UserInvite)?.schools?.length ||
      (user as UserModelType)?.profile?.schools?.length;
    const district =
      (user as UserInvite)?.district ||
      (user as UserModelType)?.profile?.district;

    if (!role) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.users-dialog.validation.role-required",
        }),
      };
      return validationOption;
    }

    const isNonDistrictRole = [
      UserRole.Superadmin,
      UserRole.NJ_SCI_Leadership,
      UserRole.NJ_SCI_TaProvider,
    ].includes(role);

    if (isNonDistrictRole && district) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.users-dialog.validation.district-not-required",
        }),
      };
      return validationOption;
    }

    if (!isNonDistrictRole && !district) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.invitation.validation.district-required",
        }),
      };
      return validationOption;
    }

    return validationOption;
  };

  return (
    <form onSubmit={handleFormSubmit} id={form}>
      <div className="userEditform">
        <div className="nj-row ">
          <div className="nj-md-6">
            <TextField
              fullWidth
              size="small"
              id="first_name"
              name="first_name"
              label="First Name"
              variant="outlined"
              required
              value={user?.first_name ?? ""}
              onChange={handleUserChange}
              placeholder={intl.formatMessage({
                id: "app.forms.first-name.placeholder",
              })}
            />
          </div>
          <div className="nj-md-6">
            <TextField
              fullWidth
              size="small"
              id="last_name"
              name="last_name"
              required
              label="Last Name"
              variant="outlined"
              value={user?.last_name ?? ""}
              onChange={handleUserChange}
              placeholder={intl.formatMessage({
                id: "app.forms.last-name.placeholder",
              })}
            />
          </div>
        </div>
        <div className="nj-row">
          <div className="nj-md-12">
            <TextField
              fullWidth
              size="small"
              id="email"
              type="email"
              name="email"
              required
              value={user?.email ?? ""}
              onChange={handleUserChange}
              placeholder={intl.formatMessage({
                id: "app.forms.email.placeholder",
              })}
              label="District-issued Email"
              variant="outlined"
            />
          </div>
        </div>

        {isDistrictSelectorAvailable && (
          <div className="nj-row">
            <div className="nj-md-12">
              <DistrictSelector
                districtId={
                  isUserInvite
                    ? (user as UserInvite)?.district
                    : (user as UserModel<number>)?.profile?.district
                }
                isClearable
                onDistrictChange={(districtId) => {
                  if (!!user) {
                    if (isUserInvite) {
                      setUser({ ...user, district: districtId, schools: [] });
                    } else {
                      setUser({
                        ...user,
                        profile: {
                          ...(user as UserModelType)?.profile,
                          district: districtId,
                          schools: [],
                        },
                      });
                    }
                  }
                }}
              />
            </div>
          </div>
        )}

        <div className="nj-row">
          <div className="nj-md-12">
            <SchoolRoleCell
              role={role}
              schoolRole={
                isUserInvite
                  ? (user as UserInvite)?.custom_role_name
                  : (user as UserModelType)?.profile?.custom_role_name
              }
              onRoleChange={([role, staffRole]) => {
                handleRolesUpdate(role, staffRole);
              }}
              onSchoolRoleNameChange={(value) => {
                if (role) handleRolesUpdate(role, value);
              }}
              isSmallSelector={false}
            />
          </div>
        </div>
        {isCurrentUserSuperintendent && (
          <FormGroup>
            <Checkbox
              title={intl.formatMessage({
                id: "app.titles.district-climate-coordinator",
              })}
              label={intl.formatMessage({
                id: "app.titles.district-climate-coordinator",
              })}
              large
              checked={
                isUserInvite
                  ? (user as UserInvite)?.is_dcc
                  : isRoleCheck(role)
                  ? false
                  : (user as UserModelType)?.profile?.is_dcc
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const checked = e.target?.checked;
                if (!!user) {
                  if (isUserInvite) {
                    setUser({ ...user, is_dcc: checked });
                  } else {
                    setUser({
                      ...user,
                      profile: {
                        ...(user as UserModelType)?.profile,
                        is_dcc: checked,
                      },
                    });
                  }
                }
              }}
              disabled={isRoleCheck(role)}
            />
          </FormGroup>
        )}
        {/* {(isSchoolPrincipleCheck || isSchoolPrincipleActiveCheck) &&
          errorSchoolPrincipleCheck} */}
        {!isUserInvite && (
          <FormGroup>
            <Switch
              checked={(user as UserModelType)?.is_active ?? true}
              label={intl.formatMessage({
                id: "app.titles.account-active",
              })}
              onChange={handleIsActiveChange}
            />
          </FormGroup>
        )}
        {/* {isSchoolPrincipleActiveCheck && errorSchoolPrincipleActiveCheck} */}
      </div>
      {(isSchoolPrincipleCheck || isSchoolPrincipleActiveCheck) && (
        <div className="warningMsg flex items-center gap-5 mt-1  mb-3">
          <div className="warningIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="43"
              viewBox="0 0 34 43"
              fill="none"
            >
              <g clip-path="url(#clip0_5381_543)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2675 2.82316C10.4668 2.62156 10.7371 2.5083 11.0188 2.5083H22.9818C23.2636 2.5083 23.5339 2.62156 23.7332 2.82316L31.2099 10.388C31.4092 10.5896 31.5212 10.863 31.5212 11.1481V23.2518C31.5212 23.5369 31.4092 23.8104 31.2099 24.012L23.7332 31.5767C23.5339 31.7784 23.2636 31.8916 22.9818 31.8916H11.0188C10.7371 31.8916 10.4668 31.7784 10.2675 31.5767L2.79069 24.012C2.59144 23.8104 2.47949 23.5369 2.47949 23.2518V11.1481C2.47949 10.863 2.59144 10.5896 2.79069 10.388L10.2675 2.82316ZM11.459 4.6583L4.60449 11.5934V22.8066L11.459 29.7416H22.5418L29.3962 22.8066V11.5934L22.5418 4.6583H11.459Z"
                  fill="#B57F58"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17 7.5249C17.5868 7.5249 18.0625 8.0062 18.0625 8.5999V21.4999C18.0625 22.0936 17.5868 22.5749 17 22.5749C16.4132 22.5749 15.9375 22.0936 15.9375 21.4999V8.5999C15.9375 8.0062 16.4132 7.5249 17 7.5249Z"
                  fill="#B57F58"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17 24.0083C17.5868 24.0083 18.0625 24.4896 18.0625 25.0833V25.8C18.0625 26.3937 17.5868 26.875 17 26.875C16.4132 26.875 15.9375 26.3937 15.9375 25.8V25.0833C15.9375 24.4896 16.4132 24.0083 17 24.0083Z"
                  fill="#B57F58"
                />
              </g>
              <defs>
                <clipPath id="clip0_5381_543">
                  <rect width="34" height="43" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>{errorSchoolPrincipleCheck}</div>
        </div>
      )}

      {/* <FormGroup
        label={intl.formatMessage({
          id: "app.invites-table.cells.first-name",
        })}
      >
        <InputGroup
          id="first_name"
          name="first_name"
          required
          fill
          value={user?.first_name ?? ""}
          onChange={handleUserChange}
          placeholder={intl.formatMessage({
            id: "app.forms.first-name.placeholder",
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({
          id: "app.invites-table.cells.last-name",
        })}
      >
        <InputGroup
          id="last_name"
          name="last_name"
          required
          fill
          value={user?.last_name ?? ""}
          onChange={handleUserChange}
          placeholder={intl.formatMessage({
            id: "app.forms.last-name.placeholder",
          })}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({
          id: "app.users-table.columns.email",
        })}
      >
        <InputGroup
          id="email"
          type="email"
          name="email"
          required
          fill
          value={user?.email ?? ""}
          onChange={handleUserChange}
          placeholder={intl.formatMessage({
            id: "app.forms.email.placeholder",
          })}
        />
      </FormGroup>
      {isDistrictSelectorAvailable && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.district",
          })}
        >
          <DistrictSelector
            districtId={
              isUserInvite
                ? (user as UserInvite)?.district
                : (user as UserModel<number>)?.profile?.district
            }
            isClearable
            onDistrictChange={(districtId) => {
              if (!!user) {
                if (isUserInvite) {
                  setUser({ ...user, district: districtId, schools: [] });
                } else {
                  setUser({
                    ...user,
                    profile: {
                      ...(user as UserModelType)?.profile,
                      district: districtId,
                      schools: [],
                    },
                  });
                }
              }
            }}
          />
        </FormGroup>
      )}
      <FormGroup
        label={intl.formatMessage({
          id: "app.titles.role",
        })}
      >
        <SchoolRoleCell
          role={role}
          schoolRole={
            isUserInvite
              ? (user as UserInvite)?.custom_role_name
              : (user as UserModelType)?.profile?.custom_role_name
          }
          onRoleChange={([role, staffRole]) => {
            handleRolesUpdate(role, staffRole);
          }}
          onSchoolRoleNameChange={(value) => {
            if (role) handleRolesUpdate(role, value);
          }}
          isSmallSelector={false}
        />
      </FormGroup> */}
      {/* {isCurrentUserSuperintendent && (
        <FormGroup>
          <Checkbox
            title={intl.formatMessage({
              id: "app.titles.district-climate-coordinator",
            })}
            label={intl.formatMessage({
              id: "app.titles.district-climate-coordinator",
            })}
            large
            checked={
              isUserInvite
                ? (user as UserInvite)?.is_dcc
                : isRoleCheck(role)
                  ? false
                  : (user as UserModelType)?.profile?.is_dcc
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const checked = e.target?.checked;
              if (!!user) {
                if (isUserInvite) {
                  setUser({ ...user, is_dcc: checked });
                } else {
                  setUser({
                    ...user,
                    profile: {
                      ...(user as UserModelType)?.profile,
                      is_dcc: checked,
                    },
                  });
                }
              }
            }}
            disabled={isRoleCheck(role)}
          />
        </FormGroup>
      )} */}
      {/* {(isSchoolPrincipleCheck || isSchoolPrincipleActiveCheck) &&
        errorSchoolPrincipleCheck}
      {!isUserInvite && (
        <FormGroup>
          <Switch
            checked={(user as UserModelType)?.is_active ?? true}
            label={intl.formatMessage({
              id: "app.titles.account-active",
            })}
            onChange={handleIsActiveChange}
          />
        </FormGroup>
      )} */}
      {/* {isSchoolPrincipleActiveCheck && errorSchoolPrincipleActiveCheck} */}

      {isVisibleWarning && (
        <div className="my-3">
          <Callout intent="warning">{userWarningMsg}</Callout>
        </div>
      )}
    </form>
  );
};

export default UserEditingForm;
