import React, { FunctionComponent, useMemo } from "react";
import _ from "lodash";
import { Locale, LocaleDisplayedValues } from "../../../store/UIState";
import { Divider, Icon, Tag } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { getTranslationLocales } from "./index";
import {
  DomainAnswerSet,
  SurveyDemographic,
  TranslatedAnswerSet,
} from "../../../types";

type OwnProps = {
  domainAnswerSet: DomainAnswerSet;
  btnMinimal?: boolean;
};

type Props = OwnProps;

const AnswerSetItem: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { domainAnswerSet, btnMinimal = true } = props;

  const translationLocales = useMemo(
    () => getTranslationLocales(domainAnswerSet),
    [domainAnswerSet]
  );

  return (
    <div className="flex flex-1 flex-col">
      {_.chain(domainAnswerSet)
        .pick(Object.values(SurveyDemographic))
        .entries()
        .filter(
          ([demographic, locales]: [
            string,
            TranslatedAnswerSet | undefined
          ]) => {
            const enChoices = locales?.[Locale.English];
            return !!(enChoices && Object.keys(enChoices).length);
          }
        )
        .map(
          (
            [demographic, locales]: [SurveyDemographic, TranslatedAnswerSet],
            index
          ) => {
            const enChoices = locales?.[Locale.English];

            return (
              <>
                {!!index && <Divider />}
                <div className="flex flex-wrap -mx-1 -mt-1">
                  <span className="mx-1 mt-1">
                    {intl.formatMessage(
                      {
                        id: "app.domains.answers.for",
                      },
                      {
                        demographic: intl.formatMessage({
                          id: `app.filters.respondent-type.${demographic}`,
                        }),
                      }
                    )}
                  </span>
                  {_.chain(enChoices)
                    .values()
                    .map((text, index) => (
                      <Tag
                        key={index}
                        minimal={btnMinimal}
                        intent="primary"
                        className="mx-1 mt-1"
                      >
                        {text}
                      </Tag>
                    ))
                    .value()}
                </div>
              </>
            );
          }
        )
        .value()}

      {!!translationLocales.length && (
        <>
          <hr className="border-top border-gray-500 my-1" />
          <div className="flex flex-wrap -m-1">
            <div className="m-1">
              <span>
                {intl.formatMessage({ id: "app.titles.translations" })}
              </span>
              <Icon className="ml-1" icon="translate" />:
            </div>
            {translationLocales.map((locale) => (
              <Tag
                key={locale.locale}
                intent={locale.filled ? "success" : "warning"}
                minimal={btnMinimal}
                className="m-1"
              >
                {LocaleDisplayedValues[locale.locale as Locale]}
              </Tag>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AnswerSetItem;
