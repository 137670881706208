import React, { FunctionComponent, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { NOT_APPLICABLE_KEY, SurveyDemographic } from "../../../../types";
import _ from "lodash";
import { Locale } from "../../../../store/UIState";
import useResize from "./useResize";

type OwnProps = {
  tabId: string;
};
type Props = OwnProps;

const AnswerChoicesHeader: FunctionComponent<Props> = (props) => {
  const { tabId } = props;

  const selectedDomainAnswerSet = useSelector(
    (s) => s.domainsAnswerSet.selectedDomainAnswerSet
  );

  const domainAnswerSetByDemographic = useMemo(() => {
    return Object.values(SurveyDemographic).includes(tabId as any)
      ? selectedDomainAnswerSet?.[tabId as SurveyDemographic]
      : undefined;
  }, [tabId, selectedDomainAnswerSet]);

  const selectedLocale = useSelector((s) => s.UIState.locale);

  const domainAnswerSetOptions = useMemo(() => {
    return domainAnswerSetByDemographic
      ? _.chain(
          domainAnswerSetByDemographic[selectedLocale] ??
            domainAnswerSetByDemographic[Locale.English]
        )
          .entries()
          .map(([index, value]) => ({ index: +index, value }))
          .filter((a) => a.index != NOT_APPLICABLE_KEY)
          .value()
      : [];
  }, [selectedLocale, domainAnswerSetByDemographic]);

  const ref = useRef<HTMLDivElement>(null);

  const containerPadding = 8;
  const numberWidth = 9.2;

  const { width: clientWidth } = useResize(ref);
  const answerChoiceLabelWidth = useMemo(
    () => clientWidth / domainAnswerSetOptions.length,
    [clientWidth, domainAnswerSetOptions.length]
  );
  return (
    <>
      {/*<div*/}
      {/*  ref={ref}*/}
      {/*  className={`answer-choices-header ${*/}
      {/*    domainAnswerSetOptions.length % 2 ? "total-odd" : ""*/}
      {/*  }`}*/}
      {/*>*/}
      {/*  {domainAnswerSetOptions.map((aso, index, array) => {*/}
      {/*    let styles: CSSProperties | undefined;*/}
      {/*    if (index !== 0 && index !== array.length - 1 && array.length >= 4) {*/}
      {/*      const offset =*/}
      {/*        ((index + 0.5) * answerChoiceLabelWidth -*/}
      {/*          containerPadding -*/}
      {/*          index **/}
      {/*            ((array.length * answerChoiceLabelWidth -*/}
      {/*              2 * containerPadding -*/}
      {/*              array.length * numberWidth) /*/}
      {/*              (array.length - 1)) -*/}
      {/*          (index + 0.5) * numberWidth) **/}
      {/*        -1;*/}

      {/*      styles = {*/}
      {/*        marginLeft: `${offset}px`,*/}
      {/*      };*/}
      {/*    }*/}

      {/*    return (*/}
      {/*      <div key={index}>*/}
      {/*        <div style={styles}>{aso.value}</div>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
      {/*<div className="flex justify-between px-2 pb-1 font-bold">*/}
      {/*  {domainAnswerSetOptions.map((aso: any) => (*/}
      {/*    <div>{aso.index}</div>*/}
      {/*  ))}*/}
      {/*</div>*/}
      <div className="flex justify-between">
        {domainAnswerSetOptions.map((aso: any, index) => (
          <div key={index} className="font-bold">
            {aso.index}
          </div>
        ))}
      </div>
    </>
  );
};

export default AnswerChoicesHeader;
