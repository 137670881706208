import React, { FunctionComponent, useMemo } from 'react';
import './NoGraphData.scss';
import { useIntl } from 'react-intl';
import { Line } from 'react-chartjs-2';


type OwnProps = {};

type Props = OwnProps;

const NoGraphData: FunctionComponent<Props> = (props) => {
    const intl = useIntl();




    const data:any= useMemo(() => ({
       
        datasets: [],
      }), []);
      const options:any = useMemo(() => ({
        maintainAspectRatio	:false,
        responsive: true,
        
        legend:{display:false},
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "black",
                maxRotation: 70,
                minRotation: 70,
                maxTicksLimit: 12,
              },
              gridLines: {
                zeroLineColor: "black",
                color: "white",
              },
            },
           
          ],
  
          yAxes: [
            {
              id: "score",
              type: "linear",
              ticks: {
                min: 1,
                max: 4,
                stepSize: 1,
                beginAtZero: true,
                fontColor: "black",
                callback: (value:any) => (value !== 0 ? value : '')
  
              },
              gridLines: {
                zeroLineColor: "black",
                color: ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)' ,'black','rgba(0, 0, 0, 0.1)'],
                drawTicks: { font: { color: "white" } },
              },
            },
          ],
        },
      }), []);


      const data2:any= useMemo(() => ({
       
        datasets: [],
      }), []);
      const options2:any = useMemo(() => ({
        maintainAspectRatio	:false,
        responsive: true,
        
        legend:{display:false},
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "black",maxRotation: 70,minRotation: 70,maxTicksLimit: 12,
              },
              gridLines: {
                zeroLineColor: "black",
                color: "white",
              },
            },
          ],
          yAxes: [
            {
              id: "score",
              type: "linear",
              ticks: {
                min: 0,
                max: 100,
                stepSize: 20,
                beginAtZero: true,
                fontColor: "black",
                callback: (value:any) => (value !== 0 ? value : '')
              },
              gridLines: {
                zeroLineColor: "black",
              },
            },
          ],
        },
      }), []);



    return (
        <>
        <div className="noGraphData" style={{ width: '', height: '300px' }}> 
        <h2 className='d-flex justify-center'><b>{intl.formatMessage({ id: "app.title.strategies.survey-data-title" })}</b> </h2>

        <Line data={data} options={options} />
    

            <div className="notfound-message no-graph-found">
                
                    <div className="notfound-message_inner">
                        <h3>
                            {intl.formatMessage({ id: "app.title.goal-progress-monitoring.no-data-found" })}
                        </h3>
                        <p>
                            {intl.formatMessage({ id: "app.title.goal-progress-monitoring.no-data-found.description"  })}
                           
                        </p>
                    </div>
                
            </div>
            </div>

            
            <div className="noGraphData" style={{ width: '', height: '300px' }}> 
            <h2 className='d-flex justify-center'><b>{intl.formatMessage({ id: "app.title.strategies.strategy-implimentation-graph-title" })}</b> </h2>
            
             <Line data={data2} options={options2} />
            <div className="notfound-message no-graph-found">
                
            <div className="notfound-message_inner">
                        <h3>
                            {intl.formatMessage({ id: "app.title.goal-progress-monitoring.no-data-found" })}
                        </h3>
                        <p>
                            {intl.formatMessage({ id: "app.title.goal-progress-monitoring.no-data-found.description"  })}
                           
                        </p>
                    </div>
                
            </div>
            </div>
        </>
    )
}


export default NoGraphData;