import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Callout } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { NonAuthRoutes } from "../../App";

type OwnProps = {
  errorCode: 403 | 404 | 400;
};

type Props = OwnProps;

const InvitationErrorCallout: FunctionComponent<Props> = (props) => {
  const { errorCode } = props;

  const intl = useIntl();

  const loginFormLink = useCallback(
    (part) => (
      <Link to={NonAuthRoutes.Login} className="underline text-blue-500">
        {part}
      </Link>
    ),
    []
  );

  const { title, content } = useMemo(() => {
    if (errorCode === 400) {
      return {
        title: intl.formatMessage({ id: "app.invitation.expired" }),
        content: intl.formatMessage(
          { id: "app.invitation.expired.info" },
          {
            a: (part) => loginFormLink(part),
          }
        ),
      };
    } else if (errorCode === 403) {
      return {
        title: intl.formatMessage({
          id: "app.invitation.email-already-registered",
        }),
        content: intl.formatMessage(
          { id: "app.invitation.email-already-registered.info" },
          {
            a: (part) => loginFormLink(part),
          }
        ),
      };
    } else {
      return {
        title: intl.formatMessage({ id: "app.invitation.not-found" }),
        content: intl.formatMessage({ id: "app.invitation.not-found.info" }),
      };
    }
  }, [errorCode, loginFormLink]);

  return (
    <Callout intent="warning" title={title}>
      {content}
    </Callout>
  );
};

export default InvitationErrorCallout;
