import { NonIdealState } from "@blueprintjs/core";
import React from "react";
import { useIntl } from "react-intl";

type OwnProps = {
  createdBy?: string;
  item: string;
};

type Props = OwnProps;

const StrategyDescription: React.FC<Props> = ({ item, createdBy }: Props) => {
  const intl = useIntl();

  return (
    <>
      {!!createdBy && (
        <div className="mb-2">{`${intl.formatMessage({
          id: "app.titles.created-by",
        })} ${createdBy}`}</div>
      )}

      {!!item ? (
        <div
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        />
      ) : (
        <NonIdealState
          icon="zoom-out"
          title={intl.formatMessage({ id: "app.titles.no-data" })}
          description={intl.formatMessage({
            id:
              "app.strategies.strategy-details-dialog.update-selected-strategy",
          })}
        />
      )}
    </>
  );
};

export default StrategyDescription;
