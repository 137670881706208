import { CommonText } from "../../types";
import { createAction, createAsyncAction } from "typesafe-actions";

export const getCommonTexts = createAsyncAction(
  "@common-text/GET_COMMON_TEXT_REQUEST",
  "@common-text/GET_COMMON_TEXT_SUCCESS",
  "@common-text/GET_COMMON_TEXT_FAILURE"
)<void, CommonText[], Error>();

export const addCommonText = createAsyncAction(
  "@common-text/ADD_SCHOOL_REQUEST",
  "@common-text/ADD_SCHOOL_SUCCESS",
  "@common-text/ADD_SCHOOL_FAILURE"
)<Pick<CommonText<number>, "common_text" | "questions">, CommonText, Error>();

export const updateCommonText = createAsyncAction(
  "@common-text/UPDATE_SCHOOL_REQUEST",
  "@common-text/UPDATE_SCHOOL_SUCCESS",
  "@common-text/UPDATE_SCHOOL_FAILURE"
)<
  Pick<CommonText<number>, "id" | "common_text" | "questions">,
  CommonText,
  Error
>();

export const deleteCommonText = createAsyncAction(
  "@common-text/DELETE_SCHOOL_REQUEST",
  "@common-text/DELETE_SCHOOL_SUCCESS",
  "@common-text/DELETE_SCHOOL_FAILURE"
)<number, number, Error>();

export const showCommonTextUpsertDialog = createAction(
  "@common-text/SHOW_COMMON_TEXT_UPSERT_DIALOG"
)<void | CommonText>();
export const hideCommonTextUpsertDialog = createAction(
  "@common-text/HIDE_COMMON_TEXT_UPSERT_DIALOG"
)<void>();
