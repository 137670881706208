import React, { FunctionComponent } from "react";
import { AnchorButton, ButtonGroup, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { AppToaster } from "../../../helpers/toaster";
import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

type OwnProps = {
  extension: string;
  attachmentUrl?: string;
  externalUrl?: string;
  newDesign?: boolean;
  outline?: boolean;
  title?: string;
};

type Props = OwnProps;

const DownloadButton: FunctionComponent<Props> = (props) => {
  const {
    externalUrl,
    attachmentUrl = externalUrl,
    extension,
    newDesign,
    outline,
    title,
  } = props;

  const intl = useIntl();

  const handleDownloadClick = async (extension:string|null|undefined = null) => {
    // if doc
    if (attachmentUrl && extension == ".docx") {
      try {
        // Fetch from the server
        const response = await fetch(attachmentUrl ?? "");
        if (!response.ok) throw new Error("Network response was not ok");

        // Convert the response to a Blob
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = title ?? "Document"; // Specify the desired file name
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        return window.URL.revokeObjectURL(url);
      } catch (error) {
        return console.error("Download failed", error);
      }
    } else if (attachmentUrl) {
      const link = document.createElement("a");
      if (externalUrl) {
        link.setAttribute("target", "_blank");
      }
      link.href = attachmentUrl;
      link.target = "_blank";
      document.body.append(link);
      link.click();
      link.remove();
    }
  };

  const handleUrlCopy = () => {
    if (attachmentUrl) {
      const tempInput = document.createElement("input");

      document.body.append(tempInput);
      tempInput.value = attachmentUrl;
      tempInput.focus();
      tempInput.select();
      document.execCommand("copy");
      tempInput.remove();

      AppToaster.show({
        message: intl.formatMessage({
          id: "app.download-button.url.copied-message",
        }),
        intent: "success",
      });
    }
  };

  return (
    <>
      {!newDesign ? (
        <ButtonGroup
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AnchorButton
            disabled={!attachmentUrl}
            intent="primary"
            text={intl.formatMessage(
              { id: "app.titles.download" },
              { extension: extension }
            )}
            onClick={()=>handleDownloadClick()}
          />
          <AnchorButton
            disabled={!attachmentUrl}
            intent="primary"
            icon="clipboard"
            title={intl.formatMessage({ id: "app.download-button.url.copy" })}
            onClick={handleUrlCopy}
          />
        </ButtonGroup>
      ) : (
        <Button
          variant="outlined"
          className="stroke-btn"
          startIcon={<FileDownloadOutlinedIcon />}
          disabled={!attachmentUrl}
          onClick={() => handleDownloadClick(extension)}
        >
          {intl.formatMessage({ id: "app.title.download" })}{" "}
          {intl.formatMessage(
            {
              id: `app.titles.download-extentions`,
            },
            { extention: extension?.replace(".", "") }
          )}
        </Button>
      )}
    </>
  );
};

export default DownloadButton;
