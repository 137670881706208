import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { MenuItem, Spinner } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/actions";
import { useLoading } from "../../helpers/hooks/useLoading";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../store/UIState/actions";
import {
  createGoal,
  resetNewGoalState,
  updateGoal,
  updateNewGoalState,
} from "../../store/goals/actions";
import { AddGoalRequest } from "../../api/goals/types";
import { Goal } from "../../types";

type Props = {};

const LogOutMenuItem: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.auth.loading.logout);
  const error = useSelector((s) => s.auth.errors.logout);

  useLoading({ loading, error });

  // autosave goal - start
  const { goalStatement, selectedGoal } = useSelector(
    (s) => s.goals.newGoalState
  );

  const [isNewGoal, setNewGoal] = useState<boolean>(false);
  const lastGoal = useSelector((s) => s.goals.goals.slice(-1)[0]);

  const updatedSelectedGoal = useSelector((s) =>
    s.goals.goals.filter((s:Goal) => s.id === selectedGoal?.id)
  );

  // useEffect(() => {
  //   isNewGoal &&
  //     lastGoal &&
  //     dispatch(updateNewGoalState({ show: true, selectedGoal: lastGoal }));
  // }, [isNewGoal, lastGoal]);

  useEffect(() => {
    dispatch(
      updateNewGoalState({
        show: true,
        selectedGoal: updatedSelectedGoal[0],
      })
    );
  }, [updatedSelectedGoal[0]]);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const selectedPriority = useSelector((s) => s.goals.selectedPriority);
  const selectedPriorityIds = useMemo(() => {
    let priorityIds = selectedPriority.map((s) => s.id);

    return priorityIds;
  }, [selectedPriority]);
  // autosave goal - end

  const handleLogOut = () => {
    if (
      window.location.pathname.includes("plan/goals") &&
      selectedPriorityIds.length > 0
    ) {
      showWarningDialog();
    } else {
      dispatch(logout.request());
    }
  };

  const saveGoal = () => {
    const request: AddGoalRequest = {
      is_active: false,
      planId: activePlanId!,
      statement: goalStatement ? goalStatement : "",
      targets: [],
      desired_outcome: "",
      priority_areas: selectedPriorityIds,
      is_completed: false,
    };

    if (selectedGoal) {
      selectedGoal.targets.forEach((el:any) => {
        request.targets?.push({
          direction: el.direction,
          target_date: el.target_date,
          target_value: el.target_value,
          domain_priority: el.domain_priority,
          dataset: el.dataset,
        });
      });

      dispatch(updateGoal.request({ ...request, goalId: selectedGoal.id }));
    } else {
      dispatch(createGoal.request(request));
      setNewGoal(true);
    }
  };

  const showWarningDialog = () => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          saveGoal();
          setTimeout(() => {
            dispatch(resetNewGoalState());
            // dispatch(updateNewGoalState({ show: false }));
            dispatch(hideConfirmDialog());
            dispatch(logout.request());
          }, 200);
        },
        show: true,
        intent: "warning",
        icon: "info-sign",
        text: intl.formatMessage({ id: "app.goals.new-goal.warning-dialogs" }),
        confirmButtonText: intl.formatMessage({
          id: "app.titles.save-and-continue-later",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.cancel",
        }),
        onCancel: () => {
          dispatch(hideConfirmDialog());
        },
        canOutsideClickCancel: true,
      })
    );
  };

  return (
    <>
      {loading ? (
        <Spinner intent="primary" />
      ) : (
        <MenuItem
          text={intl.formatMessage({ id: "app.menu.log-out" })}
          icon="log-out"
          onClick={handleLogOut}
          shouldDismissPopover={false}
        />
      )}
    </>
  );
};

export default LogOutMenuItem;
