import { RootState } from "typesafe-actions";
import { UserRole } from "../../types";

export function getIsAuthenticated(state: RootState) {
  return state.auth.isAuthenticated;
}

export function getCurrentUserRole(state: RootState) {
  return state.auth.authenticatedUser?.profile?.role;
}

export function getCurrentUserDistrictId(state: RootState) {
  return state.auth.authenticatedUser?.profile?.district?.id;
}

export function getCurrentUserDistrictName(state: RootState) {
  return state.auth.authenticatedUser?.profile?.district?.name;
}

export function getCurrentUserDistrict(state: RootState) {
  return state.auth.authenticatedUser?.profile?.district;
}

export function selectIsAdmin(state: RootState) {
  const userRole = state.auth.authenticatedUser?.profile?.role;
  return (
    userRole === UserRole.Superadmin ||
    userRole === UserRole.NJ_SCI_Leadership ||
    userRole === UserRole.NJ_SCI_TaProvider
  );
}

export function getCurrentUserId(state: RootState) {
  return state.auth.authenticatedUser?.id;
}

export function getCurrentUserIsDCC(state: RootState) {
  return !!state.auth.authenticatedUser?.profile?.is_dcc;
}

export function getCurrentUserTeamRole(state: RootState) {
  const activePlanId = state.plans.activePlan?.id;
  if (activePlanId) {
    return state.auth.authenticatedUser?.team_roles?.find(
      (tr) => tr.plan === activePlanId
    )?.team_role;
  }
  return undefined;
}

export function getCustomRoleName(state: RootState) {
  return state.auth.authenticatedUser?.profile?.custom_role_name;
}

export function getIsSetupCompleted(state: RootState) {
  return state.auth.authenticatedUser?.profile?.is_setup_completed;
}
