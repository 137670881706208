import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import TooltipComponent from "../tour/TooltipComponent";
import { useSelector } from "react-redux";
import { DistrictSetupTaskKey, UserRole } from "../../types";
import useUserRole from "../../helpers/hooks/useUserRole";
import { getCurrentUserRole } from "../../store/auth/selectors";

type OwnProps = {};

type Props = OwnProps;

const LearningModuleTour: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const userRole = useSelector(getCurrentUserRole);

  const steps = useMemo((): Step[] => {
    return [
      {
        target: ".wrap-title .bp3-heading",
        content: intl.formatMessage({
          id: `app.tours.learning-module${
            userRole === UserRole.Superintendent ? ".superintendent" : ""
          }`,
        }),
        disableBeacon: true,
      },
    ];
  }, []);

  const [show, setShow] = useState<boolean>(false);

  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  useEffect(() => {
    setupCompleted
      ? setShow(false)
      : setTimeout(() => {
          setShow(!tours[DistrictSetupTaskKey.CommunicateWithPrincipals]);
        }, 200);
  }, [tours, setupCompleted]);

  const handleJoyrideAction = (data: CallBackProps) => {
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      setShow(false);
    }
  };

  return (
    <ReactJoyride
      run={show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
    />
  );
};

export default LearningModuleTour;
