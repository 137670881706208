import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Button, NonIdealState } from "@blueprintjs/core";
import { showMeetingTemplateDialog } from "../../store/meeting-templates/actions";

type OwnProps = {};

type Props = OwnProps;

const NoMeetingTemplates: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    dispatch(showMeetingTemplateDialog({}));
  };
  return (
    <NonIdealState
      icon="highlight"
      title={intl.formatMessage({ id: "app.meeting-templates.empty" })}
      description={intl.formatMessage({
        id: "app.meeting-templates.tip",
      })}
      action={
        <Button
          large
          intent={"primary"}
          text={intl.formatMessage({
            id: "app.meeting-templates.create-new",
          })}
          className="px-3 capitalize"
          onClick={handleDialogOpen}
        />
      }
    />
  );
};

export default NoMeetingTemplates;
