import React from "react";
import { SurveyDemographic } from "../../../types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@blueprintjs/core";
import { downloadCSVReport } from "../../../store/survey-reports/actions";

type OwnProps = {
  surveyDeploymentId: number;
  schoolId: number;
  selectedDemographic: SurveyDemographic;
  surveyName: string;
};

type Props = OwnProps;

const DownloadReportCSVButton: React.FC<Props> = ({
  surveyDeploymentId,
  schoolId,
  selectedDemographic,
  surveyName,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loading = useSelector((s) => s.surveyReports.loading.downloadCSVReport);

  const handleDownloadCSVReportClick = () => {
    dispatch(
      downloadCSVReport.request({
        surveyDeploymentId: surveyDeploymentId,
        schoolId: schoolId,
        demographic: selectedDemographic,
        surveyName: surveyName,
      })
    );
  };

  return (
    <Button
      className="ml-2 text-center"
      intent="primary"
      icon="download"
      title={intl.formatMessage({
        id: "app.survey-report.breadcrumb.download-csv-report",
      })}
      text={intl.formatMessage({
        id: "app.survey-report.breadcrumb.download-csv-report",
      })}
      onClick={handleDownloadCSVReportClick}
      loading={loading}
    />
  );
};

export default DownloadReportCSVButton;
