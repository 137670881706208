import { createAction, createAsyncAction } from "typesafe-actions";
import { School } from "../../types";
import {
  AddSchoolRequest,
  GetSchoolsByDistrictsRequest,
  UpdateSchoolRequest,
} from "../../api/schools/types";

export const getSchools = createAsyncAction(
  "@schools/GET_SCHOOLS_REQUEST",
  "@schools/GET_SCHOOLS_SUCCESS",
  "@schools/GET_SCHOOLS_FAILURE"
)<void, School[], Error>();

export const addSchool = createAsyncAction(
  "@schools/ADD_SCHOOL_REQUEST",
  "@schools/ADD_SCHOOL_SUCCESS",
  "@schools/ADD_SCHOOL_FAILURE"
)<AddSchoolRequest, School, Error>();

export const updateSchool = createAsyncAction(
  "@schools/UPDATE_SCHOOL_REQUEST",
  "@schools/UPDATE_SCHOOL_SUCCESS",
  "@schools/UPDATE_SCHOOL_FAILURE"
)<UpdateSchoolRequest, School, Error>();

export const deleteSchool = createAsyncAction(
  "@schools/DELETE_SCHOOL_REQUEST",
  "@schools/DELETE_SCHOOL_SUCCESS",
  "@schools/DELETE_SCHOOL_FAILURE"
)<number, number, Error>();

export const setSelectedSchool = createAction("@schools/SET_SELECTED_SCHOOL")<
  School | undefined
>();
export const showUpsertSchoolDialog = createAction(
  "@schools/SHOW_UPSERT_SCHOOL_DIALOG"
)<School | undefined>();
export const hideUpsertSchoolDialog = createAction(
  "@schools/HIDE_UPSERT_SCHOOL_DIALOG"
)<void>();

export const getSchoolsByDistrict = createAsyncAction(
  "@schools/GET_SCHOOLS_BY_DISTRICT_REQUEST",
  "@schools/GET_SCHOOLS_BY_DISTRICT_SUCCESS",
  "@schools/GET_SCHOOLS_BY_DISTRICT_FAILURE"
)<number, School[], Error>();

export const getSchoolsByDistricts = createAsyncAction(
  "@schools/GET_SCHOOLS_BY_DISTRICTS_REQUEST",
  "@schools/GET_SCHOOLS_BY_DISTRICTS_SUCCESS",
  "@schools/GET_SCHOOLS_BY_DISTRICTS_FAILURE"
)<GetSchoolsByDistrictsRequest, School[], Error>();
