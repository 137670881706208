import { Plan, PlanStatus } from "../../types";
import { createAction, createAsyncAction } from "typesafe-actions";

export const getPlans = createAsyncAction(
  "@plans/GET_PLANS_REQUEST",
  "@plans/GET_PLANS_SUCCESS",
  "@plans/GET_PLANS_FAILURE"
)<void, Plan[], Error>();

export const addPlan = createAsyncAction(
  "@plans/ADD_PLAN_REQUEST",
  "@plans/ADD_PLAN_SUCCESS",
  "@plans/ADD_PLAN_FAILURE"
)<void, Plan, Error>();

export const updatePlan = createAsyncAction(
  "@plans/UPDATE_PLAN_REQUEST",
  "@plans/UPDATE_PLAN_SUCCESS",
  "@plans/UPDATE_PLAN_FAILURE"
)<any, Plan, Error>();

export const changeActivePlan = createAction(
  "@plans/CHANGE_ACTIVE_PLAN"
)<Plan>();

export const getPlanStatus = createAsyncAction(
  "@plans/GET_PLAN_STATUS_REQUEST",
  "@plans/GET_PLAN_STATUS_SUCCESS",
  "@plans/GET_PLAN_STATUS_FAILURE"
)<number, PlanStatus, Error>();
