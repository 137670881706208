import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getSurveys } from "../../../store/surveys/actions";
import { SelectorOptionType } from "../../../types";
import Select from "react-select";

type OwnProps = {
  surveyId?: number;
  onSurveyChange: (selectedDistrictId?: number) => any;
  isDisabled?: boolean;
  selectedSurvey?: SelectorOptionType;
};

type Props = OwnProps;

const SurveySelector: React.FC<Props> = (props: Props) => {
  const { surveyId, onSurveyChange, isDisabled, selectedSurvey } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const surveys = useSelector((s) => s.surveys.surveys);
  const surveysOptions: SelectorOptionType[] = useMemo(
    () => surveys.map((s) => ({ value: s.id, label: s.name })),
    [surveys]
  );

  const loading = useSelector((s) => s.surveys.loading.getSurveys);

  useEffect(() => {
    dispatch(getSurveys.request());
  }, []);

  const handleSurveyChange = (value?: number) => {
    onSurveyChange(value);
  };

  const displayNoOptions = useCallback(() => {
    return intl.formatMessage({ id: "app.titles.no-options" });
  }, []);

  return (
    <Select
      value={
        isDisabled
          ? selectedSurvey
          : surveysOptions.find((option) => {
              return option.value === surveyId;
            })
      }
      placeholder={intl.formatMessage({
        id: "app.forms.survey-deployment-form.survey-selector.placeholder",
      })}
      noOptionsMessage={displayNoOptions}
      options={surveysOptions}
      isSearchable
      isClearable
      isDisabled={isDisabled}
      isLoading={loading}
      onChange={(value) => handleSurveyChange(value?.value)}
    />
  );
};

export default SurveySelector;
