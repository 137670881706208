import { createAction, createAsyncAction } from "typesafe-actions";
import { Activity, ActivityScopeEnum, ActivityTagEnum } from "../../types";
import { ActivityRequest } from "../../api/activities/types";

export const getActivities = createAsyncAction(
  "@activities/GET_ACTIVITIES_REQUEST",
  "@activities/GET_ACTIVITIES_SUCCESS",
  "@activities/GET_ACTIVITIES_FAILURE"
)<{ type?: ActivityScopeEnum; tag?: ActivityTagEnum }, Activity[], Error>();

export const getActivityByName = createAsyncAction(
  "@activities/GET_ACTIVITY_BY_NAME_REQUEST",
  "@activities/GET_ACTIVITY_BY_NAME_SUCCESS",
  "@activities/GET_ACTIVITY_BY_NAME_FAILURE"
)<{ name: string; onComplete?: Function }, Activity | undefined, Error>();

export const showActivityVideoDialog = createAction(
  "@activities/SHOW_ACTIVITY_VIDEO_DIALOG"
)<{ activity: Activity; viewable_field: string }>();

export const hideActivityVideoDialog = createAction(
  "@activities/HIDE_ACTIVITY_VIDEO_DIALOG"
)<void>();

export const showActivityDialog = createAction(
  "@activities/SHOW_ACTIVITY_DIALOG"
)<{ activity?: Activity }>();

export const hideActivityDialog = createAction(
  "@activities/HIDE_ACTIVITY_DIALOG"
)<void>();

export const saveActivity = createAsyncAction(
  "@activities/SAVE_ACTIVITY_REQUEST",
  "@activities/SAVE_ACTIVITY_SUCCESS",
  "@activities/SAVE_ACTIVITY_FAILURE"
)<ActivityRequest, Activity, Error>();

export const updateActivity = createAsyncAction(
  "@activities/UPDATE_ACTIVITY_REQUEST",
  "@activities/UPDATE_ACTIVITY_SUCCESS",
  "@activities/UPDATE_ACTIVITY_FAILURE"
)<{ id: number; body: ActivityRequest }, Activity, Error>();

export const deleteActivity = createAsyncAction(
  "@activities/DELETE_ACTIVITY_REQUEST",
  "@activities/DELETE_ACTIVITY_SUCCESS",
  "@activities/DELETE_ACTIVITY_FAILURE"
)<number, number, Error>();

export const getActivityById = createAsyncAction(
  "@activities/GET_ACTIVITY_BY_ID_REQUEST",
  "@activities/GET_ACTIVITY_BY_ID_SUCCESS",
  "@activities/GET_ACTIVITY_BY_ID_FAILURE"
)<number, Activity, Error>();
