import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { School, SelectorOptionType } from "../../../types";
import Select, { StylesConfig } from "react-select";

type Props<IsMulti extends boolean> = {
  schools: School[];
  schoolId?: IsMulti extends true ? number[] : number;
  onSchoolChange: (values?: IsMulti extends true ? number[] : number) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
};

const SchoolSelector = <IsMulti extends boolean = false>(
  props: Props<IsMulti>
) => {
  const {
    schools,
    schoolId,
    onSchoolChange,
    disabled,
    loading,
    className,
  } = props;
  const intl = useIntl();

  const schoolsOptions: SelectorOptionType[] = useMemo(() => {
    return schools.map((s) => ({ value: s.id, label: s.name }));
  }, [schools]);

  const handleSchoolChange = (value?: any) => {
    onSchoolChange(
      (isArray ? value?.map((v: any) => v.value) : value?.value) as any
    );
  };

  const customStyles: StylesConfig<any, any> = {
    control: (base, state) => ({
      ...base,
      background: "#fafafa",
    }),
  };
  const displayNoOptions = useCallback(() => {
    return intl.formatMessage({ id: "app.titles.no-options" });
  }, []);

  const isArray = useMemo(() => {
    return Array.isArray(schoolId);
  }, [schoolId]);

  const selectedValue = useMemo(() => {
    return isArray
      ? (schoolId as number[])?.map((sId) =>
          schoolsOptions.find((option) => {
            return option.value === sId;
          })
        )
      : schoolsOptions.find((option) => {
          return option.value === schoolId;
        });
  }, [schoolsOptions, schoolId, isArray]);

  return (
    <Select
      isMulti={isArray}
      value={selectedValue}
      styles={customStyles}
      isDisabled={disabled}
      isLoading={loading}
      options={schoolsOptions}
      className={className ?? "selector-min-width"}
      placeholder={intl.formatMessage({
        id: "app.survey-report.breadcrumb.select-school",
      })}
      isClearable
      noOptionsMessage={displayNoOptions}
      onChange={handleSchoolChange}
    />
  );
};

export default SchoolSelector;
