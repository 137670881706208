import { Tab, Tabs } from "@blueprintjs/core";
import React, { FunctionComponent, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import {
  ActivityTagEnum,
  ActivityTypeEnum,
  LearningPageTabEnum,
  UserNotesType,
} from "../../../types";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import { generatePath, useHistory, useParams } from "react-router";
import { AuthRoutes } from "../../../App";
import ActivityResourcesTab from "../members/tabs/resources-tab/ActiveResourcesTabs";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";

type OwnProps = {};

type Props = OwnProps;

const LearningPage: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const { tabId } = useParams<{ tabId?: string }>();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const history = useHistory();

  const selectedTabId: LearningPageTabEnum = useMemo(() => {
    return tabId &&
      [
        LearningPageTabEnum.SelfDirectedLearningModules,
        LearningPageTabEnum.LearningNetworkModules,
      ].includes(tabId as LearningPageTabEnum)
      ? (tabId as LearningPageTabEnum)
      : LearningPageTabEnum.SelfDirectedLearningModules;
  }, [tabId]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.learning" })}</title>
      </Helmet>

      <div className="teampage_container">
        <WorkspaceHeader
          title={intl.formatMessage({ id: "app.titles.learning" })}
          userNotesType={
            isDistrictActivePlan
              ? UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE
              : UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE
          }
        />

<div className="teampage_container__tab">

        <Tabs
          selectedTabId={selectedTabId}
          onChange={(tabId) => {
            history.push(
              generatePath(AuthRoutes.YourPlanLearning, {
                workspace: "learning",
                tabId: tabId,
              } as any)
            );
          }}
          renderActiveTabPanelOnly
        >
          <Tab
            id={LearningPageTabEnum.SelfDirectedLearningModules}
            title={intl.formatMessage({
              id: "app.notes.types.self_directed_learning_modules",
            })}
            panel={
              <ActivityResourcesTab
                showTagSelector={false}
                userNotesType={
                  isDistrictActivePlan
                    ? UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE
                    : UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE
                }
                filterFn={(a) => {
                  return (
                    a.activity_type === ActivityTypeEnum.KALTURA_VIDEO &&
                    a.tag !== ActivityTagEnum.LEARNING_NETWORK_EVENTS
                  );
                }}
              />
            }
          />
          <Tab
            id={LearningPageTabEnum.LearningNetworkModules}
            title={intl.formatMessage({
              id: "app.notes.types.learning_network_modules",
            })}
            panel={
              <ActivityResourcesTab
                activityTag={ActivityTagEnum.LEARNING_NETWORK_EVENTS}
                userNotesType={
                  isDistrictActivePlan
                    ? UserNotesType.DISTRICT_LEARNING_MODULES_EVENTS_WORKSPACE
                    : UserNotesType.SCHOOL_LEARNING_MODULES_EVENTS_WORKSPACE
                }
                filterFn={(a) => {
                  return a.tag === ActivityTagEnum.LEARNING_NETWORK_EVENTS;
                }}
              />
            }
          />
        </Tabs>
      </div>
      </div>
    </>
  );
};

export default LearningPage;
