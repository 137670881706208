import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  AuthenticatedUserModel,
  DistrictSetupTaskKey,
  PlanWorkspaceButton,
  PlanWorkspaces,
  TourDictionary,
  WorkspaceAvailabilityDictionary,
} from "../../types";
import { useIntl } from "react-intl";
import _ from "lodash";
import useUserPlanAssociation from "./useUserPlanAssociation";
import useUserRole from "./useUserRole";

export const useWorkspacesLock = ({
  workspaces,
}: {
  workspaces: (PlanWorkspaceButton | null)[];
}) => {
  const intl = useIntl();

  const districtSetupState: TourDictionary = useSelector(
    (s) => s.tours.districtSetupState
  );

  const activePlan = useSelector((s) => s.plans.activePlan);
  const { isSingleSchoolDistrict } = useUserPlanAssociation();

  const wsAvailableDictAllAvailable: WorkspaceAvailabilityDictionary = useMemo(() => {
    return _.chain(PlanWorkspaces)
      .values()
      .map((key) => [key, { available: true }])
      .fromPairs()
      .value();
  }, []);

  const me: AuthenticatedUserModel | undefined = useSelector(
    (s) => s.auth.authenticatedUser
  );

  const wsAvailableDictDistrictLock: WorkspaceAvailabilityDictionary = useMemo(() => {
    if (!activePlan?.district) {
      return {};
    }
    return {
      [PlanWorkspaces.Data]: {
        available:
          activePlan.district.enabled_data_collection &&
          me?.profile.is_setup_completed,
        lockType: "district",
      },
      [PlanWorkspaces.Needs]: {
        available:
          activePlan.district.enabled_needs_analysis &&
          me?.profile.is_setup_completed,
        lockType: "district",
      },
      [PlanWorkspaces.Goals]: {
        available:
          activePlan.district.enabled_strategic_plan &&
          me?.profile.is_setup_completed,
        lockType: "district",
      },
      // [PlanWorkspaces.Goals]: {
      //   available:
      //     activePlan.district.enabled_strategic_plan &&
      //     me?.profile.is_setup_completed,
      //   lockType: "district",
      // },
    };
  }, [activePlan?.district, me?.profile.is_setup_completed]);

  const { isDCC, isSuperintendent } = useUserRole();

  const wsAvailableDictPlanLock = useMemo(() => {
    if (!districtSetupState[DistrictSetupTaskKey.Review]) {
      return {};
    }

    return {
      [PlanWorkspaces.Dashboard]: { available: true },
      [PlanWorkspaces.Team]: {
        available:
          isDCC && !isSuperintendent
            ? districtSetupState.register_your_school ||
              districtSetupState.register_schools_and_invite_principals
            : true,
      },
      [PlanWorkspaces.MyDistrict]: {
        available: isSingleSchoolDistrict
          ? districtSetupState.familiarize_with_sccp
          : districtSetupState.communicate_with_principals ||
            districtSetupState.register_schools_and_invite_principals,
      },
    };
  }, [isSingleSchoolDistrict, isDCC, isSuperintendent, districtSetupState]);

  const wsAvailableDict: WorkspaceAvailabilityDictionary = useMemo(() => {
    const widgetHidden =
      !!districtSetupState?.lastTourWatched || me?.profile.is_setup_completed;

    if (widgetHidden) {
      return {
        ...wsAvailableDictAllAvailable,
        ...wsAvailableDictDistrictLock,
      };
    }

    return {
      ...wsAvailableDictPlanLock,
      ...wsAvailableDictDistrictLock,
    };
  }, [
    districtSetupState.hidden,
    me?.profile.is_setup_completed,
    wsAvailableDictDistrictLock,
    wsAvailableDictPlanLock,
  ]);

  const workspacesWithLocks: (PlanWorkspaceButton | null)[] = useMemo(() => {
    return workspaces.map((workspace) => {
      if (!workspace) {
        return workspace;
      }

      const blockedWorkspaceKey:
        | PlanWorkspaces
        | undefined = workspace.workspace_keys.find(
        (wK) => !wsAvailableDict[wK]?.available
      );

      return {
        ...workspace,
        disabled: workspace.disabled || !!blockedWorkspaceKey,
        popoverMessage: blockedWorkspaceKey
          ? intl.formatMessage({
              id:
                wsAvailableDict[blockedWorkspaceKey!]?.lockType === "district"
                  ? "app.district-setup.workspace-blocked"
                  : "app.district-setup.not-ready-for-step",
            })
          : undefined,
      };
    });
  }, [workspaces, wsAvailableDict]);

  return {
    wsAvailableDict,
    workspacesWithLocks,
  };
};

export default useWorkspacesLock;
