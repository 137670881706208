import _ from "lodash";
import { MAX_DISCREPANCY } from "../../../../types";
import { useMemo, useState } from "react";

export const useDiscrepancy = ({
  items = [],
}: {
  items?: (number | null)[];
}) => {
  const rowsWithDiscrepancy: number[] = useMemo(() => {
    if (items.length) {
      const maxByRow: number = _.max(items) ?? 0;
      const appropriateValue = maxByRow - MAX_DISCREPANCY;
      return _.chain(items)
        .map((item, index) => ({ value: item, index: index }))
        .filter((item) => item.value != null && item.value <= appropriateValue)
        .map((item) => item.index)
        .value();
    }

    return [];
  }, [items]);

  const [showDiscrepancyPopover, setShowDiscrepancyPopover] = useState<boolean>(
    false
  );
  const onMouseEnter = () => {
    setShowDiscrepancyPopover(true);
  };
  const onMouseLeave = () => {
    setShowDiscrepancyPopover(false);
  };

  return {
    hasDiscrepancy: !!rowsWithDiscrepancy.length,
    rowsWithDiscrepancy: [] as number[],
    // rowsWithDiscrepancy: rowsWithDiscrepancy,
    showDiscrepancyPopover,
    onMouseEnter,
    onMouseLeave,
  };
};

export default useDiscrepancy;
