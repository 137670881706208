import React from "react";

type Props<T> = {
  redactedStatistics: T[];
  rowsWithDiscrepancy: number[];
  totalKey: keyof T;
  showExtraSpan: boolean;
};

const TotalRespondentsCell = <T extends object>(props: Props<T>) => {
  const {
    redactedStatistics,
    rowsWithDiscrepancy,
    totalKey,
    showExtraSpan,
  } = props;

  return (
    <td style={{ height: "100%" }}>
      {!!redactedStatistics.length && (
        <div
          className="flex flex-col justify-around"
          style={{ height: "100%" }}
        >
          {redactedStatistics.map((item, index) => {
            const hasDiscrepancy = rowsWithDiscrepancy.includes(index);
            return (
              <span
                key={index}
                style={{ height: "2rem" }}
                className={`flex items-center justify-center text-2xl font-semibold ${
                  hasDiscrepancy ? "has-discrepancy" : ""
                }`}
              >
                {item[totalKey]}
              </span>
            );
          })}
          {showExtraSpan && (
            <span
              className="text-2xl font-semibold text-center"
              style={{ height: "2rem" }}
            />
          )}
        </div>
      )}
    </td>
  );
};

export default TotalRespondentsCell;
