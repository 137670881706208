import React from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Spinner } from "@blueprintjs/core";
import logo from "../../static/images/nj-sci-logo.png";
import WelcomeIntro from "../welcome-page-content-by-role/WelcomeIntro";
import { generatePath, useHistory } from "react-router";
import { AuthRoutes } from "../../App";
import { markStepCompleted } from "../../store/tours/actions";
import { DistrictSetupTaskKey } from "../../types";
import './appWelcomePage.scss'
import useUserRole from "../../helpers/hooks/useUserRole";

type OwnProps = {};

type Props = OwnProps;

const AppWelcomePage: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector((s) => s.plans.loading.getPlans);
  
  const { isDCC, isSuperintendent, teamRole, isTeamMember, role, user} = useUserRole();

  const handleNavigateClick = () => {
    dispatch(
      markStepCompleted({
        key: DistrictSetupTaskKey.Review,
      })
    );
    if(isDCC){
      history.push(
        generatePath(AuthRoutes.YourPlan, {
          workspace: "my-district" as any,
        } as any),
        {
          fromWorkSpace: "welcome",
        }
      )
    }
   else if(isSuperintendent){
    history.push(
    generatePath(AuthRoutes.YourPlan, {
      workspace: "team" as any,
    } as any),
    {
      fromWorkSpace: "welcome",
    }
  ) 
   }
    else{
      history.push(
        generatePath(AuthRoutes.YourPlanTeam, {
          workspace: "team" as any,
        } as any),
      );
    }
  };

  return (
    <>
      <div className="welcomePage-wrapper">
      <div className="welcomePage-wrapper__header">
        <div className="container">
        <div className="nj-md-12 cstmColPad">
          <img src={logo} alt="NJ SCI Platform" style={{ width: "140px" }} />
          </div>
        </div>
      </div>
      <div className="welcomePage-wrapper__content">
      <div className="container">
      <div className="nj-row">
        <div className="nj-md-12 cstmColPad">
          <div className="welcomePage-wrapper__txt">
          {intl.formatMessage({ id: "app.titles.welcome-page" },{strong:(chunks) => <strong>{chunks}</strong>})}
          </div>
        </div>
      </div>
        <div className="nj-row">
          <div className="nj-md-6 cstmColPad">
          <div className="welcomePage-wrapper__briefedTxt">
            {intl.formatMessage({ id: "app.titles.welcome-page-content" })}
              <ul>
              {intl.formatMessage({ id: "app.titles.welcome-page-content-list" },
              {li:(chunks) => <li>{chunks}</li>}
              )}
              </ul>
              <div className="blueHighlated-Txt">
              <p>{intl.formatMessage({ id: "app.titles.welcome-page-content-blue-band" },{b:(chunks)=><b>{chunks}</b>})}</p>
              </div>
          </div>
          <div className="action"><button className="nj-btn change-password-btn" onClick={handleNavigateClick} title={intl.formatMessage({
                  id: "app.welcome-page.navigate-to-dashboard-page",
                })}>{intl.formatMessage({
                  id: "app.welcome-page.navigate-to-dashboard-page",
                })}</button></div>
          </div>
          <div className="nj-md-6 cstmColPad">
          <div className="welcomePage-wrapper-videoDiv">
            <iframe id="kaltura_player" src="https://cdnapisec.kaltura.com/p/208082/sp/20808200/embedIframeJs/uiconf_id/12441821/partner_id/208082?iframeembed=true&playerId=kaltura_player&entry_id=1_r4yvcsy8&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_rnymhegt" width="100%" height="360" allowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation" frameBorder="0" title="Overview of The School Climate Change Process"></iframe>
          </div>
          <div className="video-Notes">
          {intl.formatMessage({ id: "app.titles.welcome-page-content-sponsored-text" })}
          </div>
          </div>
        </div>
      </div>
      </div>
        
      </div>
    </>
  );
};
export default AppWelcomePage;
