import React, { useCallback, useState } from "react";
import { Button, Classes, Dialog, Tab, Tabs } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { MeetingTemplateRequest } from "../../../api/meeting-templates/types";
import GeneralInfoTab from "./tabs/GeneralInfoTab";
import {
  addMeetingTemplate,
  hideMeetingTemplateDialog,
  updateMeetingTemplate,
} from "../../../store/meeting-templates/actions";
import {
  Activity,
  ActivityScopeEnum,
  AttachedToType,
  MeetingType,
} from "../../../types";
import ResourcesTab from "../../team-plan/strategies/upsert-strategy-dialog/tabs/ResourcesTab";
import ActivitiesTab from "../../activities/ActivitiesTab";
import { AppToaster } from "../../../helpers/toaster";

type OwnProps = {};

type Props = OwnProps;

const MeetingTemplateUpsertDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { show, meetingTemplate } = useSelector(
    (s) => s.meetingTemplates.dialogs.meetingTemplateDialog
  );

  const onSuccess = useCallback(() => {
    AppToaster.show({
      icon: "tick",
      intent: "success",
      message: intl.formatMessage(
        { id: `app.titles.${meetingTemplate ? "update-" : ""}toaster-message` },
        { name: meetingTemplateRequest.short_title }
      ),
    });
    handleClose();
  }, [meetingTemplate]);

  const loading = useSelector(
    (s) => s.meetingTemplates.loading.addMeetingTemplate
  );
  const error = useSelector(
    (s) => s.meetingTemplates.errors.addMeetingTemplate
  );
  useLoading({ loading, error, onSuccess });

  const updateLoading = useSelector(
    (s) => s.meetingTemplates.loading.updateMeetingTemplate
  );
  const updateError = useSelector(
    (s) => s.meetingTemplates.errors.updateMeetingTemplate
  );
  useLoading({
    loading: updateLoading,
    error: updateError,
    onSuccess: onSuccess,
  });

  const [
    meetingTemplateRequest,
    setMeetingTemplateRequest,
  ] = useState<MeetingTemplateRequest>({
    title: "",
    resources: [],
    activities: [],
    meeting_type: MeetingType.TeamMeeting,
    type: ActivityScopeEnum.ALL,
  });

  const handleClose = () => {
    setMeetingTemplateRequest({
      title: "",
      resources: [],
      activities: [],
      meeting_type: MeetingType.TeamMeeting,
      type: ActivityScopeEnum.ALL,
    });
    dispatch(hideMeetingTemplateDialog());
  };

  const handleDialogOpen = () => {
    setMeetingTemplateRequest({
      title: meetingTemplate?.title ?? "",
      short_title: meetingTemplate?.short_title,
      meeting_type: meetingTemplate?.meeting_type ?? MeetingType.TeamMeeting,
      meeting_type_other: meetingTemplate?.meeting_type_other,
      resources: meetingTemplate?.resources ?? [],
      activities: meetingTemplate?.activities?.map((a) => a.id) ?? [],
      type: meetingTemplate?.type ?? ActivityScopeEnum.ALL,
      agenda:
        meetingTemplate?.agenda === null ? undefined : meetingTemplate?.agenda,
      meeting_code: meetingTemplate?.meeting_code,
      can_be_added: meetingTemplate?.can_be_added,
      general_notes:
        meetingTemplate?.general_notes === null
          ? undefined
          : meetingTemplate?.general_notes,
      is_hidden: meetingTemplate?.is_hidden,
      order_index: meetingTemplate?.order_index,
      pre_work:
        meetingTemplate?.pre_work === null
          ? undefined
          : meetingTemplate?.pre_work,
      timing_guidance:
        meetingTemplate?.timing_guidance === null
          ? undefined
          : meetingTemplate?.timing_guidance,
    });
  };

  const handleActivityClick = (activity: Activity) => {
    const updatedActivities = meetingTemplateRequest.activities.filter(
      (a) => a !== activity.id
    );
    setMeetingTemplateRequest({
      ...meetingTemplateRequest,
      activities:
        meetingTemplateRequest.activities.length === updatedActivities.length
          ? [...updatedActivities, activity.id]
          : updatedActivities,
    });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (meetingTemplate) {
      dispatch(
        updateMeetingTemplate.request({
          id: meetingTemplate.id,
          body: meetingTemplateRequest,
        })
      );
    } else {
      dispatch(addMeetingTemplate.request(meetingTemplateRequest));
    }
  };

  return (
    <Dialog
      style={{ minWidth: "50rem", width: "95%" }}
      isOpen={show}
      onClose={handleClose}
      onOpening={handleDialogOpen}
      enforceFocus={false}
      icon={"highlight"}
      title={intl.formatMessage({ id: "app.meeting-template.title" })}
    >
      <div className={Classes.DIALOG_BODY}>
        <form id="meeting-template-form" onSubmit={handleFormSubmit}>
          <Tabs>
            <Tab
              id={"general-info"}
              title={intl.formatMessage({
                id: "app.survey-deployment-dialog.tabs.survey-deployment-tab",
              })}
              panel={
                <GeneralInfoTab
                  meetingTemplateRequest={meetingTemplateRequest}
                  setMeetingTemplateRequest={setMeetingTemplateRequest}
                />
              }
            />
            <Tab
              id={"activities"}
              title={intl.formatMessage({
                id: "app.titles.activities",
              })}
              panel={
                <ActivitiesTab
                  displayNavigationPanel={false}
                  selectedActivities={meetingTemplateRequest.activities}
                  onActivityClick={handleActivityClick}
                  type={meetingTemplateRequest?.type}
                />
              }
            />
            <Tab
              id={"resources"}
              title={intl.formatMessage({
                id: "app.titles.resources",
              })}
              panel={
                <ResourcesTab
                  strategyResources={meetingTemplateRequest.resources}
                  setStrategyResources={(r) =>
                    setMeetingTemplateRequest({
                      ...meetingTemplateRequest,
                      resources: r,
                    })
                  }
                  attachedToType={AttachedToType.MEETING}
                  forUserNotes={false}
                  hideUrl
                />
              }
            />
          </Tabs>
        </form>
      </div>
      <div className={`${Classes.DIALOG_FOOTER} mt-4`}>
        <div className="flex justify-between">
          <Button
            className="button-min-width"
            onClick={handleClose}
            disabled={loading || updateLoading}
          >
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            form="meeting-template-form"
            intent="primary"
            className="button-min-width"
            text={intl.formatMessage({ id: "app.titles.save" })}
            title={intl.formatMessage({ id: "app.titles.save" })}
            loading={loading || updateLoading}
            type="submit"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default MeetingTemplateUpsertDialog;
