import { applyMiddleware, compose, createStore } from "redux";

import { createEpicMiddleware } from "redux-observable";
import { RootAction, RootState, Services } from "typesafe-actions";

import { createBrowserHistory } from "history";

import createRootReducer, { ApplicationState } from "./reducers";
import rootEpic from "./epics";
import services from "../services";
import { routerMiddleware } from "connected-react-router";
import { LocalStorageKeys, resetLocalStorage } from "../constants";

export const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const history = createBrowserHistory({ basename: "/app" });

export const rootReducer = createRootReducer(history);
const applicationStore = (
  preloadedState: Partial<ApplicationState> = {},
  useEpicMiddleware = true
) => {
  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
  >({
    dependencies: services,
  });

  const appReducer: any = (state: any, action: any) => {
    if (action.type === "@auth/LOGOUT_SUCCESS") {
      state = undefined;
      resetLocalStorage([
        LocalStorageKeys.DistrictSetupWidget,
        LocalStorageKeys.SuperintendentAsPrincipalInfo,
      ]);
    }
    return rootReducer(state, action);
  };

  const middlewares = [epicMiddleware, routerMiddleware(history)];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(appReducer, preloadedState, enhancer);

  if (useEpicMiddleware) {
    epicMiddleware.run(rootEpic);
  }

  return store;
};

export default applicationStore;
