import { createAction } from "typesafe-actions";
import { Locale } from "./reducers";
import { ConfirmDialogConfig } from "../../types";

export const setMenuCollapsed = createAction(
  "@UIState/SET_MENU_COLLAPSED"
)<boolean>();

export const changeLocale = createAction("@UIState/CHANGE_LOCALE")<{
  locale: Locale;
  storageType: "sessionStorage" | "localStorage";
}>();

export const showConfirmDialog = createAction(
  "@UIState/showConfirmDialog"
)<ConfirmDialogConfig>();

export const hideConfirmDialog = createAction(
  "@UIState/HIDE_CONFIRM_DIALOG"
)<void>();

export const filterLanguages = createAction("@UIState/FILTER_LANGUAGES")<
  Locale[]
>();

export const hideLanguageSelector = createAction(
  "@UIState/HIDE_LANGUAGE_SELECTOR"
)<boolean | undefined>();

export const resetSurveyLanguage = createAction(
  "@UIState/RESET_SURVEY_LANGUAGE"
)<void>();

export const showFeedbackDialog = createAction(
  "@UIState/SHOW_FEEDBACK_DIALOG"
)<void>();

export const hideFeedbackDialog = createAction(
  "@UIState/HIDE_FEEDBACK_DIALOG"
)<void>();
