import { Activity, ActivityScopeEnum, ActivityTagEnum } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import { ActivityRequest } from "./types";
import { addResource } from "../resources";

export const getActivities = (
  type?: ActivityScopeEnum,
  tag?: ActivityTagEnum
): Promise<Activity[]> => {
  return Axios.get(
    `${API_ROOT}/activities/${
      type ? `?plan_type=${type}${tag ? `&tag=${tag}` : ""}` : ""
    }`
  );
};

export const saveActivity = async (
  request: ActivityRequest
): Promise<Activity> => {
  let docxResource;
  if (request.docx_resource && request.docx_resource.id < 0) {
    docxResource = await addResource(request.docx_resource);
  }
  let pdfResource;
  if (request.pdf_resource && request.pdf_resource.id < 0) {
    pdfResource = await addResource(request.pdf_resource);
  }
  let pptResource;
  if (request.ppt_resource && request.ppt_resource.id < 0) {
    pptResource = await addResource(request.ppt_resource);
  }
  return Axios.post(`${API_ROOT}/activities/`, {
    ...request,
    docx_resource: docxResource?.id || request.docx_resource?.id,
    pdf_resource: pdfResource?.id || request.pdf_resource?.id,
    ppt_resource: pptResource?.id || request.ppt_resource?.id,
  });
};

export const updateActivity = async (
  id: number,
  request: ActivityRequest
): Promise<Activity> => {
  let docxResource;
  if (request.docx_resource && request.docx_resource.id < 0) {
    docxResource = await addResource(request.docx_resource);
  }
  let pdfResource;
  if (request.pdf_resource && request.pdf_resource.id < 0) {
    pdfResource = await addResource(request.pdf_resource);
  }
  let pptResource;
  if (request.ppt_resource && request.ppt_resource.id < 0) {
    pptResource = await addResource(request.ppt_resource);
  }
  return Axios.put(`${API_ROOT}/activities/${id}/`, {
    ...request,
    docx_resource: docxResource ? docxResource?.id : request.docx_resource?.id,
    pdf_resource: pdfResource ? pdfResource?.id : request.pdf_resource?.id,
    ppt_resource: pptResource?.id || request.ppt_resource?.id,
  });
};

export const deleteActivity = (id: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/activities/${id}/`);
};

export const getActivityByName = (name: string): Promise<Activity[]> => {
  return Axios.get(`${API_ROOT}/activities/?name=${name}`);
};

export const getActivityById = (id: number): Promise<Activity> => {
  return Axios.get(`${API_ROOT}/activities/${id}/`);
};
