import React from "react";
import { useIntl } from "react-intl";
import { Tab, Tabs } from "@blueprintjs/core";
import { AttachedResource } from "../../../../types";
import PageEditor from "../../../survey-deployment/forms/PageEditor";
import ActivityFilesFormGroup from "./ActivityFilesFormGroup";

type OwnProps = {
  htmlContent?: string;
  notesPlaceholderHTML?: string;
  pdfFile?: AttachedResource;
  docxFile?: AttachedResource;
  pptFile?: AttachedResource;
  onRichTextFieldChange: (
    html: string,
    field: "html_content" | "notes_placeholder_html"
  ) => void;
  onFileChange: (type: "pdf" | "docx" | "ppt", resource?: AttachedResource) => void;
};

type Props = OwnProps;

const ActivitySciWritingTabs: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    htmlContent,
    notesPlaceholderHTML,
    pdfFile,
    docxFile,
    pptFile,
    onRichTextFieldChange,
    onFileChange,
  } = props;

  return (
    <Tabs defaultSelectedTabId={"activity-content"}>
      <Tab
        id="activity-content"
        title={"Activity Content"}
        panel={
          <PageEditor
            content={htmlContent}
            onBlur={(html) => onRichTextFieldChange(html, "html_content")}
          />
        }
      />
      <Tab
        id="notes-placeholder"
        title={"Notes Placeholder"}
        panel={
          <PageEditor
            content={notesPlaceholderHTML}
            onBlur={(html) =>
              onRichTextFieldChange(html, "notes_placeholder_html")
            }
          />
        }
      />
      <Tab
        id="resources"
        title={intl.formatMessage({ id: "app.titles.resources" })}
        panel={
          <ActivityFilesFormGroup
            pdfFile={pdfFile}
            docxFile={docxFile}
            pptFiles={pptFile}
            onFileChange={onFileChange}
          />
        }
      />
    </Tabs>
  );
};

export default ActivitySciWritingTabs;
