import React, { FunctionComponent, useMemo, useState } from "react";
import Select from "react-select";
import { Grade, StudentGrades } from "../../types";
import _ from "lodash";
import { useIntl } from "react-intl";

type OwnProps = {
  grades: Grade[];
  setGrades: React.Dispatch<React.SetStateAction<Grade[]>>;
};

type Props = OwnProps;

const SchoolGradesSelector: FunctionComponent<Props> = (props) => {
  const { grades, setGrades } = props;
  const intl = useIntl();

  const gradesOptions: { label: string; value: any }[] = useMemo(() => {
    const groupedOptions = [
      "elementary_students",
      "middle_students",
      "high_students",
    ].map((groupKey) => ({
      label: intl.formatMessage({
        id: `app.grades.groups.${groupKey}`,
      }),
      value: StudentGrades[groupKey],
    }));

    const options = Object.values(Grade).map((grade) => ({
      label: grade,
      value: grade,
    }));

    return [...groupedOptions, ...options];
  }, []);

  const handleGradesChange = (values: any, action: any) => {
    setGrades(
      values
        ? _.chain(values)
            .flatMap((v: any) => v.value)
            .uniq()
            .value()
        : []
    );

    if (_.isArray(action?.option?.value)) {
      setMenuIsOpen(false);
    }
  };

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  return (
    <Select
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      options={gradesOptions}
      isMulti
      placeholder={intl.formatMessage({
        id: "app.forms.grades.placeholder",
      })}
      value={gradesOptions.filter((go) => grades.includes(go.value as Grade))}
      closeMenuOnSelect={false}
      onChange={handleGradesChange}
    />
  );
};

export default SchoolGradesSelector;
