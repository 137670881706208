import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DomainStatistics,
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  SurveyDemographic,
  SurveyStatistics,
} from "../../../types";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { Spinner } from "@blueprintjs/core";
import SurveyReportTableHead from "./survey-report-table-head/SurveyReportTableHead";
import { getMaxAnswerIndex } from "./utils";
import SurveyStatisticsTableRow from "./survey-report-table-row/SurveyStatisticsTableRow";
import GroupedSurveyStatisticsTableRow from "./survey-report-table-row/GroupedSurveyStatisticsTableRow";
import DomainStatisticsTableRow from "./survey-report-table-row/DomainStatisticsTableRow";
import GroupedDomainStatisticsTableRow from "./survey-report-table-row/GroupedDomainStatisticsTableRow";
import SurveyReportNonIdealState from "./non-ideal-states/SurveyReportNonIdealState";
import { selectIsAdmin } from "../../../store/auth/selectors";
import { selectDomainAnswerSet } from "../../../store/domain-answer-set/actions";
import useGetDatasetMarksLoading from "../../../helpers/hooks/loading/useGetDatasetMarksLoading";
import { useGetDatasetsLoading } from "../../../helpers/hooks/loading/useGetDatasetsLoading";
import useSurveyReportType from "../../../helpers/hooks/survey-reports/useSurveyReportType";
import { isArray } from "lodash";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../../constants";

type OwnProps = {
  schoolId: number | undefined;
  surveyDeploymentId: number | undefined;
  domainId: number | undefined;
  tabId: string | undefined;
  surveyReport:
    | SurveyStatistics[]
    | GroupedSurveyStatistics[]
    | DomainStatistics
    | DomainStatistics<GroupedAnswerStatistics[]>;
  demographic: SurveyDemographic;
  segmentationCode?: string;
  showTableHeaderContent?: boolean;
  forNegativeDomains?: boolean;
  manageBookmarkDialog?: boolean;
  inReportInsights?: boolean;
  isDisaggregation?: boolean;
};

type Props = OwnProps;

const NUMBER_OF_HIGHLIGHTED_ELEMENTS = 0;
const MINIMUM_ELEMENTS_FOR_HIGHLIGHTING = 3;

const TABLE_COLUMN_NUMBER = 5;

const REMOVE_NOT_SPECIFIED_ELEMENTS = true;

const SurveyReportTable: React.FC<Props> = (props) => {
  const {
    demographic,
    schoolId,
    surveyDeploymentId,
    domainId,
    tabId,
    surveyReport,
    segmentationCode,
    isDisaggregation,
    showTableHeaderContent,
    inReportInsights,
    forNegativeDomains,
    manageBookmarkDialog,
  } = props;

  const selectedBookmarkDetail = useSelector(
    (s) => s.surveyReports.bookmarkDialogDetails
  );
  const dispatch = useDispatch();

  const isAuthenticatedAdmin = useSelector(selectIsAdmin);

  const loading = useSelector((s) => s.surveyReports.loading.getSurveyReport);
  const error = useSelector((s) => s.surveyReports.errors.getSurveyReport);
  useLoading({ loading, error });

  const supportsBookmarks = useSelector(
    (s) => !!s.surveyReports.selectedSurveyDeployment?.survey.supports_bookmarks
  );

  const { loading: loadingGetDatasetMarks } = useGetDatasetMarksLoading({
    schoolId: schoolId,
    supportsBookmarks: supportsBookmarks,
    surveyDeploymentId: surveyDeploymentId ? surveyDeploymentId : undefined,
  });

  const { loading: loadingGetDatasets } = useGetDatasetsLoading();

  const [displayedData, setDisplayedData] = useState<
    | SurveyStatistics[]
    | GroupedSurveyStatistics[]
    | DomainStatistics
    | DomainStatistics<GroupedAnswerStatistics[]>
    | undefined
  >(undefined);

  const {
    isSurveyStatisticsArray,
    isGroupedStatisticsArray,
    isDomainStatistics,
    isGroupedDomainStatistics,
    groupingKeys,
    isGroupedData,
  } = useSurveyReportType(schoolId, surveyDeploymentId, domainId, surveyReport);

  useEffect(() => {
    if (REMOVE_NOT_SPECIFIED_ELEMENTS) {
      if (isGroupedStatisticsArray) {
        setDisplayedData(
          (surveyReport as GroupedSurveyStatistics[])?.map((gss) => ({
            ...gss,
            responses: {
              ...gss.responses,
              [demographic]: gss?.responses?.[demographic]?.filter(
                (stat) => stat?.label
              ),
            },
          }))
        );
      } else if (isGroupedDomainStatistics) {
        setDisplayedData({
          ...(surveyReport as DomainStatistics<GroupedAnswerStatistics[]>),
          survey_questions: (surveyReport as DomainStatistics<
            GroupedAnswerStatistics[]
          >)?.survey_questions?.map((ds) => ({
            ...ds,
            [demographic]:
              ds?.[demographic]?.length === 1 && ds?.[demographic]?.[0]?.label
                ? ds?.[demographic]
                : ds?.[demographic]?.filter((stat) => stat?.label),
          })),
        });
      } else {
        setDisplayedData(surveyReport);
      }
    } else {
      setDisplayedData(surveyReport);
    }
  }, [surveyReport]);

  const showTotalRespondentsColumn = useMemo(() => {
    // if (isAuthenticatedAdmin) {
    //   return !getBooleanFromLocalStorage(
    //     LocalStorageKeys.SensitiveReportingDataHidden
    //   );
    // }
    return !(
      isAuthenticatedAdmin &&
      (isGroupedStatisticsArray || isGroupedDomainStatistics)
    );
  }, [
    isAuthenticatedAdmin,
    isGroupedStatisticsArray,
    isGroupedDomainStatistics,
  ]);

  const isRowHighlighted = useCallback(
    (arrayLength: number, index: number, isDescending: boolean) => {
      if (arrayLength > MINIMUM_ELEMENTS_FOR_HIGHLIGHTING) {
        if (
          arrayLength - NUMBER_OF_HIGHLIGHTED_ELEMENTS - 1 < index &&
          isDescending
        ) {
          return true;
        } else if (
          arrayLength - 1 - NUMBER_OF_HIGHLIGHTED_ELEMENTS > index &&
          !isDescending
        ) {
          return true;
        }
      }
      return false;
    },
    []
  );

  useEffect(() => {
    if (!displayedData) {
      return;
    }
    let domainAnswerSet;
    if (isSurveyStatisticsArray) {
      domainAnswerSet =
        (displayedData as SurveyStatistics[])?.length &&
        (displayedData as SurveyStatistics[])[0].domain.domain_answer;
    } else if (isGroupedStatisticsArray) {
      domainAnswerSet =
        (displayedData as GroupedSurveyStatistics[])?.length &&
        (displayedData as GroupedSurveyStatistics[])[0].domain.domain_answer;
    } else if (isGroupedDomainStatistics || isDomainStatistics) {
      domainAnswerSet = (displayedData as DomainStatistics)?.domain
        ?.domain_answer;
    }
    dispatch(selectDomainAnswerSet(domainAnswerSet || undefined));
  }, [
    displayedData,
    isSurveyStatisticsArray,
    isGroupedStatisticsArray,
    isGroupedDomainStatistics,
    isDomainStatistics,
  ]);

  const searchWords = useSelector((s) => s.surveyReports.searchBarString);

  const filteredDisplayedData:
    | SurveyStatistics[]
    | GroupedSurveyStatistics[]
    | DomainStatistics
    | DomainStatistics<GroupedAnswerStatistics[]>
    | undefined = useMemo(() => {
    if (!searchWords) {
      return displayedData;
    }
    const lowercase = searchWords.toLowerCase();
    if (isSurveyStatisticsArray) {
      return ((isArray(displayedData)
        ? displayedData
        : []) as SurveyStatistics[])?.filter((item) =>
        item.domain.name.toLowerCase().includes(lowercase)
      );
    }
    if (isGroupedStatisticsArray) {
      return ((isArray(displayedData)
        ? displayedData
        : []) as GroupedSurveyStatistics[])?.filter((item) =>
        item.domain.name.toLowerCase().includes(lowercase)
      );
    }

    if (isDomainStatistics) {
      return displayedData
        ? {
            ...(displayedData as DomainStatistics),
            survey_questions: (displayedData as DomainStatistics).survey_questions.filter(
              (sq) => {
                let question: string = sq.text;
                if (sq.lead_in) {
                  question = sq.lead_in + question;
                }

                return question.toLowerCase().includes(lowercase);
              }
            ),
          }
        : displayedData;
    }

    if (isGroupedDomainStatistics) {
      return displayedData
        ? {
            ...(displayedData as DomainStatistics<GroupedAnswerStatistics[]>),
            survey_questions: (displayedData as DomainStatistics<
              GroupedAnswerStatistics[]
            >).survey_questions.filter((sq) => {
              let question: string = sq.text;
              if (sq.lead_in) {
                question = sq.lead_in + question;
              }

              return question.toLowerCase().includes(lowercase);
            }),
          }
        : displayedData;
    }

    return displayedData;
  }, [
    displayedData,
    searchWords,
    isSurveyStatisticsArray,
    isGroupedStatisticsArray,
    isDomainStatistics,
    isGroupedDomainStatistics,
  ]);

  const showNonIdealState: boolean = useMemo(() => {
    if (isSurveyStatisticsArray || isGroupedStatisticsArray) {
      if (!(surveyReport as [])?.length) {
        return true;
      }

      if (searchWords?.length) {
        return (
          !(filteredDisplayedData as any[])?.length &&
          !!(displayedData as any[])?.length
        );
      }
    } else if (isDomainStatistics || isGroupedDomainStatistics) {
      if (!(surveyReport as any)?.survey_questions?.length) {
        return true;
      }

      if (searchWords?.length) {
        return (
          !(filteredDisplayedData as any)?.survey_questions?.length &&
          !!(displayedData as any)?.survey_questions?.length
        );
      }
    }
    return false;
  }, [surveyReport, searchWords, filteredDisplayedData]);

  return (
    <>
      <table cellPadding={0} cellSpacing={0} className="survey-report-table">
        {
          <SurveyReportTableHead
            forNegativeDomains={forNegativeDomains}
            groupingKeys={groupingKeys}
            isGroupedData={isGroupedData}
            showTotalRespondentsColumn={showTotalRespondentsColumn}
            schoolId={schoolId}
            surveyDeploymentId={surveyDeploymentId}
            domainId={domainId}
            tabId={tabId}
            segmentationCode={segmentationCode}
            showTableHeaderContent={showTableHeaderContent}
            manageBookmarkDialog={manageBookmarkDialog}
          />
        }
        <tbody>
          {(manageBookmarkDialog && loadingGetDatasetMarks) ||
          loading ||
          loadingGetDatasets ? (
            <tr>
              <td colSpan={TABLE_COLUMN_NUMBER}>
                <Spinner intent={"primary"} className="pt-4" />
              </td>
            </tr>
          ) : showNonIdealState ? (
            <tr>
              <td colSpan={TABLE_COLUMN_NUMBER}>
                <SurveyReportNonIdealState hasFilters />
              </td>
            </tr>
          ) : isSurveyStatisticsArray ? (
            ((isArray(filteredDisplayedData)
              ? filteredDisplayedData
              : []) as SurveyStatistics[])?.map((item, index) => {
              if (item?.responses?.[demographic] !== null) {
                return (
                  <SurveyStatisticsTableRow
                    isDisaggregation={isDisaggregation}
                    key={index}
                    index={index}
                    maxAnswerIndex={getMaxAnswerIndex(demographic, item)}
                    surveyStatistics={item}
                    demographic={demographic}
                    isHighlighted={
                      inReportInsights &&
                      isRowHighlighted(
                        (surveyReport as SurveyStatistics[])?.length,
                        index,
                        true
                      )
                    }
                    inReportInsights={inReportInsights}
                    showTotalRespondentsColumn={showTotalRespondentsColumn}
                    manageBookmarkDialog={manageBookmarkDialog}
                  />
                );
              }
            })
          ) : isGroupedStatisticsArray ? (
            ((isArray(filteredDisplayedData)
              ? filteredDisplayedData
              : []) as GroupedSurveyStatistics[])?.map((item, index) => (
              <GroupedSurveyStatisticsTableRow
                isDisaggregation={isDisaggregation}
                key={index}
                index={index}
                inReportInsights={inReportInsights}
                maxAnswerIndex={getMaxAnswerIndex(demographic, item)}
                groupedSurveyStatistics={item}
                demographic={demographic}
                showTotalRespondentsColumn={showTotalRespondentsColumn}
                surveyDeploymentId={surveyDeploymentId}
                manageBookmarkDialog={manageBookmarkDialog}
              />
            ))
          ) : isGroupedDomainStatistics ? (
            (filteredDisplayedData as DomainStatistics<
              GroupedAnswerStatistics[]
            >)?.survey_questions?.map(
              (item, index) =>
                (manageBookmarkDialog &&
                selectedBookmarkDetail?.domainIndex &&
                selectedBookmarkDetail?.questionId
                  ? item.question_id == selectedBookmarkDetail.questionId &&
                    domainId == selectedBookmarkDetail.domainIndex
                  : true) && (
                  <GroupedDomainStatisticsTableRow
                    key={index}
                    index={index}
                    domainId={domainId}
                    isDisaggregation={isDisaggregation}
                    inReportInsights={inReportInsights}
                    maxAnswerIndex={getMaxAnswerIndex(
                      demographic,
                      filteredDisplayedData as DomainStatistics<
                        GroupedAnswerStatistics[]
                      >
                    )}
                    surveyQuestionStatistics={item}
                    demographic={demographic}
                    showTotalRespondentsColumn={showTotalRespondentsColumn}
                    surveyDeploymentId={surveyDeploymentId}
                    manageBookmarkDialog={manageBookmarkDialog}
                  />
                )
            )
          ) : isDomainStatistics ? (
            (filteredDisplayedData as DomainStatistics)?.survey_questions?.map(
              (item, index) => {
                if (item?.[demographic] !== null) {
                  return (
                    <DomainStatisticsTableRow
                      index={index}
                      key={index}
                      domainId={domainId}
                      inReportInsights={inReportInsights}
                      isDisaggregation={isDisaggregation}
                      maxAnswerIndex={getMaxAnswerIndex(
                        demographic,
                        filteredDisplayedData as DomainStatistics
                      )}
                      surveyQuestionStatistics={item}
                      demographic={demographic}
                      isHighlighted={isRowHighlighted(
                        (surveyReport as DomainStatistics)?.survey_questions
                          ?.length,
                        index,
                        true
                      )}
                      showTotalRespondentsColumn={showTotalRespondentsColumn}
                      manageBookmarkDialog={manageBookmarkDialog}
                    />
                  );
                }
              }
            )
          ) : (
            <td colSpan={TABLE_COLUMN_NUMBER}>
              <SurveyReportNonIdealState />
            </td>
          )}
        </tbody>
      </table>
    </>
  );
};

export default SurveyReportTable;
