import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { DomainAnswerSet } from "../../types";

type ActionName =
  | "getDomainAnswerSets"
  | "addDomainAnswerSet"
  | "updateDomainAnswerSet"
  | "deleteDomainAnswerSet";

export type DomainsAnswerSetState = {
  selectedDomainAnswerSet?: DomainAnswerSet;
  dialogs: {
    domainAnswerSetUpsertDialog?: {
      show: boolean;
      editing?: boolean;
      onSuccess?: Function;
    };
  };
  domainAnswerSets: DomainAnswerSet[];
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: DomainsAnswerSetState = {
  domainAnswerSets: [],
  dialogs: {},
  loading: {},
  errors: {},
};

export const domainsAnswerSetReducer = createReducer<
  DomainsAnswerSetState,
  RootAction
>(initialState)
  // get domain answer sets
  .handleAction(
    actions.getDomainAnswerSets.request,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      loading: {
        ...state.loading,
        getDomainAnswerSets: true,
      },
      errors: {
        ...state.errors,
        getDomainAnswerSets: undefined,
      },
    })
  )
  .handleAction(
    actions.getDomainAnswerSets.success,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      domainAnswerSets: action.payload,
      loading: {
        ...state.loading,
        getDomainAnswerSets: false,
      },
    })
  )
  .handleAction(
    actions.getDomainAnswerSets.failure,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      errors: {
        ...state.errors,
        getDomainAnswerSets: action.payload,
      },
      loading: {
        ...state.loading,
        getDomainAnswerSets: false,
      },
    })
  )
  // add domain answer set
  .handleAction(
    actions.addDomainAnswerSet.request,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      loading: {
        ...state.loading,
        addDomainAnswerSet: true,
      },
      errors: {
        ...state.errors,
        addDomainAnswerSet: undefined,
      },
    })
  )
  .handleAction(
    actions.addDomainAnswerSet.success,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      domainAnswerSets: [action.payload, ...state.domainAnswerSets],
      loading: {
        ...state.loading,
        addDomainAnswerSet: false,
      },
    })
  )
  .handleAction(
    actions.addDomainAnswerSet.failure,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      errors: {
        ...state.errors,
        addDomainAnswerSet: action.payload,
      },
      loading: {
        ...state.loading,
        addDomainAnswerSet: false,
      },
    })
  )
  // update domain answer set
  .handleAction(
    actions.updateDomainAnswerSet.request,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      loading: {
        ...state.loading,
        updateDomainAnswerSet: true,
      },
      errors: {
        ...state.errors,
        updateDomainAnswerSet: undefined,
      },
    })
  )
  .handleAction(
    actions.updateDomainAnswerSet.success,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      domainAnswerSets: state.domainAnswerSets.map((das) =>
        das.id === action.payload.id ? action.payload : das
      ),
      loading: {
        ...state.loading,
        updateDomainAnswerSet: false,
      },
    })
  )
  .handleAction(
    actions.updateDomainAnswerSet.failure,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      errors: {
        ...state.errors,
        updateDomainAnswerSet: action.payload,
      },
      loading: {
        ...state.loading,
        updateDomainAnswerSet: false,
      },
    })
  )
  // delete domain answer set
  .handleAction(
    actions.deleteDomainAnswerSet.request,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteDomainAnswerSet: true,
      },
      errors: {
        ...state.errors,
        deleteDomainAnswerSet: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteDomainAnswerSet.success,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      domainAnswerSets: state.domainAnswerSets.filter(
        (das) => das.id !== action.payload
      ),
      loading: {
        ...state.loading,
        deleteDomainAnswerSet: false,
      },
    })
  )
  .handleAction(
    actions.deleteDomainAnswerSet.failure,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteDomainAnswerSet: action.payload,
      },
      loading: {
        ...state.loading,
        deleteDomainAnswerSet: false,
      },
    })
  )
  // domain answer set upsert dialog
  .handleAction(
    actions.showDomainAnswerSetUpsertDialog,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        domainAnswerSetUpsertDialog: {
          onSuccess: action.payload.onSuccess,
          editing: action.payload.editing,
          show: true,
        },
      },
      selectedDomainAnswerSet: action.payload.domainAnswerSet,
    })
  )
  .handleAction(
    actions.hideDomainAnswerSetUpsertDialog,
    (state, action): DomainsAnswerSetState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        domainAnswerSetUpsertDialog: {
          show: false,
        },
      },
      selectedDomainAnswerSet: undefined,
    })
  )
  // select domain answer set
  .handleAction(
    actions.selectDomainAnswerSet,
    (state, action): DomainsAnswerSetState => ({
      ...state,

      selectedDomainAnswerSet: action.payload,
    })
  );
