import { Epic } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getPlansEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getPlans.request)),
    switchMap((action) =>
      from(api.plans.getPlans()).pipe(
        map(actions.getPlans.success),
        catchError((error) => of(actions.getPlans.failure(error)))
      )
    )
  );
};

export const addPlanEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addPlan.request)),
    switchMap((action) =>
      from(api.plans.addPlan()).pipe(
        map(actions.addPlan.success),
        catchError((error) => of(actions.addPlan.failure(error)))
      )
    )
  );
};

export const getPlanStatusEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getPlanStatus.request)),
    switchMap((action) =>
      from(api.plans.getPlanStatus(action.payload)).pipe(
        map(actions.getPlanStatus.success),
        catchError((error) => of(actions.getPlanStatus.failure(error)))
      )
    )
  );
};
