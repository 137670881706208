import {
  AnswerDistributionByQuartiles,
  AnswerStatisticsDistribution,
  DemographicWrapper,
  Domain,
  DomainComparison,
  DomainQuestionsComparison,
  DomainStatistics,
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  SurveyDemographic,
  SurveyStatistics,
} from "../../../types";
import * as _ from "lodash";
import { Locale } from "../../../store/UIState";
import { DisplayedRankingViewType } from "./DomainTitleRankingViewComparisonTable";

export const isSurveyStatistics = (
  item: SurveyStatistics | GroupedSurveyStatistics | DomainStatistics,
  demographic: SurveyDemographic
): item is SurveyStatistics => {
  return (
    (item as SurveyStatistics)?.responses?.[demographic]?.distributions !==
    undefined
  );
};

export const isGroupedSurveyStatistics = (
  item: SurveyStatistics | GroupedSurveyStatistics | DomainStatistics,
  demographic: SurveyDemographic
): item is GroupedSurveyStatistics => {
  const statisticsByDemographic = (item as GroupedSurveyStatistics)
    ?.responses?.[demographic];
  return (
    !!statisticsByDemographic && statisticsByDemographic[0]?.label !== undefined
  );
};

export const isDomainStatistics = (
  item:
    | SurveyStatistics[]
    | GroupedSurveyStatistics[]
    | DomainStatistics
    | DomainStatistics<GroupedAnswerStatistics[]>
): item is DomainStatistics => {
  return (item as DomainStatistics)?.survey_questions !== undefined;
};

export const isGroupedDomainStatistics = (
  item:
    | SurveyStatistics[]
    | GroupedSurveyStatistics[]
    | DomainStatistics
    | DomainStatistics<GroupedAnswerStatistics[]>,
  demographic: SurveyDemographic
): item is DomainStatistics<GroupedAnswerStatistics[]> => {
  const domainQuestions = (item as DomainStatistics<GroupedAnswerStatistics[]>)
    ?.survey_questions;
  if (domainQuestions !== undefined && domainQuestions.length > 0) {
    const groupedStatistics = domainQuestions[0]?.[demographic];
    if (groupedStatistics !== null) {
      return groupedStatistics?.length > 0;
    }
  }
  return false;
};

export const getGroupedObjectByMean = (
  answersDistribution: AnswerStatisticsDistribution[]
) => {
  return _.groupBy(answersDistribution, (item) => {
    if (!!item?.mean_rating) {
      if (item?.mean_rating < 0.25) {
        return "0to24";
      } else if (item?.mean_rating >= 0.25 && item?.mean_rating < 0.5) {
        return "25to49";
      } else if (item?.mean_rating >= 0.5 && item?.mean_rating < 0.75) {
        return "50to74";
      } else {
        return "75to100";
      }
    } else if (!!item?.percent) {
      if (item?.percent < 25) {
        return "0to24";
      } else if (item?.percent >= 25 && item?.percent < 50) {
        return "25to49";
      } else if (item?.percent >= 50 && item?.percent < 75) {
        return "50to74";
      } else {
        return "75to100";
      }
    }
  });
};

export const getDistributionChartDataSets = (
  item: SurveyStatistics[] | DomainStatistics,
  demographic: SurveyDemographic,
  hasDomainId?: boolean
): AnswerDistributionByQuartiles[] => {
  if (!hasDomainId) {
    return (item as SurveyStatistics[]).map((sp) => {
      let distribution: AnswerDistributionByQuartiles = {
        "0to24": 0,
        "25to49": 0,
        "50to74": 0,
        "75to100": 0,
      };
      const groupedObjectByMean = getGroupedObjectByMean(
        sp.responses?.[demographic]?.distributions ?? []
      );
      Object.entries(groupedObjectByMean)?.forEach((item) => {
        distribution = {
          ...distribution,
          [item[0]]: item[1]
            ?.map((item) => item.count as number)
            ?.reduce((accum, currentValue) => accum + currentValue),
        };
      });
      return distribution;
    });
  } else {
    return (item as DomainStatistics)?.survey_questions?.map((sq) => {
      let distribution: AnswerDistributionByQuartiles = {
        "0to24": 0,
        "25to49": 0,
        "50to74": 0,
        "75to100": 0,
      };
      const groupedObjectByMean = getGroupedObjectByMean(
        sq?.[demographic]?.distributions ?? []
      );
      Object.entries(groupedObjectByMean)?.forEach((item) => {
        distribution = getUpdatedDistribution(distribution, item);
      });
      return distribution;
    });
  }
};

export const isDomainQuestionsComparison = (
  item: DomainQuestionsComparison | DomainComparison[]
): item is DomainQuestionsComparison => {
  return (item as DomainQuestionsComparison)?.responses !== undefined;
};

export const getUpdatedDistribution = (
  distribution: AnswerDistributionByQuartiles,
  item: [string, AnswerStatisticsDistribution[]]
): AnswerDistributionByQuartiles => {
  return {
    ...distribution,
    [item[0]]: item[1]
      ?.map((item) => item.count as number)
      ?.reduce((accum, currentValue) => accum + currentValue),
  };
};

export const getInitializedAnswerDistributionsObject = (
  availableDemographic: SurveyDemographic[] = Object.values(SurveyDemographic)
): DemographicWrapper<AnswerDistributionByQuartiles> => {
  let distributionByDemographic = {};
  availableDemographic.forEach((item) => {
    distributionByDemographic = {
      ...distributionByDemographic,
      [item]: {
        "0to24": 0,
        "25to49": 0,
        "50to74": 0,
        "75to100": 0,
      },
    };
  });
  return distributionByDemographic;
};

export const getMaxAnswerIndex = <
  T extends
    | GroupedSurveyStatistics
    | DomainStatistics<GroupedAnswerStatistics[]>
    | DomainStatistics
    | SurveyStatistics
    | DomainQuestionsComparison
>(
  demographic: SurveyDemographic,
  stat: T
) => {
  return Number(
    _.max(
      Object.keys(
        stat?.domain?.domain_answer?.[demographic]?.[Locale.English] ?? {}
      )
    )
  );
};

export const fillDisplayedDataDemographicColumn = (
  resultArray: Array<Array<DisplayedRankingViewType | undefined>>,
  demographicIndex: number,
  respondentComparison: {
    domain: Domain;
    mean_score: number;
    demographic: string;
    not_enough_data: boolean;
  }[] = []
): Array<Array<DisplayedRankingViewType | undefined>> => {
  const numberOfHighestRankingDomains = 3;
  const numberOfLowestRankingDomains = 3;
  const highBorder = respondentComparison.length - numberOfLowestRankingDomains;
  let tempResultArray = resultArray;

  if (
    numberOfHighestRankingDomains + numberOfLowestRankingDomains <
    respondentComparison.length
  ) {
    for (let i = 0; i < numberOfHighestRankingDomains; i++) {
      tempResultArray[demographicIndex][i] = {
        isRedacted: false,
        demographic: respondentComparison[i]?.demographic,
        domain: respondentComparison[i]?.domain,
        mean_score: respondentComparison[i]?.mean_score,
        not_enough_data: respondentComparison[i]?.not_enough_data
      };
    }
    tempResultArray[demographicIndex][numberOfHighestRankingDomains] = {
      isRedacted: true,
      domains: respondentComparison
        .filter((rc, i) => i >= numberOfHighestRankingDomains && i < highBorder)
        ?.map(({ domain }) => domain),
      mean_scores: respondentComparison
      .filter((rc, i) => i >= numberOfHighestRankingDomains && i < highBorder)
      ?.map(({ mean_score }) => mean_score),
      not_enough_datas: respondentComparison
      .filter((rc, i) => i >= numberOfHighestRankingDomains && i < highBorder)
      ?.map(({ not_enough_data }) => not_enough_data),
    };
    const lowestRankingDomainsIndexes = _.range(
      highBorder,
      respondentComparison.length
    );
    for (
      let i = numberOfLowestRankingDomains + 1;
      i <= 2 * numberOfLowestRankingDomains;
      i++
    ) {
      const pivot = 2 * numberOfLowestRankingDomains - i;
      tempResultArray[demographicIndex][i] = {
        isRedacted: false,
        demographic:
          respondentComparison[lowestRankingDomainsIndexes[pivot]]?.demographic,
        domain:
          respondentComparison[lowestRankingDomainsIndexes[pivot]]?.domain,
        mean_score:
          respondentComparison[lowestRankingDomainsIndexes[pivot]]?.mean_score,
        not_enough_data:
          respondentComparison[lowestRankingDomainsIndexes[pivot]]?.not_enough_data
      };

      tempResultArray[demographicIndex] = tempResultArray[
        demographicIndex
      ].sort((a, b) => {
        if (a?.mean_score !== undefined && b?.mean_score !== undefined) {
          return b?.mean_score - a?.mean_score;
        } else {
          return 0;
        }
      });
    }

    return tempResultArray;
  }

  for (let i = 0; i < respondentComparison.length; i++) {
    tempResultArray[demographicIndex][i] = {
      isRedacted: false,
      demographic: respondentComparison[i]?.demographic,
      domain: respondentComparison[i]?.domain,
      mean_score: respondentComparison[i]?.mean_score,
      not_enough_data: respondentComparison[i]?.not_enough_data
    };
  }
  return tempResultArray;
};
