import React from "react";
import { useIntl } from "react-intl";
import { Button, Dialog } from "@blueprintjs/core";
import SurveysDeploymentTable from "../table/surveys-deployment-table/SurveysDeploymentTable";

type OwnProps = {
  show: boolean;
  onClose: (ele: boolean) => void;
};

type Props = OwnProps;

const ComparisonReportRestoreSchoolDialog: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { show, onClose } = props;

  return (
    <Dialog
      isOpen={show}
      onClose={() => onClose(false)}
      title={intl.formatMessage({
        id: "app.district-comparison.dialog.add-survey",
      })}
      style={{ width: "80rem" }}
      portalClassName="activity-content-portal"
    >
      <SurveysDeploymentTable showSdDialog={show} darkHeader isTeamPlanTable />
      <div className="text-right mt-4 mr-12">
        <Button
          intent="primary"
          onClick={() => onClose(false)}
          text={intl.formatMessage({
            id: "app.titles.submit",
          })}
        />
      </div>
    </Dialog>
  );
};

export default ComparisonReportRestoreSchoolDialog;
