import React, { FunctionComponent, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Button, NonIdealState } from "@blueprintjs/core";
import { showDomainAnswerSetUpsertDialog } from "../../../../store/domain-answer-set/actions";

type OwnProps = {};

type Props = OwnProps;

const NoDomainAnswerSets: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const handleShowUpsertDomainDialog = useCallback(() => {
    dispatch(showDomainAnswerSetUpsertDialog({ domainAnswerSet: undefined }));
  }, []);

  return (
    <NonIdealState
      icon="clipboard"
      title={intl.formatMessage({
        id: "app.forms.no-domain-answer-sets.title",
      })}
      description={intl.formatMessage({
        id: "app.forms.no-domain-answer-sets.description",
      })}
      action={
        <Button
          large
          className="capitalize ml-2"
          intent="primary"
          onClick={handleShowUpsertDomainDialog}
        >
          {intl.formatMessage({
            id: "app.domains.setup-new-domain-answer-set",
          })}
        </Button>
      }
    />
  );
};

export default NoDomainAnswerSets;
