import { DataPoint, Dataset } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import {
  AddCustomDatasetRequest,
  AddDataPointToCustomDatasetRequest,
  DeleteCustomDatasetRequest,
  GetDatasetsRequest,
  UpdateDataPointInCustomDatasetRequest,
} from "./types";
import { generateQueryString } from "../../helpers/stringUtils";

export const getDatasets = (
  request: GetDatasetsRequest | void
): Promise<Dataset[]> => {
  let url = `${API_ROOT}/datasets/`;

  if (request) {
    const queryStr = generateQueryString(request);

    if (queryStr.length) {
      url += "filters/?" + queryStr;
    }
  }

  return Axios.get(url);
};

export const createCustomDataset = ({
  planId,
  ...req
}: AddCustomDatasetRequest): Promise<Dataset> => {
  return Axios.post(`${API_ROOT}/plans/${planId}/datasets/`, req);
};

export const deleteCustomDataset = ({
  planId,
  datasetId,
}: DeleteCustomDatasetRequest): Promise<void> => {
  return Axios.delete(`${API_ROOT}/plans/${planId}/datasets/${datasetId}/`);
};

export const addDataPointToCustomSet = ({
  planId,
  datasetId,
  ...req
}: AddDataPointToCustomDatasetRequest): Promise<DataPoint> => {
  return Axios.post(
    `${API_ROOT}/plans/${planId}/datasets/${datasetId}/data-points/`,
    req
  );
};

export const deleteDataPointFromCustomDataset = (
  planId: number,
  dataPointId: number
): Promise<void> => {
  return Axios.delete(
    `${API_ROOT}/plans/${planId}/data-points/${dataPointId}/`
  );
};

export const updateDataPointInCustomDataset = ({
  id,
  planId,
  ...rest
}: UpdateDataPointInCustomDatasetRequest): Promise<DataPoint> => {
  return Axios.patch(`${API_ROOT}/plans/${planId}/data-points/${id}/`, rest);
};
