import React, { useMemo } from "react";
import {
  DomainComparison,
  DomainQuestionsComparison,
  SurveyDemographic,
} from "../../../types";
import SurveyComparisonTable from "./SurveyComparisonTable";
import { isDomainQuestionsComparison } from "./utils";
import * as _ from "lodash";
import { Callout, Spinner } from "@blueprintjs/core";
import { useSelector } from "react-redux";

type OwnProps = {
  tagId: string | undefined;
  domainId: number | undefined;
  surveyDeploymentId: number | undefined;
  demographics: SurveyDemographic[];
  surveyComparison: DomainQuestionsComparison | DomainComparison[];
  showNonIdealState?: boolean;
};

type Props = OwnProps;

const SurveyComparisonTableWrapper: React.FC<Props> = (props: Props) => {
  const {
    tagId,
    domainId,
    surveyDeploymentId,
    demographics,
    surveyComparison,
    showNonIdealState,
  } = props;

  const loading = useSelector(
    (s) => s.surveyReports.loading.getSurveyComparison
  );

  const groupedComparison = useMemo(() => {
    if (isDomainQuestionsComparison(surveyComparison)) {
      return surveyComparison;
    } else {
      return _.chain(surveyComparison as DomainComparison[])
        .groupBy((sc) => sc.domain?.domain_answer?.is_negative)
        .entries()
        .orderBy(([isNegative, items]) => isNegative)
        .map(([isNegative, items]) => items)
        .value() as DomainComparison[][];
    }
  }, [surveyComparison]);

  if (loading) {
    return <Spinner intent="primary" />;
  }

  return (
    <>
      {isDomainQuestionsComparison(surveyComparison) ? (
        <SurveyComparisonTable
          tagId={tagId}
          domainId={domainId}
          surveyDeploymentId={surveyDeploymentId}
          demographics={demographics}
          surveyComparison={groupedComparison as DomainQuestionsComparison}
          showNonIdealState={showNonIdealState}
        />
      ) : (
        <>
          {(groupedComparison as DomainComparison[][])?.length ? (
            (groupedComparison as DomainComparison[][])?.map((gc, i) => (
              <div key={i} className="mb-4">
                <SurveyComparisonTable
                  tagId={tagId}
                  domainId={domainId}
                  surveyDeploymentId={surveyDeploymentId}
                  demographics={demographics}
                  surveyComparison={gc}
                  showNonIdealState={showNonIdealState}
                />
              </div>
            ))
          ) : (
            <Callout intent="warning">
              There is no data for the selected domain
              {
                //todo replace message
              }
            </Callout>
          )}
        </>
      )}
    </>
  );
};

export default SurveyComparisonTableWrapper;
