import { createAction, createAsyncAction } from "typesafe-actions";
import { DomainAnswerSet, SurveyDemographic } from "../../types";

export const getDomainAnswerSets = createAsyncAction(
  "@domain-answer-set/GET_DOMAIN_ANSWER_SET_ANSWER_SETS_REQUEST",
  "@domain-answer-set/GET_DOMAIN_ANSWER_SET_ANSWER_SETS_SUCCESS",
  "@domain-answer-set/GET_DOMAIN_ANSWER_SET_ANSWER_SETS_FAILURE"
)<void, DomainAnswerSet[], Error>();

export const addDomainAnswerSet = createAsyncAction(
  "@domain-answer-set/ADD_DOMAIN_ANSWER_SET_REQUEST",
  "@domain-answer-set/ADD_DOMAIN_ANSWER_SET_SUCCESS",
  "@domain-answer-set/ADD_DOMAIN_ANSWER_SET_FAILURE"
)<
  {
    domainAnswerSet: Pick<DomainAnswerSet, SurveyDemographic> & {
      is_negative: boolean;
    };
    onSuccess?: Function;
  },
  DomainAnswerSet,
  Error
>();

export const updateDomainAnswerSet = createAsyncAction(
  "@domain-answer-set/UPDATE_DOMAIN_ANSWER_SET_REQUEST",
  "@domain-answer-set/UPDATE_DOMAIN_ANSWER_SET_SUCCESS",
  "@domain-answer-set/UPDATE_DOMAIN_ANSWER_SET_FAILURE"
)<
  {
    domainAnswerSet: Pick<DomainAnswerSet, "id" | SurveyDemographic> & {
      is_negative: boolean;
    };
    onSuccess?: Function;
  },
  DomainAnswerSet,
  Error
>();

export const deleteDomainAnswerSet = createAsyncAction(
  "@domain-answer-set/DELETE_DOMAIN_ANSWER_SET_REQUEST",
  "@domain-answer-set/DELETE_DOMAIN_ANSWER_SET_SUCCESS",
  "@domain-answer-set/DELETE_DOMAIN_ANSWER_SET_FAILURE"
)<number, number, Error>();

export const showDomainAnswerSetUpsertDialog = createAction(
  "@domain-answer-set/SHOW_DOMAIN_ANSWER_SET_UPSERT_DIALOG"
)<{
  domainAnswerSet: DomainAnswerSet | undefined;
  onSuccess?: Function;
  editing?: boolean;
}>();

export const hideDomainAnswerSetUpsertDialog = createAction(
  "@domain-answer-set/HIDE_DOMAIN_ANSWER_SET_UPSERT_DIALOG"
)<void>();

export const selectDomainAnswerSet = createAction(
  "@domain-answer-set/SELECT_DOMAIN_ANSWER_SET"
)<DomainAnswerSet | undefined>();
