/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Strategy } from '../../../../../types';
import NoStrategy from './no-strategy/NoStrategies';
import { Icon, Spinner } from '@blueprintjs/core';
import './StrategiesList.scss';
import StrategiesListResourceFilter from '../strategies-list-filter/StrategiesListResourceFilter';
import StrategyResourceItem from './strategy-item/StrategyResourceItem';
import { useDispatch, useSelector } from 'react-redux';
import { getSchools } from '../../../../../store/schools/actions';
import { generatePath, useHistory } from 'react-router';
import { AuthRoutes } from '../../../../../App';
import { isDistrictPlan } from '../../../../../store/plans/selectors';

type OwnProps = {};

type Props = OwnProps;
const MIN_STRATEGY_PER_LOAD = 5;

const StrategiesResourceList: FunctionComponent<Props> = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const isActiveDistrictPlan = useSelector(isDistrictPlan);
  const me = useSelector((s) => s.auth.authenticatedUser);

  // STATE
  const [displayedStrategies, setDisplayedStrategies] = useState<Strategy[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const [displayedItems, setDisplayedItems] = useState<number>(MIN_STRATEGY_PER_LOAD);

  const schools = useSelector((s) => s.schools.schools);
  const districts = useSelector((s) => s.districts.districts);

  const filteredStrategy = (data: React.SetStateAction<Strategy[]>) => {
    setDisplayedStrategies(data);
  };

  const data = useMemo(() => {
    let sorted = [];

    sorted = displayedStrategies.sort(function (a, b) {
      let c: any = new Date(a.created_at);
      let d: any = new Date(b.created_at);
      return c - d;
    });
    return sorted;

  }, [displayedStrategies]);

  const loading = (isLoader: boolean | undefined) => {
    setIsLoading(isLoader);
  }

  useEffect(() => {
    dispatch(getSchools.request());
  }, [dispatch]);

  const handleCreateCustomStrategy = () => {
    history.push(
      generatePath(AuthRoutes.YourPlan, {
        workspace: "goals" as any,
        tabId: "custom-strategy"
      } as any)
    );
  }

  return (
    <>
      <div className="StrategiesListFilter">
        <StrategiesListResourceFilter filteredStrategy={filteredStrategy} isLoader={loading} />
      </div>

      <div className="strategies-content">
        <div className="strategies-content__head">
          <label>{intl.formatMessage({ id: "app.strategies.count-label" }, { total: displayedStrategies?.length })}</label>
          
          {isActiveDistrictPlan && (
              <div className="action-bar">
                <button className="nj_btn large-btn" onClick={handleCreateCustomStrategy}>
                  <Icon icon="plus" iconSize={16} /> {intl.formatMessage({ id: "app.title.add-custom-strategy" })}
                </button>
              </div>
           )}
          
        </div>

        <div className="strategies-content__list"  style={{scrollbarWidth:"thin"}}>
          <ul>
          {
            data.length ? data.slice(0, displayedItems).map((ps) => (
              <StrategyResourceItem key={`plan_strategy_${ps.id}`} strategy={ps} schools={schools} />
            ))
              : (
                (isLoading || isLoading === undefined) ?
                  <Spinner intent="primary" className="my-2" /> :
                  <NoStrategy />
              )
          }
          </ul>
          <div>
            <div className="strategies-action">
              <div className="load-more">
                {
                  displayedItems < data.length && (
                    <div className="load-more">
                      <a className="stroke-btn" onClick={() => setDisplayedItems(displayedItems + MIN_STRATEGY_PER_LOAD)}>
                        {intl.formatMessage({ id: "app.title.load-more" })}
                      </a>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StrategiesResourceList;