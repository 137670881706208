import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useEffect, useMemo } from "react";
import { getUrlParam } from "../url-utils";
import { NOTE } from "../../constants/constants";
import { getNotes, showNotesDialog } from "../../store/notes/actions";
import { UserNotes } from "../../types";
import { getActivityById } from "../../store/activities/actions";
import { getMeetingById } from "../../store/meetings/actions";

const useNotesDialogOpenBySearchParams = () => {
  const dispatch = useDispatch();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const location = useLocation();

  const { noteId } = useMemo(() => {
    const noteParam = getUrlParam(NOTE, location.search);
    return { noteId: noteParam && +noteParam };
  }, [location.search]);

  const notes = useSelector((s) => s.notes.notes);
  useEffect(() => {
    if (activePlan?.id && noteId) {
      dispatch(getNotes.request({ planId: activePlan?.id }));
    }
  }, [noteId, activePlan]);

  const noteFromSearchParams: UserNotes | undefined = useMemo(() => {
    if (noteId) {
      return notes.find((n) => n.id === noteId);
    }
  }, [notes]);

  const selectedActivity = useSelector((s) => s.activities.selectedActivity);

  const selectedMeeting = useSelector((s) => s.meetings.meeting);

  useEffect(() => {
    if (noteFromSearchParams && activePlan?.id) {
      if (noteFromSearchParams.activity?.id) {
        dispatch(getActivityById.request(noteFromSearchParams.activity.id));
      }
      if (noteFromSearchParams.meeting) {
        dispatch(
          getMeetingById.request({
            id: noteFromSearchParams.meeting,
            planId: activePlan.id,
          })
        );
      }
    }
  }, [noteFromSearchParams]);

  useEffect(() => {
    if (noteFromSearchParams) {
      if (noteFromSearchParams.activity?.id && noteFromSearchParams.meeting) {
        if (selectedActivity && selectedMeeting) {
          dispatch(
            showNotesDialog({
              agenda: selectedMeeting.agenda,
              meeting: selectedMeeting,
              selectedNote: noteFromSearchParams,
              selectedActivity: selectedActivity,
            })
          );
        }
      } else if (noteFromSearchParams.activity?.id) {
        if (selectedActivity) {
          dispatch(
            showNotesDialog({
              selectedNote: noteFromSearchParams,
              selectedActivity: selectedActivity,
            })
          );
        }
      } else {
        dispatch(showNotesDialog({ selectedNote: noteFromSearchParams }));
      }
    }
  }, [noteFromSearchParams, selectedActivity, selectedMeeting]);
};

export default useNotesDialogOpenBySearchParams;
