import React, { FunctionComponent } from "react";
import { FormGroup, Icon, NumericInput } from "@blueprintjs/core";
import { useIntl } from "react-intl";

type OwnProps = {
  value: string | number;
  onChange: (value: number | string) => any;
  percentage?: boolean;
  labelless?: boolean;
  required: boolean;
};

type Props = OwnProps;

const NumericDataPointInput: FunctionComponent<Props> = (props) => {
  const { value, onChange, percentage, labelless, required } = props;

  const intl = useIntl();

  const handleValueChange = (value: number, valueAsString: string) => {
    onChange(valueAsString);
  };

  return (
    <div className="flex justify-between">
      <FormGroup
        label={
          labelless
            ? undefined
            : intl.formatMessage({
                id: "app.titles.value",
              })
        }
        className="mb-0"
      >
        <NumericInput
          className="right-icon"
          leftIcon={percentage && <Icon icon={"percentage"} />}
          required={required}
          allowNumericCharactersOnly
          placeholder={intl.formatMessage({
            id: "app.forms.value.placeholder",
          })}
          value={value}
          onValueChange={handleValueChange}
          pattern="^[+-]?\d+(\.\d+)?$"
        />
      </FormGroup>
      {props.children}
    </div>
  );
};

export default NumericDataPointInput;
