import { AttachedResource, Meeting } from "../../types";
import { API_ROOT } from "../config";
import Axios from "axios";
import { AddMeetingRequest, UpdateMeetingRequest } from "./types";
import { addResources } from "../resources";

export const getMeetings = (planId: number | undefined): Promise<Meeting[]> => {
  let url = `${API_ROOT}/meetings/`;

  if (planId) {
    url += `?plan_id=${planId}`;
  }

  return Axios.get(url);
};

export const getMeetingById = (
  id: number,
  planId?: number
): Promise<Meeting<string, false>> => {
  let url = `${API_ROOT}/meetings/${id}/`;

  if (planId) {
    url += `?plan_id=${planId}`;
  }
  return Axios.get(url);
};

export const addMeeting = async (body: AddMeetingRequest): Promise<Meeting> => {
  let resourceIds: number[] = body?.attached_resources
    ?.filter((r) => r?.id > 0)
    ?.map((r) => r.id);
  await addResources(
    body?.attached_resources?.filter((r) => r?.id < 0 || !r.id) ?? [],
    body.plan
  ).then((success) => {
    resourceIds = [
      ...resourceIds,
      ...success
        ?.filter((response) => response.status === "fulfilled")
        ?.map(
          (resource) =>
            (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
        ),
    ];
  });
  return Axios.post(`${API_ROOT}/meetings/`, {
    ...body,
    attached_resources: resourceIds,
  });
};

export const updateMeeting = async (
  body: UpdateMeetingRequest
): Promise<Meeting> => {
  let resourceIds: number[] = body?.attached_resources
    ?.filter((r) => r?.id > 0)
    ?.map((r) => r.id);
  await addResources(
    body?.attached_resources?.filter((r) => r?.id < 0 || !r.id) ?? [],
    body.plan
  ).then((success) => {
    resourceIds = [
      ...resourceIds,
      ...success
        ?.filter((response) => response.status === "fulfilled")
        ?.map(
          (resource) =>
            (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
        ),
    ];
  });
  return Axios.put(`${API_ROOT}/meetings/${body.id}/`, {
    ...body,
    attached_resources: resourceIds,
  });
};

export const deleteMeeting = (id: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/meetings/${id}/`);
};
