import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../useLoading";
import { useEffect } from "react";
import { getGoalsByPlan } from "../../../store/goals/actions";

export const useGetGoals = () => {
  const dispatch = useDispatch();

  const activePlan = useSelector((s) => s.plans.activePlan);
  const loading = useSelector((s) => s.goals.loading.getGoals);
  const error = useSelector((s) => s.goals.errors.getGoals);
  useLoading({ loading, error });
  const goals = useSelector((s) => s.goals.goals);

  useEffect(() => {
    if (activePlan?.id) {
      dispatch(getGoalsByPlan.request(activePlan.id));
    }
  }, [activePlan?.id]);

  return {
    loading,
    error,
    goals,
    activePlanId: activePlan?.id,
  };
};
