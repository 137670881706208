import { Button, NonIdealState } from "@blueprintjs/core";
import moment from "moment";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { deletePlanStrategyImplementation } from "../../../../store/plan-strategies/actions";
import { showImplementationStatusDialog } from "../../../../store/strategies/actions";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import { LoggedStrategyImplementation, PlanStrategy } from "../../../../types";
import EntityTable from "../../../common/EntityTable";

type OwnProps = {
  planStrategy: PlanStrategy;
};

type Props = OwnProps;

const ImplementationLogsTable: React.FC<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const deleteLoading = useSelector(
    (s) => s.planStrategies.loading.deletePlanStrategyImplementation
  );
  const deleteError = useSelector(
    (s) => s.planStrategies.errors.deletePlanStrategyImplementation
  );
  useLoading({ loading: deleteLoading, error: deleteError });

  const { planStrategy } = props;

  const implementations = useMemo(() => planStrategy.implementations, [
    planStrategy,
  ]);

  const showConfirmDeleteDialog = (implementation: number) => () => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(
            deletePlanStrategyImplementation.request({
              plan_strategy_id: planStrategy.id,
              implementation: implementation,
            })
          );
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-implementation",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const showUpdateImplementationDialog = (implementation: number) => () => {
    dispatch(
      showImplementationStatusDialog({
        planStrategy: planStrategy,
        implementation: implementation,
      })
    );
  };

  const columns: any = useMemo(
    () => [
      {
        Header: intl.formatMessage({
          id: "app.titles.date",
        }),
        accessor: "date",
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<LoggedStrategyImplementation>) => {
          return <div>{moment(original.date).format("MM/DD/YYYY")}</div>;
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.titles.score",
        }),
        accessor: "score",
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<LoggedStrategyImplementation>) => {
          return <div>{Math.round((original.score || 0) * 100)}</div>;
        },
      },
      {
        id: "actions",
        width: "100%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({
          row: { original },
        }: CellProps<LoggedStrategyImplementation>) => {
          return (
            <div className="flex -ml-1">
              <Button
                title={intl.formatMessage({
                  id: "app.tables.implementation-logs.columns.actions.edit",
                })}
                icon="edit"
                className="whitespace-no-wrap m-1"
                intent="primary"
                onClick={showUpdateImplementationDialog(original.id)}
              />
              <Button
                title={intl.formatMessage({
                  id: "app.surveys-table.columns.delete",
                })}
                icon="trash"
                className="whitespace-no-wrap m-1"
                intent="danger"
                onClick={showConfirmDeleteDialog(original.id)}
              />
            </div>
          );
        },
      },
    ],
    [planStrategy]
  );

  const handleUpdateImplementationStatusClick = () => {
    dispatch(
      showImplementationStatusDialog({
        planStrategy: planStrategy,
      })
    );
  };

  const nonIdealState = useMemo(
    () => (
      <NonIdealState
        icon="search"
        className="pb-2"
        title={intl.formatMessage({
          id:
            "app.tables.implementation-logs.non-ideal-state.no-implementations",
        })}
        description={intl.formatMessage({
          id:
            "app.tables.implementation-logs.non-ideal-state.no-implementations.description",
        })}
        action={
          <Button
            text={intl.formatMessage({
              id: "app.strategies.update-implementation-status",
            })}
            title={intl.formatMessage({
              id: "app.strategies.update-implementation-status",
            })}
            icon="plus"
            intent="primary"
            onClick={handleUpdateImplementationStatusClick}
          />
        }
      />
    ),
    []
  );

  return (
    <EntityTable
      data={implementations}
      loading={deleteLoading}
      columns={columns}
      noDataComp={nonIdealState}
      darkHeader
    />
  );
};

export default ImplementationLogsTable;
