import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  BookmarkDetail,
  ChartDataSet,
  DatasetParamsKey,
  Gender,
  Grade,
  GroupedAnswerStatistics,
  Race,
  RemoteLearning,
  StackedBarLabels,
  StaffRoleQuestions,
  UserRole,
  userRoles,
  YesNoAnswerOptions,
} from "../../../../types";
import _ from "lodash";
import useRolesPermissions from "../../../../helpers/hooks/useRolesPermissions";
import { getBooleanFromLocalStorage, LocalStorageKeys } from "../../../../constants";

export const useDatasetStatistic = ({
  groupedAnswerStatistics = [],
  nonIdealStateMessage,
  maxAnswerIndex,
  manageBookmarkDialog,
}: {
  groupedAnswerStatistics: GroupedAnswerStatistics[] | null | undefined;
  nonIdealStateMessage: string;
  maxAnswerIndex: number;
  manageBookmarkDialog?: boolean;
}) => {
  const intl = useIntl();
  const LABEL = "label";
  const TOTAL_RESPONDENTS_THRESHOLD = 10;
  const isSuperAdmins = useRolesPermissions(userRoles.admins as UserRole[]);

  const isRedactedDataCollapsed = useSelector(
    (s) => s.surveyReports.isRedactedDataCollapsed
  );

  const selectedBookmarkDetails: BookmarkDetail = useSelector(
    (s) => s.surveyReports.bookmarkDialogDetails
  );

  const [redactedStatistics, doubleDisaggregation] = useMemo(() => {
    let filteredStatistics = groupedAnswerStatistics;
    let doubleDisaggregation = 0;
    if (groupedAnswerStatistics?.length) {
      groupedAnswerStatistics?.forEach((s: any) => {
        const paramsWithValues = _.pickBy(s?.parameters, (val) => val != null);
        if (_.size(paramsWithValues) > 1) doubleDisaggregation++;
      });
      if (doubleDisaggregation > 0) {
        filteredStatistics = groupedAnswerStatistics?.filter((s: any) => {
          if (isRedactedDataCollapsed && s.not_enough_data) return false;
          const paramsWithValues = _.pickBy(
            s?.parameters,
            (val) => val != null
          );
          if (_.size(paramsWithValues) > 1) return true;
          else return false;
        });
      } else if (isRedactedDataCollapsed) {
        filteredStatistics = groupedAnswerStatistics?.filter(
          (s) => !s.not_enough_data
        );
      }
    }
    return [
      filteredStatistics,
      !manageBookmarkDialog && doubleDisaggregation > 0,
    ];
  }, [isRedactedDataCollapsed, groupedAnswerStatistics]);

  const hasNotEnoughData = useMemo(() => {
    return redactedStatistics?.length !== groupedAnswerStatistics?.length;
  }, [redactedStatistics, groupedAnswerStatistics]);

  const searchParam = window.location.search
    .split("&")[0]
    ?.split("?")[1]
    .split("=")[1];

  const secondSearchParam = window.location.search.split("&")[1]?.split("=")[1];

  const orderedList = useMemo(() => {
    return {
      grade: Object.values(Grade),
      gender: Object.values(Gender),
      race: Object.values(Race),
      remote_learning: Object.values(RemoteLearning),
      staff_role: Object.values(StaffRoleQuestions),
      direct_instruction: Object.values(YesNoAnswerOptions),
      reduced_lunch: Object.values(YesNoAnswerOptions),
      has_iep: Object.values(YesNoAnswerOptions),
      english_at_home: Object.values(YesNoAnswerOptions),
      demographic: Object.values(YesNoAnswerOptions),
      is_grouped: Object.values(YesNoAnswerOptions),
    };
  }, []);

  const dataSet: ChartDataSet & any = useMemo(() => {
    if (
      redactedStatistics?.length &&
      groupedAnswerStatistics?.length &&
      !doubleDisaggregation
    ) {
      let barDataSet = _.chain(redactedStatistics)
        .sort(
          // sort labels of chart
          (a: GroupedAnswerStatistics, b: GroupedAnswerStatistics) => {
            let a_i = ((orderedList as any)[searchParam] as any).indexOf(
              (a.parameters as any)[searchParam]
            );
            let b_i = ((orderedList as any)[searchParam] as any).indexOf(
              (b.parameters as any)[searchParam]
            );
            return a_i > b_i ? 1 : a_i === b_i ? 0 : -1;
          }
        )
        .reduce<any>(
          (pV, cV: any) => {
            let dataItem;
            if (
              selectedBookmarkDetails &&
              cV.parameters &&
              (cV.parameters as any)[
                selectedBookmarkDetails.selectedLabelKey
              ] != selectedBookmarkDetails.selectedLabelValue
            ) {
              return pV;
            } else {      
              let mean_val = (cV.value?.mean_rating ?? 0) * maxAnswerIndex;
              // this is to show thin bar for mean score 1 // in future we can try better approch
              if (mean_val === 1) mean_val = 1.04;
              dataItem = cV.not_enough_data
                ? null // null uses to display non ideal state in HorizontalBarChart
                : mean_val;
            }

            const label = cV.not_enough_data ? nonIdealStateMessage : cV.label;

            let displayedLabel =
              cV.label === ""
                ? intl.formatMessage({
                    id: "app.survey-report.charts.not-specified",
                  })
                : cV.label;
            if (secondSearchParam) {
              displayedLabel = `${cV.parameters[searchParam + LABEL]}, ${
                cV.parameters[secondSearchParam + LABEL]
              }`;
            }

            return {
              data: [...pV.data, dataItem],
              labels: [...pV.labels, label],
              displayedLabels: [...pV.displayedLabels, displayedLabel],
              params: [...pV.params, cV.parameters],
            };
          },
          {
            data: [],
            labels: [],
            displayedLabels: [],
            params: [],
          }
        )
        .value();

      if (hasNotEnoughData && isRedactedDataCollapsed) {
        const labels = groupedAnswerStatistics
          ?.filter((item) => item.not_enough_data)
          ?.map((item) =>
            item.label === ""
              ? intl.formatMessage({
                  id: "app.survey-report.charts.not-specified",
                })
              : item.label
          );
        return {
          ...barDataSet,
          data: [...barDataSet.data, null], // null uses to display non ideal state in HorizontalBarChart
          labels: [...barDataSet.labels, nonIdealStateMessage],
          displayedLabels: [...barDataSet.displayedLabels, labels],
        };
      }
      return barDataSet;
    } else {
      let barDataSet: any = {
        labels: [],
        datasets: [],
        displayedLabels: [],
      };

      if (redactedStatistics && redactedStatistics.length > 0) {
        redactedStatistics?.forEach((l: any) => {
          [searchParam, secondSearchParam].forEach((param) => {
            if (
              param === "english_at_home" ||
              param === "has_iep" ||
              param === "reduced_lunch"
            ) {
              l.parameters[param + LABEL] = intl.formatMessage(
                { id: "app.choices.simple-answers" },
                { value: l.parameters[param] }
              );
            } else if (param === "remote_learning") {
              l.parameters[param + LABEL] = intl.formatMessage(
                { id: "app.enums.remote-learning" },
                { learning: l.parameters[param] }
              );
            } else if (param === "gender") {
              l.parameters[param + LABEL] = intl.formatMessage(
                { id: "app.enums.gender" },
                { gender: l.parameters[param] }
              );
            } else if (param === "race") {
              l.parameters[param + LABEL] = intl.formatMessage(
                { id: "app.choices.race" },
                { race: l.parameters[param] }
              );
            } else if (param === "grade") {
              l.parameters[param + LABEL] = intl.formatMessage(
                { id: "app.double-disaggregation.grades.grade" },
                { grade: l.parameters[param] }
              );
            } else if (param === "staff_role") {
              l.parameters[param + LABEL] = intl.formatMessage(
                { id: "app.choices.staff_role.certified" },
                { value: l.parameters[param] }
              );
            }
          });

          let labelIndex = barDataSet.labels.findIndex(
            (x: StackedBarLabels) =>
              x.label === l.parameters[searchParam + LABEL]
          );
          if (labelIndex === -1) {
            barDataSet.labels.push({
              label: l.parameters[searchParam + LABEL],
              original_label_key: searchParam,
              original_label_value: l.parameters[searchParam],
              total_respondents: l.total_respondents || l.count,
            });
          } else if (l.total_respondents || l.count) {
            barDataSet.labels[labelIndex].total_respondents +=
              l.total_respondents || l.count;
          }

          let mean_val = l.not_enough_data
            ? null
            : (l?.value?.mean_rating ?? 0) * maxAnswerIndex;
          if (mean_val) {
            mean_val = parseFloat(mean_val.toFixed(2));
          }
          let dataSetIndex = barDataSet.datasets.findIndex(
            (x: any) => x.label === l.parameters[secondSearchParam + LABEL]
          );
          if (dataSetIndex === -1) {
            barDataSet.datasets.push({
              original_key: secondSearchParam,
              original_value: l.parameters[secondSearchParam],
              label: l.parameters[secondSearchParam + LABEL],
              data: [
                {
                  y: l.parameters[searchParam],
                  x: mean_val,
                },
              ],
            });
          } else {
            barDataSet.datasets[dataSetIndex].data.push({
              y: l.parameters[searchParam],
              x: mean_val,
            });
          }
        });
      }

      barDataSet.labels = barDataSet.labels.sort(
        // sort labels of chart
        (a: StackedBarLabels, b: StackedBarLabels) => {
          let a_i = (orderedList[a.original_label_key] as any).indexOf(
            a.original_label_value
          );
          let b_i = (orderedList[a.original_label_key] as any).indexOf(
            b.original_label_value
          );
          return a_i > b_i ? 1 : a_i === b_i ? 0 : -1;
        }
      );

      barDataSet.labelsWithDetail = barDataSet.labels;

      const localFlagToHideSensitiveData: boolean =  getBooleanFromLocalStorage(
        LocalStorageKeys.SensitiveReportingDataHidden
      );
      barDataSet.displayedLabels = barDataSet.labels.map((x: any) => x.label);
      barDataSet.labels = barDataSet.labels.map((x: any) =>( (x.total_respondents  == null ||
        x.total_respondents  < TOTAL_RESPONDENTS_THRESHOLD) &&
        isSuperAdmins && localFlagToHideSensitiveData)?nonIdealStateMessage:x.label);

      barDataSet.labelsWithDetail.forEach((e: any, i: number) => {
        barDataSet.datasets.forEach((de: any, m: number) => {
          if (de.data.length < barDataSet.labels.length) {
            if (
              de.data.findIndex((x: any) => x.y === e.original_label_value) ===
              -1
            ) {
              // push missing value in data for currect chart view
              barDataSet.datasets[m].data.push({
                y: e.original_label_value,
                x: null,
              });
            }
          }
        });

        if (
          (e.total_respondents == null ||
            e.total_respondents < TOTAL_RESPONDENTS_THRESHOLD) &&
          !isSuperAdmins
        ) {
          barDataSet.datasets.forEach((de: any, m: any) => {
            de.data.forEach((datae: any, n: any) => {
              if (datae.y === barDataSet.labels[i].toLowerCase())
                barDataSet.datasets[m].data[n].x = null; // make all x value null which has total_respondents less then TOTAL_RESPONDENTS_THRESHOLD/10
            });
          });
          barDataSet.labels[i] = nonIdealStateMessage;
        }
      });

      barDataSet.datasets.forEach((de: any, m: any) => {
        barDataSet.datasets[m].data = de.data.sort((a: any, b: any) => {
          // sort data in dataset
          let a_i = (orderedList[
            searchParam as DatasetParamsKey
          ] as any).indexOf(a.y);
          let b_i = (orderedList[
            searchParam as DatasetParamsKey
          ] as any).indexOf(b.y);
          return a_i > b_i ? 1 : a_i === b_i ? 0 : -1;
        });
      });

      return barDataSet;
    }
  }, [
    redactedStatistics,
    groupedAnswerStatistics,
    maxAnswerIndex,
    isRedactedDataCollapsed,
    hasNotEnoughData,
    selectedBookmarkDetails,
  ]);

  const params = useMemo(() => {
    if (manageBookmarkDialog) {
      return dataSet.params ?? [];
    }

    return _.chain(redactedStatistics)
      .map((rd) => rd && rd.parameters)
      .value();
  }, [redactedStatistics, dataSet?.params]);

  return {
    dataSet,
    redactedStatistics,
    hasNotEnoughData,
    params,
    doubleDisaggregation,
  };
};

export default useDatasetStatistic;
