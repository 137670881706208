import React, { FunctionComponent } from 'react';
import StrategiesList from './strategies-list/StrategiesList';


type OwnProps = {};

type Props = OwnProps;

const Strategies: FunctionComponent<Props> = () => {

    return (
        <>
            <StrategiesList />
        </>
    )
}


export default Strategies;