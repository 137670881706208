import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Icon, Tooltip } from "@blueprintjs/core";
import { ChartTooltipModel } from "chart.js";
import { useSelector } from "react-redux";
import {
  BookmarkType,
  SurveyDemographic,
  StackedBarDataset,
  StackedBarLabels,
  DatasetParamsKey,
} from "../../types";
import _ from "lodash";
import { datasetParamsArray } from "../surveys/cards/useDatasetMark";

type OwnProps = {
  domainId?: number;
  questionId?: number;
  datasets?: StackedBarDataset[];
  labelsWithDetail?: StackedBarLabels[];
  demographic?: SurveyDemographic;
  tooltipModel?: ChartTooltipModel;
};

type Props = OwnProps;

const CustomHorizontalBarTooltip: FunctionComponent<Props> = (props) => {
  const {
    domainId,
    questionId,
    datasets = [],
    labelsWithDetail = [],
    demographic,
    tooltipModel,
  } = props;

  const TOOLTIL_CARET_Y_ADJUSTMENT_VALUE = 23;

  const datasetMarks = useSelector((s) => s.datasetMarks.datasetMarks);
  const [isOpenTooltip, setIsopenTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (tooltipModel?.dataPoints) {
      setIsopenTooltip(false);
      const timeout = setTimeout(() => {
        setIsopenTooltip(true);
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, [tooltipModel?.dataPoints[0]?.value]);

  const { firstGroupingKey, firstGroupingKeyDictionary } = useMemo((): {
    firstGroupingKeyDictionary: string[];
    firstGroupingKey?: DatasetParamsKey;
  } => {
    return {
      firstGroupingKeyDictionary: labelsWithDetail.map(
        (d) => d.original_label_value
      ),
      firstGroupingKey: labelsWithDetail[0]?.original_label_key,
    };
  }, [labelsWithDetail]);

  const { secondGroupingKey, secondGroupingKeyDictionary } = useMemo((): {
    secondGroupingKeyDictionary: string[];
    secondGroupingKey?: DatasetParamsKey;
  } => {
    return {
      secondGroupingKeyDictionary: datasets.map((d) => d.original_value),
      secondGroupingKey: datasets[0]?.original_key,
    };
  }, [datasets]);

  const marksDictionary = useMemo(() => {
    if (
      !domainId ||
      secondGroupingKey == null ||
      firstGroupingKey == null ||
      !demographic
    ) {
      return null;
    }

    const nullParams = _.without(
      datasetParamsArray,
      "demographic",
      "is_grouped",
      firstGroupingKey,
      secondGroupingKey
    );

    return _.chain(datasetMarks)
      .filter(({ dataset }) => {
        return (
          // to make sure
          !!dataset.domain?.id &&
          domainId == dataset.domain.id &&
          questionId == dataset.question?.id &&
          !!dataset.is_grouped &&
          dataset.demographic == demographic &&
          dataset[firstGroupingKey] != null &&
          dataset[secondGroupingKey] != null &&
          nullParams.every((key) => dataset[key] == null)
        );
      })
      .groupBy((dM) => dM.dataset[firstGroupingKey])
      .toPairs()
      .map(([key, items]) => [
        key,
        _.chain(items)
          .groupBy((dM) => dM.dataset[secondGroupingKey])
          .mapValues((items) => items?.[0]?.bookmark_type)
          .value(),
      ])
      .fromPairs()
      .value();
  }, [
    domainId,
    questionId,
    demographic,
    datasetMarks,
    firstGroupingKey,
    secondGroupingKey,
  ]);

  if (!tooltipModel || tooltipModel.body.length > 1) {
    return null;
  }

  return (
    <div
      className="absolute"
      style={{
        top: tooltipModel.caretY - TOOLTIL_CARET_Y_ADJUSTMENT_VALUE,
        left: tooltipModel.caretX,
      }}
    >
      <Tooltip
        content={
          <div
            className="m-2"
            style={{
              maxWidth: "30rem",
            }}
          >
            {tooltipModel.body.map((item, index) => {
              let bookMarkIconColor;
              if (
                tooltipModel?.dataPoints?.[0].index != null &&
                tooltipModel?.dataPoints?.[0].datasetIndex != null
              ) {
                const marked: BookmarkType | undefined =
                  marksDictionary?.[
                  firstGroupingKeyDictionary[tooltipModel.dataPoints[0].index]
                  ]?.[
                  secondGroupingKeyDictionary[
                  tooltipModel.dataPoints[0].datasetIndex
                  ]
                  ];
                if (marked) {
                  bookMarkIconColor =
                    marked === BookmarkType.Positive ? "green" : "red";
                }
              }
              return (
                <>
                  <div className="flex items-center" key={index}>
                    {item.lines[0]}
                    {bookMarkIconColor && (
                      <Icon icon={"bookmark"} color={bookMarkIconColor} />
                    )}
                  </div>
                  <i>{item?.lines?.[1]}</i>
                </>
              );
            })}
          </div>
        }
        isOpen={isOpenTooltip}
        position="right"
      >
        <div />
      </Tooltip>
    </div>
  );
};

export default CustomHorizontalBarTooltip;
