import React, { FunctionComponent, useMemo } from "react";
import { MeasurementType, PossibleValue } from "../../../../types";
import DateInputGroup from "./DateInputGroup";
import NumericDataPointInput from "./NumericDataPointInput";
import RatioDataPointInput from "./RatioDataPointInput";
import LabelDataInput from "./LabelDataInput";

type OwnProps = {
  labelless?: boolean;
  value: string | number;
  setValue: (value: string | number) => void;
  measurementType?: MeasurementType;
  date?: Date;
  setDate?: React.Dispatch<React.SetStateAction<Date | undefined>>;
  possibleValues?: Pick<PossibleValue, "id" | "order" | "name">[];
  requiredValue?: boolean;
};

type Props = OwnProps;

const MeasurementBasedInput: FunctionComponent<Props> = (props) => {
  const {
    value,
    setValue,
    date,
    setDate,
    possibleValues,
    measurementType,
    labelless,
    requiredValue = true,
  } = props;

  const dateInput = useMemo(
    () =>
      setDate ? (
        <DateInputGroup
          minDateOffset={5}
          date={date}
          onDateChange={(d) => {
            setDate(d);
          }}
        />
      ) : null,
    [date, setDate]
  );

  switch (measurementType) {
    case MeasurementType.Number:
    case undefined:
    case null:
      return (
        <NumericDataPointInput
          labelless={labelless}
          value={value}
          onChange={(v) => setValue(v)}
          required={requiredValue}
        >
          {dateInput}
        </NumericDataPointInput>
      );
    case MeasurementType.Percentage:
      return (
        <NumericDataPointInput
          labelless={labelless}
          percentage
          value={value}
          onChange={(v) => setValue(v)}
          required={requiredValue}
        >
          {dateInput}
        </NumericDataPointInput>
      );
    case MeasurementType.Ratio:
      return (
        <RatioDataPointInput
          labelless={labelless}
          value={value}
          onChange={(v) => setValue(v)}
          required={requiredValue}
        >
          {dateInput}
        </RatioDataPointInput>
      );
    case MeasurementType.Labels:
      return (
        <LabelDataInput
          labelless={labelless}
          possibleValues={possibleValues}
          value={value}
          onChange={(v) => setValue(v)}
          required={requiredValue}
        >
          {dateInput}
        </LabelDataInput>
      );
    default:
      return null;
  }
};

export default MeasurementBasedInput;
