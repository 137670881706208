import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Callout, NonIdealState, Spinner } from "@blueprintjs/core";
import useComparison from "../../../surveys/tab/useComparison";
import { Domain, DomainComparison, SurveyDemographic } from "../../../../types";
import DomainComparisonCard from "./DomainComparisonCard";
import _ from "lodash";
import { useLoadingWithDependencies } from "../../../../helpers/hooks/loading/useLoadingWithDependencies";
import DemographicEyeIcon from "./DemographicEyeIcon";

type OwnProps = {
  surveyDeploymentId?: number;
  showRedBorder?: boolean;
  isPriorityConfirmed?: boolean;
};

type Props = OwnProps;

const ExploreStrengthsAndNeedsTab: FunctionComponent<Props> = (props) => {
  const { surveyDeploymentId, showRedBorder, isPriorityConfirmed } = props;

  const intl = useIntl();

  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);

  const loadingGetSurveyComparison = useSelector(
    (s) => s.surveyReports.loading.getSurveyComparison
  );
  const loadingGetDomainPriorities = useSelector(
    (s) => s.needs.loading.getDomainPriorities
  );
  const loadingGetDatasetMarks = useSelector(
    (s) => s.datasetMarks.loading.getDatasetMarks
  );

  const { demographics } = useComparison({
    surveyComparison: surveyComparison,
  });

  const { sortedData, domainsGroups } = useMemo(() => {
    const domainsGroups = _.isArray(surveyComparison)
      ? _.chain(surveyComparison)
        .reduce<{
          positive: DomainComparison[];
          negative: DomainComparison[];
        }>(
          (pV, cV) => {
            if (cV.domain.domain_answer.is_negative) {
              return {
                ...pV,
                negative: [...pV.negative, cV],
              };
            }
            return {
              ...pV,
              positive: [...pV.positive, cV],
            };
          },
          {
            positive: [],
            negative: [],
          }
        )
        .values()
        .value()
      : [];

    const sortedData: {
      [key in SurveyDemographic]?: {
        domain: Domain;
        meanScore?: number;
      }[][];
    } = _.chain(demographics)
      .reduce((pV, d) => {
        return {
          ...pV,
          [d]: _.chain(domainsGroups)
            .mapValues((items) => {
              return _.chain(items)
                .map((sC) => ({
                  domain: sC.domain,
                  meanScore: sC.respondents?.[d]?.mean_score,
                }))
                .filter((d) => !!d.meanScore)
                .orderBy((d) => d.meanScore ?? "", ["desc"])
                .value();
            })
            .value(),
        };
      }, {})
      .value();

    return {
      domainsGroups: domainsGroups,
      sortedData: sortedData,
    };
  }, [surveyComparison, demographics]);

  const { loading } = useLoadingWithDependencies({
    loadingDeps: [
      loadingGetSurveyComparison,
      loadingGetDomainPriorities,
      loadingGetDatasetMarks,
    ],
  });

  const { hiddenDemographics } = useSelector((s) => s.needs.hovered);

  if (loading) {
    return <Spinner intent={"primary"} />;
  }

  if (!domainsGroups.length) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({ id: "app.titles.no-data" })}
      />
    );
  }
  
  return (
    <div className="teamContent">
      {isPriorityConfirmed && (
        <div className="mb-3">
          <Callout intent="warning">
            {intl.formatMessage({
              id: "app.prioritized-domain-warning-text",
            })}
          </Callout>
        </div>
      )}
      {
        domainsGroups.map((domainComparisons, comparisonGroupIndex) => {
          let notEnoghGroup = {
            elementary_students: false,
            students: false,
            parents: false,
            school_staff: false
          };
          return (
            <div
              key={comparisonGroupIndex}
              className="explore-strengths-and-needs-table"
            >
              <div className="explore-strengths-and-needs-table__header">
                <div>
                  {intl.formatMessage({
                    id: "app.titles.domain-scores-and-bookmarks-summary",
                  })}
                </div>
                <div>
                  {demographics.map((demographic) => (
                    <div key={demographic} className="left-center-grid-container">
                      <DemographicEyeIcon
                        demographic={demographic}
                        hidden={hiddenDemographics[demographic]}
                      />
                      {intl.formatMessage({
                        id: `app.filters.respondent-type.${demographic}`,
                      })}
                    </div>
                  ))}
                </div>
              </div>

              <div className="explore-strengths-and-needs-table__body">
                {domainComparisons.length ? (
                  domainComparisons.map((dc, index) => (
                    <div key={index} className="flex gap-10">
                      {demographics.map((demographic) => {
                        const item =
                          sortedData[demographic]?.[comparisonGroupIndex]?.[index];

                        if (dc.respondents[demographic]?.not_enough_data && sortedData[demographic]?.[0].length && !notEnoghGroup[demographic]) {
                          notEnoghGroup[demographic] = true;

                          return (
                            <div className="flex-1" key={demographic + index}>
                              <Callout
                                title={intl.formatMessage({
                                  id:
                                    "app.non-ideal-state.survey-reports.not-enough-respondents-in-domain",
                                })}
                                intent="warning"
                              />
                            </div>
                          );
                        }

                        if (!item && !sortedData[demographic]?.[0].length && index === 0) {
                          return (
                            <div className="flex-1" key={demographic + index} >
                              <Callout title={intl.formatMessage({ id: "app.non-ideal-state.survey-reports.not-enough-respondents" })} intent="warning" />
                            </div>
                          );
                        }

                        if (!item) {
                          return (
                            <div className="flex-1" key={demographic + index} />
                          );
                        }

                        return (
                          <DomainComparisonCard
                            key={`${item.domain.id}_${demographic}`}
                            hiddenOnDemographicLevel={
                              hiddenDemographics[demographic]
                            }
                            isPriorityConfirmed={isPriorityConfirmed}
                            domain={item.domain}
                            surveyDeploymentId={surveyDeploymentId}
                            demographic={demographic}
                            meanScore={item.meanScore}
                            showRedBorder={showRedBorder}
                          />
                        );
                      })}
                    </div>
                  ))
                ) : (
                  <Callout
                    title={intl.formatMessage({ id: "app.titles.no-data" })}
                    intent="warning"
                  />
                )}
              </div>
            </div>
          )
        })}
    </div>
  );
};

export default ExploreStrengthsAndNeedsTab;
