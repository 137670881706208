import React, { useMemo } from "react";
import {
  ChartDataSet,
  SurveyDemographic,
  SurveyStatistics,
} from "../../../../types";
import ReportTableDomainItem from "../../tab/domains/ReportTableDomainItem";
import HorizontalBarChart from "../../../charts/HorizontalBarChart";
import { Locale } from "../../../../store/UIState";
import BarChart from "../../../charts/BarChart";
import SurveyAnonymizeNonIdealState from "../non-ideal-states/SurveyAnonymizeNonIdealState";
import useDatasetMark from "../../cards/useDatasetMark";
import ItemMark from "./ItemMark";
import * as _ from "lodash";

type AnswerDataSet = {
  value: string;
  percent: number;
};

type OwnProps = {
  index: number;
  maxAnswerIndex: number;
  surveyStatistics: SurveyStatistics;
  demographic: SurveyDemographic;
  showTotalRespondentsColumn: boolean;
  isHighlighted?: boolean;
  manageBookmarkDialog?: boolean;
  inReportInsights?: boolean;
  isDisaggregation?: boolean;
};

type Props = OwnProps;

const SurveyStatisticsTableRow: React.FC<Props> = (props) => {
  const {
    index,
    maxAnswerIndex,
    surveyStatistics,
    demographic,
    showTotalRespondentsColumn,
    isHighlighted,
    isDisaggregation,
    manageBookmarkDialog,
    inReportInsights,
  } = props;

  const answersSet: AnswerDataSet[] = useMemo(() => {
    const domainAnswers =
      surveyStatistics?.domain?.domain_answer?.[demographic];
    if (!!domainAnswers) {
      return Object.entries(domainAnswers?.[Locale.English] ?? {})
        ?.filter((entry) => Number(entry[0]) !== -1)
        ?.map((item) => ({
          value: item[1],
          percent:
            (surveyStatistics?.responses?.[demographic]?.distributions?.find(
              (d) => d.answer_index === Number(item[0])
            )?.mean_rating ?? 0) * 100,
        }));
    }
    return [];
  }, [demographic, surveyStatistics]);

  const dataSet: ChartDataSet = useMemo(
    () => ({
      data: answersSet?.map((item) => Math.round(item?.percent ?? 0)),
      labels: answersSet?.map((item) => item?.value),
    }),
    [answersSet]
  );

  const overallMean: number = useMemo(() => {
    const mValue =
      (surveyStatistics.responses?.[demographic]?.mean_rating ?? 0) *
      maxAnswerIndex;
    //to show tiny bar for value 1
    if (mValue === 1.0) return 1.04;
    else return mValue;
  }, [surveyStatistics, demographic, maxAnswerIndex]);

  const {
    flagged,
    bookmarkType,
    onClick,
    onDiscard,
    supportsBookmarks,
  } = useDatasetMark(
    {
      domainId: surveyStatistics.domain.id,
      demographic: demographic,
      is_grouped: false,
    },
    overallMean
  );

  const { leftPartClassName, rightPartClassName } = useMemo(() => {
    return {
      leftPartClassName: isHighlighted ? "bg-orange-300" : "bg-gray-200",
      rightPartClassName: isHighlighted ? "bg-orange-300" : "bg-gray-100",
    };
  }, [isHighlighted]);

  const keyHorizontalBarChart = useMemo(() => {
    return inReportInsights ? 0 : 1;
  }, [inReportInsights]);

  return (
    <tr>
      <td colSpan={supportsBookmarks ? 1 : 2} className={leftPartClassName}>
        <ReportTableDomainItem
          domain={surveyStatistics.domain}
          manageBookmarkDialog={manageBookmarkDialog}
        />
      </td>

      {supportsBookmarks && (
        <td className={`${leftPartClassName} p-2`}>
          <ItemMark
            flagged={flagged}
            bookmarkType={bookmarkType}
            onClick={onClick}
            onDiscard={onDiscard}
          />
        </td>
      )}

      {surveyStatistics?.not_enough_data ? (
        <td colSpan={3} className={leftPartClassName}>
          <SurveyAnonymizeNonIdealState />
        </td>
      ) : (
        <>
          <td
            key={keyHorizontalBarChart}
            className={rightPartClassName}
            style={{ maxWidth: 200 }}
          >
            <div className="flex">
              <div className="flex w-full items-center">
                <HorizontalBarChart
                  index={index}
                  maxAnswerIndex={maxAnswerIndex}
                  dataSet={{
                    data: [overallMean],
                    labels: [],
                  }}
                  isDisaggregation={isDisaggregation}
                  domainId={surveyStatistics?.domain?.id}
                  color={surveyStatistics?.domain?.color_hex}
                  inReportInsights={inReportInsights}
                />
              </div>
            </div>
          </td>
          <td className={`${rightPartClassName} py-1`}>
            <div>
              <BarChart
                dataSet={dataSet}
                index={surveyStatistics?.domain?.id}
                colors={
                  surveyStatistics?.domain?.color_hex
                    ? _.fill(
                        Array(dataSet.data?.length),
                        surveyStatistics?.domain?.color_hex
                      )
                    : undefined
                }
              />
            </div>
          </td>
          {showTotalRespondentsColumn && (
            <td className={`${rightPartClassName} py-1`}>
              <div className="flex justify-center text-2xl font-semibold">
                {surveyStatistics?.total_respondents}
              </div>
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default SurveyStatisticsTableRow;
