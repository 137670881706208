import { Domain, Goal, GoalActivityLog,GoalActivityLogAll,PageSizeNumber } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import { AddGoalRequest, PusherEvent, UpdateGoalRequest } from "./types";


export const getGoalByGoalId = (data:{goalId:number;planId: number;}): Promise<Goal> => {
  return Axios.get(`${API_ROOT}/plans/${data.planId}/goals/${data.goalId}/`);
};

export const getGoalsByPlan = (planId: number): Promise<Goal[]> => {
  return Axios.get(`${API_ROOT}/plans/${planId}/goals/`);
};
export const addGoal = ({
  planId,
  ...request
}: AddGoalRequest): Promise<Goal> => {
  return Axios.post(`${API_ROOT}/plans/${planId ?? request?.plan_id}/goals/`, { ...request, planId });
};

export const updateGoal = ({
  goalId,
  planId,
  ...request
}: UpdateGoalRequest): Promise<Goal> => {
  return Axios.patch(`${API_ROOT}/plans/${planId}/goals/${goalId ?? request.id}/`, request);
};

export const deleteGoal = (planId: number | undefined, goalId: number | undefined): Promise<any> => {
  return Axios.delete(`${API_ROOT}/plans/${planId}/goals/${goalId}`);
};

export const addDomainItemIndicator = (planId: number | undefined, indicator: any): Promise<Domain> => {
  return Axios.post(`${API_ROOT}/plans/${planId}/item_domain_priorities/`, indicator);
};

export const updateGoalIndicator = ({
  id,
  ...request
}: any): Promise<any> => {
  return Axios.put(`${API_ROOT}/goal-indicator-targets/${id}/`, request);
};

export const deleteGoalIndicator = ({
  id
}: any): Promise<any> => {
  return Axios.delete(`${API_ROOT}/goal-indicator-targets/${id}/`);
};

export const getGoalActivityLog = (goal: number): Promise<GoalActivityLog[]> => {
  return Axios.get(`${API_ROOT}/goal-activity-logs/${goal}`);
};


export const getGoalActivityAllLog = ({
  page_no,
  page_size,
}: PageSizeNumber): Promise<GoalActivityLogAll> => {
  return Axios.get(`${API_ROOT}/goal-activity-logs/?page_no=${page_no}&page_size=${page_size}`);
};

export const deleteGoalDomain = ({
  domain_id, goal_id, plan_id
}: { domain_id: number, goal_id: number, plan_id: number | undefined }): Promise<any> => {
  return Axios.get(`${API_ROOT}/plans/${plan_id}/goals/remove_domain?domain_id=${domain_id}&goal_id=${goal_id}`);
};

export const triggerPusherEvent = (plan_id: number | undefined = 2, eventData: PusherEvent): Promise<any> => {
  return Axios.post(`${API_ROOT}/plans/${plan_id}/goals/trigger_payload/`, eventData);
};