import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { updateTeamMember } from "../../../../store/team-members/actions";
import { StaffRole, TeamRole, UserModel, UserRole } from "../../../../types";
import SchoolRoleCell from "../../../districts/district-users/SchoolRoleCell";
import { getCurrentUserId } from "../../../../store/auth/selectors";
import { isDistrictPlan } from "../../../../store/plans/selectors";

type OwnProps = {
  user: UserModel;
  hasEditingAccess?: boolean;
};

type Props = OwnProps;

const TeamMemberSchoolRoleCell: FunctionComponent<Props> = (props) => {
  const { user, hasEditingAccess } = props;

  const currentUserId = useSelector(getCurrentUserId);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const isMe = useMemo(() => {
    return currentUserId === user.id;
  }, [user, currentUserId]);

  const dispatch = useDispatch();

  const [role, setRole] = useState<UserRole | undefined>();
  const [schoolRole, setSchoolRole] = useState<
    StaffRole | string | undefined
  >();

  useEffect(() => {
    setRole(user.profile.role);
  }, [user.profile.role]);

  useEffect(() => {
    setSchoolRole(user.profile.custom_role_name);
  }, [user.profile.custom_role_name]);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const [localLoading, setLocalLoading] = useState<boolean>(false);

  const resetLoading = () => {
    setLocalLoading(false);
  };

  const loading = useSelector((s) => s.teamMembers.loading.updateTeamMember);
  const error = useSelector((s) => s.teamMembers.errors.updateTeamMember);

  const isLoading = useMemo(() => localLoading && loading, [
    localLoading,
    loading,
  ]);

  useLoading({
    loading: isLoading,
    error,
    onError: resetLoading,
    onSuccess: resetLoading,
  });

  const handleRolesUpdate = (
    newRole: UserRole | undefined = role,
    newStaffRole: StaffRole | string | undefined
  ) => {
    setLocalLoading(true);
    dispatch(
      updateTeamMember.request({
        body: {
          planId: activePlanId!,
          user: {
            id: user.id,
            role: newRole,
            custom_role_name: newStaffRole,
            team_role: isDistrictActivePlan
              ? newRole === UserRole.Superintendent
                ? TeamRole.TeamChampion
                : undefined
              : newRole === UserRole.Principal
              ? TeamRole.TeamChampion
              : undefined,
          },
        },
        onSuccess: isMe
          ? () => {
              document.location.reload();
            }
          : undefined,
      })
    );
  };

  return (
    <SchoolRoleCell
      isLoading={isLoading}
      role={role}
      schoolRole={schoolRole}
      onRoleChange={([role, staffRole]) => {
        handleRolesUpdate(role, staffRole);
      }}
      onSchoolRoleNameChange={(value) => {
        handleRolesUpdate(role, value);
      }}
      hasEditingAccess={hasEditingAccess}
      menuPortalTargetSelector={"body"}
    />
  );
};

export default TeamMemberSchoolRoleCell;
