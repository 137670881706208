import { TeamMember, UserInvite, UserModel } from "../../types";
import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";

type ActionName =
  | "getTeamMembersByPlan"
  | "inviteTeamMembers"
  | "updateTeamMember"
  | "addTeamMembers"
  | "deleteTeamMemberFromPlan";

export type TeamMembersState = {
  teamMembers: TeamMember[];
  failedInvites: UserInvite[];
  successInvites: UserInvite[];
  dialogs: {
    understandingRolesDialog: {
      show?: boolean;
    };

    learningModuleDialog:{
      show?: boolean;
      isVideo?: boolean;
    };

    screencastTutorialDialog:{
      showtutorial?: boolean;
      isVideotutorial?: boolean;
    }

    learnAboutSccpAndDcltdDialog: {
      show?: boolean;
      isVideo?: boolean;
    };

    updateTeamMemberDialog: {
      show?: boolean;
      user?: UserModel;
      planId?: number;
    };

    manageUsersDialog: {
      show?: boolean;
    };
  };
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: TeamMembersState = {
  failedInvites: [],
  successInvites: [],
  teamMembers: [],
  dialogs: {
    learningModuleDialog:{},
    screencastTutorialDialog:{},
    understandingRolesDialog: {},
    learnAboutSccpAndDcltdDialog: {},
    updateTeamMemberDialog: {},
    manageUsersDialog: {},
  },
  loading: {},
  errors: {},
};

export const teamMembersReducer = createReducer<TeamMembersState, RootAction>(
  initialState
)
  // get team members by plan
  .handleAction(
    actions.getTeamMembersByPlan.request,
    (state, action): TeamMembersState => ({
      ...state,
      loading: {
        ...state.loading,
        getTeamMembersByPlan: true,
      },
      errors: {
        ...state.errors,
        getTeamMembersByPlan: undefined,
      },
    })
  )
  .handleAction(
    actions.getTeamMembersByPlan.success,
    (state, action): TeamMembersState => ({
      ...state,
      teamMembers: action.payload,
      loading: {
        ...state.loading,
        getTeamMembersByPlan: false,
      },
    })
  )
  .handleAction(
    actions.getTeamMembersByPlan.failure,
    (state, action): TeamMembersState => ({
      ...state,
      errors: {
        ...state.errors,
        getTeamMembersByPlan: action.payload,
      },
      loading: {
        ...state.loading,
        getTeamMembersByPlan: false,
      },
    })
  )
  // update team member
  .handleAction(
    actions.updateTeamMember.request,
    (state, action): TeamMembersState => ({
      ...state,
      loading: {
        ...state.loading,
        updateTeamMember: true,
      },
      errors: {
        ...state.errors,
        updateTeamMember: undefined,
      },
    })
  )
  .handleAction(
    actions.updateTeamMember.success,
    (state, action): TeamMembersState => ({
      ...state,
      teamMembers: state.teamMembers.map((tm) =>
        tm.user.id === action.payload.user.id ? action.payload : tm
      ),
      loading: {
        ...state.loading,
        updateTeamMember: false,
      },
    })
  )
  .handleAction(
    actions.updateTeamMember.failure,
    (state, action): TeamMembersState => ({
      ...state,
      errors: {
        ...state.errors,
        updateTeamMember: action.payload,
      },
      loading: {
        ...state.loading,
        updateTeamMember: false,
      },
    })
  )
  // invite team members
  .handleAction(
    actions.inviteTeamMembers.request,
    (state, action): TeamMembersState => ({
      ...state,
      failedInvites: [],
      successInvites: [],
      loading: {
        ...state.loading,
        inviteTeamMembers: true,
      },
      errors: {
        ...state.errors,
        inviteTeamMembers: undefined,
      },
    })
  )
  .handleAction(
    actions.inviteTeamMembers.success,
    (state, action): TeamMembersState => ({
      ...state,
      teamMembers: [...state.teamMembers, ...action.payload.created_users],
      failedInvites: action.payload.failed_invites,
      successInvites: action.payload.success_invites,
      loading: {
        ...state.loading,
        inviteTeamMembers: false,
      },
    })
  )
  .handleAction(
    actions.inviteTeamMembers.failure,
    (state, action): TeamMembersState => ({
      ...state,
      errors: {
        ...state.errors,
        inviteTeamMembers: action.payload,
      },
      loading: {
        ...state.loading,
        inviteTeamMembers: false,
      },
    })
  )
  //

  .handleAction(
    actions.showUnderstandingRolesDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        understandingRolesDialog: {
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideUnderstandingRolesDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        understandingRolesDialog: {},
      },
    })
  )

.handleAction(
    actions.showLearningModuleDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        learningModuleDialog: {
          isVideo: action.payload,
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideLearningModuleDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        learningModuleDialog: {},
      },
    })
  )
  .handleAction(
    actions.showScreencastTutorialDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        screencastTutorialDialog: {
          isVideotutorial: action.payload,
          showtutorial: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideScreencastTutorialDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        screencastTutorialDialog: {},
      },
    })
  )
  
  .handleAction(
    actions.showLearnAboutSccpAndDcltdDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        learnAboutSccpAndDcltdDialog: {
          isVideo: action.payload,
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideLearnAboutSccpAndDcltdDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        learnAboutSccpAndDcltdDialog: {},
      },
    })
  )

  // add team members
  .handleAction(
    actions.addTeamMembers.request,
    (state, action): TeamMembersState => ({
      ...state,
      loading: {
        ...state.loading,
        addTeamMembers: true,
      },
      errors: {
        ...state.errors,
        addTeamMembers: undefined,
      },
    })
  )
  .handleAction(
    actions.addTeamMembers.success,
    (state, action): TeamMembersState => ({
      ...state,
      teamMembers: [...action.payload, ...state.teamMembers],
      loading: {
        ...state.loading,
        addTeamMembers: false,
      },
    })
  )
  .handleAction(
    actions.addTeamMembers.failure,
    (state, action): TeamMembersState => ({
      ...state,
      errors: {
        ...state.errors,
        addTeamMembers: action.payload,
      },
      loading: {
        ...state.loading,
        addTeamMembers: false,
      },
    })
  )
  // delete team member from plan
  .handleAction(
    actions.deleteTeamMemberFromPlan.request,
    (state, action): TeamMembersState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteTeamMemberFromPlan: true,
      },
      errors: {
        ...state.errors,
        deleteTeamMemberFromPlan: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteTeamMemberFromPlan.success,
    (state, action): TeamMembersState => ({
      ...state,
      teamMembers: state.teamMembers.filter(
        (tm) => tm.user?.id !== action.payload
      ),
      loading: {
        ...state.loading,
        deleteTeamMemberFromPlan: false,
      },
    })
  )
  .handleAction(
    actions.deleteTeamMemberFromPlan.failure,
    (state, action): TeamMembersState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteTeamMemberFromPlan: action.payload,
      },
      loading: {
        ...state.loading,
        deleteTeamMemberFromPlan: false,
      },
    })
  )
  //show update teamMember dialog
  .handleAction(
    actions.showUpdateTeamMemberDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        updateTeamMemberDialog: {
          show: true,
          planId: action.payload.planId,
          user: action.payload.user,
        },
      },
    })
  )
  //hide update teamMember dialog
  .handleAction(
    actions.hideUpdateTeamMemberDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        updateTeamMemberDialog: {},
      },
    })
  )
  // update user field update in TeamMember
  .handleAction(
    actions.updateUserFieldInTeamMember,
    (state, action): TeamMembersState => ({
      ...state,
      teamMembers: state.teamMembers.map((tm) =>
        tm.user?.id === action.payload.id ? { ...tm, user: action.payload } : tm
      ),
    })
  )
  .handleAction(
    actions.showManageUsersDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        manageUsersDialog: {
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideManageUsersDialog,
    (state, action): TeamMembersState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        manageUsersDialog: {},
      },
    })
  );
