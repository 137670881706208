import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FunctionComponent } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Icon } from "@blueprintjs/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import PublishedNotesFilterDateRange from "./PublishedNotesFilterDateRange";
import { any } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import { isDistrictPlan } from '../../../../../../../../store/plans/selectors';
import { useIntl } from "react-intl";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import {
  SelectorOptionType,
  UserNotesType,
  userNotesTypeValuePublishedNotes,
} from "../../../../types";
// import { SelectorOptionType, userNotesTypeValues,UserNotesType, userNotesTypeValuePublishedNotes} from '../../../../../../../../types';
import "./PublishedNotesFilter.scss";

type OwnProps = {
  filterByKeyword: any;
  setFilterByKeyword: any;
  filterByTopic: any;
  setFilterByTopic: any;
  setSelectedDateRange: any;
  selectedDateRange: any;
  isFilterApplied: any;
  setIsFilterApplied: any;
};

type Props = OwnProps;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    maxHeight: 200,
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "lightgrey",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "darkgrey",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#565656",
    },
  },
  paper: {
    marginTop: 10,
    maxHeight: 200,
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "lightgrey",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "darkgrey",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#565656",
    },
  },
}));

const PublishedNotesFilter: FunctionComponent<Props> = (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isReset, setReset] = useState(false);
  const intl = useIntl();
  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const classes = useStyles();
  const { filterByKeyword, setFilterByKeyword } = props;
  const { filterByTopic, setFilterByTopic } = props;
  const { selectedDateRange, setSelectedDateRange } = props;
  const { isFilterApplied, setIsFilterApplied } = props;
  const {
    userNotesType,
    selectedNote: preselectedNote,
    selectedActivity,
    agenda,
    inviterRole,
    inviterTeamRole,
    meeting,
    checkExisting,
  } = useSelector((s) => s.notes.dialogs.notesDialog);

  const options: SelectorOptionType<UserNotesType>[] = useMemo(() => {
    return (isDistrictActivePlan
      ? userNotesTypeValuePublishedNotes.districtLevel
      : userNotesTypeValuePublishedNotes.schoolLevel
    ).map((value: any) => ({
      label: intl.formatMessage(
        {
          id: `app.notes.types.PublishedNotes`,
        },
        { noteType: value }
      ),
      value: value as UserNotesType,
    }));
  }, []);

  const handleDateChange = (selectedRange: string) => {
    setSelectedDateRange(selectedRange);
  };

  const onClearFilters = () => {
    setFilterByKeyword("");
    setFilterByTopic("");
    setSelectedDateRange(null);
    setIsFilterApplied(!isFilterApplied);
    handleDateChange("");
    setReset(true);
  };

  useEffect(() => {
    setReset(false);
  }, [isReset]);

  const onApplyFilters = () => {
    setIsFilterApplied(!isFilterApplied); // Set filter application status to true
  };

  return (
    <div>
      <div className="filterbar">
        <span className="filter-icon">
          <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.10791 0.369736L6.60393 7.87166V15.3736L10.316 17.249V7.8713L16.812 0.369385H8.46013L0.10791 0.369736Z"
              fill="#8B8B8B"
            />
          </svg>
        </span>
        <div className="filtergroup">
          <div className="filterCol">
            <TextField
              fullWidth
              label={`${intl.formatMessage({
                id: "app.titles.search-activities",
              })}`}
              id="outlined-size-small"
              size="small"
              onChange={(e) => setFilterByKeyword(e.target.value)}
              value={filterByKeyword}
            />
          </div>

          <div className="filterCol">
            <PublishedNotesFilterDateRange
              onDateChange={handleDateChange}
              isReset={isReset}
              // Provide appropriate reset state
              setIsReset={() => {}} // Provide appropriate reset function
            />
          </div>

          <div className="filterCol">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Filter by Topic
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Filter by Topic"
                className={classes.select}
                value={filterByTopic}
                onChange={(e) => setFilterByTopic(e.target.value)}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  PaperProps: {
                    className: classes.paper,
                  },
                }}
              >
                {options.map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="progressM_filterlist-listSave">
            <Tooltip title="Apply Filter" arrow>
              <span>
                <button className="filtersave" onClick={onApplyFilters}>
                  <Icon iconSize={16} icon="tick" />
                </button>
              </span>
            </Tooltip>
            <Tooltip title="Clear" arrow>
              <button className="filtercancel" onClick={onClearFilters}>
                <Icon iconSize={16} icon="cross" />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishedNotesFilter;
