/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react'
import { Icon, Tab, Tabs } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import './StrategicDetails.scss';
import { useIntl } from 'react-intl';
import { Strategy } from '../../../../types';

type OwnProps = {
    strategy: Strategy;
};

type Props = OwnProps;

const StrategicDetails: FunctionComponent<Props> = ({ strategy }) => {
    const intl = useIntl();

    const [ViewDetailsopen, ViewDetailssetOpen] = React.useState(false);

    const ViewDetailsClickOpen = () => {
        ViewDetailssetOpen(true);
    };

    const ViewDetailsClose = () => {
        ViewDetailssetOpen(false);
    };

    return (
        <>
            <a onClick={ViewDetailsClickOpen}>
                <Icon icon="eye-open" iconSize={14} /> View Details
            </a>
            <Dialog
                className="large-modal view-details-modal"
                open={ViewDetailsopen}
                onClose={ViewDetailsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal-title">
                    <h3>
                        {intl.formatMessage({
                            id: "app.strategies.view-details",
                        })}
                    </h3>
                    <button onClick={ViewDetailsClose}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>
                <div className="summary-modal-content">
                    <h4>{strategy.name}</h4>
                </div>
                <Tabs
                    animate={true}
                    key={"vertical"}
                    vertical={false}
                >
                    <Tab id="rx" title="Overview" panel={
                        <div dangerouslySetInnerHTML={{ __html: strategy.description ?? "" }} className="view-detail-cnt" />
                    } />
                    <Tab id="ng" title="Supporting Evidence/References" panel={
                        <div dangerouslySetInnerHTML={{ __html: strategy.supporting_evidence ?? "" }} className="view-detail-cnt" />
                    } />
                </Tabs>
            </Dialog>
        </>
    );
}

export default StrategicDetails
