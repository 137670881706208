import { combineEpics } from "redux-observable";
import * as auth from "./auth/epics";
import * as domains from "./domains/epics";
import * as domainAnswerSet from "./domain-answer-set/epics";
import * as districts from "./districts/epics";
import * as commonText from "./common-text/epics";
import * as schools from "./schools/epics";
import * as surveys from "./surveys/epics";
import * as profile from "./profile/epics";
import * as notifications from "./notifications/epics";
import * as surveysDeployment from "./surveys-deployment/epics";
import * as surveyReports from "./survey-reports/epics";
import * as users from "./users/epics";
import * as strategies from "./strategies/epics";
import * as resources from "./resources/epics";
import * as datasets from "./data-sets/epics";
import * as plans from "./plans/epics";
import * as teamMembers from "./team-members/epics";
import * as datasetMarks from "./dataset-marks/epics";
import * as planStrategies from "./plan-strategies/epics";
import * as needs from "./needs/epics";
import * as goals from "./goals/epics";
import * as notes from "./notes/epics";
import * as meetings from "./meetings/epics";
import * as activities from "./activities/epics";
import * as meetingTemplates from "./meeting-templates/epics";
import { catchError } from "rxjs/operators";

const rootEpic = (action$: any, store$: any, dependencies?: any) =>
  combineEpics(
    ...Object.values(auth),
    ...Object.values(districts),
    ...Object.values(commonText),
    ...Object.values(domains),
    ...Object.values(domainAnswerSet),
    ...Object.values(schools),
    ...Object.values(surveys),
    ...Object.values(profile),
    ...Object.values(notifications),
    ...Object.values(surveysDeployment),
    ...Object.values(surveyReports),
    ...Object.values(users),
    ...Object.values(strategies),
    ...Object.values(resources),
    ...Object.values(datasets),
    ...Object.values(plans),
    ...Object.values(teamMembers),
    ...Object.values(datasetMarks),
    ...Object.values(planStrategies),
    ...Object.values(needs),
    ...Object.values(goals),
    ...Object.values(notes),
    ...Object.values(meetings),
    ...Object.values(activities),
    ...Object.values(meetingTemplates)
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export default rootEpic;
