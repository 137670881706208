import React, { FunctionComponent, useState } from "react";
import { Collapse, Icon, Tag } from "@blueprintjs/core";
import { TeamRoleDefinition, TeamRoleDefinitionDict } from "../../../../types";
import { useIntl } from "react-intl";
import "./RoleCardCollapse.scss";

type OwnProps = {
  teamRoleDefinition: TeamRoleDefinition;
};

type Props = OwnProps;

const RoleCardCollapse: FunctionComponent<Props> = (props) => {
  const { teamRoleDefinition } = props;

  const intl = useIntl();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={`collapsable-card`}>
      <div
        className={isOpen ? undefined : "rounded-b"}
        onClick={() => setIsOpen((s) => !s)}
      >
        <span>
          <Tag className="mr-4" round large>
            {intl.formatMessage({
              id: `app.titles.role.${
                TeamRoleDefinitionDict[teamRoleDefinition.role]
              }`,
            })}
          </Tag>

          {teamRoleDefinition.roleTitle}
        </span>
        <Icon
          className="mr-2"
          icon={isOpen ? "chevron-down" : "chevron-right"}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <p className="my-2 font-bold">
          {intl.formatMessage({ id: "app.tile.role-responsibilities" })}
        </p>
        <ul className="list-disc pl-5">
          {teamRoleDefinition.responsibilities.map((r, index) => (
            <li key={index}>{r}</li>
          ))}
        </ul>
        <p className="my-2 font-bold">
          {intl.formatMessage({ id: "app.tile.platform-permissions" })}
        </p>
        <p>{teamRoleDefinition.permissions}</p>
      </Collapse>
    </div>
  );
};

export default RoleCardCollapse;
