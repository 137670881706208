import React, { FunctionComponent, useMemo } from "react";
import { Button, Spinner } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import moment from "moment";
import UpcomingActivityTag from "./UpcomingActivityTag";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../../App";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { UserNotesType } from "../../../../types";

type OwnProps = {
  title?: string;
};

type Props = OwnProps;

const NUMBER_OF_MONTHS = 2;
const MAX_ITEMS = 6;

const UpcomingEvents: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const history = useHistory();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const {
    title = intl.formatMessage({
      id: "app.titles.upcoming-meetings-activities",
    }),
  } = props;

  const meetings = useSelector((s) => s.meetings.meetings);
  const loading = useSelector((s) => s.meetings.loading.getMeetings);
  const error = useSelector((s) => s.meetings.errors.getMeetings);
  useLoading({ loading, error });

  const upcomingMeetings = useMemo(() => {
    return meetings
      .filter(
        (m) =>
          moment(m.date).isBetween(
            moment(),
            moment(m.date).add(NUMBER_OF_MONTHS, "months"),
            "day",
            "[]"
          ) ||
          moment(m.end_date).isBetween(
            moment(),
            moment(m.end_date).add(NUMBER_OF_MONTHS, "months"),
            "day",
            "[]"
          )
      )
      .sort((a, b) => {
        return new Date(b.date).getTime() > new Date(a.date).getTime() ? -1 : 1;
      });
  }, [meetings]);

  return (
    <div className="flex-1">
      <div className="text-xl font-bold text-blue-600 text-center mb-2">
        {title}
      </div>

      {loading ? (
        <Spinner intent="primary" className="mt-2" />
      ) : upcomingMeetings?.length ? (
        <div className="flex flex-col gap-2">
          {upcomingMeetings.slice(0, MAX_ITEMS).map((m) => (
            <UpcomingActivityTag key={m.id} meeting={m} />
          ))}
          {!!upcomingMeetings.slice(MAX_ITEMS).length && (
            <div className="flex items-center justify-center">
              <Button
                intent={"primary"}
                text={intl.formatMessage({ id: "app.titles.view-all-events" })}
                onClick={() => {
                  history.push(
                    generatePath(AuthRoutes.YourPlanTeam, {
                      workspace: "team",
                      tabId: isDistrictActivePlan
                        ? UserNotesType.DCLT_SCI_CALENDAR
                        : UserNotesType.SCLT_SCI_CALENDAR,
                    } as any)
                  );
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="mt-4">
          <p className="text-blue-600 text-center">
            {intl.formatMessage({ id: "app.dashboard.no-events" })}
          </p>
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
