import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Locale } from "../../../../store/UIState";
import { PageTextKeys, SurveyPrompt } from "../../../../types";
import { Button, ButtonGroup, Classes, Dialog, Icon } from "@blueprintjs/core";
import SurveyPromptComponent from "../SurveyPromptComponent";
import TranslationSelector from "../../../common/selectors/TranslationSelector";

type OwnProps = {
  show?: boolean;
  onClose?: Function;
  demographic: string;
  availableLanguages: Locale[];
  demographicPrompt?: SurveyPrompt;
  onTranslationUpdate: (prompt: SurveyPrompt) => void;
};

type Props = OwnProps;

const SurveyContentTranslationsDialog: React.FC<Props> = (props: Props) => {
  const {
    demographic,
    availableLanguages,
    show,
    onClose,
    demographicPrompt,
    onTranslationUpdate,
  } = props;

  const intl = useIntl();

  const [selectedPage, setSelectedPage] = useState<PageTextKeys>("intro");

  const [translatedPrompt, setTranslatedPrompt] = useState<
    SurveyPrompt | undefined
  >(demographicPrompt);

  const handleUpdateTranslatedPrompt = (objectKey: string, value: string) => {
    if (translatedPrompt !== undefined) {
      setTranslatedPrompt({
        ...translatedPrompt,
        [selectedPage]: {
          ...translatedPrompt?.[selectedPage],
          [selectedLanguage]: {
            ...translatedPrompt?.[selectedPage]?.[selectedLanguage],
            [objectKey]: value,
          },
        },
      });
    } else {
      setTranslatedPrompt({
        [selectedPage]: {
          [selectedLanguage]: {
            [objectKey]: value,
          },
        },
      } as SurveyPrompt);
    }
  };

  const handleTextAreaUpdate = (
    e: React.FocusEvent<HTMLTextAreaElement>,
    objectKey: string
  ) => {
    const value = e.target?.value;
    handleUpdateTranslatedPrompt(objectKey, value);
  };

  const [selectedLanguage, setSelectedLanguage] = useState<Locale>(
    Locale.English
  );

  const handleClose = () => {
    onClose && onClose();
  };

  const pages = useMemo(() => {
    return Object.keys(demographicPrompt ?? {}) as PageTextKeys[];
  }, [demographicPrompt]);

  const dialogTitle = useMemo(() => {
    return (
      <div className="flex justify-between pr-2">
        <p>
          {intl.formatMessage({
            id: "app.translations.manage-translations",
          })}
          :&nbsp;
          {intl.formatMessage({
            id: `app.surveys.survey-demographic.${demographic}`,
          })}
        </p>
        <TranslationSelector
          items={availableLanguages}
          onLanguageChange={(updatedLocale) => {
            setSelectedLanguage(updatedLocale);
          }}
          selectedLocale={selectedLanguage}
        />
      </div>
    );
  }, [selectedLanguage, setSelectedLanguage]);

  const handleOpen = () => {
    setSelectedLanguage(Locale.English);
    setSelectedPage("intro");
    setTranslatedPrompt(demographicPrompt);
  };

  const handleTranslationSubmit = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (!!translatedPrompt) {
      onTranslationUpdate(translatedPrompt);
      handleClose();
    }
  };

  return (
    <Dialog
      isOpen={show}
      onOpening={handleOpen}
      onClose={handleClose}
      icon={<Icon icon="clipboard" iconSize={24} />}
      title={dialogTitle}
      style={{ minWidth: "800px" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="flex pb-4">
          <p className="font-bold text-xl mr-4">
            {intl.formatMessage({
              id: "app.surveys.survey-questions.page",
            })}
          </p>

          <ButtonGroup className="flex flex-wrap">
            {pages.map((item, index) => (
              <Button
                icon={selectedPage === item ? "tick" : "blank"}
                key={index}
                onClick={() => setSelectedPage(item)}
                intent={selectedPage === item ? "primary" : undefined}
              >
                {!isNaN(Number(item)) ? +item + 1 : item}
              </Button>
            ))}
          </ButtonGroup>
        </div>

        <div className="flex">
          <div className="flex-1 mx-1">
            <SurveyPromptComponent
              selectedPage={selectedPage}
              selectedLanguage={Locale.English}
              prompt={translatedPrompt}
              readOnly
            />
          </div>
          {selectedLanguage !== Locale.English && (
            <div className="flex-1 mx-1">
              <SurveyPromptComponent
                selectedPage={selectedPage}
                selectedLanguage={selectedLanguage}
                onUpdatePrompt={handleUpdateTranslatedPrompt}
                onTextAreaUpdate={handleTextAreaUpdate}
                prompt={translatedPrompt}
              />
            </div>
          )}
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={handleClose}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            className="w-1/4"
            intent="primary"
            onClick={handleTranslationSubmit}
          >
            {intl.formatMessage({
              id: "app.titles.save",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SurveyContentTranslationsDialog;
