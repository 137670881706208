import React, { FunctionComponent, useEffect, useRef } from "react";
import TableSearchPanel from "./TableSearchPanel";
import GenericTable from "./GenericTable";
import TablePagination from "./TablePagination";
import {
  usePagination,
  useRowSelect,
  UseRowSelectInstanceProps,
  useSortBy,
  useTable,
} from "react-table";
import TableCheckboxCell from "./TableCheckboxCell";
import { useSticky } from "react-table-sticky";

type DisabledState = {
  checked?: boolean;
  disabled?: boolean;
};

type OwnProps = {
  data: any[];
  columns: any[];
  initialState?: any;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  loading?: boolean;
  noDataComp?: React.ReactNode;
  checkboxSelection?: boolean;
  onSelectedChange?: (any: []) => void;
  onRowClick?: (entity: any) => any;
  darkHeader?: boolean;
  showTopContainer?: boolean;
  searchPlaceholder?: string;
  maxHeight?: string;
  isCheckBoxDisable?: (entity: any) => DisabledState | undefined;
  getTooltipMessage?: (entity: any) => string | undefined;
  fixed?: boolean;
  customPagination?: boolean;
  customPageCount?: number;
  customPageIndex?: number;
  customPageSize?: number;
  pageInd?: any;
  customGoToPage?: (page: number) => void;
  customSetPageSize?: (page: number) => void;
  id?:string;
  disableSort?:boolean;
};

type Props = OwnProps;

const EntityTable: FunctionComponent<Props> = (props) => {
  const CUSTOMPAGECOUNT = 1;
  const CUSTOMPAGESIZE = 10;
  const COSTOMPAGEINDEX = 0;

  const {
    data,
    pageInd,
    columns,
    initialState,
    searchString,
    setSearchString,
    loading,
    noDataComp,
    children,
    checkboxSelection,
    onSelectedChange,
    onRowClick,
    darkHeader,
    showTopContainer = true,
    searchPlaceholder,
    maxHeight,
    getTooltipMessage,
    isCheckBoxDisable,
    fixed = false,
    customGoToPage,
    customSetPageSize,
    customPageCount = CUSTOMPAGECOUNT,
    customPageIndex = COSTOMPAGEINDEX,
    customPageSize = CUSTOMPAGESIZE,
    customPagination,
    id,
    disableSort=false
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    pageCount,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
    (hooks) => {
      if (checkboxSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            width: "0%",
            Header: ({
              getToggleAllRowsSelectedProps,
            }: UseRowSelectInstanceProps<any>) => (
              <TableCheckboxCell
                {...getToggleAllRowsSelectedProps()}
                // disabled={readOnly}
              />
            ),
            Cell: ({ row }: any) => (
              <TableCheckboxCell
                key={row.original.id}
                {...row.getToggleRowSelectedProps()}
                {...(isCheckBoxDisable &&
                  isCheckBoxDisable(row.original?.state))}
              />
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    if (onSelectedChange) {
      onSelectedChange(selectedFlatRows.map((r) => r.original) as any);
    }
  }, [selectedFlatRows.length]);

  useEffect(() => {
    if (customPagination && customSetPageSize) {
      setPageSize(customPageSize);
    }
  }, [customPageSize]);

  useEffect(() => {
    if(pageInd)
    pageInd.current = pageIndex
  }, [pageIndex]);

  return (
    <>
      {showTopContainer && (
        <div className="flex flex-wrap w-full border-gray-400 border addUserBtn">
          {children}
          {setSearchString && (
            <div className="flex flex-grow justify-end">
              <TableSearchPanel
                searchString={searchString || ""}
                setSearchString={setSearchString}
                placeholder={searchPlaceholder}
                setPageSize={setPageSize}
                {...(customPagination
                  ? { pageSize: customPageSize }
                  : { pageSize: pageSize })}
                customPagination={customPagination}
                customSetPageSize={customSetPageSize}
              />
            </div>
          )}
        </div>
      )}
      {/* {!showTopContainer && setSearchString && (
        // <div className="memberTbl_head">
        //  <label>Uploaded Materials (20)</label>
        //  <div className="memberTbl_button">
        //  <button>upload files</button>
        // <button><Icon iconSize={16} icon="plus"/> Resource URL</button>
        //  </div>
    
          {/* {children} */}

          {/* <TableSearchPanel
            searchString={searchString || ""}
            setSearchString={setSearchString}
            pageSize={pageSize}
            setPageSize={setPageSize}
            showPageSizeSelector={false}
            placeholder={searchPlaceholder}
          /> */}
        {/* </div> */}
      {/* )} */}
       {/* } */}
      <div
        className={`generic-table bg-white border adduserTbl mt-5${
          showTopContainer ? "" : " border-top"
        }${darkHeader ? " " : ""}`}
        style={maxHeight ? { maxHeight: maxHeight } : undefined}
      >
        <GenericTable
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          onClearSearchString={() => setSearchString && setSearchString("")}
          prepareRow={prepareRow}
          headerGroups={headerGroups}
          page={page}
          interactive
          bordered
          striped
          fixed={fixed}
          loading={loading}
          filtered={!!searchString?.length}
          noDataComp={noDataComp}
          onRowClick={onRowClick}
          getTooltipMessage={getTooltipMessage}
          id={id}
          disableSort={disableSort}
        />
      </div>
      <TablePagination
        gotoPage={gotoPage}
        {...(customPagination
          ? { pageCount: customPageCount, pageIndex: customPageIndex }
          : { pageCount: pageCount, pageIndex: pageIndex })}
        customPagination={customPagination}
        customGoToPage={customGoToPage}
      />
    </>
  );
};

export default EntityTable;