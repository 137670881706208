import { Classes } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const WelcomeStepOne: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  return (
    <div className={"flex flex-col gap-4 items-center"}>
      <iframe
        id="kaltura_player"
        src="https://cdnapisec.kaltura.com/html5/html5lib/v2.98/mwEmbedFrame.php/p/208082/uiconf_id/12441821/entry_id/1_r4yvcsy8?wid=_208082&iframeembed=true&playerId=kaltura_player&entry_id=1_s0sp6l1o&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_6bj0w3pc"
        width="640"
        height="480"
        allowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        frameBorder="0"
        title="Overview of The School Climate Change Process"
        data-tour={"familiarize-with-sccp"}
      />
      <div className="flex justify-center">
        <p className="modal-sponsorship-statement">
          <i>{intl.formatMessage(
            {
              id: "app.video-modal.sponsorship-statement",
            },
            {
              b: (chunks) => <b>{chunks}</b>,
            }
          )}
          </i>
        </p>
      </div>
      {props.children}
    </div>
  );
};

export default WelcomeStepOne;
