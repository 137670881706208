import {
  AddDomainRequest,
  GetDomainsAndItemsByIdsRequest,
  GetDomainsAndItemsByIdsResponse,
  UpdateDomainRequest,
} from "./types";
import { Domain } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";

export const getDomains = (): Promise<Domain[]> => {
  return Axios.get(`${API_ROOT}/domains/`);
};

export const getDomainsById = (data:{domainsIds:number[],deployment_id:number,planId:number|undefined}): Promise<Domain[]> => {
  return Axios.get(`${API_ROOT}/domain/respondent/check/?domain_ids=${data.domainsIds}&deployment_id=${data.deployment_id}&plan_id=${data.planId}`);
};

export const getExistDomainItems = (data:{deployment_id:number}): Promise<Domain[]> => {
  return Axios.get(`${API_ROOT}/domain_item/respondent/check/?deployment_id=${data.deployment_id}`);
};


export const addDomain = (request: AddDomainRequest): Promise<Domain> => {
  return Axios.post(`${API_ROOT}/domains/`, request);
};
export const updateDomain = ({
  id: domainId,
  ...request
}: UpdateDomainRequest): Promise<Domain> => {
  return Axios.put(`${API_ROOT}/domains/${domainId}/`, request);
};
export const deleteDomain = (domainId: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/domains/${domainId}`);
};
export const getDomainById = (domainId: number): Promise<Domain> => {
  return Axios.get(`${API_ROOT}/domain/${domainId}`);
};

export const getDomainsAndItemsByIds = (
  req: GetDomainsAndItemsByIdsRequest
): Promise<GetDomainsAndItemsByIdsResponse> => {
  return Axios.post(`${API_ROOT}/insights/domains_questions`, req);
};
