import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { ActivityScopeEnum, MeetingTemplate } from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getMeetingTemplates } from "../../../../store/meeting-templates/actions";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { Button, Classes, NonIdealState, Spinner } from "@blueprintjs/core";
import MeetingTemplatesTable from "./MeetingTemplatesTable";

type OwnProps = {
  setSelectedTemplate?: React.Dispatch<
    React.SetStateAction<MeetingTemplate | undefined>
  >;
  setSkipSelectingTemplates: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = OwnProps;

const MeetingTemplatesList: React.FC<Props> = (props) => {
  const { setSelectedTemplate, setSkipSelectingTemplates } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const meetingTemplates = useSelector(
    (s) => s.meetingTemplates.meetingTemplates
  );
  useEffect(() => {
    dispatch(
      getMeetingTemplates.request({
        type: isDistrictActivePlan
          ? ActivityScopeEnum.DISTRICT
          : ActivityScopeEnum.SCHOOL,
      })
    );
  }, [isDistrictActivePlan]);

  const loading = useSelector(
    (s) => s.meetingTemplates.loading.getMeetingTemplates
  );
  const error = useSelector(
    (s) => s.meetingTemplates.errors.getMeetingTemplates
  );
  useLoading({ loading, error });

  const skipButton = useMemo(
    () => (
      <Button
        data-cy="skip-selecting-meeting-templates-button"
        intent={"primary"}
        text={intl.formatMessage({
          id: "app.non-ideal-state.meeting-templates.skip-selection",
        })}
        title={intl.formatMessage({
          id: "app.non-ideal-state.meeting-templates.skip-selection",
        })}
        onClick={() => setSkipSelectingTemplates(true)}
      />
    ),
    [setSkipSelectingTemplates]
  );

  const handleMeetingTemplateClick = (meetingTemplate: MeetingTemplate) => {
    setSelectedTemplate && setSelectedTemplate(meetingTemplate);
  };

  return (
    <div className={Classes.DIALOG_BODY}>
      <div className={"font-bold mb-2"}>
        {intl.formatMessage(
          {
            id: "app.calendar.select-meeting-template",
          },
          {
            i: (a) => <i>{a}</i>,
          }
        )}
      </div>

      {loading ? (
        <Spinner intent="primary" className="my-2" />
      ) : meetingTemplates?.length ? (
        <MeetingTemplatesTable
          data={meetingTemplates}
          onRowClick={handleMeetingTemplateClick}
        />
      ) : (
        <NonIdealState
          icon="zoom-out"
          title={intl.formatMessage({
            id: "app.non-ideal-state.meeting-templates.title",
          })}
          description={intl.formatMessage(
            { id: "app.non-ideal-state.meeting-templates.description" },
            {
              buttonName: intl.formatMessage({
                id: "app.non-ideal-state.meeting-templates.skip-selection",
              }),
            }
          )}
          action={skipButton}
        />
      )}
    </div>
  );
};

export default MeetingTemplatesList;
