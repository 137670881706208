import { createReducer, RootAction } from "typesafe-actions";
import { LocalStorageKeys } from "../../constants";
import {
  DomainComparison,
  DomainQuestionsComparison,
  DomainResponses,
  DomainStatistics,
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  Insight,
  InsightSubgroupInfoDialogType,
  ResponseRates,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
  SurveyReportTaskIdName,
  SurveyStatistics,
} from "../../types";
import actions from "../actions";

type ActionName =
  | "getSurveyReport"
  | "fillBreadcrumb"
  | "getSurveyComparison"
  | "getRespondentGraphDomainData"
  | "getRespondentGraphItemData"
  | "getDistrictComparison"
  | "getSurveyInsights"
  | "downloadStaticReport"
  | "downloadCSVReport"
  | "getSurveyReportResponseRates"
  | "shareFeedback"
  | "getShareFeedbackOption"
  | "getDomainsAndItemsByIds"
  | "actionAuditselectSurveyDeployment"
  | "getComparisonDomainItemsData";

export type SurveyReportsState = {
  surveyReportTaskId?: string | undefined | null;
  surveyReportName?: string | undefined | null;
  showBookmarkDialog?: boolean;
  getFeedbackOptions?: any;
  bookmarkDialogDetails?: any;
  domainComparisonDialog: {
    show?: boolean;
    responses?: DomainResponses;
    demographics?: SurveyDemographic[];
  };

  selectedSurveyDeployment?: SurveyDeployment<number, Survey<number>>;

  responseRates?: ResponseRates;

  surveyReport:
  | SurveyStatistics[]
  | GroupedSurveyStatistics[]
  | DomainStatistics
  | DomainStatistics<GroupedAnswerStatistics[]>;

  surveyComparison: DomainQuestionsComparison | DomainComparison[];

  surveyComparisons: any[];

  ComparisonDomainItemsData:any[];

  surveyRespondentDomainsData: any[];

  surveyRespondentItemsData: any[];

  districtComparison: (DomainQuestionsComparison | DomainComparison[])[];

  insights: Insight[];

  isRedactedDataCollapsed: boolean;

  inReportInsights?: boolean;

  hideSensitiveData?: boolean;

  searchBarString?: string;

  dialogs: {
    insightSubgroupInfoDialog: InsightSubgroupInfoDialogType;
  };

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: SurveyReportsState = {
  surveyReportName: null,
  
  surveyReportTaskId: null,

  surveyReport: [],
  getFeedbackOptions: [],

  domainComparisonDialog: {},

  surveyComparison: [],
  
  surveyComparisons: [],

  surveyRespondentDomainsData:[],

  surveyRespondentItemsData:[],

  ComparisonDomainItemsData:[],

  districtComparison: [],

  insights: [],

  inReportInsights: true,

  isRedactedDataCollapsed: false,

  hideSensitiveData: false,

  dialogs: {
    insightSubgroupInfoDialog: {},
  },
  loading: {},
  errors: {},
};

export const surveyReportsReducer = createReducer<
  SurveyReportsState,
  RootAction
>(initialState)
  .handleAction(actions.showDomainComparisonDialog, (state, action) => ({
    ...state,
    domainComparisonDialog: {
      show: true,
      responses: action.payload.responses,
      demographics: action.payload.demographics,
    },
  }))
  .handleAction(actions.hideDomainComparisonDialog, (state, action) => ({
    ...state,
    domainComparisonDialog: {},
  }))
  .handleAction(actions.showBookmarkDialog, (state, action) => ({
    ...state,
    showBookmarkDialog: true,
    bookmarkDialogDetails: action.payload.bookmarkDetail,
  }))
  .handleAction(actions.hideBookmarkDialog, (state, action) => ({
    ...state,
    showBookmarkDialog: false,
    selectedDomainForBookmark: undefined,
    bookmarkDialogDetails: undefined,
  }))
  .handleAction(actions.toggleSensitiveData, (state, action) => {
    return {
    ...state,
    hideSensitiveData: action.payload.sensitiveDataToggle
  };
  })
  //get survey report
  .handleAction(
    actions.getSurveyReport.request,
    (state, action): SurveyReportsState => ({
      // ...state,
      ...state,
      loading: {
        ...state.loading,

        getSurveyReport: true,
      },
      errors: {
        ...state.errors,
        getSurveyReport: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyReport.success,
    (state, action): SurveyReportsState => ({
      ...state,
      surveyReport: action.payload,
      loading: {
        ...state.loading,
        getSurveyReport: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyReport.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyReport: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyReport: false,
      },
    })
  )
  //fill breadcrumb
  .handleAction(
    actions.fillBreadcrumb.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        fillBreadcrumb: true,
      },
      errors: {
        ...state.errors,
        fillBreadcrumb: undefined,
      },
    })
  )
  .handleAction(
    actions.fillBreadcrumb.success,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        fillBreadcrumb: false,
      },
    })
  )
  .handleAction(
    actions.fillBreadcrumb.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        fillBreadcrumb: action.payload,
      },
      loading: {
        ...state.loading,
        fillBreadcrumb: false,
      },
    })
  )
  //get survey comparison
  .handleAction(
    actions.getSurveyComparison.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyComparison: true,
      },
      errors: {
        ...state.errors,
        getSurveyComparison: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyComparison.success,
    (state, action): SurveyReportsState => ({
      ...state,
      surveyComparison: action.payload,
      surveyComparisons:[...state.surveyComparisons, action.payload],
      loading: {
        ...state.loading,
        getSurveyComparison: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyComparison.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyComparison: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyComparison: false,
      },
    })
  )

// get Survey Respondent Graph domains Data
.handleAction(
  actions.getRespondentGraphDomainData.request,
  (state, action): SurveyReportsState => {
    return{
    ...state,
    loading: {
      ...state.loading,
      getRespondentGraphDomainData: true,
    },
    errors: {
      ...state.errors,
      getRespondentGraphDomainData: undefined,
    },
  }
}
)
.handleAction(
  actions.getRespondentGraphDomainData.success,
  (state, action): SurveyReportsState => {
    return{ 
    ...state,
    // surveyComparison: action.payload,
    surveyRespondentDomainsData:[...state.surveyRespondentDomainsData, ...action.payload],
    loading: {
      ...state.loading,
      getRespondentGraphDomainData: false,
    },
  }}
)
.handleAction(
  actions.getRespondentGraphDomainData.failure,
  (state, action): SurveyReportsState => {
    return{
      ...state,
    errors: {
      ...state.errors,
      getRespondentGraphDomainData: action.payload,
    },
    loading: {
      ...state.loading,
      getRespondentGraphDomainData: false,
    },
  }
}
)

// get Survey Respondent Graph Items Data
.handleAction(
  actions.getRespondentGraphItemData.request,
  (state, action): SurveyReportsState => {
    return{
    ...state,
    loading: {
      ...state.loading,
      getRespondentGraphItemData: true,
    },
    errors: {
      ...state.errors,
      getRespondentGraphItemData: undefined,
    },
  }
}
)
.handleAction(
  actions.getRespondentGraphItemData.success,
  (state, action): SurveyReportsState => {
    return{ 
    ...state,
    surveyRespondentDomainsData:[...state.surveyRespondentDomainsData],
    surveyRespondentItemsData:[...state.surveyRespondentItemsData, action.payload],
    loading: {
      ...state.loading,
      getRespondentGraphItemData: false,
    },
  }}
)
.handleAction(
  actions.getRespondentGraphItemData.failure,
  (state, action): SurveyReportsState => {
    return{
      ...state,
    errors: {
      ...state.errors,
      getRespondentGraphItemData: action.payload,
    },
    loading: {
      ...state.loading,
      getRespondentGraphItemData: false,
    },
  }
}
)

//clear Respondent Graph Domains and Items
.handleAction(
  actions.clearRespondentGraphDomainsItemsArray,
  (state, action): SurveyReportsState => {
    
    return{ 
    ...state,
    surveyRespondentDomainsData:[],
    surveyRespondentItemsData:[],
 
  }}
)

  //get survey district comparison
  .handleAction(
    actions.getDistrictSurveyComparison.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDistrictComparison: true,
      },
      errors: {
        ...state.errors,
        getDistrictComparison: undefined,
      },
    })
  )
  .handleAction(
    actions.getDistrictSurveyComparison.success,
    (state, action): SurveyReportsState => ({
      ...state,
      districtComparison: action.payload,
      loading: {
        ...state.loading,
        getDistrictComparison: false,
      },
    })
  )
  .handleAction(
    actions.getDistrictSurveyComparison.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDistrictComparison: action.payload,
      },
      loading: {
        ...state.loading,
        getDistrictComparison: false,
      },
    })
  )
  //get insights
  .handleAction(
    actions.getSurveyInsights.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyInsights: true,
      },
      errors: {
        ...state.errors,
        getSurveyInsights: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyInsights.success,
    (state, action): SurveyReportsState => ({
      ...state,
      insights: action.payload,
      loading: {
        ...state.loading,
        getSurveyInsights: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyInsights.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyInsights: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyInsights: false,
      },
    })
  )
  .handleAction(
    actions.resetState,
    (state, action): SurveyReportsState => {
      return {
        ...initialState,
        isRedactedDataCollapsed: state.isRedactedDataCollapsed,
      };
    }
  )
  //download static report
  .handleAction(
    actions.downloadStaticReport.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadStaticReport: true,
      },
      errors: {
        ...state.errors,
        downloadStaticReport: undefined,
      },
    })
  )
  .handleAction(
    actions.downloadStaticReport.success,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadStaticReport: false,
      },
    })
  )
  .handleAction(
    actions.downloadStaticReport.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        downloadStaticReport: action.payload,
      },
      loading: {
        ...state.loading,
        downloadStaticReport: false,
      },
    })
  )
  //download static report
  .handleAction(
    actions.downloadCSVReport.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadCSVReport: true,
      },
      errors: {
        ...state.errors,
        downloadCSVReport: undefined,
      },
    })
  )
  .handleAction(
    actions.downloadCSVReport.success,
    (state, action): SurveyReportsState => {
      return {
        ...state,
        surveyReportName:(action?.payload as SurveyReportTaskIdName)?.name ? (action.payload as SurveyReportTaskIdName).name : null,
        surveyReportTaskId: (action?.payload as SurveyReportTaskIdName)?.task_id ? (action.payload as SurveyReportTaskIdName).task_id : null,
        loading: {
          ...state.loading,
          downloadCSVReport: (action?.payload as SurveyReportTaskIdName)?.task_id ? true : false,
        },
      }
    }
  )
  .handleAction(
    actions.downloadCSVReport.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        downloadCSVReport: action.payload,
      },
      loading: {
        ...state.loading,
        downloadCSVReport: false,
      },
    })
  )
  // download static report
  .handleAction(
    actions.downloadCSVReportByTaskId.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadCSVReport: true,
      },
      errors: {
        ...state.errors,
        downloadCSVReport: undefined,
      },
    })
  )
  .handleAction(
    actions.downloadCSVReportByTaskId.success,
    (state, action): SurveyReportsState => {
      return {
        ...state,
        surveyReportTaskId: null,
        surveyReportName: null,
        loading: {
          ...state.loading,
          downloadCSVReport: false,
        },
      }
    }
  )
  .handleAction(
    actions.downloadCSVReportByTaskId.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        downloadCSVReport: action.payload,
      },
      loading: {
        ...state.loading,
        downloadCSVReport: false,
      },
    })
  )
  //get response rates
  .handleAction(
    actions.getSurveyReportResponseRates.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        getSurveyReportResponseRates: true,
      },
      errors: {
        ...state.errors,
        getSurveyReportResponseRates: undefined,
      },
    })
  )
  .handleAction(
    actions.getSurveyReportResponseRates.success,
    (state, action): SurveyReportsState => ({
      ...state,
      responseRates: action.payload,
      loading: {
        ...state.loading,
        getSurveyReportResponseRates: false,
      },
    })
  )
  .handleAction(
    actions.getSurveyReportResponseRates.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        getSurveyReportResponseRates: action.payload,
      },
      loading: {
        ...state.loading,
        getSurveyReportResponseRates: false,
      },
    })
  )
  .handleAction(actions.changeRedactedDataCollapsed, (state, action) => {
    localStorage.setItem(
      LocalStorageKeys.RedactedDataCollapsed,
      JSON.stringify(action.payload)
    );
    return {
      ...state,
      isRedactedDataCollapsed: action.payload,
    };
  })
  // in report insights
  .handleAction(actions.changeInReportInsights, (state, action) => {
    localStorage.setItem(
      LocalStorageKeys.InReportInsights,
      JSON.stringify(action.payload)
    );
    return {
      ...state,
      inReportInsights: action.payload,
    };
  })
  //share feedback
  .handleAction(
    actions.shareFeedback.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        shareFeedback: true,
      },
      errors: {
        ...state.errors,
        shareFeedback: undefined,
      },
    })
  )
  .handleAction(
    actions.shareFeedback.success,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        shareFeedback: false,
      },
    })
  )
  .handleAction(
    actions.shareFeedback.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        shareFeedback: action.payload,
      },
      loading: {
        ...state.loading,
        shareFeedback: false,
      },
    })
  )
  // share feedback options

  .handleAction(
    actions.getShareFeedbackOption.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        getShareFeedbackOption: true,
      },
      errors: {
        ...state.errors,
        getShareFeedbackOption: undefined,
      },
    })
  )
  .handleAction(
    actions.getShareFeedbackOption.success,
    (state, action): SurveyReportsState => ({
      ...state,
      getFeedbackOptions: action.payload,
      loading: {
        ...state.loading,
        getShareFeedbackOption: false,
      },
    })
  )
  .handleAction(
    actions.getShareFeedbackOption.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        getShareFeedbackOption: undefined,
      },
      loading: {
        ...state.loading,
        getShareFeedbackOption: false,
      }
    })
  )

  // get domains and items by ids
  .handleAction(
    actions.getDomainsAndItemsByIds.request,
    (state, action): SurveyReportsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDomainsAndItemsByIds: true,
      },
      errors: {
        ...state.errors,
        getDomainsAndItemsByIds: undefined,
      },
    })
  )
  .handleAction(
    actions.getDomainsAndItemsByIds.success,
    (state, action): SurveyReportsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        insightSubgroupInfoDialog: {
          ...state.dialogs.insightSubgroupInfoDialog,
          ...action.payload,
        },
      },
      loading: {
        ...state.loading,
        getDomainsAndItemsByIds: false,
      },
    })
  )
  .handleAction(
    actions.getDomainsAndItemsByIds.failure,
    (state, action): SurveyReportsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDomainsAndItemsByIds: action.payload,
      },
      loading: {
        ...state.loading,
        getDomainsAndItemsByIds: false,
      },
    })
  )
  //
  .handleAction(
    actions.changeReportsSearchBarString,
    (state, action): SurveyReportsState => ({
      ...state,
      searchBarString: action.payload,
    })
  )
  //
  .handleAction(
    actions.changeSelectedSurveyDeployment,
    (state, action): SurveyReportsState => ({
      ...state,
      selectedSurveyDeployment: action.payload,
    })
  )
  //
  .handleAction(
    actions.showInsightSubgroupInfoDialog,
    (state, action): SurveyReportsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        insightSubgroupInfoDialog: {
          ...action.payload,
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideInsightSubgroupInfoDialog,
    (state, action): SurveyReportsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        insightSubgroupInfoDialog: {},
      },
    })
  )
  //Action Auditselect SurveyDeployment
  .handleAction(
    actions.actionAuditselectSurveyDeployment.request,
    (state, action): SurveyReportsState => ({
      ...state,
    })
  )
  .handleAction(
    actions.actionAuditselectSurveyDeployment.success,
    (state, action): SurveyReportsState => ({
      ...state,
    })
  )
  .handleAction(
    actions.actionAuditselectSurveyDeployment.failure,
    (state, action): SurveyReportsState => ({
      ...state,
    })
  )
  // get Survey domains items Data
.handleAction(
  actions.getComparisonDomainItemsData.request,
  (state, action): SurveyReportsState => {
    return{
    ...state,
    loading: {
      ...state.loading,
      getComparisonDomainItemsData: true,
    },
    errors: {
      ...state.errors,
      getComparisonDomainItemsData: undefined,
    },
  }
}
)
.handleAction(
  actions.getComparisonDomainItemsData.success,
  (state, action): SurveyReportsState => {
    return{ 
    ...state,
    // surveyComparison: action.payload,
    ComparisonDomainItemsData:action.payload,
    loading: {
      ...state.loading,
      getComparisonDomainItemsData: false,
    },
  }}
)
.handleAction(
  actions.getComparisonDomainItemsData.failure,
  (state, action): SurveyReportsState => {
    return{
      ...state,
    errors: {
      ...state.errors,
      getComparisonDomainItemsData: action.payload,
    },
    loading: {
      ...state.loading,
      getComparisonDomainItemsData: false,
    },
  }
}
);
