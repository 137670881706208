import React from "react";
import { DomainComparison, SurveyDemographic } from "../../../../types";
import ReportTableDomainItem from "../../tab/domains/ReportTableDomainItem";
import DomainComparisonTableRowDemographic from "./DomainComparisonTableRowDemographic";

type OwnProps = {
  index: number;
  item: DomainComparison;
  demographics: SurveyDemographic[];
  color?: string;
};

type Props = OwnProps;

const DomainComparisonTableRow: React.FC<Props> = (props: Props) => {
  const { item, index, demographics, color } = props;

  return (
    <tr key={index}>
      <td className={"bg-gray-200"}>
        <div className="flex justify-center px-2">
          <ReportTableDomainItem domain={item.domain} />
        </div>
      </td>

      {demographics?.map((demographic, index) => (
        <DomainComparisonTableRowDemographic
          key={index}
          item={item}
          demographic={demographic}
          color={color}
        />
      ))}
    </tr>
  );
};

export default DomainComparisonTableRow;
