import React, { FunctionComponent, useEffect } from "react";
import DistrictUsersTable from "./DistrictUsersTable";
import { getDistricts } from "../../../store/districts/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getUsers } from "../../../store/users/actions";
import UserEditingDialog from "../dialogs/UserEditingDialog";
import InviteUsersDialogContainer from "../dialogs/invite-users-dialog/InviteUsersDialogContainer";
import { getActivePlanDistrictId } from "../../../store/plans/selectors";
import { useIntl } from "react-intl";
type OwnProps = {
  forAllSystem?: boolean;
  isDistrictSelectorAvailable?: boolean;
  isTeamPlanPage?: boolean;
};

type Props = OwnProps;

const DistrictUsers: FunctionComponent<Props> = ({
  isTeamPlanPage,
  forAllSystem,
  isDistrictSelectorAvailable,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  
  const { districtId } = useParams<{ districtId: string }>();

  const activePlanDistrictId = useSelector(getActivePlanDistrictId);

  useEffect(() => {
    if (isTeamPlanPage) {
      if (activePlanDistrictId) {
        dispatch(getUsers.request(activePlanDistrictId));
      }
    } else {
      dispatch(getUsers.request(districtId ? +districtId : undefined));
    }
  }, [activePlanDistrictId, districtId, isTeamPlanPage]);

  useEffect(() => {
    if (!!districtId && isDistrictSelectorAvailable) {
      dispatch(getDistricts.request());
    }
  }, [isDistrictSelectorAvailable]);

  return (
    <>
     <div className="add-instruction">
       {intl.formatMessage({ id: "app.schools-users-management.instructions" },
        {
          br: <br />
        }
       )}
      </div>
      <br/>
      <DistrictUsersTable
        isTeamPlanPage={isTeamPlanPage}
        forAllSystem={forAllSystem}
      />
      <InviteUsersDialogContainer forAllSystem={forAllSystem} />
      <UserEditingDialog
        isDistrictSelectorAvailable={isDistrictSelectorAvailable}
      />
    </>
  );
};

export default DistrictUsers;
