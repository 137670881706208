import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { Goal, GoalActivityLog, GoalActivityLogAll, NewGoalState, NewGoalTabs, Priority } from "../../types";
import _ from "lodash";
import State from "pusher-js/types/src/core/http/state";
import { EDIT_GOAL_MODE, INDICATOR_DOMAIN_LEVEL, INDICATOR_ITEM_LEVEL, NEW_GOAL_ADDED } from "../../constants/constants";
import { useIntl } from "react-intl";
import { AppToaster } from "../../helpers/toaster";
import { GOAL_INDICATOR_UPDATED_SUCCESSFULLY, GOAL_UPDATED_SUCCESSFULLY, GOAL_CREATED_SUCCESSFULLY } from "../../constants/constants";
import { triggerPusherEvent } from "../../api/goals";
import events from "../../components/pages/strategic-plan/events";
import { plansReducer } from "../plans";
import applicationStore from "../index";
import * as pako from 'pako';

type ActionName = "getGoals" | "createGoal" | "updateGoal" | "deleteGoal" | "addIndicator" | "getGoalActivities" | "goalSwitch";

export type GoalsState = {
  newGoalState: NewGoalState;
  selectedPriority: Priority[];
  goalActivityData?: GoalActivityLog[];
  goalActivityDataAll?: GoalActivityLogAll;
  dialogs: {
    goalTemplateLibraryDialog: {
      show?: boolean;
      goalStatement: string;
      desiredOutcome: string;
      onConfirm?: Function;
    };
  };
  finalIndicators?: any[];
  goals: Goal[];
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
  confirmedIndicatorStatus: any[];
};

const defaultNewGoalState: NewGoalState = {
  goal: {},
  show: false,
  isEditMode: false,
  selectedTabId: NewGoalTabs.ChooseNeeds,
  targets: {},
  selectedNeeds: [],
  indicatorGroups: [],
  goalStatement: "",
  desiredOutcome: "",
  strategies: [],
  errors: [],
  canNavigateToStep2: false,
  canNavigateToStep3: false,
  selectedGoal: {},
  latestAddedByPusher : false,
};

const initialState: GoalsState = {
  newGoalState: defaultNewGoalState,
  dialogs: {
    goalTemplateLibraryDialog: {
      goalStatement: "",
      desiredOutcome: "",
    },
  },
  selectedPriority: [],
  goalActivityData: [],
  goalActivityDataAll: {},
  goals: [],
  loading: {},
  errors: {},
  confirmedIndicatorStatus: [],
};

export const goalsReducer = createReducer<GoalsState, RootAction>(initialState)
  // get goals
  .handleAction(
    actions.getGoalsByPlan.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        getGoals: true,
      },
      errors: {
        ...state.errors,
        getGoals: undefined,
      },
    })
  )
  .handleAction(
    actions.getGoalsByPlan.success,
    (state, action): GoalsState => ({
      ...state,
      goals: action.payload,
      loading: {
        ...state.loading,
        getGoals: false,
      },
    })
  )
  .handleAction(
    actions.getGoalsByPlan.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        getGoals: action.payload,
      },
      loading: {
        ...state.loading,
        getGoals: false,
      },
    })
  )
  // get goal by goal id
  .handleAction(
    actions.getGoalByGoalId.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        getGoals: true,
        goalSwitch: true,
      },
      errors: {
        ...state.errors,
        getGoals: undefined,
      },
    })
  )
  .handleAction(
    actions.getGoalByGoalId.success,
    (state, action): GoalsState => ({
      ...state,
      // goals: action.payload,
      loading: {
        ...state.loading,
        getGoals: false,
        goalSwitch: false,

      },
    })
  )
  .handleAction(
    actions.getGoalByGoalId.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        getGoals: action.payload,
        goalSwitch: action.payload
      },
      loading: {
        ...state.loading,
        getGoals: false,
        goalSwitch: false
      },
    })
  )

  // create goal
  .handleAction(
    actions.createGoal.request,
    (state, action): GoalsState => ({
      ...state,


      loading: {
        ...state.loading,
        createGoal: true,
      },
      errors: {
        ...state.errors,
        createGoal: undefined,
      },
    })
  )
  .handleAction(
    actions.createGoal.success,
    (state, action): GoalsState => {
      // const intl = useIntl();

      AppToaster.clear();
      AppToaster.show({
        message: GOAL_CREATED_SUCCESSFULLY,
        // intl.formatMessage({
        //   id: "app.goals.success.messages.goal-update", //"app.goals.messages.write-goal-statement",
        // }),
        icon: "tick-circle",
        intent: "success",
      });


      // trigger goal creation pusher here
      try {
      
        const s = action.payload?.survey_deployment?.survey ?? {};
        triggerPusherEvent(action.payload.plan_id, {
          event: events.goal_create,
          channel_name: "presence-sp-" + action.payload.plan_id,
          data: {
            ...action.payload,
            survey_deployment: {
              ...action.payload.survey_deployment,
              survey: { ...s, demographic_data: [], page_text: {} },
            }
          }
        });
      }
      catch (err) {
        console.log(err, 'ERROR 1');
      }




      const exists = state.goals?.find(et => et.id === action.payload.id);
      if (exists)
        return { ...state };
      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          latestAddedByPusher:false,
          // selectedGoal: action.payload,
          goal: { ...state.newGoalState.goal, ...action.payload, mode: EDIT_GOAL_MODE },
        },
        goals: [action.payload, ...state.goals],
        loading: {
          ...state.loading,
          createGoal: false,
        },
      };
    }
  )
  .handleAction(
    actions.createGoal.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        createGoal: action.payload,
      },
      loading: {
        ...state.loading,
        createGoal: false,
      },
    })
  )
  .handleAction(
    actions.pusherNewGoalEvent,
    (state, action): GoalsState => {
      AppToaster.clear();
      AppToaster.show({
        message: NEW_GOAL_ADDED,
        icon: "tick-circle",
        intent: "success",
      });

      const exists = state.goals?.find(et => et.id === action.payload.id);
      if (exists)
        return { ...state };
      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          latestAddedByPusher : true
          // selectedGoal: !state.goals.length ? action.payload : state.newGoalState.selectedGoal,
          // goal:  !state.goals.length ?  {...action.payload,mode: EDIT_GOAL_MODE}: {...state.newGoalState.goal  }
        },
        goals: !exists ? [action.payload, ...state.goals] : [...state.goals],
        loading: {
          ...state.loading,
          createGoal: false,
        },
      };
    }
  )
  .handleAction(actions.addDomainItemIndicator.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        addIndicator: true,
      },
      errors: {
        ...state.errors,
        addIndicator: undefined,
      },
    }))
  .handleAction(actions.addDomainItemIndicator.success,
    (state, action): GoalsState => {
     
      try {
        const new_targets = action.payload["targets"].filter((tg: any) => {
          return state.newGoalState.goal.targets.find((et: any) => et.id === tg.id) ? false : true
        })
        // state.newGoalState.goal
        if (action.payload?.plan_id && action.payload.id)
          triggerPusherEvent(action.payload.plan_id, {
            event: events.goal_target_create,
            channel_name: "presence-sp-" + action.payload.plan_id,
            data: {
              goal: {
                "id": action.payload["id"],
                "updated_at": action.payload["updated_at"],
                "selected_domains": action.payload["selected_domains"],
                "targets": new_targets
              },
              user: action.payload.user
            }
          });
      } catch (err) {
      }

      return {
        ...state,
        goals: state.goals.map((goal) => {
          return goal.id === action.payload.id ? { ...goal, ...action.payload } : goal
        }
        ),
        newGoalState: {
          ...state.newGoalState,
          goal: { ...state.newGoalState.goal, ...action.payload, activeTab: state.newGoalState.goal.activeTab === NewGoalTabs.ChooseNeeds ? NewGoalTabs.GoalStatement : state.newGoalState.goal.activeTab }
        },
        loading: {
          ...state.loading,
          addIndicator: false,
        },
        errors: {
          ...state.errors,
          addIndicator: undefined,
        },
      }
    })
  .handleAction(actions.addDomainItemIndicator.failure,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        addIndicator: false,
      },
      errors: {
        ...state.errors,
        addIndicator: action.payload,
      },
    }))
  // update Goal
  .handleAction(
    actions.updateGoal.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateGoal: true,
      },
      errors: {
        ...state.errors,
        updateGoal: undefined,
      },
    })
  )
  .handleAction(
    actions.updateGoal.success,
    (state, action): GoalsState => {
      AppToaster.clear();
      AppToaster.show({
        message: GOAL_UPDATED_SUCCESSFULLY,
        // intl.formatMessage({
        //   id: "app.goals.success.messages.goal-update", //"app.goals.messages.write-goal-statement",
        // }),
        icon: "tick-circle",
        intent: "success",
      });

      try {
        const diff = events.object_diff(state.newGoalState.goal, action.payload);
        // goal_target_create
        // 1. If difference contains statement trigger update with statement 
        if (diff.includes('statement')) {
          if (action.payload?.plan_id && action.payload.id)
            triggerPusherEvent(action.payload.plan_id, {
              event: events.goal_update_statement,
              channel_name: "presence-sp-" + action.payload.plan_id,
              data: {
                goal: {
                  "id": action.payload['id'],
                  "updated_at": action.payload["updated_at"],
                  "statement": action.payload["statement"]
                },
                user: action.payload.user
              },
            });
        }
        // 2. If difference contains descriprion trigger update with desc
        if (diff.includes('desired_outcome')) {
          if (action.payload?.plan_id && action.payload.id)
          triggerPusherEvent(action.payload.plan_id, {
            event: events.goal_update_desired_outcome,
            channel_name: "presence-sp-" + action.payload.plan_id,
            data: {
              goal: {
                "id": action.payload['id'],
                "updated_at": action.payload["updated_at"],
                "desired_outcome": action.payload["desired_outcome"]
              },
              user: action.payload.user
            },
          });
        }
        // 3. If difference contains targets / selected_domains then check and trigger update to that only
        if (diff.includes('targets')) {
          // find which are the new targets  
          const new_targets = action.payload["targets"].filter((tg: any) => {
            return state.newGoalState.goal.targets.find((et: any) => et.id === tg.id) ? false : true
          })
          // state.newGoalState.goal
          if (action.payload?.plan_id && action.payload.id)
            triggerPusherEvent(action.payload.plan_id, {
              event: events.goal_target_create,
              channel_name: "presence-sp-" + action.payload.plan_id,
              data: {
                goal: {
                  "id": action.payload['id'],
                  "updated_at": action.payload["updated_at"],
                  "selected_domains": action.payload["selected_domains"],
                  "targets": new_targets
                },
                user: action.payload.user
              }
            });
        }
      }
      catch (err) {
      }

      return {
        ...state,
        goals: state.goals.map((goal) =>
          goal.id === action.payload.id ? { ...goal, ...action.payload } : goal
        ),
        newGoalState: {
          ...state.newGoalState,
          goal: { ...state.newGoalState.goal, ...action.payload, activeTab: state.newGoalState.goal.activeTab === NewGoalTabs.ChooseNeeds ? NewGoalTabs.GoalStatement : state.newGoalState.goal.activeTab }
        },
        loading: {
          ...state.loading,
          updateGoal: false,
        },
      };
    }
  )
  .handleAction(
    actions.updateGoal.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateGoal: action.payload,
      },
      loading: {
        ...state.loading,
        updateGoal: false,
      },
    })
  )
  .handleAction(
    actions.pusherUpdateGoalEvent,
    (state, action): GoalsState => {
      return {
        ...state,
        goals: state.goals.map((goal) =>
          goal.id == action.payload.id ? { ...goal, ...action.payload } : goal
        ),
        newGoalState: {
          ...state.newGoalState,
          goal: action.payload?.id == state.newGoalState?.goal?.id ? { ...state.newGoalState.goal, ...action.payload } : { ...state.newGoalState.goal }
        },
        loading: {
          ...state.loading,
          updateGoal: false,
        },
      };
    }
  )
  // update Goal Indicator
  .handleAction(
    actions.updateGoalIndicator.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateGoal: true,
      },
      errors: {
        ...state.errors,
        updateGoal: undefined,
      },
    })
  )
  .handleAction(
    actions.updateGoalIndicator.success,
    (state, action): GoalsState => {
      AppToaster.clear();
      AppToaster.show({
        message: GOAL_INDICATOR_UPDATED_SUCCESSFULLY,
        // intl.formatMessage({
        //   id: "app.goals.success.messages.goal-update", //"app.goals.messages.write-goal-statement",
        // }),
        icon: "tick-circle",
        intent: "success",
      });
      const updatedGoalIndicator = {
        ...state.newGoalState.goal,
        targets: [...state.newGoalState.goal.targets.map((trgt: any) => {
          if (trgt.id === action.payload.id) {
            return { ...trgt, ...action.payload }
          }
          return trgt;
        })]
      }
  
      try {
        const jsonData={
          goal: {
            "id": updatedGoalIndicator['id'],
            // "updated_at": action.payload["updated_at"],
            // "selected_domains": action.payload["selected_domains"],
            "updated_target": action.payload
          },
          user: state.newGoalState.goal.user
        }
          // Convert JSON object to string
          const jsonString = JSON.stringify(jsonData);

          // Convert string to Uint8Array
          const dataToCompress = new TextEncoder().encode(jsonString);

          // Compress the data using pako
          const compressedData = pako.gzip(dataToCompress);

          // Convert compressed data to Base64 (for easier storage/transmission)
          const compressedDataBase64 = btoa(String.fromCharCode.apply(null, Array.from(compressedData)));

  
        

        if (updatedGoalIndicator.plan_id)
          triggerPusherEvent(updatedGoalIndicator.plan_id, {
            event: events.goal_target_update,
            channel_name: "presence-sp-" + updatedGoalIndicator.plan_id,
            data: compressedDataBase64
          });
      } catch (err) {

      }

      // if (action.payload?.plan_id && action.payload.id)
      //   triggerPusherEvent(action.payload.plan_id, {
      //     event: events.goal_target_update,
      //     channel_name: "presence-goal_setting_" + action.payload.id,
      //     data: { goal_id: action.payload.id }
      //   });




      return {
        ...state,
        goals: state.goals.map((goal) =>
          goal.id === updatedGoalIndicator.id ? { ...goal, ...updatedGoalIndicator } : goal
        ),
        newGoalState: {
          ...state.newGoalState,
          goal: { ...updatedGoalIndicator }
        },
        loading: {
          ...state.loading,
          updateGoal: false,
        },
        errors: {
          ...state.errors,
          updateGoal: undefined,
        },
      }
    }
  )
  .handleAction(
    actions.updateGoalIndicator.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateGoal: action.payload,
      },
      loading: {
        ...state.loading,
        updateGoal: false,
      },
    })
  )

  // delete Goal Domain
  .handleAction(
    actions.deleteGoalDomain.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateGoal: true,
      },
      errors: {
        ...state.errors,
        updateGoal: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteGoalDomain.success,
    (state, action): GoalsState => {
      const domainId = action.payload.domain_id;
      let latestTargets: any[] = [...state.newGoalState?.goal?.targets?.filter((et: any) => et?.domain_priority?.domain?.id != domainId)];


      // trigger pusher when the domain is deleted 

      try {
       
        if (action.payload?.plan_id)
          triggerPusherEvent(action.payload.plan_id, {
            event: events.goal_update_domain_remove,
            channel_name: "presence-sp-" + action.payload.plan_id,
            data: {
              ...action.payload,
              remove_type: 'domain_remove'
            }
          });
      } catch (err) {
      }


      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          goal: {
            ...state.newGoalState.goal,
            targets: latestTargets,
            selected_domains: [...latestTargets.filter((trgt: any) => trgt.id !== action.payload.id)]
              .filter((trgt: any) => (trgt?.domain_priority?.domain?.id === domainId || trgt?.item_priority?.domain?.id === domainId)).length ?
              [...state.newGoalState.goal.selected_domains]
              :
              [...state.newGoalState.goal.selected_domains
                .filter((sd: any) => {
                  return domainId !== sd.id
                })]
          }
        },
        loading: {
          ...state.loading,
          updateGoal: false,
        },
        errors: {
          ...state.errors,
          updateGoal: undefined,
        },
      }
    }
  )
  .handleAction(
    actions.deleteGoalDomain.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateGoal: action.payload,
      },
      loading: {
        ...state.loading,
        updateGoal: false,
      },
    })
  )

  // delete Goal Indicator
  .handleAction(
    actions.deleteGoalIndicator.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateGoal: true,
      },
      errors: {
        ...state.errors,
        updateGoal: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteGoalIndicator.success,
    (state, action): GoalsState => {
      const deletedIndicator = state.newGoalState.goal.targets.filter((trgt: any) => trgt.id === action.payload.id);
      const domainId = deletedIndicator[0].type === INDICATOR_ITEM_LEVEL ? deletedIndicator[0].item_priority.domain.id : deletedIndicator[0].domain_priority.domain.id;
      // check if the indicator is domain level and then delete all the item level indicators 
      let latestTargets = [...state.newGoalState.goal.targets.filter((trgt: any) => trgt.id !== action.payload.id)];
      if (deletedIndicator[0].type === INDICATOR_DOMAIN_LEVEL) {
        latestTargets = [...latestTargets.filter((trgt: any) => trgt.type === INDICATOR_ITEM_LEVEL ? !(trgt?.item_priority?.domain?.id === deletedIndicator[0]?.domain_priority?.domain?.id && trgt?.item_priority?.demographic === deletedIndicator[0]?.domain_priority?.demographic) : true)]
      }
      AppToaster.clear();
      AppToaster.show({
        message: GOAL_UPDATED_SUCCESSFULLY,
        icon: "tick-circle",
        intent: "success",
      });

     

      try {
        if (state.newGoalState.goal?.plan_id)
          triggerPusherEvent(state.newGoalState.goal.plan_id, {
            event: events.goal_update_domain_remove,
            channel_name: "presence-sp-" + state.newGoalState.goal.plan_id,
            data: {
              ...action.payload,
              goalId: state.newGoalState.goal.id,
              targetId: action.payload.id,
              remove_type: 'item_remove'
            }
          });
      } catch (err) {
      }

      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          goal: {
            ...state.newGoalState.goal,
            targets: latestTargets,
            selected_domains: [...latestTargets.filter((trgt: any) => trgt.id !== action.payload.id)]
              .filter((trgt: any) => (trgt?.domain_priority?.domain?.id === domainId || trgt?.item_priority?.domain?.id === domainId)).length ?
              [...state.newGoalState.goal.selected_domains]
              :
              [...state.newGoalState.goal.selected_domains
                .filter((sd: any) => {
                  return domainId !== sd.id
                })]
          }
        },
        loading: {
          ...state.loading,
          updateGoal: false,
        },
        errors: {
          ...state.errors,
          updateGoal: undefined,
        },
      }
    }
  )
  .handleAction(
    actions.deleteGoalIndicator.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateGoal: action.payload,
      },
      loading: {
        ...state.loading,
        updateGoal: false,
      },
    })
  )

  // update new goal state
  .handleAction(
    actions.updateNewGoalState,
    (state, action): GoalsState => {
      let newGoalState = {
        ...state.newGoalState,
        ...action.payload,
      };
      if (action.payload.selectedNeeds) {
        newGoalState = {
          ...newGoalState,
          targets: _.chain(newGoalState.targets)
            .toPairs()
            .filter(([key, value]) =>
              newGoalState.selectedNeeds.some((sn) => sn.id === +key)
            )
            .fromPairs()
            .value(),
        };
      }
      return {
        ...state,
        newGoalState: newGoalState,
      };
    }
  )
  // update statements in new goal state
  .handleAction(
    actions.updateNewGoalStatementsState,
    (state, action): GoalsState => {
      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          goal: {
            ...state.newGoalState.goal,
            ...action.payload.goal
          }
        },
      };
    }
  )
  .handleAction(
    actions.updateIndicatorStatus,
    (state, action): GoalsState => {
      return {
        ...state,
        confirmedIndicatorStatus: [...action.payload],
      };
    }
  )

  // update new goal state errors
  .handleAction(
    actions.updateNewGoalErrorsState,
    (state, action): GoalsState => {
      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          errors: { ...state.newGoalState.errors, ...action.payload },
        },
      };
    }
  )
  //updated selected priority
  .handleAction(
    actions.updateSelectedPriorityState,
    (state, action): GoalsState => {
      let selectedPriority = {
        ...state.selectedPriority,
        ...action.payload,
      };
      return {
        ...state,
        selectedPriority: selectedPriority,
      };
    }
  )
  // reset new goal state
  .handleAction(
    actions.resetNewGoalState,
    (state, action): GoalsState => ({
      ...state,
      newGoalState: defaultNewGoalState,
    })
  )
  .handleAction(
    actions.showGoalTemplateLibraryDialog,
    (state, action): GoalsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        goalTemplateLibraryDialog: {
          show: true,
          goalStatement: action.payload.goalStatement,
          desiredOutcome: action.payload.desiredOutcome,
          onConfirm: action.payload.onConfirm,
        },
      },
    })
  )
  .handleAction(
    actions.hideGoalTemplateLibraryDialog,
    (state, action): GoalsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        goalTemplateLibraryDialog: {
          show: false,
          goalStatement: "",
          desiredOutcome: "",
        },
      },
    })
  )
  .handleAction(
    actions.selectedPriorityAreas,
    (state, action): GoalsState => ({
      ...state,
      selectedPriority: action.payload,
    })
  )
  // add the action to display warnings here
  .handleAction(
    actions.loadIndicators,
    (state, action): GoalsState => {
      return {
        ...state,
        newGoalState: { ...state.newGoalState, indicatorGroups: action.payload },
      }
    }
  )
  .handleAction(
    actions.loadFinalIndicators,
    (state, action): GoalsState => {
      return {
        ...state,
        finalIndicators: action.payload,
      };
    }
  )
  // delete goal request 
  .handleAction(
    actions.deleteGoal.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteGoal: true,
      },
      errors: {
        ...state.errors,
        deleteGoal: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteGoal.success,
    (state, action): GoalsState => {
      // events.goal_delete

  


      try {
        const goal = state.goals.find((n: Goal) => n.id == action.payload)
        if (goal)
          triggerPusherEvent(goal.plan_id, {
            event: events.goal_delete,
            channel_name: "presence-sp-" + goal.plan_id,
            data: action.payload
          });
      } catch (error) {

      }


      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          latestAddedByPusher:false,
          // selectedGoal: action.payload,
        },
        goals: state.goals.filter((n: Goal) => n.id !== action.payload),
        errors: {
          ...state.errors,
          deleteGoal: undefined,
        },
        loading: {
          ...state.loading,
          deleteGoal: undefined,
        },
      }
    }
  )
  .handleAction(
    actions.deleteGoal.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteGoal: action.payload,
      },
      loading: {
        ...state.loading,
        deleteGoal: false,
      },
    })
  )
  // pusher delete goal
  .handleAction(
    actions.pusherDeleteGoalEvent,
    (state, action): GoalsState => {
      return {
        ...state,
        newGoalState: {
          ...state.newGoalState,
          latestAddedByPusher:false
        },
        goals: state.goals.filter((n: Goal) => n.id !== action.payload),
        errors: {
          ...state.errors,
          deleteGoal: undefined,
        },
        loading: {
          ...state.loading,
          deleteGoal: undefined,
        },
      };
    }
  )
  .handleAction(
    actions.addDomainToGoal,
    (state, action): GoalsState => ({
      ...state,
      newGoalState: {
        ...state.newGoalState,
        goal: {
          ...state.newGoalState.goal,
          selected_domains: [...state.newGoalState.goal.selected_domains, { ...action.payload }]
        },
      }
    })
  )
  .handleAction(
    actions.removeDomainFromGoal,
    (state, action): GoalsState => ({
      ...state,
      newGoalState: {
        ...state.newGoalState,
        goal: {
          ...state.newGoalState.goal,
          selected_domains: [...state.newGoalState.goal.selected_domains.filter((dm: any) => (dm.id !== action.payload.id))]
        },
      }
    })
  )
  .handleAction(
    actions.switchActiveGoalTab,
    (state, action): GoalsState => ({
      ...state,
      newGoalState: {
        ...state.newGoalState,
        goal: {
          ...state.newGoalState.goal,
          activeTab: action.payload
        },
      }
    })
  )

  .handleAction(
    actions.getGoalActivityLog.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        getGoalActivities: true,
      },
      errors: {
        ...state.errors,
        getGoalActivities: undefined,
      },
    })
  )
  .handleAction(
    actions.getGoalActivityLog.success,
    (state, action): GoalsState => ({
      ...state,
      goalActivityData: action.payload,
      loading: {
        ...state.loading,
        getGoalActivities: false,
      },
    })
  )
  .handleAction(
    actions.getGoalActivityLog.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        getGoalActivities: action.payload,
      },
      loading: {
        ...state.loading,
        getGoalActivities: false,
      },
    })
  )

  .handleAction(
    actions.getGoalActivityAllLog.request,
    (state, action): GoalsState => ({
      ...state,
      loading: {
        ...state.loading,
        getGoals: true,
      },
      errors: {
        ...state.errors,
        getGoals: undefined,
      },
    })
  )
  .handleAction(
    actions.getGoalActivityAllLog.success,
    (state, action): GoalsState => ({
      ...state,
      goalActivityDataAll: action.payload,
      loading: {
        ...state.loading,
        getGoals: false,
      },
    })
  )
  .handleAction(
    actions.getGoalActivityAllLog.failure,
    (state, action): GoalsState => ({
      ...state,
      errors: {
        ...state.errors,
        getGoals: action.payload,
      },
      loading: {
        ...state.loading,
        getGoals: false,
      },
    })
  )