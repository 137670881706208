import { Button, Collapse, Icon } from "@blueprintjs/core";
import { Button as MUIButton } from "@mui/material";

import React, { FunctionComponent, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getNotes, showNotesDialog } from "../../../../../store/notes/actions";
import {
  Activity,
  ActivityTagEnum,
  ActivityTypeEnum,
  Meeting,
  SciWritingFilterEnum,
  SelectorOptionType,
  UserNotes,
  UserNotesType,
} from "../../../../../types";
import { showActivityVideoDialog } from "../../../../../store/activities/actions";
import ActivityTypeTag from "./ActivityTypeTag";
import DownloadButton from "../../../../common/buttons/DownloadButton";
import {
  getActivePlanId,
  isDistrictPlan,
} from "../../../../../store/plans/selectors";

import _ from "lodash";
import { getUserNotesTypeBySciWritingFilter } from "../../../notes/notes-dialog/utils";

type OwnProps = {
  activity: Activity;
  agenda?: string;
  meeting?: Meeting<string, false>;
  showSciWriting?: boolean;
  newDesign?: boolean;
};

type Props = OwnProps;

const CollapsableActivity: FunctionComponent<Props> = (props) => {
  const {
    activity,
    agenda,
    meeting,
    showSciWriting = true,
    newDesign = false,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const activePlanId = useSelector(getActivePlanId);

  const [leftFilterType, setLeftFilterType] = useState<
    SelectorOptionType<SciWritingFilterEnum | number> | undefined | null
  >(
    activity
      ? {
          label: activity.name,
          value: activity.id,
        }
      : undefined
  );

  const noteTypeByActivity = useMemo(() => {
    switch (activity.tag) {
      case ActivityTagEnum.TEAM:
        return isDistrictActivePlan
          ? UserNotesType.DCLT_RESOURCES
          : UserNotesType.SCLT_RESOURCES;

      case ActivityTagEnum.DATA:
        return isDistrictActivePlan
          ? UserNotesType.DC_REPORTS_RESOURCES
          : UserNotesType.DC_REPORTING_RESOURCES;

      case ActivityTagEnum.GOALS:
        return UserNotesType.SCHOOL_RESOURCES_WORKSPACE;

      case ActivityTagEnum.NEEDS:
        return UserNotesType.SNA_RESOURCES;

      case ActivityTagEnum.LEARNING_NETWORK_EVENTS:
        return isDistrictActivePlan
          ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
          : UserNotesType.SCHOOL_RESOURCES_WORKSPACE;
      case ActivityTagEnum.RESOURCES:
        return isDistrictActivePlan
          ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
          : UserNotesType.SCHOOL_RESOURCES_WORKSPACE;
    }
  }, [activity, isDistrictActivePlan]);

  const FetchUserNotes = () => {
    if (activePlanId && leftFilterType?.value) {
      const isActivity = activity && _.isNumber(leftFilterType?.value);

      const activityId = isActivity
        ? (leftFilterType!.value as number)
        : undefined;
      const notesType = isActivity
        ? isDistrictActivePlan
          ? UserNotesType.DCLT_SCI_CALENDAR
          : UserNotesType.SCLT_SCI_CALENDAR
        : typeof leftFilterType?.value === "string"
        ? getUserNotesTypeBySciWritingFilter(
            leftFilterType?.value,
            isDistrictActivePlan
          )
        : (String(leftFilterType?.value) as UserNotesType | undefined);
      dispatch(
        getNotes.request({
          planId: activePlanId,
          meeting_id: meeting?.id,
          activity_id: activityId,
          note_type: notesType,
        })
      );
    }
  };

  const handleNotesDialogOpen = (checkExisting: Boolean = true) => {
    const existingActivityNotes = meeting?.user_notes.find(
      (uNote: UserNotes) => uNote.activity?.id === activity.id
    );
    FetchUserNotes();
    dispatch(
      showNotesDialog({
        agenda: agenda,
        meeting: meeting,
        checkExisting: checkExisting,
        selectedNote: existingActivityNotes,
        selectedActivity: activity,
        type: noteTypeByActivity,
      })
    );
  };

  const handleDownloadClick = (url: string, extension?: string) => {
    const link = document.createElement("a");
    let name = activity.name;
    if (extension) {
      name += `.${extension}`;
    }

    link.setAttribute("download", name);
    link.setAttribute("target", "_blank");
    link.href = url;
    document.body.append(link);
    link.click();
    link.remove();
  };

  const buttonContent = useMemo(() => {
    switch (activity.activity_type) {
      case ActivityTypeEnum.SCI_WRITING: {
        return (
          <>
            <DownloadButton
              newDesign={newDesign}
              attachmentUrl={activity.docx_resource?.attachment_url}
              extension={".docx"}
            />
<span className="seprator"> | </span>
            <DownloadButton
              newDesign={newDesign}
              attachmentUrl={activity.pdf_resource?.attachment_url}
              extension={".pdf"}
            />
{activity.ppt_resource && <><span className="seprator"> | </span><DownloadButton
              newDesign={newDesign}
              attachmentUrl={activity.ppt_resource?.attachment_url}
              extension={".ppt"} /></>
}

{/* <span className="seprator"> | </span> 
             {showSciWriting &&
              (newDesign ? (
                <MUIButton
                  variant="outlined"
                  onClick={() => handleNotesDialogOpen(true)}
                >
                  {intl.formatMessage({
                    id: "app.notes.add-notes-in-platform",
                  })}
                </MUIButton>
              ) : (
                <Button
                  intent="primary"
                  text={intl.formatMessage({
                    id: "app.notes.add-notes-in-platform",
                  })}
                  title={intl.formatMessage({
                    id: "app.notes.add-notes-in-platform",
                  })}
                  onClick={() => handleNotesDialogOpen(true)}
                />
              ))} */}


          </>
        );
      }
      case ActivityTypeEnum.EXTERNAL_RESOURCE: {
        return (
          <>
          {activity.pdf_resource &&
          <DownloadButton
            newDesign={newDesign}
            attachmentUrl={activity.pdf_resource?.attachment_url}
            externalUrl={activity.external_url}
            extension={".pdf"} />
          } 
          {activity.ppt_resource &&
          <DownloadButton
              newDesign={newDesign}
              attachmentUrl={activity.ppt_resource?.attachment_url}
              externalUrl={activity.external_url}
              extension={".ppt"} />
          }
          </>
        );
      }
      case ActivityTypeEnum.KALTURA_VIDEO: {
        return newDesign ? (
          <MUIButton
            variant="outlined"
            onClick={() => {
              dispatch(
                showActivityVideoDialog({
                  activity: activity,
                  viewable_field: "embed_code",
                })
              );
            }}
          >
            {"Watch Video"}
          </MUIButton>
        ) : (
          <Button
            // newDesign={true}
            intent="primary"
            text="Watch Video"
            onClick={() => {
              dispatch(
                showActivityVideoDialog({
                  activity: activity,
                  viewable_field: "embed_code",
                })
              );
            }}
          />
        );
      }
      default:
        return null;
    }
  }, [showSciWriting, activity?.id]);

  return (
    <div className={`collapsable-card resourses-card`}>
      <div
        className={
          `flex items-center gap-2 ` + (newDesign ? "new-resource-bar" : "")
        }
        onClick={
          activity.activity_type === ActivityTypeEnum.SCI_WRITING
            ? () => setIsOpen((s) => !s)
            : undefined
        }
      >
        {!newDesign && (
          <ActivityTypeTag activityType={activity.activity_type} />
        )}
        {activity.activity_type === ActivityTypeEnum.SCI_WRITING && (
          <Icon
            icon={isOpen ? "chevron-down" : "chevron-right"}
            className=""
            iconSize={18}
          />
        )}
        
        <div className="activity-type-custom">
        <span >{activity.name}</span>
        &nbsp; &nbsp;
        <span className="activity-name-custom">
        {intl.formatMessage(
                  { id: "app.titles.activity-type" },
                  { type: activity.activity_type}
                )}
        </span>
       
                  
                </div>
              
        
      </div>
      <Collapse isOpen={isOpen}>
        <div className="activityHTMLContainer">
          <div className="my-4">
            <div
              dangerouslySetInnerHTML={{
                __html: activity.html_content ?? "",
              }}
            />
          </div>
        </div>
      </Collapse>
      <div className="flex flex-wrap gap-2 p-2 bg-white">{buttonContent}</div>
    </div>
  );
};

export default CollapsableActivity;
