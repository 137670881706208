import React, { FunctionComponent, useEffect } from "react";
import { Spinner } from "@blueprintjs/core";
import DistrictsTable from "../districts/districts-table/DistrictsTable";
import NoDistricts from "../districts/districts-table/NoDistricts";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../helpers/hooks/useLoading";
import { getDistricts } from "../../store/districts/actions";

type OwnProps = {};

type Props = OwnProps;

const DistrictsList: FunctionComponent<Props> = (props) => {
  const loading = useSelector((s) => s.districts.loading.getDistricts);
  const error = useSelector((s) => s.districts.errors.getDistricts);
  useLoading({ loading, error });

  const districts = useSelector((s) => s.districts.districts);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDistricts.request());
  }, []);

  return (
    <>
      {loading ? (
        <Spinner intent="primary" />
      ) : districts.length ? (
        <>
          <DistrictsTable districts={districts} />
        </>
      ) : (
        <NoDistricts />
      )}
    </>
  );
};

export default DistrictsList;
