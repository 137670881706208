import {
  Button,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { downloadSampleLetters } from "../../../store/surveys-deployment/actions";
import { getTeamMembersByPlan } from "../../../store/team-members/actions";
import { QuestionResponses, DemographicOnly } from "../../../types";
import _ from "lodash";

type OwnProps = {
  common_text: string | undefined;
  survyObj: (QuestionResponses | DemographicOnly)[] | undefined;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = OwnProps;

const BriefDescriptionDialog: React.FC<Props> = (props: Props) => {
  const { isOpen, setOpen, survyObj, common_text } = props;
  const uniqQuestions = _.chain(survyObj)
    .uniqBy((q) => (q as QuestionResponses)?.question_id)
    .value();
  const intl = useIntl();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      className="w-1/3"
      style={{ minWidth: "500px" }}
      isOpen={isOpen}
      onClose={handleClose}
      title={intl.formatMessage(
        {
          id: "app.district-comparison-table.brief-description.dialog.title",
        },
        {
          commontext: common_text,
        }
      )}
    >
      <div className={Classes.DIALOG_BODY}>
        <p className="text-lg">
          See below for exact item wording for all relevant respondent groups.
        </p>
        <br />
        <br />
        {uniqQuestions &&
          uniqQuestions?.map(
            (q, i: number) =>
              q &&
              (q as QuestionResponses)?.question_text && (
                <p key={i}>
                  <b>
                    {intl.formatMessage({
                      id: "app.surveys.survey-demographic." + q.demographic,
                    })}
                    {": "}
                  </b>
                  "{(q as QuestionResponses)?.question_text}"
                  <br />
                </p>
              )
          )}
      </div>
    </Dialog>
  );
};

export default BriefDescriptionDialog;
