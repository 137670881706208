import { findAll } from "highlight-words-core";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useHighlightedText = ({ text = "" }: { text?: string }) => {
  const searchWords = useSelector((s) => s.surveyReports.searchBarString);

  const chunks = useMemo(() => {
    if (searchWords?.length) {
      return findAll({
        searchWords: [searchWords],
        textToHighlight: text,
      });
    }
    return [];
  }, [searchWords, text]);

  const highlightedText = useMemo(() => {
    return chunks.length
      ? chunks
          .map((chunk) => {
            const { end, highlight, start } = chunk;
            const part = text.substr(start, end - start);
            if (highlight) {
              return `<mark>${part}</mark>`;
            } else {
              return part;
            }
          })
          .join("")
      : text;
  }, [chunks, text]);

  return {
    highlightedText,
  };
};
export default useHighlightedText;
