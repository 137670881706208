import React, { ChangeEvent, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Callout,
  Checkbox,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import Select, { ActionMeta } from "react-select";
import { Locale, LocaleDisplayedValues } from "../../../../store/UIState";
import {
  SelectorOptionType,
  Survey,
  SurveyCategoryType,
} from "../../../../types";
import _ from "lodash";
import { GeneralInfoTabType } from "../SurveyUpsertDialog";
import { ValueType } from "react-select/src/types";

type OwnProps = {
  isAdmins?: boolean;
  generalInfoTabObject: GeneralInfoTabType;
  setGeneralInfoTabObject: React.Dispatch<
    React.SetStateAction<GeneralInfoTabType>
  >;
  defaultAvailableLanguages: Locale[];
  selectedSurvey?: Survey;
};

type Props = OwnProps;

const SurveyGeneralInfoTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    isAdmins,
    generalInfoTabObject,
    setGeneralInfoTabObject,
    defaultAvailableLanguages,
    selectedSurvey,
  } = props;

  const localeOptions: SelectorOptionType<Locale>[] = useMemo(() => {
    return Object.values(Locale).map((locale, index) => {
      return {
        value: locale,
        label: LocaleDisplayedValues[locale],
      };
    });
  }, []);

  const handleSurveyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setGeneralInfoTabObject({
      ...generalInfoTabObject,
      name: value,
    });
  };

  const handleChangeAvailableLanguages = (
    values: ValueType<SelectorOptionType<Locale>, true>,
    { action, removedValue }: ActionMeta<SelectorOptionType<Locale>>
  ) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue?.value === Locale.English) {
          return;
        }
        break;
      case "clear": {
        setGeneralInfoTabObject({
          ...generalInfoTabObject,
          available_languages: defaultAvailableLanguages,
        });
        return;
      }
    }

    setGeneralInfoTabObject({
      ...generalInfoTabObject,
      available_languages:
        values?.flatMap((v) => v.value) ?? defaultAvailableLanguages,
    });
  };

  const handleRedirectToQuestionsPageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    setGeneralInfoTabObject({
      ...generalInfoTabObject,
      redirect_to_questions_page: checked,
    });
  };

  const handleIsSystemSurveyChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    setGeneralInfoTabObject({
      ...generalInfoTabObject,
      is_system_survey: checked,
    });
  };

  const handleSurveyCategoriesTypeChange = (item: SurveyCategoryType) => {
    setGeneralInfoTabObject({
      ...generalInfoTabObject,
      survey_categories_type: item,
    });
  };

  return (
    <div>
      <FormGroup
        label={intl.formatMessage({
          id: "app.surveys.survey-dialog.survey-name",
        })}
        labelFor="survey-name"
      >
        <InputGroup
          id="survey-name"
          required
          placeholder={intl.formatMessage({
            id: "app.surveys.survey-dialog.survey-name.placeholder",
          })}
          fill
          value={generalInfoTabObject.name}
          onChange={handleSurveyNameChange}
        />
      </FormGroup>

      <FormGroup
        label={intl.formatMessage({
          id: "app.languages.available-languages",
        })}
      >
        <Select
          isMulti
          isClearable
          value={localeOptions.filter((locale) =>
            generalInfoTabObject.available_languages.includes(
              locale.value as Locale
            )
          )}
          options={localeOptions}
          placeholder={intl.formatMessage({
            id: "app.languages.available-languages.select.placeholder",
          })}
          styles={{
            multiValue: (base, state) => {
              return state.data.value === Locale.English
                ? { ...base, backgroundColor: "gray" }
                : base;
            },
            multiValueLabel: (base, state) => {
              return state.data.value === Locale.English
                ? {
                    ...base,
                    fontWeight: "bold",
                    color: "white",
                    paddingRight: 6,
                  }
                : base;
            },
            multiValueRemove: (base, state) => {
              return state.data.value === Locale.English
                ? { ...base, display: "none" }
                : base;
            },
          }}
          onChange={handleChangeAvailableLanguages}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({
          id: "app.titles.survey-category-type",
        })}
      >
        <div className="flex">
          {Object.values(SurveyCategoryType).map((item, index) => (
            <Button
              key={index}
              intent={
                generalInfoTabObject.survey_categories_type === item
                  ? "primary"
                  : "none"
              }
              large
              active={generalInfoTabObject.survey_categories_type === item}
              className="mr-2"
              text={intl.formatMessage({
                id: `app.filters.survey-categories-type.${item}`,
              })}
              title={intl.formatMessage({
                id: `app.filters.survey-categories-type.${item}`,
              })}
              onClick={() => handleSurveyCategoriesTypeChange(item)}
            />
          ))}
        </div>
      </FormGroup>

      <div className="mb-4">
        {(!selectedSurvey ||
          !!_.difference(
            generalInfoTabObject.available_languages,
            selectedSurvey.available_languages
          ).length) && (
          <Callout className="mb-4" intent="primary">
            {intl.formatMessage({
              id: selectedSurvey
                ? "app.translations.survey.recommendation.missing-translations"
                : generalInfoTabObject.available_languages.length > 1
                ? "app.translations.survey.recommendation.add-questions-and-translations"
                : "app.translations.survey.recommendation.add-questions",
            })}
          </Callout>
        )}
        <Checkbox
          label={intl.formatMessage({
            id: "app.surveys.survey-dialog.redirect-checkbox",
          })}
          checked={generalInfoTabObject.redirect_to_questions_page}
          onChange={handleRedirectToQuestionsPageChange}
        />
      </div>
      {isAdmins && (
        <div className="mb-4">
          <Checkbox
            label={intl.formatMessage({
              id: "app.surveys.is-system-survey",
            })}
            checked={generalInfoTabObject.is_system_survey}
            onChange={handleIsSystemSurveyChange}
          />
        </div>
      )}
    </div>
  );
};

export default SurveyGeneralInfoTab;
