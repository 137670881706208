import React from "react";
import { SelectorOptionType } from "../../../types";
import { Checkbox } from "@blueprintjs/core";

type Props<T extends string | number> = {
  direction?: string;
  options: SelectorOptionType<T>[];
  selectedItems?: T[];
  onChange: (items: T[]) => void;
};

const GenericCheckboxButtonsGroup = <T extends string | number>(
  props: Props<T>
) => {
  const { selectedItems = [], onChange, options, direction } = props;

  const handleItemsChange = (item: T, checked: boolean) => {
    onChange(
      checked
        ? selectedItems.filter((i) => i !== item)
        : [...selectedItems, item]
    );
  };

  return (
    <div>
      {options.map((item) => {
        const checked = selectedItems.includes(item.value);
        return (
          <Checkbox
            large
            alignIndicator={direction === "rtl" ? "right" : "left"}
            key={item.value}
            checked={checked}
            label={item.label}
            onChange={() => handleItemsChange(item.value, checked)}
          />
        );
      })}
    </div>
  );
};

export default GenericCheckboxButtonsGroup;
