import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  InviteStatus,
  School,
  TeamRole,
  UserEntity,
  UserInfo,
  UserInvite,
  UserModel,
  UserRole,
  UserType
} from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { CellProps } from "react-table";
import { Button, Icon, NonIdealState, Tag } from "@blueprintjs/core";
import { useLoading } from "../../../helpers/hooks/useLoading";
import EntityTable from "../../common/EntityTable";

import {
  redispatchInvite,
  showUserEditingDialog,
} from "../../../store/users/actions";
import {
  getSchools,
  getSchoolsByDistrict,
} from "../../../store/schools/actions";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { AppToaster } from "../../../helpers/toaster";
import {
  getActivePlanDistrictId,
  isDistrictPlan,
} from "../../../store/plans/selectors";
import useUserRole from "../../../helpers/hooks/useUserRole";
import UserTableHeaderActions from "./UserTableHeaderActions";
import { getFullName } from "../../../helpers/message-format-utils";
import _ from "lodash";
import { getPlans } from "../../../store/plans/actions";


type OwnProps = {
  isTeamPlanPage?: boolean;
  forAllSystem?: boolean;
};

type RouteParams = {
  districtId?: string;
};

type Props = OwnProps;

const DistrictUsersTable: FunctionComponent<Props> = (props) => {
  const { isTeamPlanPage, forAllSystem } = props;

  const users: UserEntity = useSelector((s) => s.users.users);

  const intl = useIntl();
  const dispatch = useDispatch();

  const routeParams = useParams<RouteParams>();

  const activePlanDistrictId = useSelector(getActivePlanDistrictId);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const { isDCC, teamRole, isSuperintendent, isTeamChampion } = useUserRole();

  const isTeamRoleHigherOrEqualTeamChair = useMemo(() => {
    return isTeamChampion || teamRole === TeamRole.TeamChair;
  }, [isTeamChampion, teamRole]);

  const loading = useSelector((s) => s.users.loading.getUsers);
  const error = useSelector((s) => s.users.errors.getUsers);
  useLoading({ loading, error });

  const schools = useSelector((s) => s.schools.schools as School[]);
  const schoolsLoading = useSelector(
    (s) =>
      s.schools.loading.getSchoolsByDistrict || s.schools.loading.getSchools
  );
  useEffect(() => {
    if (!isTeamPlanPage) {
      if (routeParams.districtId) {
        const districtId = Number(routeParams.districtId);
        dispatch(getSchoolsByDistrict.request(districtId));
      } else {
        dispatch(getSchools.request());
      }
    } else if (activePlanDistrictId && isTeamPlanPage) {
      dispatch(getSchoolsByDistrict.request(activePlanDistrictId));
    }
  }, [routeParams, activePlanDistrictId, isTeamPlanPage]);

  const redispatchLoading = useSelector(
    (s) => s.users.loading.redispatchInvite
  );
  const redispatchError = useSelector((s) => s.users.errors.redispatchInvite);
  useLoading({
    loading: redispatchLoading,
    error: redispatchError,
    onSuccess: () => {
      AppToaster.show({
        message: intl.formatMessage({ id: "app.toaster.invite-redispatched" }),
        icon: "tick",
        intent: "success",
      });
    },
  });

  const [searchString, setSearchString] = useState<string>("");

  const roleDisplayDictionary: {
    [key in UserRole]: string;
  } = Object.values(UserRole).reduce(
    (pV, cV, i) => ({
      ...pV,
      [cV]: intl.formatMessage({ id: "app.roles" }, { role: cV }),
    }),
    {} as any
  );

  const inviteStatusDisplayDictionary: {
    [key in InviteStatus]: string;
  } = Object.values(InviteStatus).reduce(
    (pV, cV, i) => ({
      ...pV,
      [cV]: intl.formatMessage(
        { id: "app.invite-status" },
        { inviteStatus: i }
      ),
    }),
    {} as any
  );

  const getRole = (role: UserRole, customRoleName?: string): string => {
    if (customRoleName) {
      return customRoleName;
    }

    return (
      roleDisplayDictionary[role] ||
      intl.formatMessage({ id: "app.titles.not-applicable" })
    );
  };

  const inviteDateCalculator = (numOfDays: number, date: Date) => {
    const daysAgo = new Date(date.getTime());
    daysAgo.setDate(date.getDate() - numOfDays);
    return daysAgo;
  };

  useEffect(() => {
    dispatch(getPlans.request());
  }, [dispatch]);

  const plans = useSelector((s) => s.plans.plans);

  const mapUserModelToInfo = (
    user: UserModel,

    [inviteStatus, invite_id, bounce_status]: [InviteStatus, number | undefined, string | undefined] = [
      InviteStatus.Accepted,
      undefined,
      undefined
    ]

  ): UserInfo => {
    return {
      id: user.id,
      invite_id: invite_id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      full_name: getFullName(user),
      role: user.profile.role,
      is_active: user.is_active,
      created_at: user.profile.created_at,
      user_agreement: user.user_agreement,
      role_name: getRole(user.profile.role, user.profile.custom_role_name),
      district_name: user.profile.district?.name,
      is_single_school_district:
        user.profile.district?.is_single_school_district,
      is_dcc: user.profile.is_dcc,
      schools: (user.team_roles || [])
        .map((sId) => plans.find((plan) => plan.id === sId.plan)?.school.name)
        .filter((sName) => !!sName) as string[],
      invite_status_name: inviteStatusDisplayDictionary[inviteStatus],
      invite_status: inviteStatus,
      original: user,
      bounce_status: bounce_status,
      type: UserType.User
    };
  };


  const mapUserInviteToInfo = (invite: UserInvite): UserInfo => {
    return {
      id: invite.invited_user,
      bounce_status: invite?.bounce_status,
      invite_id: invite.id,
      email: invite.email,
      first_name: invite.first_name,
      last_name: invite.last_name,
      full_name: getFullName(invite),
      role: invite.role!,
      created_at: invite.expires,
      role_name: getRole(invite.role!, invite.custom_role_name),
      district_name: invite.district_name,
      is_single_school_district: invite.is_single_school_district,
      is_dcc: invite.is_dcc,
      schools: (invite.schools || [])
        .map((sId) => schools.find((school) => school.id === sId)?.name)
        .filter((sName) => !!sName) as string[],
      invite_status_name: inviteStatusDisplayDictionary[invite.status],
      invite_status: invite.status,
      original: invite,
      type: UserType.Invite
    };
  };

  const data: UserInfo[] = useMemo(() => {
    if (users?.users && users?.invites) {
      const { invites_with_user, invites_without_user } = users.invites.reduce<{
        invites_with_user: UserInvite[];
        invites_without_user: UserInvite[];
      }>(
        (res, invite) => {
          const hasUser = users.users!.some(
            (user) => user.id === invite.invited_user
          );

          if (hasUser) {
            return {
              ...res,
              invites_with_user: [...res.invites_with_user, invite],
            };
          } else {
            return {
              ...res,
              invites_without_user: [...res.invites_without_user, invite],
            };
          }
        },
        {
          invites_with_user: [],
          invites_without_user: [],
        }
      );

      const invitesWithUserStatusDictionary = _.chain(invites_with_user)
        .map((invite) => [invite.invited_user, [invite.status, invite.id, invite.bounce_status]])
        .fromPairs()
        .value();

      return [
        ...users.users.map((u) =>
          mapUserModelToInfo(u, invitesWithUserStatusDictionary[u.id])
        ),
        ...invites_without_user.map(mapUserInviteToInfo),
      ];
    }
    return [];
  }, [users, mapUserModelToInfo, mapUserInviteToInfo]);

  const callbackFilter = useCallback(
    (userInfo: UserInfo) => {
      const searchStringLowerCase = searchString.trim().toLowerCase();

      if (!searchStringLowerCase) {
        return true;
      }
    const isDeactivated =  userInfo.type === UserType.User && userInfo.is_active === false && userInfo.invite_status === InviteStatus.Accepted ;
    const deactivateUserName = isDeactivated?intl.formatMessage({ id: "app.titles.deactivated"}).toLowerCase() + ' '+ userInfo.full_name.toLowerCase() : userInfo.full_name.toLowerCase();
    return (
        userInfo.email.toLowerCase()?.includes(searchStringLowerCase) ||
        userInfo.first_name.toLowerCase()?.includes(searchStringLowerCase) ||
        userInfo.last_name.toLowerCase()?.includes(searchStringLowerCase) ||
        deactivateUserName.toLowerCase()?.includes(searchStringLowerCase)   
      );
    },
    [searchString]
  );

  const filteredData = useMemo(() => {

    return data.filter(callbackFilter);
  }, [data, callbackFilter]);

  const showConfirmRedispatchInviteDialog = (
    invite: Pick<UserInvite, "id" | "email">
  ) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(hideConfirmDialog());
          dispatch(redispatchInvite.request(invite.id));
        },
        show: true,
        intent: "warning",
        text: intl.formatMessage(
          {
            id: "app.confirmation-dialogs.redispatch-invite",
          },
          { email: invite?.email }
        ),
        icon: "info-sign",
        confirmButtonText: intl.formatMessage({ id: "app.titles.submit" }),
      })
    );
  };

  const canEditUser = useCallback(
    (targetRole?: UserRole) => {
      return (
        !isTeamPlanPage ||
        (isDistrictActivePlan && isSuperintendent) ||
        (!isDistrictActivePlan && isTeamRoleHigherOrEqualTeamChair) ||
        (isDCC && targetRole !== UserRole.Superintendent)
      );
    },
    [isTeamPlanPage, isDistrictActivePlan, isSuperintendent, isTeamRoleHigherOrEqualTeamChair, isDCC]
  );

  const isRoleCheck = (userInfo?: UserInfo) => {
    //console.log('email', userInfo?.email, userInfo?.role, 'role', userInfo?.role_name, )

    if (!!userInfo && userInfo.role && ![UserRole.DistrictStaff].includes(userInfo?.role)) {
      return [
        UserRole.Staff,
        UserRole.Principal,
        UserRole.VicePrincipal,
      ].includes(userInfo?.role);
    }
    return false;
  };

  const columns = useMemo(() => {

    const columns = [
      {
        Header: intl.formatMessage({ id: "app.common-table.columns.date" }),
        width: "20%",
        wrap: true,
        accessor: (userInfo: UserInfo) => {

          if (userInfo.type === UserType.User) {

            const inviteDate = inviteDateCalculator(
              0,
              new Date(userInfo.user_agreement == null ? userInfo.created_at : userInfo.user_agreement)
            );

            const inviteDateString =
              inviteDate.getMonth() +
              1 +
              "/" +
              inviteDate.getDate() +
              "/" +
              inviteDate.getFullYear().toString().substr(-2);

            return inviteDateString;

          }

          else if (userInfo.type === UserType.Invite) {

            const inviteDate = inviteDateCalculator(
              14,
              new Date(userInfo.created_at)
            );

            const inviteDateString =
              inviteDate.getMonth() +
              1 +
              "/" +
              inviteDate.getDate() +
              "/" +
              inviteDate.getFullYear().toString().substr(-2);

            return inviteDateString;
          }
        },
      },
      {
        Header: intl.formatMessage({ id: "app.common-table.columns.name" }),
        width: "20%",
        accessor: (userInfo: UserInfo) => userInfo.full_name,
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
        return (  
        <div>
        <div className="font">
        {(original.type === UserType.User && original.is_active === false && original.invite_status === InviteStatus.Accepted ?<i>{intl.formatMessage({ id: "app.titles.deactivated"})}</i> : ' ')}
        {" "}
        {`${original.full_name}`}   
        </div>
        </div>
        )
      }, 
      },
      {
        Header: intl.formatMessage({ id: "app.users-table.columns.email" }),
        wrap: true,
        width: "30%",
        accessor: (userInfo: UserInfo) => userInfo.email,
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
          return (
            <span className="break-all">
              {original.email ||
                intl.formatMessage({ id: "app.titles.not-applicable" })}
            </span>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.role" }),
        wrap: true,
        accessor: (userInfo: UserInfo) => {
          return (
            userInfo.role_name ||
            intl.formatMessage({ id: "app.titles.not-applicable" })
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.role-can-access-school-district-plan" }),
        wrap: true,
        accessor: (userInfo: UserInfo) => {
          return (
            isRoleCheck(userInfo) ?
              intl.formatMessage({ id: "app.titles.no" }) :
              intl.formatMessage({ id: "app.titles.yes" })
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.forms.create-district.name" }),
        wrap: true,
        width: "25%",
        accessor: (userInfo: UserInfo) => {
          return (
            userInfo.district_name ||
            intl.formatMessage({ id: "app.titles.not-applicable" })
          );
        },
        hidden: isTeamPlanPage,
      },
      {
        Header: intl.formatMessage({
          id: "app.titles.is-single-school-district",
        }),
        wrap: true,
        accessor: (userInfo: UserInfo) => {
          return userInfo.is_single_school_district;
        },
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
          const checked = original.is_single_school_district;

          return <Icon icon={checked ? "tick" : "cross"} />;
        },
        hidden: isTeamPlanPage,
      },
      {
        Header: intl.formatMessage({ id: "app.titles.member-school-teams" }),
        width: "10%",
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
          return (
            <div className="flex flex-wrap gap-2 school-members-list">
              {original.schools.map((schoolName, index) => (
                <Tag key={index} minimal intent="primary" title={schoolName} multiline className="break-all">
                  {schoolName}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.is-dcc" }),
        accessor: (userInfo: UserInfo) => userInfo.is_dcc,
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
          const checked = original.is_dcc;

          return <Icon icon={checked ? "tick" : "cross"} />;
        },
        hidden: isTeamPlanPage,
      },
      {
        Header: intl.formatMessage({ id: "app.titles.invite-status" }),
        accessor: (userInfo: UserInfo) => userInfo.invite_status_name,
        width: "15%",
        wrap: true,
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
          return (
            <i className="flex justify-center">{original.invite_status_name}</i>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.bounce" }),
        accessor: (userInfo: UserInfo) => userInfo.bounce_status,
        width: "10%",
        wrap: true,
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
          return (
            <i className="flex justify-center">{original.bounce_status ? intl.formatMessage({
              id: "app.titles.bounce",
            }) : ""}</i>
          );
        },
      },
      {
        id: "actions",
        width: "1%",
        wrap: true,
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<UserInfo>) => {
          const showEditButton = canEditUser(original.role);
          return (
            <div className="flex justify-center gap-2">
              {showEditButton && (
                <Button
                  title={intl.formatMessage({
                    id: "app.titles.edit",
                  })}
                  icon="edit"
                  intent="primary"
                  onClick={() => {
                    dispatch(showUserEditingDialog(original.original));
                  }}
                />
              )}
              {original.invite_status &&
                original.invite_id &&
                original.invite_status != InviteStatus.Accepted && (
                  <Button
                    title={intl.formatMessage({
                      id: "app.titles.resend-invite",
                    })}
                    icon="refresh"
                    intent="primary"
                    onClick={() => {
                      showConfirmRedispatchInviteDialog({
                        id: original.invite_id!,
                        email: original.email,
                      });
                    }}
                  />
                )}
            </div>
          );
        },
      },
    ];

    return columns.filter((c: any) => !c.hidden);
  }, [canEditUser]);

  const noUsers = useMemo(
    () => (
      <NonIdealState
        icon="user"
        title={intl.formatMessage({
          id: "app.non-ideal-state.users-table.title",
        })}
        description={intl.formatMessage({
          id: "app.non-ideal-state.users-table.description",
        })}
      />
    ),
    []
  );

  return (
    <EntityTable
      data={filteredData}
      columns={columns}
      searchString={searchString}
      setSearchString={setSearchString}
      loading={loading || schoolsLoading || redispatchLoading}
      noDataComp={noUsers}
    >
      {!isTeamPlanPage && (
        <UserTableHeaderActions
          users={data}
          keys={[
            "full_name",
            "email",
            "role_name",
            "district_name",
            "is_single_school_district",
            "schools",
            "is_dcc",
            "invite_status_name",
          ]}
          forAllSystem={forAllSystem}
        />
      )}
    </EntityTable>
  );
};

export default DistrictUsersTable;
