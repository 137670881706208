import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Menu,
  MenuItem,
  Popover,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";
import {
  DemographicWrapper,
  EditedSurveyQuestionsByPage,
  QuestionTranslations,
  SurveyDemographic,
  SurveyPrompt,
  SurveyQuestionExcludeBaseModelType,
} from "../../types";
import useWindowSize, { WindowSize } from "../../helpers/hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../helpers/hooks/useLoading";
import {
  editSurveyQuestionsInSelectedSurvey,
  getSurvey,
  getSurveyQuestions,
  setSurveyQuestions,
} from "../../store/surveys/actions";
import * as _ from "lodash";
import {
  updateRemovedQuestionsPageTextTranslations,
  updateTranslations,
} from "../surveys/survey-questions/utils";
import SurveyPromptsTab from "../surveys/survey-questions/tabs/SurveyPromptsTab";
import { Locale } from "../../store/UIState";
import EditSurveyQuestionsTab from "../surveys/survey-questions/tabs/EditSurveyQuestionsTab";
import { AdminRoutes, AuthRoutes } from "../../App";
import { AdminTabs } from "./AdminTables";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SurveyQuestionRequest } from "../../api/surveys/types";

type OwnProps = {};

type RouterParams = {
  id: string;
};

type Props = OwnProps;

type DemographicTranslation = {
  demographic: SurveyDemographic;
  translate: string;
};

const EditSurveyQuestions: React.FC<Props> = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { id } = useParams<RouterParams>(); //Edited survey id

  const history = useHistory();

  const [warning, setWarning] = useState<string | undefined>();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const surveyQuestions: SurveyQuestionExcludeBaseModelType[] = useSelector(
    (s) => s.surveys.surveyQuestions
  );
  const questionsLoading = useSelector(
    (s) => s.surveys.loading.getSurveyQuestions
  );
  const questionsErrors = useSelector(
    (s) => s.surveys.errors.getSurveyQuestions
  );
  useLoading({ loading: questionsLoading, error: questionsErrors });

  const updateLoading = useSelector(
    (s) => s.surveys.loading.editSurveyQuestionsInSelectedSurvey
  );
  const updateError = useSelector(
    (s) => s.surveys.errors.editSurveyQuestionsInSelectedSurvey
  );
  const onSuccess = useCallback(() => {
    handleCancelClick();
  }, []);
  useLoading({
    loading: updateLoading,
    error: updateError,
    onSuccess: onSuccess,
  });

  useEffect(() => {
    dispatch(getSurveyQuestions.request(Number(id)));
    dispatch(getSurvey.request(Number(id)));
  }, [id]);

  const [
    showTranslationsDialog,
    setShowTranslationsDialog,
  ] = useState<boolean>();

  const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);

  const [selectedTabId, setSelectedTabId] = useState<TabId>("survey-questions");

  const [pageText, setPageText] = useState<
    DemographicWrapper<SurveyPrompt> | undefined
  >({});
  useEffect(() => {
    setPageText(selectedSurvey?.page_text);
  }, [selectedSurvey]);

  const [questions, setQuestions] = useState<
    DemographicWrapper<EditedSurveyQuestionsByPage>
  >({});

  useEffect(() => {
    setQuestions(
      _.fromPairs(
        _.map(_.toPairs(_.groupBy(surveyQuestions, "demographic")), (o) => [
          o[0],
          _.groupBy(_.sortBy(o[1], "order"), "page"),
        ])
      )
    );
  }, [surveyQuestions]);

  useEffect(() => {
    return () => {
      dispatch(setSurveyQuestions([]));
    };
  }, []);

  const windowSize = useWindowSize();

  const isSmallScreen = useMemo(() => {
    return windowSize === WindowSize.SM;
  }, [windowSize]);

  const demographicTranslates: DemographicTranslation[] = useMemo(() => {
    return Object.values(SurveyDemographic).map((sd) => ({
      demographic: sd,
      translate: intl.formatMessage({
        id: `app.surveys.survey-demographic.${sd}`,
      }),
    }));
  }, []);

  const [surveyDemographic, setSurveyDemographic] = useState<SurveyDemographic>(
    demographicTranslates[0].demographic
  );

  const handleSurveyDemographicButtonsClick = (
    newSurveyDemographic: SurveyDemographic
  ) => {
    setSurveyDemographic(newSurveyDemographic);
  };

  const smallScreenPopoverContent = (
    <Popover
      content={
        <Menu>
          {demographicTranslates.map((dt, i) => (
            <MenuItem
              key={i}
              text={dt.translate}
              onClick={() =>
                handleSurveyDemographicButtonsClick(dt.demographic)
              }
            />
          ))}
        </Menu>
      }
    >
      <Button minimal icon="menu" />
    </Popover>
  );

  const handleQuestionPagesChange = (
    updatedQuestionsPages: EditedSurveyQuestionsByPage,
    currentPage?: number
  ) => {
    setQuestions({
      ...questions,
      [surveyDemographic]: updatedQuestionsPages,
    });
    if (currentPage !== undefined && pageText) {
      setPageText(
        updateRemovedQuestionsPageTextTranslations(
          pageText,
          currentPage,
          surveyDemographic
        )
      );
    }
  };

  const handleUpdateQuestionField = (
    page: number,
    order: number,
    field: string,
    value: string | number | number[] | boolean | undefined
  ) => {
    setQuestions({
      ...questions,
      [surveyDemographic]: {
        ...questions?.[surveyDemographic],
        [page]: (questions?.[surveyDemographic] || {})[page]?.map((sq) =>
          sq.order === order
            ? field === "domain"
              ? { ...sq, [field]: value, is_not_applicable_answer: false }
              : { ...sq, [field]: value }
            : { ...sq }
        ),
      },
    });
  };

  const handleUpdateQuestion = (
    question: SurveyQuestionExcludeBaseModelType
  ) => {
    setQuestions({
      ...questions,
      [surveyDemographic]: {
        ...questions?.[surveyDemographic],
        [question.page]: (questions?.[surveyDemographic] || {})[
          question.page
        ]?.map((sq) => (sq.order === question.order ? question : sq)),
      },
    });
  };

  const handleUpdateTranslation = (translates: QuestionTranslations) => {
    setQuestions({
      ...questions,
      [surveyDemographic]: updateTranslations(
        questions[surveyDemographic] ?? {},
        translates
      ),
    });
  };

  const handleCancelClick = () => {
    if (activePlanId) {
      history.push(
        generatePath(AuthRoutes.YourPlan, {
          workspace: "data" as any,
        } as any)
      );
    } else {
      history.push(
        generatePath(AdminRoutes.AdminTables, {
          tab: AdminTabs.SURVEYS,
        })
      );
    }
  };

  const handleSaveClick = () => {
    const { isValid, message } = validate();
    if (isValid) {
      const requestingQuestions = _.flatMap(
        _.flatMap(_.values(questions), (o) => _.values(o))
      );
      dispatch(
        editSurveyQuestionsInSelectedSurvey.request({
          request: {
            questions: requestingQuestions.map(
              (
                item: SurveyQuestionExcludeBaseModelType
              ): SurveyQuestionRequest => {
                if (typeof item.id === "string") {
                  return {
                    domain_id: item.domain,
                    demographic: item.demographic,
                    text: item.text,
                    page: item.page,
                    order: item.order,
                    translations: item.translations,
                    is_not_applicable_answer: item.is_not_applicable_answer,
                    question_tags: item.question_tags as number[],
                    lead_in: !!item.lead_in ? item.lead_in : undefined,
                    short_code: item.short_code,
                    variable_name: item.variable_name,
                    segmentation_code: item.segmentation_code,
                  };
                } else {
                  return {
                    demographic: item.demographic,
                    text: item.text,
                    page: item.page,
                    order: item.order,
                    domain_id: item.domain,
                    survey_question_id: item.id as number,
                    translations: item.translations,
                    is_not_applicable_answer: item.is_not_applicable_answer,
                    question_tags: item.question_tags as number[],
                    lead_in: !!item.lead_in ? item.lead_in : undefined,
                    short_code: item.short_code,
                    variable_name: item.variable_name,
                    segmentation_code: item.segmentation_code,
                  };
                }
              }
            ),
            page_text: pageText!,
          },
          surveyId: Number(id),
        })
      );
      setWarning(undefined);
    } else {
      setWarning(message);
    }
  };

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (!Object.values(questions).length) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.survey-questions.validation.one-question",
        }),
      };
      return validationOption;
    }

    return validationOption;
  };

  const handleShowTranslationsDialog = () => {
    setShowTranslationsDialog(true);
  };

  const handleTranslationsDialogClose = () => {
    setShowTranslationsDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "app.surveys-table.columns.edit-survey-questions",
          })}
        </title>
      </Helmet>
      <Card className="flex-grow">
        <div className="flex">
          {!isSmallScreen && (
            <div className="pr-5">
              <p>{intl.formatMessage({ id: "app.surveys.content-for" })}</p>
              <ButtonGroup minimal={true} vertical={true} alignText={"left"}>
                {demographicTranslates.map((dt, i) => (
                  <Button
                    key={i}
                    intent="primary"
                    text={dt.translate}
                    active={surveyDemographic === dt.demographic}
                    onClick={() =>
                      handleSurveyDemographicButtonsClick(dt.demographic)
                    }
                  />
                ))}
              </ButtonGroup>
            </div>
          )}
          <div className="flex-grow">
            <Tabs
              id="edit-survey-data-tabs"
              selectedTabId={selectedTabId}
              onChange={setSelectedTabId}
              renderActiveTabPanelOnly
            >
              <Tab
                id="survey-questions"
                title={intl.formatMessage({
                  id: "app.titles.survey-questions",
                })}
                panel={
                  <EditSurveyQuestionsTab
                    surveyId={+id}
                    demographic={surveyDemographic}
                    questionsPages={questions[surveyDemographic]}
                    questionsLoading={questionsLoading}
                    onQuestionUpdate={handleUpdateQuestion}
                    onQuestionPagesChange={handleQuestionPagesChange}
                    onQuestionFieldUpdate={handleUpdateQuestionField}
                    onTranslationUpdate={handleUpdateTranslation}
                    showDialog={showTranslationsDialog}
                    onClose={handleTranslationsDialogClose}
                  >
                    {smallScreenPopoverContent}
                  </EditSurveyQuestionsTab>
                }
              />
              <Tab
                id="page-content"
                title={intl.formatMessage({
                  id: "app.surveys.tabs.page-content",
                })}
                panel={
                  <SurveyPromptsTab
                    questionPages={Object.keys(
                      questions[surveyDemographic] ?? {}
                    ).map((p) => +p)}
                    demographic={surveyDemographic}
                    pageText={pageText ?? {}}
                    setPageText={setPageText}
                    selectedLanguageAbbreviation={Locale.English}
                    showDialog={showTranslationsDialog}
                    onClose={handleTranslationsDialogClose}
                  />
                }
              />
              <Tabs.Expander />
              <Button
                onClick={handleShowTranslationsDialog}
                intent="primary"
                text={intl.formatMessage({
                  id: "app.translations.manage-translations",
                })}
                title={intl.formatMessage({
                  id: "app.translations.manage-translations",
                })}
              />
            </Tabs>
            {warning && (
              <div className="my-3">
                <Callout intent="warning">{warning}</Callout>
              </div>
            )}
            <div className="flex justify-between mt-2">
              <Button
                className="button-min-width"
                disabled={updateLoading}
                large
                text={intl.formatMessage({
                  id: "app.titles.cancel",
                })}
                title={intl.formatMessage({
                  id: "app.titles.cancel",
                })}
                onClick={handleCancelClick}
              />
              <Button
                loading={updateLoading}
                className="button-min-width"
                large
                intent="primary"
                text={intl.formatMessage({ id: "app.titles.save" })}
                title={intl.formatMessage({
                  id: "app.titles.save",
                })}
                onClick={handleSaveClick}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EditSurveyQuestions;
