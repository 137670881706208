import { useAttachActivities } from "./useAttachActivities";
import { Activity, Meeting, UserNotes, UserNotesType } from "../../types";
import { showNotesDialog } from "../../store/notes/actions";
import { useDispatch, useSelector } from "react-redux";
import { isDistrictPlan } from "../../store/plans/selectors";

type Props = {
  meeting?: Meeting<string, false>;
  agenda?: string;
  occurrences: string[];
  activities?: Activity[];
};

export const useMeetingStart = (props: Props) => {
  const {
    meeting,
    agenda = meeting?.agenda,
    occurrences,
    activities = meeting?.activities ?? [],
  } = props;

  const dispatch = useDispatch();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const handleNotesShow = (activity?: Activity, checkExisting?: Boolean ) => {
    const existingNotes = activity
      ? meeting?.user_notes.find(
          (uNotes: UserNotes) =>
            uNotes.activity && uNotes.activity.id === activity.id
        )
      : undefined;
    dispatch(
      showNotesDialog({
        selectedActivity: activity,
        agenda: agenda,
        meeting: meeting,
        selectedNote: existingNotes,
        checkExisting:checkExisting,
        type: isDistrictActivePlan
          ? UserNotesType.DCLT_RESOURCES
          : UserNotesType.SCLT_RESOURCES,
      })
    );
  };

  const handleShowExistingActivityNotes = () => {
    let activity: Activity | undefined = undefined;

    occurrences.forEach((name: string) => {
      if (activity) {
        return;
      }

      const meetingActivity = activities.find((act) => act.name === name);
      if (meetingActivity) {
        activity = meetingActivity;
      }
    });

    if (!activity) {
      activity = activities[0]!;
    }

    handleNotesShow(activity);
  };

  const { onActivityCheck } = useAttachActivities({
    onClick: handleNotesShow,
    onError: handleShowExistingActivityNotes,
  });

  const onMeetingStart = (checkExisting?:Boolean) => {
    if (occurrences.length) {
      onActivityCheck(occurrences[0],checkExisting);
    } else {
      handleNotesShow(activities[0]);
    }
  };

  return {
    onMeetingStart,
  };
};

export default useMeetingStart;
