import React, { useCallback } from "react";
import {
  Domain,
  DomainAnswerSet,
  SurveyDemographic,
  SurveyQuestionExcludeBaseModelType,
} from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import CreatableSelect from "react-select/creatable";
import { useIntl } from "react-intl";
import {
  showDomainUpsertDialog,
  updateDomain,
} from "../../../store/domains/actions";
import { Icon, Tooltip } from "@blueprintjs/core";
import { Locale, LocaleDisplayedValues } from "../../../store/UIState";
import _ from "lodash";
import AnswerSetItem from "../../domains/domain-answer-set/AnswerSetItem";
import { showDomainAnswerSetUpsertDialog } from "../../../store/domain-answer-set/actions";

type Props = {
  surveyQuestion: SurveyQuestionExcludeBaseModelType;
  surveyAvailableTranslations: Locale[];
  onDomainChange: (domainId: number) => void;
};

const DomainSelector: React.FC<Props> = (props: Props) => {
  const { surveyQuestion, surveyAvailableTranslations, onDomainChange } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const loading = useSelector((s) => s.domains.loading.getDomains);
  const error = useSelector((s) => s.domains.errors.getDomains);
  useLoading({ loading, error });

  const domains = useSelector((s) => s.domains.domains);

  const domainCreationLoading = useSelector((s) => s.domains.loading.addDomain);
  const domainCreationError = useSelector((s) => s.domains.errors.addDomain);
  const onSuccess = useCallback(() => {
    onDomainChange(domains[domains.length - 1].id ?? surveyQuestion.domain);
  }, [domains]);

  useLoading({
    loading: domainCreationLoading,
    error: domainCreationError,
    onSuccess: onSuccess,
  });

  const handleItemSelect = (activeItem?: number) => {
    onDomainChange(activeItem ?? surveyQuestion.domain);
  };

  const handleDomainCreate = (domainName: string) => {
    dispatch(showDomainUpsertDialog({ domainName: domainName }));
  };

  const renderOptionLabel = (domain: Domain) => {
    const translations = _.chain(domain.domain_answer)
      .pick(Object.values(SurveyDemographic))
      .values()
      .flatMap((tas) => _.keys(tas))
      .uniq()
      .value();

    const diff = _.difference(surveyAvailableTranslations, translations);

    const handleEditAnswerSet = () => {
      dispatch(
        showDomainAnswerSetUpsertDialog({
          domainAnswerSet: domain.domain_answer.id
            ? domain.domain_answer
            : undefined,
          editing: !!domain.domain_answer.id,
          onSuccess: ({ id }: DomainAnswerSet) => {
            dispatch(
              updateDomain.request({
                id: domain.id,
                name: domain.name,
                description: domain.description,
                domain_answers_id: id,
              })
            );
          },
        })
      );
    };

    return (
      <div className="flex justify-between items-center">
        <span>{domain.name}</span>

        <div className="flex" onMouseDown={(e) => e.stopPropagation()}>
          <Tooltip
            position="bottom"
            content={
              domain.domain_answer.id ? (
                <AnswerSetItem
                  btnMinimal={false}
                  domainAnswerSet={domain.domain_answer}
                />
              ) : (
                <>
                  {intl.formatMessage({
                    id: "app.domain-answer-set.no-answer-set-assigned",
                  })}
                </>
              )
            }
          >
            <Icon
              onClick={handleEditAnswerSet}
              className="ml-2"
              intent={domain.domain_answer.id ? "primary" : "warning"}
              icon="clipboard"
            />
          </Tooltip>

          {!!diff.length && (
            <Tooltip
              position="bottom"
              intent="warning"
              content={intl.formatMessage(
                { id: "app.domain-answer-set.no-translations" },
                {
                  hasAnswerSet: !!domain.domain_answer.id,
                  translations: diff
                    .map((locale) => LocaleDisplayedValues[locale as Locale])
                    .join(", "),
                }
              )}
            >
              <Icon
                onClick={handleEditAnswerSet}
                className="ml-2"
                intent="warning"
                icon={"warning-sign"}
              />
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const renderCreateLabel = (inputValue: string) => {
    return intl.formatMessage(
      { id: "app.surveys.selectors.create-new-domain" },
      { domainName: inputValue }
    );
  };

  return (
    <CreatableSelect
      value={domains.find((d) => d.id === surveyQuestion.domain) ?? domains[0]}
      isLoading={loading}
      className="w-1/3 button-min-width"
      options={domains}
      getOptionValue={(domain) => domain.id as any}
      getOptionLabel={(domain) => domain.name}
      formatOptionLabel={renderOptionLabel}
      formatCreateLabel={renderCreateLabel}
      isSearchable
      onChange={(newValue) => handleItemSelect(newValue?.id)}
      onCreateOption={handleDomainCreate}
    />
  );
};

export default DomainSelector;
