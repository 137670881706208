import { Callout } from "@blueprintjs/core";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  LoggedStrategyLookForStatus,
  LookForItemStatusEnum,
  PlanStrategy,
  RecurringStatusEnum,
} from "../../../../types";
import DateInputGroup from "../../data/measurement-based-input/DateInputGroup";
import CoreComponentItem from "./CoreComponentItem";
import * as _ from "lodash";
import moment from "moment";

type OwnProps = {
  strategy: PlanStrategy;
  statuses: Omit<
    LoggedStrategyLookForStatus,
    "id" | "created_at" | "updated_at"
  >[];
  setStatuses: React.Dispatch<
    React.SetStateAction<
      Omit<LoggedStrategyLookForStatus, "id" | "created_at" | "updated_at">[]
    >
  >;
  date?: Date;
  onDateChange: (date: Date) => void;
  readonly?: boolean;
  implementation?: number;
};

type Props = OwnProps;

const PlanStrategyCreator: FunctionComponent<Props> = (props) => {
  const {
    strategy,
    statuses,
    setStatuses,
    date,
    onDateChange,
    readonly,
    implementation,
  } = props;

  const intl = useIntl();

  const strategyLookFors = useMemo(() => {
    return strategy?.strategy?.look_for_items;
  }, [strategy]);

  useEffect(() => {
    if (strategy.implementations?.length) {
      if (implementation) {
        const selectedImplementation = strategy.implementations.find(
          (i) => i.id === implementation
        );
        if (selectedImplementation) {
          setStatuses(
            selectedImplementation.statuses.map(
              ({ id, created_at, updated_at, ...loggedStatus }) => loggedStatus
            )
          );
        }
      } else {
        const sortedStrategyImplementations = _.orderBy(
          strategy.implementations,
          (implementation) => new Date(implementation.date),
          ["desc"]
        );
        if (sortedStrategyImplementations.length) {
          const latestImplementation = sortedStrategyImplementations[0];
          setStatuses(
            latestImplementation.statuses
              ?.filter((loggedStatus) => {
                const lookForItemStatus = strategy.look_for_statuses?.find(
                  (lookForStatus) => lookForStatus.item === loggedStatus.item
                );
                if (
                  lookForItemStatus &&
                  lookForItemStatus.recurring_status !==
                    RecurringStatusEnum.NotRecurring
                ) {
                  return (
                    (lookForItemStatus.recurring_status ===
                      RecurringStatusEnum.Monthly &&
                      moment(latestImplementation.date)
                        .add(1, "months")
                        .isSameOrAfter(moment())) ||
                    (lookForItemStatus.recurring_status ===
                      RecurringStatusEnum.Yearly &&
                      moment(latestImplementation.date)
                        .add(1, "y")
                        .isSameOrAfter(moment())) ||
                    (lookForItemStatus.recurring_status ===
                      RecurringStatusEnum.SixMonths &&
                      moment(latestImplementation.date)
                        .add(6, "months")
                        .isSameOrAfter(moment()))
                  );
                }
                return true;
              })
              ?.map(({ id, created_at, updated_at, ...loggedStatus }) => {
                return loggedStatus;
              }) ?? []
          );
        }
      }
    }
  }, [strategyLookFors, implementation]);

  const handleItemClick = (itemId: number, status: LookForItemStatusEnum) => {
    const loggedLookForStatus = statuses.find(
      (status) => status.item === itemId
    );

    if (loggedLookForStatus) {
      setStatuses(
        statuses.map((loggedStatus) =>
          loggedStatus.item === loggedLookForStatus.item
            ? { ...loggedStatus, status: status }
            : loggedStatus
        )
      );
    } else {
      setStatuses([...statuses, { item: itemId, status: status }]);
    }
  };

  const lookForItemStatusDictionary = useMemo(() => {
    return _.chain(strategy.look_for_statuses)
      .keyBy((s) => s.item)
      .value();
  }, [strategy.look_for_statuses]);

  return (
    <div className="space-y-2">
      {!readonly && (
        <>
          <Callout intent="primary">
            {intl.formatMessage({ id: "app.strategies.look-fors.status.tip" })}
          </Callout>
          <DateInputGroup
            label={`${intl.formatMessage({
              id: "app.titles.implementation-status-for-date",
            })}:`}
            date={date}
            minDateOffset={10}
            onDateChange={onDateChange}
          />
        </>
      )}
      <div className="space-y-2">
        {strategyLookFors?.map((lookFor) => (
          <CoreComponentItem
            key={lookFor.id}
            lookFor={lookFor}
            status={
              statuses.find((status) => status.item === lookFor.id)?.status
            }
            lookForItemStatus={lookForItemStatusDictionary[lookFor.id]}
            readonly={readonly}
            onItemClick={handleItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default PlanStrategyCreator;
