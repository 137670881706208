import React, { useEffect, useMemo } from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import {
  SurveyDemographic,
  SurveyReportRouteParams,
  SurveyReportTabEnum,
} from "../../../types";
import { generatePath } from "react-router";
import { useHistory } from "react-router-dom";
import ClusterTagsTab from "./cluster-tags-tab/ClusterTagsTab";
import ResponseRatesTab from "./ResponseRatesTab";
import { useIntl } from "react-intl";
import SurveyComparisonTab from "./SurveyComparisonTab";
import ReportInsightsTab from "./insights/ReportInsightsTab";
import SurveyReportTableWrapper from "../table/SurveyReportTableWrapper";
import { useDispatch, useSelector } from "react-redux";
import { changeReportsSearchBarString } from "../../../store/survey-reports/actions";
import useSchoolPlanDemographics from "../../../helpers/hooks/useSchoolPlanDemographics";
import ManageBookmarkDialog from "../table/dialogs/ManageBookmarkDialog";

type OwnProps = {
  districtId: number | undefined;
  schoolId: number | undefined;
  surveyDeploymentId: number | undefined;
  domainId: number | undefined;
  tabId: string | undefined;
  tagId: string | undefined;
  reportPagePath: string;
  routeParams: SurveyReportRouteParams;
};

type Props = OwnProps;

const RESPONSE_RATES_TAB_INDEX = 0;
const ELEMENTARY_STUDENTS_TAB_INDEX = 1;
const STUDENTS_TAB_INDEX = 2;
const SCHOOL_STAFF_TAB_INDEX = 3;
const PARENTS_TAB_INDEX = 4;
const COMPARISON_TAB_INDEX = 5;
const CLUSTERTAGS_TAB_INDEX = 6;
const INSIGHTS_TAB_INDEX = 7;

const SurveyReportTabs: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const translatedTabs = useMemo(() => {
    return Object.values(SurveyReportTabEnum)?.map((tab) =>
      intl.formatMessage({ id: `app.survey-report.tabs.titles.${tab}` })
    );
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    districtId,
    schoolId,
    surveyDeploymentId,
    domainId,
    tabId,
    tagId,

    reportPagePath,
    routeParams,
  } = props;

  const handleTabChange = (newTabId: SurveyReportTabEnum) => {
    const { tagId, domainId, ...params } = routeParams;

    if (newTabId === SurveyReportTabEnum.ClusterTags) {
      history.push(
        generatePath(reportPagePath, {
          ...params,
          tabId: newTabId,

          // only for plan
          workspace: "report",
        })
      );
      return;
    }

    history.push(
      generatePath(reportPagePath, {
        ...params,
        tabId: newTabId,

        // only for plan
        workspace: "report",
      })
    );
  };

  useEffect(() => {
    dispatch(changeReportsSearchBarString(""));
  }, [tabId]);

  const schools = useSelector((s) => s.schools.schools);
  const selectedSchool = useMemo(() => {
    return schoolId ? schools.find((s) => s.id == schoolId) : undefined;
  }, [schools, schoolId]);

  const {
    demographicDisplayed,
    demographics: availableDemographicsInSchool,
  } = useSchoolPlanDemographics({
    grades: selectedSchool?.grades,
  });

  const showReportingTagsTab = useSelector(
    (s) =>
      s.surveyReports.selectedSurveyDeployment?.survey.show_reporting_tags_tab
  );

  const isReportInsights = useSelector((s) => s.surveyReports.inReportInsights);

  return (
    <Tabs
      id="SurveyReportTabs"
      selectedTabId={tabId}
      onChange={handleTabChange}
      renderActiveTabPanelOnly
      className="tabs-wrap"
      large
    >
      <Tab
        id={SurveyReportTabEnum.ResponseRates}
        title={translatedTabs[RESPONSE_RATES_TAB_INDEX]}
        panel={
          <ResponseRatesTab
            schoolId={schoolId}
            surveyDeploymentId={surveyDeploymentId}
            domainId={domainId}
            availableDemographicsInSchool={availableDemographicsInSchool}
          />
        }
      />
      <Tab
        hidden={!demographicDisplayed[SurveyDemographic.ElementaryStudents]}
        id={SurveyReportTabEnum.ElementaryStudents}
        title={translatedTabs[ELEMENTARY_STUDENTS_TAB_INDEX]}
        panel={
          <>
            <SurveyReportTableWrapper
              demographic={SurveyDemographic.ElementaryStudents}
              surveyDeploymentId={surveyDeploymentId}
              isReportInsights={isReportInsights}
              domainId={domainId}
              schoolId={schoolId}
              tabId={tabId}
            />
            <ManageBookmarkDialog
              demographic={SurveyDemographic.ElementaryStudents}
              surveyDeploymentId={surveyDeploymentId}
              isReportInsights={isReportInsights}
              domainId={domainId}
              schoolId={schoolId}
              tabId={tabId}
            />
          </>
        }
      />
      <Tab
        hidden={!demographicDisplayed[SurveyDemographic.Students]}
        id={SurveyReportTabEnum.Students}
        title={translatedTabs[STUDENTS_TAB_INDEX]}
        panel={
          <>
            <SurveyReportTableWrapper
              demographic={SurveyDemographic.Students}
              surveyDeploymentId={surveyDeploymentId}
              domainId={domainId}
              isReportInsights={isReportInsights}
              schoolId={schoolId}
              tabId={tabId}
            />
            <ManageBookmarkDialog
              isReportInsights={isReportInsights}
              demographic={SurveyDemographic.Students}
              surveyDeploymentId={surveyDeploymentId}
              domainId={domainId}
              schoolId={schoolId}
              tabId={tabId}
            />
          </>
        }
      />
      <Tab
        id={SurveyReportTabEnum.SchoolStaff}
        title={translatedTabs[SCHOOL_STAFF_TAB_INDEX]}
        panel={
          <>
            <SurveyReportTableWrapper
              demographic={SurveyDemographic.SchoolStaff}
              surveyDeploymentId={surveyDeploymentId}
              domainId={domainId}
              isReportInsights={isReportInsights}
              schoolId={schoolId}
              tabId={tabId}
            />
            <ManageBookmarkDialog
              isReportInsights={isReportInsights}
              demographic={SurveyDemographic.SchoolStaff}
              surveyDeploymentId={surveyDeploymentId}
              domainId={domainId}
              schoolId={schoolId}
              tabId={tabId}
            />
          </>
        }
      />
      <Tab
        id={SurveyReportTabEnum.Parents}
        title={translatedTabs[PARENTS_TAB_INDEX]}
        panel={
          <>
            <SurveyReportTableWrapper
              demographic={SurveyDemographic.Parents}
              surveyDeploymentId={surveyDeploymentId}
              domainId={domainId}
              isReportInsights={isReportInsights}
              schoolId={schoolId}
              tabId={tabId}
            />
            <ManageBookmarkDialog
              isReportInsights={isReportInsights}
              demographic={SurveyDemographic.Parents}
              surveyDeploymentId={surveyDeploymentId}
              domainId={domainId}
              schoolId={schoolId}
              tabId={tabId}
            />
          </>
        }
      />
      <Tab
        id={SurveyReportTabEnum.Comparison}
        title={translatedTabs[COMPARISON_TAB_INDEX]}
        panel={
          <SurveyComparisonTab
            surveyDeploymentId={surveyDeploymentId}
            domainId={domainId}
            tagId={tagId}
          />
        }
      />
      <Tab
        id={SurveyReportTabEnum.ClusterTags}
        title={translatedTabs[CLUSTERTAGS_TAB_INDEX]}
        hidden={!showReportingTagsTab}
        panel={
          <ClusterTagsTab
            schoolId={schoolId}
            domainId={domainId}
            surveyDeploymentId={surveyDeploymentId}
            tagId={tagId}
            routeParams={routeParams}
            reportPagePath={reportPagePath}
          />
        }
      />
      <Tab
        id={SurveyReportTabEnum.Insights}
        title={translatedTabs[INSIGHTS_TAB_INDEX]}
        panel={
          <ReportInsightsTab
            availableDemographicsInSchool={availableDemographicsInSchool}
            surveyDeploymentId={surveyDeploymentId}
            schoolId={schoolId}
          />
        }
      />
    </Tabs>
  );
};

export default SurveyReportTabs;
