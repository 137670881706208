import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Card,
  Classes,
  Dialog,
  Icon,
  InputGroup,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getStrategies,
  hideAddPlanStrategyDialog,
  showAddStrategyToGoalDialog,
} from "../../../../store/strategies/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import NewStrategyButton from "../NewStrategyButton";
import { Domain, PlanStrategy, Strategy } from "../../../../types";
import Select from "react-select";
import StrategyItem from "./StrategyItem";
import { ValueType } from "react-select/src/types";
import { AppToaster } from "../../../../helpers/toaster";
import {
  addStrategyToPlan,
  getPlanStrategies,
  updatePlanStrategy,
} from "../../../../store/plan-strategies/actions";
import {
  filterStrategiesByDomainAndSearchString,
  isPlanStrategy,
  isStrategy,
} from "../../utils";
import { getDomains } from "../../../../store/domains/actions";
import { smallSelectorStyles } from "../../../common/selectors";
import StrategyParameters from "./StrategyParameters";
import StrategyDetails from "../details-strategy-dialog/StrategyDetails";

type OwnProps = {};

type Props = OwnProps;

const AddPlanStrategyDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    goal,
    show,
    domains: pSelectedDomains,
    strategy,
    onConfirm,
    showPlanStrategies,
    loadingSelector,
    errorSelector,
  } = useSelector((s) => s.strategies.dialogs.addPlanStrategyDialog);

  const handleClose = () => {
    dispatch(hideAddPlanStrategyDialog());
  };

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const strategies = useSelector((s) => s.strategies.strategies);
  const planStrategies = useSelector((s) => s.planStrategies.planStrategies);

  const domains = useSelector((s) => s.domains.domains);

  const [selectedDomains, setSelectedDomains] = useState<Domain[]>([]);

  const handleSelectedDomainChange = (value: ValueType<Domain, true>) => {
    setSelectedDomains((value as Domain[]) ?? []);
  };

  const [searchString, setSearchString] = useState<string>("");
  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchString(value);
  };

  const allStrategies = useMemo(() => {
    if (showPlanStrategies) {
      return [...strategies, ...planStrategies];
    }
    return [...strategies];
  }, [strategies, planStrategies, showPlanStrategies]);

  const filteredStrategies = useMemo(() => {
    return filterStrategiesByDomainAndSearchString(
      allStrategies,
      searchString,
      selectedDomains
    );
  }, [allStrategies, selectedDomains, searchString]);

  const handleDialogOpening = () => {
    setSelectedDomains(pSelectedDomains ?? []);
    setSelectedStrategy(strategy);
    dispatch(getDomains.request());
    dispatch(getStrategies.request());
    if (showPlanStrategies && activePlanId) {
      dispatch(getPlanStrategies.request(activePlanId));
    }
  };
  const loadingGetStrategies = useSelector((s) =>
    showPlanStrategies
      ? s.strategies.loading.getStrategies ||
      s.planStrategies.loading.getPlanStrategies
      : s.strategies.loading.getStrategies
  );
  const errorGetStrategies = useSelector((s) =>
    showPlanStrategies
      ? s.strategies.errors.getStrategies ||
      s.planStrategies.errors.getPlanStrategies
      : s.strategies.errors.getStrategies
  );
  useLoading({
    loading: loadingGetStrategies,
    error: errorGetStrategies,
  });

  const [selectedStrategy, setSelectedStrategy] =
    useState<Strategy | PlanStrategy | undefined>(undefined);

  const loading = useSelector(
    loadingSelector ?? ((s) => s.planStrategies.loading.addStrategyToPlan)
  );
  const error = useSelector(
    errorSelector ?? ((s) => s.planStrategies.errors.addStrategyToPlan)
  );
  const onSuccess = useCallback(() => {
    if (isStrategy(selectedStrategy)) {
      AppToaster.show({
        icon: "tick",
        intent: "success",
        message: intl.formatMessage(
          { id: "app.plan-strategies.plan-strategy-created" },
          { name: selectedStrategy?.name }
        ),
      });
    }
    dispatch(getStrategies.request());
    handleClose();
  }, [selectedStrategy]);
  useLoading({ loading: loading, error: error, onSuccess: onSuccess });

  const submitForm = (
    params: Pick<
      PlanStrategy,
      | "status"
      | "target_groups"
      | "grades"
      | "launch_date"
      | "locations"
      | "timeframe"
      | "general_approach"
    > & {
      leaders: number[];
    }
  ) => {
    if (activePlanId && selectedStrategy) {
      if (isStrategy(selectedStrategy)) {
        dispatch(
          addStrategyToPlan.request({
            planStrategy: {
              plan_id: activePlanId,
              strategy: selectedStrategy.id,
              ...params,
            },
            onSuccess: onConfirm,
          })
        );
      } else {
        dispatch(
          updatePlanStrategy.request({
            planStrategy: {
              planId: activePlanId,
              planStrategyId: selectedStrategy.id,
              strategy: selectedStrategy.strategy.id,
              ...params,
            },
            onSuccess: onConfirm,
          })
        );
      }
    }
  };

  const formId = useMemo(() => "strategy-parameters-form", []);

  return (
    <Dialog
      enforceFocus={false}
      className="w-2/3"
      isOpen={show}
      onOpening={handleDialogOpening}
      onClose={handleClose}
      icon={<Icon icon="briefcase" iconSize={24} />}
      title={intl.formatMessage({ id: "app.strategies.strategy-library" })}
    >
      <div className={Classes.DIALOG_BODY}>
        {loadingGetStrategies ? (
          <Spinner intent={"primary"} />
        ) : (
          <div>
            <div className="flex justify-between items-start mb-2">
              <Select
                isClearable
                isMulti
                styles={smallSelectorStyles}
                placeholder={intl.formatMessage({ id: "app.domains.filter" })}
                className="flex-1 mr-2"
                options={domains}
                value={selectedDomains}
                getOptionLabel={(domain) => domain.name}
                getOptionValue={(domain) => domain.id.toString()}
                onChange={handleSelectedDomainChange}
              />
              <InputGroup
                placeholder={intl.formatMessage({
                  id: "app.strategies.search",
                })}
                leftIcon="search"
                value={searchString}
                onChange={handleSearchStringChange}
              />
            </div>

            {!!allStrategies.length && (
              <div className="mb-2 flex justify-end">
                <NewStrategyButton onClick={handleClose} />
              </div>
            )}

            <div className="flex -m-2" style={{ maxHeight: "70vh" }}>
              <div className="flex-1 p-2 space-y-2 overflow-y-auto">
                {filteredStrategies.length ? (
                  filteredStrategies.map((strategy) => {
                    const isStrategyOfTypePlanStrategy =
                      isPlanStrategy(strategy);
                    const isActive = selectedStrategy
                      ? isPlanStrategy(selectedStrategy)
                        ? strategy?.id === selectedStrategy?.id &&
                        isStrategyOfTypePlanStrategy
                        : strategy?.id === selectedStrategy?.id &&
                        !isStrategyOfTypePlanStrategy
                      : false;

                    return (
                      <StrategyItem
                        key={strategy.id}
                        interactive
                        isActive={isActive}
                        strategy={strategy}
                        onClick={(newStrategy) =>
                          setSelectedStrategy((prevStrategy) =>
                            isActive ? undefined : newStrategy
                          )
                        }
                      />
                    );
                  })
                ) : searchString ? (
                  <NonIdealState
                    icon="search"
                    title={intl.formatMessage({
                      id: "app.strategies.strategy-library.empty",
                    })}
                    description={intl.formatMessage({
                      id: "app.tables.description.change-search-options",
                    })}
                    action={
                      <Button
                        intent="primary"
                        text={intl.formatMessage({
                          id: "app.tables.button-text.cleat-search-string",
                        })}
                        title={intl.formatMessage({
                          id: "app.tables.button-text.cleat-search-string",
                        })}
                        onClick={() => {
                          setSearchString("");
                        }}
                      />
                    }
                  />
                ) : (
                  <NonIdealState
                    icon="inbox"
                    title={intl.formatMessage({
                      id: "app.strategies.strategy-library.empty",
                    })}
                    description={intl.formatMessage({
                      id: "app.strategies.strategy-library.empty.tip",
                    })}
                    action={<NewStrategyButton onClick={handleClose} />}
                  />
                )}
              </div>
              {filteredStrategies.length ? (
                <div className="flex flex-col justify-between flex-1 m-2">
                  <Card className="flex-1 overflow-y-auto">
                    {selectedStrategy ? (
                      goal ? (
                        <StrategyDetails strategy={selectedStrategy} />
                      ) : (
                        <StrategyParameters
                          formId={formId}
                          onSubmit={submitForm}
                          strategy={selectedStrategy}
                        />
                      )
                    ) : (
                      <NonIdealState
                        className="flex-1 m-2"
                        icon="inbox"
                        title={intl.formatMessage({
                          id: "app.strategies.strategy-not-selected",
                        })}
                        description={intl.formatMessage({
                          id: "app.strategies.strategy-not-selected.info",
                        })}
                      />
                    )}
                  </Card>
                  {selectedStrategy && goal && (
                    <Button
                      className="mt-2"
                      intent="primary"
                      text={intl.formatMessage({
                        id: "app.goals.add-to-goal",
                      })}
                      title={intl.formatMessage({
                        id: "app.goals.add-to-goal",
                      })}
                      onClick={() => {
                        dispatch(
                          showAddStrategyToGoalDialog({
                            strategy: selectedStrategy,
                            goal: goal,
                            onConfirm: onConfirm,
                          })
                        );
                      }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between mt-4">
          <Button className="w-1/4" onClick={handleClose} type={"reset"}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>

          {!goal && (
            <Button
              type="submit"
              form={formId}
              className="w-1/4"
              intent="primary"
              loading={loading}
              disabled={!selectedStrategy}
            >
              {intl.formatMessage({
                id: "app.title.strategy-library.add-to-plan",
              })}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default AddPlanStrategyDialog;
