import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Meeting, MeetingType } from "../../types";
import { showMeetingDialog } from "../../store/meetings/actions";
import React, { useCallback } from "react";
import { Button } from "@blueprintjs/core";
import { getBackgroundColorByMeetingType } from "../../components/charts/utils";

export const useUpcomingActivityTag = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleMeetingClick = (meeting: Meeting) => {
    dispatch(showMeetingDialog({ meeting: meeting, viewOnly: true }));
  };

  const getTagBackgroundColor = useCallback((meeting: Meeting) => {
    return getBackgroundColorByMeetingType(meeting.meeting_type);
  }, []);

  const getTagButton = useCallback(
    (meeting: Meeting) => {
      const isMeetingEvent =
        meeting.meeting_type === MeetingType.TeamMeeting ||
        meeting.meeting_type === MeetingType.DistrictWideLeadershipMeeting;
      return (
        <Button
          text={intl.formatMessage({
            id: isMeetingEvent
              ? "app.titles.go-to-meeting"
              : "app.strategies.view-details",
          })}
          title={intl.formatMessage({
            id: isMeetingEvent
              ? "app.titles.go-to-meeting"
              : "app.strategies.view-details",
          })}
          intent="primary"
          onClick={() => handleMeetingClick(meeting)}
        />
      );
    },
    [handleMeetingClick]
  );

  return {
    getTagButton,
    getTagBackgroundColor,
  };
};

export default useUpcomingActivityTag;
