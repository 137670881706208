import React from "react";
import { AnchorButton } from "@blueprintjs/core";

type OwnProps = {
  text?: string;
  href: string;
  className?: string;
};

type Props = OwnProps;

const DownloadResourceButton: React.FC<Props> = ({
  text,
  href,
  className,
}: Props) => {
  return (
    <AnchorButton
      intent="primary"
      className={className}
      large
      text={text}
      icon="import"
      title={text}
      href={href}
      target="_blank"
    />
  );
};

export default DownloadResourceButton;
