import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Card,
  Classes,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { DatasetType, MeasurementType, PossibleValue } from "../../../../types";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import MeasurementBasedInput from "../measurement-based-input/MeasurementBasedInput";
import PossibleValuesForm from "./possible-values-form/PossibleValuesForm";
import { createCustomDataset } from "../../../../store/data-sets/actions";
import { AddCustomDatasetRequest } from "../../../../api/data-sets/types";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { useMeasurementOptions } from "../../../../helpers/hooks/useMeasurementOptions";

type OwnProps = {
  onClose: () => void;
};

type Props = OwnProps;

const CreateCustomDataSetDialogContent: FunctionComponent<Props> = (props) => {
  const { onClose } = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!date || !activePlanId) {
      return;
    }

    let req: AddCustomDatasetRequest = {
      planId: activePlanId,
      name: name,
      type: DatasetType.MeasurementTimeseries,
      measurement_type: measurementType,
      data_points: [{ date: date.toISOString(), value: value }],
    };

    if (measurementType === MeasurementType.Labels) {
      req = {
        ...req,
        possible_values: possibleValues.map(({ id, ...pv }) => pv),
      };
    }

    dispatch(createCustomDataset.request(req));
  };

  const [name, setName] = useState<string>("");
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };

  const {
    measurementTypes,
    setMeasurementType,
    measurementType,
    selectedMeasurementOption,
  } = useMeasurementOptions();

  const handleNumericTypeChange = (
    newValue: ValueType<{ label: string; value: MeasurementType }, false>
  ) => {
    if (newValue?.value) {
      setMeasurementType(newValue.value);
      setValue("");
      setPossibleValues([]);
    }
  };

  const [possibleValues, setPossibleValues] = useState<
    Pick<PossibleValue, "id" | "order" | "name">[]
  >([]);

  const [date, setDate] = useState<Date>();
  const [value, setValue] = useState<string | number>("");

  const loading = useSelector((s) => s.datasets.loading.createCustomDataset);
  const error = useSelector((s) => s.datasets.errors.createCustomDataset);
  useLoading({
    loading,
    error,
    onSuccess: onClose,
  });

  return (
    <>
      <form
        id="create-custom-data-set"
        className={Classes.DIALOG_BODY}
        onSubmit={handleFormSubmit}
      >
        <div className="mb-4">
          {intl.formatMessage({
            id: "app.datasets.create-custom-dataset.info",
          })}
        </div>

        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.name",
          })}
        >
          <InputGroup
            required
            value={name}
            onChange={handleNameChange}
            placeholder={intl.formatMessage({
              id: "app.forms.name.placeholder",
            })}
          />
        </FormGroup>

        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.type",
          })}
        >
          <Select
            options={measurementTypes}
            value={selectedMeasurementOption}
            onChange={handleNumericTypeChange}
          />
        </FormGroup>

        {measurementType === MeasurementType.Labels && (
          <PossibleValuesForm
            items={possibleValues}
            setItems={setPossibleValues}
          />
        )}

        <FormGroup
          label={intl.formatMessage({
            id: "app.data-sets.data-point.enter-first-point",
          })}
        >
          <Card className="p-2">
            <MeasurementBasedInput
              value={value}
              setValue={setValue}
              date={date}
              setDate={setDate}
              measurementType={measurementType}
              possibleValues={possibleValues}
            />
          </Card>
        </FormGroup>
      </form>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={onClose} disabled={loading}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            loading={loading}
            className="w-1/4"
            intent="primary"
            type="submit"
            form="create-custom-data-set"
          >
            {intl.formatMessage({
              id: "app.titles.save",
            })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateCustomDataSetDialogContent;
