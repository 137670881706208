import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { getUserTermsAgreement } from "../../../store/users/actions";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import UserTermsAgreementTable from "./UserTermsAgreementTable";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const DistrictTermsAgreement: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const loading = useSelector((s) => s.users.loading.getUserTermsAgreement);
  const error = useSelector((s) => s.users.errors.getUserTermsAgreement);
  useLoading({ loading, error });

  const usersAgreement = useSelector((s) => s.users.usersAgreement);
  useEffect(() => {
    dispatch(getUserTermsAgreement.request());
  }, []);

  if (loading) {
    return <Spinner intent="primary" className="mt-4" />;
  }

  if (!usersAgreement.length) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({
          id: "app.non-ideal-state.user-terms-agreement",
        })}
        description={intl.formatMessage({
          id: "app.non-ideal-state.user-terms-agreement.description",
        })}
      />
    );
  }

  return <UserTermsAgreementTable usersAgreement={usersAgreement} />;
};

export default DistrictTermsAgreement;
