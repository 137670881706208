import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { SurveyDemographic } from "../../../types";
import {
  Button,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
} from "@blueprintjs/core";
import { downloadStaticReport } from "../../../store/survey-reports/actions";
import { isSet, toArray } from "lodash";
import { exit } from "process";
import { getBooleanFromLocalStorage, LocalStorageKeys } from "../../../constants";

type OwnProps = {
  surveyDeploymentId: number;
  schoolId: number;
  selectedDemographic: SurveyDemographic;
  surveyName: string;
  disable:boolean;
};

type Props = OwnProps;

const DownloadReportButton: React.FC<Props> = ({
  surveyDeploymentId,
  schoolId,
  selectedDemographic,
  surveyName,
  disable
}: Props) => {
  const intl = useIntl();
  const TOTAL_RESPONDENTS_THRESHOLD = 10;
  const dispatch = useDispatch();

  const [isEnoughData, setisEnoughData] = useState(true);
  const [isNoData, setisNoData] = useState(true);

  const loading = useSelector(
    (s) => s.surveyReports.loading.downloadStaticReport
  );

  const isExistReport = useSelector(
    (s) => !!s.surveyReports.surveyReport.toString()
  );

  const items: any = useSelector((s) => s.surveyReports.surveyReport);

  useEffect(() => {
    if (items.length > 0 ){
      items.forEach((i: { total_respondents: number }) => {
        if (i.total_respondents < TOTAL_RESPONDENTS_THRESHOLD) {
          setisEnoughData(false);
          setisNoData(true);
          return false;
        }
        else {
          setisEnoughData(true);
          setisNoData(true);
          return true
        }
      });
    }
    else{
      setisEnoughData(false);
      setisNoData(false);
    }
  }, [items]);
  
  const handleDownloadReportClick = () => {
    const localFlagToHideSensitiveData: boolean =  getBooleanFromLocalStorage(
      LocalStorageKeys.SensitiveReportingDataHidden
    );

    dispatch(
      downloadStaticReport.request({
        surveyDeploymentId: surveyDeploymentId,
        schoolId: schoolId,
        demographic: selectedDemographic,
        surveyName: surveyName,
        hide_sensitive_reporting_data:localFlagToHideSensitiveData
      })
    );
  };

  return !isExistReport || (isExistReport && !isEnoughData) ? (
    <Popover
      popoverClassName={"w-20rem"}
      interactionKind={PopoverInteractionKind.HOVER}
      position={PopoverPosition.BOTTOM}
      content={
        <div className="p-2">
          {!isNoData && intl.formatMessage({
            id:
              "app.survey-report.breadcrumb.download-static-report.no.data",
          })}
          {isNoData && intl.formatMessage({
            id:
              "app.survey-report.breadcrumb.download-static-report.not.enogh.data",
          })}
        </div>
      }
    >
      <Button
        disabled={true}
        className="text-center"
        intent="primary"
        icon="download"
        text={intl.formatMessage({
          id: "app.survey-report.breadcrumb.download-static-report",
        })}
        onClick={handleDownloadReportClick}
        loading={loading}
      />
    </Popover>
  ) : (
  <> 
   { isExistReport && disable ? <Popover
      popoverClassName={"w-20rem"}
      interactionKind={PopoverInteractionKind.HOVER}
      position={PopoverPosition.BOTTOM}
      content={
        <div className="p-2">
          {intl.formatMessage({
            id:
              "app.survey-report.breadcrumb.download-static-report.not.enogh.data",
          })}
        </div>
      }
    >
      <Button
        disabled={true}
        className="text-center"
        intent="primary"
        icon="download"
        text={intl.formatMessage({
          id: "app.survey-report.breadcrumb.download-static-report",
        })}
        onClick={handleDownloadReportClick}
        loading={loading}
      />
    </Popover>
    :
    <Button
      disabled={ false }
      className="text-center"
      intent="primary"
      icon="download"
      title={intl.formatMessage({
        id: "app.survey-report.breadcrumb.download-static-report",
      })}
      text={intl.formatMessage({
        id: "app.survey-report.breadcrumb.download-static-report",
      })}
      onClick={handleDownloadReportClick}
      loading={loading}
    />
    }
    </>
  );
};

export default DownloadReportButton;
