import React from "react";
import { useIntl } from "react-intl";
import { Button, Classes, Dialog, Icon } from "@blueprintjs/core";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { useSelector } from "react-redux";

type OwnProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = OwnProps;

const ReviewDataDialog: React.FC<Props> = ({ show, setShow }: Props) => {
  const intl = useIntl();

  const handleClose = () => {
    setShow(false);
  };

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon="info-sign" iconSize={24} />}
      title={intl.formatMessage({
        id: "app.survey-report.info-dialog.title",
      })}
      style={{ width: "90%" }}
    >
      <div className={Classes.DIALOG_BODY}>
        {intl.formatMessage(
          { id: "app.survey-report.info-dialog.body" },
          {
            br: <br />,
            span: (chunk) => (
              <span {...(!isDistrictActivePlan && { className: "hidden" })}>
                {chunk}
              </span>
            ),
          }
        )}
      </div>
      <div className={`${Classes.DIALOG_FOOTER} mt-2`}>
        <div className="flex justify-center">
          <Button className="w-1/4" onClick={handleClose} intent="primary">
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ReviewDataDialog;
