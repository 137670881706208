import React from "react";
import { Domain } from "../../../../types";
import { useIntl } from "react-intl";
import {
  DOMAIN_DEFAULT_COLOR,
  DOMAIN_DEFAULT_COLOR_DISABLED,
  DOMAIN_TEXT_DEFAULT_COLOR_DISABLED,
} from "../../../../constants/constants";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";

type OwnProps = {
  domain?: Domain;
  dismissed: boolean;
  prioritized: boolean;
  hasPriorities: boolean;
  color?: string;
  selected_domains_name?: string;
  hideStatusText?: boolean;
};

type Props = OwnProps;

const DomainComparisonCollapseTitle: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { domain, dismissed, color, prioritized, selected_domains_name, hideStatusText } = props;

  return (
    <div className={"flex items-center flex-wrap justify-between "}>
      <div
        className="rounded px-1"
        style={{
          background: dismissed
            ? DOMAIN_DEFAULT_COLOR_DISABLED
            : color ?? DOMAIN_DEFAULT_COLOR,
          color: dismissed
            ? DOMAIN_TEXT_DEFAULT_COLOR_DISABLED
            : pickTextColorBasedOnBgColor(color ?? DOMAIN_DEFAULT_COLOR),
        }}
      >
        {selected_domains_name ? selected_domains_name : domain?.name}
      </div>
      <div className="px-1">
        {
          !hideStatusText &&
          <span className="priority-status-text">
            {" "}
            {(dismissed || prioritized) &&
              intl.formatMessage({
                id: dismissed
                  ? "app.team-plan.setup.needs-and-goals.not-prioritized"
                  : "app.team-plan.setup.needs-and-goals.prioritized",
              })}
          </span>
        }
      </div>
    </div>
  );
};

export default DomainComparisonCollapseTitle;
