import React, { FunctionComponent, useCallback } from "react";
import { Button } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { showDistrictDialog } from "../../../store/districts/actions";

type Props = {};

const SetupNewDistrictBtn: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleShowDistrictCreationDialog = useCallback(() => {
    dispatch(showDistrictDialog(undefined));
  }, []);

  return (
    <Button
      large
      className="capitalize ml-2"
      intent="primary"
      onClick={handleShowDistrictCreationDialog}
    >
      {intl.formatMessage({
        id: "app.districts.setup-new-district",
      })}
    </Button>
  );
};

export default SetupNewDistrictBtn;
