import React, { useMemo } from "react";
import { Button, Icon, NonIdealState } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { changeReportsSearchBarString } from "../../../../store/survey-reports/actions";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router";
import { useHistory } from "react-router-dom";
import DistrictSelector from "../../../survey-deployment/selectors/DistrictSelector";
import SchoolSelector from "../../../common/selectors/SchoolSelector";
import { School } from "../../../../types";
import SurveyDeploymentSelector from "../../../common/selectors/SurveyDeploymentSelector";
import useReportParams from "../../../../helpers/hooks/useReportParams";

type OwnProps = {
  hasFilters?: boolean;
};

type Props = OwnProps;

const SurveyReportNonIdealState = ({ hasFilters }: Props) => {
  const intl = useIntl();

  const schools = useSelector((s) => s.schools.schools as School[]);

  const surveyDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const handleClearFiltersClick = () => {
    dispatch(changeReportsSearchBarString(""));
  };

  const searchWords = useSelector((s) => s.surveyReports.searchBarString);

  const isFiltersSelected = useMemo(() => {
    return !!searchWords?.length;
  }, [searchWords]);

  const {
    reportPagePath,
    tabId: selectedTabId,
    districtId: selectedDistrictId,
    schoolId: selectedSchoolId,
    surveyDeploymentId: selectedSurveyDeploymentId,
  } = useReportParams();

  const handleDistrictChange = (districtId?: number) => {
    history.push(
      generatePath(reportPagePath, {
        districtId: districtId,
        tabId: selectedTabId,
        // only for plan
        workspace: "report",
      } as any)
    );
  };

  const handleSchoolChange = (selectedSchoolId?: number) => {
    history.push(
      generatePath(reportPagePath, {
        districtId: selectedDistrictId,
        schoolId: selectedSchoolId,
        tabId: selectedTabId,
        // only for plan
        workspace: "report",
      } as any)
    );
  };

  const handleSurveyDeploymentChange = (selectedSurveyDeployment?: number) => {
    history.push(
      generatePath(reportPagePath, {
        districtId: selectedDistrictId,
        schoolId: selectedSchoolId,
        surveyDeploymentId: selectedSurveyDeployment,
        tabId: selectedTabId,
        // only for plan
        workspace: "report",
      } as any)
    );
  };

  return (
    <NonIdealState
      className="pb-4"
      icon={
        <Icon
          icon={"info-sign"}
          iconSize={40}
          color={"#137cbd"}
        />
      }
      title={intl.formatMessage({
        id: selectedDistrictId
          ? selectedSchoolId
            ? selectedSurveyDeploymentId
              ? "app.tables.no-available-data"
              : "app.non-ideal-state.survey-reports.no-survey-deployment.title"
            : "app.non-ideal-state.survey-reports.no-school.title"
          : "app.non-ideal-state.survey-reports.no-district.title",
      })}
      description={intl.formatMessage({
        id: selectedDistrictId
          ? selectedSchoolId
            ? selectedSurveyDeploymentId
              ? " "
              : "app.non-ideal-state.survey-reports.no-survey-deployment.description"
            : "app.non-ideal-state.survey-reports.no-school.description"
          : "app.non-ideal-state.survey-reports.no-district.description",
      })}
      action={
        selectedDistrictId ? (
          selectedSchoolId ? (
            selectedSurveyDeploymentId ? (
              isFiltersSelected && hasFilters ? (
                <Button
                  text={intl.formatMessage({
                    id: "app.tables.button-text.clear-filters",
                  })}
                  intent="primary"
                  onClick={handleClearFiltersClick}
                />
              ) : undefined
            ) : (
              <SurveyDeploymentSelector
                surveyDeployments={surveyDeployments}
                onSurveyDeploymentChange={handleSurveyDeploymentChange}
              />
            )
          ) : (
            <SchoolSelector
              schools={schools}
              onSchoolChange={handleSchoolChange}
            />
          )
        ) : (
          <DistrictSelector
            className={"selector-min-width"}
            onDistrictChange={handleDistrictChange}
          />
        )
      }
    />
  );
};

export default SurveyReportNonIdealState;
