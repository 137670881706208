import { createAction, createAsyncAction } from "typesafe-actions";
import {
  UserEntity,
  UserInvite,
  UserModel,
  UserProfileExcludeBaseModel,
  UserTermsAgreement,
  LoginHistory,
  UserActionAuditResponse,
  PageSizeNumber,
  CountAudit,
} from "../../types";
import { InviteUsersRequest } from "../../api/users/types";

export const getUsers = createAsyncAction(
  "@users/GET_USERS_REQUEST",
  "@users/GET_USERS_SUCCESS",
  "@users/GET_USERS_FAILURE"
)<number | undefined, UserEntity, Error>();

export const getUserLoginHistory = createAsyncAction(
  "@users/GET_LOGIN_HISTORY_REQUEST",
  "@users/GET_LOGIN_HISTORY_SUCCESS",
  "@users/GET_LOGIN_HISTORY_FAILURE"
)<PageSizeNumber, LoginHistory, Error>();

export const getUserActionAudit = createAsyncAction(
  "@users/GET_USER_ACTION_AUDIT_REQUEST",
  "@users/GET_USER_ACTION_AUDIT_SUCCESS",
  "@users/GET_USER_ACTION_AUDIT_FAILURE"
)<PageSizeNumber, UserActionAuditResponse, Error>();

export const getAuditCounts = createAsyncAction(
  "@users/GET_DISTRICT_SUMMRY_COUNTS_REQUEST",
  "@users/GET_DISTRICT_SUMMRY_COUNTS_SUCCESS",
  "@users/GET_DISTRICT_SUMMRY_COUNTS_FAILURE"
)<void, CountAudit[], Error>();

export const downloadDistrictSummaryCounts = createAsyncAction(
  "@survey-reports/DOWNLOAD_DISTRICT_SUMMARY_COUNTS_REQUEST",
  "@survey-reports/DOWNLOAD_DISTRICT_SUMMARY_COUNTS_SUCCESS",
  "@survey-reports/DOWNLOAD_DISTRICT_SUMMARY_COUNTS_FAILURE",
)<void, Blob, Error>();

export const downloadSchoolSummaryCounts = createAsyncAction(
  "@survey-reports/DOWNLOAD_SCHOOL_SUMMARY_COUNTS_REQUEST",
  "@survey-reports/DOWNLOAD_SCHOOL_SUMMARY_COUNTS_SUCCESS",
  "@survey-reports/DOWNLOAD_SCHOOL_SUMMARY_COUNTS_FAILURE",
)<void, Blob, Error>();

export const getInviteByCode = createAsyncAction(
  "@users/GET_INVITE_BY_CODE_REQUEST",
  "@users/GET_INVITE_BY_CODE_SUCCESS",
  "@users/GET_INVITE_BY_CODE_FAILURE"
)<string, UserInvite, Error>();

export const changeSelectedUserInvite = createAction(
  "@users/CHANGE_SELECTED_USER_INVITE"
)<UserInvite | undefined>();

export const inviteUsers = createAsyncAction(
  "@users/INVITE_USERS_REQUEST",
  "@users/INVITE_USERS_SUCCESS",
  "@users/INVITE_USERS_FAILURE"
)<InviteUsersRequest, { success: UserInvite[]; failed: UserInvite[] }, Error>();

export const showAddUserToPlanDialog = createAction(
  "@users/SHOW_ADD_USER_TO_PLAN_DIALOG"
)<void>();

export const hideAddUserToPlanDialog = createAction(
  "@users/HIDE_ADD_USER_TO_PLAN_DIALOG"
)<void>();

export const showInviteUsersDialog = createAction(
  "@users/SHOW_INVITE_USERS_DIALOG"
)<{ dccPreselected?: boolean; onSuccess?: Function }>();

export const hideInviteUsersDialog = createAction(
  "@users/HIDE_INVITE_USERS_DIALOG"
)<void>();

export const showUserEditingDialog = createAction(
  "@users/SHOW_USER_EDITING_DIALOG"
)<UserModel | UserInvite>();

export const hideUserEditingDialog = createAction(
  "@users/HIDE_USER_EDITING_DIALOG"
)<void>();

export const editUser = createAsyncAction(
  "@users/EDIT_USER_REQUEST",
  "@users/EDIT_USER_SUCCESS",
  "@users/EDIT_USER_FAILURE"
)<
  {
    user: UserModel<number, UserProfileExcludeBaseModel<number>>;
    onSuccess: Function;
  },
  UserModel,
  Error
>();

export const editInvitedUser = createAsyncAction(
  "@users/EDIT_INVITED_USER_REQUEST",
  "@users/EDIT_INVITED_USER_SUCCESS",
  "@users/EDIT_INVITED_USER_FAILURE"
)<{ user: UserInvite; onSuccess?: Function }, UserInvite, Error>();

export const redispatchInvite = createAsyncAction(
  "@users/REDISPATCH_INVITE_REQUEST",
  "@users/REDISPATCH_INVITE_SUCCESS",
  "@users/REDISPATCH_INVITE_FAILURE"
)<number, void, Error>();

export const getUserTermsAgreement = createAsyncAction(
  "@users/GET_USER_TERMS_AGREEMENT_REQUEST",
  "@users/GET_USER_TERMS_AGREEMENT_SUCCESS",
  "@users/GET_USER_TERMS_AGREEMENT_FAILURE"
)<void, UserTermsAgreement[], Error>();

export const deleteUserTermsAgreement = createAsyncAction(
  "@users/DELETE_USER_TERMS_AGREEMENT_REQUEST",
  "@users/DELETE_USER_TERMS_AGREEMENT_SUCCESS",
  "@users/DELETE_USER_TERMS_AGREEMENT_FAILURE"
)<number, number, Error>();
