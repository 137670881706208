import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { Survey, SurveyDeploymentStateEnum, UserRole } from "../../../types";
import {
  Button,
  EditableText,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  PopoverPosition,
  Tag,
} from "@blueprintjs/core";
import { useLoading } from "../../../helpers/hooks/useLoading";
import {
  deleteSurvey,
  getSurveys,
  setSelectedSurvey,
  showSurveyUpsertDialog,
  updateSurvey,
} from "../../../store/surveys/actions";
import { AdminRoutes, AuthRoutes } from "../../../App";
import { generatePath, useHistory } from "react-router";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { AppToaster } from "../../../helpers/toaster";
import EntityTable from "../../common/EntityTable";
import { LocaleDisplayedValues } from "../../../store/UIState";
import { showSurveyDeploymentDialog } from "../../../store/surveys-deployment/actions";
import useRolesPermissions from "../../../helpers/hooks/useRolesPermissions";
import moment from "moment";

type OwnProps = {
  darkHeader?: boolean;
  isTeamPlanTable?: boolean;
  id?: string;
};

type Props = OwnProps;

const AdminSurveysTable: FunctionComponent<Props> = ({
  darkHeader,
  isTeamPlanTable,
  id,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const surveys = useSelector((s) => s.surveys.surveys);

  const [
    isExistSameSchoolYearSD,
    setIsExistSameSchoolYearSD,
  ] = useState<boolean>(false);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const me = useSelector((s) => s.auth.authenticatedUser);

  const surveysDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeployment
  );

  useEffect(() => {
    setIsExistSameSchoolYearSD(false);
    surveysDeployments.forEach((sd) => {
      if (sd.state !== SurveyDeploymentStateEnum.READY) {
        const surveyDeploymentYear = sd.deployment_name
          ? sd.deployment_name.split(" ")[0]
          : "";

        if (surveyDeploymentYear === getCurrentSession()) {
          setIsExistSameSchoolYearSD(true);
        }

        if (
          parseInt(surveyDeploymentYear.split("-")[0]) <
            parseInt(getCurrentSession().split("-")[0]) &&
          sd.state === SurveyDeploymentStateEnum.IN_PROGRESS &&
          Object.values(sd.responses).length
        ) {
          setIsExistSameSchoolYearSD(true);
        }
      }
    });
  }, [surveysDeployments]);

  const getCurrentSession = () => {
    const SURVEY_MONTH: number = 6;
    const date = moment(new Date());

    if (parseInt(moment(date).format("M")) > SURVEY_MONTH) {
      return `${moment(date).format("YYYY")}-${moment(date)
        .add(1, "years")
        .format("YYYY")}`;
    } else {
      return `${moment(date).subtract(1, "year").format("YYYY")}-${moment(
        date
      ).format("YYYY")}`;
    }
  };

  const isAdmins = useRolesPermissions([
    UserRole.Superadmin,
    UserRole.NJ_SCI_Leadership,
  ]);

  const loading = useSelector((s) => s.surveys.loading.getSurveys);
  const sdLoading = useSelector(
    (s) => s.surveysDeployment.loading.getSurveysDeployment
  );
  const error = useSelector((s) => s.surveys.errors.getSurveys);
  useLoading({ loading, error });

  const deleteSurveyLoading = useSelector(
    (s) => s.surveys.loading.deleteSurvey
  );
  const deleteSurveyError = useSelector((s) => s.surveys.errors.deleteSurvey);
  useLoading({
    loading: deleteSurveyLoading,
    error: deleteSurveyError,
    onSuccess: () => {
      AppToaster.show({
        message: (
          <p>
            {intl.formatMessage({ id: "app.surveys.survey-deleted-message" })}
          </p>
        ),
        icon: "tick",
        intent: "success",
      });
    },
  });

  const updateSurveyLoading = useSelector(
    (s) => s.surveys.loading.updateSurvey
  );

  useEffect(() => {
    dispatch(getSurveys.request());
  }, []);

  const showConfirmDeleteDialog = (surveyId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onSurveyDeleteConfirmed(surveyId);
        },
        show: true,
        intent: "danger",
        icon: "trash",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-survey",
        }),
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const onSurveyDeleteConfirmed = (surveyId: number) => {
    dispatch(hideConfirmDialog());
    dispatch(deleteSurvey.request(surveyId));
  };

  const handleCreateSurveyClick = () => {
    dispatch(showSurveyUpsertDialog());
  };

  const [searchString, setSearchString] = useState<string>("");

  const popoverContent = () => {
    return (
      <Menu>
        <MenuItem
          text={intl.formatMessage({ id: "app.surveys.popover.create-survey" })}
          icon="plus"
          className="px-3 capitalize"
          onClick={handleCreateSurveyClick}
        />
      </Menu>
    );
  };

  const showExistSDDialog = (survey: Survey) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(setSelectedSurvey(survey));
          dispatch(showSurveyDeploymentDialog());
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "primary",
        icon: "info-sign",
        text: intl.formatMessage({
          id: "app.survey-deployment-dialog.exist-same-school-year",
        }),
        confirmButtonText: intl.formatMessage({
          id: "app.survey-deployment-dialog.exist-same-school-year.yes",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.no",
        }),
      })
    );
  };

  const showSurveyDeploymentDialogWithSelectedSurvey = (survey: Survey) => {
    if (!sdLoading) {
      if (isExistSameSchoolYearSD) {
        showExistSDDialog(survey);
      } else {
        dispatch(setSelectedSurvey(survey));
        dispatch(showSurveyDeploymentDialog());
      }
    } else if (loading) {
      if (isExistSameSchoolYearSD) {
        showExistSDDialog(survey);
      } else {
        dispatch(setSelectedSurvey(survey));
        dispatch(showSurveyDeploymentDialog());
      }
    }
  };

  const handleEditSurveyQuestionsClick = (surveyId: number) => {
    isTeamPlanTable
      ? history.push(
          generatePath(AuthRoutes.PlanSurveyQuestions, {
            workspace: "survey-questions",
            id: surveyId,
          } as any)
        )
      : history.push(
          generatePath(AdminRoutes.EditSurveyQuestions, {
            id: surveyId,
          })
        );
  };

  const columns: any = useMemo(() => {
    const cols = [
      {
        Header: intl.formatMessage({ id: "app.common-table.columns.name" }),
        accessor: "name",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<Survey>) => {
          const handleConfirmSurveyNameChange = (value: string) => {
            if (original.name !== value) {
              dispatch(
                updateSurvey.request({
                  surveyId: original.id,
                  updatedSurvey: {
                    name: value,
                    available_languages: original.available_languages,
                    survey_categories_type: original.survey_categories_type,
                    demographic_data: original.demographic_data ?? [],
                    is_system_survey: !!original.creator,
                  },
                })
              );
            }
          };

          if (isTeamPlanTable) {
            return <p>{original.name}</p>;
          }

          return (
            <EditableText
              className="w-full"
              defaultValue={original.name}
              onConfirm={handleConfirmSurveyNameChange}
            />
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.languages.available-languages" }),
        accessor: "available_languages",
        minWidth: "20rem",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<Survey>) => {
          return (
            <div className="flex flex-wrap -m-1">
              {original.available_languages.map((locale) => (
                <Tag key={locale} intent="success" className="m-1" minimal>
                  {LocaleDisplayedValues[locale]}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.survey-category-type" }),
        accessor: "survey_categories_type",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<Survey>) => {
          return (
            <div className="flex flex-wrap justify-center -m-1">
              <Tag intent="success" className="m-1" minimal>
                {intl.formatMessage({
                  id: `app.filters.survey-categories-type.${original.survey_categories_type}`,
                })}
              </Tag>
            </div>
          );
        },
      },
      {
        id: "actions",
        width: "100%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<Survey>) => {
          return (
            <div className="flex -ml-1 justify-center">
              {((isTeamPlanTable && !!original.creator) ||
                !isTeamPlanTable) && (
                <>
                  <Button
                    title={intl.formatMessage({
                      id: "app.surveys.survey-dialog.edit-survey",
                    })}
                    icon="edit"
                    className="whitespace-no-wrap m-1"
                    intent="primary"
                    onClick={() => {
                      dispatch(showSurveyUpsertDialog(original));
                    }}
                  />
                  <Button
                    title={intl.formatMessage({
                      id: "app.surveys-table.columns.edit-survey-questions",
                    })}
                    icon="clipboard"
                    className="whitespace-no-wrap m-1"
                    intent="primary"
                    onClick={() => handleEditSurveyQuestionsClick(original.id)}
                  />
                  <Button
                    title={intl.formatMessage({
                      id: "app.surveys-table.columns.delete",
                    })}
                    icon="trash"
                    className="whitespace-no-wrap m-1"
                    intent="danger"
                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                      showConfirmDeleteDialog(original.id)
                    }
                  />
                </>
              )}
              {isTeamPlanTable &&
                (isExistSameSchoolYearSD ? (
                  <Popover
                    popoverClassName={"w-20rem"}
                    interactionKind={"hover"}
                    content={
                      <div className="p-2">
                        {intl.formatMessage({
                          id:
                            "app.surveys-table.columns.deploy-in-my-school-already-exist",
                        })}
                      </div>
                    }
                  >
                    <Button
                      text={intl.formatMessage({
                        id: "app.surveys-table.columns.deploy-in-my-school",
                      })}
                      className="whitespace-no-wrap m-1"
                      intent="primary"
                      disabled={true}
                    />
                  </Popover>
                ) : me?.profile?.district?.is_dummy_district ? (
                  <Button
                    text={intl.formatMessage({
                      id: "app.surveys-table.columns.deploy-in-my-school",
                    })}
                    title={intl.formatMessage({
                      id: "app.surveys-table.columns.deploy-in-my-school",
                    })}
                    className="whitespace-no-wrap m-1"
                    intent="primary"
                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                      showSurveyDeploymentDialogWithSelectedSurvey(original)
                    }
                  />
                ) : (
                  <Popover
                    popoverClassName={"w-20rem"}
                    interactionKind={"hover"}
                    content={
                      <div className="p-2">
                        {intl.formatMessage({
                          id:
                            "app.surveys-table.columns.deploy-in-my-school-disabled-temp",
                        })}
                      </div>
                    }
                  >
                    <Button
                      text={intl.formatMessage({
                        id: "app.surveys-table.columns.deploy-in-my-school",
                      })}
                      title={intl.formatMessage({
                        id: "app.surveys-table.columns.deploy-in-my-school",
                      })}
                      className="whitespace-no-wrap m-1"
                      intent="primary"
                      onClick={(e: React.MouseEvent<HTMLElement>) =>
                        showSurveyDeploymentDialogWithSelectedSurvey(original)
                      }
                    />
                  </Popover>
                ))}
            </div>
          );
        },
      },
    ];
    if (isTeamPlanTable) {
      return cols.filter(
        (c) =>
          c.accessor !== "available_languages" &&
          c.accessor !== "survey_categories_type"
      );
    }
    return cols;
  }, [
    isTeamPlanTable,
    surveysDeployments,
    activePlanId,
    isExistSameSchoolYearSD,
  ]);

  const surveysFilter = (survey: Survey, index: number, array: Survey[]) => {
    const searchStringLowerCase = searchString.trim().toLowerCase();

    if (!searchStringLowerCase) {
      return true;
    }

    return survey.name.toLowerCase().includes(searchStringLowerCase);
  };

  const filteredData = useMemo(() => surveys.filter(surveysFilter), [
    surveys,
    searchString,
  ]);

  const nonIdealState = useMemo(
    () => (
      <NonIdealState
        className="pb-4 pt-4 survey-non-ideal"
        title={intl.formatMessage({
          id: "app.survey.non-ideal-state.title",
        })}
        description={intl.formatMessage({
          id: "app.survey.non-ideal-state.description",
        })}
      />
    ),
    []
  );

  return (
    <EntityTable
      data={filteredData}
      columns={columns}
      searchString={searchString}
      setSearchString={setSearchString}
      loading={loading || deleteSurveyLoading || updateSurveyLoading}
      noDataComp={nonIdealState}
      darkHeader={darkHeader}
      id={id}
    >
      {isAdmins && (
        <Popover
          position={"bottom-left"}
          className={`flex-grow justify-start`}
          content={popoverContent()}
          minimal
        >
          <Button
            large
            minimal
            title={intl.formatMessage({ id: "app.titles.surveys" })}
            icon={"properties"}
            text={intl.formatMessage({ id: "app.titles.surveys" })}
            className="font-bold px-4"
          />
        </Popover>
      )}
    </EntityTable>
  );
};

export default AdminSurveysTable;