import {
  CollectedDemographicDataEnum,
  parentGroupByOrder,
  studentGroupByOrder,
  SurveyDemographic,
} from "../types";

export const sortDemographicData = (
  demographic?: string | SurveyDemographic
) => (key: CollectedDemographicDataEnum) => {
  switch (demographic) {
    case SurveyDemographic.Students:
    case SurveyDemographic.ElementaryStudents:
      return studentGroupByOrder[key];

    case SurveyDemographic.Parents:
      return parentGroupByOrder[key];
    default:
      return undefined;
  }
};
