import React, { FunctionComponent } from "react";
import { NonIdealState } from "@blueprintjs/core";
import SetupNewSchoolBtn from "../schools-selector-panel/SetupNewSchoolBtn";

type OwnProps = {
  hasAction?: boolean;
  className?: string;
};

type Props = OwnProps;

const NoSchools: FunctionComponent<Props> = (props) => {
  const { hasAction = true, className } = props;

  return (
    <div data-tour="schools-setup" data-cy={"schools-setup"}>
      <NonIdealState
        className={className}
        icon="office"
        action={hasAction ? <SetupNewSchoolBtn /> : undefined}
      />
    </div>
  );
};

export default NoSchools;
