import React, { useCallback, useMemo, useState } from "react";
import { UserTermsAgreement } from "../../../types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { Button, NonIdealState } from "@blueprintjs/core";
import EntityTable from "../../common/EntityTable";
import moment from "moment/moment";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { deleteUserTermsAgreement } from "../../../store/users/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../helpers/toaster";
import DownloadTermsAgreementCSV from "./DownloadTermsAgreementCSV";

type OwnProps = {
  usersAgreement: UserTermsAgreement[];
};

type Props = OwnProps;

const UserTermsAgreementTable: React.FC<Props> = ({
  usersAgreement,
}: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState<string>("");

  const callbackFilter = useCallback(
    (agreement: UserTermsAgreement) => {
      const searchStringLowerCase = searchString.trim().toLowerCase();

      if (!searchStringLowerCase) {
        return true;
      }
      const isDeactivated = agreement.is_active === false ;
      const deactivateUserName = isDeactivated?intl.formatMessage({ id: "app.titles.deactivated"}).toLowerCase() + ' '+ agreement.first_name.toLowerCase() : agreement.first_name.toLowerCase();

      return (
        agreement?.email?.toLowerCase()?.includes(searchStringLowerCase) ||
        agreement?.first_name?.toLowerCase()?.includes(searchStringLowerCase) ||
        deactivateUserName.toLowerCase()?.includes(searchStringLowerCase)   ||
        agreement?.last_name?.toLowerCase()?.includes(searchStringLowerCase) ||
        agreement?.district_name
          ?.toLowerCase()
          ?.includes(searchStringLowerCase) ||
        agreement?.school_role
          ?.toLowerCase()
          ?.includes(searchStringLowerCase) ||
        agreement?.date?.toLowerCase()?.includes(searchStringLowerCase)
      );
    },
    [searchString]
  );

  const filteredData = useMemo(() => {
    return usersAgreement.filter(callbackFilter);
  }, [usersAgreement, callbackFilter]);

  const getFullName = (entity: UserTermsAgreement) => {
    return entity
      ? `${entity?.first_name} ${entity?.last_name}`
      : intl.formatMessage({ id: "app.titles.not-applicable" });
  };

  const showConfirmDeleteDialog = (userAgreementId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteUserTermsAgreement.request(userAgreementId));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-user-term-agreement",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const deleteLoading = useSelector(
    (s) => s.users.loading.deleteUserTermsAgreement
  );
  const deleteError = useSelector(
    (s) => s.users.errors.deleteUserTermsAgreement
  );
  const onSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage(
        {
          id: "app.titles.delete-toaster-message",
        },
        { name: "User Terms Agreement" }
      ),
      icon: "tick",
      intent: "success",
    });
  }, []);
  useLoading({
    loading: deleteLoading,
    error: deleteError,
    onSuccess: onSuccess,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({ id: "app.common-table.columns.name" }),
        width: "100%",
        accessor: (originalRow: UserTermsAgreement) => {
          return <p>{getFullName(originalRow)}</p>;},
        Cell: ({ row: { original } }: CellProps<UserTermsAgreement>) => {
          return (   
          <div>
          <div className="font">
          {(original.is_active === false ?<i>{intl.formatMessage({ id: "app.titles.deactivated"})}</i> : ' ')}
          {" "}
          <p>{getFullName(original)}</p>
          </div>
          </div>
          )
        }, 
      },
      {
        Header: intl.formatMessage({ id: "app.titles.date-accepted" }),
        width: "100%",
        Cell: ({ row: { original } }: CellProps<UserTermsAgreement>) => {
          return (
            <p className="flex justify-center text-center whitespace-no-wrap">
              {moment(original.date).format("MM/DD/YYYY HH:mm")}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.users-table.columns.email" }),
        accessor: "email",
        width: "100%",
      },
      {
        Header: intl.formatMessage({ id: "app.forms.create-district.name" }),
        width: "100%",
        accessor: "district_name",
      },
      {
        Header: intl.formatMessage({ id: "app.titles.role" }),
        width: "100%",
        Cell: ({ row: { original } }: CellProps<UserTermsAgreement>) => {
          return (
            <p className="flex justify-center text-center">
              {original.custom_role_name ??
                intl.formatMessage(
                  { id: "app.roles" },
                  { role: original?.school_role }
                )}
            </p>
          );
        },
      },
      {
        id: "actions",
        width: "100%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<UserTermsAgreement>) => {
          return (
            <div className="flex justify-center -ml-1">
              <Button
                className="whitespace-no-wrap m-1"
                title={intl.formatMessage({
                  id: "app.titles.delete",
                })}
                icon="trash"
                intent="danger"
                onClick={() => showConfirmDeleteDialog(original.id)}
              />
            </div>
          );
        },
      },
    ];
  }, []);

  const noUsers = useMemo(
    () => (
      <NonIdealState
        icon="user"
        title={intl.formatMessage({
          id: "app.non-ideal-state.users-table.title",
        })}
        description={intl.formatMessage({
          id: "app.non-ideal-state.users-table.description",
        })}
      />
    ),
    []
  );

  return (
    <EntityTable
      data={filteredData}
      columns={columns}
      searchString={searchString}
      setSearchString={setSearchString}
      noDataComp={noUsers}
      loading={deleteLoading}
    >
      <div className="flex items-center ml-2">
        <DownloadTermsAgreementCSV usersAgreement={usersAgreement} />
      </div>
    </EntityTable>
  );
};

export default UserTermsAgreementTable;
