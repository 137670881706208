import { Domain, PlanStrategy, Strategy } from "../../../types";

export function isStrategy(
    strategy?: Strategy | PlanStrategy
): strategy is Strategy {
    if (!strategy) {
        return false;
    }

    return (
        (strategy as Strategy).name !== undefined &&
        (strategy as Strategy).domains !== undefined
    );
}

export const filterStrategiesByDomain = <
    T extends Strategy
>(
    defaultArray: T[],
    selectedDomains: any[] = []
): T[] => {
    const isDomainFilter = !!selectedDomains.length;

    if (isDomainFilter) {
        return defaultArray.filter((strategy) => {
            if (isStrategy(strategy)) {
                return strategy.domains?.some((d) =>
                    selectedDomains.some((sd) => sd === d?.name)
                );
            }
        });
    }

    return defaultArray;
};

export const filterStrategiesByDomainAndSearchString = <
    T extends PlanStrategy
>(
    defaultArray: T[],
    searchString: string,
  selectedDomains: any[] = [],
  isCustomStrategy?: boolean
): T[] => {
    const lowerCasedSearchString = searchString.toLowerCase();

  const isAllFilters = searchString !== "" && !!selectedDomains.length;
  const isDomainFilter = !!selectedDomains.length && searchString === "";
  const isStringFilter = !selectedDomains.length && searchString !== "";

  let filteredStrategies: T[] = defaultArray;
  if (isCustomStrategy) {
    filteredStrategies = defaultArray.filter(strategy => !strategy.strategy.is_global);
  }

  if (isAllFilters) {
    return filteredStrategies.filter((strategy) => {
            return (
                strategy.strategy.name
                    .toLowerCase()
                    .includes(lowerCasedSearchString) &&
                strategy.strategy.domains.some((d) =>
                    selectedDomains.some((sd) => sd === d?.name)
                )
            );
        });
    }

    if (isDomainFilter) {
      return filteredStrategies.filter((strategy) => {
            return strategy.strategy.domains.some((d) =>
                selectedDomains.some((sd) => sd === d?.name)
            );

        });
    }

    if (isStringFilter) {
      return filteredStrategies.filter((strategy) => {
            return strategy.strategy.name
                .toLowerCase()
                .includes(lowerCasedSearchString);
        });
    }

  if (isCustomStrategy) {
    return filteredStrategies.filter(strategy => !strategy.strategy.is_global);
  }

  return filteredStrategies;
};

export const filterResourceStrategiesByDomainAndSearchString = <
  T extends Strategy | (Strategy)
>(
  defaultArray: T[],
  searchString: string,
  selectedDomains: any[] = [],
  isCustomStrategy?: boolean
): T[] => {
  const lowerCasedSearchString = searchString.toLowerCase();

  const isAllFilters = searchString !== "" && !!selectedDomains.length;
  const isDomainFilter = !!selectedDomains.length && searchString === "";
  const isStringFilter = !selectedDomains.length && searchString !== "";

  let filteredStrategies: T[] = defaultArray;
  if (isCustomStrategy) {
    filteredStrategies = defaultArray.filter(strategy => !strategy.is_global);
  }

  if (isAllFilters) {
    return filteredStrategies.filter((strategy) => {
      if (isStrategy(strategy)) {
        return (
          strategy.name.toLowerCase().includes(lowerCasedSearchString) &&
          strategy.domains.some((d) =>
            selectedDomains.some((sd) => sd.id === d?.id)
          )
        );
      }
    });
  }

  if (isDomainFilter) {
    return filteredStrategies.filter((strategy) => {
      if (isStrategy(strategy)) {
        return strategy.domains?.some((d) =>
          selectedDomains.some((sd) => sd === d?.name)
        );
      }
    });
  }

  if (isStringFilter) {
    return filteredStrategies.filter((strategy) => {
      if (isStrategy(strategy)) {
        return strategy.name.toLowerCase().includes(lowerCasedSearchString);
      }
    });
  }

  return filteredStrategies;
};