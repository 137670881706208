import { useMemo } from "react";
import {
  Grade,
  StudentGrades,
  SurveyCustomDemographic,
  SurveyDemographic,
} from "../../types";

export const useSchoolPlanDemographics = ({ grades }: { grades?: Grade[] }) => {
  const { demographics, demographicDisplayed } = useMemo(() => {
    let items = Object.values(SurveyDemographic);
    let demographicDisplayed: {
      [key in SurveyDemographic]?: boolean;
    } = {};
    if (grades?.length) {
      [
        SurveyDemographic.Students,
        SurveyCustomDemographic.ElementaryStudents_3_5,
      ].forEach((studentDemographic) => {
        const hasStudentGrade = grades.some((g) =>
          StudentGrades[
            studentDemographic as
              | SurveyDemographic.Students
              | SurveyCustomDemographic.ElementaryStudents_3_5
          ].includes(g)
        );
        if (
          studentDemographic === SurveyCustomDemographic.ElementaryStudents_3_5
        )
          studentDemographic = SurveyDemographic.ElementaryStudents;
        if (!hasStudentGrade) {
          items = items.filter((d) => d !== studentDemographic);
        }
        demographicDisplayed[studentDemographic] = hasStudentGrade;
      });
    }

    return { demographics: items, demographicDisplayed: demographicDisplayed };
  }, [grades]);

  return {
    demographics,
    demographicDisplayed,
  };
};

export default useSchoolPlanDemographics;
