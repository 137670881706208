import { District } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import { AddDistrictRequest, UpdateDistrictRequest } from "./types";

export const getDistricts = (): Promise<District[]> => {
  return Axios.get(`${API_ROOT}/districts/`);
};

export const addDistrict = (request: AddDistrictRequest): Promise<District> => {
  return Axios.post(`${API_ROOT}/districts/`, request);
};

export const updateDistrict = ({
  id,
  ...rest
}: UpdateDistrictRequest): Promise<District> => {
  return Axios.put(`${API_ROOT}/districts/${id}/`, rest);
};

export const getDistrictById = (districtId: number): Promise<District> => {
  return Axios.get(`${API_ROOT}/districts/${districtId}/`);
};
