import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";
import { push } from "connected-react-router";
import { AdminRoutes, AuthRoutes, NonAuthRoutes } from "../../App";
import { generatePath } from "react-router";
import { userRoles } from "../../types";
import { DistrictTabs } from "../../components/pages/Districts";

export const loginEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.login.request)),
    switchMap((action) =>
      from(api.auth.login(action.payload)).pipe(
        mergeMap(() =>
          from([
            actions.login.success(),
            actions.getAuthenticatedUser.request(),
          ])
        ),
        catchError((error) => of(actions.login.failure(error)))
      )
    )
  );
};

export const logoutEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.logout.request)),
    switchMap((action) =>
      from(api.auth.logout()).pipe(
        mergeMap(() =>
          from([actions.logout.success(), push(NonAuthRoutes.Login)])
        ),
        catchError((error) => of(actions.logout.failure(error)))
      )
    )
  );
};

export const registerUserEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.registerUser.request)),
    switchMap((action) =>
      from(
        api.auth.registerUser(action.payload.user, action.payload.inviteCode)
      ).pipe(
        mergeMap((a) => {
          return from([
            actions.registerUser.success(a),
            push(
              generatePath(AuthRoutes.AppWelcomePage, {
                workspace: "welcome",
              } as any)
            ),
          ]);
        }),
        catchError((error) => of(actions.registerUser.failure(error)))
      )
    )
  );
};

export const resetPasswordEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.resetPassword.request)),
    switchMap((action) =>
      from(api.auth.resetPassword(action.payload)).pipe(
        mergeMap(() =>
          from([actions.resetPassword.success(), push(NonAuthRoutes.Login)])
        ),
        catchError((error) => of(actions.resetPassword.failure(error)))
      )
    )
  );
};

export const getAuthenticatedUserEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getAuthenticatedUser.request)),
    switchMap((action) =>
      from(api.auth.getAuthenticatedUser()).pipe(
        map(actions.getAuthenticatedUser.success),
        catchError((error) => of(actions.getAuthenticatedUser.failure(error)))
      )
    )
  );
};

export const uploadProfileImageEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.uploadProfileImage.request)),
    switchMap((action) =>
      from(api.auth.uploadProfileImage(action.payload)).pipe(
        map(actions.uploadProfileImage.success),
        catchError((error) => of(actions.uploadProfileImage.failure(error)))
      )
    )
  );
};

export const removeProfileImageEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.removeProfileImage.request)),
    switchMap((action) =>
      from(api.auth.removeProfileImage(action.payload)).pipe(
        map(actions.removeProfileImage.success),
        catchError((error) => of(actions.removeProfileImage.failure(error)))
      )
    )
  );
};

export const restorePasswordEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.restorePassword.request)),
    switchMap((action) =>
      from(
        api.auth.restorePassword(action.payload.request, action.payload.code)
      ).pipe(
        mergeMap((a) => {
          const userRole = a?.profile?.role;
          const isSuperadmins = userRoles.admins.includes(String(userRole));
          return from([
            actions.restorePassword.success(a),
            push(
              !isSuperadmins
                ? generatePath(AuthRoutes.YourPlan, {
                    workspace: "dashboard" as any,
                  } as any)
                : generatePath(AdminRoutes.Districts, {
                    adminTab: DistrictTabs.DISTRICTS,
                  })
            ),
          ]);
        }),
        catchError((error) => of(actions.restorePassword.failure(error)))
      )
    )
  );
};
