import React, { FunctionComponent } from 'react';
import './NoStrategies.scss'
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

type OwnProps = {
    inGoalView?: boolean;
};

type Props = OwnProps;

const NoStrategy: FunctionComponent<Props> = (props) => {
    const intl = useIntl();
    const { inGoalView } = props;
    return (
        <>
            <div className="notfound-message no-stragies-found">
                {!inGoalView ?
                    <div className="notfound-message_inner">
                        <h3>
                            {intl.formatMessage({ id: "app.title.strategies.no-strategies-found" })}
                        </h3>
                        <p>
                            {intl.formatMessage({ id: "app.titles.no-strategies-description" })}
                        </p>
                    </div>
                    : <div className="notfound-message_inner">
                        <h3>
                            {intl.formatMessage({ id: "app.title.strategies.no-strategies-found-goal-view" })}
                        </h3>
                        <p>
                            {intl.formatMessage({ id: "app.titles.no-strategies-description-goal-view" },
                            {
                                a: (chunks) => <span><u><Link to='/plan/goals/strategies'>{chunks}</Link></u></span>,
                            })}
                        </p>
                    </div>
                }
            </div>
        </>
    )
}


export default NoStrategy;