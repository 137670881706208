import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { GetDatasetsRequest } from "../../api/data-sets/types";
import { getBooleanFromLocalStorage, LocalStorageKeys } from "../../constants";
import usePrevProps from "../../helpers/hooks/usePrevProps";
import useReportParams from "../../helpers/hooks/useReportParams";
import { getUrlParams } from "../../helpers/url-utils";
import { getCurrentUserDistrictId } from "../../store/auth/selectors";
import { getDatasets } from "../../store/data-sets/actions";
import { getDomainById, setSelectedDomain } from "../../store/domains/actions";
import {
  getSurveyComparison,
  getSurveyReport,
  resetState,
} from "../../store/survey-reports/actions";
import {
  DatasetType,
  DomainQuestionsComparison,
  DomainStatistics,
  SurveyDemographic,
  SurveyReportTabEnum,
} from "../../types";
import BreadcrumbsSurveyReport from "../surveys/breadcrumb/BreadcrumbsSurveyReport";
import ReportHeaderCard from "../surveys/cards/ReportHeaderCard";
import DomainComparisonDialog from "../surveys/dialogs/DomainComparisonDialog";
import SurveyReportTabs from "../surveys/tab/SurveyReportTabs";
import { GROUP_BY_PARAM } from "../surveys/table/survey-report-table-head/SurveyReportTableHead";

type OwnProps = {};

type Props = OwnProps;

const SurveyReport: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();

  const {
    reportPagePath,
    tabId: selectedTabId,
    districtId: selectedDistrictId,
    schoolId: selectedSchoolId,
    surveyDeploymentId: selectedSurveyDeploymentId,
    domainId: selectedDomainId,
    tagId,
    routeParams,
  } = useReportParams();

  const selectedDemographic = useMemo(() => {
    if (
      Object.values(SurveyDemographic).includes(
        (selectedTabId as unknown) as SurveyDemographic
      )
    ) {
      return (selectedTabId as unknown) as SurveyDemographic;
    }
  }, [selectedTabId]);

  const grouping = useMemo(() => {
    return getUrlParams(GROUP_BY_PARAM, location.search);
  }, [location.search]);

  const surveyDeployment = useSelector(
    (s) => s.surveyReports.selectedSurveyDeployment
  );

  useEffect(() => {
    if (
      !selectedSchoolId ||
      !surveyDeployment?.survey?.id ||
      !surveyDeployment.survey.supports_bookmarks ||
      selectedTabId == SurveyReportTabEnum.Insights
    ) {
      return;
    }

    let req: GetDatasetsRequest = {
      school_id: selectedSchoolId,
      survey_id: surveyDeployment.survey.id,
      demographic: selectedDemographic,
      domain_id: selectedDomainId,
      type: DatasetType.Survey,
      group_by: grouping,
    };

    if (!grouping || !grouping.length) {
      req = { ...req, is_grouped: false };
    }

    dispatch(getDatasets.request(req));
  }, [
    selectedSchoolId,
    surveyDeployment?.survey?.id,
    selectedDemographic,
    selectedDomainId,
    grouping,
  ]);

  const userDistrictId = useSelector(getCurrentUserDistrictId);

  // const surveyFilters = useSelector((s) => s.surveyReports.filtersState);

  const selectedDomain = useSelector((s) => s.domains.selectedDomain);
  const domainLoading = useSelector((s) => s.domains.loading.getDomainById);

  const prevProps = usePrevProps({ domainId: selectedDomainId });

  useEffect(() => {
    if (prevProps?.domainId !== selectedDomainId) {
      if (selectedDomainId !== undefined) {
        dispatch(getDomainById.request(selectedDomainId));
      } else {
        dispatch(setSelectedDomain(undefined));
      }
    }
  }, [selectedDomainId]);

  useEffect(() => {
    if (selectedDistrictId === undefined && userDistrictId !== undefined) {
      history.push(
        generatePath(reportPagePath, {
          districtId: userDistrictId,
          tabId: SurveyReportTabEnum.ResponseRates,
          // only for plan
          workspace: "report",
        } as any)
      );
    }
  }, [selectedDistrictId, userDistrictId, reportPagePath]);

  const surveyReport = useSelector((s) => s.surveyReports.surveyReport);
  const reportLoading = useSelector(
    (s) => s.surveyReports.loading.getSurveyReport
  );

  useEffect(() => {
    const localFlagToHideSensitiveData = getBooleanFromLocalStorage(
      LocalStorageKeys.SensitiveReportingDataHidden
    );
    if (
      selectedDistrictId &&
      selectedSchoolId &&
      selectedSurveyDeploymentId &&
      selectedDemographic
    ) {
      dispatch(
        getSurveyReport.request({
          district_id: selectedDistrictId,
          school_id: selectedSchoolId,
          survey_deployment_id: selectedSurveyDeploymentId,
          domain_id: selectedDomainId,
          demographic: selectedDemographic,
          group_by: grouping,
          skip_distributions: grouping?.length ? true : undefined,
          hide_sensitive_reporting_data: localFlagToHideSensitiveData,
        })
      );
    }
  }, [
    selectedDistrictId,
    selectedSchoolId,
    selectedSurveyDeploymentId,
    selectedDemographic,
    selectedDomainId,

    // surveyFilters,

    grouping,
  ]);

  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);
  const comparisonLoading = useSelector(
    (s) => s.surveyReports.loading.getSurveyComparison
  );
  useEffect(() => {
    const localFlagToHideSensitiveData: boolean = getBooleanFromLocalStorage(
        LocalStorageKeys.SensitiveReportingDataHidden
      );
    
    if (
      selectedSchoolId &&
      selectedSurveyDeploymentId &&
      selectedTabId === SurveyReportTabEnum.Comparison
    ) {
      dispatch(
        getSurveyComparison.request({
          survey_deployment_id: [selectedSurveyDeploymentId],
          school_id: [selectedSchoolId],
          domain_id: selectedDomainId,
          hide_sensitive_reporting_data:localFlagToHideSensitiveData
        })
      );
    }
  }, [
    selectedDomainId,
    selectedSchoolId,
    selectedSurveyDeploymentId,
    selectedTabId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDomain(undefined));
      dispatch(resetState());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.survey-report" })}</title>
      </Helmet>

      <BreadcrumbsSurveyReport
        reportPagePath={reportPagePath}
        tabId={selectedTabId}
        districtId={selectedDistrictId}
        schoolId={selectedSchoolId}
        surveyDeploymentId={selectedSurveyDeploymentId}
        domainName={selectedDomain?.name}
      />

      <>
        {(domainLoading || reportLoading || comparisonLoading) &&
        selectedDomainId ? (
          <Spinner intent={"primary"} className={"mt-5"} />
        ) : (
          <>
            <ReportHeaderCard
              schoolId={selectedSchoolId}
              surveyDeploymentId={selectedSurveyDeploymentId}
              surveyReport={surveyReport as DomainStatistics}
              surveyComparison={surveyComparison as DomainQuestionsComparison}
              demographic={selectedDemographic}
              selectedDomain={selectedDomain}
              reportPagePath={reportPagePath}
              routeParams={routeParams}
              domainId={selectedDomainId}
              tabId={selectedTabId}
              tagId={tagId}
            />

            <SurveyReportTabs
              districtId={selectedDistrictId}
              schoolId={selectedSchoolId}
              surveyDeploymentId={selectedSurveyDeploymentId}
              domainId={selectedDomainId}
              tabId={selectedTabId}
              reportPagePath={reportPagePath}
              routeParams={routeParams}
              tagId={tagId}
            />
          </>
        )}
      </>

      <DomainComparisonDialog />
    </>
  );
};

export default SurveyReport;
