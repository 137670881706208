import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const ResponseDistributionStepContent: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  return (
    <div>
      <p>
        {intl.formatMessage({
          id: "app.survey-report.item-level-guide.responses",
        })}
      </p>
      <p className="pt-2">
        {intl.formatMessage({
          id: "app.survey-report.item-level-guide.responses.second-paragraph",
        })}
      </p>
    </div>
  );
};

export default ResponseDistributionStepContent;
