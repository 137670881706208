import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import SurveyForm from "../surveys/forms/SurveyForm";
import { useLocation, useParams } from "react-router";
import FinishSurveyPage from "./FinishSurveyPage";
import WelcomeSurveyPage from "./WelcomeSurveyPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getSurveyQuestionsByHash,
  resetSurveyCompletion,
} from "../../store/surveys/actions";
import { Icon, NonIdealState, Spinner } from "@blueprintjs/core";
import { SurveyDemographic, Timer } from "../../types";
import moment from "moment-timezone";
import { getLocalizedInstruction } from "../utils/utils";
import { AppToaster } from "../../helpers/toaster";
import usePrevProps from "../../helpers/hooks/usePrevProps";
import {
  getArrayFromSessionStorage,
  getBooleanFromSessionStorage,
  getNumberFromSessionStorage,
  getStringFromSessionStorage,
  resetSessionStorage,
  SessionStorageKeys,
} from "../../constants/session-storage";
import {
  filterLanguages,
  hideLanguageSelector,
  resetSurveyLanguage,
} from "../../store/UIState/actions";
import { Locale } from "../../store/UIState";
import { TEST_URL_ENDING } from "../../App";
import SurveyLanguagePage from "./SurveyLanguagePage";

type OwnProps = {};

type Props = OwnProps;

type RouterParams = {
  hash: string;
  school: string;
};

const EXPIRED_ERROR_MESSAGE = "Survey time has expired";

const Survey: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { hash, school } = useParams<RouterParams>();

  const TIME_OUT_HOURS: number = 24;
  const TIME_OUT_IN_MILLISECONDS: number = 1000 * 60 * 60 * TIME_OUT_HOURS;

  const dispatch = useDispatch();
  const location = useLocation();

  const isTesting = useMemo(() => {
    return location.pathname.endsWith(TEST_URL_ENDING);
  }, [location]);

  const [surveyTimer, setSurveyTimer] = useState<Timer[]>(
    getArrayFromSessionStorage(SessionStorageKeys.SurveyTimer)
  );

  const locale = useSelector((s) => s.UIState.surveyLocale);

  const [isLanguageSelected, setIsLanguageSelected] = useState<boolean>(
    getBooleanFromSessionStorage(SessionStorageKeys.IsSurveyLanguageSelected)
  );

  const [isSurveyCompleted, setSurveyCompleted] = useState<boolean>(
    getBooleanFromSessionStorage(SessionStorageKeys.IsSurveyCompleted)
  );
  const [showSurveyQuestions, setShowSurveyQuestions] = useState<boolean>(
    getNumberFromSessionStorage(SessionStorageKeys.SurveyCurrentPage) !==
      undefined
  );
  const lastSurveyHash = useMemo(
    () => getStringFromSessionStorage(SessionStorageKeys.LastSurveyHash),
    [hash]
  );
  const lastSurveySchool = useMemo(
    () => getStringFromSessionStorage(SessionStorageKeys.LastSurveySchoolHash),
    [school]
  );

  useEffect(() => {
    if (
      (!lastSurveyHash && !lastSurveySchool) ||
      (lastSurveyHash !== hash && lastSurveySchool !== school)
    ) {
      sessionStorage.setItem(
        SessionStorageKeys.LastSurveyHash,
        JSON.stringify(hash)
      );
      sessionStorage.setItem(
        SessionStorageKeys.LastSurveySchoolHash,
        JSON.stringify(school)
      );
    }
  }, [hash, school, lastSurveyHash, lastSurveySchool]);

  const completedSurvey = useSelector((s) => s.surveys.completedSurvey);
  useEffect(() => {
    fetchSurveyQuestions();
  }, [hash]);

  useEffect(() => {
    if (!!completedSurvey) {
      if (completedSurvey.session_hash !== undefined) {
        sessionStorage.setItem(
          SessionStorageKeys.SurveySessionHash,
          JSON.stringify(completedSurvey.session_hash)
        );
      }
    }
  }, [completedSurvey]);

  const fetchSurveyQuestions = useCallback(() => {
    dispatch(
      getSurveyQuestionsByHash.request({
        demographicHash: hash,
        schoolHash: school,
        sessionHash:
          getStringFromSessionStorage(SessionStorageKeys.SurveySessionHash) ??
          "",
        locale: locale,
        password: getStringFromSessionStorage(
          SessionStorageKeys.SurveyPassword
        ),
      })
    );
  }, [hash, school, locale]);

  const loading = useSelector(
    (s) => s.surveys.loading.getSurveyQuestionsByHash
  );
  const error = useSelector((s) => s.surveys.surveyPermissionsError);

  const expiredError = useSelector((s) => s.surveys.surveyExpiredError);

  const prevProps = usePrevProps({ permissionError: error, loading: loading });

  useEffect(() => {
    if (error === undefined && prevProps?.permissionError !== undefined) {
      handleStartSurveyClick(true);
    }
  }, [error, prevProps]);

  useEffect(() => {
    if (!!error) {
      dispatch(filterLanguages(error?.available_languages ?? []));
    }
    if (!!completedSurvey) {
      dispatch(filterLanguages(completedSurvey?.available_languages ?? []));
    }
    return () => {
      dispatch(filterLanguages(Object.values(Locale)));
    };
  }, [error, completedSurvey]);

  useEffect(() => {
    if (prevProps?.loading && !loading && !!error?.error) {
      handleInvalidPasswordError();
    }
  }, [loading, prevProps?.loading]);

  const handleInvalidPasswordError = () => {
    sessionStorage.setItem(
      SessionStorageKeys.SurveyPassword,
      ""
    );
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.surveys.forms.invalid-password",
      }),
      icon: "error",
      intent: "danger",
      timeout: 2000,
    });
  };

  const localizedDemographic = useMemo(() => {
    if (!!completedSurvey?.demographic) {
      return intl.formatMessage({
        id: `app.filters.respondent-type.${completedSurvey?.demographic}`,
      });
    } else if (!!error) {
      return intl.formatMessage({
        id: `app.filters.respondent-type.${error?.demographic}`,
      });
    }
  }, [completedSurvey, error]);

  const direction = useMemo(() => {
    return `${intl.formatMessage({
      id: "app.language.direction",
    })}`;
  }, []);

  useEffect(() => {
    const surveyTimerLength = surveyTimer?.length;
    if (surveyTimerLength > 0) {
      const lastTimer = surveyTimer[surveyTimerLength - 1];
      if (lastTimer?.page === "post-complete") {
        const currentTime = moment().format();
        const subtractedTime = moment(currentTime).subtract(30, "minutes");
        if (subtractedTime.isAfter(lastTimer.time_start)) {
          resetSurveySessionStorage();
          setSurveyTimer([]);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (lastSurveyHash !== hash || lastSurveySchool !== school) {
      dispatch(resetSurveyLanguage());
      resetSurveySessionStorage();
      setSurveyTimer([]);
      setSurveyCompleted(false);
      setShowSurveyQuestions(false);
    }
  }, [hash, school, lastSurveySchool, lastSurveyHash]);

  const resetSurveySessionStorage = useCallback(() => {
    sessionStorage.setItem(
      SessionStorageKeys.LastSurveyHash,
      JSON.stringify(hash)
    );
    sessionStorage.setItem(
      SessionStorageKeys.LastSurveySchoolHash,
      JSON.stringify(school)
    );
    sessionStorage.setItem(
      SessionStorageKeys.IsSurveyLanguageSelected,
      JSON.stringify(false)
    );
    resetSessionStorage(
      Array.from([
        SessionStorageKeys.SurveyCurrentPage,
        SessionStorageKeys.SurveyTimer,
        SessionStorageKeys.IsSurveyCompleted,
        SessionStorageKeys.SurveyPassword,
        SessionStorageKeys.MaxSurveyPageVisited,
        SessionStorageKeys.SurveySessionHash,
      ])
    );
  }, [hash, school]);

  const handleSurveyCompletedChange = (
    isSurveyCompleted: boolean,
    doResetSurveyLanguage: boolean = true
  ) => {
    if (!isSurveyCompleted) {
      dispatch(resetSurveyCompletion());
      if (doResetSurveyLanguage) dispatch(resetSurveyLanguage());
      resetSurveySessionStorage();
      setSurveyTimer([]);
      fetchSurveyQuestions();
    } else {
      sessionStorage.setItem(
        SessionStorageKeys.IsSurveyCompleted,
        JSON.stringify(true)
      );
    }
    resetSessionStorage([SessionStorageKeys.SurveySessionHash]);
    setShowSurveyQuestions(false);
    setSurveyCompleted(isSurveyCompleted);
    setIsLanguageSelected(false);
  };

  const handleStartSurveyClick = (showSurveyQuestions: boolean) => {
    if (showSurveyQuestions) {
      sessionStorage.setItem(
        SessionStorageKeys.SurveyCurrentPage,
        JSON.stringify(0)
      );
    }
    setShowSurveyQuestions(showSurveyQuestions);
  };

  const handleUpdateSurveyTimer = (timer: Timer) => {
    let updatedTimers: Timer[];
    if (timer.page === "intro" && surveyTimer?.length !== 0) {
      updatedTimers = surveyTimer.map((t) => {
        return t.page === "intro" ? timer : t;
      });
    } else {
      updatedTimers = [...surveyTimer, timer];
    }
    setSurveyTimer(updatedTimers);

    sessionStorage.setItem(
      SessionStorageKeys.SurveyTimer,
      JSON.stringify(updatedTimers)
    );
  };

  const showLanguageSelector = useMemo(() => {
    if (!!completedSurvey && !!completedSurvey.demographic) {
      return completedSurvey?.demographic !== SurveyDemographic.SchoolStaff;
    }
    if (!!error && !!error.demographic) {
      return error?.demographic !== SurveyDemographic.SchoolStaff;
    }
  }, [completedSurvey, error]);

  useEffect(() => {
    if (
      (showLanguageSelector !== undefined && !showLanguageSelector) ||
      isSurveyCompleted
    ) {
      dispatch(hideLanguageSelector(true));
    } else if (!showSurveyQuestions) {
      dispatch(hideLanguageSelector(undefined));
    }
  }, [showLanguageSelector, isSurveyCompleted]);

  const [surveyTimeOut, setSurveyTimeOut] = useState<boolean>(false);

  const getCurrentDateTime = () => {
    const time = moment().toISOString();
    return time;
  };

  const getSurveyTimeOutValue = () => {
    return sessionStorage.getItem(SessionStorageKeys.surveyTimeOut);
  };

  const getTimeOfTimeOutInMilliseconds = () => {
    const dateTime = getCurrentDateTime();
    let surveyDateTime = getSurveyTimeOutValue();
    if (surveyDateTime !== null)
      return (
        TIME_OUT_IN_MILLISECONDS -
        (new Date(dateTime).getTime() - new Date(surveyDateTime).getTime())
      );
    else return TIME_OUT_IN_MILLISECONDS;
  };

  //this is use for timeout survey
  useEffect(() => {
    let TimeOut: NodeJS.Timeout;
    if (
      completedSurvey &&
      [
        SurveyDemographic.ElementaryStudents,
        SurveyDemographic.Students,
      ].includes(completedSurvey?.demographic)
    ) {
      const surveyDateTime = getSurveyTimeOutValue();

      if (surveyDateTime === null)
        sessionStorage.setItem(
          SessionStorageKeys.surveyTimeOut,
          getCurrentDateTime() as string
        );
      TimeOut = setTimeout(() => {
        setSurveyTimeOut(true); // call the survey timeout alert
        handleSurveyCompletedChange(false, false); //reset the store and state
        sessionStorage.removeItem(SessionStorageKeys.surveyTimeOut);
      }, getTimeOfTimeOutInMilliseconds() as number);
    }
    return () => {
      clearInterval(TimeOut);
    };
  }, [completedSurvey?.demographic]);

  useEffect(() => {
    if (isTesting) {
      const nodeBody = document.body;
      nodeBody.classList.add("bordered-body");
      nodeBody.removeAttribute("height");
      document.documentElement.classList.add("html-height-auto");
      return () => {
        nodeBody.classList.remove("bordered-body");
        document.documentElement.classList.remove("html-height-auto");
      };
    }
  }, [isTesting]);

  const nonSchoolHours = useMemo(() => {
    const demographic = error?.demographic || completedSurvey?.demographic;

    const isStudent =
      demographic &&
      [
        SurveyDemographic.ElementaryStudents,
        SurveyDemographic.Students,
      ].includes(demographic);

    if (!isStudent) {
      return false;
    }

    let startTimeHrs = "06:00 am";
    let endTimeHrs = "06:00 pm";
    const startTime = moment(startTimeHrs, "HH:mm a"); // moment.tz(startTimeHrs, "HH:mm a", "EST");
    const endTime = moment(endTimeHrs, "HH:mm a"); //moment.tz(endTimeHrs, "HH:mm a", "EST");
    // return !moment.tz("EST").isBetween(startTime, endTime, undefined, "[)");
    return !moment().isBetween(startTime, endTime, undefined, "[)");
  }, [error?.demographic, completedSurvey?.demographic]);

  return (
    <div dir={direction}>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.surveys" })}</title>
      </Helmet>

      <div
        className={`p-4 bg-white border border-gray-400 rounded shadow relative`}
      >
        {expiredError && expiredError === EXPIRED_ERROR_MESSAGE ? (
          <NonIdealState
            icon={<Icon icon="warning-sign" intent="warning" iconSize={70} />}
            title={intl.formatMessage({
              id: "app.non-ideal-state.surveys-expired.title",
            })}
          />
        ) : nonSchoolHours && isLanguageSelected ? (
          <NonIdealState
            icon={<Icon icon="warning-sign" intent="warning" iconSize={70} />}
            title={intl.formatMessage({
              id: "app.non-ideal-state.surveys.out-of-school-time.title",
            })}
          />
        ) : surveyTimeOut ? (
          <NonIdealState
            icon={<Icon icon="warning-sign" intent="warning" iconSize={70} />}
            title={intl.formatMessage({
              id: "app.alert.surveys-expired.message",
            })}
          />
        ) : loading ? (
          <Spinner intent="primary" />
        ) : isSurveyCompleted ? (
          <FinishSurveyPage
            onReattemptSurveyClick={handleSurveyCompletedChange}
            onUpdateTimer={handleUpdateSurveyTimer}
            demographic={localizedDemographic}
            schoolName={completedSurvey?.school?.name}
            instruction={getLocalizedInstruction(
              locale,
              "post",
              completedSurvey
            )}
          />
        ) : showSurveyQuestions ? (
          completedSurvey?.survey_questions?.length ? (
            <div className="mb-32">
              <SurveyForm
                isTesting={isTesting}
                surveyTimer={surveyTimer}
                onSurveyFinished={handleSurveyCompletedChange}
                onUpdateTimer={handleUpdateSurveyTimer}
                demographic={localizedDemographic}
                showLanguageSelector={showLanguageSelector}
              />
            </div>
          ) : (
            <NonIdealState
              icon={<Icon icon="warning-sign" intent="warning" iconSize={70} />}
              title={intl.formatMessage({ id: "app.titles.this-survey-has-ended" })}
              description={intl.formatMessage({
                id: "app.non-ideal-state.surveys.questions.description",
              })}
            />
          )
        ) : !showLanguageSelector ||
          (showLanguageSelector && isLanguageSelected) ? (
          <WelcomeSurveyPage
            requestQuestions={fetchSurveyQuestions}
            onStartSurveyClick={handleStartSurveyClick}
            onUpdateTimer={handleUpdateSurveyTimer}
            demographic={localizedDemographic}
            schoolName={completedSurvey?.school?.name}
            instruction={getLocalizedInstruction(
              locale,
              "intro",
              completedSurvey
            )}
          />
        ) : (
          <SurveyLanguagePage
            demographic={localizedDemographic}
            schoolName={
              !!error ? error?.school_name : completedSurvey?.school?.name
            }
            onContinue={() => setIsLanguageSelected(true)}
          />
        )}
      </div>
    </div>
  );
};

export default Survey;
