import React, { FunctionComponent } from "react";
import logo from "../../static/images/nj-sci-survey-logo.png";

type OwnProps = {
  height?: number;
};

type Props = OwnProps;

const NjSciLogo: FunctionComponent<Props> = (props) => {
  const { height = 180 } = props;

  return (
    <div
      className={"flex justify-center p-4 rounded-md"}
      // style={{ background: "#1A2654" }}
    >
      <img
        src={logo}
        style={{ background: "rgba(255, 255, 255, 1.0)", height: height }}
        className="rounded-md"
        alt="NJ SCI Survey"
      />
    </div>
  );
};

export default NjSciLogo;
