import { useEffect, useState } from "react";

type Props = {
  loadingDeps: (boolean | undefined)[];
};

export const useLoadingWithDependencies = (props: Props) => {
  const { loadingDeps } = props;

  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const initLoadingDeps = loadingDeps.filter((dLoading) => dLoading != null);

    if (initLoadingDeps.length === loadingDeps.length) {
      setLoading(initLoadingDeps.some((loading) => loading));
    } else {
      setLoading((loading) => loading);
    }
  }, loadingDeps);

  return {
    loading: loading,
  };
};
