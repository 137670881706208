import { createAction, createAsyncAction } from "typesafe-actions";
import { District } from "../../types";
import {
  AddDistrictRequest,
  UpdateDistrictRequest,
} from "../../api/districts/types";

export const getDistricts = createAsyncAction(
  "@districts/GET_DISTRICTS_REQUEST",
  "@districts/GET_DISTRICTS_SUCCESS",
  "@districts/GET_DISTRICTS_FAILURE"
)<void, District[], Error>();

export const addDistrict = createAsyncAction(
  "@districts/ADD_SCHOOL_REQUEST",
  "@districts/ADD_SCHOOL_SUCCESS",
  "@districts/ADD_SCHOOL_FAILURE"
)<{ district: AddDistrictRequest; onSuccess?: Function }, District, Error>();

export const updateDistrict = createAsyncAction(
  "@districts/UPDATE_SCHOOL_REQUEST",
  "@districts/UPDATE_SCHOOL_SUCCESS",
  "@districts/UPDATE_SCHOOL_FAILURE"
)<UpdateDistrictRequest, District, Error>();

export const showDistrictDialog = createAction(
  "@districts/SHOW_DISTRICT_DIALOG"
)<undefined | District>();
export const hideDistrictDialog = createAction(
  "@districts/HIDE_DISTRICT_DIALOG"
)<void>();

export const getDistrictById = createAsyncAction(
  "@districts/GET_DISTRICT_BY_ID_REQUEST",
  "@districts/GET_DISTRICT_BY_ID_SUCCESS",
  "@districts/GET_DISTRICT_BY_ID_FAILURE"
)<number, District, Error>();
