/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { PlanStrategy, School, Strategy } from '../../../../../../types';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import StrategyDetails from "../../../strategic-view-details/StrategicDetails";
import StrategicResources from "../../../strategic-resources/StrategicResources";
import './StrategyItem.scss';
import { useDispatch, useSelector } from "react-redux";
import CoreComponent from "../../../core-components/CoreComponents";
import { Icon } from "@blueprintjs/core";
import { getDistrictById } from "../../../../../../store/districts/actions";
import { getPlanStrategies } from "../../../../../../store/plan-strategies/actions";
import StrategyDistrictActions from "../strategy-actions/StrategyDistrictActions";
import { isDistrictPlan } from "../../../../../../store/plans/selectors";

type OwnProps = {
  strategy: Strategy;
  inGoalView?: boolean;
  schools?: School[];
  planStrategy?: PlanStrategy[];
};

type Props = OwnProps;

const StrategyResourceItem: FunctionComponent<Props> = (props) => {
  const { strategy, inGoalView, schools } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const schoolName = useMemo(() => {
    return schools?.find(s => s.id === strategy.school)?.name
  }, [schools, strategy.school]);

  const districtName = useSelector((s) => s.districts.selectedDistrict?.name);

  const districtId = strategy.district;

  const isActiveDistrictPlan = useSelector(isDistrictPlan);

  useEffect(() => {
    if (districtId) {
      dispatch(getDistrictById.request(Number(districtId)));
    }
  }, [dispatch, districtId]);

  const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  useEffect(() => {
    if (activePlanId) {
      dispatch(getPlanStrategies.request(activePlanId));
    }
  }, [activePlanId, dispatch]);

  const ps: PlanStrategy[] = useMemo(() => {
    return isActiveDistrictPlan ? planStrategies.filter(ps => ps.strategy.id === strategy.id) : [];
  }, [isActiveDistrictPlan, planStrategies, strategy.id]);

  return (
    <>
      {!inGoalView ?  
          <li>
          <div className="strategies-content__list-head">
            <h3>
              {strategy.name}
              {strategy.school &&
                <span>{intl.formatMessage({ id: "app.custom-strategy" }, { school: schoolName })}</span>}
              {strategy.district &&
                <span className="custom-strategy-district">{intl.formatMessage({ id: "app.custom-strategy" }, { school: districtName })}</span>}
            </h3>
            {
              ps.length > 0 && (
                <ul>
                  <StrategyDistrictActions item={ps[0]} />
                </ul>
              )
            }
          </div>

          <div className="strategies-content__list-content">
              <div className="strategies-content__list-content--link">
                <ul>
                <li><CoreComponent strategy={strategy} isLibrary={true} /></li>
                <li><StrategyDetails strategy={strategy} /></li>
                <li><StrategicResources strategy={strategy} /> </li>
                {
                  !!strategy.pdf_resource &&
                  <li><a href={strategy.pdf_resource.attachment_url} target="_blank" rel="noreferrer">
                    <Icon iconSize={14} icon="import" /> {intl.formatMessage({
                      id: "app.resources-table.download",
                    })}</a></li>
                }
                </ul>
              </div>
              {
                (strategy.domains.length > 0) && (
                  <div className="strategies-content__list-content--domain">
                    <label>
                      {intl.formatMessage({
                        id: "app.strategies.linked-domains",
                      })}
                    </label>
                    <ul>
                      {
                        strategy.domains.map((domain: any) => {
                          return <li key={domain.id} style={{ borderLeft: `5px solid ${domain.color_hex}` }}>{domain.name}</li>;
                        })
                      }
                    </ul>
                  </div>
                )
              }
            </div>
        </li>
        : <>
          <Accordion>
            <AccordionSummary className="strategies-content__list-head" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <Typography>
                <div className="accordionbar__headaing">
                  <h3>{strategy.name}
                    {strategy.school &&
                      <span>{intl.formatMessage({ id: "app.custom-strategy" }, { school: schoolName })}</span>}
                  </h3>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="strategies-content__list-content">
                <div className="strategies-content__list-content--link">
                  <ul>
                    <li><CoreComponent strategy={strategy} /></li>
                    <li><StrategyDetails strategy={strategy} /></li>
                  </ul>
                </div>
                {
                  (!!strategy.domains.length) && (
                    <div className="strategies-content__list-content--domain">
                      <label>
                        {intl.formatMessage({
                          id: "app.strategies.linked-domains",
                        })}
                      </label>
                      <ul>
                        {
                          strategy.domains.map((domain: any) => {
                            return <li key={domain.id} style={{ borderLeft: `5px solid ${domain.color_hex}` }}>{domain.name}</li>;
                          })
                        }
                      </ul>
                    </div>
                  )
                }
              </div>
            </AccordionDetails>
          </Accordion>
        </>}
    </>
  )
};

export default StrategyResourceItem;
