import React from "react";
import { useIntl } from "react-intl";
import DetailsStrategyDialog from "../../strategies/details-strategy-dialog/DetailsStrategyDialog";
import DashboardCard from "../DashboardCard";
import StrategicPlanWidgetBody from "./StrategicPlanWidgetBody";

type OwnProps = {};

type Props = OwnProps;

const StrategicPlanWidget: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  return (
    <>
      <DashboardCard
        title={intl.formatMessage({ id: "app.titles.strategic-plan" })}
        body={<StrategicPlanWidgetBody />}
        centeredBody
      />
      <DetailsStrategyDialog />
    </>
  );
};

export default StrategicPlanWidget;
