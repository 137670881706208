import React, { useMemo } from "react";
import { Card, FormGroup } from "@blueprintjs/core";
import { Locale, LocaleDisplayedValues } from "../../store/UIState";
import { useDispatch, useSelector } from "react-redux";
import { changeLocale } from "../../store/UIState/actions";
import { useIntl } from "react-intl";
import { SessionStorageKeys } from "../../constants/session-storage";
import NjSciLogo from "../common/NjSciLogo";
import Slider, { Settings } from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowSize, { WindowSize } from "../../helpers/hooks/useWindowSize";
import CustomArrow from "../climate-overview-carousel/CustomArrow";

type OwnProps = {
  schoolName?: string;
  demographic?: string;
  availableLanguages?: Locale[];
  onContinue: () => void;
};

type Props = OwnProps;

const SurveyLanguagePage: React.FC<Props> = (props: Props) => {
  const { schoolName, demographic, onContinue, availableLanguages } = props;
  const windowSize = useWindowSize();
  const menuCollapsed = useSelector((s) => s.UIState.menuCollapsed);
  const languages = useSelector((s) => s.UIState.filteredLanguages);

  const slidesToShow = useMemo(() => {
    const itemsLength: number = languages.length;
    switch (windowSize) {
      case WindowSize.SM:
        return 1;
      case WindowSize.MD:
        return menuCollapsed ? 1 : itemsLength === 1 ? 1 : 2;
      default:
        return itemsLength < 5 ? itemsLength : 5;
    }
  }, [windowSize, menuCollapsed, languages]);

  const settings: Settings = useMemo(
    () => ({
      dots: slidesToShow == 1 ? false : true,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      prevArrow: <CustomArrow isPrev />,
      nextArrow: <CustomArrow />,
    }),
    []
  );

  const intl = useIntl();

  const selectedLocale = useSelector((s) => s.UIState.surveyLocale);

  const dispatch = useDispatch();

  const handleLocaleChange = (locale: Locale) => {
    sessionStorage.setItem(
      SessionStorageKeys.IsSurveyLanguageSelected,
      JSON.stringify(true)
    );
    dispatch(changeLocale({ locale: locale, storageType: "sessionStorage" }));
    onContinue();
  };

  const chunks = (a: any, size: number) =>
    Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
      a.slice(i * size, i * size + size)
    );

  return (
    <div>
      <NjSciLogo />
      <div className="flex justify-center slickSlider">
        <div className="slickSliderDiv mt-8 mb-8 sm:w-full sm:px-4 md:px-0 xl:w-2/3">
          <FormGroup
            label={
              <b>{`${intl.formatMessage({
                id: "app.titles.language",
              })}:`}</b>
            }
          >
            <Slider {...settings}>
              {languages.map((locale: Locale) => (
                <div className="justify-between">
                  <Card
                    key={locale}
                    interactive
                    tabIndex={-1}
                    role="button"
                    onClick={() => handleLocaleChange(locale)}
                    title={LocaleDisplayedValues[locale]}
                    style={{
                      width: "12rem",
                    }}
                    className={`bg-blue-600 text-white cursor-pointer text-center text-xl ${
                      locale === selectedLocale ? "font-bold" : undefined
                    }`}
                  >
                    <p className="whitespace-no-wrap">
                      {LocaleDisplayedValues[locale]}
                    </p>
                  </Card>
                </div>
              ))}
            </Slider>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default SurveyLanguagePage;
