import React, { FunctionComponent } from "react";
import { Classes, Dialog, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";

type OwnProps = {
  show: boolean;
  onClose: Function;
};

type Props = OwnProps;

const SchoolTeamResourcesInfoDialog: FunctionComponent<Props> = (props) => {
  const { show, onClose } = props;

  const intl = useIntl();

  const handleDialogClose = () => {
    onClose();
  };

  return (
    <Dialog
      isOpen={show}
      onClose={handleDialogClose}
      icon={<Icon icon="info-sign" iconSize={24} />}
      title={intl.formatMessage({ id: "app.team-plan.resources.dialog.title" })}
    >
      <div className={`${Classes.DIALOG_BODY} text-left`}>
        {intl.formatMessage(
          { id: "app.team-plan.leadership-team.resources.dialog.info" },
          {
            br: <br />,
          }
        )}
      </div>
    </Dialog>
  );
};
export default SchoolTeamResourcesInfoDialog;
