import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from "moment";

type OwnProps = {
  selectedAlignedStrategy: any;
  selectedDateRange: any;
  alignStrategies: any;
};
type Props = OwnProps;

const StrategyRespondentGroupGraph: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { selectedAlignedStrategy, selectedDateRange, alignStrategies } = props;

  let finalAlignedStrategies = [];
  finalAlignedStrategies = useMemo(() => {
    return alignStrategies.filter((as: { strategy: { name: string | any[]; }; }) => {
      return selectedAlignedStrategy.includes(as.strategy.name);
    })
  }, [alignStrategies, selectedAlignedStrategy]);

  const allImplementationsWithStrategyNames = finalAlignedStrategies.reduce((acc: any, strategy: any) => {
    return acc.concat(strategy.implementations.map((implementation: any) => ({
      ...implementation,
      strategyName: strategy.strategy.name,
    })));
  }, []);

  const sortedData = [...allImplementationsWithStrategyNames].sort(function (a, b):any {
    if (a === b || (a.strategyName === b.strategyName && a.date === b.date)) return 0;
    if (a.strategyName > b.strategyName) return 1;
    if (a.strategyName < b.strategyName) return -1;
    if (b.date > a.date) return 1;
    if (b.date < a.date) return -1;
  })

  let startDate = selectedDateRange[0];
  let endDate = selectedDateRange[selectedDateRange.length - 1];

  function convertDateFormat(inputDate: string) {
    // Split the inputDate into day, month, and year components
    var parts = inputDate.split('/');
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];

    // Create a new date string in MM/DD/YY format
    var newDate = month + '/' + day + '/' + year;

    return newDate;
  }

  var finalStartDate = convertDateFormat(startDate);
  var finalEndDate = convertDateFormat(endDate);

  // const filteredData = sortedData.filter(item => {
  //   const itemDate = new Date(item.date);
  //   return itemDate >= new Date(finalStartDate) && itemDate <= new Date(finalEndDate);
  // });

  const dateTemplate = (filteredData: { date: string | number | Date; }) => {
    const formattedDate = new Date(filteredData.date).toDateString();
    var date=moment(formattedDate).format('MM/DD/YY') //for Date format moment imported
    return <span>{date}</span>;
  };

  const scoreTemplate = (filteredData: { score: any; }) => {
    const formattedScore = Math.round((filteredData.score || 0) * 100);
    return <span>{formattedScore}%</span>;
  };

  return (
    <div className="monitoring_surveyTbl">
    <div className="blueHeader_tbl">
    {/* filteredData  was old value for Datatable binding */}
    <DataTable value={sortedData} tableStyle={{ minWidth: '' }}>
        <Column field="date" header={intl.formatMessage({ id: "app.titles.date" })} sortable style={{ width: '15%' }} body={dateTemplate}></Column>
        <Column field="strategyName" header={intl.formatMessage({ id: "app.titles.strategy-name" })} sortable style={{ width: '50%' }}></Column>
        <Column field="score" header={intl.formatMessage({ id: "app.titles.score" })} style={{ width: '40%' }} body={scoreTemplate}></Column>
      </DataTable>
    </div>

    </div>
  )
};

export default StrategyRespondentGroupGraph;