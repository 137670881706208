import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Icon } from "@blueprintjs/core";
import Tooltip from '@mui/material/Tooltip';
import './ViewLogsTable.scss';
import { PlanStrategy } from '../../../../../../types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { deletePlanStrategyImplementation, updatePlanStrategy } from '../../../../../../store/plan-strategies/actions';
import { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import UpdateImplementation from '../../update-implementation/UpdateImplementation';

type OwnProps = {
  planstrategy: PlanStrategy;
  activePlan?: number;
};
type Props = OwnProps;

const ViewLogsTable: React.FC<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { planstrategy, activePlan } = props;
  const [open, setOpen] = useState(false);
  const [implementationId, setImplementationId] = useState<number | undefined>(undefined);

  const updatedPlanStrategies = useSelector((s) => s.planStrategies.planStrategies);

  const planStrategy = useMemo(() => {
    const planS = updatedPlanStrategies.find(ps => ps.id === planstrategy.id);
    return planS || planstrategy;

  }, [updatedPlanStrategies, planstrategy]);

  const showConfirmDelete = (implementation: number) => () => {
    setImplementationId(implementation);
    handleClickOpen();
  };

  const handleDelete = () => {
    if (implementationId && activePlan) {
      dispatch(
        deletePlanStrategyImplementation.request({
          plan_strategy_id: planstrategy.id,
          implementation: implementationId,
        })
      );

      dispatch(
        updatePlanStrategy.request({
          planStrategy: {
            planId: activePlan,
            strategy: planstrategy.strategy.id,
            planStrategyId: planstrategy.id
          }
        })
      );
    }

    setImplementationId(undefined);
    handleClose();
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const items = useMemo(() => {
    let implementations = planstrategy.implementations;

    const sortedData = [...implementations].sort((a, b) => (new Date(b.date) as any) - (new Date(a.date) as any));

    return {
      sortedData
    };
  }, [planstrategy.implementations]);

 
  return (
    <>
      <p>({planstrategy.implementations.length}) {intl.formatMessage({id:"app.titles.implementation-logs"})}</p>
      <TableContainer sx={{ maxHeight: 170 }} component={Paper} className="viewLogs__table">
      <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="View Logs Table">
        <TableHead>
          <TableRow>
              <TableCell>{intl.formatMessage({ id: "app.titles.date" })}</TableCell>
              <TableCell>{intl.formatMessage({ id: "app.titles.score" })}</TableCell>
              <TableCell align="center">{intl.formatMessage({ id: "app.titles.actions" })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            !!items.sortedData.length && items.sortedData.map((implementation) => {
              return (<TableRow>
                <TableCell>{moment(implementation.date).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{Math.round((implementation.score || 0) * 100)}&#37;</TableCell>
                <TableCell align="center">
                  {/* <UpdateImplementation planstrategy={planStrategy} activePlan={activePlan} handleClose={handleClose} implementationId={implementation.id} /> */}
                  <Tooltip title="Delete" arrow>
                    <button aria-label="Delete" onClick={showConfirmDelete(implementation.id)}><Icon icon="trash" iconSize={14} /></button>
                  </Tooltip>
                </TableCell>
              </TableRow>)
            })
          }
        </TableBody>
      </Table>
      </TableContainer>

      <Dialog
        className="medium-popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="cancel">
          <button onClick={handleClose}>
            <Icon icon="cross" iconSize={18} />
          </button>
        </div>
        <Icon icon="trash" iconSize={30} />
        <h3>
          {intl.formatMessage({ id: "app.logs.log.dialog.title" })}
        </h3>
        <div>
          {intl.formatMessage(
            { id: "app.logs.log.dialog.text" },
            { b: (chunks) => <b>{chunks}</b>, br: <br /> }
          )}
        </div>
        <div className="button-group">
          <button onClick={handleClose} className="stroke-btn">
            {intl.formatMessage({ id: "app.titles.cancel" })}
          </button>
          <button
            onClick={() => handleDelete()}
            className="nj-btn"
          >
            {intl.formatMessage({
              id: "app.titles.delete",
            })}
          </button>
        </div>
      </Dialog>
    </>
  );
}

export default ViewLogsTable;