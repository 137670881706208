import {
  Button,
  Callout,
  Card,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  HTMLTable,
  InputGroup,
} from "@blueprintjs/core";
import _ from "lodash";
import moment from "moment";
import { ParseResult } from "papaparse";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import { AddCustomDatasetRequest } from "../../../../api/data-sets/types";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { useMeasurementOptions } from "../../../../helpers/hooks/useMeasurementOptions";
import { createCustomDataset } from "../../../../store/data-sets/actions";
import {
  DatasetType,
  MeasurementType,
  PossibleValue,
  SelectorOptionType,
} from "../../../../types";
import PossibleValuesForm from "../create-custom-data-set-dialog/possible-values-form/PossibleValuesForm";
import PreviewTable from "./PreviewTable";

type OwnProps = {
  parseResult: ParseResult<any>;
  onClose: () => void;
};

type Props = OwnProps;

const CreateDatasetFromFileDialogContent: FunctionComponent<Props> = (
  props
) => {
  const { parseResult, onClose } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const headers: SelectorOptionType<string>[] = useMemo(() => {
    return (
      parseResult.meta.fields
        ?.filter((v) => !!v)
        .map((v) => ({ label: v, value: v })) ?? []
    );
  }, [parseResult]);
  const [selectedValueHeader, setSelectedValueHeader] = useState<
    SelectorOptionType<string> | undefined
  >(undefined);
  const [selectedDateHeader, setSelectedDateHeader] = useState<
    SelectorOptionType<string> | undefined
  >(undefined);

  const {
    measurementTypes,
    measurementType,
    setMeasurementType,
    selectedMeasurementOption,
  } = useMeasurementOptions();

  const [possibleValues, setPossibleValues] = useState<
    Pick<PossibleValue, "id" | "order" | "name">[]
  >([]);

  const handleMeasurementTypeChange = (
    newValue: ValueType<{ label: string; value: MeasurementType }, false>
  ) => {
    if (newValue?.value) {
      setMeasurementType(newValue.value);
    }
  };

  useEffect(() => {
    if (selectedValueHeader) {
      const firstValue = parseResult.data[0][selectedValueHeader.value];
      if (_.isNumber(firstValue)) {
        setMeasurementType(MeasurementType.Number);
      } else {
        setMeasurementType(MeasurementType.Labels);
      }
    }
  }, [selectedValueHeader, parseResult.data]);

  useEffect(() => {
    switch (measurementType) {
      case MeasurementType.Labels: {
        if (selectedValueHeader) {
          const options = _.chain(parseResult.data)
            .map((v) => v[selectedValueHeader.value])
            .uniq()
            .map((v, index) => ({
              id: -1 * index,
              order: index,
              name: v,
            }))
            .value();
          setPossibleValues(options);
          setDataPoints(
            parseResult.data.map((dp, index) => {
              const date = selectedDateHeader?.value
                ? dp[selectedDateHeader.value]
                : undefined;

              const dateMoment = date ? moment(date) : undefined;

              return {
                originalIndex: index,
                date: dateMoment
                  ? dateMoment.isValid()
                    ? dateMoment.toDate()
                    : new Date(undefined as any)
                  : undefined,
                value: options.find(
                  (o) => o.name == dp[selectedValueHeader.value]
                )?.order!,
              };
            })
          );
        }
        break;
      }
      default: {
        if (selectedValueHeader) {
          setDataPoints(
            parseResult.data.map((dp, index) => {
              const date = selectedDateHeader?.value
                ? dp[selectedDateHeader.value]
                : undefined;

              const dateMoment = date ? moment(date) : undefined;

              return {
                originalIndex: index,
                date: dateMoment
                  ? dateMoment.isValid()
                    ? dateMoment.toDate()
                    : new Date(undefined as any)
                  : undefined,
                value: dp[selectedValueHeader.value],
              };
            })
          );
        } else {
          setDataPoints([]);
        }
        setPossibleValues([]);
      }
    }
  }, [measurementType, selectedValueHeader?.value]);

  const [name, setName] = useState<string>("");
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };

  const [
    selectDatasetNameFromFirstRow,
    setSelectDatasetNameFromFirstRow,
  ] = useState<boolean>(true);

  useEffect(() => {
    if (selectDatasetNameFromFirstRow && selectedValueHeader?.value) {
      setName(selectedValueHeader.value);
    } else {
      setName("");
    }
  }, [selectDatasetNameFromFirstRow, selectedValueHeader?.value]);

  const loading = useSelector((s) => s.datasets.loading.createCustomDataset);
  const error = useSelector((s) => s.datasets.errors.createCustomDataset);
  useLoading({
    loading,
    error,
    onSuccess: onClose,
  });

  const formId = useMemo(() => "create-dataset-from-file-form", []);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const [dataPoints, setDataPoints] = useState<
    { value: string | number; date?: Date; originalIndex: number }[]
  >([]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!activePlanId) {
      return;
    }

    // const dPWithMissingData = filteredDataPoints.findIndex(
    //   (dP) => dP.date == null || dP.value == null || dP.value === ""
    // );
    //
    // const currentTarget = e.currentTarget;
    // if (dPWithMissingData !== -1) {
    //   setScrollToIndex(dPWithMissingData);
    //   setTimeout(() => {
    //     currentTarget?.reportValidity();
    //     setScrollToIndex(undefined);
    //   }, 500);
    //   return;
    // }

    let req: AddCustomDatasetRequest = {
      planId: activePlanId,
      name: name,
      type: DatasetType.MeasurementTimeseries,
      measurement_type: measurementType,
      data_points: filteredDataPoints.map((v) => ({
        ...v,
        date: v.date?.toISOString()!,
      })),
    };

    if (measurementType === MeasurementType.Labels) {
      req = {
        ...req,
        possible_values: possibleValues.map(({ id, ...pv }) => pv),
      };
    }

    dispatch(createCustomDataset.request(req));
  };

  useEffect(() => {
    if (selectedDateHeader) {
      setDataPoints((dataPoints) =>
        dataPoints.map((dp) => {
          const date =
            parseResult.data[dp.originalIndex][selectedDateHeader.value];
          const dateMoment = date ? moment(date) : undefined;
          return {
            ...dp,
            date: dateMoment
              ? dateMoment.isValid()
                ? dateMoment.toDate()
                : new Date(undefined as any)
              : undefined,
          };
        })
      );
    }
  }, [selectedDateHeader]);

  const dateHeaders = useMemo(() => {
    if (selectedValueHeader) {
      return headers.filter((h) => h.value !== selectedValueHeader.value);
    }
    return headers;
  }, [headers, selectedValueHeader]);

  const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(
    undefined
  );

  const filteredDataPoints = useMemo(() => {
    return dataPoints.filter((dp) => {
      let valid: boolean = true;
      switch (measurementType) {
        case MeasurementType.Number:
        case MeasurementType.Percentage: {
          valid =
            (!dp.value || _.chain(dp.value).toNumber().isNumber().value()) &&
            (!dp.date || moment(dp.date).isValid());
          break;
        }
        case MeasurementType.Ratio: {
          valid =
            (!dp.value ||
              !!dp.value.toString().match("([+-]?d+(.d+)?):([+-]?d+(.d+)?)")) &&
            (!dp.date || moment(dp.date).isValid());
          break;
        }

        default: {
          // valid = !dp.value || moment(dp.date).isValid();
        }
      }
      return valid;
    });
  }, [dataPoints, measurementType]);

  const showPreview = useMemo(() => {
    return !!(
      selectedValueHeader &&
      selectedDateHeader &&
      filteredDataPoints.length
    );
  }, [filteredDataPoints.length, selectedDateHeader, selectedValueHeader]);

  const invalidDataPoints = useMemo(() => {
    return _.differenceBy(
      dataPoints,
      filteredDataPoints,
      (e) => e.originalIndex
    );
  }, [filteredDataPoints, dataPoints]);

  const [showInvalidDataOverlay, setShowOverlayDataOverlay] = useState<boolean>(
    false
  );

  return (
    <>
      <form
        id={formId}
        className={Classes.DIALOG_BODY}
        onSubmit={handleFormSubmit}
      >
        <FormGroup label={intl.formatMessage({ id: "app.titles.data-column" })}>
          <Select
            options={headers}
            value={selectedValueHeader}
            onChange={(v) =>
              setSelectedValueHeader(
                v as SelectorOptionType<string> | undefined
              )
            }
          />
        </FormGroup>

        <FormGroup label={intl.formatMessage({ id: "app.titles.date-column" })}>
          <Select
            isDisabled={!selectedValueHeader}
            options={dateHeaders}
            value={selectedDateHeader}
            onChange={(v) =>
              setSelectedDateHeader(v as SelectorOptionType<string> | undefined)
            }
          />
        </FormGroup>

        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.name",
          })}
        >
          <InputGroup
            disabled={!selectedValueHeader || selectDatasetNameFromFirstRow}
            required
            value={name}
            onChange={handleNameChange}
            placeholder={intl.formatMessage({
              id: "app.forms.name.placeholder",
            })}
          />
          <Checkbox
            className="mb-0"
            disabled={!selectedValueHeader}
            checked={selectDatasetNameFromFirstRow}
            label={intl.formatMessage({
              id: "app.datasets.select-dataset-name-from-first-row",
            })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const checked = e.target.checked;
              setSelectDatasetNameFromFirstRow(checked);
            }}
          />
        </FormGroup>

        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.type",
          })}
        >
          <Select
            isDisabled={!selectedValueHeader}
            options={measurementTypes}
            value={selectedMeasurementOption}
            onChange={handleMeasurementTypeChange}
          />
        </FormGroup>

        {measurementType === MeasurementType.Labels && (
          <PossibleValuesForm
            items={possibleValues}
            setItems={setPossibleValues}
            style={{
              overflow: "auto",
              maxHeight: 230, // 5 * 46px
            }}
          />
        )}

        {showPreview && (
          <Card className="p-0">
            <PreviewTable
              dataPoints={filteredDataPoints}
              setDataPoints={setDataPoints}
              measurementType={measurementType}
              possibleValues={possibleValues}
              scrollToIndex={scrollToIndex}
            />
          </Card>
        )}

        {!!invalidDataPoints.length && (
          <>
            <Callout
              className="mt-2"
              intent="warning"
              onClick={() => setShowOverlayDataOverlay(true)}
            >
              {intl.formatMessage({
                id: "app.datasets.invalid-data-from-file",
              })}
            </Callout>
            <Dialog
              title={intl.formatMessage({ id: "app.titles.invalid-data" })}
              isOpen={showInvalidDataOverlay}
              onClose={() => {
                setShowOverlayDataOverlay(false);
              }}
            >
              <div className="invalid-data-dialog-body">
                <HTMLTable className="w-full bg-white" striped>
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: "app.titles.value" })}</th>
                      <th>{intl.formatMessage({ id: "app.titles.date" })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invalidDataPoints.map((dp) => (
                      <tr key={dp.originalIndex}>
                        <td>{dp.value}</td>
                        <td>{dp.date?.toString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
              </div>
            </Dialog>
          </>
        )}
      </form>
      <div className={`${Classes.DIALOG_FOOTER} mt-4`}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={onClose} disabled={loading}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            loading={loading}
            className="w-1/4"
            intent="primary"
            type="submit"
            form={formId}
            disabled={!showPreview}
          >
            {intl.formatMessage({
              id: "app.titles.save",
            })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateDatasetFromFileDialogContent;
