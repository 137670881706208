import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { hideWorkspaceTour } from "../../../store/tours/actions";
import TooltipComponent from "../TooltipComponent";

type OwnProps = {};

type Props = OwnProps;

const LeadershipTeamWorkspaceTour: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const show = useSelector((s) => s.tours.showWorkspaceTour);

  const steps = useMemo((): Step[] => {
    return [
      {
        target: "#root",
        content: intl.formatMessage({
          id: "app.tours.team-workspace-tour",
        }),
        placement: "center",
        title: intl.formatMessage({
          id: "app.tours.team-workspace-tour.title",
        }),
        disableBeacon: true,
      },
      {
        target: '[data-tour="sci-calendar-tab"]',
        content: intl.formatMessage({
          id: "app.tours.team-workspace-tour.sci-calendar-tab",
        }),
        disableBeacon: true,
      },
      {
        target: '[data-tour="resources-tab"]',
        content: intl.formatMessage({
          id: "app.tours.team-workspace-tour.resources-tab",
        }),
        disableBeacon: true,
      },
    ];
  }, []);

  const handleJoyrideAction = (data: CallBackProps) => {
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      dispatch(hideWorkspaceTour());
    }
  };

  return (
    <ReactJoyride
      run={!!show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
    />
  );
};

export default LeadershipTeamWorkspaceTour;
