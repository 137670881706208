import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  Button,
  Callout,
  Classes,
  ControlGroup,
  Dialog,
  Icon,
  InputGroup,
  NonIdealState,
  TextArea,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import LocaleSelector from "../../../domains/LocaleSelector";
import useLanguages from "../../../../helpers/hooks/useLanguages";
import { Locale, LocaleDisplayedValues } from "../../../../store/UIState";
import {
  QuestionTranslations,
  SurveyQuestionExcludeBaseModelType,
} from "../../../../types";
import _ from "lodash";

type OwnProps = {
  show?: boolean;
  onClose?: Function;
  panelTitle: string;
  availableLanguages: Locale[];
  surveyQuestionsForDemographic: SurveyQuestionExcludeBaseModelType[];
  onTranslationUpdate: (translates: QuestionTranslations) => void;
};

type Props = OwnProps;

const SurveyQuestionsTranslationsDialog: FunctionComponent<Props> = (props) => {
  const {
    panelTitle,
    availableLanguages,
    show,
    onClose,
    surveyQuestionsForDemographic,
    onTranslationUpdate,
  } = props;

  const intl = useIntl();

  const handleOpen = () => {
    setSearchString("");
    setWarning(undefined);
    setSelectedLocale(Locale.English);
    setUsedLocales(availableLanguages);
    setTranslation(
      surveyQuestionsForDemographic.reduce((pV, cV) => {
        if (!cV.translations) {
          return pV;
        }

        return { ...pV, [cV.id]: cV.translations };
      }, {})
    );
  };

  const questionIdsByLeadIn = useMemo(() => {
    return _.chain(surveyQuestionsForDemographic)
      .filter((sq) => !!sq.lead_in)
      .map((sq) => _.pick(sq, ["id", "lead_in"]))
      .groupBy("lead_in")
      .value();
  }, [surveyQuestionsForDemographic]);

  const handleClose = () => {
    onClose && onClose();
  };

  const [usedLocales, setUsedLocales] = useState<Locale[]>([]);
  const { selectedLocale, setSelectedLocale } = useLanguages();
  const handleAddNewLocale = (locale: Locale) => {
    setUsedLocales((s) => [...s, locale]);
    setSelectedLocale(locale);
  };
  const [translations, setTranslation] = useState<QuestionTranslations>({});

  const handleTextTranslationChange = (questionId: string | number) => (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;

    if (!value && !translations[questionId]?.[selectedLocale]) {
      return;
    }

    setTranslation((translations) => ({
      ...translations,
      [questionId]: {
        ...(translations[questionId] ?? {}),
        [selectedLocale]: {
          ...(translations[questionId] ?? {})[selectedLocale],
          text: value,
        },
      },
    }));
  };

  const handleLeadInTranslationChange = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value, name } = e.target;
    questionIdsByLeadIn?.[name]?.forEach((leadInTranslationWithIds) => {
      setTranslation((translations) => ({
        ...translations,
        [leadInTranslationWithIds.id]: {
          ...(translations[leadInTranslationWithIds.id] ?? {}),
          [selectedLocale]: {
            ...(translations[leadInTranslationWithIds.id] ?? {})[
              selectedLocale
            ],
            lead_in: value,
          },
        },
      }));
    });
  };

  const [searchString, setSearchString] = useState<string>("");
  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchString(value);
  };
  const handleClearSearchString = () => {
    setSearchString("");
  };

  const filteredSurveyQuestions = useMemo(() => {
    return surveyQuestionsForDemographic.filter(
      (sq) =>
        !searchString ||
        sq.text.toLowerCase().includes(searchString.toLowerCase())
    );
  }, [surveyQuestionsForDemographic, searchString]);

  const [warning, setWarning] = useState<string | undefined>();

  const validateTranslations = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    _.chain(usedLocales)
      .filter((locale) => locale !== Locale.English)
      .every((locale) => {
        return surveyQuestionsForDemographic.every((sq) => {
          if (!translations[sq.id]?.[locale]) {
            validationOption = {
              isValid: false,
              message: intl.formatMessage(
                {
                  id: "app.translations.questions.no-translation",
                },
                { language: LocaleDisplayedValues[locale] }
              ),
            };
            setSelectedLocale(locale);
            setSearchString("");

            refs[sq.id]?.current?.textareaElement?.focus();

            return false;
          }
          return true;
        });
      })
      .value();

    return validationOption;
  };

  const handleSave = () => {
    onTranslationUpdate(translations);
    onClose && onClose();
  };

  const handleValidate = () => {
    const { isValid, message } = validateTranslations();
    if (isValid) {
      setWarning(undefined);
      handleSave();
    } else {
      setWarning(message);
    }
  };

  const [refs, setRefs] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    setRefs((refs) =>
      _.chain(surveyQuestionsForDemographic)
        .reduce((pV, sq) => {
          return {
            ...pV,
            [sq.id]: refs[sq.id] ?? React.createRef(),
          };
        }, {})
        .value()
    );
  }, [surveyQuestionsForDemographic]);

  return (
    <Dialog
      isOpen={show}
      onOpening={handleOpen}
      onClose={handleClose}
      icon={<Icon icon="clipboard" iconSize={24} />}
      title={`${intl.formatMessage({
        id: "app.translations.manage-translations",
      })}: ${panelTitle}`}
      style={{ minWidth: "500px", width: "50%" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <LocaleSelector
          withoutNewTranslations
          usedLocales={usedLocales}
          onSelectedLocaleChange={(locale) => {
            setSelectedLocale(locale);
          }}
          onAddTranslation={handleAddNewLocale}
          selectedLocale={selectedLocale}
        />

        <ControlGroup vertical={false} className="my-2">
          <InputGroup
            value={searchString}
            onChange={handleSearchStringChange}
            fill
            placeholder="Find filters..."
          />
          <Button icon="search" />
        </ControlGroup>

        {surveyQuestionsForDemographic.length ? (
          filteredSurveyQuestions.length ? (
            <div
              className="mb-2 overflow-auto p-1"
              style={{ maxHeight: "20rem" }}
            >
              {_.toPairs(questionIdsByLeadIn).map(
                ([leadIn, questionIds], i) => {
                  return (
                    <div className="flex -mr-1 ml-2 items-center" key={i}>
                      <span className="font-bold">{i + 1}</span>
                      <InputGroup
                        className="flex-1 m-1"
                        placeholder={intl.formatMessage({
                          id:
                            "app.survey-deployment-dialog.prompts-tab.lead-in.placeholder",
                        })}
                        defaultValue={leadIn}
                        readOnly
                      />
                      {selectedLocale !== Locale.English && (
                        <InputGroup
                          key={`${selectedLocale}`}
                          className="flex-1 m-1"
                          placeholder={intl.formatMessage({
                            id:
                              "app.survey-deployment-dialog.prompts-tab.lead-in.placeholder",
                          })}
                          name={leadIn}
                          defaultValue={
                            translations[questionIds?.[0]?.id]?.[selectedLocale]
                              ?.lead_in || ""
                          }
                          onBlur={handleLeadInTranslationChange}
                        />
                      )}
                    </div>
                  );
                }
              )}
              {filteredSurveyQuestions.map((sq) => (
                <div className="flex -mx-1" key={sq.id}>
                  <span className="font-bold mt-2">{`${sq.page + 1}.${
                    sq.order + 1
                  }`}</span>
                  <TextArea
                    readOnly
                    value={sq.text}
                    growVertically
                    className="flex-1 m-1"
                  />
                  {selectedLocale !== Locale.English && (
                    <TextArea
                      ref={refs[sq.id]}
                      id={`${sq.id}_${selectedLocale}`}
                      key={`${sq.id}_${selectedLocale}`}
                      defaultValue={
                        translations[sq.id]?.[selectedLocale]?.text || ""
                      }
                      onBlur={handleTextTranslationChange(sq.id)}
                      growVertically
                      className="flex-1 m-1"
                    />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <NonIdealState
              className="my-2"
              icon="search"
              title={intl.formatMessage({
                id: "app.tables.no-matching-data",
              })}
              description={intl.formatMessage({
                id: "app.tables.description.change-search-options",
              })}
              action={
                <Button
                  text={intl.formatMessage({
                    id: "app.tables.button-text.cleat-search-string",
                  })}
                  intent="primary"
                  onClick={handleClearSearchString}
                />
              }
            />
          )
        ) : (
          <NonIdealState
            className="my-2"
            title={intl.formatMessage({
              id: "app.translations.no-survey-questions",
            })}
            description={intl.formatMessage({
              id: "app.translations.no-survey-questions.info",
            })}
          />
        )}

        {warning && (
          <Callout
            className="my-3 flex justify-between items-start flex-wrap"
            intent="warning"
          >
            {warning}
            <Button onClick={handleSave} intent="warning">
              {intl.formatMessage({
                id: "app.titles.save-and-continue-later",
              })}
            </Button>
          </Callout>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={handleClose}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button onClick={handleValidate} className="w-1/4" intent="primary">
            {intl.formatMessage({
              id: "app.titles.save",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SurveyQuestionsTranslationsDialog;
