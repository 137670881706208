import React, { useMemo } from "react";
import {
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  SurveyDemographic,
} from "../../../../types";
import ReportTableDomainItem from "../../tab/domains/ReportTableDomainItem";
import HorizontalBarChart from "../../../charts/HorizontalBarChart";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../../../../store/auth/selectors";
import GroupedItemFilterLabel from "./grouped-item-filter/GroupedItemFilterLabel";
import useDatasetStatistic from "./useDatasetStatistic";
import DiscrepancyPopover from "./DiscrepancyPopover";
import useDiscrepancy from "./useDiscrepancy";
import TotalRespondentsCell from "./TotalRespondentsCell";

type OwnProps = {
  maxAnswerIndex: number;
  groupedSurveyStatistics: GroupedSurveyStatistics;
  demographic: SurveyDemographic;
  index: number;
  isDisaggregation?: boolean;
  showTotalRespondentsColumn: boolean;
  inReportInsights?: boolean;
  surveyDeploymentId?: number;
  manageBookmarkDialog?: boolean;
};

type Props = OwnProps;

const GroupedSurveyStatisticsTableRow: React.FC<Props> = (props: Props) => {
  const {
    maxAnswerIndex,
    groupedSurveyStatistics,
    demographic,
    index,
    isDisaggregation,
    showTotalRespondentsColumn,
    surveyDeploymentId,
    inReportInsights,
    manageBookmarkDialog,
  } = props;

  const intl = useIntl();

  const isAuthenticatedAdmin = useSelector<boolean>(selectIsAdmin);

  const isRedactedDataCollapsed = useSelector(
    (s) => s.surveyReports.isRedactedDataCollapsed
  );

  const nonIdealStateMessage = useMemo(() => {
    return intl.formatMessage({
      id: "app.non-ideal-state.survey-reports.not-enough-respondents",
    });
  }, []);

  const groupedAnswerStatistics = useMemo(() => {
    return groupedSurveyStatistics?.responses?.[demographic];
  }, [groupedSurveyStatistics, demographic]);

  const {
    dataSet,
    redactedStatistics,
    hasNotEnoughData,
    params,
    doubleDisaggregation,
  } = useDatasetStatistic({
    groupedAnswerStatistics,
    nonIdealStateMessage,
    maxAnswerIndex,
    manageBookmarkDialog,
  });
// console.log(dataSet,"<<dataset");

  const {
    hasDiscrepancy,
    rowsWithDiscrepancy,
    showDiscrepancyPopover,
    onMouseLeave,
    onMouseEnter,
  } = useDiscrepancy({
    items: dataSet?.data,
  });

  const keyHorizontalBarChart = useMemo(() => {
    return inReportInsights ? 0 : 1;
  }, [inReportInsights]);

  return (
    <tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <td
        className={
          hasDiscrepancy && isDisaggregation && inReportInsights
            ? "has-discrepancy"
            : "bg-gray-200"
        }
        colSpan={2}
      >
        <DiscrepancyPopover
          isDomain
          show={showDiscrepancyPopover}
          disabled={!hasDiscrepancy || !isDisaggregation || !inReportInsights}
        >
          <ReportTableDomainItem
            domain={groupedSurveyStatistics.domain}
            manageBookmarkDialog={manageBookmarkDialog}
          />
        </DiscrepancyPopover>
      </td>

      {groupedAnswerStatistics?.length && dataSet ? (
        <>
          <td style={{ minWidth: 150 }}>
            {!!dataSet.displayedLabels && (
              <table cellSpacing={0} cellPadding={0}>
                <tbody>
                  {dataSet.displayedLabels.map(
                    (labels: string | string[], index: number) => {
                      const hasDiscrepancy = rowsWithDiscrepancy.includes(
                        index
                      );
                      return (
                        <GroupedItemFilterLabel
                          key={index}
                          labels={labels}
                          notEnoughData={dataSet?.data?.[index] == null}
                          domainId={groupedSurveyStatistics.domain.id}
                          params={params[index]}
                          demographic={demographic}
                          overallMean={dataSet?.data?.[index]}
                          className={
                            hasDiscrepancy ? "has-discrepancy" : undefined
                          }
                          labelsWithDetail={dataSet?.labelsWithDetail}
                          doubleDisaggregation={doubleDisaggregation}
                        />
                      );
                    }
                  )}
                </tbody>
              </table>
            )}
          </td>
          <td
            colSpan={isAuthenticatedAdmin && showTotalRespondentsColumn ? 1 : 2}
            style={{ maxWidth: 200 }}
          >
            {!!groupedAnswerStatistics?.length && (
              <div className="w-full" key={keyHorizontalBarChart}>
                <HorizontalBarChart
                  index={index}
                  maxAnswerIndex={maxAnswerIndex}
                  inReportInsights={inReportInsights}
                  dataSet={dataSet}
                  isDisaggregation={isDisaggregation}
                  displayGroupedLabels
                  nonIdealStateMessage={nonIdealStateMessage}
                  demographic={demographic}
                  domainId={groupedSurveyStatistics?.domain?.id}
                  rowsWithDiscrepancy={rowsWithDiscrepancy}
                  color={groupedSurveyStatistics?.domain?.color_hex}
                  doubleDisaggregation={doubleDisaggregation}
                />
              </div>
            )}
          </td>
          {isAuthenticatedAdmin && showTotalRespondentsColumn && (
            <TotalRespondentsCell<GroupedAnswerStatistics>
              totalKey={"total_respondents"}
              redactedStatistics={redactedStatistics || []}
              rowsWithDiscrepancy={rowsWithDiscrepancy}
              showExtraSpan={isRedactedDataCollapsed && hasNotEnoughData}
            />
          )}
        </>
      ) : (
        <td colSpan={3}>
          <p className="text-center">
            {intl.formatMessage({
              id: "app.non-ideal-state.survey-reports.not-enough-respondents",
            })}
          </p>
        </td>
      )}
    </tr>
  );
};

export default GroupedSurveyStatisticsTableRow;
