import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getAuditCounts } from "../../../store/users/actions";
import CountAuditLogTable from "./CountAuditLogTable";

type OwnProps = {};

type Props = OwnProps;

const CountAuditLog: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const countAudits = useSelector<any>((s) => s.users.countAudits);
//   const loading = useSelector((s) => s.users.loading.getUserActionAudit);

  const [customPageSize, setCustomPageSize] = useState<number>(10);
  const [customPageIndex, setCustomPageIndex] = useState<number>(0);

  const customSetPageSize = (page: number) => {
    setCustomPageSize(page);
  };

  const customGoToPage = (index: number) => {
    setCustomPageIndex(index);
  };

  useEffect(() => {
    dispatch(
        getAuditCounts.request()
    );
  }, []);
  
  if (!countAudits) {
    return (
      <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({
          id: "app.non-ideal-state.action-audit-log",
        })}
      />
    );
  }

  return (
    <CountAuditLogTable
      countAudits={[countAudits]}
    />
  );
};

export default CountAuditLog;