import React, {
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
  } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import { CellProps } from "react-table";
import moment from "moment/moment";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import EntityTable from "../../../../common/EntityTable";
import DownloadGoalHistory from "./DownloadGoalHistory";
import { useSelector } from "react-redux";
import { getGoalActivityAllLog } from '../../../../../store/goals/actions';
import { Goal, GoalActivityLog, GoalActivityLogAll, GoalIndicatorTarget } from "../../../../../types";

type OwnProps = {
    goalActivitydata: Goal[];
    customSetPageSize?: (page: number) => void;
    customPageCount: number;
    customGoToPage?: (page: number) => void;
    customPageIndex?: number;
    customPageSize?: number;
  };

type Props = OwnProps;

const GoalActivityTable: FunctionComponent<Props> = (props) => {
    const {
      goalActivitydata,
      customSetPageSize,
      customGoToPage,
      customPageCount,
      customPageIndex,
      customPageSize,
    } = props;

    const intl = useIntl();

    const [searchString, setSearchString] = useState<string>("");
    const isGreenCheckVisible = (goal: any) => {
      return goal.selected_domains.length >0 && goal.targets.length >0 &&!!goal.desired_outcome && !!goal.statement && !goal.targets.find((et: any) => (!et.target_date || !et.target_value));
    }
    const callbackFilter = useCallback(
        (history: Goal) => {
          const searchStringLowerCase = searchString.trim().toLowerCase();
    
          if (!searchStringLowerCase) {
            return true;
          }
          return (
               moment(history?.created_at?.toLowerCase()).format("MM/DD/YYYY")?.includes(searchStringLowerCase) 
            || history?.statement?.toLowerCase()?.includes(searchStringLowerCase) 
            || moment(history?.updated_at?.toLowerCase()).format("MM/DD/YYYY")?.includes(searchStringLowerCase) 
            || moment(history?.updated_at).format("HH:mm")?.toLowerCase()?.includes(searchStringLowerCase) 
            || history?.district_name?.toString().toLowerCase()?.includes(searchStringLowerCase) 
            || history?.school_name?.toString().toLowerCase()?.includes(searchStringLowerCase) 
            || history?.domain_targets?.toString().toLowerCase()?.includes(searchStringLowerCase) 
            || history?.item_targets?.toString().toLowerCase()?.includes(searchStringLowerCase) 
            || isGreenCheckVisible(history) && "complete"?.toLowerCase()?.startsWith(searchStringLowerCase)
            || !isGreenCheckVisible(history) &&"incomplete"?.toLowerCase()?.startsWith(searchStringLowerCase)
       
          );
        },
        [searchString]
      );
    
    const filteredData = useMemo(() => {
    return goalActivitydata.filter(callbackFilter);
    }, [goalActivitydata, callbackFilter]);

    const customSort = (rowA:any, rowB:any) => {
     
      const row1 = isGreenCheckVisible(rowA.original);
      const row2 = isGreenCheckVisible(rowB.original);
    
      if (row1 < row2) return -1;
      if (row1 > row2) return 1;
      return 0;
    };
    const columns = useMemo(() => {
        return [
          {
            Header: intl.formatMessage({ id: "app.goal-log.goal-created-date" }),
            width: "10%",
            accessor: "create_date",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return (
                <p className="flex whitespace-no-wrap">
                  {moment(original.created_at).format("MM/DD/YYYY")}
                </p>
              );
            },
          },
          {
            Header: intl.formatMessage({ id: "app.goal-log.last-updated-date" }),
            width: "10%",
            accessor: "update_date",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return (
                <p className="flex whitespace-no-wrap">
                  {moment(original.updated_at).format("MM/DD/YYYY")}
                </p>
              );
            },
          },
          {
            Header: intl.formatMessage({ id: "app.goal-log.last-updated-time" }),
            width: "10%",
            accessor: "time",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return (
                <p className="flex whitespace-no-wrap">
                  {moment(original.updated_at).format("HH:mm")}
                </p>
              );
            },
          },
          {
            Header: intl.formatMessage({ id: "app.goal-log.district-name" }),
            width: "9%",
            accessor: "district",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return <p className="flex whitespace-no-wrap">{original.district_name}</p>;
            },
          },
          {
            Header: intl.formatMessage({ id: "app.goal-log.school-name" }),
            width: "10%",
            accessor: "school",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return <p className="flex whitespace-no-wrap">{original.school_name}</p>;
            },
          },
          {
            Header: intl.formatMessage({ id: "app.goal-log.goal-title" }),
            width: "10%",
            accessor: "goal_short_title",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return (
                <p className="flex">
                  {original.statement}
                </p>
              );
            },
          },
          {
            Header: intl.formatMessage({
              id: "app.goal-log.goal-domain-respondent-group",
            }),
            width: "20%",
            // accessor: "Domain",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return (
                <p className="flex">
                  {original.domain_targets}
                </p>
              );
            },
          },
          {
            Header: intl.formatMessage({
              id: "app.goal-log.goal-item-respondent-group",
            }),
            width: "20%",
            // accessor: "Item",
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              return (
                <p className="flex">
                  {original.item_targets}
                </p>
              );
            },
          },
          {
            Header: intl.formatMessage({
              id: "app.goal-log.goal-status",
            }),
            width: "20%",
            accessor: "status",
            sortType: customSort,
            Cell: ({ row: { original } }: CellProps<Goal>) => {
              const isGreenCheckVisibleFlag = isGreenCheckVisible(original);
              return (
                <p className="flex">
                  {!isGreenCheckVisibleFlag ?
                  "Incomplete" : "Complete" }
                </p>
              );
            },
          },
        ];
      }, []);
    
    const noUsers = useMemo(
    () => (
        <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({
            id: "app.non-ideal-state.goal-edit-history",
        })}
        />
    ),
    []
    );
    return (
        <EntityTable
          data={filteredData}
          columns={columns}
          searchString={searchString}
          setSearchString={setSearchString}
          loading={false}
          noDataComp={noUsers}
          customSetPageSize={customSetPageSize}
          customGoToPage={customGoToPage}
          customPageCount={customPageCount}
          customPageIndex={customPageIndex}
          customPageSize={customPageSize}
          customPagination={true}
        >
          <div className="flex items-center ml-2">
            <DownloadGoalHistory goalActivitydata={goalActivitydata} />
          </div>
        </EntityTable>
      );
    };
    
export default GoalActivityTable;