import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { getNotes, showNotesDialog } from "../../../../store/notes/actions";
import { Button, Callout, Spinner } from "@blueprintjs/core";
import NoteCard from "../../notes/notes-dialog/NoteCard";
import { UserNotes, UserNotesType } from "../../../../types";
import {
  getActivePlanId,
  isDistrictPlan,
} from "../../../../store/plans/selectors";
import { useIntl } from "react-intl";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../../App";
import { useHistory } from "react-router-dom";

type OwnProps = {
  forDashboard?: boolean;
};

type Props = OwnProps;

const DEFAULT_DISPLAYED_NOTES_SIZE = 3;
const RECENT_NOTES_TO_DISPLAY = 5;

const RecentSCIWritingWidgetBody: React.FC<Props> = ({
  forDashboard,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const activePlanId = useSelector(getActivePlanId);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const history = useHistory();

  const notes = useSelector((s) => s.notes.notes);
  const loading = useSelector((s) => s.notes.loading.getNotes);
  const error = useSelector((s) => s.notes.errors.getNotes);
  useLoading({ loading, error });

  useEffect(() => {
    if (activePlanId) {
      dispatch(
        getNotes.request({
          planId: activePlanId,
          size: forDashboard ? undefined : DEFAULT_DISPLAYED_NOTES_SIZE,
          note_type: forDashboard
            ? undefined
            : isDistrictActivePlan
            ? [UserNotesType.DCLT_RESOURCES]
            : [UserNotesType.SCLT_RESOURCES],
        })
      );
    }
  }, [activePlanId, forDashboard]);

  const handleNoteClick = (note: UserNotes) => () => {
    dispatch(showNotesDialog({ type: undefined, selectedNote: note }));
  };

  const handleGoToResourcesClick = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanTeam, {
        workspace: "team",
        tabId: isDistrictActivePlan
          ? UserNotesType.DCLT_RESOURCES
          : UserNotesType.SCLT_RESOURCES,
      } as any)
    );
  };

  const notesList = useMemo(() => {
    if (forDashboard) {
      return notes.slice(0, RECENT_NOTES_TO_DISPLAY);
    } else {
      return notes;
    }
  }, [notes]);

  return (
    <div className="flex-1 min-w-0">
      {loading ? (
        <Spinner className="mt-2" />
      ) : notes?.length ? (
        <>
          {notesList?.map((note) => (
            <div key={note.id} className="mb-2">
              <NoteCard note={note} onClick={handleNoteClick(note)} />
            </div>
          ))}
          {!forDashboard && (
            <div className="flex justify-center">
              <Button
                text={intl.formatMessage({
                  id: "app.titles.go-to-team-resources",
                })}
                title={intl.formatMessage({
                  id: "app.titles.go-to-team-resources",
                })}
                intent="primary"
                onClick={handleGoToResourcesClick}
              />
            </div>
          )}
        </>
      ) : (
        <Callout>{intl.formatMessage({ id: "app.notes.no-data" })}</Callout>
      )}
    </div>
  );
};

export default RecentSCIWritingWidgetBody;
