import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import DashboardCard from "../DashboardCard";
import DistrictSetupWidgetContent from "./DistrictSetupWidgetContent";
import { useSelector } from "react-redux";
import useUserPlanAssociation from "../../../../helpers/hooks/useUserPlanAssociation";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import { DistrictSetupTaskKey } from "../../../../types";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../../App";
import GetStartedCheckList from "../../../onboarding-tour/GetStartedCheckList"

type OwnProps = {};

type Props = OwnProps;

const DistrictSetupWidget: FunctionComponent<Props> = () => {
  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const {
    planSchoolId,
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
  } = useUserPlanAssociation();
  const { isDCC, isSuperintendent, teamRole } = useUserRole();

  const history = useHistory();

  const [hidden, setHidden] = useState<boolean>(true);
  useEffect(() => {
    if (setupCompleted !== undefined) {
      setHidden(setupCompleted);
    }
  }, [setupCompleted]);

  const hiddenWidgetForSchoolPlan:any = useMemo(() => {
    return (
      planSchoolId &&
      !(
        isSchoolPlanPrincipal ||
        (isSingleSchoolDistrict && (isDCC || isSuperintendent)) ||
        teamRole
      )
    );
  }, [
    isSingleSchoolDistrict,
    planSchoolId,
    isSchoolPlanPrincipal,
    isSuperintendent,
    teamRole,
    isDCC,
  ]);

  useMemo(() => {
    if (hidden || hiddenWidgetForSchoolPlan) {
      return;
    }

    if (!tours[DistrictSetupTaskKey.Review]) {
      history.push(
        generatePath(AuthRoutes.AppWelcomePage, {
          workspace: "welcome",
        } as any)
      );
    }
  }, [hidden, hiddenWidgetForSchoolPlan, tours[DistrictSetupTaskKey.Review]]);

  if (
    hidden ||
    hiddenWidgetForSchoolPlan ||
    !tours[DistrictSetupTaskKey.Review]
  ) {
    return null;
  }

  return (
    <div className="lg:w-1/2">
      {/* {console.log(tours,"<<tours")} */}
      {/* <DashboardCard body={<DistrictSetupWidgetContent tours={tours} />} /> */}
      <GetStartedCheckList tours={tours} />
    </div>
  );
};

export default DistrictSetupWidget;
