import React, { useMemo } from "react";
import { FormGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { CompletedSurveyRequest } from "../../../api/surveys/types";
import {
  CollectedDemographicDataEnum,
  Gender,
  Grade,
  Race,
  RemoteLearning,
  SelectorOptionType,
  SimpleAnswerOptions,
  StaffRoleQuestions,
  StudentGrades,
  SurveyDemographic,
  YesNoAnswerOptions,
} from "../../../types";
import GenericCheckboxButtonsGroup from "../selectors/GenericCheckboxButtonsGroup";
import GenericRadioButtonsGroup from "../selectors/GenericRadioButtonsGroup";
import _ from "lodash";

type OwnProps = {
  completedSurvey: CompletedSurveyRequest;
  onCompletedSurveyChange: (
    updatedCompletedSurvey: CompletedSurveyRequest
  ) => void;
  collectedDemographicData?: CollectedDemographicDataEnum[];
  availableGrades?: string[];
  demographic?: SurveyDemographic;
};

type Props = OwnProps;

const RespondentInfoForm: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    completedSurvey,
    onCompletedSurveyChange,
    collectedDemographicData = [],
    availableGrades = [],
    demographic,
  } = props;

  const dictionary: {
    [key in CollectedDemographicDataEnum]?: boolean;
  } = useMemo(() => {
    return _.chain(collectedDemographicData)
      .reduce((pV, cV) => {
        return {
          ...pV,
          [cV]: true,
        };
      }, {})
      .value();
  }, [collectedDemographicData]);

  const handleSelectedGradeChange = (value?: string) => {
    onCompletedSurveyChange({
      ...completedSurvey,
      grade: value == null ? undefined : value,
    });
  };

  const handleSelectedRaceChange = (races: Race[]) => {
    onCompletedSurveyChange({
      ...completedSurvey,
      race: races,
    });
  };

  const handleSelectedGenderChange = (genders: Gender[]) => {
    onCompletedSurveyChange({
      ...completedSurvey,
      gender: genders,
    });
  };

  const handleProvideDirectInstructionsChange = (value?: string) => {
    onCompletedSurveyChange({
      ...completedSurvey,
      direct_instruction: value == null ? undefined : Boolean(+value),
    });
  };

  const handleRemoteLearningChange = (value?: string) => {
    onCompletedSurveyChange({
      ...completedSurvey,
      remote_learning: value == null ? undefined : +value,
    });
  };

  const direction = useMemo(() => {
    return `${intl.formatMessage({
      id: "app.language.direction",
    })}`;
  }, []);

  const displayedGrades = useMemo(() => {
    const allGradeOptions: SelectorOptionType<Grade>[] = Object.values(
      Grade
    ).map((item) => ({
      label: intl.formatMessage(
        { id: "app.forms.surveys.answers.grades" },
        { grade: item }
      ),
      value: item,
    }));

    const demographicGrades = _.intersection(
      availableGrades,
      demographic === SurveyDemographic.ElementaryStudents
        ? StudentGrades.elementary_students_3_5
        : StudentGrades[SurveyDemographic.Students]
    );

    return allGradeOptions.filter((item) =>
      demographicGrades.length ? demographicGrades.includes(item.value) : true
    );
  }, [demographic, availableGrades]);

  const raceOptions: SelectorOptionType<Race>[] = useMemo(() => {
    const availableRaces = [
      Race.RACE_AMERICAN_INDIAN_OR_ALASKA_NATIVE,
      Race.RACE_ASIAN_OR_ASIAN_AMERICAN,
      Race.RACE_AFRICAN_AMERICAN_OR_BLACK,
      Race.RACE_HISPANIC_OR_LATINO,
      Race.RACE_NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER,
      Race.RACE_WHITE,
      Race.RACE_GROUP_NOT_LISTED_HERE,
    ];

    return availableRaces.map((value) => ({
      label: intl.formatMessage({ id: "app.choices.race" }, { race: value }),
      value: value,
    }));
  }, []);

  const yesNoAnswerOptions: SelectorOptionType<number>[] = useMemo(() => {
    return _.chain(Object.values(YesNoAnswerOptions))
      .filter((item) => typeof item === "number")
      .map((value) => ({
        label: intl.formatMessage(
          { id: "app.choices.simple-answers" },
          { value: value }
        ),
        value: +value,
      }))
      .value();
  }, []);

  const learningOptions: SelectorOptionType[] = useMemo(
    () =>
      Object.values(RemoteLearning)
        .filter((value) => typeof RemoteLearning[value as any] !== "number")
        .map((item) => ({
          label: intl.formatMessage(
            { id: "app.filters.remote-learning" },
            { learning: item }
          ),
          value: Number(item),
        })),
    []
  );

  const genderOptions = useMemo(() => {
    return _.chain(Object.values(Gender) as any[])
      .filter((item) =>
        demographic === SurveyDemographic.ElementaryStudents
          ? [Gender.Male, Gender.Female, Gender.UseDifferentWords].includes(
              item
            )
          : true
      )
      .map((value) => ({
        label: intl.formatMessage(
          { id: "app.enums.gender" },
          { gender: value }
        ),
        value: value,
      }))
      .value();
  }, [demographic]);

  const staffRoleAnswerOptions = useMemo(() => {
    return _.chain(Object.values(StaffRoleQuestions))
      .filter((item) => typeof item === "number")
      .map((value) => ({
        label: intl.formatMessage(
          { id: "app.choices.staff_role" },
          { value: value }
        ),
        value: value,
      }))
      .value();
  }, []);

  return (
    <div className="respondent-info-form container-min-width">
      {dictionary[CollectedDemographicDataEnum.grade] && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.forms.surveys.answers.grade-select",
          })}
        >
          <GenericRadioButtonsGroup<string>
            direction={direction}
            options={displayedGrades}
            selectedItem={completedSurvey.grade}
            onClick={handleSelectedGradeChange}
          />
        </FormGroup>
      )}
      {dictionary[CollectedDemographicDataEnum.gender] && (
        <FormGroup
          label={intl.formatMessage(
            {
              id: "app.forms.surveys.answers.gender-select",
            },
            { i: (chunks) => <i>{chunks}</i> }
          )}
        >
          <GenericCheckboxButtonsGroup<Gender>
            options={genderOptions}
            direction={direction}
            selectedItems={completedSurvey.gender}
            onChange={handleSelectedGenderChange}
          />
        </FormGroup>
      )}
      {dictionary[CollectedDemographicDataEnum.race] && (
        <FormGroup
          label={intl.formatMessage(
            {
              id: "app.forms.surveys.answers.race-select",
            },
            { i: (chunks) => <i>{chunks}</i> }
          )}
        >
          <GenericCheckboxButtonsGroup<Race>
            options={raceOptions}
            direction={direction}
            selectedItems={completedSurvey.race}
            onChange={handleSelectedRaceChange}
          />
        </FormGroup>
      )}
      {dictionary[CollectedDemographicDataEnum.english_at_home] && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.forms.surveys.questions.english_at_home",
          })}
        >
          <GenericRadioButtonsGroup<SimpleAnswerOptions>
            options={yesNoAnswerOptions}
            direction={direction}
            selectedItem={completedSurvey.english_at_home as number}
            onClick={(value) => {
              onCompletedSurveyChange({
                ...completedSurvey,
                english_at_home: value == null ? undefined : +value,
              });
            }}
          />
        </FormGroup>
      )}
      {dictionary[CollectedDemographicDataEnum.staff_role] && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.forms.surveys.questions.staff_role",
          })}
        >
          <GenericRadioButtonsGroup
            options={staffRoleAnswerOptions}
            direction={direction}
            selectedItem={completedSurvey.staff_role}
            onClick={(value) => {
              onCompletedSurveyChange({
                ...completedSurvey,
                staff_role: value == null ? undefined : +value,
              });
            }}
          />
        </FormGroup>
      )}

      {dictionary[CollectedDemographicDataEnum.reduced_lunch] && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.forms.surveys.questions.reduced_lunch",
          })}
        >
          <GenericRadioButtonsGroup
            options={yesNoAnswerOptions}
            direction={direction}
            selectedItem={completedSurvey.reduced_lunch}
            onClick={(value) => {
              onCompletedSurveyChange({
                ...completedSurvey,
                reduced_lunch: value == null ? undefined : +value,
              });
            }}
          />
        </FormGroup>
      )}

      {dictionary[CollectedDemographicDataEnum.has_iep] && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.forms.surveys.questions.has_iep",
          })}
        >
          <GenericRadioButtonsGroup
            options={yesNoAnswerOptions}
            direction={direction}
            selectedItem={completedSurvey.has_iep}
            onClick={(value) => {
              onCompletedSurveyChange({
                ...completedSurvey,
                has_iep: value == null ? undefined : +value,
              });
            }}
          />
        </FormGroup>
      )}
      {dictionary[CollectedDemographicDataEnum.direct_instruction] && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.forms.surveys.answers.provide_direct_instructions_switch",
          })}
        >
          <GenericRadioButtonsGroup
            direction={direction}
            options={yesNoAnswerOptions}
            selectedItem={
              completedSurvey.direct_instruction == null
                ? undefined
                : Number(completedSurvey.direct_instruction)
            }
            onClick={handleProvideDirectInstructionsChange}
          />
        </FormGroup>
      )}
      {dictionary[CollectedDemographicDataEnum.remote_learning] && (
        <FormGroup
          label={intl.formatMessage({
            id: "app.forms.surveys.answers.remote-learning-select",
          })}
        >
          <GenericRadioButtonsGroup
            options={learningOptions}
            direction={direction}
            selectedItem={completedSurvey.remote_learning}
            onClick={handleRemoteLearningChange}
          />
        </FormGroup>
      )}
    </div>
  );
};

export default RespondentInfoForm;
