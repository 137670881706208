import usePrevProps from "../../../../../helpers/hooks/usePrevProps";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const useDashboardTourFix = ({ show }: { show: boolean }) => {
  const getTeamMembersByPlanLoading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  const getSurveysDeploymentLoading = useSelector(
    (s) => s.surveysDeployment.loading.getSurveysDeployment
  );
  const prev = usePrevProps({
    getTeamMembersByPlanLoading,
    getSurveysDeploymentLoading,
  });

  const [rerender, setRerender] = useState(0); // todo find a better solution to fix tour position

  useEffect(() => {
    if (show) {
      if (
        (!getTeamMembersByPlanLoading && prev?.getTeamMembersByPlanLoading) ||
        (!getSurveysDeploymentLoading && prev?.getSurveysDeploymentLoading)
      ) {
        setRerender((k) => k + 1);
      }
    }
  }, [show, getTeamMembersByPlanLoading, getSurveysDeploymentLoading]);

  return {
    rerender,
  };
};

export default useDashboardTourFix;
