import React, { FunctionComponent } from "react";
import { Card } from "@blueprintjs/core";
import { QuestionTag, SurveyReportRouteParams } from "../../../../types";
import { generatePath } from "react-router";
import { useHistory } from "react-router-dom";

type OwnProps = {
  questionTags: QuestionTag[];
  routeParams: SurveyReportRouteParams;
  reportPagePath: string;
};

type Props = OwnProps;

const ClusterTagsGrid: FunctionComponent<Props> = (props) => {
  const { questionTags, routeParams, reportPagePath } = props;

  const history = useHistory();

  const handleQuestionTagClick = (tagId: number) => () => {
    history.push(
      generatePath(reportPagePath, {
        ...routeParams,
        tagId: tagId,
        // only for plan
        workspace: "report",
      } as any)
    );
  };

  return (
    <div className="flex flex-wrap -m-1">
      {questionTags.map((tag) => (
        <Card
          key={tag.id}
          onClick={handleQuestionTagClick(tag.id)}
          interactive
          className="m-1 text-xl text-center flex items-center justify-center"
          style={{ flex: "0 0 calc(33.33% - 0.5rem)" }}
        >
          <strong>{tag.tag}</strong>
        </Card>
      ))}
    </div>
  );
};

export default ClusterTagsGrid;
