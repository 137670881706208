import { Epic } from "redux-observable";
import { catchError, delay, filter, map, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getDomainsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getDomains.request)),
    switchMap((action) =>
      from(api.domains.getDomains()).pipe(
        map(actions.getDomains.success),
        catchError((error) => of(actions.getDomains.failure(error)))
      )
    )
  );
};


export const getDomainsById: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getDomainsById.request)),
    switchMap((action) =>
      from(api.domains.getDomainsById(action.payload)).pipe(
        map(actions.getDomainsById.success),
        catchError((error) => of(actions.getDomainsById.failure(error)))
      )
    )
  );
};

export const getExistDomainItems: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getExistDomainItems.request)),
    switchMap((action) =>
      from(api.domains.getExistDomainItems(action.payload)).pipe(
        map(actions.getExistDomainItems.success),
        catchError((error) => of(actions.getExistDomainItems.failure(error)))
      )
    )
  );
};



export const addDomainEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addDomain.request)),
    switchMap((action) =>
      from(api.domains.addDomain(action.payload)).pipe(
        map(actions.addDomain.success),
        catchError((error) => of(actions.addDomain.failure(error)))
      )
    )
  );
};

export const updateDomainEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateDomain.request)),
    switchMap((action) =>
      from(api.domains.updateDomain(action.payload)).pipe(
        map(actions.updateDomain.success),
        catchError((error) => of(actions.updateDomain.failure(error)))
      )
    )
  );
};

export const deleteDomainEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteDomain.request)),
    switchMap((action) =>
      from(api.domains.deleteDomain(action.payload)).pipe(
        map(() => actions.deleteDomain.success(action.payload)),
        catchError((error) => of(actions.deleteDomain.failure(error)))
      )
    )
  );
};

export const getDomainByIdEpic: Epic<RootAction, RootAction, RootAction> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getDomainById.request)),
    switchMap((action) =>
      from(api.domains.getDomainById(action.payload)).pipe(
        map(actions.getDomainById.success),
        catchError((error) => of(actions.getDomainById.failure(error)))
      )
    )
  );
};
