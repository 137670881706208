import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";

type OwnProps = {
  innerHtml?: string;
};

type Props = OwnProps;

const BracketContentWrapper: FunctionComponent<Props> = (props) => {
  const { innerHtml } = props;

  const intl = useIntl();

  return useMemo(() => {
    if (innerHtml) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: innerHtml,
          }}
        />
      );
    } else {
      return (
        <em>
          {intl.formatMessage({
            id: "app.titles.not-applicable",
          })}
        </em>
      );
    }
  }, [innerHtml]);
};

export default BracketContentWrapper;
