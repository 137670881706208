import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { Activity } from "../../types";

type ActionName =
  | "getActivities"
  | "saveActivity"
  | "updateActivity"
  | "deleteActivity"
  | "getActivityByName"
  | "getActivityById";

export type ActivitiesState = {
  dialogs: {
    activityVideoDialog: {
      show?: boolean;
      activity?: Activity;
      viewable_field?: string;
    };
    activityDialog: {
      show?: boolean;
      activity?: Activity;
    };
  };

  activities: Activity[];
  selectedActivity?: Activity;

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: ActivitiesState = {
  dialogs: {
    activityVideoDialog: {},
    activityDialog: {},
  },
  activities: [],
  loading: {},
  errors: {},
};

export const activitiesReducer = createReducer<ActivitiesState, RootAction>(
  initialState
)
  // get activities
  .handleAction(
    actions.getActivities.request,
    (state, action): ActivitiesState => ({
      ...state,
      loading: {
        ...state.loading,
        getActivities: true,
      },
      errors: {
        ...state.errors,
        getActivities: undefined,
      },
    })
  )
  .handleAction(
    actions.getActivities.success,
    (state, action): ActivitiesState => ({
      ...state,
      activities: action.payload,
      loading: {
        ...state.loading,
        getActivities: false,
      },
    })
  )
  .handleAction(
    actions.getActivities.failure,
    (state, action): ActivitiesState => ({
      ...state,
      errors: {
        ...state.errors,
        getActivities: action.payload,
      },
      loading: {
        ...state.loading,
        getActivities: false,
      },
    })
  )
  // get activity by name
  .handleAction(
    actions.getActivityByName.request,
    (state, action): ActivitiesState => ({
      ...state,
      loading: {
        ...state.loading,
        getActivityByName: true,
      },
      errors: {
        ...state.errors,
        getActivityByName: undefined,
      },
    })
  )
  .handleAction(
    actions.getActivityByName.success,
    (state, action): ActivitiesState => ({
      ...state,
      selectedActivity: action.payload,
      loading: {
        ...state.loading,
        getActivityByName: false,
      },
    })
  )
  .handleAction(
    actions.getActivityByName.failure,
    (state, action): ActivitiesState => ({
      ...state,
      errors: {
        ...state.errors,
        getActivityByName: action.payload,
      },
      loading: {
        ...state.loading,
        getActivityByName: false,
      },
    })
  )
  .handleAction(
    actions.showActivityVideoDialog,
    (state, action): ActivitiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        activityVideoDialog: {
          show: true,
          activity: action.payload.activity,
          viewable_field: action.payload.viewable_field,
        },
      },
    })
  )
  .handleAction(
    actions.hideActivityVideoDialog,
    (state, action): ActivitiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        activityVideoDialog: {},
      },
    })
  )
  .handleAction(
    actions.showActivityDialog,
    (state, action): ActivitiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        activityDialog: {
          show: true,
          activity: action.payload.activity,
        },
      },
    })
  )
  .handleAction(
    actions.hideActivityDialog,
    (state, action): ActivitiesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        activityDialog: {},
      },
    })
  )
  // save activity
  .handleAction(
    actions.saveActivity.request,
    (state, action): ActivitiesState => ({
      ...state,
      loading: {
        ...state.loading,
        saveActivity: true,
      },
      errors: {
        ...state.errors,
        saveActivity: undefined,
      },
    })
  )
  .handleAction(
    actions.saveActivity.success,
    (state, action): ActivitiesState => ({
      ...state,
      activities: [action.payload, ...state.activities],
      loading: {
        ...state.loading,
        saveActivity: false,
      },
    })
  )
  .handleAction(
    actions.saveActivity.failure,
    (state, action): ActivitiesState => ({
      ...state,
      errors: {
        ...state.errors,
        saveActivity: action.payload,
      },
      loading: {
        ...state.loading,
        saveActivity: false,
      },
    })
  )
  // update activity
  .handleAction(
    actions.updateActivity.request,
    (state, action): ActivitiesState => ({
      ...state,
      loading: {
        ...state.loading,
        updateActivity: true,
      },
      errors: {
        ...state.errors,
        updateActivity: undefined,
      },
    })
  )
  .handleAction(
    actions.updateActivity.success,
    (state, action): ActivitiesState => ({
      ...state,
      activities: state.activities.map((a) =>
        a.id === action.payload.id ? action.payload : a
      ),
      loading: {
        ...state.loading,
        updateActivity: false,
      },
    })
  )
  .handleAction(
    actions.updateActivity.failure,
    (state, action): ActivitiesState => ({
      ...state,
      errors: {
        ...state.errors,
        updateActivity: action.payload,
      },
      loading: {
        ...state.loading,
        updateActivity: false,
      },
    })
  )
  // delete activity
  .handleAction(
    actions.deleteActivity.request,
    (state, action): ActivitiesState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteActivity: true,
      },
      errors: {
        ...state.errors,
        deleteActivity: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteActivity.success,
    (state, action): ActivitiesState => ({
      ...state,
      activities: state.activities.filter((a) => a.id !== action.payload),
      loading: {
        ...state.loading,
        deleteActivity: false,
      },
    })
  )
  .handleAction(
    actions.deleteActivity.failure,
    (state, action): ActivitiesState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteActivity: action.payload,
      },
      loading: {
        ...state.loading,
        deleteActivity: false,
      },
    })
  )
  // get activity by id
  .handleAction(
    actions.getActivityById.request,
    (state, action): ActivitiesState => ({
      ...state,
      loading: {
        ...state.loading,
        getActivityById: true,
      },
      errors: {
        ...state.errors,
        getActivityById: undefined,
      },
    })
  )
  .handleAction(
    actions.getActivityById.success,
    (state, action): ActivitiesState => ({
      ...state,
      selectedActivity: action.payload,
      loading: {
        ...state.loading,
        getActivityById: false,
      },
    })
  )
  .handleAction(
    actions.getActivityById.failure,
    (state, action): ActivitiesState => ({
      ...state,
      errors: {
        ...state.errors,
        getActivityById: action.payload,
      },
      loading: {
        ...state.loading,
        getActivityById: false,
      },
    })
  );
