import React from "react";
import { useIntl } from "react-intl";
import DashboardCard from "../DashboardCard";
import RecentSCIWritingWidgetBody from "./RecentSCIWritingWidgetBody";

type OwnProps = { forDashboard?: boolean };

type Props = OwnProps;

const RecentSCIWritingWidget: React.FC<Props> = ({ forDashboard }: Props) => {
  const intl = useIntl();

  return (
    <DashboardCard
      title={intl.formatMessage({
        id: forDashboard
          ? "app.titles.recent-sci-writing"
          : "app.titles.recent-team-development-activities",
      })}
      body={<RecentSCIWritingWidgetBody forDashboard={forDashboard} />}
      centeredBody
    />
  );
};

export default RecentSCIWritingWidget;
