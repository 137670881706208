import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Icon, InputGroup } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import _ from "lodash";
import Button from '@mui/material/Button';

import './SurveyItemPanel.scss';
import { useIntl } from 'react-intl';
import { DomainStatistics, Goal, GoalIndicatorTargetDirection, GroupedAnswerStatistics, SurveyStatistics } from '../../../../../../../types';
import { INDICATOR_DOMAIN_LEVEL, INDICATOR_ITEM_LEVEL } from '../../../../../../../constants/constants';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyReport } from '../../../../../../../store/survey-reports/actions';
import { addDomainItemIndicator, deleteGoalIndicator, deleteGoalDomain, removeDomainFromGoal, updateGoalIndicator, updateGoal } from '../../../../../../../store/goals/actions';
import { Locale } from '../../../../../../../store/UIState';
import { Tooltip } from '@mui/material';
import CustomDateInput from '../../../../../../common/CustomDateInput';
//

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 500,
        },
    },
};


type OwnProps = {
    domainItem: any;
    goal: Goal;
};

type Props = OwnProps;

const SurveyItemPanel: FunctionComponent<Props> = (props) => {
    // CONSTANT
    const intl = useIntl();
    const dispatch = useDispatch();
    const { domainItem, goal } = props;

    // USE STATE
    const [personName, setPersonName] = React.useState<string[]>([]);
    const [domainItemsOptions, setDomainItemsOptions] = React.useState<any[]>([]);
    const [questions, setQuestions] = React.useState<any[]>([]);
    const [open1, setOpen1] = React.useState(null);
    const [domainSurveyReport, setDomainSurveyReport] = React.useState<any>();
    const [isScoreEditing, setIsScoreEditing] = React.useState(0);
    const [isItemClicked, setIsItemClicked] = React.useState(0);
    const [newScore, setNewScore] = React.useState<string>("");
    const [isDateEditing, setIsDateEditing] = React.useState(0);
    const [achivedByDate, setAchivedByDate] = useState<Date | undefined>();
   const link ="https://platform.njschoolclimate.org/files/activity/MjAy/download/pdf/NJ+SCI+Survey+Items+By+Domain+Across+Respondent+Groups.pdf";
    // USE SELECTOR 
    const activePlan = useSelector((s) => s.plans?.activePlan);
    const surveyReport = useSelector((s: any) => s.surveyReports.surveyReport);
  const { loading } = useSelector((s) => s.goals);

   
    const getResponses = (text: any) => {
        const { survey_questions } = surveyReport
        const data = (survey_questions?.filter((sq: any) => sq.text === text))
        return data?.map((d: any) => d[domainItem?.demographic]?.distributions)
    }
    // USE EFFECT
    useEffect(() => {
        if (
            open1 === domainItem?.id &&
            goal?.survey_deployment?.id &&
            domainItem?.demographic &&
            // domain?.value &&
            activePlan?.district?.id &&
            activePlan?.school?.id
        ) {
            dispatch(
                getSurveyReport.request({
                    district_id: activePlan?.district?.id,
                    school_id: activePlan?.school?.id,
                    survey_deployment_id: goal?.survey_deployment?.id,
                    domain_id: domainItem?.domain_priority?.domain?.id,
                    demographic: domainItem?.demographic,
                })
            );
        }
    }, [open1]) //[district_id, school_id, surveyDeploymentId, selectedDemographic, domain]);

    useEffect(() => {
        const availableDomains = [surveyReport] as SurveyStatistics[];
        if (availableDomains?.length) {
            setDomainSurveyReport(availableDomains);
        }
    }, [surveyReport]);

    // useEffect for domain options
    useEffect(() => {
        setDomainItemsOptions([]);
        if (!domainItem && surveyReport) return;
        if (
            (surveyReport as DomainStatistics<GroupedAnswerStatistics[]>)
                .survey_questions
        ) {

            const items = (surveyReport as DomainStatistics<GroupedAnswerStatistics[]>).survey_questions.filter((s) => !s.deleted_in_njsci_v2)
                .map((s: any) => {
                    const demographicData = s[domainItem.demographic] as { mean_rating?: number };
                    const meanRating = demographicData?.mean_rating !== undefined ? demographicData.mean_rating : 0.0;
                    return {
                        value: s.question_id,
                        lead_in:s.lead_in,
                        label: s.text,
                        mean_rating: meanRating,
                        disabled: questions.includes(s.question_id) ? true : false,
                    }
                });
            setDomainItemsOptions(items);
        } else setDomainItemsOptions([]);
        // setIsDomainItemLoading(false);
    }, [surveyReport]);

    // USE MEMO
    const items = useMemo(() => {
        return goal.targets.filter((t: any) => (t?.type === INDICATOR_ITEM_LEVEL && t?.demographic == domainItem.demographic && t?.item_priority?.domain?.id === domainItem?.domain_priority?.domain?.id))
    }, [goal]);

    

    // FUNCTION 
    const handleScoreChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value.toString().length === 3)
            value = value.toString() + 0.00;
        setNewScore(value);
    };

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value },
        } = event;
        // console.log(domainItemsOptions, '<domainItemsOptions')
        // add item level indicator 
        // const 


        // setPersonName(
        //     // On autofill we get a stringified value.
        //     typeof value === 'string' ? value.split(',') : value,
        // );
    };
    const handleClickOpen1 = () => {
        setOpen1(domainItem?.id);
    };
    const handleClose1 = () => {
        setOpen1(null);
    };

    const surveyItemScores = (id: number) => {

        const dm = domainSurveyReport?.find((s: any) => s.domain.id == domainItem?.domain_priority?.domain?.id);
        const domainMaxAnswerIndex = Number(
            _.max(
                Object.keys(
                    (domainItem.demographic &&
                        dm?.domain?.domain_answer?.[domainItem.demographic]?.[Locale.English]) ??
                    {}
                )
            ) ?? 0
        );
        if (domainItem) {
            let score;
            // find item level score     
            const itemIndicator: any = (surveyReport as DomainStatistics<GroupedAnswerStatistics[]>).survey_questions?.filter((s) => !s.deleted_in_njsci_v2).find(et => et.question_id == id)
            return score = (itemIndicator?.[domainItem.demographic]?.mean_rating * domainMaxAnswerIndex).toFixed(2);
        }
    }
    const handleItemSelection = (questionId: any) => {
        setIsItemClicked(questionId?.value)
        const exists = items.find(et => et?.item_priority?.item.id == questionId?.value)
        if (!exists) {

            const resultsItems = (surveyReport as DomainStatistics<GroupedAnswerStatistics[]>)?.survey_questions.filter((s) => !s.deleted_in_njsci_v2)
            const itemFound = resultsItems.find(et => et.question_id == questionId.value);
            const dm = domainSurveyReport?.find((s: any) => s.domain.id == domainItem?.domain_priority?.domain?.id);
            const domainMaxAnswerIndex = Number(
                _.max(
                    Object.keys(
                        (domainItem.demographic &&
                            dm?.domain?.domain_answer?.[domainItem.demographic]?.[Locale.English]) ??
                        {}
                    )
                ) ?? 0
            );

            let score = (dm?.responses?.[domainItem.demographic]?.mean_rating ?? 0) * domainMaxAnswerIndex;
            if (domainItem) {
                // find item level score     
                const itemIndicator: any = (surveyReport as DomainStatistics<GroupedAnswerStatistics[]>).survey_questions.filter((s) => !s.deleted_in_njsci_v2).find(et => et.question_id == questionId.value)
                score = itemIndicator?.[domainItem.demographic]?.mean_rating * domainMaxAnswerIndex;
            }

            const newDomainItemIndicator = {
                "demographic": domainItem?.demographic,
                "direction": GoalIndicatorTargetDirection.Maintain,
                "direction_of_change": GoalIndicatorTargetDirection.Maintain,
                "default_score": score ? score.toFixed(2).toString() : '',
                "plan": activePlan?.id,
                "domain": domainItem?.domain_priority?.domain?.id,
                "item": itemFound?.question_id ?? null,
                "survey_deployment": goal?.survey_deployment?.id,
                "goal_id": goal?.id,
                "type": INDICATOR_ITEM_LEVEL,
                "target_value": null //score ? (score).toFixed(2).toString() : ''
            }

            dispatch(addDomainItemIndicator.request({ planId: activePlan?.id, indicator: newDomainItemIndicator }))
        }
        else {
            handleDeleteGoalItem(exists)
        }
    }


    const handleDeleteGoalItem = (indicator: any) => {
        // CALL DELETE TARGET API HERE AND UPDATE THE GOAL STATE
        // let target = goal?.targets?.find((et: any) => et?.domain_priority && (et.domain_priority === indicator.domain_priority || et.domain_priority.id === indicator.domain_priority))
        dispatch(deleteGoalIndicator.request({ id: indicator.id }));
    }

    const handleTargetScoreUpdate = (indicator: any) => {
        dispatch(updateGoalIndicator.request({
            ...indicator,
            id: indicator.id,
            domain_priority: indicator?.domain_priority?.id ?? indicator.domain_priority,
            item_priority: indicator?.item_priority?.id ?? indicator.item_priority,
            need: null,
            target_value: newScore,
            direction: newScore == indicator?.default_score ? GoalIndicatorTargetDirection.Maintain : GoalIndicatorTargetDirection.Increase,
            direction_of_change: newScore == indicator?.default_score ? GoalIndicatorTargetDirection.Maintain : GoalIndicatorTargetDirection.Increase
        }))
        setIsScoreEditing(0)
    }

    const handleScoreEditing = (e: any) => {
        setIsScoreEditing(e.id)
        // setNewScore(e?.target_value)
    }

    const handleDateEditing = (e: any) => {
        setIsDateEditing(e.id)
    }

    const handleAchivedByDateChange = (
        selectedDate: Date,
        isUserChange: boolean,
        et: any
    ) => {
        if (isUserChange) {
            setAchivedByDate(
                moment(selectedDate).isBefore(moment()) ? moment().toDate() : selectedDate
            );
            handleTargetDateUpdate(et, moment(selectedDate).isBefore(moment()) ? moment().toDate() : selectedDate);
        }
    };

    const handlePopupClose = () => {
        setIsDateEditing(0);
    }

    const handleTargetDateUpdate = (indicator: any, targetDate: Date) => {
        dispatch(updateGoalIndicator.request({
            ...indicator,
            id: indicator.id,
            domain_priority: indicator?.domain_priority?.id ?? indicator.domain_priority,
            item_priority: indicator?.item_priority?.id ?? indicator.item_priority,
            need: null,
            target_date: targetDate
        }))
        targetDate === null ? setIsDateEditing(indicator.id) : setIsDateEditing(0)
    }

    const placeHolderValues = useMemo(() => {
        return items.map((it: any) =>it?.title?.lead_in ?  it?.title?.lead_in+' '+it?.title?.text:it?.title?.text)
    }, [items])


    return (
        <>
            <a onClick={handleClickOpen1}>{
                items.length ?
                    <>({items.length}) {intl.formatMessage({
                        id: "app.goals.target-indicator.survey-items",
                    })}</> :

                    <>
                        {intl.formatMessage({
                            id: "app.goals.target-indicator.add-survey-items",
                        })}
                    </>
            }</a>
            <Dialog
                open={open1 === domainItem?.id ? true : false}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="sidebar-modal"
            >
                <div className="sidepanel">
                    <div className="sidepanel__header">
                        <h3>Add Survey Item</h3>

                        <button onClick={handleClose1}><Icon icon="cross" iconSize={12} /></button>
                    </div>
                    <div className="sidepanel__content">
                    <h4>
                    {intl.formatMessage({
                        id: "app.goals.target-indicator.survey-items-text",
                    })}&nbsp; 
                     <a className="text-blue-400 hover:text-blue-500" target="_blank" href={link}> 
                      {intl.formatMessage({
                        id: "app.goals.target-indicator.survey-items-text-here",
                    })} 
                    </a>
                      </h4><br/>
                      <hr></hr>
                      <br/>
                        <h4>{domainItem.title}</h4>
                        <p>{intl.formatMessage({ id: `app.filters.respondent-type.${domainItem.demographic}` })}</p>

                        <div className="select-list">
                            <FormControl sx={{ m: 1, width: 900 }} size="small">
                                <InputLabel id="demo-select-small">Select Survey Item</InputLabel>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    className="checkbox-list"
                                    multiple
                                    value={placeHolderValues}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Select Survey Item" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                                 {domainItemsOptions.map((option, ind) => (
                                        option.mean_rating !== null && (
                                        <MenuItem key={`option_${ind}`} value={option} onClick={() => { handleItemSelection(option) }}>
                                            {(loading?.updateGoal || loading?.addIndicator) && isItemClicked ===option?.value  && <div id="overlay">
                  <div className="loader"></div>
                </div>}
                        
                                                <Checkbox checked={items.find(et => et.item_priority.item.id == option.value) ? true : false} />
                                                <ListItemText className="itemTextEllipse" primary={(option?.lead_in ? option?.lead_in+(option?.lead_in.includes(":") ? " " : ": ") : "")+' '+option.label} />
                                               
                                               <div className="accordionbar">
                                                    <div className="accordionbar__content">
                                                        <div className="accordionbar__content-row">
                                                            <div className="nj-row">
                                                                <div className="nj-sm-6">
                                                                    <div className="grade">
                                                                        <span
                                                                            style={{ background: domainItem?.color_hex ? `${domainItem?.color_hex} !important` : 'none', fontWeight: 'normal' }}
                                                                        >
                                                                            {surveyItemScores(option.value)}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        )
                                    ))}

                                </Select>
                            </FormControl>
                        </div>

                        <div className="survey-item">
                            <label>({items.length}) Survey Items Found</label>

                            <div className="accordionbar">
                                <div className="accordionbar__content">

                                    {
                                        items.map((it: any, ind: number) => (
                                            <div className="accordionbar__content-row" key={'side_bar_item_' + ind}>
                                                <div className="nj-row">

                                                    <div className="nj-sm-6">
                                                        <div className="grade" style={{  width: "100%",overflowX: "hidden",textOverflow: "ellipsis"}}>

                                                            <Tooltip
                                                                 title={_.flatMap(getResponses(it?.title?.text)).map((response: any, index: number) =>
                                                                    response !== null && <span
                                                                        key={index}
                                                                        className='d-flex-inline'
                                                                    >{response?.label}
                                                                        <br />
                                                                        {" " + Math.round(response?.mean_rating * 100) + " %"}
                                                                    </span>
                                                                )
                                                                }
                                                                arrow>
                                                                <span style={{ background: domainItem?.color_hex ? `${domainItem?.color_hex} !important` : 'none', fontWeight: 'normal' }}>{it?.default_score ? it.default_score : (0.00).toFixed(2)}</span>
                                                            </Tooltip>
                                        
                                                         <Tooltip 
                                                         title={it?.title?.lead_in ?  it?.title?.lead_in+' '+it?.title?.text:it?.title?.text}
                                                         placement='bottom-start'>
                                                          <p style={{display: 'flex'}}>
                                                          {it?.title?.lead_in ?  it?.title?.lead_in+' '+it?.title?.text:it?.title?.text}
                                                          </p>
                                                          </Tooltip>

                                                           
                                                        </div>
                                                    </div>

                                                    <div className="nj-sm-2">
                                                    {isScoreEditing == it.id ?
                                            <div className="hvr-bar" >
                                                <InputGroup
                                                    style={{ borderRadius: '20px' }}
                                                    type='number'
                                                    min={it?.default_score ? parseFloat(it.default_score).toFixed(2) : 1.00}//it?.default_score ? parseFloat(it.default_score).toFixed(2) : 1.00}
                                                    max={4.00}
                                                    intent={
                                                        it
                                                            ? !it?.target_value
                                                                ? "danger"
                                                                : "none"
                                                            : "none"
                                                    }
                                                    step={0.01}
                                                    disabled={false}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                    value={newScore}
                                                    onBlur={() => handleTargetScoreUpdate(it)}
                                                    onChange={handleScoreChange}
                                                    autoFocus={true}
                                                />
                                            </div> :
                                            <div className="hvr-bar" >
                                                <Tooltip title={intl.formatMessage({id: "app.goals.target-indicator.target-score-title",})} arrow>
                                                    <Button onClick={() =>{setNewScore(it?.target_value); handleScoreEditing(it)}}>{intl.formatMessage({id: "app.goals.target-indicator.target-score"})}: {it.target_value?Number(it.target_value).toFixed(2):'None'} <Icon icon="edit" iconSize={16} /></Button>
                                                </Tooltip>
                                            </div>
                                        }
                                                    </div>
                                                    <div className="nj-sm-3">
                                                        {isDateEditing == it.id ?
                                                            <div className="hvr-bar">
                                                                <Button onClick={() => handleDateEditing(it)}>{intl.formatMessage({ id: "app.goals.target-indicator.achieved-by" })}
                                                                    <Tooltip title={intl.formatMessage({ id: "app.goals.target-indicator.achieved-by-title", })} arrow>
                                                                        <CustomDateInput style={{ borderRadius: '20px' }} goalsPage={true} isPickerOpen={true} value={it.target_date ? new Date(it.target_date) : undefined} onChange={(sd: Date, iuc: boolean) => handleAchivedByDateChange(sd, iuc, it)} handlePopupClose={handlePopupClose} />
                                                                    </Tooltip>
                                                                </Button>
                                                            </div>
                                                            :
                                                            <div className="hvr-bar">
                                                                <Tooltip title={intl.formatMessage({ id: "app.goals.target-indicator.achieved-by-title", })} arrow>
                                                                    <Button onClick={() => handleDateEditing(it)}>{intl.formatMessage({ id: "app.goals.target-indicator.achieved-by" })} {it.target_date ? moment(it.target_date).format("MM/DD/YYYY") : 'None'} <Icon icon="edit" iconSize={16} /></Button>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="nj-sm-1">
                                                        <div className="action-bar">
                                                            <Icon icon="trash" iconSize={16} onClick={() => handleDeleteGoalItem(it)} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="button-group">
                            <button className="stroke-btn" onClick={handleClose1}>{intl.formatMessage({
                                id: "app.titles.close",
                            })}</button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>)
}
export default SurveyItemPanel;