import { useMemo } from "react";
import { ChartDataSet } from "../../../types";

const useDiscrepancyPlugin = (dataSet: ChartDataSet) => {
  const BAR_MIN_VALUE = 0.04

  return useMemo(() => {
    if (dataSet.data) {
      return {};
    }
    return {
      id: "discrepancy_plugin",

      /*
       * Sorts data by value and calculates values for bar stacks
       */
      beforeRender(chart: any) {
        // create data container in chart instance
        chart.sortedData = {};
        // iterate over datasets
        chart.data.datasets.forEach((dataset: any, datasetIndex: any) => {
          // iterate over dataset records
          dataset.data.forEach((data: any, index: any) => {
            // create data container for bar stack data
            if (!chart.sortedData[index]) {
              chart.sortedData[index] = {
                data: [],
              };
            }
            // save data
            chart.sortedData[index].data[datasetIndex] = {
              datasetIndex: datasetIndex,
              hidden: chart.getDatasetMeta(datasetIndex).hidden ? true : false,
              color: dataset.backgroundColor,
              value: dataset.data[index].x,
              ovalue: dataset.data[index].x,
              label: dataset.label,
              x: chart.getDatasetMeta(datasetIndex).data[index]._model.x,
              base: chart.getDatasetMeta(datasetIndex).data[index]._model.base,
            };
          });
        });

        var chartTop = chart.scales["x-axis-0"].left;
        var max = chart.scales["x-axis-0"]._valueRange;
        var w = chart.scales["x-axis-0"].width / max;
        // iterate over datasets
        chart.data.datasets.forEach((dataset: any, datasetIndex: any) => {
          // iterate over dataset records
          dataset.data.forEach((data: any, index: any) => {
            // sort data in bar stack by value
            chart.sortedData[index].data = Object.keys(
              chart.sortedData[index].data
            )
              .map((k) => chart.sortedData[index].data[k])
              .sort((a, b) => a.ovalue - b.ovalue);
            // iterate over stack records
            chart.sortedData[index].data.forEach((d: any, i: any) => {
              // calculate base value
              d.base = chartTop-w;
              if(chart.sortedData[index].data[i].ovalue===1){
                  chart.sortedData[index].data[i].value+=BAR_MIN_VALUE
                  chart.sortedData[index].data[i].ovalue+=BAR_MIN_VALUE
                }
              if (i > 0 && chart.sortedData[index].data[i].ovalue) {
                var diff =
                  chart.sortedData[index].data[i].ovalue -
                  chart.sortedData[index].data[i - 1].ovalue;

                if (diff <= BAR_MIN_VALUE && diff > 0) {
                  chart.sortedData[index].data[i].value = BAR_MIN_VALUE;
                } else {
                  chart.sortedData[index].data[i].value = diff;
                }
              }
              // increase base value with values of previous records
              for (var j = 0; j < i; j++) {
                d.base += chart.sortedData[index].data[j].hidden
                  ? 1
                  : w * chart.sortedData[index].data[j].value;
              }
              // set x value  
              d.x = d.base + w * d.value;
            });
          });
        });
        chart.data.datasets.forEach((d: any, j: any) => {
          chart.getDatasetMeta(j).data.forEach((data: any, index: any) => {
            var el = chart.sortedData[index]?.data.filter(
              (e: any) => e.datasetIndex === j
            )[0];
            data._model.x = el?.x;
            data._model.base = el?.base;
            data._model.value = el?.ovalue;
          });
        });
      },
    };
  }, [dataSet]);
};

export default useDiscrepancyPlugin;
