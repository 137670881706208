import { DomainAnswerSet, SurveyDemographic } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";

export const getDomainAnswerSets = (): Promise<DomainAnswerSet[]> => {
  return Axios.get(`${API_ROOT}/domain_answers/`);
};

export const addDomainAnswerSet = (
  request: Pick<DomainAnswerSet, SurveyDemographic>
): Promise<DomainAnswerSet> => {
  return Axios.post(`${API_ROOT}/domain_answers/`, request);
};

export const updateDomainAnswerSet = ({
  id,
  ...request
}: Pick<
  DomainAnswerSet,
  "id" | SurveyDemographic
>): Promise<DomainAnswerSet> => {
  return Axios.put(`${API_ROOT}/domain_answers/${id}/`, request);
};

export const deleteDomainAnswerSet = (id: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/domain_answers/${id}/`);
};
