import {
  Activity,
  Meeting,
  TeamRole,
  UserNotes,
  UserNotesType,
  UserRole,
} from "../../types";
import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import { AddNotesRequest } from "../../api/notes/types";
import { triggerPusherEvent } from "../../api/notes";
import events from "../../components/pages/strategic-plan/events";
import { AppToaster } from "../../helpers/toaster";
import {
  NOTE_CREATED_SUCCESSFULLY,
  NOTE_DELETED_SUCCESSFULLY,
  NOTE_UPDATED_SUCCESSFULLY,
} from "../../constants/constants";

type ActionName = "getNotes" | "addNotes" | "updateNotes" | "deleteNote";

export type NotesState = {
  notes: UserNotes[];
  dialogs: {
    notesDialog: {
      show?: boolean;
      meeting?: Meeting<string, false>;
      userNotesType?: UserNotesType | string;
      selectedNote?: UserNotes;
      note?: AddNotesRequest;
      selectedActivity?: Activity;
      agenda?: string;
      inviterRole?: UserRole;
      inviterTeamRole?: TeamRole;
      checkExisting?: Boolean;
    };
    navigationWarning:{
      show?:boolean;
      navigateTo?:string;
      haveUnsavedChanges?:boolean;
    };
  };
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: NotesState = {
  notes: [],
  loading: {},
  errors: {},
  dialogs: {
    notesDialog: {},
    navigationWarning: {},
  },
};

export const notesReducer = createReducer<NotesState, RootAction>(initialState)
  // show notes dialog
  .handleAction(
    actions.showNotesDialog,
    (state, action): NotesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        notesDialog: {
          show: true,
          userNotesType: action.payload?.type,
          selectedNote: action.payload?.selectedNote,
          selectedActivity: action.payload?.selectedActivity,
          checkExisting: action.payload?.checkExisting,
          note: state.dialogs.notesDialog?.note,
          agenda: action.payload?.agenda,
          meeting: action.payload.meeting,
        },
      },
    })
  )
  .handleAction(
    actions.hideNotesDialog,
    (state, action): NotesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        notesDialog: {
          note: state.dialogs.notesDialog?.note,
        },
      },
    })
  )

  .handleAction(
    actions.changeActivityNotesDialog,
    (state, action): NotesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        notesDialog: {
          ...(state.dialogs.notesDialog || {}),
          note: undefined,
          userNotesType: action.payload.userNotesType,
          selectedActivity: action.payload.activity,
          selectedNote: action.payload.notes,
        },
      },
    })
  )

  // get notes
  .handleAction(
    actions.getNotes.request,
    (state, action): NotesState => ({
      ...state,
      loading: {
        ...state.loading,
        getNotes: true,
      },
      errors: {
        ...state.errors,
        getNotes: undefined,
      },
    })
  )
  .handleAction(
    actions.getNotes.success,
    (state, action): NotesState => ({
      ...state,
      notes: action.payload.user_notes,
      dialogs: {
        ...state.dialogs,
        notesDialog: {
          ...state.dialogs.notesDialog,
          inviterRole: action.payload.inviter_role,
          inviterTeamRole: action.payload.inviter_team_role,
        },
      },
      loading: {
        ...state.loading,
        getNotes: false,
      },
    })
  )
  .handleAction(
    actions.getNotes.failure,
    (state, action): NotesState => ({
      ...state,
      errors: {
        ...state.errors,
        getNotes: action.payload,
      },
      loading: {
        ...state.loading,
        getNotes: false,
      },
    })
  )
  // add notes
  .handleAction(
    actions.addNotes.request,
    (state, action): NotesState => ({
      ...state,
      loading: {
        ...state.loading,
        addNotes: true,
      },
      errors: {
        ...state.errors,
        addNotes: undefined,
      },
    })
  )
  .handleAction(
    actions.addNotes.success,
    (state, action): NotesState => {
      // AppToaster.clear();
      // AppToaster.show({
      //   message: NOTE_CREATED_SUCCESSFULLY,
      //   icon: "tick-circle",
      //   intent: "success",
      // });
      try {
        // const meetings = [...(action.payload?.meetings??[])];
        delete action.payload?.meetings;
        if (action.payload?.plan && action.payload.id)
          triggerPusherEvent(action.payload.plan, {
            event: events.note_create,
            channel_name: "presence-sci_notes" + action?.payload?.plan,
            data: { note: action.payload },
          });
        // if(meetings?.length)
        // triggerPusherEvent(action.payload.plan, {
        //   event: events.note_update,
        //   channel_name: "presence-sci_notes" + action?.payload?.plan,
        //   data: { note: {id:action.payload.id, meetings:meetings} },
        // });
      } catch (error) {
        console.log(error, "<<error ");
      }

      return {
        ...state,
        notes: [...state.notes],
        loading: {
          ...state.loading,
          addNotes: false,
        },
        // dialogs: {
        //   ...state.dialogs,
        //   notesDialog: {
        //     ...state.dialogs.notesDialog,
        //     meeting: state.dialogs.notesDialog?.meeting
        //       ? {
        //           ...state.dialogs.notesDialog.meeting,
        //           user_notes: [
        //             ...state.dialogs.notesDialog.meeting.user_notes,
        //             action.payload,
        //           ],
        //         }
        //       : undefined,
        //   },
        // },
      };
    }
  )
  .handleAction(
    actions.addNotes.failure,
    (state, action): NotesState => ({
      ...state,
      errors: {
        ...state.errors,
        addNotes: action.payload,
      },
      loading: {
        ...state.loading,
        addNotes: false,
      },
    })
  )
  .handleAction(
    actions.pusherNewNoteEvent,
    (state, action): NotesState => {
      return {
        ...state,
        notes: [action.payload, ...state.notes],
        loading: {
          ...state.loading,
          addNotes: false,
        },
        dialogs: {
          ...state.dialogs,
          notesDialog: {
            ...state.dialogs.notesDialog,
            meeting: state.dialogs.notesDialog?.meeting
              ? {
                  ...state.dialogs.notesDialog.meeting,
                  user_notes: [
                    ...state.dialogs.notesDialog.meeting.user_notes,
                    action.payload,
                  ],
                }
              : undefined,
          },
        },
      };
    }
  )

  // delete note
  .handleAction(
    actions.deleteNote.request,
    (state, action): NotesState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteNote: true,
      },
      errors: {
        ...state.errors,
        deleteNote: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteNote.success,
    (state, action): NotesState => {
      AppToaster.clear();
      AppToaster.show({
        message: NOTE_DELETED_SUCCESSFULLY,
        icon: "tick-circle",
        intent: "success",
      });
      try {
        const note = state.notes.find((n) => n?.id === action.payload);
        if (note)
          triggerPusherEvent(note?.plan, {
            event: events.note_delete,
            channel_name: "presence-sci_notes" + note?.plan,
            data: action.payload,
          });
      } catch (error) {}
      return {
        ...state,
        notes: state.notes.filter((n) => n.id !== action.payload),
        loading: {
          ...state.loading,
          deleteNote: false,
        },
        dialogs: {
          ...state.dialogs,
          notesDialog: {
            ...state.dialogs.notesDialog,
            note: undefined,
            meeting: state.dialogs.notesDialog?.meeting
              ? {
                  ...state.dialogs.notesDialog.meeting,
                  user_notes: state.dialogs.notesDialog.meeting.user_notes.filter(
                    (un) => un.id !== action.payload
                  ),
                }
              : undefined,
          },
        },
      };
    }
  )
  .handleAction(
    actions.deleteNote.failure,
    (state, action): NotesState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteNote: action.payload,
      },
      loading: {
        ...state.loading,
        deleteNote: false,
      },
    })
  )
  .handleAction(
    actions.pusherDeleteNoteEvent,
    (state, action): NotesState => {
      return {
        ...state,
        notes: state.notes.filter((n) => n.id !== action.payload),
        loading: {
          ...state.loading,
          deleteNote: false,
        },
        dialogs: {
          ...state.dialogs,
          notesDialog: {
            ...state.dialogs.notesDialog,
            note: undefined,
            meeting: state.dialogs.notesDialog?.meeting
              ? {
                  ...state.dialogs.notesDialog.meeting,
                  user_notes: state.dialogs.notesDialog.meeting.user_notes.filter(
                    (un) => un.id !== action.payload
                  ),
                }
              : undefined,
          },
        },
      };
    }
  )
  // .handleAction(
  //   actions.pusherUpdateNoteEvent,
  //   (state, action): NotesState => {
  //     let note: UserNotes | undefined = state.notes?.find(
  //       (n) => n.id == action.payload?.id
  //     );
  //     note = { ...note, ...action.payload };

  //     return {
  //       ...state,
  //       notes: note
  //         ? [...state.notes.filter((n) => n.id !== action.payload), note]
  //         : state.notes,
  //       loading: {
  //         ...state.loading,
  //         deleteNote: false,
  //       },
  //       dialogs: {
  //         ...state.dialogs,
  //         notesDialog: {
  //           ...state.dialogs.notesDialog,
  //           // note: undefined,
  //           // meeting: state.dialogs.notesDialog?.meeting
  //           //   ? {
  //           //       ...state.dialogs.notesDialog.meeting,
  //           //       user_notes: state.dialogs.notesDialog.meeting.user_notes.filter(
  //           //         (un) => un.id !== action.payload
  //           //       ),
  //           //     }
  //           //   : undefined,
  //         },
  //       },
  //     };
  //   }
  // )
  //
  .handleAction(
    actions.updateDialogNote,
    (state, action): NotesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        notesDialog: {
          ...state.dialogs?.notesDialog,
          note: action.payload,
        },
      },
    })
  )
  // update notes
  .handleAction(
    actions.updateNotes.request,
    (state, action): NotesState => ({
      ...state,
      loading: {
        ...state.loading,
        updateNotes: true,
      },
      errors: {
        ...state.errors,
        updateNotes: undefined,
      },
    })
  )
  .handleAction(
    actions.updateNotes.success,
    (state, action): NotesState => {
      try {
        if (action.payload?.plan && action.payload.id) {
          delete action.payload?.meetings;
          triggerPusherEvent(action.payload.plan, {
            event: events.note_update,
            channel_name: "presence-sci_notes" + action?.payload?.plan,
            data: { note: { ...action.payload } },
          });
        }
      } catch (error) {
        console.log(error);
      }
      // s.notes.dialogs.notesDialog.selectedNote
      return {
        ...state,
        notes: state.notes.map((n) =>
          n?.id === action.payload?.id ? action.payload : n
        ),
        loading: {
          ...state.loading,
          updateNotes: false,
        },
        dialogs: {
          ...state.dialogs,
          notesDialog: {
            ...state.dialogs.notesDialog,
            // selectedNote: state.dialogs.notesDialog?.selectedNote?.id == action.payload.id? {...state.dialogs.notesDialog?.selectedNote, ...action.payload}: state.dialogs.notesDialog?.selectedNote,
            meeting: state.dialogs.notesDialog?.meeting
              ? {
                  ...state.dialogs.notesDialog.meeting,
                  user_notes: state.dialogs.notesDialog.meeting.user_notes.map(
                    (un) => (un.id === action.payload.id ? action.payload : un)
                  ),
                }
              : undefined,
          },
        },
      };
    }
  )
  .handleAction(
    actions.updateNotes.failure,
    (state, action): NotesState => ({
      ...state,
      errors: {
        ...state.errors,
        updateNotes: action.payload,
      },
      loading: {
        ...state.loading,
        updateNotes: false,
      },
    })
  )
  .handleAction(
    actions.pusherUpdateNoteEvent,
    (state, action): NotesState => {
      return {
        ...state,
        notes: state.notes.map((n) =>
          n?.id === action.payload?.id ? { ...n, ...action.payload } : n
        ),
        loading: {
          ...state.loading,
          updateNotes: false,
        },
        dialogs: {
          ...state.dialogs,
          notesDialog: {
            ...state.dialogs.notesDialog,
            meeting: state.dialogs.notesDialog?.meeting
              ? {
                  ...state.dialogs.notesDialog.meeting,
                  user_notes: state.dialogs.notesDialog.meeting.user_notes.map(
                    (un) => (un.id === action.payload.id ? action.payload : un)
                  ),
                }
              : undefined,
          },
        },
      };
    }
  )

  .handleAction(
    actions.saveNoteToRedux1,
    (state, action): NotesState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        notesDialog: {
          selectedNote: action.payload?.selectedNote,
        },
      },
    })
  )
  .handleAction(
    actions.setNavigationWarning,
    (state, action): NotesState => {
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          navigationWarning: {...state.dialogs.navigationWarning, ...action.payload}
        },
      };
    }
  );
