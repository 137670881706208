import { TeamRoleDefinition, TeamRoleDefinitionRoles } from "../../types";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

export const useTeamRoleDefinitions = () => {
  const intl = useIntl();

  const roles: TeamRoleDefinition[] = useMemo(() => {
    return Object.values(TeamRoleDefinitionRoles).map(
      (role): TeamRoleDefinition => ({
        role: role,
        roleTitle: intl.formatMessage({
          id: `app.team-plan.team.roles.${role}`,
        }),
        responsibilities: intl
          .formatMessage({
            id: `app.team-plan.team.roles.responsibilities.${role}`,
          })
          .split("\n"),
        permissions: intl.formatMessage(
          {
            id: `app.team-plan.team.roles.permissions.${role}`,
          },
          {
            b: (chunks: string) => <b>{chunks}</b>,
            i: (chunks: string) => <i>{chunks}</i>,
            u: (chunks: string) => <u>{chunks}</u>,
          }
        ),
      })
    );
  }, []);

  return roles;
};

export default useTeamRoleDefinitions;
