import { Tab, Tabs } from "@blueprintjs/core";
import React, { FunctionComponent, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { PlanStrategy, Strategy } from "../../../../types";
import { isPlanStrategy } from "../../utils";
import StrategyDescription from "./StrategyDescription";

type OwnProps = {
  strategy: Strategy | PlanStrategy;
};

type Props = OwnProps;

const StrategyDetails: FunctionComponent<Props> = (props) => {
  const { strategy } = props;

  const intl = useIntl();

  const [selectedTab, setSelectedTab] = useState("description");

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const isPlanStrategyType = useMemo(() => {
    return isPlanStrategy(strategy);
  }, [strategy]);

  const createdBy = useMemo(() => {
    if (isPlanStrategyType && (strategy as PlanStrategy)?.strategy.creator) {
      const {
        first_name,
        last_name,
      } = (strategy as PlanStrategy).strategy.creator!;
      return `${first_name} ${last_name}`;
    }
  }, [strategy, isPlanStrategyType]);

  return (
    <Tabs
      id="details-strategy"
      onChange={handleTabChange}
      selectedTabId={selectedTab}
    >
      <Tab
        id="description"
        title={intl.formatMessage({ id: "app.titles.description" })}
        panel={
          <StrategyDescription
            createdBy={createdBy}
            item={
              isPlanStrategyType
                ? (strategy as PlanStrategy)?.strategy?.description
                : (strategy as Strategy)?.description
            }
          />
        }
      />
      <Tab
        id="supporting-evidence"
        title={intl.formatMessage({
          id: "app.titles.supporting-evidence",
        })}
        panel={
          <StrategyDescription
            item={
              isPlanStrategyType
                ? (strategy as PlanStrategy)?.strategy?.supporting_evidence
                : (strategy as Strategy)?.supporting_evidence
            }
          />
        }
      />
    </Tabs>
  );
};

export default StrategyDetails;
