import React from "react";
import { ActivityTypeEnum, AttachedResource } from "../../../../types";
import { useIntl } from "react-intl";
import { FormGroup, TextArea } from "@blueprintjs/core";
import ActivitySciWritingTabs from "./ActivitySciWritingTabs";
import ActivityFilesFormGroup from "./ActivityFilesFormGroup";

type OwnProps = {
  embedCode?: string;
  externalUrl?: string;
  htmlContent?: string;
  notesPlaceholderHTML?: string;
  pdfFile?: AttachedResource;
  docxFile?: AttachedResource;
  pptFile?: AttachedResource;
  activityType: ActivityTypeEnum;
  onResourceLinkChange: (
    link: string,
    field: "external_url" | "embed_code"
  ) => void;
  onRichTextFieldChange: (
    html: string,
    field: "html_content" | "notes_placeholder_html"
  ) => void;
  onFileChange: (type: "pdf" | "docx" | "ppt", resource?: AttachedResource) => void;
};

type Props = OwnProps;

const ActivityContentByType: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    embedCode,
    externalUrl,
    htmlContent,
    notesPlaceholderHTML,
    pdfFile,
    docxFile,
    pptFile,
    activityType,
    onResourceLinkChange,
    onRichTextFieldChange,
    onFileChange,
  } = props;

  if (activityType === ActivityTypeEnum.SCI_WRITING) {
    return (
      <ActivitySciWritingTabs
        htmlContent={htmlContent}
        notesPlaceholderHTML={notesPlaceholderHTML}
        pdfFile={pdfFile}
        docxFile={docxFile}
        pptFile={pptFile}
        onRichTextFieldChange={onRichTextFieldChange}
        onFileChange={onFileChange}
      />
    );
  }

  if (activityType === ActivityTypeEnum.KALTURA_VIDEO) {
    return (
      <FormGroup
        label={intl.formatMessage({ id: "app.activity-form.embed-code" })}
      >
        <TextArea
          key={`embedCode-${embedCode}`}
          placeholder={`${intl.formatMessage({
            id: "app.activity-form.embed-code",
          })}...`}
          growVertically
          fill
          defaultValue={embedCode}
          onBlur={(e) => onResourceLinkChange(e.target.value, "embed_code")}
        />
      </FormGroup>
    );
  }

  if (activityType === ActivityTypeEnum.EXTERNAL_RESOURCE) {
    return (
      <>
        <p className="mb-2 italic">
          {intl.formatMessage({
            id: "app.activity-form.pdf-attachment-helper-text",
          })}
        </p>
        <FormGroup
          label={intl.formatMessage({
            id: "app.activity-form.external-resource",
          })}
        >
          <TextArea
            key={`externalUrl-${externalUrl}`}
            placeholder={`${intl.formatMessage({
              id: "app.activity-form.external-resource",
            })}...`}
            growVertically
            fill
            disabled={!!pdfFile}
            defaultValue={externalUrl}
            onBlur={(e) => onResourceLinkChange(e.target.value, "external_url")}
          />
        </FormGroup>
        <ActivityFilesFormGroup
          onFileChange={(type, resource) => {
            onResourceLinkChange("", "external_url");
            onFileChange(type, resource);
          }}
          pdfFile={pdfFile}
          pptFiles={pptFile}
          isExternalResourceActivityType
        />
      </>
    );
  }

  return null;
};

export default ActivityContentByType;
