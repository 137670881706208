import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../helpers/hooks/useLoading";
import { AppToaster } from "../../helpers/toaster";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../store/UIState/actions";
import { MeetingTemplate, MeetingType } from "../../types";
import { CellProps } from "react-table";
import { Button, Icon, Menu, MenuItem, Popover, Tag } from "@blueprintjs/core";
import EntityTable from "../common/EntityTable";
import {
  deleteMeetingTemplate,
  getMeetingTemplates,
  showMeetingTemplateDialog,
} from "../../store/meeting-templates/actions";
import NoMeetingTemplates from "./NoMeetingTemplates";
import MeetingTemplateUpsertDialog from "./dialog/MeetingTemplateUpsertDialog";

type OwnProps = {};

type Props = OwnProps;

const AdminMeetingTemplatesTable: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMeetingTemplates.request({ showHidden: true }));
  }, []);

  const meetingTemplates = useSelector(
    (s) => s.meetingTemplates.meetingTemplates
  );
  const loading = useSelector(
    (s) => s.meetingTemplates.loading.getMeetingTemplates
  );
  const error = useSelector(
    (s) => s.meetingTemplates.errors.getMeetingTemplates
  );
  useLoading({ loading, error });

  const deleteLoading = useSelector(
    (s) => s.meetingTemplates.loading.deleteMeetingTemplate
  );
  const deleteError = useSelector(
    (s) => s.meetingTemplates.errors.deleteMeetingTemplate
  );
  const onDeleteSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage(
        {
          id: "app.titles.delete-toaster-message",
        },
        {
          name: "Meeting Template",
        }
      ),
      icon: "tick",
      intent: "success",
    });
  }, []);
  useLoading({
    loading: deleteLoading,
    error: deleteError,
    onSuccess: onDeleteSuccess,
  });

  const showConfirmDeleteDialog = (meetingTemplateId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteMeetingTemplate.request(meetingTemplateId));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirm-dialogs.meeting-templates.delete",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const columns: any[] = useMemo(
    () => [
      {
        id: "short_title",
        Header: intl.formatMessage({ id: "app.titles.short-title" }),
        accessor: ({ title, short_title }: MeetingTemplate) => {
          return short_title ? short_title : title;
        },
        width: "20%",
      },
      {
        Header: intl.formatMessage({ id: "app.titles.meeting-code" }),
        accessor: "meeting_code",
        disableSortBy: true,
        width: "10%",
      },
      {
        Header: intl.formatMessage({ id: "app.titles.scope" }),
        width: "5%",
        accessor: (meetingTemplate: MeetingTemplate) => {
          return intl.formatMessage(
            { id: "app.titles.activity-scope" },
            { scope: meetingTemplate.type }
          );
        },
      },
      {
        id: "meeting_type",
        width: "15%",
        Header: intl.formatMessage({ id: "app.titles.type" }),
        accessor: (meetingTemplate: MeetingTemplate, index: number) => {
          return meetingTemplate.meeting_type
            ? meetingTemplate.meeting_type === MeetingType.Other
              ? meetingTemplate.meeting_type_other
              : intl.formatMessage(
                { id: "app.enums.meeting-type" },
                { type: meetingTemplate.meeting_type }
              )
            : intl.formatMessage({ id: "app.titles.not-applicable" });
        },
      },
      {
        id: "activities",
        Header: intl.formatMessage({ id: "app.titles.activities" }),
        disableSortBy: true,
        width: "20%",
        accessor: (meetingTemplate: MeetingTemplate) => {
          return (
            <div className="flex flex-wrap gap-2 justify-center">
              {meetingTemplate?.activities?.map((a, i) => (
                <Tag key={i} intent="success" minimal>
                  {a.name}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        id: "is_hidden",
        disableSortBy: true,
        Header: intl.formatMessage({ id: "app.titles.is-hidden" }),
        accessor: (meetingTemplate: MeetingTemplate) => {
          return (
            <div className="flex justify-center">
              <Icon icon={meetingTemplate.is_hidden ? "eye-off" : "eye-open"} />
            </div>
          );
        },
      },
      {
        id: "can_be_added",
        disableSortBy: true,
        Header: intl.formatMessage({ id: "app.titles.can-be-added" }),
        accessor: (meetingTemplate: MeetingTemplate) => {
          return (
            <div className="flex justify-center">
              <Icon icon={meetingTemplate.can_be_added ? "tick" : "cross"} />
            </div>
          );
        },
      },
      {
        id: "actions",
        width: "5%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<MeetingTemplate>) => {
          return (
            <div className="flex -ml-1">
              <Button
                className="whitespace-no-wrap m-1"
                title={intl.formatMessage({
                  id: "app.titles.edit",
                })}
                icon="edit"
                intent="primary"
                onClick={() => {
                  dispatch(
                    showMeetingTemplateDialog({ meetingTemplate: original })
                  );
                }}
              />
              <Button
                title={intl.formatMessage({
                  id: "app.surveys-table.columns.delete",
                })}
                icon="trash"
                className="whitespace-no-wrap m-1"
                intent="danger"
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  showConfirmDeleteDialog(original.id)
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const [searchString, setSearchString] = useState<string>("");

  const callbackFilter = (meetingTemplate: MeetingTemplate) => {
    const searchStringLowerCase = searchString.trim().toLowerCase();

    if (!searchStringLowerCase) {
      return true;
    }

    return (
      meetingTemplate?.short_title
        ?.toLowerCase()
        ?.includes(searchStringLowerCase) ||
      meetingTemplate?.title?.toLowerCase()?.includes(searchStringLowerCase) ||
      meetingTemplate?.general_notes
        ?.toLowerCase()
        ?.includes(searchStringLowerCase) ||
      meetingTemplate?.agenda?.toLowerCase()?.includes(searchStringLowerCase) ||
      meetingTemplate?.pre_work
        ?.toLowerCase()
        ?.includes(searchStringLowerCase) ||
      meetingTemplate?.timing_guidance
        ?.toLowerCase()
        ?.includes(searchStringLowerCase)
    );
  };

  const data = useMemo(() => meetingTemplates.filter(callbackFilter), [
    meetingTemplates,
    searchString,
  ]);

  const handleDialogOpen = () => {
    dispatch(showMeetingTemplateDialog({}));
  };

  return (
    <>
      <EntityTable
        data={data}
        columns={columns}
        searchString={searchString}
        setSearchString={setSearchString}
        noDataComp={<NoMeetingTemplates />}
        loading={loading}
      >
        <Popover
          position={"bottom-left"}
          className="flex-grow justify-start"
          content={
            <Menu>
              <MenuItem
                text={intl.formatMessage({
                  id: "app.meeting-templates.create-new",
                })}
                icon="plus"
                className="px-3 capitalize"
                onClick={handleDialogOpen}
              />
            </Menu>
          }
          minimal
        >
          <Button
            large
            minimal
            title={intl.formatMessage({ id: "app.titles.meeting-templates" })}
            icon={"properties"}
            text={intl.formatMessage({ id: "app.titles.meeting-templates" })}
            intent="primary"
            className="font-bold px-4"
          />
        </Popover>
      </EntityTable>
      <MeetingTemplateUpsertDialog />
    </>
  );
};

export default AdminMeetingTemplatesTable;
