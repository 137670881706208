import React, { useCallback, useMemo, useState } from "react";
import DemographicButtonGroup from "../../../survey-deployment/forms/DemographicButtonGroup";
import { Checkbox, FormGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import {
  CollectedDemographicData,
  CollectedDemographicDataEnum,
  SurveyDemographic,
} from "../../../../types";
import { getUpdatedSurveyDeploymentCollectedData } from "../../../survey-deployment/utils/utils";

type OwnProps = {
  collectedData: Omit<
    CollectedDemographicData,
    "id" | "created_at" | "updated_at"
  >[];
  setCollectedData: React.Dispatch<
    React.SetStateAction<
      Omit<CollectedDemographicData, "id" | "created_at" | "updated_at">[]
    >
  >;
};

type Props = OwnProps;

const SurveyDeploymentCollectedData: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { collectedData, setCollectedData } = props;

  const [clickedDemographic, setClickedDemographic] =
    useState<SurveyDemographic>(SurveyDemographic.ElementaryStudents);

  const handleCollectedDemographicChange = (
    e: React.FormEvent<HTMLInputElement>,
    selectorType: CollectedDemographicDataEnum
  ) => {
    setCollectedData(
      getUpdatedSurveyDeploymentCollectedData(
        collectedData,
        clickedDemographic,
        selectorType,
        e.currentTarget?.checked
      )
    );
  };

  const isChecked = useCallback(
    (selectorType: CollectedDemographicDataEnum) => {
      return collectedData
        .filter((item) => item.demographic === clickedDemographic)
        .some((item) =>
          item.demographic_data.some((selector) => selector === selectorType)
        );
    },
    [collectedData, clickedDemographic]
  );

  const collectedDataOptions = useMemo(() => {
    const list = [
      {
        dataType: CollectedDemographicDataEnum.gender,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.gender-selector",
        }),
        demographics: [],
      },
      {
        dataType: CollectedDemographicDataEnum.grade,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.grade-selector",
        }),
        demographics: [
          SurveyDemographic.ElementaryStudents,
          SurveyDemographic.Students,
        ],
      },
      {
        dataType: CollectedDemographicDataEnum.remote_learning,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.remote-learning-selector",
        }),
        demographics: [
          SurveyDemographic.ElementaryStudents,
          SurveyDemographic.Students,
        ],
      },
      {
        dataType: CollectedDemographicDataEnum.race,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.race-selector",
        }),
        demographics: [],
      },
      {
        dataType: CollectedDemographicDataEnum.english_at_home,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.english_at_home",
        }),
        demographics: [],
      },
      {
        dataType: CollectedDemographicDataEnum.staff_role,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.staff_role",
        }),
        demographics: [SurveyDemographic.SchoolStaff],
      },
      {
        dataType: CollectedDemographicDataEnum.direct_instruction,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.provide-direct-instruction-switch",
        }),
        demographics: [SurveyDemographic.SchoolStaff],
      },
      {
        dataType: CollectedDemographicDataEnum.reduced_lunch,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.reduced_lunch",
        }),
        demographics: [
          SurveyDemographic.SchoolStaff,
          SurveyDemographic.Parents,
        ],
      },
      {
        dataType: CollectedDemographicDataEnum.has_iep,
        label: intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.has_iep",
        }),
        demographics: [
          SurveyDemographic.SchoolStaff,
          SurveyDemographic.Parents,
        ],
      },
    ];

    return list.filter(
      (item) =>
        !item.demographics.length ||
        item.demographics.includes(clickedDemographic)
    );
  }, [clickedDemographic]);

  return (
    <div>
      <DemographicButtonGroup
        selectedDemographic={clickedDemographic}
        setSelectedDemographic={setClickedDemographic}
      />

      <p className="py-4 text-xl font-semibold">
        {intl.formatMessage({
          id: "app.survey-deployment-dialog.collected-data-tab.title",
        })}
      </p>

      {collectedDataOptions.map((item) => (
        <FormGroup key={item.dataType}>
          <Checkbox
            checked={isChecked(item.dataType)}
            label={item.label}
            onChange={(e) => handleCollectedDemographicChange(e, item.dataType)}
          />
        </FormGroup>
      ))}
    </div>
  );
};

export default SurveyDeploymentCollectedData;
