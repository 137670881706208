import react, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Icon, InputGroup, NumericInput, Spinner } from '@blueprintjs/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import Popover from "@mui/material/Popover";
import BookmarksModal from './BookmarksModal/BookmarksModal';

import Dialog from '@mui/material/Dialog';
import { Domain, GoalIndicatorTargetDirection } from '../../../../../../types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import SurveyItemPanel from './SurveyItemPanel/SurveyItemPanel';
import { EDIT_GOAL_MODE, INDICATOR_DOMAIN_LEVEL } from '../../../../../../constants/constants';
import { deleteGoalDomain, deleteGoalIndicator, updateGoal, updateGoalIndicator } from '../../../../../../store/goals/actions';
import { getActivePlanId } from '../../../../../../store/plans/selectors';
import { showItemLevelInfoAndBookmarksDialog } from '../../../../../../store/needs/actions';
import useDomainLevelBookmarkCounter from '../../../../../../helpers/hooks/bookmarks/useDomainLevelBookmarkCounter';
import CustomDateInput from '../../../../../common/CustomDateInput';

import './DomainListBar.scss';
import { getSurveyReport } from '../../../../../../store/survey-reports/actions';
import _ from 'lodash';

type OwnProps = {
    domain: Domain;
};

type Props = OwnProps;

const DomainListBar: FunctionComponent<Props> = (props) => {

    const [containerEl, setContainerEl] = useState(null);
    const [openMenuId, setOpenMenuId] = useState<string>('');

    const handleOpen = (menuId: string, e: any) => {
        setOpenMenuId(menuId);
        setContainerEl(e.currentTarget);
    };

    const handleClose = () => {
        setOpenMenuId('');
        setContainerEl(null);

    };

    const open = Boolean(containerEl);
    const id = open ? "simple-popover" : undefined;

    // CONSTANTS
    const dispatch = useDispatch();
    const intl = useIntl();
    const { domain } = props;


    // CUSTOM HOOKS
    const { marksCountByDemographic } = useDomainLevelBookmarkCounter({
        domainId: domain.id,
    });

    // USE STATE
    const [domainDeleteModalOpen, setDomainDeleteModalOpen] = useState(false);
    const [isScoreEditing, setIsScoreEditing] = useState(0);
    const [isDateEditing, setIsDateEditing] = useState(0);
    const [newScore, setNewScore] = useState<string>("");
    const [achivedByDate, setAchivedByDate] = useState<Date | undefined>();

    // USE SELECTOR
    const { goal } = useSelector((s) => s.goals.newGoalState);
    const activePlan = useSelector(getActivePlanId);
    const currentUser = useSelector((s) => s.auth.authenticatedUser);
    const activePlanData = useSelector((s) => s.plans?.activePlan);
    const surveyReport = useSelector((s: any) => s.surveyReports.surveyReport);
    const surveyReportLoading = useSelector((s: any) => s.surveyReports.loading.getSurveyReport);
    
    const getResponses=(et:any) => {
        const data=(surveyReport.length>0?
            surveyReport?.filter((sq:any)=>sq?.domain?.name===et?.domain_priority?.domain?.name)
            :[])

        return (data.length>0?data?.map((d:any)=>d.responses[et?.demographic]?.distributions):[])
        }

    const fetchResponses=(et:any)=>{
        
    dispatch(
        getSurveyReport.request({
            district_id: activePlanData?.district?.id,
            school_id: activePlanData?.school?.id,
            survey_deployment_id: goal?.survey_deployment?.id,
            domain_id: undefined,
            demographic: et?.demographic,
        })
    );
    }
    
    // FUNCTION
    const handleScoreChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value.toString().length === 3)
            value = value.toString() + 0.00;
        setNewScore(value);
    };

    const handleAchivedByDateChange = (
        selectedDate: Date,
        isUserChange: boolean,
        et: any
    ) => {
        if (isUserChange) {
            setAchivedByDate(
                moment(selectedDate).isBefore(moment()) ? moment().toDate() : selectedDate
            );
            handleTargetDateUpdate(et, moment(selectedDate).isBefore(moment()) ? moment().toDate() : selectedDate);
        }
    };

    const handleScoreEditing = (e: any) => {
        setIsScoreEditing(e.id)
        // setNewScore(e?.target_value)
    }

    const handleDateEditing = (e: any) => {
        setIsDateEditing(e.id)
    }

    const handleDomainDeleteClickOpen = () => {
        setDomainDeleteModalOpen(true);
    };
    const handleDomainDeleteClose = () => {
        setDomainDeleteModalOpen(false);
    };
    const handleDomainDelete = () => {
        doDeleteDomainFromGoal();
    };
    const doDeleteDomainFromGoal = () => {
        dispatch(
            deleteGoalDomain.request({
                domain_id: domain.id,
                goal_id: goal.id,
                plan_id: activePlan
            })
        );
        setDomainDeleteModalOpen(false);
    }

    const handleTargetDateUpdate = (indicator: any, targetDate: Date) => {
        dispatch(updateGoalIndicator.request({
            ...indicator,
            id: indicator.id,
            domain_priority: indicator?.domain_priority?.id ?? indicator.domain_priority,
            item_priority: indicator?.item_priority?.id ?? indicator.item_priority,
            need: null,
            target_date: targetDate
        }))
        // setIsDateEditing(0)
        targetDate === null ? setIsDateEditing(indicator.id) : setIsDateEditing(0)

    }

    const handleTargetScoreUpdate = (indicator: any) => {
        dispatch(updateGoalIndicator.request({
            ...indicator,
            id: indicator.id,
            domain_priority: indicator?.domain_priority?.id ?? indicator.domain_priority,
            item_priority: indicator?.item_priority?.id ?? indicator.item_priority,
            need: null,
            target_value: newScore,
            direction: newScore == indicator?.default_score ? GoalIndicatorTargetDirection.Maintain : GoalIndicatorTargetDirection.Increase,
            direction_of_change: newScore == indicator?.default_score ? GoalIndicatorTargetDirection.Maintain : GoalIndicatorTargetDirection.Increase
        }))
        setIsScoreEditing(0)
    }

    const handleDeleteGoalItem = (indicator: any) => {
        // CALL DELETE TARGET API HERE AND UPDATE THE GOAL STATE
        // let target = goal?.targets?.find((et: any) => et?.domain_priority && (et.domain_priority === indicator.domain_priority || et.domain_priority.id === indicator.domain_priority))
        dispatch(deleteGoalIndicator.request({ id: indicator.id }));

        setOpenMenuId('');
        setContainerEl(null);
    }
    const handleShowBookmarks = (domain: Domain, item: any) => {
        const marksCount = marksCountByDemographic[item?.demographic]
        dispatch(
            showItemLevelInfoAndBookmarksDialog({
                domain: domain,
                demographic: item.demographic,
                meanScore: item.default_score,
                marksCount: marksCount
            })
        );
    }

    const handlePopupClose = () => {
        setIsDateEditing(0);
    }

    function addOpacity(color:string, opacity:any) {
        // coerce values so ti is between 0 and 1.
        let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }
    
    // USE MEMO
    const filterTargets = useMemo(() => {
        return goal.targets.filter((et: any) => et.type === INDICATOR_DOMAIN_LEVEL && et.domain_priority.domain.id === domain.id)
    }, [goal]);

    // const marksCount = useMemo(() => marksCountByDemographic[demographic] ?? {}, [
    //     marksCountByDemographic,
    //     demographic,
    // ]);

    return (<>
        <Accordion style={{ 'borderLeft': '5px solid ' + (domain?.color_hex ?? ''), 'backgroundColor':domain?.color_hex ? addOpacity(domain?.color_hex,0.1):'null' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Typography>
                    <div className="accordionbar__headaing">
                        <h3>{domain.name}</h3>

                        <ul>
                            <li>
                                <Button variant="outlined" onClick={handleDomainDeleteClickOpen}>
                                    <Icon icon="trash" iconSize={16} />
                                </Button>
                                <Dialog
                                    className="medium-popup"
                                    open={domainDeleteModalOpen}
                                    onClose={handleDomainDeleteClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <div className="cancel">
                                        <button onClick={handleDomainDeleteClose}><Icon icon="cross" iconSize={18} /></button>
                                    </div>
                                    <Icon icon="trash" iconSize={30} />

                                    <h3>
                                        {intl.formatMessage(
                                            { id: "app.domain.delete-dialogs.title" }
                                        )}
                                    </h3>
                                    <p>
                                        {intl.formatMessage(
                                            { id: "app.domain.delete-dialogs.body" },
                                            {
                                                br: <br />,
                                                domain: domain?.name,
                                            }
                                        )}
                                    </p>
                                    <div className="button-group">
                                        <button onClick={handleDomainDeleteClose} className="stroke-btn">{intl.formatMessage(
                                            { id: "app.titles.cancel" }
                                        )}</button>
                                        <button onClick={handleDomainDelete} className="nj-btn">{intl.formatMessage(
                                            { id: "app.titles.delete" }
                                        )}</button>
                                    </div>
                                </Dialog>
                            </li>
                        </ul>
                    </div>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="accordionbar__content">
                    {
                        filterTargets.map((et: any, i: number) =>
                            <div className="accordionbar__content-row">
                                <div className="nj-row">
                                    <div className="nj-sm-3">
                                        <div className="grade">
                                    
                                        <Tooltip 
                                        onMouseEnter={(e)=>fetchResponses(et)} 
                                        title={surveyReportLoading?<Spinner /> :
                                        _.flatMap(getResponses(et)).map((response:any,index:number)=>
                                        <span key={index} className='d-flex-inline'>
                                            {response?.label}
                                            <br/>
                                        {" "+Math.round(response?.mean_rating*100)+" %"}
                                        </span>
                                        )} 
                                        arrow>
                                            
                                         <span >{et.default_score}</span>
                                            
                                        </Tooltip>
                                            {intl.formatMessage({ id: `app.filters.respondent-type.${et.demographic}`, })}
                                        </div>
                                    </div>
                                    <div className="nj-sm-3">
                                        
                                    {isScoreEditing == et.id ?
                                            <div className="hvr-bar" >
                                                <InputGroup
                                                    style={{ borderRadius: '20px' }}
                                                    type='number'
                                                    min={et?.default_score ? parseFloat(et.default_score).toFixed(2) : 1.00}//et?.default_score ? parseFloat(et.default_score).toFixed(2) : 1.00}
                                                    max={4.00}
                                                    intent={
                                                        et
                                                            ? !et?.target_value
                                                                ? "danger"
                                                                : "none"
                                                            : "none"
                                                    }
                                                    step={0.01}
                                                    disabled={false}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                    value={newScore}
                                                    onBlur={() => handleTargetScoreUpdate(et)}
                                                    onChange={handleScoreChange}
                                                    autoFocus={true}
                                                />
                                            </div> :
                                            <div className="hvr-bar" >
                                                <Tooltip title={intl.formatMessage({id: "app.goals.target-indicator.target-score-title",})} arrow>
                                                    <Button onClick={() =>{setNewScore(et?.target_value); handleScoreEditing(et)}}>{intl.formatMessage({id: "app.goals.target-indicator.target-score"})}: {et.target_value?Number(et.target_value).toFixed(2):'None'} <Icon icon="edit" iconSize={16} /></Button>
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                    <div className="nj-sm-3">
                                        {isDateEditing === et.id ? (
                                            <div className="hvr-bar">
                                                <Tooltip title={intl.formatMessage({ id: "app.goals.target-indicator.achieved-by-title" })} arrow>
                                                    <div>
                                                        <span>Achieved By:</span>
                                                        <CustomDateInput
                                                            style={{ borderRadius: '20px' }}
                                                            goalsPage={true}
                                                            isPickerOpen={true}
                                                            value={et.target_date ? new Date(et.target_date) : undefined}
                                                            onChange={(sd: Date, iuc: boolean) => handleAchivedByDateChange(sd, iuc, et)}
                                                            handlePopupClose={handlePopupClose}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ) : (
                                            <div className="hvr-bar">
                                                <Tooltip title={intl.formatMessage({ id: "app.goals.target-indicator.achieved-by-title" })} arrow>
                                                    <Button onClick={() => handleDateEditing(et)}>
                                                        Achieve By: {et.target_date ? moment(et.target_date).format("MM/DD/YYYY") : 'None'} <Icon icon="edit" iconSize={16} />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                    <div className="nj-sm-2">
                                        <div className="hvr-bar">
                                            <SurveyItemPanel domainItem={et} goal={goal} />
                                        </div>
                                    </div>

                                    <div className="nj-sm-1">
                                        <div className="action-bar">
                                            <button aria-describedby={'menu-item-' + i}
                                                onClick={(e) => { handleOpen('menu-item-' + i, e) }} >
                                                <Icon icon="more" iconSize={16} />
                                            </button>
                                            <Popover
                                                id={'menu-item-' + i}
                                                className="domainfilter-dropdown"
                                                open={openMenuId == 'menu-item-' + i}
                                                anchorEl={containerEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <ul>
                                                    <li>
                                                        <button onClick={() => handleDeleteGoalItem(et)}><Icon icon="trash" iconSize={16} /> {intl.formatMessage({ id: "app.surveys-table.columns.delete" })}</button>
                                                    </li>
                                                    <li onClick={handleClose}>
                                                        <Icon icon="bookmark" iconSize={16} />  <BookmarksModal data={et} handleShowBookmarks={() => handleShowBookmarks(domain, et)} />
                                                    </li>
                                                </ul>
                                            </Popover>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        )
                    }
                </div>
            </AccordionDetails>
        </Accordion>

    </>)
}

export default DomainListBar;
