import React from "react";
import { login } from "../store/auth/actions";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, FormGroup } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { NonAuthRoutes } from "../App";
import useFormInputField from "../helpers/hooks/useFormInputField";
import useLockButton from "../helpers/hooks/useLockButton";
import { Helmet } from "react-helmet";
import { useLoading } from "../helpers/hooks/useLoading";
import logo from "../static/images/nj-sci-logo.png";

type OwnProps = {};

type Props = OwnProps;

const SponsorshipStatement: React.FC<Props> = () => {
  const intl = useIntl();
  return (
    <p className="text-center my-5 p-5 w-60">
      {intl.formatMessage(
        {
          id: "app.forms.login-form.sponsorship-statement",
        },
        {
          br: <br />,
        }
      )}
    </p>
  );
};

const LoginForm: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const [lockButton, showPassword] = useLockButton();
  const [email, emailInputField] = useFormInputField({
    id: "email",
    required: true,
    type: "email",
  });
  const [password, passwordInputField] = useFormInputField({
    id: "password",
    required: true,
    type: showPassword ? "text" : "password",
    rightElement: lockButton as JSX.Element,
  });

  const loading = useSelector(
    (s) => s.auth.loading.login || s.auth.loading.getAuthenticatedUser
  );
  const error = useSelector((s) => s.auth.errors.login);
  useLoading({
    loading,
    error,
    errorTitle: (e: any) => {
      return e?.response?.status === 400
        ? intl.formatMessage({
            id: "app.forms.login-form.invalid-email-or-password",
          })
        : undefined;
    },
  });

  const sendRequest = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      login.request({
        username: email as string,
        password: password as string,
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.login" })}</title>
      </Helmet>
      <div className="flex justify-center items-center">
        <Card>
          <div className={"flex justify-center"}>
            <img
              src={logo}
              style={{ background: "rgba(255, 255, 255, 1.0)", height: 180 }}
              className="rounded-md"
              alt="NJ SCI Platform"
            />
          </div>
          <div className="flex flex-col items-center">
            <b className="text-2xl text-center mb-2">
              {intl.formatMessage({ id: "app.forms.login-form.title" })}
            </b>
            <p>{intl.formatMessage({ id: "app.forms.login-form.info" })}</p>
          </div>
          <form className="mt-4" onSubmit={sendRequest}>
            <FormGroup
              label={intl.formatMessage({
                id: "app.forms.email",
              })}
            >
              {emailInputField}
            </FormGroup>
            <FormGroup
              label={intl.formatMessage({
                id: "app.forms.login-form.password",
              })}
            >
              {passwordInputField}
            </FormGroup>

            <Button loading={loading} intent="primary" fill type={"submit"}>
              {intl.formatMessage({
                id: "app.titles.login",
              })}
            </Button>
            <div className="text-center mt-2">
              <Link to={NonAuthRoutes.ForgotPassword}>
                {intl.formatMessage({
                  id: "app.forms.login-form.forget-password-message",
                })}
              </Link>
            </div>
          </form>
        </Card>
      </div>
      <SponsorshipStatement />
    </>
  );
};

export default LoginForm;
