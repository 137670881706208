import { createAction, createAsyncAction } from "typesafe-actions";
import { AttachedResource, UpsertResourceDialogConfig } from "../../types";
import {
  AddResourceRequest,
  UpdateResourceRequest,
} from "../../api/resources/types";

export const showUpsertResourceDialog = createAction(
  "@resources/SHOW_UPSERT_RESOURCE_DIALOG"
)<UpsertResourceDialogConfig>();
export const hideUpsertResourceDialog = createAction(
  "@resources/HIDE_UPSERT_RESOURCE_DIALOG"
)<void>();

export const getResources = createAsyncAction(
  "@resources/GET_RESOURCES_REQUEST",
  "@resources/GET_RESOURCES_SUCCESS",
  "@resources/GET_RESOURCES_FAILURE"
)<number, AttachedResource[], Error>();

export const addResource = createAsyncAction(
  "@resources/ADD_RESOURCE_REQUEST",
  "@resources/ADD_RESOURCE_SUCCESS",
  "@resources/ADD_RESOURCE_FAILURE"
)<
  { resource: AddResourceRequest; planId: number; onSuccess?: Function },
  AttachedResource,
  Error
>();

export const updateResource = createAsyncAction(
  "@resources/UPDATE_RESOURCE_REQUEST",
  "@resources/UPDATE_RESOURCE_SUCCESS",
  "@resources/UPDATE_RESOURCE_FAILURE"
)<
  { resource: UpdateResourceRequest; planId: number; onSuccess?: Function },
  AttachedResource,
  Error
>();

export const deleteResource = createAsyncAction(
  "@resources/DELETE_RESOURCE_REQUEST",
  "@resources/DELETE_RESOURCE_SUCCESS",
  "@resources/DELETE_RESOURCE_FAILURE"
)<
  { resourceId: number; planId: number; onSuccess?: Function },
  number,
  Error
>();
