import { Button, Classes, Dialog, Icon } from "@blueprintjs/core";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { UpdatePlanStrategyImplementationStatusRequest } from "../../../../api/plan-strategies/types";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../../helpers/toaster";
import {
  updatePlanStrategyImplementation,
  updatePlanStrategyImplementationStatus,
} from "../../../../store/plan-strategies/actions";
import { hideImplementationStatusDialog } from "../../../../store/strategies/actions";
import { LoggedStrategyLookForStatus } from "../../../../types";
import PlanStrategyCreator from "../add-plan-strategy-dialog/PlanStrategyCreator";

type OwnProps = {};

type Props = OwnProps;

const UpdateImplementationStatusDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { show, selectedStrategy, onConfirm, implementation } = useSelector(
    (s) => s.strategies.dialogs.updateImplementationStatusDialog
  );

  const [date, setDate] = useState<Date | undefined>();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const handleClose = () => {
    dispatch(hideImplementationStatusDialog());
    setDate(undefined);
    setStatuses([]);
  };

  const onSuccess = () => {
    AppToaster.show({
      icon: "tick",
      intent: "success",
      message: intl.formatMessage(
        { id: "app.plan-strategies.update-implementation-status-success" },
        { name: selectedStrategy?.strategy?.name }
      ),
    });
    onConfirm && onConfirm();
    handleClose();
  };
  const updateImplementationLoading = useSelector(
    (s) => s.planStrategies.loading.updatePlanStrategyImplementation
  );
  const updateImplementationError = useSelector(
    (s) => s.planStrategies.errors.updatePlanStrategyImplementation
  );
  useLoading({
    loading: updateImplementationLoading,
    error: updateImplementationError,
    onSuccess: onSuccess,
  });

  const loading = useSelector(
    (s) => s.planStrategies.loading.updatePlanStrategyImplementationStatus
  );
  const error = useSelector(
    (s) => s.planStrategies.errors.updatePlanStrategyImplementationStatus
  );
  useLoading({ loading: loading, error: error, onSuccess: onSuccess });

  const [statuses, setStatuses] = useState<
    Omit<LoggedStrategyLookForStatus, "id" | "created_at" | "updated_at">[]
  >([]);

  const handleUpdateImplementationStatusesClick = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (selectedStrategy && activePlanId) {
      const body: UpdatePlanStrategyImplementationStatusRequest = {
        plan_strategy_id: selectedStrategy.id,
        date: moment(date).toISOString(),
        statuses: statuses,
      };
      if (implementation) {
        dispatch(
          updatePlanStrategyImplementation.request({
            body: body,
            implementation: implementation,
          })
        );
      } else {
        dispatch(updatePlanStrategyImplementationStatus.request(body));
      }
    }
  };

  const formId = useMemo(() => "update-statuses", []);

  return (
    <Dialog
      className="w-2/3"
      isOpen={show}
      onClose={handleClose}
      style={{ zIndex: 99999 }}
      icon={<Icon icon="briefcase" iconSize={24} />}
      title={selectedStrategy?.strategy?.name}
      enforceFocus={false}
      portalClassName="update-implementation-status-portal"
    >
      <div className={Classes.DIALOG_BODY}>
        {selectedStrategy && (
          <form id={formId} onSubmit={handleUpdateImplementationStatusesClick}>
            <PlanStrategyCreator
              strategy={selectedStrategy}
              statuses={statuses}
              setStatuses={setStatuses}
              date={date}
              onDateChange={(date) => setDate(date)}
              implementation={implementation}
            />
          </form>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between mt-4">
          <Button className="w-1/4" onClick={handleClose} type={"reset"}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            className="w-1/4"
            intent="primary"
            loading={loading || updateImplementationLoading}
            type="submit"
            form={formId}
            disabled={!selectedStrategy && !activePlanId}
          >
            {intl.formatMessage({
              id: "app.titles.save",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateImplementationStatusDialog;
