import React, { FunctionComponent, useRef } from "react";
import { Button, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
} from "../../../types";
import { AppToaster } from "../../../helpers/toaster";
import EnterUrlDialouge from "../../pages/strategic-plan/strategies/edit-core-components/sci-writing/CreateNotes/EnterUrlDialouge";
import { useSelector } from "react-redux";

type OwnProps = {
  isPlanResources?: boolean;
  isVertical?: boolean;
  onClick: (
    resource: Partial<AttachedResource> & Pick<AttachedResource, "type">
  ) => void;
  attachedToType: AttachedToType;
  hideUrl?: boolean;
};

type Props = OwnProps;

const AddResourcesSciWritingButtons: FunctionComponent<Props> = (props) => {
  const {
    isPlanResources,
    isVertical,
    onClick,
    attachedToType,
    hideUrl,
  } = props;

  const intl = useIntl();

  const uploadFileBtnRef = useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => {
    
    uploadFileBtnRef.current?.click();
  };
  const {
    show,
    onClose,
    onSave,
    resource,
    isLoadingSelector,
    errorSelector,
  } = useSelector((s) => s.resources.dialogs.upsertResourceDialog);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    const fileList = e.target.files;
    if (fileList) {
      const files: File[] = Array.from(fileList);
      const acceptedTypes = [
        ".doc",
        ".docx",
        ".pdf",
        ".jpg",
        ".jpeg",
        ".png",
        ".csv",
        ".xlsx",
        ".ppt",
        ".pptx",
      ];

      for (let { name } of files) {
        let resourceFileType = name.slice(name.lastIndexOf("."));

        if (acceptedTypes.includes(resourceFileType)) {
          const attachedResources: Array<
            Pick<AttachedResource, "title" | "attachment" | "type">
          > = files.map((file) => ({
            title: file.name,
            attachment: file,
            type: AttachedResourceType.Upload,
            attached_to_type: attachedToType,
          }));
          onClick(attachedResources[0]);
        } else {
          // files.filter((sr) =>{ sr.id!=id}))
          AppToaster.show({
            message: intl.formatMessage({
              id:
                "app.resources.add-resources.not-supported-file-error.message",
            }),
            intent: "danger",
          });
        }
      }
    }
    e.target.value = "";
  };

  const handleEnterUrlClick = () => {
    onClick({
      type: AttachedResourceType.Link,
      attached_to_type: attachedToType,
    });
  };

  return (
    <>
      {" "}
      {isPlanResources ? (
        <>
          <button
            className="m-2"
            // icon="document"
            // intent="primary"
            onClick={handleFileUploadClick}
            // text={intl.formatMessage({ id: "app.resources.upload-file" })}
            title={intl.formatMessage({ id: "app.resources.upload-file" })}
          >
            {intl.formatMessage({ id: "app.resources.upload-file" })}s
            <input
              ref={uploadFileBtnRef}
              style={{ display: "none" }}
              type="file"
              accept=".doc, .docx, .pdf, .jpg, .jpeg .png, .csv, .xlsx, .ppt, .pptx"
              onChange={handleFileChange}
            />
          </button>

          {!hideUrl && (
             <EnterUrlDialouge />
          )}
        </>
      ) : (
        <div >
          {/* className={`-m-2 flex ${isVertical ? "flex-col" : ""}`} */}
          <button
            className="stroke-btn"
            style={{marginBottom:'1px !important'}}
            onClick={handleFileUploadClick}
            // text={intl.formatMessage({ id: "app.resources.upload-file" })}
            title={intl.formatMessage({ id: "app.resources.upload-file" })}
          >
            {intl.formatMessage({ id: "app.resources.upload-file" })}
            <input
              ref={uploadFileBtnRef}
              style={{ display: "none" }}
              type="file"
              accept=".doc, .docx, .pdf, .jpg, .jpeg .png, .csv, .xlsx, .ppt, .pptx"
              onChange={handleFileChange}
            />
          </button>

          {!hideUrl && (
           <EnterUrlDialouge onEnterUrlClick={handleEnterUrlClick}  />)}
        </div>
      )}
    </>
  );
};

export default AddResourcesSciWritingButtons;