import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getActivitiesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getActivities.request)),
    switchMap((action) =>
      from(
        api.activities.getActivities(action.payload.type, action.payload.tag)
      ).pipe(
        map(actions.getActivities.success),
        catchError((error) => of(actions.getActivities.failure(error)))
      )
    )
  );
};

export const saveActivityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.saveActivity.request)),
    switchMap((action) =>
      from(api.activities.saveActivity(action.payload)).pipe(
        map(actions.saveActivity.success),
        catchError((error) => of(actions.saveActivity.failure(error)))
      )
    )
  );
};

export const updateActivityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateActivity.request)),
    switchMap((action) =>
      from(
        api.activities.updateActivity(action.payload.id, action.payload.body)
      ).pipe(
        map(actions.updateActivity.success),
        catchError((error) => of(actions.updateActivity.failure(error)))
      )
    )
  );
};

export const deleteActivityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteActivity.request)),
    switchMap((action) =>
      from(api.activities.deleteActivity(action.payload)).pipe(
        map(() => actions.deleteActivity.success(action.payload)),
        catchError((error) => of(actions.deleteActivity.failure(error)))
      )
    )
  );
};

export const getActivityByNameEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getActivityByName.request)),
    switchMap((action) =>
      from(api.activities.getActivityByName(action.payload.name)).pipe(
        mergeMap((activities) =>
          from(
            [actions.getActivityByName.success(activities[0])],
            action.payload.onComplete &&
              action.payload.onComplete(activities[0])
          )
        ),
        catchError((error) => of(actions.getActivityByName.failure(error)))
      )
    )
  );
};

export const getActivityByIdEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getActivityById.request)),
    switchMap((action) =>
      from(api.activities.getActivityById(action.payload)).pipe(
        map(actions.getActivityById.success),
        catchError((error) => of(actions.getActivityById.failure(error)))
      )
    )
  );
};
