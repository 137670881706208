import React, { useCallback, useMemo } from "react";
import { CompletedDomain, SurveyQuestion } from "../../../types";
import { Locale } from "../../../store/UIState";
import LeadInSticky from "./LeadInSticky";
import { SurveyFormRefs } from "./SurveyForm";

type OwnProps = {
  stickyVisible: boolean;
  surveyQuestion: SurveyQuestion<number, CompletedDomain>;
  locale: Locale;
  refs?: SurveyFormRefs;
};

type Props = OwnProps;

const SurveyQuestionCardHeader: React.FC<Props> = ({
  surveyQuestion,
  stickyVisible,
  locale,
  refs,
}: Props) => {
  const getLocalizedQuestionLeadIn = useCallback(() => {
    return (
      surveyQuestion.translations?.[locale]?.lead_in?.replaceAll(/\s/g, " ") ??
      surveyQuestion.lead_in?.replaceAll(/\s/g, " ")
    );
  }, [surveyQuestion]);

  const questionLeadInRef = useMemo(() => {
    return refs?.leadIns?.find(
      (leadInRef) => leadInRef.sqId === surveyQuestion.id
    )?.ref;
  }, [refs]);

  const questionSeparatorRef = useMemo(() => {
    return refs?.separators?.find(
      (separatorRef) => separatorRef.sqId === surveyQuestion.id
    )?.ref;
  }, [refs]);

  if (questionLeadInRef) {
    if (stickyVisible) {
      return (
        <div ref={questionLeadInRef}>
          <LeadInSticky translatedLeadIn={getLocalizedQuestionLeadIn() ?? ""} />
        </div>
      );
    } else {
      return (
        <div
          ref={questionLeadInRef}
          className="text-xl sm:text-2xl ml-4 my-4 font-bold"
        >
          {getLocalizedQuestionLeadIn() ?? ""}
        </div>
      );
    }
  }

  if (questionSeparatorRef) {
    return (
      <div
        ref={questionSeparatorRef}
        className="border-2 border-gray-600 my-8"
      />
    );
  }

  return <></>;
};

export default SurveyQuestionCardHeader;
