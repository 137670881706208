import React, { useMemo } from "react";
import { Button, Icon } from "@blueprintjs/core";
import moment from "moment";
import { useWindowUnload } from "../../helpers/hooks/useWindowUnload";
import { Timer } from "../../types";
import NjSciLogo from "../common/NjSciLogo";

type OwnProps = {
  onReattemptSurveyClick: (isCompletedSurvey: boolean) => void;
  onUpdateTimer: (timer: Timer) => void;
  schoolName?: string;
  demographic?: string;
  instruction?: string;
};

type Props = OwnProps;

const FinishSurveyPage: React.FC<Props> = (props: Props) => {
  const { onReattemptSurveyClick, onUpdateTimer, instruction } = props;

  const timer = useMemo<Timer>(
    () => ({
      page: "post-complete",
      time_start: moment().format(),
    }),
    []
  );

  const setEndDateToTimer = () => {
    onUpdateTimer({ ...timer, time_end: moment().format() });
  };
  useWindowUnload({ onBeforeUnload: setEndDateToTimer });

  return (
    <div>
      <div className="my-4">
        <div dangerouslySetInnerHTML={{ __html: instruction ?? "" }} />
        <div className="flex justify-center">
          {/* This is temporary; Prompt editor interface is still buggy and I couldn't do it there */}
          <a target="_blank" href="https://njschoolclimate.org/">
            <NjSciLogo />
          </a>
        </div>
      </div>

      <div className="flex justify-center my-4">
        <Button
          intent="primary"
          className="w-1/3 mt-4 p-4"
          onClick={() => onReattemptSurveyClick(false)}
          icon={<Icon icon={"tick-circle"} iconSize={36} />}
        />
      </div>
    </div>
  );
};

export default FinishSurveyPage;
