import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  getBooleanFromSessionStorage,
  getNumberFromSessionStorage,
  SessionStorageKeys,
} from "../../../constants/session-storage";
import { useIntl } from "react-intl";

type Props = {
  totalSteps: number;
  currentStep: number;
  onClick: (page: number) => void;
};

const Stepper: React.FC<Props> = (props) => {
  const { totalSteps, currentStep, onClick } = props;

  const intl = useIntl();

  const [maxPageVisited, setMaxPageVisited] = useState<number>(
    getNumberFromSessionStorage(SessionStorageKeys.MaxSurveyPageVisited) ?? 0
  );

  useEffect(() => {
    if (currentStep > maxPageVisited) {
      setMaxPageVisited(currentStep);
      sessionStorage.setItem(
        SessionStorageKeys.MaxSurveyPageVisited,
        JSON.stringify(currentStep)
      );
    }
  }, [totalSteps, currentStep]);

  const handleStepClick = (stepNum: number) => () => {
    if (stepNum <= maxPageVisited) {
      onClick(stepNum);
    }
  };

  return (
    <div role="navigation" className="stepper">
      {_.range(0, totalSteps + 1).map((stepNum) => {
        return stepNum < currentStep ? (
          <React.Fragment key={stepNum}>
            <div
              role="button"
              tabIndex={-1}
              aria-label={intl.formatMessage(
                {
                  id: "app.survey.aria.navigation.page-button",
                },
                { pageNumber: stepNum + 1 }
              )}
              className={`step completed ${
                stepNum <= maxPageVisited ? "cursor-pointer" : ""
              }`}
              onClick={handleStepClick(stepNum)}
            />
            {stepNum !== totalSteps! && (
              <hr className={"line green"} aria-hidden={true} />
            )}
          </React.Fragment>
        ) : stepNum === currentStep ? (
          <React.Fragment key={stepNum}>
            <div
              role="button"
              tabIndex={-1}
              aria-label={intl.formatMessage(
                {
                  id: "app.survey.aria.navigation.page-button.current_page",
                },
                { pageNumber: stepNum + 1 }
              )}
              className={`step active`}
            />
          </React.Fragment>
        ) : (
          <React.Fragment key={stepNum}>
            <hr className={"line grey"} aria-hidden={true} />
            <div
              role="button"
              tabIndex={-1}
              aria-label={intl.formatMessage(
                {
                  id: "app.survey.aria.navigation.page-button",
                },
                { pageNumber: stepNum + 1 }
              )}
              className={`step pending ${
                stepNum <= maxPageVisited ? "cursor-pointer" : ""
              }`}
              onClick={handleStepClick(stepNum)}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
