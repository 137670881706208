import { NonIdealState, Spinner } from "@blueprintjs/core";
import moment from "moment";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { CellProps } from "react-table";
import { UserActionAudit } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getUserActionAudit } from "../../../store/users/actions";

import EntityTable from "../../common/EntityTable";
import DownloadActionAudit from "./DownloadActionAudit";

type OwnProps = {
  actionAudit: UserActionAudit[];
  customSetPageSize?: (page: number) => void;
  customPageCount: number;
  customGoToPage?: (page: number) => void;
  customPageIndex?: number;
  customPageSize?: number;
};

type Props = OwnProps;

const ActionAuditLogTable: FunctionComponent<Props> = (props) => {
  const {
    actionAudit,
    customSetPageSize,
    customGoToPage,
    customPageCount,
    customPageIndex,
    customPageSize,
  } = props;
  

  const dispatch = useDispatch();
  const loading = useSelector((s) => s.users.loading.getUserLoginHistory);
  const error = useSelector((s) => s.users.errors.getUserLoginHistory);

  const intl = useIntl();
  const [searchString, setSearchString] = useState<string>("");

  const callbackFilter = useCallback(
    (history: UserActionAudit) => {
      const searchStringLowerCase = searchString.trim().toLowerCase();

      if (!searchStringLowerCase) {
        return true;
      }

      return (
        history?.created_at?.toLowerCase()?.includes(searchStringLowerCase) ||
        history?.district?.name
          ?.toLowerCase()
          ?.includes(searchStringLowerCase) ||
        history?.role?.toLowerCase()?.includes(searchStringLowerCase) ||
        history?.school?.toLowerCase()?.includes(searchStringLowerCase) ||
        history?.action_type?.toLowerCase()?.includes(searchStringLowerCase) ||
        history?.targets?.toLowerCase()?.includes(searchStringLowerCase)
      );
    },
    [searchString]
  );

  const filteredData = useMemo(() => {
    return actionAudit.filter(callbackFilter);
  }, [actionAudit, callbackFilter]);

  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({
          id: "app.login-history.action-audit.date",
        }),
        width: "10%",
        accessor: "date",
        Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
          return (
            <p className="flex whitespace-no-wrap">
              {moment(original.created_at).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.login-history.action-audit.time",
        }),
        width: "5%",
        accessor: "time",
        Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
          return (
            <p className="flex whitespace-no-wrap">
              {moment(original.created_at).format("HH:mm")}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.invites-table.cells.district" }),
        width: "20%",
        accessor: "district",
        Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
          return <p className="flex">{original.district}</p>;
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.school" }),
        width: "15%",
        accessor: "district_school",
        Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
          return (
            <p className="flex capitalize">
              {original.action_type.toLowerCase() === "soft data delete" || original.action_type.toLowerCase() === "generate reports"
                ? original.school_in_which_action_done 
                : original.action_type.toLowerCase() === "confirmed prioritized domains" || original.action_type.toLowerCase() === "school deletion"? original.school_in_which_action_done :""}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.district-school-role" }),
        width: "10%",
        accessor: "district_school_role",
        Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
          return (
            <p className="flex capitalize">
              {original.role}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.login-history.action-audit.action-type",
        }),
        width: "20%",
        wrap: true,
        accessor: "action_type",
        Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
          return (
            <p className="flex whitespace-no-wrap capitalize">
              {original.action_type}
            </p>
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.login-history.action-audit.target",
        }),
        width: "20%",
        accessor: "admin",
        Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
          return (
            <p className="flex">
              {" "}
              {original.action_type.toLowerCase() !== "comparison report check boxes"
                ? original.targets
                : ""}
            </p>
          );
        },
      },
      // {
      //   Header: intl.formatMessage({
      //     id: "parameter 1",
      //   }),
      //   width: "15%",
      //   accessor: "parameter 1",
      //   Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
      //     return <p className="flex whitespace-no-wrap"></p>;
      //   },
      // },
      // {
      //   Header: intl.formatMessage({
      //     id: "parameter 2",
      //   }),
      //   width: "15%",
      //   accessor: "parameter 2",
      //   Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
      //     return <p className="flex whitespace-no-wrap"></p>;
      //   },
      // },
      // {
      //   Header: intl.formatMessage({
      //     id: "parameter 3",
      //   }),
      //   width: "15%",
      //   accessor: "parameter 3",
      //   Cell: ({ row: { original } }: CellProps<UserActionAudit>) => {
      //     return <p className="flex whitespace-no-wrap"></p>;
      //   },
      // },
    ];
  }, []);

  const noUsers = useMemo(
    () => (
      <NonIdealState
        icon="zoom-out"
        title={intl.formatMessage({
          id: "app.non-ideal-state.action-audit-log",
        })}
      />
    ),
    []
  );

  if (loading) {
    return <Spinner intent="primary" className="mt-4" />;
  }

  return (
    <EntityTable
      data={filteredData}
      columns={columns}
      searchString={searchString}
      setSearchString={setSearchString}
      loading={false}
      noDataComp={noUsers}
      customSetPageSize={customSetPageSize}
      customGoToPage={customGoToPage}
      customPageCount={customPageCount}
      customPageIndex={customPageIndex}
      customPageSize={customPageSize}
      customPagination={true}
    >
      <div className="flex items-center ml-2">
        <DownloadActionAudit actionAudit={actionAudit} />
      </div>
    </EntityTable>
  );
};

export default ActionAuditLogTable;
