import React, { useMemo } from "react";
import {
  GroupedAnswerStatistics,
  SurveyDemographic,
  SurveyQuestionStatistics,
} from "../../../../types";
import HorizontalBarChart from "../../../charts/HorizontalBarChart";
import { useIntl } from "react-intl";
import { selectIsAdmin } from "../../../../store/auth/selectors";
import { useSelector } from "react-redux";
import GroupedItemFilterLabel from "./grouped-item-filter/GroupedItemFilterLabel";
import useDatasetStatistic from "./useDatasetStatistic";
import QuestionShortCode from "./QuestionShortCode";
import DiscrepancyPopover from "./DiscrepancyPopover";
import useDiscrepancy from "./useDiscrepancy";
import useHighlightedText from "../../../../helpers/hooks/useHighlightedText";
import TotalRespondentsCell from "./TotalRespondentsCell";

type OwnProps = {
  domainId?: number;
  maxAnswerIndex: number;
  surveyQuestionStatistics: SurveyQuestionStatistics<GroupedAnswerStatistics[]>;
  index: number;
  isDisaggregation?: boolean;
  demographic: SurveyDemographic;
  showTotalRespondentsColumn: boolean;
  inReportInsights?: boolean;
  surveyDeploymentId?: number;
  manageBookmarkDialog?: boolean;
};

type Props = OwnProps;

const GroupedDomainStatisticsTableRow: React.FC<Props> = (props: Props) => {
  const {
    domainId,
    maxAnswerIndex,
    surveyQuestionStatistics,
    index,
    demographic,
    isDisaggregation,
    showTotalRespondentsColumn,
    surveyDeploymentId,
    inReportInsights,
    manageBookmarkDialog,
  } = props;
  const selectedDomain = useSelector((s) => s.domains.selectedDomain);

  const isRedactedDataCollapsed = useSelector(
    (s) => s.surveyReports.isRedactedDataCollapsed
  );

  const intl = useIntl();

  const isAuthenticatedAdmin = useSelector<boolean>(selectIsAdmin);

  const nonIdealStateMessage = useMemo(() => {
    return intl.formatMessage({
      id: "app.non-ideal-state.survey-reports.not-enough-respondents",
    });
  }, []);

  const groupedAnswerStatistics = useMemo(() => {
    return surveyQuestionStatistics?.[demographic];
  }, [surveyQuestionStatistics, demographic]);

  const {
    dataSet,
    redactedStatistics,
    hasNotEnoughData,
    params,
    doubleDisaggregation,
  } = useDatasetStatistic({
    groupedAnswerStatistics,
    nonIdealStateMessage,
    maxAnswerIndex,
    manageBookmarkDialog,
  });

  const itemText: string = useMemo(() => {
    if (
      surveyQuestionStatistics.lead_in &&
      /[a-z]/.test(surveyQuestionStatistics.text.charAt(0))
    ) {
      return `${surveyQuestionStatistics.lead_in} ${surveyQuestionStatistics.text}`;
    }

    return surveyQuestionStatistics.text;
  }, [surveyQuestionStatistics.lead_in, surveyQuestionStatistics.text]);

  const {
    hasDiscrepancy,
    rowsWithDiscrepancy,
    showDiscrepancyPopover,
    onMouseLeave,
    onMouseEnter,
  } = useDiscrepancy({
    items: dataSet?.data,
  });

  const { highlightedText } = useHighlightedText({
    text: itemText,
  });
  const keyHorizontalBarChart = useMemo(() => {
    return inReportInsights ? 0 : 1;
  }, [inReportInsights]);
  return (
    <tr onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <td
        className={`text-xl ${
          hasDiscrepancy && isDisaggregation && inReportInsights
            ? "has-discrepancy"
            : "bg-gray-200"
        }`}
        colSpan={2}
      >
        {surveyQuestionStatistics.short_code && (
          <QuestionShortCode
            shortCode={surveyQuestionStatistics.short_code}
            deletedIcon={surveyQuestionStatistics?.deleted_in_njsci_v2}
          />
        )}

        <DiscrepancyPopover
          show={showDiscrepancyPopover}
          disabled={!hasDiscrepancy || !isDisaggregation || !inReportInsights}
        >
          <div
            className="font-bold"
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          />
        </DiscrepancyPopover>
      </td>

      {groupedAnswerStatistics?.length ? (
        <>
          <td style={{ minWidth: 150 }}>
            {!!dataSet?.displayedLabels && (
              <table cellSpacing={0} cellPadding={0}>
                <tbody>
                  {dataSet.displayedLabels?.map(
                    (labels: string | string[], index: number) => {
                      const hasDiscrepancy = rowsWithDiscrepancy.includes(
                        index
                      );
                      return (
                        <GroupedItemFilterLabel
                          key={index}
                          labels={labels}
                          domainId={domainId!}
                          notEnoughData={dataSet?.data?.[index] == null}
                          questionId={surveyQuestionStatistics.question_id}
                          demographic={demographic}
                          params={params[index]}
                          overallMean={dataSet?.data?.[index]}
                          className={
                            hasDiscrepancy && !doubleDisaggregation
                              ? "has-discrepancy"
                              : undefined
                          }
                          labelsWithDetail={dataSet?.labelsWithDetail}
                          doubleDisaggregation={doubleDisaggregation}
                        />
                      );
                    }
                  )}
                </tbody>
              </table>
            )}
          </td>
          <td
            colSpan={isAuthenticatedAdmin && showTotalRespondentsColumn ? 1 : 2}
            style={{ maxWidth: 200 }}
            key={keyHorizontalBarChart}
          >
            <div className="w-full">
              {!!dataSet && (
                <HorizontalBarChart
                  index={index}
                  demographic={demographic}
                  maxAnswerIndex={maxAnswerIndex}
                  dataSet={dataSet}
                  inReportInsights={inReportInsights}
                  isDisaggregation={isDisaggregation}
                  displayGroupedLabels
                  nonIdealStateMessage={nonIdealStateMessage}
                  domainId={selectedDomain?.id}
                  questionId={surveyQuestionStatistics.question_id}
                  rowsWithDiscrepancy={rowsWithDiscrepancy}
                  color={selectedDomain?.color_hex}
                  doubleDisaggregation={doubleDisaggregation}
                />
              )}
            </div>
          </td>
          {isAuthenticatedAdmin && showTotalRespondentsColumn && (
            <TotalRespondentsCell<GroupedAnswerStatistics>
              totalKey={"count"}
              redactedStatistics={redactedStatistics || []}
              rowsWithDiscrepancy={rowsWithDiscrepancy}
              showExtraSpan={isRedactedDataCollapsed && hasNotEnoughData}
            />
          )}
        </>
      ) : (
        <td colSpan={3}>
          <p className="text-center">
            {intl.formatMessage({
              id: "app.non-ideal-state.survey-reports.not-enough-respondents",
            })}
          </p>
        </td>
      )}
    </tr>
  );
};

export default GroupedDomainStatisticsTableRow;
