import React, { useState } from "react";
import { Button, Tooltip } from "@blueprintjs/core";
import { useIntl } from "react-intl";

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useLockButton = () => {
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();

  const disabled = false;

  const lockButton: JSX.Element = (
    <InputAdornment position="end">
      <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={() => setShowPassword(!showPassword)}
          edge="end"
          >
          {showPassword ? 
          <VisibilityOff />
          : 
          <Visibility />
          }
      </IconButton>
    </InputAdornment>
  );

  return [lockButton, showPassword];
};

export default useLockButton;
