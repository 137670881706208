import { GraphTypeEnum, PlanStrategy } from "../../../../types";
import "./ProgressMonitoring.scss";
import { FunctionComponent, useMemo } from "react";
import IndicatorGraph from "./graph-content/IndicatorGraph";
import RespondentGroupGraph from "./graph-content/RespondentGroupGraph";
import NoGraphData from "./graph-content/NoGraphData";

type OwnProps = {
  graphType?: string;
  selectedRespondentGroup?: string;
  selectedGoal?: any;
  selectedDateRange?: any;
  selectedMultiDomains?: any;
  selectedAlignedStrategy?: any;
  selectedMultiSurveyItem?: any;
  saveFilter: boolean;
  alignStrategies?: PlanStrategy[];
  selectedMultiRespondentGroup?: string[];
  selectedDomain?: string;
  selectedSurveyItem?: string;
};

type Props = OwnProps;

const ProgressContent: FunctionComponent<Props> = (props) => {
  const {
    graphType,
    selectedRespondentGroup,
    selectedGoal,
    selectedDateRange,
    selectedMultiDomains,
    selectedAlignedStrategy,
    selectedMultiSurveyItem,
    saveFilter,
    alignStrategies,
    selectedMultiRespondentGroup,
    selectedDomain,
    selectedSurveyItem,
  } = props;

  const renderGraph = useMemo(() => {
    const noGraphData = (
      <NoGraphData />
    );

    const indicatorGraph = (
      <IndicatorGraph
        selectedMultiRespondentGroup={selectedMultiRespondentGroup}
        selectedGoal={selectedGoal}
        selectedDateRange={selectedDateRange}
        selectedDomain={selectedDomain}
        selectedAlignedStrategy={selectedAlignedStrategy}
        selectedSurveyItem={selectedSurveyItem}
        saveFilter={saveFilter}
        alignStrategies={alignStrategies}
      />
    );

    const respondentGroupGraph = (
      <RespondentGroupGraph
        selectedRespondentGroup={selectedRespondentGroup}
        selectedGoal={selectedGoal}
        selectedDateRange={selectedDateRange}
        selectedMultiDomains={selectedMultiDomains}
        selectedAlignedStrategy={selectedAlignedStrategy}
        selectedMultiSurveyItem={selectedMultiSurveyItem}
        saveFilter={saveFilter}
        alignStrategies={alignStrategies}
      />
    );

    if (
      (selectedDateRange.length === 0 ||(selectedMultiDomains.length === 0 && selectedMultiSurveyItem.length === 0) )
    ) {
      return noGraphData;
    }

    return (
      <>
        {graphType === GraphTypeEnum.INDICATOR_GRAPH ? indicatorGraph : respondentGroupGraph}
      </>
    );
  }, [saveFilter, graphType]);


  return <>{renderGraph}</>;
};

export default ProgressContent;
