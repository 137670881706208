import React, { FunctionComponent, useEffect } from "react";
import { Button, FormGroup } from "@blueprintjs/core";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getDomainAnswerSets } from "../../../store/domain-answer-set/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { useIntl } from "react-intl";
import { DomainAnswerSet } from "../../../types";
import AnswerSetItem from "./AnswerSetItem";

type OwnProps = {
  domainAnswerSets: DomainAnswerSet[];
  onCustomize: Function;
  selectedDomainAnswerSet?: DomainAnswerSet;
  setSelectedDomainAnswerSet: React.Dispatch<
    React.SetStateAction<DomainAnswerSet | undefined>
  >;
};

type Props = OwnProps;

const DomainAnswerSetSelector: FunctionComponent<Props> = (props) => {
  const {
    domainAnswerSets,
    selectedDomainAnswerSet,
    setSelectedDomainAnswerSet,
    onCustomize,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleAnswerSetChange = (selectedAnswerSet: any) => {
    setSelectedDomainAnswerSet(selectedAnswerSet);
  };

  useEffect(() => {
    dispatch(getDomainAnswerSets.request());
  }, []);
  const loading = useSelector(
    (s) => s.domainsAnswerSet.loading.getDomainAnswerSets
  );
  const error = useSelector(
    (s) => s.domainsAnswerSet.errors.getDomainAnswerSets
  );
  useLoading({ loading, error });

  return (
    <FormGroup
      className="mb-0"
      label={
        <div
          className="flex justify-between items-end"
          onClick={(e) => e.preventDefault()}
        >
          {intl.formatMessage({
            id: "app.forms.domain-form.select-existing-answer-choices",
          })}

          <Button
            intent="primary"
            onClick={() => {
              onCustomize();
            }}
          >
            {intl.formatMessage({
              id: selectedDomainAnswerSet
                ? "app.forms.domain-answer-set.customize"
                : "app.forms.domain-answer-set.create-new",
            })}
          </Button>
        </div>
      }
    >
      <Select
        isSearchable={false}
        isClearable
        isLoading={loading}
        value={selectedDomainAnswerSet as any}
        getOptionValue={(domainAnswerSet) => domainAnswerSet.id as any}
        onChange={handleAnswerSetChange}
        formatOptionLabel={(ans) => <AnswerSetItem domainAnswerSet={ans} />}
        options={domainAnswerSets}
        styles={{
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              color: "initial",
              backgroundColor: isFocused ? "rgba(19, 124, 189, 0.15)" : "",
            };
          },
          singleValue: (styles) => ({
            ...styles,
            top: "unset",
            transform: "unset",
            position: "initial",
            padding: "0.125rem",
            flex: 1,
          }),
          menu: (styles) => ({ ...styles, zIndex: 10 }),
        }}
      />
    </FormGroup>
  );
};

export default DomainAnswerSetSelector;
