import React, { FunctionComponent, useMemo } from "react";
import { Icon, Popover } from "@blueprintjs/core";
import { useIntl } from "react-intl";

type OwnProps = {
  iconSize?: number;
  forCard?: boolean;
  targetClassName?: string;
  targetStyle?: React.CSSProperties;
  isDistricComparison?: boolean;
};

type Props = OwnProps;

const NegativeDomainIcon: FunctionComponent<Props> = (props) => {
  const {
    iconSize = 24,
    forCard,
    targetClassName,
    targetStyle,
    isDistricComparison,
  } = props;

  const intl = useIntl();

  const title = useMemo(() => {
    return intl.formatMessage(
      {
        id: forCard
          ? isDistricComparison
            ? "app.domains.negative.card.district-info"
            : "app.domains.negative.card.info"
          : "app.domains.negative.info",
      },
      {
        b: (chunks) => <span className="font-bold">{chunks}</span>,
        br: <br />,
      }
    );
  }, [forCard]);

  return (
    <Popover
      popoverClassName={"max-w-140"}
      content={<div className={"p-2"}>{title}</div>}
      targetClassName={targetClassName}
      interactionKind={"hover"}
    >
      <Icon
        icon={"warning-sign"}
        iconSize={iconSize}
        intent={"warning"}
        style={targetStyle}
      />
    </Popover>
  );
};

export default NegativeDomainIcon;
