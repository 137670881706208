import React, { FunctionComponent, useCallback } from "react";
import { Button, NonIdealState } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { showDomainUpsertDialog } from "../../../store/domains/actions";

type OwnProps = {};

type Props = OwnProps;

const NoDomains: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const handleShowUpsertDomainDialog = useCallback(() => {
    dispatch(showDomainUpsertDialog({}));
  }, []);

  return (
    <NonIdealState
      icon="inbox"
      title={intl.formatMessage({ id: "app.forms.no-domains.title" })}
      description={intl.formatMessage({
        id: "app.forms.no-domains.description",
      })}
      action={
        <Button
          large
          className="capitalize ml-2"
          intent="primary"
          onClick={handleShowUpsertDomainDialog}
        >
          {intl.formatMessage({
            id: "app.domains.setup-new-domain",
          })}
        </Button>
      }
    />
  );
};

export default NoDomains;
