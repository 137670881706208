import React from "react";
import { ConnectedRouter } from "connected-react-router";
import applicationStore, { history } from "../../store";
import LocalizationProvider from "../localization/LocalizationProvider";
import { Provider } from "react-redux";

const withProviders = (Component: any) => ({
  store = applicationStore(),
  ...props
}: any) => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LocalizationProvider>
          <Component {...props} />
        </LocalizationProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default withProviders;
