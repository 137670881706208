import React, { FunctionComponent, useMemo } from "react";
import { Menu, MenuItem, Popover, Tag } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { Locale, LocaleDisplayedValues } from "../../store/UIState";
import { changeLocale } from "../../store/UIState/actions";
import { matchPath, useLocation } from "react-router";
import { AuthAndNonAuthRoutes } from "../../App";

type Props = {};

const LocaleSelector: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const isSurveyQuestionsPage = useMemo(
    () => !!matchPath(location.pathname, AuthAndNonAuthRoutes.SurveysQuestions),
    [location]
  );

  const selectedLocale = useSelector((s) => s.UIState.locale);

  const surveyLocale = useSelector((s) => s.UIState.surveyLocale);

  const items = useSelector((s) => s.UIState.filteredLanguages);

  const handleLocaleChange = (locale: Locale) => () => {
    dispatch(
      changeLocale({
        locale: locale,
        storageType: isSurveyQuestionsPage ? "sessionStorage" : "localStorage",
      })
    );
  };

  return (
    <Popover
      content={
        <Menu className="min-w-0 lang-menu-scroll">
          {items.map((locale) => (
            <MenuItem
              key={locale}
              text={LocaleDisplayedValues[locale]}
              active={
                locale ===
                (isSurveyQuestionsPage ? surveyLocale : selectedLocale)
              }
              onClick={handleLocaleChange(locale)}
            />
          ))}
        </Menu>
      }
    >
      <Tag className="uppercase font-bold" interactive>
        {
          LocaleDisplayedValues[
            isSurveyQuestionsPage ? surveyLocale : selectedLocale
          ]
        }
      </Tag>
    </Popover>
  );
};

export default LocaleSelector;
