import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Classes, Dialog, Icon, NonIdealState } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { hideDomainComparisonDialog } from "../../../store/survey-reports/actions";
import {
  AnswerSet,
  ChartDataSet,
  QuestionResponses,
  SurveyDemographic,
} from "../../../types";
import BarChart from "../../charts/BarChart";
import { Locale } from "../../../store/UIState";
import DomainAnswerSetContainer from "./DomainAnswerSetContainer";

type OwnProps = {};

type Props = OwnProps;

const BAR_COLORS = ["#8A2A0E", "#F5A877", "#1DD171", "#385624"];

const DomainComparisonDialog = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { show, responses, demographics } = useSelector(
    (s) => s.surveyReports.domainComparisonDialog
  );

  const selectedDomain = useSelector(
    (s) => s.domains.selectedDomain ?? responses?.domain
  );

  const getDomainDemographicAnswers = useCallback(
    (demographic: SurveyDemographic) => {
      const answer =
        selectedDomain?.domain_answer?.[demographic]?.[Locale.English];
      if (answer) {
        delete answer[-1];
      }
      return answer;
    },
    [selectedDomain]
  );

  const handleClose = () => {
    dispatch(hideDomainComparisonDialog());
  };

  const getBarDataSet = useCallback(
    (item?: QuestionResponses, demographicAnswers?: AnswerSet) => {
      let dataSet: ChartDataSet = { data: [], labels: [] };
      if (demographicAnswers) {
        Object.entries(demographicAnswers ?? {})?.forEach(
          (entry: [string, string]) => {
            dataSet = {
              data: [
                ...dataSet.data,
                Math.round(
                  item?.distribution?.find(
                    (d) => d.answer_index === Number(entry[0])
                  )?.percent ?? 0
                ),
              ],
              labels: [],
            };
          }
        );
      }
      return dataSet;
    },
    []
  );

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon="comparison" iconSize={24} />}
      style={{ width: "76rem" }}
      title={<span>{responses?.common_text ?? ""}</span>}
      canEscapeKeyClose
      canOutsideClickClose
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="text-center text-2xl font-bold px-4 py-3">
          Response Distribution for Related Items
        </div>
        <div className="text-center text-xl px-4 pb-8">
          <em>&ldquo;{responses?.common_text ?? ""}&rdquo;</em>
        </div>
        <table className="bp3-html-table bp3-html-table-bordered w-full table-fixed">
          <thead>
            <tr>
              {demographics?.map((d, index) => (
                <th key={index}>
                  <span className="font-semibold text-2xl flex justify-center">
                    {intl.formatMessage({
                      id: `app.filters.respondent-type.${d}`,
                    })}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {demographics?.map((d, index) => {
                const demographicResponses = responses?.respondents?.[d];
                return (
                  <td key={index} style={{ verticalAlign: "middle" }}>
                    {demographicResponses?.not_enough_data ? (
                      <div>
                        <NonIdealState
                          icon="warning-sign"
                          title={intl.formatMessage({
                            id:
                              "app.non-ideal-state.survey-reports.not-enough-respondents",
                          })}
                        />
                      </div>
                    ) : demographicResponses ? (
                      <BarChart
                        dataSet={getBarDataSet(
                          demographicResponses,
                          getDomainDemographicAnswers(d)
                        )}
                        barPercentage={0.8}
                        minimalDaraPercent={10}
                        height={250}
                        width={250}
                        colors={[...BAR_COLORS, ...BAR_COLORS]}
                        labelless
                      />
                    ) : (
                      <div>
                        <NonIdealState
                          icon="info-sign"
                          title={intl.formatMessage({
                            id:
                              "app.survey-report.comparison.no-comparable-item",
                          })}
                        />
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        <DomainAnswerSetContainer
          colors={BAR_COLORS}
          demographics={demographics}
          getDomainDemographicAnswers={getDomainDemographicAnswers}
        />
        <div className="px-4 py-2">
          <p>
            {intl.formatMessage({
              id: "app.survey-report.comparison.description",
            })}
          </p>
        </div>
      </div>
    </Dialog>
  );
};

export default DomainComparisonDialog;
