import React, { FunctionComponent } from "react";
import { InputGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { changeReportsSearchBarString } from "../../../../store/survey-reports/actions";

type OwnProps = {};

type Props = OwnProps;

const SurveyReportSearchBar: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const value = useSelector((s) => s.surveyReports.searchBarString);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(changeReportsSearchBarString(value));
  };

  return (
    <InputGroup
      value={value ?? ""}
      leftIcon="search"
      type="search"
      placeholder={intl.formatMessage({ id: "app.titles.search" }) + "..."}
      onChange={handleInputChange}
    />
  );
};

export default SurveyReportSearchBar;
