import _ from "lodash";
/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
function getObjectDiff(obj1:any, obj2:any) {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
}

export default {
    goal_create:'GOAL_CREATE',
    goal_update:'GOAL_UPDATE',
    goal_update_desired_outcome:'GOAL_UPDATE_DESIRED_OUTCOME',
    goal_update_statement:'GOAL_UPDATE_STATEMENT',
    goal_update_target_added:'GOAL_UPDATE_TARGET',
    goal_update_target_remove: 'GOAL_UPDATE_TARGET_REMOVE',
    goal_update_domain_remove:'GOAL_UPDATE_DOMAIN_REMOVE',
    goal_delete:'GOAL_DELETE',
    goal_target_create:'GOAL_TARGET_CREATE',
    goal_target_update:'GOAL_TARGET_UPDATE',
    goal_target_delete:'GOAL_TARGET_DELETE',
    note_editing:"IS_NOTE_EDITING",
    note_update:"UPDATE_NOTE",
    note_create:"CREATE_NOTE",
    note_delete:"DELETE_NOTE",
    object_diff: getObjectDiff
}