import { Button, Tooltip } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { PlanWorkspaces } from "../../../../types";
import { useDispatch } from "react-redux";
import { setNavigationWarning } from "../../../../store/notes/actions";
import { AuthRoutes } from "../../../../App";
import { useSelector } from "react-redux";

export const NotesSidebar = ({
  activePage,
  setActivePage,
}: {
  activePage: string;
  setActivePage: any;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const navigationWarning = useSelector(
    (s) => s.notes.dialogs.navigationWarning
  );


  const { workspace: currentWorkspaceKey } = useParams<{
    workspace?: string;
    setupStep?: string;
  }>();

  const handleNavigation = () => {
    // dispatch(setNavigationWarning({
    //   show:true,
    //   navigateTo:'dashboard'
    // }))
  };

  const handleNavigationToSCIWriting = () => {
    console.log(PlanWorkspaces.SCIWriting, "path");

    history.push(
      generatePath(AuthRoutes.YourPlan, {
        workspace: PlanWorkspaces.SCIWriting,
      } as any)
    );
  };

  const handleNavigationToPublishedNotes = useCallback(() => {
    if (navigationWarning?.haveUnsavedChanges) {
      dispatch(
        setNavigationWarning({
          show: true,
          navigateTo: PlanWorkspaces.PublishedNotes,
        })
      );
    } else {
      history.push(
        generatePath(AuthRoutes.YourPlan, {
          workspace: PlanWorkspaces.PublishedNotes,
        } as any)
      );
      // setActivePage(PlanWorkspaces.PublishedNotes);
    }
  }, [navigationWarning]);

  useEffect(() => {
    if (
      currentWorkspaceKey == PlanWorkspaces.PublishedNotes ||
      currentWorkspaceKey == PlanWorkspaces.SCIWriting
    )
      setActivePage(currentWorkspaceKey);
  }, [currentWorkspaceKey]);

  return (
    <div className="sci-leftbar">
      <ul>
        <li
          className={
            activePage == PlanWorkspaces.SCIWriting ? "activeItem" : ""
          }
        >
          <Tooltip title="Create or Edit Note" arrow placement="right">
            <Button
              onClick={() => {
                handleNavigationToSCIWriting();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
              >
                <path
                  d="M3.63672 6.47778H9.32705V7.80907H3.63672V6.47778Z"
                  fill="white"
                />
                <path
                  d="M3.59375 16.699H6.90051V18.0303H3.59375V16.699Z"
                  fill="white"
                />
                <path
                  d="M3.59375 13.3062H7.7595V14.616H3.59375V13.3062Z"
                  fill="white"
                />
                <path
                  d="M3.61523 10.0637H8.36075V11.3092H3.61523V10.0637Z"
                  fill="white"
                />
                <path
                  d="M10.1862 20.4782H1.57552V1.88261H11.9899V5.68336H15.7906V8.92573H17.0147L17.0145 4.30905L13.3642 0.658691H0.351562V21.6807H10.1862L10.1862 20.4782ZM12.9775 1.7323L15.9193 4.67405H12.9775V1.7323Z"
                  fill="white"
                />
                <path
                  d="M9.0918 19.1189L11.805 18.5198L9.69079 16.4058L9.0918 19.1189Z"
                  fill="white"
                />
                <path
                  d="M18.8253 11.9193C19.155 11.5903 19.3368 11.1525 19.337 10.6867C19.3373 10.2209 19.156 9.78287 18.8265 9.4535L18.7568 9.38367C18.4278 9.05455 17.9901 8.87329 17.5246 8.87329H17.5237C17.0579 8.87354 16.6202 9.05528 16.291 9.38504L9.97949 15.7096L12.5006 18.2307L18.8253 11.9193Z"
                  fill="white"
                />
              </svg>
            </Button>
          </Tooltip>
        </li>
        <li
          className={
            activePage == PlanWorkspaces.PublishedNotes ? "activeItem" : ""
          }
        >
          <Tooltip title="Published Notes" arrow placement="right">
            <Button
              onClick={() => {
                handleNavigationToPublishedNotes();
              }}
            >
              <svg viewBox="0 0 19 22" fill="none">
                <path
                  d="M10.9578 19.3072H2.77127C1.50576 19.3072 0.51709 18.3185 0.51709 17.053V3.21133C0.51709 1.94583 1.50576 0.957153 2.77127 0.957153H11.5112C11.9857 0.957153 12.3812 1.15492 12.6976 1.51078L14.9913 4.08142C15.2681 4.39776 15.3868 4.75374 15.3868 5.14916V12.1885C16.415 12.9794 17.0874 14.2053 17.0874 15.55C17.0874 16.5387 16.7315 17.4483 16.1777 18.1602L18.7087 20.6911C18.9065 20.8889 18.9065 21.2052 18.7087 21.403C18.5109 21.6008 18.1946 21.6008 17.9968 21.403L15.4658 18.872C14.754 19.4257 13.8444 19.7817 12.8952 19.7817C12.1833 19.7817 11.511 19.6234 10.9574 19.3071L10.9578 19.3072ZM11.9465 2.18318V4.04198C11.9465 4.47702 12.342 4.8724 12.7769 4.8724H14.3192C14.2797 4.83288 14.2797 4.79336 14.2401 4.75383L11.9465 2.18318ZM14.3984 5.86108H12.7769C11.7882 5.86108 10.9577 5.03065 10.9577 4.04183V1.94575H2.77116C2.05928 1.94575 1.50565 2.49938 1.50565 3.21126V17.0529C1.50565 17.7648 2.05928 18.3184 2.77116 18.3184H9.69198C9.05917 17.567 8.66379 16.6179 8.66379 15.5502C8.66379 13.2565 10.5621 11.3583 12.8954 11.3583C13.4095 11.3583 13.9236 11.4769 14.3982 11.6351V5.86113L14.3984 5.86108ZM9.69216 15.5502C9.69216 17.3298 11.1159 18.7535 12.8955 18.7535C14.6355 18.7535 16.0592 17.3298 16.0592 15.5502C16.0592 13.8102 14.6355 12.3864 12.8955 12.3864C11.1159 12.3864 9.69216 13.8102 9.69216 15.5502Z"
                  fill="#155B98"
                />
                <path
                  d="M4.07698 5.70279C3.80017 5.70279 3.56287 5.4655 3.56287 5.18867C3.56287 4.91186 3.80015 4.67456 4.07698 4.67456H8.70411C8.98092 4.67456 9.17867 4.91185 9.17867 5.18867C9.17867 5.46548 8.9809 5.70279 8.70411 5.70279H4.07698Z"
                  fill="#155B98"
                />
                <path
                  d="M4.07698 8.15469C3.80017 8.15469 3.56287 7.95692 3.56287 7.68013C3.56287 7.40332 3.80015 7.16602 4.07698 7.16602H11.8282C12.105 7.16602 12.3423 7.4033 12.3423 7.68013C12.3423 7.95694 12.1051 8.15469 11.8282 8.15469H4.07698Z"
                  fill="#155B98"
                />
                <path
                  d="M4.07698 10.6464C3.80017 10.6464 3.56287 10.4091 3.56287 10.1323C3.56287 9.85547 3.80015 9.61816 4.07698 9.61816H11.8282C12.105 9.61816 12.3423 9.85545 12.3423 10.1323C12.3423 10.4091 12.1051 10.6464 11.8282 10.6464H4.07698Z"
                  fill="#155B98"
                />
              </svg>
            </Button>
          </Tooltip>
        </li>
      </ul>
    </div>
  );
};
