import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppToaster } from "../../toaster";
import { useLoading } from "../useLoading";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import {
  deleteSurveyDeployment,
  deleteSurveyDeploymentSoftly,
} from "../../../store/surveys-deployment/actions";
import { useIntl } from "react-intl";
import useUserRole from "../useUserRole";

const useSurveyDeploymentDelete = (
  isManageDeletedDataWorkspace?: boolean,
  isDistrictActivePlan?: boolean,
  text?:any
) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const {
    hasDataCoordinatorAccess,
    hasTeamChampionAccess,
    isTeamMember,
    isSuperintendent,
    isPrincipal,
  } = useUserRole();

  const deleteLoading = useSelector(
    (s) =>
      s.surveysDeployment.loading.deleteSurveyDeployment ||
      s.surveysDeployment.loading.deleteSurveyDeploymentSoftly
  );
  const deleteError = useSelector(
    (s) =>
      s.surveysDeployment.errors.deleteSurveyDeployment ||
      s.surveysDeployment.errors.deleteSurveyDeploymentSoftly
  );
  const onDeleteSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.surveys-deployment.deleted",
      }),
      icon: "tick",
      intent: "success",
    });
  }, []);
  useLoading({
    loading: deleteLoading,
    error: deleteError,
    onSuccess: onDeleteSuccess,
  });

  const showConfirmDeleteDialog = (surveyDeploymentId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onSurveyDeploymentDeleteConfirmed(surveyDeploymentId);
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-survey-deployment",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const onSurveyDeploymentDeleteConfirmed = (surveyDeploymentId: number) => {
    dispatch(hideConfirmDialog());
    dispatch(deleteSurveyDeployment.request(surveyDeploymentId));
  };

  const showConfirmSoftDeleteDialog = (surveyDeploymentId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onFirstStepSoftDeleteConfirmed(surveyDeploymentId);
        },
        show: true,
        intent: "warning",
        text: text,
        icon: "info-sign",
        confirmButtonText: intl.formatMessage({ id: "app.titles.continue" }),
        cancelButtonText: intl.formatMessage({ id: "app.titles.cancel" }),
      })
    );
  };

  const onFirstStepSoftDeleteConfirmed = (surveyDeploymentId: number) => {
    dispatch(hideConfirmDialog());
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          isManageDeletedDataWorkspace
            ? onSurveyDeploymentDeleteConfirmed(surveyDeploymentId)
            : onSurveyDeploymentSoftDeleteConfirmed(surveyDeploymentId);
        },
        show: true,
        intent: "warning",
        text: intl.formatMessage({
          id: `app.confirm-dialog${
            isManageDeletedDataWorkspace ? ".manage-deleted" : ""
          }.survey-deployment-soft-delete.second-step`,
        }),
        icon: "info-sign",
        confirmButtonText:
        isManageDeletedDataWorkspace && isSuperintendent?intl.formatMessage({
          id: "app.confirm-dialog.survey-deployment-soft-delete.confirm",
        }):(isSuperintendent||isPrincipal) ? intl.formatMessage({id:"app.titles.yes.delete"}):"",
        cancelButtonText: intl.formatMessage({ id: "app.titles.no" }),
      })
    );
  };

  const onSurveyDeploymentSoftDeleteConfirmed = (
    surveyDeploymentId: number
  ) => {
    dispatch(hideConfirmDialog());
    dispatch(deleteSurveyDeploymentSoftly.request(surveyDeploymentId));
  };

  const processSurveyDeploymentDelete = (
    surveyDeploymentId: number,
    isSoftDelete?: boolean
  ) => {
    if (isSoftDelete) {
      showConfirmSoftDeleteDialog(surveyDeploymentId);
    } else {
      if (isDistrictActivePlan) {
        showConfirmDeleteDialog(surveyDeploymentId);
      } else {
        showConfirmSoftDeleteDialog(surveyDeploymentId);
      }
    }
  };

  return {
    deleteLoading,
    processSurveyDeploymentDelete,
  };
};

export default useSurveyDeploymentDelete;
