import React, { useEffect, useMemo, useState } from "react";
import CollapsableActivity from "./CollapsableActivity";
import { Button as MUIButton } from "@mui/material";
import {
  Activity,
  ActivityScopeEnum,
  ActivityTagEnum,
  ActivityTypeEnum,
  UserNotesType,
} from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getActivities } from "../../../../../store/activities/actions";
import { isDistrictPlan } from "../../../../../store/plans/selectors";
import { useLoading } from "../../../../../helpers/hooks/useLoading";
import Button from "@mui/material/Button";

import {
  Card,
  InputGroup,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import SchoolTeamResourcesInfo from "./SchoolTeamResourcesInfo";
import Select from "react-select";
import ViewActivityContentDialog from "../../activity-video-dialog/ViewActivityContentDialog";
import "./resourseTabs.scss";

type OwnProps = {
  userNotesType: UserNotesType;
  activityTag?: ActivityTagEnum;
  filterFn?: (act: Activity) => boolean;
  showTagSelector?: boolean;
};

type Props = OwnProps;

const ActivityResourcesTab: React.FC<Props> = (props) => {
  const {
    userNotesType,
    activityTag,
    showTagSelector = true,
    filterFn,
  } = props;

  const dispatch = useDispatch();

  const intl = useIntl();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const activities = useSelector((s) => s.activities.activities);
  const loading = useSelector((s) => s.activities.loading.getActivities);
  const error = useSelector((s) => s.activities.errors.getActivities);
  useLoading({ loading, error });
  useEffect(() => {
    if (activePlanId) {
      dispatch(
        getActivities.request({
          type: isDistrictActivePlan
            ? ActivityScopeEnum.DISTRICT
            : ActivityScopeEnum.SCHOOL,
          tag: activityTag,
        })
      );
    }
  }, [activityTag, activePlanId]);

  const [searchString, setSearchString] = useState("");

  const [type, setType] = useState<ActivityTypeEnum | undefined>();

  const handleTypeChange = (type?: ActivityTypeEnum) => {
   setType(type);
  };

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target?.value);
  };
  

  const displayedActivities = useMemo(() => {
    const filters: ((activity: Activity) => boolean)[] = [];
    if (filterFn) {
      filters.push(filterFn);
      
    }
    if (type) {
      
      filters.push((a: Activity) => type === a.activity_type);
    }
    if (searchString) {
      const lowerCasedSearchString = searchString.toLocaleLowerCase();
      filters.push((a: Activity) =>
        a.name?.toLocaleLowerCase().includes(lowerCasedSearchString)
      );
    }
    
    return filters.length
      ? activities.filter((a: Activity) => filters.every((f) => f(a)))
      : activities;
  }, [activities, type, searchString, filterFn]);
 

  const handleClearFilters = () => {
    setType(undefined);
    setSearchString("");
  };

  const activityTypeSelectOptions = useMemo(() => {
    return Object.values(ActivityTypeEnum).map((type) => ({
      label: intl.formatMessage(
        { id: "app.titles.activity-type" },
        { type: type }
      ),
      value: type,
    }));
  }, []);

  return (
    <>
      {/* <div className="teamContent mt-5"> */}
      <div className="resourses-page__content">
        <SchoolTeamResourcesInfo
          userNotesType={userNotesType}
          activityTag={activityTag}
        />

        <div className="flex justify-between mt-5">
          {/* {showTagSelector ? (
            <Select
              key={type}
              isClearable
              value={
                type && {
                  label: intl.formatMessage(
                    { id: "app.titles.activity-type" },
                    { type: type }
                  ),
                  value: type,
                }
              }
              styles={{
                // .bp3-button can have zIndex =< 9 which can be behind the menu
                menu: (base) => ({ ...base, zIndex: 10 }),
              }}
              options={activityTypeSelectOptions}
              onChange={(v) => handleTypeChange(v?.value)}
              className="selector-min-width"
            />
          ) : (
            <div />
          )} */}

          {showTagSelector && (
            <div className="tab-buttonGroup">
             {/* <button className="tabButton">All</button> */}
             <button className={(  type == ActivityTypeEnum.SCI_WRITING ||  type == ActivityTypeEnum.EXTERNAL_RESOURCE ||  type == ActivityTypeEnum.KALTURA_VIDEO ) ? "tabButton"
                  : "tabButton activeTab"}
                  onClick={() => handleTypeChange()}>All</button>
              <button
                className={
                  type == ActivityTypeEnum?.SCI_WRITING
                    ? "tabButton activeTab"
                    : "tabButton"
                }
                onClick={() => handleTypeChange(ActivityTypeEnum?.SCI_WRITING)}
              >
                {intl.formatMessage(
                  { id: "app.titles.activity-type" },
                  { type: ActivityTypeEnum?.SCI_WRITING }
                )}
              </button>
              <button
                className={
                  type == ActivityTypeEnum?.EXTERNAL_RESOURCE
                    ? "tabButton activeTab"
                    : "tabButton"
                }
                onClick={() =>
                  handleTypeChange(ActivityTypeEnum?.EXTERNAL_RESOURCE)
                }
              >
                {intl.formatMessage(
                  { id: "app.titles.activity-type" },
                  { type: ActivityTypeEnum?.EXTERNAL_RESOURCE }
                )}
              </button>
              <button
                className={
                  type == ActivityTypeEnum?.KALTURA_VIDEO
                    ? "tabButton activeTab"
                    : "tabButton"
                }
                onClick={() =>
                  handleTypeChange(ActivityTypeEnum?.KALTURA_VIDEO)
                }
              >
                {intl.formatMessage(
                  { id: "app.titles.activity-type" },
                  { type: ActivityTypeEnum?.KALTURA_VIDEO }
                )}
              </button>
            </div>
          )}
          
        </div>
        <div className="resourses-page__head">
       <label>Resources ({displayedActivities?.length})</label>
        <div className="resources_search">
          <InputGroup
            type="search"
            leftIcon="search"
            large
           
            // placeholder={`${intl.formatMessage({
            //   id: "app.titles.search-activities",
            // })}...`}
            placeholder={`${intl.formatMessage({
              id: "app.titles.search-activities",
            })}`}
            value={searchString}
            onChange={handleSearchStringChange}
          />
          </div>
          </div>
        
        
        {loading ? (
          <Spinner intent="primary" className="my-2" />
        ) : displayedActivities.length ? (
          displayedActivities.map((activity, i) => (
            <div className="mt-5">
              <CollapsableActivity
                key={i}
                activity={activity}
                newDesign={true}
              />
            </div>
          ))
        ) : (
          <Card>
            <NonIdealState
              icon="zoom-out"
              title={intl.formatMessage({
                id: !!(type || searchString)
                  ? "app.tables.no-matching-data"
                  : "app.non-ideal-state.activities.title",
              })}
              description={
                !(type || searchString)
                  ? intl.formatMessage({
                      id: "app.non-ideal-state.activities.description",
                    })
                  : undefined
              }
              action={
                !!(type || searchString) ? (
                  <>
                      <Button variant="outlined" onClick={handleClearFilters} >
                        {intl.formatMessage({
                           id: "app.tables.button-text.clear-filters",
                        })}
                      </Button>
                    </>
                ) : undefined
              }
            />
          </Card>
        )}
        
      </div>
      <ViewActivityContentDialog isResourceTab={true} />
    </>
  );
};

export default ActivityResourcesTab;
