import { useMemo } from "react";
import { isDomainQuestionsComparison } from "../table/utils";
import {
  DomainComparison,
  DomainQuestionsComparison,
  SurveyDemographic,
} from "../../../types";

export const getSurveyDemographicForComparison = (
  surveyComparison: DomainQuestionsComparison | DomainComparison[]
): SurveyDemographic[] => {
  return Object.values(SurveyDemographic).filter((d) => {
    if (isDomainQuestionsComparison(surveyComparison)) {
      return (surveyComparison as DomainQuestionsComparison)?.responses?.some(
        (r) => !!r.respondents[d]
      );
    }
    return (surveyComparison as DomainComparison[])?.some(
      (r) => !!r.respondents[d]
    );
  });
};

export const useComparison = ({
  surveyComparison,
}: {
  surveyComparison: DomainQuestionsComparison | DomainComparison[];
}) => {
  const showNonIdealState: boolean = useMemo(() => {
    if (isDomainQuestionsComparison(surveyComparison)) {
      return (
        (surveyComparison as DomainQuestionsComparison)?.responses?.length === 0
      );
    }
    return (surveyComparison as [])?.length === 0;
  }, [surveyComparison]);

  const demographics = useMemo(() => {
    return getSurveyDemographicForComparison(surveyComparison);
  }, [surveyComparison]);

  return {
    showNonIdealState,
    demographics,
  };
};
export default useComparison;
