import { useState } from "react";
import { Locale } from "../../store/UIState";

const useLanguages = () => {
  const [selectedLocale, setSelectedLocale] = useState<Locale>(Locale.English);

  return {
    selectedLocale,
    setSelectedLocale,
  };
};

export default useLanguages;
