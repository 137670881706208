import React, { FunctionComponent, useMemo, useState } from "react";
import { Button, Checkbox, Classes, Dialog, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { UserRole } from "../../types";

type OwnProps = {
  show: boolean;
  onClose: Function;
  onConfirm: Function;
  onNotConfirm: Function;
  role?: UserRole;
};

type Props = OwnProps;

const TermsAndConditionsDialog: FunctionComponent<Props> = (props) => {
  const { show, onClose, role, onConfirm, onNotConfirm } = props;

  const intl = useIntl();

  const handleContinue = () => {
    onConfirm();
  };

  const handleOpen = () => {
    setAgree(false);
  };

  const handleClose = () => {
    onClose();
  };

  const [agree, setAgree] = useState<boolean>(false);

  const handleAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAgree(checked);
    if(checked){
    onConfirm();
    }
    else{
    onNotConfirm()
    }
  };

  const { terms, agreeText } = useMemo(() => {
    switch (role) {
      case UserRole.Superintendent:
        return {
          terms:
            intl.formatMessage(
            {
              id: "app.terms-and-conditions.superintendent.body",
            },
            {
              br: <br />,
              points: (
                <ul className="list-disc pl-10">
                  <br />
                  <li>
                    {intl.formatMessage({

                      id: "app.welcome.page.message.terms.use.first"

                    })}
                  </li>
                  <br />
                  <li>
                    {intl.formatMessage({
                      id: "app.welcome.page.message.terms.use.modification"
                    })}
                    <ul className="list-disc pl-10">
                      <br />
                      <li className="mb-2">
                        {intl.formatMessage({
                          id: "app.welcome.page.message.terms.use.section.iii"
                        })}
                      </li>
                      <ol className="list-decimal pl-10">
                        <li value={1}>
                          <p className="not-italic">
                            {intl.formatMessage({
                              id: "app.welcome.page.message.terms.use.section.iii.content"
                            })}
                          </p>
                        </li>
                      </ol>
                      <br />
                      <li className="mb-2">
                        {intl.formatMessage({
                          id: "app.welcome.page.message.terms.use.section.vii"
                        })}
                      </li>
                      <ol className="list-decimal pl-10">
                        <li value={3}>
                          <p className="not-italic">
                            {intl.formatMessage({
                              id: "app.welcome.page.message.terms.use.section.vii.content"
                            })}
                          </p>
                        </li>
                      </ol>
                    </ul>
                  </li>
                </ul>
              ),
              
            }
          ),
          agreeText: intl.formatMessage({
            id: "app.terms-and-conditions.superintendent.agree",
          }),
        };

      default:
        return {
          agreeText: intl.formatMessage({
            id: "app.terms-and-conditions.agree",
          }),
          terms: intl.formatMessage(
            { id: "app.terms-and-conditions.body" },
            {
              rutgersMenu: (
                <ul className="list-disc pl-10">
                  {intl.formatMessage(
                    { id: "app.terms-and-conditions.rutgers-menu" },
                    {
                      li: (chunks) => (
                        <>
                          <br />
                          <li>{chunks}</li>
                        </>
                      ),
                    }
                  )}
                  <br />
                </ul>
              ),
              br: <br />,
              a: (chunks) => (
                <a
                  href="https://platform.njschoolclimate.org/files/activity/NDQ=/download/pdf/NJ+SCI+Platform+Terms+of+Use+2022+Review+Copy.pdf"
                  target="_blank"
                  className="text-blue-400 hover:text-blue-500"
                >
                  {chunks}
                </a>
              ),
              userMenu: (
                <ul className="list-disc pl-10">
                  {intl.formatMessage(
                    { id: "app.terms-and-conditions.user-menu" },
                    {
                      li: (chunks) => (
                        <>
                          <br />
                          <li>{chunks}</li>
                        </>
                      ),
                    }
                  )}
                  <br />
                </ul>
              ),
            }
          ),
        };
    }
  }, [role]);

  const agreeInformation = useMemo(() => {
    switch (role) {
      case UserRole.Superintendent:
        return intl.formatMessage(
          { id: "app.terms-conditions.superintendent-agree-info" },
          {
            br: <br />,
            a: (chunks) => (
              <a
                href="https://platform.njschoolclimate.org/files/activity/NDQ=/download/pdf/NJ+SCI+Platform+Terms+of+Use+2022+Review+Copy.pdf"
                target="_blank"
                className="text-blue-400 hover:text-blue-500"
              >
                {chunks}
              </a>
            ),
          }
        );
      default:
        return intl.formatMessage({ id: "app.terms-and-conditions.agree-info" });
    }
  }, [role, intl]);

  return (
      <>
      <div className="nj-md-6 cstmColPad">
        <div className="form-header termsBlock">
          <p className="form-header__subTxt">
        <strong> User Terms and Conditions</strong>
          </p>
          </div>
          <div className="registration-wclmsg">
          <div className="terms-and-conditions-body mb-2" style={{maxHeight:role===UserRole.Superintendent?"":"14rem"}}>
            {terms}
          </div>
          </div>
          <div>{agreeInformation}</div>
          <Checkbox className={" mt-4"} checked={agree} label={agreeText} onChange={handleAgree} /><div className={Classes.DIALOG_FOOTER}>
          </div>
      </div>
          </>
  );
};

export default TermsAndConditionsDialog;
