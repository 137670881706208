import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  DemographicWrapper,
  PageTextKeys,
  SurveyDemographic,
  SurveyPrompt,
} from "../../../../types";
import { Button, ButtonGroup, FormGroup, TextArea } from "@blueprintjs/core";
import PageEditor from "../../../survey-deployment/forms/PageEditor";
import SurveyContentTranslationsDialog from "../dialogs/SurveyContentTranslationsDialog";
import { Locale } from "../../../../store/UIState";
import { useSelector } from "react-redux";
import SurveyPromptComponent from "../SurveyPromptComponent";

type OwnProps = {
  questionPages: number[];
  demographic: SurveyDemographic;
  pageText: DemographicWrapper<SurveyPrompt>;
  setPageText: React.Dispatch<
    React.SetStateAction<DemographicWrapper<SurveyPrompt> | undefined>
  >;
  selectedLanguageAbbreviation: string;
  showDialog?: boolean;
  onClose?: () => void;
};

type Props = OwnProps;

const SurveyPromptsTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    pageText,
    setPageText,
    selectedLanguageAbbreviation,
    questionPages,
    demographic,
    showDialog,
    onClose,
  } = props;

  const [pages, setPages] = useState<Array<PageTextKeys>>([]);

  const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);

  useEffect(() => {
    setPages(
      Array.from(["intro", ...questionPages, "demographic_data", "post"])
    );
  }, [questionPages, demographic]);

  const [selectedPage, setSelectedPage] = useState<PageTextKeys>("intro");

  useEffect(() => {
    setSelectedPage("intro");
  }, [demographic]);

  const handleUpdatePrompts = (objectKey: string, value: string) => {
    if (pageText !== undefined) {
      setPageText({
        ...pageText,
        [demographic]: {
          ...pageText?.[demographic],
          [selectedPage]: {
            ...pageText?.[demographic]?.[selectedPage],
            [selectedLanguageAbbreviation]: {
              ...pageText?.[demographic]?.[selectedPage]?.[
                selectedLanguageAbbreviation
              ],
              [objectKey]: value,
            },
          },
        },
      });
    }
  };

  const handleTextAreaUpdate = (
    e: React.FocusEvent<HTMLTextAreaElement>,
    objectKey: string
  ) => {
    const value = e.target?.value;
    handleUpdatePrompts(objectKey, value);
  };

  const handlePromptTranslationUpdate = (translatedPrompt: SurveyPrompt) => {
    if (pageText !== undefined) {
      setPageText({
        ...pageText,
        [demographic]: translatedPrompt,
      });
    }
  };

  return (
    <div>
      <div className="flex pb-4">
        <p className="font-bold text-xl mr-4">
          {intl.formatMessage({
            id: "app.surveys.survey-questions.page",
          })}
        </p>

        <ButtonGroup className="flex flex-wrap">
          {pages.map((item, index) => (
            <Button
              icon={selectedPage === item ? "tick" : "blank"}
              key={index}
              onClick={() => setSelectedPage(item)}
              intent={selectedPage === item ? "primary" : undefined}
            >
              {typeof item === "number" ? item + 1 : item}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <SurveyPromptComponent
        selectedPage={selectedPage}
        selectedLanguage={Locale.English}
        onTextAreaUpdate={handleTextAreaUpdate}
        onUpdatePrompt={handleUpdatePrompts}
        prompt={pageText[demographic]}
      />

      <SurveyContentTranslationsDialog
        show={showDialog}
        onClose={onClose}
        demographic={demographic}
        availableLanguages={
          selectedSurvey?.available_languages ?? [Locale.English]
        }
        demographicPrompt={pageText[demographic]}
        onTranslationUpdate={handlePromptTranslationUpdate}
      />
    </div>
  );
};

export default SurveyPromptsTab;
