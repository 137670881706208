import react, { FunctionComponent, useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { useIntl } from 'react-intl';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
// import SurveyItem from '../survey-item/survey-item';

import './DomainList.scss';
import DomainListBar from './DomainListBar/DomainListBar';
import { useSelector } from 'react-redux';
import { Domain } from '../../../../../types';
import { Alert, Snackbar, Stack } from '@mui/material';

type OwnProps = {};
type Props = OwnProps;

const DomainList: FunctionComponent<Props> = () => {
    // CONSTANTS
    const intl = useIntl();

    // USE SELECTOR
    const { goal } = useSelector((s) => s.goals.newGoalState);
        
    return (
        <>
            <label>{intl.formatMessage({
                id: "app.titles.domain",
            })} ({goal?.selected_domains?.length})</label>

            {goal?.selected_domains?.length ?
                <div className="accordionbar">
                    {
                        goal?.selected_domains?.map((et: Domain) =>
                            <DomainListBar domain={et} />
                        )
                    }
                </div> :
                <div className="notfound-message fullwidth-message">
                    <div className="notfound-message_inner">
                        <h3>{intl.formatMessage({
                            id: "app.goals.goal.domain.no-domain-title",
                        })}</h3>
                        <p>{intl.formatMessage({
                            id: "app.goals.goal.domain.no-domain-description",
                        })}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default DomainList;
