import React from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const SurveyAnonymizeNonIdealState: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  return (
    <div className="w-full flex justify-center">
      <span className="text-2xl font-semibold">
        {intl.formatMessage({
          id: "app.non-ideal-state.survey-reports.not-enough-respondents",
        })}
      </span>
    </div>
  );
};

export default SurveyAnonymizeNonIdealState;
