import { Epic } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getNotificationSettingsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getNotificationSettings.request)),
    switchMap((action) =>
      from(api.notifications.getNotificationSettings()).pipe(
        map(actions.getNotificationSettings.success),
        catchError((error) =>
          of(actions.getNotificationSettings.failure(error))
        )
      )
    )
  );
};

export const updateNotificationSettingEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updateNotificationSettings.request)),
    switchMap((action) =>
      from(api.notifications.updateNotificationSettings(action.payload)).pipe(
        map(actions.updateNotificationSettings.success),
        catchError((error) =>
          of(actions.updateNotificationSettings.failure(error))
        )
      )
    )
  );
};

export const getNotificationsEpic: Epic<
  RootAction,
  RootAction,
  RootState
  > = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getNotifications.request)),
    switchMap((action) =>
      from(api.notifications.getNotifications()).pipe(
        map(actions.getNotifications.success),
        catchError((error) =>
          of(actions.getNotifications.failure(error))
        )
      )
    )
  );
};

export const markNotificationsAsReadEpic: Epic<
  RootAction,
  RootAction,
  RootState
  > = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.markNotificationsAsRead.request)),
    switchMap((action) =>
      from(api.notifications.markNotificationsAsRead(action.payload)).pipe(
        map(actions.markNotificationsAsRead.success),
        catchError((error) =>
          of(actions.markNotificationsAsRead.failure(error))
        )
      )
    )
  );
};
