import { AttachedResource, UserNotes } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import { AddNotesRequest, GetNotesRequest, GetNotesResponse } from "./types";
import { addResources } from "../resources";
import { generateQueryString } from "../../helpers/stringUtils";
import { PusherEvent } from "../goals/types";

export const getNotes = ({
  planId,
  ...request
}: GetNotesRequest): Promise<GetNotesResponse> => {
  let url = `${API_ROOT}/notes/plans/${planId}/filters`;
  const queryStr = generateQueryString(request);

  if (queryStr) {
    url += "?" + queryStr;
  }

  return Axios.get(url);
};

export const addNotes = async (
  body: Omit<AddNotesRequest, "onSuccess">
): Promise<UserNotes> => {
  let resourceIds: number[] = [];
  await addResources(body?.resources ?? [], body.plan_id).then((success) => {
    resourceIds = success
      ?.filter((response) => response.status === "fulfilled")
      ?.map(
        (resource) =>
          (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
      );
  });
  return Axios.post(`${API_ROOT}/plans/${body.plan_id}/notes/`, {
    ...body,
    resources: resourceIds,
  });
};

export const updateNotes = async (
  { meeting_id, ...body }: Omit<AddNotesRequest, "onSuccess">,
  id: number
): Promise<UserNotes> => {
  let resourceIds: number[] = body?.resources
    ?.filter((r) => r?.id > 0)
    ?.map((r) => r.id);
  await addResources(
    body?.resources?.filter((r) => r?.id < 0 || !r.id) ?? [],
    body.plan_id
  ).then((success) => {
    resourceIds = [
      ...resourceIds,
      ...success
        ?.filter((response) => response.status === "fulfilled")
        ?.map(
          (resource) =>
            (resource as PromiseFulfilledResult<AttachedResource>)?.value?.id
        ),
    ];
  });
  return Axios.put(`${API_ROOT}/plans/${body.plan_id}/notes/${id}/`, {
    ...body,
    resources: resourceIds,
  });
};

export const deleteNote = (id: number, planId: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/plans/${planId}/notes/${id}/`);
};

export const triggerPusherEvent = (plan_id: number | undefined = 2, eventData: PusherEvent): Promise<any> => {
    return Axios.post(`${API_ROOT}/plans/${plan_id}/notes/trigger_payload/`, eventData);
  
};
