import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "@blueprintjs/core";
import moment from "moment/moment";
import { Goal, GoalActivityLog, GoalActivityLogAll, GoalIndicatorTarget } from "../../../../../types";

type OwnProps = {
    goalActivitydata: Goal[];
  };

type Props = OwnProps;

const TERMS_COLUMNS =
  '["Date Goal Created","Date of Last Edit","District","School(s)","Goal Short Title", "Domain (Respondent Group)","Item (Respondent Group)"]';


const DownloadGoalHistory: React.FC<Props> = ({ goalActivitydata }: Props) => {
const intl = useIntl();
const handleCSVDownloadClick = useCallback(() => {
    const csvStr = [
    TERMS_COLUMNS,
    ...goalActivitydata.map((ua: Goal) => {
        const formattedObject = {
        created_at: moment(ua.created_at).format("MM/DD/YYYY"),
        updated_at: moment(ua.updated_at).format("MM/DD/YYYY"),
        time: moment(ua.updated_at).format("HH:mm"),
        district_name: ua.district_name,
        school_name: ua.school_name,
        statement: ua.statement,
        domain_targets: ua.domain_targets,
        item_targets: ua.item_targets,
        };
        return JSON.stringify(Object.values(formattedObject));
    }),
    ]
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, "");
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute(
    "download",
    `Goal History${moment().format("MM/DD/YYYY")}`
    );
    link.href = csvUrl;
    document.body.append(link);
    link.click();
    link.remove();
}, [goalActivitydata]);
return (
    <Button
    text={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
    title={intl.formatMessage({ id: "app.titles.download-as-CSV" })}
    intent="primary"
    onClick={handleCSVDownloadClick}
    />
);
};
  
  export default DownloadGoalHistory;