import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Icon } from '@blueprintjs/core';
import Tooltip from '@mui/material/Tooltip';
import './TeamFilter.scss';
import { PlanStrategy, TeamMember } from '../../../../types';
import { useSelector } from 'react-redux';
import useUserRole from '../../../../helpers/hooks/useUserRole';
import { isDistrictPlan } from '../../../../store/plans/selectors';
import { filterTeambyInviteRoleActivationStatus } from '../../utils';
import { useIntl } from "react-intl";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

type OwnProps = {
  teamMemberFilter: (data: TeamMember[]) => void;
  isLoader: (isLoader: boolean | undefined) => void;
};

type Props = OwnProps;


const Teamfilter: React.FunctionComponent<Props> = ({ teamMemberFilter, isLoader }) => {
  const [roleFilter, setRoleFilter] = React.useState<string[]>([]);
  const [activationStatus, setActivationStatus] = React.useState<string[]>([]);

  const members = useSelector((s) => s.teamMembers.teamMembers);
  const isLoading = useSelector((s) => s.teamMembers.loading.getTeamMembersByPlan);
  const {
    isDCC,
    role,
    teamRole,
    isSuperintendent,
    isPrincipal,
    isAdmin,
    isTeamChampion,
    hasDistrictPlanAccess,
  } = useUserRole();
  
  const activePlan = useSelector((s) => s.plans.activePlan);
  const teamRoles = useSelector((s) => s.auth.authenticatedUser?.team_roles);
  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const [inviteStatus, setInviteStatus] = useState<(string | null)[]>([]);

  const [displayedTeamMembers, setDisplayedTeamMembers] = useState<
  TeamMember[]
  >([]);
  const [displayedActivationList, setDisplayedActivationList] = useState<
  TeamMember[]
  >([]);
  
  const intl = useIntl();

  useEffect(() => {
    setDisplayedTeamMembers(
      filterTeambyInviteRoleActivationStatus(
                members,
                inviteStatus,
                roleFilter,
                activationStatus
            )
        );
}, [inviteStatus, members, roleFilter, activationStatus]);

useEffect(() => {
  setDisplayedActivationList(
    filterTeambyInviteRoleActivationStatus(
              members,
              inviteStatus,
              roleFilter,
              activationStatus
          )
      );
}, [inviteStatus, members, roleFilter,]);


  const handleInviteStatusChange = (event: SelectChangeEvent<typeof inviteStatus>) => {
    const {
      target: { value },
    } = event;
    let inviteStatusValues: (string | null)[] = typeof value === 'string' ? value.split(',') : value;
  
    // Include null values when "failed" is selected
    if (inviteStatusValues.includes('failed')) {
      inviteStatusValues = [...inviteStatusValues, null];
    }
  
    setInviteStatus(inviteStatusValues);
  };


  const clearInviteStatusChange = () => {
    setInviteStatus([]);
  };

  const handleRoleChange = (event: SelectChangeEvent<typeof roleFilter>) => {
    const {
      target: { value },
    } = event;
    setRoleFilter(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const clearRoleChange = () => {
    setRoleFilter([]);
  };

  const handleActivationChange = (event: SelectChangeEvent<typeof roleFilter>) => {
    const {
      target: { value },
    } = event;
    setActivationStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
  };

  const clearSelection = () => {
    setRoleFilter([]);
    setInviteStatus([]);
    setActivationStatus([]);
  };

  const filteredInviteStatus = useMemo(() => {
    const uniqueInviteStatus: any[] = [];
    displayedTeamMembers.forEach((item) => {
      const findItem = uniqueInviteStatus.find((x) => x === item.invited_status || item.invited_status === null || item.invited_status.trim() === '');
      if (!findItem) uniqueInviteStatus.push(item.invited_status);
    });
    return uniqueInviteStatus;
  }, [displayedTeamMembers]);

  const filteredActivatedStatus = useMemo(() => {
    let uniqueActivationStatus: any[] = [];
        displayedActivationList.forEach((item:any) =>{
        if (uniqueActivationStatus.indexOf(item.user.is_active) === -1) {
        uniqueActivationStatus.push(item.user.is_active);
        }
    });
    return uniqueActivationStatus;
  }, [displayedActivationList,]);

  const filteredRoleStatus = useMemo(() => {
    const uniqueRoleStatus: any[] = [];
    displayedTeamMembers.map((item) => {
      var findCustomItem = uniqueRoleStatus.find((x) => x === item.user.profile.custom_role_name);
      var findItem = uniqueRoleStatus.find((x) => x === item.user.profile.role);
      if (!findCustomItem && item.user.profile.custom_role_name != null) {
        uniqueRoleStatus.push(item.user.profile.custom_role_name);
      }
      if(!findItem){
        uniqueRoleStatus.push(item.user.profile.role);
      }
    });
    return uniqueRoleStatus
  }, [displayedTeamMembers,]);

  useEffect(() => {
    teamMemberFilter(displayedTeamMembers);
    isLoader(isLoading);
}, [displayedTeamMembers, teamMemberFilter, isLoader, isLoading]);


  return (
  <div className="filterbar">
  <span className="filter-icon"><svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.10791 0.369736L6.60393 7.87166V15.3736L10.316 17.249V7.8713L16.812 0.369385H8.46013L0.10791 0.369736Z" fill="#8B8B8B"></path></svg></span>
      
      <FormControl  size="small">
      <InputLabel id="demo-select-small-label">By Invite Status</InputLabel>
        <Select
          style={{textTransform: "capitalize"}}
          labelId="demo-multiple-checkbox-label"
          label="By Invite Status"
          id="demo-multiple-checkbox"
          multiple
          value={inviteStatus.map(status => status === 'failed' ? 'N/A' : status)}

          onChange={handleInviteStatusChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          
         {filteredInviteStatus.map((name) => {
            const displayName = (name === 'failed' || name === null) ? 'N/A' : name;
            return (
              <MenuItem key={name} value={name}>
                <Checkbox checked={inviteStatus.includes(name === 'failed' ? 'N/A' : name)} />
                <ListItemText style={{ textTransform: 'capitalize' }} primary={displayName} />
              </MenuItem>
            );
          })}



          {inviteStatus.length != 0 &&
              <div className="clear-btn">
                  <button onClick={clearInviteStatusChange}> <Icon icon="cross" iconSize={16} /> Clear All</button>
              </div>
          }
        </Select>
      </FormControl>


      <FormControl size="small">
      <InputLabel id="demo-select-small-label">By District/School Role</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          label="By District/School Role"
          id="demo-multiple-checkbox"
          multiple
          value={roleFilter}
          onChange={handleRoleChange}
          // renderValue={(selected) => selected.join(', ')}
          renderValue={(selected) => selected.map(
            group => group === group.toLowerCase()? intl.formatMessage(
            { id: `app.team-plan.team.roles.filter.${group}`},
          ):group).join(', ')}
          MenuProps={MenuProps}
        >
          {filteredRoleStatus.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={roleFilter.indexOf(name) > -1} />
              {name === name.toLowerCase()?
              <ListItemText primary={intl.formatMessage(
                {
                  id: `app.team-plan.team.roles.filter.${name}`,
                },
                { role: name }
              )} />
              :
              <ListItemText primary={name} />
              }
            </MenuItem>
          ))}
          {roleFilter.length != 0 &&
            <div className="clear-btn">
              <button onClick={clearRoleChange}> <Icon icon="cross" iconSize={16} /> Clear All</button>
            </div>
          }
        </Select>
      </FormControl>


  <FormControl  size="small">
      <InputLabel id="demo-select-small-label">By Activation Status</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        label="By Activation Status"
        onChange={handleActivationChange}
        value={activationStatus}
      >
        {filteredActivatedStatus.map((name) => (
          name ? <MenuItem value={1}>Activated</MenuItem>:<MenuItem value={0}>Deactivated</MenuItem>
        ))}
      </Select>
    </FormControl>
    {(activationStatus.length != 0 || roleFilter.length != 0 || inviteStatus.length != 0) &&
    <Tooltip title="Clear Filter" arrow>
      <button onClick={clearSelection} className="filtercancel" ><Icon iconSize={14} icon="cross" /></button>
    </Tooltip>
    }
    </div>
    


  );
}
export default Teamfilter;