import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const addStrategyToPlanEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addStrategyToPlan.request)),
    switchMap((action) =>
      from(
        api.planStrategies.addStrategyToPlan(action.payload.planStrategy)
      ).pipe(
        mergeMap((planStrategy) =>
          from(
            [actions.addStrategyToPlan.success(planStrategy)],
            action.payload.onSuccess && action.payload.onSuccess(planStrategy)
          )
        ),
        catchError((error) => of(actions.addStrategyToPlan.failure(error)))
      )
    )
  );
};

export const updateStrategyToPlanEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updatePlanStrategy.request)),
    switchMap((action) =>
      from(
        api.planStrategies.updatePlanStrategy(action.payload.planStrategy)
      ).pipe(
        mergeMap((planStrategy) =>
          from(
            [actions.updatePlanStrategy.success(planStrategy)],
            action.payload.onSuccess && action.payload.onSuccess(planStrategy)
          )
        ),
        catchError((error) => of(actions.updatePlanStrategy.failure(error)))
      )
    )
  );
};

export const deletePlanStrategyEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deletePlanStrategy.request)),
    switchMap((action) =>
      from(
        api.planStrategies.deletePlanStrategy(
          action.payload.planStrategyId,
          action.payload.planId
        )
      ).pipe(
        mergeMap(() =>
          from(
            [actions.deletePlanStrategy.success(action.payload.planStrategyId)],
            action.payload.onSuccess && action.payload.onSuccess()
          )
        ),
        catchError((error) => of(actions.deletePlanStrategy.failure(error)))
      )
    )
  );
};

export const updatePlanStrategyImplementationStatusEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updatePlanStrategyImplementationStatus.request)),
    switchMap((action) =>
      from(
        api.planStrategies.updatePlanStrategyImplementationStatus(
          action.payload
        )
      ).pipe(
        map(actions.updatePlanStrategyImplementationStatus.success),
        catchError((error) =>
          of(actions.updatePlanStrategyImplementationStatus.failure(error))
        )
      )
    )
  );
};

export const getPlanStrategiesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getPlanStrategies.request)),
    switchMap((action) =>
      from(api.planStrategies.getPlanStrategies(action.payload)).pipe(
        map(actions.getPlanStrategies.success),
        catchError((error) => of(actions.getPlanStrategies.failure(error)))
      )
    )
  );
};

export const deletePlanStrategyImplementationEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deletePlanStrategyImplementation.request)),
    switchMap((action) =>
      from(
        api.planStrategies.deletePlanStrategyImplementation(
          action.payload.plan_strategy_id,
          action.payload.implementation
        )
      ).pipe(
        mergeMap(() =>
          from(
            [
              actions.deletePlanStrategyImplementation.success(
                action.payload.implementation
              ),
            ],
            action.payload.onSuccess && action.payload.onSuccess()
          )
        ),
        catchError((error) =>
          of(actions.deletePlanStrategyImplementation.failure(error))
        )
      )
    )
  );
};

export const updatePlanStrategyImplementationEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updatePlanStrategyImplementation.request)),
    switchMap((action) =>
      from(
        api.planStrategies.updatePlanStrategyImplementation(
          action.payload.implementation,
          action.payload.body
        )
      ).pipe(
        mergeMap((implementation) => {
          return from(
            [
              actions.updatePlanStrategyImplementation.success({
                implementation: action.payload.implementation,
                response: implementation,
              }),
            ],
            action.payload.onSuccess && action.payload.onSuccess()
          );
        }),
        catchError((error) =>
          of(actions.updatePlanStrategyImplementation.failure(error))
        )
      )
    )
  );
};
