import React, { FunctionComponent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Icon, Spinner } from '@blueprintjs/core';
import './AddStrategyButton.scss';
import { generatePath, useHistory } from 'react-router';
import { AuthRoutes } from '../../../../../../App';
import { useSelector } from 'react-redux';

type OwnProps = {};

type Props = OwnProps;

const AddStrategyButton: FunctionComponent<Props> = () => {
    const history = useHistory();
    const planStrategies = useSelector((s) => s.planStrategies.loading);

    const canNavigate = () => {
        history.push(
            generatePath(AuthRoutes.YourPlan, {
                workspace: "goals" as any,
                tabId: "add-strategy"
            } as any)
        );
    }

    return (
        <>
            {planStrategies?.getPlanStrategies ?<Spinner intent='primary' size={30}/> : <Tooltip title="Add Strategy" arrow>
                <button className="nj-btn addBtn" disabled={planStrategies?.getPlanStrategies} onClick={canNavigate}><Icon icon="small-plus" iconSize={20} />  strategy</button>
            </Tooltip>}
        </> 
    );
}

export default AddStrategyButton;