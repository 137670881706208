import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Classes, Icon } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { hideItemLevelInfoAndBookmarksDialog } from "../../../../store/needs/actions";
import ItemLevelInfoAndBookmarksDialogContent from "./ItemLevelInfoAndBookmarksDialogContent";
import Dialog from '@mui/material/Dialog';
import './ItemLevelInfoAndBookmarksDialog.scss';


type OwnProps = {};

type Props = OwnProps;

const ItemLevelInfoAndBookmarksDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { show } = useSelector(
    (s) => s.needs.dialogs.itemLevelInfoAndBookmarksDialog
  );

  const handleDialogClose = () => {
    dispatch(hideItemLevelInfoAndBookmarksDialog());
  };

  return (
    // <Dialog
    //   title={intl.formatMessage({
    //     id: "app.title.item-level-information-and-bookmarks",
    //   })}
    //   className={"dialog-md-width"}
    //   isOpen={show}
    //   onClose={handleDialogClose}
    // >

    <Dialog
      className="large-modal"
      open={show ? true : false}//itemLevelInfoAndBookmarksDialog?.show ? true : false} // SummaryofBookmarkopen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="modal-title"><h3>{intl.formatMessage({ id: "app.title.item-level-information-and-bookmarks" })}</h3>
        <button onClick={handleDialogClose}>
          <Icon icon="cross" iconSize={18} />
        </button>
      </div>
      <div className={Classes.DIALOG_BODY}>
        <ItemLevelInfoAndBookmarksDialogContent />
      </div>
    </Dialog>
  );

  // return (
  //   <Dialog
  //     title={intl.formatMessage({
  //       id: "app.title.item-level-information-and-bookmarks",
  //     })}
  //     className={"dialog-md-width"}
  //     isOpen={show}
  //     onClose={handleDialogClose}
  //   >
  //     <div className={Classes.DIALOG_BODY}>
  //       <ItemLevelInfoAndBookmarksDialogContent />
  //     </div>
  //   </Dialog>
  // );
};

export default ItemLevelInfoAndBookmarksDialog;
