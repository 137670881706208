import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const PotentialPriorityIcon: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <div>
      <span className="sr-only">
        {intl.formatMessage({ id: "app.titles.potential-priority" })}
      </span>
    </div>
  );
};

export default PotentialPriorityIcon;
