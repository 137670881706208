import React, { CSSProperties, FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Button, Card, FormGroup, Icon, InputGroup } from "@blueprintjs/core";
import { PossibleValue } from "../../../../../types";
import _ from "lodash";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

type OwnProps = {
  items: Pick<PossibleValue, "id" | "order" | "name">[];
  setItems: React.Dispatch<
    React.SetStateAction<Pick<PossibleValue, "id" | "order" | "name">[]>
  >;
  style?: CSSProperties;
};

type Props = OwnProps;

const PossibleValuesForm: FunctionComponent<Props> = (props) => {
  const { items, setItems, style } = props;

  const intl = useIntl();

  const handleNewItemAdd = () => {
    setItems((items) => [
      ...items,
      { id: -1 * +_.uniqueId(), name: "", order: items.length },
    ]);
  };

  const handleItemRemove = (itemId: number) => () => {
    setItems((items) =>
      items.reduce<Pick<PossibleValue, "id" | "order" | "name">[]>(
        (pV, cV, index) => {
          if (itemId === cV.id) {
            return pV;
          }

          return [...pV, { ...cV, order: index }];
        },
        []
      )
    );
  };

  const handleItemNameChange = (itemId: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;

    setItems((items) =>
      items.map((item) =>
        item.id === itemId ? { ...item, name: value } : item
      )
    );
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (destination && !(destination.index === source.index)) {
      setItems((items) =>
        _.chain(items)
          .map((item) => {
            if (item.order === source.index) {
              return { ...item, order: destination.index };
            }
            if (item.order > source.index) {
              if (item.order > destination.index) {
                return item;
              } else {
                return { ...item, order: item.order - 1 };
              }
            } else {
              if (item.order < destination.index) {
                return item;
              } else {
                return { ...item, order: item.order + 1 };
              }
            }
          })
          .value()
      );
    }
  };

  return (
    <FormGroup
      label={intl.formatMessage({
        id: "app.data-sets.possible-values",
      })}
    >
      <Card className="p-2 flex flex-col items-center">
        {!!items.length && (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="space-y-2 w-full mb-2"
                  style={style}
                >
                  {items
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Card className="p-1 flex items-center justify-between bg-gray-300">
                              <Icon
                                icon={"drag-handle-vertical"}
                                className="mr-1"
                              />
                              <InputGroup
                                required
                                value={item.name}
                                onChange={handleItemNameChange(item.id)}
                                fill
                                placeholder={intl.formatMessage({
                                  id: "app.forms.name.placeholder",
                                })}
                              />
                              <Button
                                className="ml-1"
                                icon="cross"
                                minimal
                                small
                                intent="danger"
                                onClick={handleItemRemove(item.id)}
                              />
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        <Button
          text={intl.formatMessage({
            id: "app.data-sets.possible-values.add-new-value",
          })}
          title={intl.formatMessage({
            id: "app.data-sets.possible-values.add-new-value",
          })}
          icon="add"
          intent="primary"
          onClick={handleNewItemAdd}
        />
      </Card>
    </FormGroup>
  );
};

export default PossibleValuesForm;
