import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Callout,
  Card,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import {
  addSchool,
  getSchoolsByDistrict,
  hideUpsertSchoolDialog,
  updateSchool,
} from "../../store/schools/actions";
import { useIntl } from "react-intl";
import { useLoading } from "../../helpers/hooks/useLoading";
import { AppToaster } from "../../helpers/toaster";
import {
  DistrictSetupTaskKey,
  Grade,
  UserModel,
  UserRole,
  userRoles,
} from "../../types";
import { generatePath, useParams } from "react-router";
import {
  getCurrentUserDistrictId,
  getIsSetupCompleted,
  selectIsAdmin,
} from "../../store/auth/selectors";
import { getUsers } from "../../store/users/actions";
import UserSelector from "../districts/district-users/UserSelector";
import { AddSchoolRequest } from "../../api/schools/types";
import useNewUser from "../../helpers/hooks/useNewUser";
import { getPlans } from "../../store/plans/actions";
import {
  getActivePlanDistrictId,
  isDistrictPlan,
} from "../../store/plans/selectors";
import SchoolGradesSelector from "./SchoolGradesSelector";
import { useHistory } from "react-router-dom";
import { AuthRoutes } from "../../App";
import { markStepsCompleted } from "../../store/tours/actions";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../store/UIState/actions";
import { add } from "lodash";

type RouteParams = {
  districtId?: string;
};

type OwnProps = {
  isTeamPlanPage?: boolean;
  isSingleSchoolDistrict?: boolean;
};

type Props = OwnProps & RouteParams;

enum SubmitterName {
  SubmitAndClose = "submit_and_close",
  SubmitAndAddAnother = "submit_and_add_another",
}

const SchoolsUpsertDialog: FunctionComponent<Props> = (props) => {
  const { isSingleSchoolDistrict, isTeamPlanPage } = props;

  const dispatch = useDispatch();

  const intl = useIntl();

  const isSetupCompleted = useSelector(getIsSetupCompleted);

  const {setupCompleted} = useSelector((s) => s.tours);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const activePlanDistrictId = useSelector(getActivePlanDistrictId);

  const history = useHistory();

  const tours = useSelector((s) => s.tours.districtSetupState);

  const [selectedTab, setSelectedTab] = useState<TabId>("invite-now");

  const {
    newUserComponent,
    firstName,
    lastName,
    email,
    resetForm,
  } = useNewUser();

  const isAuthenticatedAdmin = useSelector(selectIsAdmin);

  const [warning, setWarning] = useState<string | undefined>();

  const districtId = useSelector(getCurrentUserDistrictId);

  const routeParams = useParams<RouteParams>();

  const { show, selectedSchool } = useSelector(
    (s) => s.schools.schoolsUpsertDialog
  );

  const { schools } = useSelector(
    (s) => s.schools
  );

  const handleClose = () => {
    if (!loading) {
      setWarning(undefined);
      dispatch(hideUpsertSchoolDialog());
      setSchoolName("");
      setGrades([]);
      setPrincipalId(undefined);
      resetForm();
      setSelectedTab("invite-now");
    }
  };

  const handleOpening = () => {
    if (selectedSchool) {
      setSchoolName(selectedSchool.name);
      setGrades(selectedSchool.grades ?? []);
      setPrincipalId(selectedSchool.principal?.id);
      setSelectedTab("choose-existing-user");
    } else {
      newSchoolReset();
    }
  };

  const newSchoolReset = () => {
    setSchoolName("");
    setGrades([]);
    setPrincipalId(undefined);
    setSelectedTab("invite-now");
    resetForm();
  };

  const activeDistrictId = useMemo(() => {
    if (isAuthenticatedAdmin && !isTeamPlanPage) {
      return Number(routeParams.districtId);
    } else if (isTeamPlanPage) {
      return activePlanDistrictId;
    }

    return Number(districtId);
  }, [
    isAuthenticatedAdmin,
    routeParams.districtId,
    districtId,
    activePlanDistrictId,
  ]);

  const users: UserModel[] = useSelector((s) => s.users.users?.users ?? []);
  useEffect(() => {
    if ((isTeamPlanPage && activeDistrictId) || !isTeamPlanPage) {
      dispatch(getUsers.request(activeDistrictId));
    }
  }, [activeDistrictId, isTeamPlanPage]);

  const [schoolName, setSchoolName] = useState<string>("");

  const isExistSchool = useMemo(() => {
    if (selectedSchool?.name.toLowerCase() === schoolName.toLowerCase()){
      return false
    }
    const isExist = schools.find(et => et.name.toLowerCase() == schoolName.toLowerCase()) 
    return isExist;  
  },[schools, schoolName]);

  const handleSchoolNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSchoolName(e.target.value);
  };

  const [grades, setGrades] = useState<Grade[]>([]);

  const [principalId, setPrincipalId] = useState<number | undefined>();

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (!grades?.length) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.schools.upsert-dialog.validation.grade-required",
        }),
      };
      return validationOption;
    }

    if (isExistSchool) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.schools.upsert-dialog.validation.sameschool",
        }),
      };
      return validationOption;
    }

    if (firstName && email && lastName && principalId) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.validation.one-option-required",
        }),
      };
      return validationOption;
    }

    if (!principalId && (!firstName || !email || !lastName)) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.schools.upsert-dialog.validation.principal-required",
        }),
      };
      return validationOption;
    }

    return validationOption;
  };

  const [submitterName, setSubmitterName] = useState<
    SubmitterName | undefined
  >();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, message } = validate();
    if (isValid && activeDistrictId) {
      setWarning(undefined);

      setSubmitterName((e.nativeEvent as any)?.submitter.name);

      confirmAddOrUpdateSchool();
    } else {
      setWarning(message);
    }
  };

  const addOrUpdateSchool = useCallback(() => {
    const schoolObject: AddSchoolRequest = {
      name: schoolName,
      grades: grades,
      district: activeDistrictId!,
    };

    if (!principalId) {
      schoolObject.principal_team_champion = {
        first_name: firstName,
        last_name: lastName,
        email: email,
      };
    } else {
      schoolObject.principal = principalId;
    }

    if (selectedSchool) {
      dispatch(
        updateSchool.request({
          id: selectedSchool.id,
          ...schoolObject,
        })
      );
      return;
    }
    dispatch(addSchool.request(schoolObject));
  }, [
    schoolName,
    grades,
    activeDistrictId,
    principalId,
    firstName,
    lastName,
    email,
    selectedSchool,
  ]);

  const confirmAddOrUpdateSchool = useCallback(() => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(hideConfirmDialog());
          addOrUpdateSchool();
   !setupCompleted&& localStorage.setItem("tourSteps","AddSchoolInvitePrinciple")

        },
        show: true,
        intent: "warning",
        text: intl.formatMessage(
          {
            id: "app.confirm-dialog.add-update-school",
          },
          {
            grades: grades.reduce(
              (prevGrade, curGrade, i) =>
                prevGrade + `${i !== 0 ? ", " : ""}` + curGrade,
              ""
            ),
            b: (a) => <strong>{a}</strong>,
          }
        ),
        icon: "info-sign",
        confirmButtonText: intl.formatMessage({
          id: "app.titles.yes",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.no",
        }),
      })
    );
  }, [addOrUpdateSchool, grades]);

  const loading = useSelector((s) =>
    selectedSchool
      ? s.schools.loading.updateSchool
      : s.schools.loading.addSchool
  );
  const error = useSelector((s) =>
    selectedSchool ? s.schools.errors.updateSchool : s.schools.errors.addSchool
  );

  const markStepInvitePrincipalCompleted = useCallback(
    (stayHere?: boolean) => {
      dispatch(
        markStepsCompleted({
          keys: [
            DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
            DistrictSetupTaskKey.RegisterYourSchool,
          ],
        })
      );
      if (!stayHere) {
        history.push(
          generatePath(AuthRoutes.YourPlanTeam, {
            workspace: "team",
          } as any)
        );
      }
    },
    [tours]
  );

  const onSuccess = useCallback(() => {
    AppToaster.show({
      message: selectedSchool
        ? intl.formatMessage({ id: "app.toaster.schools.updated" })
        : intl.formatMessage({ id: "app.toaster.schools.added" }),
      icon: "tick",
      intent: "success",
    });
    const isSubmitAndAddAnother =
      submitterName === SubmitterName.SubmitAndAddAnother;

    if (isSubmitAndAddAnother) {
      newSchoolReset();
    } else {
      handleClose();
    }

    activeDistrictId &&
      dispatch(getSchoolsByDistrict.request(activeDistrictId));
    isTeamPlanPage && isDistrictActivePlan && dispatch(getPlans.request());
    !isSetupCompleted &&
      markStepInvitePrincipalCompleted(isSubmitAndAddAnother);
  }, [
    selectedSchool,
    activeDistrictId,
    isTeamPlanPage,
    submitterName,
    isDistrictActivePlan,
    handleClose,
    markStepInvitePrincipalCompleted,
    isSetupCompleted,
  ]);

  useLoading({ loading, error, onSuccess });

  const handlePrincipalChange = (value?: UserModel) => {
    setPrincipalId(value?.id);
  };

  const allPrincipals = useMemo(() => {
    return users.filter((u) => {
      if (u.profile?.role === UserRole.Principal) {
        return !u.profile?.is_dcc;
      }
      return (
        u.profile?.role && userRoles.schoolLeadership.includes(u.profile.role)
      );
    });
  }, [users]);

  return (
    <Dialog
      isOpen={show}
      onOpening={handleOpening}
      onClose={handleClose}
      icon={<Icon icon="office" iconSize={24} />}
      title={intl.formatMessage({
        id: selectedSchool
          ? "app.schools.upsert-dialog.edit-school"
          : "app.schools.upsert-dialog.add-school",
      })}
      canOutsideClickClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <form id="upsert-school-form" onSubmit={handleFormSubmit}>
          <FormGroup
            label={intl.formatMessage({
              id: "app.schools.upsert-dialog.school-name",
            })}
            labelFor="school-name"
          >
            <InputGroup
              id="school-name"
              required
              placeholder={intl.formatMessage({
                id: "app.schools.upsert-dialog.school-name.placeholder",
              })}
              fill
              value={schoolName}
              onChange={handleSchoolNameChange}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.titles.grades",
            })}
          >
            <SchoolGradesSelector grades={grades} setGrades={setGrades} />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.titles.principal",
            })}
          >
            <Card>
              <Tabs
                id={"principal-tabs"}
                selectedTabId={selectedTab}
                onChange={setSelectedTab}
                renderActiveTabPanelOnly
              >
                <Tab
                  id="invite-now"
                  title={intl.formatMessage({
                    id: "app.school-upsert-dialog.tabs.invite-now",
                  })}
                  panel={newUserComponent}
                />
                <Tab
                  id="choose-existing-user"
                  title={intl.formatMessage({
                    id: "app.school-upsert-dialog.tabs.choose-existing-user",
                  })}
                  panel={
                    <UserSelector
                      placeholder={intl.formatMessage({
                        id: "app.schools.select-principal",
                      })}
                      userId={principalId}
                      users={allPrincipals}
                      onUserChange={handlePrincipalChange}
                    />
                  }
                />
              </Tabs>
            </Card>
          </FormGroup>
        </form>
        {warning && (
          <div className="my-3">
            <Callout intent="warning">{warning}</Callout>
          </div>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex gap-8 justify-between items-center">
          <Button className="w-1/4" onClick={handleClose} disabled={loading}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>

          <div className="flex flex-row-reverse gap-2">
            <Button
              name={SubmitterName.SubmitAndClose}
              className="text-center flex-1"
              intent="primary"
              type="submit"
              form="upsert-school-form"
              loading={loading}
            >
              {intl.formatMessage({
                id: selectedSchool
                  ? "app.schools.upsert-dialog.save-school"
                  : "app.titles.submit-and-close",
              })}
            </Button>

            {!isSingleSchoolDistrict && !selectedSchool && (
              <Button
                name={SubmitterName.SubmitAndAddAnother}
                className="text-center flex-1"
                intent="primary"
                type="submit"
                form="upsert-school-form"
                loading={loading}
              >
                {intl.formatMessage({
                  id: "app.schools.upsert-dialog.submit-and-add-another-school",
                })}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SchoolsUpsertDialog;
