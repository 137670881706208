import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { School } from "../../../types";
import { useIntl } from "react-intl";
import EntityTable from "../../common/EntityTable";
import { Button, Tag } from "@blueprintjs/core";
import NoSchools from "../../pages/NoSchools";
import { CellProps } from "react-table";
import {
  deleteSchool,
  getSchoolsByDistrict,
  showUpsertSchoolDialog,
} from "../../../store/schools/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { useParams } from "react-router";
import SetupSchoolsTour from "./SetupSchoolsTour";
import { getPlans } from "../../../store/plans/actions";
import { getActivePlanDistrictId } from "../../../store/plans/selectors";
import useUserRole from "../../../helpers/hooks/useUserRole";
import { AppToaster } from "../../../helpers/toaster";

type RouteParams = {
  districtId?: string;
};

type OwnProps = { isSingleSchoolDistrict?: boolean; isTeamPlanPage?: boolean };

type Props = OwnProps & RouteParams;

const SchoolsTable: FunctionComponent<Props> = (props) => {
  const { isSingleSchoolDistrict, isTeamPlanPage } = props;

  const schools: School[] = useSelector((s) => s.schools.schools as School[]);

  const deleteLoading = useSelector((s) => s.schools.loading.deleteSchool);
  const deleteError = useSelector((s) => s.schools.errors.deleteSchool);

  const onDeleteSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage(
        {
          id: "app.titles.delete-toaster-message",
        },
        {
          name: "School",
        }
      ),
      icon: "tick",
      intent: "success",
    });

    if (isTeamPlanPage) {
      dispatch(getPlans.request());
    }
  }, []);

  useLoading({
    loading: deleteLoading,
    error: deleteError,
    onSuccess: onDeleteSuccess,
  });

  const intl = useIntl();
  const dispatch = useDispatch();

  const routeParams = useParams<RouteParams>();

  const activePlanDistrictId = useSelector(getActivePlanDistrictId);

  const [searchString, setSearchString] = useState<string>("");
  const { isSuperintendent, isAdmin } = useUserRole();

  const data = useMemo(() => {
    return schools;
  }, [schools]);

  const callbackFilter = useCallback(
    (school: School) => {
      const searchStringLowerCase = searchString.trim().toLowerCase();

      if (!searchStringLowerCase) {
        return true;
      }

      return school.name?.toLowerCase()?.includes(searchStringLowerCase);
    },
    [searchString]
  );

  const filteredData = useMemo(() => {
    return data.filter(callbackFilter);
  }, [data, callbackFilter]);

  const showConfirmDeleteDialog = (schoolId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onContinueDeleteSchool(schoolId)
        },
        show: true,
        intent: "warning",
        text: intl.formatMessage({
          id: "app.warning-dialogs.delete-school",
        }),
        icon: "info-sign",
        confirmButtonText: intl.formatMessage({ id: "app.titles.continue" }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.cancel",
        }),
        onCancel: () => {
          dispatch(hideConfirmDialog());
        },
      })
    );
  };

  const onContinueDeleteSchool = (schoolId: number) => {
    dispatch(hideConfirmDialog());
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteSchool.request(schoolId));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "warning",
        text: intl.formatMessage({
          id: "app.warning-dialogs.delete-school-text",
        }),
        icon: "info-sign",
        confirmButtonText: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-school-yes",
        }),
        cancelButtonText: intl.formatMessage({ id: "app.titles.no" }),
        canOutsideClickCancel: true,
      })
    );
  };


  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({ id: "app.common-table.columns.name" }),
        accessor: "name",
        width: "30%",
      },
      {
        Header: intl.formatMessage({ id: "app.schools-table.principal-email" }),
        accessor: "principal.email",
        wrap: true,
        width: "40%",
        Cell: ({ row: { original } }: CellProps<School>) => {
          if (!original.principal?.email) {
            return (
              <i className="flex justify-center">
                {intl.formatMessage({
                  id: "app.titles.not-applicable",
                })}
              </i>
            );
          }
          return <span className="break-all">{original.principal?.email}</span>;
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.grades" }),
        accessor: "grades",
        width: "20%",
        Cell: ({ row: { original } }: CellProps<School>) => {
          if (!original.grades?.length) {
            return (
              <i>
                {intl.formatMessage({
                  id: "app.titles.not-applicable",
                })}
              </i>
            );
          }

          return (
            <div className="flex flex-wrap gap-1">
              {original.grades.map((grade) => (
                <Tag key={grade} minimal intent="primary">
                  {grade}
                </Tag>
              ))}
            </div>
          );
        },
      },
      {
        id: "actions",
        width: "1%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<School>) => {
          return (
            <div className="flex gap-2">
              <Button
                title={intl.formatMessage({
                  id: "app.titles.edit",
                })}
                icon="edit"
                intent="primary"
                onClick={() => {
                  dispatch(showUpsertSchoolDialog(original));
                }}
              />
              <div>
                {(isAdmin || isSuperintendent) &&
                  <Button
                    title={intl.formatMessage({
                      id: "app.surveys-table.columns.delete",
                    })}
                    icon="trash"
                    intent="danger"
                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                      showConfirmDeleteDialog(original.id)
                    }
                  />
                }
              </div>
            </div>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    const routerDistrictId = Number(routeParams.districtId);
    if (!isTeamPlanPage && routerDistrictId) {
      dispatch(getSchoolsByDistrict.request(routerDistrictId));
    } else if (isTeamPlanPage && activePlanDistrictId) {
      dispatch(getSchoolsByDistrict.request(activePlanDistrictId));
    }
  }, [routeParams, isTeamPlanPage, activePlanDistrictId]);

  const loading = useSelector((s) => s.schools.loading.getSchoolsByDistrict);
  const error = useSelector((s) => s.schools.errors.getSchoolsByDistrict);
  useLoading({ loading, error });

  return (
    <EntityTable
      data={filteredData}
      columns={columns}
      searchString={searchString}
      setSearchString={setSearchString}
      loading={loading}
      noDataComp={
        <>
         <div className="my-32"> <NoSchools /></div>
          <SetupSchoolsTour isSingleSchoolDistrict={isSingleSchoolDistrict} />
        </>
      }
    />
  );
};

export default SchoolsTable;
