import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Classes, Dialog } from "@blueprintjs/core";
import {
  ACTIVITY_LINK_CLASSNAME,
  useAttachActivities,
} from "../../../../helpers/hooks/useAttachActivities";
import { Activity, ActivityTypeEnum } from "../../../../types";

type OwnProps = {
  show?: boolean;
  onClose: () => void;
};

type Props = OwnProps;

const DistrictComparisonInfoDialog: React.FC<Props> = ({
  show,
  onClose,
}: Props) => {
  const intl = useIntl();

  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      if (
        activity.pdf_resource?.attachment_url &&
        activity.activity_type === ActivityTypeEnum.EXTERNAL_RESOURCE
      ) {
        const link = document.createElement("a");
        if (activity.external_url) {
          link.setAttribute("target", "_blank");
        }
        link.href = activity.pdf_resource?.attachment_url;
        link.target = "_blank";
        document.body.append(link);
        link.click();
        link.remove();
      }
    },
  });

  const formatLi = useCallback((ending: string) => {
    return (
      <>
        <br />
        <li>
          {intl.formatMessage(
            {
              id: `app.district-comparison.instruction.dialog.containt.points.${ending}`,
            },
            { b: (chunks) => <b>{chunks}</b> }
          )}
        </li>
      </>
    );
  }, []);

  return (
    <Dialog
      isOpen={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: "app.district-comparison.instruction.dialog.title",
      })}
      style={{ minWidth: "48rem" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <>
          {intl.formatMessage(
            { id: "app.district-comparison.instruction.dialog.containt" },
            {
              b: (chunks) => <b>{chunks}</b>,
              i: (chunks) => <i>{chunks}</i>,
              activity: (chunks) => (
                <a
                  className={ACTIVITY_LINK_CLASSNAME}
                  onClick={onActivityClick()}
                >
                  <u>{chunks}</u>
                </a>
              ),
              competencesMenu: (
                <ul className="list-disc pl-10">
                  {intl.formatMessage(
                    {
                      id:
                        "app.district-comparison.instruction.dialog.containt.points",
                    },
                    {
                      firstLi: formatLi("firstLi"),
                      secondLi: formatLi("secondLi"),
                      thirdLi: formatLi("thirdLi"),
                      fourthLi: formatLi("fourthLi"),
                      fifthLi: formatLi("fifthLi"),
                    }
                  )}
                  <br />
                </ul>
              ),
              br: <br />,
            }
          )}
        </>
      </div>
    </Dialog>
  );
};

export default DistrictComparisonInfoDialog;
