import React, { useState } from "react";
import { Button, Tooltip } from "@blueprintjs/core";
import { useIntl } from "react-intl";

const useLockButton = () => {
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();

  const disabled = false;

  const lockButton: JSX.Element = (
    <Tooltip
      content={intl.formatMessage(
        { id: "app.forms.lock-button-tooltip" },
        { showPassword: +showPassword }
      )}
      disabled={disabled}
    >
      <Button
        className="flex"
        disabled={disabled}
        icon={showPassword ? "unlock" : "lock"}
        intent="warning"
        minimal={true}
        onClick={() => setShowPassword(!showPassword)}
      />
    </Tooltip>
  );

  return [lockButton, showPassword];
};

export default useLockButton;
