import React, { FunctionComponent } from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import PageEditor from "../../../../survey-deployment/forms/PageEditor";
import ActivityFilesFormGroup from "../../../../activities/dialogs/upsert-activity-dialog/ActivityFilesFormGroup";
import { AttachedResource, AttachedToType } from "../../../../../types";

type OwnProps = {
  strategyName: string;
  setStrategyName: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  supportingEvidence: string;
  setSupportingEvidence: React.Dispatch<React.SetStateAction<string>>;
  onFileChange: (type: "pdf" | "docx" | "ppt", resource?: AttachedResource) => void;
  pdfFile?: AttachedResource;
};

type Props = OwnProps;

const GeneralInfoTab: FunctionComponent<Props> = (props) => {
  const {
    strategyName,
    setStrategyName,
    description,
    setDescription,
    supportingEvidence,
    setSupportingEvidence,
    onFileChange,
    pdfFile
  } = props;

  const intl = useIntl();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStrategyName(value);
  };

  const handleDescriptionBlur = (value: string) => {
    setDescription(value);
  };
  const handleSupportingEvidenceBlur = (value: string) => {
    setSupportingEvidence(value);
  };

  return (
    <div>
      <FormGroup
        label={intl.formatMessage({
          id: "app.titles.name",
        })}
      >
        <InputGroup
          placeholder={intl.formatMessage({
            id: "app.strategies.name.placeholder",
          })}
          required
          value={strategyName}
          onChange={handleNameChange}
          type="text"
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({
          id: "app.titles.overview",
        })}
      >
        <PageEditor content={description} onBlur={handleDescriptionBlur} />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ id: "app.titles.supporting-evidence" })}
      >
        <PageEditor
          content={supportingEvidence}
          onBlur={handleSupportingEvidenceBlur}
        />
      </FormGroup>

      <ActivityFilesFormGroup
        onFileChange={onFileChange}
        pdfFile={pdfFile}
        isExternalResourceActivityType
        attachedType={AttachedToType.PDF_STRATEGY}
      />

    </div>
  );
};

export default GeneralInfoTab;
