import React, { FunctionComponent, useMemo, useState } from "react";
import TeamRoleSelector from "../../../districts/district-users/TeamRoleSelector";
import { TeamMember, TeamRole } from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { updateTeamMember } from "../../../../store/team-members/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { getCurrentUserId } from "../../../../store/auth/selectors";

type OwnProps = {
  teamMember: TeamMember;
};

type Props = OwnProps;

const TeamMemberRoleCell: FunctionComponent<Props> = (props) => {
  const { teamMember } = props;

  const currentUserId = useSelector(getCurrentUserId);

  const isMe = useMemo(() => {
    return currentUserId === teamMember.user.id;
  }, [teamMember, currentUserId]);

  const dispatch = useDispatch();

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const handleTeamRoleChange = (role: TeamRole) => {
    setLocalLoading(true);
    dispatch(
      updateTeamMember.request({
        body: {
          planId: activePlanId!,
          user: {
            id: teamMember.user.id,
            team_role: role,
            custom_role_name: teamMember?.user?.profile?.custom_role_name,
          },
        },
        onSuccess: isMe
          ? () => {
              document.location.reload();
            }
          : undefined,
      })
    );
  };

  const [localLoading, setLocalLoading] = useState<boolean>(false);

  const resetLoading = () => {
    setLocalLoading(false);
  };

  const loading = useSelector((s) => s.teamMembers.loading.updateTeamMember);
  const error = useSelector((s) => s.teamMembers.errors.updateTeamMember);

  const isLoading = useMemo(() => localLoading && loading, [
    localLoading,
    loading,
  ]);

  useLoading({
    loading: isLoading,
    error,
    onError: resetLoading,
    onSuccess: resetLoading,
  });

  return (
    <TeamRoleSelector
      isLoading={isLoading}
      onChange={handleTeamRoleChange}
      role={teamMember.team_role}
    />
  );
};

export default TeamMemberRoleCell;
