import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Domain, SelectorOptionType } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { CellProps } from "react-table";
import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { useLoading } from "../../../helpers/hooks/useLoading";
import EntityTable from "../../common/EntityTable";
import {
  deleteDomain,
  getDomains,
  showDomainUpsertDialog,
} from "../../../store/domains/actions";
import DomainUpsertDialog from "../domain-upsert-dialog/DomainUpsertDialog";
import NoDomains from "./NoDomains";
import { AppToaster } from "../../../helpers/toaster";
import { getSurveys } from "../../../store/surveys/actions";

type OwnProps = {};

type Props = OwnProps;

const DomainsTable: FunctionComponent<Props> = (props) => {
  const domains: Domain[] = useSelector((s) => s.domains.domains);

  const intl = useIntl();
  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState<string>("");

  const callbackFilter = useCallback(
    (domain: Domain) => {
      const searchStringLowerCase = searchString.trim().toLowerCase();

      if (!searchStringLowerCase) {
        return true;
      }

      return (
        domain.name.toLowerCase().includes(searchStringLowerCase) ||
        domain.description?.toLowerCase()?.includes(searchStringLowerCase)
      );
    },
    [searchString]
  );

  const filteredData = useMemo(() => {
    return domains.filter(callbackFilter);
  }, [domains, callbackFilter]);

  const surveys = useSelector((s) => s.surveys.surveys);
  const surveysOptions: SelectorOptionType[] = useMemo(
    () => surveys.map((s) => ({ value: s.id, label: s.name })),
    [surveys]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSurveyName = (surveyId: number | undefined) => {
    let survey = surveysOptions.find((option) => {
      return option.value === surveyId;
    });
    return survey?.label;
  }

  useEffect(() => {
    dispatch(getSurveys.request());
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showConfirmDeleteDialog = (domainId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteDomain.request(domainId));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-domain",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const loadingDeleteDomain = useSelector(
    (s) => s.domains.loading.deleteDomain
  );
  const errorDeleteDomain = useSelector((s) => s.domains.errors.deleteDomain);
  const onSuccessDeleteDomain = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.domains.deleted",
      }),
      icon: "tick",
      intent: "success",
    });
  }, [intl]);

  useLoading({
    loading: loadingDeleteDomain,
    error: errorDeleteDomain,
    onSuccess: onSuccessDeleteDomain,
  });

  const columns = useMemo(() => {
    return [
      {
        Header: intl.formatMessage({ id: "app.surveys-deployment-table.columns.survey" }),
        accessor: "survey",
        width: "20%",
        Cell: ({ row: { original } }: CellProps<Domain>) => {
          return (
            <div className="flex -ml-1">
              {getSurveyName(original.survey)}
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({ id: "app.common-table.columns.name" }),
        accessor: "name",
        width: "20%",
      },
      {
        Header: intl.formatMessage({
          id: "app.common-table.columns.description",
        }),
        accessor: "description",
        width: "100%",
      },
      {
        id: "actions",
        width: "100%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<Domain>) => {
          return (
            <div className="flex -ml-1">
              <Button
                className="whitespace-no-wrap m-1"
                title={intl.formatMessage({
                  id: "app.titles.edit",
                })}
                icon="edit"
                intent="primary"
                onClick={() => {
                  dispatch(showDomainUpsertDialog({ domain: original }));
                }}
              />
              <Button
                title={intl.formatMessage({
                  id: "app.surveys-table.columns.delete",
                })}
                icon="trash"
                className="whitespace-no-wrap m-1"
                intent="danger"
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  showConfirmDeleteDialog(original.id)
                }
              />
            </div>
          );
        },
      },
    ];
  }, [dispatch, getSurveyName, intl, showConfirmDeleteDialog]);

  const handleDialogOpen = () => {
    dispatch(showDomainUpsertDialog({}));
  };

  useEffect(() => {
    dispatch(getDomains.request());
  }, [dispatch]);

  const loading = useSelector((s) => s.domains.loading.getDomains);
  const error = useSelector((s) => s.domains.errors.getDomains);
  useLoading({ loading, error });

  return (
    <>
      <EntityTable
        data={filteredData}
        columns={columns}
        searchString={searchString}
        setSearchString={setSearchString}
        loading={loading}
        noDataComp={<NoDomains />}
      >
        <Popover
          position={"bottom-left"}
          className="flex-grow justify-start"
          content={
            <Menu>
              <MenuItem
                text={intl.formatMessage({
                  id: "app.domains.create-new-domain",
                })}
                icon="plus"
                className="px-3 capitalize"
                onClick={handleDialogOpen}
              />
            </Menu>
          }
          minimal
        >
          <Button
            large
            minimal
            title={intl.formatMessage({ id: "app.titles.domains" })}
            icon={"properties"}
            text={intl.formatMessage({ id: "app.titles.domains" })}
            intent="primary"
            className="font-bold px-4"
          />
        </Popover>
      </EntityTable>
      <DomainUpsertDialog />
    </>
  );
};

export default DomainsTable;
