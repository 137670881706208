import { createAction, createAsyncAction } from "typesafe-actions";
import {
  LoginRequest,
  RegisterUserRequest,
  RestorePasswordRequest,
} from "../../api/auth";
import { AuthenticatedUserModel, UserProfile } from "../../types";

export const logout = createAsyncAction(
  "@auth/LOGOUT_REQUEST",
  "@auth/LOGOUT_SUCCESS",
  "@auth/LOGOUT_FAILURE"
)<void, void, Error>();

export const login = createAsyncAction(
  "@auth/LOGIN_REQUEST",
  "@auth/LOGIN_SUCCESS",
  "@auth/LOGIN_FAILURE"
)<LoginRequest, void, Error>();

export const registerUser = createAsyncAction(
  "@auth/REGISTER_USER_REQUEST",
  "@auth/REGISTER_USER_SUCCESS",
  "@auth/REGISTER_USER_FAILURE"
)<
  { user: RegisterUserRequest; inviteCode: string },
  AuthenticatedUserModel,
  Error
>();

export const resetPassword = createAsyncAction(
  "@auth/RESET_PASSWORD_REQUEST",
  "@auth/RESET_PASSWORD_SUCCESS",
  "@auth/RESET_PASSWORD_FAILURE"
)<string, void, Error>();

export const getAuthenticatedUser = createAsyncAction(
  "@auth/GET_AUTHENTICATED_USER_REQUEST",
  "@auth/GET_AUTHENTICATED_USER_SUCCESS",
  "@auth/GET_AUTHENTICATED_USER_FAILURE"
)<void, AuthenticatedUserModel, Error>();

export const restorePassword = createAsyncAction(
  "@auth/RESTORE_PASSWORD_REQUEST",
  "@auth/RESTORE_PASSWORD_SUCCESS",
  "@auth/RESTORE_PASSWORD_FAILURE"
)<
  { request: RestorePasswordRequest; code: string },
  AuthenticatedUserModel,
  Error
>();

export const uploadProfileImage = createAsyncAction(
  "@auth/UPLOAD_PROFILE_IMAGE_REQUEST",
  "@auth/UPLOAD_PROFILE_IMAGE_SUCCESS",
  "@auth/UPLOAD_PROFILE_IMAGE_FAILURE"
)<File, Pick<UserProfile<number>, "image_url">, Error>();

export const removeProfileImage = createAsyncAction(
  "@auth/REMOVE_PROFILE_IMAGE_REQUEST",
  "@auth/REMOVE_PROFILE_IMAGE_SUCCESS",
  "@auth/REMOVE_PROFILE_IMAGE_FAILURE"
)<any,any ,Error>();

export const setAuthenticatedUser = createAction(
  "@auth/SET_AUTHENTICATED_USER"
)<AuthenticatedUserModel>();
