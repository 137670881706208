import { Search } from "history";
import { CollectedDemographicDataEnum } from "../types";

export const getUrlParams = (
  key: string,
  search: Search
): CollectedDemographicDataEnum[] => {
  const searchParams = new URLSearchParams(search);
  return searchParams.getAll(key) as CollectedDemographicDataEnum[];
};

export const getUrlParam = (key: string, search: Search): string | null => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get(key);
};

export const buildUrlWithSearchParams = (
  pathname: string,
  params: URLSearchParams
): string => {
  return (
    window.location.protocol +
    "//" +
    window.location.host +
    "/app" +
    pathname +
    "?" +
    params.toString()
  );
};

export const removeUrlSearchParam = (key: string, search: Search): string => {
  const updatedSearchParams = new URLSearchParams(search);
  updatedSearchParams.delete(key);
  return updatedSearchParams.toString();
};
