
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Icon, InputGroup } from '@blueprintjs/core';
import { Goal } from '../../../../types';
import { useIntl } from 'react-intl';
type OwnProps = {
    isSideMenuClosed: boolean;
    setIsSideMenuClosed: any;
    completedGoals: Goal[];
    onSelect: any;
    selectedGoal?: Goal;
};

type Props = OwnProps;

const ProgressLeftDrawer: FunctionComponent<Props> = (props) => {
    const intl = useIntl();
    const { isSideMenuClosed, setIsSideMenuClosed, completedGoals, onSelect, selectedGoal } = props;
    const [sortBy, setSortBy] = useState<boolean>(true);
    // FUNCTIONS
    const handleToggleSideMenu = () => {
        setIsSideMenuClosed(!isSideMenuClosed);
    }

    const handleGoalClick = (selectedGoal: Goal) => {
        onSelect(selectedGoal);
    };

    const isGreenCheckVisible = (goal: any) => {
        return goal.selected_domains.length > 0 && goal.targets.length > 0 && !!goal.desired_outcome && !!goal.statement && !goal.targets.find((et: any) => (!et.target_date || !et.target_value));
    }

    const data = useMemo(() => {
        // return completedGoals
        let sorted = completedGoals.sort(function (a, b) {
            let c: any = new Date(a.created_at);
            let d: any = new Date(b.created_at);
            return c - d;
        });

        if (sortBy)
            sorted = sorted.reverse();
        return sorted;
    }, [sortBy, completedGoals]);

    return (
        <div className={`planning__left `}>
            <div className="left-inner">
                <div className="left-bar-toggle" id="planning-toggle" onClick={() => handleToggleSideMenu()}>
                    {
                        isSideMenuClosed ?
                            <Icon icon="chevron-right" iconSize={16} />
                            : <Icon icon="chevron-left" iconSize={16} />
                    }
                </div>

                <div className="planning__left-list">
                    {
                        !!completedGoals.length && (
                            <ul>
                                                                    
                                { data.map((goal, index) => {
                                        const isGreenCheckVisibleFlag = isGreenCheckVisible(goal);
                                        return (<li className={`progressMlinks ${goal.id === selectedGoal?.id ? 'active' : ''}`} key={index} onClick={() => handleGoalClick(goal)} >
                                         
                                           <button>
                                          {!isGreenCheckVisibleFlag ?
                                                 <div className="list-icon warning-sign" title={intl.formatMessage({ id: "app.title.required-info-missing" })}>
                                                     <Icon icon="warning-sign" iconSize={13} />
                                                 </div> : <div className="list-icon check-sign">
                                                     <Icon icon="tick-circle" iconSize={13} />
                                                 </div>}
                                             <div className="list-content">
                                                
                                                 <h3>{goal.statement ? goal.statement : intl.formatMessage({ id: "app.titles.short-title-not-yet" })}</h3>
                                                 {/* <p>{et.desired_outcome ? et.desired_outcome : intl.formatMessage({ id: "app.titles.no-description-yet" })}</p> */}
                                             </div>
                                         </button> 
                                        </li>)
                                    })
                                }
                            </ul>
                        )
                    }
                </div>
            </div>
        </div >
    )
}


export default ProgressLeftDrawer;