import React, { FunctionComponent, useMemo } from "react";
import EntityTable from "../../../common/EntityTable";
import { useIntl } from "react-intl";
import useTeamRoleDefinitions from "../../../../helpers/hooks/useTeamRoleDefinitions";
import { TeamRole, TeamRoleDefinition } from "../../../../types";
import { CellProps } from "react-table";

type OwnProps = {};

type Props = OwnProps;

const TeamMemberRolesTable: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const roles = useTeamRoleDefinitions();

  const yourRole = TeamRole.TeamChampion; // todo

  const columns: any[] = useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: "app.titles.team-role" }),
        accessor: "title",
        Cell: ({ row: { original } }: CellProps<TeamRoleDefinition>) => (
          <span className="font-bold">
            {original.roleTitle}
            {original.roleTitle === yourRole && (
              <i className="ml-1 font-normal">
                ({intl.formatMessage({ id: "app.titles.your-role" })})
              </i>
            )}
          </span>
        ),
        width: "50%",
      },
      {
        Header: intl.formatMessage({ id: "app.title.responsibilities" }),
        accessor: (teamRoleDefinition: TeamRoleDefinition) => {
          return teamRoleDefinition.responsibilities.join(", ");
        },
        width: "50%",
      },
    ],
    [yourRole]
  );

  return (
    <EntityTable
      data={roles}
      columns={columns}
      showTopContainer={false}
      darkHeader
    />
  );
};

export default TeamMemberRolesTable;
