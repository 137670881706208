import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  TextArea,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import {
  AttachedResource,
  AttachedResourceType,
  AttachedToType,
  SelectorOptionType,
  TopicTag,
  UserTag,
} from "../../../../types";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getCurrentUserRole } from "../../../../store/auth/selectors";
import { defineUserTagByAuthenticatedUserRole, addHttp } from "../../utils";

type OwnProps = {
  resource: Partial<AttachedResource> & Pick<AttachedResource, "type">;
  onSave: (
    resource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => void;
  isLoading?: boolean;
  showAttachedToSelector?: boolean;
};

type Props = OwnProps;

const UpsertResourceForm: FunctionComponent<Props> = (props) => {
  const { resource, onSave, isLoading, showAttachedToSelector } = props;

  const intl = useIntl();

  const [resourceUrl, setResourceUrl] = useState<string>("");
  const handleResourceUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setResourceUrl(value);
  };

  const handleResourceUrlUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let httpValue = addHttp(value);
    setResourceUrl(httpValue);
  };

  const [resourceTitle, setResourceTitle] = useState<string>("");
  const handleResourceTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setResourceTitle(value);
  };

  const [description, setDescription] = useState<string>("");
  const handleResourceDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setDescription(value);
  };

  const [otherTopicTag, setOtherTopicTag] = useState<string>("");
  const handleResourceOtherTopicTagChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setOtherTopicTag(value);
  };

  const [attachedTo, setAttachedTo] = useState<AttachedToType | undefined>(
    undefined
  );
  const handleAttachedToChange = (
    v: ValueType<SelectorOptionType<AttachedToType>, false>
  ) => {
    setAttachedTo(v?.value);
  };

  const [topicTag, setTopicTag] = useState<TopicTag | undefined>(undefined);
  const handleTopicTagChange = (
    v: ValueType<SelectorOptionType<TopicTag>, false>
  ) => {
    const value = v?.value;
    if (value === TopicTag.OTHER) {
      setOtherTopicTag("");
    }
    setTopicTag(value);
  };

  const [userTag, setUserTag] = useState<UserTag | undefined>(undefined);

  const userRole = useSelector(getCurrentUserRole);

  useEffect(() => {
    setResourceUrl(resource?.url ?? "");
    setResourceTitle(resource?.title ?? "");
    setDescription(resource?.description ?? "");
    setAttachedTo(resource?.attached_to_type);
    setTopicTag(resource?.topic_tag);
    setOtherTopicTag(resource?.topic_tag_other ?? "");
    userRole &&
      setUserTag(
        resource?.user_tag ?? defineUserTagByAuthenticatedUserRole(userRole)
      );
  }, [resource, userRole]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onSave({
      ...resource,
      title: resourceTitle,
      url: resourceUrl,
      description: description,
      attached_to_type: attachedTo!,
      topic_tag: topicTag,
      topic_tag_other: otherTopicTag,
      user_tag: userTag,
    });
  };

  const attachedToOptions: SelectorOptionType<AttachedToType>[] = useMemo(() => {
    return _.chain(AttachedToType)
      .values()
      .map((value) => ({
        label: intl.formatMessage(
          { id: `app.attached-to-type` },
          { type: value }
        ),
        value: value,
      }))
      .sortBy((opt) => opt.label)
      .value();
  }, []);

  const attachedToValue = useMemo(() => {
    return attachedTo
      ? attachedToOptions.find((opt) => opt.value === attachedTo)
      : undefined;
  }, [attachedTo, attachedToOptions]);

  const topicTagOptions: SelectorOptionType<TopicTag>[] = useMemo(() => {
    return Object.values(TopicTag).map((value) => ({
      label: intl.formatMessage({ id: `app.topic-tag.${value}` }),
      value: value,
    }));
  }, []);

  const topicTagValue = useMemo(() => {
    return topicTag
      ? topicTagOptions.find((opt) => opt.value === topicTag)
      : undefined;
  }, [topicTag, topicTagOptions]);

  return (
    <form className={Classes.DIALOG_BODY} onSubmit={handleFormSubmit}>
      <FormGroup
        label={intl.formatMessage({ id: "app.resources.link.resource-title" })}
      >
        <InputGroup
          required
          value={resourceTitle}
          onChange={handleResourceTitleChange}
        />
      </FormGroup>
      {resource?.type !== AttachedResourceType.Upload && (
        <FormGroup
          label={intl.formatMessage({ id: "app.resources.link.resource-url" })}
        >
          <InputGroup
            type="text"
            required
            value={resourceUrl}
            onChange={handleResourceUrlChange}
            onBlur={handleResourceUrlUpdate}
          />
        </FormGroup>
      )}

      {/* {showAttachedToSelector ? (
        <FormGroup
          label={intl.formatMessage({ id: "app.resources.attached-to" })}
        >
          <Select
            value={attachedToValue}
            options={attachedToOptions}
            onChange={handleAttachedToChange}
          />
        </FormGroup>
      ) : null} */}

      <FormGroup label={intl.formatMessage({ id: "app.resources.topic-tag" })}>
        <Select
          value={topicTagValue}
          options={topicTagOptions}
          onChange={handleTopicTagChange}
        />
      </FormGroup>

      {topicTag === TopicTag.OTHER ? (
        <FormGroup
          label={intl.formatMessage({ id: "app.resources.other-topic-tag" })}
        >
          <TextArea
            fill
            value={otherTopicTag}
            onChange={handleResourceOtherTopicTagChange}
          />
        </FormGroup>
      ) : null}

      <FormGroup label={intl.formatMessage({ id: "app.titles.description" })}>
        <TextArea
          fill
          value={description}
          onChange={handleResourceDescriptionChange}
        />
      </FormGroup>

      <div className="flex items-center justify-end">
        <Button intent="primary" loading={isLoading} type="submit">
          {intl.formatMessage({ id: "app.titles.save" })}
        </Button>
      </div>
    </form>
  );
};

export default UpsertResourceForm;
