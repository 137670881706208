import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import TooltipComponent from "../../../tour/TooltipComponent";
import { useSelector } from "react-redux";
import { DistrictSetupTaskKey } from "../../../../types";

type OwnProps = {};

type Props = OwnProps;

const FamiliarizeWithSccpTour: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const steps: Step[] = useMemo(() => {
    return [
      {
        target: '[data-tour="familiarize-with-sccp"]',
        content: intl.formatMessage({
          id: "app.tours.familiarize-with-sccp",
        }),
        disableBeacon: true,
        placement: "center",
      },
    ];
  }, []);

  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const [show, setShow] = useState(false);
  useEffect(() => {
    setupCompleted
      ? setShow(false)
      : setTimeout(
        () => setShow(!tours[DistrictSetupTaskKey.FamiliarizeWithSccp]),
        200
      );
  }, [setupCompleted]);

  const handleJoyrideAction = (data: CallBackProps) => {
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      setShow(false);
    }
  };

  return (
    <ReactJoyride
      run={show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
    />
  );
};

export default FamiliarizeWithSccpTour;
