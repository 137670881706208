import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import TooltipComponent from "../../../../tour/TooltipComponent";
import moment from "moment";
import useDashboardTourFix from "./useDashboardTourFix";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useLocation } from "react-router";
import { markStepCompleted, markStepsCompleted, updateMakingProgressTourTimeWatched } from "../../../../../store/tours/actions";
import { AuthRoutes } from "../../../../../App";
import { DistrictSetupTaskKey} from "../../../../../types/custom";

type OwnProps = {
  isFirst?: boolean;
};

type Props = OwnProps;

const HOURS_BREAKPOINT = 4;

const MakingProgressTour: FunctionComponent<Props> = (props) => {
  const { isFirst } = props;
  const history = useHistory();

  const intl = useIntl();

  const dispatch = useDispatch();

  const [show, setShow] = useState<boolean>(false);
  const { rerender } = useDashboardTourFix({ show });
  const { state: locationState } = useLocation<{ fromWorkSpace?: string }>();

  const steps: Step[] = useMemo((): Step[] => {
    const items = [
      {
        target: '[data-tour="add-school-button"]',
        content: intl.formatMessage({
          id: "app.tours.superintendent-setup.making-progress.add-school-button",
        }),
        disableBeacon: true,
      },
    ];
    if (
      locationState?.fromWorkSpace &&
      locationState.fromWorkSpace === "welcome"
    ) {
      const welcomeStep: Step = {
        target: '[data-tour="manage-school-users"]',
        content: intl.formatMessage({
          id: "app.tours.district-setup-widget.welcome-to-dashboard",
        }),
        disableBeacon: true,
      };
      return [welcomeStep, ...items];
    }

    return items;
  }, [isFirst]);

  const handleJoyrideAction = (data: CallBackProps) => {
    if(data.action === 'prev'){
      setShow(false);
    }
    if(data.step.target === '[data-tour="add-school-button"]' && ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)){
      dispatch(markStepCompleted({
        key: DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
        }))
      history.push(
        generatePath(AuthRoutes.YourPlanTeam, {
          workspace: "team",
        } as any),
      );
      // setShow(false);
      dispatch(updateMakingProgressTourTimeWatched(new Date()));
    }
    else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
      setShow(false);
      dispatch(updateMakingProgressTourTimeWatched(new Date()));
    }
  };

  const {
    selectedDistrictSetupTaskKey: dialogInfoKey,
    setupCompleted,
    districtSetupState: tours,
  } = useSelector((s) => s.tours);

  useEffect(() => {
    if (setupCompleted) {
      setShow(false);
    } else {
      const { makingProgressTourTimeWatched: time } = tours;
      if (!dialogInfoKey) {
        if (time) {
          const diff = moment().diff(moment(time), "hours");
          if (diff >= HOURS_BREAKPOINT) {
            setShow(true);
          }
        } else {
          //first visit (after welcome page)
          setShow(true);
        }
      }
      dispatch(updateMakingProgressTourTimeWatched(new Date()));
    }
  }, [setupCompleted]);

  return (
    <ReactJoyride
      // key={rerender}
      run={show}
      steps={steps}
      tooltipComponent={TooltipComponent}
      continuous
      callback={handleJoyrideAction}
      locale={{
        next: { title: intl.formatMessage({ id: "app.titles.next" }) },
      }}
      disableOverlayClose
    />
  );
};

export default MakingProgressTour;
