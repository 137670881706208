import { Spinner } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import useGetDatasetMarksLoading from "../../../helpers/hooks/loading/useGetDatasetMarksLoading";
import { useGetDatasetsLoading } from "../../../helpers/hooks/loading/useGetDatasetsLoading";
import { useLoading } from "../../../helpers/hooks/useLoading";
import {
  DomainComparison,
  DomainQuestionsComparison,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
} from "../../../types";
import { COMPARISON_COLORS } from "../tab/SurveyComparisonTab";
import SurveyReportNonIdealState from "./non-ideal-states/SurveyReportNonIdealState";
import DomainComparisonTableRow from "./survey-comparison-table-row/DomainComparisonTableRow";
import DomainQuestionComparisonList from "./survey-comparison-table-row/DomainQuestionComparisonList";
import { isDomainQuestionsComparison } from "./utils";

type OwnProps = {
  tagId: string | undefined;
  domainId: number | undefined;
  surveyDeploymentId: number | undefined;
  demographics: SurveyDemographic[];
  surveyComparison: DomainQuestionsComparison | DomainComparison[];
  showNonIdealState?: boolean;
};

type Props = OwnProps;

const SurveyComparisonTable: React.FC<Props> = (props) => {
  const {
    tagId,
    domainId,
    surveyDeploymentId,
    demographics,
    surveyComparison,
    showNonIdealState,
  } = props;

  const intl = useIntl();

  const selectedSurveyDeployment = useSelector<
    SurveyDeployment<number, Survey<number>> | undefined
  >((s) =>
    s.surveysDeployment.surveysDeploymentBySchool?.find(
      (s) => s.id === surveyDeploymentId
    )
  );

  const comparisonLoading = useSelector(
    (s) => s.surveyReports.loading.getSurveyComparison
  );
  const errors = useSelector((s) => s.surveyReports.errors.getSurveyComparison);
  useLoading({ loading: comparisonLoading, error: errors });

  const activePlanSchoolId = useSelector((s) => s.plans.activePlan?.school?.id);

  const { loading: loadingGetDatasetMarks } = useGetDatasetMarksLoading({
    schoolId: activePlanSchoolId,
    supportsBookmarks: !!selectedSurveyDeployment?.survey.supports_bookmarks,
  });
  const { loading: loadingGetDatasets } = useGetDatasetsLoading();

  const numberOfColumns = useMemo(() => {
    return demographics?.length + 1;
  }, [demographics]);

  const isTagComparison = useMemo(() => {
    return tagId !== undefined;
  }, [tagId]);

  const hasExtraColumn = useMemo(() => {
    return !!(domainId || tagId);
  }, [domainId, tagId]);

  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      className={"survey-comparison-table bordered"}
    >
      <thead>
        <tr>
          <th>
            {isTagComparison
              ? intl.formatMessage({
                  id: "app.survey-report.dialogs.item",
                })
              : intl.formatMessage({
                  id: `app.survey-report.table.second-head.${
                    domainId
                      ? "common-text"
                      : `${
                          selectedSurveyDeployment?.survey
                            ?.survey_categories_type ?? "domain"
                        }`
                  }`,
                })}
          </th>
          <th colSpan={numberOfColumns - 1}>
            {intl.formatMessage({
              id: "app.survey-report.table.second-head.mean-score",
            })}
          </th>
          {hasExtraColumn && (
            <th style={{ width: "12rem", minWidth: "12rem" }} />
          )}
        </tr>
        <tr>
          <th />
          {demographics?.map((item, index) => (
            <th key={index} className="text-white font-light text-xl">
              {intl.formatMessage({
                id: `app.filters.respondent-type.${item}`,
              })}
            </th>
          ))}
          {hasExtraColumn && <th />}
        </tr>
      </thead>
      <tbody>
        {comparisonLoading || loadingGetDatasetMarks || loadingGetDatasets ? (
          <tr>
            <td
              colSpan={hasExtraColumn ? numberOfColumns + 1 : numberOfColumns}
            >
              <Spinner intent={"primary"} className="py-4" />
            </td>
          </tr>
        ) : showNonIdealState ? (
          <tr>
            <td
              colSpan={hasExtraColumn ? numberOfColumns + 1 : numberOfColumns}
            >
              <SurveyReportNonIdealState />
            </td>
          </tr>
        ) : isDomainQuestionsComparison(surveyComparison) ? (
          <DomainQuestionComparisonList
            demographics={demographics}
            domainId={domainId}
            surveyComparison={surveyComparison}
          />
        ) : (
          (surveyComparison as DomainComparison[])?.map((item, index) => (
            <DomainComparisonTableRow
              index={index}
              item={item}
              key={index}
              demographics={demographics}
              color={
                item.domain?.color_hex
                  ? item.domain?.color_hex
                  : COMPARISON_COLORS[
                      (item?.domain?.id ?? 0) % COMPARISON_COLORS.length
                    ]
              }
            />
          ))
        )}
      </tbody>
    </table>
  );
};

export default SurveyComparisonTable;
