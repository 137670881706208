import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import {
  discardDatasetMark,
  flagDatasetMark,
  updateDatasetMark,
  upsertDatasetWithDatasetMark,
} from "../../../store/dataset-marks/actions";
import {
  BookmarkType,
  Dataset,
  DatasetType,
  MeasurementType,
  DatasetParamsKey,
} from "../../../types";
import useSelectedSchoolId from "../../../helpers/hooks/useSelectedSchoolId";
import _ from "lodash";
import { UpsertDatasetWithDatasetMarkRequest } from "../../../api/dataset-marks/types";

export type DatasetParams = Pick<
  Dataset,
  | "direct_instruction"
  | "gender"
  | "grade"
  | "race"
  | "remote_learning"
  | "staff_role"
  | "reduced_lunch"
  | "has_iep"
  | "english_at_home"
  | "demographic"
  | "is_grouped"
> & {
  domainId?: number;
  questionId?: number;
};

export const datasetParamsArray: DatasetParamsKey[] = [
  "direct_instruction",
  "gender",
  "grade",
  "race",
  "remote_learning",
  "staff_role",
  "reduced_lunch",
  "has_iep",
  "english_at_home",
  // "demographic",
  "is_grouped",
];

const KeysRemap: any = {
  domainId: "domain",
  questionId: "question",
};

export const findDatasetsByParams = (params: DatasetParams) => (
  dataset: Dataset
) => {
  return (
    params.domainId == dataset.domain?.id &&
    params.questionId == dataset.question?.id &&
    params.direct_instruction == dataset.direct_instruction &&
    params.gender == dataset.gender &&
    params.grade == dataset.grade &&
    params.race == dataset.race &&
    params.remote_learning == dataset.remote_learning &&
    params.is_grouped == dataset.is_grouped &&
    params.demographic == dataset.demographic &&
    params.staff_role == dataset.staff_role &&
    params.reduced_lunch == dataset.reduced_lunch &&
    params.has_iep == dataset.has_iep &&
    params.english_at_home == dataset.english_at_home
  );
};

export const useDatasetMark = (params: DatasetParams, overallMean?: number) => {
  const dispatch = useDispatch();

  const surveyDeployment = useSelector(
    (s) => s.surveyReports.selectedSurveyDeployment
  );

  const datasets = useSelector((s) => s.datasets.datasets);
  const datasetMarks = useSelector((s) => s.datasetMarks.datasetMarks);

  const relatedDataset = useMemo(() => {
    const filterF = findDatasetsByParams(params);
    return datasets.find(filterF);
  }, [datasets, params, surveyDeployment?.id]);

  const relatedDatasetMark = useMemo(() => {
    if (!relatedDataset || !surveyDeployment?.id) {
      return undefined;
    }
    return datasetMarks.find(
      (datasetMark) =>
        datasetMark.dataset.id === relatedDataset.id &&
        datasetMark.dataset.data_points?.some(
          (dp) => dp.survey_deployment_id === surveyDeployment.id
        )
    );
  }, [datasetMarks, relatedDataset, surveyDeployment?.id]);

  const { schoolId } = useSelectedSchoolId();

  const onDiscard = useCallback(() => {
    if (!schoolId) {
      return;
    }

    if (relatedDatasetMark) {
      dispatch(
        discardDatasetMark.request({
          schoolId: schoolId,
          datasetMarkId: relatedDatasetMark.id,
        })
      );
    }
  }, [relatedDatasetMark, relatedDataset, schoolId]);

  const allPlans = useSelector((s) => s.plans.plans);
  const activePlanId = allPlans.find((et) => et.school?.id === schoolId)?.id;

  const createDatasetAndBookmark = (bookmarkType: BookmarkType) => {
    if (activePlanId && surveyDeployment?.id) {
      const req: UpsertDatasetWithDatasetMarkRequest = {
        planId: activePlanId,
        surveyId: surveyDeployment.survey.id,
        body: {
          dataset: {
            ..._.mapKeys<any>(params, (value, key: string): string => {
              return KeysRemap[key] ?? key;
            }),
            type: DatasetType.Survey,
            measurement_type: MeasurementType.Number,
          },
          data_point: {
            type: DatasetType.Survey,
            numeric_type: MeasurementType.Number,
            survey_deployment: surveyDeployment.id,
            value: overallMean,
          },
          bookmark_type: bookmarkType,
        },
      };
      dispatch(upsertDatasetWithDatasetMark.request(req));
    }
  };

  const onClick = useCallback(
    (bookmarkType: BookmarkType) => {
      if (!schoolId) {
        return;
      }

      if (relatedDatasetMark) {
        dispatch(
          updateDatasetMark.request({
            schoolId: schoolId,
            datasetMarkId: relatedDatasetMark.id,
            bookmarkType: bookmarkType,
          })
        );
      } else if (relatedDataset) {
        if (surveyDeployment?.id) {
          dispatch(
            flagDatasetMark.request({
              schoolId: schoolId,
              datasetId: relatedDataset.id,
              bookmarkType: bookmarkType,
              dataPoint: {
                type: DatasetType.Survey,
                numeric_type: MeasurementType.Number,
                survey_deployment: surveyDeployment.id,
                value: overallMean,
              },
            })
          );
        }
      } else {
        createDatasetAndBookmark(bookmarkType);
      }
    },
    [relatedDatasetMark, relatedDataset, schoolId]
  );

  return {
    supportsBookmarks: !!surveyDeployment?.survey.supports_bookmarks,
    relatedDataset,
    relatedDatasetMark,
    bookmarkType: relatedDatasetMark?.bookmark_type,
    flagged: !!relatedDatasetMark,
    onClick: onClick,
    onDiscard: onDiscard,
  };
};

export default useDatasetMark;
