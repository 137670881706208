import { useMemo } from "react";
import moment from "moment";
import { Months } from "@blueprintjs/datetime";

export const useDateInputDefaultMinMax = () => {
  const minDate = useMemo(() => {
    return moment().toDate();
  }, []);

  const maxDate = useMemo(() => {
    return moment()
      .add(10, "y")
      .set({ month: Months.DECEMBER, day: 31 })
      .toDate();
  }, []);

  return {
    minDate,
    maxDate,
  };
};
