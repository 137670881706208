import { Button, Callout, Spinner } from "@blueprintjs/core";
import moment from "moment";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getMeetings } from "../../../store/meetings/actions";
import { isDistrictPlan } from "../../../store/plans/selectors";
import { getSurveysDeployment } from "../../../store/surveys-deployment/actions";
import DashboardWidget from "../dashboard-widget/DashboardWidget";
import TeamPlanWelcome from "../TeamPlanWelcome";
import StrategicPlanWidget from "./strategic-plan-widget/StrategicPlanWidget";
import SurveyAdministrationWidget from "./survey-administration-widget/SurveyAdministrationWidget";
import UpcomingEventsCard from "./UpcomingEventsCard";
import RecentSCIWritingWidget from "./recent-sci-writing-widget/RecentSCIWritingWidget";
import image from "../../../static/icons/png/SeeYouSoon.png";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../App";
import { PlanWorkspaces, UserNotesType } from "../../../types";
import { useHistory } from "react-router-dom";
import DistrictSetupWidget from "./district-setup-widget/DistrictSetupWidget";
import useWorkspacesLock from "../../../helpers/hooks/useWorkspacesLock";
import DashboardTour from "../../tour/team-plan/DashboardTour";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";

type OwnProps = {};

type Props = OwnProps;

const TeamPlanDashboard: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const history = useHistory();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const activeSurveysDeployment = useSelector((s) =>
    s.surveysDeployment.surveysDeployment?.filter((sd) =>
      moment(sd.end_date).isAfter(moment())
    )
  );

  const { wsAvailableDict } = useWorkspacesLock({ workspaces: [] });

  const members = useSelector((s) => s.teamMembers.teamMembers);
  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  const [showStrategicPlan, setShowStrategicPlan] = useState(false);

  const showNoMembersCallout = useMemo(
    () => {
      return false; // TODO 10/11/2021 Hide for now: onboarding updates will replace it

      // return (
      //   isDistrictActivePlan &&
      //   members?.length &&
      //   members.length === 1 &&
      //   !loading
      // );
    },
    [
      // members, loading, isDistrictActivePlan
    ]
  );
  const me = useSelector((s) => s.auth.authenticatedUser);

  const getPlansLoading = useSelector((s) => s.plans.loading.getPlans);

  useEffect(() => {
    if (activePlan) {
      dispatch(getSurveysDeployment.request({ planId: activePlan?.id }));
    }
  }, [activePlan]);

  useEffect(() => {
    if (activePlan) {
      dispatch(getMeetings.request(activePlan.id));
    }
  }, [activePlan]);

  const handleNavigateClick = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanTeam, {
        workspace: "team",
        tabId: isDistrictActivePlan
          ? UserNotesType.DCLT_TEAM_HOME
          : UserNotesType.SCLT_TEAM_HOME,
      } as any)
    );
  };

  useEffect(() => {
    const isDummyDistrict = me?.profile?.district?.is_dummy_district;
    if (isDummyDistrict){
      setShowStrategicPlan(true);
    }
    else{
      setShowStrategicPlan(false);
    }
  }, [activePlan, me]);

  const handleMoveToCollectDataClick = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanData, {
        workspace: "data",
        tabId: UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
      } as any)
    );
  };

  if (getPlansLoading && !activePlan) {
    return <Spinner intent="primary" />;
  }

  if (!activePlan) {
    return <TeamPlanWelcome />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.dashboard" })}</title>
      </Helmet>

      <div className="teampage_container">
        <WorkspaceHeader
          title={intl.formatMessage({
            id: "app.titles.dashboard",
          })}
        />

        <hr className="mt-2 mb-4" />
        <div>
          {!isDistrictActivePlan ? (
            <DashboardWidget wsAvailableDict={wsAvailableDict} />
          ) : null}

          {showNoMembersCallout ? (
            <div className="mb-4">
              <Callout intent="primary" icon={null}>
                <div className="flex items-center">
                  <img
                    src={image}
                    style={{ width: 48, height: "auto" }}
                    alt="Alert"
                  />
                  <p className="text-xl font-semibold ml-2">
                    {intl.formatMessage({
                      id: "app.teaming.dashboard.no-users",
                    })}
                  </p>
                  <div className="flex flex-grow justify-end">
                    <Button
                      intent="primary"
                      text={intl.formatMessage({
                        id: "app.titles.navigate-to-invite-user-page",
                      })}
                      title={intl.formatMessage({
                        id: "app.titles.navigate-to-invite-user-page",
                      })}
                      onClick={handleNavigateClick}
                    />
                  </div>
                </div>
              </Callout>
            </div>
          ) : null}

          {/* <div className="space-y-4 mb-4">
            <DistrictSetupWidget />
          </div> */}

          <div
            className="mb-4"
            data-tour="leadership-team-widget"
            data-cy="leadership-team-widget"
          >
            <UpcomingEventsCard isActiveDistrictPlan={isDistrictActivePlan} />
          </div>

          <div className={`grid grid-cols-1 gap-4`}>
            <div className="space-y-4">
              {!isDistrictActivePlan && (
                <SurveyAdministrationWidget
                  activeSurveysDeployment={activeSurveysDeployment}
                  onMoveToCollectDataPage={handleMoveToCollectDataClick}
                  isDataWorkspaceLocked={
                    !wsAvailableDict[PlanWorkspaces.Data]?.available
                  }
                />
              )}

              <div data-tour={"recent-sci-writing-widget"}>
                <RecentSCIWritingWidget forDashboard />
              </div>
            </div>

            <div>{!isDistrictActivePlan && showStrategicPlan && <StrategicPlanWidget />}</div>
          </div>
        </div>
      </div>

      <DashboardTour />
    </>
  );
};

export default TeamPlanDashboard;
