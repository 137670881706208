import React, { FunctionComponent, useMemo } from "react";
import { Dialog, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import UpsertResourceForm from "./UpsertResourceForm";
import { AttachedResource, AttachedResourceType } from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { hideUpsertResourceDialog } from "../../../../store/resources/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";

type OwnProps = {
  isUploadedFilesTab?: boolean;
};

type Props = OwnProps;

const UpsertResourceDialog: FunctionComponent<Props> = ({
  isUploadedFilesTab,
}: Props) => {
  const {
    show,
    onClose,
    onSave,
    resource,
    isLoadingSelector,
    errorSelector,
  } = useSelector((s) => s.resources.dialogs.upsertResourceDialog);
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleLinkResourceAdd = (
    resource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => {
    onSave && onSave(resource);
  };

  const isUpload = useMemo(() => {
    return resource?.type === AttachedResourceType.Upload;
  }, [resource?.type]);

  const title = useMemo(() => {
    return isUpload
      ? intl.formatMessage({
          id: resource?.id
            ? "app.resources.edit-file-details"
            : "app.resources.enter-file-details",
        })
      : intl.formatMessage({
          id: resource?.id
            ? "app.resources.edit-url"
            : "app.resources.enter-url",
        });
  }, [isUpload, resource?.id]);

  const handleClose = (event: React.SyntheticEvent<HTMLElement>) => {
    if (onClose) {
      onClose(event);
    } else {
      dispatch(hideUpsertResourceDialog());
    }
  };

  const isLoading = useSelector(isLoadingSelector ?? (() => undefined));
  const error = useSelector(errorSelector ?? (() => undefined));
  useLoading({ loading: isLoading, error: error });

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon={isUpload ? "document" : "link"} iconSize={24} />}
      title={title}
      portalClassName="upsert-resource-portal"
    >
      {resource && (
        <UpsertResourceForm
          resource={resource}
          onSave={handleLinkResourceAdd}
          isLoading={isLoading}
          showAttachedToSelector={!isUploadedFilesTab}
        />
      )}
    </Dialog>
  );
};

export default UpsertResourceDialog;
