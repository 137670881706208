import { TeamMember } from "../../types";
import Axios from "axios";
import { API_ROOT } from "../config";
import {
  AddTeamMemberToPlanRequest,
  InviteTeamMembersRequest,
  InviteTeamMembersResponse,
  UpdateTeamMemberRequest,
  GetTeamMemberRequest,
} from "./types";

export const getTeamMembersByPlan = (
  detail: GetTeamMemberRequest
): Promise<TeamMember[]> => {
  return Axios.get(
    `${API_ROOT}/plans/${detail.planId}/members/${
      detail.include_district_user
        ? `?include_district_user=${detail.include_district_user}`
        : ""
    }`
  );
};

export const updateTeamMember = ({
  planId,
  user: { id: userId, ...body },
}: UpdateTeamMemberRequest): Promise<TeamMember> => {
  return Axios.put(`${API_ROOT}/plans/${planId}/members/${userId}/`, body);
};

export const addTeamMembers = (
  planId: number,
  members: AddTeamMemberToPlanRequest[]
): Promise<TeamMember[]> => {
  return Axios.post(`${API_ROOT}/plans/${planId}/members/`, members);
};

export const inviteTeamMembers = ({
  planId,
  users,
}: InviteTeamMembersRequest): Promise<InviteTeamMembersResponse> => {
  return Axios.post(`${API_ROOT}/users/plans/${planId}/invite/`, users);
};

export const deleteTeamMemberFromPlan = (
  planId: number,
  userId: number
): Promise<void> => {
  return Axios.delete(`${API_ROOT}/plans/${planId}/members/${userId}/`);
};
