import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Button, FormGroup, Icon, InputGroup } from "@blueprintjs/core";
import { Timer } from "../../types";
import { useWindowUnload } from "../../helpers/hooks/useWindowUnload";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getArrayFromSessionStorage,
  SessionStorageKeys,
} from "../../constants/session-storage";
import SurveyTitle from "../utils/SurveyTitle";
import NjSciLogo from "../common/NjSciLogo";

type OwnProps = {
  requestQuestions: Function;
  onStartSurveyClick: (isShowQuestions: boolean) => void;
  onUpdateTimer: (timer: Timer) => void;
  schoolName?: string;
  demographic?: string;
  instruction?: string;
};

type Props = OwnProps;

const WelcomeSurveyPage: React.FC<Props> = (props: Props) => {
  const {
    requestQuestions,
    onStartSurveyClick,
    schoolName,
    demographic,
    onUpdateTimer,
    instruction,
  } = props;

  const error = useSelector((s) => s.surveys.surveyPermissionsError);

  const timer = useMemo<Timer>(() => {
    const timers: Timer[] = getArrayFromSessionStorage(
      SessionStorageKeys.SurveyTimer
    );
    const introTimer = timers.find((t) => t.page === "intro");
    return !!introTimer
      ? introTimer
      : {
          page: "intro",
          time_start: moment().format(),
        };
  }, []);

  const setEndDateToTimer = () => {
    onUpdateTimer({ ...timer, time_end: moment().format() });
  };
  useWindowUnload({ onBeforeUnload: setEndDateToTimer });

  const intl = useIntl();

  const selectedLocale = useSelector((s) => s.UIState.surveyLocale);

  const [password, setPassword] = useState<string>("");

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!!error) {
      setEndDateToTimer();
      sessionStorage.setItem(
        SessionStorageKeys.SurveyPassword,
        JSON.stringify(password)
      );
      requestQuestions();
    } else {
      setEndDateToTimer();
      onStartSurveyClick(true);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget?.setCustomValidity("");
    setPassword(e.target?.value);
  };

  const setErrorMessage = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget?.setCustomValidity(
      intl.formatMessage({ id: "app.validators.password-required" })
    );
  };

  const direction = useMemo(() => {
    return `${intl.formatMessage({
      id: "app.language.direction",
    })}`;
  }, []);

  return (
    <div dir={direction}>
      <NjSciLogo />

      <SurveyTitle
        schoolName={!!error ? error?.school_name : schoolName}
        demographic={demographic}
      />

      <div className="my-4">
        <div
          dangerouslySetInnerHTML={{
            __html:
              (!!error
                ? !!error?.page_text
                  ? error?.page_text[selectedLocale]?.instruction
                  : ""
                : instruction) ?? "",
          }}
        />
      </div>

      <form className={"mx-4 my-4"} onSubmit={handleFormSubmit}>
        {!!error && (
          <div className="flex justify-center mt-4">
            <hr />
            <FormGroup
              className="w-full font-bold sm:w-1/2 text-lg mt-4"
              label={intl.formatMessage(
                { id: "app.forms.surveys.passwords" },
                {
                  surveyName: demographic,
                  b: (a) => (
                    <span className={"uppercase text-blue-second"}>{a}</span>
                  ),
                }
              )}
            >
              <InputGroup
                className="uppercase"
                value={password}
                placeholder={intl.formatMessage({
                  id: "app.forms.surveys.passwords.placeholder",
                })}
                onChange={handlePasswordChange}
                fill
                large
                onInvalid={setErrorMessage}
                required
                title={intl.formatMessage({
                  id: "app.validators.password-required",
                })}
              />
            </FormGroup>
          </div>
        )}

        <div className="flex justify-center pt-12 pb-24">
          <Button
            intent="primary"
            className="w-1/3 p-4"
            icon={<Icon icon={"arrow-right"} iconSize={36} />}
            type={"submit"}
          />
        </div>
      </form>
    </div>
  );
};

export default WelcomeSurveyPage;
