import { createAction, createAsyncAction } from "typesafe-actions";
import { ActivityScopeEnum, MeetingTemplate } from "../../types";
import { MeetingTemplateRequest } from "../../api/meeting-templates/types";

export const getMeetingTemplates = createAsyncAction(
  "@meeting-templates/GET_MEETING_TEMPLATES_REQUEST",
  "@meeting-templates/GET_MEETING_TEMPLATES_SUCCESS",
  "@meeting-templates/GET_MEETING_TEMPLATES_FAILURE"
)<
  { type?: ActivityScopeEnum; showHidden?: boolean },
  MeetingTemplate[],
  Error
>();

export const addMeetingTemplate = createAsyncAction(
  "@meeting-templates/ADD_MEETING_TEMPLATE_REQUEST",
  "@meeting-templates/ADD_MEETING_TEMPLATE_SUCCESS",
  "@meeting-templates/ADD_MEETING_TEMPLATE_FAILURE"
)<MeetingTemplateRequest, MeetingTemplate, Error>();

export const updateMeetingTemplate = createAsyncAction(
  "@meeting-templates/UPDATE_MEETING_TEMPLATE_REQUEST",
  "@meeting-templates/UPDATE_MEETING_TEMPLATE_SUCCESS",
  "@meeting-templates/UPDATE_MEETING_TEMPLATE_FAILURE"
)<{ id: number; body: MeetingTemplateRequest }, MeetingTemplate, Error>();

export const deleteMeetingTemplate = createAsyncAction(
  "@meeting-templates/DELETE_MEETING_TEMPLATE_REQUEST",
  "@meeting-templates/DELETE_MEETING_TEMPLATE_SUCCESS",
  "@meeting-templates/DELETE_MEETING_TEMPLATE_FAILURE"
)<number, number, Error>();

export const showMeetingTemplateDialog = createAction(
  "@meeting-templates/SHOW_MEETING_TEMPLATE_DIALOG"
)<{ meetingTemplate?: MeetingTemplate }>();

export const hideMeetingTemplateDialog = createAction(
  "@meeting-templates/HIDE_MEETING_TEMPLATE_DIALOG"
)<void>();
