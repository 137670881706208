import React, { useEffect, useMemo, useState } from "react";
import { Icon, InputGroup } from '@blueprintjs/core';
import { Button as MUIButton } from "@mui/material";
import {
  Activity,
  ActivityScopeEnum,
  ActivityTagEnum,
  ActivityTypeEnum,
  UserNotesType,
} from "../../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getActivities, showActivityDialog, showActivityVideoDialog } from "../../../../../store/activities/actions";
import { isDistrictPlan } from "../../../../../store/plans/selectors";
import { useLoading } from "../../../../../helpers/hooks/useLoading";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// import Accordians from "../../../../common/Accordian/Accordain";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useIntl } from "react-intl";
import "./resourseTabs.scss";
import { NonIdealState, Spinner,Card } from "@blueprintjs/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import CollapsableActivity from "./CollapsableActivity";
import DownloadButton from "../../../../common/buttons/DownloadButton";
import videoimg from "../../../../../static/images/resourcesVideo.png";
import ActiveVideoTabs from "./ActiveVideoTabs";
type OwnProps = {
  userNotesType: UserNotesType;
  activityTag?: ActivityTagEnum;
  filterFn?: (act: Activity) => boolean;
  showTagSelector?: boolean;
};

type Props = OwnProps;

const ActivityResourcesTab: React.FC<Props> = (props) => {
  const {
    userNotesType,
    activityTag,
    showTagSelector = true,
    filterFn,
  } = props;

  const dispatch = useDispatch();

  // USE SELECTOR
  const intl = useIntl();
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const activities = useSelector((s) => s.activities.activities);
  const loading = useSelector((s) => s.activities.loading.getActivities);
  const error = useSelector((s) => s.activities.errors.getActivities);

  useLoading({ loading, error });

  // USE EFFECT
  useEffect(() => {
    if (activePlanId) {
      dispatch(
        getActivities.request({
          type: isDistrictActivePlan
            ? ActivityScopeEnum.DISTRICT
            : ActivityScopeEnum.SCHOOL,
          tag: activityTag,
        })
      );
    }
  }, [activityTag, activePlanId]);
  //console.log('userNotesType ' +userNotesType)
  // USE STATE
  const [searchString, setSearchString] = useState("");
  const [type, setType] = useState<ActivityTypeEnum | undefined>();

  // FUNCTIONS - HANDLERS
  const handleTypeChange = (type?: ActivityTypeEnum) => {
   setType(type);
  };
  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target?.value);
  };
  const handleClearFilters = () => {
    setType(undefined);
    setSearchString("");
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChangeA = (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  
  //USE MEMO
  const displayedActivities = useMemo(() => {
    const filters: ((activity: Activity) => boolean)[] = [];
    if (filterFn) {
      filters.push(filterFn);
    }
    if (type) {
      filters.push((a: Activity) => type === a.activity_type);
    }
    if (searchString) {
      const lowerCasedSearchString = searchString.toLocaleLowerCase();
      filters.push((a: Activity) =>
        a.name?.toLocaleLowerCase().includes(lowerCasedSearchString)
      );
    }
    return filters.length
      ? activities.filter((a: Activity) => filters.every((f) => f(a)))
      : activities;
  }, [activities, type, searchString, filterFn]);

 // console.log('displayedActivities ' +JSON.stringify(displayedActivities))

  const activityTypeSelectOptions = useMemo(() => {
    return Object.values(ActivityTypeEnum).map((type) => ({
      label: intl.formatMessage(
        { id: "app.titles.activity-type" },
        { type: type }
      ),
      value: type,
    }));
  }, []);

  const open = Boolean(anchorEl);
  const [age, setAge] = React.useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="resourses-page__content">

        {showTagSelector && (
          <div className="tab-buttonGroup">
            <button className={(  type == ActivityTypeEnum.SCI_WRITING ||  type == ActivityTypeEnum.EXTERNAL_RESOURCE ||  type == ActivityTypeEnum.KALTURA_VIDEO ) ? "tabButton"
                  : "tabButton activeTab"}
                  onClick={() => handleTypeChange()}>All</button>
            <button
              className={
                type == ActivityTypeEnum?.SCI_WRITING
                  ? "tabButton activeTab"
                  : "tabButton"
              }
              onClick={() => handleTypeChange(ActivityTypeEnum?.SCI_WRITING)}
            >
              {intl.formatMessage(
                { id: "app.titles.activity-type" },
                { type: ActivityTypeEnum?.SCI_WRITING }
              )}
            </button>
            <button
              className={
                type == ActivityTypeEnum?.EXTERNAL_RESOURCE
                  ? "tabButton activeTab"
                  : "tabButton"
              }
              onClick={() =>
                handleTypeChange(ActivityTypeEnum?.EXTERNAL_RESOURCE)
              }
            >
              {intl.formatMessage(
                { id: "app.titles.activity-type" },
                { type: ActivityTypeEnum?.EXTERNAL_RESOURCE }
              )}
            </button>
            <button
              className={
                type == ActivityTypeEnum?.KALTURA_VIDEO
                  ? "tabButton activeTab"
                  : "tabButton"
              }
              onClick={() => handleTypeChange(ActivityTypeEnum?.KALTURA_VIDEO)}
            >
              {intl.formatMessage(
                { id: "app.titles.activity-type" },
                { type: ActivityTypeEnum?.KALTURA_VIDEO }
              )}
            </button>
          </div>
        )}
        <div className="resourses-page__head">
          {/* <label>Activities ({displayedActivities?.length})</label> */}
          <label>{userNotesType  === 'school_resources_workspace' || userNotesType  === 'district_resources_workspace' ? 'SCTP Resources' : 'Resources'} ({displayedActivities?.length})</label>
         
          <div className="resources_search">
            
            <InputGroup
              type="search"
              leftIcon="search"
              large
              placeholder="Search by Keyword"
              value={searchString}
              onChange={(e) => setSearchString(e.target?.value)}
            />
          </div>

        </div>
        <div className="resourses-page__acc-content">
        
          {loading ? (
            <Spinner intent="primary" className="my-2" />
          ) : displayedActivities.length ? (
            // check which resource pill is selected and display the list accordingly
           
         
             <>
              { type != ActivityTypeEnum?.KALTURA_VIDEO ?
displayedActivities.map((activity, i) => (
                <div className="mt-5">
                <CollapsableActivity
                  key={i}
                  activity={activity}
                  newDesign={true}
                /></div>
              ))
                :
                <div className="videorow">
                {
                displayedActivities.map((activity, i) => (
                  
                  <ActiveVideoTabs  key={i}
                  activity={activity}
                  newDesign={true} />
                 
                ))
                } </div>

                }
             
               </>
          
           
          ) : (
            <Card>
              <NonIdealState
                icon="zoom-out"
                title={intl.formatMessage({
                  id: !!(type || searchString)
                    ? "app.tables.no-matching-data"
                    : "app.non-ideal-state.activities.title",
                })}
                description={
                  !(type || searchString)
                    ? intl.formatMessage({
                      id: "app.non-ideal-state.activities.description",
                    })
                    : undefined
                }
                action={
                  !!(type || searchString) ? (
                    <>
                      <Button variant="outlined" onClick={handleClearFilters}>
                        {intl.formatMessage({
                          id: "app.tables.button-text.clear-filters",
                        })}
                      </Button>
                    </>
                  ) : undefined
                }
                
              />
            </Card>



          )}
        </div>
      </div>
    </>
  );
};

export default ActivityResourcesTab;
