import { CommonText, SurveyDemographic } from "../../types";
import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";

type ActionName =
  | "getCommonTexts"
  | "addCommonText"
  | "updateCommonText"
  | "deleteCommonText";

export type CommonTextsState = {
  commonTexts: CommonText[];
  selectedCommonText: CommonText | undefined;
  showCommonTextUpsertDialog?: boolean;
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: CommonTextsState = {
  commonTexts: [],
  selectedCommonText: undefined,
  loading: {},
  errors: {},
};

export const commonTextsReducer = createReducer<CommonTextsState, RootAction>(
  initialState
)
  //get common texts
  .handleAction(
    actions.getCommonTexts.request,
    (state, action): CommonTextsState => ({
      ...state,
      loading: {
        ...state.loading,
        getCommonTexts: true,
      },
      errors: {
        ...state.errors,
        getCommonTexts: undefined,
      },
    })
  )
  .handleAction(
    actions.getCommonTexts.success,
    (state, action): CommonTextsState => ({
      ...state,
      commonTexts: action.payload,
      loading: {
        ...state.loading,
        getCommonTexts: false,
      },
    })
  )
  .handleAction(
    actions.getCommonTexts.failure,
    (state, action): CommonTextsState => ({
      ...state,
      errors: {
        ...state.errors,
        getCommonTexts: action.payload,
      },
      loading: {
        ...state.loading,
        getCommonTexts: false,
      },
    })
  )
  //add commonText
  .handleAction(
    actions.addCommonText.request,
    (state, action): CommonTextsState => ({
      ...state,
      loading: {
        ...state.loading,
        addCommonText: true,
      },
      errors: {
        ...state.errors,
        addCommonText: undefined,
      },
    })
  )
  .handleAction(
    actions.addCommonText.success,
    (state, action): CommonTextsState => ({
      ...state,
      commonTexts: [...state.commonTexts, action.payload],
      loading: {
        ...state.loading,
        addCommonText: false,
      },
    })
  )
  .handleAction(
    actions.addCommonText.failure,
    (state, action): CommonTextsState => ({
      ...state,
      errors: {
        ...state.errors,
        addCommonText: action.payload,
      },
      loading: {
        ...state.loading,
        addCommonText: false,
      },
    })
  )
  // update commonText
  .handleAction(
    actions.updateCommonText.request,
    (state, action): CommonTextsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateCommonText: true,
      },
      errors: {
        ...state.errors,
        updateCommonText: undefined,
      },
    })
  )
  .handleAction(
    actions.updateCommonText.success,
    (state, action): CommonTextsState => ({
      ...state,
      commonTexts: state.commonTexts.map((d) =>
        d.id === action.payload.id ? action.payload : d
      ),
      loading: {
        ...state.loading,
        updateCommonText: false,
      },
    })
  )
  .handleAction(
    actions.updateCommonText.failure,
    (state, action): CommonTextsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateCommonText: action.payload,
      },
      loading: {
        ...state.loading,
        updateCommonText: false,
      },
    })
  )
  // update commonText
  .handleAction(
    actions.deleteCommonText.request,
    (state, action): CommonTextsState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteCommonText: true,
      },
      errors: {
        ...state.errors,
        deleteCommonText: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteCommonText.success,
    (state, action): CommonTextsState => ({
      ...state,
      commonTexts: state.commonTexts.filter((d) => d.id !== action.payload),
      loading: {
        ...state.loading,
        deleteCommonText: false,
      },
    })
  )
  .handleAction(
    actions.deleteCommonText.failure,
    (state, action): CommonTextsState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteCommonText: action.payload,
      },
      loading: {
        ...state.loading,
        deleteCommonText: false,
      },
    })
  )
  // commonText upsert dialog
  .handleAction(
    actions.showCommonTextUpsertDialog,
    (state, action): CommonTextsState => ({
      ...state,
      selectedCommonText: action.payload ? action.payload : undefined,
      showCommonTextUpsertDialog: true,
    })
  )
  .handleAction(
    actions.hideCommonTextUpsertDialog,
    (state, action): CommonTextsState => ({
      ...state,
      showCommonTextUpsertDialog: false,
    })
  );
