import React from "react";
import { useIntl } from "react-intl";
import { Classes, Dialog, Icon } from "@blueprintjs/core";
import {
  ACTIVITY_LINK_CLASSNAME,
  useAttachActivities,
} from "../../../../../helpers/hooks/useAttachActivities";
import { Activity, UserNotesType } from "../../../../../types";
import { showNotesDialog } from "../../../../../store/notes/actions";
import { useDispatch, useSelector } from "react-redux";
import { isDistrictPlan } from "../../../../../store/plans/selectors";

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
};

type Props = OwnProps;

const CoordinateSurveyAdministrationDialog: React.FC<Props> = ({
  isOpen,
  onClose,
}: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const handleDialogClose = () => {
    onClose();
  };

  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      dispatch(
        showNotesDialog({
          selectedActivity: activity,
          type: isDistrictActivePlan
            ? UserNotesType.DCLT_RESOURCES
            : UserNotesType.SCLT_RESOURCES,
        })
      );
    },
  });

  return (
    <Dialog
      className="w-1/2"
      style={{ minWidth: "40rem" }}
      isOpen={isOpen}
      onClose={handleDialogClose}
      title={intl.formatMessage({
        id: "app.team-home.coordinate-survey-administration-dialog.title",
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        {intl.formatMessage(
          {
            id: "app.team-home.coordinate-survey-administration-dialog.content",
          },
          {
            i: (chunks) => <i>{chunks}</i>,
            br: <br />,
            b: (chunks) => (
              <a
                className={ACTIVITY_LINK_CLASSNAME}
                onClick={onActivityClick()}
              >
                <b>{chunks}</b>
              </a>
            ),
          }
        )}
      </div>
    </Dialog>
  );
};

export default CoordinateSurveyAdministrationDialog;
