import { useSelector } from "react-redux";
import { useMemo } from "react";
import { TeamRole, UserRole, userRoles } from "../../types";
import { getCurrentUserIsDCC } from "../../store/auth/selectors";

const useUserRole = () => {
  const user = useSelector((s) => s.auth.authenticatedUser);
  const role = useSelector((s) => s.auth.authenticatedUser?.profile?.role);
  const isDCC = useSelector(getCurrentUserIsDCC);
  const teamRoles = useSelector((s) => s.auth.authenticatedUser?.team_roles);

  const activePlan = useSelector((s) => s.plans.activePlan);

  const teamRole = useMemo(() => {
    if (activePlan?.id && teamRoles) {
      return teamRoles.find((tr) => tr.plan === activePlan.id)?.team_role;
    }
    return undefined;
  }, [activePlan?.id, teamRoles]);

  const isAdmin = useMemo(() => {
    return !!role && userRoles.admins.includes(role);
  }, [role]);

  const hasDataCoordinatorAccess = useMemo(() => {
    return (
      (role && userRoles.districtOrSchoolLeadership.includes(role)) ||
      (teamRole &&
        [
          TeamRole.TeamChampion,
          TeamRole.TeamChair,
          TeamRole.DataCoordinator,
        ].includes(teamRole))
    );
  }, [role, teamRole]);

  const { isSuperintendent, isPrincipal } = useMemo(() => {
    const roles = {
      isSuperintendent: false,
      isPrincipal: false,
    };
    switch (role) {
      case UserRole.Superintendent:
        return { ...roles, isSuperintendent: true };
      case UserRole.Principal: {
        return { ...roles, isPrincipal: true };
      }
      default:
        return roles;
    }
  }, [role]);

  const { isTeamMember, isTeamChampion } = useMemo(() => {
    const teamRoles = {
      isTeamMember: false,
      isTeamChampion: false,
    };
    switch (teamRole) {
      case TeamRole.TeamMember:
        return { ...teamRoles, isTeamMember: true };
      case TeamRole.TeamChampion:
        return { ...teamRoles, isTeamChampion: true };
      default:
        return teamRoles;
    }
  }, [teamRole]);

  const isDistrictAdmin = useMemo(() => {
    return role && userRoles.districtAdmins.includes(role);
  }, []);

  const hasDistrictPlanAccess = useMemo(() => {
    return isDCC || isAdmin || isDistrictAdmin;
  }, [isDCC, isAdmin, isDistrictAdmin]);

  return {
    role: role,
    isDCC: isDCC,
    teamRole: teamRole,
    hasDataCoordinatorAccess: isAdmin || hasDataCoordinatorAccess,
    isTeamMember,
    isTeamChampion,
    hasTeamChampionAccess: isAdmin || isTeamChampion,
    isAdmin,
    hasDistrictPlanAccess,
    isSuperintendent,
    isPrincipal,
    user,
  };
};

export default useUserRole;
