import { useSelector } from "react-redux";
import { useLoading } from "../useLoading";

export const useGetDomainPriorities = () => {
  const loadingGetDomainPriorities = useSelector(
    (s) => s.needs.loading.getDomainPriorities
  );
  const errorGetDomainPriorities = useSelector(
    (s) => s.needs.errors.getDomainPriorities
  );
  useLoading({
    loading: loadingGetDomainPriorities,
    error: errorGetDomainPriorities,
  });

  return {
    loading: loadingGetDomainPriorities,
    error: errorGetDomainPriorities,
  };
};
