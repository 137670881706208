import { School } from "../../types";
import { API_ROOT } from "../config";
import Axios from "axios";
import {
  AddSchoolRequest,
  GetSchoolsByDistrictsRequest,
  UpdateSchoolRequest,
} from "./types";

export const addSchool = (request: AddSchoolRequest): Promise<School> => {
  return Axios.post(`${API_ROOT}/district/schools/`, request);
};

export const updateSchool = ({
  id,
  ...body
}: UpdateSchoolRequest): Promise<School> => {
  return Axios.put(`${API_ROOT}/district/schools/${id}/`, body);
};

export const getSchools = (): Promise<School[]> => {
  return Axios.get(`${API_ROOT}/district/schools/`);
};

export const deleteSchool = (id: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/district/schools/${id}`);
};

export const getSchoolsByDistrict = (districtId: number): Promise<School[]> => {
  return Axios.get(`${API_ROOT}/districts/${districtId}/schools`);
};

export const getSchoolsByDistricts = (
  request: GetSchoolsByDistrictsRequest
): Promise<School<number>[]> => {
  return Axios.post(`${API_ROOT}/schools/districts`, request);
};
