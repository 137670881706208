import React from "react";
import { Radio, RadioGroup } from "@blueprintjs/core";
import { SelectorOptionType } from "../../../types";

type Props<T extends string | number> = {
  direction?: string;
  options: SelectorOptionType<T>[];
  selectedItem: T | undefined;
  onClick: (value: string | undefined) => void;
};

const GenericRadioButtonsGroup = <T extends string | number>(
  props: Props<T>
) => {
  const { options, selectedItem, direction, onClick } = props;

  const handleChange = (e: any) => {
    const value = e.currentTarget.value;
    onClick(value == null ? undefined : value);
  };

  return (
    <RadioGroup
      selectedValue={selectedItem}
      className={direction === "rtl" ? "bp3-align-right" : ""}
      onChange={handleChange}
    >
      {options.map((item, index) => (
        <Radio
          key={item.value}
          large
          label={item.label}
          value={item.value}
          className={direction === "rtl" ? "bp3-align-right" : ""}
        />
      ))}
    </RadioGroup>
  );
};

export default GenericRadioButtonsGroup;
