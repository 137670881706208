/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import './EditProfile.scss';
import UserAvatar from '../../../profile/UserAvatar';
import { updateProfile } from '../../../../store/profile/actions';
import { AppToaster } from '../../../../helpers/toaster';
import { useLoading } from '../../../../helpers/hooks/useLoading';

type OwnProps = {
    handleClose: () => void;
};
type Props = OwnProps;

const EditProfile: React.FC<Props> = (props) => {
    const { handleClose } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    // STATES
    const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
    const [isEditEmail, setIsEditEmail] = useState<boolean>(false);
    const [isEditName, setIsEditName] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [userProfile, setUserProfile] = useState<string>("");
    const [userName, setUserName] = useState<string>("");

    const me = useSelector((s) => s.auth.authenticatedUser);

    useEffect(() => {
        if (me?.email) {
            setEmail(me?.email);
        }

        if (me?.first_name || me?.last_name) {
            setUserName(`${me.first_name} ${me.last_name}`);
        }

        if (me?.profile.image_url) {
            setUserProfile(me.profile.image_url);
        }
    }, [me])

    const editProfileClickOpen = () => {
        setIsEditProfileOpen(true);
    };

    const editProfileClose = () => {
        setIsEditProfileOpen(false);
        handleClose();
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value);
    };

    const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setUserName(value);
    };

    const handleFormSubmit = (type: string) => {
        //email 
        if (type === 'email' && !!email.length) {
            setIsEditEmail(false)
        };

        //User Name 
        if (type === 'userName' && userName.length) {
            setIsEditName(false)
        };

        if (userName.length && email.length) {

            // spli full name
            const names = userName.split(' ');

            // save fname, lname and email
            dispatch(
                updateProfile.request({
                    first_name: names[0],
                    last_name: names.slice(1).join(' '),
                    email: email,
                })
            );
        }
    }

    return (
        <>
            <a onClick={editProfileClickOpen}>{intl.formatMessage({
                id: "app.profile.edit-profile"
            })}</a>
            <Dialog
                className="edit-profile-modal"
                open={isEditProfileOpen}
                onClose={editProfileClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal-title">
                    <h3>{intl.formatMessage({
                        id: "app.profile.edit-profile"
                    })}</h3>
                    <button onClick={editProfileClose}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>
                <div className="edit-profile-modal-wrapper">
                    <div>
                        <UserAvatar src={userProfile} />
                    </div>

                    <div className="profile__name">
                        <div className="userIcon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.94347 0.783806C10.2446 0.783806 12.1125 2.65153 12.1125 4.95286C12.1125 7.25386 10.2448 9.12192 7.94347 9.12192C5.64247 9.12192 3.77441 7.25404 3.77441 4.95286C3.77441 2.65172 5.64229 0.783806 7.94347 0.783806ZM7.94347 2.07663C6.35577 2.07663 5.06742 3.36498 5.06742 4.95268C5.06742 6.54038 6.35577 7.82873 7.94347 7.82873C9.53117 7.82873 10.8195 6.54038 10.8195 4.95268C10.8195 3.36498 9.53117 2.07663 7.94347 2.07663Z" fill="black" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.6355 15.5195C15.6355 16.5163 14.9768 17.3617 13.8215 17.9606C12.3948 18.6969 10.1721 19.0825 7.94422 19.0825C5.71636 19.0825 3.49374 18.6969 2.06699 17.9606C0.911528 17.3617 0.25293 16.5163 0.25293 15.5195C0.25293 11.2748 3.69957 7.82813 7.94433 7.82813C12.1891 7.82813 15.6357 11.2749 15.6357 15.5195H15.6355ZM14.3427 15.5195C14.3427 11.9882 11.4754 9.12081 7.94397 9.12081C4.41249 9.12081 1.54523 11.9881 1.54523 15.5195C1.54523 16.0796 2.01196 16.4759 2.66188 16.8122C3.94056 17.4744 5.94479 17.7898 7.94374 17.7898C9.9427 17.7898 11.947 17.4744 13.2256 16.8122C13.8756 16.4759 14.3423 16.0796 14.3423 15.5195H14.3427Z" fill="black" />
                        </svg></div>
                        {
                            isEditName ?
                                <div className="usenameEdit-area">
                                    <input
                                        style={{ border: !userName ? '2px solid red' : '' }}
                                        type="text"
                                        value={userName}
                                        onBlur={() => handleFormSubmit('userName')}
                                        onChange={handleUserNameChange}
                                        autoFocus={true}
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                handleFormSubmit('userName')
                                            }
                                        }}
                                    ></input>
                                </div>
                                :
                                (<>
                                    <div className="userName">{userName}</div>

                                    <button className="editIcon">
                                        <Icon icon="edit" iconSize={16} onClick={() => setIsEditName(true)} />
                                    </button>
                                  
                                </>)
                        }

                    </div>

                    <div className="profile__email">
                        <div className="mailIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13" viewBox="0 0 19 13" fill="none">
                                <path d="M1.58553 0.356628C1.15504 0.356628 0.790039 0.72177 0.790039 1.15212V11.4944C0.790039 11.9248 1.15518 12.2899 1.58553 12.2899H17.8945C18.325 12.2899 18.69 11.9248 18.69 11.4944V1.15212C18.69 0.721628 18.3249 0.356628 17.8945 0.356628H1.58553ZM2.76644 1.55004H16.7136L9.74004 7.52919L2.76644 1.55004ZM1.98328 2.43876L6.17844 6.03726L1.98328 10.2324V2.43876ZM17.4967 2.43876V10.2328L13.3016 6.03761L17.4967 2.43876ZM7.08624 6.82075L9.35483 8.76C9.46263 8.85115 9.59914 8.9011 9.74025 8.9011C9.88136 8.9011 10.0179 8.85115 10.1257 8.76L12.3943 6.82075L16.6704 11.0969H2.81023L7.08624 6.82075Z" fill="#4C4C4C" />
                            </svg>

                        </div>
                        <div className="emailLabel">
                            Email Address
                        </div>
                    </div>
                    <div className="profile__emailEdit">
                        {isEditEmail ?
                            <div className="emailEditt-area">
                                <input
                                    style={{ border: !email ? '2px solid red' : '' }}
                                    type="email"
                                    value={email}
                                    onBlur={() => handleFormSubmit('email')}
                                    onChange={handleEmailChange}
                                    autoFocus={true}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            handleFormSubmit('email')
                                        }
                                    }}
                                ></input>
                            </div>
                            :
                            (<>
                                <a href={`mailto:${email}`} >{email}</a>
                                <button className="editIcon">
                                    <Icon icon="edit" iconSize={16} onClick={() => setIsEditEmail(true)} />
                                </button>
                            </>)
                        }
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default EditProfile;