import { useSelector } from "react-redux";
import { useCallback } from "react";
import { AppToaster } from "../../toaster";
import { useLoading } from "../useLoading";
import { useIntl } from "react-intl";

export const useUpdateSurveyDeploymentLoading = ({
  onClose,
}: {
  onClose: Function;
}) => {
  const intl = useIntl();

  const loading = useSelector(
    (s) => s.surveysDeployment.loading.updateSurveyDeployment
  );
  const error = useSelector(
    (s) => s.surveysDeployment.errors.updateSurveyDeployment
  );
  const onSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.surveys-deployment.updated",
      }),
      icon: "tick",
      intent: "success",
    });
    onClose();
  }, [onClose]);
  useLoading({
    loading: loading,
    error: error,
    onSuccess: onSuccess,
  });

  return {
    loading,
  };
};
