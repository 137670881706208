import {
  Button,
  Callout,
  Card,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../helpers/toaster";
import {
  addDistrict,
  hideDistrictDialog,
  updateDistrict,
} from "../../../store/districts/actions";
import { getUsers } from "../../../store/users/actions";
import { UserModel, UserRole } from "../../../types";
import UserSelector from "../district-users/UserSelector";
import { AddDistrictRequest } from "../../../api/districts/types";
import useNewUser from "../../../helpers/hooks/useNewUser";

type OwnProps = {};

type Props = OwnProps;

const DistrictDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<TabId>("invite-now");

  const show = useSelector((s) => s.districts.showDistrictCreationDialog);

  const selectedDistrict = useSelector((s) => s.districts.selectedDistrict);
  useEffect(() => {
    if (selectedDistrict?.superintendent) {
      setSelectedTab("choose-existing-user");
    }
  }, [selectedDistrict]);

  const [districtName, setDistrictName] = useState<string>(
    selectedDistrict?.name ?? ""
  );

  const [isSingleSchoolDistrict, setSingleSchoolDistrict] = useState<
    boolean | undefined
  >(false);

  const [enabledDataCollection, setEnabledDataCollection] = useState<boolean>(
    false
  );

  const [isDummyDistrict, setIsDummyDistrict] = useState<boolean | undefined>(
    false
  );

  const handleEnabledDataCollectionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.checked;
    setEnabledDataCollection(value);
  };

  const [enabledNeedsAnalysis, setEnabledNeedsAnalysis] = useState<boolean>(
    false
  );
  const handleEnabledNeedsAnalysisChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.checked;
    setEnabledNeedsAnalysis(value);
  };

  const [enabledStrategicPlan, setEnabledStrategicPlan] = useState<boolean>(
    false
  );
  const handleEnabledStrategicPlanChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.checked;
    setEnabledStrategicPlan(value);
  };

  const handleDummyDistrictChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.checked;
    setIsDummyDistrict(value);
  };

  const users: UserModel[] = useSelector((s) => s.users.users?.users ?? []);

  const {
    newUserComponent,
    firstName,
    lastName,
    email,
    resetForm,
  } = useNewUser();

  const [superintendentId, setSuperintendentId] = useState<
    number | undefined
  >();

  const allSuperintendents: UserModel[] = useMemo(() => {
    return users.filter(
      (user) => user.profile?.role === UserRole.Superintendent
    );
  }, [users]);

  const [warning, setWarning] = useState<string>();

  const handleOpen = () => {
    setDistrictName(selectedDistrict?.name ?? "");
    setSingleSchoolDistrict(selectedDistrict?.is_single_school_district);
    setIsDummyDistrict(selectedDistrict?.is_dummy_district);
    setSuperintendentId(
      selectedDistrict?.superintendent?.id ||
        selectedDistrict?.new_superintendent?.id
    );

    setEnabledDataCollection(!!selectedDistrict?.enabled_data_collection);
    setEnabledNeedsAnalysis(!!selectedDistrict?.enabled_needs_analysis);
    setEnabledStrategicPlan(!!selectedDistrict?.enabled_strategic_plan);

    dispatch(getUsers.request(undefined));
  };

  const handleClose = () => {
    setDistrictName("");
    setSuperintendentId(undefined);
    setWarning(undefined);
    setSelectedTab("invite-now");
    setSingleSchoolDistrict(false);
    resetForm();
    dispatch(hideDistrictDialog());
  };

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (firstName && email && lastName && superintendentId) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.validation.one-option-required",
        }),
      };
      return validationOption;
    }

    if (!superintendentId && (!firstName || !email || !lastName)) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.districts.upsert-dialog.validation.superintendent-required",
        }),
      };
      return validationOption;
    }

    return validationOption;
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, message } = validate();
    if (isValid) {
      const districtObject: AddDistrictRequest = {
        name: districtName,
        is_single_school_district: !!isSingleSchoolDistrict,
        is_dummy_district: !!isDummyDistrict,
        enabled_data_collection: enabledDataCollection,
        enabled_needs_analysis: enabledNeedsAnalysis,
        enabled_strategic_plan: enabledStrategicPlan,
      };

      if (!superintendentId) {
        districtObject.new_superintendent = {
          first_name: firstName,
          last_name: lastName,
          email: email,
        };
      } else {
        districtObject.superintendent = superintendentId;
      }

      if (!!selectedDistrict) {
        dispatch(
          updateDistrict.request({ id: selectedDistrict.id, ...districtObject })
        );
        return;
      }
      dispatch(
        addDistrict.request({
          district: districtObject,
        })
      );
      setWarning(undefined);
    } else {
      setWarning(message);
    }
  };

  const handleSuccess = () => {
    AppToaster.show({
      icon: "tick",
      intent: "success",
      message: intl.formatMessage(
        {
          id: `app.toaster.districts.${
            !!selectedDistrict ? "updated" : "created"
          }`,
        },
        { name: districtName }
      ),
    });
    if (!!selectedDistrict && !superintendentId) {
      dispatch(getUsers.request(undefined));
    }
    handleClose();
  };

  const loading = useSelector((s) => s.districts.loading.addDistrict);
  const error = useSelector((s) => s.districts.errors.addDistrict);
  useLoading({
    loading,
    error,
    errorTitle: intl.formatMessage({ id: "app.errors.add-district.title" }),
    onSuccess: handleSuccess,
  });

  const updateLoading = useSelector((s) => s.districts.loading.updateDistrict);
  const updateError = useSelector((s) => s.districts.errors.updateDistrict);
  useLoading({
    loading: updateLoading,
    error: updateError,
    errorTitle: intl.formatMessage({ id: "app.errors.update-district.title" }),
    onSuccess: handleSuccess,
  });

  const handleDistrictNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDistrictName(value);
  };

  const handleSuperintendentChange = (user?: UserModel) => {
    setSuperintendentId(user?.id);
  };

  const handleSingleSchoolDistrictChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.checked;
    setSingleSchoolDistrict(value);
  };

  return (
    <Dialog
      isOpen={show}
      onOpening={handleOpen}
      onClose={handleClose}
      icon={
        <Icon icon={!!selectedDistrict ? "edit" : "office"} iconSize={24} />
      }
      title={intl.formatMessage({
        id: `app.districts.${
          !!selectedDistrict ? "update-district" : "create-new-district"
        }`,
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        <form id="create-district-form" onSubmit={handleFormSubmit}>
          <FormGroup
            label={intl.formatMessage({
              id: "app.forms.create-district.name",
            })}
            labelFor="district-name"
          >
            <InputGroup
              id="district-name"
              required
              placeholder={intl.formatMessage({
                id: "app.forms.create-district.name.placeholder",
              })}
              fill
              value={districtName}
              onChange={handleDistrictNameChange}
            />
          </FormGroup>

          {/* <Checkbox
            checked={isSingleSchoolDistrict}
            label={intl.formatMessage({
              id: "app.titles.single-school-district",
            })}
            onChange={handleSingleSchoolDistrictChange}
          /> */}
          <Checkbox
            checked={isDummyDistrict}
            label={intl.formatMessage({
              id: "app.titles.dummy-district",
            })}
            onChange={handleDummyDistrictChange}
          />

          <FormGroup
            label={intl.formatMessage({
              id: "app.tiles.enabled-workspaces",
            })}
          >
            <Checkbox
              checked={enabledDataCollection}
              label={intl.formatMessage({
                id: "app.titles.data-collection-and-reports",
              })}
              onChange={handleEnabledDataCollectionChange}
            />
            <Checkbox
              checked={enabledNeedsAnalysis}
              label={intl.formatMessage({
                id: "app.titles.needs",
              })}
              onChange={handleEnabledNeedsAnalysisChange}
            />
            <Checkbox
              checked={enabledStrategicPlan}
              label={intl.formatMessage({
                id: "app.titles.strategic-plan",
              })}
              onChange={handleEnabledStrategicPlanChange}
            />
          </FormGroup>

          <FormGroup
            label={intl.formatMessage({
              id: "app.titles.superintendent",
            })}
          >
            <Card>
              {selectedDistrict ? (
                <Tabs
                  id={"superintendent-tabs"}
                  selectedTabId={selectedTab}
                  onChange={setSelectedTab}
                  renderActiveTabPanelOnly
                >
                  <Tab
                    id="invite-now"
                    title={intl.formatMessage({
                      id: "app.school-upsert-dialog.tabs.invite-now",
                    })}
                    panel={newUserComponent}
                  />
                  <Tab
                    id="choose-existing-user"
                    title={intl.formatMessage({
                      id: "app.school-upsert-dialog.tabs.choose-existing-user",
                    })}
                    panel={
                      <UserSelector
                        placeholder={intl.formatMessage({
                          id: "app.titles.select-superintendent",
                        })}
                        userId={superintendentId}
                        users={allSuperintendents}
                        onUserChange={handleSuperintendentChange}
                      />
                    }
                  />
                </Tabs>
              ) : (
                newUserComponent
              )}
            </Card>
          </FormGroup>
        </form>
        {warning && (
          <div className="my-3">
            <Callout intent="warning">{warning}</Callout>
          </div>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button
            className="w-1/4"
            onClick={handleClose}
            disabled={loading || updateLoading}
          >
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            className="w-1/4"
            intent="primary"
            type="submit"
            loading={loading || updateLoading}
            form="create-district-form"
          >
            {intl.formatMessage({
              id: "app.titles.save",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DistrictDialog;
