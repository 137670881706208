import { Epic } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getCommonTextsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getCommonTexts.request)),
    switchMap((action) =>
      from(api.commonText.getCommonTexts()).pipe(
        map(actions.getCommonTexts.success),
        catchError((error) => of(actions.getCommonTexts.failure(error)))
      )
    )
  );
};

export const createCommonTextEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addCommonText.request)),
    switchMap((action) =>
      from(api.commonText.addCommonText(action.payload)).pipe(
        map(actions.addCommonText.success),
        catchError((error) => of(actions.addCommonText.failure(error)))
      )
    )
  );
};

export const updateCommonTextEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateCommonText.request)),
    switchMap((action) =>
      from(api.commonText.updateCommonText(action.payload)).pipe(
        map(actions.updateCommonText.success),
        catchError((error) => of(actions.updateCommonText.failure(error)))
      )
    )
  );
};

export const deleteCommonTextEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteCommonText.request)),
    switchMap((action) =>
      from(api.commonText.deleteCommonText(action.payload)).pipe(
        map(() => actions.deleteCommonText.success(action.payload)),
        catchError((error) => of(actions.deleteCommonText.failure(error)))
      )
    )
  );
};
