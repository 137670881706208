import * as React from 'react';
import './ViewLogsChart.scss';
import { PlanStrategy } from '../../../../../../types';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import moment from 'moment';

type OwnProps = {
  planstrategy: PlanStrategy;
};
type Props = OwnProps;

const ViewLogsChart: React.FC<Props> = (props) => {
  const { planstrategy } = props;
  const intl = useIntl();

  const items = useMemo(() => {
    let implementations = planstrategy.implementations;

    const sortedData = [...implementations].sort((a, b) => (new Date(a.date) as any) - (new Date(b.date) as any));

    return {
      title: "",
      labels: sortedData.map((implementation) =>
        moment(new Date(implementation.date)).format("MM/DD/YY")
      ),
      data: sortedData.map(
        (implementation) => (implementation.score || 0) * 100
      ),
    };
  }, [planstrategy.implementations]);

  const DatesArray = useMemo(() => {
    const dateObjects: any = items?.labels?.map(
      (dateStr: any) => new Date(dateStr)
    );

    // Find the lowest and greatest dates
    const lowestDate = new Date(Math.min(...dateObjects));
    const greatestDate = new Date(Math.max(...dateObjects));

    const datesArray: string[] = [];
    let currentDate = lowestDate;

    while (currentDate <= greatestDate) {
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      datesArray.push(moment(formattedDate).format("MM/DD/YY"));
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return datesArray;
  }, [items]);

  const data = useMemo(() => ({
    labels: DatesArray,
    datasets: [
      {
        yAxisID: "score",
        label: intl.formatMessage({
          id: "app.dashboard.climate-overview-carousel.score",
        }),
        pointBorderColor: "rgb(255,255,255)",
        pointBorderWidth: 1,
        pointRadius: 4,
        pointHoverRadius: 6,
        data: DatesArray.map(
          (date: any) =>
            Number(items.data[items.labels.indexOf(date)]?.toFixed(2)) ?? null
        ),
        // items.data.map((dp:number)=>Number(dp.toFixed(2))),
        fill: false,
        tension: 0.1,
        hoverBorderColor: "#20275A",
        backgroundColor: "#20275A",
      }
    ],
  }), [items.labels, items.data, intl]);

  const options = useMemo(
    () => ({
      spanGaps: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            let label = data.datasets[tooltipItem.datasetIndex].label || "";
            label += ": " + tooltipItem.yLabel + " %"; // Append icon to the value
            return label;
          },
        },
      },
      legend: { display: false },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "black",
              maxRotation: 70,
              minRotation: 70,
              maxTicksLimit: DatesArray.length < 85? DatesArray.length/2:DatesArray.length,
              callback:(value:any,index:number)=>{
                return  DatesArray.length < 85 ?  value : (value.includes("07/01") || value.includes("10/01") || value.includes("01/01")|| value.includes("04/01") ? moment(value).format("MMM YYYY")+"    "      : "" )
                }
            },
            gridLines: {
              zeroLineColor: "black",
              color: "white",
            },
          },
        ],
        yAxes: [
          {
            id: "score",
            type: "linear",
            ticks: {
              min: 0,
              max: 100,
              stepSize: 20,
              beginAtZero: true,
              callback: (value:any) => `${value}%`
            },
          },
        ],
      },
    }),
    []
  );

  return (
    <>
            {/* <h3 className='d-flex justify-center'>{planstrategy.strategy.name}</h3> */}

        <h3 className='d-flex justify-center' style={{ margin: '35px 0' }}>{planstrategy.strategy.name}</h3>
        <div className="viewLogs-modal__chart">
        <Line data={data} options={options} />
      </div>
    </>
  );
}

export default ViewLogsChart;