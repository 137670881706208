import React, { FunctionComponent } from "react";

type OwnProps = {
  fileReaderRef: React.RefObject<HTMLInputElement>;
  onFileSelect: (file: File) => void;
};

type Props = OwnProps;

const PictureReader: FunctionComponent<Props> = (props) => {
  const { fileReaderRef, onFileSelect } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const file = event.target.files[0];
      if (file) {
        onFileSelect(file);
      }
    }
  };

  return (
    <input
      ref={fileReaderRef}
      style={{ display: "none" }}
      type="file"
      accept={"image/*"}
      onChange={handleChange}
    />
  );
};

export default PictureReader;
