import React, { FunctionComponent, useMemo } from "react";
import { Icon } from "@blueprintjs/core";
import { BookmarkType } from "../../../../types";

type OwnProps = {
  count?: number;
  bookmarkType?: BookmarkType;
};

type Props = OwnProps;

const BookmarkNumIcon: FunctionComponent<Props> = (props) => {
  const { bookmarkType, count = 0 } = props;

  const color = useMemo(() => {
    return bookmarkType === BookmarkType.Negative ? "red" : "green";
  }, []);

  return (
    <div className="bookmark-count-sec">
      <Icon
        icon={"bookmark"}
        color={color}
        iconSize={24}
        style={{ transform: "scaleX(1.5)" }}
      />
      <span className="bookmark-count">{count}</span>
    </div>
  );
};

export default BookmarkNumIcon;
