import Axios from "axios";
import { API_ROOT } from "../config";
import {
  CountAudit,
  LoginHistory,
  PageSizeNumber,
  UserActionAuditResponse,
  UserEntity,
  UserInvite,
  UserModel,
  UserProfileExcludeBaseModel,
  UserTermsAgreement,
} from "../../types";
import { InviteUsersRequest } from "./types";

export const getUsers = (districtId?: number): Promise<UserEntity> => {
  return Axios.get(
    `${API_ROOT}/users/${districtId ? `?district_id=${districtId}` : ""}`
  );
};

export const inviteUsers = ({
  invites,
}: InviteUsersRequest): Promise<{
  success: UserInvite[];
  failed: UserInvite[];
}> => {
  return Axios.post(`${API_ROOT}/users/invite`, invites);
};

export const getInviteByCode = (inviteCode: string): Promise<UserInvite> => {
  return Axios.get(`${API_ROOT}/invite/accept/${inviteCode}/`);
};

export const editUser = (
  user: UserModel<number, UserProfileExcludeBaseModel<number>>
): Promise<UserModel> => {
  return Axios.put(`${API_ROOT}/users/${user.id}/`, user);
};

export const editInvitedUser = (user: UserInvite): Promise<UserInvite> => {
  return Axios.put(`${API_ROOT}/users/invite/${user.id}/`, user);
};

export const redispatchInvite = (inviteId: number): Promise<void> => {
  return Axios.get(`${API_ROOT}/invites/${inviteId}/redispatch`);
};

export const getUserTermsAgreement = (): Promise<UserTermsAgreement[]> => {
  return Axios.get(`${API_ROOT}/user-term-agreements/`);
};

export const deleteUserTermsAgreement = (id: number): Promise<void> => {
  return Axios.delete(`${API_ROOT}/user-term-agreements/${id}/`);
};

export const getUserLoginHistory = ({
  page_no,
  page_size,
}: PageSizeNumber): Promise<LoginHistory> => {
  return Axios.get(
    `${API_ROOT}/login-history?page_no=${page_no}&page_size=${page_size}`
  );
};

export const getUserActionAudit = ({
  page_no,
  page_size,
}: PageSizeNumber): Promise<UserActionAuditResponse> => {
  return Axios.get(
    `${API_ROOT}/action-history/?page_no=${page_no}&page_size=${page_size}`
  );
};

export const getAuditCounts = (): Promise<CountAudit[]> => {
  return Axios.get(
    `${API_ROOT}/counts-audit-log/`
  );
};

export const downloadDistrictSummaryCounts = (): Promise<Blob> => {
  return Axios.get(
    `${API_ROOT}/district-summary-counts/`,
    {
      responseType: "text",
    }
  );
};

export const downloadSchoolSummaryCounts = (): Promise<Blob> => {
  return Axios.get(
    `${API_ROOT}/school-summary-counts/`,
    {
      responseType: "text",
    }
  );
};


