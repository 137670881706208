import { Alert, IAlertProps } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideConfirmDialog } from "../../store/UIState/actions";
import { useLoading } from "../../helpers/hooks/useLoading";

type OwnProps = {};

type Props = OwnProps & IAlertProps;

const ConfirmDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const confirmDialogConfig = useSelector((s) => s.UIState.confirmDialog);
  const dispatch = useDispatch();

  const handleCancelClick = () => {
    dispatch(hideConfirmDialog());
  };

  const loading = useSelector(
    confirmDialogConfig.isLoadingSelector ?? (() => undefined)
  );
  const error = useSelector(
    confirmDialogConfig.errorSelector ?? (() => undefined)
  );
  useLoading({ loading, error });

  return (
    <Alert
      {...props} 
      {...{
        portalClassName: "confirm-alert-portal",
      }}
      style={{ zIndex: 10000 }}
      className={confirmDialogConfig.className}
      loading={loading}
      isOpen={confirmDialogConfig.show}
      confirmButtonText={confirmDialogConfig.confirmButtonText}
      cancelButtonText={confirmDialogConfig.cancelButtonText}
      onCancel={
        confirmDialogConfig.onCancel
          ? confirmDialogConfig.onCancel
          : handleCancelClick
      }
      icon={confirmDialogConfig.icon}
      onConfirm={confirmDialogConfig.onConfirm}
      intent={confirmDialogConfig.intent}
      canOutsideClickCancel={!confirmDialogConfig.canOutsideClickCancel}
    >
      <p
        dir={intl.formatMessage({
          id: "app.language.direction",
        })}
      >
        {confirmDialogConfig.text}
      </p>
    </Alert>
  );
};

export default ConfirmDialog;
