import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { Helmet } from "react-helmet";
import GenericCollapse from "../../../common/GenericCollapse";
import InsightContent from "./InsightContent";
import { getSurveyInsights } from "../../../../store/survey-reports/actions";
import InsightSubgroupInfoDialog from "./subgroup-info-dialog/InsightSubgroupInfoDialog";
import { getQuestionTags } from "../../../../store/surveys/actions";
import ComparisonInsightContent from "./ComparisonInsightContent";
import { SurveyDemographic } from "../../../../types";

type OwnProps = {
  surveyDeploymentId?: number;
  schoolId?: number;
  availableDemographicsInSchool: SurveyDemographic[];
};

type Props = OwnProps;

const ReportInsightsTab: React.FC<Props> = ({
  surveyDeploymentId,
  schoolId,
  availableDemographicsInSchool,
}: Props) => {
  const intl = useIntl();
  const FRIST_ACCORDION_COLOR = "#24607E";
  const dispatch = useDispatch();

  const questionTags = useSelector((s) => s.surveys.questionTags);
  const tagsLoading = useSelector((s) => s.surveys.loading.getQuestionTags);
  const surveysDeployment = useSelector(
    (s) => s.surveysDeployment.surveysDeployment
  );

  const surveysDeploymentBySchool = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  const displayedQuestionTags = useMemo(() => {
    return (questionTags || []).filter((qt) => qt.show_in_insights);
  }, [questionTags]);

  const sd = useMemo(() => {
    return (
      surveysDeployment.find((et) => et.id == surveyDeploymentId) ||
      surveysDeploymentBySchool.find((et) => et.id == surveyDeploymentId)
    );
  }, [surveyDeploymentId, surveysDeploymentBySchool]);

  const insights = useSelector((s) => s.surveyReports.insights);
  const loading = useSelector((s) => s.surveyReports.loading.getSurveyInsights);
  useEffect(() => {
    if (surveyDeploymentId && schoolId) {
      dispatch(
        getSurveyInsights.request({
          surveyDeploymentId: surveyDeploymentId,
          schoolId: schoolId,
        })
      );
      dispatch(
        getQuestionTags.request({ survey_deployment_id: surveyDeploymentId })
      );
    }
  }, [surveyDeploymentId, schoolId]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "app.survey-report.insights.insights" })}
        </title>
      </Helmet>
      <div>
        <div className={"text-xl mb-2"}>
          {intl.formatMessage(
            {
              id: "app.surveys.reports.insights.info",
            },
            {
              i: (chunks) => <i>{chunks}</i>,
            }
          )}
        </div>

        {loading || tagsLoading ? (
          <Spinner intent={"primary"} />
        ) : insights.length ? (
          <>
            <div className="space-y-5">
              {displayedQuestionTags.map((questionTag, index) => (
                <GenericCollapse
                  key={`tag_${index}`}
                  color={FRIST_ACCORDION_COLOR}
                  title={"Comparison: " + questionTag.tag}
                  popoverText={intl.formatMessage({
                    id: "app.titles.equity-comparison",
                  })}
                >
                  {sd?.njsci_version == 1 && (
                    <span>
                      <span className="blue-sq">&#9830;</span>
                      <i>
                        {" "}
                        ={" "}
                        {intl.formatMessage({
                          id: "app.collect-data.icon-info",
                        })}
                      </i>
                    </span>
                  )}

                  <ComparisonInsightContent
                    tag={questionTag}
                    surveyDeploymentId={surveyDeploymentId}
                    schoolId={schoolId}
                  />
                </GenericCollapse>
              ))}
              {insights.map((insight, index) => (
                <GenericCollapse
                  key={`insight_${index}`}
                  title={"Insight: " + insight.title}
                  popoverText={intl.formatMessage(
                    {
                      id: "app.insights.sub-group.popover",
                    },
                    {
                      code: insight.code,
                    }
                  )}
                >
                  <InsightContent
                    availableDemographicsInSchool={
                      availableDemographicsInSchool
                    }
                    insight={insight}
                  />
                </GenericCollapse>
              ))}
            </div>
          </>
        ) : (
          <NonIdealState
            icon="search"
            title={intl.formatMessage({
              id: "app.survey-report.insights.non-ideal-state",
            })}
          />
        )}
      </div>

      <InsightSubgroupInfoDialog />
    </>
  );
};

export default ReportInsightsTab;
