import React, { FunctionComponent, useMemo } from "react";
import { Dialog, Icon } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { hideInsightSubgroupInfoDialog } from "../../../../../store/survey-reports/actions";
import InsightSubgroupInfoDialogBody from "./InsightSubgroupInfoDialogBody";
import { useIntl } from "react-intl";
import {
  CollectedDemographicDataEnum,
  SurveyDemographic,
} from "../../../../../types";

type OwnProps = {};

type Props = OwnProps;

const InsightSubgroupInfoDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { show, subgroupName, demographic, groupBy } = useSelector(
    (s) => s.surveyReports.dialogs.insightSubgroupInfoDialog
  );

  const handleClose = () => {
    dispatch(hideInsightSubgroupInfoDialog());
  };

  const dialogTitle = useMemo(() => {
    if (!show) {
      return undefined;
    }

    let target = `${subgroupName} ${intl.formatMessage({
      id: `app.filters.respondent-type.${demographic}`,
    })}`;

    if (
      groupBy &&
      groupBy.key == CollectedDemographicDataEnum.grade &&
      demographic &&
      [
        SurveyDemographic.ElementaryStudents || SurveyDemographic.Students,
      ].includes(demographic)
    ) {
      target = target.replace("Graders", "Grade").replace("Grades", "");
    }

    return intl.formatMessage(
      { id: "app.survey-report.insights.for" },
      { target: target }
    );
  }, [subgroupName, groupBy, demographic]);

  return (
    <Dialog
      style={{ width: "64rem" }}
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon={"lightbulb"} iconSize={24} />}
      title={dialogTitle}
    >
      <InsightSubgroupInfoDialogBody />
    </Dialog>
  );
};

export default InsightSubgroupInfoDialog;
