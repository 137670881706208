import { StylesConfig } from "react-select";

export const smallSelectorStyles: StylesConfig<any, any> = {
  // container: (base: any) => ({
  //   ...base,
  //   minWidth: 200,
  // }),
  control: (base: any) => ({
    ...base,
    minHeight: 30,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: "0px 6px",
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
