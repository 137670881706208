import React, { useMemo, useState } from "react";
import {
  District,
  Survey,
  SurveyDemographic,
  SurveyDeployment,
  SurveyDeploymentStateEnum,
} from "../../../../types";
import {
  Button,
  Divider,
  PopoverInteractionKind,
  Popover,
  Tag,
  PopoverPosition,
} from "@blueprintjs/core";
import SurveyLinkForm from "../../../surveys/forms/SurveyLinkForm";
import { useIntl } from "react-intl";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import moment from "moment";

type Props = {
  surveyDeployment: SurveyDeployment<District, Survey<number>> & {
    availableDemographics?: SurveyDemographic[];
  };
  forTeamPlanView: undefined | boolean;
  onEditSurveyDeployment: () => void;
  onDeleteSurveyDeployment: (e: React.MouseEvent<HTMLElement>) => void;
  onGenerateReports: (e: React.MouseEvent<HTMLElement>) => void;
  onViewReports: (e: React.MouseEvent<HTMLElement>) => void;
  onDownloadLetters: (e: React.MouseEvent<HTMLElement>) => void;
  isManagedDeletedDataWorkspace?: boolean;
  isAdminOrDistrictActivePlan: boolean;
  isLatestSD:any;
};

export const SurveyDeploymentTableActions: React.FC<Props> = (props) => {
  
  const {
    surveyDeployment,
    onEditSurveyDeployment,
    onDeleteSurveyDeployment,
    onGenerateReports,
    onViewReports,
    onDownloadLetters,
    forTeamPlanView,
    isManagedDeletedDataWorkspace,
    isAdminOrDistrictActivePlan,
    isLatestSD
  } = props;

  const intl = useIntl();
  

  const {
    hasDataCoordinatorAccess,
    hasTeamChampionAccess,
    isTeamMember,
    isSuperintendent,
    isPrincipal,
  } = useUserRole();

  const getCurrentSession = () => {
    const SURVEY_MONTH: number = 6;
    const date = moment(new Date());

    if (parseInt(moment(date).format("M")) > SURVEY_MONTH) {
      return `${moment(date).format("YYYY")}-${moment(date)
        .add(1, "years")
        .format("YYYY")}`;
    } else {
      return `${moment(date).subtract(1, "year").format("YYYY")}-${moment(
        date
      ).format("YYYY")}`;
    }
  };

  const surveyDeploymentYear = surveyDeployment.deployment_name
    ? surveyDeployment.deployment_name.split(" ")[0]
    : "";

  const [isSdExpiredwithData, setIsSdExpiredwithData] = useState(false);
  const [isSdExpiredwithoutData, setIsSdExpiredwithoutData] = useState(false);

  const showGenerateReportsSection = useMemo(() => {
    if (isTeamMember) {
      return false;
    }

    if (parseInt(surveyDeploymentYear.split("-")[0]) < parseInt(getCurrentSession().split("-")[0])
      && surveyDeployment.state === SurveyDeploymentStateEnum.IN_PROGRESS
      && Object.values(surveyDeployment.responses).length
    ) {
      setIsSdExpiredwithData(true);
    }

    if (parseInt(surveyDeploymentYear.split("-")[0]) < parseInt(getCurrentSession().split("-")[0])
      && surveyDeployment.state === SurveyDeploymentStateEnum.IN_PROGRESS
      && !Object.values(surveyDeployment.responses).length
    ) {
      setIsSdExpiredwithoutData(true);
    }

    return (
      hasDataCoordinatorAccess &&
      [SurveyDeploymentStateEnum.IN_PROGRESS].includes(
        surveyDeployment.state!
      ) &&
      moment(surveyDeployment.start_date).isBefore()
    );
  }, [
    hasDataCoordinatorAccess,
    surveyDeployment.start_date,
    surveyDeployment.state,
    isTeamMember,
  ]);

  const hasEditAccess = useMemo(() => {
    return !forTeamPlanView || hasDataCoordinatorAccess;
  }, [forTeamPlanView, hasDataCoordinatorAccess]);

  const status = useMemo(() => {
    switch (surveyDeployment.state) {
      case SurveyDeploymentStateEnum.READY:
      case SurveyDeploymentStateEnum.IN_PROGRESS:
        return null;

      case SurveyDeploymentStateEnum.GENERATING_DATASETS:
      case SurveyDeploymentStateEnum.QUEUED:
        return !isAdminOrDistrictActivePlan ? (
          <Tag className="self-center" intent="primary" large>
            {intl.formatMessage({
              id: "app.surveys.table.actions.view-reports.generating_datasets",
            })}
          </Tag>
        ) : null;

      case SurveyDeploymentStateEnum.SCHEDULED:
      default:
        return !isAdminOrDistrictActivePlan ? (
          hasDataCoordinatorAccess ? (
            <Popover
              popoverClassName={"w-20rem"}
              interactionKind="hover"
              content={
                <div className="p-2">
                  {!Object.values(surveyDeployment.responses).length
                    ? intl.formatMessage({
                      id:
                        "app.surveys-deployment-table.columns.actions.disable.generate-reports.info",
                    })
                    : intl.formatMessage({
                      id:
                        "app.surveys-deployment-table.columns.actions.generate-reports.info",
                    })}
                </div>
              }
              position={PopoverPosition.LEFT}
            >
              <Button
                text={intl.formatMessage({
                  id:
                    "app.surveys-deployment-table.columns.actions.generate-reports",
                })}
                intent="primary"
                className="whitespace-no-wrap"
                disabled={!Object.values(surveyDeployment.responses).length}
                onClick={onGenerateReports}
              />
            </Popover>
          ) : (
            <Popover
              popoverClassName="w-48"
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <div className="p-3">
                  {intl.formatMessage({
                    id: "app.survey-deployment.veiw-report.disable-popover",
                  })}
                </div>
              }
            >
              <Tag className="self-center" large>
                {intl.formatMessage({
                  id: "app.titles.view-reports",
                })}
              </Tag>
            </Popover>
          )
        ) : null;
    }
  }, [surveyDeployment.state]);

  const showEditAndLinksButtons = useMemo(() => {
    switch (surveyDeployment.state) {
      case SurveyDeploymentStateEnum.QUEUED:
      case SurveyDeploymentStateEnum.GENERATING_DATASETS:
      case SurveyDeploymentStateEnum.READY:
        return false;
      default:
        return true;
    }
  }, [surveyDeployment.state]);

  const deleteButton = useMemo(
    () => (
      <Button
        title={intl.formatMessage({
          id: "app.surveys-table.columns.delete",
        })}
        icon="trash"
        className="whitespace-no-wrap"
        intent="danger"
        onClick={onDeleteSurveyDeployment}
      />
    ),
    [intl, onDeleteSurveyDeployment]
  );

  const section = useMemo(() => {
    if (showGenerateReportsSection && !isAdminOrDistrictActivePlan) {
      return (
        <Popover
          popoverClassName={"w-20rem"}
          interactionKind={"hover"}
          content={
            <div className="p-2">
              {!Object.values(surveyDeployment.responses).length
                ? intl.formatMessage({
                  id:
                    "app.surveys-deployment-table.columns.actions.disable.generate-reports.info",
                })
                : intl.formatMessage({
                  id:
                    "app.surveys-deployment-table.columns.actions.generate-reports.info",
                })}
            </div>
          }
          position={PopoverPosition.LEFT}
        >
          <Button
            text={intl.formatMessage({
              id:
                "app.surveys-deployment-table.columns.actions.generate-reports",
            })}
            intent="primary"
            className="whitespace-no-wrap"
            disabled={!Object.values(surveyDeployment.responses).length}
            onClick={onGenerateReports}
          />
        </Popover>
      );
    }


    if (surveyDeployment.state === SurveyDeploymentStateEnum.READY) {
   
      return (
        <Button
        id={isLatestSD && "latest-report-button"}
          intent="primary"
          className="whitespace-no-wrap"
          text={intl.formatMessage({
            id: "app.titles.view-reports",
          })}
          title={intl.formatMessage({
            id: "app.titles.view-reports",
          })}
          onClick={onViewReports}
        />
      );
    } else if (
      isAdminOrDistrictActivePlan ||
      isTeamMember ||
      !hasDataCoordinatorAccess
    ) {
      return (
        <Popover
          popoverClassName="w-48"
          interactionKind={PopoverInteractionKind.HOVER}
          content={
            <div className="p-3">
              {intl.formatMessage({
                id: "app.survey-deployment.veiw-report.disable-popover",
              })}
            </div>
          }
        >
          <Tag className="self-center" large>
            {intl.formatMessage({
              id: "app.titles.view-reports",
            })}
          </Tag>
        </Popover>
      );
    }
    return null;
  }, [showGenerateReportsSection, surveyDeployment.state]);

  //adding !hasDataCoordinatorAccess to check if the current user is in the school team this flag will contain true otherwise undefined or false
  if ((!isSuperintendent && isTeamMember) || !hasDataCoordinatorAccess) {
    return status ?? section;
  }

  return (
    <div className={"flex flex-col gap-1"}>
      {
        isSdExpiredwithoutData && (
          <p className="expire-deployment-label">
            {intl.formatMessage({
              id: "app.surveys-deployment-table.columns.actions.expire-deployment-without-date",
            },
              { i: (chunks) => <i>{chunks}</i> }
            )}
          </p>
        )
      }

      {
        isSdExpiredwithData && (
          <p className="expire-deployment-label">
            {intl.formatMessage({
              id: "app.surveys-deployment-table.columns.actions.expire-deployment-with-date",
            },
              { i: (chunks) => <i>{chunks}</i> }
            )}
          </p>
        )
      }

      <div className="flex gap-1">
        {hasEditAccess &&
          !isAdminOrDistrictActivePlan &&
          showEditAndLinksButtons &&
          !isManagedDeletedDataWorkspace && (
            <Button
              title={intl.formatMessage({
                id: "app.surveys-deployment-table.columns.actions.edit",
              })}
              icon="edit"
              className="whitespace-no-wrap"
              intent="primary"
              onClick={onEditSurveyDeployment}
              disabled={(isSdExpiredwithoutData || isSdExpiredwithData) ? true : false}
            />
          )}

        {forTeamPlanView &&
          showEditAndLinksButtons &&
          !isAdminOrDistrictActivePlan &&
          !isManagedDeletedDataWorkspace && (
            <Button
              title={intl.formatMessage({
                id:
                  "app.surveys-deployment-table.columns.actions.download-letters",
              })}
              text={intl.formatMessage({
                id:
                  "app.surveys-deployment-table.columns.actions.download-letters",
              })}
              className="whitespace-no-wrap"
              intent="primary"
              onClick={onDownloadLetters}
              disabled={(isSdExpiredwithoutData || isSdExpiredwithData) ? true : false}
            />
          )}

        {showEditAndLinksButtons &&
          !isAdminOrDistrictActivePlan &&
          !isManagedDeletedDataWorkspace && (
            (isSdExpiredwithoutData || isSdExpiredwithData) ?
              <Button
                title={intl.formatMessage({
                  id: "app.surveys-deployment-table.columns.actions.link",
                })}
                icon="link"
                className="whitespace-no-wrap"
                intent="primary"
                disabled={true}
              /> :
              <Popover
                popoverClassName={"popover-container-min-width"}
                content={
                  <SurveyLinkForm
                    forTeamPlanView={forTeamPlanView}
                    districts={surveyDeployment.districts}
                    availableDemographics={surveyDeployment.availableDemographics}
                    surveyHashMap={{
                      [SurveyDemographic.ElementaryStudents]:
                        surveyDeployment.elementary_student_hash,
                      [SurveyDemographic.Students]: surveyDeployment.student_hash,
                      [SurveyDemographic.SchoolStaff]:
                        surveyDeployment.school_staff_hash,
                      [SurveyDemographic.Parents]: surveyDeployment.parent_hash,
                    }}
                  />
                }
                target={
                  <Button
                    title={intl.formatMessage({
                      id: "app.surveys-deployment-table.columns.actions.link",
                    })}
                    icon="link"
                    className="whitespace-no-wrap"
                    intent="primary"
                  />
                }
              />
          )}

        {hasEditAccess && (
          <>
            {(isSuperintendent || isPrincipal) && !isAdminOrDistrictActivePlan ? (
              <>
                {forTeamPlanView && !isManagedDeletedDataWorkspace && (
                  <Popover
                    popoverClassName={"w-25"}
                    interactionKind={"hover"}
                    content={
                      <div className={"p-2"}>
                        {intl.formatMessage({
                          id: "app.surveys-table.columns.delete.info",
                        })}
                      </div>
                    }
                    target={deleteButton}
                  />
                )}
              </>
            ) : (
              isManagedDeletedDataWorkspace && deleteButton
            )}

            {section && !isManagedDeletedDataWorkspace && (
              <>
                {((hasEditAccess &&
                  ((isSuperintendent && !isAdminOrDistrictActivePlan) ||
                    showEditAndLinksButtons)) ||
                  showEditAndLinksButtons) &&
                  !isAdminOrDistrictActivePlan && <Divider className="my-0 mx-1" />}
                {section}
              </>
            )}
            {hasDataCoordinatorAccess && !isAdminOrDistrictActivePlan && (
              <Divider className="my-0 mx-1" />
            )}
          </>
        )}
        {status}
      </div>
    </div>
  );
};
