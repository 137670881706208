import { useSelector } from "react-redux";
import { useLoading } from "../useLoading";

export const useUpdateDomainPrioritiesDecisionLoading = () => {
  const error = useSelector(
    (s) => s.needs.errors.updateDomainPrioritiesDecision
  );
  const loading = useSelector(
    (s) => s.needs.loading.updateDomainPrioritiesDecision
  );

  useLoading({
    error: error,
    loading: loading,
  });

  return { loading, error };
};
