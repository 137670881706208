import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { SelectorOptionType } from "../../../types";
import { getDistricts } from "../../../store/districts/actions";

type OwnProps = {
  className?: string;
  disabled?: boolean;
  districtId?: number;
  onDistrictChange: (selectedDistrictId?: number) => any;
  isClearable?: boolean;
};

type Props = OwnProps;

const DistrictSelector: React.FC<Props> = (props: Props) => {
  const {
    districtId,
    onDistrictChange,
    disabled,
    className,
    isClearable,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const loading = useSelector((s) => s.districts.loading.getDistricts);
  const districts = useSelector((s) => s.districts.districts);
  const districtsOptions: SelectorOptionType[] = useMemo(
    () => districts.map((s) => ({ value: s.id, label: s.name })),
    [districts]
  );

  useEffect(() => {
    dispatch(getDistricts.request());
  }, []);

  const handleDistrictChange = (value?: number) => {
    onDistrictChange(value);
  };

  const displayNoOptions = useCallback(() => {
    return intl.formatMessage({ id: "app.titles.no-options" });
  }, []);

  return (
    <Select
      value={districtsOptions.find((option) => {
        return option.value === districtId;
      })}
      className={className}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={loading}
      options={districtsOptions}
      placeholder={intl.formatMessage({
        id: "app.forms.school-form.district-selector.placeholder",
      })}
      noOptionsMessage={displayNoOptions}
      onChange={(item) => handleDistrictChange(item?.value)}
    />
  );
};

export default DistrictSelector;
