import React, { ReactNode, ReactNodeArray, useMemo, useState } from "react";
import {
  Collapse,
  Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import { pickTextColorBasedOnBgColor } from "../charts/utils";
import { DOMAIN_DEFAULT_COLOR } from "../../constants/constants";

type OwnProps = {
  title: string | ReactNodeArray | ReactNode;
  defaultIsOpen?: boolean;
  showCollapseWithEmptyContent?: boolean;
  popoverText?: string | ReactNodeArray | ReactNode;
  color?: string;
  className?: string;
  chevronIntent?: Intent;
  keepChildrenMounted?:boolean;
};

type Props = OwnProps;

const GenericCollapse: React.FC<Props> = (props) => {
  const {
    title,
    defaultIsOpen,
    children,
    showCollapseWithEmptyContent = true,
    popoverText,
    color,
    className,
    chevronIntent,
    keepChildrenMounted
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(!!defaultIsOpen);


  const handleOpenChange = () => {
    setIsOpen((s) => !s);
  };

  const showCollapse = useMemo(
    () => showCollapseWithEmptyContent || !!children,
    [showCollapseWithEmptyContent]
  );

  const headerClassName = useMemo(() => {
    const classes = [];
    if (!isOpen) {
      classes.push("rounded-b");
    }

    if (showCollapse) {
      classes.push("cursor-pointer");
    }

    return classes.join(" ");
  }, [isOpen, showCollapse]);

  const collapseHeader = useMemo(
    () => (
      <div
        className={`${
          color ? "" : "bg-blue-600"
        } text-white rounded-t flex items-center p-2 ${headerClassName}`}
        style={{ backgroundColor: color ? color : undefined }}
        onClick={handleOpenChange}
      >
        <div
          className="text-xl font-bold w-full"
          style={{
            color: pickTextColorBasedOnBgColor(
              color ? color : DOMAIN_DEFAULT_COLOR
            ),
          }}
        >
          {title}
        </div>
        {showCollapse && (
          <Icon
            className="mr-2"
            icon={isOpen ? "chevron-down" : "chevron-right"}
            iconSize={30}
            intent={chevronIntent}
          />
        )}
      </div>
    ),
    [
      headerClassName,
      handleOpenChange,
      title,
      showCollapse,
      isOpen,
      color,
      chevronIntent,
    ]
  );

  const collapseClassName = useMemo(() => {
    let res = "generic-collapse";
    if (className) {
      res += ` ${className}`;
    }

    return res;
  }, [className]);

  return (
    <div className={collapseClassName}>
      {popoverText ? (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          content={<div className="p-4">{popoverText}</div>}
          targetClassName="w-full"
          popoverClassName="w-25"
          target={collapseHeader}
        />
      ) : (
        collapseHeader
      )}
 
      {showCollapse && <Collapse keepChildrenMounted={keepChildrenMounted??false} isOpen={isOpen}>{children}</Collapse>}
    </div>
  );
};

export default GenericCollapse;
