import _, { truncate } from "lodash";
import { IntlShape } from "react-intl";
import {
  AttachedResource,
  CollectedDemographicDataEnum,
  Dataset,
  Domain,
  MeasurementType,
  PlanStrategy,
  Strategy,
  SurveyQuestion,
  TeamMember,
  TopicTag,
  UserRole,
  userRoles,
  UserTag,
} from "../../types";

export function isPlanStrategy(
  strategy: Strategy | PlanStrategy
): strategy is PlanStrategy {
  return (strategy as PlanStrategy)?.strategy !== undefined;
}

export function isStrategy(
  strategy?: Strategy | PlanStrategy
): strategy is Strategy {
  if (!strategy) {
    return false;
  }

  return (
    (strategy as Strategy).name !== undefined &&
    (strategy as Strategy).domains !== undefined
  );
}

export const calculateDatasetScore = (dataset: Dataset, sdid: Number = 0): any => {
  if (!dataset.data_points?.length) {
    return "N/A"; // todo
  }

  if (dataset.measurement_type === MeasurementType.Ratio) {
    return _.chain(dataset.data_points)
      .map((dataPoint) => {
        const parts = dataPoint.value.toString().split(":");

        const numerator = +parts[0];
        const denominator = +parts[1];

        if (
          parts.length !== 2 ||
          Number.isNaN(numerator) ||
          Number.isNaN(denominator)
        ) {
          return 0;
        }

        return +parts[0] / (+parts[1] || 1);
      })
      .mean()
      .round(2)
      .value();
  } else {
    const score = _.chain(dataset.data_points)
      .filter((s) => (sdid > 0 ? s.survey_deployment_id === sdid : true))
      .map((dp) => +dp.value)
      .mean()
      .round(2)
      .value();

    switch (dataset.measurement_type) {
      case MeasurementType.Percentage:
        return `${score}%`;

      case MeasurementType.Labels: {
        const v = dataset.possible_values?.sort(
          (a, b) => Math.abs(+score - a.order) - Math.abs(+score - b.order)
        )[0];

        return v ? v.name : score;
      }

      default:
        return score;
    }
  }
};

export const getFiltersString = (dataset: Dataset): string => {
  let resultString = "";
  Object.values(CollectedDemographicDataEnum).forEach((cd) => {
    if (!!dataset?.[cd]) {
      resultString = resultString + dataset[cd] + " ";
    }
  });
  return resultString;
};

export const filterStrategiesByDomainAndSearchString = <
  T extends PlanStrategy | Strategy | (PlanStrategy | Strategy)
>(
  defaultArray: T[],
  searchString: string,
  selectedDomains: Domain[] = []
): T[] => {
  const lowerCasedSearchString = searchString.toLowerCase();

  const isAllFilters = searchString !== "" && !!selectedDomains.length;
  const isDomainFilter = !!selectedDomains.length && searchString === "";
  const isStringFilter = !selectedDomains.length && searchString !== "";

  if (isAllFilters) {
    return defaultArray.filter((strategy) => {
      if (isPlanStrategy(strategy)) {
        return (
          strategy.strategy.name
            .toLowerCase()
            .includes(lowerCasedSearchString) &&
          strategy.strategy.domains.some((d) =>
            selectedDomains.some((sd) => sd.id === d?.id)
          )
        );
      } else if (isStrategy(strategy)) {
        return (
          strategy.name.toLowerCase().includes(lowerCasedSearchString) &&
          strategy.domains.some((d) =>
            selectedDomains.some((sd) => sd.id === d?.id)
          )
        );
      }
    });
  }

  if (isDomainFilter) {
    return defaultArray.filter((strategy) => {
      if (isPlanStrategy(strategy)) {
        return strategy.strategy.domains.some((d) =>
          selectedDomains.some((sd) => sd.id === d?.id)
        );
      } else if (isStrategy(strategy)) {
        return strategy.domains?.some((d) =>
          selectedDomains.some((sd) => sd.id === d?.id)
        );
      }
    });
  }

  if (isStringFilter) {
    return defaultArray.filter((strategy) => {
      if (isPlanStrategy(strategy)) {
        return strategy.strategy.name
          .toLowerCase()
          .includes(lowerCasedSearchString);
      } else if (isStrategy(strategy)) {
        return strategy.name.toLowerCase().includes(lowerCasedSearchString);
      }
    });
  }

  return defaultArray;
};

export const defineUserTagByAuthenticatedUserRole = (
  userRole: UserRole
): UserTag => {
  if (userRoles.admins.includes(userRole)) {
    return UserTag.RUTGERS_SCTP_STAFF;
  }
  if (userRoles.districtAdmins.includes(userRole)) {
    return UserTag.DISTRICT_TEAM_MEMBER;
  }
  return UserTag.SCHOOL_TEAM_MEMBER;
};


export const filterTeambyInviteRoleActivationStatus = <
    T extends TeamMember
>(
    defaultArray: T[],
    inviteStatus: any[] = [],
    roleFilter: any[] = [],
    activationStatus: any[] = []
): T[] => {
  const isAllFilters = !!inviteStatus.length && !!roleFilter.length;
  const isInviteStatusFilter = !!inviteStatus.length && !roleFilter.length;
  const isRoleFilter = !!roleFilter.length && !inviteStatus.length;

  let filteredMembers: T[] = defaultArray;

  if((activationStatus as any) === 1){
    filteredMembers = defaultArray.filter(member => member.user.is_active);;
  }

  if((activationStatus as any) === 0){
    filteredMembers = defaultArray.filter(member => !member.user.is_active);;
  }

  if (isAllFilters) {
    return filteredMembers.filter((member) => {
            return (
              inviteStatus.includes(member.invited_status) && 
              ((member.user.profile.custom_role_name && roleFilter.includes(member.user.profile.custom_role_name)) ||
              roleFilter.includes(member.user.profile.role))
            );
        });
    }

    if (isInviteStatusFilter) {
      return filteredMembers.filter((member) => {
            return (inviteStatus.includes(member.invited_status));
        });
    }

    if (isRoleFilter) {
      return filteredMembers.filter((member) => {
        return (member.user.profile.custom_role_name &&
                roleFilter.includes(member.user.profile.custom_role_name) || 
                roleFilter.includes(member.user.profile.role)
          );
    });
    }

    if((activationStatus as any) === 1){
      return filteredMembers.filter(member => member.user.is_active);
    }

  return filteredMembers;
};

export const filterResources = (
  userTags: UserTag[],
  topicTags: TopicTag[],
  resources: AttachedResource[],
  lowerCasedSearchString: string,
  intl: IntlShape
): AttachedResource[] => {
  return resources
    .filter((resource) => {
      if (lowerCasedSearchString) {
        return (
          (resource.topic_tag &&
            intl
              .formatMessage({ id: `app.topic-tag.${resource.topic_tag}` })
              .toLocaleLowerCase()
              .includes(lowerCasedSearchString)) ||
          (resource.user_tag &&
            intl
              .formatMessage({ id: `app.user-tag.${resource.user_tag}` })
              .toLocaleLowerCase()
              .includes(lowerCasedSearchString)) ||
          resource.title
            ?.toLocaleLowerCase()
            ?.includes(lowerCasedSearchString) ||
          resource.description
            ?.toLocaleLowerCase()
            ?.includes(lowerCasedSearchString)
        );
      }
      return true;
    })
    .filter((resource) => {
      if (userTags.length && !resource.user_tag) {
        return false;
      }
      if (userTags.length && resource.user_tag) {
        return userTags.includes(resource.user_tag);
      }
      return true;
    })
    .filter((resource) => {
      if (topicTags.length && !resource.topic_tag) {
        return false;
      }
      if (topicTags.length && resource.topic_tag) {
        return topicTags.includes(resource.topic_tag);
      }
      return true;
    });
};

export const addHttp = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  return url;
}

export const makeFullQuestionName = (
  question: SurveyQuestion,
  showOrder?: boolean
) => {
  if (!question) {
    return undefined;
  }

  let fullQuestion: string = "";

  if (question.lead_in) {
    fullQuestion += question.lead_in + " ";
  }

  fullQuestion += question.text;

  if (showOrder) {
    // fullQuestion = `${question.order + 1}. ` + fullQuestion;
    fullQuestion = `${question.short_code} ` + fullQuestion;
  }

  return fullQuestion;
};
