import React, { FunctionComponent, useMemo } from "react";
import { Button, Icon, Tooltip } from "@blueprintjs/core";
import { SurveyDemographic } from "../../../../types";
import { useDispatch } from "react-redux";
import { toggleDemographicHidden } from "../../../../store/needs/actions";
import { useIntl } from "react-intl";

type OwnProps = {
  demographic: SurveyDemographic;
  hidden?: boolean;
};

type Props = OwnProps;

const DemographicEyeIcon: FunctionComponent<Props> = (props) => {
  const { demographic, hidden } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleDemographicHidden(demographic));
  };

  const tooltipContent = useMemo(() => {
    return intl.formatMessage({
      id: hidden
        ? "app.needs.tabs.explore-strengths-and-needs.eye-popover.demographic.show"
        : "app.needs.tabs.explore-strengths-and-needs.eye-popover.demographic.hide",
    });
  }, [hidden]);

  return (
    <div
      style={{
        marginRight: "unset",
        marginLeft: "auto",
      }}
    >
      <Tooltip interactionKind="hover" content={tooltipContent}>
        <Button
          icon={
            <Icon
              icon={hidden ? "eye-off" : "eye-open"}
              style={{ color: "white" }}
            />
          }
          minimal
          onClick={handleClick}
        />
      </Tooltip>
    </div>
  );
};

export default DemographicEyeIcon;
