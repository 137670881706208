import React, { useMemo } from "react";
import {
  DomainQuestionsComparison,
  SurveyDemographic,
} from "../../../../types";
import DomainQuestionComparisonTableRow from "./DomainQuestionComparisonTableRow";

type OwnProps = {
  surveyComparison: DomainQuestionsComparison;
  demographics: SurveyDemographic[];
  domainId?: number;
};

type Props = OwnProps;

const DomainQuestionComparisonList: React.FC<Props> = ({
  surveyComparison,
  demographics,
  domainId,
}: Props) => {
  const domainColor = useMemo(() => {
    return surveyComparison.domain?.color_hex;
  }, [surveyComparison]);

  surveyComparison?.responses?.sort((a, b) => {
    let anum = 0;
    let bnum = 0;
    demographics.map((demographic) => {
      if (
        a?.respondents?.[demographic]?.mean_score === undefined ||
        a?.respondents?.[demographic]?.mean_score === null
      ) {
        anum = anum + 1;
      }
      if (
        b?.respondents?.[demographic]?.mean_score === undefined ||
        b?.respondents?.[demographic]?.mean_score === null
      ) {
        bnum = bnum + 1;
      }
    });
    if (anum === bnum && a.domain?.name && b.domain?.name) {
      if (a.domain?.name === b.domain?.name) {
        return a.common_text.toUpperCase() < b.common_text.toUpperCase()
          ? -1
          : 1;
      }
      return a.domain?.name.toUpperCase() < b.domain?.name.toUpperCase()
        ? -1
        : 1;
    }
    return anum < bnum ? -1 : 1;
  });

  return (
    <>
      {surveyComparison?.responses?.map((item, index) => (
        <DomainQuestionComparisonTableRow
          key={index}
          domainId={domainId}
          item={item}
          demographics={demographics}
          domainColor={domainColor ?? item.domain?.color_hex}
        />
      ))}
    </>
  );
};

export default DomainQuestionComparisonList;
