import {
  Button,
  NonIdealState,
  Popover,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../../helpers/toaster";
import {
  addResource,
  getResources,
  hideUpsertResourceDialog,
  showUpsertResourceDialog,
} from "../../../../store/resources/actions";
import {
  AttachedResource,
  AttachedToType,
  SelectorOptionType,
  TopicTag,
  UserTag,
} from "../../../../types";
import EntityTable from "../../../common/EntityTable";
import AddResourcesButtons from "../AddResourcesButtons";
import UpsertResourceDialog from "../modify-resource-dialog/UpsertResourceDialog";
import ResourceActions from "../ResourceActions";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { Icon } from "@blueprintjs/core";

import "./uploadedFiles.scss";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { addHttp, defineUserTagByAuthenticatedUserRole } from "../../utils";
import { ValueType } from "react-select";
import Select from "react-select";
import { getCurrentUserRole } from "../../../../store/auth/selectors";
import "./uploadedSidePanel.scss"
type OwnProps = {};

type Props = OwnProps;

const UploadedFiles: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [resourceTitle, setResourceTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [resourceUrl, setResourceUrl] = useState<string>("");
  const [topicTag, setTopicTag] = useState<any>(undefined);
  const [otherTopicTag, setOtherTopicTag] = useState<string>("");
  const [userTag, setUserTag] = useState<UserTag | undefined>(undefined);

  const uploadPanelClose = () => {
    setResourceTitle("");
    setDescription("");
    setResourceUrl("");
    setTopicTag("");
    setOtherTopicTag("");
    dispatch(hideUpsertResourceDialog());
  };

  const {
    show,
    onClose,
    onSave,
    resource,
    isLoadingSelector,
    errorSelector,
  } = useSelector((s) => s.resources.dialogs.upsertResourceDialog);
 
  const userRole = useSelector(getCurrentUserRole);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const resources: AttachedResource[] = useSelector(
    (s) => s.resources.resources
  );

  const isLoading = useSelector((s) => s.resources.loading.getResources);
  const error = useSelector((s) => s.resources.errors.getResources);
  useLoading({ loading: isLoading, error: error });

  const isDeleteLoading = useSelector(
    (s) => s.resources.loading.deleteResource
  );
  const deleteError = useSelector((s) => s.resources.errors.deleteResource);
  useLoading({ loading: isDeleteLoading, error: deleteError });

  useEffect(() => {
    setResourceUrl(resource?.url ?? "");
    setResourceTitle(resource?.title ?? "");
    setDescription(resource?.description ?? "");
    setTopicTag(resource?.topic_tag);
    setOtherTopicTag(resource?.topic_tag_other ?? "");
    userRole &&
      setUserTag(
        resource?.user_tag ?? defineUserTagByAuthenticatedUserRole(userRole)
      );
  }, [resource, userRole]);

  useEffect(() => {
    if (activePlanId) {
      dispatch(getResources.request(activePlanId));
    }
  }, [activePlanId]);

  const columns: any = useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: "app.titles.name" }),
        accessor: "name",
        width: "30%",
        Cell: ({ row: { original } }: CellProps<AttachedResource>) => {
          return <div className="font-bold">{original?.title}</div>;
        },
      },
      {
        Header: intl.formatMessage({ id: "app.titles.description" }),
        accessor: (originalRow: AttachedResource) => {
          return (
            originalRow.description ||
            intl.formatMessage({ id: "app.titles.not-applicable" })
          );
        },
        width: "100%",
      },
      {
        Header: intl.formatMessage({ id: "app.resources.topic-tag" }),
        accessor: "topic_tag",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<AttachedResource>) => {
          return original?.topic_tag || original?.topic_tag_other ? (
            <div className="flex justify-center">
              <Tag minimal>
                {original?.topic_tag
                  ? intl.formatMessage({
                      id: `app.topic-tag.${original.topic_tag}`,
                    })
                  : original?.topic_tag_other}
              </Tag>
            </div>
          ) : null;
        },
      },
      {
        id: "actions",
        // width: "100%",
        Header: intl.formatMessage({ id: "app.titles.actions" }),
        Cell: ({ row: { original } }: CellProps<AttachedResource>) => {
          return <ResourceActions resource={original} />;
        },
      },
    ],
    []
  );

  const [searchString, setSearchString] = useState("");

  const resourcesFilter = (
    resource: AttachedResource,
    index: number,
    array: AttachedResource[]
  ) => {
    const searchStringLowerCase = searchString.trim().toLowerCase();

    if (!searchStringLowerCase) {
      return true;
    }

    return (
      resource?.title?.toLowerCase()?.includes(searchStringLowerCase) ||
      resource?.type?.toLowerCase()?.includes(searchStringLowerCase)
    );
  };

  const filteredData = useMemo(() => resources.filter(resourcesFilter), [
    resources,
    searchString,
  ]);

  const handleResourceUpload = (
    resource: Partial<AttachedResource> & Pick<AttachedResource, "type">
  ) => {
    
    dispatch(
      showUpsertResourceDialog({
        resource: resource,
        onSave: handleResourceAdd,
        onClose: handleClose,
        isLoadingSelector: (s) => s.resources.loading.addResource,
        errorSelector: (s) => s.resources.errors.addResource,
      })
    );
  };

  const nonIdealState = useMemo(
    () => (
      <NonIdealState
        icon="search"
        className="pb-2"
        title={intl.formatMessage({
          id: "app.resources.no-data",
        })}
        description={intl.formatMessage({
          id: "app.resources.no-data.tip",
        })}
        action={
          <AddResourcesButtons
            onClick={handleResourceUpload}
            attachedToType={
              isDistrictActivePlan
                ? AttachedToType.DISTRICT_RESOURCES_WORKSPACE
                : AttachedToType.SCHOOL_RESOURCES_WORKSPACE
            }
          />
        }
      />
    ),
    [handleResourceUpload, isDistrictActivePlan]
  );

  const handleResourceAddSuccess = (
    resource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => {
    AppToaster.show({
      intent: "success",
      icon: "tick",
      message: intl.formatMessage(
        { id: "app.titles.toaster-message" },
        { name: resource.title }
      ),
    });
    handleClose();
  };

  const handleResourceAdd = useCallback(
    (
      resource: Partial<AttachedResource> &
        Pick<
          AttachedResource,
          "type" | "title" | "description" | "attached_to_type"
        >
    ) => {
      dispatch(
        addResource.request({
          resource: resource,
          planId: activePlanId!,
          onSuccess: () => handleResourceAddSuccess(resource),
        })
      );
    },
    [activePlanId]
  );

  const handleClose = () => {
    dispatch(hideUpsertResourceDialog());
  };

  const handleResourceTitleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setResourceTitle(value);
  };

  const handleResourceDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setDescription(value);
  };

  const handleResourceUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setResourceUrl(value);
  };

  const handleResourceUrlUpdate = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let httpValue = addHttp(value);
    setResourceUrl(httpValue);
  };

  const handleTopicTagChange = (
    v: ValueType<SelectorOptionType<TopicTag>, false>
  ) => {
    const value = v?.value;
    if (value === TopicTag.OTHER) {
      // setOtherTopicTag("");
    }
    setTopicTag(value);
  };

  const handleResourceOtherTopicTagChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setOtherTopicTag(value);
  };

  const topicTagOptions: SelectorOptionType<TopicTag>[] = useMemo(() => {
    return Object.values(TopicTag).map((value) => ({
      label: intl.formatMessage({ id: `app.topic-tag.${value}` }),
      value: value,
    }));
  }, []);

  const topicTagValue = useMemo(() => {
    return topicTag
      ? topicTagOptions.find((opt: any) => opt.value === topicTag)
      : undefined;
  }, [topicTag, topicTagOptions]);

  const handleSubmit = () => {
    const data = {
      ...resource,
      title: resourceTitle,
      url: resourceUrl,
      description: description,
      topic_tag: topicTag,
      topic_tag_other: otherTopicTag,
      user_tag: userTag,
    };
    const handleLinkResourceAdd = (resource: any) => {
      onSave && onSave(resource);
    };

    handleLinkResourceAdd(data);
  };

  const UploadFilesPanel = () => {
    return (
      <>
        <Dialog
          open={resource?.type === "upload" ? true : false}
          onClose={uploadPanelClose}
          className="sidebar-modal small-modal"
        >
          <div className="sidepanel">
            <div className="sidepanel__header">
              <h3>
                {intl.formatMessage({ id: "app.resources.enter-file-details" })}
              </h3>
              <button onClick={uploadPanelClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content enterFileDetails">
              <TextField
                label={
                  <>
                    {intl.formatMessage({
                      id: "app.resources.link.resource-title",
                    })}{" "}
                    <span className="text-red-700">*</span>
                  </>
                }
                id="outlined-size-small"
                // defaultValue="Small"
                size="small"
                value={resourceTitle}
                onChange={handleResourceTitleChange}
                fullWidth
              />
              <FormControl fullWidth size="small">
                <Select
                  className="z-50"
                  placeholder="Topic Tag"
                  value={topicTagValue}
                  options={topicTagOptions}
                  onChange={handleTopicTagChange}
                />
              </FormControl>

              {topicTag === TopicTag.OTHER ? (
                <TextArea
                  className="mb-4 mt-5"
                  fill
                  value={otherTopicTag}
                  onChange={handleResourceOtherTopicTagChange}
                />
              ) : (
                ""
              )}
              <TextField
                id="filled-multiline-static"
                label="Description"
                multiline
                rows={3}
                value={description}
                onChange={handleResourceDescriptionChange}
                fullWidth
              />
              <div className="action">
                <button
                  onClick={uploadPanelClose}
                  style={{ backgroundColor: "#FFFFFF", color: "#619CD1" }}
                >
                  {intl.formatMessage({ id: "app.titles.cancel" })}
                </button>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button onClick={handleSubmit} disabled={!resourceTitle}>
                  {intl.formatMessage({ id: "app.titles.save" })}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  const UploadResourceURLPanel = () => {
    return (
      <>
        <Dialog
          open={resource?.type === "link" ? true : false}
          onClose={uploadPanelClose}
          className="sidebar-modal small-modal"
        >
          <div className="sidepanel">
            <div className="sidepanel__header">
              <h3>
                {intl.formatMessage({ id: "app.resources.enter-resource-url" })}
              </h3>
              <button onClick={uploadPanelClose}>
                <Icon icon="cross" iconSize={12} />
              </button>
            </div>
            <div className="sidepanel__content enterFileDetails">
              <TextField
                label={
                  <>
                    {intl.formatMessage({
                      id: "app.resources.link.resource-title",
                    })}{" "}
                    <span className="text-red-700">*</span>
                  </>
                }
                id="outlined-size-small"
                // defaultValue="Small"
                size="small"
                value={resourceTitle}
                onChange={handleResourceTitleChange}
                fullWidth
              />

              <TextField
                label={
                  <>
                    {intl.formatMessage({
                      id: "app.resources.link.resource-url",
                    })}{" "}
                    <span className="text-red-700">*</span>
                  </>
                }
                id="outlined-size-small"
                // defaultValue="Small"
                size="small"
                value={resourceUrl}
                onChange={handleResourceUrlChange}
                onBlur={handleResourceUrlUpdate}
                // required
                fullWidth
              />
              <FormControl fullWidth size="small">
                <Select
                  className="z-50"
                  placeholder="Topic Tag"
                  value={topicTagValue}
                  options={topicTagOptions}
                  onChange={handleTopicTagChange}
                />
                {topicTag === TopicTag.OTHER ? (
                  <TextArea
                    className="mb-4 mt-5"
                    fill
                    value={otherTopicTag}
                    onChange={handleResourceOtherTopicTagChange}
                  />
                ) : (
                  ""
                )}
              </FormControl>
              <TextField
                id="filled-multiline-static"
                label="Description"
                value={description}
                onChange={handleResourceDescriptionChange}
                multiline
                rows={3}
                fullWidth
              />
              <div className="action">
                <button
                  onClick={uploadPanelClose}
                  style={{ backgroundColor: "#FFFFFF", color: "#619CD1" }}
                >
                  {intl.formatMessage({ id: "app.titles.cancel" })}
                </button>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button
                  onClick={handleSubmit}
                  disabled={!resourceTitle || !resourceUrl}
                >
                  {intl.formatMessage({ id: "app.titles.save" })}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <div className="teamContent mt-5">
        {filteredData.length === 0 ? (
          <div className="uploadMaterials_box">
            <p>
              {intl.formatMessage(
                { id: "app.message.resources.no-upload-materials" },
                { br: <br /> }
              )}
            </p>
            <div className="uploadMaterials_btns">
              <AddResourcesButtons
                isPlanResources={true}
                onClick={handleResourceUpload}
                attachedToType={
                  isDistrictActivePlan
                    ? AttachedToType.DISTRICT_RESOURCES_WORKSPACE
                    : AttachedToType.SCHOOL_RESOURCES_WORKSPACE
                }
              />
            </div>
            <p>
              {intl.formatMessage({id:"app.resources.add-resources.subheading"},{strong:(chunks)=><strong>{chunks}</strong>})}
            </p>
          </div>
        ) : (
          <div>
            <div className="memberTbl_head">
         <label>Uploaded Materials ({filteredData.length})</label>
         <div className="memberTbl_button">
         <AddResourcesButtons
                isPlanResources={true}
                onClick={handleResourceUpload}
                attachedToType={
                  isDistrictActivePlan
                    ? AttachedToType.DISTRICT_RESOURCES_WORKSPACE
                    : AttachedToType.SCHOOL_RESOURCES_WORKSPACE
                }
              />
         </div>
         </div>
            <EntityTable
              loading={isLoading || isDeleteLoading}
              data={filteredData}
              columns={columns}
              searchString={searchString}
              setSearchString={setSearchString}
              noDataComp={nonIdealState}
              darkHeader
              showTopContainer={false}
              searchPlaceholder={intl.formatMessage({
                id: "app.resources-table.search.text",
              })}
            >
              <Popover
                className="flex items-center"
                content={
                  <div className="p-4">
                    <AddResourcesButtons
                      isVertical
                      onClick={handleResourceUpload}
                      attachedToType={
                        isDistrictActivePlan
                          ? AttachedToType.DISTRICT_RESOURCES_WORKSPACE
                          : AttachedToType.SCHOOL_RESOURCES_WORKSPACE
                      }
                    />
                  </div>
                }
              >
                <Button
                  intent="primary"
                  text={intl.formatMessage({
                    id: "app.resources.upload-resource",
                  })}
                  title={intl.formatMessage({
                    id: "app.resources.upload-resource",
                  })}
                />
              </Popover>
            </EntityTable>
          </div>
        )}
        {UploadFilesPanel()}
        {UploadResourceURLPanel()}
        {/* <UpsertResourceDialog isUploadedFilesTab /> */}
      </div>
    </>
  );
};

export default UploadedFiles;