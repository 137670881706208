import { useMemo } from "react";
import { Plan, SurveyReportRouteParams } from "../../types";
import { matchPath, useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import { AuthRoutes } from "../../App";

export const useReportParams = () => {
  const activePlan = useSelector((s) => s.plans.activePlan);

  const routeParams = useParams<SurveyReportRouteParams>();

  const location = useLocation();

  const isPlanPage = useMemo(
    () => !!matchPath(location.pathname, AuthRoutes.YourPlanReports),
    [location.pathname]
  );

  const plan: Plan | undefined = useMemo(() => {
    return isPlanPage ? activePlan : undefined;
  }, [isPlanPage, activePlan]);

  const selectedDistrictId: number | undefined = useMemo(() => {
    return plan?.district.id
      ? +plan.district.id
      : routeParams?.districtId
      ? +routeParams.districtId
      : undefined;
  }, [plan?.district.id, routeParams?.districtId]);

  const selectedSchoolId: number | undefined = useMemo(() => {
    return plan?.school.id
      ? +plan.school.id
      : routeParams?.schoolId
      ? +routeParams.schoolId
      : undefined;
  }, [plan?.school.id, routeParams?.schoolId]);

  const reportPagePath = useMemo(() => {
    return plan ? AuthRoutes.YourPlanReports : AuthRoutes.SurveyReport;
  }, [plan]);

  return {
    reportPagePath: reportPagePath,

    tabId: routeParams.tabId,
    districtId: selectedDistrictId,
    schoolId: selectedSchoolId,
    surveyDeploymentId: routeParams.surveyDeploymentId
      ? +routeParams.surveyDeploymentId
      : undefined,
    domainId: routeParams.domainId ? +routeParams.domainId : undefined,
    tagId: routeParams.tagId,

    routeParams: routeParams,
  };
};

export default useReportParams;
