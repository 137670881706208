import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CommonTextUpsertDialog from "../dialog/CommonTextUpsertDialog";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CommonText, SurveyDemographic } from "../../../types";
import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
import EntityTable from "../../common/EntityTable";
import NoCommonTexts from "./NoCommonTexts";
import { useLoading } from "../../../helpers/hooks/useLoading";
import {
  deleteCommonText,
  getCommonTexts,
  showCommonTextUpsertDialog,
} from "../../../store/common-text/actions";
import { CellProps } from "react-table";
import { showConfirmDialog } from "../../../store/UIState/actions";
import { AppToaster } from "../../../helpers/toaster";

type OwnProps = {};

type Props = OwnProps;

const CommonTextTable: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommonTexts.request());
  }, []);

  const loading = useSelector((s) => s.commonTexts.loading.getCommonTexts);
  const error = useSelector((s) => s.commonTexts.errors.getCommonTexts);
  useLoading({ loading, error });

  const commonTexts = useSelector((s) => s.commonTexts.commonTexts);

  const deleteLoading = useSelector(
    (s) => s.commonTexts.loading.deleteCommonText
  );
  const deleteError = useSelector((s) => s.commonTexts.errors.deleteCommonText);
  const onDeleteSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.common-text.deleted",
      }),
      icon: "tick",
      intent: "success",
    });
  }, []);
  useLoading({
    loading: deleteLoading,
    error: deleteError,
    onSuccess: onDeleteSuccess,
  });

  const showConfirmDeleteDialog = (commonTextId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteCommonText.request(commonTextId));
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-common-text",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const columns: any[] = useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: "app.titles.common-text" }),
        accessor: "common_text",
        width: "20%",
      },
      ...Object.values(SurveyDemographic).map((key) => ({
        Header: intl.formatMessage({
          id: `app.surveys.survey-demographic.${key}`,
        }),
        Cell: ({ row: { original } }: CellProps<CommonText>) => {
          let questionText = "";
          if (original.questions[key]?.lead_in) {
            questionText += `${original.questions[key]!.lead_in} `;
          }
          questionText += original.questions[key]?.text ?? "";
          return <div>{questionText}</div>;
        },
        width: "20%",
      })),
      {
        id: "actions",
        width: "100%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<CommonText>) => {
          return (
            <div className="flex -ml-1">
              <Button
                className="whitespace-no-wrap m-1"
                title={intl.formatMessage({
                  id: "app.titles.edit",
                })}
                icon="edit"
                intent="primary"
                onClick={() => {
                  dispatch(showCommonTextUpsertDialog(original));
                }}
              />
              <Button
                title={intl.formatMessage({
                  id: "app.surveys-table.columns.delete",
                })}
                icon="trash"
                className="whitespace-no-wrap m-1"
                intent="danger"
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  showConfirmDeleteDialog(original.id)
                }
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const [searchString, setSearchString] = useState<string>("");

  const callbackFilter = (commonText: CommonText) => {
    const searchStringLowerCase = searchString.trim().toLowerCase();

    if (!searchStringLowerCase) {
      return true;
    }

    return commonText.common_text.toLowerCase().includes(searchStringLowerCase);
  };

  const data = useMemo(() => commonTexts.filter(callbackFilter), [
    commonTexts,
    searchString,
  ]);

  const handleDialogOpen = () => {
    dispatch(showCommonTextUpsertDialog());
  };

  return (
    <>
      <EntityTable
        data={data}
        columns={columns}
        searchString={searchString}
        setSearchString={setSearchString}
        noDataComp={<NoCommonTexts />}
        loading={loading}
      >
        <Popover
          position={"bottom-left"}
          className="flex-grow justify-start"
          content={
            <Menu>
              <MenuItem
                text={intl.formatMessage({
                  id: "app.common-text.create-new",
                })}
                icon="plus"
                className="px-3 capitalize"
                onClick={handleDialogOpen}
              />
            </Menu>
          }
          minimal
        >
          <Button
            large
            minimal
            title={intl.formatMessage({ id: "app.titles.common-texts" })}
            icon={"properties"}
            text={intl.formatMessage({ id: "app.titles.common-texts" })}
            intent="primary"
            className="font-bold px-4"
          />
        </Popover>
      </EntityTable>
      <CommonTextUpsertDialog />
    </>
  );
};

export default CommonTextTable;
