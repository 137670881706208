import React, { FunctionComponent, useMemo } from "react";
import { Switch } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Locale } from "../../../store/UIState";
import { useIntl } from "react-intl";
import { SurveyDemographic } from "../../../types";

type OwnProps = {
  domainId: number;
  checked: boolean;
  onChange: (value: boolean) => void;
};

type Props = OwnProps;

const NaOptionSwitch: FunctionComponent<Props> = (props) => {
  const { checked, domainId, onChange } = props;

  const intl = useIntl();

  const domains = useSelector((s) => s.domains.domains);
  const domainAnswerSetHasNaOption = useMemo(() => {
    const domain = domains.find((d) => d.id === domainId);

    if (domain) {
      return _.chain(domain.domain_answer)
        .pick(Object.values(SurveyDemographic))
        .toPairs()
        .some(([demographicKey, localesAnswers]: any) => {
          return _.chain(localesAnswers?.[Locale.English])
            .keys()
            .includes("-1")
            .value();
        })
        .value();
    }

    return false;
  }, [domains, domainId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    onChange(checked);
  };

  if (!domainAnswerSetHasNaOption) {
    return null;
  }

  return (
    <Switch
      large
      checked={checked}
      label={intl.formatMessage({
        id: "app.titles.not-applicable.allow-as-option",
      })}
      className="mb-0 mx-2 flex-1"
      onChange={handleChange}
    />
  );
};

export default NaOptionSwitch;
