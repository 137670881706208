import { Epic } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getMeetingTemplatesEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getMeetingTemplates.request)),
    switchMap((action) =>
      from(
        api.meetingTemplates.getMeetingTemplates(
          action.payload.type,
          action.payload.showHidden
        )
      ).pipe(
        map(actions.getMeetingTemplates.success),
        catchError((error) => of(actions.getMeetingTemplates.failure(error)))
      )
    )
  );
};

export const addMeetingTemplateEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addMeetingTemplate.request)),
    switchMap((action) =>
      from(api.meetingTemplates.addMeetingTemplate(action.payload)).pipe(
        map(actions.addMeetingTemplate.success),
        catchError((error) => of(actions.addMeetingTemplate.failure(error)))
      )
    )
  );
};

export const updateMeetingTemplateEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updateMeetingTemplate.request)),
    switchMap((action) =>
      from(
        api.meetingTemplates.updateMeetingTemplate(
          action.payload.id,
          action.payload.body
        )
      ).pipe(
        map(actions.updateMeetingTemplate.success),
        catchError((error) => of(actions.updateMeetingTemplate.failure(error)))
      )
    )
  );
};

export const deleteMeetingTemplateEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteMeetingTemplate.request)),
    switchMap((action) =>
      from(api.meetingTemplates.deleteMeetingTemplate(action.payload)).pipe(
        map(() => actions.deleteMeetingTemplate.success(action.payload)),
        catchError((error) => of(actions.deleteMeetingTemplate.failure(error)))
      )
    )
  );
};
