import { createAction, createAsyncAction } from "typesafe-actions";
import { DataPoint, Dataset, ViewDataSetDialogType } from "../../types";
import {
  AddCustomDatasetRequest,
  AddDataPointToCustomDatasetRequest,
  DeleteCustomDatasetRequest,
  GetDatasetsRequest,
  UpdateDataPointInCustomDatasetRequest,
} from "../../api/data-sets/types";
import { ParseResult } from "papaparse";

export const showCreateCustomDataSetDialog = createAction(
  "@data-sets/SHOW_CREATE_CUSTOM_DATA_SET_DIALOG"
)<void>();
export const hideCreateCustomDataSetDialog = createAction(
  "@data-sets/HIDE_CREATE_CUSTOM_DATA_SET_DIALOG"
)<void>();

export const showCreateDatasetFromFileDialog = createAction(
  "@data-sets/SHOW_CREATE_DATASET_FROM_FILE_DIALOG"
)<ParseResult<any>>();
export const hideCreateDatasetFromFileDialog = createAction(
  "@data-sets/HIDE_CREATE_DATASET_FROM_FILE_DIALOG"
)<void>();

export const showUploadCsvDialog = createAction(
  "@data-sets/SHOW_UPLOAD_CSV_DIALOG_DIALOG"
)<void>();
export const hideUploadCsvDialog = createAction(
  "@data-sets/HIDE_UPLOAD_CSV_DIALOG_DIALOG"
)<void>();

export const getDatasets = createAsyncAction(
  "@data-sets/GET_DATA_SETS_REQUEST",
  "@data-sets/GET_DATA_SETS_SUCCESS",
  "@data-sets/GET_DATA_SETS_FAILURE"
)<GetDatasetsRequest | void, Dataset[], Error>();

export const createCustomDataset = createAsyncAction(
  "@data-sets/CREATE_CUSTOM_SET_REQUEST",
  "@data-sets/CREATE_CUSTOM_SET_SUCCESS",
  "@data-sets/CREATE_CUSTOM_SET_FAILURE"
)<AddCustomDatasetRequest, Dataset, Error>();

export const deleteCustomDataset = createAsyncAction(
  "@data-sets/DELETE_CUSTOM_DATASET_REQUEST",
  "@data-sets/DELETE_CUSTOM_DATASET_SUCCESS",
  "@data-sets/DELETE_CUSTOM_DATASET_FAILURE"
)<DeleteCustomDatasetRequest, number, Error>();

export const addDataPointToCustomDataset = createAsyncAction(
  "@data-sets:data-points/ADD_DATA_POINT_TO_CUSTOM_DATASET_REQUEST",
  "@data-sets:data-points/ADD_DATA_POINT_TO_CUSTOM_DATASET_SUCCESS",
  "@data-sets:data-points/ADD_DATA_POINT_TO_CUSTOM_DATASET_FAILURE"
)<
  AddDataPointToCustomDatasetRequest,
  { dataPoint: DataPoint; datasetId: number; planId: number },
  Error
>();

export const showViewDataSetDialog = createAction(
  "@data-sets/SHOW_VIEW_DATA_SET_DIALOG"
)<ViewDataSetDialogType>();
export const hideViewDataSetDialog = createAction(
  "@data-sets/HIDE_VIEW_DATA_SET_DIALOG"
)<void>();

export const deleteDataPointFromCustomDataset = createAsyncAction(
  "@data-sets:data-points/DELETE_DATA_POINT_FROM_CUSTOM_DATASET_REQUEST",
  "@data-sets:data-points/DELETE_DATA_POINT_FROM_CUSTOM_DATASET_SUCCESS",
  "@data-sets:data-points/DELETE_DATA_POINT_FROM_CUSTOM_DATASET_FAILURE"
)<
  {
    planId: number;
    datasetId: number;
    dataPointId: number;
    onSuccess?: Function;
  },
  {
    datasetId: number;
    dataPointId: number;
  },
  Error
>();

export const updateDataPointInCustomDataset = createAsyncAction(
  "@data-sets:data-points/UPDATE_DATA_POINT_IN_CUSTOM_DATASET_REQUEST",
  "@data-sets:data-points/UPDATE_DATA_POINT_IN_CUSTOM_DATASET_SUCCESS",
  "@data-sets:data-points/UPDATE_DATA_POINT_IN_CUSTOM_DATASET_FAILURE"
)<
  {
    datasetId: number;
    body: UpdateDataPointInCustomDatasetRequest;
    onSuccess?: Function;
  },
  { datasetId: number; dataPoint: DataPoint },
  Error
>();

export const showAddDataPointDialog = createAction(
  "@data-sets/SHOW_ADD_DATA_POINT_DIALOG"
)<DataPoint | undefined>();

export const hideAddDataPointDialog = createAction(
  "@data-sets/HIDE_ADD_DATA_POINT_DIALOG"
)<void>();

export const upsertDataset = createAction(
  "@data-sets/UPSERT_DATASET"
)<Dataset>();
