import { DistrictSetupTaskKey, TourDictionary } from "../../types";
import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import {
  getBooleanFromLocalStorage,
  getObjectFromLocalStorage,
  LocalStorageKeys,
} from "../../constants";
import _ from "lodash";

export type ToursState = {
  selectedDistrictSetupTaskKey?: DistrictSetupTaskKey;
  districtSetupState: TourDictionary;
  setupCompleted?: boolean;
  toursLocalStorageKey: string;

  showWorkspaceTour?: boolean;
  showTourThisWorkspaceTour?: boolean;
};

const initialState: ToursState = {
  districtSetupState: {},
  toursLocalStorageKey: "",
};

export const toursReducer = createReducer<ToursState, RootAction>(initialState)
  .handleAction(
    actions.initDistrictSetupState,
    (state, action): ToursState => {
      const currentUser = action.payload.me;
      const localStorageKey = `${LocalStorageKeys.DistrictSetupWidget}:${action.payload.plan.district.id}_${currentUser?.id}`;
      return {
        ...state,
        districtSetupState: state.districtSetupState ?? {},
       // districtSetupState: getObjectFromLocalStorage(localStorageKey) ?? {}, ////old code: it was getting blank to disctrict setup state
        setupCompleted: currentUser?.profile?.is_setup_completed,
        toursLocalStorageKey: localStorageKey,
      };
    }
  )
  .handleAction(
    actions.hideDistrictSetupWidget,
    (state, action): ToursState => {
      const updatedState = {
        ...state,
        districtSetupState: {
          ...state.districtSetupState,
          hidden: true,
        },
        setupCompleted: true,
        showTourThisWorkspaceTour: true,
      };
      localStorage.setItem(
        state.toursLocalStorageKey,
        JSON.stringify(updatedState.districtSetupState)
      );
      return updatedState;
    }
  )
  .handleAction(
    actions.markStepsCompleted,
    (state, action): ToursState => {
      let updatedDictionary: any = state.districtSetupState;
      action.payload.keys.forEach((key) => (updatedDictionary[key] = true));
      localStorage.setItem(
        state.toursLocalStorageKey,
        JSON.stringify(updatedDictionary)
      );
      return {
        ...state,
        districtSetupState: updatedDictionary,
      };
    }
  )
  .handleAction(
    actions.markStepCompleted,
    (state, action): ToursState => {
      const updatedDictionary = {
        ...state.districtSetupState,
        [action.payload.key]:
          action.payload.schoolId &&
          [
            DistrictSetupTaskKey.InviteSchoolStaff,
            DistrictSetupTaskKey.SetupSchoolCalendar,
          ].includes(action.payload.key)
            ? {
                ...(_.isPlainObject(
                  state.districtSetupState[action.payload.key]
                )
                  ? (state.districtSetupState[action.payload.key] as any)
                  : {}),
                [action.payload
                  .schoolId]: DistrictSetupTaskKey.InviteSchoolStaff
                  ? {
                      completed: !action.payload.softAdd,
                      softAddStaff: action.payload.softAdd,
                    }
                  : true,
              }
            : !action.payload.softAdd, // need to display callout on TeamHomeTab without moving further for district plan
        softAddDistrictStaff:
          action.payload.key === DistrictSetupTaskKey.InviteOtherMembers
            ? action.payload.softAdd
            : state.districtSetupState?.softAddDistrictStaff,
      };
      localStorage.setItem(
        state.toursLocalStorageKey,
        JSON.stringify(updatedDictionary)
      );
      return {
        ...state,
        districtSetupState: updatedDictionary,
      };
    }
  )
  .handleAction(
    actions.selectDistrictSetupTaskKey,
    (state, action): ToursState => ({
      ...state,
      selectedDistrictSetupTaskKey: action.payload,
    })
  )
  .handleAction(
    actions.updateMakingProgressTourTimeWatched,
    (state, action): ToursState => {
      const updatedDictionary = {
        ...state.districtSetupState,
        makingProgressTourTimeWatched: action.payload,
      };
      localStorage.setItem(
        state.toursLocalStorageKey,
        JSON.stringify(updatedDictionary)
      );
      return {
        ...state,
        districtSetupState: updatedDictionary,
      };
    }
  )
  .handleAction(
    actions.completeSetup,
    (state, action): ToursState => {
      const updatedState = {
        ...state,
        districtSetupState: {
          ...state.districtSetupState,
          lastTourWatched: true,
        },
      };
      localStorage.setItem(
        state.toursLocalStorageKey,
        JSON.stringify(updatedState.districtSetupState)
      );
      return updatedState;
    }
  )
  .handleAction(
    actions.showWorkspaceTour,
    (state, action): ToursState => ({
      ...state,
      showWorkspaceTour: true,
    })
  )
  .handleAction(
    actions.hideWorkspaceTour,
    (state, action): ToursState => ({
      ...state,
      showWorkspaceTour: undefined,
    })
  )
  .handleAction(
    actions.hideTourThisWorkspaceTour,
    (state, action): ToursState => ({
      ...state,
      showTourThisWorkspaceTour: false,
    })
  );
