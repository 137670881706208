import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const MeanContent: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  return (
    <div>
      {intl.formatMessage({
        id: "app.survey-report.guide.mean",
      })}
    </div>
  );
};

export default MeanContent;
