import React, { FunctionComponent, useMemo, useState } from "react";
import { District } from "../../../types";
import { CellProps } from "react-table";
import { useIntl } from "react-intl";
import { Button, Icon, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import { showDistrictDialog } from "../../../store/districts/actions";
import { generatePath, useHistory } from "react-router";
import EntityTable from "../../common/EntityTable";
import { AdminRoutes } from "../../../App";
import { DistrictDetailsTabs } from "../../pages/DistrictDetails";
import moment from "moment";
import _ from "lodash";

type OwnProps = {
  districts: District[];
};

type Props = OwnProps;

const DistrictsTable: FunctionComponent<Props> = (props) => {
  const { districts } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const columns: any[] = useMemo(
    () => [
      {
        Header: intl.formatMessage({ id: "app.common-table.columns.name" }),
        accessor: "name",
        width: "100%",
      },
      {
        Header: intl.formatMessage({
          id: "app.district-table.superintendent-email",
        }),
        accessor: "superintendent.email",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<District>) => {
          return (
            <div className="flex justify-center">
              {original.superintendent?.email ??
                intl.formatMessage({ id: "app.titles.not-applicable" })}
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.districts-table.is-single-school-district",
        }),
        accessor: "is_single_school_district",
        width: "100%",
        Cell: ({ row: { original } }: CellProps<District>) => {
          return (
            <div className="flex justify-center">
              <Icon
                icon={
                  original.is_single_school_district ? "tick-circle" : "cross"
                }
              />
            </div>
          );
        },
      },
      {
        id: "actions",
        width: "100%",
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({ row: { original } }: CellProps<District>) => {
          return (
            <div className="flex justify-center -ml-1">
              <Button
                className="whitespace-no-wrap m-1"
                title={intl.formatMessage({
                  id: "app.titles.edit",
                })}
                icon="edit"
                intent="primary"
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  e.stopPropagation();
                  dispatch(showDistrictDialog(original));
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const [searchString, setSearchString] = useState<string>("");

  const callbackFilter = (district: District) => {
    const searchStringLowerCase = searchString.trim().toLowerCase();

    if (!searchStringLowerCase) {
      return true;
    }

    return district.name?.toLowerCase()?.includes(searchStringLowerCase);
  };

  const data = useMemo(() => districts.filter(callbackFilter), [
    districts,
    searchString,
  ]);

  const handleDialogOpen = () => {
    dispatch(showDistrictDialog(undefined));
  };

  const handleRowClick = (entity: District) => {
    history.push(
      generatePath(AdminRoutes.DistrictDetails, {
        districtId: entity?.id,
        tabId: DistrictDetailsTabs.SCHOOLS,
      })
    );
  };

  const handleCsvDownload = () => {
    let updatedData: any = [];

    districts.forEach((dist: any) => {
      let distSch: any = [];
      if (dist.school.length > 0) {
        dist.school.forEach((school: any) => {
          const _obj = { district_name: dist.name, school_name: school.name };
          distSch.push(_obj);
        });
      } else {
        const _obj = { district_name: dist.name, school_name: "" };
        distSch.push(_obj);
      }

      updatedData = [...updatedData, ...distSch];
    });

    const header = ["District Name", "School Name"];
    const dataRows = updatedData.map((u: any) =>
      _.chain(u)
        .pick(["district_name", "school_name"])
        .values()
        .map((val: string) => {
          if (val && val.toString().indexOf(",") >= 0) {
            return `"${val.toString().replaceAll('"', '\\"')}"`;
          } else {
            return val;
          }
        })
        .join(",")
    );

    const csvStr = [header, ...dataRows].join("\n");
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute(
      "download",
      `NJ SCI Districts Details Export ${moment().format("MM/DD/YYYY")}`
    );
    link.href = csvUrl;
    document.body.append(link);
    link.click();
    link.remove();
  };

  return (
    <div>
      <button
        className="bp3-button bp3-intent-primary mb-1 "
        onClick={handleCsvDownload}
      >
        {intl.formatMessage({ id: "app.titles.download-as-CSV" })}
      </button>
      <EntityTable
        data={data}
        columns={columns}
        searchString={searchString}
        setSearchString={setSearchString}
        onRowClick={handleRowClick}
      >
        <Popover
          position={"bottom-left"}
          className="flex-grow justify-start"
          content={
            <Menu>
              <MenuItem
                text={intl.formatMessage({
                  id: "app.districts.create-new-district",
                })}
                icon="plus"
                className="px-3 capitalize"
                onClick={handleDialogOpen}
              />
            </Menu>
          }
          minimal
        >
          <Button
            large
            minimal
            title={intl.formatMessage({ id: "app.titles.districts" })}
            icon={"properties"}
            text={intl.formatMessage({ id: "app.titles.districts" })}
            intent="primary"
            className="font-bold px-4"
          />
        </Popover>
      </EntityTable>
    </div>
  );
};

export default DistrictsTable;
