import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useLockButton from "../../helpers/hooks/useLockButton";
import useFormInputField from "../../helpers/hooks/useFormInputField";
import { Helmet } from "react-helmet";
import { Button, Card, FormGroup } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../App";
import { restorePassword } from "../../store/auth/actions";
import { AppToaster } from "../../helpers/toaster";
import { useLoading } from "../../helpers/hooks/useLoading";

type OwnProps = {};

type Props = OwnProps;

const RestorePasswordPage: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const params = useParams<{ code: string }>();
  const pattern = /^(?=.*[0-9])(?=.*[A-Za-z])([A-Za-z\d\w\W]{8,})$/;

  const loading = useSelector((s) => s.auth.loading.restorePassword);
  const error = useSelector((s) => s.auth.errors.restorePassword);
  const onSuccess = () => {
    AppToaster.show({
      icon: "tick",
      intent: "success",
      message: intl.formatMessage({
        id: "app.toaster.restore-password-form.password-restored",
      }),
      timeout: 5000,
    });
  };
  useLoading({ loading, error, onSuccess });

  const [lockButton, showPassword] = useLockButton();
  const [confirmLockButton, showConfirmPassword] = useLockButton();
  const [isPasswordsEqual, setPasswordsEqual] = useState(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState(true);
  const [isValidForm, setIsValidForm] = useState(true);

  const [password, passwordInputField] = useFormInputField({
    required: true,
    intent: isPasswordsEqual ? "none" : "danger",
    type: showPassword ? "text" : "password",
    rightElement: lockButton as JSX.Element,
    id: "password",
  });
  const [confirmPassword, confirmPasswordInputField] = useFormInputField({
    required: true,
    intent: isPasswordsEqual ? "none" : "danger",
    type: showConfirmPassword ? "text" : "password",
    rightElement: confirmLockButton as JSX.Element,
    id: "confirmPassword",
  });

  useEffect(() => {
    setPasswordsEqual(password === confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    password &&
    setIsPasswordsValid(pattern.test(password));
  }, [password]);

  useEffect(() => {
    isPasswordsValid && isPasswordsEqual ? 
    setIsValidForm(true):setIsValidForm(false);
  },[isPasswordsValid,isPasswordsEqual]);

  const errorMessage: JSX.Element = (
    <div className="pb-3">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.not-equal-to-confirm-password",
        })}
      </strong>
    </div>
  );
  
  const errorPasswordValid: JSX.Element = (
    <div className="pb-3">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.invalid-password",
        })}
      </strong>
    </div>
  );

  const changePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      restorePassword.request({
        request: {
          restore_password: password as string,
          restore_password_confirm: confirmPassword as string,
        },
        code: params.code,
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "app.titles.change-password" })}
        </title>
      </Helmet>
      <div className="flex justify-center items-center">
        <Card style={{ minWidth: "38rem" }}>
          <b className="text-2xl flex justify-center">
            {intl.formatMessage({ id: "app.forms.change-password-form.title" })}
          </b>
          <form className="mt-4" onSubmit={changePassword}>
            <FormGroup
              label={intl.formatMessage({
                id: "app.forms.registration-form.password",
              })}
            >
              {passwordInputField}
            </FormGroup>
            <FormGroup
              label={intl.formatMessage({
                id: "app.forms.registration-form.confirm-password",
              })}
            >
              {confirmPasswordInputField}
            </FormGroup>
            
            {!isPasswordsValid && errorPasswordValid}
            {isPasswordsValid && !isPasswordsEqual && errorMessage}

            <Button disabled={!isValidForm} type={"submit"} intent="primary" fill loading={loading}>
              {intl.formatMessage({
                id:
                  "app.forms.change-password-form.change-password-button-text",
              })}
            </Button>

            <div className="text-center mt-2">
              <Link to={NonAuthRoutes.Login}>
                {intl.formatMessage({
                  id: "app.titles.login",
                })}
              </Link>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default RestorePasswordPage;
