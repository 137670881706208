import React, { useEffect, useMemo } from "react";
import {
  ResponseItemType,
  ResponseRatesByDemographic,
  SurveyDemographic,
} from "../../../types";
import { useIntl } from "react-intl";
import ClusterTagsGrid from "../tab/cluster-tags-tab/ClusterTagsGrid";
import { Locale, LocaleDisplayedValues } from "../../../store/UIState";



type OwnProps = {
  demographic: SurveyDemographic;
  // groupingKey: SurveyResponseRatesKeys;
  // responses?: ResponseItemType[];
  ratesByDemographic: ResponseRatesByDemographic;
};

type Props = OwnProps;

const LOW_BORDER = 0;
const HIGH_BORDER = 10;
const NOT_SPECIFIED = "Not Specified";

const ResponseRatesTable: React.FC<Props> = (props: Props) => {
  const { demographic, ratesByDemographic } = props;

  const intl = useIntl();

  const displayedData: ResponseItemType[] = useMemo(() => {
    if (ratesByDemographic.results?.length) {
      return ratesByDemographic.results.map((i) =>
        i.label === "Overall" ? i :
          i.count > LOW_BORDER && i.count < HIGH_BORDER
            ? {
                ...i,
                count: (
                  <i>
                    {intl.formatMessage({
                      id: "app.response-rates.1-10-respondents",
                    })}
                  </i>
                ),
              }
          : i
      );
    }

    return [];
  }, [ratesByDemographic.results, intl]);

  const sortedDisplayedData: ResponseItemType[] = useMemo(() => {
    let notSpecifiedRecord: ResponseItemType | undefined;
    const sortedData = displayedData.filter(et => et.label != NOT_SPECIFIED );
    notSpecifiedRecord = displayedData.find(et => et.label == NOT_SPECIFIED);
    sortedData.push(notSpecifiedRecord!)
   
    if (notSpecifiedRecord == undefined){
      return displayedData;
    }
    return sortedData 
   },[displayedData]
  )

  const { groupName, note } = useMemo(() => {
    const groupName =
      ratesByDemographic.title === "overall"
        ? intl.formatMessage({
            id: `app.filters.respondent-type.${demographic}`,
          })
        : intl.formatMessage({
            id: `app.filters.response-rating-key.${ratesByDemographic.title}`,
          });
             
    return {
      groupName,
      note: ratesByDemographic.note,
    };
  }, [ratesByDemographic.title, ratesByDemographic.note, demographic]);

  return (
    <table className="w-full mt-2 border-2 border-gray-600">
      <thead>
        <tr className="bg-gray-700">
          <th className="p-2 text-white font-normal" colSpan={2}>
            <div>{groupName}</div>
            {!!note && <div className="italic text-sm mt-2">{note}</div>}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedDisplayedData.filter((item)=>((groupName === intl.formatMessage({
            id: "app.filters.response-rating-key.language",
          })
        && demographic === SurveyDemographic.SchoolStaff) ? item.label === LocaleDisplayedValues[Locale.English] ? LocaleDisplayedValues[Locale.English] : '': item.label)).map((item, i) => (
          <tr key={i}>
            <td className="text-center w-1/2 bg-gray-500 py-2 border-b-2 border-gray-600">
            {item.label}
            </td>
            <td className="text-center w-1/2 bg-gray-400 py-2 border-b-2 border-gray-600">
              {item.count}
            </td>
          </tr>
       
       ))} 
      </tbody>
    </table>
  );
};

export default ResponseRatesTable;
