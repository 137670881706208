import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import Axios from "axios";
import { getBooleanFromLocalStorage, LocalStorageKeys } from "../../constants";
import { ConfirmDialogConfig } from "../../types";
import {
  filterLanguages,
  hideConfirmDialog,
  hideLanguageSelector,
  showConfirmDialog,
  showFeedbackDialog,
} from "./actions";
import { SessionStorageKeys } from "../../constants/session-storage";

export enum Locale {
  English = "en",
  Spanish = "es",
  BrazilianPortuguese = "pt",
  HaitianCreole = "ht",
  Arabic = "ar",
  Afrikaans = "af",
  Albanian = "sq",
  Amharic = "am",
  Armenian = "hy",
  Bengali = "bn",
  Dari = "prs",
  Estonian = "et",
  Georgian = "ka",
  German = "de",
  Greek = "el",
  Gujarati = "gu",
  Hebrew = "he",
  Hindi = "hi",
  Japanese = "ja",
  Kiche = "quc",
  Korean = "ko",
  Malayalam = "ml",
  Polish = "pl",
  Russian = "ru",
  SimplifiedChinese = "zh",
  Tagalog = "tl",
  Tamil = "ta",
  Telugu = "te",
  TraditionalChinese = "zh-CH",
  Turkish = "tr",
  Ukrainian = "uk",
  Urdu = "ur",
  Vietnamese = "vi",
}

export const LocaleEnglishValues = {
  [Locale.English]: "English",
  [Locale.Spanish]: "Spanish",
  [Locale.BrazilianPortuguese]: "Brazilian Portuguese",
  [Locale.HaitianCreole]: "Haitian Creole",
  [Locale.Arabic]: "Arabic",
  [Locale.Afrikaans]: "Afrikaans",
  [Locale.Albanian]: "Albanian",
  [Locale.Amharic]: "Amharic",
  [Locale.Armenian]: "Armenian",
  [Locale.Bengali]: "Bengali",
  [Locale.Dari]: "Dari",
  [Locale.Estonian]: "Estonian",
  [Locale.Georgian]: "Georgian",
  [Locale.German]: "German",
  [Locale.Greek]: "Greek",
  [Locale.Gujarati]: "Gujarati",
  [Locale.Hebrew]: "Hebrew",
  [Locale.Hindi]: "Hindi",
  [Locale.Japanese]: "Japanese",
  [Locale.Kiche]: "K'iche",
  [Locale.Korean]: "Korean",
  [Locale.Malayalam]: "Malayalam",
  [Locale.Polish]: "Polish",
  [Locale.Russian]: "Russian",
  [Locale.SimplifiedChinese]: "Simplified Chinese",
  [Locale.Tagalog]: "Tagalog",
  [Locale.Tamil]: "Tamil",
  [Locale.Telugu]: "Telugu",
  [Locale.TraditionalChinese]: "Traditional Chinese",
  [Locale.Turkish]: "Turkish",
  [Locale.Ukrainian]: "Ukrainian",
  [Locale.Urdu]: "Urdu",
  [Locale.Vietnamese]: "Vietnamese",

}

export const LocaleDisplayedValues = {
  [Locale.English]: "English",
  [Locale.Spanish]: "Español",
  [Locale.BrazilianPortuguese]: "Portuguese",
  [Locale.HaitianCreole]: "Kreyòl Ayisyen",
  [Locale.Arabic]: "عربى",
  [Locale.Afrikaans]: "Afrikaans",
  [Locale.Albanian]: "Shqip",
  [Locale.Amharic]: "አማርኛ",
  [Locale.Armenian]: "Հայերեն",
  [Locale.Bengali]: "বাঙালি",
  [Locale.Dari]: "دری",
  [Locale.Estonian]: "Eesti Keel",
  [Locale.Georgian]: "ქართული",
  [Locale.German]: "Deutsch",
  [Locale.Greek]: "Ελληνικά",
  [Locale.Gujarati]: "ગુજરાતી",
  [Locale.Hebrew]: "עברית",
  [Locale.Hindi]: "हिन्दी",
  [Locale.Japanese]: "日本語",
  [Locale.Kiche]: "K'iche",
  [Locale.Korean]: "한국어",
  [Locale.Malayalam]: "മലയാളം",
  [Locale.Polish]: "Polski",
  [Locale.Russian]: "Русский",
  [Locale.SimplifiedChinese]: "简体中文",
  [Locale.Tagalog]: "Tagalog",
  [Locale.Tamil]: "தமிழ்",
  [Locale.Telugu]: "తెలుగు",
  [Locale.TraditionalChinese]: "繁體中文",
  [Locale.Turkish]: "Türkçe",
  [Locale.Ukrainian]: "Українська",
  [Locale.Urdu]: "اردو",
  [Locale.Vietnamese]: "tiếng Việt",
};

export type UIState = {
  hideLanguageSelector?: boolean;
  menuCollapsed: boolean;
  locale: Locale;
  surveyLocale: Locale;

  showLanguageAlert?: boolean;

  showFeedbackDialog?: boolean;

  filteredLanguages: Locale[];

  confirmDialog: ConfirmDialogConfig;
};

export type UIStateActions = ActionType<typeof import("./actions")>;

const initialState: UIState = {
  menuCollapsed: getBooleanFromLocalStorage(LocalStorageKeys.MenuCollapsed),
  locale:
    (localStorage.getItem(LocalStorageKeys.Locale) as Locale) || Locale.English,
  surveyLocale:
    (sessionStorage.getItem(SessionStorageKeys.Locale) as Locale) ||
    Locale.English,

  filteredLanguages: Object.values(Locale),

  hideLanguageSelector: true,

  confirmDialog: {},
};

export const UIStateReducer = createReducer<UIState, UIStateActions>(
  initialState
)
  .handleAction(actions.setMenuCollapsed, (state, action) => {
    localStorage.setItem(
      LocalStorageKeys.MenuCollapsed,
      JSON.stringify(action.payload)
    );
    return { ...state, menuCollapsed: action.payload };
  })
  .handleAction(actions.changeLocale, (state, action) => {
    Axios.defaults.headers.common["Accept-Language"] = action.payload.locale;
    Axios.defaults.headers.common["x-accept-language"] = action.payload.locale;
    if (action.payload.storageType === "localStorage") {
      localStorage.setItem(LocalStorageKeys.Locale, action.payload.locale);
      return {
        ...state,
        locale: action.payload.locale,
      };
    } else {
      sessionStorage.setItem(SessionStorageKeys.Locale, action.payload.locale);
      return {
        ...state,
        surveyLocale: action.payload.locale,
      };
    }
  })
  .handleAction(
    showConfirmDialog,
    (state, action): UIState => ({
      ...state,
      confirmDialog: action.payload,
    })
  )
  .handleAction(
    hideConfirmDialog,
    (state, action): UIState => ({
      ...state,
      confirmDialog: {},
    })
  )
  .handleAction(
    filterLanguages,
    (state, action): UIState => ({
      ...state,
      filteredLanguages: Object.values(Locale).filter((locale) =>
        action.payload?.some((item) => item === locale)
      ),
    })
  )
  .handleAction(
    hideLanguageSelector,
    (state, action): UIState => ({
      ...state,
      hideLanguageSelector: action.payload,
    })
  )
  .handleAction(
    actions.resetSurveyLanguage,
    (state, action): UIState => {
      Axios.defaults.headers.common["Accept-Language"] = state.locale;
      Axios.defaults.headers.common["x-accept-language"] = state.locale;
      sessionStorage.setItem(SessionStorageKeys.Locale, Locale.English);
      return {
        ...state,
        surveyLocale: Locale.English,
      };
    }
  )
  .handleAction(
    actions.showFeedbackDialog,
    (state, action): UIState => ({
      ...state,
      showFeedbackDialog: true,
    })
  )
  .handleAction(
    actions.hideFeedbackDialog,
    (state, action): UIState => ({
      ...state,
      showFeedbackDialog: false,
    })
  );
