import React, { FunctionComponent, useMemo } from "react";
import { calculateDatasetScore, makeFullQuestionName } from "../../utils";
import { BookmarksLevelInfoInfo } from "../item-level-info-and-bookmarks-dialog/useBookmarksLevelInfo";
import BookmarksIcons from "../explore-strengths-and-needs-tab/BookmarksIcons";
import DatasetTitle from "./DatasetTitle";
import _ from "lodash";
import BookmarksInfoTable, {
  BookmarksInfoTableItem,
} from "./BookmarksInfoTable";
import { useSelector } from "react-redux";
import { useInsightTitle } from "../../../surveys/tab/insights/useInsightTitle";
import { useIntl } from "react-intl";
import BookmarksInfoSubGroupTable from "./BookmarksInfoSubGroupTable";

type OwnProps = {
  items: BookmarksLevelInfoInfo[];
  isQuestionLevel?: boolean;
};

type Props = OwnProps;

const BookmarksInfoWrapperTable: FunctionComponent<Props> = (props) => {
  const { items, isQuestionLevel } = props;

  const intl = useIntl();

  const { meanScore } = useSelector(
    (s) => s.needs.dialogs.itemLevelInfoAndBookmarksDialog
  );
  const sdid = useSelector((s) => s.needs.surveyDeploymentId);
  const { generateInsightTitle } = useInsightTitle({} as any);

  const sortedItems: BookmarksInfoTableItem[] = useMemo(() => {
    return _.chain(items)
      .map((item) => {
        let title: JSX.Element = (
          <DatasetTitle
            dataset={item.dataset}
            hideDemographic={true}
          />
        );

        const dataSetTitle = _.chain(item.dataset)
          .pick(
            "direct_instruction",
            "gender",
            "grade",
            "race",
            "remote_learning",
            "staff_role",
            "reduced_lunch",
            "has_iep",
            "english_at_home",
            "demographic"
          )
          .toPairs()
          .filter(([key, value]) => value != null)
          .map(([key, value]) => generateInsightTitle(key, value as string))
          .join("; ")
          .value();

        return {
          title: title,

          bookmarksPanel: (
            <BookmarksIcons negative={item.negative} positive={item.positive} />
          ),
          score:
            !isQuestionLevel &&
              intl
                .formatMessage({
                  id: `app.filters.respondent-type.${item.dataset.demographic}`,
                })
                .includes(dataSetTitle)
              ? meanScore
              : calculateDatasetScore(item.dataset, sdid),
          item:
            isQuestionLevel && item.dataset.question
              ? makeFullQuestionName(item.dataset.question, true)
              : undefined,
          deleted_in_njsci_v2: item.dataset.question
            ? item.dataset.question.deleted_in_njsci_v2
            : false,
          itemTitle: dataSetTitle
        };
      })
      .filter((item) => (isQuestionLevel ? item.score : true))
      .orderBy((item) => item.score, ["desc"])
      .value();
  }, [items, isQuestionLevel]);

  return (
    <>
      <BookmarksInfoTable isQuestionLevel={isQuestionLevel} items={sortedItems} />
      <BookmarksInfoSubGroupTable isQuestionLevel={isQuestionLevel} items={sortedItems} />
    </>
  );
};

export default BookmarksInfoWrapperTable;
