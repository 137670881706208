/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './ChangePassword.scss';
import { updatePassword } from '../../../../store/profile/actions';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
type OwnProps = {
    handleClose: () => void;
};
type Props = OwnProps;
const pattern = /^(?=.*[0-9])(?=.*[A-Za-z])([A-Za-z\d\w\W]{8,})$/;


const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  

const ChangePassword: React.FC<Props> = (props) => {
    const { handleClose } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const me = useSelector((s) => s.auth.authenticatedUser);

    // STATES
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);

    const [isEmailPasswordEqual, setIsEmailPasswordEqual] = useState(true);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordsEqual, setPasswordsEqual] = useState(true);
    const [isPasswordsValid, setIsPasswordsValid] = useState(true);
    const [isValidForm, setIsValidForm] = useState(true);

    const changePasswordClickOpen = () => {
        setIsChangePasswordOpen(true);
    };

    const changePasswordClose = () => {
        setIsChangePasswordOpen(false);
        handleClose();
    };

    //password
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleOldPasswordChange = (oldPasswordValue: string) => {
        setOldPassword(oldPasswordValue);
    };

    const handleNewPasswordChange = (newPasswordValue: string) => {
        setNewPassword(newPasswordValue);
    };

    const handleConfirmPasswordChange = (newPasswordValue: string) => {
        setConfirmPassword(newPasswordValue);
    };

    useEffect(() => {
        setPasswordsEqual(newPassword === confirmPassword);
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        newPassword &&
            setIsPasswordsValid(pattern.test(newPassword));
    }, [newPassword]);

    useEffect(() => {
        setIsEmailPasswordEqual(newPassword === me?.email);
    }, [newPassword, me?.email]);

    useEffect(() => {
        isPasswordsValid && isPasswordsEqual && !isEmailPasswordEqual ?
            setIsValidForm(true) : setIsValidForm(false);
    }, [isPasswordsValid, isPasswordsEqual, isEmailPasswordEqual]);

    const errorMessage: JSX.Element = (
        <div className="pb-3">
            <strong className="text-red-700">
                {intl.formatMessage({
                    id: "app.forms.password.not-equal-to-confirm-password",
                })}
            </strong>
        </div>
    );

    const errorPasswordValid: JSX.Element = (
        <div className="pb-3">
            <strong className="text-red-700">
                {intl.formatMessage({
                    id: "app.forms.password.invalid-password",
                })}
            </strong>
        </div>
    );

    const errorEmailPasswordEqual: JSX.Element = (
        <div className="pb-3">
            <strong className="text-red-700">
                {intl.formatMessage({
                    id: "app.forms.password.email-password-not-equal",
                })}
            </strong>
        </div>
    );

    const handleFormSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (isPasswordsEqual) {
            dispatch(
                updatePassword.request({
                    new_password: newPassword,
                    new_password_confirm: confirmPassword,
                    current_password: oldPassword,
                })
            );
            changePasswordClose();
        }
    };

    return (
        <>
            <a onClick={changePasswordClickOpen}>{intl.formatMessage({ id: "app.titles.change-password" })}</a>

            <Dialog
                className="change-password-modal"
                open={isChangePasswordOpen}
                onClose={changePasswordClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal-title">
                    <h3>{intl.formatMessage({ id: "app.titles.change-password" })}</h3>
                    <button onClick={changePasswordClose}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>
                <div className="change-password-modal-content">
                    <div className="change-password-feilds">
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel htmlFor="old-password">{intl.formatMessage({ id: "app.profile.dialogs.change-password.old-password" })}</InputLabel>
                            <OutlinedInput
                                id="old-password"
                                type={showOldPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                       <BootstrapTooltip title={showOldPassword ? "Hide Password" : "Show Password"} placement="top">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowOldPassword(!showOldPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"

                                            >
                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </BootstrapTooltip>
                                    </InputAdornment>
                                }
                                label={intl.formatMessage({ id: "app.profile.dialogs.change-password.old-password" })}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleOldPasswordChange(event.target?.value)
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel htmlFor="new-password">{intl.formatMessage({ id: "app.profile.dialogs.change-password.new-password" })}</InputLabel>
                            <OutlinedInput
                                id="new-password"
                                type={showNewPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                       <BootstrapTooltip title={showNewPassword ? "Hide Password" : "Show Password"} placement="top">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </BootstrapTooltip>
                                    </InputAdornment>
                                }
                                label={intl.formatMessage({ id: "app.profile.dialogs.change-password.new-password" })}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleNewPasswordChange(event.target?.value)
                                }
                            />
                        </FormControl>

                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel htmlFor="confirm-password">{intl.formatMessage({ id: "app.profile.dialogs.change-password.confirm-new-password" })}</InputLabel>
                            <OutlinedInput
                                id="confirm-password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                       <BootstrapTooltip title={showConfirmPassword ? "Hide Password" : "Show Password"} placement="top">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </BootstrapTooltip>
                                    </InputAdornment>
                                }
                                label={intl.formatMessage({ id: "app.profile.dialogs.change-password.confirm-new-password" })}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    handleConfirmPasswordChange(event.target?.value)
                                }
                            />
                        </FormControl>
                        {isEmailPasswordEqual && errorEmailPasswordEqual}
                        {!isEmailPasswordEqual && !isPasswordsValid && errorPasswordValid}
                        {!isEmailPasswordEqual && isPasswordsValid && !isPasswordsEqual && errorMessage}
                    </div>

                    <div className="action">                        
                        <button className="nj-btn change-password-btn" disabled={!isValidForm} onClick={handleFormSubmit}>{intl.formatMessage({ id: "app.titles.change-password" })}</button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default ChangePassword;