import React, { FunctionComponent, useMemo } from "react";
import {
  Menu,
  MenuItem,
  Popover,
  PopoverPosition,
  Tag,
} from "@blueprintjs/core";
import { Locale, LocaleDisplayedValues } from "../../store/UIState";
import { useIntl } from "react-intl";

type OwnProps = {
  position?: PopoverPosition;
  withoutNewTranslations?: boolean;
  usedLocales: Locale[];
  onSelectedLocaleChange: (locale: Locale) => void;
  onAddTranslation: (locale: Locale) => void;
  selectedLocale: string;
};

type Props = OwnProps;

const LocaleSelector: FunctionComponent<Props> = (props) => {
  const {
    withoutNewTranslations,
    position,
    usedLocales,
    onSelectedLocaleChange,
    onAddTranslation,
    selectedLocale,
  } = props;

  const intl = useIntl();

  const filteredLocales = useMemo(() => {
    return Object.values(Locale).filter(
      (locale) => !usedLocales.includes(locale)
    );
  }, [usedLocales]);

  return (
    <Popover
      portalClassName="locale-selector-portal"
      position={position}
      content={
        <Menu className="min-w-0">
          {!withoutNewTranslations && (
            <>
              <MenuItem
                text={intl.formatMessage({
                  id: "app.translations.add-new-translation",
                })}
                disabled={!filteredLocales.length}
              >
                {filteredLocales.map((locale) => (
                  <MenuItem
                    key={locale}
                    text={LocaleDisplayedValues[locale as Locale]}
                    onClick={() => onAddTranslation(locale)}
                  />
                ))}
              </MenuItem>
              <Menu.Divider />
            </>
          )}

          {usedLocales.map((locale) => (
            <MenuItem
              key={locale}
              text={LocaleDisplayedValues[locale as Locale]}
              active={locale === selectedLocale}
              onClick={() => onSelectedLocaleChange(locale)}
            />
          ))}
        </Menu>
      }
    >
      <Tag
        // rightIcon="translate"
        className="uppercase font-bold"
        interactive
        intent="primary"
        minimal
      >
        {LocaleDisplayedValues[selectedLocale as Locale]}
      </Tag>
    </Popover>
  );
};

export default LocaleSelector;
