import React from "react";
import { useIntl } from "react-intl";
import DashboardCard from "./DashboardCard";
import { Divider } from "@blueprintjs/core";
import TeamTable from "./TeamTable";
import UpcomingEvents from "./upcoming-events/UpcomingEvents";

type OwnProps = {
  isActiveDistrictPlan?: boolean;
};

type Props = OwnProps;

const UpcomingEventsCard: React.FC<Props> = ({
  isActiveDistrictPlan,
}: Props) => {
  const intl = useIntl();

  return (
    <DashboardCard
      title={intl.formatMessage({
        id: `app.titles.${isActiveDistrictPlan ? "district-" : ""}teaming`,
      })}
      body={
        <div className="upcoming-events-card">
          <div>
            <TeamTable />
          </div>
          <Divider />
          <UpcomingEvents />
        </div>
      }
    />
  );
};

export default UpcomingEventsCard;
