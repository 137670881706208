import React, { FunctionComponent, useMemo } from "react";
import {
  CollectedDemographicDataBgColors,
  CollectedDemographicDataEnum,
  InsightDemographicResult,
  SurveyDemographic,
} from "../../../../types";
import { useDispatch } from "react-redux";
import { showInsightSubgroupInfoDialog } from "../../../../store/survey-reports/actions";
import { Card } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { pickTextColorBasedOnBgColor } from "../../../charts/utils";

type OwnProps = {
  groupBy: CollectedDemographicDataEnum;
  demographic: SurveyDemographic;
  insightSubgroupName: string;
  insightDemographicResult: InsightDemographicResult;
};

type Props = OwnProps;

const InsightDemographicItem: FunctionComponent<Props> = (props) => {
  const {
    insightDemographicResult,
    insightSubgroupName,
    groupBy,
    demographic,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const subgroupStats = useMemo(() => {
    let titleParts = [];
    if (insightDemographicResult.domains?.length) {
      titleParts.push(
        intl.formatMessage(
          { id: "app.insights.sub-group.item.domains-number" },
          { num: insightDemographicResult.domains.length }
        )
      );
    }
    if (insightDemographicResult.items?.length) {
      titleParts.push(
        intl.formatMessage(
          { id: "app.insights.sub-group.item.items-number" },
          { num: insightDemographicResult.items.length }
        )
      );
    }

    return titleParts.join("; ");
  }, [insightDemographicResult]);

  const handleSubgroupClick = () => {
    dispatch(
      showInsightSubgroupInfoDialog({
        subgroupName: insightSubgroupName,
        domainsCortege: insightDemographicResult.domains,
        itemsCortege: insightDemographicResult.items,
        demographic: demographic,
        groupBy: {
          key: groupBy,
          value: insightDemographicResult.title,
        },
      })
    );
  };

  const { bgColor, textColor } = useMemo(() => {
    const bgColor = groupBy
      ? CollectedDemographicDataBgColors[groupBy]
      : undefined;
    return {
      bgColor: bgColor,
      textColor: bgColor ? pickTextColorBasedOnBgColor(bgColor) : undefined,
    };
  }, [groupBy]);

  return (
    <Card
      // interactive
      // onClick={handleSubgroupClick}
      className="leading-tight border border-gray-600 px-2 py-1"
      style={{
        color: textColor,
        background: bgColor,
      }}
    >
      <div className="font-bold">{insightSubgroupName}</div>
      <div>{subgroupStats}</div>
    </Card>
  );
};

export default InsightDemographicItem;
