import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  Callout,
  FormGroup,
  InputGroup,
  Popover,
  Tab,
  Tabs,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import DomainAnswerSetSelector from "../domain-answer-set/DomainAnswerSetSelector";
import { Domain, DomainAnswerSet } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { showDomainAnswerSetUpsertDialog } from "../../../store/domain-answer-set/actions";
import { ColorResult, SketchPicker } from "react-color";
import PageEditor from "../../survey-deployment/forms/PageEditor";
import { AddDomainRequest } from "../../../api/domains/types";
import SurveySelector from "../../survey-deployment/selectors/SurveySelector";

type OwnProps = {
  selectedDomain?: Domain;
  query?: string;
  onFormSubmit: (params: AddDomainRequest) => any;
  formId: string;
};

type Props = OwnProps;

const MAX_DOMAIN_NAME_LENGTH = 256;
const MAX_DOMAIN_DESCRIPTION_LENGTH = 1024;

enum TabKeys {
  General = "general",
  FocusingQuestions = "focusing-questions",
}

const DomainForm: React.FC<Props> = (props: Props) => {
  const { query, formId, onFormSubmit, selectedDomain } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const [domainName, setDomainName] = useState<string>("");
  const [domainDescription, setDomainDescription] = useState("");
  const [selectedDomainAnswerSet, setSelectedDomainAnswerSet] = useState<
    DomainAnswerSet | undefined
  >();
  const [domainColor, setDomainColor] = useState<string | undefined>();
  const [focusingQuestions, setFocusingQuestions] = useState<
    string | undefined
  >();
  const [surveyId, setSurveyId] = useState<number | undefined>(undefined);
  const [warningMessage, setWarningMessage] = useState<string | undefined>();

  useEffect(() => {
    if (selectedDomain) {
      setDomainName(selectedDomain.name);
      setDomainDescription(selectedDomain.description);
      setDomainColor(selectedDomain.color_hex);
      setFocusingQuestions(selectedDomain.focusing_questions || undefined);
      setSurveyId(selectedDomain.survey);
    } else {
      setDomainName(query ?? "");
      setDomainDescription("");
      setSelectedDomainAnswerSet(undefined);
      setDomainColor(undefined);
      setFocusingQuestions(undefined);
      setSurveyId(undefined);
    }
  }, [query, selectedDomain]);

  const handleColorChange = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDomainColor(color.hex);
  };

  const domainAnswerSets = useSelector(
    (s) => s.domainsAnswerSet.domainAnswerSets
  );

  useEffect(() => {
    if (selectedDomain && selectedDomain.domain_answer) {
      setSelectedDomainAnswerSet(
        selectedDomain.domain_answer.id
          ? selectedDomain.domain_answer
          : undefined
      );

      if (selectedDomain && selectedDomain.survey) {
        setSurveyId(selectedDomain.survey);
      }
      else {
        setSurveyId(undefined);
      }
    }
  }, [selectedDomain, domainAnswerSets]);

  const handleDomainNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainName(e.target?.value);
  };

  const handleDomainDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDomainDescription(e.target?.value);
  };

  const [activeTabId, setActiveTabId] = useState<TabKeys>(TabKeys.General);

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (surveyId === undefined) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.validators.survey-required",
        }),
      };
      return validationOption;
    }
    return validationOption;
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, message } = validate();

    if (!domainName) {
      setActiveTabId(TabKeys.General);
      e.currentTarget.reportValidity();
      return;
    }

    if (isValid) {
      onFormSubmit({
        color_hex: domainColor,
        description: domainDescription,
        domain_answers_id: selectedDomainAnswerSet?.id!,
        name: domainName,
        focusing_questions: focusingQuestions,
        survey: surveyId
      });
      setWarningMessage(undefined);
    } else {
      setWarningMessage(message);
    }
  };

  const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);

  const surveyDeployment = useSelector(
    (s) => s.surveysDeployment.selectedSurveyDeployment
  );

  const selectedSurveyOptionType = useMemo(() => {
    return !!selectedSurvey
      ? { label: selectedSurvey.name, value: selectedSurvey.id }
      : !!surveyDeployment
        ? {
          label: surveyDeployment?.survey?.name,
          value: surveyDeployment?.survey?.id,
        }
        : undefined;
  }, [selectedSurvey, surveyDeployment]);

  const onSurveyIdChange = (selectedSurvey?: number | undefined) => {
    setSurveyId(selectedSurvey);
  };

  return (
    <form onSubmit={handleFormSubmit} id={formId}>
      <Tabs
        selectedTabId={activeTabId}
        renderActiveTabPanelOnly
        onChange={(newTabId) => setActiveTabId(newTabId as TabKeys)}
      >
        <Tab
          id={TabKeys.General}
          title={intl.formatMessage({
            id: "app.titles.general-info",
          })}
          panel={
            <>
              <FormGroup
                label={intl.formatMessage({
                  id: "app.forms.survey-deployment-form.survey-selector",
                })}
                labelFor="survey-selector"
              >
                <SurveySelector
                  surveyId={surveyId}
                  onSurveyChange={onSurveyIdChange}
                  selectedSurvey={selectedSurveyOptionType}
                />
              </FormGroup>

              <FormGroup
                label={intl.formatMessage({ id: "app.forms.domain-form.name" })}
                labelFor="domain-name-input"
                labelInfo={intl.formatMessage({ id: "app.forms.required" })}
              >
                <InputGroup
                  id="domain-name-input"
                  placeholder={intl.formatMessage({
                    id: "app.forms.domain-form.name.placeholder",
                  })}
                  value={domainName}
                  onChange={handleDomainNameChange}
                  required
                  maxLength={MAX_DOMAIN_NAME_LENGTH}
                />
              </FormGroup>

              <FormGroup
                label={intl.formatMessage({
                  id: "app.forms.domain-form.description",
                })}
                labelFor="domain-description-input"
              >
                <TextArea
                  growVertically
                  id="domain-description-input"
                  value={domainDescription}
                  placeholder={intl.formatMessage({
                    id: "app.forms.domain-form.description.placeholder",
                  })}
                  onChange={handleDomainDescriptionChange}
                  maxLength={MAX_DOMAIN_DESCRIPTION_LENGTH}
                  required
                  fill
                />
              </FormGroup>

              <FormGroup
                label={intl.formatMessage({ id: "app.domains.color" })}
                labelFor="domain-color"
              >
                <Popover
                  content={
                    <SketchPicker
                      color={domainColor ?? undefined}
                      onChange={handleColorChange}
                    />
                  }
                  target={
                    <div className="flex space-x-2">
                      <InputGroup
                        placeholder={`${intl.formatMessage({
                          id: "app.domains.color",
                        })}...`}
                        value={domainColor}
                      />
                      {domainColor && (
                        <Tag style={{ backgroundColor: domainColor }} />
                      )}
                    </div>
                  }
                />
              </FormGroup>

              <div className="mt-3">
                <DomainAnswerSetSelector
                  domainAnswerSets={domainAnswerSets}
                  onCustomize={() => {
                    dispatch(
                      showDomainAnswerSetUpsertDialog({
                        domainAnswerSet: selectedDomainAnswerSet,
                        onSuccess: (a: any) => setSelectedDomainAnswerSet(a),
                      })
                    );
                  }}
                  selectedDomainAnswerSet={selectedDomainAnswerSet}
                  setSelectedDomainAnswerSet={setSelectedDomainAnswerSet}
                />
              </div>
              {warningMessage && (
                <div className="my-3 px-3">
                  <Callout intent="warning">{warningMessage}</Callout>
                </div>
              )}
            </>
          }
        />

        <Tab
          id={TabKeys.FocusingQuestions}
          title={intl.formatMessage({
            id: "app.titles.focusing-questions",
          })}
          panel={
            <div className="bg-white">
              <PageEditor
                content={focusingQuestions}
                onBlur={(value) => setFocusingQuestions(value)}
              />
            </div>
          }
        />
      </Tabs>
    </form>
  );
};

export default DomainForm;
