import React from "react";
import FooterLogos from "./FooterLogos";

const withBodyContainer = (Component: any, OutOfContainerComponent?: any) => ({
  ...props
}: any) => {
  return (
    <>
      {OutOfContainerComponent && <OutOfContainerComponent />}
      <div className="flex flex-col flex-grow min-w-0 justify-between bg-gray-100">
        <div className="flex-grow p-2 body-container">

          <Component {...props} />
        </div>
        <FooterLogos />
      </div>
    </>
  );
};

export default withBodyContainer;
