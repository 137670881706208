import { useSelector } from "react-redux";
import { useLoading } from "../useLoading";

export const useGetDatasetsLoading = () => {
  const loadingGetDatasets = useSelector((s) => s.datasets.loading.getDatasets);
  const errorGetDatasets = useSelector((s) => s.datasets.errors.getDatasets);
  useLoading({ loading: loadingGetDatasets, error: errorGetDatasets });

  return {
    loading: loadingGetDatasets,
  };
};
