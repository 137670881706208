import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Icon, Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core";
import { BookmarkType } from "../../../../types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

type OwnProps = {
  flagged: boolean;
  bookmarkType?: BookmarkType;
  iconSize?: number;
  onClick?: (bookmarkType: BookmarkType) => void;
  onDiscard?: () => void;
  className?: string;
  defaultIsOpen?: boolean;
  /**
   * Controls the disabled property of the popover component
   */
  notEnoughData?: boolean;
  loading?: boolean;
};

type Props = OwnProps;

const ItemMark: FunctionComponent<Props> = (props) => {
  const {
    flagged,
    bookmarkType,
    iconSize = 24,
    onClick,
    onDiscard,
    className,
    notEnoughData,
    defaultIsOpen,
    loading
  } = props;

  const intl = useIntl();

  const { flagDatasetMark, updateDatasetMark } = useSelector(
    (s) => s.datasetMarks.loading
  );

  const [inprogress, setInprogress] = useState<boolean>(false);

  const handleClick = (bType: BookmarkType) => () => {
    setInprogress(true);
    if (onClick && bookmarkType !== bType) {
      onClick(bType);
      setInprogress(true);
    }
  };

  const handleBookmarkRemove = () => {
    setInprogress(true);
    if (onDiscard) {
      onDiscard();
    }
  };

  const iconClassName: string = useMemo(() => {
    const classNameParts = ["flex items-center cursor-pointer"];

    if (!flagged) {
      classNameParts.push("opacity-25 hover:opacity-100");
    }

    return classNameParts.join(" ");
  }, [flagged]);

  useEffect(() => {
    setInprogress(false)
  }, [bookmarkType]);

  return (
    <Popover
      defaultIsOpen={defaultIsOpen}
      className={`flex items-center ${className ?? ""}`}
      content={
        <Menu>
          <MenuItem
            active={bookmarkType === BookmarkType.Negative}
            icon={<Icon icon={"bookmark"} color="red" />}
            text={intl.formatMessage({ id: "app.bookmarks.negative-bookmark" })}
            onClick={handleClick(BookmarkType.Negative)}
          />
          <MenuItem
            active={bookmarkType === BookmarkType.Positive}
            icon={<Icon icon={"bookmark"} color="green" />}
            text={intl.formatMessage({ id: "app.bookmarks.positive-bookmark" })}
            onClick={handleClick(BookmarkType.Positive)}
          />
          {flagged && (
            <>
              <MenuDivider />
              <MenuItem
                icon={"cross"}
                text={intl.formatMessage({
                  id: "app.bookmarks.remove-bookmark",
                })}
                onClick={handleBookmarkRemove}
              />
            </>
          )}
        </Menu>
      }
      disabled={
        notEnoughData || (inprogress)
      }
    >
      <Icon
        icon="bookmark"
        iconSize={iconSize}
        color={
          flagged
            ? bookmarkType === BookmarkType.Negative
              ? "red"
              : "green"
            : inprogress
            ? "black"
            : "gray"
        }
        className={inprogress ? "fadeElement " + iconClassName : iconClassName}
      />
    </Popover>
  );
};

export default ItemMark;
