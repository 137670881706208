import { BookmarksInfoTableItem } from "../../../components/team-plan/needs-and-goals/bookmarks-info-table/BookmarksInfoTable";
import React, { useMemo } from "react";
import _ from "lodash";
import {
  DomainComparison,
  DomainDemographicTriageStatuses,
  DomainPriorityTriageStatus,
  SurveyDemographic,
} from "../../../types";
import PotentialPriorityIcon from "../../../components/team-plan/needs-and-goals/bookmarks-info-table/PotentialPriorityIcon";
import { POTENTIAL_PRIORITY_COLOR } from "../../../components/team-plan/needs-and-goals/item-level-info-and-bookmarks-dialog/useBookmarksLevelInfo";
import { DEFAULT_PRECISION } from "../../../constants/constants";
import BookmarksIcons from "../../../components/team-plan/needs-and-goals/explore-strengths-and-needs-tab/BookmarksIcons";
import useDomainLevelBookmarkCounter from "./useDomainLevelBookmarkCounter";
import { useIntl } from "react-intl";

type Props = {
  domainComparison: DomainComparison;
  domainDemographicTriageStatuses?: DomainDemographicTriageStatuses;
};

export const useDomainLevelItemBookmarkInfo = (props: Props) => {
  const { domainComparison, domainDemographicTriageStatuses } = props;

  const intl = useIntl();

  const { marksCountByDemographic } = useDomainLevelBookmarkCounter({
    domainId: domainComparison.domain.id,
  });

  const items: BookmarksInfoTableItem[] = useMemo(() => {
    return _.chain(domainComparison.respondents)
      .toPairs()
      .filter(([demographic, value]) => value != null)
      .map<BookmarksInfoTableItem>(([demographic, value]) => {
        let styles;
        let title: JSX.Element | string = intl.formatMessage({
          id: `app.filters.respondent-type.${demographic}`,
        });
        switch (
          domainDemographicTriageStatuses?.[
            demographic as SurveyDemographic
          ] as DomainPriorityTriageStatus
        ) {
          case DomainPriorityTriageStatus.PotentialPriority: {
            title = (
              <div className="left-center-grid-container">
                <PotentialPriorityIcon />
                <div>{title}</div>
              </div>
            );
            styles = {
              background: POTENTIAL_PRIORITY_COLOR,
            };
            break;
          }

          case DomainPriorityTriageStatus.Archived: {
            styles = {
              filter: "grayscale()",
            };
            break;
          }

          default:
            break;
        }

        const marksCount = marksCountByDemographic[demographic];

        return {
          title: title,
          score:
            value.mean_score && _.round(value.mean_score, DEFAULT_PRECISION),
          bookmarksPanel: (
            <BookmarksIcons
              negative={marksCount?.negative}
              positive={marksCount?.positive}
            />
          ),
          styles: styles,
        };
      })
      .orderBy((item) => item.score, ["desc"])
      .value();
  }, [domainComparison, marksCountByDemographic]);

  return {
    items,
  };
};

export default useDomainLevelItemBookmarkInfo;
