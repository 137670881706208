import { Epic } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getDomainAnswerSetsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getDomainAnswerSets.request)),
    switchMap((action) =>
      from(api.domainAnswerSet.getDomainAnswerSets()).pipe(
        map(actions.getDomainAnswerSets.success),
        catchError((error) => of(actions.getDomainAnswerSets.failure(error)))
      )
    )
  );
};

export const addDomainAnswerSetEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addDomainAnswerSet.request)),
    switchMap((action) =>
      from(
        api.domainAnswerSet.addDomainAnswerSet(action.payload.domainAnswerSet)
      ).pipe(
        map((domainAnswerSet) => {
          action.payload.onSuccess && action.payload.onSuccess(domainAnswerSet);
          return actions.addDomainAnswerSet.success(domainAnswerSet);
        }),
        catchError((error) => of(actions.addDomainAnswerSet.failure(error)))
      )
    )
  );
};

export const updateDomainAnswerSetEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updateDomainAnswerSet.request)),
    switchMap((action) =>
      from(
        api.domainAnswerSet.updateDomainAnswerSet(
          action.payload.domainAnswerSet
        )
      ).pipe(
        map((domainAnswerSet) => {
          action.payload.onSuccess && action.payload.onSuccess(domainAnswerSet);
          return actions.updateDomainAnswerSet.success(domainAnswerSet);
        }),
        catchError((error) => of(actions.updateDomainAnswerSet.failure(error)))
      )
    )
  );
};

export const deleteDomainAnswerSetEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteDomainAnswerSet.request)),
    switchMap((action) =>
      from(api.domainAnswerSet.deleteDomainAnswerSet(action.payload)).pipe(
        map(() => actions.deleteDomainAnswerSet.success(action.payload)),
        catchError((error) => of(actions.deleteDomainAnswerSet.failure(error)))
      )
    )
  );
};
