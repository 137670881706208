import { createAction, createAsyncAction } from "typesafe-actions";
import { TeamMember, UserModel } from "../../types";
import {
  AddTeamMemberToPlanRequest,
  InviteTeamMembersRequest,
  InviteTeamMembersResponse,
  UpdateTeamMemberRequest,
  GetTeamMemberRequest,
} from "../../api/team-members/types";

export const getTeamMembersByPlan = createAsyncAction(
  "@team-members/GET_TEAM_MEMBERS_BY_PLAN_REQUEST",
  "@team-members/GET_TEAM_MEMBERS_BY_PLAN_SUCCESS",
  "@team-members/GET_TEAM_MEMBERS_BY_PLAN_FAILURE"
)<{ planId: number; include_district_user?: boolean }, TeamMember[], Error>();

export const updateTeamMember = createAsyncAction(
  "@team-members/UPDATE_TEAM_MEMBER_REQUEST",
  "@team-members/UPDATE_TEAM_MEMBER_SUCCESS",
  "@team-members/UPDATE_TEAM_MEMBER_FAILURE"
)<{ body: UpdateTeamMemberRequest; onSuccess?: Function }, TeamMember, Error>();

export const inviteTeamMembers = createAsyncAction(
  "@team-members/INVITE_TEAM_MEMBERS_REQUEST",
  "@team-members/INVITE_TEAM_MEMBERS_SUCCESS",
  "@team-members/INVITE_TEAM_MEMBERS_FAILURE"
)<
  { body: InviteTeamMembersRequest; onSuccess?: Function },
  InviteTeamMembersResponse,
  Error
>();

export const showUnderstandingRolesDialog = createAction(
  "@team-members/SHOW_UNDERSTANDING_ROLES_DIALOG"
)<void>();
export const hideUnderstandingRolesDialog = createAction(
  "@team-members/HIDE_UNDERSTANDING_ROLES_DIALOG"
)<void>();

export const showLearnAboutSccpAndDcltdDialog = createAction(
  "@team-members/SHOW_LEARN_ABOUT_SCCP_AND_DCLTD_DIALOG"
)<boolean>();
export const hideLearnAboutSccpAndDcltdDialog = createAction(
  "@team-members/HIDE_LEARN_ABOUT_SCCP_AND_DCLTD_DIALOG"
)<void>();

export const showLearningModuleDialog = createAction(
  "@team-members/SHOW_LEARNING_MODULE_DIALOG"
)<boolean>();
export const hideLearningModuleDialog = createAction(
  "@team-members/HIDE_LEARNING_MODULE_DIALOG"
)<void>();

export const showScreencastTutorialDialog = createAction(
  "@team-members/SHOW_SCREENCAST_TUTORIALS_DIALOG"
)<boolean>();
export const hideScreencastTutorialDialog = createAction(
  "@team-members/HIDE_SCREENCAST_TUTORIALS_DIALOG"
)<void>();

export const addTeamMembers = createAsyncAction(
  "@team-members/ADD_TEAM_MEMBERS_REQUEST",
  "@team-members/ADD_TEAM_MEMBERS_SUCCESS",
  "@team-members/ADD_TEAM_MEMBERS_FAILURE"
)<
  { planId: number; members: AddTeamMemberToPlanRequest[] },
  TeamMember[],
  Error
>();

export const showUpdateTeamMemberDialog = createAction(
  "@team-members/SHOW_UPDATE_TEAM_MEMBER_DIALOG"
)<{ planId: number; user: UserModel }>();

export const hideUpdateTeamMemberDialog = createAction(
  "@team-members/HIDE_UPDATE_TEAM_MEMBER_DIALOG"
)<void>();

export const deleteTeamMemberFromPlan = createAsyncAction(
  "@team-members/DELETE_TEAM_MEMBER_FROM_PLAN_REQUEST",
  "@team-members/DELETE_TEAM_MEMBER_FROM_PLAN_SUCCESS",
  "@team-members/DELETE_TEAM_MEMBER_FROM_PLAN_FAILURE"
)<{ planId: number; userId: number; onSuccess?: Function }, number, Error>();

export const updateUserFieldInTeamMember = createAction(
  "@team-members/UPDATE_TEAM_MEMBER"
)<UserModel>();

export const showManageUsersDialog = createAction(
  "@team-members/SHOW_MANAGE_USERS_DIALOG"
)<void>();

export const hideManageUsersDialog = createAction(
  "@team-members/HIDE_MANAGE_USERS_DIALOG"
)<void>();
