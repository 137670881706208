import React, { FunctionComponent, useMemo, useState } from "react";
import { AnchorButton, Button, Divider, Icon } from "@blueprintjs/core";
import {
  deleteResource,
  hideUpsertResourceDialog,
  showUpsertResourceDialog,
  updateResource,
} from "../../../store/resources/actions";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AttachedResource, AttachedResourceType } from "../../../types";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../store/UIState/actions";
import { AppToaster } from "../../../helpers/toaster";
import { Dialog } from "@mui/material";

type OwnProps = {
  resource: AttachedResource;
};

type Props = OwnProps;

const ResourceActions: FunctionComponent<Props> = (props) => {
  const { resource } = props;

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const intl = useIntl();
  const dispatch = useDispatch();

  const [resourceDeleteModalOpen, setResourceDeleteModalOpen] = useState(false);


  const handleResourceUpdate = (
    resource: Partial<AttachedResource> &
      Pick<
        AttachedResource,
        "type" | "title" | "description" | "attached_to_type"
      >
  ) => {
    dispatch(
      updateResource.request({
        resource: resource as AttachedResource,
        planId: activePlanId!,
        onSuccess: () => {
          AppToaster.show({
            intent: "success",
            icon: "tick",
            message: intl.formatMessage(
              { id: "app.titles.update-toaster-message" },
              { name: resource?.title }
            ),
          });
          handleClose();
        },
      })
    );
  };

  const handleClose = () => {
    dispatch(hideUpsertResourceDialog());
  };

  const handleEditResourceClick = () => {
    dispatch(
      showUpsertResourceDialog({
        resource: resource,
        onSave: handleResourceUpdate,
        onClose: handleClose,
        isLoadingSelector: (s) => s.resources.loading.updateResource,
        errorSelector: (s) => s.resources.errors.updateResource,
      })
    );
  };

  // const handleDeleteResourceClick = () => {
  //   dispatch(
  //     showConfirmDialog({
  //       onConfirm: () => {
  //         dispatch(
  //           deleteResource.request({
  //             resourceId: resource.id,
  //             planId: activePlanId!,
  //             onSuccess: () => {
  //               AppToaster.show({
  //                 icon: "tick",
  //                 intent: "success",
  //                 message: intl.formatMessage(
  //                   { id: "app.titles.delete-toaster-message" },
  //                   { name: resource.title }
  //                 ),
  //               });
  //               dispatch(hideConfirmDialog());
  //             },
  //           })
  //         );
  //       },
  //       show: true,
  //       intent: "danger",
  //       text: intl.formatMessage({
  //         id: "app.confirmation-dialogs.delete-resource",
  //       }),
  //       icon: "trash",
  //       confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
  //     })
  //   );
  // };


  const handleDeleteResourceClick=()=>{
    dispatch(
      deleteResource.request({
        resourceId: resource.id,
        planId: activePlanId!,
        onSuccess: () => {
          AppToaster.show({
            icon: "tick",
            intent: "success",
            message: intl.formatMessage(
              { id: "app.titles.delete-toaster-message" },
              { name: resource.title }
            ),
          });
          dispatch(hideConfirmDialog());
        },
      })
    );
  } 

  const hasWriteAccess = true; //todo

  const isLinkType = useMemo(() => {
    return resource.type === AttachedResourceType.Link;
  }, [resource.type]);




  const handleResourceDeleteClickOpen = () => {
    setResourceDeleteModalOpen(true);
};
  const handleResourceDeleteClose = () => {
    setResourceDeleteModalOpen(false);
};

  const DeleteResourceDialog=(title:any)=>{
    return(<Dialog
      className="medium-popup"
      open={resourceDeleteModalOpen}
      onClose={handleResourceDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
      <div className="cancel">
          <button onClick={handleResourceDeleteClose}><Icon icon="cross" iconSize={18} /></button>
      </div>
      <Icon icon="trash" iconSize={30} />

      <h3>
          {intl.formatMessage(
              { id: "app.resource.delete-dialogs.title" }
          )}
      </h3>
      <p>
          {intl.formatMessage(
              { id: "app.resource.delete-dialogs.body" },
              {
                  br: <br />,
                  resource: title,
              }
          )}
      </p>
      <div className="button-group">
          <button 
          onClick={handleResourceDeleteClose} 
          className="stroke-btn">{intl.formatMessage(
              { id: "app.titles.cancel" }
          )}</button>
          <button
           onClick={handleDeleteResourceClick} 
           className="nj-btn">{intl.formatMessage(
              { id: "app.titles.delete" }
          )}</button>
      </div>
  </Dialog>)
  }

  return (<>
    <div className="flex justify-center -m-1 entityActionS">
      <AnchorButton
        href={resource.url && resource.url.length > 0 ? resource.url : resource.attachment_url}
        title={intl.formatMessage({
          id: "app.resources-table.download-resource",
        })}
        target="_blank"
        rel="noopener noreferrer"
        icon={isLinkType ? "link" : "import"}
        className="m-1"
        intent="primary"
        download={!isLinkType}
      />
      {hasWriteAccess && (
        <>
          <Button
            title={intl.formatMessage({
              id: "app.resources.edit",
            })}
            icon="edit"
            className="m-1"
            intent="primary"
            onClick={handleEditResourceClick}
          />
          <Button
            title={intl.formatMessage({
              id: "app.titles.delete",
            })}
            icon="trash"
            className="m-1"
            intent="danger"
            onClick={handleResourceDeleteClickOpen}
          />
        </>
      )}
    </div>
    {DeleteResourceDialog(resource?.title)}
    </>
  );
};

export default ResourceActions;