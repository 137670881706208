import Axios from "axios";
import { API_ROOT } from "../config";
import {
  DomainComparison,
  DomainQuestionsComparison,
  DomainStatistics,
  GroupedAnswerStatistics,
  GroupedSurveyStatistics,
  Insight,
  ResponseRates,
  SchoolNameDeplyments,
  ShareFeedbackModel,
  SurveyDemographic,
  SurveyStatistics,
} from "../../types";
import { GetSurveyComparisonRequest, GetSurveyReportRequest } from "./types";
import { makeRequestPath } from "../../helpers/stringUtils";

Axios.defaults.timeout = 999999999999

export const getSurveyReports = (
  request: GetSurveyReportRequest
): Promise<
  | SurveyStatistics[]
  | GroupedSurveyStatistics[]
  | DomainStatistics
  | DomainStatistics<GroupedAnswerStatistics[]>
> => {
  const url = `${API_ROOT}/surveys_deployments/reports`;
  return Axios.get(makeRequestPath(url, request));
};

export const getSurveyComparison = (
  request: GetSurveyComparisonRequest | GetSurveyComparisonRequest[]
): Promise<DomainQuestionsComparison | DomainComparison[]> => {
  const url = `${API_ROOT}/surveys_deployments/comparison`;
  if (request instanceof Array){
    return Axios.get(makeRequestPath(url, [(request[0] as any)]));
  }
  return Axios.get(makeRequestPath(url, request));
};

export const getComparisonDomainItemsData = (
  requestPayload:any
): Promise<any> => {
   return Axios.post(`${API_ROOT}/surveys_deployments/comparison-item`,requestPayload);
};

export const getRespondentGraphDomainData = (
  request:any
): Promise<any> => {
  return Axios.post(`${API_ROOT}/surveys_deployments/respondent_graph`,request.survey_deployment_id.map((item:any)=> {return{surveyDeploymentID:item,school_id:request.school_id[0] }}));
};

export const getRespondentGraphItemData = (
  request:any
): Promise<any> => {
  return Axios.post(`${API_ROOT}/surveys_deployments/respondent_graph`,request.survey_deployment_id.map((item:any)=>{return {surveyDeploymentID:item.surveyDeploymentID,domain:item.domain,school_id:request.school_id[0]}}))
};

export const getInsights = (
  surveyDeploymentId: number,
  schoolId: number
): Promise<Insight[]> => {
  return Axios.get(
    `${API_ROOT}/surveys_deployments/insights?survey_deployment_id=${surveyDeploymentId}&school_id=${schoolId}`
  );
};

export const downloadStaticReport = (
  surveyDeploymentId: number,
  schoolId: number,
  demographic: SurveyDemographic,
  hide_sensitive_reporting_data:boolean
): Promise<Blob> => {
  return Axios.get(
    `${API_ROOT}/pdf/static_reports/survey_deployment/${surveyDeploymentId}/schools/${schoolId}/${demographic}?hide_sensitive_reporting_data=${hide_sensitive_reporting_data}`,
    {
      responseType: "blob",
    }
  );
};

export const downloadCSVReport = (
  surveyDeploymentId: number,
  schoolId: number,
  demographic: SurveyDemographic
): Promise<Blob> => {
  return Axios.get(
    `${API_ROOT}/csv/report/completed_surveys/schools/${schoolId}/survey_deployment/${surveyDeploymentId}/${demographic}`,
    {
      responseType: "text",
    }
  );
};

export const downloadCSVReportByTaskId = (
  taskId?: string,
): Promise<Blob> => {
  return Axios.get(
    `${API_ROOT}/deployment_report_download/${taskId}`,
    {
      responseType: "text",
    }
  );
};

export const getSurveyReportResponseRates = (
  surveyDeploymentId: number,
  schoolId: number,
  domainId?: number
): Promise<ResponseRates> => {
  return Axios.get(
    `${API_ROOT}/response_rates/schools/${schoolId}/survey_deployment/${surveyDeploymentId}/${
      !!domainId ? `?domain_id=${domainId}` : ""
    }`
  );
};

export const shareFeedback = (message: string,feedback_choices:number): Promise<void> => {
  return Axios.post(`${API_ROOT}/reports/share_feedback/`, {
    message: message,
    feedback_choices:feedback_choices
  });
};

export const getShareFeedbackOption = (): Promise<ShareFeedbackModel> => {
  return Axios.get(`${API_ROOT}/feedback-option/`);
};

export const actionAuditselectSurveyDeployment = (
  schoolNameDeplyment: SchoolNameDeplyments
): Promise<void> => {
  return Axios.post(`${API_ROOT}/action-history/`, schoolNameDeplyment);
};