import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
} from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import {
  hideChangePasswordDialog,
  hideEditProfileDialog,
  updatePassword,
} from "../../../store/profile/actions";
import useLockButton from "../../../helpers/hooks/useLockButton";
import { AppToaster } from "../../../helpers/toaster";
import { useLoading } from "../../../helpers/hooks/useLoading";

type OwnProps = {};

type Props = OwnProps;

const pattern = /^(?=.*[0-9])(?=.*[A-Za-z])([A-Za-z\d\w\W]{8,})$/;



const ChangePasswordDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const show = useSelector((s) => s.profile.showChangePasswordDialog);
  const me = useSelector((s) => s.auth.authenticatedUser);
  const [isEmailPasswordEqual, setIsEmailPasswordEqual] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordsEqual, setPasswordsEqual] = useState(true);
  const [isPasswordsValid, setIsPasswordsValid] = useState(true);
  const [isValidForm, setIsValidForm] = useState(true);
  const [isOldPasswordEqual, setIsOldPasswordEqual] = useState(true);

  const [oldPasswordLockButton, showOldPassword] = useLockButton();
  const [newPasswordLockButton, showNewPassword] = useLockButton();
  const [confirmPasswordLockButton, showConfirmPassword] = useLockButton();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isPasswordsEqual) {
      dispatch(
        updatePassword.request({
          new_password: newPassword,
          new_password_confirm: confirmPassword,
          current_password: oldPassword,
        })
      );
    }
  };

  const handleOldPasswordChange = (oldPasswordValue: string) => {
    setOldPassword(oldPasswordValue);
  };

  const handleNewPasswordChange = (newPasswordValue: string) => {
    setNewPassword(newPasswordValue);
  };

  const handleConfirmPasswordChange = (newPasswordValue: string) => {
    setConfirmPassword(newPasswordValue);
  };

  useEffect(() => {
    setPasswordsEqual(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    newPassword &&
    setIsPasswordsValid(pattern.test(newPassword));
  }, [newPassword]);

  useEffect(() => {
    setIsEmailPasswordEqual(newPassword === me?.email);
  }, [newPassword, me?.email]);

  useEffect(() => {
    isPasswordsValid && isPasswordsEqual && !isEmailPasswordEqual ? 
    setIsValidForm(true):setIsValidForm(false);
  },[isPasswordsValid,isPasswordsEqual,isEmailPasswordEqual]);

  const errorMessage: JSX.Element = (
    <div className="pb-3">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.not-equal-to-confirm-password",
        })}
      </strong>
    </div>
  );

  const errorPasswordValid: JSX.Element = (
    <div className="pb-3">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.invalid-password",
        })}
      </strong>
    </div>
  );

  const errorEmailPasswordEqual: JSX.Element = (
    <div className="pb-3">
      <strong className="text-red-700">
        {intl.formatMessage({
          id: "app.forms.password.email-password-not-equal",
        })}
      </strong>
    </div>
  );

  const handleClose = () => {
    if (!loading) {
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setPasswordsEqual(true);
      dispatch(hideChangePasswordDialog());
    }
  };

  const loading = useSelector((s) => s.profile.loading.updatePassword);
  const error = useSelector((s) => s.profile.errors.updatePassword);

  const onSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.profile.toaster.password-changed-success",
      }),
      icon: "tick",
      intent: "success",
    });
    handleClose();
    dispatch(hideEditProfileDialog());
  }, []);

  useLoading({ loading, error, onSuccess });

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      icon={<Icon icon="key" iconSize={24} />}
      title={intl.formatMessage({
        id: "app.profile.change-password",
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        <form id="change-password-form" onSubmit={handleFormSubmit}>
          <FormGroup
            label={intl.formatMessage({
              id: "app.profile.dialogs.change-password.old-password",
            })}
            labelFor="old-password"
          >
            <InputGroup
              id="old-password"
              required={true}
              type={showOldPassword ? "text" : "password"}
              rightElement={oldPasswordLockButton as JSX.Element}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleOldPasswordChange(event.target?.value)
              }
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.profile.dialogs.change-password.new-password",
            })}
            labelFor="new-password"
          >
            <InputGroup
              id="new-password"
              required={true}
              intent={isPasswordsEqual ? "none" : "danger"}
              type={showNewPassword ? "text" : "password"}
              rightElement={newPasswordLockButton as JSX.Element}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleNewPasswordChange(event.target?.value)
              }
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.profile.dialogs.change-password.confirm-new-password",
            })}
            labelFor="confirm-new-password"
          >
            <InputGroup
              id="confirm-new-password"
              required={true}
              intent={isPasswordsEqual ? "none" : "danger"}
              type={showConfirmPassword ? "text" : "password"}
              rightElement={confirmPasswordLockButton as JSX.Element}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleConfirmPasswordChange(event.target?.value)
              }
            />
          </FormGroup>
          {isEmailPasswordEqual && errorEmailPasswordEqual}
          {!isEmailPasswordEqual && !isPasswordsValid && errorPasswordValid}
          {!isEmailPasswordEqual && isPasswordsValid && !isPasswordsEqual && errorMessage} 
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={handleClose}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            disabled={!isValidForm}
            className="w-2/5"
            intent="primary"
            type="submit"
            loading={loading}
            form="change-password-form"
          >
            {intl.formatMessage({
              id: "app.profile.change-password",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangePasswordDialog;
