import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FilterDaterange from './FilterDaterange'
import Tooltip from '@mui/material/Tooltip';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Icon, Label, Spinner } from '@blueprintjs/core';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Domain, Goal, GraphTypeEnum, PlanStrategy, Survey, SurveyDemographic, SurveyDeployment } from '../../../../../types';
import { useIntl } from 'react-intl';
// import Checkbox from '@mui/material/Checkbox';
import {Checkbox} from "@blueprintjs/core"

import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListSubheader from '@mui/material/ListSubheader';
import './ProgressListFilter.scss';
import { filterStrategiesByDomainAndSearchString } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import ProgressContent from '../ProgressContent';
import moment from 'moment';
import { clearRespondentGraphDomainsItemsArray } from '../../../../../store/survey-reports/actions';
import { getSurveyDeploymentsBySchool } from '../../../../../store/surveys-deployment/actions';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Popover  from '@mui/material/Popover';
import { AppToaster } from '../../../../../helpers/toaster';

type OwnProps = {
  graphType?: string;
  selectedGoal?: Goal;
  isGoalChange?:boolean;
};

type Props = OwnProps;

interface FilterColumnProps {
  labelId: string;
  labelText: string;
  value: any; // Adjust this type as per your needs
  onChange: any; // Adjust this type
  children: React.ReactNode;
  disabled: boolean;
}

// const FilterColumn: React.FC<FilterColumnProps> = ({ labelId, labelText, value, onChange, children, disabled }) => (
//   // <div className="filterCol">
//      <div className="">
//     <FormControl size="small" className="selectMargin" fullWidth>
//       <InputLabel id={labelId}>{labelText}</InputLabel>
//       <Select
//         labelId={labelId}
//         id={`${labelId}-select`}
//         value={value}
//         label={labelText}
//         onChange={onChange}
//         input={<OutlinedInput label={labelText} />}
//         disabled={disabled}
//       >
//         {children}
//       </Select>
//     </FormControl>
//   </div>
// );


const MAXIMUM_YEARS_SELECTION_LIMIT = 5 ;
const ProgressListFilter: React.FC<Props> = (props) => {
  const { graphType, selectedGoal,isGoalChange } = props;
 

  const intl = useIntl();
  const dispatch = useDispatch();

  // STATES
  const [selectedRespondentGroup, setSelectedRespondentGroup] = useState<string>('');
  const [selectedDomain, setSelectedDomain] = useState<string>('');
  const [selectedMultiRespondentGroup, setSelectedMultiRespondentGroups] = useState<string[]>([]);
  const [selectedMultiDomains, setSelectedMultiDomains] = useState<string[]>([]);
  const [selectedSurveyItem, setSelectedSurveyItem] = useState<string>('');
  const [selectedMultiSurveyItem, setSelectedMultiSurveyItem] = useState<string[]>([]);
  const [alignStrategies, setAlignStrategies] = useState<
    PlanStrategy[]
  >([]);
  const [selectedMultiAlignedStrategy, setSelectedMultiAlignedStrategy] = useState<string[]>([]);
  const [isFilteredButtonShow, setIsFilteredButtonShow] = useState(false);
  const [saveFilter, setSaveFilter] = useState(false);
  const [isReset, setIsReset] = useState<boolean>(false);

  const [selectedDateRange, setSelectedDateRange] = useState<any>([]);
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElItem, setAnchorElItem] = React.useState<HTMLButtonElement | null>(null);
  const [selectedDomainArray,setSelectedDomainArray]=useState<any[]>([])
  const [selectedItemArray,setSelectedItemArray]=useState<any[]>([])
  const [refresh,setRefresh]=useState<boolean>(false) // state for false re-render 

  const RespondentGraphItemsData = (useSelector((s) => s.surveyReports.surveyRespondentItemsData))
  const RespondentGraphDomainsDataLoading = useSelector(
    (s) => s.surveyReports.loading.getRespondentGraphDomainData
  );
  const RespondentGraphItemsDataLoading = useSelector(
    (s) => s.surveyReports.loading.getRespondentGraphItemData
  );

  // Get states from redux
  const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
  const plan = useSelector((s) => s.plans.activePlan);
  const planId = plan?.school.id;
  const surveyDeploymentsBySchool:SurveyDeployment<number, Survey<number>>[] = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );



const schoolYearsOptions = useMemo(() => {
  const dateArray = ["07/01/2021"]; 
  // surveyDeploymentsBySchool.map((srd: any) => srd.start_date);
  const dateObjects: any = dateArray.map((dateStr) => new Date(dateStr));

  // Find the lowest and greatest dates
  const lowestDate = new Date(Math.min(...dateObjects));
  const greatestDate = new Date(Math.max(...dateObjects));

  // Create groups
  const startYear = lowestDate.getFullYear();
  const endYear = greatestDate.getFullYear()+20;
  const groups = [];

  for (let year = startYear; year <= endYear; year++) {
    const nextYear = year + 1;
    const group = `${year}-${nextYear}`;
    groups.push(group);
  }

  return groups;
}, [surveyDeploymentsBySchool]);



  //////useEffects
  useEffect(() => {
    if (planId) {
      dispatch(getSurveyDeploymentsBySchool.request(planId));
    }
  }, [planId, dispatch]);

  const respondentGroup = new Set();
  selectedGoal && selectedGoal?.targets.forEach(item => {
    if (item.demographic) {
      respondentGroup.add(item.demographic);
    }
  });


  const FilterColumn: React.FC<FilterColumnProps> = ({ labelId, labelText, value, onChange, children, disabled }) => (
    <Tooltip
      title={disabled && intl.formatMessage({ id: "app.warning.domain.item.filter.progress-monitoring" }, {
        i: (chunks) => (
          <i>{chunks}</i>
        )
      })}
      placement={"top"}
      arrow
    >
      {/* <div className="filterCol"> */}
      <div className="">
        <FormControl size="small" className="selectMargin" fullWidth>
          <InputLabel id={labelId}>{labelText}</InputLabel>
          <Select
            labelId={labelId}
            id={`${labelId}-select`}
            value={value}
            label={labelText}
            onChange={onChange}
            input={<OutlinedInput label={labelText} />}
            disabled={disabled}
          >
            {children}
          </Select>
        </FormControl>
      </div>
    </Tooltip>
  );

  const respondentGroupArray = Array.from(respondentGroup);

  const respondentGroupOptions = useMemo(() =>
    Object.values(respondentGroupArray).map((r: any, i) => ({
      label: r,
      value: i,
      translate: intl.formatMessage({ id: `app.survey-report.tabs.titles.${r}` }),
    })),
    [intl, respondentGroupArray]
  );

  function generateYearDates(year: any) {
    const startDate = new Date(parseInt(year?.split("-")[0], 10), 6, 1); // july 1, year
    const endDate = new Date(parseInt(year?.split("-")[1], 10), 5, 30); // june 30, year

    const datesArray: string[] = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      datesArray.push(moment(formattedDate).format("MM/DD/YY"));
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return datesArray;
  }
  


  useEffect(() => {
    const yearsTimeOut = setTimeout(() => {
      setSelectedDateRange([]);
      selectedYears.map((year: string,index:number) => {
        setSelectedDateRange((prevState: string[]) => [
          ...prevState,
          ...generateYearDates(year),
          index===selectedYears.length-1?`07/01/${parseInt(selectedYears[selectedYears.length-1]?.split("-")[1], 10)}`:""
          
        ]);
       
      });
    }, 1000);

    return () => {
      clearTimeout(yearsTimeOut);
    };
  }, [selectedYears]);


  const handleSaveFilter = () => {
    setSaveFilter(!saveFilter)
  }

  const handleResetFilter = () => {
    setSelectedRespondentGroup('');
    setSelectedDomain('');
    setSelectedSurveyItem('');
    setSelectedMultiAlignedStrategy([]);
    setSelectedMultiRespondentGroups([]);
    setSelectedMultiDomains([]);
    setSaveFilter(!saveFilter);
    setSelectedMultiSurveyItem([]);
    setIsReset(true);
    setSelectedDomainArray([]);
    setSelectedItemArray([]);
    setSelectedYears([]);
    dispatch(clearRespondentGraphDomainsItemsArray([]))
  };

  useEffect(() => {
    setIsFilteredButtonShow(!!graphType);
    if (graphType) {
      setSelectedDomain('');
      setSelectedMultiDomains([]);
      setSelectedRespondentGroup('');
      setSelectedMultiRespondentGroups([]);
      setSelectedSurveyItem('');
      setSelectedMultiSurveyItem([]);
      setSelectedMultiAlignedStrategy([]);
      setIsReset(true);
      handleResetFilter();
    }
    handleResetFilter();
  }, [graphType, isGoalChange]);

  const handleRespontGroupChange = (event: SelectChangeEvent) => {
    setSelectedRespondentGroup(event.target.value);
    setSelectedMultiSurveyItem([]);
  };

  const handleMultiRespondentGroupChange = (event: SelectChangeEvent<typeof selectedMultiRespondentGroup>) => {
    const { target: { value } } = event;
    setSelectedMultiRespondentGroups(typeof value === 'string' ? value.split(',') : value);
  };

  const handleMultiDomainsChange = (event: SelectChangeEvent<typeof selectedMultiDomains>) => {
    const { target: { value } } = event;
    setSelectedMultiDomains(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (selectedGoal && selectedGoal.selected_domains?.length) {
      const strategies = filterStrategiesByDomainAndSearchString(
        planStrategies,
        '',
        selectedGoal.selected_domains.map((et: any) => et.name) ?? []
      );
      setAlignStrategies(strategies);
    } else {
      setAlignStrategies([]);
    }
  }, [planStrategies, isGoalChange]);

  useEffect(() => {
    if (graphType) {
      setIsFilteredButtonShow(true);
    }
    else {
      setIsFilteredButtonShow(false);
    }

    if (graphType) {
      setSelectedDomain('');
      setSelectedMultiDomains([]);
      setSelectedRespondentGroup('');
      setSelectedMultiRespondentGroups([]);
      setSelectedSurveyItem('');
      setSelectedMultiSurveyItem([]);
      setSelectedMultiAlignedStrategy([]);
    }
  }, [graphType, isGoalChange]);

  // on change single domain
  const handleDomainChange = (event: SelectChangeEvent) => {
    setSelectedDomain(event.target.value);
    setSelectedSurveyItem('');
  };

  // on change multi aligned strategy
  const handleMultiAlignedStrategyChange = (event: SelectChangeEvent<typeof selectedMultiAlignedStrategy>) => {
    const { target: { value } } = event;
    setSelectedMultiAlignedStrategy(typeof value === 'string' ? value.split(',') : value);
  };

  const domains: Domain[] | undefined = useMemo(() => {
    return selectedGoal?.selected_domains;
  }, [selectedGoal?.selected_domains]);

  // on change survey item
  const handleSurveyItemChange = (event: SelectChangeEvent) => {
   
   
    setSelectedSurveyItem(event.target.value);

  };

  // on change multi survey items
  const handleMultiSurveyItemChange = (event: SelectChangeEvent<typeof selectedMultiSurveyItem>) => {
    
    const {
      target: { value },
    } = event;
   
    setSelectedMultiSurveyItem(
    typeof value === 'string' ? value.split(',') : value,
    );

  };

  //  Survey Item List
  const SurveyItemMenuList = useMemo(() => {
    if (!selectedGoal?.selected_domains || !selectedGoal?.targets) {
      return [];
    }

    return selectedGoal.selected_domains.flatMap((domain) => {
      const subheader = <ListSubheader key={domain.id}>{domain.name}</ListSubheader>;

      const targetItems = selectedGoal.targets
        .filter(
          (target: any) => !target.is_domain && target.title.domain === domain.id
        )
        .map((target: any) => (
           <MenuItem key={target.title.id} value= {target?.title?.lead_in?target?.title?.lead_in+' '+target.title.text:target.title.text}>
            {target?.title?.lead_in?target?.title?.lead_in+' '+target.title.text:target.title.text}
          </MenuItem> 
        ));


      const menuItems = targetItems.length > 0 ? [subheader, ...targetItems] : [subheader, <MenuItem key="no-items" disabled>No items in this goal.</MenuItem>];
      return menuItems;
    });
  }, [selectedGoal?.selected_domains, selectedGoal?.targets]);

  // Multi Survey Item List
  const multiSurveyItemMenuList = useMemo(() => {
    if (!selectedGoal?.selected_domains || !selectedGoal?.targets) {
      return [];
    }
    let subheader = <ListSubheader >{'Select'}</ListSubheader>;
    const filteredData1 = selectedGoal?.targets.filter((target) => target.demographic === selectedRespondentGroup);

    return selectedGoal.selected_domains.flatMap((domain) => {

      // Filtered data as per respondent group
      const filteredData = selectedGoal?.targets.filter((target) => target.demographic === selectedRespondentGroup);

      subheader = <ListSubheader key={domain.id}>{domain.name}</ListSubheader>;
      const targetItems = filteredData
        .filter(
          (target: any) => !target.is_domain && target.title.domain === domain.id
        )
        .map((target: any) => (
          
          <MenuItem 
          key={(target?.title?.lead_in ? target?.title?.lead_in + " " + target.title.text : target.title.text).trim()} 
          value={(target?.title?.lead_in ? target?.title?.lead_in + " " + target.title.text : target.title.text).trim()} 
          
          className="checkbox-list">



            <Checkbox 
            checked={selectedMultiSurveyItem.indexOf((target?.title?.lead_in ? target?.title?.lead_in + " " + target.title.text : target.title.text).trim()) > -1} />
            
            <ListItemText 
            primary={(target?.title?.lead_in ? target?.title?.lead_in + " " + target.title.text : target.title.text).trim()} />
          </MenuItem>

        ));
      const menuItems = targetItems.length > 0 ? [subheader, ...targetItems] : [subheader, <MenuItem key="no-items" disabled>No items in this goal.</MenuItem>];
      
      return menuItems;

      // return [subheader, ...targetItems];
    });
    // }

  }, [selectedGoal?.selected_domains, selectedGoal?.targets, selectedMultiSurveyItem, selectedRespondentGroup]);


  // Align Strategies option of Selected Goal
  const alignStrategiesOptions = useMemo(() => {
    return Object.values(alignStrategies.filter(s => s.implementations && s.implementations.length > 0)).map((r, i) => ({
      label: r.strategy.name,
      value: r.strategy.id,
    }));
  }, [alignStrategies]);

  // other Strategies option of Selected Goal
  const otherStrategiesOptions = useMemo(() => {

    const otherStrategies = planStrategies.filter((pls: any) => !alignStrategies.some((als: any) => als.id === pls.id));

    return Object.values(otherStrategies.filter(s => s.implementations && s.implementations.length > 0)).map((r, i) => ({
      label: r.strategy.name,
      value: r.strategy.id
    }));
  }, [alignStrategies]);

  // Multi alignStratgy List
  const multiAlignStratgyMenuList = useMemo(() => {

    let subheader = <ListSubheader key={"Align Strategies"}>{intl.formatMessage({ id: "app.goals.card.aligned-strategies" })}</ListSubheader>;
    const alignStrategiesList =
      alignStrategiesOptions &&
      alignStrategiesOptions.map((strategy) => {
        return (
          <MenuItem
            key={strategy.label}
            value={strategy.label}
            className="checkbox-list"
          >
            <input
              type="checkbox"
              style={{ marginBottom: 0, marginRight: 5, height: 15, width: 15 }}
              checked={
                selectedMultiAlignedStrategy.includes(strategy.label)
                  ? true
                  : false
              }
            />
            <ListItemText primary={strategy.label} />
          </MenuItem>
        );
      });

    let subheader2 = (
      <ListSubheader key={"Other Strategies"}>
        {intl.formatMessage({ id: "app.topic-tag.other" })}{" "}
        {intl.formatMessage({ id: "app.titles.strategies" })}
      </ListSubheader>
    );
    const otherStrategiesList =
      otherStrategiesOptions &&
      otherStrategiesOptions.map((strategy) => (
        <MenuItem
          key={strategy.label}
          value={strategy.label}
          className="checkbox-list"
        >
          <input
            type="checkbox"
            style={{ marginBottom: 0, marginRight: 5, height: 15, width: 15 }}
            checked={
              selectedMultiAlignedStrategy.includes(strategy.label)
                ? true
                : false
            }
          />
          <ListItemText primary={strategy.label} />
        </MenuItem>
      ));


    const menuItems = alignStrategies?.length > 0 ? [subheader, ...alignStrategiesList] : [subheader, <MenuItem key="no-items" disabled>No Align strategies in this goal.</MenuItem>];
    const othermenuItems = planStrategies?.length > 0 ? [subheader2, ...otherStrategiesList] : [subheader2, <MenuItem key="no-items" disabled>No other strategies in this goal.</MenuItem>];

    return [...menuItems, ...othermenuItems];


    // }

  }, [selectedGoal?.selected_domains, selectedGoal?.targets, selectedMultiSurveyItem, selectedRespondentGroup, selectedMultiAlignedStrategy]);

  // Align strategies of selected goal 
  useEffect(() => {
    if (selectedGoal && selectedGoal.selected_domains?.length) {
      let strategies = filterStrategiesByDomainAndSearchString(
        planStrategies,
        '',
        selectedGoal.selected_domains.map((et: any) => et.name) ?? []
      )
      setAlignStrategies(strategies)
    } else {
      setAlignStrategies([])
    }
  }, [planStrategies, isGoalChange]);


  const isDisabledSave = useMemo(() => {
    const isDisable = ((selectedDateRange.length && ( selectedDomainArray?.length > 0  || selectedItemArray.length > 0 )) ? false : true)

    return isDisable

  }, [selectedDateRange,
   
    selectedDomainArray.length,
    selectedItemArray.length,
    selectedMultiSurveyItem])
    

    const open = Boolean(anchorEl);
    const itempopupOpen=Boolean(anchorElItem)

    const handleClose=() => {
      setAnchorEl(null)
      setAnchorElItem(null)
    }
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClickItem = (event: React.MouseEvent<HTMLButtonElement>) => {

      setAnchorElItem(event.currentTarget)
    };
    
    const id = useMemo(() => {
      return open ? 'simple-popover' : undefined;
    }, [open]);

    const itemId = useMemo(() => {
      return itempopupOpen ? 'item-popover' : undefined;
    }, [open]);



const selectedDomains=selectedGoal?.selected_domains

const selectedDomainDemographic=(domainName:string,demographic:string,isChecked:boolean)=>{
  setRefresh(!refresh) // for re render of checkbox 

 if(selectedDomainArray.find((obj) => obj.domain === domainName && obj.demographic===demographic)){
   selectedDomainArray.splice(selectedDomainArray.findIndex((obj) => obj.domain === domainName && obj.demographic===demographic),1)
 }else{
 
   selectedDomainArray.push({domain:domainName,demographic:demographic,isChecked:isChecked})
 }
  
}

//('selectedDomainArray ', JSON.stringify(selectedDomainArray))
    const domainSelector=()=>{
      return (
        <>
          <FormControl size="small" className="selectMargin" fullWidth>
            <InputLabel htmlFor="survey-item-multiple-checkbox-label">
              {intl.formatMessage({ id: "app.title.select-domain" })}s
            </InputLabel>
            <Select
              id="survey-item-multiple-checkbox-label"
              label="survey-item-multiple-checkbox"
              multiple
              value={selectedDomainArray}
              // onChange={handleMultiSurveyItemChange}

              renderValue={(selected) =>
                selectedDomainArray
                  .map((domain: any) => domain.domain)
                  .join(", ")
              }
            >
              {selectedDomains?.map((d) => {
                return (
                  <div className="planing-goal__button-group progressM__button-group">
                    <div className="popover-list">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Label>{d?.name}</Label>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul>
                            {" "}
                            {selectedGoal?.targets
                              ?.filter(
                                (goal: any) =>
                                  goal?.domain_priority?.domain?.name === d.name
                              )
                              ?.map((goal: any) => (
                                <ol>
                                  <Checkbox
                                    id={d?.name + goal?.demographic}
                                    checked={selectedDomainArray.find(
                                      (s: any) =>
                                        s.domain === d?.name &&
                                        s.demographic === goal?.demographic
                                    )}
                                    onClick={() =>
                                      selectedDomainDemographic(
                                        d?.name,
                                        goal?.demographic,
                                        true
                                      )
                                    }
                                  >
                                    <label
                                      className="text-black"
                                      htmlFor={d?.name + goal?.demographic}
                                    >
                                      {intl.formatMessage({
                                        id: `app.filters.respondent-type.${goal?.demographic}`,
                                      })}{" "}
                                    </label>
                                  </Checkbox>
                                </ol>
                              ))}
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                );
              })}
              {!!selectedDomainArray.length && (
                <div className="clear-btn">
                  <button onClick={() => setSelectedDomainArray([])}>
                    <Icon icon="cross" iconSize={16} />
                    {intl.formatMessage({ id: "app.titles.clear-all" })}
                  </button>
                </div>
              )}
            </Select>
          </FormControl>
        </>
      );
  }
  
  const groupedItems:any = {};
  
  const getDomainItemsOption = (name: string) => {
    selectedGoal?.targets
      ?.filter(
        (tar: any) =>
          tar?.type === "item_level" &&
          tar?.item_priority?.domain?.name === name
      )
      .forEach((it: any) => {
        const key = `${it.item_priority.domain.name}`; // Create a unique key using domain name

        if (!groupedItems[key]) {
          groupedItems[key] = []; // Initialize an empty array for each unique key
        }

        // Push the item text to the corresponding key array
        groupedItems[key].push({[it.demographic]:{text:it.item_priority?.item?.text,leadIn:it.item_priority?.item?.lead_in}});
      });
  };

  const groupedValuesArray = (d: any) => {
    const groupedValues: any = [];
    groupedItems[d]?.forEach((item: any) => {
      const key = Object.keys(item)[0]; // Get the key of each object

      if (!groupedValues[key]) {
        groupedValues[key] = []; // Initialize an empty array for each unique key
      }
      // Push the value to the corresponding key array
      groupedValues[key].push(item[key]);
    });
    return groupedValues;
  };

  const selectedDomainItemDemographic:any=(domainName:string,demographic:string,text:string,leadIn:string,isChecked:boolean)=>{
    setRefresh(!refresh) // for re render of checkbox 
    if(selectedItemArray.find((obj) => obj.domain === domainName && obj.demographic===demographic && obj.text===text)){
      selectedItemArray.splice(selectedItemArray.findIndex((obj) => obj.domain === domainName && obj.demographic===demographic && obj.text === text),1)
    }else{
      selectedItemArray.push({domain:domainName,demographic:demographic,text:text,leadIn:leadIn,isChecked:isChecked})
    } 
   }

  const ItemSelector = () => {
    return (
      <>
      <FormControl size="small" className="selectMargin" fullWidth>
          <InputLabel htmlFor="survey-item-multiple-checkbox-label">
            {intl.formatMessage({ id: "app.titles.select" }).slice(0, 6) +
              " " +
              intl.formatMessage({ id: "app.titles.item" }) +
              "s"}
          </InputLabel>
          <Select
            id="survey-item-multiple-checkbox-label"
            label="survey-item-multiple-checkbox"
            multiple
            value={selectedItemArray}
            // onChange={handleMultiSurveyItemChange}

            renderValue={(selected) =>
              selectedItemArray.map((item: any) => item.text).join(", ")
            }
          >
          <div style={{height:"350px",overflowY:"scroll"}}> 
            {selectedDomains?.map((d) => {
              return (
                <div className="planing-goal__button-group progressM__item-group">
                  <div className="popover-list">
                    <Label className="ml-5 font-bold itemLbl">{d?.name}</Label>
                    <hr />
                    {getDomainItemsOption(d?.name)}
                    {
                      <div className="ml-5 text-sm" style={{maxHeight:"200px",overflowY:"scroll",overflowX:"hidden"}}>
                        {Object.keys(groupedValuesArray(d?.name)).map(
                          (item: any) => (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <div>
                                  <label>
                                    {intl.formatMessage({
                                      id: `app.filters.respondent-type.${item}`,
                                    })}
                                  </label>
                                </div>
                              </AccordionSummary>
                              <div></div>
                              <AccordionDetails>
                              <div>
                                  <ul>
                                  {groupedItems[d?.name].map((it: any, itIndex:any) => (
                                      <ol>
                                        {Object.keys(it) == item && (
                                          <>
                                            <Checkbox
                                            id={it[item]?.text}
                                              checked={
                                                selectedItemArray.find(
                                                  (obj) =>
                                                    obj.domain === d?.name &&
                                                    obj.demographic === item &&
                                                    obj.text === it[item].text
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                selectedDomainItemDemographic(
                                                  d?.name,
                                                  item,
                                                  it[item].text,
                                                  it[item].leadIn,
                                                  true
                                                )
                                              }
                                            >
                                              <label
                                                className="text-black"
                                                htmlFor={it[item]?.text}
                                              >
                                                {it[item].leadIn ?? ""}&nbsp;
                                                {it[item].text}
                                              </label>
                                            </Checkbox>
                                          </>
                                        )}
                                      </ol>
                                    ))}
                                  </ul>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </div>
                    }
                  </div>
                </div>
              );
            })}
          </div>

            {!!selectedItemArray.length && (
              <div className="clear-btn">
                <button onClick={() => setSelectedItemArray([])}>
                  <Icon icon="cross" iconSize={16} />
                  {intl.formatMessage({ id: "app.titles.clear-all" })}
                </button>
              </div>
            )}
          </Select>
        </FormControl>
      </>
    );
}


const warningToast = () => {
  return AppToaster.show({
    message: intl.formatMessage({
      id: "app.schools.select-school-years-warning",
    }),
    intent: "danger",
  });
}

// const onSelectSchoolYears=(year:string,index:number,event:any)=>{
//   setRefresh(!refresh) // for re render of checkbox 

  // const fiveYearCheck = selectedYears.length > 0
  //     ? parseInt(selectedYears[selectedYears.length - 1]?.split("-")[1], 10) -
  //         parseInt(year?.split("-")[1], 10) <
  //       MAXIMUM_YEARS_SELECTION_LIMIT
  //     : true;

  // if (selectedYears.length < MAXIMUM_YEARS_SELECTION_LIMIT && fiveYearCheck) {
  //   setSelectedYears((prevSelectedYears) => {
  //     const newSelectedYears = [...prevSelectedYears, year];
  //     newSelectedYears.sort();

//       if (newSelectedYears.length > 1) {
//         const startYear = parseInt(newSelectedYears[0].split("-")[0], 10);
//         const endYear = parseInt(newSelectedYears[newSelectedYears.length - 1].split("-")[1],10);

//         // make Array of multi selected years Length <=5 
//         const allYearsBetween = Array.from({length:endYear - startYear <= MAXIMUM_YEARS_SELECTION_LIMIT? endYear - startYear: 1,},(_, index) =>
//             startYear + index + "-" + (startYear + (index + (2 - 1))).toString()
//         );
//         return allYearsBetween;
//       }

//       return newSelectedYears;
//     });
//   } else {
//     // show warning of maximum five selection when select five and try to select more than 5 from previous years
//     warningToast()
//   }

//   if (selectedYears.find((y) => y === year)) {
//     const filterYears = selectedYears.filter((item: any) => item !== year);
//     setSelectedYears(filterYears);
//   }

//   const selected1 = selectedYears? parseInt(selectedYears[0]?.split("-")[0], 10): 0;
//   const selected2 = selectedYears ? parseInt(year?.split("-")[1], 10) : 0;

//   // show warning of maximum five selection when try to select more than 5 years
//   (selected2 - selected1 > MAXIMUM_YEARS_SELECTION_LIMIT || 
//     (selectedYears.length === MAXIMUM_YEARS_SELECTION_LIMIT && selected2 <= selected1)) &&
//     warningToast()

// }


let alertShown = false;

const onSelectSchoolYears = (year: string, index: number, event: any) => {
    setRefresh(!refresh); // for re-render of checkbox 

    const fiveYearCheck = selectedYears.length > 0
        ? parseInt(selectedYears[selectedYears.length - 1]?.split("-")[1], 10) -
            parseInt(year?.split("-")[1], 10) <
            MAXIMUM_YEARS_SELECTION_LIMIT
        : true;

    if (selectedYears.length < MAXIMUM_YEARS_SELECTION_LIMIT && fiveYearCheck) {
        setSelectedYears((prevSelectedYears) => {
            const newSelectedYears = [...prevSelectedYears, year];
            newSelectedYears.sort();

            if (newSelectedYears.length > 1) {
                const startYear = parseInt(newSelectedYears[0].split("-")[0], 10);
                const endYear = parseInt(newSelectedYears[newSelectedYears.length - 1].split("-")[1],10);

                // make Array of multi selected years Length <=5 
                const allYearsBetween = Array.from({length:endYear - startYear <= MAXIMUM_YEARS_SELECTION_LIMIT? endYear - startYear: 1,},(_, index) =>
                    startYear + index + "-" + (startYear + (index + (2 - 1))).toString()
                );
                return allYearsBetween;
            }

            return newSelectedYears;
        });
    } else if (selectedYears.length > MAXIMUM_YEARS_SELECTION_LIMIT && !alertShown) {
        // Show warning of maximum five selection only if it hasn't been shown before
        warningToast();
        alertShown = true;
    } else if (selectedYears.length <= MAXIMUM_YEARS_SELECTION_LIMIT) {
        // Reset the flag when the number of selected years goes below the limit
        alertShown = false;
    }

    if (selectedYears.find((y) => y === year)) {
        const filterYears = selectedYears.filter((item: any) => item !== year);
        setSelectedYears(filterYears);
    }

    const selected1 = selectedYears? parseInt(selectedYears[0]?.split("-")[0], 10): 0;
    const selected2 = selectedYears ? parseInt(year?.split("-")[1], 10) : 0;

        // show warning of maximum five selection when try to select more than 5 years    
        (selected2 - selected1 > MAXIMUM_YEARS_SELECTION_LIMIT || (selected1+1) - (selected2-1) > MAXIMUM_YEARS_SELECTION_LIMIT ||
        (selectedYears.length === MAXIMUM_YEARS_SELECTION_LIMIT && selected2 <= selected1)) &&
        warningToast();
};

return (
  <div className="progressM_filterlist">
    <div className="filterbar">
      <span className="filter-icon">
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.10791 0.369736L6.60393 7.87166V15.3736L10.316 17.249V7.8713L16.812 0.369385H8.46013L0.10791 0.369736Z" fill="#8B8B8B" />
        </svg>
      </span>
      <div className="filtergroup">
        <div className="filterCol">
        <FormControl size="small" className="selectMargin" fullWidth>
            <InputLabel htmlFor="school-year-multiple-checkbox-dates-label">
              {intl.formatMessage({ id: "app.schools.select-school-years" })}
            </InputLabel>
            <Select
              id="school-year-multiple-checkbox-dates-label"
              label="school-year-multiple-checkbox-dates"
              multiple
              value={selectedYears}
              // onChange={handleMultiSurveyItemChange}

              renderValue={(selected) =>
                selectedYears
                .map((item: any) => item.slice(0, item.length))
                  .join(", ")
              }
            >
               <div>     
               <h6 className='ml-1'>
               School Years Selected({selectedYears.length})
                </h6>
                <hr />
              <div
                style={{
                  height: "200px",
                  width: "auto",
                  overflowY: "scroll",
                }}
              >
                <div className="planing-goal__button-group progressM__item-group">
                <div className="popover-list-Selected-year">
                    {schoolYearsOptions.map(
                      (year: string, index: number) => {
                        return (
                          <div className="planing-goal__button-group progressM__item-group">
                            <div className="popover-list-Selected-year">
                              <MenuItem
                                key={year}
                                value={year}
                                className="checkbox-list"
                              >
                                <Checkbox
                                  id={"school-year-checkbox" + year}
                                  onClick={(event) =>
                                    onSelectSchoolYears(year, index, event)
                                  }
                                  checked={
                                    selectedYears.find(
                                      (y: string) => y === year
                                    )
                                      ? true
                                      : false
                                  }
                                >
                                  {year}
                                </Checkbox>
                              </MenuItem>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
                {!!selectedYears.length && (
                  <div className="clear-btn">
                    <button onClick={() => setSelectedYears([])}>
                      <Icon icon="cross" iconSize={16} />
                      {intl.formatMessage({ id: "app.titles.clear-all" })}
                    </button>
                  </div>
                )}
            </div>
            </Select>
          </FormControl>

         
        </div>
        {
          graphType === GraphTypeEnum.INDICATOR_GRAPH ? (
            <>
              {/* Respondent Group Dropdown */}
              <div className="filterCol">
                < FormControl size="small" className="selectMargin" fullWidth>
                  <InputLabel id="respondent-groups-multiple-checkbox-label">{intl.formatMessage({ id: "app.titles.stakeholder-group" })}</InputLabel>
                  <Select
                    labelId="respondent-groups-multiple-checkbox-label"
                    id="respondent-groups-multiple-checkbox"
                    multiple
                    value={selectedMultiRespondentGroup}
                    onChange={handleMultiRespondentGroupChange}
                    input={<OutlinedInput label={intl.formatMessage({ id: "app.titles.stakeholder-group" })} />}
                    renderValue={(selected) => selected.map(group => intl.formatMessage(
                      { id: "app.progress-monitoring.respondent-groups-dropdown" },
                      { target: group }
                    )).join(', ')}
                  >
                    {respondentGroupOptions.map((item) => (
                      <MenuItem key={item.label} value={item.label} className="checkbox-list">
                        <Checkbox checked={selectedMultiRespondentGroup.indexOf(item.label) > -1} />
                        <ListItemText primary={item.translate} />
                      </MenuItem>
                    ))}
                    {
                      !!selectedMultiRespondentGroup.length && (
                        <div className="clear-btn">
                          <button onClick={() => setSelectedMultiRespondentGroups([])}><Icon icon="cross" iconSize={16} />{intl.formatMessage({ id: "app.titles.clear-all" })}</button>
                        </div>
                      )
                    }
                  </Select>
                </FormControl>
              </div>

              {/* Domain Dropdown */}
              <div className="filterCol">
                <FilterColumn
                  labelId="domain-select-small-label"
                  labelText={intl.formatMessage({ id: "app.titles.domain" })}
                  value={selectedDomain}
                  onChange={handleDomainChange}
                  disabled={!!selectedSurveyItem}
                >
                  {domains && domains.map(doamin => (
                    <MenuItem key={doamin.name} value={doamin.name} className="status-dropdown">
                      {doamin.name}
                    </MenuItem>
                  ))}
                  {
                    !!selectedDomain && (
                      <div className="clear-btn">
                        <button onClick={() => setSelectedDomain('')}><Icon icon="cross" iconSize={16} />{intl.formatMessage({ id: "app.titles.clear-all" })}</button>
                      </div>
                    )
                  }
                </FilterColumn>
              </div>

              {/* Survey Item Dropdown */}

              {/* <Tooltip title="Select Respondent Group First"  arrow> */}

              <div className="filterCol">
                <FilterColumn
                  labelId="survey-item-select-small-label"
                  labelText={intl.formatMessage({ id: "app.titles.survey-item" })}
                  value={selectedSurveyItem}
                  onChange={handleSurveyItemChange}
                  disabled={!!selectedDomain}

                >
                  {/* Render selected domains and their targets */}
                  {SurveyItemMenuList}
                  {
                    !!selectedSurveyItem && (
                      <div className="clear-btn">
                        <button onClick={() => setSelectedSurveyItem('')}><Icon icon="cross" iconSize={16} />{intl.formatMessage({ id: "app.titles.clear-all" })}</button>
                      </div>
                    )
                  }

                </FilterColumn>

              </div>
              {/* </Tooltip> */}

            </>
          )
            : (
              <>
                {/* Respondent Group Dropdown */}
                <div className="filterCol">
                {domainSelector()}
                </div>
                {/* Multi Domain Dropdown */}

                {/* Multi Survey Items Dropdown */}

                {!selectedRespondentGroup ? (
                    <div className="filterCol" >
                      {ItemSelector()}
                    </div>
                ) : (
                  <div className="filterCol">
                    <FormControl size="small" className="selectMargin" fullWidth>
                      <InputLabel htmlFor="survey-item-multiple-checkbox-label">{intl.formatMessage({ id: "app.goals.target-indicator.survey-items" })}</InputLabel>
                      <Select
                        id="survey-item-multiple-checkbox-label"
                        label="survey-item-multiple-checkbox"
                        multiple
                        value={selectedMultiSurveyItem}
                        onChange={handleMultiSurveyItemChange}

                        renderValue={(selected) => selected.map(surveyItem => surveyItem).join(', ')}
                      >
                        {/* Render selected domains and their targets */}
                        {multiSurveyItemMenuList}
                        {
                          !!selectedMultiSurveyItem.length && (
                            <div className="clear-btn">
                              <button onClick={() => setSelectedMultiSurveyItem([])}><Icon icon="cross" iconSize={16} />{intl.formatMessage({ id: "app.titles.clear-all" })}</button>
                            </div>
                          )
                        }
                      </Select>
                    </FormControl>
                  </div>
                )}

              </>
            )
        }

        {/* Multi Aligned Strategies */}
        <div className="filterCol">
          < FormControl size="small" className="selectMargin" fullWidth>
            <InputLabel id="aligned-strategy-multiple-checkbox-label">{intl.formatMessage({ id: "app.titles.strategies" })}</InputLabel>
            <Select
              labelId="aligned-strategy-multiple-checkbox-label"
              id="aligned-strategy-multiple-checkbox"
              multiple
              value={selectedMultiAlignedStrategy}
              onChange={handleMultiAlignedStrategyChange}
              input={<OutlinedInput label={intl.formatMessage({ id: "app.goals.card.aligned-strategies" })} />}
              renderValue={(selected) => selected.map(strategy => strategy).join(', ')}
            >
              {multiAlignStratgyMenuList}
              {
                !!selectedMultiAlignedStrategy.length && (
                  <div className="clear-btn">
                    <button onClick={() => setSelectedMultiAlignedStrategy([])}><Icon icon="cross" iconSize={16} />{intl.formatMessage({ id: "app.titles.clear-all" })}</button>
                  </div>
                )
              }
            </Select>
          </FormControl>
        </div>
      </div>
      {/* Save Filter and Clear Filter Button */}
      {
        isFilteredButtonShow && (
          RespondentGraphDomainsDataLoading || RespondentGraphItemsDataLoading ?
          <Spinner intent="primary" size={30} />
          :
         ( <div className="progressM_filterlist-listSave">
            <Tooltip title={isDisabledSave ? intl.formatMessage({ id: "app.warning.save.filter.progress-monitoring" }) : intl.formatMessage({ id: "app.titles.save-filter" })} arrow>
              <span>
                <button className="filtersave" disabled={isDisabledSave} onClick={handleSaveFilter}><Icon iconSize={16} icon="tick" /></button>
              </span>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: "app.titles.clear-filter" })} arrow>
              <button className="filtercancel" onClick={handleResetFilter}><Icon iconSize={16} icon="cross" /></button>
            </Tooltip>
          </div>)
        )
      }
    </div>
    <div className="monitoring-content">
      {/* for Progress content show i.e graph */}
      <ProgressContent
        graphType={graphType}
        selectedRespondentGroup={selectedRespondentGroup}
        selectedGoal={selectedGoal}
        selectedDateRange={selectedDateRange}
        selectedMultiDomains={selectedDomainArray}
        selectedAlignedStrategy={selectedMultiAlignedStrategy}
        selectedMultiSurveyItem={selectedItemArray}
        alignStrategies={planStrategies}
        //alignStrategies={alignStrategies} Old logic
        selectedMultiRespondentGroup={selectedMultiRespondentGroup}
        saveFilter={saveFilter}
        selectedDomain={selectedDomain}
        selectedSurveyItem={selectedSurveyItem}
      />
    </div>
  </div>
);
}

export default ProgressListFilter;
