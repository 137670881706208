import React, { FunctionComponent, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { ChartDataSet } from "../../types";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { pickTextColorBasedOnBgColor } from "./utils";
import _ from "lodash";

type OwnProps = {
  dataSet: ChartDataSet;
  barPercentage?: number;
  minimalDaraPercent?: number;
  height?: number;
  width?: number;
  index?: number;
  colors?: string[];
  labelless?: boolean;
};

type Props = OwnProps;

const DEFAULT_CHART_AREA_HEIGHT = 100;
const MINIMAL_DATA_PERCENT = 25;
const MULTILINE_DIVIDER = " ";

const X_AXIS_TICK_FONT_SIZE = 16;
const X_AXIS_TICK_LINE_HEIGHT = 1.2;
const X_AXIS_TICK_LABEL_LINE_HEIGHT =
  X_AXIS_TICK_FONT_SIZE * X_AXIS_TICK_LINE_HEIGHT; // fontSize * lineHeight of xAxis tick

const DEFAULT_LABEL_LINE_OFFSET = 17.68; // todo find how it is calculated f(X_AXIS_TICK_FONT_SIZE, X_AXIS_TICK_LINE_HEIGHT); it's value for 16 and 1.2

const BACKGROUND_COLORS = [
  "#003f5c",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];

const BarChart: FunctionComponent<Props> = ({
  dataSet,
  barPercentage,
  minimalDaraPercent,
  height,
  width,
  index,
  colors,
  labelless,
}) => {
  const data: any = useMemo(() => {
    const colorsToUse = !!colors
      ? colors
      : index !== undefined
      ? BACKGROUND_COLORS[index % BACKGROUND_COLORS.length]
      : BACKGROUND_COLORS;

    return {
      labels: labelless ? dataSet.data : dataSet.labels,
      datasets: [
        {
          backgroundColor: colorsToUse,
          categoryPercentage: 1.0,
          barPercentage: barPercentage ?? 0.5,
          data: dataSet.data,
          datalabels: {
            color: function (context: Context) {
              const index = context?.dataIndex;
              if (
                context?.dataset?.data &&
                (context.dataset.data[index] as number) <
                  (minimalDaraPercent ?? MINIMAL_DATA_PERCENT)
              ) {
                return "#000000";
              }

              if (colorsToUse && index < colorsToUse.length) {
                const color = colorsToUse[index];
                return pickTextColorBasedOnBgColor(color, "#ffffff", "#000000");
              } else {
                return "#ffffff";
              }
            },
            align: function (context: Context) {
              const index = context?.dataIndex;
              if (
                context?.dataset?.data &&
                (context.dataset.data[index] as number) <
                  (minimalDaraPercent ?? MINIMAL_DATA_PERCENT)
              ) {
                return "top";
              }
              return "center";
            },
            formatter: function (value: number) {
              return value.toFixed(2).replace(".00", "") + "%";
            },
            font: {
              weight: "bold",
              size: 16,
              lineHeight: 1.5,
              family: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
            },
          },
        },
      ],
    };
  }, [colors, dataSet, labelless]);

  const makeMultiLine = (labels: string | string[]): string[] => {
    if (typeof labels == "string") {
      return labels.split(MULTILINE_DIVIDER);
    } else {
      return labels;
    }
  };

  const options: any = useMemo(
    //todo extend base types
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              max: 100,
              min: 0,
              stepSize: 10,
            },
            display: false,
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontSize: X_AXIS_TICK_FONT_SIZE,
              lineHeight: X_AXIS_TICK_LINE_HEIGHT,
              fontFamily: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
              fontStyle: "bold",
              callback: makeMultiLine,
            },
            display: !labelless,
          },
        ],
      },
    }),
    [labelless]
  );

  const containerHeight = useMemo(() => {
    const axisLinesMaxSize: number =
      _.chain(data.labels)
        .map((d) => makeMultiLine(d).length)
        .max()
        .value() ?? 0;
    return (
      (height ?? DEFAULT_CHART_AREA_HEIGHT) +
      axisLinesMaxSize * X_AXIS_TICK_LABEL_LINE_HEIGHT +
      DEFAULT_LABEL_LINE_OFFSET
    );
  }, [height, data.labels]);

  return (
    <div style={{ height: containerHeight }}>
      <Bar
        width={width}
        data={data}
        options={options}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
};

export default BarChart;
