import React, { FunctionComponent } from "react";
import { Classes, Dialog, Icon } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { TeamRoleDefinition } from "../../../../types";
import RoleCardCollapse from "./RoleCardCollapse";
import useTeamRoleDefinitions from "../../../../helpers/hooks/useTeamRoleDefinitions";
import { useDispatch, useSelector } from "react-redux";
import { hideUnderstandingRolesDialog } from "../../../../store/team-members/actions";

type OwnProps = {};

type Props = OwnProps;

const UnderstandingRolesDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const show = useSelector(
    (s) => s.teamMembers.dialogs.understandingRolesDialog.show
  );
  const handleDialogClose = () => {
    dispatch(hideUnderstandingRolesDialog());
  };

  const roles: TeamRoleDefinition[] = useTeamRoleDefinitions();

  return (
    <Dialog
      className="roleDialoge"
      isOpen={show}
      onClose={handleDialogClose}
  
      title={intl.formatMessage({
        id: "app.team-plan.team.understanding-roles",
      })}
    >
      <div className={`${Classes.DIALOG_BODY} space-y-2 `}>
        <div className="text-center">
          <div >
            {intl.formatMessage({ id: "app.titles.role.dclt.full-name" })} | {intl.formatMessage({ id: "app.titles.role.sclt.full-name" })}
          </div>
        </div>

        {roles.map((role) => (
          <RoleCardCollapse key={role.role} teamRoleDefinition={role} />
        ))}
      </div>
    </Dialog>
  );
};

export default UnderstandingRolesDialog;
