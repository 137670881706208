import React, { FunctionComponent } from "react";
import { Button } from "@blueprintjs/core";
import { CalendarOptions, ICalendar } from "datebook";
import { useIntl } from "react-intl";
import { Meeting } from "../../../../types";

type OwnProps = {
  meeting?: Meeting<string, false>;
};

const HTML_TAG_REG_EXP = /(<([^>]+)>)/gi;

type Props = OwnProps;

const DownloadIcsFileButton: FunctionComponent<Props> = (props) => {
  const { meeting } = props;

  const intl = useIntl();

  const handleDownloadIcsFile = () => {
    if (!meeting) {
      return;
    }

    let config: CalendarOptions = {
      title: meeting.title,
      description: meeting.general_notes?.replace(HTML_TAG_REG_EXP, ""),
      start: new Date(meeting.date),
      // location: intl.formatMessage({ id: "app.title" }),
    };

    if (meeting.end_date && meeting.end_date !== meeting.date) {
      config.end = new Date(meeting.end_date);
    }

    const icalendar = new ICalendar(config);
    icalendar.download();
  };

  return (
    <Button
      intent="primary"
      text={intl.formatMessage({
        id: "app.titles.download-ics-file",
      })}
      title={intl.formatMessage({
        id: "app.titles.download-ics-file",
      })}
      onClick={handleDownloadIcsFile}
    />
  );
};

export default DownloadIcsFileButton;
