import { Epic } from "redux-observable";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import { catchError, delay, filter, map, switchMap } from "rxjs/operators";
import actions from "../actions";
import api from "../../api";
import { from, of } from "rxjs";

export const getSchoolsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getSchools.request)),
    switchMap((action) =>
      from(api.schools.getSchools()).pipe(
        map(actions.getSchools.success),
        catchError((error) => of(actions.getSchools.failure(error)))
      )
    )
  );
};

export const getSchoolsByDistrictEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSchoolsByDistrict.request)),
    switchMap((action) =>
      from(api.schools.getSchoolsByDistrict(action.payload)).pipe(
        map(actions.getSchoolsByDistrict.success),
        catchError((error) => of(actions.getSchoolsByDistrict.failure(error)))
      )
    )
  );
};

export const addSchoolsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addSchool.request)),
    switchMap((action) =>
      from(api.schools.addSchool(action.payload)).pipe(
        map(actions.addSchool.success),
        catchError((error) => of(actions.addSchool.failure(error)))
      )
    )
  );
};

export const updateSchoolEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updateSchool.request)),
    switchMap((action) =>
      from(api.schools.updateSchool(action.payload)).pipe(
        map(actions.updateSchool.success),
        catchError((error) => of(actions.updateSchool.failure(error)))
      )
    )
  );
};

export const deleteSchoolEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteSchool.request)),
    switchMap((action) =>
      from(api.schools.deleteSchool(action.payload)).pipe(
        map(() => actions.deleteSchool.success(action.payload)),
        catchError((error) => of(actions.deleteSchool.failure(error)))
      )
    )
  );
};

export const getSchoolsByDistrictsEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSchoolsByDistricts.request)),
    switchMap((action) =>
      from(api.schools.getSchoolsByDistricts(action.payload)).pipe(
        map(actions.getSchoolsByDistricts.success),
        catchError((error) => of(actions.getSchoolsByDistricts.failure(error)))
      )
    )
  );
};
