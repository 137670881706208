import React, { FunctionComponent, useMemo } from "react";
import { Popover, PopoverPosition } from "@blueprintjs/core";
import OverallMeanStepContent from "./steps/OverallMeanStepContent";
import GroupingStepContent from "./steps/GroupingStepContent";
import OverallResponseDistributionStepContent from "./steps/OverallResponseDistributionStepContent";
import TotalRespondentsStepContent from "./steps/TotalRespondentsStepContent";
import ResponseDistributionStepContent from "./steps/ResponseDistributionStepContent";
import TopicAreaStepContent from "./steps/TopicAreaStepContent";
import MeanContent from "./steps/MeanContent";

type OwnProps = {
  forNegativeDomains?: boolean;
  dataTour?: string;
  disabled?: boolean;
};

type Props = OwnProps;

const TourStepPopover: FunctionComponent<Props> = (props) => {
  const { forNegativeDomains } = props;

  const content: JSX.Element | string = useMemo(() => {
    let res;
    switch (props.dataTour) {
      case "overall-mean":
        res = (
          <OverallMeanStepContent forNegativeDomains={forNegativeDomains} />
        );
        break;
      case "grouping":
        res = <GroupingStepContent />;
        break;
      case "topic-area":
        res = <TopicAreaStepContent />;
        break;
      case "overall-response-distribution":
        res = <OverallResponseDistributionStepContent />;
        break;
      case "total-respondents":
        res = <TotalRespondentsStepContent />;
        break;
      case "response-distribution":
        res = <ResponseDistributionStepContent />;
        break;
      case "mean-Content":
        res = <MeanContent />;
        break;
      default:
        res = null;
    }
    return <div className="p-2">{res}</div>;
  }, [props.dataTour, forNegativeDomains]);

  if (props.disabled) {
    return <>{props.children}</>;
  }

  return (
    <Popover
      targetTagName={"div"}
      interactionKind={"hover"}
      position={PopoverPosition.BOTTOM}
      content={
        <div className="p-4" style={{ maxWidth: 360 }}>
          {content}
        </div>
      }
    >
      {props.children}
    </Popover>
  );
};

export default TourStepPopover;
