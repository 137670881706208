import React from "react";
import { TooltipRenderProps } from "react-joyride";
import { useIntl } from "react-intl";
import { Button } from "@blueprintjs/core";

type OwnProps = {
  onContinueClick: () => void;
  onDismissClick: () => void;
};

type Props = OwnProps & TooltipRenderProps;

const CustomButtonsTooltipComponent: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { step, tooltipProps, onContinueClick, onDismissClick } = props;

  return (
    <div
      {...tooltipProps}
      style={{ minWidth: "15rem", maxWidth: "20rem" }}
      className="bg-white p-4 rounded"
    >
      {step.title && <p className="text-base font-semibold">{step.title}</p>}
      {step.content && <div className="pt-2">{step.content}</div>}
      <div className="flex justify-end">
          <Button
            text={intl.formatMessage({
              id: "app.survey-report.guide.tooltip.button.do-now",
            })}
            title={intl.formatMessage({
              id: "app.survey-report.guide.tooltip.button.do-now",
            })}
            intent="primary"
            className="mr-1"
            onClick={onDismissClick}
            large
          />
          <Button
            text={intl.formatMessage({
              id: "app.titles.next",
            })}
            title={intl.formatMessage({
              id: "app.titles.next",
            })}
            intent="primary"
            className="mr-1"
            large
            onClick={onContinueClick}
          />
      </div>
    </div>
  );
};

export default CustomButtonsTooltipComponent;
