/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  LookForItem,
  LookForItemStatus,
  LookForItemStatusEnum,
} from "../../../../../../types";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

type OwnProps = {
  lookForStatus: any;
  onLookForStatusFieldUpdate: <T extends string | LookForItemStatusEnum>(
    key: keyof LookForItem | keyof LookForItemStatus,
    value: T,
    itemId: number
  ) => void;
};

type Props = OwnProps;

const UpdateImplementationItem: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { lookForStatus, onLookForStatusFieldUpdate } = props;

  const [statusClassName, setStatusClassName] = useState<string>("");

  const lookForItemStatusOptions = useMemo(() => {
    return Object.values(LookForItemStatusEnum).map((r, i) => ({
      label: r,
      value: i,
      translate: intl.formatMessage({ id: `app.strategies.look-fors.status.${r}` }),
    }));
  }, [intl]);

  useEffect(() => {
    selectedDropdownClass(lookForStatus.status);
  }, [lookForStatus]);

  const selectedDropdownClass = (status: string) => {
    let statusClassName = '';

    if (status === LookForItemStatusEnum.Present)
      statusClassName = 'status-done';
    else if (status === LookForItemStatusEnum.InProgress)
      statusClassName = 'status-progress';
    else if (status === LookForItemStatusEnum.NotPresent)
      statusClassName = 'status-notStarted';

    setStatusClassName(statusClassName);
  };

  return (
    <>
      <div className="accordionbar__content-row">
        <div className="nj-row">
          <div className="nj-sm-8">
            <div className="edit-core-lock">
              {lookForStatus.item.text}
            </div>
          </div>
          <div className="nj-sm-2">
            {intl.formatMessage({ id: `app.recurring-status.${lookForStatus.recurring_status}` })}
          </div>
          <div className="nj-sm-2">
            <div className="not-recurting">
              <FormControl size="small">
                <InputLabel
                  style={{ 'backgroundColor': '#fff' }} id="demo-select-small-label">
                  {intl.formatMessage({ id: 'app.titles.status' })}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className={`${statusClassName} dropdowlist`}
                  label={intl.formatMessage({ id: 'app.titles.status' })}
                  value={lookForStatus.status}
                  onChange={(value) => {
                    onLookForStatusFieldUpdate(
                      "status",
                      value.target.value as string,
                      lookForStatus.item.id
                    );
                  }}
                >
                  {
                    lookForItemStatusOptions.map(item =>
                      <MenuItem key={item.label} value={item.label} className="status-dropdown">
                        {item.translate}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateImplementationItem;
