import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
// import TooltipComponent from "../../../tour/TooltipComponent";
import { useDispatch, useSelector } from "react-redux";
import SuperintendentTooltip from "../../../../tour/SuperintendentTooltip";
import { DistrictSetupTaskKey } from "../../../../../types";
import { markStepCompleted, updateMakingProgressTourTimeWatched } from "../../../../../store/tours/actions";
import { generatePath, useHistory } from "react-router";
import { AuthRoutes } from "../../../../../App";
import { isDistrictPlan } from "../../../../../store/plans/selectors";
import useUserPlanAssociation from "../../../../../helpers/hooks/useUserPlanAssociation";

type OwnProps = {};

type Props = OwnProps;

const LearnAboutTeamDevelopmentTour: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch=useDispatch();
  const history = useHistory();
  const isActiveDistrictPlan = useSelector(isDistrictPlan);
  const {
    planSchoolId,
    planDistrictId,
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
  } = useUserPlanAssociation();

  const steps: Step[] = useMemo(() => {
    return [
      
      
      {
        target: '[data-tour="setup-calendar-button"]',
        content:intl.formatMessage({id:"app.tours.superintendent-setup.making-progress.dashboard-calendar-button"}),
        disableBeacon: true,
      },{
        target: '[data-tour="setup-calenda-step-2-button"]',
        content:intl.formatMessage({id:"app.tours.superintendent-setup.making-progress.add-calendar-event"}),
        disableBeacon: true,
      },
    ];
  }, []);

  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const [show, setShow] = useState(false);
  useEffect(() => {
    setupCompleted
      ? setShow(false)
      : 
      setTimeout(
        () => setShow(true),
        200
      );
  }, [setupCompleted]);

  const handleJoyrideAction = (data: CallBackProps) => {
    // console.log("handleJoyrideAction>>",data)
        if(data.action==="prev" ){
          isActiveDistrictPlan ?dispatch(markStepCompleted({key: DistrictSetupTaskKey.SetupDistrictCalendar,})):dispatch(markStepCompleted({
            key: DistrictSetupTaskKey.SetupSchoolCalendar,
            schoolId: planSchoolId,
            
          }))  
          setShow(false)
        }

      
switch (data.step.target) {
    
        case '[data-tour="setup-calendar-button"]':
                         dispatch(markStepCompleted({
                key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
              }))
        history.push(
                generatePath(`/plan/team/${isActiveDistrictPlan ? 'dclt_sci_' : 'sclt_sci_'}calendar` as any),
                {
                  fromWorkSpace: "welcome",
                  }
              );
        break;

    default:
        break;
}
  

  if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
        setShow(false);  
        dispatch(updateMakingProgressTourTimeWatched(new Date()));  
         isActiveDistrictPlan ?dispatch(markStepCompleted({key: DistrictSetupTaskKey.SetupDistrictCalendar,})):dispatch(markStepCompleted({
                key: DistrictSetupTaskKey.SetupSchoolCalendar,
                schoolId: planSchoolId,
              }))
      }
  
  };


  return (
    <ReactJoyride
      run={show}
      steps={steps}
      tooltipComponent={SuperintendentTooltip}
      continuous
      callback={handleJoyrideAction}
      disableOverlayClose
    />
  );
};

export default LearnAboutTeamDevelopmentTour;
