import React, { FunctionComponent, useMemo } from "react";
import SchoolSelector from "../../common/selectors/SchoolSelector";
import { useSelector } from "react-redux";

type OwnProps = {
  districtId?: number;
  loading?: boolean;
  schoolsIds: number[];
  onChange: (schoolsIds: number[]) => void;
  disabled?: boolean;
};

type Props = OwnProps;

const SchoolSelectorHOC: FunctionComponent<Props> = (props) => {
  const { districtId, loading, schoolsIds, onChange, disabled } = props;

  const schools = useSelector((s) => s.schools.schools);

  const selectorData = useMemo(() => {
    if (!!districtId) {
      return schools.filter(
        (school) => (school?.district || districtId) === districtId
      );
    }
    return schools;
  }, [districtId, schools]);

  return (
    <SchoolSelector<true>
      disabled={disabled}
      loading={loading}
      className={""}
      schoolId={schoolsIds}
      schools={selectorData}
      onSchoolChange={(schoolsIds) => {
        onChange(schoolsIds ?? []);
      }}
    />
  );
};

export default SchoolSelectorHOC;
