import React, { FunctionComponent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  Button,
  Classes,
  Dialog,
  Icon,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import "moment/locale/es";
import {
  addSurvey,
  hideSurveyUpsertDialog,
  updateSurvey,
} from "../../../store/surveys/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { AppToaster } from "../../../helpers/toaster";
import { Locale } from "../../../store/UIState";
import {
  CollectedDemographicData,
  Survey,
  SurveyCategoryType,
  UserRole,
} from "../../../types";
import { useHistory } from "react-router-dom";
import useRolesPermissions from "../../../helpers/hooks/useRolesPermissions";
import { AddSurveyRequest } from "../../../api/surveys/types";
import SurveyGeneralInfoTab from "./tabs/SurveyGeneralInfoTab";
import SurveyDeploymentCollectedData from "./tabs/SurveyCollectedData";

type OwnProps = {};

type Props = OwnProps;

const defaultAvailableLanguages = [Locale.English];

export type GeneralInfoTabType = Omit<AddSurveyRequest, "demographic_data"> & {
  redirect_to_questions_page: boolean;
};

const SurveyUpsertDialog: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const show = useSelector((s) => s.surveys.showSurveyUpsertDialog);
  const selectedSurvey = useSelector((s) => s.surveys.selectedSurvey);

  const isAdmins = useRolesPermissions([
    UserRole.Superadmin,
    UserRole.NJ_SCI_Leadership,
  ]);

  const [selectedTabId, setSelectedTabId] = useState<TabId>("general-info");

  const [generalInfoTabObject, setGeneralInfoTabObject] =
    useState<GeneralInfoTabType>({
      name: "",
      available_languages: defaultAvailableLanguages,
      is_system_survey: isAdmins,
      survey_categories_type: SurveyCategoryType.DOMAIN,
      redirect_to_questions_page: true,
    });

  const [collectedData, setCollectedData] = useState<
    Omit<CollectedDemographicData, "id" | "created_at" | "updated_at">[]
  >([]);

  const handleClose = () => {
    dispatch(hideSurveyUpsertDialog());
  };

  const handleOpen = () => {
    if (selectedSurvey) {
      setGeneralInfoTabObject({
        ...generalInfoTabObject,
        name: selectedSurvey.name,
        available_languages: selectedSurvey.available_languages,
        is_system_survey: !selectedSurvey?.creator,
        survey_categories_type: selectedSurvey.survey_categories_type,
        redirect_to_questions_page: true,
      });
      setCollectedData(selectedSurvey?.demographic_data ?? []);
    } else {
      setGeneralInfoTabObject({
        ...generalInfoTabObject,
        name: "",
        available_languages: defaultAvailableLanguages,
        is_system_survey: isAdmins,
        survey_categories_type: SurveyCategoryType.DOMAIN,
        redirect_to_questions_page: true,
      });
      setCollectedData([]);
    }
    setSelectedTabId("general-info");
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const requestBody = {
      name: generalInfoTabObject.name,
      available_languages: generalInfoTabObject.available_languages,
      survey_categories_type: generalInfoTabObject.survey_categories_type,
      is_system_survey: generalInfoTabObject.is_system_survey,
      demographic_data: collectedData.filter(
        (item) => !!item.demographic_data.length
      ),
    };
    const onSuccess1 = generalInfoTabObject.redirect_to_questions_page
      ? (survey: Survey) => {
          onSuccess();
          history.push(`/admin/surveys/${survey.id}/edit`);
        }
      : undefined;

    if (selectedSurvey) {
      dispatch(
        updateSurvey.request({
          surveyId: selectedSurvey.id,
          updatedSurvey: {
            ...requestBody,
            page_text: selectedSurvey.page_text,
          },
          onSuccess: onSuccess1,
        })
      );
    } else {
      dispatch(
        addSurvey.request({ survey: requestBody, onSuccess: onSuccess1 })
      );
    }
  };

  const loading = useSelector((s) =>
    selectedSurvey
      ? s.surveys.loading.updateSurvey
      : s.surveys.loading.addSurvey
  );
  const error = useSelector((s) =>
    selectedSurvey ? s.surveys.errors.updateSurvey : s.surveys.errors.addSurvey
  );
  const onSuccess = useCallback(() => {
    handleClose();
    AppToaster.show({
      message: (
        <p>
          {intl.formatMessage({
            id: selectedSurvey
              ? "app.surveys.survey-updated-message"
              : "app.surveys.survey-created-message",
          })}
        </p>
      ),
      icon: "tick",
      intent: "success",
    });
  }, [selectedSurvey]);

  useLoading({ loading, error, onSuccess });

  return (
    <Dialog
      isOpen={show}
      onOpening={handleOpen}
      onClose={handleClose}
      style={{
        width: "37.5rem",
      }}
      icon={<Icon icon="properties" iconSize={24} />}
      title={intl.formatMessage({
        id: selectedSurvey
          ? "app.surveys.survey-dialog.edit-survey"
          : "app.surveys.survey-dialog.add-survey",
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        <form id="upsert-survey-form" onSubmit={handleFormSubmit}>
          <Tabs
            id="SurveyDialogTabs"
            selectedTabId={selectedTabId}
            onChange={setSelectedTabId}
          >
            <Tab
              id="general-info"
              title={intl.formatMessage({ id: "app.titles.general-info" })}
              panel={
                <SurveyGeneralInfoTab
                  generalInfoTabObject={generalInfoTabObject}
                  setGeneralInfoTabObject={setGeneralInfoTabObject}
                  defaultAvailableLanguages={defaultAvailableLanguages}
                  isAdmins={isAdmins}
                  selectedSurvey={selectedSurvey}
                />
              }
            />
            <Tab
              id={"collected_data"}
              title={intl.formatMessage({
                id: "app.survey-dialog.tabs.collected-data",
              })}
              panel={
                <SurveyDeploymentCollectedData
                  collectedData={collectedData}
                  setCollectedData={setCollectedData}
                />
              }
            />
          </Tabs>
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={handleClose} disabled={loading}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            className="w-1/4"
            intent="primary"
            type="submit"
            loading={loading}
            form="upsert-survey-form"
          >
            {intl.formatMessage({
              id: selectedSurvey
                ? "app.titles.save"
                : "app.surveys.survey-dialog.add-survey",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SurveyUpsertDialog;
