import { Spinner } from '@blueprintjs/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getGoalActivityLog } from '../../../../../store/goals/actions';
import { useIntl } from 'react-intl';
import StrategyItem from '../../strategies/strategies-list/strategy-item/StrategyItem';
import { PlanStrategy } from '../../../../../types';
import NoStrategy from '../../strategies/strategies-list/no-strategy/NoStrategies';
import { filterStrategiesByDomainAndSearchString } from '../../utils';
import './GoalStrategies.scss';
import { getSchools } from '../../../../../store/schools/actions';

type OwnProps = {};

type Props = OwnProps;

const MIN_STRATEGY_PER_LOAD = 4;

const GoalStrategies: FunctionComponent<Props> = () => {
    // CONSTANT
    const intl = useIntl();
    const dispatch = useDispatch();

    // USE SELECTOR 
    const { goal } = useSelector(s => s.goals.newGoalState);
    const planStrategies = useSelector((s) => s.planStrategies.planStrategies);
    const currentUser = useSelector((s) => s.auth.authenticatedUser);
    const schools = useSelector((s) => s.schools.schools);

    // USE STATE
    const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
    const [displayedPlanStrategies, setDisplayedPlanStrategies] = useState<
        PlanStrategy[]
    >([]);
    const [displayedItems, setDisplayedItems] = useState<number>(MIN_STRATEGY_PER_LOAD);

    // USE EFFECT
    useEffect(() => {
        goal.id &&
            dispatch(getGoalActivityLog.request(goal.id));
    }, [goal]);

    useEffect(() => {
        dispatch(getSchools.request());
    }, [dispatch]);

    // test 
    useEffect(() => {
        if (goal && goal.selected_domains?.length) {
            let strategies = filterStrategiesByDomainAndSearchString(
                planStrategies,
                '',
                goal.selected_domains.map((et:any)=>et.name)??[]
            )
            setDisplayedPlanStrategies(strategies)
        }else{
            setDisplayedPlanStrategies([])

        }
    }, [planStrategies, goal])

    return (
        <> 
            <h3>{intl.formatMessage({
                id: "app.titles.strategies-in-plan-aligned",
            })}</h3>

            { 
            <div className="goalstrategies-accordion">
            <div className="strategies-content__list">
                {
                    displayedPlanStrategies.length ? displayedPlanStrategies.slice(0, displayedItems).map((ps) => (
                        <StrategyItem key={`plan_strategy_${ps.id}`} inGoalView={true} strategy={ps} schools={schools} />
                    ))
                        : (
                            (isLoading || isLoading === undefined) ?
                                <Spinner intent="primary" className="my-2" /> :
                                <NoStrategy inGoalView={true} />
                        )
                }
                <div className="load-more mt-5">
                        {
                        displayedItems < displayedPlanStrategies.length && (
                          <div className="load-more">
                            <a className="stroke-btn" onClick={() => setDisplayedItems(displayedItems + MIN_STRATEGY_PER_LOAD)}>
                              {intl.formatMessage({ id: "app.title.load-more" })}
                            </a>
                          </div>
                        )
                      }
                          </div>
            </div>
            </div>}

        </>
    )
}

export default GoalStrategies