import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Activity,
  AttachedResource,
  AttachedToType,
  DistrictSetupTaskKey,
  Meeting,
  MeetingTemplate,
  MeetingType,
  YEARS_OFFSET,
} from "../../../../types";
import { useIntl } from "react-intl";
import {
  Button,
  Checkbox,
  Classes,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import {
  DateInput,
  DateInputProps,
  TimePrecision,
} from "@blueprintjs/datetime";
import ResourcesList from "../../resources/ResourcesList";
import ResourcesTab from "../../strategies/upsert-strategy-dialog/tabs/ResourcesTab";
import moment, { MomentSetObject } from "moment";
import {
  addMeeting,
  deleteMeeting,
  showMeetingDialog,
  updateMeeting,
} from "../../../../store/meetings/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppToaster } from "../../../../helpers/toaster";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import * as constants from "../../../../constants/constants";
import CollapsableActivity from "../../members/tabs/resources-tab/CollapsableActivity";
import useNextTourCallback from "../../../../helpers/hooks/team-members/useNextTourCallback";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import BracketContentWrapper from "./BracketContentWrapper";
import { wrapObjectFields } from "../../../../helpers/stringUtils";
import useMeetingStart from "../../../../helpers/hooks/useMeetingStart";
import { UpdateMeetingRequest } from "../../../../api/meetings/types";
import PageEditor from "../../../survey-deployment/forms/PageEditor";
import DownloadIcsFileButton from "./DownloadIcsFileButton";
import { selectIsAdmin } from "../../../../store/auth/selectors";
import { Link, generatePath } from "react-router-dom";
import { AuthRoutes } from "../../../../App";

type OwnProps = {
  meeting?: Meeting<string, false>;
  shortenMeeting?: Meeting;
  selectedTemplate?: MeetingTemplate;
  onDialogClose?: (isSaveAndAddOther?: boolean) => void;
  editableFields: (keyof Meeting)[];
};

type Props = OwnProps;

enum SubmitterName {
  SubmitAndClose = "submit_and_close",
  SubmitAndAddAnother = "submit_and_add_another",
}

const MIN_DATE_DEFAULT = moment().subtract(YEARS_OFFSET, "years").toDate();
const MAX_DATE_DEFAULT = moment().add(YEARS_OFFSET, "years").toDate();

const DEFAULT_TIME: MomentSetObject = {
  hour: 9,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

const UpsertMeetingDialogForm: React.FC<Props> = (props: Props) => {
  const {
    meeting,
    selectedTemplate,
    onDialogClose,
    editableFields,
    shortenMeeting,
  } = props;

  const intl = useIntl();

  const activePlanId = useSelector((s) => s.plans?.activePlan?.id);
  const plan = useSelector((s) => s.plans?.activePlan);

  const [title, setTitle] = useState("");
  const [shortTitle, setShortTitle] = useState<string | undefined>();

  const [allDayEvent, setAllDayEvent] = useState<boolean>(true);

  const [date, setDate] = useState<Date>();

  const [endDate, setEndDate] = useState<Date>();

  const [generalNotes, setGeneralNotes] = useState<string | undefined>("");

  const [preWork, setPreWork] = useState<string | undefined>("");

  const [agenda, setAgenda] = useState<string | undefined>("");

  const [timingGuidance, setTimingGuidance] = useState<string | undefined>();

  const [attachedResources, setAttachedResources] = useState<
    AttachedResource[]
  >([]);

  const [activities, setActivities] = useState<Activity[]>([]);

  const isDistrictActivePlan: boolean | any = useSelector(isDistrictPlan);

  const onNextTour = useNextTourCallback(
    isDistrictActivePlan
      ? DistrictSetupTaskKey.SetupDistrictCalendar
      : DistrictSetupTaskKey.SetupSchoolCalendar
  );

  const [submitterName, setSubmitterName] = useState<
    SubmitterName | undefined
  >();

  useEffect(() => {
    if (meeting) {
      setTitle(meeting.title);
      setShortTitle(meeting.short_title);
      setGeneralNotes(meeting.general_notes);
      setTimingGuidance(meeting.timing_guidance);
      setPreWork(meeting.pre_work);
      setAgenda(meeting.agenda);
      setAllDayEvent(!!meeting.all_day_event);
      setDate(moment(meeting.date).toDate());
      setEndDate(moment(meeting.end_date ?? meeting.date).toDate());
      setAttachedResources(meeting.attached_resources);
      setActivities(meeting.activities ?? []);
    }
  }, [meeting]);

  useEffect(() => {
    if (selectedTemplate) {
      setTitle(selectedTemplate.title);
      setShortTitle(selectedTemplate.short_title);
      setGeneralNotes(selectedTemplate.general_notes);
      setTimingGuidance(selectedTemplate.timing_guidance);
      setPreWork(selectedTemplate.pre_work);

      if (
        selectedTemplate.meeting_type &&
        [
          MeetingType.TeamMeeting,
          MeetingType.DistrictWideLeadershipMeeting,
        ].includes(selectedTemplate.meeting_type)
      ) {
        setAllDayEvent(false);
      } else {
        setAllDayEvent(true);
      }

      setAgenda(selectedTemplate.agenda);
      setAttachedResources(selectedTemplate.resources);
      setActivities(selectedTemplate.activities ?? []);
    }
  }, [selectedTemplate]);

  const loading = useSelector((s) => s.meetings.loading.addMeeting);
  const error = useSelector((s) => s.meetings.errors.addMeeting);
  const onSuccess = () => {
    AppToaster.show({
      icon: "tick",
      intent: "success",
      message: intl.formatMessage(
        { id: "app.titles.toaster-message" },
        { name: title }
      ),
    });
    const isSubmitAndAddAnother =
      submitterName === SubmitterName.SubmitAndAddAnother;

    if (isSubmitAndAddAnother) {
      onDialogClose && onDialogClose(true);
    } else {
      onNextTour && onNextTour();
      onDialogClose && onDialogClose();
    }
  };
  useLoading({ loading, error, onSuccess });
  const isAdmin = useSelector(selectIsAdmin);

  const updateLoading = useSelector((s) => s.meetings.loading.updateMeeting);
  const updateError = useSelector((s) => s.meetings.errors.updateMeeting);
  const onUpdateSuccess = () => {
    AppToaster.show({
      icon: "tick",
      intent: "success",
      message: intl.formatMessage(
        { id: "app.titles.update-toaster-message" },
        { name: title }
      ),
    });
    onDialogClose && onDialogClose();
  };
  useLoading({
    loading: updateLoading,
    error: updateError,
    onSuccess: onUpdateSuccess,
  });

  const dispatch = useDispatch();

  const handleAllDayEventChange = (e: React.FormEvent<HTMLInputElement>) => {
    const checked = e.currentTarget?.checked;
    setAllDayEvent(checked);
  };

  const handleTitleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target?.value;
    setTitle(value);
  };

  const handleShortTitleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target?.value;
    setShortTitle(value);
  };

  const handleTimingGuidanceChange = (value: string) => {
    setTimingGuidance(value);
  };

  const handleDateChange = (selectedDate: Date) => {
    if (!date && !allDayEvent) {
      selectedDate = moment(selectedDate).set(DEFAULT_TIME).toDate();
    }

    setDate(selectedDate);
    if (!endDate || moment(endDate).isBefore(moment(selectedDate))) {
      setEndDate(selectedDate);
    }
  };

  const handleEndDateChange = (selectedDate: Date) => {
    setEndDate(selectedDate);
  };

  const handleGeneralNotesFocus = (value: string) => {
    setGeneralNotes(value);
  };

  const handleAgendaFocus = (value: string) => {
    setAgenda(value);
  };

  const handlePreWorkFocus = (value: string) => {
    setPreWork(value);
  };

  const { fromAdminView } = useSelector(
    (s) => s.meetings.dialogs.showMeetingDialog
  );

  const handleMeetingFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitterName((e.nativeEvent as any)?.submitter.name);
    if (activePlanId || fromAdminView || isAdmin) {
      const body: Omit<UpdateMeetingRequest, "id"> = {
        title: title,
        short_title: shortTitle,
        all_day_event: allDayEvent,
        date: date ? moment(date).toISOString() : "",
        end_date: endDate ? moment(endDate).toISOString() : "",
        general_notes: generalNotes,
        pre_work: preWork,
        agenda: agenda,
        attached_resources: attachedResources,
        plan: fromAdminView || isAdmin ? undefined : activePlanId,
        system_event: fromAdminView || isAdmin,
        timing_guidance: timingGuidance,

        // hidden fields
        meeting_code: selectedTemplate?.meeting_code,
        meeting_type: selectedTemplate?.meeting_type,
        meeting_type_other: selectedTemplate?.meeting_type_other,
      };
      if (meeting) {
        dispatch(
          updateMeeting.request({
            ...body,
            id: meeting.id,
            activities: meeting.activities?.map((a) => a.id),
          })
        );
      } else {
        dispatch(
          addMeeting.request({
            ...body,
            activities: selectedTemplate?.activities?.map((a) => a.id),
          })
        );
      }
    }
  };

  const handleAttachedResourcesChange = (resources: AttachedResource[]) => {
    setAttachedResources(resources);
  };

  const handleEditButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      showMeetingDialog({
        meeting: shortenMeeting,
        viewOnly: false,
        fromAdminView: isAdmin,
      })
    );
  };

  const showConfirmDeleteDialog = (meetingId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(
            deleteMeeting.request([
              meetingId,
              () => {
                onDialogClose && onDialogClose();
              },
            ] as any)
          );
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage({
          id: "app.confirmation-dialogs.delete-meeting",
        }),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete" }),
      })
    );
  };

  const deleteLoading = useSelector((s) => s.meetings.loading.deleteMeeting);
  const deleteError = useSelector((s) => s.meetings.errors.deleteMeeting);
  useLoading({ loading: deleteLoading, error: deleteError });

  const wrappedContent: any = useMemo(() => {
    return wrapObjectFields({
      title,
      shortTitle,
      generalNotes,
      timingGuidance,
      preWork,
      agenda,
    });
  }, [title, generalNotes, timingGuidance, preWork, agenda]);

  const { onMeetingStart: handleMeetingStart } = useMeetingStart({
    meeting: meeting,
    agenda: agenda,
    occurrences: wrappedContent.occurrences,
    activities: activities,
  });

  const dateTimeFormat = useMemo(
    () => (allDayEvent ? "MM/DD/YYYY" : "MM/DD/YYYY h:mm A"),
    [allDayEvent]
  );

  const momentFormatter: Partial<DateInputProps> = useMemo(() => {
    return {
      closeOnSelection: allDayEvent,
      placeholder: dateTimeFormat,
      formatDate: (date: Date, locale: string) =>
        moment(date).locale(locale).format(dateTimeFormat),
      parseDate: (str: string, locale: string) =>
        moment(str, dateTimeFormat).locale(locale).toDate(),
      timePickerProps: allDayEvent
        ? undefined
        : {
            defaultValue: moment().set(DEFAULT_TIME).toDate(),
            useAmPm: true,
            showArrowButtons: true,
            precision: TimePrecision.MINUTE,
          },
    };
  }, [allDayEvent]);

  const preWorkRef = useRef(null);
  const agendaRef = useRef(null);

  const generalNotesEditor = useMemo(
    () => (
      <PageEditor content={generalNotes} onBlur={handleGeneralNotesFocus} />
    ),
    [generalNotes]
  );

  const preWorkEditor = useMemo(
    () => (
      <PageEditor
        content={preWork}
        onBlur={handlePreWorkFocus}
        ref={preWorkRef}
      />
    ),
    [preWork, preWorkRef]
  );

  const agendaEditor = useMemo(
    () => (
      <PageEditor content={agenda} onBlur={handleAgendaFocus} ref={agendaRef} />
    ),
    [agenda, agendaRef]
  );

  const timingGuidanceEditor = useMemo(
    () => (
      <PageEditor
        content={timingGuidance}
        onBlur={handleTimingGuidanceChange}
      />
    ),
    [timingGuidance]
  );
  const canEdit = useMemo(() => {
    return (
      meeting && (!meeting.system_event || (meeting.system_event && isAdmin))
    );
  }, [meeting?.system_event, isAdmin]);

  return (
    <>
      <div className={`${Classes.DIALOG_BODY} form-with-bold-labels labels-lg`}>
        {meeting && !!editableFields.length && (
          <Button
            className="mb-2"
            text={intl.formatMessage({
              id: "app.titles.delete",
            })}
            icon="trash"
            intent="danger"
            onClick={() => {
              showConfirmDeleteDialog(meeting.id);
            }}
            loading={deleteLoading}
          />
        )}
        <form
          id={"meeting-dialog"}
          onSubmit={(e) => {
            return editableFields.length
              ? handleMeetingFormSubmit(e)
              : handleEditButtonClick(e);
          }}
        >
          <FormGroup
            label={intl.formatMessage({ id: "app.meetings.dialog.title" })}
          >
            {editableFields.includes(constants.MEETING_TITLE_FIELD) ? (
              <InputGroup
                placeholder={intl.formatMessage({
                  id: "app.meetings.dialog.title-placeholder",
                })}
                defaultValue={title}
                onBlur={handleTitleChange}
                required
              />
            ) : (
              <BracketContentWrapper innerHtml={wrappedContent.title} />
            )}
          </FormGroup>

          <FormGroup
            label={intl.formatMessage({ id: "app.titles.short-title" })}
          >
            {editableFields.includes(constants.MEETING_SHORT_TITLE_FIELD) ? (
              <InputGroup
                placeholder={
                  intl.formatMessage({
                    id: "app.titles.short-title",
                  }) + "..."
                }
                defaultValue={shortTitle ?? ""}
                onBlur={handleShortTitleChange}
              />
            ) : (
              <BracketContentWrapper innerHtml={wrappedContent.shortTitle} />
            )}
          </FormGroup>

          <FormGroup
            label={intl.formatMessage({
              id: "app.meetings.dialog.general-notes",
            })}
          >
            {editableFields.includes(constants.MEETING_GENERAL_NOTES_FIELD) ? (
              <>{generalNotesEditor}</>
            ) : (
              <BracketContentWrapper innerHtml={wrappedContent.generalNotes} />
            )}
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ id: "app.titles.timing-guidance" })}
          >
            {editableFields.includes(constants.MEETING_TIMING_GUIDANCE) ? (
              <>{timingGuidanceEditor}</>
            ) : (
              <BracketContentWrapper
                innerHtml={wrappedContent.timingGuidance}
              />
            )}
          </FormGroup>

          {editableFields.includes(constants.MEETING_DATE_FIELD) && (
            <Checkbox
              checked={allDayEvent}
              onChange={handleAllDayEventChange}
              label={intl.formatMessage({
                id: "app.meetings.dialog.all-day-event",
              })}
            />
          )}

          <FormGroup
            label={intl.formatMessage({
              id: "app.meetings.dialog.meeting-date",
            })}
          >
            {editableFields.includes(constants.MEETING_DATE_FIELD) ? (
              <DateInput
                locale={intl.locale}
                {...momentFormatter}
                value={date}
                minDate={MIN_DATE_DEFAULT}
                maxDate={MAX_DATE_DEFAULT}
                inputProps={{ required: true }}
                onChange={handleDateChange}
              />
            ) : (
              <p>{moment(date).format(dateTimeFormat)}</p>
            )}
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.meetings.dialog.meeting-end-date",
            })}
          >
            {editableFields.includes(constants.MEETING_END_DATE_FIELD) ? (
              <DateInput
                locale={intl.locale}
                {...momentFormatter}
                value={endDate}
                inputProps={{ required: true }}
                minDate={date ?? MIN_DATE_DEFAULT}
                maxDate={MAX_DATE_DEFAULT}
                disabled={!date}
                onChange={handleEndDateChange}
              />
            ) : (
              <p>{moment(endDate).format(dateTimeFormat)}</p>
            )}
          </FormGroup>
          {((selectedTemplate && preWork) || !selectedTemplate) && (
            <FormGroup
              label={intl.formatMessage({
                id: "app.meetings.dialog.pre-work",
              })}
              className="relative"
            >
              {editableFields.includes(constants.MEETING_PRE_WORK_FIELD) ? (
                <>{preWorkEditor}</>
              ) : (
                <BracketContentWrapper innerHtml={wrappedContent.preWork} />
              )}
            </FormGroup>
          )}
          {((selectedTemplate && agenda) || !selectedTemplate) && (
            <FormGroup
              label={intl.formatMessage({
                id: "app.meetings.dialog.agenda",
              })}
              className="relative"
            >
              {editableFields.includes(constants.MEETING_AGENDA_FIELD) ? (
                <>{agendaEditor}</>
              ) : (
                <BracketContentWrapper innerHtml={wrappedContent.agenda} />
              )}
            </FormGroup>
          )}
          {attachedResources?.length ? (
            <FormGroup
              label={intl.formatMessage({
                id: "app.titles.files-links",
              })}
            >
              {editableFields.includes(
                constants.MEETING_ATTACHED_RESOURCES_FIELD
              ) ? (
                <ResourcesTab
                  strategyResources={attachedResources}
                  setStrategyResources={handleAttachedResourcesChange}
                  attachedToType={AttachedToType.MEETING}
                />
              ) : (
                <ResourcesList resources={attachedResources} />
              )}
            </FormGroup>
          ) : null}
          {activities.length ? (
            <>
              {meeting?.meeting_type &&
                [
                  MeetingType.TeamMeeting,
                  MeetingType.DistrictWideLeadershipMeeting,
                ].includes(meeting.meeting_type) && (
                  <Link
                    to={{
                      pathname: generatePath(AuthRoutes.YourPlan, {
                        workspace: "sci-writing" as any,
                      } as any),
                      state: {
                        data: { ...meeting, isCalenderNote: true } as Meeting<
                          string,
                          false
                        >,
                        isCalenderNote: true,
                      },
                    }}
                  >
                    {" "}
                    <Button
                      className="mb-2"
                      intent="success"
                      text={intl.formatMessage({
                        id: "app.meetings.start-meeting",
                      })}
                      title={intl.formatMessage({
                        id: "app.meetings.start-meeting",
                      })}
                      // onClick={() => {
                      // handleMeetingStart(false);

                      // }}
                      onClick={() => onDialogClose && onDialogClose()}
                    />
                  </Link>
                )}

              <FormGroup
                label={intl.formatMessage({
                  id: "app.titles.activities-resources",
                })}
              >
                <div className="space-y-2">
                  {activities.map((a, i) => (
                    <CollapsableActivity
                      key={i}
                      activity={a}
                      agenda={agenda}
                      meeting={meeting}
                      showSciWriting={!!meeting}
                    />
                  ))}
                </div>
              </FormGroup>
            </>
          ) : null}
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button
            className="w-1/4"
            onClick={() => onDialogClose && onDialogClose()}
          >
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          {editableFields.length ? (
            <div className="space-x-2">
              {!meeting && (
                <Button
                  name={SubmitterName.SubmitAndAddAnother}
                  intent="primary"
                  type="submit"
                  form="meeting-dialog"
                  text={intl.formatMessage({
                    id: "app.meetings.save-add-other-event",
                  })}
                  title={intl.formatMessage({
                    id: "app.meetings.save-add-other-event",
                  })}
                  loading={loading || updateLoading}
                />
              )}
              <Button
                name={SubmitterName.SubmitAndClose}
                intent="primary"
                type="submit"
                form="meeting-dialog"
                text={intl.formatMessage({ id: "app.titles.save-close" })}
                title={intl.formatMessage({ id: "app.titles.save-close" })}
                loading={loading || updateLoading}
              />
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-end gap-2">
              {canEdit && (
                <Button
                  className="w-1/4"
                  intent="primary"
                  form="meeting-dialog"
                  type="submit"
                  text={intl.formatMessage({ id: "app.titles.edit" })}
                  title={intl.formatMessage({ id: "app.titles.edit" })}
                />
              )}

              <DownloadIcsFileButton meeting={meeting} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default UpsertMeetingDialogForm;
