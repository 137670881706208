import React from "react";
import { useIntl } from "react-intl";


function InfoBar() {
 const intl=useIntl();

  return (
    <>
      <button>
        <span className="info-icon">i</span> {intl.formatMessage({id:"app.titles.info"})}
      </button>
    </>
  );
}

export default InfoBar;
