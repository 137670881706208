/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from "react-intl";
import { Grade, PlanStrategy, PlanStrategyRespondentGroups } from '../../../../../types';
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './EditParameters.scss';
import useGetTeamMembersLoading from '../../../../../helpers/hooks/loading/useGetTeamMembersLoading';
import { useDispatch, useSelector } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { updatePlanStrategy } from '../../../../../store/plan-strategies/actions';
import { AppToaster } from '../../../../../helpers/toaster';

type OwnProps = {
    planstrategy: PlanStrategy;
    activePlan?: number;
    handleClose: () => void;
};
type Props = OwnProps;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
            width: 370,
        },
    },
};

const EditParameters: React.FC<Props> = (props) => {
    const { planstrategy, activePlan, handleClose } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    // STATES
    const [isEditParametersOpen, setIsEditParametersOpen] = useState(false);
    const [selectedGrades, setSelectedGrades] = useState<string[]>([]);
    const [selectedRespondentGroups, setSelectedRespondentGroups] = useState<string[]>([]);
    const [selectedLeaders, setSelectedLeaders] = useState<number[]>([]);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

    // Grades Options
    const gradesOptions = useMemo(() => {
        return Object.values(Grade).map((v) => ({
            value: v.toString(),
            label: intl.formatMessage({ id: "app.grades.grade" }, { grade: v }),
        }));
    }, [intl]);

    // Respondent Groups Options
    const respondentGroupsOptions = useMemo(() => {
        return Object.values(PlanStrategyRespondentGroups).map((v) => ({
            value: v,
            label: intl.formatMessage(
                { id: "app.strategies.strategy-parameters.respondent-groups" },
                { target: v }
            ),
        }));
    }, [intl]);

    // Team members
    const teamMembers = useSelector((s) => s.teamMembers.teamMembers);

    // Team Members Options
    let teamMembersOptions = useMemo(() => {
        return Object.values(teamMembers).map((v) => ({
            value: v.user.id,
            label: `${v.user.first_name} ${v.user.last_name}`,
        }));
    }, [teamMembers]);

    // unique team members options
    teamMembersOptions = teamMembersOptions.filter((item, index) => {
        return (index === teamMembersOptions.findIndex((t) => {
            return t.value === item.value;
        }));
    });

    useGetTeamMembersLoading();

    // On change grades
    const handleGradesChange = (event: SelectChangeEvent<typeof selectedGrades>) => {
        const {
            target: { value },
        } = event;
        setSelectedGrades(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // On change respondent groups
    const handleRespondentGroupChange = (event: SelectChangeEvent<typeof selectedRespondentGroups>) => {
        const {
            target: { value },
        } = event;
        setSelectedRespondentGroups(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // On change team members
    const handleTeamMembersChange = (event: { target: { value: any; }; }) => {
        const {
            target: { value },
        } = event;
        setSelectedLeaders(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // HOOKS
    useEffect(() => {
        if (planstrategy) {
            setSelectedGrades(planstrategy.grades!);
            setSelectedRespondentGroups(planstrategy.respondent_group!);
            setSelectedLeaders(planstrategy.leaders?.map(user => user.id) as any)
        }
        else {
            setSelectedGrades([]);
            setSelectedRespondentGroups([])
            setSelectedLeaders([]);
        }

    }, [planstrategy, planstrategy.status]);

    useEffect(() => {
        if ( !!selectedGrades.length || !!selectedRespondentGroups.length || !!selectedLeaders.length) {
            setIsBtnDisabled(false);
        }
        else {
            setIsBtnDisabled(true);
        }
    }, [selectedGrades, selectedRespondentGroups, selectedLeaders]);

    const editParametersClickOpen = () => {
        setIsEditParametersOpen(true);
    };

    const editParametersClose = () => {
        setIsEditParametersOpen(false);
        handleClose();
    };

    const onSuccess = () => {
        AppToaster.show({
            icon: "tick",
            intent: "success",
            message: intl.formatMessage(
                { id: "app.plan-strategies.update-parameters" },
                { name: planstrategy?.strategy?.name }
            ),
        });
        handleClose();
    };

    const getLabelByValue = (value: any) => {
        const element = teamMembersOptions.find((item: { value: any; }) => item.value === value);
        return element ? element.label : null;
    }

    const handleFormSubmit = () => {
        // Update Plan Strategy for Edit Parameters
        if (activePlan) {
            dispatch(
                updatePlanStrategy.request({
                    planStrategy: {
                        planId: activePlan,
                        strategy: planstrategy.strategy.id,
                        planStrategyId: planstrategy.id,
                        grades: selectedGrades,
                        respondent_group: selectedRespondentGroups,
                        leaders: selectedLeaders
                    },
                    onSuccess: () => onSuccess()
                })
            );
            setIsEditParametersOpen(false)
        }
    }

    return (
        <>
            <a onClick={editParametersClickOpen}><Icon icon="edit" iconSize={14} /> Edit Parameters</a>

            <Dialog
                className="edit-parameters-modal"
                open={isEditParametersOpen}
                onClose={editParametersClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="modal-title">
                    <h3>Edit Parameters</h3>
                    <button onClick={editParametersClose}>
                        <Icon icon="cross" iconSize={18} />
                    </button>
                </div>
                <div className="edit-parameters-modal-content">
                <FormControl size="small">
                        <InputLabel id="respondent-groups-multiple-checkbox-label">{intl.formatMessage({ id: "app.strategies.strategy-parameters.groups" })}</InputLabel>
                        <Select
                            labelId="respondent-groups-multiple-checkbox-label"
                            id="respondent-groups-multiple-checkbox"
                            multiple
                            value={selectedRespondentGroups}
                            onChange={handleRespondentGroupChange}
                            input={<OutlinedInput label={intl.formatMessage({ id: "app.strategies.strategy-parameters.groups" })} />}
                            renderValue={(selected) => selected.map(group => intl.formatMessage(
                                { id: "app.strategies.strategy-parameters.respondent-groups" },
                                { target: group }
                            )).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {respondentGroupsOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value} className="checkbox-list">
                                    <Checkbox checked={selectedRespondentGroups.indexOf(option.value) > -1} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl size="small">
                        <InputLabel id="grades-multiple-checkbox-label">{intl.formatMessage({ id: "app.titles.grades" })}</InputLabel>
                        <Select
                            labelId="grades-multiple-checkbox-label"
                            id="grades-multiple-checkbox"
                            multiple
                            value={selectedGrades}
                            onChange={handleGradesChange}
                            input={<OutlinedInput label={intl.formatMessage({ id: "app.titles.grades" })} />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {gradesOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value} className="checkbox-list">
                                    <Checkbox checked={selectedGrades.indexOf(option.value) > -1} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                    <FormControl size="small">
                        <InputLabel id="team-members-multiple-checkbox-label">{intl.formatMessage({ id: "app.titles.strategy-leaders" })}</InputLabel>
                        <Select
                            labelId="team-members-multiple-checkbox-label"
                            id="team-members-multiple-checkbox"
                            multiple
                            value={selectedLeaders}
                            onChange={handleTeamMembersChange}
                            name='team-members'
                            input={<OutlinedInput label={intl.formatMessage({ id: "app.titles.strategy-leaders" })} />}
                            renderValue={(selected) => selected.map(element => getLabelByValue(element)).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {teamMembersOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value} className="checkbox-list">
                                    <Checkbox checked={selectedLeaders.indexOf(option.value) > -1} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className="action">
                        <button onClick={editParametersClose} className="stroke-btn">{intl.formatMessage({ id: "app.titles.cancel" })}</button>
                        <button onClick={handleFormSubmit} className="nj-btn" disabled={isBtnDisabled}>{intl.formatMessage({ id: "app.titles.save" })}</button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default EditParameters
