/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import Dialog from '@mui/material/Dialog';
import './StrategicDisclaimer.scss';

type OwnProps = {};

type Props = OwnProps;

const StrategicDisclaimer: FunctionComponent<Props> = (Props) => {
  const [disclaimerOpen, DisclaimersetOpen] = useState(false);

  const disclaimerClickOpen = () => {
    DisclaimersetOpen(true);
  };

  const disclaimerClose = () => {
    DisclaimersetOpen(false);
  };

  return (
    <>
      <a onClick={disclaimerClickOpen}>
        Disclaimer
      </a>
      <Dialog
        className="large-modal disclaimer-details-modal"
        open={disclaimerOpen}
        onClose={disclaimerClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="modal-title">
          <h3>Disclaimer</h3>
          <button onClick={disclaimerClose}>
            <Icon icon="cross" iconSize={18} />
          </button>
        </div>
        <div className="summary-modal-content">
          <p>The publications and resources cited or listed in this resource do not necessarily represent the opinions, findings, or recommendations of Rutgers, The State University of New Jersey or New Jersey Department of Education. The research cited in this resource represents the most recent and generally accepted research related to each strategy area at the time of publication. This resource will be reviewed and updated as new research becomes available. The user is solely responsible for determining if the linked materials are appropriate for use in any given school community and should adhere to local policies and district approval processes prior to implementing school climate strategies. Neither Rutgers University nor the NJDOE take any responsibility for the use of any of the materials in this resource.</p>
        </div>
      </Dialog>
    </>
  );
}
export default StrategicDisclaimer;