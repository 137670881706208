import React, { FunctionComponent } from "react";
import BookmarkNumIcon from "./BookmarkNumIcon";
import { BookmarkType } from "../../../../types";

type OwnProps = {
  positive?: number;
  negative?: number;
};

type Props = OwnProps;

const BookmarksIcons: FunctionComponent<Props> = (props) => {
  const { positive = 0, negative = 0 } = props;

  return (
    <div className="flex justify-center">
      <BookmarkNumIcon count={positive} bookmarkType={BookmarkType.Positive} />
      <BookmarkNumIcon count={negative} bookmarkType={BookmarkType.Negative} />
    </div>
  );
};

export default BookmarksIcons;
