import { Icon, InputGroup } from '@blueprintjs/core'
import Popover from '@mui/material/Popover';
import { filter } from 'lodash';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { EDIT_GOAL_MODE } from '../../../../constants/constants';
import { getGoalByGoalId, pusherUpdateGoalEvent, updateNewGoalState } from '../../../../store/goals/actions';
import { Goal, NewGoalTabs } from '../../../../types';
import './GoalList.scss';
import { useLocation } from 'react-router';
import Pusher from 'pusher-js';
import { PUSHER_KEY } from '../../../../api/config';
import events from '../events';

type OwnProps = {
    isSideMenuClosed: boolean;
    setIsSideMenuClosed: any;
    setSubscribersData: any;
    pusher?: any;
    goalPusherGeneralChannel?: any;
};

type Props = OwnProps;

const GoalList: FunctionComponent<Props> = ({ isSideMenuClosed, goalPusherGeneralChannel, setIsSideMenuClosed, setSubscribersData, pusher }) => {
    // CONSTANTS
    const intl = useIntl();
    const dispatch = useDispatch();
    const location = useLocation();

    // USE SELECTOR
    const { goals, newGoalState, loading } = useSelector((s) => s.goals);
    const activePlanId = useSelector((s) => s.plans.activePlan?.id);
    const currentUser = useSelector((s) => s.auth.authenticatedUser);

    // USE STATE
    const [searchText, setSearchText] = useState('');
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [sortBy, setSortBy] = useState<boolean>(true);
    const [selectedGoalId, setSelectedGoalId] = useState<number>();

    // USE MEMO 
    const disabled = useMemo(() => {
        return loading.goalSwitch;
    }, [loading])
    const filteredGoals = useMemo(() => {
        let result: Goal[] = [...goals];
        if (!!searchText)
            return result?.filter((et: Goal) => (et?.statement?.toLowerCase()?.includes(searchText?.toLowerCase()) || et?.desired_outcome?.toLowerCase()?.includes(searchText?.toLowerCase())))
        return result;
    }, [goals, searchText])
    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);
    const id = useMemo(() => {
        return open ? 'simple-popover' : undefined;
    }, [open])
    const data = useMemo(() => {
        // return filteredGoals
        let sorted = filteredGoals.sort(function (a, b) {
            let c: any = new Date(a.created_at);
            let d: any = new Date(b.created_at);
            return c - d;
        });

        if (sortBy)
            sorted = sorted.reverse();
        return sorted;
    }, [sortBy, filteredGoals]);


    // USE EFFECT 

    useEffect(() => {
        if (location.pathname.includes("plan/goals")) {
            handleGoalSelection(data[0])
        }
    }, [location, sortBy])
    // FUNCTIONS
    const handleToggleSideMenu = () => {
        setIsSideMenuClosed(!isSideMenuClosed);
    }
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleGoalSelection = (goal: Goal) => {
        // prepare the list of domains available in the survey deployment
        // prepare get available and used list of domains and item for the selected survey deployment
        setSelectedGoalId(goal?.id);
        //get updated goal data


        // if(!selectedGoalId && goal)
        // dispatch(getGoalByGoalId.request({ planId: activePlanId ?? 0, goalId: goal?.id }));

        dispatch(updateNewGoalState({
            show: true, selectedGoal: goal, isEditMode: true, goal: {
                ...goal, user: goal?.user?.id, mode: EDIT_GOAL_MODE,
                activeTab: NewGoalTabs.GoalStatement,
            }
        }));
    }

    const handleSortBy = (sortBy: string) => {
        if (sortBy == 'NEWFIRST')
            setSortBy(true);
        else if (sortBy === 'OLDESTFIRST')
            setSortBy(false);
    }

    const isGreenCheckVisible = (goal: any) => {
        return goal.selected_domains.length > 0 && goal.targets.length > 0 && !!goal.desired_outcome && !!goal.statement && !goal.targets.find((et: any) => (!et.target_date || !et.target_value));
    }

    // useEffect(()=>{
    //     if(goal)
    // dispatch(getGoalByGoalId.request({ planId: activePlanId ?? 0, goalId: goal?.id }));

    // },[goal])

    useEffect(() => {
        if (newGoalState.goal?.id) {
            // Pusher.logToConsole = true;
            pusher.connection.bind("error", function (err: any) {
                if (err?.error?.data?.code === 4004) {
                    console.log("Over limit!");
                }
            });

            // Subscribe to this presence channel in order 
            // to get information about the connected memebers 
            const pusherChannelSubscribed: any = pusher.subscribe(
                "presence-goal_setting_" + selectedGoalId
            );

            pusherChannelSubscribed.bind(
                "pusher:subscription_succeeded",
                function (data: { data: any; is_new: boolean }) {
                    setSubscribersData({ users: pusherChannelSubscribed.members.members })
                }
            );

            pusherChannelSubscribed.bind('pusher:member_added', (member: any) => {
                setSubscribersData({ users: pusherChannelSubscribed.members.members })
            });

            pusherChannelSubscribed.bind('pusher:member_removed', (member: any) => {
                setSubscribersData({ users: pusherChannelSubscribed.members.members })
            });



            return () => {
                pusher.unsubscribe("presence-goal_setting_" + selectedGoalId)
            };
        }
    }, [newGoalState.goal.id]);

    return (
        <div className={`planning__left `}>
            <div className="left-inner">
                <div className="left-bar-toggle" id="planning-toggle" onClick={() => handleToggleSideMenu()}>{isSideMenuClosed ? <Icon icon="chevron-right" iconSize={16} /> : <Icon icon="chevron-left" iconSize={16} />}</div>
                <div className="planning__left-topbar">
                    <label>Goals ({filteredGoals.length})</label>
                    <ul>
                        <li>
                            <button onClick={handleClick}>
                                <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.63203 0.733255C7.38339 0.732156 7.14447 0.829947 6.96818 1.00528C6.79175 1.18078 6.69254 1.41906 6.69238 1.66769C6.69254 1.91633 6.79175 2.15476 6.96818 2.33009C7.14446 2.50543 7.38337 2.60322 7.63203 2.60212H16.457C16.7056 2.60322 16.9445 2.50543 17.1208 2.33009C17.2972 2.15475 17.3965 1.91631 17.3966 1.66769C17.3965 1.41904 17.2972 1.18077 17.1208 1.00528C16.9445 0.829947 16.7056 0.732154 16.457 0.733255H7.63203Z" fill="#232323" />
                                    <path d="M7.63203 4.69426C7.38339 4.69316 7.14447 4.79111 6.96818 4.96645C6.79175 5.14178 6.69254 5.38007 6.69238 5.62869C6.69254 5.87733 6.79175 6.11576 6.96818 6.29109C7.14446 6.46643 7.38337 6.56422 7.63203 6.56312H13.8096C14.0573 6.56296 14.295 6.46439 14.4702 6.28921C14.6454 6.11402 14.7439 5.87639 14.7441 5.62865C14.7439 5.38095 14.6453 5.1433 14.4702 4.9681C14.295 4.79289 14.0573 4.6945 13.8096 4.69418L7.63203 4.69426Z" fill="#232323" />
                                    <path d="M7.63201 8.6548C7.38242 8.6537 7.14272 8.75228 6.96631 8.92871C6.7899 9.10515 6.69114 9.34482 6.69239 9.59441C6.69255 9.84305 6.79176 10.0815 6.96819 10.2568C7.14447 10.4321 7.38338 10.5299 7.63204 10.5288H11.162C11.4097 10.5287 11.6474 10.4301 11.8226 10.2549C11.9978 10.0797 12.0962 9.84223 12.0965 9.59437C12.0976 9.34573 11.9996 9.10681 11.8243 8.93052C11.649 8.75423 11.4107 8.65504 11.1621 8.65472L7.63201 8.6548Z" fill="#232323" />
                                    <path d="M4.0986 0.681411C3.86251 0.681882 3.63631 0.776223 3.46979 0.943708C3.30324 1.1112 3.21016 1.33802 3.21094 1.57424V9.6413C3.21157 9.8766 3.30528 10.102 3.47152 10.2684C3.6379 10.4348 3.86347 10.5285 4.0986 10.529C4.33484 10.5297 4.56164 10.4367 4.72913 10.2703C4.89662 10.1037 4.99096 9.87737 4.99159 9.6413V1.57424C4.99237 1.33706 4.89866 1.10963 4.73101 0.941977C4.56337 0.774327 4.33577 0.680471 4.0986 0.681411Z" fill="#232323" />
                                    <path d="M0.821593 5.83632C0.633385 5.99894 0.517227 6.22969 0.498702 6.47787C0.48018 6.72588 0.560864 6.97121 0.722856 7.16008L3.33923 10.2022C3.50201 10.3904 3.7326 10.5064 3.98078 10.5249C4.22895 10.5434 4.47413 10.4627 4.66299 10.3008C4.8512 10.138 4.96736 9.90738 4.98588 9.6592C5.00441 9.41103 4.92372 9.16586 4.76173 8.97699L2.14535 5.9349C1.98257 5.74669 1.75198 5.63053 1.50381 5.61217C1.25564 5.59364 1.01046 5.67433 0.821593 5.83632Z" fill="#232323" />
                                </svg>
                            </button>
                        </li>
                        <Popover open={open} id={'id'} className="goal-list-filter" anchorEl={anchorEl ?? null} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}><ol>
                            <li className='sort-option' onClick={() => handleSortBy('NEWFIRST')}>Newest</li>
                            <li className='sort-option' onClick={() => handleSortBy('OLDESTFIRST')}>Oldest</li>
                        </ol></Popover>
                    </ul>
                </div>
                <div className="planning__left-searchbar">
                    <InputGroup
                        disabled={false}
                        leftIcon="search"
                        placeholder="Search Goal..."
                        value={searchText}
                        onChange={(t) => { setSearchText(t.target.value) }}
                    />
                </div>
                <div className="planning__left-list">
                    <ul>
                        {
                            data?.map((et, i) => {
                                const isGreenCheckVisibleFlag = isGreenCheckVisible(et);
                                return <li key={'list_goal_' + i} onClick={() => { if (!disabled) handleGoalSelection(et) }} className={et.id === newGoalState.goal.id ? 'active' : undefined}>
                                    <button>
                                        {!isGreenCheckVisibleFlag ?
                                            <div className="list-icon warning-sign" title={intl.formatMessage({ id: "app.title.required-info-missing" })}>
                                                <Icon icon="warning-sign" iconSize={13} />
                                            </div> : <div className="list-icon check-sign">
                                                <Icon icon="tick-circle" iconSize={13} />
                                            </div>}
                                        <div className="list-content">
                                            <h3>{et.statement ? et.statement : intl.formatMessage({ id: "app.titles.short-title-not-yet" })}</h3>
                                            {/* <p>{et.desired_outcome ? et.desired_outcome : intl.formatMessage({ id: "app.titles.no-description-yet" })}</p> */}
                                        </div>
                                    </button>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>)
}


export default GoalList;