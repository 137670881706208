import React, { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getSurveys } from "../../../../../store/surveys/actions";
import { Domain } from "../../../../../types";
import DomainListItem from "../../../../domains/DomainListItem";
import NoDomains from "../../../../domains/table/NoDomains";

type OwnProps = {
  selectedDomainsIds: number[];
  setSelectedDomainsIds: React.Dispatch<React.SetStateAction<number[]>>;
};

type Props = OwnProps;

const DomainSelectionTab: FunctionComponent<Props> = (props) => {
  const { selectedDomainsIds, setSelectedDomainsIds } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSurveys.request());
  }, []);

  const surveys = useSelector((s) => s.surveys.surveys);
  const domains = useSelector((s) => s.domains.domains);

  // grouping domains by survey
  const recordsBySurvey = domains.reduce((acc: any, record) => {
    let surveyId = record.survey;
    if (surveyId !== undefined && !acc[surveyId]) {
      acc[surveyId] = [];
    }
    surveyId &&
      acc[surveyId].push(record);
    return acc;
  }, {});

  const handleDomainClick = (domainId: number, isActive: boolean) => {
    if (isActive) {
      setSelectedDomainsIds((ids) => ids.filter((id) => id !== domainId));
    } else {
      setSelectedDomainsIds((ids) => [...ids, domainId]);
    }
  };

  if (!domains.length) {
    return <NoDomains />;
  }

  return (
    <>
      {surveys.map(survey => {
        return (
          <>
            {recordsBySurvey[survey.id] &&
              <p className="text-2xl mb-6 ml-10 mt-5">
                {survey.name + " " + intl.formatMessage({ id: "app.titles.domains" })}
              </p>
            }
            <div className="flex flex-wrap justify-center -m-4 mb-5">
              {recordsBySurvey[survey.id] && recordsBySurvey[survey.id].map((domain: Domain) => {
                const isActive = selectedDomainsIds.includes(domain.id);
                return (
                  <DomainListItem
                    key={domain.id}
                    isActive={isActive}
                    domain={domain}
                    onDomainClick={() => {
                      handleDomainClick(domain.id, isActive);
                    }}
                  />
                );
              })}
            </div>
          </>
        )
      })}
    </>
  );
};

export default DomainSelectionTab;
