import React, { FunctionComponent, useMemo } from "react";
import { HTMLSelect, InputGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";

type OwnProps = {
  searchString: string;
  setSearchString: (str: string) => any;
  pageSize: number;
  setPageSize: (pageSize: number) => any;
  customSetPageSize?: (pageSize: number) => any;
  showPageSizeSelector?: boolean;
  placeholder?: string;
  customPagination?: boolean;
};

type Props = OwnProps;

const TableSearchPanel: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const {
    pageSize,
    setPageSize,
    searchString,
    setSearchString,
    customSetPageSize,
    customPagination,
    showPageSizeSelector = true,
    placeholder = intl.formatMessage({ id: "app.titles.search" }),
  } = props;

  const options = useMemo(() => {
    return [10, 20, 30, 40, 50];
  }, []);

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const onSetPageSize = (pageSize: number) => {
    if (customSetPageSize && customPagination) {
      customSetPageSize(pageSize);
    } else {
      setPageSize(pageSize);
    }
  };

  return (
    <div className={`flex items-center px-1 -m-1`}>
      {showPageSizeSelector && (
        <div className="flex text-xl items-baseline m-2">
          Show
          <HTMLSelect
            className="mx-2"
            value={pageSize}
            onChange={(e) => {
              onSetPageSize(Number(e.target.value));
            }}
          >
            {options.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </HTMLSelect>
          entries
        </div>
      )}

      <InputGroup // todo add option to use onBlur for big tables
        className="m-2"
        type="search"
        placeholder={placeholder}
        leftIcon="search"
        value={searchString}
        onChange={handleSearchStringChange}
      />
    </div>
  );
};

export default TableSearchPanel;
