import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Icon, NonIdealState } from "@blueprintjs/core";
import ViewLogsTable from './view-logs-table/ViewLogsTable';
import './ViewLogsModal.scss';
import { useMemo, useState } from 'react';
import { PlanStrategy } from '../../../../../types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import ViewLogsChart from './view-logs-chart/ViewLogsChart';

type OwnProps = {
  planstrategy: PlanStrategy;
  activePlan?: number;
  handleClose: () => void;
};
type Props = OwnProps;

const ViewLogsModal: React.FC<Props> = (props) => {
  const { planstrategy, activePlan, handleClose } = props;

  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    handleClose();
  };

  const nonIdealState = useMemo(() => (
    <NonIdealState
      icon="search"
      className="pb-2"
      title={intl.formatMessage({
        id:
          "app.tables.implementation-logs.non-ideal-state.no-implementations",
      })}
    />
  ),
    [intl]
  );

  return (
    <div>
      <a onClick={handleClickOpen}> <Icon icon="folder-open" iconSize={14} /> {intl.formatMessage({id:"app.titles.view.logs"})} </a>
      <Dialog
        className="viewLogs-modal"
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="viewLogs-modal-heading">
          <h3>{intl.formatMessage({id:"app.titles.strategy"})} {intl.formatMessage({id:"app.titles.implementation-logs"})}</h3>
          <button onClick={handleClickClose}>
            <Icon icon="cross" iconSize={18} />
          </button>
        </div>

        <div className="viewLogs-modal__content">
          {
            !planstrategy.implementations.length ?
              nonIdealState
              : (
                <>
                  <ViewLogsChart planstrategy={planstrategy} />
                  <ViewLogsTable planstrategy={planstrategy} activePlan={activePlan} />
                </>
              )
          }
        </div>
      </Dialog>
    </div>
  );
}

export default ViewLogsModal;