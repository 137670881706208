import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import actions from "../actions";
import { from, of } from "rxjs";
import api from "../../api";

export const getPrioritizedNeedsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getPrioritizedNeeds.request)),
    switchMap((action) =>
      from(api.needs.getPrioritizedNeeds(action.payload)).pipe(
        map(actions.getPrioritizedNeeds.success),
        catchError((error) => of(actions.getPrioritizedNeeds.failure(error)))
      )
    )
  );
};

export const checkPrioritizedAreaEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.checkPrioritizedArea.request)),
    switchMap((action) =>
      from(api.needs.checkPrioritizedArea(action.payload)).pipe(
        map(actions.checkPrioritizedArea.success),
        catchError((error) => of(actions.checkPrioritizedArea.failure(error)))
      )
    )
  );
};

export const getDomainPrioritiesEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getDomainPriorities.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.getDomainPriorities(request)).pipe(
        mergeMap((domainPriorities) =>
          from(
            [actions.getDomainPriorities.success(domainPriorities)],
            onComplete && onComplete(domainPriorities)
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.getDomainPriorities.failure(error));
        })
      )
    )
  );
};

export const getPrioritiesEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.getPriorities.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.getPriorities(request)).pipe(
        mergeMap((priorities) =>
          from(
            [actions.getPriorities.success(priorities)],
            onComplete && onComplete(priorities)
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.getPriorities.failure(error));
        })
      )
    )
  );
};

export const addPriorityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addPriority.request)),
    switchMap(({ payload: { onComplete, id, ...request } }) =>
      from(api.needs.addPriority(request)).pipe(
        mergeMap((priority) =>
          from(
            [
              actions.addPriority.success({
                priority: priority,
                localId: id,
              }),
            ],
            onComplete && onComplete(priority)
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.addPriority.failure(error));
        })
      )
    )
  );
};

export const confirmPriorityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.confirmDomainPriorityStatus.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.confirmPriority(request?.schoolId, request?.surveyDeploymentId)).pipe(
        mergeMap((priority) =>
          from(
            [actions.confirmDomainPriorityStatus.success(priority)],
            onComplete && onComplete(priority)
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.confirmDomainPriorityStatus.failure(error));
        })
      )
    )
  );
};

export const updatePriorityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.updatePriority.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.updatePriority(request)).pipe(
        mergeMap((priority) =>
          from(
            [actions.updatePriority.success(priority)],
            onComplete && onComplete(priority)
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.updatePriority.failure(error));
        })
      )
    )
  );
};

export const addDomainPriorityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.addDomainPriority.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.addDomainPriority(request)).pipe(
        mergeMap((item) =>
          from(
            [actions.addDomainPriority.success(item)],
            onComplete && onComplete()
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.addDomainPriority.failure(error));
        })
      )
    )
  );
};

export const updateDomainPriorityEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updateDomainPriority.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.updateDomainPriority(request)).pipe(
        mergeMap((item) =>
          from(
            [actions.updateDomainPriority.success(item)],
            onComplete && onComplete()
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.updateDomainPriority.failure(error));
        })
      )
    )
  );
};

export const deletePriorityEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.deletePriority.request)),
    switchMap(({ payload }) =>
      from(api.needs.deletePriority(payload)).pipe(
        mergeMap(() => from([actions.deletePriority.success(payload.id)])),
        catchError((error) => {
          return of(actions.deletePriority.failure(error));
        })
      )
    )
  );
};

export const updateDomainPrioritiesDecisionEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updateDomainPrioritiesDecision.request)),
    switchMap(({ payload: { onComplete, ...request } }) => {
      return from(api.needs.updateDomainPrioritiesDecision(request)).pipe(
        mergeMap((response) =>
          from(
            [actions.updateDomainPrioritiesDecision.success(response)],
            onComplete && onComplete(response, request)
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.updateDomainPrioritiesDecision.failure(error));
        })
      );
    })
  );
};

export const prioritizeDatasetEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => {
  return action$.pipe(
    filter(isActionOf(actions.prioritizeDataset.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.prioritizeDataset(request)).pipe(
        mergeMap((needs) =>
          from(
            [actions.prioritizeDataset.success(needs)],
            onComplete && onComplete()
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.prioritizeDataset.failure(error));
        })
      )
    )
  );
};

export const deprioritizeDatasetEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deprioritizeDataset.request)),
    switchMap(({ payload: { onComplete, ...request } }) =>
      from(api.needs.deprioritizeDataset(request)).pipe(
        mergeMap(() =>
          from(
            [actions.deprioritizeDataset.success(request.needs)],
            onComplete && onComplete()
          )
        ),
        catchError((error) => {
          onComplete && onComplete();
          return of(actions.deprioritizeDataset.failure(error));
        })
      )
    )
  );
};
