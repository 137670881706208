import {
  Button,
  Classes,
  Dialog,
  Divider,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import Select, { ValueType, ActionMeta, OptionsType } from "react-select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { downloadSampleLetters } from "../../../store/surveys-deployment/actions";
import { getTeamMembersByPlan } from "../../../store/team-members/actions";
import { TeamRole } from "../../../types";
import { Locale, LocaleEnglishValues } from "../../../store/UIState";

type OwnProps = {
  surveyDeploymentId?: number;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = OwnProps;

const SampleLettersDialog: React.FC<Props> = (props: Props) => {
  const { isOpen, setOpen, surveyDeploymentId } = props;
  const activePlan = useSelector((s) => s.plans?.activePlan);
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const [schoolPrincipalName, setSchoolPrincipalName] = useState("");
  const [dataCoordinatorName, setDataCoordinatorName] = useState("");
  const [dataCoordinatorEmail, setDataCoordinatorEmail] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  const languageOption: { label: string; value: string }[] = useMemo(() => {
    const languageOptions: { label: string; value: string }[] = [];
    Object.keys(Locale).forEach(function (key, index) {
      if (key !== LocaleEnglishValues[Locale.English])
        languageOptions.push({
          label: key.replace(/([A-Z])/g, " $1").trim(),
          value: key,
        });
    });
    return languageOptions;
  }, [LocaleEnglishValues]);

  const members = useSelector((s) => s.teamMembers.teamMembers);
  // useEffect(() => {
  //   if (activePlan?.id) {
  //     dispatch(getTeamMembersByPlan.request({ planId: activePlan.id }));
  //   }
  // }, [activePlan?.id]);

  const handleDialogOpen = useCallback(() => {
    if (activePlan) {
      const { principal } = activePlan.school;
      setSchoolPrincipalName(
        principal?.first_name + " " + principal?.last_name
      );
      const firstDataCoordinator = members?.find(
        (m) => m.team_role === TeamRole.DataCoordinator
      );
      if (firstDataCoordinator) {
        setDataCoordinatorEmail(firstDataCoordinator.user?.email);
        setDataCoordinatorName(
          firstDataCoordinator.user?.first_name +
            " " +
            firstDataCoordinator.user?.last_name
        );
      } else {
        setDataCoordinatorName("");
        setDataCoordinatorEmail("");
      }
    }
  }, [activePlan, members]);

  const handleSchoolPrincipalNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSchoolPrincipalName(e.target.value);
  };

  const handleDataCoordinatorNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDataCoordinatorName(e.target.value);
  };

  const handleDataCoordinatorEmailChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDataCoordinatorEmail(e.target.value);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (activePlan?.id) {
      dispatch(
        downloadSampleLetters.request({
          surveyDeploymentId: surveyDeploymentId!,
          planId: activePlan.id,
          body: {
            data_coordinator_email: dataCoordinatorEmail,
            data_coordinator_name: dataCoordinatorName,
            team_champion_name: schoolPrincipalName,
            languages: selectedLanguages, //selectedLanguages?.map((e) => e?.value)
          },
        })
      );
    }
  };

  const languageSelection = function (e: any) {
    const lang: string[] = e?.map(
      (e: { label: string; value: string }) => e?.value
    );
    setSelectedLanguages(lang);
  };

  const loading = useSelector(
    (s) => s.surveysDeployment.loading.downloadSampleLetters
  );

  const error = useSelector(
    (s) => s.surveysDeployment.errors.downloadSampleLetters
  );

  useLoading({ loading, error });

  return (
    <Dialog
      className="w-1/3"
      style={{ minWidth: "500px" }}
      isOpen={isOpen}
      onClose={handleClose}
      onOpening={handleDialogOpen}
      icon={"document"}
      title={intl.formatMessage({
        id: "app.surveys-deployment-table.columns.actions.download-letters",
      })}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          {intl.formatMessage(
            { id: "app.sample-letters.header" },
            { b: (chunks) => <b>{chunks}</b> }
          )}
        </p>

        <Divider className="my-2" />
        <form id="sample-letters-form" onSubmit={handleFormSubmit}>
          <FormGroup
            label={intl.formatMessage({
              id: "app.sample-letters-dialog.data-coordinator-name",
            })}
          >
            <InputGroup
              value={dataCoordinatorName}
              onChange={handleDataCoordinatorNameChange}
              required
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.sample-letters-dialog.data-coordinator-email",
            })}
          >
            <InputGroup
              type="email"
              value={dataCoordinatorEmail}
              onChange={handleDataCoordinatorEmailChange}
              required
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({
              id: "app.sample-letters-dialog.school-principal-name",
            })}
          >
            <InputGroup
              value={schoolPrincipalName}
              onChange={handleSchoolPrincipalNameChange}
              required
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage(
              {
                id: "app.sample-letters-dialog.translations",
              },
              {
                em: (chunks) => (
                  <>
                    <em>{chunks}</em>
                  </>
                ),
              }
            )}
          >
            <Select
              isMulti
              isClearable={false}
              onChange={languageSelection}
              options={languageOption}
            />
          </FormGroup>
        </form>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button
            className="button-min-width"
            onClick={handleClose}
            disabled={loading}
          >
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            form="sample-letters-form"
            intent="primary"
            className="button-min-width"
            text={intl.formatMessage({ id: "app.resources-table.download" })}
            title={intl.formatMessage({ id: "app.resources-table.download" })}
            loading={loading}
            type="submit"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SampleLettersDialog;
