import { Dialog, Icon, Spinner } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getMeetingById,
  hideMeetingDialog,
} from "../../../../store/meetings/actions";
import UpsertMeetingDialogForm from "./UpsertMeetingDialogForm";
import { Meeting, MeetingTemplate } from "../../../../types";
import MeetingTemplatesList from "./MettingTemplatesList";
import * as constants from "../../../../constants/constants";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";

type OwnProps = {};

type Props = OwnProps;

const MeetingDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const { show, meeting, viewOnly, showMeetingTemplates } = useSelector(
    (s) => s.meetings.dialogs.showMeetingDialog
  );

  const selectedMeeting = useSelector((s) => s.meetings.meeting);

  const loading = useSelector((s) => s.meetings.loading.getMeetingById);
  const error = useSelector((s) => s.meetings.errors.getMeetingById);
  useLoading({ loading, error });
  const handleOpen = () => {
    if (meeting) {
      dispatch(
        getMeetingById.request({ id: meeting.id, planId: meeting.plan })
      );
    }
  };

  const dispatch = useDispatch();

  const handleClose = (isSaveAndAddOther?: boolean) => {
    setSelectedTemplate(undefined);
    setSkipSelectingTemplate(false);
    if (!isSaveAndAddOther) {
      dispatch(hideMeetingDialog());
    }
  };
  const showConfirmEvent = () => {
    if (viewOnly === false) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            handleClose();
            dispatch(hideConfirmDialog());
          },
          show: true,
          intent: "warning",
          text: intl.formatMessage({
            id: "app.confirmation-meetings.event-common-text",
          }),
          icon: "info-sign",
          confirmButtonText: intl.formatMessage({
            id: "app.forms.surveys.answers.yes",
          }),
          cancelButtonText: intl.formatMessage({
            id: "app.forms.surveys.answers.no",
          }),
        })
      );
    } else {
      handleClose();
    }
  };
  const [selectedTemplate, setSelectedTemplate] = useState<MeetingTemplate>();
  const [skipSelectingTemplate, setSkipSelectingTemplate] = useState<boolean>(
    false
  );

  const showMeetingTemplatesList = useMemo(() => {
    return !selectedTemplate && showMeetingTemplates && !skipSelectingTemplate;
  }, [selectedTemplate, showMeetingTemplates, skipSelectingTemplate]);

  const upsertMeetingDialogEditableFields = useMemo<(keyof Meeting)[]>(() => {
    if (viewOnly) {
      return [];
    }
    if (selectedTemplate) {
      return [
        constants.MEETING_DATE_FIELD,
        constants.MEETING_END_DATE_FIELD,
      ] as (keyof Meeting)[];
    }
    return [
      constants.MEETING_DATE_FIELD,
      constants.MEETING_END_DATE_FIELD,
      constants.MEETING_AGENDA_FIELD,
      constants.MEETING_PRE_WORK_FIELD,
      constants.MEETING_ATTACHED_RESOURCES_FIELD,
      constants.MEETING_TITLE_FIELD,
      constants.MEETING_SHORT_TITLE_FIELD,
      constants.MEETING_TIMING_GUIDANCE,
      constants.MEETING_GENERAL_NOTES_FIELD,
    ] as (keyof Meeting)[];
  }, [selectedTemplate, meeting, viewOnly]);

  return (
    <Dialog
      isOpen={show}
      onOpening={handleOpen}
      onClose={() => showConfirmEvent()}
      icon={<Icon icon={!!meeting ? "edit" : "comment"} iconSize={24} />}
      title={intl.formatMessage({
        id: showMeetingTemplates
          ? !!selectedTemplate
            ? "app.meetings.dialog.event-title"
            : "app.meetings.setup-calendar"
          : viewOnly == false
          ? "app.meetings.dialog.edit-title"
          : `app.meetings.dialog.${!!meeting ? "update" : "event"}-title`,
      })}
      className={"meeting-dialog"}
      enforceFocus={false}
    >
      {showMeetingTemplatesList ? (
        <MeetingTemplatesList
          setSelectedTemplate={setSelectedTemplate}
          setSkipSelectingTemplates={setSkipSelectingTemplate}
        />
      ) : loading ? (
        <Spinner intent="primary" />
      ) : (
        <UpsertMeetingDialogForm
          selectedTemplate={selectedTemplate}
          meeting={selectedMeeting}
          onDialogClose={handleClose}
          editableFields={upsertMeetingDialogEditableFields}
        />
      )}
    </Dialog>
  );
};

export default MeetingDialog;
