import React, { useState } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";
import { Button, Dialog } from "@blueprintjs/core";
import GenericCollapse from "../../common/GenericCollapse";
import { SurveyDeploymentYear } from "../../../types";

type OwnProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  distinctSurveyDeployments: SurveyDeploymentYear[];
  distinctSchoolYear: string[];
  handleSelectChange: (
    value: string | SurveyDeploymentYear,
    comparisonReportType: string
  ) => void;
};

type Props = OwnProps;

const ComparisonReportDialog: React.FC<Props> = (props) => {
  const intl = useIntl();
  const {
    isOpen,
    setOpen,
    distinctSurveyDeployments,
    distinctSchoolYear,
    handleSelectChange,
  } = props;

  const SCHOOL_BY_SAME_DEPLOYMENT = "school-by-same-deployment";
  const SCHOOL_BY_MOST_RECENT_SURVEY = "school-by-most-recent-survey";
  const [
    selectedDeploymentValue,
    setSelectedDeploymentValue,
  ] = useState<SurveyDeploymentYear>();

  const [selectedSchoolValue, setSelectedSchoolValue] = useState<string>();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeploymentViewReport = () => {
    if (selectedDeploymentValue)
      handleSelectChange(selectedDeploymentValue, SCHOOL_BY_SAME_DEPLOYMENT);
  };

  const handleSchoolViewReport = () => {
    if (selectedSchoolValue)
      handleSelectChange(selectedSchoolValue, SCHOOL_BY_MOST_RECENT_SURVEY);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={intl.formatMessage({
        id: "app.survey-deployment.comparison-reports",
      })}
      style={{ width: "50rem" }}
      portalClassName="activity-content-portal"
    >
      <div className="p-3">
        {intl.formatMessage({
          id: "app.survey-deployment.comparison-report-dialog",
        })}
      </div>
      <div className="p-3">
        <GenericCollapse
          title={intl.formatMessage({
            id: "app.survey-deployment." + SCHOOL_BY_SAME_DEPLOYMENT,
          })}
        >
          <div className="p-1">
            <div className="mb-4">
              {intl.formatMessage({
                id: "app.survey-deployment.school-by-same-deployment-info",
              })}
            </div>
            <div className="mx-4">
              <label className="font-semibold">
                {intl.formatMessage({
                  id: "app.district-comparison.comparison-report.deployment",
                })}
                :
              </label>
              <Select
                className="w-20rem mt-4"
                options={distinctSurveyDeployments?.map(
                  (sd: SurveyDeploymentYear) => ({
                    label:
                      sd.deployment + " Adminstration #" + sd.administration,
                    value: sd,
                  })
                )}
                onChange={(value) =>
                  setSelectedDeploymentValue(
                    value?.value as SurveyDeploymentYear
                  )
                }
              />
              <div className="text-right mt-2">
                <Button
                  intent="primary"
                  text={intl.formatMessage({
                    id: "app.survey-deployment.veiw-comparison-report",
                  })}
                  onClick={handleDeploymentViewReport}
                />
              </div>
            </div>
          </div>
        </GenericCollapse>
      </div>
    </Dialog>
  );
};

export default ComparisonReportDialog;
