import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { Button, ButtonGroup } from "@blueprintjs/core";
import {
  CollectedDemographicDataEnum,
  SurveyDemographic,
} from "../../../../types";
import { useSelector } from "react-redux";
import TourStepPopover from "../../../tour/TourStepPopover";
import _ from "lodash";
import { sortDemographicData } from "../../../../helpers/demographic-data";

type OwnProps = {
  domainId: number | undefined;
  surveyDeploymentId: number | undefined;
  tabId: string | undefined;

  groupingKeys: CollectedDemographicDataEnum[];
  onGroupByButtonClick: (buttonKey: CollectedDemographicDataEnum) => void;
};

type Props = OwnProps;

const SurveyGroupByTableHead: React.FC<Props> = (props) => {
  const intl = useIntl();

  const {
    groupingKeys,
    onGroupByButtonClick,
    surveyDeploymentId,
    tabId,
    domainId,
  } = props;

  const selectedSurveyDeployment = useSelector((s) =>
    s.surveysDeployment.surveysDeploymentBySchool.find(
      (sd) => sd.id === surveyDeploymentId
    )
  );

  const sortedDemographicData = useMemo(() => {
    const demographicData =
      selectedSurveyDeployment?.survey?.demographic_data?.find(
        (dd) => dd.demographic === ((tabId as unknown) as SurveyDemographic)
      )?.demographic_data ?? [];

    const sortByDemographic = sortDemographicData(tabId);

    return _.sortBy(demographicData, sortByDemographic);
  }, [selectedSurveyDeployment, tabId]);

  return (
    <div className="flex">
      {!!sortedDemographicData.length && (
        <>
          <p className="text-white pr-2 flex items-center">
            {intl.formatMessage({
              id: "app.survey-report.table.group-by-head.group-by",
            })}
          </p>
          <TourStepPopover dataTour="grouping" disabled={!!domainId}>
            <ButtonGroup large data-tour="grouping" className="flex-wrap">
              {sortedDemographicData.map((data, index) => {
                const isActive = groupingKeys?.some((key) => key === data);
                const isDisabled =
                  !isActive && groupingKeys && groupingKeys.length >= 2;
                return (
                  <Button
                    key={index}
                    rightIcon={isActive ? "tick" : undefined}
                    active={isActive}
                    disabled={isDisabled}
                    text={intl.formatMessage({
                      id: `app.survey-report.table.group-by-head.${data}`,
                    })}
                    title={intl.formatMessage({
                      id: `app.survey-report.table.group-by-head.${data}`,
                    })}
                    onClick={() => onGroupByButtonClick(data)}
                  />
                );
              })}
            </ButtonGroup>
          </TourStepPopover>
        </>
      )}
    </div>
  );
};

export default SurveyGroupByTableHead;
