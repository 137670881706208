import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride";
import TooltipComponent from "../../../../tour/TooltipComponent";
import moment from "moment";
import useDashboardTourFix from "./useDashboardTourFix";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useLocation } from "react-router";
import { markStepCompleted, updateMakingProgressTourTimeWatched } from "../../../../../store/tours/actions";
import useUserRole from "../../../../../helpers/hooks/useUserRole";
import { AuthRoutes } from "../../../../../App";
import { DistrictSetupTaskKey } from "../../../../../types";
import SuperintendentTooltip from "../../../../tour/SuperintendentTooltip";
import { isDistrictPlan } from "../../../../../store/plans/selectors";
import useUserPlanAssociation from "../../../../../helpers/hooks/useUserPlanAssociation";
import { styled , Backdrop } from "@material-ui/core";

type OwnProps = {};

type Props = OwnProps;

const HOURS_BREAKPOINT = 4;

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: '#fff',
  zIndex: theme.zIndex.drawer + 1,
}));

const SuperintendentProgressTour: FunctionComponent<Props> = (props) => {

  const intl = useIntl();

  const history = useHistory();
  const dispatch = useDispatch();
  const isActiveDistrictPlan = useSelector(isDistrictPlan);
  const {
    planSchoolId,
    planDistrictId,
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
  } = useUserPlanAssociation();

  const { isDCC, isSuperintendent, user } = useUserRole();
  const [show, setShow] = useState<boolean>(false);
  const [refresh , setRefresh] = useState<boolean>(false);
  const { rerender } = useDashboardTourFix({ show });
  const { state: locationState } = useLocation<{ fromWorkSpace?: string }>();

  const steps: Step[] = useMemo((): Step[] => {
    const items = [
      {
        target: '[data-tour="manage-users-tour"]',
        content: intl.formatMessage({id:"app.tours.superintendent-setup.making-progress.invite-school-principal"}),
        disableBeacon: true,
      },
      {
        target: '[data-tour="district"]',
        content:"",
        disableBeacon: true,
      },
      {
        target: '[data-tour="manage-school-users"] ',
        content:intl.formatMessage({id:"app.tours.superintendent-setup.making-progress.manage-school-users"}),
        disableBeacon: true,
      },
      {
        target: '[data-tour="add-school-button"]',
        content:intl.formatMessage({id:  "app.tours.superintendent-setup.making-progress.add-school-button"}),
        disableBeacon: true,
      },
      {
        target: '[data-tour=""]',
        content:"",
        disableBeacon: true,
        run:true,
      },
      {
        target: '[data-tour="team-development-presentation"]',
        content:intl.formatMessage({id:"app.tours.superintendent-setup.making-progress.leadership-team-development"})    ,
        disableBeacon: true,
        run:true,
      },
      {
        target: '[data-tour="setup-calendar-button"]',
        content:intl.formatMessage({id:"app.tours.superintendent-setup.making-progress.dashboard-calendar-button"}),
        disableBeacon: true,
      },{
        target: '[data-tour="setup-calenda-step-2-button"]',
        content:intl.formatMessage({id:"app.tours.superintendent-setup.making-progress.add-calendar-event"}),
        disableBeacon: true,
      },
    ];
    if (
      locationState?.fromWorkSpace &&
      locationState.fromWorkSpace === "welcome"
    ) {
      const welcomeStep: Step = {
        target:'[data-tour="team-member-table-isDCC-checkbox"]',
        content: intl.formatMessage({
          id:"app.tours.superintendent-setup.making-progress.isDCC-checkbox",
        }),
        disableBeacon: true,
      };
      return [welcomeStep, ...items];
    }

    return items;
  }, []);

  const handleJoyrideAction = (data: CallBackProps) => {
        data.action==="prev" && setShow(false);
        if(data.action==="prev" && data.step.target==='[data-tour="manage-users-tour"]'){ 
           dispatch(markStepCompleted({
          key: DistrictSetupTaskKey.DesignateDistrictClimateCoordinator,
        })) 
      }else if(data.action==="prev" && data.step.target==='[data-tour="add-school-button"]'){

        dispatch(markStepCompleted({
          key: DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
        }))
        }else if(data.action==="prev" && data.step.target==='[data-tour="team-development-presentation"]'){
          dispatch(markStepCompleted({
            key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
          }))
        }

switch (data.step.target) {
    case '[data-tour="district"]':
        dispatch(markStepCompleted({
                      key: DistrictSetupTaskKey.DesignateDistrictClimateCoordinator,
                    })) 
        history.push(
                generatePath(AuthRoutes.YourPlanDistrict, {  
                  workspace: "my-district" as any,
                } as any),
                {
                  fromWorkSpace: "welcome",
                  }
              );
        break;
    case '[data-tour=""]':
          dispatch(markStepCompleted({
            key: DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
          }))
         
        history.push(
                generatePath(AuthRoutes.YourPlanTeam, {  
                  workspace: "team" as any,
                } as any),
                {
                  fromWorkSpace: "welcome",
                  }
              );
              setTimeout(() =>{
                history.push(
                generatePath(AuthRoutes.YourPlanTeam, {  
                  workspace: "team" as any,
                } as any),
                {
                  fromWorkSpace: "welcome",
                  }
              );
              },100)
        break;
        case '[data-tour="team-development-presentation"]':
            dispatch(markStepCompleted({
                key: DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
              }))
        break;
        case '[data-tour="setup-calendar-button"]':
                         dispatch(markStepCompleted({
                key: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
              }))
        history.push(
                generatePath(`/plan/team/${isActiveDistrictPlan ? 'dclt_sci_' : 'sclt_sci_'}calendar` as any),
                {
                  fromWorkSpace: "welcome",
                  }
              );
        break;

    default:
        break;
}
  

  if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)) {
        setShow(false);  
        dispatch(updateMakingProgressTourTimeWatched(new Date()));  
         isActiveDistrictPlan ?dispatch(markStepCompleted({key: DistrictSetupTaskKey.SetupDistrictCalendar,})):dispatch(markStepCompleted({
                key: DistrictSetupTaskKey.SetupSchoolCalendar,
                schoolId: planSchoolId,

              }))
      }
  
  };

  const {
    selectedDistrictSetupTaskKey: dialogInfoKey,
    setupCompleted,
    districtSetupState: tours,
  } = useSelector((s) => s.tours);

  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );

  useEffect(() => {
    if (setupCompleted) {
      setShow(false);
    } else {
      const { makingProgressTourTimeWatched: time } = tours;
      if (!dialogInfoKey) {
        if (time) {
          const diff = moment().diff(moment(time), "hours");
          if (diff >= HOURS_BREAKPOINT) {
            setShow(true);
          }
        } else {
          //first visit (after welcome page)
          setShow(true);
        }
      }
      dispatch(updateMakingProgressTourTimeWatched(new Date()));
    }
  }, [setupCompleted]);

  let tourTimeOut:any;
  useEffect(()=>{

  tourTimeOut = setTimeout(()=>{
    setRefresh(!refresh);
  },2000)

    return ()=>{
      clearTimeout(tourTimeOut);

    }
  },[]);

  return (
    loading? <StyledBackdrop open={true} /> :  <ReactJoyride
      key={rerender}
      run={show && !loading}
      steps={steps}
      tooltipComponent={SuperintendentTooltip}
      continuous
      callback={handleJoyrideAction}
      
      locale={{
        next: { title: intl.formatMessage({ id: "app.titles.next" }) },
      }}
      disableOverlayClose
    />
  );
};

export default SuperintendentProgressTour;
