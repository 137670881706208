import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Card, Icon, Spinner } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isDistrictPlan } from "../../../../../store/plans/selectors";
import {
  ACTIVITY_LINK_CLASSNAME,
  useAttachActivities,
} from "../../../../../helpers/hooks/useAttachActivities";
import { Activity, ActivityTypeEnum } from "../../../../../types";
import { showActivityVideoDialog } from "../../../../../store/activities/actions";
import { useLoading } from "../../../../../helpers/hooks/useLoading";
import { getMeetings } from "../../../../../store/meetings/actions";
import DistrictClimateTeamDevelopmentTour from "../../tours/DistrictClimateTeamDevelopmentTour";
import InfoBar from "../../../InfoBar/InfoBar";

type OwnProps = {};

type Props = OwnProps;
const GUIDELINES_URL =
  "https://platform.njschoolclimate.org/files/activity/Mg==/download/pdf/Guidance+on+School+Climate+Leadership+Team+Development.pdf";

const TeamHomeTabButtons: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  useEffect(() => {
    if (activePlan?.id) {
      // dispatch(getTeamMembersByPlan.request({ planId: activePlan.id }));
      dispatch(getMeetings.request(activePlan.id));
    }
  }, [activePlan?.id]);

  const members = useSelector((s) => s.teamMembers.teamMembers);
  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  const error = useSelector((s) => s.teamMembers.errors.getTeamMembersByPlan);
  useLoading({ error, loading });

  const showGuidanceButton = useMemo(() => {
    return !!activePlan?.school?.id;
  }, [activePlan?.school?.id]);

  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      switch (activity.activity_type) {
        case ActivityTypeEnum.KALTURA_VIDEO:
          dispatch(
            showActivityVideoDialog({
              activity: activity,
              viewable_field: "embed_code",
            })
          );
          break;

        default:
          break;
      }
    },
  });

  return (
    <>
    <ul>
      <li data-tour="team-development-presentation">
        <button 
        className={`${ACTIVITY_LINK_CLASSNAME} playButton`} 
        onClick={onActivityClick(
          intl.formatMessage(
            {
              id: "app.team-home-setup.learn_about_team_development",
            },
            { isSchoolPlan: !!activePlan?.school?.id }
          )
        )}
        data-cy="team-development-presentation"
        >
        <span className="playButtonBg">
          <Icon iconSize={15} icon="play"/>
        </span> {intl.formatMessage(
                  {
                    id: "app.team-home.team-development-video",
                  },
                  { isSchoolPlan: !!activePlan?.school?.id }
                )}
         
      </button>
      </li>
      <li>
      {showGuidanceButton && (
            <div className="flex flex-col items-center p-1">
              <a target="_blank" href={GUIDELINES_URL}>
              <Icon iconSize={16} icon="import"/> Guidance on SCLT Development
                {/* <Card
                  elevation={2}
                  className="bg-blue-500 cursor-pointer mt-2 p-2 bk-min-16"
                  //onClick={handleTeamDevelopmentGuidanceClick}
                >
                  <p className="text-white text-center">
                    {intl.formatMessage(
                      {
                        id: "app.team-home.download-team-development-guidance",
                      },
                      { i: (chunks) => <i>{chunks}</i> }
                    )}
                  </p>
                </Card> */}
              </a>
            </div>
          )}
      </li>
      <li>
       <InfoBar />
      </li>
      {/* <DistrictClimateTeamDevelopmentTour /> */}
    </ul>
      </>
   
  );
};

export default TeamHomeTabButtons;
