import { createReducer, RootAction } from "typesafe-actions";
import * as actions from "./actions";
import {
  UserEntity,
  UserInvite,
  UserModel,
  UserLoginHistory,
  UserTermsAgreement,
  LoginHistory,
  UserActionAudit,
  UserActionAuditResponse,
  CountAudit,
} from "../../types";

type ActionName =
  | "getUsers"
  | "inviteUsers"
  | "getInviteByCode"
  | "editUser"
  | "editInvitedUser"
  | "redispatchInvite"
  | "getUserTermsAgreement"
  | "deleteUserTermsAgreement"
  | "getUserLoginHistory"
  | "getUserActionAudit"
  | "getAuditCounts"
  | "downloadDistrictSummaryCounts"
  | "downloadSchoolSummaryCounts";

export type UsersState = {
  users: UserEntity;
  usersAgreement: UserTermsAgreement[];
  failedInvites: UserInvite[];
  userLoginHistory?: LoginHistory;
  auditActionData?: UserActionAuditResponse;
  countAudits: CountAudit[];
  downloadDistrictSummary: any;
  downloadSchoolSummary: any;
  userInvite?: UserInvite;
  isDCCPreselected?: boolean;
  showInviteUsersDialog?: boolean;
  showUserEditingDialog?: boolean;
  showAddUserToPlanDialog?: boolean;
  onSuccess?: Function;

  selectedUser?: UserModel | UserInvite;

  amountOfSucceedInvites?: number;
  amountOfFailedInvites?: number;

  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: UsersState = {
  users: {},
  usersAgreement: [],
  userLoginHistory: {},
  auditActionData: { list: [], totalcount: 0 },
  countAudits: [],
  failedInvites: [],
  loading: {},
  errors: {},
  downloadDistrictSummary: undefined,
  downloadSchoolSummary: undefined
};

export const usersReducer = createReducer<UsersState, RootAction>(initialState)
  // get users
  .handleAction(
    actions.getUsers.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        getUsers: true,
      },
      errors: {
        ...state.errors,
        getUsers: undefined,
      },
    })
  )
  .handleAction(
    actions.getUsers.success,
    (state, action): UsersState => ({
      ...state,
      users: action.payload,
      loading: {
        ...state.loading,
        getUsers: false,
      },
    })
  )
  .handleAction(
    actions.getUsers.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        getUsers: action.payload,
      },
      loading: {
        ...state.loading,
        getUsers: false,
      },
    })
  )
  // invite users
  .handleAction(
    actions.inviteUsers.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        inviteUsers: true,
      },
      errors: {
        ...state.errors,
        inviteUsers: undefined,
      },
    })
  )
  .handleAction(
    actions.inviteUsers.success,
    (state, action): UsersState => ({
      ...state,
      users: {
        ...state.users,
        invites: !!state.users?.invites
          ? [...state.users?.invites, ...action.payload.success]
          : action.payload.success,
      },
      failedInvites: action.payload.failed,
      amountOfSucceedInvites: action.payload.success?.length,
      amountOfFailedInvites: action.payload.failed?.length,
      loading: {
        ...state.loading,
        inviteUsers: false,
      },
    })
  )
  .handleAction(
    actions.inviteUsers.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        inviteUsers: action.payload,
      },
      loading: {
        ...state.loading,
        inviteUsers: false,
      },
    })
  )
  // get invite by code
  .handleAction(
    actions.getInviteByCode.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        getInviteByCode: true,
      },
      errors: {
        ...state.errors,
        getInviteByCode: undefined,
      },
    })
  )
  .handleAction(
    actions.getInviteByCode.success,
    (state, action): UsersState => ({
      ...state,
      userInvite: action.payload,
      loading: {
        ...state.loading,
        getInviteByCode: false,
      },
    })
  )
  .handleAction(
    actions.getInviteByCode.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        getInviteByCode: action.payload,
      },
      loading: {
        ...state.loading,
        getInviteByCode: false,
      },
    })
  )
  // change selected user invite
  .handleAction(
    actions.changeSelectedUserInvite,
    (state, action): UsersState => ({
      ...state,
      userInvite: action.payload,
    })
  ) // invite users dialog
  .handleAction(
    actions.showInviteUsersDialog,
    (state, action): UsersState => ({
      ...state,
      showInviteUsersDialog: true,
      isDCCPreselected: action.payload.dccPreselected,
      onSuccess: action.payload.onSuccess,
    })
  )
  .handleAction(
    actions.hideInviteUsersDialog,
    (state, action): UsersState => ({
      ...state,
      showInviteUsersDialog: false,
      failedInvites: [],
      amountOfFailedInvites: undefined,
      amountOfSucceedInvites: undefined,
      isDCCPreselected: undefined,
      onSuccess: undefined,
    })
  )
  // add user to plan dialog
  .handleAction(
    actions.showAddUserToPlanDialog,
    (state, action): UsersState => ({
      ...state,
      showAddUserToPlanDialog: true,
    })
  )
  .handleAction(
    actions.hideAddUserToPlanDialog,
    (state, action): UsersState => ({
      ...state,
      showAddUserToPlanDialog: false,
    })
  )
  // user editing dialog
  .handleAction(
    actions.showUserEditingDialog,
    (state, action): UsersState => ({
      ...state,
      showUserEditingDialog: true,
      selectedUser: action.payload,
    })
  )
  .handleAction(
    actions.hideUserEditingDialog,
    (state, action): UsersState => ({
      ...state,
      showUserEditingDialog: false,
      selectedUser: undefined,
    })
  )
  // edit user
  .handleAction(
    actions.editUser.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        editUser: true,
      },
      errors: {
        ...state.errors,
        editUser: undefined,
      },
    })
  )
  .handleAction(
    actions.editUser.success,
    (state, action): UsersState => ({
      ...state,
      users: {
        ...state.users,
        users: state.users?.users?.map((u) =>
          u.id === action.payload?.id ? action.payload : u
        ),
      },
      loading: {
        ...state.loading,
        editUser: false,
      },
    })
  )
  .handleAction(
    actions.editUser.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        editUser: action.payload,
      },
      loading: {
        ...state.loading,
        editUser: false,
      },
    })
  )
  // edit Invited user
  .handleAction(
    actions.editInvitedUser.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        editInvitedUser: true,
      },
      errors: {
        ...state.errors,
        editInvitedUser: undefined,
      },
    })
  )
  .handleAction(
    actions.editInvitedUser.success,
    (state, action): UsersState => ({
      ...state,
      users: {
        ...state.users,
        invites: state.users?.invites?.map((u) =>
          u.id === action.payload?.id ? action.payload : u
        ),
      },
      loading: {
        ...state.loading,
        editInvitedUser: false,
      },
    })
  )
  .handleAction(
    actions.editInvitedUser.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        editInvitedUser: action.payload,
      },
      loading: {
        ...state.loading,
        editInvitedUser: false,
      },
    })
  )
  // redispatch invite
  .handleAction(
    actions.redispatchInvite.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        redispatchInvite: true,
      },
      errors: {
        ...state.errors,
        redispatchInvite: undefined,
      },
    })
  )
  .handleAction(
    actions.redispatchInvite.success,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        redispatchInvite: false,
      },
    })
  )
  .handleAction(
    actions.redispatchInvite.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        redispatchInvite: action.payload,
      },
      loading: {
        ...state.loading,
        redispatchInvite: false,
      },
    })
  )
  // get user terms agreement
  .handleAction(
    actions.getUserTermsAgreement.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        getUserTermsAgreement: true,
      },
      errors: {
        ...state.errors,
        getUserTermsAgreement: undefined,
      },
    })
  )
  .handleAction(
    actions.getUserTermsAgreement.success,
    (state, action): UsersState => ({
      ...state,
      usersAgreement: action.payload,
      loading: {
        ...state.loading,
        getUserTermsAgreement: false,
      },
    })
  )
  .handleAction(
    actions.getUserTermsAgreement.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        getUserTermsAgreement: action.payload,
      },
      loading: {
        ...state.loading,
        getUserTermsAgreement: false,
      },
    })
  )
  // delete user terms agreement
  .handleAction(
    actions.deleteUserTermsAgreement.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteUserTermsAgreement: true,
      },
      errors: {
        ...state.errors,
        deleteUserTermsAgreement: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteUserTermsAgreement.success,
    (state, action): UsersState => ({
      ...state,
      usersAgreement: state.usersAgreement.filter(
        (ua) => ua.id !== action.payload
      ),
      loading: {
        ...state.loading,
        deleteUserTermsAgreement: false,
      },
    })
  )
  .handleAction(
    actions.deleteUserTermsAgreement.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteUserTermsAgreement: action.payload,
      },
      loading: {
        ...state.loading,
        deleteUserTermsAgreement: false,
      },
    })
  )
  // user login history
  .handleAction(
    actions.getUserLoginHistory.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        getUserLoginHistory: true,
      },
      errors: {
        ...state.errors,
        getUserLoginHistory: undefined,
      },
    })
  )
  .handleAction(
    actions.getUserLoginHistory.success,
    (state, action): UsersState => ({
      ...state,
      userLoginHistory: action.payload,
      loading: {
        ...state.loading,
        getUserLoginHistory: false,
      },
    })
  )
  .handleAction(
    actions.getUserLoginHistory.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        getUserLoginHistory: action.payload,
      },
      loading: {
        ...state.loading,
        getUserLoginHistory: false,
      },
    })
  )
  // audit action data
  .handleAction(
    actions.getUserActionAudit.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        getUserActionAudit: true,
      },
      errors: {
        ...state.errors,
        getUserActionAudit: undefined,
      },
    })
  )
  .handleAction(
    actions.getUserActionAudit.success,
    (state, action): UsersState => ({
      ...state,
      auditActionData: action.payload,
      loading: {
        ...state.loading,
        getUserActionAudit: false,
      },
    })
  )
  .handleAction(
    actions.getUserActionAudit.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        getUserActionAudit: action.payload,
      },
      loading: {
        ...state.loading,
        getUserActionAudit: false,
      },
    })
  )

  // District Summry Counts
  .handleAction(
    actions.getAuditCounts.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        getAuditCounts: true,
      },
      errors: {
        ...state.errors,
        getAuditCounts: undefined,
      },
    })
  )
  .handleAction(
    actions.getAuditCounts.success,
    (state, action): UsersState => ({
      ...state,
      countAudits: action.payload,
      loading: {
        ...state.loading,
        getAuditCounts: false,
      },
    })
  )
  .handleAction(
    actions.getAuditCounts.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        getAuditCounts: action.payload,
      },
      loading: {
        ...state.loading,
        getAuditCounts: false,
      },
    })
  )

  // download static report
  .handleAction(
    actions.downloadDistrictSummaryCounts.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadDistrictSummaryCounts: true,
      },
      errors: {
        ...state.errors,
        downloadDistrictSummaryCounts: undefined,
      },
    })
  )
  .handleAction(
    actions.downloadDistrictSummaryCounts.success,
    (state, action): UsersState => {
      return {
        ...state,
        downloadDistrictSummary: action.payload,
        loading: {
          ...state.loading,
          downloadDistrictSummaryCounts: false,
        },
      }
    }
  )
  .handleAction(
    actions.downloadDistrictSummaryCounts.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        downloadDistrictSummaryCounts: action.payload,
      },
      loading: {
        ...state.loading,
        downloadDistrictSummaryCounts: false,
      },
    })
  )

  .handleAction(
    actions.downloadSchoolSummaryCounts.request,
    (state, action): UsersState => ({
      ...state,
      loading: {
        ...state.loading,
        downloadSchoolSummaryCounts: true,
      },
      errors: {
        ...state.errors,
        downloadSchoolSummaryCounts: undefined,
      },
    })
  )
  .handleAction(
    actions.downloadSchoolSummaryCounts.success,
    (state, action): UsersState => {
      return {
        ...state,
        downloadSchoolSummary: action.payload,
        loading: {
          ...state.loading,
          downloadSchoolSummaryCounts: false,
        },
      }
    }
  )
  .handleAction(
    actions.downloadSchoolSummaryCounts.failure,
    (state, action): UsersState => ({
      ...state,
      errors: {
        ...state.errors,
        downloadSchoolSummaryCounts: action.payload,
      },
      loading: {
        ...state.loading,
        downloadSchoolSummaryCounts: false,
      },
    })
  )