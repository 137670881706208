import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getGoalActivityAllLog } from '../../../../../store/goals/actions';
import { Domain, Goal, GoalActivityLog, GoalActivityLogAll, GoalIndicatorTarget, Plan } from "../../../../../types";
import GoalActivityTable from "./GoalActivityTable";
import { INDICATOR_DOMAIN_LEVEL } from "../../../../../constants/constants";


type OwnProps = {};

type Props = OwnProps;

const GoalActivityAll: FunctionComponent<Props> = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const goalActivityDataAll = useSelector(s => s.goals.goalActivityDataAll);
    const [customPageSize, setCustomPageSize] = useState<number>(10);
    const [customPageIndex, setCustomPageIndex] = useState<number>(0);

    const customSetPageSize = (page: number) => {
        setCustomPageSize(page);
      };
    
      const customGoToPage = (index: number) => {
        setCustomPageIndex(index);
      };

    useEffect(() => {
    dispatch(
        getGoalActivityAllLog.request({
        page_no: 1 + customPageIndex,
        page_size: customPageSize,
        })
    );
    }, [customPageSize, customPageIndex]);


    const customPageCount = useMemo(() => {
        if (goalActivityDataAll?.totalcount) {
          if (
            Math.ceil(goalActivityDataAll?.totalcount / customPageSize) <
            1 + customPageIndex
          ) {
            setCustomPageIndex(0);
          }
          return Math.ceil(goalActivityDataAll?.totalcount / customPageSize);
        }
        return 0;
      }, [customPageSize, goalActivityDataAll]);
    
    const goalActivityData = useMemo(() => {
      if (goalActivityDataAll?.list) {
        const result: Goal[] = goalActivityDataAll?.list?.map(
          (et: any) => {
            return {
              id: et.id,
              is_active: et.is_active,
              statement: et.statement,
              is_archived: et.is_archived,
              is_completed: et.is_completed,
              is_confirmed: et.is_confirmed,
              created_at: et.created_at,
              updated_at: et.updated_at,
              user: et.user,
              goal: et.goal,
              plan_strategies: et.plan_strategies,
              targets : et.targets,
              plans : et.plans,
              domain_targets : et.targets?.filter((ep: GoalIndicatorTarget) => {
                if(ep.type == INDICATOR_DOMAIN_LEVEL){
                  return true
                }
              }).map((et: GoalIndicatorTarget) =>{
              return `${et.domain_priority.domain.name} (${intl.formatMessage({
                id: `app.surveys.survey-demographic.${et.demographic}`,
              })})`
              }).join("; "),
              item_targets : et.targets?.filter((ep: GoalIndicatorTarget) => {
                if(ep.type != INDICATOR_DOMAIN_LEVEL){
                  return true
                }
              }).map((et: GoalIndicatorTarget) =>{
              return `${et.item_priority?.item.short_code?et.item_priority?.item.short_code:et.item_priority?.item.text} (${intl.formatMessage({
                id: `app.surveys.survey-demographic.${et.demographic}`,
              })})`
              }).join("; "),
              school_name : et.plans?.school.name,
              district_name : et.plans.district.name,
              selected_domains : et.selected_domains,
              desired_outcome : et.desired_outcome,
            };
          }
        );
        return result;
      } else return [];
    }, [goalActivityDataAll]);

    if (!goalActivityDataAll?.list?.length) {
      return (
        <NonIdealState
          icon="zoom-out"
          title={intl.formatMessage({
            id: "app.non-ideal-state.goal-edit-history",
          })}
        />
      );
    }
    return (
      <GoalActivityTable
        goalActivitydata={goalActivityData}
        customSetPageSize={customSetPageSize}
        customPageCount={customPageCount}
        customPageSize={customPageSize}
        customPageIndex={customPageIndex}
        customGoToPage={customGoToPage}
      />
    );
  };
export default GoalActivityAll;