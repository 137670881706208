import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Callout,
  Card,
  Classes,
  FormGroup,
  Icon,
  Spinner,
  TextArea,
  Tooltip,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getDomains } from "../../../store/domains/actions";
import { ValueType } from "react-select/src/types";
import { CommonText, Domain, SurveyDemographic } from "../../../types";
import { getSurveyQuestionsByDomain } from "../../../store/surveys/actions";
import _ from "lodash";
import Sticky from "react-sticky-el";
import { useLoading } from "../../../helpers/hooks/useLoading";
import {
  addCommonText,
  updateCommonText,
} from "../../../store/common-text/actions";

type OwnProps = {
  onClose: Function;
  selectedCommonText?: CommonText;
};

type Props = OwnProps;

const CommonTextUpsertDialogContent: FunctionComponent<Props> = (props) => {
  const { onClose, selectedCommonText } = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(getDomains.request());
  }, []);
  const loadingGetDomains = useSelector((s) => s.domains.loading.getDomains);
  const domains = useSelector((s) => s.domains.domains);

  const [commonText, setCommonText] = useState<string>("");
  const handleCommonTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setCommonText(value);
  };

  useEffect(() => {
    if (selectedCommonText) {
      const domainId = (_.chain(selectedCommonText.questions)
        .toPairs()
        .find(([key, question]) => !!question?.domain)
        .value()?.[1] as any)?.domain;
      const items = _.chain(selectedCommonText.questions)
        .toPairs()
        .map(([key, question]) => [key, question?.id])
        .fromPairs()
        .value();
      setCommonText(selectedCommonText.common_text);
      setSelectedDomainId(domainId);
      setSelectedItems(items);
    } else {
      setCommonText("");
      setSelectedDomainId(undefined);
      setSelectedItems({});
    }
  }, [selectedCommonText]);

  const [selectedDomainId, setSelectedDomainId] = useState<
    number | undefined
  >();
  const handleSelectedDomainIdChange = (value: ValueType<Domain, false>) => {
    setSelectedDomainId(value?.id);
    setSelectedItems({});
  };
  const selectedDomain = useMemo(
    () =>
      selectedDomainId
        ? domains.find((d) => d.id === selectedDomainId)
        : undefined,
    [selectedDomainId, domains]
  );

  const surveyDemographicLst = useMemo(() => {
    return Object.values(SurveyDemographic).map((item) => ({
      key: item,
      value: intl.formatMessage({
        id: `app.surveys.survey-demographic.${item}`,
      }),
    }));
  }, []);

  const questions = useSelector((s) => s.surveys.surveyQuestions);
  const groups = useMemo(() => {
    return _.chain(questions)
      .groupBy((q) => q.demographic)
      .value();
  }, [questions]);

  useEffect(() => {
    if (selectedDomainId) {
      dispatch(getSurveyQuestionsByDomain.request(selectedDomainId));
    }
  }, [selectedDomainId]);

  const loadingGetSurveyQuestionsByDomain = useSelector(
    (s) => s.surveys.loading.getSurveyQuestionsByDomain
  );
  const errorGetSurveyQuestionsByDomain = useSelector(
    (s) => s.surveys.errors.getSurveyQuestionsByDomain
  );
  useLoading({
    loading: loadingGetSurveyQuestionsByDomain,
    error: errorGetSurveyQuestionsByDomain,
  });

  const [selectedItems, setSelectedItems] = useState<
    {
      [key in SurveyDemographic]?: number;
    }
  >({});

  const selectedItemsKeys: number[] = useMemo(() => {
    return _.values(selectedItems).filter<number>((s): s is number => !!s);
  }, [selectedItems]);

  const handleClose = () => {
    onClose();
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isInvalid) {
      return;
    }

    if (selectedCommonText) {
      dispatch(
        updateCommonText.request({
          id: selectedCommonText.id,
          common_text: commonText,
          questions: selectedItems,
        })
      );
    } else {
      dispatch(
        addCommonText.request({
          common_text: commonText,
          questions: selectedItems,
        })
      );
    }
  };

  const loading = useSelector((s) =>
    selectedCommonText
      ? s.commonTexts.loading.updateCommonText
      : s.commonTexts.loading.addCommonText
  );
  const error = useSelector((s) =>
    selectedCommonText
      ? s.commonTexts.errors.updateCommonText
      : s.commonTexts.errors.addCommonText
  );
  useLoading({ loading, error, onSuccess: handleClose });

  const hasDemographicValue = useMemo(() => {
    return _.chain(selectedItems)
      .entries()
      .some(([key, value]) => !!value)
      .value();
  }, [selectedItems]);

  const isInvalid = useMemo(() => {
    return !selectedDomainId || !hasDemographicValue;
  }, [selectedDomainId, hasDemographicValue]);

  return (
    <>
      <form
        id="upsert-common-text-form"
        className={Classes.DIALOG_BODY}
        onSubmit={handleFormSubmit}
      >
        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.common-text",
          })}
        >
          <TextArea
            fill
            required={true}
            value={commonText}
            onChange={handleCommonTextChange}
          />
        </FormGroup>

        <FormGroup
          label={intl.formatMessage({
            id: "app.titles.domain",
          })}
        >
          <Select
            isClearable
            isLoading={loadingGetDomains}
            value={selectedDomain}
            options={domains}
            onChange={handleSelectedDomainIdChange}
            getOptionLabel={(d) => d.name}
            getOptionValue={(d) => d.id.toString()}
            styles={{
              menu: (styles) => ({ ...styles, zIndex: 10 }),
            }}
          />
        </FormGroup>

        {selectedDomainId ? (
          loadingGetSurveyQuestionsByDomain ? (
            <Spinner intent="primary" />
          ) : questions.length ? (
            <div className="flex -m-1">
              <div className="flex-1 m-1">
                <p className="mb-2">
                  {intl.formatMessage({
                    id: "app.common-text.dialogs.selected-items",
                  })}
                </p>
                <Card className="p-2">
                  <div className="-my-1">
                    {surveyDemographicLst.map((item) => {
                      const question =
                        selectedItems[item.key] &&
                        groups[item.key]?.find(
                          (q) => q.id === selectedItems[item.key]
                        );

                      return (
                        <Callout
                          key={item.key}
                          title={item.value}
                          className="my-1 border border-1 border-gray-400"
                          onClick={() => {
                            const block = question
                              ? document.getElementById(
                                  `${item.key}_${question.id}`
                                )
                              : document.getElementById(`area_${item.key}`);

                            block?.scrollIntoView({
                              // block: "center",
                              behavior: "smooth",
                            });
                          }}
                        >
                          {question ? (
                            <>
                              <Icon
                                intent="danger"
                                icon="cross"
                                className="cursor-pointer absolute"
                                style={{ top: "0.5rem", right: "0.5rem" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedItems((items) => ({
                                    ...items,
                                    [item.key]: undefined,
                                  }));
                                }}
                              />
                              {`${question.order + 1}. ${
                                question.lead_in ? `${question.lead_in} ` : ""
                              }${question.text}`}
                            </>
                          ) : (
                            intl.formatMessage({
                              id: "app.titles.not-applicable",
                            })
                          )}
                        </Callout>
                      );
                    })}
                  </div>
                </Card>
              </div>
              <div className="flex-1 m-1">
                <p className="mb-2">
                  {intl.formatMessage({
                    id: "app.common-text.dialogs.selected-items",
                  })}
                </p>
                <Card
                  id="scrollarea"
                  className="p-2 overflow-auto"
                  style={{ maxHeight: 500 }}
                >
                  {_.entries(groups).map(([key, questions], index) => (
                    <React.Fragment key={key}>
                      {!!index && <hr className="my-4" />}
                      <p id={`area_${key}`} />
                      <Sticky
                        topOffset={-25}
                        scrollElement={"#scrollarea"}
                        stickyClassName="bg-gray-300 shadow-md opacity-90 py-1"
                        stickyStyle={{ zIndex: 5 }}
                        className="text-center -mx-2"
                      >
                        {intl.formatMessage({
                          id: `app.surveys.survey-demographic.${key}`,
                        })}
                      </Sticky>
                      {questions.map((question) => (
                        <Callout
                          id={`${key}_${question.id}`}
                          icon={null}
                          intent={
                            selectedItemsKeys.includes(question.id as number)
                              ? "primary"
                              : undefined
                          }
                          key={question.id}
                          className="my-1 border border-1 border-gray-400 cursor-pointer"
                          onClick={() => {
                            setSelectedItems((items) => ({
                              ...items,
                              [question.demographic]: question.id,
                            }));
                          }}
                        >
                          {`${question.order + 1}. ${
                            question.lead_in ? `${question.lead_in} ` : ""
                          }${question.text}`}
                        </Callout>
                      ))}
                    </React.Fragment>
                  ))}
                </Card>
              </div>
            </div>
          ) : (
            <Callout intent="warning">
              {intl.formatMessage({
                id: "app.common-text.dialogs.domain-questions-list-is-empty",
              })}
            </Callout>
          )
        ) : (
          <Callout
            title={intl.formatMessage({
              id: "app.common-text.dialogs.domain-not-selected",
            })}
            intent="primary"
          >
            {intl.formatMessage({
              id: "app.common-text.dialogs.domain-select-instructions",
            })}
          </Callout>
        )}
      </form>
      <div className={`${Classes.DIALOG_FOOTER} mt-4`}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={handleClose}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>

          <Tooltip
            intent="warning"
            className={"w-1/4"}
            targetClassName="w-full"
            content={
              <p>
                {selectedDomainId
                  ? intl.formatMessage({
                      id: "app.common-text.dialogs.questions-not-specified",
                    })
                  : intl.formatMessage({
                      id: "app.common-text.dialogs.domain-not-selected",
                    })}
              </p>
            }
            disabled={!isInvalid}
          >
            <Button
              className="w-full"
              intent="primary"
              type="submit"
              loading={loading}
              form="upsert-common-text-form"
            >
              {intl.formatMessage({
                id: "app.titles.save",
              })}
            </Button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default CommonTextUpsertDialogContent;
