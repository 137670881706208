import React, { FunctionComponent } from "react";
import { Button, Card } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import TeamMemberRolesTable from "./TeamMemberRolesTable";

type OwnProps = {
  onClick: Function;
};

type Props = OwnProps;

const NoMembers: FunctionComponent<Props> = (props) => {
  const { onClick } = props;

  const intl = useIntl();

  const handleInviteUsersDialogShow = () => {
    onClick();
  };

  return (
    <Card className="text-center" elevation={3}>
      <div className="text-2xl mb-4">
        <div className="font-bold mb-4">
          {intl.formatMessage({ id: "app.team-plan.team.no-other-members" })}
        </div>
        <div>
          {intl.formatMessage({
            id: "app.team-plan.team.no-other-members.info",
          })}
        </div>
      </div>
      <div className="text-xl font-bold">
        {intl.formatMessage({ id: "app.team-plan.team.team-member-roles" })}
      </div>

      <div className="my-4">
        <TeamMemberRolesTable />
      </div>

      <Button
        intent="primary"
        text={intl.formatMessage({
          id: "app.team-plan.setup.team.invite-first-member",
        })}
        title={intl.formatMessage({
          id: "app.team-plan.setup.team.invite-first-member",
        })}
        onClick={handleInviteUsersDialogShow}
      />
    </Card>
  );
};

export default NoMembers;
