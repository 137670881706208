import React, { FunctionComponent } from "react";
import { Button, Icon } from "@blueprintjs/core";

type OwnProps = {
  text: string;
  onClick: () => void;
};

type Props = OwnProps;

const ReturnButton: FunctionComponent<Props> = (props) => {
  const { text, onClick } = props;
  return (
    <Button
      text={<span className="text-2xl font-bold">{text}</span>}
      title={text}
      large
      icon={<Icon color="#000" icon="circle-arrow-left" iconSize={30} />}
      onClick={onClick}
    />
  );
};

export default ReturnButton;
