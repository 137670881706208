import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import Select from "react-select";
import {
  Grade,
  PlanStrategy,
  PlanStrategyStatus,
  PlanStrategyTargetGroups,
  SelectorOptionType,
  Strategy,
  UserModel,
} from "../../../../types";
import { ValueType } from "react-select/src/types";
import { isPlanStrategy } from "../../utils";
import moment from "moment";
import CustomDateInput from "../../../common/CustomDateInput";
import { useSelector } from "react-redux";
import { getFullName } from "../../../../helpers/message-format-utils";
import useGetTeamMembersLoading from "../../../../helpers/hooks/loading/useGetTeamMembersLoading";

type OwnProps = {
  formRef?: React.RefObject<HTMLFormElement>;
  formId?: string;
  strategy?: Strategy | PlanStrategy;
  onSubmit: (
    params: Pick<
      PlanStrategy,
      | "status"
      | "target_groups"
      | "grades"
      | "launch_date"
      | "locations"
      | "timeframe"
      | "general_approach"
    > & {
      leaders: number[];
    }
  ) => void;
};

type Props = OwnProps;

const StrategyParameters: FunctionComponent<Props> = (props) => {
  const { formRef, strategy, formId, onSubmit } = props;

  const intl = useIntl();

  const [status, setStatus] =
    useState<SelectorOptionType<PlanStrategyStatus> | undefined>();
  const handleStatusChange = (value: ValueType<any, false>) => {
    setStatus(value);
  };
  const statusesOptions: SelectorOptionType<PlanStrategyStatus>[] =
    useMemo(() => {
      return Object.values(PlanStrategyStatus).map((v) => ({
        value: v,
        label: intl.formatMessage(
          { id: "app.strategies.strategy-parameters.status" },
          { status: v }
        ),
      }));
    }, []);

  const [targetGroups, setTargetGroups] =
    useState<SelectorOptionType<PlanStrategyTargetGroups> | undefined>();
  const handleTargetGroupsChange = (
    value: ValueType<SelectorOptionType<PlanStrategyTargetGroups>, false>
  ) => {
    setTargetGroups(value as any);
  };
  const targetGroupsOptions = useMemo(() => {
    return Object.values(PlanStrategyTargetGroups).map((v) => ({
      value: v,
      label: intl.formatMessage(
        { id: "app.strategies.strategy-parameters.target-groups" },
        { target: v }
      ),
    }));
  }, []);

  const [grades, setGrades] = useState<SelectorOptionType<Grade>[]>([]);
  const handleGradesChange = (
    value: ValueType<SelectorOptionType<Grade>, true>
  ) => {
    setGrades((value as any) ?? []);
  };
  const gradesOptions = useMemo(() => {
    return Object.values(Grade).map((v) => ({
      value: v,
      label: intl.formatMessage({ id: "app.grades.grade" }, { grade: v }),
    }));
  }, []);

  const teamMembers = useSelector((s) => s.teamMembers.teamMembers);
  const teamMembersOptions = useMemo(() => {
    return teamMembers.map((tM) => tM.user);
  }, [teamMembers]);

  const [leaders, setLeaders] = useState<UserModel[]>([]);
  const handleLeadersChange = (value: ValueType<UserModel, true>) => {
    setLeaders((value as UserModel[]) ?? []);
  };
  const { loading: getTeamMembersLoading } = useGetTeamMembersLoading();

  const [launchDate, setLaunchDate] = useState<Date | undefined>();
  const handleLaunchDateChange = (
    selectedDate: Date,
    isUserChange: boolean
  ) => {
    setLaunchDate(
      moment(selectedDate).isBefore(moment()) ? moment().toDate() : selectedDate
    );
  };

  const [locations, setLocations] = useState<string | undefined>();
  const handleLocationsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocations(value);
  };

  const [timeframe, setTimeframe] = useState<string | undefined>();
  const handleTimeframeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTimeframe(value);
  };

  const [generalApproach, setGeneralApproach] = useState<string | undefined>();
  const handleGeneralApproachChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setGeneralApproach(value);
  };

  useEffect(() => {
    if (strategy && isPlanStrategy(strategy)) {
      setStatus(
        strategy.status &&
          statusesOptions.find((s) => s.value === strategy.status)
      );
      setTargetGroups(
        strategy.target_groups &&
          targetGroupsOptions.find((s) => s.value === strategy.target_groups)
      );
      setGrades(
        strategy.grades
          ? gradesOptions.filter((go) => strategy.grades!.includes(go.value))
          : []
      );
      setLeaders(strategy.leaders ?? []);
      setLaunchDate(
        strategy.launch_date ? new Date(strategy.launch_date) : undefined
      );
      setLocations(strategy.locations);
      setTimeframe(strategy.timeframe);
      setGeneralApproach(strategy.general_approach);
    } else {
      setStatus(undefined);
      setTargetGroups(undefined);
      setGrades([]);
      setLeaders([]);
      setLaunchDate(undefined);
      setLocations(undefined);
      setTimeframe(undefined);
      setGeneralApproach(undefined);
    }
  }, [
    strategy,
    statusesOptions,
    targetGroupsOptions,
    gradesOptions,
    teamMembers,
  ]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      status: status?.value,
      target_groups: targetGroups?.value,
      grades: grades.map((g) => g.value),
      launch_date: launchDate?.toISOString(),
      locations: locations,
      timeframe: timeframe,
      general_approach: generalApproach,
      leaders: leaders.map((u) => u.id),
    });
  };

  return (
    <form ref={formRef} id={formId} onSubmit={handleFormSubmit}>
      <FormGroup label={intl.formatMessage({ id: "app.titles.status" })}>
        <Select
          value={status || null}
          onChange={handleStatusChange}
          options={statusesOptions}
        />
      </FormGroup>
      <FormGroup label={intl.formatMessage({ id: "app.titles.target-groups" })}>
        <Select
          value={targetGroups || null}
          onChange={handleTargetGroupsChange}
          options={targetGroupsOptions}
        />
      </FormGroup>
      <FormGroup label={intl.formatMessage({ id: "app.titles.grades" })}>
        <Select
          isMulti
          value={grades || null}
          menuPortalTarget={document.body}
          onChange={handleGradesChange}
          options={gradesOptions}
          styles={{
            menuPortal: (styles) => {
              return {
                ...styles,
                zIndex: 100,
              };
            },
          }}
        />
      </FormGroup>

      <FormGroup
        label={intl.formatMessage({ id: "app.titles.strategy-leaders" })}
      >
        <Select
          isMulti
          isLoading={getTeamMembersLoading}
          value={leaders || null}
          menuPortalTarget={document.body}
          onChange={handleLeadersChange}
          options={teamMembersOptions}
          getOptionValue={(user) => user.id.toString()}
          getOptionLabel={(user) => getFullName(user)}
          styles={{
            menuPortal: (styles) => {
              return {
                ...styles,
                zIndex: 100,
              };
            },
          }}
        />
      </FormGroup>

      <FormGroup label={intl.formatMessage({ id: "app.titles.launch_date" })}>
        <CustomDateInput value={launchDate} onChange={handleLaunchDateChange} />
      </FormGroup>
      <FormGroup label={intl.formatMessage({ id: "app.titles.locations" })}>
        <InputGroup
          placeholder={
            intl.formatMessage({ id: "app.titles.locations" }) + "..."
          }
          value={locations || ""}
          onChange={handleLocationsChange}
        />
      </FormGroup>
      <FormGroup label={intl.formatMessage({ id: "app.titles.timeframe" })}>
        <InputGroup
          placeholder={
            intl.formatMessage({ id: "app.titles.timeframe" }) + "..."
          }
          value={timeframe || ""}
          onChange={handleTimeframeChange}
        />
      </FormGroup>
      <FormGroup
        label={intl.formatMessage({ id: "app.titles.general_approach" })}
      >
        <InputGroup
          placeholder={
            intl.formatMessage({ id: "app.titles.general_approach" }) + "..."
          }
          value={generalApproach || ""}
          onChange={handleGeneralApproachChange}
        />
      </FormGroup>
    </form>
  );
};

export default StrategyParameters;
