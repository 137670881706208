import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf, RootAction, RootState } from "typesafe-actions";
import api from "../../api";
import actions from "../actions";

export const getSurveysDeploymentEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveysDeployment.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.getSurveysDeployment(
          action.payload?.planId,
          action.payload?.isDeleted
        )
      ).pipe(
        map(actions.getSurveysDeployment.success),
        catchError((error) => of(actions.getSurveysDeployment.failure(error)))
      )
    )
  );
};

export const getSurveyPagesGroupedByDemographicEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyPagesGroupedByDemographic.request)),
    switchMap((action) =>
      from(api.surveys.getSurveyPagesGroupedByDemographic(action.payload)).pipe(
        map(actions.getSurveyPagesGroupedByDemographic.success),
        catchError((error) =>
          of(actions.getSurveyPagesGroupedByDemographic.failure(error))
        )
      )
    )
  );
};

export const getSurveyDeploymentsBySchoolEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveyDeploymentsBySchool.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.getSurveyDeploymentsBySchool(action.payload)
      ).pipe(
        map(actions.getSurveyDeploymentsBySchool.success),
        catchError((error) =>
          of(actions.getSurveyDeploymentsBySchool.failure(error))
        )
      )
    )
  );
};

export const getSurveysDeploymentStatisticsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSurveysDeploymentStatistics.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.getSurveysDeploymentStatistics(action.payload)
      ).pipe(
        map(actions.getSurveysDeploymentStatistics.success),
        catchError((error) =>
          of(actions.getSurveysDeploymentStatistics.failure(error))
        )
      )
    )
  );
};

export const addSurveyDeploymentEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.addSurveyDeployment.request)),
    switchMap((action) =>
      from(api.surveysDeployment.addSurveyDeployment(action.payload)).pipe(
        map(actions.addSurveyDeployment.success),
        catchError((error) => of(actions.addSurveyDeployment.failure(error)))
      )
    )
  );
};

export const updateSurveyDeploymentEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.updateSurveyDeployment.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.updateSurveyDeployment(
          action.payload.surveyDeploymentId,
          action.payload.updatedSurveyDeployment
        )
      ).pipe(
        map(actions.updateSurveyDeployment.success),
        catchError((error) => of(actions.updateSurveyDeployment.failure(error)))
      )
    )
  );
};

export const deleteSurveyDeploymentEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteSurveyDeployment.request)),
    switchMap((action) =>
      from(api.surveysDeployment.deleteSurveyDeployment(action.payload)).pipe(
        map(() => actions.deleteSurveyDeployment.success(action.payload)),
        catchError((error) => of(actions.deleteSurveyDeployment.failure(error)))
      )
    )
  );
};

export const getSummaryStatisticsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.getSummaryStatistics.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.getSummaryStatistics(
          action.payload.school,
          action.payload.surveyDeploymentId
        )
      ).pipe(
        map(actions.getSummaryStatistics.success),
        catchError((error) => of(actions.getSummaryStatistics.failure(error)))
      )
    )
  );
};

export const downloadSampleLettersEpic: Epic<
  RootAction,
  RootAction,
  RootAction
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.downloadSampleLetters.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.downloadSampleLetters(
          action.payload.surveyDeploymentId,
          action.payload.planId,
          action.payload.body
        )
      ).pipe(
        map((file) => {
          const blob = new Blob([file], { type: file.type });

          const pdfUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("download", "NJSCI_SampleLetters");
          link.href = pdfUrl;
          document.body.append(link);
          link.click();
          link.remove();
          return actions.downloadSampleLetters.success(file);
        }),
        catchError((error) => of(actions.downloadSampleLetters.failure(error)))
      )
    )
  );
};

export const closeSurveyDeploymentEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.closeSurveyDeployment.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.closeSurveyDeployment(
          action.payload.surveyDeploymentId,
          action.payload.planId
        )
      ).pipe(
        map(actions.closeSurveyDeployment.success),
        catchError((error) => of(actions.closeSurveyDeployment.failure(error)))
      )
    )
  );
};

export const deleteSurveyDeploymentSoftlyEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) => {
  return action$.pipe(
    filter(isActionOf(actions.deleteSurveyDeploymentSoftly.request)),
    switchMap((action) =>
      from(
        api.surveysDeployment.deleteSurveyDeploymentSoftly(action.payload)
      ).pipe(
        map(() => actions.deleteSurveyDeploymentSoftly.success(action.payload)),
        catchError((error) =>
          of(actions.deleteSurveyDeploymentSoftly.failure(error))
        )
      )
    )
  );
};
