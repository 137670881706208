import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { isPlanStrategy } from "../../../components/team-plan/utils";
import { deletePlanStrategy } from "../../../store/plan-strategies/actions";
import { deleteStrategy } from "../../../store/strategies/actions";
import {
  hideConfirmDialog,
  showConfirmDialog
} from "../../../store/UIState/actions";
import { PlanStrategy, Strategy } from "../../../types";
import { AppToaster } from "../../toaster";

export const useStrategyConfrimDelete = ({
  item,
  onDeleteSuccess,
}: {
  item: Strategy | PlanStrategy;
  onDeleteSuccess?: () => void;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activePlanId = useSelector((s) => s.plans.activePlan?.id);

  const isPlanStrategyType = useMemo(() => {
    return isPlanStrategy(item);
  }, [item]);

  const confirmDelete = () => {
    if (!!activePlanId && item) {
      dispatch(
        showConfirmDialog({
          onConfirm: () => {
            if (isPlanStrategyType) {
              dispatch(
                deletePlanStrategy.request({
                  planStrategyId: item.id,
                  planId: activePlanId,
                  onSuccess: () => {
                    AppToaster.show({
                      icon: "tick",
                      intent: "success",
                      message: intl.formatMessage(
                        { id: "app.plan-strategies.deleted-successfully" },
                        { name: (item as PlanStrategy)?.strategy?.name }
                      ),
                    });
                    onDeleteSuccess && onDeleteSuccess();
                  },
                })
              );
            } else {
              dispatch(
                deleteStrategy.request({
                  strategyId: item.id,
                  onSuccess: () => {
                    AppToaster.show({
                      icon: "tick",
                      intent: "success",
                      message: intl.formatMessage(
                        { id: "app.strategies.deleted-successfully" },
                        { name: (item as Strategy)?.name }
                      ),
                    });
                    onDeleteSuccess && onDeleteSuccess();
                  },
                })
              );
            }
            dispatch(hideConfirmDialog());
          },
          show: true,
          intent: "danger",
          text: intl.formatMessage(
            {
              id: "app.confirmation-dialogs.delete-strategy",
            },
            {
              name: isPlanStrategyType
                ? (item as PlanStrategy).strategy?.name
                : (item as Strategy).name,
            }
          ),
          icon: "trash",
          confirmButtonText: intl.formatMessage({ id: "app.confirmation-dialogs.delete-strategy-yes" }),
          cancelButtonText: intl.formatMessage({
            id: "app.titles.cancel",
          }),
          onCancel: () => {
            dispatch(hideConfirmDialog());
          },
        })
      );
    }
  };

  return {
    confirmDelete,
  };
};
