import React, { useEffect, useMemo } from "react";
import { TooltipRenderProps } from "react-joyride";
import { Button } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import _ from "lodash";

type OwnProps = {};

type Props = OwnProps & TooltipRenderProps;

const SuperintendentTooltip: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
  } = props;

  const nextStepTitle = useMemo(() => {
    if (
      step.locale?.next &&
      _.isPlainObject(step.locale.next) &&
      (step.locale.next as any).title
    ) {
      return (step.locale.next as any).title;
    }
    return intl.formatMessage({
      id: isLastStep
        ? index
          ? "app.titles.end-tour"
          : "app.titles.got-it"
        : "app.titles.next",
    });
  }, [index, isLastStep, step.locale?.next]);
 
  useEffect(() => {
    let scrollTimeout:any;
    // Clear the previous timeout (if any) before setting a new one
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    // Set a new timeout to scroll to the top
    scrollTimeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 500);

    // Clean up the timeout on component unmount
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [step]);

  return (
    <div
      {...tooltipProps}
      style={{ minWidth: "15rem", maxWidth: "20rem" }}
      className="bg-white p-4 rounded"
    >
      {step.title && <p className="text-base font-semibold">{step.title}</p>}
      {step.content && <div className="pt-2">{step.content}</div>}
      <div className="pt-4 flex justify-between">
        <div>
          {!isLastStep && step.showSkipButton && (
            <Button
              {...closeProps}
              title={intl.formatMessage({
                id: "app.survey-report.guide.tooltip.button.skip-guide",
              })}
              text={intl.formatMessage({
                id: "app.survey-report.guide.tooltip.button.skip-guide",
              })}
            />
          )}
        </div>
        <div className="flex justify-end">
          {/* {(step.target === '[data-tour="team-member-table-isDCC-checkbox"]' 
          || step.target==='[data-tour="manage-users-tour"]' 
          || step.target ==='[data-tour="add-school-button"]'
          || step.target === '[data-tour="team-development-presentation"]'
          || step.target ==='[data-tour="setup-calenda-step-2-button"]' )&& (
            step.target ==='[data-tour="setup-calenda-step-2-button"]' ?<Button
              {...closeProps}
              text={intl.formatMessage({id:"app.survey-report.guide.tooltip.button.do-now"})}
              title={intl.formatMessage({id:"app.survey-report.guide.tooltip.button.do-now"})}
              intent="none"
              
              className="mr-5"
            />
            :
            <Button
              {...backProps}
               text={intl.formatMessage({id:"app.survey-report.guide.tooltip.button.do-now"})}
              title={intl.formatMessage({id:"app.survey-report.guide.tooltip.button.do-now"})}
              intent="none"
              
              className="mr-5"
            />
          )} */}
          {continuous && (
            isLastStep ?
            <Button 
            {...closeProps}
            intent="primary"
            title={intl.formatMessage({id:"app.titles.end-tour"})}
            text={intl.formatMessage({id:"app.titles.end-tour"})}
            />
            :<Button
              {...primaryProps}
              text={nextStepTitle}
              title={nextStepTitle}
              intent="primary"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperintendentTooltip;
