import React, { FunctionComponent, useEffect } from "react";
import { Button, Icon, Spinner } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionTagById } from "../../../store/surveys/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";

type OwnProps = {
  tagId: string;
  buttonText: string;
  onClick: any;
};

type Props = OwnProps;

const TagTabHeader: FunctionComponent<Props> = (props) => {
  const { buttonText, onClick, tagId } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuestionTagById.request(tagId));
  }, [tagId]);

  const selectedQuestionTag = useSelector((s) => s.surveys.selectedQuestionTag);
  const loading = useSelector((s) => s.surveys.loading.getQuestionTagById);
  const error = useSelector((s) => s.surveys.errors.getQuestionTagById);
  useLoading({
    loading,
    error,
    onError: () => {
      onClick();
    },
  });

  return (
    <div className="mt-4">
      <Button
        text={<span className="text-2xl font-bold">{buttonText}</span>}
        title={buttonText}
        large
        icon={<Icon color="#000" icon="circle-arrow-left" iconSize={30} />}
        onClick={onClick}
      />
      <div className="flex items-center mt-4 mb-1">
        {loading ? (
          <Spinner />
        ) : (
          <strong className="text-3xl">{selectedQuestionTag?.tag}</strong>
        )}
      </div>
    </div>
  );
};

export default TagTabHeader;
