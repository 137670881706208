import _ from "lodash";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useIntl } from "react-intl";

type OwnProps = {
  selectedMultiRespondentGroup?: any;
  selectedDomain?: any;
  selectedSurveyItem?: any;
  AdminLabelPoints?: any;
  IndicatorGraphDomainsData?: any;
  IndicatorGraphItemsData?: any;
  selectedGoal?: any;
  selectedDateRange?: any;
};
type Props = OwnProps;

const SurveyIndicatorGraph: React.FC<Props> = (props) => {
  const intl = useIntl();

  const {
    selectedMultiRespondentGroup,
    selectedDomain,
    selectedSurveyItem,
    AdminLabelPoints,
    IndicatorGraphDomainsData,
    IndicatorGraphItemsData,
    selectedGoal,
    selectedDateRange,
  } = props;

  const TableData = selectedMultiRespondentGroup.map((item: any) => {
    const getMean = (item: any) => {
      const Data: any = {};

      if (selectedDomain) {
        selectedDateRange.map((sdr: any) =>
          IndicatorGraphDomainsData.map((rg: any) => {
            return rg?.domain?.name === selectedDomain &&
              rg?.respondents[`${item}`] &&
              sdr ===
                moment(rg?.survey_deployment[0]?.end_date).format("MM/DD/YY")
              ? (Data[
                  rg?.survey_deployment[0]?.deployment_name
                ] = Number(rg?.respondents[item].mean_score).toFixed(2))==="0.00"? intl.formatMessage({id:"app.non-ideal-state.survey-reports.not-enough-respondents"}):Number(rg?.respondents[item].mean_score).toFixed(2)
              : "";
          })
        );
      }

      if (selectedSurveyItem) {
        selectedDateRange.map((sdr: any) =>
          IndicatorGraphItemsData.map((rg: any) =>
            rg?.responses?.forEach((response: any) => {
              if (
                response.respondents[`${item}`] &&
                response.respondents[`${item}`]?.question_text?.includes(
                  selectedSurveyItem
                ) &&
                sdr ===
                  moment(response?.survey_deployment[0]?.end_date).format(
                    "MM/DD/YY"
                  )
              ) {
                Data[
                  response?.survey_deployment[0]?.deployment_name
                ] = Number(response?.respondents?.[`${item}`]?.mean_score)?.toFixed(2)==="0.00"? intl.formatMessage({id:"app.non-ideal-state.survey-reports.not-enough-respondents"}):Number(response?.respondents?.[`${item}`]?.mean_score)?.toFixed(2)
              }
            })
          )
        );
      }

      return Data;
    };
    return {
      respondentGroup: intl.formatMessage({
        id: `app.filters.respondent-type.${item}`,
      }),
      MeanScore: getMean(item),
      TargetScore: selectedDomain
        ? selectedGoal.targets.map((target: any) =>
            target?.domain_priority?.demographic === item &&
            target?.domain_priority?.domain?.name === selectedDomain
              ?Number(target.target_value)?.toFixed(2)
              : ""
          )
        : selectedSurveyItem
        ? selectedGoal.targets.map((target: any) =>
            target?.item_priority?.demographic === item &&
            target?.item_priority?.item?.text.includes(selectedSurveyItem)
              ? Number(target.target_value)?.toFixed(2)
              : ""
          )
        : "",
    };
  });

  const DomainMeanColumns = Object.keys(AdminLabelPoints);

  return (
    <div className="monitoring_surveyTbl ">
      <b className="tblDomain_Name">
        {selectedDomain && selectedDomain}
        {selectedSurveyItem && selectedSurveyItem}
      </b>
<div className="blueHeader_tbl mb-5">

      <DataTable
        value={TableData.filter((TableData:any)=>Object.keys(TableData.MeanScore).length>0)}
        tableStyle={{ minWidth: "50rem" }}
        className=""
      >
        <Column
          field="respondentGroup"
          header={intl.formatMessage({ id: "app.titles.respondent.group" })}
          style={{ width: "20%" }}
        ></Column>

        {DomainMeanColumns.map((dmd: any) => (
          <Column
            field={`MeanScore.${AdminLabelPoints[dmd]}`}
            header={
              (selectedDomain?
              intl.formatMessage({ id: "app.titles.domain-mean" }) 
              :
               intl.formatMessage({id:"app.survey-report.dialogs.item"})+" "+
               intl.formatMessage({id:"app.survey-report.dialogs.mean"}) ) +
              " " +
              AdminLabelPoints[dmd]?.slice(0, 10) +
              AdminLabelPoints[dmd]?.slice(10, 16) +
              " " +
              AdminLabelPoints[dmd]?.slice(26)
            }
            style={{ width: 65 }}
          ></Column>
        ))}

        <Column
          field="TargetScore"
          header={intl.formatMessage({ id: "app.titles.target-score" })}
          style={{ width: "15%" }}
        ></Column>
      </DataTable>
    </div>
    </div>
  );
};

export default SurveyIndicatorGraph;
