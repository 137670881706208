import React, {
  FunctionComponent,
  CSSProperties,
  useState,
  useEffect,
  useMemo,
  memo,
} from "react";

import {
  Button,
  Card,
  Classes,
  Divider,
  Elevation,
  H5,
  Icon,
  Label,
  Popover,
  PopoverInteractionKind,
  Slider,
  Spinner,
  Switch,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import {
  Domain,
  DomainPriority,
  Goal,
  NewGoalTabs,
  PlanStrategy,
  Priority,
  priorityAreas,
  PriorityAreaSectionDetails,
  SelectorOptionTypeAdmin,
} from "../../../../types";
import moment from "moment";
import PriorityAreas from "../tabs/choose-needs-tab/PriorityAreas";
import PriorityAreasCard from "../tabs/choose-needs-tab/PriorityAreasCard";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGoal,
  selectedPriorityAreas,
  updateNewGoalState,
} from "../../../../store/goals/actions";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import DomainComparisonCollapseTitle from "../../needs-and-goals/dig-deeper-tab/DomainComparisonCollapseTitle";
import { EDIT_GOAL_MODE } from "../../../../constants/constants";
import { WARNING_SIGN } from "@blueprintjs/icons/lib/esm/generated/iconContents";
import _ from "lodash";

type OwnProps = {
  key: number;
  goal: Goal;
  interactive?: boolean;
  index?: number;
  elevation?: Elevation;
  className?: string;
  style?: CSSProperties;
  dashboardWidget?: boolean;
};

type Props = OwnProps;

const GoalItemCard: FunctionComponent<Props> = (props) => {
  const { goal } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const activePlan = useSelector((s) => s.plans.activePlan);

  // Delete goal button functionality

  const handleRemoveDraftGoal = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(deleteGoal.request({ id: goal.id, planId: activePlan?.id }));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "danger",
        text: intl.formatMessage(
          {
            id: "app.confirmation-dialogs.delete-draft-goal",
          },
          {
            b: (chunk) => <b>{chunk}</b>,
          }
          // { type: notesType }
        ),
        icon: "trash",
        confirmButtonText: intl.formatMessage({ id: "app.titles.delete-goal" }),
        cancelButtonText: intl.formatMessage({ id: "app.titles.cancel" }),
      })
    );
  };

  // archived goal button functionality
  const handleArchiveGoal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          // dispatch(deleteGoal.request({ id: goal.id, planId: activePlan?.id }));
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "primary",
        text: intl.formatMessage(
          {
            id: "app.confirmation-dialogs.archive-draft-goal",
          },
          {
            b: (chunk) => <b>{chunk}</b>,
          }
        ),
        icon: "archive",
        confirmButtonText: intl.formatMessage({ id: "app.titles.archive-goal" }),
        cancelButtonText: intl.formatMessage({ id: "app.titles.cancel" }),
      })
    );
  };

  // Edit Goals
  const handleEditGoal = () => {
    dispatch(updateNewGoalState({ show: true, selectedGoal: goal, isEditMode: true, goal: { ...goal, user: goal?.user?.id, mode: EDIT_GOAL_MODE,
      activeTab:NewGoalTabs.GoalStatement,
    }}));


  }
  const warningSign = () => {
    if (!goal?.is_completed) {
      const canNavigateToStep2 = !(goal?.selected_domains?.length === 0);
      const canNavigateToStep3 = goal.targets.filter(
        (trt: any) => !trt.target_value || !trt.target_date
      ).length
        ? false
        : true;
      const isStep3NotValid = !goal?.statement || !goal?.desired_outcome;

      if (!canNavigateToStep2 || !canNavigateToStep3 || isStep3NotValid) {
        return (
          <div className="float-right mt-2">
            <Popover
              className=""
              popoverClassName="w-56"
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <div className="p-3 text-center">
                  {intl.formatMessage({
                    id: "app.goals.warning.messages.incomplete-goal-details",
                  })}
                </div>
              }
            >
              <Icon icon={"warning-sign"} iconSize={30} intent={"warning"} />
            </Popover>
          </div>
        );
      }
    }
  };
  return (
    <>
      <Card interactive={true} elevation={Elevation.TWO} className="goal-card">
        {/* <pre>{JSON.stringify(_.pick(goal,["is_completed","is_active","is_confirmed"]),null,2)}</pre> */}
        <div className="goal-card-header-text">
          <span>
            <b>
              {intl.formatMessage({ id: "app.goals.goal.card.title" })}:
              {goal.statement ? (
                " "+goal.statement
              ) : (
                <i>
                  {" "+intl.formatMessage({ id: "app.titles.short-title-not-yet" })}
                </i>
              )}
            </b>
          </span>
{/* Hide the “Add Strategy” button in the card ( we will reveal 
  the button to users at a later date) sc_891 acceptence criteria */}
          {/* <div
            className={
              goal?.is_confirmed
                ? "goal-card-button-add-enable"
                : "goal-card-button-add-disabled"
            }
          >
            <Popover
              className=""
              popoverClassName="w-56"
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <div className="p-3 text-center">
                  {intl.formatMessage({
                    id: "app.goals.warning.messages.not-confirmed-goals",
                  })}
                </div>
              }
              disabled={goal?.is_confirmed}
            >
              <button
                onClick={() =>
                  goal?.is_confirmed ? alert("add strategy") : ""
                }
              >
                <span className="goal-card-add-strategies-button-icon">
                  <span className="bp3-icon ">
                    <svg
                      data-icon="add"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <desc>add</desc>
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        fillRule="evenodd"
                        d="M10.99,6.99h-2v-2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2c-0.55,0-1,0.45-1,1    s0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2h2c0.55,0,1-0.45,1-1S11.54,6.99,10.99,6.99z M7.99-0.01c-4.42,0-8,3.58-8,8    s3.58,8,8,8s8-3.58,8-8S12.41-0.01,7.99-0.01z M7.99,13.99c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S11.31,13.99,7.99,13.99z"
                      />
                    </svg>
                  </span>
                  <span className="ml-1">
                    {intl.formatMessage({ id: "app.titles.strategies" })}
                  </span>
                </span>
              </button>
            </Popover>
          </div> */}

          <div className="font-itlatic">
            <div className="bp3-text-muted text-sm">
              <div className="color-hex">
              <i>
                {intl.formatMessage(
                  { id: "app.common-text.goal.last-saved-on" },
                  {
                    dateTime:
                      goal && goal?.updated_at
                        ? moment(goal?.updated_at).format("MM/DD/YYYY h:mm a")
                        : "",
                    userName:
                      goal?.user?.first_name + " " + goal?.user?.last_name,
                      
                    ActiveStatus: goal?.user?.is_active ? "" : intl.formatMessage({ id: "app.titles.deactivated" })
                  }
                )}
              </i>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex"> */}
        <div className={"goal-card-domains"}>

          {goal?.selected_domains?.map(
            (item: any, index: any, arr: any) => (
              <p
                key={"___select_domain_" + index}
              >
                <DomainComparisonCollapseTitle
                  hideStatusText={true}
                  domain={item}
                  prioritized={false}
                  hasPriorities={false}
                  dismissed={false}
                  color={item.color_hex}
                />
              </p>
            )
          )}
        </div>
        <hr color="lightgrey" className="goal-card-horizontal-grey-divider" />

          {/* <Divider/> */}

        <div className="d-flex-20 ml-5 mt-2">

          {!goal?.plan_strategies ? (<div className="ml-3">
            <h3>
              <b>
                {intl.formatMessage({ id: "app.goals.card.aligned-strategies" })}
              </b>
            </h3>

            <div className={goal?.plan_strategies?.length >4?"goal-card-strategies":"goal-card-strategies-auto"}>
            
            {/* needs to be dynamic strategies in future when functionality develope */}
            {/* <li>
             strategy Name 1
            </li>
            <li>strategy Name 2</li>
            <li>strategy Name 3</li>
            <li>strategy Name 4</li>
            <li> strategy Name 5</li> */}
            </div>

          </div>)
            :
            <p className="text-center mt-4 mb-4"> 
            {intl.formatMessage({
              id: "app.team-plan.strategies.no-strategies",
            })}
            </p>
          }


        </div>
        {/* </div> */}

        <hr color="lightgrey" />



        <div className="goal-card-survey-text pt-5">
          {(goal?.survey_deployment?.survey as any).name}{" "}
          {goal?.survey_deployment?.deployment_name}
        </div>
        <div className="goal-card-bottom-buttons">
          <button
            type="button"
            title={intl.formatMessage({ id: "app.titles.delete" })}
            className="bp3-button bp3-intent-danger"
            onClick={handleRemoveDraftGoal}
          >
            <Icon icon={"trash"} iconSize={18} intent={"none"} />
          </button>

          {/*
          Hide the Archive button; this button will move to another page in a future story 
          so please retain the pop up in coding for when this button is reactivated in its new location SC_893
          <button
            type="button"
            className="bp3-button bp3-intent-primary whitespace-no-wrap"
            title={intl.formatMessage({ id: "app.titles.archive" })}
            onClick={handleArchiveGoal}
          > */}
            {/* Arch archive */}
            {/* <Icon icon={"archive"} iconSize={18} intent={"none"} />
          </button> */}

          <button
            type="button"
            title={intl.formatMessage({ id: "app.titles.graph" })}
            className="bp3-button bp3-intent-primary whitespace-no-wrap"
          >
            <Icon icon={"chart"} iconSize={18} intent={"none"} />
          </button>

          <button
            type="button"
            title={intl.formatMessage({ id: "app.titles.edit" })}
            className="bp3-button bp3-intent-primary whitespace-no-wrap"
            onClick={handleEditGoal}
          >
            <Icon icon={"edit"} iconSize={18} intent={"none"} />
          </button>

        </div>
        {warningSign()}
      </Card>
    </>
  );
};

export default memo(GoalItemCard);
