import React, { FunctionComponent } from "react";
import ItemMark from "../ItemMark";
import { Popover } from "@blueprintjs/core";
import GroupingLabelsPopoverContent from "./GroupingLabelsPopoverContent";
import { useIntl } from "react-intl";
import useDatasetMark from "../../../cards/useDatasetMark";
import { Dataset, SurveyDemographic, StackedBarLabels } from "../../../../../types";
import { showBookmarkDialog } from "../../../../../store/survey-reports/actions";
import { useDispatch } from "react-redux";

type OwnProps = {
  domainId: number;
  questionId?: number;
  labels: string | string[];
  demographic?: SurveyDemographic;
  params?: Pick<
    Dataset,
    | "direct_instruction"
    | "gender"
    | "grade"
    | "race"
    | "remote_learning"
    | "staff_role"
    | "reduced_lunch"
    | "has_iep"
    | "english_at_home"
  >;
  barHeight?: number;
  overallMean?: number;
  className?: string;
  doubleDisaggregation?: boolean
  labelsWithDetail?: StackedBarLabels[]
  notEnoughData?: boolean
};

type Props = OwnProps;

const GroupedItemFilterLabel: FunctionComponent<Props> = (props) => {
  const {
    labels,
    params = {},
    demographic,
    domainId,
    questionId,
    barHeight,
    overallMean,
    className,
    doubleDisaggregation,
    labelsWithDetail,
    notEnoughData
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    flagged,
    bookmarkType,
    onClick,
    onDiscard,
    supportsBookmarks,
  } = useDatasetMark(
    {
      ...params,
      demographic,
      domainId,
      questionId,
      is_grouped: true,
    },
    overallMean
  );
  
  const openBookmarkDialog = () =>{
    if(doubleDisaggregation && labelsWithDetail){
      const selectedIndex = labelsWithDetail.findIndex((x:StackedBarLabels)=>x.label==labels)
      dispatch(
        showBookmarkDialog({
          bookmarkDetail: {
            domainIndex: domainId,
            questionId,
            selectedLabelKey:
              labelsWithDetail[selectedIndex].original_label_key,
            selectedLabelValue:
              labelsWithDetail[selectedIndex]
                .original_label_value,
          },
        })
      );
    }
  }

  if (typeof labels === "string") {
    return (
      <tr style={{ height: barHeight }} className={className}>
        <td
          colSpan={supportsBookmarks ? 1 : 2}
          className="flex-1 text-xl text-center whitespace-no-wrap p-1 w-full"
          onClick={openBookmarkDialog}
          style={{ cursor: doubleDisaggregation? 'pointer': 'initial'}}
        >
          {labels}
        </td>
        {!doubleDisaggregation && supportsBookmarks && (
          <td className="p-1">
            <ItemMark
              flagged={flagged}
              bookmarkType={bookmarkType}
              onClick={onClick}
              notEnoughData = {notEnoughData}
              onDiscard={onDiscard}
            />
          </td>
        )}
      </tr>
    );
  }
  return (
    <tr style={{ height: barHeight }} className={className}>
      <td colSpan={2}>
        <Popover
          content={<GroupingLabelsPopoverContent labels={labels} />}
          className="flex justify-center"
          interactionKind="hover"
        >
          <span className="flex text-xl text-center border-2 border-gray-700 rounded bg-gray-600 p-1 text-white relative">
            {intl.formatMessage(
              { id: "app.survey-report.grouping-popover-target" },
              { count: labels?.length }
            )}
          </span>
        </Popover>
      </td>
    </tr>
  );
};

export default GroupedItemFilterLabel;
