import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@blueprintjs/core";
import {downloadSchoolSummaryCounts } from "../../../store/users/actions";

type OwnProps = {
};

type Props = OwnProps;

const DownloadSchoolSummary: React.FC<Props> = ({
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loading = useSelector((s) => s.surveyReports.loading.downloadCSVReport);

  const handleDownloadCSVReportClick = () => {
    dispatch(
      downloadSchoolSummaryCounts.request()
    );
  };

  return (
    <Button
    text={intl.formatMessage({ id: "app.titles.download-school-summary" })}
    title={intl.formatMessage({ id: "app.titles.download-school-summary" })}
    intent="primary"
      onClick={handleDownloadCSVReportClick}
      loading={loading}
    />
  );
};

export default DownloadSchoolSummary;
