import React, { FunctionComponent, useMemo } from "react";
import { Button, ButtonGroup } from "@blueprintjs/core";

type OwnProps = {
  pageCount: number;
  gotoPage: (page: number) => void;
  pageIndex: number;
  customGoToPage?: (page: number) => void;
  customPagination?: boolean;
};

type Props = OwnProps;

const CELL_COUNT = 8;

type PaginationCell = {
  pIndex: number;
  ellipsis?: boolean;
};

const TablePagination: FunctionComponent<Props> = (props) => {
  const {
    pageCount,
    gotoPage,
    pageIndex,
    customGoToPage,
    customPagination,
  } = props;

  const paginationCells: PaginationCell[] = useMemo(() => {
    const offset = pageCount - (pageIndex + 1);
    const pivot = ~~(CELL_COUNT / 2);

    let cells: PaginationCell[] = [];

    if (pageCount > CELL_COUNT) {
      // Fill in first and last positions
      cells[0] = { pIndex: 0 };
      cells[1] = { pIndex: 1 };
      cells[CELL_COUNT - 2] = { pIndex: pageCount - 2 };
      cells[CELL_COUNT - 1] = { pIndex: pageCount - 1 };

      if (pageIndex + 1 <= pivot) {
        // last ellipse is enabled and the rest of the array is filled
        cells[CELL_COUNT - 2].ellipsis = true;
        for (let i = 2; i < CELL_COUNT - 2; i++) {
          cells[i] = { pIndex: i };
        }
      } else if (offset < pivot) {
        // a ellipsis is enabled and the later part of array is filled
        cells[1].ellipsis = true;
        for (let i = 2; i < CELL_COUNT - 2; i++) {
          cells[i] = { pIndex: pageCount - CELL_COUNT + i };
        }
      } else {
        // both a and b ellipsis are enabled
        cells[1].ellipsis = true;
        cells[CELL_COUNT - 2].ellipsis = true;

        // Current selected is put in centre
        cells[pivot] = { pIndex: pageIndex };
        // Fill next and prev to mid point
        // CELL_COUNT - 5 := n{MID, FIRST, SECOND, LAST, SECONDLAST}
        for (let i = 1; i < CELL_COUNT - 5; i++) {
          cells[pivot + i] = { pIndex: pageIndex + i };
          cells[pivot - i] = { pIndex: pageIndex - i };
        }
      }
    } else {
      for (let i = 0; i < pageCount; i++) {
        cells[i] = { pIndex: i, ellipsis: false };
      }
    }
    return cells;
  }, [pageCount, pageIndex]);

  if (pageCount <= 1) {
    return null;
  }

  const OnGoToPage = (index: number) => {
    if (customGoToPage && customPagination) {
      customGoToPage(index);
    } else {
      gotoPage(index);
    }
  };

  const goToPrevious = (index: number) => {
    if (customGoToPage && customPagination) {
      customGoToPage(index);
    } else {
      gotoPage(index);
    }
  };

  const goToNext = (index: number) => {
    if (customGoToPage && customPagination) {
      customGoToPage(index);
    } else {
      gotoPage(index);
    }
  };

  return (
    <div className="mt-4">
      <ButtonGroup>
        <Button
          icon="arrow-left"
          onClick={() => goToPrevious(pageIndex - 1)}
          disabled={pageIndex === 0}
        />
        {paginationCells.map(({ ellipsis, pIndex }) => (
          <Button
            key={pIndex}
            text={!ellipsis && pIndex + 1}
            icon={ellipsis && "more"}
            disabled={ellipsis}
            active={pIndex === pageIndex}
            onClick={() => OnGoToPage(pIndex)}
          />
        ))}
        <Button
          icon="arrow-right"
          onClick={() => goToNext(pageIndex + 1)}
          disabled={pageIndex === pageCount - 1}
        />
      </ButtonGroup>
    </div>
  );
};

export default TablePagination;
