import React, { FunctionComponent, useMemo, useRef } from "react";
import { FormGroup } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { DateInput, Months } from "@blueprintjs/datetime";
import moment from "moment";
import { StringifyOptions } from "querystring";

type OwnProps = {
  label?: string;
  date?: Date;
  labelless?: boolean;
  onDateChange: (date: Date) => any;
  minDateOffset?: number;
  maxDateOffset?: number;
  warningBorder?: boolean;
};

type Props = OwnProps;

const DATE_FORMAT = "M/D/YYYY";

const DateInputGroup: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const {
    date,
    onDateChange,
    labelless,
    minDateOffset = 0,
    maxDateOffset = 10,
    warningBorder = false,
    label = intl.formatMessage({
      id: "app.titles.date",
    }),
  } = props;

  const handleDateChange = (selectedDate: Date) => {
    onDateChange(selectedDate);
  };

  const minDate = useMemo(() => {
    return moment().subtract(minDateOffset, "y").toDate();
  }, [minDateOffset]);

  const maxDate = useMemo(() => {
    return moment()
      .add(maxDateOffset, "y")
      .set({ month: Months.DECEMBER, day: 31 })
      .toDate();
  }, [maxDateOffset]);

  const handleDateParse = (str: string) => {
    const momentDate = moment(str, DATE_FORMAT);
    const isValidInput = momentDate.isValid();

    if (isValidInput) {
      if (momentDate.isBefore(minDate)) {
        return minDate;
      } else if (momentDate.isAfter(maxDate)) {
        return maxDate;
      } else {
        return momentDate.toDate();
      }
    } else {
      return null;
    }
  };

  return (
    <FormGroup className="mb-0" label={labelless ? undefined : label}>
      <DateInput
        inputProps={{ required: true, readOnly: true }}
        formatDate={(date) => date.toLocaleDateString(intl.locale)}
        parseDate={handleDateParse}
        placeholder={DATE_FORMAT}
        minDate={minDate}
        maxDate={maxDate}
        value={date}
        onChange={handleDateChange}
        className={warningBorder ? "border-danger" : ""}
        popoverProps={{ portalClassName: "date-input-portal" }}
      />
    </FormGroup>
  );
};

export default DateInputGroup;
