import { UserRole } from "../../types";
import { useSelector } from "react-redux";
import { getCurrentUserRole } from "../../store/auth/selectors";

const useRolesPermissions = (permittedRoles: UserRole[]): boolean => {
  const currentUserRole = useSelector(getCurrentUserRole);
  if (currentUserRole === undefined) {
    return false;
  } else {
    return permittedRoles.includes(currentUserRole);
  }
};

export default useRolesPermissions;
