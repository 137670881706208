import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { DatePicker } from "@blueprintjs/datetime";
import moment from "moment";
import { InputGroup, Popover } from "@blueprintjs/core";
import { useDateInputDefaultMinMax } from "../../helpers/hooks/useDateInputDefaultMinMax";
import { AppToaster } from "../../helpers/toaster";
import { Alert, Button, Snackbar, SnackbarOrigin } from "@mui/material";


type OwnProps = {
  value?: Date;
  isPickerOpen?: boolean;
  onChange: (selectedDate: Date, isUserChange: boolean) => void;
  handlePopupClose?: any;
  goalsPage?:boolean;
  style?:any;
};

type Props = OwnProps;

export interface State extends SnackbarOrigin {
  open: boolean;
}


const CustomDateInput: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const { minDate, maxDate } = useDateInputDefaultMinMax();

  const {
    value,
    onChange,
    isPickerOpen,
    handlePopupClose,
    goalsPage,
    style,
  } = props;

  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(
    isPickerOpen ?? false
  );

if(!isDatePickerOpen){
  handlePopupClose()
}

  const handleDateChange = (selectedDate: Date, isUserChange: boolean) => {
    onChange(selectedDate, isUserChange);
    // setDatePickerOpen(false);
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setDatePickerOpen(true);
  };

  const ref = useRef<any>(null);
  const CalenderRef = useRef<any>(null);

  const handleDocumentClick = useCallback((event: MouseEvent) => {
    const isClickedInside = ref.current.contains(event.target);
    const isCalenderClick = CalenderRef.current?.contains(event.target)
   
    if (!isClickedInside && !isCalenderClick) {
      setState({ open: true, vertical: "top", horizontal: "center" });
      setDatePickerOpen(false);
    }else{
      setDatePickerOpen(true)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick, true);
    return () => {
      document.removeEventListener("click", handleDocumentClick, true);
    };
  }, []);

  return (
    <div ref={ref}>
      <Popover
        isOpen={isDatePickerOpen}
        content={
          <div ref={CalenderRef}>
            <DatePicker
              value={moment(value).isBefore(moment()) ? undefined : value}
              onChange={handleDateChange}
              minDate={minDate}
              maxDate={maxDate}
              highlightCurrentDay
            />
          </div>
        }
        target={
          <InputGroup
            intent={!value && goalsPage ? "danger" : "none"}
            style={style ? { ...style } : {}}
            autoFocus={isPickerOpen ?? false}
            value={value?.toLocaleDateString(intl.locale) ?? ""}
            placeholder={
              !value && goalsPage
                ? "None"
                : intl.formatMessage({ id: "app.titles.date-input" })
            }
            onClick={handleInputClick}
            onChange={() => handlePopupClose()}
          />
        }
      />
    </div>
  );
};

export default CustomDateInput;
