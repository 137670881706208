import React, { ChangeEvent, useCallback, useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Icon,
  InputGroup,
} from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  hideEditProfileDialog,
  showChangePasswordDialog,
  updateProfile,
} from "../../../store/profile/actions";
import { AppToaster } from "../../../helpers/toaster";
import { useLoading } from "../../../helpers/hooks/useLoading";
import UserAvatar from "../UserAvatar";

type OwnProps = {};

type Props = OwnProps;

const EditProfileDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const show = useSelector((s) => s.profile.showEditProfileDialog);

  const me = useSelector((s) => s.auth.authenticatedUser);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleFirstNameChange = (changedFirstName: string) => {
    setFirstName(changedFirstName);
  };

  const handleLastNameChange = (changedLastName: string) => {
    setLastName(changedLastName);
  };

  const handleEmailChange = (changedEmail: string) => {
    setEmail(changedEmail);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      updateProfile.request({
        first_name: firstName,
        last_name: lastName,
        email: email,
      })
    );
  };

  const handleClose = () => {
    if (!loading) {
      setFirstName("");
      setLastName("");
      setEmail("");
      dispatch(hideEditProfileDialog());
    }
  };

  const handleOpen = () => {
    if (me) {
      setFirstName(me.first_name);
      setLastName(me.last_name);
      setEmail(me.email);
    }
  };

  const loading = useSelector((s) => s.profile.loading.updateProfile);
  const error = useSelector((s) => s.profile.errors.updateProfile);

  const onSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.profile.toaster.profile-updated-success",
      }),
      icon: "tick",
      intent: "success",
    });
    handleClose();
  }, []);

  useLoading({ loading, error, onSuccess });

  const handleChangePasswordClick = () => {
    dispatch(showChangePasswordDialog());
  };

  return (
    <Dialog
      isOpen={show}
      onClose={handleClose}
      onOpening={handleOpen}
      icon={<Icon icon="edit" iconSize={24} />}
      title={intl.formatMessage({ id: "app.profile.edit-profile" })}
    >
      <div className={Classes.DIALOG_BODY}>
        <form id="edit-profile-form" onSubmit={handleFormSubmit}>
          <div className="flex items-center gap-4">
            <div>
              <UserAvatar src={me?.profile?.image_url} size={96} />
            </div>
            <div className="flex-1">
              <FormGroup
                label={intl.formatMessage({
                  id: "app.profile.dialogs.edit-profile.first-name",
                })}
                labelFor="first-name"
              >
                <InputGroup
                  id="first-name"
                  required={true}
                  type="text"
                  value={firstName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleFirstNameChange(event.target?.value)
                  }
                />
              </FormGroup>
              <FormGroup
                label={intl.formatMessage({
                  id: "app.profile.dialogs.edit-profile.last-name",
                })}
                labelFor="last-name"
              >
                <InputGroup
                  id="last-name"
                  required={true}
                  type="text"
                  value={lastName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleLastNameChange(event.target?.value)
                  }
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup
            label={intl.formatMessage({
              id: "app.forms.email",
            })}
            labelFor="email"
          >
            <InputGroup
              id="email"
              required={true}
              type="text"
              value={email}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleEmailChange(event.target?.value)
              }
            />
          </FormGroup>
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button className="w-1/4" onClick={handleClose}>
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
          <Button
            text={intl.formatMessage({ id: "app.profile.change-password" })}
            title={intl.formatMessage({ id: "app.profile.change-password" })}
            onClick={handleChangePasswordClick}
            intent="primary"
          />
          <Button intent="primary" type="submit" form="edit-profile-form">
            {intl.formatMessage({
              id: "app.profile.dialogs.edit-profile.save-changes",
            })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditProfileDialog;
