import { RefObject, useEffect, useState } from "react";

const useResize = (myRef: RefObject<any>) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(myRef.current?.offsetWidth || 0);
      setHeight(myRef.current?.offsetHeight || 0);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return { width, height };
};

export default useResize;
