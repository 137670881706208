import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyReportResponseRates } from "../../../store/survey-reports/actions";
import { Spinner } from "@blueprintjs/core";
import {
  ResponseRatesByDemographic,
  SurveyDemographic,
  SurveyDeploymentStateEnum,
} from "../../../types";
import ResponseRatesTable from "../table/ResponseRatesTable";
import SurveyReportNonIdealState from "../table/non-ideal-states/SurveyReportNonIdealState";
import _ from "lodash";

type OwnProps = {
  schoolId: number | undefined;
  surveyDeploymentId: number | undefined;
  domainId: number | undefined;
  availableDemographicsInSchool: SurveyDemographic[];
};

type Props = OwnProps;

const ResponseRatesTab: React.FC<Props> = (props: Props) => {
  const {
    schoolId,
    surveyDeploymentId,
    domainId,
    availableDemographicsInSchool,
  } = props;

  const dispatch = useDispatch();

  const surveysDeployment = useSelector(
    (s) => s.surveysDeployment.surveysDeploymentBySchool
  );

  const isSelectedSurveyDeploymentStateReady = useMemo(() => {
    return (
      surveysDeployment.find((sd) => sd.id === surveyDeploymentId)?.state ===
      SurveyDeploymentStateEnum.READY
    );
  }, [surveysDeployment, surveyDeploymentId]);

  const isValidParameters = useMemo(() => {
    return !!schoolId && !!surveyDeploymentId;
  }, [schoolId, surveyDeploymentId]);

  const responseRates = useSelector((s) => s.surveyReports.responseRates);
  const loading = useSelector(
    (s) => s.surveyReports.loading.getSurveyReportResponseRates
  );
  useEffect(() => {
    if (isValidParameters) {
      dispatch(
        getSurveyReportResponseRates.request({
          schoolId: schoolId!,
          surveyDeploymentId: surveyDeploymentId!,
          domainId: domainId,
        })
      );
    }
  }, [isValidParameters]);

  const displayedData = useMemo(() => {
    return _.chain(responseRates?.response_rates)
      .pick(availableDemographicsInSchool)
      .entries()
      .map<[string, ResponseRatesByDemographic[] | undefined]>(
        ([demographic, rates]) => [
          demographic,
          !isSelectedSurveyDeploymentStateReady
            ? rates?.filter(
                (rate) =>
                  rate.title === "overall" ||
                  ([
                    SurveyDemographic.Students,
                    SurveyDemographic.ElementaryStudents,
                  ].includes(demographic as SurveyDemographic)
                    ? rate.title === "grade"
                    : false)
              )
            : rates,
        ]
      )
      .value();
  }, [
    responseRates,
    availableDemographicsInSchool,
    isSelectedSurveyDeploymentStateReady,
  ]);

  return (
    <>
      {loading ? (
        <Spinner intent={"primary"} className="py-4" />
      ) : (
        <>
          {isValidParameters ? (
            <div className="flex flex-row flex-wrap">
              {displayedData.map(
                ([demographic, ratesByDemographics]: [
                  string,
                  ResponseRatesByDemographic[] | undefined
                ]) => (
                  <div key={demographic} className="flex flex-1 flex-col px-2">
                    {ratesByDemographics?.map(
                      (
                        ratesByDemographic: ResponseRatesByDemographic,
                        index: number
                      ) => (
                        <ResponseRatesTable
                          key={index}
                          demographic={demographic as SurveyDemographic}
                          ratesByDemographic={ratesByDemographic}
                        />
                      )
                    )}
                  </div>
                )
              )}
            </div>
          ) : (
            <SurveyReportNonIdealState />
          )}
        </>
      )}
    </>
  );
};

export default ResponseRatesTab;
