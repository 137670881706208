import { Tag } from "@blueprintjs/core";
import React from "react";
import { AttachedResource, AttachedResourceType } from "../../../types";

type OwnProps = {
  resources: AttachedResource[];
  onResourceEdit?: (res: AttachedResource) => () => void;
  onResourceRemove?: (resourceId: number) => () => void;
  clickable?: boolean;
  noData?: React.ReactNode;
  lastElement?: React.ReactNode;
};

type Props = OwnProps;

const ResourcesList: React.FC<Props> = (props) => {
  
  const {
    resources,
    onResourceEdit,
    onResourceRemove,
    clickable = true,
    noData,
    children,
    lastElement,
  } = props;

  const handleResourceClick = (res: AttachedResource) => {
    const isLink = res.type === AttachedResourceType.Link;
    const link = document.createElement("a");
    !isLink && link.setAttribute("download", res.title);
    link.target = isLink ? "_target" : "";
    link.href = (isLink ? res.url : res.attachment_url) ?? "";
    document.body.append(link);
    link.click();
    link.remove();
  };
//console.log(resources)
  const handleRemoveClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    resId: number
  ) => {
    e.stopPropagation();
    !!onResourceRemove && onResourceRemove(resId)();
  };

  return (
    <div className="flex gap-2 flex-wrap" onClick={(e) => e.stopPropagation()}>
      {children}
      {resources.length
        ? resources.map((res) => (
            <Tag
              key={res.id}
              className="flex justify-between items-center"
              large
              interactive={clickable}
              intent="primary"
              onDoubleClick={!!onResourceEdit ? onResourceEdit(res) : undefined}
              onRemove={
                !!onResourceRemove
                  ? (e) => handleRemoveClick(e, res.id)
                  : undefined
              }
              icon={
                res.type === AttachedResourceType.Upload ? "document" : "link"
              }
              onClick={() => clickable && handleResourceClick(res)}
            >
              {res.title}
            </Tag>
          ))
        : noData}
      {lastElement}
    </div>
  );
};

export default ResourcesList;
