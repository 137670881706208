import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { getObjectFromLocalStorage, LocalStorageKeys } from "../../constants";
import { AuthenticatedUserModel } from "../../types";

type ActionName =
  | "registerUser"
  | "login"
  | "resetPassword"
  | "getAuthenticatedUser"
  | "logout"
  | "restorePassword"
  | "uploadProfileImage"
  | "removeProfileImage";

export type AuthState = {
  isAuthenticated?: boolean;
  authenticatedUser?: AuthenticatedUserModel;
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

export type AuthActions = ActionType<typeof import("./actions")>;

const initialState: AuthState = {
  loading: {},
  errors: {},
  isAuthenticated:
    getObjectFromLocalStorage(LocalStorageKeys.CurrentUser) !== undefined,
  authenticatedUser: getObjectFromLocalStorage(LocalStorageKeys.CurrentUser),
};

export const authReducer = createReducer<AuthState, AuthActions>(initialState)
  //login
  .handleAction(
    actions.login.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        login: true,
      },
      errors: {
        ...state.errors,
        login: undefined,
      },
    })
  )
  .handleAction(actions.login.success, (state, action): AuthState => {
    return {
      ...state,
      loading: {
        ...state.loading,
        login: false,
      },
      isAuthenticated: true,
    };
  })
  .handleAction(
    actions.login.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        login: action.payload,
      },
      loading: {
        ...state.loading,
        login: false,
      },
    })
  )
  //logout
  .handleAction(
    actions.logout.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        logout: true,
      },
      errors: {
        ...state.errors,
        logout: undefined,
      },
    })
  )
  .handleAction(actions.logout.success, (state, action): AuthState => {
    localStorage.removeItem(LocalStorageKeys.CurrentUser);
    return {
      ...state,
      loading: {
        ...state.loading,
        logout: false,
      },
      authenticatedUser: undefined,
      isAuthenticated: false,
    };
  })
  .handleAction(
    actions.logout.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        logout: action.payload,
      },
      loading: {
        ...state.loading,
        logout: false,
      },
    })
  )
  //register user
  .handleAction(
    actions.registerUser.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        registerUser: true,
      },
      errors: {
        ...state.errors,
        registerUser: undefined,
      },
    })
  )
  .handleAction(
    actions.registerUser.success,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        registerUser: false,
      },
      isAuthenticated: true,
      authenticatedUser: action.payload,
    })
  )
  .handleAction(
    actions.registerUser.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        registerUser: action.payload,
      },
      loading: {
        ...state.loading,
        registerUser: false,
      },
    })
  )
  //reset password
  .handleAction(
    actions.resetPassword.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        resetPassword: true,
      },
      errors: {
        ...state.errors,
        resetPassword: undefined,
      },
    })
  )
  .handleAction(
    actions.resetPassword.success,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        resetPassword: false,
      },
    })
  )
  .handleAction(
    actions.resetPassword.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        resetPassword: action.payload,
      },
      loading: {
        ...state.loading,
        resetPassword: false,
      },
    })
  )
  //get authenticated user
  .handleAction(
    actions.getAuthenticatedUser.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        getAuthenticatedUser: true,
      },
      errors: {
        ...state.errors,
        getAuthenticatedUser: undefined,
      },
    })
  )
  .handleAction(
    actions.getAuthenticatedUser.success,
    (state, action): AuthState => {
      localStorage.setItem(
        LocalStorageKeys.CurrentUser,
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        loading: {
          ...state.loading,
          getAuthenticatedUser: false,
        },
        authenticatedUser: action.payload,
      };
    }
  )
  .handleAction(
    actions.getAuthenticatedUser.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        getAuthenticatedUser: action.payload,
      },
      loading: {
        ...state.loading,
        getAuthenticatedUser: false,
      },
    })
  )
  //restore password
  .handleAction(
    actions.restorePassword.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        restorePassword: true,
      },
      errors: {
        ...state.errors,
        restorePassword: undefined,
      },
    })
  )
  .handleAction(
    actions.restorePassword.success,
    (state, action): AuthState => ({
      ...state,
      isAuthenticated: true,
      authenticatedUser: action.payload,
      loading: {
        ...state.loading,
        restorePassword: false,
      },
    })
  )
  .handleAction(
    actions.restorePassword.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        restorePassword: action.payload,
      },
      loading: {
        ...state.loading,
        restorePassword: false,
      },
    })
  )
  //restore password
  .handleAction(
    actions.uploadProfileImage.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        uploadProfileImage: true,
      },
      errors: {
        ...state.errors,
        uploadProfileImage: undefined,
      },
    })
  )
  .handleAction(
    actions.uploadProfileImage.success,
    (state, action): AuthState => ({
      ...state,
      authenticatedUser: state.authenticatedUser
        ? {
            ...state.authenticatedUser,
            profile: { ...state.authenticatedUser.profile, ...action.payload },
          }
        : state.authenticatedUser,
      loading: {
        ...state.loading,
        uploadProfileImage: false,
      },
    })
  )
  .handleAction(
    actions.uploadProfileImage.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        uploadProfileImage: action.payload,
      },
      loading: {
        ...state.loading,
        uploadProfileImage: false,
      },
    })
  )
  .handleAction(
    actions.removeProfileImage.request,
    (state, action): AuthState => ({
      ...state,
      loading: {
        ...state.loading,
        removeProfileImage: true,
      },
      errors: {
        ...state.errors,
        removeProfileImage: undefined,
      },
    })
  )
  .handleAction(
    actions.removeProfileImage.success,
    (state, action): AuthState => ({
      ...state,
      authenticatedUser: state.authenticatedUser
        ? {
            ...state.authenticatedUser,
            profile: { ...state.authenticatedUser.profile, ...action.payload },
          }
        : state.authenticatedUser,
      loading: {
        ...state.loading,
        removeProfileImage: false,
      },
    })
  )
  .handleAction(
    actions.removeProfileImage.failure,
    (state, action): AuthState => ({
      ...state,
      errors: {
        ...state.errors,
        removeProfileImage: action.payload,
      },
      loading: {
        ...state.loading,
        removeProfileImage: false,
      },
    })
  )


  //setAuthenticatedUser
  .handleAction(
    actions.setAuthenticatedUser,
    (state, action): AuthState => ({
      ...state,
      authenticatedUser: action.payload,
    })
  );
