import { ParseResult } from "papaparse";
import { createReducer, RootAction } from "typesafe-actions";
import { DataPoint, Dataset, ViewDataSetDialogType } from "../../types";
import * as actions from "./actions";

type ActionName =
  | "getDatasets"
  | "createCustomDataset"
  | "deleteCustomDataset"
  | "addDataPointToCustomDataset"
  | "deleteDataPointFromCustomDataset"
  | "updateDataPointInCustomDataset";

export type DatasetsState = {
  datasets: Dataset[];
  dialogs: {
    createCustomDataSetDialog: {
      show?: boolean;
    };
    createDatasetFromFileDialog: {
      show?: boolean;
      parseResult?: ParseResult<any>;
    };
    viewDataSetDialog: ViewDataSetDialogType;
    addDataPointDialog: {
      show?: boolean;
      dataPoint?: DataPoint;
    };
    uploadCsvDialog: {
      show?: boolean;
    };
  };
  loading: {
    [action in ActionName]?: boolean;
  };
  errors: {
    [action in ActionName]?: Error;
  };
};

const initialState: DatasetsState = {
  datasets: [],
  loading: {},
  errors: {},
  dialogs: {
    createCustomDataSetDialog: {},
    createDatasetFromFileDialog: {},
    viewDataSetDialog: {},
    addDataPointDialog: {},
    uploadCsvDialog: {},
  },
};

export const datasetsReducer = createReducer<DatasetsState, RootAction>(
  initialState
)
  // get Datasets
  .handleAction(
    actions.getDatasets.request,
    (state, action): DatasetsState => ({
      ...state,
      loading: {
        ...state.loading,
        getDatasets: true,
      },
      errors: {
        ...state.errors,
        getDatasets: undefined,
      },
    })
  )
  .handleAction(
    actions.getDatasets.success,
    (state, action): DatasetsState => ({
      ...state,
      datasets: action.payload,
      loading: {
        ...state.loading,
        getDatasets: false,
      },
    })
  )
  .handleAction(
    actions.getDatasets.failure,
    (state, action): DatasetsState => ({
      ...state,
      errors: {
        ...state.errors,
        getDatasets: action.payload,
      },
      loading: {
        ...state.loading,
        getDatasets: false,
      },
    })
  )
  // get Data Sets
  .handleAction(
    actions.createCustomDataset.request,
    (state, action): DatasetsState => ({
      ...state,
      loading: {
        ...state.loading,
        createCustomDataset: true,
      },
      errors: {
        ...state.errors,
        createCustomDataset: undefined,
      },
    })
  )
  .handleAction(
    actions.createCustomDataset.success,
    (state, action): DatasetsState => ({
      ...state,
      datasets: [...state.datasets, action.payload],
      loading: {
        ...state.loading,
        createCustomDataset: false,
      },
    })
  )
  .handleAction(
    actions.createCustomDataset.failure,
    (state, action): DatasetsState => ({
      ...state,
      errors: {
        ...state.errors,
        createCustomDataset: action.payload,
      },
      loading: {
        ...state.loading,
        createCustomDataset: false,
      },
    })
  )
  // add data point to custom set
  .handleAction(
    actions.addDataPointToCustomDataset.request,
    (state, action): DatasetsState => ({
      ...state,
      loading: {
        ...state.loading,
        addDataPointToCustomDataset: true,
      },
      errors: {
        ...state.errors,
        addDataPointToCustomDataset: undefined,
      },
    })
  )
  .handleAction(
    actions.addDataPointToCustomDataset.success,
    (state, action): DatasetsState => ({
      ...state,
      datasets: state.datasets.map((ds) =>
        ds.id === action.payload.datasetId
          ? {
              ...ds,
              data_points: [
                ...(ds.data_points ?? []),
                action.payload.dataPoint,
              ],
            }
          : ds
      ),
      dialogs: state.dialogs.viewDataSetDialog.show
        ? {
            ...state.dialogs,
            viewDataSetDialog: {
              ...state.dialogs.viewDataSetDialog,
              dataset: {
                ...state.dialogs.viewDataSetDialog.dataset!,
                data_points: [
                  ...(state.dialogs.viewDataSetDialog.dataset!.data_points ??
                    []),
                  action.payload.dataPoint,
                ],
              },
            },
          }
        : state.dialogs,
      loading: {
        ...state.loading,
        addDataPointToCustomDataset: false,
      },
    })
  )
  .handleAction(
    actions.addDataPointToCustomDataset.failure,
    (state, action): DatasetsState => ({
      ...state,
      errors: {
        ...state.errors,
        addDataPointToCustomDataset: action.payload,
      },
      loading: {
        ...state.loading,
        addDataPointToCustomDataset: false,
      },
    })
  )
  // delete custom dataset
  .handleAction(
    actions.deleteCustomDataset.request,
    (state, action): DatasetsState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteCustomDataset: true,
      },
      errors: {
        ...state.errors,
        deleteCustomDataset: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteCustomDataset.success,
    (state, action): DatasetsState => ({
      ...state,
      datasets: state.datasets.filter((d) => d.id !== action.payload),
      loading: {
        ...state.loading,
        deleteCustomDataset: false,
      },
    })
  )
  .handleAction(
    actions.deleteCustomDataset.failure,
    (state, action): DatasetsState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteCustomDataset: action.payload,
      },
      loading: {
        ...state.loading,
        deleteCustomDataset: false,
      },
    })
  )
  // custom data set dialog
  .handleAction(
    actions.showCreateCustomDataSetDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        createCustomDataSetDialog: {
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideCreateCustomDataSetDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        createCustomDataSetDialog: {},
      },
    })
  )

  // create dataset from file dialog
  .handleAction(
    actions.showCreateDatasetFromFileDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        createDatasetFromFileDialog: {
          show: true,
          parseResult: action.payload,
        },
        uploadCsvDialog: {
          ...state.dialogs.uploadCsvDialog,
          show: false,
        },
      },
    })
  )
  .handleAction(
    actions.hideCreateDatasetFromFileDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        createDatasetFromFileDialog: {},
      },
    })
  )
  // create dataset from file dialog
  .handleAction(
    actions.showUploadCsvDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        uploadCsvDialog: {
          ...state.dialogs.uploadCsvDialog,
          show: true,
        },
      },
    })
  )
  .handleAction(
    actions.hideUploadCsvDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        uploadCsvDialog: {
          ...state.dialogs.uploadCsvDialog,
          show: false,
        },
      },
    })
  )
  // view data set dialog
  .handleAction(
    actions.showViewDataSetDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        viewDataSetDialog: {
          show: true,
          ...action.payload,
        },
        addDataPointDialog: {
          show: action.payload.addingByDefault,
        },
      },
    })
  )
  .handleAction(
    actions.hideViewDataSetDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        viewDataSetDialog: {},
        addDataPointDialog: {},
      },
    })
  )
  // delete data point from custom set
  .handleAction(
    actions.deleteDataPointFromCustomDataset.request,
    (state, action): DatasetsState => ({
      ...state,
      loading: {
        ...state.loading,
        deleteDataPointFromCustomDataset: true,
      },
      errors: {
        ...state.errors,
        deleteDataPointFromCustomDataset: undefined,
      },
    })
  )
  .handleAction(
    actions.deleteDataPointFromCustomDataset.success,
    (state, action): DatasetsState => ({
      ...state,
      datasets: state.datasets.map((ds) =>
        ds.id === action.payload.datasetId
          ? {
              ...ds,
              data_points: ds?.data_points?.filter(
                (dp) => dp.id !== action.payload.dataPointId
              ),
            }
          : ds
      ),
      dialogs: state.dialogs.viewDataSetDialog.show
        ? {
            ...state.dialogs,
            viewDataSetDialog: {
              ...state.dialogs.viewDataSetDialog,
              dataset: {
                ...state.dialogs.viewDataSetDialog.dataset!,
                data_points: (
                  state.dialogs.viewDataSetDialog.dataset!.data_points ?? []
                ).filter((dp) => dp.id !== action.payload.dataPointId),
              },
            },
          }
        : state.dialogs,
      loading: {
        ...state.loading,
        deleteDataPointFromCustomDataset: false,
      },
    })
  )
  .handleAction(
    actions.deleteDataPointFromCustomDataset.failure,
    (state, action): DatasetsState => ({
      ...state,
      errors: {
        ...state.errors,
        deleteDataPointFromCustomDataset: action.payload,
      },
      loading: {
        ...state.loading,
        deleteDataPointFromCustomDataset: false,
      },
    })
  )
  // update data point in custom dataset
  .handleAction(
    actions.updateDataPointInCustomDataset.request,
    (state, action): DatasetsState => ({
      ...state,
      loading: {
        ...state.loading,
        updateDataPointInCustomDataset: true,
      },
      errors: {
        ...state.errors,
        updateDataPointInCustomDataset: undefined,
      },
    })
  )
  .handleAction(
    actions.updateDataPointInCustomDataset.success,
    (state, action): DatasetsState => ({
      ...state,
      datasets: state.datasets.map((ds) =>
        ds.id === action.payload.datasetId
          ? {
              ...ds,
              data_points: ds?.data_points?.map((dp) =>
                dp.id === action.payload.dataPoint.id
                  ? action.payload.dataPoint
                  : dp
              ),
            }
          : ds
      ),
      dialogs: state.dialogs.viewDataSetDialog.show
        ? {
            ...state.dialogs,
            viewDataSetDialog: {
              ...state.dialogs.viewDataSetDialog,
              dataset: {
                ...state.dialogs.viewDataSetDialog.dataset!,
                data_points: (
                  state.dialogs.viewDataSetDialog.dataset!.data_points ?? []
                ).map((dp) =>
                  dp.id === action.payload.dataPoint.id
                    ? action.payload.dataPoint
                    : dp
                ),
              },
            },
          }
        : state.dialogs,
      loading: {
        ...state.loading,
        updateDataPointInCustomDataset: false,
      },
    })
  )
  .handleAction(
    actions.updateDataPointInCustomDataset.failure,
    (state, action): DatasetsState => ({
      ...state,
      errors: {
        ...state.errors,
        updateDataPointInCustomDataset: action.payload,
      },
      loading: {
        ...state.loading,
        updateDataPointInCustomDataset: false,
      },
    })
  )
  // add data point dialog
  .handleAction(
    actions.showAddDataPointDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        addDataPointDialog: {
          show: true,
          dataPoint: action.payload,
        },
      },
    })
  )
  .handleAction(
    actions.hideAddDataPointDialog,
    (state, action): DatasetsState => ({
      ...state,
      dialogs: { ...state.dialogs, addDataPointDialog: {} },
    })
  )
  .handleAction(
    actions.upsertDataset,
    (state, action): DatasetsState => {
      const isUpdate = state.datasets.some((dM) => dM.id === action.payload.id);
      const datasets = isUpdate
        ? state.datasets.map((dataset) =>
            dataset.id === action.payload.id ? action.payload : dataset
          )
        : [...state.datasets, action.payload];
      return {
        ...state,
        datasets: datasets,
      };
    }
  );
