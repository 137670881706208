import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Button, Callout, Classes, Tab, Tabs } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  District,
  OpenTimePeriod,
  School,
  Survey,
  SurveyDeployment,
  SurveyDeploymentPassword,
} from "../../../types";
import {
  AddSurveyDeploymentRequest,
  UpdateSurveyDeploymentRequest,
} from "../../../api/surveys-deployment/types";
import {
  addSurveyDeployment,
  updateSurveyDeployment,
  getSurveysDeployment,
} from "../../../store/surveys-deployment/actions";
import { getCurrentUserDistrictId } from "../../../store/auth/selectors";
import SurveyDeploymentPasswords from "../forms/SurveyDeploymentPasswords";
import SurveyDeploymentGeneralInfoTab from "../forms/SurveyDeploymentGeneralInfoTab";
import {
  getSchools,
  getSchoolsByDistricts,
} from "../../../store/schools/actions";
import _ from "lodash";

type OwnProps = {
  selectedSurvey?: Survey;
  surveyDeployment?: SurveyDeployment<District, Survey<number>>;
  isTeamPlanDialog?: boolean;
  loading?: boolean;
  onClose: () => void;
};

type Props = OwnProps;

enum SurveyDeploymentTabEnum {
  GENERAL_INFO_TAB = "GENERAL_INFO_TAB",
  PASSWORDS_TAB = "PASSWORDS_TAB",
}

const SurveyDeploymentDialogContent: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    isTeamPlanDialog,
    selectedSurvey,
    surveyDeployment,
    loading,
    onClose,
  } = props;

  const activePlan = useSelector((s) => s.plans.activePlan);

  const [selectedTabId, setSelectedTabId] = useState(
    SurveyDeploymentTabEnum.GENERAL_INFO_TAB
  );
  const handleTabChange = (newTabId: SurveyDeploymentTabEnum) => {
    setSelectedTabId(newTabId);
  };

  const [surveyId, setSurveyId] = useState<number | undefined>();
  const [isGlobal, setGlobal] = useState(false);
  const [districtIds, setDistrictIds] = useState<number[]>([]);
  const [passwords, setPasswords] = useState<
    Omit<SurveyDeploymentPassword, "created_at" | "updated_at">[]
  >([]);

  const [schoolsIds, setSchoolsIds] = useState<number[]>([]);

  const [openTimePeriods, setOpenTimePeriods] = useState<OpenTimePeriod[]>([]);
  const [warningMessage, setWarningMessage] = useState<string | undefined>();

  useEffect(() => {
    setSelectedTabId(SurveyDeploymentTabEnum.GENERAL_INFO_TAB);
    if (isTeamPlanDialog && selectedSurvey) {
      setSurveyId(selectedSurvey.id);
    } else {
      setSurveyId(surveyDeployment?.survey?.id);
    }
    setGlobal(surveyDeployment?.is_global ?? false);
    setPasswords(surveyDeployment?.passwords ?? []);
    if (isTeamPlanDialog) {
      setDistrictIds(activePlan?.district ? [activePlan.district.id] : []);
    } else {
      setDistrictIds(surveyDeployment?.districts?.map((item) => item.id) ?? []);
    }
    if (isTeamPlanDialog) {
      setSchoolsIds(activePlan?.school ? [activePlan.school.id] : []);
    } else if (surveyDeployment) {
      const ids = _.chain(surveyDeployment.school_open_periods)
        .map((tp) => tp.school.id)
        .concat(
          _.chain(surveyDeployment.passwords)
            .map((tp) => tp.school)
            .value()
        )
        .uniq()
        .value();
      setSchoolsIds(ids);
    }

    setWarningMessage(undefined);
  }, []);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, message } = validate();
    if (isValid) {
      const newSurveyDeployment:
        | AddSurveyDeploymentRequest
        | UpdateSurveyDeploymentRequest = {
        survey: surveyId as number,
        is_global: isGlobal,
        districts: districtIds,
        passwords: passwords.map((pass) => {
          return {
            school: pass.school,
            demographic: pass.demographic,
            password: pass.password,
          };
        }),
        school_open_periods: openTimePeriods
          .map(({ id, ...tp }) => (id && id > 0 ? { id, ...tp } : tp))
          .filter((op) => !op.disabled),
      };
      surveyDeployment != null
        ? dispatch(
            updateSurveyDeployment.request({
              surveyDeploymentId: surveyDeployment.id,
              updatedSurveyDeployment: newSurveyDeployment as UpdateSurveyDeploymentRequest,
            })
          )
        : dispatch(
            addSurveyDeployment.request(
              newSurveyDeployment as AddSurveyDeploymentRequest
            )
          );

      setWarningMessage(undefined);
    } else {
      setWarningMessage(message);
    }
  };

  const handleSurveyIdChange = (selectedSurvey?: number) => {
    setSurveyId(selectedSurvey);
  };

  const handleGlobalChange = (e: React.FormEvent<HTMLInputElement>) => {
    setGlobal(e.currentTarget?.checked);
  };

  const handleDistrictChange = (selectedDistricts?: number[]) => {
    const districtIds = selectedDistricts ?? [];
    setDistrictIds(districtIds);
    setSchoolsIds((ids) =>
      ids.filter(
        (id) =>
          schoolsDictionary[id].district &&
          districtIds.includes(schoolsDictionary[id].district)
      )
    );
  };

  const validate = () => {
    let validationOption: {
      isValid: boolean;
      message?: string;
    } = {
      isValid: true,
      message: undefined,
    };

    if (surveyId === undefined) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.validators.survey-required",
        }),
      };
      return validationOption;
    }

    if (
      openTimePeriods.some(
        (tp) => !tp.disabled && (!tp.start_date || !tp.end_date)
      )
    ) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.validators.start-end-date-required",
        }),
      };
      return validationOption;
    }

    if (passwords.some((p) => !p.password)) {
      validationOption = {
        isValid: false,
        message: intl.formatMessage({
          id: "app.validation.passwords-required",
        }),
      };
      return validationOption;
    }
    return validationOption;
  };

  useEffect(() => {
    if (!isTeamPlanDialog) {
      if (isGlobal) {
        dispatch(getSchools.request());
      } else {
        dispatch(
          getSchoolsByDistricts.request({
            district_ids: [],
          })
        );
      }
    }
  }, []);

  const allSchools = useSelector((s) => s.schools.schools);

  const schoolsOptions = useMemo(() => {
    return districtIds.length
      ? allSchools.filter((s) => districtIds.includes(s.district))
      : allSchools;
  }, [allSchools, districtIds]);

  const schoolsDictionary: {
    [school: number]: School;
  } = useMemo(() => {
    return _.chain(allSchools)
      .keyBy((s) => s.id!)
      .value();
  }, [allSchools]);

  const selectedSchools = useMemo(() => {
    if (isTeamPlanDialog) {
      return activePlan?.school ? [activePlan.school] : [];
    }

    return schoolsIds.length
      ? schoolsIds
          .map((id) => schoolsDictionary[id]!)
          .filter((id) => id != null)
      : schoolsOptions;
  }, [
    isTeamPlanDialog,
    activePlan?.school,
    schoolsOptions,
    schoolsDictionary,
    schoolsIds,
  ]);

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <form id={"survey-deployment-form"} onSubmit={handleFormSubmit}>
          <Tabs
            id="survey-deployment-tabs"
            onChange={handleTabChange}
            selectedTabId={selectedTabId}
          >
            <Tab
              id={SurveyDeploymentTabEnum.GENERAL_INFO_TAB}
              title={intl.formatMessage({
                id: "app.survey-deployment-dialog.tabs.survey-deployment-tab",
              })}
              panel={
                <SurveyDeploymentGeneralInfoTab
                  creating={!surveyDeployment}
                  surveyId={surveyId}
                  onSurveyIdChange={handleSurveyIdChange}
                  districtIds={districtIds}
                  onDistrictChange={handleDistrictChange}
                  isGlobal={isGlobal}
                  onGlobalChange={handleGlobalChange}
                  isTeamPlanDialog={isTeamPlanDialog}
                  openTimePeriods={openTimePeriods}
                  setOpenTimePeriods={setOpenTimePeriods}
                  schoolsIds={schoolsIds}
                  setSchoolsIds={setSchoolsIds}
                  schoolsOptions={schoolsOptions}
                  schoolsDictionary={schoolsDictionary}
                />
              }
            />

            <Tab
              id={SurveyDeploymentTabEnum.PASSWORDS_TAB}
              title={intl.formatMessage({
                id:
                  "app.survey-deployment-dialog.tabs.survey-deployment-passwords-tab",
              })}
              panel={
                <SurveyDeploymentPasswords
                  districtIds={districtIds}
                  isGlobal={isGlobal}
                  passwords={passwords}
                  setPasswords={setPasswords}
                  isTeamPlanDialog={isTeamPlanDialog}
                  schools={selectedSchools}
                  openTimePeriods={openTimePeriods}
                />
              }
            />
          </Tabs>
          {warningMessage && (
            <div className="my-3 px-3">
              <Callout intent="warning">{warningMessage}</Callout>
            </div>
          )}
        </form>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className="flex justify-between">
          <Button
            className="button-min-width"
            onClick={onClose}
            disabled={loading}
          >
            {intl.formatMessage({ id: "app.titles.cancel" })}
          </Button>
          <Button
            form="survey-deployment-form"
            intent="primary"
            className="button-min-width"
            text={intl.formatMessage({ id: "app.titles.save" })}
            title={intl.formatMessage({ id: "app.titles.save" })}
            loading={loading}
            type="submit"
          />
        </div>
      </div>
    </>
  );
};

export default SurveyDeploymentDialogContent;
