import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

type OwnProps = {};

type Props = OwnProps;

const OverallResponseDistributionStepContent: FunctionComponent<Props> = (
  props
) => {
  const intl = useIntl();

  return (
    <div>
      {intl.formatMessage(
        {
          id: "app.survey-report.guide.overall-response-distribution",
        },
        {
          i: (content) => <i>{content}</i>,
          br: <br />,
        }
      )}
    </div>
  );
};

export default OverallResponseDistributionStepContent;
