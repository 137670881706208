import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "normalize.css/normalize.css"; 
import { FocusStyleManager } from "@blueprintjs/core";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import "./styles/index.css";
import applicationStore from "./store";
import Axios, { AxiosError } from "axios";
import { ApplicationState } from "./store/reducers";
import { Locale } from "./store/UIState";

import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import withProviders from "./components/HOCs/WithProviders";
import { logout } from "./store/auth/actions";

Chart.plugins.unregister(ChartDataLabels);

FocusStyleManager.onlyShowFocusOnTabs();

const store = applicationStore();

Axios.defaults.baseURL = ""; //todo
Axios.defaults.headers["content-type"] = "application/json";

const CancelToken = Axios.CancelToken;
const source = CancelToken.source();
Axios.create({ cancelToken: source.token });

Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.xsrfHeaderName = "X-CSRFToken";
Axios.defaults.withCredentials = true;
Axios.defaults.headers.common["Accept-Language"] =
  (store.getState() as ApplicationState).UIState.locale || Locale.English;
Axios.defaults.headers.common["x-accept-language"] = (store.getState() as ApplicationState).UIState.locale || Locale.English;
Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error: AxiosError) => {
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      (store.getState() as ApplicationState).auth.isAuthenticated
    ) {
      source.cancel();
      store.dispatch(logout.success());
    }

    return Promise.reject(error);
  }
);

export const WithProviders = withProviders(App);

ReactDOM.render(
  <WithProviders store={store} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
