import React, { useEffect } from "react";
import SurveyGroupByTableHead from "./SurveyGroupByTableHead";
import { useIntl } from "react-intl";
import {
  CollectedDemographicDataEnum,
  Survey,
  SurveyDeployment,
} from "../../../../types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectIsAdmin } from "../../../../store/auth/selectors";
import TourStepPopover from "../../../tour/TourStepPopover";
import AnswerChoicesHeader from "./AnswerChoicesHeader";
import { useHistory } from "react-router-dom";
import SurveyReportSearchBar from "./SurveyReportSearchBar";

type OwnProps = {
  groupingKeys: CollectedDemographicDataEnum[];
  isGroupedData: boolean;
  showTotalRespondentsColumn: boolean;

  schoolId: number | undefined;
  surveyDeploymentId: number | undefined;
  domainId: number | undefined;
  tabId: string | undefined;
  segmentationCode?: string;
  showTableHeaderContent?: boolean;
  forNegativeDomains?: boolean;
  manageBookmarkDialog?: boolean;
};

type Props = OwnProps;

export const GROUP_BY_PARAM = "group_by";

const SurveyReportTableHead: React.FC<Props> = (props) => {
  const {
    groupingKeys,
    isGroupedData,
    showTotalRespondentsColumn,
    surveyDeploymentId,
    domainId,
    tabId,
    segmentationCode,
    showTableHeaderContent,
    forNegativeDomains,
    manageBookmarkDialog,
  } = props;
  const DOUBLE_DISAGGREGATION_GROUP_COUNT=2
  const intl = useIntl();

  const isAuthenticatedAdmin = useSelector(selectIsAdmin);

  const history = useHistory();

  const location = useLocation();

  const selectedSurveyDeployment = useSelector<
    SurveyDeployment<number, Survey<number>> | undefined
  >((s) =>
    s.surveysDeployment.surveysDeploymentBySchool?.find(
      (s) => s.id === surveyDeploymentId
    )
  );

  const handleGroupByButtonClick = (buttonKey: string) => {
    let searchParams = new URLSearchParams(location.search);
    let updatedSearchParams = new URLSearchParams();
    let uniqueKey = true;
    searchParams.forEach((v, k) => {
      if (v !== buttonKey) {
        updatedSearchParams.append(k, v);
      } else {
        uniqueKey = false;
      }
    });
    if (uniqueKey) {
      updatedSearchParams.append(GROUP_BY_PARAM, buttonKey);
    }
    history.push({
      pathname: location.pathname,
      search: updatedSearchParams.toString(),
    });
  };

  const firstSearchParam = window.location.search
    .split("&")[0]
    ?.split("?")[1]
    ?.split("=")[1];

  return (
    <>
      <thead>
        <tr>
          <th colSpan={isGroupedData ? 6 : 5}>
            {showTableHeaderContent && (
              <div className="flex justify-between items-center gap-2">
                <div>
                  <SurveyGroupByTableHead
                    groupingKeys={groupingKeys}
                    onGroupByButtonClick={handleGroupByButtonClick}
                    domainId={domainId}
                    surveyDeploymentId={surveyDeploymentId}
                    tabId={tabId}
                  />
                </div>
                <SurveyReportSearchBar />
              </div>
            )}
          </th>
        </tr>
        <tr
          className={groupingKeys?.length===DOUBLE_DISAGGREGATION_GROUP_COUNT ? "sticky-thc" : ""}
        >
          <th className="topic-area-container" colSpan={2}>
            {segmentationCode && (
              <div className="text-white text-lg">
                <i>
                  {segmentationCode === "null"
                    ? intl.formatMessage({
                        id: "app.survey-report.charts.not-specified",
                      })
                    : segmentationCode}
                </i>
              </div>
            )}

            {domainId ? (
              <span className="text-xl font-semibold">
                {intl.formatMessage({
                  id: "app.survey-report.table.second-head.item-text",
                })}
              </span>
            ) : (
              <TourStepPopover dataTour={"topic-area"}>
                <div className="flex justify-center" data-tour="topic-area">
                  <span className="text-xl font-semibold">
                    {intl.formatMessage({
                      id: `app.survey-report.table.second-head.${
                        selectedSurveyDeployment?.survey
                          ?.survey_categories_type ?? "domain"
                      }`,
                    })}
                  </span>
                </div>
              </TourStepPopover>
            )}
          </th>
          {isGroupedData && (
            <th>
              <div className="flex-wrap">
                {groupingKeys?.map((key, index) => (
                  <React.Fragment key={index}>
                    <span className="text-xl font-semibold">
                      {(manageBookmarkDialog || firstSearchParam === key) &&
                        intl.formatMessage({
                          id: `app.survey-report.table.group-by-head.${key}`,
                        })}
                      {manageBookmarkDialog &&
                        groupingKeys.length - 1 > index &&
                        groupingKeys.length !== 0 &&
                        ", "}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            </th>
          )}

          <th
            className="w-full"
            data-tour="overall-mean"
            style={{ minWidth: "17.5rem" }}
            colSpan={
              (!isAuthenticatedAdmin || !showTotalRespondentsColumn) &&
              isGroupedData
                ? 2
                : 1
            }
          >
            <TourStepPopover
              forNegativeDomains={forNegativeDomains}
              dataTour={ !!domainId ? "mean-Content":"overall-mean"} 
              //disabled={!!domainId}
            >
              <div>
                <div>
                  {domainId ? (
                    <span className="text-xl font-semibold">
                      {intl.formatMessage({
                        id: "app.survey-report.table.second-head.mean",
                      })}
                    </span>
                  ) : (
                    <span className="text-xl font-semibold">
                      {intl.formatMessage({
                        id: "app.survey-report.table.second-head.mean-score",
                      })}
                    </span>
                  )}
                </div>
                <AnswerChoicesHeader tabId={tabId!} />
              </div>
            </TourStepPopover>
          </th>

          {domainId ? (
            <>
              {!isGroupedData && (
                <th
                  className="w-19-125rem min-w-19-125rem"
                  data-tour="response-distribution"
                >
                  <TourStepPopover dataTour="response-distribution">
                    <div className="flex flex-col">
                      <span className="text-xl font-semibold">
                        {intl.formatMessage({
                          id:
                            "app.survey-report.table.second-head.response-distribution",
                        })}
                      </span>
                      <span className="text-xl font-semibold">
                        {intl.formatMessage({
                          id: "app.survey-report.table.second-head.total-count",
                        })}
                      </span>
                    </div>
                  </TourStepPopover>
                </th>
              )}
            </>
          ) : (
            <>
              {!isGroupedData && (
                <th
                  className={`w-21-875rem min-w-21-875rem`}
                  data-tour="overall-response-distribution"
                >
                  <TourStepPopover dataTour="overall-response-distribution">
                    <span className="text-xl font-semibold">
                      {intl.formatMessage({
                        id: `app.survey-report.table.second-head.overall-response-distribution`,
                      })}
                    </span>
                  </TourStepPopover>
                </th>
              )}
            </>
          )}
          {((isGroupedData && isAuthenticatedAdmin) || !isGroupedData) &&
            showTotalRespondentsColumn && (
              <th
                colSpan={isGroupedData ? 2 : 1}
                className={`w-8-5 min-w-8-5rem`}
              >
                <TourStepPopover
                  dataTour="total-respondents"
                  disabled={!!domainId}
                >
                  <span
                    className="text-xl font-semibold"
                    data-tour="total-respondents"
                  >
                    {intl.formatMessage({
                      id: `app.survey-report.table.second-head.total-respondents`,
                    })}
                  </span>
                </TourStepPopover>
              </th>
            )}
        </tr>
      </thead>
    </>
  );
};

export default SurveyReportTableHead;
