import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Classes, Spinner } from "@blueprintjs/core";
import { useLoading } from "../../../../../helpers/hooks/useLoading";
import { DatasetType, Domain, SurveyQuestion } from "../../../../../types";
import DomainGroupCollapse from "./DomainGroupCollapse";
import useGetDatasetMarksLoading from "../../../../../helpers/hooks/loading/useGetDatasetMarksLoading";
import { useGetDatasetsLoading } from "../../../../../helpers/hooks/loading/useGetDatasetsLoading";
import { getDatasets } from "../../../../../store/data-sets/actions";
import { GetDatasetsRequest } from "../../../../../api/data-sets/types";
import { getDomainsAndItemsByIds } from "../../../../../store/survey-reports/actions";
import _ from "lodash";
import { GetDomainsAndItemsByIdsRequest } from "../../../../../api/domains/types";

type OwnProps = {};

type Props = OwnProps;

const InsightSubgroupInfoDialogBody: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();

  const selectedSurveyDeployment = useSelector(
    (s) => s.surveyReports.selectedSurveyDeployment
  );

  const {
    domainsCortege = [],
    itemsCortege = [],
    domains = [],
    questions = [],
    domains_avg: domainsMeansDictionary = {},
    questions_avg: itemsMeansDictionary = {},
    groupBy,
    demographic,
  } = useSelector((s) => s.surveyReports.dialogs.insightSubgroupInfoDialog);

  const activePlanSchoolId = useSelector((s) => s.plans.activePlan?.school?.id);

  const { loading: loadingGetDatasetMarks } = useGetDatasetMarksLoading({
    schoolId: activePlanSchoolId,
    supportsBookmarks: !!selectedSurveyDeployment?.survey.supports_bookmarks,
  });
  const { loading: loadingGetDatasets } = useGetDatasetsLoading();

  const getRelatedDatasets = () => {
    if (
      !activePlanSchoolId ||
      !selectedSurveyDeployment?.survey.supports_bookmarks
    ) {
      return;
    }

    let req: GetDatasetsRequest = {
      school_id: activePlanSchoolId,
      domain_id: domains.map((d) => d.id),
      // question_id: questions.map((q) => q.id), todo uncomment when api will be fixed
      survey_id: selectedSurveyDeployment?.survey?.id,
      demographic: demographic,
      type: DatasetType.Survey,
      group_by: groupBy?.key ? ([groupBy.key] as any) : undefined,
      is_grouped: true,
    };
    dispatch(getDatasets.request(req));
  };

  const loadingGetDomainsAndItemsByIds = useSelector(
    (s) => s.surveyReports.loading.getDomainsAndItemsByIds
  );
  const errorGetDomainsAndItemsByIds = useSelector(
    (s) => s.surveyReports.errors.getDomainsAndItemsByIds
  );
  useLoading({
    loading: loadingGetDomainsAndItemsByIds,
    error: errorGetDomainsAndItemsByIds,
    onSuccess: getRelatedDatasets,
  });

  useEffect(() => {
    if (activePlanSchoolId && (domainsCortege.length || itemsCortege.length)) {
      let req: GetDomainsAndItemsByIdsRequest = {
        school_id: activePlanSchoolId,
        survey_deployment_id: selectedSurveyDeployment?.id!,
        demographic: demographic!,
        domains: domainsCortege.map(([id]) => id),
        questions: itemsCortege.map(([id]) => id),
      };

      if (groupBy) {
        req.group_by = {
          [groupBy.key]: groupBy.value,
        };
      }

      dispatch(getDomainsAndItemsByIds.request(req));
    }
  }, [domainsCortege, itemsCortege, activePlanSchoolId]);

  const stats: [Domain, SurveyQuestion[]][] = useMemo(() => {
    return _.chain(questions)
      .groupBy((item) => item.domain)
      .toPairs()
      .orderBy(([domainId]) => {
        return domainsMeansDictionary[domainId];
      }, "asc")
      .map<[Domain, SurveyQuestion[]]>(([domainId, items]) => {
        const existingDomain = domains.find((d) => d.id == (domainId as any))!;
        return [
          existingDomain,
          _.orderBy(items, (item) => itemsMeansDictionary[item.id], "asc"),
        ];
      })
      .value();
  }, [questions, domains, domainsMeansDictionary, itemsMeansDictionary]);

  return (
    <div className={Classes.DIALOG_BODY}>
      {loadingGetDomainsAndItemsByIds ||
      loadingGetDatasetMarks ||
      loadingGetDatasets ? (
        <Spinner intent="primary" />
      ) : (
        <div className="space-y-2">
          {stats.map(([domain, items], index) => (
            <DomainGroupCollapse
              key={index}
              domain={domain}
              items={items}
              domainMeanScore={domainsMeansDictionary[domain.id]}
              itemsMeansDictionary={itemsMeansDictionary}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default InsightSubgroupInfoDialogBody;
