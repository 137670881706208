import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button, Classes, Dialog } from "@blueprintjs/core";

type OwnProps = {
  show?: boolean;
  onClose: () => void;
};

type Props = OwnProps;

const RoleInfoDialog: React.FC<Props> = ({ show, onClose }: Props) => {
  const intl = useIntl();

  const formatLi = useCallback((ending: string) => {
    return (
      <>
        <br />
        <li>
          {intl.formatMessage(
            {
              id: `app.dialogs.role-info.competences-menu.${ending}`,
            },
            { b: (chunks) => <b>{chunks}</b> }
          )}
        </li>
      </>
    );
  }, []);

  return (
    <Dialog
      isOpen={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: "app.dialogs.role-info.title",
      })}
      style={{ minWidth: "45rem" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <>
          {intl.formatMessage(
            { id: "app.dialogs.role-info.content" },
            {
              b: (chunks) => <b>{chunks}</b>,
              i: (chunks) => <i>{chunks}</i>,
              superintendentMenu: (
                <ul className="list-disc pl-10">
                  {intl.formatMessage(
                    {
                      id: "app.dialogs.role-info.superintendent-menu",
                    },
                    {
                      li: (chunks) => (
                        <>
                          <br />
                          <li>{chunks}</li>
                        </>
                      ),
                    }
                  )}
                  <br />
                </ul>
              ),
              responsibilitiesMenu: (
                <ul className="list-disc pl-10">
                  {intl.formatMessage(
                    {
                      id: "app.dialogs.role-info.responsibilities-menu",
                    },
                    {
                      li: (chunks) => (
                        <>
                          <br />
                          <li>{chunks}</li>
                        </>
                      ),
                    }
                  )}
                  <br />
                </ul>
              ),
              competencesMenu: (
                <ul className="list-disc pl-10">
                  {intl.formatMessage(
                    {
                      id: "app.dialogs.role-info.competences-menu",
                    },
                    {
                      firstLi: formatLi("firstLi"),
                      secondLi: formatLi("secondLi"),
                      thirdLi: formatLi("thirdLi"),
                      fourthLi: formatLi("fourthLi"),
                      fifthLi: formatLi("fifthLi"),
                    }
                  )}
                  <br />
                </ul>
              ),
              br: <br />,
            }
          )}
        </>
      </div>
      <div className={`mb-4`}>
        <div className="flex justify-center">
          <Button className="w-1/4" onClick={onClose} intent="primary">
            {intl.formatMessage({ id: "app.titles.close" })}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RoleInfoDialog;
