import _ from "lodash";

export enum LocalStorageKeys {
  CurrentUser = "app:current_user",
  Locale = "app.ui:language",
  MenuCollapsed = "app.ui:menu_collapsed",
  RedactedDataCollapsed = "app.report:redacted_data_collapsed",
  InReportInsights = "app.report:in_report_insights",
  NeedsSelectedSurveyDeploymentId = "app.needs:selected_survey_deployment_id",
  ViewedPlan = "app.team:viewed_plan",
  DistrictSetupWidget = "app.plan:district_setup_widget",
  SuperintendentAsPrincipalInfo = "app.next-steps.superintendent-as-principal-info:watched",
  UserNotesTourWatched = "app.tours.user-notes:watched",
  PriorityCardBellTour = "app.tour.priority-card-bell:watched",
  DataCollectionModalWatched = "app.modals.data-collection:watched",
  SensitiveReportingDataHidden = "app.admin:reporting_data_hidden",
}

export const getBooleanFromLocalStorage = (
  key: string,
  defaultValue?: boolean
) => {
  return Boolean(
    JSON.parse(localStorage.getItem(key) ?? Boolean(defaultValue).toString())
  );
};

export const getObjectFromLocalStorage = (key: string) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : undefined;
};

export const getNumberFromLocalStorage = (key: string): number | undefined => {
  const item = localStorage.getItem(key);
  if (!item) {
    return undefined;
  }
  const number = _.toNumber(item);
  return isNaN(number) ? undefined : number;
};

export const getStringFromLocalStorage = (key: string) => {
  const item = localStorage.getItem(key);
  return item ? String(JSON.parse(item)) : undefined;
};

export function getArrayFromLocalStorage<T>(key: string): Array<T> {
  return Array.from(JSON.parse(localStorage.getItem(key) ?? "[]"));
}

export const resetLocalStorage = (keys: string[]) => {
  keys.forEach((key) => localStorage.removeItem(key));
};
