import { Plan, UserRole } from "../../types";
import { changeActivePlan } from "../../store/plans/actions";
import { AppToaster } from "../toaster";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

export const usePlanSwitcher = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const plans = useSelector((s) => s.plans.plans);
  const activePlan = useSelector((s) => s.plans.activePlan);
  const currentUser = useSelector((s) => s.auth.authenticatedUser);

  const switchPlanIfNeeded = (
    callback: Function,
    toDistrictPlan?: boolean,
    onlyForPrincipal?: boolean
  ) => {
    if (!currentUser?.id) {
      return;
    }

    let findFunc;

    if (toDistrictPlan) {
      findFunc = (plan: Plan) => !plan?.school?.id;
    } else {
      if (onlyForPrincipal) {
        findFunc = (plan: Plan) =>
          currentUser?.profile?.role === UserRole.Principal
            ? plan.id === activePlan?.id
            : plan?.school?.principal?.id &&
              currentUser.id == plan.school.principal.id;
      } else {
        findFunc = (plan: Plan) => plan?.school?.principal?.id;
      }
    }

    const plan = plans.find(findFunc);

    if (plan) {
      if (plan.id !== activePlan?.id) {
        dispatch(changeActivePlan(plan));
      }
      callback();
    } else {
      AppToaster.show({
        icon: "warning-sign",
        intent: "warning",
        message: intl.formatMessage(
          {
            id: "app.errors.registration.unable-to-find-plan",
          },
          {
            isSchoolPlan: !toDistrictPlan,
          }
        ),
      });
    }
  };

  return {
    switchPlanIfNeeded,
  };
};

export default usePlanSwitcher;
