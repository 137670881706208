import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  District,
  ResponseRatesByDemographic,
  School,
  SurveyDemographic,
} from "../../../types";
import {
  Classes,
  Dialog,
  Icon,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolsByDistricts } from "../../../store/schools/actions";
import SchoolSelector from "../../common/selectors/SchoolSelector";
import { getSurveyReportResponseRates } from "../../../store/survey-reports/actions";
import ResponseRatesTable from "../../surveys/table/ResponseRatesTable";
import _ from "lodash";

type OwnProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  surveyDeploymentId?: number;
  districts?: District[];
  isTeamPlanDialog?: boolean;
  isReady?: boolean;
  surveyDeploymentSchoolId?: number;
};

type Props = OwnProps;

const SurveyDeploymentResponsesDialog: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const {
    isOpen,
    setOpen,
    surveyDeploymentId,
    districts,
    isTeamPlanDialog,
    isReady,
    surveyDeploymentSchoolId,
  } = props;

  const [schoolId, setSchoolId] = useState<number>();

  const handleClose = () => {
    setSchoolId(undefined);
    setOpen(false);
  };

  const handleOpen = () => {
    if (isTeamPlanDialog) {
      setSchoolId(surveyDeploymentSchoolId);
    }
  };

  const handleSchoolChange = (schoolId?: number) => {
    setSchoolId(schoolId);
  };

  const schoolsByDistricts = useSelector(
    (s) => s.schools.schools as School<number>[]
  );
  const schoolsLoading = useSelector(
    (s) => s.schools.loading.getSchoolsByDistricts
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (districts !== undefined && !isTeamPlanDialog) {
      dispatch(
        getSchoolsByDistricts.request({
          district_ids: districts.map((item) => item.id),
        })
      );
    }
  }, [districts]);

  const responseRates = useSelector((s) => s.surveyReports.responseRates);

  const loading = useSelector(
    (s) => s.surveyReports.loading.getSurveyReportResponseRates
  );

  useEffect(() => {
    if (!!surveyDeploymentId && !!schoolId) {
      dispatch(
        getSurveyReportResponseRates.request({
          schoolId: schoolId,
          surveyDeploymentId: surveyDeploymentId,
        })
      );
    }
  }, [schoolId, surveyDeploymentId]);

  const displayedData = useMemo(() => {
    return _.chain(responseRates?.response_rates)
      .entries()
      .map<[string, ResponseRatesByDemographic[] | undefined]>(
        ([demographic, rates]) => [
          demographic,
          !isReady
            ? rates?.filter(
                (rate) =>
                  rate.title === "overall" ||
                  ([
                    SurveyDemographic.Students,
                    SurveyDemographic.ElementaryStudents,
                  ].includes(demographic as SurveyDemographic)
                    ? rate.title === "grade"
                    : false)
              )
            : rates,
        ]
      )
      .value();
  }, [responseRates, isReady]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      onOpening={handleOpen}
      icon={<Icon icon="form" iconSize={24} />}
      title={intl.formatMessage({
        id: "app.survey-deployment.responses-dialog.title",
      })}
      className={"w-2/3"}
    >
      <div className={Classes.DIALOG_BODY}>
        {!isTeamPlanDialog && (
          <SchoolSelector
            schools={schoolsByDistricts}
            onSchoolChange={handleSchoolChange}
            loading={schoolsLoading}
          />
        )}
        {!!schoolId && !!surveyDeploymentId ? (
          <>
            {loading ? (
              <Spinner intent="primary" className="my-2" />
            ) : (
              <div className="space-y-2">
                {!isReady && (
                  <p>
                    {intl.formatMessage({ id: "app.responses-dialog.header" })}
                  </p>
                )}
                <div className="flex flex-row flex-wrap">
                  {displayedData.map(
                    (
                      [demographic, ratesByDemographic]: [
                        string,
                        ResponseRatesByDemographic[] | undefined
                      ],
                      index
                    ) => (
                      <div className="flex flex-1 flex-col px-2" key={index}>
                        {ratesByDemographic?.map((rates, i) => (
                          <ResponseRatesTable
                            key={i}
                            demographic={demographic as SurveyDemographic}
                            ratesByDemographic={rates}
                          />
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <NonIdealState
            className="py-2"
            icon="zoom-out"
            title={intl.formatMessage({
              id: "app.non-ideal-state.survey-reports.no-school.title",
            })}
            description={intl.formatMessage({
              id: "app.non-ideal-state.survey-reports.no-school.description",
            })}
          />
        )}
      </div>
    </Dialog>
  );
};

export default SurveyDeploymentResponsesDialog;
