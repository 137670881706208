import { Tabs, Tab, InputGroup } from '@blueprintjs/core';
import React, { FunctionComponent, useEffect } from 'react'
import './GoalActivity.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getGoalActivityLog } from '../../../../../store/goals/actions';
import moment from 'moment';
import { useIntl } from 'react-intl';

type OwnProps = {};

type Props = OwnProps;

const GoalActivity: FunctionComponent<Props> = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { goal } = useSelector(s => s.goals.newGoalState);
    const goalActivityData = useSelector(s => s.goals.goalActivityData);

    useEffect(() => {
        goal.id &&
            dispatch(getGoalActivityLog.request(goal.id));
    }, [goal]);

    return (
        <>
            <h3>{intl.formatMessage({
                id: "app.titles.activity",
            })}</h3>
            <div className="goal-activity">
                <div className="goal-activity__content">
                    <ul>
                        {
                            goalActivityData?.map((et, i) => (
                                <><li><span className="users light-blue">{`${et.user?.first_name.charAt(0).toUpperCase()}${et.user?.last_name.charAt(0).toUpperCase()}`}</span>
                                    <strong>{et.user?.first_name} {et.user?.last_name}</strong>
                                    &nbsp;{et.updated && et.is_item_domain_deleted ? 'deleted a domain/item indicator' : et.updated && et.is_target_date_changed ? 'updated achieve by date' : et.updated && et.is_target_value_changed ? 'updated target score': et.updated ? 'updated the goal' : 'created the goal'}
                                    &nbsp; &nbsp;{moment(et.created_at).format("MMM DD,  YYYY")}
                                    &nbsp;at&nbsp;{moment(et.created_at).format("hh:mm A")}</li></>
                            ))
                        }
                    </ul>
                </div>

            </div>
        </>
    )
}

export default GoalActivity