import React, { FunctionComponent, useEffect, useMemo } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Icon } from '@blueprintjs/core';
import './GetStartedCheckList.scss'
import { DistrictSetupTask, DistrictSetupTaskKey, TourDictionary, UserNotesType } from '../../types';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import useUserRole from '../../helpers/hooks/useUserRole';
import useUserPlanAssociation from '../../helpers/hooks/useUserPlanAssociation';
import { useSelector } from 'react-redux';
import { hideDistrictSetupWidget, markStepCompleted, markStepsCompleted, selectDistrictSetupTaskKey } from '../../store/tours/actions';
import { getSchoolsByDistrict } from "../../store/schools/actions";
import usePlanSwitcher from "../../helpers/hooks/usePlanSwitcher";
import { AuthRoutes } from "../../App";
import _ from "lodash";
import DistrictSetupInfoDialog from "../team-plan/dashboard/district-setup-widget/district-setup-info-dialog/DistrictSetupInfoDialog";
import MakingProgressTour from "../team-plan/dashboard/district-setup-widget/tours/MakingProgressTour";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DialogActions } from "@material-ui/core";
import { updateProfile } from "../../store/profile/actions";
import  CongratulationImg  from "../../static/images/congratulationImg.png"
import './congratulation.scss';
import SuperintendentProgressTour from "../team-plan/dashboard/district-setup-widget/tours/SuperintendentProgressTour";
import IsDccCheckMarkTour from "../team-plan/dashboard/district-setup-widget/tours/IsDccCheckMarkTour";
import InviteTeamMemberTour from "../team-plan/dashboard/district-setup-widget/tours/InviteTeamMemberTour";
import AddSchoolInvitePrincipleTour from "../team-plan/dashboard/district-setup-widget/tours/AddSchoolInvitePrincipleTour";
import LearnAboutTeamDevelopmentTour from "../team-plan/dashboard/district-setup-widget/tours/LearnAboutTeamDevelopmentTour";
import { isDistrictPlan } from "../../store/plans/selectors";
import DccInviteTeamMemberTour from "../team-plan/members/tours/DccInviteTeamMemberTour";



type OwnProps = {
  tours: TourDictionary;

};

type Props = OwnProps;

const  BasicAccordion:FunctionComponent<Props>=({tours})=> {

  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const isActiveDistrictPlan = useSelector(isDistrictPlan);
  
  const {setupCompleted}=useSelector((s)=>s.tours)
  const { isDCC, isSuperintendent, user } = useUserRole();
  const {
    planSchoolId,
    planDistrictId,
    isSingleSchoolDistrict,
    isSchoolPlanPrincipal,
  } = useUserPlanAssociation();

  const members = useSelector((s) => s.teamMembers.teamMembers);
  // React.useEffect(() => {
  //   const DCCs = members.filter((m) => m.user?.profile?.is_dcc);

  //   if (DCCs.length) {
  //     dispatch(
  //       markStepCompleted({
  //         key: DistrictSetupTaskKey.DesignateDistrictClimateCoordinator,
  //       })
  //     );
  //   }
  // }, [members]);

  const schools = useSelector((s) => s.schools.schools);
  useEffect(() => {
    if (planDistrictId) {
      dispatch(getSchoolsByDistrict.request(planDistrictId));
    }
  }, [planDistrictId]);

  useEffect(() => {
    if (schools.length) {
      if(isSuperintendent){
        return;
      }else{ 
        dispatch(
        markStepsCompleted({
          keys: [
            DistrictSetupTaskKey.RegisterYourSchool,
            DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
          ],
        })
      );
      }
    }
  }, [schools]);

  const handleHideClick = () => {
    if (user) {
      dispatch(
        updateProfile.request({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          profile: {
            is_setup_completed: true,
          },
        })
      );
      localStorage.removeItem('tourSteps');
    }
    dispatch(hideDistrictSetupWidget());
  };

  const { switchPlanIfNeeded } = usePlanSwitcher();

  const planTasks: DistrictSetupTask[] = useMemo((): DistrictSetupTask[] => {
    const tasks = [
      {
        id: DistrictSetupTaskKey.DesignateDistrictClimateCoordinator,
        order: 1,
        text: intl.formatMessage({
          id: `app.district-setup.tasks.${DistrictSetupTaskKey.DesignateDistrictClimateCoordinator}`,
        }),
        checked:
          tours[DistrictSetupTaskKey.DesignateDistrictClimateCoordinator],
        show: isSuperintendent && !isDCC,
        onClick: () => {
          switchPlanIfNeeded(
            () =>
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                } as any)
              ),
            true,
            false
          );
        },
      },
      {
        id: DistrictSetupTaskKey.RegisterYourSchool,
        order: 4,
        text: intl.formatMessage({
          id: `app.district-setup.tasks.${DistrictSetupTaskKey.RegisterYourSchool}`,
        }),
        checked: tours[DistrictSetupTaskKey.RegisterYourSchool],
        show: isSingleSchoolDistrict && (isSuperintendent || isDCC),
        onClick: () => {
          switchPlanIfNeeded(
            () =>
              history.push(
                generatePath(AuthRoutes.YourPlanDistrict, {
                  workspace: "my-district",
                } as any)
              ),
            true,
            false
          );
        },
      },
      
      {
        id: DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals,
        order: 5,
        text: intl.formatMessage({
          id: `app.district-setup.tasks.${DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals}`,
        }),
        checked: tours[DistrictSetupTaskKey.RegisterSchoolsAndInvitePrincipals],
        show: isSuperintendent || isDCC,
        onClick: () => {
          switchPlanIfNeeded(
            () =>
              history.push(
                generatePath(AuthRoutes.YourPlanDistrict, {
                  workspace: "my-district",
                } as any)
              ),
            true,
            false
          );
        },
      },

      {
        id: DistrictSetupTaskKey.LearnAboutTeamDevelopment,
        order: 6,
        text: intl.formatMessage(
          {
            id: `app.district-setup.tasks.${DistrictSetupTaskKey.LearnAboutTeamDevelopment}`,
          },
          { isSchoolPlan: !!planSchoolId }
        ),
        checked: tours[DistrictSetupTaskKey.LearnAboutTeamDevelopment],
        show: true,
        onClick: () => {
          history.push(
            generatePath(AuthRoutes.YourPlanTeam, {
              workspace: "team",
            } as any)
          );
        },
      },
      {
        id: DistrictSetupTaskKey.InviteOtherMembers,
        order: 7,
        text: intl.formatMessage({
          id: `app.district-setup.tasks.${DistrictSetupTaskKey.InviteOtherMembers}`,
        }),
        checked: tours[DistrictSetupTaskKey.InviteOtherMembers],
        show: isDCC,
        onClick: () => {
          switchPlanIfNeeded(
            () =>
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                } as any)
              ),
            true,
            false
          );
        },
      },
      {
        id: DistrictSetupTaskKey.InviteSchoolStaff,
        order: 8,
        text: intl.formatMessage({
          id: `app.district-setup.tasks.${DistrictSetupTaskKey.InviteSchoolStaff}`,
        }),
        checked: planSchoolId
          ? !!tours[DistrictSetupTaskKey.InviteSchoolStaff]?.[planSchoolId]
              ?.completed
          : _.values(tours[DistrictSetupTaskKey.InviteSchoolStaff]).some(
              (completed) => completed
            ),
        show: !isSuperintendent && isSchoolPlanPrincipal,
        onClick: () => {
          switchPlanIfNeeded(
            () =>
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                } as any)
              ),
            false,
            true
          );
        },
      },
      {
        id: DistrictSetupTaskKey.SetupDistrictCalendar,
        order: 9,
        text: intl.formatMessage({
          id: `app.district-setup.tasks.${DistrictSetupTaskKey.SetupDistrictCalendar}`,
        }),
        checked: tours[DistrictSetupTaskKey.SetupDistrictCalendar],
        show: isActiveDistrictPlan,
        onClick: () => {
          switchPlanIfNeeded(
            () =>
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                  tabId: UserNotesType.DCLT_SCI_CALENDAR,
                } as any)
              ),
            true,
            false
          );
        },
      },
      {
        id: DistrictSetupTaskKey.SetupSchoolCalendar,
        order: 10,
        text: intl.formatMessage({
          id: `app.district-setup.tasks.${DistrictSetupTaskKey.SetupSchoolCalendar}`,
        }),
        checked: planSchoolId
          ? !!tours[DistrictSetupTaskKey.SetupSchoolCalendar]?.[planSchoolId]
          : _.values(tours[DistrictSetupTaskKey.SetupSchoolCalendar]).some(
              (completed) => completed
            ),
        show:!isActiveDistrictPlan,
        onClick: () => {
          switchPlanIfNeeded(
            () =>
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                  tabId: UserNotesType.SCLT_SCI_CALENDAR,
                } as any)
              ),
            false,
            false
          );
        },
      },
    ];

    return _.chain(tasks)
      .filter((t) => t.show)
      .sortBy((t) => t.order)
      .value();
  }, [
    tours,
    isSingleSchoolDistrict,
    isSuperintendent,
    planSchoolId,
    isSchoolPlanPrincipal,
    isDCC,
    switchPlanIfNeeded,
  ]);

  const { allTasksDone, someTaskDone, currentTask,remainingTasks  } = useMemo(() => {
    const completedTasks = planTasks.filter((t) => t.checked);
    const currentTask = planTasks.find((pT) => !pT.checked);

    const prevCompletedTasks = currentTask
      ? completedTasks.filter((cT) => cT.order <= currentTask.order)
      : [];

    return {
      allTasksDone: completedTasks.length === planTasks.length,
      someTaskDone: prevCompletedTasks.length > 1,
      currentTask: currentTask,
      remainingTasks: planTasks.length-completedTasks.length
    };
  }, [planTasks]);


  const teamTour = useMemo(() => {
    if (!setupCompleted && localStorage.getItem("tourSteps")=== "inviteTeamMembersTour") {
      return true;
    }
    return false;
  }, [isDCC,setupCompleted,localStorage.getItem("tourSteps")]);

const doNowTour = () =>{
  if(!setupCompleted && localStorage.getItem("tourSteps")=== "isDccCheckMark" ){
    return <IsDccCheckMarkTour />
  }else if(!setupCompleted && localStorage.getItem("tourSteps")=== "inviteTeamMembers"){
    return <InviteTeamMemberTour />

  }else if(!setupCompleted && localStorage.getItem("tourSteps")==="AddSchoolInvitePrinciple"){
    return <AddSchoolInvitePrincipleTour />
  }else if(!setupCompleted && localStorage.getItem("tourSteps")==="leadershipTeamDevelopment"){
    return <LearnAboutTeamDevelopmentTour/>
  }
  else if(!setupCompleted && localStorage.getItem("tourSteps")==="inviteTeamMembersTour"){
    return <DccInviteTeamMemberTour/>
  }
  else {
    return <SuperintendentProgressTour />
  }
}

  return (
    <div
      // data-tour={"district-setup-progress"}
      //   data-cy={"district-setup-progress"}
    >
      {allTasksDone ?"" : (
          <>
            {isSuperintendent && !isDCC ? doNowTour():<MakingProgressTour isFirst={!someTaskDone} />}
          </>
        )}
        <div className="onboardingGetStarted-wrapper">
        <Accordion className="onboardingGetStarted-acc" defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
       <RocketLaunchIcon /> <Typography>  Get Started</Typography> {allTasksDone?
       <Icon icon="tick-circle" iconSize={18} />:
       <span className="yellowBadge" >{remainingTasks}</span>}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* {props.children} */}
            {planTasks.map((task) => {
          const notReadyForThisStep =
            currentTask && currentTask.order < task.order;
          return ( 
              <ul key={task.id}>
               <li
               data-cy={task.id}
               aria-checked={!!task.checked}
               onClick={()=>
                  task?.onClick && task.onClick()
                } className={
                   task.checked && !notReadyForThisStep
                    ? "stageDone"
                    : "stageNotDone"
                }><Icon icon="tick-circle" iconSize={18} /> {task.text}</li>
              
           </ul>)
           
            })}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <DistrictSetupInfoDialog />

        </div>
        {allTasksDone&&
        <div className="congratulationModal">
        <img src={CongratulationImg} alt="" />
        <h1>{intl.formatMessage({id:"app.titles.congratulations"})}</h1>
        <p>{intl.formatMessage({id:"app.title.callout.completed-tour"})}</p>
        <button onClick={()=>handleHideClick()}>{intl.formatMessage({id:"app.titles.done"})}</button>
        </div>}
    </div>
    
  );
}



export default BasicAccordion;