import { useMemo } from "react";
import _ from "lodash";
import {
  BookmarkType,
  Dataset,
  DatasetMark,
  SurveyDemographic,
} from "../../../../types";
import { useSelector } from "react-redux";

type Props = {
  domainId?: number;
  demographic?: SurveyDemographic[];
};

export const POTENTIAL_PRIORITY_COLOR = "#FFE9A3";// "gold";
export const POTENTIAL_PRIORITY_BTN_COLOR = "#2A3560";  // "gold";

export type BookmarksLevelInfoInfo = {
  dataset: Dataset;
} & {
  [key in BookmarkType]: number;
};

export type BookmarksLevelInfo = {
  domainLevel: BookmarksLevelInfoInfo[];
  itemLevel: BookmarksLevelInfoInfo[];
};

export const useBookmarksLevelInfo = (props: Props) => {
  const { domainId, demographic } = props;

  const datasetMarks: DatasetMark[] = useSelector(
    (s) => s.datasetMarks.datasetMarks
  );

  const bookmarksInfo: BookmarksLevelInfo = useMemo(() => {
    const uniqueDomainGradePairs = new Set<string>();
    return _.chain(datasetMarks)
      .filter((dM) => {
        const currentDomainId = dM.dataset.domain?.id;
        const currentGrade = dM.dataset.grade;
        const isIncluded = !!demographic?.includes(dM.dataset.demographic!) && currentDomainId === domainId;
        const domainGradeKey = `${currentDomainId}-${currentGrade}`;
        if (domainGradeKey && !uniqueDomainGradePairs.has(domainGradeKey)) {
          uniqueDomainGradePairs.add(domainGradeKey);
          return isIncluded;
        }
        return false;
      })
      .groupBy((dM) => dM.dataset.id)
      .values()
      .map<BookmarksLevelInfoInfo>((items) => {
        const bookmarkStats: { [key in BookmarkType]: number } = _.chain(items)
          .reduce(
            (pV, cV) => {
              return {
                ...pV,
                [cV.bookmark_type]: pV[cV.bookmark_type] + 1,
              };
            },
            {
              positive: 0,
              negative: 0,
            }
          )
          .value();
        return {
          dataset: items[0].dataset,
          ...bookmarkStats,
        };
      })
      .reduce<BookmarksLevelInfo>(
        (pV, cV) => {
          if (cV.dataset.question) {
            return {
              ...pV,
              itemLevel: [...pV.itemLevel, cV],
            };
          }

          return {
            ...pV,
            domainLevel: [...pV.domainLevel, cV],
          };
        },
        {
          domainLevel: [],
          itemLevel: [],
        }
      )
      .value();
  }, [datasetMarks, demographic, domainId]);
  return bookmarksInfo;
};
